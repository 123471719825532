import React, { Fragment } from 'react';
import {
  Col,
  Row,
  FormGroup,
  Form,
  FormControl,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import styles from '../../styles/formSetting.module.scss';
import {
  ValidCase,
  Validator,
  WarningFeeInput,
} from '../../../../components/validator';
import SelectMultiple from '../../../../components/SelectMultiple';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import {
  Validation,
  filterCommissionServiceActive,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';

const FormSetting = (valProps) => {
  const {
    props,
    state,
    validatorCallback,
    handleZoneChange,
    handleNoteChange,
    handleSearchNote,
    searchNote,
    handleAddNote,
    noteList,
    handleCommissionTypeChangeAndValue,
    handleCommissionTypeChange,
    handleMarketplaceChange,
    showCommissionClick,
    handleCommissionDriverChange,
    saveCommissionEdit,
    editFleetCommission,
    handleMarketplaceTypeChange,
    handleCommissionChange,
    handleCommissionCompanyChange,
    showCompanyCommissionClick,
    saveCompanyCommissionEdit,
    handleCompanyCommissionTypeChangeAndValue,
    handleAcceptChange,
    handleTopUpOtherDriverChange,
    handleTopDriverChange,
    handleRideSharingChange,
    closeDialogForm,
    saveDialogForm,
    onEditable,
    action,
    noteOptions,
    handleSelectVehicleChange,
  } = valProps;

  const {
    detailItem,
    isSubmited,
    valid,
    editable,
    selectedCurrency,
    carType,
    commission,
  } = state;
  const { auth, commonData, permissions } = props;

  const selectedCarType = commonData.carType.find((ct) => ct._id == carType);

  const isRideSharingEnabled = () => {
    return true;
  };

  const getZoneBasedCompany = () => {
    const companyId = detailItem?.driverInfo?.company?.companyId || '',
      fleetZones = props.commonData.mapZone,
      fleetCompany = [
        ...props.commonData.companies,
        ...props.commonData.suppliers,
      ];

    if (companyId) {
      const cpInfo = fleetCompany.find((cp) => cp._id === companyId);
      return fleetZones.filter((zone) => {
        if (!cpInfo?.operationZone?.length) {
          return true;
        }
          return (cpInfo?.operationZone || []).includes(zone._id)
        }
      );
    }
    return fleetZones;
  };
  const allZonesByCurrency = _.filter(
    getZoneBasedCompany(),
    (z) => z.currency && z.currency.iso === selectedCurrency
  );

  const marketplaceToolTip = (
    <Tooltip id="tooltip">
      <Translate value="driver.Market_place_tooltip" />
    </Tooltip>
  );

  let zonesToSelect = allZonesByCurrency;

  if (selectedCarType) {
    const zoneByCarType = selectedCarType.zoneId || [];
    zonesToSelect = _.filter(allZonesByCurrency, (zone) => {
      let exist = _.find(zoneByCarType, (z) => z._id === zone._id);
      return exist ? true : false;
    });
  } else if (commonData.relativeInfo) {
    zonesToSelect = _.filter(allZonesByCurrency, (zone) => {
      let exist = _.find(
        commonData.relativeInfo.zones,
        (z) => z._id === zone._id
      );
      return exist ? true : false;
    });
  }

  const zoneOptions = zonesToSelect.map((zone) => {
    return {
      value: zone._id,
      label: zone.zoneName,
    };
  });
  const defaultZone = zoneOptions.filter((zone) => {
    return detailItem.driverInfo.zoneId.includes(zone.value);
  });

  const disableActivateCheckbox =
    (permissions && !permissions.activatedriver) || !state.editable;

  const companyCommission = _.get(
    auth,
    'selectedFleet.generalSetting.companyCommission',
    false
  );

  // const renderCompanyCommission = () => {
  //   const commissionCompanyValue = filterCommissionServiceActive(
  //     state.detailItem.driverInfo.commissionCompanyValue,
  //     auth.selectedFleet,
  //     true
  //   );
  //   const commissionCompanyType = detailItem.driverInfo.commissionCompanyType;
  //   return (
  //     <Fragment>
  //       <FormGroup className="mt-md" style={{ marginBottom: '7px' }}>
  //         <Form.Label>
  //           <h3
  //             style={{ fontSize: '20px', marginTop: '10px' }}
  //             className="mb-sm"
  //           >
  //             <Translate value="driver.supplierComission" />
  //           </h3>
  //           <div className={styles['description']}>
  //             <Translate value="driver.supplierComissionDes" />{' '}
  //           </div>
  //         </Form.Label>
  //         <FormControl
  //           as="select"
  //           value={commissionCompanyType}
  //           onChange={handleCommissionCompanyChange}
  //           disabled={!editable}
  //           className="form-custom"
  //         >
  //           <option value="default">Default</option>
  //           <option value="customize">Customize</option>
  //         </FormControl>
  //       </FormGroup>
  //       {commissionCompanyType === 'customize' && editable && (
  //         <a
  //           onClick={showCompanyCommissionClick}
  //           href="javascript:void(0)"
  //           className="text-active editCommission"
  //           style={{ marginTop: '0px' }}
  //         >
  //           Edit/Add
  //         </a>
  //       )}
  //       <Modal
  //         show={state.showCompanyCommission}
  //         onHide={showCompanyCommissionClick}
  //         backdrop="static"
  //         aria-labelledby="contained-modal-title-sm"
  //         dialogClassName="add-edit-driver-dialog"
  //       >
  //         <Modal.Header closeButton>
  //           <Modal.Title className="title_commission">
  //             Commission by service
  //           </Modal.Title>
  //         </Modal.Header>
  //         <Modal.Body className="clearfix commissionBody">
  //           {commissionCompanyValue &&
  //             commissionCompanyValue.map((commission) => {
  //               return (
  //                 <Fragment>
  //                   <div className="group-general">
  //                     <div key={commission.serviceType}>
  //                       <Row>
  //                         <Col xs={12}>
  //                           <FormGroupTitle>
  //                             <Translate
  //                               value={`driver.${commission.serviceType}`}
  //                             />
  //                           </FormGroupTitle>
  //                         </Col>
  //                         <Col md={6} xs={12}>
  //                           <FormGroup>
  //                             <FormControl
  //                               as="select"
  //                               value={'percent'}
  //                               // onChange={
  //                               //   e => {handleCommissionTypeChangeAndValue(e, commission.serviceType, 'type')}
  //                               // }
  //                               disabled={!editable}
  //                               className="form-custom"
  //                             >
  //                               <option value="percent">
  //                                 {I18n.t('driver.Percentage')}
  //                               </option>
  //                               {/* <option value="amount">{I18n.t("driver.Amount")}</option> */}
  //                             </FormControl>
  //                           </FormGroup>
  //                         </Col>
  //                         <Col md={6}>
  //                           <FormGroup className="qup-input-group">
  //                             <div className="input-with-validator">
  //                               <InputGroup>
  //                                 <InputGroup.Prepend>
  //                                   <InputGroup.Text>{'%'}</InputGroup.Text>
  //                                 </InputGroup.Prepend>
  //                                 <FormControl
  //                                   type="text"
  //                                   onChange={(e) => {
  //                                     handleCompanyCommissionTypeChangeAndValue(
  //                                       e,
  //                                       commission.serviceType,
  //                                       'value'
  //                                     );
  //                                   }}
  //                                   onBlur={(e) => {
  //                                     textboxNumberHelper.onBlurHandle(
  //                                       e,
  //                                       (e) => {
  //                                         handleCompanyCommissionTypeChangeAndValue(
  //                                           e,
  //                                           commission.serviceType,
  //                                           'value'
  //                                         );
  //                                       }
  //                                     );
  //                                   }}
  //                                   onFocus={(e) => {
  //                                     textboxNumberHelper.onfocusHandle(
  //                                       e,
  //                                       (e) => {
  //                                         handleCompanyCommissionTypeChangeAndValue(
  //                                           e,
  //                                           commission.serviceType,
  //                                           'value'
  //                                         );
  //                                       }
  //                                     );
  //                                   }}
  //                                   value={commission.value}
  //                                   disabled={!editable}
  //                                 />
  //                                 <InputGroup.Append>
  //                                   <InputGroup.Text>
  //                                     /<Translate value="driver.transaction" />
  //                                   </InputGroup.Text>
  //                                 </InputGroup.Append>
  //                               </InputGroup>
  //                             </div>
  //                           </FormGroup>
  //                         </Col>
  //                       </Row>
  //                     </div>
  //                   </div>
  //                 </Fragment>
  //               );
  //             })}
  //         </Modal.Body>
  //         <Modal.Footer>
  //           <Button className="btn-save" onClick={saveCompanyCommissionEdit}>
  //             <Translate value="newbooking.Save" />
  //           </Button>
  //           <Button className="btn-cancel" onClick={showCompanyCommissionClick}>
  //             <Translate value="newbooking.Cancel" />
  //           </Button>
  //         </Modal.Footer>
  //       </Modal>
  //     </Fragment>
  //   );
  // };

  const renderNewCommission = () => {
    let commissionDriverValue = filterCommissionServiceActive(
      state.detailItem.driverInfo.commissionDriverValue,
      auth.selectedFleet
    );
    let commissionDriverType = detailItem?.driverInfo?.commissionDriverType;
    const driverInsurance = auth.selectedFleet.generalSetting.driverInsurance;
    return (
      <Fragment>
        <FormGroup style={{ marginBottom: '7px' }}>
          <Form.Label className="mb">
            <Translate value="" />
          </Form.Label>
          <Form.Label>
            <h3
              style={{ fontSize: '20px', marginTop: '10px' }}
              className="mb-sm"
            >
              <Translate value="driver.fleetCommission" />
            </h3>
            <div className={styles['description']}>
              <Translate value="driver.fleetCommissionDes" />{' '}
            </div>
          </Form.Label>
          <FormControl
            as="select"
            value={commissionDriverType}
            onChange={handleCommissionDriverChange}
            disabled={editFleetCommission}
            className="form-custom"
          >
            <option value="default">Default</option>
            <option value="customize">Customize</option>
          </FormControl>
        </FormGroup>
        {commissionDriverType === 'customize' && !editFleetCommission && (
          <a
            onClick={showCommissionClick}
            href="javascript:void(0)"
            className="text-active editCommission"
            style={{ marginTop: '0px' }}
          >
            Edit/Add
          </a>
        )}
        <Modal
          show={state.showCommission}
          onHide={showCommissionClick}
          backdrop="static"
          aria-labelledby="contained-modal-title-sm"
          dialogClassName="add-edit-driver-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title className="title_commission">
              Commission by service
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="clearfix commissionBody">
            {commissionDriverValue &&
              commissionDriverValue.map((commission) => {
                let symbol =
                  commission.type === 'percent'
                    ? '%'
                    : auth.selectedFleet.currencies[0].symbol;
                return (
                  <Fragment>
                    <div className="group-general">
                      <div key={commission.serviceType}>
                        <Row>
                          <Col xs={12}>
                            <FormGroupTitle>
                              <Translate
                                value={`driver.${commission.serviceType}`}
                              />
                            </FormGroupTitle>
                          </Col>
                          <Col md={6} xs={12}>
                            <FormGroup>
                              <FormControl
                                as="select"
                                value={commission.type}
                                onChange={(e) => {
                                  handleCommissionTypeChangeAndValue(
                                    e,
                                    commission.serviceType,
                                    'type'
                                  );
                                }}
                                disabled={!editable}
                                className="form-custom"
                              >
                                <option value="percent">
                                  {I18n.t('driver.Percentage')}
                                </option>
                                <option value="amount">
                                  {I18n.t('driver.Amount')}
                                </option>
                              </FormControl>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <div className="input-with-validator">
                              <FormGroup className="qup-input-group">
                                <InputGroup className="">
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>{symbol}</InputGroup.Text>
                                  </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      onChange={(e) => {
                                        handleCommissionTypeChangeAndValue(
                                          e,
                                          commission.serviceType,
                                          'value'
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            handleCommissionTypeChangeAndValue(
                                              e,
                                              commission.serviceType,
                                              'value'
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            handleCommissionTypeChangeAndValue(
                                              e,
                                              commission.serviceType,
                                              'value'
                                            );
                                          }
                                        );
                                      }}
                                      value={commission.value}
                                      disabled={!editable}
                                    />
                                    <InputGroup.Append>
                                      <InputGroup.Text>
                                        /<Translate value="driver.transaction" />
                                      </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        {driverInsurance && (
                          <Row>
                            <Col xs={12}>
                              <Form.Label className="mb titleService">
                                <Translate value={`driver.driverInsurance`} />
                              </Form.Label>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="qup-input-group">
                                <InputGroup className={`single-addon-left ${
                                  editFleetCommission ? 'disabled' : ''
                                }`}>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      {
                                        auth.selectedFleet.currencies[0]
                                          .symbol
                                      }
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                      type="text"
                                      onChange={(e) => {
                                        handleCommissionTypeChangeAndValue(
                                          e,
                                          commission.serviceType,
                                          'insurance'
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            handleCommissionTypeChangeAndValue(
                                              e,
                                              commission.serviceType,
                                              'insurance'
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            handleCommissionTypeChangeAndValue(
                                              e,
                                              commission.serviceType,
                                              'insurance'
                                            );
                                          }
                                        );
                                      }}
                                      value={commission.insurance}
                                      disabled={!editable}
                                    />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-save" onClick={saveCommissionEdit}>
              <Translate value="newbooking.Save" />
            </Button>
            <Button className="btn-cancel" onClick={showCommissionClick}>
              <Translate value="newbooking.Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  };

  const renderCommission = () => {
    let commissionByCurrencies = detailItem.driverInfo.commissionByCurrencies;
    let formInput = null;
    const commissionType = detailItem.driverInfo.commissionType;

    if (!auth.selectedFleet || !commissionByCurrencies) return null;

    if (
      commissionType === 'percent' ||
      auth.selectedFleet.currencies.length < 2
    ) {
      // render permission input
      let validateState =
        commissionType === 'percent'
          ? valid.percentCommission
          : valid.commission;

      let symbol =
        commissionType === 'percent'
          ? '%'
          : auth.selectedFleet.currencies[0].symbol;

      formInput = (
        <FormGroup
          className={`qup-input-group ${
            isSubmited ? (validateState ? null : 'error') : null
          }`}
          style={{ marginBottom: '5px' }}
        >
          <div className="input-with-validator">
            <InputGroup className={editFleetCommission ? 'disabled' : ''}>
              <InputGroup.Prepend>
                <InputGroup.Text>{symbol}</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                onChange={handleCommissionChange}
                onBlur={(e) => {
                  textboxNumberHelper.onBlurHandle(e, handleCommissionChange);
                }}
                onFocus={(e) => {
                  textboxNumberHelper.onfocusHandle(e, handleCommissionChange);
                }}
                value={commission}
                disabled={editFleetCommission}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  /<Translate value="driver.transaction" />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <WarningFeeInput
              message={I18n.t('message.warningInputChangeOver')}
              className="text-soft-warning"
              value={commission}
              isReset={commissionType === 'percent' ? true : false}
              disabled={editFleetCommission}
              timeDelay={500}
              typeOfCheck="higherOfLower"
            />
          </div>
          <Validator
            id={
              commissionType === 'percent' ? 'percentCommission' : 'commission'
            }
            callback={validatorCallback}
          >
            <ValidCase
              hide={!isSubmited}
              valid={
                Validation.isDecimal(commission) ||
                Validation.isStringEmpty(commission)
              }
              message={I18n.t('driver.ERROR_COMMISSION_NUMBERS')}
            />
          </Validator>
        </FormGroup>
      );
    } else {
      // render amount input
      let currency = _.find(
        commissionByCurrencies,
        (c) => c.currencyISO === selectedCurrency
      );
      let currencyValue = '';
      const selectedCurrencyObj = _.find(
        auth.selectedFleet.currencies,
        (c) => c.iso === selectedCurrency
      );

      if (currency) {
        currencyValue = currency.commissionValue;
      }

      formInput = (
        <FormGroup
          className={`qup-input-group ${
            isSubmited ? (valid[selectedCurrency] ? null : 'error') : null
          }`}
          style={{ marginBottom: '5px' }}
        >
          <div className="input-with-validator">
            <InputGroup className={editFleetCommission ? 'disabled' : ''}>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {(selectedCurrencyObj && selectedCurrencyObj.symbol) || ''}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                onChange={(e) => {
                  handleCommissionChange(e, selectedCurrency);
                }}
                onBlur={(e) => {
                  textboxNumberHelper.onBlurHandle(e, (e) => {
                    handleCommissionChange(e, selectedCurrency);
                  });
                }}
                onFocus={(e) => {
                  textboxNumberHelper.onfocusHandle(e, (e) => {
                    handleCommissionChange(e, selectedCurrency);
                  });
                }}
                value={currencyValue}
                disabled={editFleetCommission}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  /<Translate value="driver.transaction" />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>

            <WarningFeeInput
              message={I18n.t('message.warningInputChangeOver')}
              className="text-soft-warning"
              value={currencyValue}
              isReset={false}
              disabled={editFleetCommission}
              timeDelay={500}
              typeOfCheck="higherOfLower"
            />
          </div>
          <Validator id={selectedCurrency} callback={validatorCallback}>
            <ValidCase
              hide={!isSubmited}
              valid={
                Validation.isDecimal(currencyValue) ||
                Validation.isStringEmpty(currencyValue)
              }
              message={I18n.t('driver.ERROR_COMMISSION_NUMBERS')}
            />
          </Validator>
        </FormGroup>
      );
    }
    return (
      <Fragment>
        <Col>
          <Form.Label>
            <h3
              style={{ fontSize: '20px', marginTop: '10px' }}
              className="mb-sm"
            >
              <Translate value="driver.fleetCommission" />
            </h3>
            <div className={styles['description']}>
              <Translate value="driver.fleetCommissionDes" />{' '}
            </div>
          </Form.Label>
        </Col>
        <Row>
          <Col md={12} lg={6} style={{ marginTop: '10px' }}>
            <FormGroup style={{ marginBottom: '5px' }}>
              <FormControl
                as="select"
                value={detailItem.driverInfo.commissionType}
                onChange={handleCommissionTypeChange}
                disabled={editFleetCommission}
                className="form-custom"
              >
                <option value="percent">{I18n.t('driver.Percentage')}</option>
                <option value="amount">{I18n.t('driver.Amount')}</option>
              </FormControl>
            </FormGroup>
          </Col>
          <Col md={12} lg={6} style={{ marginTop: '10px' }}>
            {formInput}
          </Col>
        </Row>
      </Fragment>
    );
  };

  return (
    <>
      <FormGroup
        style={{ marginBottom: '0' }}
        controlId="formControlsSelectMultipleZone"
        className={isSubmited ? (valid.zone ? null : 'error') : null}
      >
        <Form.Label>
          <h3 style={{ fontSize: '20px', marginTop: '10px' }} className="mb-sm">
            <Translate value="driver.operationZones" />
            <span className="require"> *</span>
          </h3>
          <div className={styles['description']}>
            <Translate value="driver.operationZonesDes" />{' '}
          </div>
        </Form.Label>
        <SelectMultiple
          onChange={handleZoneChange}
          options={zoneOptions}
          value={defaultZone}
          isMulti={true}
          disabled={!editable}
        />
        <Validator id="zone" callback={validatorCallback}>
          <ValidCase
            hide={!isSubmited}
            valid={
              detailItem.driverInfo.zoneId &&
              detailItem.driverInfo.zoneId.length > 0
            }
            message={I18n.t('driver.ERROR_INPUT_ZONE')}
          />
        </Validator>
      </FormGroup>

      {!props?.auth?.user?.roles?.isSupplier && (
        <>
          <FormGroup controlId="formControlsSelectMultiple" className="mb-xl mt-xl">
            <Form.Label>
              <h3 style={{ fontSize: '20px' }} className="mb-sm">
                <Translate value="driver.notes" />
              </h3>
              <div className={styles['description']}>
                <Translate value="driver.notesDes" />{' '}
              </div>
            </Form.Label>

            <InputGroup disabled={!editable} className={styles['input-custom']}>
              <InputGroup.Prepend onClick={handleAddNote}>
                <InputGroup.Text>
                  <div
                    style={{
                      width: '21px',
                      height: '21px',
                      borderRadius: '50%',
                      border: '1px solid #fff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    +
                  </div>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                className="form-custom"
                onChange={(e) => handleSearchNote(e)}
                value={searchNote}
                placeholder={I18n.t('driver.searchNotes')}
                disabled={!editable}
                style={{ zIndex: 0 }}
              />
            </InputGroup>

            <SelectMultiple
              onChange={handleNoteChange}
              options={noteOptions}
              value={noteList}
              isMulti={true}
              disabled={!editable}
            />
          </FormGroup>

          {auth.selectedFleet &&
            auth.selectedFleet.marketplace &&
            auth.selectedFleet.marketplace.enable && (
              <>
                <CcCheckbox
                  className="mb-sm"
                  checked={detailItem.marketplace == 1}
                  onChange={handleMarketplaceChange}
                  disabled={!editable}
                  text={<Translate value="driver.Market_place" />}
                >
                  <span style={{ fontSize: '20px', marginTop: '10px' }}>
                    <Translate value="driver.marketplace" />
                  </span>
                </CcCheckbox>
                <div style={{ marginBottom: '10px' }}>
                  <div className={styles['description']}>
                    <Translate value="driver.marketplaceDes" />
                  </div>
                </div>
                {detailItem.marketplace == 1 && (
                  <>
                    <FormGroup>
                      <FormControl
                        as="select"
                        value={_.get(
                          detailItem,
                          'marketplaceSettingMode',
                          'default'
                        )}
                        placeholder="select"
                        onChange={handleMarketplaceTypeChange}
                        disabled={disableActivateCheckbox}
                        className="form-custom select-driver-type"
                      >
                        <option key={'default'} value={'default'}>
                          {I18n.t(`driver.default`)}
                        </option>
                        <option key={'customized'} value={'customized'}>
                          {I18n.t(`driver.customize`)}
                        </option>
                      </FormControl>
                    </FormGroup>
                    {_.get(detailItem, 'marketplaceSettingMode', 'default') ===
                      'customized' && (
                      <>
                        <FormGroup>
                          <Form.Label>
                            <Translate value="driver.acceptInDay" />
                            <QuestionCircleTooltip
                              text={
                                <Translate
                                  value="dispatchSetting.Dispatch_setting_Maximum_Day_tooltip"
                                  dangerousHTML
                                />
                              }
                            />
                          </Form.Label>
                          <FormControl
                            className="form-custom"
                            type="text"
                            value={_.get(
                              detailItem,
                              'maximumAcceptReservationPerDay',
                              0
                            )}
                            onChange={(e) =>
                              handleAcceptChange(
                                e,
                                'maximumAcceptReservationPerDay'
                              )
                            }
                            disabled={!editable}
                            maxLength={20}
                            autoFocus
                          />
                        </FormGroup>
                        <FormGroup>
                          <Form.Label>
                            <Translate value="driver.acceptATime" />
                            <QuestionCircleTooltip
                              text={
                                <Translate
                                  value="dispatchSetting.Maximum_Accept_Reservation_tooltip"
                                  dangerousHTML
                                />
                              }
                            />
                          </Form.Label>
                          <FormControl
                            className="form-custom"
                            type="text"
                            value={_.get(
                              detailItem,
                              'maximumAcceptReservation',
                              0
                            )}
                            onChange={(e) =>
                              handleAcceptChange(e, 'maximumAcceptReservation')
                            }
                            disabled={!editable}
                            maxLength={20}
                            autoFocus
                          />
                        </FormGroup>
                      </>
                    )}
                  </>
                )}
              </>
            )}

          {auth.selectedFleet.generalSetting &&
          auth.selectedFleet.generalSetting.differentFleetCommission
            ? renderNewCommission()
            : renderCommission()}

          {/* {companyCommission && renderCompanyCommission()} */}
          <Row className="mt-md">
            <Col xs={12}>
              <FormGroup>
                <Form.Label>
                  <h3
                    style={{
                      fontSize: '20px',
                      marginTop: '10px',
                      marginTop: '10px',
                    }}
                    className="mb-sm"
                  >
                    <Translate value="driver.otherSettings" />
                  </h3>
                </Form.Label>
                <CcCheckbox
                  checked={detailItem.rank == 1}
                  onChange={handleTopDriverChange}
                  disabled={!editable}
                  text={<Translate value="driver.topDriver" />}
                  className="mb-sm"
                />
                <div
                  className={styles['description']}
                  style={{ marginLeft: '27px' }}
                >
                  <Translate value="driver.topDriverDes" />{' '}
                </div>
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <CcCheckbox
                  checked={
                    detailItem.driverInfo.allowDriverSelectVehicleForBooking
                  }
                  onChange={handleSelectVehicleChange}
                  disabled={!editable}
                  text={<Translate value="driver.selectVehicle" />}
                  className="mb-sm"
                ></CcCheckbox>
                <div
                  className={styles['description']}
                  style={{ marginLeft: '27px' }}
                >
                  <Translate value="driver.selectVehicleNote" />{' '}
                </div>
              </FormGroup>
            </Col>
          </Row>

          {auth.selectedFleet &&
          auth.selectedFleet.allowDrvTopupDriverViaCreditWallet ? (
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <CcCheckbox
                    checked={detailItem.driverInfo.hasTopUpOtherDriver}
                    onChange={handleTopUpOtherDriverChange}
                    disabled={!editable}
                    text={
                      <Translate value="driver.walletTransferBetweenDriver" />
                    }
                    className="mb-sm"
                  ></CcCheckbox>
                  <div
                    className={styles['description']}
                    style={{ marginLeft: '27px' }}
                  >
                    <Translate value="driver.walletTransferBetweenDriverDes" />{' '}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col xs={12}>
              <FormGroup>
                <CcCheckbox
                  checked={detailItem.rideSharing == 1}
                  onChange={handleRideSharingChange}
                  disabled={!editable || !isRideSharingEnabled()}
                  text={<Translate value="driver.rideSharing" />}
                  className="mb-sm"
                ></CcCheckbox>
                <div
                  className={styles['description']}
                  style={{ marginLeft: '27px' }}
                >
                  <Translate value="driver.rideSharingDes" />{' '}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs={12}>
          <Button
            style={{ padding: '6px 15px' }}
            className="btn-cancel mt-md"
            onClick={closeDialogForm}
          >
            <Translate value="carSetting.discardChange" />
          </Button>
          {!props.permissions || props.permissions.actions ? (
            state.editable ? (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={saveDialogForm}
              >
                <Translate
                  value={
                    action === 'add' ? 'carSetting.Save' : 'carSetting.Update'
                  }
                />
              </Button>
            ) : (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={onEditable}
              >
                <Translate value="carSetting.Edit" />
              </Button>
            )
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default FormSetting;
