import React, { Component } from 'react';
import { FormGroup, Image, FormControl, Button, Form } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import _ from 'lodash';
import Autosuggest from 'react-autosuggest';
import {
  CCLiteCommonFunc,
  regexpDriverAutoComplete,
  statusOfferDriverOnBookDetail,
} from '../../../utils/commonFunctions';
import { userType, HYDRA_STATUS } from '../../../constants/commondata';
import FormGroupTitle from '../../formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../ccCheckbox/CcCheckbox';
import RadioButton from '../../radioButton/radio';
import timeIcon from '../../../assets/images/icons/time.svg';
import starsIcon from '../../../assets/images/icons/stars.png';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { topUpCorporate } from '../../../actions/corporateAction';
import TrackLink from '../../trackLink/TrackLink';
import VehicleAutoComplete from './VehicleAutocomplete';

const currencyFormatter = require('currency-formatter');

class DispatchInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
    };
    this.renderDriverSuggestion = this.renderDriverSuggestion.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    this.setState({
      isCorpBoard:
        user.userType == userType.CorporateAdmin ||
        user.userType == userType.CorporateUser,
    });
  }

  toggleLightbox = (selectedIndex) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  getDriverSuggestionValue(suggestion) {
    return suggestion.driver.name;
  }

  renderDriverSuggestion(suggestion, { query }) {
    let { status } = suggestion;
    const { handleDriverCalendarClick, data } = this.props;
    if (!suggestion.online) {
      status = 'offline';
    } else if (suggestion.status == 'inProgress') {
      status = suggestion.currentJob[0]
        ? suggestion.currentJob[0].status
        : suggestion.status;
    }
    let pickUpTime = data.time;
    const isBookingDetail = !!data.bookId;

    if (isBookingDetail) {
      pickUpTime = data.request.pickUpTime;
    }

    return (
      <div className={`suggestion-content driver-assign ${status}`}>
        <div className="status" />
        <div className="info">
          <span className="">
            {`${suggestion.firstName} ${suggestion.lastName}${
              suggestion.company ? ` / ${suggestion.company.companyName}` : ''
            }`}
          </span>
          <span>{`${suggestion.vehicle.plateNumber} / ${suggestion.phone}`}</span>
        </div>
        {pickUpTime != 'Now' ? (
          <div
            className="calendar"
            onClick={(e) => {
              handleDriverCalendarClick(suggestion);
            }}
          >
            <Image src={timeIcon} />
          </div>
        ) : null}
      </div>
    );
  }

  checkShowWarningLimitBalance = () => {
    let {
      data = {},
      driver,
      selectedFleet = {},
      bookingCurrency = {},
    } = this.props;
    const { enable = false, enableOnlineJob = false} = selectedFleet?.driverDepositConfig || {}
    if(!enable) return false;
    const isBookingDetail = !!data.bookId;
    let { driverDeposit = false, warningAmount = [] } = selectedFleet;
    let creditBalances = _.get(driver, 'driverInfo.creditBalances', []);
    if (creditBalances.length == 0) {
      creditBalances = _.get(driver, 'driverId.driverInfo.creditBalances', []);
    }
    let valueBalanceDriver = CCLiteCommonFunc.getValueOfMultiCurrencies(
      creditBalances,
      bookingCurrency.iso,
      'value'
    );
    let valueBalanceSetting = CCLiteCommonFunc.getValueOfMultiCurrencies(
      warningAmount,
      bookingCurrency.iso,
      'value'
    );

    if (!driverDeposit) return false;
    if (isBookingDetail) {
      let paymentType = (data.request && data.request.paymentType) || 0;
      let driverFullname = _.get(data, 'drvInfo.fullName', '');
      if (
        (enableOnlineJob || paymentType == 1) &&
        data.dispatchType == 1 &&
        driverFullname &&
        creditBalances.length > 0 &&
        valueBalanceSetting >= valueBalanceDriver
      ) {
        return true;
      }
    } else {
      if (
        (enableOnlineJob || data.paymentMethod == 1) &&
        data.dispatch_type == 1 &&
        !!data.driver &&
        creditBalances.length > 0 &&
        valueBalanceSetting >= valueBalanceDriver
      ) {
        return true;
      }
    }
    return false;
  };

  renderVehicleSelect = (pickUpTime) => {
    if (this.props.isShuttle) return null;
    const {
      data,
      driver,
      selectedFleet,
      cartypeSelected,
      supplierCompanies,
      settingActions,
      setVehicleAutoComplete,
      isDispatchAble,
      isHydraBooking,
      resetCounterVehicleTextSearch
    } = this.props;
    const driverInfo = data.bookId ? data.drvInfo : driver,
      isBookNow = pickUpTime === 'Now';
    let disabled =
      isBookNow ||
      cartypeSelected === null ||
      isHydraBooking ||
      this.state.isCorpBoard;
    if (data.bookId && !disabled) {
      disabled = !isDispatchAble(data, true);
    }
    return (
      <Form.Group>
        <FormGroupTitle>
          <Translate value="newbooking.Vehicle" />
        </FormGroupTitle>
        <VehicleAutoComplete
          disabled={disabled}
          selectedFleet={selectedFleet}
          supplierCompanies={supplierCompanies}
          settingActions={settingActions}
          setVehicleAutoComplete={setVehicleAutoComplete}
          vehicle={data?.vehicle}
          drivers={driverInfo?.phone ? [driverInfo] : []}
          resetCounterVehicleTextSearch={resetCounterVehicleTextSearch}
          vehicleTypes={
            cartypeSelected?.vehicleType
              ? [
                  cartypeSelected.vehicleType,
                  ...(cartypeSelected.dispatch || []),
                ]
              : []
          }
          isBookNow={pickUpTime === 'Now'}
          showUnassignBtn={
            data.reservation && !disabled && data?.vehicle?.plateNumber
          }
          handleUnassignVehicle={this.props.handleUnassignVehicle}
        />
      </Form.Group>
    );
  };

  render() {
    const {
      user,
      data,
      handleChangeDispatchType,
      newBooking,
      isSubmited,
      driversuggestions,
      onDriverSuggestionsFetchRequested,
      onDriverSuggestionsClearRequested,
      handleRideSharingChange,
      driverAutocompleteSlectedHandle,
      handleDriverChanged,
      cartypeSelected,
      handleDispatch3rdOnchange,
      valid,
      isEditable,
      isDispatchAble,
      selectedFleet = {},
      isInGroupModal = false, // Nếu true thì đang được mở trong assign driver cho group booking
      groupId,
      farmIn,
      farmOut,
      supportLocalBooking,
      supportHydraBooking,
      isHydraBooking,
      roaming,
      corporate,
    } = this.props;
    const { isCorpBoard } = this.state
    const isBookingDetail = !!data.bookId;

    let dispatchType = data.dispatch_type;
    let driverName = data.driver;
    let rideSharingDisabled = false;

    let isDispatchDisable = false;
    const hydraStatus = _.get(data, 'hydraInfo.status', 'none');
    const s3Host = selectedFleet.s3Host || '';
    const vehicleImageRequest = _.get(data, 'request.vehicleImageRequest', '');
    const drvInfo = _.get(data, 'drvInfo', {});
    let disableFarmOut = false;
    let autoSuggestDisabled =
      isDispatchDisable ||
      data.dispatch_type == 0 ||
      cartypeSelected === null ||
      isHydraBooking;

    let pickUpTime = '';
    if (isBookingDetail) {
      const isEditableValue = isEditable(data);
      pickUpTime = data.request.pickUpTime;
      rideSharingDisabled = !isEditableValue || isHydraBooking;
      dispatchType = data.dispatchType;
      driverName = data.drvInfo ? data.drvInfo.fullName || '' : '';
      if (
        farmIn &&
        isEditableValue
        // && !isDispatchAble(data)
      ) {
        /**
         * Booking farmIn -> able assign to driver
         */
        isDispatchDisable = false;
        autoSuggestDisabled = data.dispatchType == 0 || isDispatchDisable;
      } else {
        disableFarmOut =
          (!isEditableValue || hydraStatus === HYDRA_STATUS.accepted) &&
          data.pricingType == 1;
        isDispatchDisable = !isEditableValue && !isDispatchAble(data);
        autoSuggestDisabled =
          data.dispatchType == 0 ||
          cartypeSelected == null ||
          isDispatchDisable;
      }
    } else {
      pickUpTime = data.time;
    }

    const disabledSearchDriver =
      autoSuggestDisabled || isHydraBooking || isCorpBoard;

    if (isHydraBooking) {
      dispatchType = -1;
    }
    const primaryPartialMethod = _.get(
      data,
      'request.primaryPartialMethod',
      -1
    );
    if (primaryPartialMethod != -1 && data.pricingType == 1) {
      disableFarmOut = true;
    }
    if (roaming) {
      return null;
    }
    // if(farmOut) {
    //   return null
    // }
    return (
      <div>
        <FormGroup>
          {(!groupId || !(isInGroupModal && groupId)) && (
            <FormGroupTitle>
              <Translate value="newbooking.DISPATCH" />
            </FormGroupTitle>
          )}
          {!isInGroupModal && groupId && (
            <div>
              <div className="btn_reassign">
                <label>Driver</label>
                <Button
                  className="btn-header mr0 btn btn-primary"
                  onClick={this.props.showAssignDriverModal}
                >
                  {driverName ? 'Reassign' : 'Assign'}
                </Button>
              </div>
              <FormControl
                type="text"
                value={driverName}
                placeholder=""
                disabled={true}
                className="form-custom"
              />
            </div>
          )}
          {(!groupId || (isInGroupModal && groupId)) && (
            <FormGroup>
              {!isCorpBoard && (
                <FormGroup className="radio-button-group">
                  {!isInGroupModal &&
                    supportLocalBooking &&
                    (!this.props.isShuttle ||
                      (this.props.isShuttle && isBookingDetail)) && (
                      <RadioButton
                        text={
                          this.props.isShuttle
                            ? 'Manual dispatch'
                            : I18n.t('newbooking.Auto_dispatch')
                        }
                        value={0}
                        onChange={handleChangeDispatchType}
                        checked={dispatchType == 0 || dispatchType == 2}
                        name="Auto_dispatch"
                        disabled={isDispatchDisable || disableFarmOut}
                      />
                    )}
                  {supportLocalBooking &&
                    (!this.props.isShuttle ||
                      (this.props.isShuttle && isBookingDetail)) && (
                      <RadioButton
                        text={I18n.t('newbooking.Assign_driver')}
                        value={1}
                        name="Auto_dispatch"
                        onChange={handleChangeDispatchType}
                        checked={dispatchType == 1}
                        disabled={isDispatchDisable || disableFarmOut}
                      />
                    )}
                  {supportLocalBooking &&
                    !this.props.isShuttle &&
                    statusOfferDriverOnBookDetail(data.status) &&
                    !groupId &&
                    pickUpTime !== 'Now' && (
                      <RadioButton
                        text={I18n.t('newbooking.Offer_driver')}
                        value={3}
                        name="Auto_dispatch"
                        onChange={handleChangeDispatchType}
                        checked={dispatchType === 3}
                        disabled={isDispatchDisable || disableFarmOut}
                      />
                    )}
                  {supportHydraBooking &&
                    pickUpTime !== 'Now' &&
                    _.get(corporate, 'isCreateRoaming', true) &&
                    primaryPartialMethod === -1 && (
                      <RadioButton
                        text={I18n.t('newbooking.FarmOut')}
                        value={-1}
                        name="Auto_dispatch"
                        onChange={handleChangeDispatchType}
                        checked={isHydraBooking && !farmIn}
                        disabled={
                          isDispatchDisable ||
                          disableFarmOut ||
                          data.rideSharing
                        }
                      />
                    )}
                </FormGroup>
              )}
              {farmOut &&
              hydraStatus === HYDRA_STATUS.accepted &&
              drvInfo &&
              drvInfo.userId ? (
                <div className="drvCardFarmIn">
                  <div className="drvCardLeft">
                    <Image
                      className="avtDrv"
                      circle
                      src={`${s3Host}${drvInfo.avatar}`}
                    />
                    {drvInfo.vehicleAvatar && (
                      <Image
                        className="avtVhc"
                        src={`${s3Host}${drvInfo.vehicleAvatar}`}
                      />
                    )}
                  </div>
                  <div className="drvCardRifht">
                    <span className="plateNumber">{drvInfo.plateNumber}</span>
                    <span className="vhcModelMake">
                      {`${drvInfo.vehicleMakeName} ${drvInfo.vehicleModelName} `}
                      {drvInfo.vhcColor ? `(${drvInfo.vhcColor})` : ''}
                    </span>
                    <span className="vhcStar">
                      {drvInfo.fullNameSort}
                      <Image src={starsIcon} />
                      {drvInfo.rating.times === 0 ||
                      typeof drvInfo.rating.times == 'undefined'
                        ? '5.0'
                        : (drvInfo.rating.stars / drvInfo.rating.times).toFixed(
                            1
                          )}
                    </span>
                    <TrackLink
                      status={data && data.status}
                      pickupTime={data.request.pickUpTime}
                      delivery={data.delivery}
                      trackUrl={selectedFleet && selectedFleet.trackLink}
                      tokenBooking={data && data.token}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="add-on-input-group">
                    <FormGroup
                      className={`assign-driver-container ${
                        !isSubmited
                          ? null
                          : valid.dispatch_driver === false
                          ? 'error'
                          : null
                      }
                          `}
                    >
                      <Autosuggest
                        suggestions={driversuggestions}
                        onSuggestionsFetchRequested={
                          onDriverSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onDriverSuggestionsClearRequested
                        }
                        getSuggestionValue={this.getDriverSuggestionValue}
                        renderSuggestion={this.renderDriverSuggestion}
                        onSuggestionSelected={driverAutocompleteSlectedHandle}
                        shouldRenderSuggestions={() => true}
                        inputProps={{
                          className: 'form-control form-custom',
                          value: driverName,
                          placeholder:
                            isHydraBooking || isCorpBoard
                              ? I18n.t('newbooking.Search_driver_hydra')
                              : I18n.t('newbooking.Search_here'),
                          onChange: handleDriverChanged,
                          disabled: disabledSearchDriver,
                        }}
                      />
                    </FormGroup>
                    {data.reservation &&
                      (data.status === 'confirmed' ||
                        data.status === 'booked' ||
                        data.status === 'arrived' ||
                        data.status === 'action' ||
                        data.status === 'pre' ||
                        data.status === 'pending') &&
                      driverName && (
                        <Button
                          className="add-on-button btn-header text-add-header"
                          onClick={this.props.handleDetachDrv}
                        >
                          <Translate value="newbooking.Unassign" />
                        </Button>
                      )}
                  </div>
                  <div>{this.renderVehicleSelect(pickUpTime)}</div>
                  {this.checkShowWarningLimitBalance() && (
                    <Translate
                      value="newbooking.Driver_credit_limited_balance_warning"
                      className="text-warning"
                    />
                  )}
                </>
              )}
            </FormGroup>
          )}
          {!isInGroupModal && (
            <div className="cb-dispatch">
              {
                // if new booking
                !isBookingDetail &&
                selectedFleet.partyDispatching &&
                !this.props.isShuttle ? (
                  <CcCheckbox
                    disabled={
                      user.userType == userType.CorporateAdmin ||
                      user.userType == userType.CorporateUser
                    }
                    checked={data.dispatch3rd}
                    onChange={handleDispatch3rdOnchange}
                    text={I18n.t('newbooking.dispatch_3rd')}
                  />
                ) : null
              }

              {
                // if booking detail check if old book is not dispatch 3rd
                isBookingDetail &&
                selectedFleet.partyDispatching &&
                data.pricingType == 0 &&
                !this.props.isShuttle ? (
                  <CcCheckbox
                    checked={data.dispatch3rd}
                    onChange={handleDispatch3rdOnchange}
                    disabled={
                      isBookingDetail &&
                      (!isEditable(data) ||
                        data.psgInfo.phone == 'No Phone' ||
                        user.userType == userType.CorporateAdmin ||
                        user.userType == userType.CorporateUser)
                    }
                    text={I18n.t('newbooking.dispatch_3rd')}
                  />
                ) : null
              }
              {cartypeSelected &&
              cartypeSelected.rideSharing &&
              newBooking.locationService &&
              !newBooking.locationService.crossZone ? (
                <CcCheckbox
                  checked={data.rideSharing}
                  onChange={handleRideSharingChange}
                  disabled={rideSharingDisabled}
                  text={I18n.t('newbooking.Ride_Sharing')}
                />
              ) : null}
            </div>
          )}
        </FormGroup>
      </div>
    );
  }
}

export default DispatchInfo;
