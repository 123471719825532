import React, { Component } from 'react';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import {
  paymentMethodNumber,
  travelerType,
  paymentGetwayDisabledNewBooking
} from '../../../constants/commondata';
import FormGroupTitle from '../../formGroupTitile/FormGroupTitle';
import { Validator, ValidCase } from '../../validator';
import { Validation, CCLiteCommonFunc } from '../../../utils/commonFunctions';
import { isPaymentMethodEditable } from '../bookFunction/payment';
import QuickAddNewCard from './QuickAddNewCard';

class PaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderNewBookingPaymentMethod() {
    const {
      data,
      handlePaymentMethodChange,
      paymentMethod,
      newBooking,
      corporateTypes,
      airlineTypes,
      isSubmited,
      valid,
      handleCreditCardChange,
      handleAddCardSuccess,
      credit,
      customer,
      selectedFleet,
      auth,
      company,
      handleClientCaseMatterChange,
      handleChargeCodeChange,
      ValidatorCallback,
      handleAddNewCustomerSuccess
    } = this.props;

    const gatewayConfig = selectedFleet.creditConfig.multiGateway
      ? _.get(newBooking, 'locationService.paymentGateways', {})
      : _.get(selectedFleet, 'creditConfig.configGateway', {});

    const supportWeb = gatewayConfig.supportWeb || false;
    const isDisableAddCard = paymentGetwayDisabledNewBooking.includes(gatewayConfig.gateway);

    const isPersonalOrCorpCard = data.paymentMethod === paymentMethodNumber.personalCard
      || data.paymentMethod === paymentMethodNumber.corporateCard;

    let corpId = '';

    if (
      data.traveler_type == travelerType.Corporate
      && data.paymentMethod === paymentMethodNumber.corporateCard
    ) {
      corpId = auth.user.corporateId || (company ? company._id : 0);
    }

    const newCustomer = {
      phone: data.phone
        .replace(new RegExp(' ', 'g'), '')
        .replace(new RegExp('-', 'g'), ''),
      firstName: data.firstname || '',
      lastName: data.lastname || '',
      email: data.email || ''
    };

    return (
      <div>
        <FormGroupTitle>
          {/* <Translate value="newbooking.PAYMENT_METHOD" /> */}
          Crew Payment
        </FormGroupTitle>
        <FormGroup>
          <FormControl
            as="select"
            value={data.paymentMethod}
            className="form-custom"
            placeholder="select"
            onChange={handlePaymentMethodChange}
          >
            <option value={0}>{I18n.t(`newbooking.Choose_a_payment_method`)}</option>
            {newBooking.locationService && newBooking.locationService.crossZone ? (
              <option value={paymentMethodNumber.personalCard}>
                {I18n.t('bookingdetail.Personal_Card')}
              </option>
            ) : (
                airlineTypes.map(payment => {
                    if (newBooking.locationService && newBooking.locationService.crossZone) {
                      if (payment.value == paymentMethodNumber.corporateCard && [13, 14, 20].indexOf(payment.value) === -1) {
                        return (
                          <option key={payment.value} value={payment.value}>
                            {I18n.t(`General.patmentMethod_${payment.value}`) /* payment.name */}
                            {/* {payment.name} */}
                          </option>
                        );
                      }
                    } else if ([7, 4, 5].indexOf(payment.value) >= 0) {
                      return (
                        <option key={payment.value} value={payment.value}>
                          {I18n.t(`General.patmentAirlineMethod_${payment.value}`) /* payment.name */}
                          {/* {payment.name} */}
                        </option>
                      );
                    }
                  })
                )}
          </FormControl>
          {isPersonalOrCorpCard ? (
            <FormGroup
              className={`mr-t-10 ${!isSubmited ? null : valid.payment_card === false ? 'error' : null}`}
            >
              <FormControl
                as="select"
                value={credit ? credit.crossToken || credit.localToken : ''}
                placeholder="select"
                className="form-custom"
                onChange={handleCreditCardChange}
              >
                <option value={0}>
                  {I18n.t(
                    `newbooking.Choose_a_stored_card`
                  ) /* Nguyen change to plain text to fix warning "span" can not is child of option */}
                  {/* <Translate value='newbooking.Choose_a_stored_card' /> */}
                </option>
                {data.paymentMethod == paymentMethodNumber.personalCard
                  ? customer
                  && customer.credits
                  && customer.credits.map(cre => {
                    if (newBooking.locationService && newBooking.locationService.crossZone) {
                      if (!Validation.isStringEmpty(cre.crossToken)) {
                        return (
                          <option key={cre.crossToken} value={cre.crossToken}>
                            {cre.cardMask}
                          </option>
                        );
                      }
                    } else if (
                      !Validation.isStringEmpty(cre.localToken)
                      && ((selectedFleet.creditConfig.multiGateway
                        && newBooking.locationService
                        && !_.isEmpty(newBooking.locationService.paymentGateways)
                        && newBooking.locationService.paymentGateways.gateway === cre.gateway)
                        || (!selectedFleet.creditConfig.multiGateway
                          && selectedFleet.creditConfig.configGateway
                          && selectedFleet.creditConfig.configGateway.gateway === cre.gateway))
                    ) {
                      return (
                        <option key={cre.localToken} value={cre.localToken}>
                          {cre.cardMask}
                        </option>
                      );
                    }
                  })
                  : company
                  && company.credits
                  && company.credits
                    .filter(cr => (
                      newBooking.locationService
                      && newBooking.locationService.paymentGateways
                      && ((selectedFleet.creditConfig.multiGateway
                        && newBooking.locationService
                        && !_.isEmpty(newBooking.locationService.paymentGateways)
                        && newBooking.locationService.paymentGateways.gateway == cr.gateway)
                        || (!selectedFleet.creditConfig.multiGateway
                          && !_.isEmpty(selectedFleet.creditConfig.configGateway)
                          && selectedFleet.creditConfig.configGateway.gateway == cr.gateway))
                    ))
                    .map(cre => (
                      <option
                        key={cre.crossToken || cre.localToken}
                        value={cre.crossToken || cre.localToken}
                      >
                        {cre.cardHolder}
                        {' '}
*
                        {(cre.cardMask || '').replace(/[\X\x]/g, '')}
                      </option>
                    ))}
              </FormControl>
              <Validator
                id="payment_card"
                callback={ValidatorCallback}
                disabled={!isPersonalOrCorpCard}
              >
                <ValidCase valid={credit} hide message="" />
              </Validator>
            </FormGroup>
          ) : (
              ''
            )}

          {/* client case master */}
          {newBooking.locationService
            && !newBooking.locationService.crossZone
            && (parseInt(data.traveler_type) === 1
              || data.paymentMethod == 5
              || data.paymentMethod == paymentMethodNumber.corporateCard
              || data.paymentMethod == 7) ? (
              <div>
                <FormGroup>
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={handleClientCaseMatterChange}
                    value={data.clientCaseMatter}
                    placeholder={I18n.t('newbooking.Client_case_matter')}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={handleChargeCodeChange}
                    value={data.chargeCode}
                    placeholder={I18n.t('newbooking.Charge_code')}
                  />
                </FormGroup>
              </div>
            ) : (
              ''
            )}

          {/* add new card */}
          {!isDisableAddCard && supportWeb && isPersonalOrCorpCard ? (
            <QuickAddNewCard
              supportWeb={supportWeb}
              customer={data.psgInfo || customer}
              corpId={corpId}
              handleAddCardSuccess={handleAddCardSuccess}
              gateWay={gatewayConfig}
              newCustomer={newCustomer}
              handleAddNewCustomerSuccess={handleAddNewCustomerSuccess}
            />
          ) : null}
        </FormGroup>
      </div>
    );
  }

  renderBookingDetailPaymentMethod() {
    const {
      newBooking,
      data,
      handlePaymentMethodChange,
      paymentMethod,
      corporateTypes,
      airlineTypes,
      isShowmDispatcherCard,
      handleCreditCardChange,
      handleAddCardSuccess,
      credit,
      selectedFleet,
      auth,
      customer,
      company,
      isDispatchAble,
      handleClientCaseMatterChange,
      handleChargeCodeChange,
      handleAddNewCustomerSuccess
    } = this.props;

    const gatewayConfig = selectedFleet.creditConfig.multiGateway
      ? _.get(newBooking, 'locationService.paymentGateways', {})
      : _.get(selectedFleet, 'creditConfig.configGateway', {});

    const supportWeb = gatewayConfig.supportWeb || false;
    const isDisableAddCard = paymentGetwayDisabledNewBooking.includes(gatewayConfig.gateway) || !isPaymentMethodEditable(data);

    const canChangeCard = !data.pricingType
      && (data.request.paymentType == paymentMethodNumber.personalCard
        || data.request.paymentType == paymentMethodNumber.corporateCard
        || data.request.paymentType == paymentMethodNumber.personalCardChangedOnApp);

    const isBookFromMDispatcher = data.bookFrom === 'mDispatcher';

    let corpId = '';
    if (
      data.travelerType === travelerType.Corporate
      && data.request.paymentType === paymentMethodNumber.corporateCard
    ) {
      corpId = auth.user.corporateId || (company ? company._id : 0);
    }

    const newCustomer = {
      phone: (data.phone || (customer || {}).phone),
      firstName: data.firstname || (customer || {}).firstName,
      lastName: data.lastname || (customer || {}).lastName,
      email: data.email || (customer || {}).email
    };

    const isHydraBooking = data.bookFrom == 'dmc' || data.bookFrom == 'corp';

    return (
      <FormGroup>
        <FormGroupTitle>
          {/* <Translate value="newbooking.PAYMENT_METHOD" /> */}
          Crew payment
        </FormGroupTitle>
        {isHydraBooking ? (
          <Form.Label className="card-label">
            {/*hard code here because dmc has only 1 payment method. it is direct invoice*/}

            {data.request.paymentType == 12 ? (
              <FormControl
                value={I18n.t('General.patmentMethod_12')}
                disabled
                className="form-custom"
              />
            ) : (
                <FormControl
                  value={I18n.t('General.patmentMethod_5')}
                  disabled
                  className="form-custom"
                />
              )}
          </Form.Label>
        ) : data.pricingType
          || data.request.paymentType == paymentMethodNumber.personalCardChangedOnApp ? (
              <Form.Label className="card-label">
                <FormControl
                  value={I18n.t('bookingdetail.Personal_Card')}
                  className="form-custom"
                  disabled
                />
              </Form.Label>
            ) : isPaymentMethodEditable(data) ? (
              <FormControl
                as="select"
                className="form-custom"
                placeholder="select"
                onChange={handlePaymentMethodChange}
                value={data.request.paymentType}
                disabled={!isPaymentMethodEditable(data)}
              >
                <option value={0}>{I18n.t(`bookingdetail.Choose_a_payment_method`)}</option>
                {!data.travelerType
                  ? paymentMethod.individualTypes.map(payment => ![13, 14, 20].includes(payment.value) || ([13, 14, 20].includes(payment.value) && data.request.paymentType === payment.value) ? (
                    <option key={payment.value} value={payment.value}>
                      {I18n.t(`General.patmentMethod_${payment.value}`)}
                    </option>
                  ) : null)
                  // : corporateTypes.map(payment => ![1, 2, 9, 13, 14, 20].includes(payment.value) || ([13, 14, 20].includes(payment.value) && data.request.paymentType === payment.value) ? (
                  : airlineTypes.map(payment => [7, 4, 5].indexOf(payment.value) >= 0 ? (
                    <option key={payment.value} value={payment.value}>
                      {I18n.t(`General.patmentAirlineMethod_${payment.value}`)}
                    </option>
                  ) : null)}
                {(isShowmDispatcherCard || isBookFromMDispatcher) // show more method partner card if booking from mDipatcher
                  ? paymentMethod.type3rd.map(pm => {
                    return (
                      <option key={pm.value} value={pm.value}>
                        {I18n.t(`General.patmentMethod_${pm.value}`)}
                      </option>
                    );
                  }) : null}
              </FormControl>
            ) : (
                <FormControl
                  value={I18n.t(
                    `General.patmentMethod_${
                    data.request.paymentType == 0
                      ? paymentMethod.individualTypes && paymentMethod.individualTypes[0]
                        ? paymentMethod.individualTypes[0].value
                        : ''
                      : data.request.paymentType
                    }`
                  )}
                  disabled
                  className="form-custom"
                />
              )}

        {data.pricingType ? (
          <FormGroup className="">
            <FormControl
              value={
                data.psgInfo && data.psgInfo.creditInfo ? data.psgInfo.creditInfo.cardMask : ''
              }
              className="form-custom"
              disabled
            />
          </FormGroup>
        ) : null}

        {/* choose a stored card */}
        {canChangeCard && (
          <FormGroup>
            <FormControl
              className="mr-t-10 form-custom"
              as="select"
              placeholder="select"
              onChange={handleCreditCardChange}
              value={credit ? credit.localToken || credit.crossToken : ''}
              disabled={!isPaymentMethodEditable(data)}
            >
              <option value={0}>{I18n.t('bookingdetail.Choose_a_stored_card')}</option>
              {data.request.paymentType === paymentMethodNumber.personalCard ? (
                customer && customer.credits && customer.credits.length > 0 ? (
                  customer.credits.map(cre => {
                    if (CCLiteCommonFunc.isBookingStatusCompleted(data.status)) {
                      if (data.psgInfo && data.psgInfo.creditInfo && data.psgInfo.creditInfo.cardMask) {
                        return (
                          <option
                            key={data.psgInfo.creditInfo.localToken || data.psgInfo.creditInfo.crossToken}
                            value={data.psgInfo.creditInfo.localToken || data.psgInfo.creditInfo.crossToken}
                          >
                            {data.psgInfo.creditInfo.cardMask}
                          </option>
                        )
                      }
                    };
                    if (!Validation.isStringEmpty(cre.crossToken) && data.pricingType == 1) {
                      return (
                        <option key={cre.crossToken} value={cre.crossToken}>
                          {cre.cardMask}
                        </option>
                      );
                    };
                    if (
                      (!Validation.isStringEmpty(cre.localToken)
                        && data.pricingType != 1
                        && (selectedFleet.creditConfig.multiGateway
                          && ((newBooking.locationService
                            && !_.isEmpty(newBooking.locationService.paymentGateways)
                            && newBooking.locationService.paymentGateways.gateway == cre.gateway)
                            || (data.psgInfo
                              && data.psgInfo.creditInfo
                              && data.psgInfo.creditInfo.localToken == cre.localToken))))
                      || (!selectedFleet.creditConfig.multiGateway
                        && selectedFleet.creditConfig.configGateway
                        && selectedFleet.creditConfig.configGateway.gateway == cre.gateway)
                    ) {
                      return (
                        <option key={cre.localToken} value={cre.localToken}>
                          {cre.cardMask}
                        </option>
                      );
                    };
                  })
                ) : data.psgInfo
                  && data.psgInfo.creditInfo
                  && (data.psgInfo.creditInfo.localToken || data.psgInfo.creditInfo.crossToken) ? (
                  <option
                    key={data.psgInfo.creditInfo.localToken || data.psgInfo.creditInfo.crossToken}
                    value={data.psgInfo.creditInfo.localToken || data.psgInfo.creditInfo.crossToken}
                  >
                    {data.psgInfo.creditInfo.cardMask}
                  </option>
                ) : (
                  ''
                )
              ) : (
                  data.request.paymentType != paymentMethodNumber.personalCardChangedOnApp
                    && company
                    && company.credits ? (
                      CCLiteCommonFunc.isBookingStatusCompleted(data.status) ? (
                        data.request.paymentType != paymentMethodNumber.personalCardChangedOnApp
                          && data.psgInfo
                          && data.corporateInfo.creditInfo
                          && data.corporateInfo.creditInfo.cardMask ? (
                            <option
                              key={
                                data.corporateInfo.creditInfo.localToken
                                || data.corporateInfo.creditInfo.crossToken
                              }
                              value={
                                data.corporateInfo.creditInfo.localToken
                                || data.corporateInfo.creditInfo.crossToken
                              }
                            >
                              {data.corporateInfo.creditInfo.cardHolder}
                              {' '}
                              {(data.corporateInfo.creditInfo.cardMask || '').replace(/[\X\x]/g, '')}
                            </option>
                          ) : null
                      ) : (
                          company.credits
                            .filter(cr => (
                              CCLiteCommonFunc.isBookingStatusCompleted(data.status)
                              ||
                              (newBooking.locationService
                                && newBooking.locationService.paymentGateways
                                && ((selectedFleet.creditConfig.multiGateway
                                  && newBooking.locationService
                                  && !_.isEmpty(newBooking.locationService.paymentGateways)
                                  && newBooking.locationService.paymentGateways.gateway == cr.gateway)
                                  || (!selectedFleet.creditConfig.multiGateway
                                    && !_.isEmpty(selectedFleet.creditConfig.configGateway)
                                    && selectedFleet.creditConfig.configGateway.gateway == cr.gateway)))
                            ))
                            .map(cre => {
                              return (
                                <option
                                  key={cre.localToken || cre.crossToken}
                                  value={cre.localToken || cre.crossToken}
                                >
                                  {cre.cardHolder}
                                  {' *'}
                                  {(cre.cardMask || '').replace(/[\X\x]/g, '')}
                                </option>
                              )
                            })
                        )
                    ) : (
                      ''
                    )
                )}
            </FormControl>
          </FormGroup>
        )}

        {/* Client case master */}
        {data
          && data.pricingType != 1
          && (parseInt(data.travelerType) === 1
            || data.request.paymentType == 5
            || data.request.paymentType == paymentMethodNumber.corporateCard
            || data.request.paymentType == 7) ? (
            <div>
              <FormGroup className="mr-t-5">
                <FormGroup>
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={handleClientCaseMatterChange}
                    value={data.corporateInfo ? data.corporateInfo.clientCaseMatter : ''}
                    placeholder={I18n.t('bookingdetail.Client_case_matter')}
                    disabled={!isDispatchAble(data) || data.pricingType}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={handleChargeCodeChange}
                    value={data.corporateInfo ? data.corporateInfo.chargeCode : ''}
                    placeholder={I18n.t('bookingdetail.Charge_code')}
                    disabled={!isDispatchAble(data) || data.pricingType}
                  />
                </FormGroup>
              </FormGroup>
            </div>
          ) : (
            ''
          )}

        {/* add new card form */}
        {!isDisableAddCard && supportWeb && canChangeCard && data.pricingType !== 1 ? (
          <QuickAddNewCard
            supportWeb={supportWeb}
            customer={data.psgInfo || customer}
            corpId={corpId}
            handleAddCardSuccess={handleAddCardSuccess}
            gateWay={gatewayConfig}
            newCustomer={newCustomer}
            handleAddNewCustomerSuccess={handleAddNewCustomerSuccess}
          />
        ) : null}
      </FormGroup>
    );
  }

  render() {
    const bookingDetail = this.props.data.bookId;
    if (bookingDetail) return this.renderBookingDetailPaymentMethod();
    return this.renderNewBookingPaymentMethod();
  }
}

export default PaymentInfo;