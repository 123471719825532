import { I18n } from 'react-redux-i18n';
import React from 'react';
import _ from 'lodash';
import { getURLFareDeeplink } from '../../../utils/commonFunctions';

const FareDeeplink = ({ fareInfo = {}, puPoints = [], doPoints = [] }) => {
  if (
    !fareInfo?.rateType ||
    !fareInfo?.rateId ||
    puPoints?.length > 1 ||
    doPoints.length > 1
  )
    return null;
  return (
    <a
      href={getURLFareDeeplink(fareInfo)}
      target="_blank"
      style={{ color: 'rgb(83, 177, 253)', fontSize: '14px' }}
    >
      {I18n.t('newbooking.viewRate')}
    </a>
  );
};

export default FareDeeplink;
