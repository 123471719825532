/* global google */

import React from 'react';
import { withGoogleMap, GoogleMap, Polygon, Polyline } from 'react-google-maps';
import { QueueColor } from './colorData';

export default withGoogleMap(props => (
  <GoogleMap
    ref={map => {
      props.onMapLoad('_map', map);
    }}
    defaultZoom={10}
    zoom={props.defaultZoom}
    center={props.center || { lat: 16.059959, lng: 108.224258 }}
    onClick={props.handleMapClick}
    options={{ gestureHandling: 'greedy' }}
    onCenterChanged={props.onCenterChanged}
  >
    {props.parentZones &&
      props.parentZones.map(zone => (
        <Polygon
          key={zone._id}
          paths={zone.path}
          onClick={props.handleMapClick}
          editable={false}
          options={{
            strokeColor: QueueColor.operationZones.border,
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: '#ffffff00',
            fillOpacity: 0.4,
            zIndex: 1
          }}
        />
      ))}
    {props.pickUpAreas &&
      props.pickUpAreas.map(zone => (
        <Polygon
          key={zone.key}
          paths={zone.path}
          editable={false}
          onClick={props.handleMapClick}
          onMouseUp={() => {
            props.onPickUpPolygonClick(zone);
          }}
          options={{
            strokeColor: props.activePickUpId === zone.key ? QueueColor.yourChoosen.border : QueueColor.pickUp.border,
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: props.activePickUpId === zone.key ? QueueColor.yourChoosen.fill : QueueColor.pickUp.fill,
            fillOpacity: 0.4,
            zIndex: 2
          }}
        />
      ))}
    {props.otherAreasPolygon &&
      props.otherAreasPolygon.map(zone => (
        <Polygon
          key={zone.key}
          paths={zone.path}
          onClick={props.handleMapClick}
          options={{
            strokeColor: QueueColor.otherAreas.border,
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: QueueColor.otherAreas.fill,
            fillOpacity: 0.4,
            zIndex: 1
          }}
        />
      ))}
    {props.driverArea ? (
      <Polygon
        key={props.driverArea.key}
        paths={props.driverArea.path}
        onClick={props.handleMapClick}
        onMouseUp={() => {
          props.onDriverAreaPolygonClick(props.driverArea);
        }}
        options={{
          strokeColor: props.activeCheckIn ? QueueColor.yourChoosen.border : QueueColor.checkIn.border,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: props.activeCheckIn ? QueueColor.yourChoosen.fill : QueueColor.checkIn.fill,
          fillOpacity: 0.4,
          zIndex: 2
        }}
      />
    ) : null}
    {props.activeZone && !props.polyline ? (
      <Polygon
        key="active_polygon"
        ref={map => {
          props.onMapLoad('Polygon', map);
        }}
        paths={props.activeZone.path}
        onClick={props.handleMapClick}
        editable={props.editable}
        onMouseUp={props.onDragEnd}
        options={{
          strokeColor: QueueColor.yourChoosen.border,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: QueueColor.yourChoosen.fill,
          fillOpacity: 0.4,
          zIndex: 3
        }}
      />
    ) : null}
    {props.activeZone && props.polyline ? (
      <Polyline
        key="active_polyline"
        ref={map => {
          props.onMapLoad('Polyline', map);
        }}
        onClick={props.handlePolylineClick}
        events={{
          click: props.handlePolylineClick
        }}
        path={props.activeZone.path}
        editable={props.editable}
        onMouseUp={props.onDragEnd}
        options={{
          strokeColor: QueueColor.yourChoosen.border,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: QueueColor.yourChoosen.border,
          fillOpacity: 0.4,
          zIndex: 3
        }}
      />
    ) : null}
  </GoogleMap>
));
