import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { Image } from 'react-bootstrap';
import _ from 'lodash';
import luggage from '../../../assets/images/icons/luggage.svg';
import pax from '../../../assets/images/icons/pax.svg';
import currencyFormatter from 'currency-formatter';
import { AFFILIATE_BOOKING_CAR_TYPE, thirdPartyIntegration } from '../../../constants/commondata';
import { getNameAffiliateCarType, getSuppilerPermission } from '../../../utils/commonFunctions'
class VehicleSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowWarning: true,
      dateValueSelected: [],
      oldDays: [],
      isShowConfirmApply: false,
      infoChange: {},
      isBookDetail: false,
      mindays: '',
      bookingsDisable: [],
    };
  }

  sortAndCheckVehicleSelecting = () => {
    const {
      vehicleTypes = [],
      cartypeSelected = {},
      disabled,
      isStatusCompleted,
      isHydraBooking,
    } = this.props;
    if (!vehicleTypes || vehicleTypes.length === 0 || !cartypeSelected)
      return vehicleTypes || [];
    let vhcSelecting = {};
    const newVehicle = vehicleTypes.map((vhc) => {
      if (isHydraBooking) {
        if (
          vhc.vehicleType === cartypeSelected.vehicleType &&
          vhc.providerOfVhc &&
          vhc.providerOfVhc.vehicleType ===
            cartypeSelected.providerOfVhc.vehicleType &&
          vhc.providerOfVhc.fleetName ===
            cartypeSelected.providerOfVhc.fleetName
        ) {
          vhcSelecting = {
            ...vhc,
            isSelecting: true,
          };
          return vhcSelecting;
        }
      } else {
        if (vhc.vehicleType === cartypeSelected.vehicleType) {
          vhcSelecting = {
            ...vhc,
            isSelecting: true,
          };
          return vhcSelecting;
        }
      }
      return vhc;
    });
    // Khi bị disabled hay đã completed thì chỉ show 1 xe
    if (disabled || isStatusCompleted) {
      return _.isEmpty(vhcSelecting) ? [] : [vhcSelecting];
    }
    return [...newVehicle];
  };

  // handle when click on vehicle
  handleCarTypeChangedState = (vhc) => {
    const {
      disabled,
      isStatusCompleted,
      handleCarTypeChanged,
      isHydraBooking,
      handleCarTypeChangedHydra,
    } = this.props;
    if (disabled || isStatusCompleted) return;
    if (isHydraBooking) {
      handleCarTypeChangedHydra(vhc);
      return;
    }
    handleCarTypeChanged(vhc.vehicleType);
  };

  buildDescriptionCarType = (vhc, etaFare = {}) => {
    const { isHydraBooking, selectedFleet, is3rdBooking, data, nameThirdParty } = this.props;
    const s3Host = selectedFleet.s3Host || '';
    let description = {
      name: vhc.vehicleType,
      imageUrl: `${s3Host}${vhc.image}`,
      maxPassengers: vhc.maxPassengers,
      maxLuggages: vhc.maxLuggages,
      etaFare: _.get(etaFare, 'etaFare', null),
      currencyISO: etaFare.currencyISO,
    };
    if (is3rdBooking) {
      let nameCarType3rd = getNameAffiliateCarType(this.props.data)
      description.name = `${vhc.vehicleType} ${
        nameCarType3rd ? `(${nameCarType3rd})` : ``
      }`;
      description.etaFare = this.props?.data?.externalInfo?.price
    }
    if (isHydraBooking) {
      const supplierId = vhc.provider && vhc.provider.fleetName;
      description = {
        name: `${vhc.vehicleType} (${
          vhc.provider && vhc.provider.vehicleType
        })`,
        servicedBy: supplierId,
        imageUrl: `${s3Host}${vhc.iw}`,
        maxPassengers: vhc.mP,
        maxLuggages: vhc.mL,
        etaFare: _.get(etaFare, 'qupSellPrice', null),
        currencyISO: etaFare.currencyISO,
      };
    }
    return description;
  };

  filterEtaByBookType = (vhc) => {
    const { etaFareMultiCar = [], isHydraBooking } = this.props;
    if (!etaFareMultiCar || etaFareMultiCar.length === 0) return {};
    if (isHydraBooking) {
      const etaVhcHydra = etaFareMultiCar.find(
        (car) =>
          car &&
          car.vehicleType === vhc.vehicleType &&
          car.vehicleTypeLocal === vhc.providerOfVhc.vehicleType
      );
      return etaVhcHydra || {};
    }
    return (
      etaFareMultiCar.find(
        (car) => car && car.vehicleType === vhc.vehicleType
      ) || {}
    );
  };

  // SL-30518 ẩn giá của các xe chỉ assign regular khi rate là hourly
  showFareWithTypeRateHourly = (vhc = {}) => {
    const { data } = this.props;
    if (!data || !data.duration || !vhc) return true;
    if (!vhc.supportedRates?.hourly)
      return false;
    return true;
  };

  render() {
    const {
      nearestDriverList,
      time,
      disabled,
      isStatusCompleted,
      isBookDetail,
      isHydraBooking,
      data,
    } = this.props;
    const isDroppedOffStatus = _.get(data, 'status', '') === 'droppedOff';
    const vehicleAfterSort = this.sortAndCheckVehicleSelecting();
    if (!vehicleAfterSort || vehicleAfterSort.length === 0) return null;
    return (
      <>
        <div
          className={`vehicleSelection ${
            disabled || isStatusCompleted ? 'vehicleDisable' : ''
          }`}
        >
          {vehicleAfterSort && vehicleAfterSort.map((vhc) => {
            const nearestDriver =
              (nearestDriverList &&
                nearestDriverList.find(
                  (car) => car && car.vehicleType === vhc.vehicleType
                )) ||
              {};
            const etaFare = this.filterEtaByBookType(vhc);
            const infoVhc = this.buildDescriptionCarType(vhc, etaFare);
            if (
              ((!vhc.superHelper &&
                !vhc.dispatchRideSharing &&
                time !== 'Now') ||
                time === 'Now') &&
              (!vhc.availability || vhc.availability.cc)
            ) {
              return (
                <div
                  className={`vehicleItem ${
                    vhc.isSelecting ? 'isSelecting' : ''
                  }`}
                  key={`${infoVhc.name}-${
                    vhc.providerOfVhc && vhc.providerOfVhc.fleetName
                  }`}
                  onClick={(e) => this.handleCarTypeChangedState(vhc, etaFare)}
                >
                  {/* name */}
                  <p>{infoVhc.name}</p>
                  {
                    /* Closest driver */
                    time == 'Now' &&
                      !isStatusCompleted &&
                      !isBookDetail &&
                      (nearestDriver && nearestDriver.minText ? (
                        <p className="nearestAvaible">
                          {I18n.t('newbooking.Closest_driver_message').format(
                            nearestDriver.minText
                          )}
                        </p>
                      ) : (
                        <p className="nearestUnavailable">
                          {I18n.t('newbooking.No_cars_available')}
                        </p>
                      ))
                  }
                  {!isHydraBooking && (
                    <p className="vhcDesc">{vhc.shortDesc}</p>
                  )}
                  {
                    // serviced By ... for hydra
                    infoVhc.servicedBy && (
                      <p className="serviceBy">{`Serviced by ${infoVhc.servicedBy}`}</p>
                    )
                  }
                  {infoVhc.imageUrl && (
                    <Image className="vehicle_booking" src={infoVhc.imageUrl} />
                  )}
                  <div className="vhcBottom">
                    {
                      // Nếu là carType delivery thì ẩn pax & luggage
                      !vhc.delivery && (
                        <p>
                          <span>
                            <Image src={pax} />
                            {infoVhc.maxPassengers ? infoVhc.maxPassengers : 0}
                          </span>
                          <span>
                            <Image src={luggage} />
                            {infoVhc.maxLuggages ? infoVhc.maxLuggages : 0}
                          </span>
                        </p>
                      )
                    }
                    {!isStatusCompleted &&
                      !isDroppedOffStatus &&
                      !getSuppilerPermission(this.props.auth) &&
                      this.showFareWithTypeRateHourly(vhc) && (
                        <span>
                          {currencyFormatter.format(infoVhc.etaFare, {
                            code: infoVhc.currencyISO,
                          })}
                        </span>
                      )}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </>
    );
  }
}

VehicleSelection.defaultProps = {
  isShow: false, // state to show/hide calendar modal
  closeYearCalendarModal: () => {}, // handle close/hide calendar modal
  setRecurringDays: () => {}, // send yearCalendar valu to parent component
};

export default VehicleSelection;
