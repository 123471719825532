
import React, { Component } from 'react';
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer
} from "react-google-maps";
// import pickupIcon from './../../../constants/staticFiles/mk_pickup.png'
// import distinationIcon from './../../../constants/staticFiles/mk_destination.png'
import pickupIcon from './../../../assets/images/icons/pickup.svg'
import distinationIcon from './../../../assets/images/icons/destination.svg'
import des1_Icon from './../../../assets/images/icons/destination1.svg'
import des2_Icon from './../../../assets/images/icons/des2.svg'
import {
    locationType,
} from './../../../constants/commondata'
import {
    QQMap,
    QQMarker,
    QQPolyline,
} from '../../../components/qqMap'

const GettingStartedGoogleMap = withGoogleMap(props => {
    const pickUpPos = props.markerPickUp ? {
        lat: props.markerPickUp.post.lat,
        lng: props.markerPickUp.post.lng
    } : null
    let desPos = props.markerDestination ? {
        lat: props.markerDestination.post.lat,
        lng: props.markerDestination.post.lng
    } : null
    let des1 = null
    let des2 = null
    if (props.markerExtraDestination) {
        desPos = null;
        des1 = {
            lat: props.markerDestination.post.lat,
            lng: props.markerDestination.post.lng
        }
        des2 = {
            lat: props.markerExtraDestination.post.lat,
            lng: props.markerExtraDestination.post.lng
        }
    }
    return (
        <GoogleMap
            ref={props.onMapLoad}
            defaultZoom={14}
            center={props.center}
            options={{ gestureHandling: 'greedy' }}
        >
            {
                props.markerPickUp && <Marker
                    draggable={props.markerPickUp.draggable}
                    onDragEnd={props.markerPickUp.drapend}
                    position={pickUpPos}
                    icon={pickupIcon} key={1}
                />
            }
            {
                desPos && <Marker
                    draggable={props.markerDestination.draggable}
                    onDragEnd={props.markerDestination.drapend}
                    position={desPos}
                    icon={distinationIcon} key={2}
                />
            }
            {
                des1 && <Marker
                    draggable={props.markerDestination.draggable}
                    onDragEnd={props.markerDestination.drapend}
                    position={des1}
                    icon={des1_Icon} key={2}
                />
            }
            {
                des2 && <Marker
                    draggable={props.markerExtraDestination.draggable}
                    onDragEnd={props.markerExtraDestination.drapend}
                    position={des2}
                    icon={des2_Icon} key={3}
                />
            }
            {
                props.directions && props.directions.map((dr, index) => {
                    return <DirectionsRenderer directions={dr} key={index} options={{ suppressMarkers: true }} />
                })
            }
        </GoogleMap>
    )
});

class BookingMap extends Component {

    constructor(e) {
        super(e)
    }

    renderQQMap = () => {
        const {
            center, handleMapLoad, locationPickUp, handlePickupDrapEnd, pickupFrom,
            locationDestination, handleDestinationDrapEnd, destinationFrom,
            extraDestination, handleExtraDestinationDrapEnd, directions,
        } = this.props
        let qqCenter = null
        if (center) {
            qqCenter = new window.qq.maps.LatLng(center.lat, center.lng)
        }
        return <QQMap className='fill' onLoad={handleMapLoad} options={{ center: qqCenter }}>
            {
                locationPickUp && <QQMarker mapkey='pickup'
                    options={{ icon: pickupIcon, draggable: pickupFrom != locationType.thirdParty, position: locationPickUp }}
                    events={{ dragend: handlePickupDrapEnd }} />
            }
            {
                locationDestination && <QQMarker mapkey='dest'
                    options={{ icon: distinationIcon, draggable: destinationFrom != locationType.thirdParty, position: locationDestination }}
                    events={{ dragend: handleDestinationDrapEnd }} />
            }
            {
                extraDestination && <QQMarker mapkey='extdest'
                    options={{ icon: distinationIcon, draggable: pickupFrom != locationType.thirdParty, position: extraDestination }}
                    events={{ dragend: handleExtraDestinationDrapEnd }} />
            }
            {
                directions && <QQPolyline path={directions} mapkey={'1'} />
            }
        </QQMap>
    }

    renderGGMap = () => {
        const {
            center, handleMapLoad, locationPickUp, handlePickupDrapEnd, pickupFrom,
            locationDestination, handleDestinationDrapEnd, destinationFrom,
            extraDestination, handleExtraDestinationDrapEnd, directions,
        } = this.props
        return <GettingStartedGoogleMap
            containerElement={
                <div className='fill booking-map' />
            }
            mapElement={
                <div style={{ height: `100%` }} />
            }
            onMapLoad={handleMapLoad}
            center={center}
            markerPickUp={
                locationPickUp ?
                    {
                        post: locationPickUp,
                        drapend: handlePickupDrapEnd,
                        draggable: pickupFrom != locationType.thirdParty
                    } : null
            }
            markerDestination={
                locationDestination ?
                    {
                        post: locationDestination,
                        drapend: handleDestinationDrapEnd,
                        draggable: destinationFrom != locationType.thirdParty
                    } : null
            }
            markerExtraDestination={
                extraDestination && extraDestination.lat ? {
                    post: extraDestination, drapend: handleExtraDestinationDrapEnd,
                    draggable: extraDestination.from != locationType.thirdParty
                } : null
            }
            directions={directions}
        />
    }

    render() {
        const { isChina } = this.props
        if (isChina) return this.renderQQMap();
        return this.renderGGMap();
    }
}

export default BookingMap