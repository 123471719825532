import {
  NEW_INTERCITY_BOOKING_FROM_EXISTED,
  NEW_INTERCITY_BOOKING_FORM_REQUEST,
  CLOSE_INTERCITY_BOOKING_FORM,
  UPDATE_COMMON_DATA,
  ETA_INTERCITY_BOOKING_SUCCEEDED,
  ETA_INTERCITY_BOOKING_FAILED,
  FETCH_VEHICLE_TYPE_BY_COMPANY_REQUEST,
  FETCH_VEHICLE_TYPE_BY_COMPANY_SUCCESS,
  FETCH_VEHICLE_TYPE_BY_COMPANY_FAILURE
} from '../actions/intercityBookingActions.js';

import _ from 'lodash'

const initialState = {
  isShow: false,
  common: {
    schedules: [],
    routes: [],
    carTypes: [],
    additionalService: {},
    credits: [],
    companyVehicleTypes: [],
    flightService: {},
    paymentMethod: 1
  }
};

function getCarTypeValid (state, action) {
  const { common: {companyId, carType, carTypes } } = state;
  if (companyId) {
    const isMatchCurrentCarType =  action.data.indexOf(_.get(carType, 'vehicleType')) !== -1
    const validCarTypes = carTypes.filter((car) => action.data.indexOf(car.vehicleType) !== -1);
    return isMatchCurrentCarType ? carType : validCarTypes[0]
  }
  return carType || carTypes[0]
}

export default function newBooking(state = initialState, action = {}) {
  switch (action.type) {
    case CLOSE_INTERCITY_BOOKING_FORM: {
      return initialState;
    }
    case NEW_INTERCITY_BOOKING_FORM_REQUEST: {
      return {
        ...initialState,
        isShow: true
      };
    }
    case NEW_INTERCITY_BOOKING_FROM_EXISTED: {
      return { 
        ...state,
        prevState: action.data,
        isShow: true,
        common: {
          ...state.common,
          promo: null,
          tripType: null
        }
      };
    }
    case UPDATE_COMMON_DATA: {
      return { 
        ...state,
        common: {
          ...state.common,
          ...action.data
        }
      };
    }
    case ETA_INTERCITY_BOOKING_SUCCEEDED: {
      return { 
        ...state,
        common: {
          ...state.common,
          etaFare: action.data,
          isFareEditedBefore: false,
          originFare: {},
          isFareEdited: false,
          reasonEditFare: ""
        }
      };
    }
    case ETA_INTERCITY_BOOKING_FAILED: {
      return { 
        ...state,
        common: {
          ...state.common,
          etaFare: null
        }
      };
    }
    case FETCH_VEHICLE_TYPE_BY_COMPANY_REQUEST: {
      return { 
        ...state,
        common: {
          ...state.common,
          companyId: action.data.companyId
        }
      };
    }
    case FETCH_VEHICLE_TYPE_BY_COMPANY_SUCCESS: {
      return { 
        ...state,
        common: {
          ...state.common,
          carType: getCarTypeValid(state, action),
          companyVehicleTypes: action.data
        }
      };
    }
    case FETCH_VEHICLE_TYPE_BY_COMPANY_FAILURE: {
      return { 
        ...state,
        common: {
          ...state.common,
          companyVehicleTypes: []
        }
      };
    }
    default:
      return state;
  }
}
