import React, { useState } from 'react';
import Checkbox from 'react-custom-checkbox';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';

const BookId = ({
  booking,
  lineShort,
  isEnableGroupSetting,
  selectedTab,
  checkEnableSelectMultiBook,
  checkedGroup,
  handleClickGroup,
  isMobile,
}) => {
  return (
    <div
      id={isMobile ? 'bookIdMobile' : 'bookId'}
      className={`${
        booking.status == 'pre' &&
        booking.delivery === true &&
        !booking.reservation
          ? 'status_cell_wrapper group_cell status action'
          : 'status_cell_wrapper group_cell status ' + booking.status
      } ${lineShort ? 'lineShort' : ''}`}
      booking-id={booking.bookId}
    >
      {/* {isEnableGroupSetting && selectedTab === 0 && ( */}
      {selectedTab == 0 && (
        <div className="">
          <Checkbox
            checked={
              checkedGroup.some((bk) => bk.bookId === booking.bookId)
                ? true
                : false
            }
            icon={<i className="fa fa-check checked-icon" />}
            onChange={(value, event) => {
              handleClickGroup(event, booking);
            }}
            borderColor={checkEnableSelectMultiBook ? '#1B1D22' : '#313030'}
            className="checkboxCue"
            borderRadius="5px"
            style={{
              backgroundColor: checkEnableSelectMultiBook ? '#1B1D22' : '#363a44'
            }}
            containerClassName={`${lineShort ? 'short' : ''} labelCBCue`}
            containerStyle={{ 
              alignItems: 'start',
            }}
            size={lineShort ? 22 : 24}
            disabled={checkEnableSelectMultiBook ? false : true}
          />
        </div>
      )}
      <div className='bkId' style={{ paddingLeft: `${selectedTab != 0 ? '10px' : 0}`}}>
        <p>{booking.bookId}</p>
      </div>
    </div>
  );
};

export default BookId;
