import './styles.scss'
import moment from 'moment'
import tz from 'moment-timezone'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import QuestionCircleTooltip from '../../../components/questionCircleTooltip/QuestionCircleTooltip'
import {
  FormGroup,
  FormControl,
  Tooltip,
  OverlayTrigger,
  Button,
  Image,
  InputGroup,
  Form,
  ProgressBar,
  Modal,
} from 'react-bootstrap'
import qs from 'query-string'
import { Translate, I18n } from 'react-redux-i18n'
import CurrencyFormater from 'currency-formatter'
import ReactDOM from 'react-dom'
import * as commonData from '../../../constants/commondata'
import * as reportActions from '../../../actions/reportActions'
import * as commonDataActions from '../../../actions/commonDataAction'
import * as customerAutocompleteActions from '../../../actions/customerAutocompleteAction'
import * as driverAction from '../../../actions/driverAction'
import * as customerAction from '../../../actions/customerAction'
import * as loadingBarActions from '../../../actions/loadingBarActions'
import ReportUtils from './utils'
import ReportComponent from './components'
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox'
import ConfirmModal from './components/ConfirmModal'
import ConversionModal from './components/ConversionModal'
import * as settingActions from '../../../actions/settingActions'
import * as serviceFeeActions from '../../../actions/serviceFeeActions'
import * as merchantActions from '../../../actions/merchantActions'
import Datetime from '../../../components/dateTime/DateTime'
import TimeZones from '../../../components/timeZonePicker/timezones'
import {
  getTimeZoneLocal,
  convertDateRangeString,
  checkEnable3rdPartyIntegration,
  handleResultExport,
  checkEnableHolidayTaxis,
  checkEnableKarhoo,
  filterPaymentMethods,
} from '../../../utils/commonFunctions'

import StickyTable from '../../../components/table/stickyTable/StickyTable'
import TableActions from '../../../components/table/tableAction/TableActions'
import CCDropDown from '../../../components/dropdown/ccDropDown'
import Filter from './components/Filter'
import AddFavoriteFilterModal from './components/AddFavoriteFilterModal'
import SettingsFavoriteFilterModal from './components/SettingsFavoriteFilterModal'

import { Validator, ValidCase } from '../../../components/validator'
import $ from 'jquery'
import { Validation, CCLiteCommonFunc } from '../../../utils/commonFunctions'

import setting from '../../../assets/images/icons/setting.svg'
import GetEmailExpModal from '../../../components/getEmailExpModal'
import { BsSearch } from 'react-icons/bs'

const { MonthSelector, DateRange } = ReportComponent
const mapCompanyKeys = function (value, key) {
  return { _id: 'value', name: 'label' }[key] || key
}
const mapOperatorKeys = function (value, key) {
  return { userId: 'value', fullName: 'label' }[key] || key
}
const mapCampaignKeys = function (value, key) {
  return { _id: 'value', name: 'label' }[key] || key
}
const mapPromocodeKeys = function (value, key) {
  return { _id: 'value', promotionCode: 'label' }[key] || key
}
const mapVoucherCodeKeys = function (value, key) {
  return { _id: 'value', voucherCode: 'label' }[key] || key
}
const mapMDispatcherTypeKeys = function (value, key) {
  return { _id: 'value', name: 'label' }[key] || key
}
const mapMDispatcherKeys = function (value, key) {
  return { _id: 'value', fullName: 'label' }[key] || key
}
const mapPickupZoneKeys = function (value, key) {
  return { _id: 'value', zoneName: 'label' }[key] || key
}
const mapIntercityKeys = function (value, key) {
  return { _id: 'value', routeName: 'label' }[key] || key
}
const mapPayoutTimeKeys = function (value, key) {
  return { _id: 'value', payoutTimeStr: 'label' }[key] || key
}
const viewModes = ['weekly', 'monthly']

let actionSearch = ''

const EXPORT_TYPE = {
  csv: 'CSV',
  excel: 'EXCEL',
  quests: 'QUESTS'
}
class BaseReport extends Component {
  constructor(props) {
    super(props)
    let defaultDateRange =
      Array.isArray(props.dateRange) &&
      props.dateRange.find((item) => item.default)
    defaultDateRange = (defaultDateRange && defaultDateRange.value) || 'today'
    const viewMode =
      viewModes.indexOf(defaultDateRange) >= 0 ? defaultDateRange : null
    const disableDatetimeSelection =
      defaultDateRange.startsWith('past') && defaultDateRange.endsWith('Days')
        ? true
        : false
    var paymentMethod = Array.isArray(this.props.paymentMethod)
      ? this.props.paymentMethod
      : commonData.bookingDetailsPaymentMethods;
    if(!this.props.paymentMethod) {
      paymentMethod = filterPaymentMethods(paymentMethod, this.props.selectedFleet)
    }

    const bookingService = Array.isArray(props.bookingService)
      ? props.bookingService
      : commonData.bookingServices
    const payoutOption = Array.isArray(props.payoutOption)
      ? props.payoutOption
      : commonData.payoutOptions
    const { timezone } = props.selectedFleet
    const bookingStatus = Array.isArray(props.bookingStatus)
      ? props.bookingStatus
      : commonData.affiliationBookingStatus

    const transactionTypes = Array.isArray(props.transactionType)
      ? props.transactionType
      : commonData.transactionTypes

    const incidentCanceledTypeList = Array.isArray(props.incidentCanceledTypes)
      ? props.incidentCanceledTypes
      : commonData.incidentCanceledTypes
    const zoneItems = (props.mapZone || []).filter(
      (o) => o?.display && o?.isActive
    )
    const defaultPickupZone = ['all'].concat(_.map(zoneItems, '_id'))

    const defaultCarTypeMulti = ['all'].concat(
      _.map(props.carTypeCommonData, 'vehicleType')
    )
    // booking type
    this.bookingTypes = commonData.bookingTypes
    this.adjustPrices = commonData.adjustPrices
    if (!_.get(props.selectedFleet, 'intercity.enable', false)) {
      this.bookingTypes = commonData.bookingTypes.filter(
        (item) => item.value !== 'intercity'
      )
    }
    let finishedStatus = Array.isArray(this.props.finishedStatus)
      ? this.props.finishedStatus
      : this.filterFinishedStatus(commonData.finishedStatusList)

    if (!_.get(props.selectedFleet, 'delivery.enable', false)) {
      this.bookingTypes = commonData.bookingTypes.filter(
        (item) => item.value !== 'delivery'
      )
    }
    if (!_.get(props.selectedFleet, 'shuttle.enable', false)) {
      this.bookingTypes = commonData.bookingTypes.filter(
        (item) => item.value !== 'shuttle'
      )
    }

    const multiSelections = {}
    if (this.props.multiSelectionsFilters) {
      this.props.multiSelectionsFilters.forEach((filter) => {
        multiSelections[filter.key] = filter.options.map((i) => i.value)
      })
    }

    this.state = {
      errorCode: 0,
      error: {},
      valid: {},
      isSubmited: false,
      shownColumn: [],
      payoutDate: '',
      localTableColumns: [],
      tableHeight: 500,
      timezoneQuery: {
        page: 0,
        limit: 20,
        total: 0,
        list: [],
        search: '',
      },
      companyQuery: {
        list: [],
        page: 0,
        limit: 20,
        total: 0,
        selectedObj: null,
        hasMore: true,
      },
      campaignQuery: {
        list: [],
        page: 0,
        limit: 20,
        total: 0,
        selectedObj: null,
        hasMore: true,
      },
      payoutTimeQuery: {
        list: [],
        page: 0,
        limit: 20,
        total: 0,
        selectedObj: null,
        hasMore: true,
      },
      driverQuery: {
        list: [],
        page: 0,
        limit: 20,
        total: 0,
        selectedObj: null,
        hasMore: true,
      },
      customerQuery: {
        list: [],
        page: 0,
        limit: 20,
        total: 0,
        selectedObj: null,
        hasMore: true,
      },
      referralCodeQuery: {
        list: [],
        page: 0,
        limit: 20,
        total: 0,
        selectedObj: null,
        hasMore: true,
      },
      voucherCodeQuery: {
        list: [],
        page: 0,
        limit: 20,
        total: 0,
        selectedObj: null,
        hasMore: true,
      },
      promocodeQuery: {
        list: [],
        page: 0,
        limit: 20,
        total: 0,
        selectedObj: null,
        hasMore: true,
      },
      intercityRouteQuery: {
        list: [],
        page: 0,
        limit: 20,
        total: 0,
        selectedObj: null,
        hasMore: true,
      },
      vehicleQuery: {
        list: [],
        limit: 20,
        page: 0,
        total: 0,
        search: '',
        selectedObj: null,
      },
      eventQuery: {
        list: [],
        page: 0,
        per_page: 20,
        total: 0,
        q: '',
        selectedObj: null,
        hasMore: true,
      },
      storeQuery: {
        list: [],
        page: 0,
        per_page: 20,
        total: 0,
        q: '',
        selectedObj: null,
        hasMore: true,
      },
      eventList: [],
      showPercent: false,
      exportPercent: 0,
      percentInterval: -1,
      changeCompany: false,
      timezone,
      resp: {},
      isSearched: false,
      loading: false,
      confirm: false,
      list: [],
      summary: [],
      total: 0,
      page: 0,
      sortName: 'phone',
      sortType: 1,
      rowCheckBoxes: [],
      numberOfQualified: 0,
      showInputModal: false,
      showConversionModal: false,
      conversionData: {},
      numberRecord: '',
      event: {},
      footerData: {
        limit: 20,
        page: 0,
        total: 0,
      },
      bookFromList: commonData.bookFrom,
      thirdPartyActionList: commonData.thirdPartyAction,
      queryBody: {
        fleetId: props.selectedFleet.fleetId,
        from: 0,
        size: 20,
        criteria: {
          selectedButton: defaultDateRange,
          timeFilterType: 'collectedTime',
          viewMode,
          disableDatetimeSelection,
          fromDate: moment().startOf('day').toISOString(),
          toDate: moment().toISOString(),
          timezone: props.selectedFleet.timezone,
          unitDistance: props.selectedFleet.unitDistance,
          month: moment().format('YYYY-MM'),
          year: moment().year(),
          finishedBookingStatus: finishedStatus.map((item) => item.value),
          paymentMethod: paymentMethod.map((item) => item.value),
          bookingStatus: bookingStatus.map((item) => item.value),
          paymentStatus: (Array.isArray(
            _.get(this.props, 'paymentStatus.options')
          )
            ? this.props.paymentStatus.options
            : commonData.bookingDetailsPaymentStatus
          ).map((i) => i.value),
          paymentStatusRefund: commonData.paymentStatusRefund.map(
            (item) => item.value
          ),
          bookFrom: commonData.bookFrom.map((item) => item.value),
          thirdPartyAction: commonData.thirdPartyAction.map((item) => item.value),
          currency:
            (props.fleetCurrencies &&
              props.fleetCurrencies[0] &&
              props.fleetCurrencies[0].iso) ||
            'USD',
          bookingType: this.bookingTypes.map((item) => item.value),
          adjustPrice: commonData.adjustPrices.map((item) => item.value),
          driverId: 'all',
          vehicleId: 'all',
          companyId: 'all',
          corporateId: 'all',
          userId: 'all',
          farmType: 'all',
          campaignId: 'all',
          promoCodeId: 'all',
          redeemCodeId: 'all',
          payoutPaymentStatus: 'all',
          selectedIntercityRoute: 'all',
          routes: [],
          shortTrip: 'all',
          ratingType: commonData.ratingTypes.map((item) => item.value),
          stars: commonData.stars.map((item) => item.value),
          vehicleRate: commonData.stars.map((item) => item.value).slice(),
          bookingService: bookingService[0].value,
          payoutOption:
            typeof props.selectedFleet.drvPayout !== 'undefined' &&
            props.selectedFleet.drvPayout.gateway === 'CIB'
              ? payoutOption[0].value
              : payoutOption[1].value,
          mDispatcherTypeId: 'all',
          mDispatcherId: 'all',
          canceledBy: 'all',
          incidentCanceledTypes: incidentCanceledTypeList.map(
            (item) => item.value
          ),
          action: 'all',
          status: 'all',
          referralStatus: 'all',
          module: 'all',
          driverType: 'all',
          withdrawStatus: 'all',
          userType: 'passenger',
          eventType: 'all',
          eventState: 'all',
          eventId: '',
          promoStatus: ['public', 'private'],
          serviceType: commonData.serviceTypes.map((item) => item.value),
          // transactionType: props.withdrawalTransactionType
          //   ? commonData.WithdrawalTransactionTypeItems.map(item => item.value)
          //   : commonData.transactionTypes.map(item => item.value),
          transactionType: transactionTypes.map((item) => item.value),
          rideService: commonData.rideServices.map((item) => item.value),
          vehicleType: 'all',
          currentStatus: commonData.bookingDetailsPaymentStatus.map(
            (item) => item.value
          ),
          originalStatus: commonData.originalPaymentStatus.map(
            (item) => item.value
          ),
          pickUpZone: defaultPickupZone,
          carTypeMulti: defaultCarTypeMulti,
          expiredDate: moment().format('MM/DD/YYYY'),
          txtSearch: '',
          tip: [],
          refererId: 'all',
          refereeId: 'all',
          driverReferrerId: 'all',
          referralType: props.referralType,
          referralCode: 'all',
          minPayout: 0,
          holdAmount: 0,
          payoutId: '',
          payoutDate: '',
          payoutType: 'all',
          merchantType: 'all',
          jobType: 'all',
          merchantId: 'all',
          documentExpiryStatus: 'all',
          settlement: 'all',
          networkType: 'all',
          settlementType: 'all',
          ...multiSelections,
        },
      },
      lastQuery: {},
      filterTemplates: [],
      filterTemplateId: '',
      nameFilter: '',
    }
    this.handleResultExport = handleResultExport.bind(this)
    this.searchLicensePlateDebounce = _.debounce(
      this.handleSearchLicensePlate,
      300
    )
    this.searchDriverDebounce = _.debounce(this.handleSearchDriver, 300)
    this.searchCustomerDebounce = _.debounce(this.handleSearchCustomer, 300)
    this.searchReferralCodeDebounce = _.debounce(
      this.handleSearchReferralCode,
      300
    )
    this.searchPromocodeDebounce = _.debounce(this.handleSearchPromocode, 300)
    this.searchTimezoneDebounce = _.debounce(this.handleSearchTimeZone, 200)
    this.searchCompanyDebounce = _.debounce(this.handleSearchCompany, 300)
    this.searchEventDebounce = _.debounce(this.handleSearchEvent, 300)
    this.searchVoucherCodeDebounce = _.debounce(
      this.handleSearchVoucherCode,
      300
    )
    this.searchCampaignDebounce = _.debounce(this.handleSearchCampaign, 300)
    this.ValidatorCallback = this.ValidatorCallback.bind(this)
    this.searchStoreDebounce = _.debounce(this.handleSearchStore, 300)
    this.fetchReportData = _.debounce(this.fetchReportData, 500)
    this.validDateRange = this.validDateRange.bind(this)
  }

  validDateRange(inputType) {
    return (current) => {
      if (this.props.user.isAdmin) {
        return true
      }
      const timezone = this.state.queryBody.criteria.timezone
      const begin2022 = moment.tz('2022-01-01', 'YYYY-MM-DD', timezone || 'UTC')
      if (current.isBefore(begin2022)) {
        return false
      }
      return true
    }
  }

  async componentDidMount() {
    const self = this
    if(
      this.props.selectedFleet?.fleetFareId?.applyType === commonData?.APPLY_SERVICE_TYPE?.CUSTOM_ZONE
      ) {
      if(!this.props?.serviceFee?.list?.length) {
        await this.props.serviceFeeActions.getServiceFeesSetting({
          fleetId: this.props.selectedFleet.fleetId
        })
      }
    }

    this.props.report.intervals.forEach((interval) => {
      clearInterval(interval)
      this.props.reportActions.removeInterval(interval)
    })

    // This is hack code, Will do in realtime to get setting

    this.props.settingActions.fleetInfo({
      fleetId: this.props.selectedFleet.fleetId,
    })

    this.props.plate && this.handleSearchLicensePlate('', null, true)
    this.props.usedPromocodes && this.handleSearchPromocode('', null, true)

    const params = _.get(this.context, 'router.location.query')
    const { driverName = '' } = params || {}
    this.props.driver &&
      this.handleSearchDriver(driverName, self.fetchQueryData, true)
    this.props.driverReferrerId &&
      this.handleSearchDriver(driverName, self.fetchQueryData, true)
    this.props.refereeId &&
      this.handleSearchDriver(driverName, self.fetchQueryData, true)
    this.props.event && this.handleSearchEvent('', null, true)

    const { customerName = '' } = params || {}
    this.props.customer &&
      this.handleSearchCustomer(customerName, self.fetchQueryCustomerData, true)

    this.props.refererId &&
      this.handleSearchCustomer(customerName, self.fetchQueryCustomerData, true)

    const { referralCode = '' } = params || {}
    this.props.referralCode &&
      this.handleSearchReferralCode(
        referralCode,
        self.fetchQueryReferralCodeData,
        true
      )
    const { voucherCode = '' } = params || {}
    this.props.voucherCodes &&
      this.handleSearchVoucherCode(
        voucherCode,
        self.fetchQueryVoucherCodeData,
        true
      )
    const { merchantId = '' } = params || {}
    this.props.storeMerchant &&
      this.handleSearchStore(merchantId, self.fetchQueryMerchantData, true)

    if (params && !_.isEmpty(params)) {
      let newState = Object.assign({}, this.state)
      newState.queryBody.fleetId = params.fleetId
      const criteria = Object.assign({}, this.state.queryBody.criteria, params)
      newState.queryBody.criteria = _.omit(criteria, ['fleetId'])
      newState.queryBody.criteria.paymentMethod = [].concat(
        newState.queryBody.criteria.paymentMethod
      )
      newState.queryBody.criteria.finishedBookingStatus = [].concat(
        newState.queryBody.criteria.finishedBookingStatus
      )
      newState.queryBody.criteria.bookFrom = [].concat(
        newState.queryBody.criteria.bookFrom
      )
      newState.queryBody.criteria.thirdPartyAction = [].concat(
        newState.queryBody.criteria.thirdPartyAction
      )
      newState.queryBody.criteria.bookingType = [].concat(
        newState.queryBody.criteria.bookingType
      )
      newState.queryBody.criteria.adjustPrice = [].concat(
        newState.queryBody.criteria.adjustPrice
      )
      this.setState(newState, () => {
        self.fetchReportData()
        // self.fetchQueryData();
      })
    }
    if (this.props.campaign) {
      const { campaignName = '' } = params || {}
      this.props.campaign &&
        this.handleSearchCampaign(
          campaignName,
          self.fetchQueryCampaignData,
          true
        )
    }
    if (this.props.company) {
      // this.props.commonDataActions.fetchCompanies(this.props.selectedFleet.fleetId);
      const { companyName = '' } = params || {}
      this.props.company &&
        this.handleSearchCompany(companyName, self.fetchQueryCompanyData, true)
    }
    if (this.props.carType || this.props.carTypeMultiple) {
      this.props.commonDataActions.loadCarType(this.props.selectedFleet.fleetId)
    }
    let newBookFrom = this.state.bookFromList,
      newThirdPartyAction = commonData.thirdPartyAction
    if(!checkEnable3rdPartyIntegration(this.props.selectedFleet)) {
      newBookFrom = commonData.bookFrom.filter(bk => bk.value !== 'booking.com')
    }
    if(!checkEnableHolidayTaxis(this.props.selectedFleet)) {
      newBookFrom = commonData.bookFrom.filter(bk => bk.value !== commonData.thirdPartyIntegration.holidaytaxis)
    }
    if(!checkEnableKarhoo(this.props.selectedFleet)) {
      newBookFrom = commonData.bookFrom.filter(bk => bk.value !== commonData.thirdPartyIntegration.karhoo)
    }
    if(this.props.title === commonData.thirdPartyIntegration.holidaytaxis) {
      newThirdPartyAction = newThirdPartyAction.filter(a => a.value !== 'Searched')
    }
    this.setState({
      thirdPartyActionList: newThirdPartyAction,
      bookFromList: newBookFrom,
      queryBody: {
        ...this.state.queryBody,
        criteria: {
          ...this.state.queryBody.criteria,
          thirdPartyAction: newThirdPartyAction.map((item) => item.value),
          bookFrom: newBookFrom.map((item) => item.value)
        }
      }
    })
    this.handleSearchTimeZone('', () => {}, true)

    this.props.enablePayoutTime &&
      this.handleSearchPayoutTime(self.fetchQueryPayoutTime, true)

    this.props.favoriteFilter && this.getFilterTemplate()
    
    let isShowExportReport = true
    const userTypeCurrent = _.get(this.props.user, 'userType', '')
    const actionCC = _.get(this.props.user, 'roles.moduleAdmin.actionCC', '')
    if(userTypeCurrent === commonData.userType.FleetAdmin && !actionCC) {
      isShowExportReport = false
    }
    const urlParams = new URLSearchParams(window.location.search);
    if(isShowExportReport && urlParams && urlParams.get('isRedirect') === 'true') {
      this.handleClickView(null)
    }
    this.setState({
      isShowExportReport
    })
  }

  getShownColumns = (respState = {}) => {
    const tableColumns = []
    _.forEach(this.props.fieldMappings.fields, (item) => {
      if (_.isFunction(item.isNotShow)) {
        const param = item.checkShowFromProps
          ? _.get(this.props, item.checkShowFromProps, false)
          : respState

        if (item.isNotShow(param, { lastQuery: this.state.lastQuery })) {
          return
        }
      }
      if (_.isFunction(item.isShowEvent)) {
        const param = respState
        if (!item.isShowEvent(param)) return
      }
      if (
        _.isString(item.isShow) &&
        !ReportUtils.isShowFareFields({
          fleet: this.props.selectedFleet,
          fleetFareIdByZone: this.props?.serviceFee?.fleetFareIdByZone || {},
          fieldName: item.isShow,
        })
      )
        return

      if (_.isString(item.isShowShortTrip)) {
        const waiveOffCommission =
          _.get(this.props.selectedFleet, 'waiveOffCommission.enable') || false
        if (!waiveOffCommission) return
      }

      if (
        _.isFunction(item.isShowCorp) &&
        !item.isShowCorp({
          fleet: this.props.selectedFleet,
          settings: this.props.settings,
        })
      )
        return

      if (
        _.isObject(item.isShowByFilter) &&
        !ReportUtils.isShowByFilterValue({
          criteria: this.state.queryBody.criteria,
          showByFilters: item.isShowByFilter,
        })
      )
        return

      if (
        _.isFunction(item.isShowDriverReferralField) &&
        !item.isShowDriverReferralField({
          fleet: this.props.selectedFleet,
          settings: this.props.settings,
          fieldName: item.key,
        })
      )
        return

      if (item.sub) {
        const columnGroup = {
          key: item.key,
          label: item.label,
          subColumns: [],
        }

        let itemSub = _.isFunction(item.sub)
          ? item.sub(
              respState.list || [],
              this.props.selectedFleet.currencies || []
            )
          : item.sub

        const newSub = []

        _.forEach(itemSub, (subItem) => {
          if (_.isFunction(subItem.isNotShow)) {
            const param = subItem.checkShowFromProps
              ? _.get(this.props, subItem.checkShowFromProps, false)
              : respState

            if (subItem.isNotShow(param)) return
          }
          if (_.isFunction(subItem.isShowEvent)) {
            const param = respState
            if (!subItem.isShowEvent(param)) return
          }
          if (
            _.isString(subItem.isShow) &&
            !ReportUtils.isShowFareFields({
              fleet: this.props.selectedFleet,
              fieldName: subItem.isShow,
            })
          )
            return
          newSub.push(Object.assign({}, subItem))
        })

        if (newSub.length)
          tableColumns.push(Object.assign({}, item, { sub: newSub }))
      } else {
        tableColumns.push(Object.assign({}, item))
      }
    })
    return tableColumns
  }

  handleChangeTimezone = (value) => {
    const newState = Object.assign({}, this.state)
    newState.queryBody.criteria.timezone = value
    this.setState(newState)
  }
  handleChangeAffiliationPayoutType = (value) => {
    const newState = Object.assign({}, this.state)
    newState.queryBody.criteria.payoutType = value
    this.setState(newState)
  }
  handleChangeInput = (e) => {
    this.setState({ numberRecord: e.target.value })
  }
  payToDrivers = (state) => {
    this.props.actions.payToDrivers(this, _.cloneDeep(this.state))
  }
  payToDriverAll = (state) => {
    this.props.actions.payToDriverAll(this, _.cloneDeep(this.state))
  }
  invalidBankAccount = (state) => {
    this.props.actions.invalidBankAccount(this, _.cloneDeep(this.state))
  }
  validBankAccount = (state) => {
    this.props.actions.validBankAccount(this, _.cloneDeep(this.state))
  }
  payToMerchants = (state) => {
    this.props.actions.payToMerchants(this, _.cloneDeep(this.state))
  }
  payToAllMerchant = (state) => {
    this.props.actions.payToAllMerchant(this, _.cloneDeep(this.state))
  }
  handleSearchTimeZone = (searchValue, callback, reload = false) => {
    const { search, limit } = this.state.timezoneQuery
    if (searchValue !== search || reload) {
      const query = getTimeZoneLocal(searchValue, 0, limit)
      const timezoneQuery = Object.assign({}, query, {
        search: searchValue,
        list: query.list,
      })
      this.setState({ timezoneQuery }, () => callback())
    } else {
      return callback()
    }
  }

  handleLoadMoreTimeZone = () => {
    const { page, search, limit, total, list } = this.state.timezoneQuery
    if (list.length >= total) return
    const query = getTimeZoneLocal(search, page + 1, limit)
    const timezoneQuery = Object.assign({}, this.state.timezoneQuery, {
      list: this.state.timezoneQuery.list.concat(query.list),
      page: query.page,
    })
    this.setState({ timezoneQuery })
  }

  handleChangeMonth = (month) => {
    const newState = Object.assign({}, this.state)
    newState.queryBody.criteria.month = month.format('YYYY-MM')
    this.setState(newState)
  }

  handleChangeTextSearch = (e) => {
    const newState = Object.assign({}, this.state)
    newState.queryBody.criteria.txtSearch = e.target.value
    this.setState(newState)
  }

  handlePressKeyTextSearch = (e) => {
    if (e.key === 'Enter') {
      actionSearch = 'Enter'
      this.handleClickView(e)
    }
  }
  handleChangePayout = (e) => {
    const newState = Object.assign({}, this.state)
    if (newState.queryBody.criteria.minPayout !== e.target.value) {
      //this.props.loadingBarActions.showLoadingSpiner();
      newState.queryBody.criteria.minPayout = e.target.value
      this.setState(newState, () => {
        this.props.driver && this.handleSearchDriver('', null, true)
      })
    }
  }
  handleChangeHoldAmount = (e) => {
    const newState = Object.assign({}, this.state)
    newState.queryBody.criteria.holdAmount = e.target.value
    this.setState(newState)
  }

  fetchQueryCompanyData = () => {
    const { queryBody, companyQuery } = this.state
    if (
      queryBody.criteria.companyId &&
      queryBody.criteria.companyId !== 'all'
    ) {
      this.props.settingActions
        .geDetailCompany({
          companyId: queryBody.criteria.companyId,
          fleetId: this.props.selectedFleet.fleetId,
        })
        .then(({ res }) => {
          this.setState({
            companyQuery: { ...companyQuery, selectedObj: res },
          })
        })
    }
  }
  fetchQueryCampaignData = () => {
    const { queryBody, campaignQuery } = this.state
    if (
      queryBody.criteria.campaignId &&
      queryBody.criteria.campaignId !== 'all'
    ) {
      this.props.settingActions
        .geDetailCampaign({
          campaignId: queryBody.criteria.campaignId,
          fleetId: this.props.selectedFleet.fleetId,
        })
        .then(({ res }) => {
          this.setState({
            campaignQuery: { ...campaignQuery, selectedObj: res },
          })
        })
    }
  }
  fetchQueryPayoutTime = () => {
    const { queryBody, payoutTimeQuery } = this.state
  }

  fetchQueryData = () => {
    const { queryBody, driverQuery } = this.state
    if (queryBody.criteria.driverId && queryBody.criteria.driverId !== 'all') {
      this.props.driverAction
        .findOneDriver({ userId: queryBody.criteria.driverId })
        .then(({ res }) => {
          this.setState({
            driverQuery: { ...driverQuery, selectedObj: res },
          })
        })
    }
    if (
      queryBody.criteria.refereeId &&
      queryBody.criteria.refereeId !== 'all'
    ) {
      this.props.driverAction
        .findOneDriver({
          userId: queryBody.criteria.refereeId,
          fleetId: this.props.selectedFleet.fleetId,
        })
        .then(({ res }) => {
          this.setState({
            driverQuery: { ...driverQuery, selectedObj: res },
          })
        })
    }
    if (
      queryBody.criteria.driverReferrerId &&
      queryBody.criteria.driverReferrerId !== 'all'
    ) {
      this.props.driverAction
        .findOneDriver({
          userId: queryBody.criteria.driverReferrerId,
          fleetId: this.props.selectedFleet.fleetId,
        })
        .then(({ res }) => {
          this.setState({
            driverQuery: { ...driverQuery, selectedObj: res },
          })
        })
    }
  }

  fetchQueryCustomerData = () => {
    const { queryBody, customerQuery } = this.state
    if (queryBody.criteria.userId && queryBody.criteria.userId !== 'all') {
      this.props.customerAction
        .findOneCustomer({
          userId: queryBody.criteria.userId,
          fleetId: this.props.selectedFleet.fleetId,
        })
        .then(({ res }) => {
          this.setState({
            customerQuery: { ...customerQuery, selectedObj: res },
          })
        })
    }

    if (
      queryBody.criteria.refererId &&
      queryBody.criteria.refererId !== 'all'
    ) {
      this.props.customerAction
        .findOneCustomer({
          userId: queryBody.criteria.refererId,
          fleetId: this.props.selectedFleet.fleetId,
        })
        .then(({ res }) => {
          this.setState({
            customerQuery: { ...customerQuery, selectedObj: res },
          })
        })
    }
  }

  fetchQueryReferralCodeData = () => {
    const { queryBody, referralCodeQuery } = this.state
    if (
      queryBody.criteria.referralCode &&
      queryBody.criteria.referralCode !== 'all'
    ) {
      this.props.settingActions
        .findOneReferralCode({
          code: queryBody.criteria.referralCode,
          fleetId: this.props.selectedFleet.fleetId,
          type: queryBody.criteria.referralType,
        })
        .then(({ res }) => {
          this.setState({
            referralCodeQuery: {
              ...referralCodeQuery,
              selectedObj: {
                ...res,
                value: res.referralCode,
                id: res.referralCodeId,
              },
            },
          })
        })
    }
  }
  fetchQueryVoucherCodeData = () => {
    const { queryBody, voucherCodeQuery } = this.state
    if (
      queryBody.criteria.redeemCodeId &&
      queryBody.criteria.redeemCodeId !== 'all'
    ) {
      this.props.settingActions
        .detailsVoucherCode({
          id: queryBody.criteria.redeemCodeId,
          fleetId: this.props.selectedFleet.fleetId,
        })
        .then(({ res }) => {
          this.setState({
            voucherCodeQuery: {
              ...voucherCodeQuery,
              selectedObj: {
                ...res,
                value: res.voucherCode,
                id: res._id,
                campaignId: res.campaignId,
              },
            },
          })
        })
    }
  }
  fetchQueryMerchantData = () => {
    const { queryBody, storeQuery } = this.state
    const { selectedFleet } = this.props
    if (
      queryBody.criteria.merchantId &&
      queryBody.criteria.merchantId !== 'all'
    ) {
      this.props.merchantActions
        .merchanDetails({
          fleetId: selectedFleet.fleetId,
          id: queryBody.criteria.merchantId,
        })
        .then(({ res }) => {
          this.setState({
            storeQuery: {
              ...storeQuery,
              selectedObj: {
                ...res,
                name: res.data.name,
                id: res.data.id,
              },
            },
          })
        })
    }
  }
  fetchReportData = (params = {}) => {
    const waiveOffCommission =
      _.get(this.props.selectedFleet, 'waiveOffCommission.enable') || false
    const driverFields = _.get(this.props.selectedFleet, 'driverFields', [])
    const modules = _.get(this.props.selectedFleet, 'modules', [])
    const isShowTotal = !!_.find(
      modules,
      (m) => m.name === 'Driver to pax' || m.name === 'Pax refers Pax'
    )
    const isIDType = !!_.find(driverFields, (df) => df.key === 'idType')
    const isAddOnPrice = _.get(
      this.props.selectedFleet,
      'priceAdjustable',
      false
    )
    if (params.export) {
      this.setState({
        numberRecord: '',
        exportPercent: 0,
        showInputModal: false,
      })
      if (this.props.hasCustomShowColumns) {
        params.criteria.timeFilterType =
          this.state.queryBody.criteria.timeFilterType
        params.criteria.showColumns = this.props.listColumnSelected
      }
      if (this.props.isReportBookingDetails) {
        params.criteria.dynamicSurcharge = this.props.isShowDynamicSurcharge
        params.criteria.dynamicFare = this.props.isShowDynamicFare
      }
      if (
        this.props.driverUserName &&
        ReportUtils.isShowFareFields({
          fleet: this.props.selectedFleet,
          fieldName: this.props.driverUserName,
        })
      ) {
        params.criteria.userName = true
      }
      if (this.props.symbol) {
        params.criteria.symbol = CurrencyFormater.findCurrency(
          params.criteria.currency
        ).symbol
      }
      params.criteria.isShortTrip = waiveOffCommission
      params.criteria.isIDType = isIDType
      params.criteria.isShowTotal = isShowTotal
      params.criteria.isAddOnPrice = isAddOnPrice
      params.criteria.type = params.type
      params.criteria.drvPayout = params.drvPayout
      if (this.props.tittle === 'Airline') {
        params.criteria.airline = true
      }
      if (this.props.isReportDailyDriver) {
        const isPaxCreditWallet = _.get(
          this.props.selectedFleet,
          'paxCreditWalletConfig.enable',
          false
        )
        const isApplePay = _.get(
          this.props.selectedFleet,
          'applePayConfig.isActive',
          false
        )
        const isTNGeWallet = _.get(
          this.props.selectedFleet,
          'tnGeWalletConfig.isActive',
          false
        )
        const isVippsWallet = _.get(
          this.props.selectedFleet,
          'vippsWalletConfig.isActive',
          false
        )
        params.criteria.isPaxCreditWallet = isPaxCreditWallet
        params.criteria.isApplePay = isApplePay
        params.criteria.isTNGeWallet = isTNGeWallet
        params.criteria.isVippsWallet = isVippsWallet
      }
      if (params.criteria.eventId) {
        const event = _.find(this.state.eventQuery.list, {
          value: params.criteria.eventId,
        })
        this.setState({ numberOfQualified: event.numOfQualify, event: event })
        let splitEvent = _.split(event.label, ' ')
        let eventName =
          splitEvent.length != 0 ? _.join(splitEvent, '-') : event.label
        params.criteria.eventName =
          eventName +
          '-' +
          moment(this.state.event.startDate)
            .tz(this.state.timezone)
            .format('DDMMYY') +
          '-' +
          moment(this.state.event.endDate)
            .tz(this.state.timezone)
            .format('DDMMYY')
        params.criteria.eventType = event.type
        params.criteria.currency = this.state.queryBody.criteria.currency
        params.criteria.unit = this.state.queryBody.criteria.unitDistance
        params.criteria.numberRecord = params.numberRecord
      }

      const showDiffTimeZoneOnReports =
        _.get(this.props.selectedFleet, 'showDiffTimeZoneOnReports') || false
      params.showDiffTimeZoneOnReports = showDiffTimeZoneOnReports
      this.props.loadingBarActions.showLoadingSpiner()
      return this.props.reportActions
        .fetchReportDataFromApi(this.props.apiurl, params)
        .then((resp) => {
          this.props.loadingBarActions.hideLoadingSpiner()
          return this.handleResultExport(resp, params.isCheckExportExisted)
        })
        .catch((error) => {
          this.props.loadingBarActions.hideLoadingSpiner()
          console.log('Export error', error)
        })
    }

    const queryBody = _.pick(this.state.queryBody, ['from', 'size', 'fleetId'])

    if (params.gateway) {
      queryBody.gateway = params.gateway
    }

    queryBody.locale = localStorage.getItem('language') || 'en'

    const criteriaFields = ReportUtils.initCriteriaFields(this.props)
    const { criteria } = this.state.queryBody
    queryBody.criteria = _.pick(criteria, criteriaFields)
    if (this.props.tittle === 'Corporate prepaid') {
      if (queryBody.criteria && queryBody.criteria.transactionType) {
        queryBody.criteria.prepaidType = queryBody.criteria.transactionType
      }
    }
    //if (this.props.user.roles.companyId !== "" && this.props.user.roles.companyId !== "all") {
    //  queryBody.criteria.companyId = this.props.user.roles.companyId;
    // }
    if (this.props.multiSelectionsFilters) {
      this.props.multiSelectionsFilters.forEach((filter) => {
        queryBody.criteria[filter.key] =
          this.state.queryBody.criteria[filter.key]
      })
    }
    if (this.props.hasCustomShowColumns) {
      queryBody.criteria.timeFilterType =
        this.state.queryBody.criteria.timeFilterType
    }
    if (criteriaFields.includes('vehicleType')) {
      const selectedCarType = _.find(
        this.props.carType,
        (item) => item.value === criteria.vehicleType
      )
      queryBody.criteria.vehicleType = _.get(selectedCarType, 'value', 'all')
    }
    if (criteriaFields.includes('carTypeMulti')) {
      queryBody.criteria.vehicleTypes = queryBody.criteria.carTypeMulti
      delete queryBody.criteria.carTypeMulti
    }

    if (criteriaFields.includes('paymentStatusRefund')) {
      queryBody.criteria.paymentMethods = queryBody.criteria.paymentStatusRefund
      delete queryBody.criteria.paymentStatusRefund
    }

    if (this.props.promoStatus) {
      queryBody.criteria.promoStatus = _.get(criteria, 'promoStatus')
    }
    if (this.props.shortTrip && waiveOffCommission) {
      queryBody.criteria.shortTrip = _.get(criteria, 'shortTrip')
    }
    if (
      !queryBody.criteria.types &&
      criteriaFields.includes('incidentCanceledTypes')
    ) {
      queryBody.criteria.types = queryBody.criteria.incidentCanceledTypes
    }
    if (queryBody.criteria.eventId) {
      const event = _.find(this.state.eventQuery.list, {
        value: queryBody.criteria.eventId,
      })
      if (event) {
        this.setState({ numberOfQualified: event.numOfQualify, event: event })
        queryBody.criteria.eventCriterias = event.criterias
        queryBody.criteria.eventQuest = event.quests
        queryBody.criteria.eventType = event.type
      }
    }
    _.isBoolean(this.props.approved) &&
      (queryBody.criteria.approved = this.props.approved)
    this.props.year &&
      (queryBody.criteria.year = parseInt(queryBody.criteria.year))
    const fleetId =
      queryBody.fleetId || _.get(this.props, 'selectedFleet.fleetId', null)
    const { timezone } = this.state.queryBody && this.state.queryBody.criteria
    const showDiffTimeZoneOnReports =
      _.get(this.props.selectedFleet, 'showDiffTimeZoneOnReports') || false
    queryBody.localTimezone = timezone
    queryBody.showDiffTimeZoneOnReports = showDiffTimeZoneOnReports
    const requestBody = Object.assign({}, queryBody, { fleetId })

    if (this.props.sort) {
      requestBody.sort = {
        [this.state.sortName]: this.state.sortType,
      }
    }
    if (params.payoutDate) {
      queryBody.criteria.payoutDate = params.payoutDate
    }
    if (this.props.tittle === 'Airline') {
      queryBody.criteria.isAirline = true
    }
    if (!ReportUtils.checkRequestBody(requestBody)) return

    const timeFields = ['fromDate', 'toDate', 'expiredDate']
    timeFields.forEach((field) => {
      if (requestBody.criteria[field]) {
        const temp = moment(requestBody.criteria[field]).format(
          'YYYY-MM-DD HH:mm:ss'
        )
        requestBody.criteria[field] = moment.tz(temp, timezone).toISOString()
        if (
          requestBody.criteria[field] > moment.tz(timezone).toISOString() &&
          /paid-to-driver/.test(this.props.apiurl)
        ) {
          requestBody.criteria[field] = moment.tz(timezone).toISOString()
        }
      }
    })
    requestBody.fromRequest = `newcc-${actionSearch}`
    actionSearch = ''
    this.setState({
      timeZoneRequested: timezone,
      lastQuery: requestBody,
    })

    if(this.props.title === commonData.thirdPartyIntegration.bookingAPI) {
      requestBody.criteria.thirdParty = commonData.thirdPartyIntegration.bookingAPI
    }

    if(this.props.title === commonData.thirdPartyIntegration.holidaytaxis) {
      requestBody.criteria.thirdParty = commonData.thirdPartyIntegration.holidaytaxis
    }
    
    if(this.props.title === commonData.thirdPartyIntegration.karhoo) {
      requestBody.criteria.thirdParty = commonData.thirdPartyIntegration.karhoo
    }

    this.props.loadingBarActions.showLoadingSpiner()

    return this.props.reportActions
      .fetchReportDataFromApi(this.props.apiurl, requestBody)
      .then((resp) => {
        console.log(resp)
        this.props.loadingBarActions.hideLoadingSpiner()
        const total = _.get(resp, 'res.total', 0)
        const footerData = {
          limit: this.state.queryBody.size,
          page: this.state.page,
          total,
        }
        const list = resp.res && resp.res.list ? resp.res.list : []
        const summary = resp.res && resp.res.summary ? resp.res.summary : {}
        const shownColumn = this.getShownColumns(resp.res)
        const payoutDate =
          resp.res && resp.res.payoutDate ? resp.res.payoutDate : ''
        requestBody.criteria.payoutDate = payoutDate
        this.setState({
          rowCheckBoxes: [],
          list,
          summary,
          total,
          isSearched: true,
          resp: resp.res,
          footerData,
          shownColumn,
          payoutDate: payoutDate,
          errorCode: resp.errorCode || 0,
          error: resp.errorCode ? resp : {},
        })
      })
      .catch((error) => {
        this.props.loadingBarActions.hideLoadingSpiner()
        this.setState({ error, errorCode: error.errorCode || 0 })
      })
  }

  validateionExport = () => {
    const { total, queryBody } = this.state
    // limit column excel
    if(total > 1000000) {
      this.context.notification(
        'error',
        'Your export exceeds the maximum limit of 1,000,000 records. Please adjust your filter to select fewer than 1,000,000 records and try again'
      )
      return false
    }

    // time range is less than 31 days
    const fromDate = _.get(queryBody, 'criteria.fromDate', moment().startOf('day').toISOString())
    const toDate = _.get(queryBody, 'criteria.toDate', moment().toISOString())
    if(moment(toDate).diff(moment(fromDate), 'days') > 31) {
      this.context.notification(
        'error',
        'Your export request spans more than one month. To ensure optimal performance, please limit your export to a single month at a time'
      )
      return false
    }

    return true
  }

  exportQuestReport = (e, emailReciveExport) => {
    e.target.blur()
    const { valid } = this.state
    this.setState({ isSubmited: false })
    if (!CCLiteCommonFunc.isFormValid(valid)) return
    this.setState({
      currentTypeExp: EXPORT_TYPE.quests,
    }, () => {
      let params = {
        email: emailReciveExport,
        numberRecord: this.state.numberRecord,
        export: true,
        total: this.state.total,
      ...this.state.lastQuery,
      }
      if(!emailReciveExport) {
        this.fetchReportData({
          ...params,
          isCheckExportExisted: true
        })
        return 
      }
      this.fetchReportData(params)
    })
  }

  exportReportHasEmail = (emailReciveExport) => {
    if(this.state.currentTypeExp === EXPORT_TYPE.csv) {
      this.exportReportCSV(null, emailReciveExport)
      return
    }
    if(this.state.currentTypeExp === EXPORT_TYPE.quests) {
      this.exportQuestReport(null, emailReciveExport)
      return
    }
    this.exportReport(null, emailReciveExport)
  }

  exportReport = (e, emailReciveExport) => {
    if(!this.validateionExport()) return
    e && e.target.blur()
    const newState = Object.assign({}, this.state)
    let titleHasGateway = [
      'Payout history',
      'Payout history merchants',
      'Pay to drivers',
      'Pay to merchants',
    ]
    if (
      this.props.tittle === 'Quest' &&
      (newState.queryBody.criteria.eventId === 'all' ||
        newState.queryBody.criteria.eventId === '')
    ) {
      this.context.notification(
        'error',
        I18n.t('report.result.customerQuest.pleaseSelectEventName')
      )
      return
    }
    this.setState({
      currentTypeExp: EXPORT_TYPE.excel,
    }, () => {
      let params = {
        export: true,
        email: emailReciveExport,
        total: this.state.total,
        ...this.state.lastQuery
      }
      if(!emailReciveExport) {
        this.fetchReportData({
          gateway: this.props.gateway,
          ...params,
          isCheckExportExisted: true
        })
        return 
      }
      if (titleHasGateway.indexOf(this.props.tittle) >= 0 && this.props.gateway) {
        this.fetchReportData({
          gateway: this.props.gateway,
          ...params,
        })
      } else {
        this.fetchReportData({ ...params })
      }
    })
  }
  exportReportCSV = (e, emailReciveExport) => {
    if (!this.validateionExport()) return;
    e && e.target.blur();
    this.setState({
      currentTypeExp: EXPORT_TYPE.csv,
    }, () => {
      let drvPayout =
        typeof this.props.selectedFleet.drvPayout !== 'undefined' &&
        this.props.selectedFleet.drvPayout.gateway === 'CIB'
          ? 'CIB'
          : 'RazerPay';
      let params = {
        email: emailReciveExport,
        total: this.state.total,
        drvPayout: drvPayout,
        type: 'csv',
        export: true,
        ...this.state.lastQuery,
      };
      if(!emailReciveExport) {
        this.fetchReportData({
          ...params,
          isCheckExportExisted: true
        })
        return 
      }
      this.fetchReportData(params);
    })
  }

  handleClickView = (e) => {
    const { valid } = this.state
    const newState = Object.assign({}, this.state)
    this.setState({ isSubmited: true, payoutDate: '' })
    if (!CCLiteCommonFunc.isFormValid(valid)) return
    if (
      this.props.tittle === 'Quest' &&
      (newState.queryBody.criteria.eventId === 'all' ||
        newState.queryBody.criteria.eventId === '')
    ) {
      this.context.notification(
        'error',
        I18n.t('report.result.customerQuest.pleaseSelectEventName')
      )
      return
    }
    newState.queryBody.from = 0
    newState.page = 0
    if (!newState.queryBody.criteria.driverId) {
      newState.queryBody.criteria.driverId = 'all'
    }
    if (!newState.queryBody.criteria.companyId) {
      newState.queryBody.criteria.companyId = 'all'
    }
    if (!newState.queryBody.criteria.campaignId) {
      newState.queryBody.criteria.campaignId = 'all'
    }
    e && e.target.blur()
    newState.queryBody.criteria.payoutDate = ''
    this.setState(newState, () => {
      this.fetchReportData()
    })
  }

  getDateValue = (language = 'en-US') => {
    const newState = Object.assign({}, this.state)
    const fromDate = moment(newState.queryBody.criteria.fromDate)
    const date = moment(newState.queryBody.criteria.toDate)
    const viewMode = newState.queryBody.criteria.viewMode
    switch (viewMode) {
      case viewModes[0]:
        return `${fromDate.locale(language).format('MM/DD/YYYY')} - ${date
          .locale(language)
          .format('MM/DD/YYYY')}` // `
      case viewModes[1]:
        return date.locale(language).format('MM/YYYY')
    }
    return date.locale(language).format('YYYY-MM-DD hh:mm A')
  }

  removeWeekCss() {
    $('tr:has(td.rdtDay)').css({ 'background-color': '' })
    $('tr:has(td.rdtDay.rdtActive)').css({
      'background-color': '',
    })
  }

  drawWeekActive = () => {
    $('tr:has(td.rdtDay)').css({ 'background-color': '#fff0' })
    $('tr:has(td.rdtDay.rdtActive)').css({
      'background-color': '#428bca',
    })
  }

  handleChangeDateRange = (params) => {
    const self = this
    const newState = Object.assign({}, this.state)
    /* const { localeCode = "en" } = this.props.selectedFleet || {}; */
    const localeCode = 'vi'
    let { fromDate, toDate } = newState.queryBody.criteria
    const viewMode = (newState.queryBody.criteria.viewMode =
      viewModes.indexOf(params.selectedButton) > -1
        ? params.selectedButton
        : null)
    newState.queryBody.criteria.disableDatetimeSelection =
      params.selectedButton.startsWith('past') &&
      params.selectedButton.endsWith('Days')
        ? true
        : false
    newState.queryBody.criteria.selectedButton = params.selectedButton
    const { fromDate: paramsFromDate, toDate: paramsToDate } = params || {}

    const selectedFromdate = paramsFromDate ? paramsFromDate : fromDate
    const selectedTodate = paramsToDate ? paramsToDate : toDate

    switch (params.selectedButton) {
      case viewModes[0]:
        toDate = moment(selectedTodate)
          .locale(localeCode)
          .endOf('week')
          .endOf('day')
          .toISOString(true)
        fromDate = moment(toDate)
          .locale(localeCode)
          .startOf('week')
          .startOf('day')
          .toISOString(true)
        break
      case viewModes[1]:
        toDate = moment(selectedTodate).endOf('month').toISOString(true)
        fromDate = moment(toDate).startOf('month').toISOString(true)
        break
      case 'past7Days':
        toDate = moment().toISOString()
        fromDate = moment(toDate).startOf('day').add(-6, 'days').toISOString()
        break
      default:
        fromDate = moment(selectedFromdate).toISOString()
        toDate = moment(selectedTodate).toISOString()
        if (fromDate > toDate) {
          if (paramsFromDate)
            toDate = moment(fromDate).endOf('day').toISOString()
          else fromDate = moment(toDate).startOf('day').toISOString()
        }
        break
    }
    newState.queryBody.criteria.fromDate = fromDate
    newState.queryBody.criteria.toDate = toDate

    this.setState(newState, () => {
      if (viewMode === viewModes[0]) {
        this.drawWeekActive()
      } else {
        this.removeWeekCss()
      }
      this.props.enablePayoutTime &&
        this.handleSearchPayoutTime(self.fetchQueryPayoutTime, true)
    })
  }

  handleCustomDateSelect = () => {
    if (this.dateFromInput) {
      this.setState({ showDateFrom: true }, () => this.dateFromInput.focus())
    }
  }
  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid
      this.setState({ valid: this.state.valid })
    }
  }
  handleChangeCombobox(e, value, name) {
    const newState = Object.assign({}, this.state)
    name = name || _.get(e, 'target.name')
    value = value || _.get(e, 'target.value')
    newState.queryBody.criteria[name] = value
    const {
      driverId,
      vehicleId,
      promoCodeId,
      mDispatcherId,
      redeemCodeId,
      refereeId,
      driverReferrerId,
    } = newState.queryBody.criteria

    if (value !== 'all') {
      switch (name) {
        case 'companyId':
          newState.driverQuery = {
            ...this.state.driverQuery,
            list: [],
            page: 0,
            total: 0,
            search: '',
            limit: 20,
          }
          // check driver by company
          if (
            this.props.driver &&
            driverId !== 'all' &&
            this.state.driverQuery.selectedObj.companyId !== value
          ) {
            newState.queryBody.criteria.driverId = 'all'
            newState.queryBody.criteria.payoutOption =
              typeof this.props.selectedFleet.drvPayout !== 'undefined' &&
              this.props.selectedFleet.drvPayout.gateway === 'CIB'
                ? 'all'
                : 'hasBank'
            newState.driverQuery.selectedObj = null
          }
          if (
            this.props.driverReferrerId &&
            driverReferrerId !== 'all' &&
            this.state.driverQuery.selectedObj.companyId !== value
          ) {
            newState.queryBody.criteria.driverReferrerId = 'all'
            newState.queryBody.criteria.payoutOption =
              typeof this.props.selectedFleet.drvPayout !== 'undefined' &&
              this.props.selectedFleet.drvPayout.gateway === 'CIB'
                ? 'all'
                : 'hasBank'
            newState.driverQuery.selectedObj = null
          }
          if (
            this.props.refereeId &&
            refereeId !== 'all' &&
            this.state.driverQuery.selectedObj.companyId !== value
          ) {
            newState.queryBody.criteria.refereeId = 'all'
            newState.queryBody.criteria.payoutOption =
              typeof this.props.selectedFleet.drvPayout !== 'undefined' &&
              this.props.selectedFleet.drvPayout.gateway === 'CIB'
                ? 'all'
                : 'hasBank'
            newState.driverQuery.selectedObj = null
          }
          // check license plate
          if (
            this.props.plate &&
            vehicleId !== 'all' &&
            this.state.vehicleQuery.selectedObj.companyId !== value
          ) {
            newState.queryBody.criteria.vehicleId = 'all'
            newState.vehicleQuery.selectedObj = null
          }
          break
        case 'campaignId':
          if (!value) {
            this.handleSearchCampaign('', null, true)
            newState.queryBody.criteria.redeemCodeId = 'all'
            newState.voucherCodeQuery.selectedObj = null
          } else {
            if (promoCodeId !== 'all') {
              if (
                _.keyBy(this.props.promocode, '_id')[promoCodeId] &&
                _.keyBy(this.props.promocode, '_id')[promoCodeId].campaignId !=
                  value
              ) {
                newState.queryBody.criteria.promoCodeId = 'all'
              }
            }
            newState.voucherCodeQuery = {
              ...this.state.voucherCodeQuery,
              list: [],
              page: 0,
              total: 0,
              search: '',
              limit: 20,
            }
            if (
              this.props.voucherCodes &&
              redeemCodeId !== 'all' &&
              this.state.voucherCodeQuery.selectedObj.campaignId !== value
            ) {
              newState.queryBody.criteria.redeemCodeId = 'all'
              newState.voucherCodeQuery.selectedObj = null
            }
          }

          break
        case 'mDispatcherTypeId':
          if (mDispatcherId !== 'all') {
            if (
              _.keyBy(this.props.mDispatcher, '_id')[mDispatcherId]
                .mDispatcherTypeId != value
            ) {
              newState.queryBody.criteria.mDispatcherId = 'all'
            }
          }
          break
        case 'vehicleType':
          if (vehicleId !== 'all') {
            if (this.state.vehicleQuery.selectedObj.vehicleTypeId !== value) {
              newState.queryBody.criteria.vehicleId = 'all'
              newState.vehicleQuery.selectedObj = null
            }
          }
          break
        case 'userType':
          newState.queryBody.criteria.eventType = 'all'
          newState.queryBody.criteria.eventId = ''
          newState.eventQuery.selectedObj = null
          newState.event = null
          break
        case 'eventType':
          newState.queryBody.criteria.eventId = ''
          newState.eventQuery.selectedObj = null
          newState.event = null
          break
        case 'merchantType':
          newState.queryBody.criteria.merchantId = ''
          newState.storeQuery.selectedObj = null
          break
        case 'jobType':
          newState.queryBody.criteria.merchantId = ''
          newState.storeQuery.selectedObj = null
          break
        default:
          break
      }
    }

    this.setState(newState, () => {
      if (name === 'companyId' || name === 'payoutOption') {
        this.props.driver && this.handleSearchDriver('', null, true)
        this.props.driverReferrerId && this.handleSearchDriver('', null, true)
        this.props.refereeId && this.handleSearchDriver('', null, true)
        this.props.plate && this.handleSearchLicensePlate('', null, true)
      } else if (name === 'vehicleType') {
        this.props.plate && this.handleSearchLicensePlate('', null, true)
      } else if (name === 'userType' || name === 'eventType') {
        this.props.event && this.handleSearchEvent('', null, true)
      } else if (name === 'campaignId') {
        this.props.voucherCodes && this.handleSearchVoucherCode('', null, true)
      } else if (name === 'merchantType' || name === 'jobType') {
        this.props.storeMerchant && this.handleSearchStore('', null, true)
      }
    })
  }

  handleChangeExpiredDate(value) {
    const newState = Object.assign({}, this.state)
    newState.queryBody.criteria.expiredDate = moment(value).format('MM/DD/YYYY')
    this.setState(newState)
  }
  handleChangeFilterDate(value) {
    const newState = Object.assign({}, this.state)
    newState.queryBody.criteria.filterDate = moment(value)
    this.setState(newState)
  }

  filterFinishedStatus = (status = []) => {
    if(!status || status.length === 0) return []
    const overrideBookingStatus = _.get(
      this.props.selectedFleet,
      'generalSetting.overrideBookingStatus',
      false
    )
    if (!_.get(this.props.selectedFleet, 'delivery.enable', false)) {
      status = status.filter(
        (i) => i.value !== 'failed' && i.value !== 'partialCompleted'
      )
    }
    if (!overrideBookingStatus) {
      status = status.filter(
        (i) => i.value !== 'completedWithoutService'
      )
    }
    return status
  }

  handleDropDownMultiSelect = (
    values,
    filterName,
    valueForAll = 'all',
    options = []
  ) => {
    const {
      paymentMethod,
      bookingStatus,
      withdrawalTransactionType,
      mapZone,
      carTypeCommonData,
    } = this.props
    const { queryBody } = this.state

    var finishedStatusCopy = Array.isArray(this.props.finishedStatus)
      ? this.props.finishedStatus
      : this.filterFinishedStatus(commonData.finishedStatusList)
    var paymentMethodCopy = Array.isArray(this.props.paymentMethod)
      ? this.props.paymentMethod
      : commonData.bookingDetailsPaymentMethods
    if (!_.get(this.props.selectedFleet, 'delivery.enable', false)) {
      paymentMethodCopy = paymentMethodCopy.filter(
        (item) =>
          item.value !== 'cashBySender' && item.value !== 'cashByRecipient'
      )
    }
    if (
      !_.get(this.props.selectedFleet, 'zainCashWalletConfig.isActive', false)
    ) {
      paymentMethodCopy = paymentMethodCopy.filter(
        (item) => item.value !== 'ZainCash'
      )
    }
    if (!_.get(this.props.selectedFleet, 'gCashWalletConfig.isActive', false)) {
      paymentMethodCopy = paymentMethodCopy.filter(
        (item) => item.value !== 'GCash'
      )
    }
    const bookingStatusCopy = Array.isArray(bookingStatus)
      ? bookingStatus
      : commonData.affiliationBookingStatus

    const zoneItems = (mapZone || []).filter((o) => o.display && o.isActive)
    const defaultPickupZone = ['all'].concat(_.map(zoneItems, '_id'))

    const defaultCarTypeMulti = ['all'].concat(
      _.map(carTypeCommonData, 'vehicleType')
    )

    const transactionTypes = Array.isArray(this.props.transactionType)
      ? this.props.transactionType
      : commonData.transactionTypes

    const incidentCanceledTypeList = Array.isArray(
      this.props.incidentCanceledTypes
    )
      ? this.props.incidentCanceledTypes
      : commonData.incidentCanceledTypes
    const mappings = {
      bookFrom: this.state.bookFromList.map((item) => item.value),
      thirdPartyAction: this.state.thirdPartyActionList.map((item) => item.value),
      finishedBookingStatus: finishedStatusCopy.map((item) => item.value),
      paymentMethod: paymentMethodCopy.map((item) => item.value),
      bookingStatus: bookingStatusCopy.map((item) => item.value),
      paymentStatus: (Array.isArray(_.get(this.props, 'paymentStatus.options'))
        ? this.props.paymentStatus.options
        : commonData.bookingDetailsPaymentStatus
      ).map((item) => item.value),
      paymentStatusRefund: commonData.paymentStatusRefund.map(
        (item) => item.value
      ),
      currentStatus: commonData.bookingDetailsPaymentStatus.map(
        (item) => item.value
      ),
      originalStatus: commonData.originalPaymentStatus.map(
        (item) => item.value
      ),
      bookingType: this.bookingTypes.map((item) => item.value),
      adjustPrice: commonData.adjustPrices.map((item) => item.value),
      ratingType: commonData.ratingTypes.map((item) => item.value),
      stars: commonData.stars.map((item) => item.value),
      serviceType: commonData.serviceTypes.map((item) => item.value),
      transactionType: transactionTypes.map((item) => item.value),
      incidentCanceledTypes: incidentCanceledTypeList.map((item) => item.value),
      rideService: commonData.rideServices.map((item) => item.value),
      vehicleRate: commonData.stars.map((item) => item.value).slice(),
      pickUpZone: defaultPickupZone,
      carTypeMulti: defaultCarTypeMulti,
      tip: commonData.TipAfterCompleted.map((item) => item.value),
      promoStatus: commonData.PromotionTypeFilter.map((item) => item.value),
    }
    const newQueryBody = Object.assign({}, queryBody)
    const existAll = _.findIndex(
      queryBody.criteria[filterName],
      (item) => item === valueForAll
    )

    if (existAll !== -1) {
      if (values.includes(valueForAll)) {
        newQueryBody.criteria[filterName] = _.filter(
          values,
          (item) => item !== valueForAll
        )
      } else {
        newQueryBody.criteria[filterName] = []
      }
      // } else if (values.includes(valueForAll) || values.length === mappings[filterName].length - 1) {
    } else if (values.includes(valueForAll)) {
      newQueryBody.criteria[filterName] =
        options && options.length
          ? options.map((i) => i.value)
          : mappings[filterName]
    } else {
      newQueryBody.criteria[filterName] = values
    }

    this.setState({ queryBody: newQueryBody })
  }

  handleChangeItemsPerPage = (limit) => {
    let payoutDate = this.state.payoutDate
    const newQueryBody = Object.assign({}, this.state.queryBody)
    newQueryBody.criteria.payoutDate = payoutDate
    this.setState(
      {
        page: 0,
        queryBody: {
          ...newQueryBody,
          size: limit,
          from: 0,
        },
      },
      () => this.fetchReportData()
    )
  }

  handlePagesRange = (page) => {
    let payoutDate = this.state.payoutDate
    const newQueryBody = Object.assign({}, this.state.queryBody)
    newQueryBody.criteria.payoutDate = payoutDate
    this.setState(
      {
        page,
        queryBody: {
          ...newQueryBody,
          from: this.state.queryBody.size * page,
        },
      },
      () => this.fetchReportData()
    )
  }
  handleHideConversion = () => {
    this.setState({
      showConversionModal: false,
      conversionData: {
        show: false,
      },
    })
  }
  handleShowConversion = (link, row) => {
    this.setState({
      showConversionModal: true,
      conversionData: {
        show: true,
        closeModal: this.handleHideConversion,
        ...link.props(row),
      },
    })
  }

  handleActionOnDataRow = (link, row, e) => {
    const data = {
      queryBody: Object.assign(
        {},
        _.omit(this.state.lastQuery, ['from', 'size'])
      ),
      row,
    }
    const { actions } = this.props
    if (actions && actions[link.name]) {
      if (link.confirm) {
        this.handleConfirmButtonClick = actions[link.name].bind(
          this,
          link,
          data,
          e
        )
        this.setState({
          confirm: {
            title: I18n.t(link.confirm.title),
            body: I18n.t(link.confirm.body),
            buttonTitle: link.confirm.buttonTitle
              ? I18n.t(link.confirm.buttonTitle)
              : '',
            closeButtonText: link.confirm.closeButtonText
              ? I18n.t(link.confirm.closeButtonText)
              : '',
          },
        })
      } else {
        Promise.resolve()
          .then(() => actions[link.name].call(this, link, data, e))
          .then(() => {
            this.setState({ confirm: false })
          })
      }
    }
  }

  handleConfirmButtonClick = () => {
    window.location = '/reports/export?isRedirect=true';
  }

  handleConfirmCloseClick = () => {
    this.setState({ confirm: false })
  }

  handleSortClick(sortName) {
    this.setState(
      {
        sortName,
        sortType: this.state.sortType === 1 ? -1 : 1,
      },
      () => {
        this.fetchReportData()
      }
    )
  }
  handleDeleteClick(col, doc) {
    return this.props.reportActions
      .deleteExportFromApi({
        id: doc._id,
      })
      .then((resp) => {
        this.fetchReportData()
      })
  }

  handleOnChangeRowCheckBoxes = (e, key = 'driverId') => {
    let rowCheckBoxes = Object.assign([], this.state.rowCheckBoxes)
    const list = _.cloneDeep(this.state.list)
    const { target } = e
    if (target.checked) {
      if (target.value === 'all') {
        _.forEach(list, (item) => {
          if (
            item.totalUnsettledAmount > 0 ||
            this.props.tittle === 'Pay to drivers' ||
            this.props.tittle === 'Pay to merchants'
          ) {
            rowCheckBoxes.push(item[key])
            item.rowSelected = true
          }
        })
        rowCheckBoxes.push('all')
      } else {
        rowCheckBoxes.push(target.value)
        rowCheckBoxes = _.uniq(rowCheckBoxes)
        _.forEach(list, (item) => {
          if (item[target.name] === target.value) {
            item.rowSelected = true
            return false
          }
        })
        const totalUnsettledAmount = _.filter(
          list,
          (item) => item.totalUnsettledAmount > 0
        )
        if (rowCheckBoxes.length === totalUnsettledAmount.length) {
          rowCheckBoxes.push('all')
        }
        if (rowCheckBoxes.length === list.length) {
          rowCheckBoxes.push('all')
        }
      }
    } else if (target.value === 'all') {
      rowCheckBoxes = []
      _.forEach(list, (item) => {
        item.rowSelected = false
      })
    } else {
      _.remove(rowCheckBoxes, (item) => item === target.value || item === 'all')
      _.forEach(list, (item) => {
        if (item[target.name] === target.value) {
          item.rowSelected = false
          return false
        }
      })
    }

    this.setState({
      rowCheckBoxes,
      list,
    })
  }

  getCustomHeader = (item, columnKey) => {
    let headerUnit = ''
    if (item.headerUnit) {
      if (_.isString(item.headerUnit)) {
        headerUnit = `(${this.props.selectedFleet[item.headerUnit]})` // `
      } else {
        headerUnit = item.headerUnit
      }
    } else if (
      _.includes(this.props.fieldMappings.fareFields || [], item.key)
    ) {
      headerUnit = `(${
        CurrencyFormater.findCurrency(this.state.queryBody.criteria.currency)
          .symbol
      })` // `
    }

    const sorting = item.sort ? (
      <i
        className={
          this.state.sortName === item.sort
            ? this.state.sortType === 1
              ? 'fa fa-sort-desc'
              : 'fa fa-sort-asc'
            : 'fa fa-sort'
        }
        aria-hidden="true"
        onClick={() => {
          this.handleSortClick(item.sort)
        }}
      />
    ) : null
    if (item.toolTip) {
      let toolTipValue = item.toolTip
      if (_.isFunction(item.toolTip)) {
        toolTipValue = item.toolTip(this.props.selectedFleet)
      }
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip">
              <Translate value={toolTipValue} />
            </Tooltip>
          }
          placement="top"
          delayHide={150}
        >
          <div className={`${item.className ? item.className : ''}`}>
            {/* ` */}
            <Translate value={item.label} /> {headerUnit} {sorting}
          </div>
        </OverlayTrigger>
      )
    }
    return (
      <div className={`${item.className ? item.className : ''}`}>
        {/* ` */}
        {item.translate && _.isFunction(item.label)
          ? item.label(this.state.resp)
          : I18n.t(item.label)}{' '}
        {headerUnit} {sorting}
      </div>
    )
  }

  renderTableHeader = () => {
    const tableColumns = []
    let isColumnGroup = false

    _.forEach(this.state.shownColumn, (item) => {
      if (item.sub) {
        isColumnGroup = true
        const columnGroup = {
          key: item.key,
          label: item.label,
          subColumns: [],
        }

        const itemSub = _.isFunction(item.sub) ? item.sub(item) : item.sub

        _.forEach(itemSub, (subItem) => {
          columnGroup.subColumns.push(
            Object.assign({}, subItem, {
              customHeader: (columnKey) =>
                this.getCustomHeader(subItem, columnKey),
            })
          )
        })
        tableColumns.push(columnGroup)
      } else {
        tableColumns.push(
          Object.assign({}, item, {
            customHeader: (columnKey) =>
              item.isCheckBox ? (
                <CcCheckbox
                  className="table-selectbox all-checkbox"
                  checked={this.state.rowCheckBoxes.indexOf('all') > -1}
                  onChange={(e) =>
                    this.handleOnChangeRowCheckBoxes(e, item.valueKey)
                  }
                  value="all"
                  // name="driverId"
                  name={item.valueKey}
                />
              ) : (
                this.getCustomHeader(item, columnKey)
              ),
          })
        )
      }
    })

    return {
      tableColumns,
      isColumnGroup,
    }
  }
  getCustomCell = (col, doc, rowIndex, floatFields) => {
    if (col.label === 'report.result.export.name') {
      let value = doc.moduleName || ''
      value = _.startCase(value.replace(/[0-9]/g, '').replace('.xlsx', ''))
      if (value === 'Cc Merchant Cash Transaction History') {
        value = 'Merchant Cash Wallet'
      }
      if (value === 'Cc Merchant Credit Transaction History') {
        value = 'Merchant Credit Wallet'
      }
      return <div>{value}</div>
    }
    if (col.label === 'report.result.export.delete') {
      return (
        <a
          onClick={this.handleDeleteClick.bind(this, col, doc)}
          style={{ textDecoration: 'underline' }}
          className="text-active"
        >
          <span className="glyphicon glyphicon-remove icon-red" />
        </a>
      )
    }
    if (col.key === 'percent') {
      if (doc.percent === 100) {
        return (
          <ProgressBar
            variant="success"
            label={`${doc.percent}%`}
            now={doc.percent}
          />
        )
      } else {
        return (
          <ProgressBar active now={doc.percent} label={`${doc.percent}%`} />
        )
      }
    }
    let timeZoneRequested = _.get(this.state.queryBody, 'criteria.timezone', '')
    const showDiffTimeZoneOnReports =
      _.get(this.props.selectedFleet, 'showDiffTimeZoneOnReports') || false
    if (this.state.timeZoneRequested) {
      timeZoneRequested = this.state.timeZoneRequested
    }
    let paramConvert = {
      fleet: this.props.selectedFleet,
      actions: this.props.actions,
    }
    if (showDiffTimeZoneOnReports) {
      paramConvert = {
        ...paramConvert,
        timezone: timeZoneRequested,
        timzoneFleet:
          (this.props.selectedFleet && this.props.selectedFleet.timezone) || '',
      }
    }
    let showValue = col.mutate
      ? col.mutate(doc[col.key], doc, paramConvert)
      : doc[col.key]
    const isFareField =
      (this.props.fieldMappings.fareFields || []).indexOf(col.key) > -1
    const isVND =
      (doc.currency ||
        doc.currencyISO ||
        this.state.queryBody.criteria.currency) === 'VND'

    let Link
    if (col.link) {
      let linkLabel = col.link.label ? I18n.t(col.link.label) : showValue
      if (isFareField) {
        linkLabel = ReportUtils.numberFormat(linkLabel)
      }
      if (col.link.name === 'showConversionModal') {
        if (col.link.isNotShow && col.link.isNotShow(doc)) {
          Link = linkLabel
        } else {
          Link = (
            <a
              onClick={(e) => this.handleShowConversion(col.link, doc)}
              style={{ textDecoration: 'underline' }}
              className="text-active"
            >
              {linkLabel}
            </a>
          )
        }
        return (
          <div className="report-table-column-content text-ellipsis">
            {Link}
          </div>
        )
      }
      let queryParams = Object.assign(
        {},
        this.state.queryBody.criteria,
        _.pick(doc, col.link.params)
      )

      // Link via BookId
      if (col.key === 'bookingId') {
        queryParams.txtSearch = queryParams.bookingId
      }
      if (col.link.name === 'goToAffiliationPayoutDetails') {
        queryParams.txtSearch = doc.payoutId
      }

      if (col.key === 'refererId') {
        queryParams.refererId = doc.customerId
      }
      if (col.key === 'driverReferrerId') {
        queryParams.driverReferrerId = doc.driverId
      }
      queryParams.fleetId =
        this.props.selectedFleet && this.props.selectedFleet.fleetId
      if (col.link.notMergeParams) {
        queryParams = _.pick(queryParams, col.link.params)
      }
      if (col.link.excludeParams) {
        queryParams = _.omit(queryParams, col.link.excludeParams)
      }
      const queryStr = qs.stringify(queryParams)

      let hideHrefLink = false
      if (col.key === 'referralEarning') {
        hideHrefLink = !this.props.isActivatedReferralDetails
      }

      if (col.link.newTab) {
        if ((col.link.isNotShow && col.link.isNotShow(doc)) || hideHrefLink) {
          Link = doc[col.key]
        } else {
          let href = ''
          let hrefOb = col.link.href
          if (_.isObject(hrefOb)) {
            if(col.link.customeHref) {
              if(['From airport - hourly/daily', 'Hourly', 'To airport - hourly/daily'].includes(doc.serviceType)) {
                hrefOb = col.link.customeHref.hourly
              }
              if(doc.serviceType === 'Round trip' || doc.flatRouteName) {
                hrefOb = col.link.customeHref.flat
              }
            }
            href = _.template(hrefOb.templateStr)(
              _.mapValues(hrefOb.params, (v, k) => doc[v])
            )
          } else {
            href = `${hrefOb}?${queryStr}` // `
          }
          Link = (
            <a href={href} target="_blank" className="text-active">
              {linkLabel}
            </a>
          )
        }
      } else if (col.link.disable && col.link.disable(doc)) {
        Link = 'N/A'
      } else if (col.link.isNotShow && col.link.isNotShow(doc)) {
        Link = linkLabel
      } else {
        Link = (
          <a
            onClick={(e) => this.handleActionOnDataRow(col.link, doc)}
            style={{ textDecoration: 'underline' }}
            className="text-active"
          >
            {linkLabel}
          </a>
        )
      }
      return (
        <div className="report-table-column-content text-ellipsis">{Link}</div>
      )
    }

    if (col.negativable) {
      if (showValue < 0) {
        showValue = `(${ReportUtils.numberFormat(
          -showValue,
          isVND ? 0 : undefined,
          isVND ? 0 : undefined
        )})` // `
      } else {
        showValue = ReportUtils.numberFormat(
          showValue,
          isVND ? 0 : undefined,
          isVND ? 0 : undefined
        )
      }
    } else if (
      floatFields.indexOf(col.key) > -1 &&
      (!_.isFunction(col.isShow) || col.isShow(doc[col.key], doc))
    ) {
      showValue = showValue || 0
      if (isFareField && isVND) {
        showValue = ReportUtils.numberFormat(showValue, 0, 0)
      } else {
        showValue = ReportUtils.numberFormat(showValue)
      }
    }
    if (col.distanceUnit) {
      var unit = this.props.selectedFleet.unitDistance
      if (unit === 'km') {
        let showValues = showValue / 1000
        showValue = ReportUtils.numberFormat(showValues)
      } else if (unit === 'mi') {
        let showValues = showValue * 0.000621371192
        showValue = ReportUtils.numberFormat(showValues)
      }
    }
    if (col.isImage && showValue) {
      showValue = (
        <img
          style={{
            height: '50px',
            width: 'auto',
          }}
          src={`${this.props.selectedFleet.s3Host}${showValue}`} /* ` */
        />
      )
    }

    if (showValue === true) {
      showValue = <div className="ticker" />
    } else if (col.isCheckBox && doc.totalUnsettledAmount > 0) {
      const isCheckedRow =
        this.state.rowCheckBoxes.indexOf('all') > -1 ||
        this.state.rowCheckBoxes.indexOf(doc[col.valueKey]) > -1
      showValue = (
        <CcCheckbox
          checked={isCheckedRow}
          onChange={this.handleOnChangeRowCheckBoxes}
          value={doc[col.valueKey]}
          disabled={false}
          name={col.valueKey}
          className="table-select-check"
        />
      )
    } else if (col.isCheckBox) {
      const isCheckedRow =
        this.state.rowCheckBoxes.indexOf('all') > -1 ||
        this.state.rowCheckBoxes.indexOf(doc[col.valueKey]) > -1
      showValue = (
        <CcCheckbox
          checked={isCheckedRow}
          onChange={this.handleOnChangeRowCheckBoxes}
          value={doc[col.valueKey]}
          disabled={false}
          name={col.valueKey}
          className="table-select-check"
        />
      )
    } else if (col.actions) {
      const actionItems = []
      _.forEach(col.actions, (action, index) => {
        if (doc.totalUnsettledAmount > 0 || action.name !== 'payToDriver') {
          actionItems.push({
            label: action.label,
            eventKey: action.name,
          })
        }
      })
      showValue = (
        <TableActions
          rowIndex={rowIndex}
          rowData={doc}
          onSelect={(eventKey, rowData) => {
            this.handleActionOnDataRow.call(
              this,
              col.actions.find((action) => action.name === eventKey),
              rowData
            )
          }}
          totalRow={this.state.list.length || 0}
          rowHeight={this.props.rowHeight || 50}
          menuItems={actionItems}
          tableHeight={this.state.tableHeight}
        />
      )
    }

    if (col.textEllipsis) {
      const manualTooltip = col.manualTooltip
        ? col.manualTooltip(doc[col.key], doc, {
            fleet: this.props.selectedFleet,
            actions: this.props.actions,
          })
        : null
      const originValue = showValue
      const referenValue =
        col.referenceKey && doc[col.referenceKey] ? doc[col.referenceKey] : null
      const toolTipValue =
        col.referenceKey && doc[col.referenceKey]
          ? `${originValue}\n(${doc[col.referenceKey]})` // `
          : manualTooltip
          ? manualTooltip
          : originValue
      const defaulTooltip = col.key == 'notes' || col.key == 'instructions'
      showValue = (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip">{toolTipValue}</Tooltip>}
          placement={defaulTooltip ? 'left' : 'top'}
          // delayShow={defaulTooltip ? "auto" : 300}
          // delayHide={defaulTooltip ? "auto" : 150}
          delayShow={300}
          delayHide={150}
        >
          {referenValue ? (
            <div>
              <p>{originValue}</p>
              <div>{referenValue}</div>
            </div>
          ) : (
            <div>{originValue}</div>
          )}
        </OverlayTrigger>
      )
    }

    const contentClass = `report-table-column-content${
      col.textEllipsis ? ' text-ellipsis' : ''
    }` // `
    return <div className={contentClass}>{showValue}</div>
  }

  getColumnFooterValue = (
    item,
    floatFields,
    summaryObj = this.state.summary
  ) => {
    let summaryValue = summaryObj[item.summary]
    if (_.isNil(summaryValue)) summaryValue = ''
    const { summaryMutate } = item
    if (summaryMutate) {
      summaryValue = summaryMutate(summaryValue, summaryObj)
    }

    const isFareField =
      (this.props.fieldMappings.fareFields || []).indexOf(item.summary) > -1
    const isVND = this.state.queryBody.criteria.currency === 'VND'

    if (item.negativable) {
      if (parseFloat(summaryValue) < 0) {
        summaryValue = `(${ReportUtils.numberFormat(
          -parseFloat(summaryValue),
          isVND ? 0 : undefined,
          isVND ? 0 : undefined
        )})` // `
      } else {
        summaryValue = ReportUtils.numberFormat(
          summaryValue,
          isVND ? 0 : undefined,
          isVND ? 0 : undefined
        )
      }
    } else if (floatFields.indexOf(item.summary) > -1) {
      summaryValue = summaryValue || 0
      if (isFareField && isVND) {
        summaryValue = ReportUtils.numberFormat(summaryValue, 0, 0)
      } else {
        summaryValue = ReportUtils.numberFormat(summaryValue)
      }
    }

    let summaryUnit = item.summaryUnit || ''

    if (summaryUnit === 'requestCurrency') {
      summaryUnit = this.state.queryBody.criteria.currency
    } else if (summaryObj[summaryUnit]) {
      summaryUnit = summaryObj[summaryUnit]
    }

    if (item.summaryUnitWithLocale) {
      summaryUnit = I18n.t(summaryUnit)
    }

    let showValue = `${summaryValue} ${summaryUnit}` // `

    if (item.summaryText) {
      const summaryText = I18n.t(item.summaryText)
      showValue = `${summaryText} ${showValue}` // `
    }
    const originValue = showValue
    showValue = (
      <OverlayTrigger
        overlay={<Tooltip id="tooltip">{originValue}</Tooltip>}
        placement={'top'}
        // delayShow={defaulTooltip ? "auto" : 300}
        // delayHide={defaulTooltip ? "auto" : 150}
        delayShow={300}
        delayHide={150}
      >
        {<div>{originValue}</div>}
      </OverlayTrigger>
    )

    const contentClass = `report-table-column-content text-ellipsis` // `
    return <div className={contentClass}>{showValue}</div>
    //return showValue;
  }

  renderStickyTable = () => {
    if (!this.state.isSearched) return undefined
    if (this.state.errorCode) {
      return (
        <div className="no-item-was-found">
          <Translate
            value={`report.error.${this.state.errorCode}`}
            {...((this.state.error || {}).data || {})}
          />
        </div>
      )
    }
    if (this.state.total === 0) {
      return (
        <div className="no-item-was-found">
          <Translate value="report.result.noResultFound" />
        </div>
      )
    }

    const floatFields = (this.props.fieldMappings.fareFields || []).concat(
      this.props.fieldMappings.floatFields || []
    )

    const { tableColumns, isColumnGroup } = this.renderTableHeader()
    _.forEach(tableColumns, (col, colIndex) => {
      if (col.subColumns) {
        if (!col.subColumns.length) {
          delete col.subColumns
          col.customCell = (doc) => <span />
          col.width = col.width || (col.isCheckBox ? 70 : 200)
        } else {
          _.forEach(col.subColumns, (subCol) => {
            subCol.width = subCol.width || (subCol.isCheckBox ? 70 : 200)
            if (subCol.textEllipsis === undefined) {
              subCol.textEllipsis = !subCol.isCheckBox && !subCol.actions
            }
            subCol.customCell = (doc, rowIndex) =>
              this.getCustomCell(subCol, doc, rowIndex, floatFields)
          })
        }
      } else {
        col.width = col.width || (col.isCheckBox ? 70 : 200)
        if (col.textEllipsis === undefined) {
          col.textEllipsis = !col.isCheckBox && !col.actions
        }
        col.customCell = (doc, rowIndex) =>
          this.getCustomCell(col, doc, rowIndex, floatFields)
      }

      if (colIndex === 0) {
        if (col.subColumns) {
          col.subColumns[0].cellClass = 'cell-align-toolbar'
          col.subColumns[0].headerClass = 'header-align-toolbar'
        } else {
          col.cellClass = 'cell-align-toolbar'
          col.headerClass = 'header-align-toolbar'
        }
      }
    })

    if (!this.props.nosummary) {
      if (!this.props.multiLineSummary) {
        _.forEach(tableColumns, (col, index) => {
          if (col.subColumns) {
            _.forEach(col.subColumns, (subCol) => {
              subCol.width = subCol.width || (subCol.isCheckBox ? 70 : 200)
              subCol.columnFooter = this.getColumnFooterValue(
                subCol,
                floatFields
              )
            })
          } else {
            col.width = col.width || (col.isCheckBox ? 70 : 200)
            col.columnFooter = this.getColumnFooterValue(col, floatFields)
          }
        })
      } else {
        const classNameSummaryCell = this.props.summaryMutilCurrencies
          ? 'report-column-footer-container mutil-lines'
          : 'report-column-footer-container'
        const summaryArray = this.state.summary[this.props.multiLineSummary]
        _.forEach(tableColumns, (col, index) => {
          if (col.subColumns) {
            _.forEach(col.subColumns, (subCol) => {
              subCol.width = subCol.width || (subCol.isCheckBox ? 70 : 200)
              subCol.columnFooter = (
                <div className={classNameSummaryCell}>
                  {_.map(summaryArray, (summaryLineObject, index) => (
                    <div key={index}>
                      {this.getColumnFooterValue(
                        subCol,
                        floatFields,
                        summaryLineObject
                      )}
                    </div>
                  ))}
                </div>
              )
            })
          } else {
            col.width = col.width || (col.isCheckBox ? 70 : 200)
            col.columnFooter = (
              <div className={classNameSummaryCell}>
                {_.map(summaryArray, (summaryLineObject, index) => (
                  <div key={index}>
                    {this.getColumnFooterValue(
                      col,
                      floatFields,
                      summaryLineObject
                    )}
                  </div>
                ))}
              </div>
            )
          }
        })
      }
    }

    return (
      <StickyTable
        bodyData={this.state.list}
        columns={tableColumns}
        groupHeaderHeight={isColumnGroup ? 40 : 0}
        footerHeight={
          this.props.nosummary
            ? 0
            : this.props.footerHeight
            ? this.props.footerHeight
            : this.props.multiLineSummary
            ? this.state.summary[this.props.multiLineSummary].length * 30
            : this.props.rowHeight || 40
        }
        rowHeight={this.props.rowHeight || 40}
        headerHeight={isColumnGroup ? 50 : this.props.headerHeight || 50}
        footerData={this.state.footerData}
        handleNumItemsPerPageChange={this.handleChangeItemsPerPage}
        handlePaginationSelect={this.handlePagesRange}
        getTableHeight={this.getTableHeight}
        descreaseWidth={window.innerHeight < 850 ? 10 : 0}
      />
    )
  }

  getDriverDropdownTitle = () => {
    const { driverId } = this.state.queryBody.criteria
    const { driverQuery } = this.state
    let title = ''

    if (!driverId || driverId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title =
        _.get(
          _.find(this.state.driverQuery.list, (item) => item.id === driverId),
          'value'
        ) || _.get(driverQuery, 'selectedObj.fullName')
    }

    return (
      <Translate
        value="report.query.driver"
        className="dropdown-title"
        name={title}
      />
    )
  }
  getDriverReferrerDropdownTitle = () => {
    const { driverReferrerId } = this.state.queryBody.criteria
    const { driverQuery } = this.state
    let title = ''

    if (!driverReferrerId || driverReferrerId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title =
        _.get(
          _.find(
            this.state.driverQuery.list,
            (item) => item.id === driverReferrerId
          ),
          'value'
        ) || _.get(driverQuery, 'selectedObj.fullName')
    }

    return (
      <Translate
        value="report.query.referer"
        className="dropdown-title"
        name={title}
      />
    )
  }
  getDriverRefereeDropdownTitle = () => {
    const { refereeId } = this.state.queryBody.criteria
    const { driverQuery } = this.state
    let title = ''

    if (!refereeId || refereeId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title =
        _.get(
          _.find(this.state.driverQuery.list, (item) => item.id === refereeId),
          'value'
        ) || _.get(driverQuery, 'selectedObj.fullName')
    }

    return (
      <Translate
        value="report.query.referee"
        className="dropdown-title"
        name={title}
      />
    )
  }
  getCustomerDropdownTitle = () => {
    const { userId } = this.state.queryBody.criteria
    let title = ''

    if (!userId || userId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title = _.get(this.state.customerQuery.selectedObj, 'value')
    }
    return (
      <Translate
        value="report.query.customer"
        className="dropdown-title"
        name={title}
      />
    )
  }
  getReferralCodeDropdownTitle = () => {
    const { referralCode } = this.state.queryBody.criteria
    let title = ''

    if (!referralCode || referralCode === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title = _.get(this.state.referralCodeQuery.selectedObj, 'value')
    }
    return (
      <Translate
        value="report.query.referralCode"
        className="dropdown-title"
        name={title}
      />
    )
  }
  getEventDropdownTitle = () => {
    const { eventId } = this.state.queryBody.criteria
    let title = ''

    if (!eventId || eventId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title = _.get(this.state.eventQuery.selectedObj, 'name')
    }
    return (
      <Translate
        value="report.query.event"
        className="dropdown-title"
        name={title}
      />
    )
  }
  getStoreDropdownTitle = () => {
    const { merchantId } = this.state.queryBody.criteria
    let title = ''

    if (!merchantId || merchantId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title = _.get(
        _.find(this.state.storeQuery.list, (item) => item.id === merchantId),
        'name'
      )
    }
    return (
      <Translate
        value={
          _.get(this.props, 'storeMerchant.selectedLabel') ||
          'report.query.store'
        }
        className="dropdown-title"
        name={title}
      />
    )
  }
  getRefererDropdownTitle = () => {
    const { refererId } = this.state.queryBody.criteria
    let title = ''

    if (!refererId || refererId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title =
        _.get(
          _.find(
            this.state.customerQuery.list,
            (item) => item.userId === refererId
          ),
          'value'
        ) || _.get(this.state.customerQuery, 'selectedObj.fullName')
    }
    return (
      <Translate
        value="report.query.referer"
        className="dropdown-title"
        name={title}
      />
    )
  }

  getPromoCodeDropdownTitle = () => {
    const { promoCodeId } = this.state.queryBody.criteria
    let title = ''

    if (!promoCodeId || promoCodeId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title = _.get(
        _.find(
          this.state.promocodeQuery.list,
          (item) => item.id === promoCodeId
        ),
        'value'
      )
    }
    return (
      <Translate
        value="report.query.usedPromoCode"
        className="dropdown-title"
        name={title}
      />
    )
  }

  customPromotionLabel = (code) => {
    const { timezone } = this.state
    return (
      <div>
        <div>{`${code.value} (${code.campaignName})`}</div>
        <div className="text-more-info">
          <span>
            {moment(code.fromDate).tz(timezone).format('MMM DD, YYYY')}
          </span>
          <span> - </span>
          <span>{moment(code.toDate).tz(timezone).format('MMM DD, YYYY')}</span>
        </div>
      </div>
    )
  }

  getVoucherCodeDropdownTitle = () => {
    const { redeemCodeId } = this.state.queryBody.criteria
    let title = ''
    if (!redeemCodeId || redeemCodeId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title = _.get(this.state.voucherCodeQuery.selectedObj, 'value')
    }
    return (
      <Translate
        value="report.query.voucherCode"
        className="dropdown-title"
        name={title}
      />
    )
  }
  getCampaignDropdownTitle = () => {
    const { campaignId } = this.state.queryBody.criteria
    const { campaignQuery } = this.state
    let title = ''
    if (!campaignId || campaignId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title =
        _.get(
          _.find(campaignQuery.list, (item) => item.value === campaignId),
          'label'
        ) || _.get(campaignQuery, 'selectedObj.name')
    }
    return (
      <Translate
        value="report.query.campaign_report"
        className="dropdown-title"
        name={title}
      />
    )
  }
  getIntercityRouteDropdownTitle = () => {
    const { selectedIntercityRoute } = this.state.queryBody.criteria
    let title = ''
    if (!selectedIntercityRoute || selectedIntercityRoute === 'all') {
      title =
        I18n.t('report.query.intercityRoute') +
        ': ' +
        I18n.t('report.query.common.all')
    } else {
      var uniqueId = selectedIntercityRoute.slice(0, -2)
      var uniqueRoute = parseInt(selectedIntercityRoute.slice(-1))
      this.state.intercityRouteQuery.selectedObj = _.find(
        this.props.intercityRoutes,
        (item) => {
          if (item._id === uniqueId && item.routeNumber === uniqueRoute) {
            var unique = true
          } else {
            var unique = false
          }
          return unique
        }
      )
      title =
        I18n.t('report.query.intercityRoute') +
        ': ' +
        _.get(
          this.state.intercityRouteQuery.selectedObj,
          'firstLocation.name'
        ) +
        ' - ' +
        _.get(this.state.intercityRouteQuery.selectedObj, 'secondLocation.name')
    }
    return title
  }

  getCompanyDropdownTitle = () => {
    const { companyId } = this.state.queryBody.criteria
    const { companyQuery } = this.state
    let title = ''
    if (!companyId || companyId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title =
        _.get(
          _.find(companyQuery.list, (item) => item.value === companyId),
          'label'
        ) || _.get(companyQuery, 'selectedObj.name')
    }
    return (
      <Translate
        value="report.query.company_report"
        className="dropdown-title"
        name={title}
      />
    )
  }

  getFilterTemplate = () => {
    const { selectedFleet, user, tittle } = this.props
    this.props.commonDataActions
      .getFilterTemplate({
        fleetId: selectedFleet.fleetId,
        type: tittle + 'Report',
        userId: user._id,
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.setState({
            filterTemplates: data.res,
          })
        }
      })
  }

  onClickFilterTemplate = (id) => {
    const { filterTemplates, queryBody } = this.state
    let query = Object.assign({}, queryBody)
    if (id) {
      let filter = filterTemplates.find((e) => e._id === id)
      if (
        filter.criteria.selectedButton &&
        filter.criteria.selectedButton !== 'custom'
      ) {
        const dateConvert = convertDateRangeString(
          filter.criteria.selectedButton
        )
        filter.criteria.fromDate = dateConvert.fromDate
        filter.criteria.toDate = dateConvert.toDate
      }

      for (var attrname in filter.criteria) {
        query.criteria[attrname] = filter.criteria[attrname]
      }
      query.from = 0
      query.size = 20
      this.setState({
        queryBody: query,
        filterTemplateId: id,
      })
    } else {
      this.setState(
        {
          filterTemplateId: '',
        },
        () => {
          this.handleClearFilters()
        }
      )
    }
  }

  handleClearFilters = () => {
    const { dateRange, selectedFleet } = this.props
    let defaultDateRange =
      Array.isArray(dateRange) && dateRange.find((item) => item.default)
    defaultDateRange = (defaultDateRange && defaultDateRange.value) || 'today'
    const viewMode =
      viewModes.indexOf(defaultDateRange) >= 0 ? defaultDateRange : null
    const disableDatetimeSelection =
      defaultDateRange.startsWith('past') && defaultDateRange.endsWith('Days')
        ? true
        : false
    let paymentMethod = Array.isArray(this.props.paymentMethod)
      ? this.props.paymentMethod
      : !_.get(this.props.selectedFleet, 'delivery.enable', false)
      ? commonData.bookingDetailsPaymentMethods.filter(
          (item) =>
            item.value !== 'cashBySender' && item.value !== 'cashByRecipient'
        )
      : !_.get(this.props.selectedFleet, 'zainCashWalletConfig.isActive', false)
      ? commonData.bookingDetailsPaymentMethods.filter(
          (item) => item.value !== 'ZainCash'
        )
      : !_.get(this.props.selectedFleet, 'ksherWalletConfig.isActive', false)
      ? commonData.bookingDetailsPaymentMethods.filter(
          (item) => item.value !== 'Ksher'
        )
      : !_.get(this.props.selectedFleet, 'xenditWalletConfig.isActive', false)
      ? commonData.bookingDetailsPaymentMethods.filter(
          (item) => item.value !== 'Xendit'
        )
      : !_.get(this.props.selectedFleet, 'gCashWalletConfig.isActive', false)
      ? commonData.bookingDetailsPaymentMethods.filter(
          (item) => item.value !== 'GCash'
        )
      : !_.get(this.props.selectedFleet, 'telebirrWalletConfig.isActive', false)
      ? commonData.bookingDetailsPaymentMethods.filter(
          (item) => item.value !== 'Telebirr'
        )
      : commonData.bookingDetailsPaymentMethods

    const overrideBookingStatus = _.get(
      this.props.selectedFleet,
      'generalSetting.overrideBookingStatus',
      false
    )
    var finishedStatus = Array.isArray(this.props.finishedStatus)
      ? this.props.finishedStatus
      : commonData.finishedStatusList
    if (!overrideBookingStatus) {
      finishedStatus = finishedStatus.filter(
        (i) => i.value !== 'completedWithoutService'
      )
    }

    commonData.gateway_port_21.map((gateway) => {
      if (
        !_.get(
          this.props.selectedFleet,
          `${gateway}WalletConfig.isActive`,
          false
        )
      ) {
        paymentMethod = paymentMethod.filter((item) => item.value !== gateway)
      }
    })

    const bookingService = Array.isArray(this.props.bookingService)
      ? this.props.bookingService
      : commonData.bookingServices
    const payoutOption = Array.isArray(this.props.payoutOption)
      ? this.props.payoutOption
      : commonData.payoutOptions
    const bookingStatus = Array.isArray(this.props.bookingStatus)
      ? this.props.bookingStatus
      : commonData.affiliationBookingStatus
    const transactionTypes = Array.isArray(this.props.transactionType)
      ? this.props.transactionType
      : commonData.transactionTypes
    const incidentCanceledTypeList = Array.isArray(
      this.props.incidentCanceledTypes
    )
      ? this.props.incidentCanceledTypes
      : commonData.incidentCanceledTypes
    const zoneItems = (this.props.mapZone || []).filter(
      (o) => o?.display && o?.isActive
    )
    const defaultPickupZone = ['all'].concat(_.map(zoneItems, '_id'))
    const defaultCarTypeMulti = ['all'].concat(
      _.map(this.props.carTypeCommonData, 'vehicleType')
    )
    // booking type
    this.bookingTypes = commonData.bookingTypes
    this.adjustPrices = commonData.adjustPrices
    if (!_.get(selectedFleet, 'intercity.enable', false)) {
      this.bookingTypes = commonData.bookingTypes.filter(
        (item) => item.value !== 'intercity'
      )
    }
    if (!_.get(selectedFleet, 'delivery.enable', false)) {
      this.bookingTypes = commonData.bookingTypes.filter(
        (item) => item.value !== 'delivery'
      )
    }
    if (!_.get(selectedFleet, 'shuttle.enable', false)) {
      this.bookingTypes = commonData.bookingTypes.filter(
        (item) => item.value !== 'shuttle'
      )
    }
    let paymentStatus = Array.isArray(
      _.get(this.props, 'paymentStatus.options')
    )
      ? this.props.paymentStatus.options
      : commonData.bookingDetailsPaymentStatus

    let queryBody = {
      fleetId: selectedFleet.fleetId,
      from: 0,
      size: 20,
      criteria: {
        selectedButton: defaultDateRange,
        viewMode,
        disableDatetimeSelection,
        fromDate: moment().startOf('day').toISOString(),
        toDate: moment().toISOString(),
        timezone: selectedFleet.timezone,
        unitDistance: selectedFleet.unitDistance,
        month: moment().format('YYYY-MM'),
        year: moment().year(),
        paymentMethod: paymentMethod.map((item) => item.value),
        bookingStatus: bookingStatus.map((item) => item.value),
        paymentStatus: paymentStatus.map((item) => item.value),
        paymentStatusRefund: commonData.paymentStatusRefund.map(
          (item) => item.value
        ),
        bookFrom: this.state.bookFromList.map((item) => item.value),
        thirdPartyAction: this.state.thirdPartyActionList.map((item) => item.value),
        finishedBookingStatus: finishedStatus.map((item) => item.value),
        currency:
          (this.props.fleetCurrencies &&
            this.props.fleetCurrencies[0] &&
            this.props.fleetCurrencies[0].iso) ||
          'USD',
        bookingType: this.bookingTypes.map((item) => item.value),
        adjustPrice: commonData.adjustPrices.map((item) => item.value),
        driverId: 'all',
        vehicleId: 'all',
        companyId: 'all',
        corporateId: 'all',
        userId: 'all',
        farmType: 'all',
        campaignId: 'all',
        promoCodeId: 'all',
        redeemCodeId: 'all',
        selectedIntercityRoute: 'all',
        routes: [],
        shortTrip: 'all',
        ratingType: commonData.ratingTypes.map((item) => item.value),
        stars: commonData.stars.map((item) => item.value),
        vehicleRate: commonData.stars.map((item) => item.value).slice(),
        bookingService: bookingService[0].value,
        payoutOption:
          typeof selectedFleet.drvPayout !== 'undefined' &&
          selectedFleet.drvPayout.gateway === 'CIB'
            ? payoutOption[0].value
            : payoutOption[1].value,
        mDispatcherTypeId: 'all',
        mDispatcherId: 'all',
        canceledBy: 'all',
        incidentCanceledTypes: incidentCanceledTypeList.map(
          (item) => item.value
        ),
        action: 'all',
        status: 'all',
        referralStatus: 'all',
        module: 'all',
        driverType: 'all',
        withdrawStatus: 'all',
        userType: 'passenger',
        eventType: 'all',
        eventState: 'all',
        eventId: '',
        serviceType: commonData.serviceTypes.map((item) => item.value),
        transactionType: transactionTypes.map((item) => item.value),
        rideService: commonData.rideServices.map((item) => item.value),
        vehicleType: 'all',
        currentStatus: commonData.bookingDetailsPaymentStatus.map(
          (item) => item.value
        ),
        originalStatus: commonData.originalPaymentStatus.map(
          (item) => item.value
        ),
        pickUpZone: defaultPickupZone,
        carTypeMulti: defaultCarTypeMulti,
        expiredDate: moment().format('MM/DD/YYYY'),
        txtSearch: '',
        tip: [],
        refererId: 'all',
        refereeId: 'all',
        driverReferrerId: 'all',
        referralType: this.props.referralType,
        referralCode: 'all',
        minPayout: 0,
        holdAmount: 0,
        payoutId: '',
        payoutDate: '',
        jobType: 'all',
        merchantId: '',
      },
    }
    if (this.props.multiSelectionsFilters) {
      this.props.multiSelectionsFilters.forEach((filter) => {
        queryBody.criteria[filter.key] = filter.options.map((i) => i.value)
      })
    }
    this.setState({
      queryBody,
    })
  }

  openSaveFavoriteFilterModal = () => {
    this.setState({ showAddFavoriteFilterModal: true })
  }

  openSettingFavoriteFilterModal = () => {
    this.setState({ showSettingsFavoriteFilterModal: true })
  }

  handleChangeNameFavoriteFilter = (e) => {
    let value = e.target.value
    this.setState({ nameFilter: value })
  }

  handleSaveFavoriteFilter = () => {
    const { nameFilter, queryBody } = this.state
    const { selectedFleet, user, tittle } = this.props
    if (nameFilter === '') return
    let paramsRequest = Object.assign({}, queryBody)
    paramsRequest.name = nameFilter
    paramsRequest.fleetId = selectedFleet.fleetId
    paramsRequest.type = tittle + 'Report'
    paramsRequest.userId = user._id
    this.props.commonDataActions
      .addFilterTemplate(paramsRequest)
      .then((data) => {
        if (data.ok && data.res) {
          this.getFilterTemplate()
          this.handleModalClose()
        }
        if (data.error) {
          this.context.notification(
            'error',
            I18n.t(
              `errors.${
                data.error.errorCode ? data.error.errorCode : 'undefined'
              }`
            )
          )
        }
      })
  }

  handleDeleteFavoriteFilter = (id) => {
    const { selectedFleet, user } = this.props
    let paramsRequest = {
      fleetId: selectedFleet.fleetId,
      id,
      userId: user._id,
    }
    this.props.commonDataActions
      .deleteFilterTemplate(paramsRequest)
      .then((data) => {
        if (data.ok && data.res) {
          this.getFilterTemplate()
          this.handleModalClose()
        }
        if (data.error) {
          this.context.notification(
            'error',
            I18n.t(
              `errors.${
                data.error.errorCode ? data.error.errorCode : 'undefined'
              }`
            )
          )
        }
      })
  }

  handleModalClose = () => {
    this.setState({
      nameFilter: '',
      showAddFavoriteFilterModal: false,
      showSettingsFavoriteFilterModal: false,
    })
  }

  getPayoutTimeList = (params, loadMore = false, callback = null) => {
    const { fromDate, toDate } = this.state.queryBody.criteria
    this.props.commonDataActions
      .findPayoutTimes({
        ...params,
        query: {
          ...params.query,
          fleetId: this.props.selectedFleet.fleetId,
          startDate: fromDate,
          endDate: toDate,
        },
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          let listFiter = data.res.list
          if (listFiter.length > 0) {
            _.map(listFiter, (ls) => {
              ls.payoutTimeStr = ReportUtils.formatTime(
                ls.payoutTime,
                ls.time,
                {
                  formatStr: 'MMM DD, YYYY hh:mm:ss A',
                }
              )
            })
          }
          const newData = listFiter.map((item) => {
            item.value = item._id
            item.label = item.payoutTimeStr
            return item
          })
          let payoutTimeList = loadMore
            ? this.state.payoutTimeQuery.list.concat(newData)
            : newData
          this.setState({
            payoutTimeQuery: {
              ...this.state.payoutTimeQuery,
              ...data.res,
              hasMore: data.res.list.length === params.limit,
              list: payoutTimeList,
            },
            queryBody: {
              ...this.state.queryBody,
              criteria: {
                ...this.state.queryBody.criteria,
                payoutId:
                  payoutTimeList.length > 0 ? payoutTimeList[0]._id : '',
              },
            },
          })
        }
        if (callback) callback()
      })
  }

  handleSearchPayoutTime = (callback, forceUpdate = false) => {
    const { enablePayoutTime } = this.props
    const params = _.pick(this.state.payoutTimeQuery, ['page', 'limit'])
    if (forceUpdate) {
      params.payTo =
        enablePayoutTime && typeof enablePayoutTime === 'string'
          ? enablePayoutTime
          : ''
      params.page = 0
      this.getPayoutTimeList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }
  getCampaignList = (params, loadMore = false, callback = null) => {
    this.props.settingActions
      .getAllCampaign({
        ...params,
        fleetId: this.props.selectedFleet.fleetId,
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          let listFiter = data.res.list
          const newData = listFiter.map((item) => {
            item.value = item._id
            item.label = item.name
            return item
          })
          this.setState({
            campaignQuery: {
              ...this.state.campaignQuery,
              ...data.res,
              search: params.search || '',
              hasMore: data.res.list.length === params.limit,
              list: loadMore
                ? this.state.campaignQuery.list.concat(newData)
                : newData,
            },
          })
        }
        if (callback) callback()
      })
  }

  getEventList = (params, loadMore = false, callback = null) => {
    this.props.settingActions
      .findEventAll({
        ...params,
        fleetId: this.props.selectedFleet.fleetId,
      })
      .then((data) => {
        if (data.ok && data.res && data.res.data) {
          const newData = data.res.data.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            }
          })
          this.setState({
            eventQuery: {
              ...this.state.eventQuery,
              ...data.res,
              page: params.page || 1,
              q: params.q || '',
              hasMore: data.res.data.length === params.per_page,
              list: loadMore
                ? this.state.eventQuery.list.concat(newData)
                : newData,
            },
          })
        }
        if (callback) callback()
      })
  }
  getStoreList = (params, loadMore = false, callback = null) => {
    const companyId = _.get(this.props, 'user.roles.companyId', '');
    if (companyId) {
      params.companyId = companyId
    }
    this.props.merchantActions
      .getMerchantList({
        ...params,
        fleetId: this.props.selectedFleet.fleetId,
      })
      .then((data) => {
        if (data.ok && data.res && data.res.data) {
          const newData = data.res.data.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            }
          })
          this.setState({
            storeQuery: {
              ...this.state.storeQuery,
              ...data.res,
              page: params.page || 1,
              q: params.q || '',
              hasMore: data.res.data.length === params.per_page,
              list: loadMore
                ? this.state.storeQuery.list.concat(newData)
                : newData,
            },
          })
        }
        if (callback) callback()
      })
  }
  handleSearchCampaign = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.campaignQuery, ['search', 'page', 'limit'])
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0
      params.search = inputValue
      this.getCampaignList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }

  getCampaignList = (params, loadMore = false, callback = null) => {
    this.props.settingActions
      .getAllCampaign({
        ...params,
        fleetId: this.props.selectedFleet.fleetId,
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          let listFiter = data.res.list
          const newData = listFiter.map((item) => {
            item.value = item._id
            item.label = item.name
            return item
          })
          this.setState({
            campaignQuery: {
              ...this.state.campaignQuery,
              ...data.res,
              search: params.search || '',
              hasMore: data.res.list.length === params.limit,
              list: loadMore
                ? this.state.campaignQuery.list.concat(newData)
                : newData,
            },
          })
        }
        if (callback) callback()
      })
  }

  handleSearchCampaign = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.campaignQuery, ['search', 'page', 'limit'])
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0
      params.search = inputValue
      this.getCampaignList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }
  getCompanyList = (params, loadMore = false, callback = null) => {
    this.props.settingActions
      .getAllCompany({
        ...params,
        fleetId: this.props.selectedFleet.fleetId,
        from: 'report',
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          let listFiter = data.res.list
          const newData = listFiter.map((item) => {
            item.value = item._id
            item.label = item.name
            return item
          })
          this.setState({
            companyQuery: {
              ...this.state.companyQuery,
              ...data.res,
              search: params.search || '',
              hasMore: data.res.list.length === params.limit,
              list: loadMore
                ? this.state.companyQuery.list.concat(newData)
                : newData,
            },
          })
        }
        if (callback) callback()
      })
  }

  handleSearchCompany = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.companyQuery, ['search', 'page', 'limit'])
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0
      params.search = inputValue
      this.getCompanyList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }

  getDriverList = (params, loadMore = false, callback = null) => {
    const { queryBody } = this.state
    const { tittle } = this.props
    let companyId = queryBody.criteria.companyId || 'all'
    let payoutOption =
      this.props.tittle === 'Pay to drivers' ||
      this.props.tittle === 'Payout history'
        ? queryBody.criteria.payoutOption
        : 'all'
    let bodyRequest = {
      ...params,
      fleetId: this.props.selectedFleet.fleetId,
      companyId,
      payoutOption,
      minPayout:
        queryBody.criteria.minPayout === '' ? 0 : queryBody.criteria.minPayout,
      type: this.props.tittle === 'Pay to drivers' ? 'payout' : 'all',
      isActive: true,
    }

    if (this.props.gateway) {
      bodyRequest.gateway = this.props.gateway
    }

    if (tittle === 'Driver deposit' || tittle === 'Cash Wallet') {
      delete bodyRequest.isActive
    }
    this.props.commonDataActions.loadMoreDrivers(bodyRequest).then((data) => {
      if (data.ok && data.res && data.res.list) {
        let listFiter = data.res.list
        const newData = listFiter.map((item) => {
          if (item.drvId) {
            item.value = `${item.drvId} - ${item.fullName}` // `
          } else {
            item.value = item.fullName
          }
          item.id = item.userId
          return item
        })
        this.setState({
          driverQuery: {
            ...this.state.driverQuery,
            ...data.res,
            search: params.search || '',
            hasMore: data.res.list.length === params.limit,
            list: loadMore
              ? this.state.driverQuery.list.concat(newData)
              : newData,
          },
        })
      }
      if (callback) callback()
    })
  }

  handleSearchDriver = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.driverQuery, ['search', 'page', 'limit'])
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0
      params.search = inputValue
      this.getDriverList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }
  handleSearchReferee = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.refereeQuery, ['search', 'page', 'limit'])
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0
      params.search = inputValue
      this.getDriverList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }
  getCustomerList = (params, loadMore = false, callback = null) => {
    this.props.customerAutocompleteActions
      .customerAutocomplete({
        ...params,
        fleetId: this.props.selectedFleet.fleetId,
        travelerType: 'individual',
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          const newData = data.res.list.map((item) => {
            let phone = item.phone === item.userId ? '' : item.phone // When customer login with social account, then phone number is blank
            return {
              ...item,
              value: `${item.firstName} ${item.lastName} - ${phone}`, // `
              id: item.userId,
            }
          })
          this.setState({
            customerQuery: {
              ...this.state.customerQuery,
              ...data.res,
              str: params.str || '',
              hasMore: data.res.list.length === params.limit,
              list: loadMore
                ? this.state.customerQuery.list.concat(newData)
                : newData,
            },
          })
        }
        if (callback) callback()
      })
  }

  handleSearchCustomer = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.customerQuery, ['str', 'page', 'limit'])
    if (inputValue !== params.str || forceUpdate) {
      params.page = 0
      const escapedValue = this.escapeRegexCharacters(inputValue.trim())
      params.str = escapedValue
      this.getCustomerList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }

  getReferralCodeList = (params, loadMore = false, callback = null) => {
    this.props.settingActions
      .referralCodeAutocomplete({
        ...params,
        fleetId: this.props.selectedFleet.fleetId,
        type: this.props.referralType,
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          const newData = data.res.list.map((item) => {
            return {
              ...item,
              value: item.referralCode,
              id: item.referralCodeId,
            }
          })
          this.setState({
            referralCodeQuery: {
              ...this.state.referralCodeQuery,
              ...data.res,
              page: params.page || 0,
              str: params.str || '',
              hasMore: data.res.list.length === params.limit,
              list: loadMore
                ? this.state.referralCodeQuery.list.concat(newData)
                : newData,
            },
          })
        }
        if (callback) callback()
      })
  }

  handleSearchReferralCode = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.referralCodeQuery, [
      'str',
      'page',
      'limit',
    ])
    if (inputValue !== params.str || forceUpdate) {
      params.page = 0
      const escapedValue = this.escapeRegexCharacters(inputValue.trim())
      params.str = escapedValue
      this.getReferralCodeList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }
  handleSearchEvent = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.eventQuery, ['q', 'page', 'per_page'])
    if (inputValue !== params.q || forceUpdate) {
      params.page = 1
      const escapedValue = this.escapeRegexCharacters(inputValue.trim())
      params.q = escapedValue
      if (this.state.queryBody.criteria.eventType !== 'all') {
        if (
          this.state.queryBody.criteria.eventType ===
          'first_will_win_lucky_draw'
        ) {
          params.type = 'first_will_win'
          params.isLuckyDraw = true
        } else {
          params.type = this.state.queryBody.criteria.eventType
          params.isLuckyDraw = false
        }
      }
      params.userType = this.state.queryBody.criteria.userType
      this.getEventList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }
  handleSearchStore = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.storeQuery, ['q', 'page', 'per_page'])
    if (inputValue !== params.q || forceUpdate) {
      params.page = 1
      const escapedValue = this.escapeRegexCharacters(inputValue.trim())
      params.q = escapedValue
      if (
        this.state.queryBody.criteria.jobType &&
        this.state.queryBody.criteria.jobType !== 'all'
      ) {
        params.merchantType = this.state.queryBody.criteria.jobType
      }
      if (
        this.state.queryBody.criteria.merchantType &&
        this.state.queryBody.criteria.merchantType !== 'all'
      ) {
        if (this.state.queryBody.criteria.merchantType === 'preferred') {
          params.isPreferred = true
        } else {
          params.isPreferred = false
        }
      }
      this.getStoreList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }
  getPromocodeList = (params, loadMore = false, callback = null) => {
    this.props.settingActions
      .fetchPromoCodesForReport({
        ...params,
        fleetId: this.props.selectedFleet.fleetId,
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          const newData = data.res.list.map((item) => {
            return {
              ...item,
              value: item.promoCode,
              id: item.promoCodeId,
            }
          })
          this.setState({
            promocodeQuery: {
              ...this.state.promocodeQuery,
              ...data.res,
              str: params.str || '',
              hasMore: data.res.list.length === params.limit,
              list: loadMore
                ? this.state.promocodeQuery.list.concat(newData)
                : newData,
            },
          })
        }
        if (callback) callback()
      })
  }

  handleSearchPromocode = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.promocodeQuery, ['str', 'page', 'limit'])
    if (inputValue !== params.str || forceUpdate) {
      params.page = 0
      const escapedValue = this.escapeRegexCharacters(inputValue.trim())
      params.str = escapedValue
      this.getPromocodeList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }
  getVoucherCodeList = (params, loadMore = false, callback = null) => {
    const { queryBody } = this.state
    let campaignId = queryBody.criteria.campaignId || 'all'
    this.props.settingActions
      .fetchVoucherCodesForReport({
        ...params,
        campaignId,
        fleetId: this.props.selectedFleet.fleetId,
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          const newData = data.res.list.map((item) => {
            return {
              ...item,
              value: item.voucherCode,
              id: item.redeemCodeId,
            }
          })
          this.setState({
            voucherCodeQuery: {
              ...this.state.voucherCodeQuery,
              ...data.res,
              str: params.str || '',
              hasMore: data.res.list.length === params.limit,
              list: loadMore
                ? this.state.voucherCodeQuery.list.concat(newData)
                : newData,
            },
          })
        }
        if (callback) callback()
      })
  }
  handleSearchVoucherCode = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.voucherCodeQuery, ['str', 'page', 'limit'])
    if (inputValue !== params.str || forceUpdate) {
      params.page = 0
      const escapedValue = this.escapeRegexCharacters(inputValue.trim())
      params.str = escapedValue
      this.getVoucherCodeList(params, false, callback)
    } else {
      return callback ? callback() : null
    }
  }
  escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  handleLoadMorePayoutTimeOptions = () => {
    const params = _.pick(this.state.payoutTimeQuery, ['page', 'limit'])
    params.page++
    if (!this.state.payoutTimeQuery.hasMore) return
    this.getPayoutTimeList(params, 'loadMore')
  }

  handleLoadMoreCompanyOptions = () => {
    const params = _.pick(this.state.companyQuery, ['search', 'page', 'limit'])
    params.page++
    if (!this.state.companyQuery.hasMore) return
    this.getCompanyList(params, 'loadMore')
  }
  handleLoadMoreCampaignOptions = () => {
    const params = _.pick(this.state.campaignQuery, ['search', 'page', 'limit'])
    params.page++
    if (!this.state.campaignQuery.hasMore) return
    this.getCampaignList(params, 'loadMore')
  }
  handleLoadMoreDriverOptions = () => {
    const params = _.pick(this.state.driverQuery, ['search', 'page', 'limit'])
    params.page++

    if (!this.state.driverQuery.hasMore) return

    this.getDriverList(params, 'loadMore')
  }

  handleLoadMoreCustomerOptions = () => {
    const params = _.pick(this.state.customerQuery, ['str', 'page', 'limit'])
    params.page++

    if (!this.state.customerQuery.hasMore) return

    this.getCustomerList(params, 'loadMore')
  }

  handleLoadMoreReferralCodeOptions = () => {
    const params = _.pick(this.state.referralCodeQuery, [
      'str',
      'page',
      'limit',
    ])
    params.page++

    if (!this.state.referralCodeQuery.hasMore) return

    this.getReferralCodeList(params, 'loadMore')
  }
  handleLoadMoreEventOptions = () => {
    const params = _.pick(this.state.eventQuery, ['q', 'page', 'per_page'])
    params.page++

    if (!this.state.eventQuery.hasMore) return
    if (this.state.queryBody.criteria.eventType !== 'all') {
      if (
        this.state.queryBody.criteria.eventType === 'first_will_win_lucky_draw'
      ) {
        params.type = 'first_will_win'
        params.isLuckyDraw = true
      } else {
        params.type = this.state.queryBody.criteria.eventType
        params.isLuckyDraw = false
      }
    }
    params.userType = this.state.queryBody.criteria.userType
    this.getEventList(params, 'loadMore')
  }
  handleLoadMoreStoreOptions = () => {
    const params = _.pick(this.state.storeQuery, ['q', 'page', 'per_page'])
    params.page++
    if (
      this.state.queryBody.criteria.jobType &&
      this.state.queryBody.criteria.jobType !== 'all'
    ) {
      params.merchantType = this.state.queryBody.criteria.jobType
    }
    if (
      this.state.queryBody.criteria.merchantType &&
      this.state.queryBody.criteria.merchantType !== 'all'
    ) {
      if (this.state.queryBody.criteria.merchantType === 'preferred') {
        params.isPreferred = true
      } else {
        params.isPreferred = false
      }
    }
    if (!this.state.storeQuery.hasMore) return
    this.getStoreList(params, 'loadMore')
  }
  handleLoadMorePromocodeOptions = () => {
    const params = _.pick(this.state.promocodeQuery, ['str', 'page', 'limit'])
    params.page++

    if (!this.state.promocodeQuery.hasMore) return

    this.getPromocodeList(params, 'loadMore')
  }
  handleLoadMoreVoucherCodeOptions = () => {
    const params = _.pick(this.state.voucherCodeQuery, ['str', 'page', 'limit'])
    params.page++

    if (!this.state.voucherCodeQuery.hasMore) return

    this.getVoucherCodeList(params, 'loadMore')
  }
  handleSelectDriver = (driverId) => {
    const driverQuery = Object.assign({}, this.state.driverQuery)
    const newQueryBody = Object.assign({}, this.state.queryBody)
    newQueryBody.criteria.driverId = driverId || 'all'

    if (!driverId) {
      driverQuery.selectedObj = null
      this.handleSearchDriver('', null, true)
    } else {
      driverQuery.selectedObj = _.find(
        this.state.driverQuery.list,
        (item) => item.id === driverId
      )
    }

    this.setState({
      queryBody: newQueryBody,
      driverQuery,
    })
  }
  handleSelectDriverReferrer = (driverId) => {
    const driverQuery = Object.assign({}, this.state.driverQuery)
    const newQueryBody = Object.assign({}, this.state.queryBody)
    newQueryBody.criteria.driverReferrerId = driverId || 'all'
    newQueryBody.criteria.driverId = driverId || 'all'
    if (!driverId) {
      driverQuery.selectedObj = null
      this.handleSearchDriver('', null, true)
    } else {
      driverQuery.selectedObj = _.find(
        this.state.driverQuery.list,
        (item) => item.id === driverId
      )
    }

    this.setState({
      queryBody: newQueryBody,
      driverQuery,
    })
  }
  handleSelectDriverReferee = (driverId) => {
    const driverQuery = Object.assign({}, this.state.driverQuery)
    const newQueryBody = Object.assign({}, this.state.queryBody)
    newQueryBody.criteria.refereeId = driverId || 'all'

    if (!driverId) {
      driverQuery.selectedObj = null
      this.handleSearchDriver('', null, true)
    } else {
      driverQuery.selectedObj = _.find(
        this.state.driverQuery.list,
        (item) => item.id === driverId
      )
    }

    this.setState({
      queryBody: newQueryBody,
      driverQuery,
    })
  }
  handleSelectCustomer = (userId) => {
    const customerQuery = Object.assign({}, this.state.customerQuery)
    const newQueryBody = Object.assign({}, this.state.queryBody)
    newQueryBody.criteria.userId = userId || 'all'

    if (!userId) {
      customerQuery.selectedObj = null
      this.handleSearchCustomer('', null, true)
    } else {
      customerQuery.selectedObj = _.find(
        this.state.customerQuery.list,
        (item) => item.id === userId
      )
    }

    this.setState({
      queryBody: newQueryBody,
      customerQuery,
    })
  }

  handleSelectReferer = (userId) => {
    const customerQuery = Object.assign({}, this.state.customerQuery)
    const newQueryBody = Object.assign({}, this.state.queryBody)
    newQueryBody.criteria.refererId = userId || 'all'

    if (!userId) {
      customerQuery.selectedObj = null
      this.handleSearchCustomer('', null, true)
    } else {
      customerQuery.selectedObj = _.find(
        this.state.customerQuery.list,
        (item) => item.id === userId
      )
    }

    this.setState({
      queryBody: newQueryBody,
      customerQuery,
    })
  }

  handleSelectReferralCode = (referralCodeId) => {
    const referralCodeQuery = Object.assign({}, this.state.referralCodeQuery)
    const newQueryBody = Object.assign({}, this.state.queryBody)

    if (!referralCodeId) {
      referralCodeQuery.selectedObj = null
      this.handleSearchReferralCode('', null, true)
      newQueryBody.criteria.referralCode = 'all'
    } else {
      referralCodeQuery.selectedObj = _.find(
        this.state.referralCodeQuery.list,
        (item) => item.id === referralCodeId
      )
      newQueryBody.criteria.referralCode =
        referralCodeQuery.selectedObj.referralCode
    }

    this.setState({
      queryBody: newQueryBody,
      referralCodeQuery,
    })
  }
  handleSelectEvent = (eventId) => {
    const eventQuery = Object.assign({}, this.state.eventQuery)
    const newQueryBody = Object.assign({}, this.state.queryBody)

    if (!eventId) {
      eventQuery.selectedObj = null
      this.handleSearchEvent('', null, true)
      newQueryBody.criteria.eventId = 'all'
    } else {
      eventQuery.selectedObj = _.find(
        this.state.eventQuery.list,
        (item) => item.id === eventId
      )
      newQueryBody.criteria.eventId = eventQuery.selectedObj.value
    }

    this.setState({
      queryBody: newQueryBody,
      eventQuery,
    })
  }
  handleSelectStore = (merchantId) => {
    const storeQuery = Object.assign({}, this.state.storeQuery)
    const newQueryBody = Object.assign({}, this.state.queryBody)

    if (!merchantId) {
      storeQuery.selectedObj = null
      this.handleSearchStore('', null, true)
      newQueryBody.criteria.merchantId = 'all'
    } else {
      storeQuery.selectedObj = _.find(
        this.state.storeQuery.list,
        (item) => item.id === merchantId
      )
      newQueryBody.criteria.merchantId = storeQuery.selectedObj.value
    }

    this.setState({
      queryBody: newQueryBody,
      storeQuery,
    })
  }
  handleSelectPromocode = (promoCodeId) => {
    const promocodeQuery = Object.assign({}, this.state.promocodeQuery)
    const newQueryBody = Object.assign({}, this.state.queryBody)
    newQueryBody.criteria.promoCodeId = promoCodeId || 'all'

    if (!promoCodeId) {
      promocodeQuery.selectedObj = null
      newQueryBody.criteria.promotion = {}
      this.handleSearchPromocode('', null, true)
    } else {
      promocodeQuery.selectedObj = _.find(
        this.state.promocodeQuery.list,
        (item) => item.id === promoCodeId
      )
      newQueryBody.criteria.promotion = _.pick(promocodeQuery.selectedObj, [
        'promoCode',
        'promoCodeId',
        'fromDate',
        'toDate',
      ])
    }
    this.setState({
      queryBody: newQueryBody,
      promocodeQuery,
    })
  }

  handleSelectVoucherCode = (redeemCodeId) => {
    const voucherCodeQuery = Object.assign({}, this.state.voucherCodeQuery)
    const newQueryBody = Object.assign({}, this.state.queryBody)
    newQueryBody.criteria.redeemCodeId = redeemCodeId || 'all'
    if (!redeemCodeId) {
      voucherCodeQuery.selectedObj = null
      this.handleSearchVoucherCode('', null, true)
      newQueryBody.criteria.redeemCodeId = 'all'
    } else {
      voucherCodeQuery.selectedObj = _.find(
        this.state.voucherCodeQuery.list,
        (item) => item.id === redeemCodeId
      )
      newQueryBody.criteria.redeemCodeId =
        voucherCodeQuery.selectedObj.redeemCodeId
    }

    this.setState({
      queryBody: newQueryBody,
      voucherCodeQuery,
    })
  }
  // handleSelectIntercity
  handleSelectIntercity = (selectedIntercityRoute) => {
    const intercityRouteQuery = Object.assign(
      {},
      this.state.intercityRouteQuery
    )
    const newQueryBody = Object.assign({}, this.state.queryBody)
    newQueryBody.criteria.selectedIntercityRoute =
      selectedIntercityRoute || 'all'
    if (!selectedIntercityRoute) {
      intercityRouteQuery.selectedObj = null
      newQueryBody.criteria.routes = []
    } else {
      if (selectedIntercityRoute !== 'all') {
        var uniqueId = selectedIntercityRoute.slice(0, -2)
        var uniqueRoute = parseInt(selectedIntercityRoute.slice(-1))
        intercityRouteQuery.selectedObj = _.find(
          this.props.intercityRoutes,
          (item) => {
            if (item._id === uniqueId && item.routeNumber === uniqueRoute) {
              var unique = true
            } else {
              var unique = false
            }
            return unique
          }
        )

        if (
          _.keyBy(this.props.intercityRoutes, '_id')[uniqueId]._id !== uniqueId
        ) {
          newQueryBody.criteria.selectedIntercityRoute = 'all'
        } else {
          if (newQueryBody.criteria.routes[0]) {
            newQueryBody.criteria.routes = []
          }
          newQueryBody.criteria.routes = [
            ...newQueryBody.criteria.routes,
            { routeId: uniqueId, routeNumber: uniqueRoute },
          ]
        }
      }
    }
    this.setState({
      queryBody: newQueryBody,
      intercityRouteQuery,
    })
  }

  getTableHeight = () => {
    const toolbarMarginBottom = 10
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0
    const parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0
    const outerHeight = toolbarMarginBottom + toolbarheight
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0
    // if (isMobile() && this.state.tableHeight < 500) return 500;
    if (window.innerHeight < 850 && tableHeight < 600) tableHeight = 600
    this.state.tableHeight = tableHeight
    return tableHeight
  }

  // Load license plate
  getLicensePlateDropDownTitle = () => {
    const { vehicleId } = this.state.queryBody.criteria
    let title = ''

    if (!vehicleId || vehicleId === 'all') {
      title = I18n.t('report.query.common.all')
    } else {
      title = _.get(this.state.vehicleQuery.selectedObj, 'plateNumber')
    }

    return (
      <Translate
        value="report.query.carLicensePlate"
        className="dropdown-title"
        name={title}
      />
    )
  }

  getLicensePlateList = (params, loadMore = false, callback = null) => {
    this.props.commonDataActions
      .loadMoreVehicles({
        ...params,
        fleetId: this.props.selectedFleet.fleetId,
        companyId: this.state.queryBody.criteria.companyId,
        vehicleType: this.state.queryBody.criteria.vehicleType,
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          this.setState({
            vehicleQuery: {
              ...this.state.vehicleQuery,
              ...data.res,
              search: params.search || '',
              list: loadMore
                ? this.state.vehicleQuery.list.concat(data.res.list)
                : data.res.list,
            },
          })
        }
        if (callback) callback()
      })
  }

  handleSearchLicensePlate = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.vehicleQuery, ['search', 'page', 'limit'])
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0
      params.search = inputValue
      this.getLicensePlateList(params, false, callback)
    } else {
      return callback()
    }
  }

  handleLoadMoreLicensePlate = () => {
    const params = _.pick(this.state.vehicleQuery, ['search', 'page', 'limit'])
    params.page++
    if (params.page * params.limit >= this.state.vehicleQuery.total) return
    this.getLicensePlateList(params, 'loadMore')
  }

  handleSelectLicensePlate = (vehicleId) => {
    const vehicleQuery = Object.assign({}, this.state.vehicleQuery)
    const queryBody = Object.assign({}, this.state.queryBody)

    queryBody.criteria.vehicleId = vehicleId || 'all'

    if (!vehicleId) {
      vehicleQuery.selectedObj = null
      this.handleSearchLicensePlate('', null, true)
    } else {
      vehicleQuery.selectedObj = _.find(
        this.state.vehicleQuery.list,
        (item) => item._id === vehicleId
      )
    }

    this.setState({
      queryBody,
      vehicleQuery,
    })
  }

  getTimezoneTitle = () => {
    let title = I18n.t('report.query.timezone')
    const selectedTimezone = _.find(
      TimeZones,
      (zone) => zone.timezone === this.state.queryBody.criteria.timezone
    )
    if (selectedTimezone) title += `: ${selectedTimezone.text}` // `
    return title
  }

  render() {
    const waiveOffCommission =
      _.get(this.props.selectedFleet, 'waiveOffCommission.enable') || false
    const showDiffTimeZoneOnReports =
      _.get(this.props.selectedFleet, 'showDiffTimeZoneOnReports') || false
    const isHideFilterTimezone = this.props.isHideFilterTimezone || false
    const listCompany =
      (Array.isArray(this.props.company) && this.props.company) ||
      [...this.props.companies, ...this.props.suppliers]
    const commonDataCompany = []
      .concat(listCompany)
      .map((item) => _.mapKeys(item, mapCompanyKeys))
    const commonDataOperator = []
      .concat(this.props.operators)
      .map((item) => _.mapKeys(item, mapOperatorKeys))
    const commonDataCampaign = []
      .concat(this.props.campaign || [])
      .map((item) => _.mapKeys(item, mapCampaignKeys))
    const commonDataPromocode = []
      .concat(this.props.promocode || [])
      .map((item) => _.mapKeys(item, mapPromocodeKeys))
    const commonDataVoucherCode = []
      .concat(this.props.voucherCodes || [])
      .map((item) => _.mapKeys(item, mapVoucherCodeKeys))
    const commonDataMDispatcherType = []
      .concat(this.props.mDispatcherType || [])
      .map((item) => _.mapKeys(item, mapMDispatcherTypeKeys))
    const commonDataMDispatcher = []
      .concat(this.props.mDispatcher || [])
      .map((item) => _.mapKeys(item, mapMDispatcherKeys))
    const commonDataMapZone = []
      .concat(this.props.mapZone || [])
      .filter((obj) => obj?.isActive && obj?.display)
      .map((item) => _.mapKeys(item, mapPickupZoneKeys))
    const commonDataCarType = []
      .concat(this.props.carTypeCommonData || [])
      .map((item) => {
        return { label: item.vehicleType, value: item.vehicleType }
      })
    const commonDataIntercityRoute = []
      .concat(this.props.intercityRoutes || [])
      .map((item) => {
        return _.mapKeys(item, mapIntercityKeys)
      })
    const commonDataPayoutTime = []
      .concat(this.props.payoutTime || [])
      .map((item) => {
        return _.mapKeys(item, mapPayoutTimeKeys)
      })
    const companies = [].concat(commonDataCompany)

    const permitCompanyId = _.get(this.props.user, 'roles.companyId', null)
    if (!permitCompanyId) {
      companies.unshift({
        label: I18n.t('report.query.common.all'),
        value: 'all',
      })
    }

    const operators = [
      { label: I18n.t('report.query.common.all'), value: 'all' },
    ].concat(commonDataOperator)

    const campaigns = [
      { label: I18n.t('report.query.common.all'), value: 'all' },
    ].concat(commonDataCampaign)
    const merchantTypes = commonData.merchantType
    const merchantServices = commonData.merchantService
    const promocodes = [
      { label: I18n.t('report.query.common.all'), value: 'all' },
    ]
      .concat(commonDataPromocode)
      .filter((doc) => {
        const { campaignId } = this.state.queryBody.criteria
        if (!campaignId || campaignId.toLowerCase() === 'all') {
          return true
        }
        if (doc.value === 'all') return true
        return doc.campaignId === campaignId
      })
    const vouchercodes = [
      { label: I18n.t('report.query.common.all'), value: 'all' },
    ]
      .concat(commonDataVoucherCode)
      .filter((doc) => {
        const { campaignId } = this.state.queryBody.criteria
        if (!campaignId || campaignId.toLowerCase() === 'all') {
          return true
        }
        if (doc.value === 'all') return true
        return doc.campaignId === campaignId
      })

    const mDispatcherTypes = [
      { label: I18n.t('report.query.common.all'), value: 'all' },
    ].concat(commonDataMDispatcherType)

    const mDispatchers = [
      { label: I18n.t('report.query.common.all'), value: 'all' },
    ]
      .concat(commonDataMDispatcher)
      .filter((doc) => {
        const { mDispatcherTypeId } = this.state.queryBody.criteria
        if (!mDispatcherTypeId || mDispatcherTypeId.toLowerCase() === 'all') {
          return true
        }
        if (doc.value === 'all') return true
        return doc.mDispatcherTypeId === mDispatcherTypeId
      })

    const carTypes = Array.isArray(this.props.carType)
      ? this.props.carType
      : commonData.carTypes

    const currencies = this.props.currencies.map((item) => ({
      label: item.iso,
      value: item.iso,
    }))

    if (this.props.multiCurrencies) {
      currencies.unshift({ label: 'All', value: 'all' })
    }

    const yearNow = moment().year()
    const years = [yearNow - 1, yearNow, yearNow + 1].map((year) => ({
      value: year,
      label: `${year}`, // `
    }))

    const bookingServiceList = Array.isArray(this.props.bookingService)
      ? this.props.bookingService
      : commonData.bookingServices
    const payoutOptionList = Array.isArray(this.props.payoutOption)
      ? this.props.payoutOption
      : commonData.payoutOptions
    const actionList =
      this.props.tittle === 'Booking summary'
        ? commonData.bookingSummaryActions
        : commonData.actions

    var paymentMethodList = Array.isArray(this.props.paymentMethod)
      ? this.props.paymentMethod
      : commonData.bookingDetailsPaymentMethods
      if(!this.props.paymentMethod) {
        paymentMethodList = filterPaymentMethods(paymentMethodList, this.props.selectedFleet)
      }

    const bookingStatusList = Array.isArray(this.props.bookingStatus)
      ? this.props.bookingStatus
      : commonData.affiliationBookingStatus

    const transactionTypeList = Array.isArray(this.props.transactionType)
      ? this.props.transactionType
      : commonData.transactionTypes

    const incidentCanceledTypeList = Array.isArray(
      this.props.incidentCanceledTypes
    )
      ? this.props.incidentCanceledTypes
      : commonData.incidentCanceledTypes

    const zoneItems = [
      { label: I18n.t('report.query.common.all'), value: 'all' },
    ].concat(commonDataMapZone)

    const carTypeMultiItems = [
      { label: I18n.t('report.query.common.all'), value: 'all' },
    ].concat(commonDataCarType)

    const { language = 'en-US' } = this.props || {}
    const {
      paymentMethod,
      paymentStatus,
      paymentStatusRefund,
      bookingStatus,
      bookingService,
      bookingType,
      bookFrom,
      finishedBookingStatus,
      ratingType,
      stars,
      serviceType,
      transactionType,
      rideService,
      vehicleRate,
      viewMode,
      selectedButton,
      disableDatetimeSelection,
      payoutOption,
      adjustPrice,
      thirdPartyAction,
    } = this.state.queryBody.criteria

    const isDisabledViewButton =
      (this.props.paymentMethod &&
        paymentMethod &&
        paymentMethod.length === 0) ||
      (this.props.paymentStatus &&
        paymentStatus &&
        paymentStatus.length === 0) ||
      (this.props.paymentStatusRefund &&
        paymentStatusRefund &&
        paymentStatusRefund.length === 0) ||
      (this.props.bookingStatus &&
        bookingStatus &&
        bookingStatus.length === 0) ||
      (this.props.bookingService &&
        bookingService &&
        bookingService.length === 0) ||
      (this.props.payoutOption && payoutOption && payoutOption.length === 0) ||
      (this.props.bookingType && bookingType && bookingType.length === 0) ||
      (this.props.adjustPrice && adjustPrice && adjustPrice.length === 0) ||
      (this.props.bookFrom && bookFrom && bookFrom.length === 0) ||
      (this.props.thirdPartyAction && thirdPartyAction && thirdPartyAction.length === 0) ||
      (this.props.finishedStatus &&
        finishedBookingStatus &&
        finishedBookingStatus.length === 0) ||
      (this.props.ratingType && ratingType && ratingType.length === 0) ||
      (this.props.stars && stars && stars.length === 0) ||
      (this.props.serviceType && serviceType && serviceType.length === 0) ||
      ((this.props.transactionType || this.props.withdrawalTransactionType) &&
        transactionType &&
        transactionType.length === 0) ||
      (this.props.rideService && rideService && rideService.length === 0) ||
      this.state.showPercent ||
      this.state.minPayout ||
      (this.props.multiSelectionsFilters &&
        this.props.multiSelectionsFilters.some(
          (filter) =>
            (this.state.queryBody.criteria[filter.key] || []).length === 0
        ))

    let finishedStatusList = this.filterFinishedStatus(commonData.finishedStatusList)

    let filterColumnItems = []
    if (this.props.hasCustomShowColumns) {
      _.forEach(this.props.listDefaultColumn, (item) => {
        filterColumnItems.push({
          key: item.key,
          label: item.label,
          disableSelect: item.isAlwayShow,
        })
      })
    }
    const exportModal = (
      <Modal
        show={this.state.showInputModal}
        backdrop="static"
        bsSize="lg"
        dialogClassName="logs-modal send-receipt-modal"
        onHide={() =>
          this.setState({
            showInputModal: false,
            numberRecord: '',
            isSubmited: false,
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t('Sidebar.number_of_records_to_export')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup
            className={`mb-lg ${
              !this.state.isSubmited
                ? null
                : this.state.valid.numberRecord
                ? null
                : 'error'
            }`}
          >
            <FormControl
              type="text"
              value={this.state.numberRecord}
              onChange={this.handleChangeInput}
              className="form-custom"
            />
          </FormGroup>
          <Validator id="numberRecord" callback={this.ValidatorCallback}>
            <ValidCase
              hide={!this.state.isSubmited}
              valid={Validation.isIntegerCheck(this.state.numberRecord)}
              message={I18n.t('messages.commonMessages.must_be_integer').format(
                0
              )}
            />
            <ValidCase
              hide={!this.state.isSubmited}
              valid={Validation.isGreaterThan(this.state.numberRecord, 0)}
              message={I18n.t('messages.commonMessages.greater_than').format(0)}
            />
          </Validator>
          <div className="text-center">
            <Button
              className="btn-send mr-lg"
              disabled={!this.state.numberRecord}
              onClick={this.exportQuestReport}
            >
              <Translate value="Sidebar.export" />
            </Button>
            <Button
              className="btn-cancel"
              onClick={() => {
                this.setState({
                  showInputModal: false,
                  numberRecord: '',
                  isSubmited: false,
                })
              }}
            >
              <Translate value="report.result.bookingDetails.cancel" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )
    return (
      <div className="base-report-container">
        {this.props.modals}
        {exportModal}
        {
          this.state.showModalGetEmail &&
          <GetEmailExpModal 
            closeModal={() => this.setState({showModalGetEmail: false})}
            // handleResulEmailExport={(email) => this.exportReport(null, email)}
            handleResulEmailExport={this.exportReportHasEmail}
            user={this.props.user}
          />
        }
        {<ConversionModal {...this.state.conversionData} />}
        {
          <ConfirmModal
            confirm={this.state.confirm}
            handleConfirmCloseClick={this.handleConfirmCloseClick}
            handleConfirmButtonClick={this.handleConfirmButtonClick}
          />
        }

        <div
          className="report-query-builder"
          ref={(node) => (this.toobarContainer = node)}
        >
          <div className="top-filter-container header-button-group" xs={12}>
            <div className="group-left">
              {this.props.timeFilterType && (
                <CCDropDown
                  id="timeFilterType-dropdown"
                  items={[
                    {
                      label: 'Collected Time',
                      value: 'collectedTime',
                    },
                    {
                      label: 'Completed Time',
                      value: 'completedTime',
                    },
                  ]}
                  selectedItems={[this.state.queryBody.criteria.timeFilterType]}
                  className={'timeFilter'}
                  valueKey="value"
                  labelKey="label"
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'timeFilterType')
                  }
                  customTitle={
                    this.state.queryBody.criteria.timeFilterType ===
                    'completedTime'
                      ? I18n.t('report.query.search.completedTime')
                      : I18n.t('report.query.search.collectedTime')
                  }
                />
              )}
              {this.props.dateRange ? (
                <DateRange
                  selectedButton={selectedButton}
                  setDateRange={this.handleChangeDateRange}
                  customRangeSelectCallback={this.handleCustomDateSelect}
                  buttons={
                    Array.isArray(this.props.dateRange)
                      ? this.props.dateRange
                      : commonData.dateButtons
                  }
                />
              ) : null}
              {this.props.search ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="searchTooltip" className="search-tool-tip">
                      <Translate
                        value={
                          typeof this.state.queryBody.criteria.userType ===
                            'undefined' ||
                          this.state.queryBody.criteria.userType === 'passenger'
                            ? this.props.search
                            : 'report.query.search.firstWillWinDriver'
                        }
                      />
                    </Tooltip>
                  }
                >
                  <FormGroup className="search-format">
                    <FormControl
                      type="text"
                      value={this.state.queryBody.criteria.txtSearch}
                      onChange={this.handleChangeTextSearch}
                      onKeyPress={this.handlePressKeyTextSearch}
                      placeholder={
                        typeof this.state.queryBody.criteria.userType ===
                          'undefined' ||
                        this.state.queryBody.criteria.userType === 'passenger'
                          ? I18n.t(this.props.search)
                          : I18n.t('report.query.search.firstWillWinDriver')
                      }
                      className="search-form"
                    />
                    <BsSearch className="search-icon"/>
                  </FormGroup>
                </OverlayTrigger>
              ) : null}
              {this.props.dateRange ? (
                <React.Fragment>
                  {viewMode ? null : (
                    <FormGroup className="input">
                      <Datetime
                        isValidDate={this.validDateRange('fromDate').bind(this)}
                        timeFormat="hh:mm A"
                        dateFormat="YYYY-MM-DD"
                        value={moment(
                          this.state.queryBody.criteria.fromDate
                        ).locale(language)}
                        inputProps={{
                          ref: (input) => {
                            this.dateFromInput = input
                          },
                          placeholder: I18n.t('report.query.fromDate'),
                          disabled:
                            disableDatetimeSelection ||
                            viewMode === viewModes[0],
                          value: moment(this.state.queryBody.criteria.fromDate)
                            .locale(language)
                            .format('YYYY-MM-DD hh:mm A'),
                        }}
                        onChange={(e) =>
                          this.handleChangeDateRange({
                            selectedButton: 'custom',
                            fromDate: e._d,
                          })
                        }
                        closeOnSelect
                      />
                    </FormGroup>
                  )}
                  <FormGroup className="input">
                    <Datetime
                      isValidDate={this.validDateRange('toDate').bind(this)}
                      viewMode={viewMode === viewModes[1] ? 'months' : 'days'}
                      timeFormat={viewMode ? false : 'hh:mm A'}
                      dateFormat={
                        viewMode === viewModes[1] ? 'MM/YYYY' : 'YYYY-MM-DD'
                      }
                      value={moment(
                        this.state.queryBody.criteria.toDate
                      ).locale(language)}
                      inputProps={{
                        placeholder: I18n.t('report.query.toDate'),
                        disabled: disableDatetimeSelection,
                        value: this.getDateValue(language),
                      }}
                      onChange={(e) =>
                        this.handleChangeDateRange({
                          selectedButton: viewMode ? viewMode : 'custom',
                          toDate: e._d,
                        })
                      }
                      closeOnSelect
                    />
                  </FormGroup>
                </React.Fragment>
              ) : null}
              {this.props.month && (
                <MonthSelector
                  value={this.state.queryBody.criteria.month}
                  timezone={this.state.timezone}
                  handleChangeMonth={this.handleChangeMonth}
                  locale={this.props.language}
                />
              )}
              {this.props.minPayout && (
                <FormGroup
                  className={
                    !this.state.isSubmited
                      ? null
                      : this.state.valid.minPayout
                      ? null
                      : 'error'
                  }
                >
                  <FormGroup className="btn-group">
                    <Form.Label>
                      <Translate value="report.result.payoutToDriver.minPayout" />
                    </Form.Label>
                    <InputGroup className="single-addon-left">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          {this.state.queryBody.criteria.currency}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="text"
                        value={this.state.queryBody.criteria.minPayout}
                        onChange={this.handleChangePayout}
                        placeholder={I18n.t(
                          'report.result.payoutToDriver.enterMinimumPayoutAmount'
                        )}
                        className="form-custom"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  </FormGroup>
                  <Validator id="minPayout" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isIntegerCheck(
                        this.state.queryBody.criteria.minPayout
                      )}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      ).format(0)}
                    />
                  </Validator>
                </FormGroup>
              )}
              {this.props.minPayout && (
                <FormGroup
                  className={
                    !this.state.isSubmited
                      ? null
                      : this.state.valid.holdAmount
                      ? null
                      : 'error'
                  }
                >
                  <FormGroup className="btn-group">
                    <Form.Label>
                      <Translate value="report.result.payoutToDriver.holdAmount" />
                      {this.props.toolTipHoldAmount && (
                        <QuestionCircleTooltip
                          text={I18n.t(this.props.toolTipHoldAmount)}
                        />
                      )}
                    </Form.Label>
                    <InputGroup className="single-addon-left">
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          {this.state.queryBody.criteria.currency}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="text"
                        value={this.state.queryBody.criteria.holdAmount}
                        onChange={this.handleChangeHoldAmount}
                        placeholder={I18n.t(
                          'report.result.payoutToDriver.holdAmount'
                        )}
                        className="form-custom"
                      />
                    </InputGroup>
                  </FormGroup>
                  <Validator id="holdAmount" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isIntegerCheck(
                        this.state.queryBody.criteria.holdAmount
                      )}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      ).format(0)}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isGreaterOrEqual(
                        this.state.queryBody.criteria.minPayout,
                        this.state.queryBody.criteria.holdAmount
                      )}
                      message={I18n.t(
                        'messages.commonMessages.less_or_equal'
                      ).format(this.state.queryBody.criteria.minPayout)}
                    />
                  </Validator>
                </FormGroup>
              )}
              {this.props.customViewButton && (
                <Button
                  className="btn-save view-button"
                  onClick={this.handleClickView}
                  disabled={isDisabledViewButton}
                  style={
                    this.props.tittle === 'Pay to driver'
                      ? { marginTop: '2px' }
                      : { marginTop: '32px' }
                  }
                >
                  {this.props.loading ? (
                    'Loading...'
                  ) : (
                    <Translate value="report.query.view" />
                  )}
                </Button>
              )}
              {showDiffTimeZoneOnReports && !isHideFilterTimezone && (
                <Filter.Timezone
                  items={this.state.timezoneQuery.list}
                  selectedItems={[this.state.queryBody.criteria.timezone]}
                  onSelect={this.handleChangeTimezone}
                  onSearchChange={this.searchTimezoneDebounce}
                  onScrollBottom={this.handleLoadMoreTimeZone}
                  customTitle={this.getTimezoneTitle()}
                />
              )}
            </div>
            <div
              className="group-right"
              style={{
                marginTop: '27px',
                marginLeft: '-15px',
              }}
            >
              {this.props.tittle === 'Pay to driver' &&
              this.state.total !== 0 &&
              this.props.actions ? (
                <Button
                  className="btn-save"
                  onClick={this.props.actions.payToDrivers.bind(
                    this,
                    _.cloneDeep(this.state)
                  )}
                >
                  <Translate value="report.result.pay" />
                </Button>
              ) : null}
              {this.props.tittle === 'Pay to drivers' &&
              this.props.actions &&
              this.state.total !== 0 &&
              this.state.lastQuery &&
              this.state.lastQuery.criteria.payoutOption === 'hasBank' ? (
                <Button className="btn-reset" onClick={this.invalidBankAccount}>
                  <Translate value="report.result.invalidBankAccount" />
                </Button>
              ) : null}
              {this.props.tittle === 'Pay to drivers' &&
              this.props.actions &&
              this.state.total !== 0 &&
              this.state.lastQuery &&
              this.state.lastQuery.criteria.payoutOption === 'invalidBank' ? (
                <Button className="btn-reset" onClick={this.validBankAccount}>
                  <Translate value="report.result.validBankAccount" />
                </Button>
              ) : null}
              {this.props.tittle === 'Pay to drivers' &&
              this.state.total !== 0 &&
              this.props.actions ? (
                <Button
                  className="btn-save"
                  onClick={this.payToDrivers}
                  disabled={
                    this.state.lastQuery &&
                    this.state.lastQuery.criteria.payoutOption !== 'hasBank' &&
                    typeof this.props.selectedFleet.drvPayout !== 'undefined' &&
                    this.props.selectedFleet.drvPayout.gateway !== 'CIB'
                  }
                >
                  <Translate value="report.result.paySelected" />
                </Button>
              ) : null}
              {this.props.tittle === 'Pay to drivers' &&
              this.state.total !== 0 &&
              this.props.actions ? (
                <Button
                  className="btn-save"
                  onClick={this.payToDriverAll}
                  disabled={
                    this.state.lastQuery &&
                    this.state.lastQuery.criteria.payoutOption !== 'hasBank' &&
                    typeof this.props.selectedFleet.drvPayout !== 'undefined' &&
                    this.props.selectedFleet.drvPayout.gateway !== 'CIB'
                  }
                >
                  <Translate value="report.result.payAll" />
                </Button>
              ) : null}

              {this.props.tittle === 'Pay to merchants' &&
              this.props.actions &&
              this.state.total !== 0 &&
              this.state.lastQuery &&
              this.state.lastQuery.criteria.payoutOption === 'hasBank' ? (
                <Button className="btn-reset" onClick={this.invalidBankAccount}>
                  <Translate value="report.result.invalidBankAccount" />
                </Button>
              ) : null}
              {this.props.tittle === 'Pay to merchants' &&
              this.props.actions &&
              this.state.total !== 0 &&
              this.state.lastQuery &&
              this.state.lastQuery.criteria.payoutOption === 'invalidBank' ? (
                <Button className="btn-reset" onClick={this.validBankAccount}>
                  <Translate value="report.result.validBankAccount" />
                </Button>
              ) : null}
              {this.props.tittle === 'Pay to merchants' &&
              this.state.total !== 0 &&
              this.props.actions ? (
                <Button
                  className="btn-save"
                  onClick={this.payToMerchants}
                  disabled={
                    this.state.lastQuery &&
                    this.state.lastQuery.criteria.payoutOption !== 'hasBank'
                  }
                >
                  <Translate value="report.result.paySelected" />
                </Button>
              ) : null}
              {this.props.tittle === 'Pay to merchants' &&
              this.state.total !== 0 &&
              this.props.actions ? (
                <Button
                  className="btn-save"
                  onClick={this.payToAllMerchant}
                  disabled={
                    this.state.lastQuery &&
                    this.state.lastQuery.criteria.payoutOption !== 'hasBank'
                  }
                >
                  <Translate value="report.result.payAll" />
                </Button>
              ) : null}

              {this.props.noexport || this.state.total === 0 ? null : (
                <div className="report-export-button-contianer">
                  {
                    this.state.isShowExportReport &&
                    <Button
                      className="btn-header text-add-header export-button"
                      onClick={this.exportReport}
                    >
                      <Translate value="report.result.exportToExcel" />
                  </Button>
                  }
                  {this.props.selectedFleet.drvPayout &&
                  this.props.selectedFleet.drvPayout.gateway !== 'DNB' &&
                  this.props.selectedFleet.drvPayout.gateway !== 'credit' &&
                  this.props.selectedFleet.drvPayout.gateway !== 'AnyBank' &&
                  this.props.selectedFleet.drvPayout.gateway !==
                    'IndiaDefault' &&
                  (this.props.tittle === 'Payout history' ||
                    this.props.tittle === 'Payout history merchants') ? (
                    <Button
                      className="btn-header text-add-header export-button"
                      onClick={this.exportReportCSV}
                      disabled={this.state.showPercent}
                      style={{ paddingLeft: '20px' }}
                    >
                      {typeof this.props.selectedFleet.drvPayout ===
                        'undefined' ||
                      this.props.selectedFleet.drvPayout.gateway ===
                        'RazerPay' ||
                      this.props.selectedFleet.drvPayout.gateway ===
                        'MOLPay' ? (
                        <Translate value="report.result.exportToCSV" />
                      ) : (
                        <Translate value="report.result.exportToPaymentFile" />
                      )}
                    </Button>
                  ) : null}
                </div>
              )}
            </div>
          </div>

          {this.props.favoriteFilter && this.state.filterTemplates.length > 0 && (
            <div className="header-button-group filter-group">
              <div className="group-left">
                <div className="filter-tags">
                  {this.state.filterTemplates.map((item) => {
                    return (
                      <span
                        key={item._id}
                        className={`filter-item ${
                          this.state.filterTemplateId === item._id
                            ? 'active'
                            : ''
                        }`}
                        onClick={(e) => this.onClickFilterTemplate(item._id)}
                      >
                        {item.name}
                      </span>
                    )
                  })}
                  {this.state.filterTemplateId !== '' && (
                    <span
                      className={'clear-filter'}
                      onClick={(e) => this.onClickFilterTemplate()}
                    >
                      {I18n.t('cue.clear_all_filters')}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="bottom-filter-container">
            <div className="filter-list">
              {this.props.affiliationPayoutType && (
                <Filter.AffiliationPayoutType
                  items={commonData.affiliationPayoutType}
                  selectedItems={[this.state.queryBody.criteria.payoutType]}
                  onSelect={this.handleChangeAffiliationPayoutType}
                />
              )}
              {this.props.pickUpZone && (
                <Filter.PickupZone
                  items={zoneItems}
                  selectedItems={this.state.queryBody.criteria.pickUpZone}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'pickUpZone')
                  }
                />
              )}
              {this.props.year && (
                <Filter.Year
                  items={years}
                  selectedItems={[this.state.queryBody.criteria.year]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'year')
                  }
                />
              )}
              {this.props.enablePayoutTime && (
                <Filter.PayoutTime
                  items={this.state.payoutTimeQuery.list}
                  selectedItems={
                    this.state.queryBody.criteria.payoutId
                      ? this.state.queryBody.criteria.payoutId
                      : ''
                  }
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'payoutId')
                  }
                  onScrollBottom={this.handleLoadMorePayoutTimeOptions}
                />
              )}
              {this.props.company && (
                <Filter.Company
                  // items={companies}
                  // selectedItems={[this.state.queryBody.criteria.companyId]}
                  items={this.state.companyQuery.list}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'companyId')
                  }
                  onSearchChange={this.searchCompanyDebounce}
                  onScrollBottom={this.handleLoadMoreCompanyOptions}
                  customTitle={this.getCompanyDropdownTitle()}
                  selectedItems={
                    !this.state.queryBody.criteria.companyId ||
                    this.state.queryBody.criteria.companyId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.companyId]
                  }
                />
              )}
              {this.props.settlementType && (
                <Filter.CompanySettlementType
                  items={commonData.companySettlementTypes}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'settlementType')
                  }
                  selectedItems={
                    !this.state.queryBody.criteria.settlementType ||
                    this.state.queryBody.criteria.settlementType === 'all'
                      ? ['all']
                      : [this.state.queryBody.criteria.settlementType]
                  }
                />
              )}
              {this.props.operator && (
                <Filter.Operator
                  items={operators}
                  selectedItems={[this.state.queryBody.criteria.userId]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'userId')
                  }
                />
              )}
              {this.props.airline && (
                <Filter.Airline
                  items={this.props.airline}
                  selectedItems={[this.state.queryBody.criteria.corporateId]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'corporateId')
                  }
                />
              )}
              {this.props.farmType && (
                <Filter.FarmType
                  items={commonData.farmTypes}
                  selectedItems={[this.state.queryBody.criteria.farmType]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'farmType')
                  }
                />
              )}
              {this.props.campaign && (
                <Filter.Campaign
                  items={this.state.campaignQuery.list}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'campaignId')
                  }
                  onSearchChange={this.searchCampaignDebounce}
                  onScrollBottom={this.handleLoadMoreCampaignOptions}
                  customTitle={this.getCampaignDropdownTitle()}
                  selectedItems={
                    !this.state.queryBody.criteria.campaignId ||
                    this.state.queryBody.criteria.campaignId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.campaignId]
                  }
                />
              )}
              {this.props.mDispatcherType && (
                <Filter.MDispatcherType
                  items={mDispatcherTypes}
                  selectedItems={[
                    this.state.queryBody.criteria.mDispatcherTypeId,
                  ]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'mDispatcherTypeId')
                  }
                />
              )}
              {this.props.mDispatcher && (
                <Filter.MDispatcher
                  items={mDispatchers}
                  selectedItems={[this.state.queryBody.criteria.mDispatcherId]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'mDispatcherId')
                  }
                />
              )}
              {this.props.carType && (
                <Filter.CarType
                  items={carTypes}
                  selectedItems={[this.state.queryBody.criteria.vehicleType]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'vehicleType')
                  }
                />
              )}
              {this.props.carTypeMultiple && (
                <Filter.CarTypeMultiple
                  items={carTypeMultiItems}
                  selectedItems={this.state.queryBody.criteria.carTypeMulti}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'carTypeMulti')
                  }
                />
              )}
              {this.props.plate && (
                <Filter.Plate
                  items={this.state.vehicleQuery.list}
                  onSelect={this.handleSelectLicensePlate}
                  selectedItems={
                    !this.state.queryBody.criteria.vehicleId ||
                    this.state.queryBody.criteria.vehicleId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.vehicleId]
                  }
                  onSearchChange={this.searchLicensePlateDebounce}
                  onScrollBottom={this.handleLoadMoreLicensePlate}
                  customTitle={this.getLicensePlateDropDownTitle()}
                />
              )}
              {this.props.driverType && (
                <Filter.DriverType
                  items={commonData.settlementDriverTypes}
                  selectedItems={[this.state.queryBody.criteria.driverType]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'driverType')
                  }
                />
              )}
              {this.props.payoutOption &&
                (typeof this.props.selectedFleet.drvPayout === 'undefined' ||
                  this.props.selectedFleet.drvPayout.gateway === 'RazerPay' ||
                  this.props.selectedFleet.drvPayout.gateway === 'MOLPay' ||
                  this.props.selectedFleet.drvPayout.gateway === 'DNB' ||
                  this.props.selectedFleet.drvPayout.gateway === 'credit' ||
                  this.props.selectedFleet.drvPayout.gateway === 'AnyBank' ||
                  this.props.selectedFleet.drvPayout.gateway ===
                    'IndiaDefault') && (
                  <Filter.PayoutOption
                    items={payoutOptionList}
                    selectedItems={[this.state.queryBody.criteria.payoutOption]}
                    onSelect={(value) =>
                      this.handleChangeCombobox({}, value, 'payoutOption')
                    }
                  />
                )}
              {this.props.driver && (
                <Filter.Driver
                  items={this.state.driverQuery.list}
                  onSelect={this.handleSelectDriver}
                  onSearchChange={this.searchDriverDebounce}
                  onScrollBottom={this.handleLoadMoreDriverOptions}
                  customTitle={this.getDriverDropdownTitle()}
                  selectedItems={
                    !this.state.queryBody.criteria.driverId ||
                    this.state.queryBody.criteria.driverId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.driverId]
                  }
                />
              )}
              {this.props.corporate && (
                <Filter.Corporate
                  items={this.props.corporate}
                  selectedItems={[this.state.queryBody.criteria.corporateId]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'corporateId')
                  }
                />
              )}
              {this.props.customer && (
                <Filter.Customer
                  items={this.state.customerQuery.list}
                  onSelect={this.handleSelectCustomer}
                  onSearchChange={this.searchCustomerDebounce}
                  onScrollBottom={this.handleLoadMoreCustomerOptions}
                  customTitle={this.getCustomerDropdownTitle()}
                  selectedItems={
                    !this.state.queryBody.criteria.userId ||
                    this.state.queryBody.criteria.userId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.userId]
                  }
                />
              )}

              {this.props.refererId && (
                <Filter.Referer
                  items={this.state.customerQuery.list}
                  onSelect={this.handleSelectReferer}
                  onSearchChange={this.searchCustomerDebounce}
                  onScrollBottom={this.handleLoadMoreCustomerOptions}
                  customTitle={this.getRefererDropdownTitle()}
                  selectedItems={
                    !this.state.queryBody.criteria.refererId ||
                    this.state.queryBody.criteria.refererId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.refererId]
                  }
                />
              )}
              {this.props.statusDriver && (
                <Filter.StatusDriver
                  items={commonData.referralStatus}
                  selectedItems={[this.state.queryBody.criteria.referralStatus]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'referralStatus')
                  }
                />
              )}
              {this.props.documentExpiryStatus && (
                <Filter.DocumentExpiryStatus
                  items={commonData.documentExpiryStatus}
                  selectedItems={[
                    this.state.queryBody.criteria.documentExpiryStatus,
                  ]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'documentExpiryStatus')
                  }
                />
              )}
              {this.props.driverReferrerId && (
                <Filter.DriverReferrer
                  items={this.state.driverQuery.list}
                  onSelect={this.handleSelectDriverReferrer}
                  onSearchChange={this.searchDriverDebounce}
                  onScrollBottom={this.handleLoadMoreDriverOptions}
                  customTitle={this.getDriverReferrerDropdownTitle()}
                  selectedItems={
                    !this.state.queryBody.criteria.driverReferrerId ||
                    this.state.queryBody.criteria.driverReferrerId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.driverReferrerId]
                  }
                />
              )}
              {this.props.refereeId && (
                <Filter.Referee
                  items={this.state.driverQuery.list}
                  onSelect={this.handleSelectDriverReferee}
                  onSearchChange={this.searchDriverDebounce}
                  onScrollBottom={this.handleLoadMoreDriverOptions}
                  customTitle={this.getDriverRefereeDropdownTitle()}
                  selectedItems={
                    !this.state.queryBody.criteria.refereeId ||
                    this.state.queryBody.criteria.refereeId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.refereeId]
                  }
                />
              )}
              {this.props.referralCode && (
                <Filter.ReferralCode
                  items={this.state.referralCodeQuery.list}
                  onSelect={this.handleSelectReferralCode}
                  onSearchChange={this.searchReferralCodeDebounce}
                  onScrollBottom={this.handleLoadMoreReferralCodeOptions}
                  customTitle={this.getReferralCodeDropdownTitle()}
                  selectedItems={
                    !this.state.queryBody.criteria.referralCode ||
                    this.state.queryBody.criteria.referralCode === 'all'
                      ? []
                      : [this.state.queryBody.criteria.referralCode]
                  }
                />
              )}

              {this.props.bookingService && (
                <Filter.BookingService
                  items={bookingServiceList}
                  selectedItems={[this.state.queryBody.criteria.bookingService]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'bookingService')
                  }
                />
              )}
              {this.props.canceledBy && (
                <Filter.CanceledBy
                  items={commonData.canceledByTypes}
                  selectedItems={[this.state.queryBody.criteria.canceledBy]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'canceledBy')
                  }
                />
              )}
              {this.props.action && (
                <Filter.Action
                  items={actionList}
                  selectedItems={[this.state.queryBody.criteria.action]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'action')
                  }
                />
              )}

              {this.props.loginStatus && (
                <Filter.LoginStatus
                  items={commonData.loginStatus}
                  selectedItems={[this.state.queryBody.criteria.status]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'status')
                  }
                />
              )}
              {this.props.module && (
                <Filter.Module
                  items={commonData.modules}
                  selectedItems={[this.state.queryBody.criteria.module]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'module')
                  }
                />
              )}
              {this.props.bookFrom && (
                <Filter.BookFrom
                  items={this.state.bookFromList}
                  selectedItems={this.state.queryBody.criteria.bookFrom}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'bookFrom')
                  }
                />
              )}
              {this.props.thirdPartyAction && (
                <Filter.ThirdPartyAction
                  items={this.state.thirdPartyActionList}
                  selectedItems={this.state.queryBody.criteria.thirdPartyAction}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'thirdPartyAction')
                  }
                />
              )}
              {this.props.jobType && (
                <Filter.MerchantService
                  items={merchantServices}
                  selectedItems={this.state.queryBody.criteria.jobType}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'jobType')
                  }
                />
              )}
              {this.props.merchantType && (
                <Filter.MerchantType
                  items={merchantTypes}
                  selectedItems={this.state.queryBody.criteria.merchantType}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'merchantType')
                  }
                />
              )}

              {this.props.finishedStatus && (
                <Filter.FinishedStatus
                  items={finishedStatusList}
                  selectedItems={
                    this.state.queryBody.criteria.finishedBookingStatus
                  }
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(
                      values,
                      'finishedBookingStatus'
                    )
                  }
                />
              )}

              {this.props.paymentMethod && (
                <Filter.PaymentMethod
                  items={paymentMethodList}
                  selectedItems={this.state.queryBody.criteria.paymentMethod}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'paymentMethod')
                  }
                />
              )}
              {this.props.settlement && (
                <Filter.Settlement
                  items={commonData.settlement}
                  selectedItems={[this.state.queryBody.criteria.settlement]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'settlement')
                  }
                />
              )}
              {this.props.networkType && (
                <Filter.NetworkType
                  items={commonData.networkTypes}
                  selectedItems={[this.state.queryBody.criteria.networkType]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'networkType')
                  }
                />
              )}

              {this.props.bookingStatus && (
                <Filter.BookingStatus
                  items={bookingStatusList}
                  selectedItems={this.state.queryBody.criteria.bookingStatus}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'bookingStatus')
                  }
                />
              )}
              {this.props.payoutPaymentStatus &&
                this.state.queryBody.criteria.bookingService !== 'roaming' && (
                  <Filter.PayoutPaymentStatus
                    items={commonData.payoutPaymentStatus}
                    selectedItems={[
                      this.state.queryBody.criteria.payoutPaymentStatus,
                    ]}
                    onSelect={(value) =>
                      this.handleChangeCombobox(
                        {},
                        value,
                        'payoutPaymentStatus'
                      )
                    }
                  />
                )}

              {this.props.paymentStatus && (
                <Filter.PaymentStatus
                  title={this.props.paymentStatus.title}
                  items={
                    Array.isArray(_.get(this.props, 'paymentStatus.options'))
                      ? this.props.paymentStatus.options
                      : commonData.bookingDetailsPaymentStatus
                  }
                  selectedItems={this.state.queryBody.criteria.paymentStatus}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'paymentStatus')
                  }
                />
              )}
              {this.props.paymentStatusRefund && (
                <Filter.PaymentStatus
                  items={commonData.paymentStatusRefund}
                  selectedItems={
                    this.state.queryBody.criteria.paymentStatusRefund
                  }
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(
                      values,
                      'paymentStatusRefund'
                    )
                  }
                />
              )}
              {this.props.ratingType && (
                <Filter.RatingType
                  items={commonData.ratingTypes}
                  selectedItems={this.state.queryBody.criteria.ratingType}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'ratingType')
                  }
                />
              )}
              {this.props.stars && (
                <Filter.CarStars
                  items={commonData.stars}
                  title={this.props.starsFilterTitle}
                  selectedItems={this.state.queryBody.criteria.stars}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'stars', 0)
                  }
                />
              )}
              {this.props.vehicleRate && (
                <Filter.VehicleStars
                  items={commonData.stars}
                  selectedItems={this.state.queryBody.criteria.vehicleRate}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'vehicleRate', 0)
                  }
                />
              )}
              {this.props.transactionType && (
                <Filter.TransactionType
                  items={transactionTypeList}
                  selectedItems={this.state.queryBody.criteria.transactionType}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'transactionType')
                  }
                />
              )}
              {this.props.incidentCanceledTypes && (
                <Filter.IncidentCanceledTypes
                  items={incidentCanceledTypeList}
                  selectedItems={
                    this.state.queryBody.criteria.incidentCanceledTypes
                  }
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(
                      values,
                      'incidentCanceledTypes'
                    )
                  }
                />
              )}
              {this.props.bookingType && (
                <Filter.BookingType
                  items={this.bookingTypes}
                  selectedItems={this.state.queryBody.criteria.bookingType}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'bookingType')
                  }
                />
              )}
              {this.props.intercityRoute && (
                <Filter.IntercityRoute
                  items={commonDataIntercityRoute}
                  selectedItems={
                    this.state.queryBody.criteria.routes[0]
                      ? [this.state.queryBody.criteria.routes[0].routeId]
                      : []
                  }
                  selectedRouteNumber={
                    this.state.queryBody.criteria.routes[0]
                      ? [this.state.queryBody.criteria.routes[0].routeNumber]
                      : []
                  }
                  onSelect={this.handleSelectIntercity}
                  customTitle={this.getIntercityRouteDropdownTitle()}
                />
              )}
              {this.props.serviceType && (
                <Filter.ServiceType
                  items={commonData.serviceTypes}
                  selectedItems={this.state.queryBody.criteria.serviceType}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'serviceType')
                  }
                />
              )}
              {this.props.rideService && (
                <Filter.RideService
                  items={commonData.rideServices}
                  selectedItems={this.state.queryBody.criteria.rideService}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'rideService')
                  }
                />
              )}

              {this.props.withdrawStatus && (
                <Filter.WithdrawalStatus
                  selectedItems={[this.state.queryBody.criteria.withdrawStatus]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'withdrawStatus')
                  }
                />
              )}

              {this.props.withdrawalTransactionType && (
                <Filter.WithdrawalTransactionType
                  selectedItems={this.state.queryBody.criteria.transactionType}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'transactionType')
                  }
                />
              )}

              {this.props.paymentOriginalStatus && (
                <Filter.PaymentOriginalStatus
                  items={commonData.originalPaymentStatus}
                  selectedItems={this.state.queryBody.criteria.originalStatus}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'originalStatus')
                  }
                />
              )}

              {this.props.tipAfterCompleted && (
                <Filter.TipAfterCompleted
                  items={commonData.TipAfterCompleted}
                  selectedItems={this.state.queryBody.criteria.tip}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'tip')
                  }
                />
              )}
              {this.props.shortTrip && waiveOffCommission && (
                <Filter.shortTrip
                  items={commonData.shortTrip}
                  selectedItems={this.state.queryBody.criteria.shortTrip}
                  onSelect={(values) =>
                    this.handleChangeCombobox({}, values, 'shortTrip')
                  }
                />
              )}
              {this.props.paymentCurrentStatus && (
                <Filter.PaymentCurrentStatus
                  items={commonData.bookingDetailsPaymentStatus}
                  selectedItems={this.state.queryBody.criteria.currentStatus}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'currentStatus')
                  }
                />
              )}
              {this.props.promocode && (
                <Filter.UsedPromocodes
                  items={this.state.promocodeQuery.list}
                  onSelect={this.handleSelectPromocode}
                  onSearchChange={this.searchPromocodeDebounce}
                  onScrollBottom={this.handleLoadMorePromocodeOptions}
                  customTitle={this.getPromoCodeDropdownTitle()}
                  customLabel={this.customPromotionLabel}
                  selectedItems={
                    !this.state.queryBody.criteria.promoCodeId ||
                    this.state.queryBody.criteria.promoCodeId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.promoCodeId]
                  }
                />
              )}
              {this.props.promoStatus && (
                <Filter.PromocodeType
                  items={commonData.PromotionTypeFilter}
                  selectedItems={this.state.queryBody.criteria.promoStatus}
                  onSelect={(value) =>
                    this.handleDropDownMultiSelect(value, 'promoStatus')
                  }
                />
              )}
              {this.props.userType && (
                <Filter.UserType
                  items={commonData.usersType}
                  selectedItems={[this.state.queryBody.criteria.userType]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'userType')
                  }
                />
              )}
              {this.props.eventType && (
                <Filter.EventType
                  items={commonData.eventType}
                  selectedItems={[this.state.queryBody.criteria.eventType]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'eventType')
                  }
                />
              )}
              {this.props.event && (
                <Filter.Event
                  items={this.state.eventQuery.list}
                  onSelect={this.handleSelectEvent}
                  onSearchChange={this.searchEventDebounce}
                  onScrollBottom={this.handleLoadMoreEventOptions}
                  customTitle={this.getEventDropdownTitle()}
                  selectedItems={
                    !this.state.queryBody.criteria.eventId ||
                    this.state.queryBody.criteria.eventId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.eventId]
                  }
                />
              )}
              {this.props.eventState && (
                <Filter.EventState
                  items={commonData.eventState}
                  selectedItems={[this.state.queryBody.criteria.eventState]}
                  onSelect={(value) =>
                    this.handleChangeCombobox({}, value, 'eventState')
                  }
                />
              )}
              {this.props.voucherCodes && (
                <Filter.VoucherCode
                  items={this.state.voucherCodeQuery.list}
                  onSelect={this.handleSelectVoucherCode}
                  onSearchChange={this.searchVoucherCodeDebounce}
                  onScrollBottom={this.handleLoadMoreVoucherCodeOptions}
                  customTitle={this.getVoucherCodeDropdownTitle()}
                  selectedItems={
                    !this.state.queryBody.criteria.redeemCodeId ||
                    this.state.queryBody.criteria.redeemCodeId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.redeemCodeId]
                  }
                />
              )}
              {this.props.storeMerchant && (
                <Filter.Store
                  title={this.props.storeMerchant.title}
                  items={this.state.storeQuery.list}
                  onSelect={this.handleSelectStore}
                  onSearchChange={this.searchStoreDebounce}
                  onScrollBottom={this.handleLoadMoreStoreOptions}
                  customTitle={this.getStoreDropdownTitle()}
                  selectedItems={
                    !this.state.queryBody.criteria.merchantId ||
                    this.state.queryBody.criteria.merchantId === 'all'
                      ? []
                      : [this.state.queryBody.criteria.merchantId]
                  }
                />
              )}
              {this.props.multiSelectionsFilters &&
                this.props.multiSelectionsFilters.map((filter) => (
                  <Filter.MultiSelectionsFilter
                    items={filter.options}
                    onSelect={(values) =>
                      this.handleDropDownMultiSelect(
                        values,
                        filter.key,
                        'all',
                        filter.options
                      )
                    }
                    selectedItems={this.state.queryBody.criteria[filter.key]}
                    title={filter.title}
                  />
                ))}
              {this.props.adjustPrice && (
                <Filter.AdjustPrice
                  items={commonData.adjustPrices}
                  selectedItems={this.state.queryBody.criteria.adjustPrice}
                  onSelect={(values) =>
                    this.handleDropDownMultiSelect(values, 'adjustPrice')
                  }
                />
              )}
              {this.props.currency &&
                this.props.tittle !== 'Pay to drivers' &&
                this.props.tittle !== 'Payout history' && (
                  <Filter.Currency
                    items={currencies}
                    selectedItems={[this.state.queryBody.criteria.currency]}
                    onSelect={(value) =>
                      this.handleChangeCombobox({}, value, 'currency')
                    }
                  />
                )}
              {this.state.payoutDate !== '' && (
                <span style={{ color: '#e6e6e6' }}>
                  <Translate value="report.result.payoutToDriver.payoutEndTime" />
                  :{' '}
                  {moment(this.state.payoutDate)
                    .tz(this.state.timezone)
                    .format('YYYY-MM-DD hh:mm A')}
                </span>
              )}
              {this.props.tittle === 'Pay to drivers' &&
              this.props.actions &&
              this.state.queryBody.criteria.payoutOption !== 'hasBank' ? (
                <span style={{ paddingLeft: '20px', color: '#e6e6e6' }}>
                  <Translate value="report.result.payoutToDriver.noteSelectDrivers" />{' '}
                </span>
              ) : null}
            </div>

            <div className="group-btn-report">
              {!this.props.customViewButton && (
                <Button
                  className="btn-save view-button"
                  onClick={this.handleClickView}
                  disabled={isDisabledViewButton}
                >
                  {this.props.loading ? (
                    'Loading...'
                  ) : (
                    <Translate value="report.query.view" />
                  )}
                </Button>
              )}
              {this.props.favoriteFilter && (
                <>
                  <Button
                    className="btn-save view-button"
                    onClick={this.openSaveFavoriteFilterModal}
                  >
                    <Translate value="cue.Save_Filter" />
                  </Button>
                  <Button
                    className="filter-btn-settings"
                    onClick={this.openSettingFavoriteFilterModal}
                  >
                    <img className="filter-settings" src={setting}></img>
                  </Button>
                </>
              )}
              {this.props.hasCustomShowColumns && (
                <CCDropDown
                  id="select-column-dropdown"
                  customTitle={<div className="column-filter-icon" />}
                  items={filterColumnItems}
                  valueKey="key"
                  labelKey="label"
                  onSelect={this.props.handleSelectColumn}
                  selectedItems={this.props.listColumnSelected}
                  multiSelect={true}
                  pullRight={true}
                  className="column-filter drop-showHide-column"
                  enableAllItemSelect={true}
                  hasActionBtn
                  actionBtnFunc={this.fetchReportData}
                />
              )}
            </div>
          </div>
          {this.state.summary.length !== 0 &&
            this.props.tittle === 'Quest' &&
            this.state.event !== null && (
              <div className="bottom-filter-container">
                <span style={{ color: '#e6e6e6', padding: '0px 15px 10px' }}>
                  <Translate value="report.result.customerQuest.period" />{' '}
                  {moment(this.state.event.startDate)
                    .tz(this.state.timezone)
                    .format('MMM DD, YYYY') +
                    ' - ' +
                    moment(this.state.event.endDate)
                      .tz(this.state.timezone)
                      .format('MMM DD, YYYY')}
                  {this.state.event !== null && !this.state.event.isUnlimited && (
                    <span style={{ paddingLeft: '30px' }}>
                      <Translate value="report.result.customerQuest.numberOfQualifiedSetting" />{' '}
                      {typeof this.state.numberOfQualified !== 'undefined'
                        ? this.state.numberOfQualified
                        : 0}{' '}
                    </span>
                  )}
                  <span style={{ paddingLeft: '30px' }}></span>
                  {this.state.queryBody.criteria.eventType !==
                    'largest_will_win' && (
                    <span>
                      <Translate value="report.result.customerQuest.numberOfActualQualified" />{' '}
                      {this.state.summary !== null
                        ? this.state.summary.qualified
                        : 0}
                    </span>
                  )}
                </span>
              </div>
            )}
        </div>
        <div className="gridViewTable">{this.renderStickyTable()}</div>

        {this.state.showAddFavoriteFilterModal && (
          <AddFavoriteFilterModal
            name={this.state.nameFilter}
            handleChangeName={this.handleChangeNameFavoriteFilter}
            handleSave={this.handleSaveFavoriteFilter}
            handleClose={this.handleModalClose}
          />
        )}

        {this.state.showSettingsFavoriteFilterModal && (
          <SettingsFavoriteFilterModal
            filterTemplates={this.state.filterTemplates}
            handleClose={this.handleModalClose}
            handleDelete={this.handleDeleteFavoriteFilter}
          />
        )}
      </div>
    )
  }
}

BaseReport.contextTypes = {
  setPageTitle: PropTypes.func,
  tittle: PropTypes.string,
  fieldMappings: PropTypes.object,
  router: PropTypes.object,
  notification: PropTypes.func,
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    fleetCurrencies:
      (state.auth.selectedFleet && state.auth.selectedFleet.currencies) || [],
    currencies: state.commonData.currencies,
    companies: state.commonData.companies,
    suppliers: state.commonData.suppliers,
    serviceFee: state.serviceFee,
    campaigns: state.commonData.campaigns,
    operators: state.commonData.operator.map((item) => {
      item.fullName = `${item.firstName} ${item.lastName}` // `
      return item
    }),
    drivers: state.commonData.drivers,
    fetchTime: state.commonData.fetchTime,
    mapZone: state.commonData.mapZone,
    carTypeCommonData: state.commonData.carType,
    intercityRoutes: state.commonData.intercityRoutes,
    user: state.auth.user,
    settings: state.settings.fleet,
    report: state.report,
    language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-US',
  }
}

function mapDispatchToProps(dispatch) {
  return {
    commonDataActions: bindActionCreators(commonDataActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    serviceFeeActions: bindActionCreators(serviceFeeActions, dispatch),
    merchantActions: bindActionCreators(merchantActions, dispatch),
    customerAutocompleteActions: bindActionCreators(
      customerAutocompleteActions,
      dispatch
    ),
    driverAction: bindActionCreators(driverAction, dispatch),
    customerAction: bindActionCreators(customerAction, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BaseReport)
