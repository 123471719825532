import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import * as driverActions from '../../../actions/driverAction';
import styles from '../styles/formDriver.module.scss';
import FormInfo from './forms/formInfo';
import '../styles/index.scss';
import { DriverType, SettelementSetting } from '../../../constants/commondata';
import {
  CCLiteCommonFunc,
  Validation,
  getSuppilerPermission,
  trimPhoneNumber,
} from '../../../utils/commonFunctions';
import {
  fetchRelativeInfo,
  fetchCompanies,
} from '../../../actions/commonDataAction';
import {
  loadMoreVehicles,
  loadMoreVehiclesByType,
} from '../../../actions/commonDataAction';
import {
  findOneVehicle,
  fetchVehicleByCompany,
  findOneDriver,
  checkExistDriverPhone,
  operateDriver,
  resestPassword,
  getBankAccountRequireField,
  getEmergencyContactDriver,
  removeBankInfo,
  setDocumentsMissing,
  updateDriverDocument,
} from '../../../actions/driverAction';
import { updateDriverInfo } from '../../../actions/mapActions';
import { Promise } from 'bluebird';
import { DriverInfoFields } from '../DynamicField';
import { Tabs, Tab, Tooltip } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment-timezone';
import VehicleDriver from './VehicleDriver';
import FormSetting from './forms/formSetting';
import FormPayment from './forms/formPayment';
import FormDocument from './forms/formDocument';
import DriverEmergencyContacts from './DriverEmergencyContact';
import AddNewVehicle from './VehicleDriver/AddNewVehicle';
import Confirm from '../../../components/confirm/Confirm';

const reg = /^\d+$/;
const FILE_ACCEPTED =
  '.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip';
const IMAGE_ACCEPTED = '.jpg, .jpeg, .png';
const MAX_FILE_SIZE = 5000000;
const LIMIT_LIST_VEHICLE = 50;
const year = new Date().getFullYear();
const tooltip = (
  <Tooltip id="tooltip">
    <Translate value="driver.Force_meter_tooltip" />
  </Tooltip>
);
const rideSharingToolTip = (
  <Tooltip id="tooltip">
    <Translate value="driver.Ride_sharing_tooltip" />
  </Tooltip>
);
const marketplaceToolTip = (
  <Tooltip id="tooltip">
    <Translate value="driver.Market_place_tooltip" />
  </Tooltip>
);

const BankAccountFields = [
  'address',
  'city',
  'state',
  'zipcode',
  'birthday',
  'email',
];
const MolpayRequireFields = [
  'email',
  'driverId',
  'address',
  'city',
  'state',
  'zipcode',
  'birthday',
];
let yearsElement = [];
let phone;
let isVhicleNoType = false;
let driverIcEditable = false;

// initDriverFiledList
// handleCompanyChange
// handleLicensePlateComboChange
// initDocumentsOrigin
// getVehiclesByType
//
// onUpdateDriver

const FormDriver = (props, context) => {
  const [state, setState] = useState({
    editable: props.action === 'view' ? false : true,
    confirm: null,
    isExistingCar: true,
    vehicleByCompany: {
      limit: LIMIT_LIST_VEHICLE,
      page: 0,
      total: 0,
      search: '',
      // companyId: null,
      list: [],
    },
    vehicleDetails: {},
    carMakeList: [],
    passengers: 0,
    luggage: 0,
    caseNumber: '',
    vhcPhone: '',
    year,
    commission: 0,
    isEditForm: false,
    stripeAccountId: '',
    stripeAccountIdVerified: '',
    vehicleIds: [],
    switchToAnyVehicle: false,
    detailItem: {
      driverInfo: {
        company: {},
        city: {},
        idType: '',
        driverType: DriverType.OwnerOperator,
        driverDocument: {},
        commissionByCurrencies: [],
        commissionType: 'percent',
        commissionDriverType: 'default',
        commissionDriverValue: [],
        commissionCompanyType: 'default',
        commissionCompanyValue: [],
        creditBalances: [],
        statusReview: 'inComplete',
        isBankAccountOwner: true,
        hasTopUpOtherDriver: false,
        switchVehicle: false,
        serviceType: 'transport',
        zoneId: [],
      },
      statusDriver: 'inReviewInProgress',
      shift: {},
      phone: '',
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      rideSharing: false,
      marketplace: false,
      marketplaceSettingMode: 'default',
      maximumAcceptReservationPerDay: 0,
      maximumAcceptReservation: 0,
    },
    originDriverType: '',
    driverInfoDynamic: {},
    driverDocument: {},
    driverDocumentExpiry: [],
    driverFiledList: [],
    isShowDriverDeposit: false,
    isShowCashWalletBalance: false,
    documentDeleteList: [],
    isView: true,
    licensePlateValidation: true,
    valid: {},
    vhcPhoneValidation: true,
    isSubmited: false,
    bankRequirement: {
      driverId: false,
      driverIc: false,
      accountName: false,
      address: false,
      bankName: false,
      bankNumber: false,
      birthday: false,
      checkNumber: false,
      city: false,
      routingNumber: false,
      ssn: false,
      state: false,
      verificationDocument: false,
      verificationDocumentBack: false,
      zipcode: false,
      IFSCCode: false,
    },
    bankInfoList: [],
    relativeInfoData: {},
    phoneFieldValidation: {},
    isVehicleDidntAssign: false,
    listShifts: [],
    documentsOrigin: [],
    documentsMissing: [],
    showCommission: false,
    showCompanyCommission: false,
  });

  const [initInfo, setInitInfo] = useState({});
  const [companyChanged, setCompanyChanged] = useState(false);
  const [initDriver, setInitDriver] = useState('');
  const [isChangeZipcode, setIsChangeZipcode] = useState(false);
  const [legalToken, setLegalToken] = useState(null);
  const [searchNote, setSearchNote] = useState('');
  const [noteList, setNoteList] = useState([]);
  const [noteOptions, setNoteOptions] = useState([]);
  const [supplierList, setSupplierList] = useState([]);

  const [validTab, setValidTab] = useState({
    info: false,
    vehicle: false,
    document: false,
    payment: false,
    setting: false,
    vehicle: false,
  });

  const handleSearchNote = (e) => {
    const val = e?.target?.value || '';
    if (val) {
      setSearchNote(val);
    } else {
      setSearchNote('');
    }
  };

  const handleAddNote = () => {
    const defaultNoteList = [...noteList];
    if (searchNote) {
      defaultNoteList.push({
        label: searchNote,
        value: searchNote,
      });
      setNoteList(defaultNoteList);
    }
    handleSearchNote('');
  };

  const parseDriverInfo = (data, fields) => {
    const s3Host = props.auth.selectedFleet.s3Host || '';

    let driverInfo = {};
    _.forEach(fields, (item) => {
      if (item.key !== 'company') {
        let valueKey = DriverInfoFields[item.key]
          ? DriverInfoFields[item.key].value
          : item.key.includes('backup')
          ? 'driverInfo.' + item.key
          : item.key;
        driverInfo[item.key] = _.get(data, valueKey, '');
        if (
          item.key.includes('backup') &&
          item.type === 'Date' &&
          driverInfo[item.key]
        ) {
          driverInfo[item.key] = moment(driverInfo[item.key]).format(
            'MM/DD/YYYY'
          );
        }
        if (
          item.key.includes('backup') &&
          item.type === 'File' &&
          driverInfo[item.key]
        ) {
          driverInfo[item.key] = s3Host + driverInfo[item.key];
        }
      }
    });
    return driverInfo;
  };

  const handleCommissionDriverChange = (data, key = 'type') => {
    const defaultState = { ...state };
    if (key == 'value') {
      defaultState.detailItem.driverInfo = {
        ...defaultState.detailItem.driverInfo,
        commissionDriverValue: data.filter((c) => c.value !== 'N/A'),
      };
      return defaultState;
    } else {
      defaultState.detailItem.driverInfo = {
        ...defaultState.detailItem.driverInfo,
        commissionDriverType: data.target.value,
      };
    }
    setState(defaultState);
  };

  const handleCommissionChange = (e, iso) => {
    let numberWithDotReg = /^(\d*\.?\d+|\d+\.)$/;
    let numberWithCommaReg = /^(\d*\,?\d+|\d+\,)$/;
    let com = e.target ? e.target.value : '';
    const defaultState = { ...state };
    if (
      numberWithDotReg.test(com) ||
      numberWithCommaReg.test(com) ||
      com === ''
    ) {
      if (iso) {
        //amount
        let commissionByCurrencies =
          defaultState.detailItem.driverInfo.commissionByCurrencies;
        if (!commissionByCurrencies) {
          commissionByCurrencies = [];
        }
        let index = commissionByCurrencies.find((x) => x.currencyISO == iso);
        if (index) {
          commissionByCurrencies = commissionByCurrencies.map((x) => {
            if (x.currencyISO == iso) {
              x.commissionValue = com === '' ? '' : com;
            }
            return x;
          });
        } else {
          commissionByCurrencies.push({
            commissionValue: com === '' ? '' : com,
            currencyISO: iso,
          });
        }
        defaultState.detailItem = {
          ...defaultState.detailItem,
          driverInfo: {
            ...defaultState.detailItem.driverInfo,
            commissionByCurrencies: commissionByCurrencies,
          },
        };
      } else {
        //percent
        defaultState.commission = com === '' ? '' : com;
      }
      setState(defaultState);
    }
  };

  const handleChangeBalanceClick = (e) => {
    const defaultState = { ...state };
    defaultState.isShowDriverDeposit = true;
    setState(defaultState);
  };

  const handleClickShowCashWalletBalance = (e) => {
    const defaultState = { ...state };
    defaultState.isShowCashWalletBalance = true;
    setState(defaultState);
  };

  const onEditable = () => {
    const defaultState = { ...state };
    defaultState.editable = true;
    setState(defaultState);
  };

  const saveCommissionEdit = () => {
    const defaultState = { ...state };
    const resultCommission = handleCommissionDriverChange(
      defaultState?.detailItem?.driverInfo?.commissionDriverValue,
      'value'
    );
    resultCommission.showCommission = false;
    setState(resultCommission);
  };

  const handleDriverInfoChange = (data, key) => {
    const { capitalLetter } = props.auth.selectedFleet;
    if (key === 'zipcode') {
      setIsChangeZipcode(true);
    }
    let value = data.value;
    if (
      capitalLetter &&
      capitalLetter.name.cc &&
      (key === 'firstName' || key === 'lastName')
    ) {
      value = value.toUpperCase();
    }
    const defaultState = { ...state };
    defaultState.driverInfoDynamic = {
      ...defaultState.driverInfoDynamic,
      [key]: value,
    };
    setState(defaultState);
  };

  const handlePhoneFieldChange = (
    status,
    value,
    countryData,
    number,
    id,
    fieldKey,
    isBlur
  ) => {
    const defaultState = { ...state };
    defaultState.driverInfoDynamic = {
      ...defaultState.driverInfoDynamic,
      [fieldKey]: number,
    };
    defaultState.phoneFieldValidation = {
      ...defaultState.phoneFieldValidation,
      [fieldKey]: status,
    };
    setState(defaultState);
  };

  const handlePhoneChange = (
    status,
    value,
    countryData,
    number,
    id,
    isBlur,
    isDialCodeOnly
  ) => {
    try {
      const defaultState = { ...state };
      let newStatus = false;
      if (!status && number.length > 6 && number !== '+undefined') {
        const phoneNumber = parsePhoneNumber(number);
        if (phoneNumber) {
          newStatus = phoneNumber.isValid();
        }
      }
      if (isBlur) {
        if (!status) return false;
        let newPhone;
        if (
          countryData &&
          '+' + countryData.dialCode == trimPhoneNumber(number)
        ) {
          newPhone = '';
        } else {
          newPhone = trimPhoneNumber(number);
        }
        if (newPhone !== phone) {
          props
            .checkExistDriverPhone({
              fleetId: props.auth.selectedFleet.fleetId,
              phone: newPhone,
            })
            .then((data) => {
              if (data.ok) {
                defaultState.phoneExistValidation =
                  data.res && !data.res.existed;
                setState(defaultState);
              }
            });
        }
      } else {
        defaultState.phoneExistValidation = true;
        defaultState.phoneValidation = status ? status : newStatus;
        defaultState.driverInfoDynamic = {
          ...defaultState.driverInfoDynamic,
          phone: number,
        };
        setState(defaultState);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarketplaceTypeChange = (e) => {
    const value = e.target.value;
    const defaultState = { ...state };
    defaultState.detailItem.marketplaceSettingMode = value;
    if (value === 'customized') {
      defaultState.detailItem = {
        ...defaultState.detailItem,
        maximumAcceptReservationPerDay: 0,
        maximumAcceptReservation: 0,
      };
    }
    setState(defaultState);
  };

  const showCommissionClick = () => {
    const defaultState = { ...state };
    defaultState.showCommission = !defaultState.showCommission;
    setState(defaultState);
  };

  const handleMarketplaceChange = (e) => {
    const defaultState = { ...state };

    if (!e.target.checked) {
      defaultState.detailItem = {
        ...defaultState.detailItem,
        marketplaceSettingMode: 'default',
        maximumAcceptReservationPerDay: 0,
        maximumAcceptReservation: 0,
      };
    }
    defaultState.detailItem.marketplace = e.target.checked;
    setState(defaultState);
  };

  const isBankRequire = (key) => {
    const { detailItem, bankRequirement } = state;
    // const isBankingInfoRequired =
    //   props.auth.selectedFleet &&
    //   props.auth.selectedFleet.bankingInfo &&
    //   props.auth.selectedFleet.bankingInfo.enable &&
    //   props.auth.selectedFleet.bankingInfo.verifyAccount ==
    //   SettelementSetting.verifyAccount;
    // if (isBankingInfoRequired) {
    //   return defaultState.bankRequirement[key];
    // }
    return bankRequirement[key] ? bankRequirement[key] : false;
  };

  const getAllSupplier = () => {
    if (!props.auth?.user?.roles?.isSupplier) {
      let param = {
        limit: 500,
        page: 0,
        fleetId: props.auth?.selectedFleet?.fleetId,
        isSupplier: true,
        agentId: props.auth?.user?.roles?.companyId,
      };
      props.settingActions.getAllCompany(param).then((data) => {
        if (data.ok) {
          if (data?.res?.list?.length) {
            setSupplierList(data?.res?.list);
          }
        }
      });
    }
  };

  const handleInitialData = async () => {
    const {
      commonData,
      auth,
      fetchCompanies,
      fetchRelativeInfo,
      getBankAccountRequireField,
      findOneDriver,
      params,
      route,
      router,
      settingActions,
    } = props;

    const defaultState = { ...state };

    const fleetId = auth.selectedFleet.fleetId;
    let visible = false;
    const defaultZone = _.find(
      commonData.mapZone || [],
      (z) => z.isDefault === true
    );

    let initDriverVal = null;

    const generalSetting = _.get(auth, 'selectedFleet.generalSetting', {});
    const { vehicleAgeLimit = {}, disableMarketPlace = false } = generalSetting;
    // SL-29287 User can select next year when input 'Vehicle year' for car type.
    const currentYear = new Date().getFullYear() + 1;
    let yearsLimit = 1999;
    if (vehicleAgeLimit.enable) {
      yearsLimit = currentYear - vehicleAgeLimit.years;
    }
    for (let i = currentYear; i > yearsLimit; i -= 1) {
      yearsElement.push(i);
    }
    const company = getCompanyDefault();
    defaultState.detailItem.driverInfo.company = company;
    if (!disableMarketPlace) {
      defaultState.detailItem.marketplace = !disableMarketPlace;

      const resultCompanyVehicle = await getVehiclesByType(company?.companyId);

      if (resultCompanyVehicle) {
        let licensePlateValidation = props.auth.selectedFleet.licensePlate
          ? true
          : false;
        defaultState.vehicleByCompany = {
          ...defaultState.vehicleByCompany,
          ...resultCompanyVehicle.res,
          search: '',
          companyId: company?.companyId || '',
        };
        defaultState.licensePlateValidation = licensePlateValidation;
      }
    }

    let promiseTask = [fetchCompanies(fleetId).then((data) => data)];

    if (!commonData.relativeInfo) {
      promiseTask.push(fetchRelativeInfo({ fleetId }));
    } else {
      promiseTask.push({
        ok: true,
        res: commonData.relativeInfo,
      });
    }

    const fleet = auth.selectedFleet;

    let gateway = '';

    if (fleet.drvPayout.enable) {
      gateway = fleet.drvPayout.gateway;
    } else if (fleet.creditConfig.configGateway) {
      gateway = fleet.creditConfig.configGateway.gateway;
    }

    const enableStripeConnect =
      (fleet.drvPayout && fleet.drvPayout.gateway === 'StripeConnect') || false;

    defaultState.enableStripeConnect = enableStripeConnect;

    if (!gateway) gateway = 'Stripe';

    promiseTask.push(
      getBankAccountRequireField({
        fleetId,
        gateway,
      })
    );

    if (params.driverId) {
      promiseTask.push(
        findOneDriver({
          userId: params.driverId,
          fleetId,
        })
      );
      visible = true;
    } else {
      promiseTask.push(null);
    }

    let editable = state.editable,
      isView = state.isView,
      licensePlateValidation = state.licensePlateValidation,
      bankRequirement = Object.assign({}, state.bankRequirement),
      driverInfoDynamic = Object.assign({}, state.driverInfoDynamic);

    let bankInfoList = Object.assign({}, state.bankInfoList);

    if (auth.selectedFleet && auth.selectedFleet.licensePlate) {
      licensePlateValidation = true;
    }

    promiseTask.push(settingActions.getCarMakeList({ fleetId }));

    await Promise.all(promiseTask)
      .spread(
        (
          companiesData = {},
          rltData,
          bankRequirementData,
          driverData,
          carMakeResponse
        ) => {
          let relativeInfoData =
            rltData && rltData.ok && rltData.res ? rltData.res : null;
          if (!relativeInfoData) {
            context.notification(
              'error',
              I18n.t(
                'errors.' +
                  (relativeInfoData.error && relativeInfoData.error.errorCode)
              )
            );
            return props.navigate.push({ pathname: '/driver' });
          }

          if (
            bankRequirementData &&
            bankRequirementData.res &&
            bankRequirementData.res.returnCode == 200
          ) {
            bankRequirement = bankRequirementData.res.response;
            if (bankRequirementData.res.response.driverIc) {
              bankRequirement.driverId =
                bankRequirementData.res.response.driverIc;
            }
            bankInfoList = _.get(
              bankRequirementData,
              'res.response.bankInfo',
              []
            );
          }

          const carMakeList =
            _.get(carMakeResponse, 'res', null) ||
            _.get(relativeInfoData, 'makes', null) ||
            [];

          // ReOrder Shifts
          const shiftList = _.get(relativeInfoData, 'shifts', null) || [];
          let listShifts = [];
          if (shiftList) {
            shiftList.forEach((e) => {
              if (e.name === 'Full') {
                listShifts.unshift(e);
              } else {
                listShifts.push(e);
              }
            });
          }

          if (driverData) {
            if (driverData.ok && driverData.res) {
              if (_.get(driverData, 'res.driverInfo.vehicleId', '')) {
                initDriverVal = _.get(driverData, 'res.driverInfo.vehicleId');
              }

              driverInfoDynamic = parseDriverInfo(
                driverData.res,
                relativeInfoData.fields
              );

              let statusDriver = driverData.res.isActive
                ? 'active'
                : driverData.res.driverInfo.isActivate
                ? 'inactive'
                : 'inReviewInProgress';

              let commissionByCurrencies =
                driverData.res.driverInfo.commissionByCurrencies;
              let commission = state.commission;

              if (commissionByCurrencies && commissionByCurrencies.length > 0) {
                commission = commissionByCurrencies[0].commissionValue;
              }

              if (
                driverData.res.driverInfo &&
                !driverData.res.driverInfo.commissionType
              )
                driverData.res.driverInfo.commissionType = 'percent';

              phone = driverData.res.phone;

              // SL-5266
              let newDriverData = Object.assign({}, driverData.res);
              defaultState.vehicleIds =
                newDriverData?.vehicles?.map((vhc) => vhc.vehicleId) || [];
              defaultState.switchToAnyVehicle =
                newDriverData?.switchToAnyVehicle || false;
              let selectedCurrency =
                defaultZone && defaultZone.currency
                  ? defaultZone.currency.iso
                  : '';

              if (
                driverData.res.driverInfo.zoneId &&
                driverData.res.driverInfo.zoneId.length
              ) {
                const parseZone = _.map(
                  driverData.res.driverInfo.zoneId,
                  (zId) =>
                    _.find(commonData.mapZone, (i) => i._id === zId) || {}
                );
                const firstZone = parseZone[0];

                if (!firstZone.currency) {
                  newDriverData.driverInfo.zoneId = [];
                } else if (parseZone.length === 1) {
                  selectedCurrency = firstZone.currency.iso;
                } else {
                  let otherCurrency = _.find(
                    parseZone.slice(1),
                    (z) =>
                      !z.currency || z.currency.iso !== firstZone.currency.iso
                  );

                  if (!otherCurrency) {
                    selectedCurrency = firstZone.currency.iso;
                  } else {
                    newDriverData.driverInfo.zoneId = [];
                  }
                }
              } else {
                newDriverData.driverInfo.zoneId = [];
              }
              var nostes = newDriverData.driverInfo.notes;
              if (
                newDriverData.driverInfo.notes != null &&
                newDriverData.driverInfo.notes.length != 0
              ) {
                nostes = newDriverData.driverInfo.notes
                  .split(',')
                  .map(function (m) {
                    return { value: m, label: m };
                  });
                setNoteList(nostes);
                newDriverData.driverInfo.notes = nostes;
              }

              if (newDriverData.shift && !newDriverData.shift._id) {
                newDriverData.shift = {
                  _id: listShifts[0]._id,
                };
              }
              const achToken = _.get(driverData, 'res.driverInfo.achToken');

              defaultState.carMakeList = carMakeList;
              defaultState.stripeAccountId =
                enableStripeConnect && achToken ? achToken : '';
              defaultState.accountConnectedStripe =
                enableStripeConnect && achToken;

              defaultState.phoneFrefix = newDriverData.phone;
              defaultState.vehicleByCompany = {
                ...defaultState.vehicleByCompany,
                vehicleType: driverData.res.driverInfo?.carType?._id,
                companyId: driverData,
              };
              defaultState.documentExpiry = driverData.res.documentExpiry;
              defaultState.loadData = driverData.res.documentExpiry;
              defaultState.originDriverType =
                driverData.res.driverInfo.driverType;
              defaultState.commission = commission;
              defaultState.selectedCurrency = selectedCurrency;
              defaultState.editable = editable;
              defaultState.isView = isView;
              defaultState.licensePlateValidation = licensePlateValidation;
              defaultState.bankRequirement = bankRequirement;

              // defaultState.carType = _.get(
              //   driverData,
              //   'res.driverInfo.carType._id'
              // );
              defaultState.visible = visible;
              defaultState.relativeInfoData = relativeInfoData;
              defaultState.bankInfoList = bankInfoList;
              defaultState.listShifts = listShifts;

              const companyId = _.get(
                driverData.res,
                'driverInfo.company.companyId',
                ''
              );
              defaultState.driverInfoDynamic = driverInfoDynamic;
              defaultState.detailItem = newDriverData;
              defaultState.detailItem.statusDriver = statusDriver;
              defaultState.detailItem.initStatusDriver = statusDriver;
              let zoneId = defaultState.detailItem.driverInfo.zoneId;

              if (defaultState.detailItem.driverInfo.company) {
                if (
                  companyId !=
                  defaultState.detailItem.driverInfo.company.companyId
                ) {
                  zoneId = [];
                }
              }

              if (defaultState.detailItem.driverInfo.company) {
                if (
                  companyId !=
                  defaultState.detailItem.driverInfo.company.companyId
                ) {
                  zoneId = [];
                }
              }

              defaultState.detailItem.driverInfo.company = companyId
                ? { companyId }
                : getCompanyDefault();
              defaultState.detailItem.driverInfo.zoneId = zoneId;

              const driverType = _.get(driverData, 'res.driverInfo.driverType');
              if (
                driverType === DriverType.IndividualDriver ||
                driverType === DriverType.OwnerOperator
              ) {
                defaultState.detailItem.driverInfo.serviceType = 'transport';
              } else if (
                driverType === DriverType.deliveryIndividual ||
                driverType === DriverType.deliveryCompany
              ) {
                defaultState.detailItem.driverInfo.serviceType = 'delivery';
              }

              defaultState.driverFiledList = initDriverFiledList(
                relativeInfoData,
                driverData.res.driverInfo.driverType
              );

              defaultState.driverDocumentExpiry = initDriverDocumentExpiryList(
                driverData.res.documentExpiry,
                relativeInfoData,
                defaultState
              );

              defaultState.documentsOrigin = initDocumentsOrigin(
                driverData.res.driverInfo,
                driverData.res.documentExpiry,
                relativeInfoData.fields,
                defaultState
              );
            } else {
              context.notification(
                'error',
                I18n.t('errors.' + driverData.error.errorCode)
              );
              props.navigate.push({ pathname: '/driver' });
            }
          } else {
            driverInfoDynamic = parseDriverInfo(
              defaultState.detailItem,
              relativeInfoData.fields
            );

            defaultState.carMakeList = carMakeList;
            defaultState.selectedCurrency =
              defaultZone && defaultZone.currency
                ? defaultZone.currency.iso
                : '';
            defaultState.detailItem = {
              ...defaultState.detailItem,
              driverInfo: {
                ...defaultState.detailItem.driverInfo,
                zoneId: [],
              },
              shift: {
                _id: listShifts[0]?._id,
              },
            };
            defaultState.editable = editable;
            defaultState.isView = isView;
            defaultState.licensePlateValidation = licensePlateValidation;
            defaultState.bankRequirement = bankRequirement;
            defaultState.driverInfoDynamic = driverInfoDynamic;
            defaultState.relativeInfoData = relativeInfoData;
            defaultState.bankInfoList = bankInfoList;
            defaultState.listShifts = listShifts;
            defaultState.driverDocumentExpiry = initDriverDocumentExpiryList(
              undefined,
              relativeInfoData,
              defaultState
            );
            defaultState.driverFiledList = initDriverFiledList(
              relativeInfoData,
              defaultState.detailItem.driverInfo.driverType
            );
          }
        }
      )
      .catch((error) => {
        console.log('============error=========', error);
      });

    setState(defaultState);
    setInitInfo({
      company: defaultState.detailItem.driverInfo.company,
      zone: defaultState.detailItem.driverInfo.zoneId || []
    })
    if (initDriverVal) {
      setInitDriver(initDriverVal);
    }
  };

  const handleDriverDocumentChange = (data, index) => {
    const defaultState = { ...state };
    let { driverDocumentExpiry } = defaultState;
    driverDocumentExpiry[index] = { ...driverDocumentExpiry[index], ...data };
    setState(defaultState);
  };

  const getLicensePlateList = (params, loadMore = false, callback = null) => {
    props
      .loadMoreVehiclesByType({
        ...params,
        fleetId: props.auth.selectedFleet.fleetId,
        isActive: true,
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          const defaultState = { ...state };
          defaultState.vehicleByCompany = {
            ...defaultState.vehicleByCompany,
            ...data.res,
            search: params.search || '',
            companyId: params.companyId,
            list: loadMore
              ? defaultState.vehicleByCompany.list.concat(data.res.list)
              : data.res.list,
          };

          setState(defaultState);
        }
        if (callback) callback();
      });
  };

  const getDriverValidationInfo = (item, isRequireStripe, isRequireMolpay) => {
    let isBankField;
    if (isRequireMolpay) {
      isBankField = MolpayRequireFields.includes(item.key);
    } else {
      isBankField = BankAccountFields.includes(item.key);
    }
    let validation = {
      isRequired: item.isRequired,
      validationState: null,
      validCases: [],
      bankField: false,
    };

    if (isBankField) {
      validation.isRequired =
        isBankRequire(item.key) || isRequireStripe || item.isRequired;
      validation.bankField = true;
      validation.validationState =
        state.isSubmited && validation.isRequired
          ? state.valid[item.key]
            ? null
            : 'error'
          : null;
      if (validation.isRequired) {
        var newMessage =
          item.key == 'driverId'
            ? I18n.t('driver.ERROR_REQUIRE_') + item.title[props.language]
            : I18n.t('driver.ERROR_INPUT_' + item.key.toUpperCase());
        validation.validCases = [
          {
            valid: !Validation.isStringEmpty(state.driverInfoDynamic[item.key]),
            message: newMessage,
          },
        ];
      }

      if (item.type === 'Email') {
        validation.validCases.push({
          valid: Validation.validateEmail(state.driverInfoDynamic[item.key]),
          message: I18n.t('driver.ERROR_INPUT_VALID_EMAIL'),
        });
      }

      if (item.type === 'Phone') {
        validation.validCases.push({
          valid: state.driverInfoDynamic[item.key]
            ? state.phoneFieldValidation[item.key]
            : true,
          message: I18n.t('driver.ERROR_INPUT_PHONE'),
        });
      }
    } else {
      switch (item.key) {
        case 'phone':
          validation.validCases = [
            {
              valid: state.driverInfoDynamic.phone
                ? state.phoneValidation
                : true,
              message: I18n.t('driver.ERROR_INPUT_PHONE'),
            },
            {
              valid: state.driverInfoDynamic.phone
                ? state.phoneExistValidation
                : true,
              message: I18n.t('driver.ERROR_EXISTED_PHONE'),
            },
          ];
          break;
        default:
          if (item.type === 'Email') {
            validation.validCases.push({
              valid: Validation.validateEmail(
                state.driverInfoDynamic[item.key]
              ),
              message: I18n.t('driver.ERROR_INPUT_VALID_EMAIL'),
            });
          }

          if (item.type === 'Phone') {
            validation.validCases.push({
              valid: state.driverInfoDynamic[item.key]
                ? state.phoneFieldValidation[item.key]
                : true,
              message: I18n.t('driver.ERROR_INPUT_PHONE'),
            });
          }

          // Check Gender require
          if (item.key === 'gender' && item.isRequired == true) {
            validation.validCases.push({
              valid: state.driverInfoDynamic['gender'] != -1,
              message:
                I18n.t('driver.ERROR_REQUIRE_') + item.title[props.language],
            });
          }
          if (item.type === 'idType' && item.isRequired == true) {
            validation.validCases.push({
              valid: state.driverInfoDynamic['idType'] != '',
              message: I18n.t(
                'driver.ERROR_REQUIRE_' + item.title[props.language]
              ),
            });
          }

          break;
      }

      if (validation.isRequired) {
        validation.validCases.push({
          valid: !Validation.isStringEmpty(state.driverInfoDynamic[item.key]),
          message: I18n.t('driver.ERROR_REQUIRE_') + item.title[props.language],
        });
      }

      validation.validationState =
        !state.isSubmited ||
        state.valid[item.key] ||
        state.valid[item.key] === null ||
        state.valid[item.key] === undefined
          ? null
          : 'error';
    }
    return validation;
  };

  const handleSearchLicensePlate = (
    inputValue,
    callback,
    forceUpdate = false
  ) => {
    let params = _.pick(state.vehicleByCompany, [
      'search',
      'page',
      'limit',
      'vehicleType',
    ]);
    if (state?.vehicleByCompany?.companyId) {
      params.companyId = state.vehicleByCompany.companyId;
    }
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0;
      params.search = inputValue;
      getLicensePlateList(params, false, callback);
    } else {
      return callback ? callback() : null;
    }
  };

  const initDocumentsOrigin = (driverInfo, documents, fields, defaultState) => {
    const s3Host = props.auth.selectedFleet.s3Host || '';
    const documentsMissing =
      defaultState.detailItem.driverInfo.documentsMissing;
    let documentsOrigin = JSON.parse(JSON.stringify(documentsMissing)) || [];

    documents.forEach((doc) => {
      let index = documentsOrigin.findIndex((e) => e.key === doc.documentKey);
      if (index !== -1) {
        documentsOrigin[index] = {
          key: doc.documentKey,
          originalName: doc.documentFileName || '',
          originalURL:
            (doc.documentUrl && doc.documentUrl.replace(s3Host, '')) || '',
        };
      } else {
        documentsOrigin.push({
          key: doc.documentKey,
          originalName: doc.documentFileName || '',
          originalURL:
            (doc.documentUrl && doc.documentUrl.replace(s3Host, '')) || '',
        });
      }
    });

    if (driverInfo.verificationDocument) {
      let index = documentsOrigin.findIndex(
        (e) => e.key === 'verificationDocument'
      );
      if (index !== -1) {
        documentsOrigin[index] = {
          key: 'verificationDocument',
          originalName: driverInfo.verificationDocumentName || '',
          originalURL: driverInfo.verificationDocument.replace(s3Host, ''),
        };
      } else {
        documentsOrigin.push({
          key: 'verificationDocument',
          originalName: driverInfo.verificationDocumentName || '',
          originalURL: driverInfo.verificationDocument.replace(s3Host, ''),
        });
      }
    }

    if (driverInfo.verificationDocumentBack) {
      let index = documentsOrigin.findIndex(
        (e) => e.key === 'verificationDocumentBack'
      );
      if (index !== -1) {
        documentsOrigin[index] = {
          key: 'verificationDocumentBack',
          originalName: driverInfo.verificationDocumentBackName || '',
          originalURL: driverInfo.verificationDocumentBack.replace(s3Host, ''),
        };
      } else {
        documentsOrigin.push({
          key: 'verificationDocumentBack',
          originalName: driverInfo.verificationDocumentBackName || '',
          originalURL: driverInfo.verificationDocumentBack.replace(s3Host, ''),
        });
      }
    }

    if (driverInfo.additionalDocument) {
      let index = documentsOrigin.findIndex(
        (e) => e.key === 'additionalDocument'
      );
      if (index !== -1) {
        documentsOrigin[index] = {
          key: 'additionalDocument',
          originalName: driverInfo.additionalDocumentName || '',
          originalURL: driverInfo.additionalDocument.replace(s3Host, ''),
        };
      } else {
        documentsOrigin.push({
          key: 'additionalDocument',
          originalName: driverInfo.additionalDocumentName || '',
          originalURL: driverInfo.additionalDocument.replace(s3Host, ''),
        });
      }
    }

    if (driverInfo.additionalDocumentBack) {
      let index = documentsOrigin.findIndex(
        (e) => e.key === 'additionalDocumentBack'
      );
      if (index !== -1) {
        documentsOrigin[index] = {
          key: 'additionalDocumentBack',
          originalName: driverInfo.additionalDocumentBackName || '',
          originalURL: driverInfo.additionalDocumentBack.replace(s3Host, ''),
        };
      } else {
        documentsOrigin.push({
          key: 'additionalDocumentBack',
          originalName: driverInfo.additionalDocumentBackName || '',
          originalURL: driverInfo.additionalDocumentBack.replace(s3Host, ''),
        });
      }
    }

    fields.forEach((item) => {
      if (
        item.key.includes('backup') &&
        item.type === 'File' &&
        driverInfo[item.key]
      ) {
        let keyName = item.key + 'Name';
        let index = documentsOrigin.findIndex((e) => e.key === item.key);
        if (index !== -1) {
          documentsOrigin[index] = {
            key: item.key,
            originalName: driverInfo[keyName] || '',
            originalURL: driverInfo[item.key] || '',
          };
        } else {
          documentsOrigin.push({
            key: item.key,
            originalName: driverInfo[keyName] || '',
            originalURL: driverInfo[item.key] || '',
          });
        }
      }
    });
    return documentsOrigin;
  };

  const handleLicensePlateComboChange = (e) => {
    const { commonData } = props;
    const defaultState = { ...state };

    let isDelivery = false;
    if (
      defaultState?.detailItem.driverInfo.driverType ===
        DriverType.deliveryIndividual ||
      defaultState?.detailItem.driverInfo.driverType ===
        DriverType.deliveryCompany
    ) {
      isDelivery = true;
    }
    if (!e) {
      // clear selected license
      defaultState.detailItem = {
        ...defaultState.detailItem,
        driverInfo: {
          ...defaultState.detailItem.driverInfo,
          company: getCompanyDefault(),
          licensePlate: null,
          vehicleId: null,
          zoneId: [],
        },
      };
      defaultState.vehicleByCompany = {
        limit: LIMIT_LIST_VEHICLE,
        page: 0,
        total: 0,
        search: '',
        list: [],
      };
      defaultState.isVehicleDidntAssign = false;
      defaultState.vehicleOwner = '';
      defaultState.carMake = '';
      defaultState.carModel = '';
      defaultState.year = year;
      defaultState.color = '';
      defaultState.passengers = 0;
      defaultState.luggage = 0;
      defaultState.caseNumber = '';
      defaultState.vhcPhone = '';
      defaultState.licenseExpiry = '';
      defaultState.licensePlate = '';
      defaultState.licensePlateValidation = props.auth.selectedFleet
        .licensePlate
        ? true
        : false;
    } else {
      let zoneId = defaultState.detailItem.driverInfo.zoneId;
      if (e !== defaultState.detailItem.driverInfo.vehicleId) zoneId = [];
      if (!defaultState.vehicleDetails?.[e]) {
        props.findOneVehicle({ vehicleId: e }).then((data) => {
          if (data.ok) {
            let carType = _.get(data.res, 'type.typeId', '');
            if (isDelivery) {
              if (
                commonData.carTypeDelivery.findIndex(
                  (e) => e._id === carType
                ) === -1
              ) {
                carType = undefined;
              }
            }
            let vehicleDetails = defaultState.vehicleDetails;
            vehicleDetails[e] = data.res;
            let isCompanyAssigned =
              defaultState.isExistingCar &&
              data.res &&
              _.get(data.res.company, '_id')
                ? true
                : false;
            isVhicleNoType =
              defaultState.isExistingCar &&
              data.res &&
              _.get(data.res.type, '_id')
                ? false
                : true;
            if (_.get(data.res.company, '_id')) {
              defaultState.vehicleByCompany = {
                ...defaultState.vehicleByCompany,
                companyId: data.res.company._id,
              };
            }

            defaultState.licensePlateValidation = true;
            defaultState.vehicleDetails = vehicleDetails;
            defaultState.detailItem = {
              ...defaultState.detailItem,
              driverInfo: {
                ...defaultState.detailItem.driverInfo,
                licensePlate: data.res.plateNumber,
                vehicleId: data.res._id,
                company: data.res.company
                  ? data.res.company
                  : getCompanyDefault(),
                zoneId: zoneId,
              },
            };
            defaultState.carType = carType;
            defaultState.vehicleOwner = data.res.vehicleOwner
              ? data.res.vehicleOwner
              : '';
            defaultState.carMake = data.res.vehicleModel
              ? data.res.vehicleModel.vehicleMakeId
              : '';
            defaultState.carModel = data.res.vehicleModel
              ? data.res.vehicleModel.modelId
              : '';
            defaultState.year = data.res.year;
            defaultState.color = data.res.color;
            defaultState.passengers = data.res.maxPassengers;
            defaultState.luggage = data.res.maxLuggage;
            defaultState.licenseExpiry = data.res.licenseExp;
            defaultState.licensePlate = data.res.plateNumber;
            defaultState.caseNumber = data.res.caseNumber;
            defaultState.vhcPhone = data.res.phone;
            defaultState.isVehicleDidntAssign = isCompanyAssigned;
          }
        });
      } else {
        let carType = defaultState.vehicleDetails[e].type.typeId
          ? defaultState.vehicleDetails[e].type.typeId
          : '';
        if (isDelivery) {
          if (
            commonData.carTypeDelivery.findIndex((e) => e._id === carType) ===
            -1
          ) {
            carType = undefined;
          }
        }
        let isCompanyAssigned =
          defaultState.isExistingCar &&
          _.get(defaultState.vehicleDetails[e].company, '_id')
            ? true
            : false;
        isVhicleNoType =
          defaultState.isExistingCar &&
          defaultState.vehicleDetails[e].type &&
          _.get(defaultState.vehicleDetails[e].type, '_id')
            ? false
            : true;
        defaultState.licensePlateValidation = true;
        defaultState.detailItem = {
          ...defaultState.detailItem,
          driverInfo: {
            ...defaultState.detailItem.driverInfo,
            company: defaultState.vehicleDetails[e].company,
            licensePlate: defaultState.vehicleDetails[e].plateNumber,
            vehicleId: defaultState.vehicleDetails[e]._id,
            zoneId: zoneId,
          },
        };
        defaultState.carMake = defaultState.vehicleDetails[e].vehicleModel
          ? defaultState.vehicleDetails[e].vehicleModel.vehicleMakeId
          : '';
        defaultState.carModel = defaultState.vehicleDetails[e].vehicleModel
          ? defaultState.vehicleDetails[e].vehicleModel.modelId
          : '';
        defaultState.carType = carType;
        defaultState.year = defaultState.vehicleDetails[e].year;
        defaultState.color = defaultState.vehicleDetails[e].color;
        defaultState.passengers = defaultState.vehicleDetails[e].maxPassengers;
        defaultState.luggage = defaultState.vehicleDetails[e].maxLuggage;
        defaultState.caseNumber = defaultState.vehicleDetails[e].caseNumber;
        defaultState.vhcPhone = defaultState.vehicleDetails[e].phone;
        defaultState.licenseExpiry = defaultState.vehicleDetails[e].licenseExp;
        defaultState.licensePlate = defaultState.vehicleDetails[e].plateNumber;
        defaultState.isVehicleDidntAssign = isCompanyAssigned;
      }
    }

    setState(defaultState);
  };

  const initDriverDocumentExpiryList = (
    documentExpiry,
    relativeInfo,
    defaultState
  ) => {
    if (!relativeInfo) return;
    const driverType = defaultState.detailItem.driverInfo.driverType;

    let documentList = [];
    if (driverType === DriverType.IndividualDriver) {
      documentList = relativeInfo.documents?.individual;
    } else if (driverType === DriverType.OwnerOperator) {
      documentList = relativeInfo.documents?.owner;
    } else if (driverType === DriverType.deliveryIndividual) {
      documentList = relativeInfo.documents?.deliveryIndividual;
    } else if (driverType === DriverType.deliveryCompany) {
      documentList = relativeInfo.documents?.deliveryCompany;
    }

    let driverDocumentExpiry = [];

    if (documentList && documentList.length > 0) {
      _.forEach(documentList, (obj) => {
        let expiryObj = {
          documentKey: obj.keyName,
          documentName: obj.documentName,
        };

        if (documentExpiry && documentExpiry.length) {
          let avaiExpiryDate = documentExpiry.find(
            (item) => item.documentKey == expiryObj.documentKey
          );

          if (avaiExpiryDate && avaiExpiryDate.expiredDate) {
            expiryObj.expiredDate = moment
              .tz(avaiExpiryDate.expiredDate, props.auth.selectedFleet.timezone)
              .format('MM/DD/YYYY');
          }
          if (avaiExpiryDate && avaiExpiryDate.documentId) {
            expiryObj.documentId = avaiExpiryDate.documentId;
          }
          if (avaiExpiryDate && avaiExpiryDate.documentUrl) {
            expiryObj.documentUrl = avaiExpiryDate.documentUrl;
          }
        }
        driverDocumentExpiry.push(expiryObj);
      });
    }

    return driverDocumentExpiry;
  };

  const initDriverFiledList = (relativeInfo, drvType) => {
    if (!relativeInfo) return;
    const driverType = drvType || state.detailItem.driverInfo.driverType;
    let driverFiledList = [];
    switch (driverType) {
      case DriverType.IndividualDriver:
        driverFiledList = _.filter(relativeInfo.fields, {
          availableCompanyCar: true,
        });
        break;
      case DriverType.OwnerOperator:
        driverFiledList = _.filter(relativeInfo.fields, {
          availableIndividualCar: true,
        });
        break;
      case DriverType.deliveryIndividual:
        driverFiledList = _.filter(relativeInfo.fields, {
          deliveryIndividual: true,
        });
        break;
      case DriverType.deliveryCompany:
        driverFiledList = _.filter(relativeInfo.fields, {
          deliveryCompany: true,
        });
        break;
      default:
        break;
    }

    return driverFiledList;
  };

  const handleDriverTypeChange = (e) => {
    let driverType = e.target.value;

    const defaultState = { ...state };
    defaultState.detailItem.driverInfo.driverType = driverType;
    defaultState.detailItem.documentExpiry =
      driverType === defaultState.originDriverType
        ? defaultState.documentExpiry
        : [];
    defaultState.driverDocument = {};
    defaultState.documentDeleteList = [];
    defaultState.carType = '';
    defaultState.valid = {};
    defaultState.isSubmited = false;
    const relativeInfo =
      defaultState?.relativeInfoData || commonData?.relativeInfo;

    defaultState.driverDocumentExpiry = initDriverDocumentExpiryList(
      defaultState.detailItem.documentExpiry,
      relativeInfo,
      defaultState
    );
    defaultState.driverFiledList = initDriverFiledList(
      relativeInfo,
      driverType
    );

    setState(defaultState);
  };

  const validatorCallback = (id, valid, messages) => {
    const defaultState = { ...state };

    if (defaultState.valid && defaultState.valid[id] !== valid) {
      defaultState.valid[id] = valid;
      setState(defaultState);
    }
  };

  const closeDialogForm = () => {
    props.navigate.push('/driver');
  };

  const handleActiveChange = (e) => {
    let value = e.target.value;
    const defaultState = { ...state };
    switch (value) {
      case 'inReviewInProgress':
        defaultState.detailItem.isActive = false;
        defaultState.detailItem.statusDriver = value;
        break;
      case 'inactive':
        defaultState.detailItem.isActive = false;
        defaultState.detailItem.statusDriver = value;
        break;
      case 'active':
        defaultState.detailItem.isActive = true;
        defaultState.detailItem.statusDriver = value;
        break;
      default:
        break;
    }

    setState(defaultState);
  };

  const handleChangeImages = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const files = [...e.target.files];
    let newImages = {};
    const maxSize = 8 * 1024 * 1024;
    let error = false;

    files.forEach((file) => {
      if (file.size > maxSize) {
        error = true;
        return;
      }

      newImages = file;
    });

    if (error)
      return context.notification('error', I18n.t('driver.maxAvatarSizeFiles'));

    let defaultState = { ...state } || {};
    defaultState.detailItem.avatar = newImages;
    setState(defaultState);
  };

  const handleDeleteImages = () => {
    let defaultState = { ...state } || {};
    defaultState.detailItem.avatar = null;
    setState(defaultState);
  };

  const handleVisibilityImages = (isZoom) => {
    const defaultState = { ...state };
    defaultState.zoomImage = isZoom;
    setState(defaultState);
  };

  const handleServiceTypeChange = (e) => {
    let serviceType = e.target.value;
    const defaultState = { ...state };
    defaultState.detailItem.driverInfo.serviceType = serviceType;
    defaultState.detailItem.driverInfo.driverType = '';
    setState(defaultState);
  };

  const getCompanyDefault = () => {
    let company = null;
    if (getSuppilerPermission(props.auth)) {
      company = {
        companyId: props.auth?.user?.roles?.companyId,
      };
    }
    return company;
  };

  const checkTabError = () => {
    if (state.valid[state.activeTabKey]) {
      var errorTab = props.auth.selectedFleet.currencies.find((c, i) => {
        return !state.valid[c.iso];
      });
      if (errorTab) {
        return errorTab.iso;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getVehiclesByType = async (companyId) => {
    let queryObject = {
      fleetId: props.auth.selectedFleet.fleetId,
      isActive: true,
      page: 0,
      limit: LIMIT_LIST_VEHICLE,
      search: '',
    };

    if (companyId) queryObject.companyId = companyId;

    if (state?.vehicleByCompany?.vehicleType) {
      queryObject.vehicleType = state.vehicleByCompany.vehicleType;
    }

    const data = await props.loadMoreVehiclesByType(queryObject);
    if (data.ok && data.res && data.res.list) {
      return data;
    } else {
      return null;
    }
  };

  const handleCompanyChange = async (companyId) => {
    const defaultState = { ...state };
    let zoneId = defaultState.detailItem.driverInfo.zoneId;
    if (defaultState.detailItem.driverInfo.company) {
      if (companyId != defaultState.detailItem.driverInfo.company.companyId) {
        zoneId = [];
      }
    }

    defaultState.detailItem.driverInfo.company = companyId
      ? { companyId }
      : getCompanyDefault();
    defaultState.detailItem.driverInfo.zoneId = zoneId;

    const resultCompanyVehicle = await getVehiclesByType(companyId);

    if (resultCompanyVehicle) {
      let licensePlateValidation = props.auth.selectedFleet.licensePlate
        ? true
        : false;
      defaultState.vehicleByCompany = {
        ...defaultState.vehicleByCompany,
        ...resultCompanyVehicle.res,
        search: '',
        companyId: companyId || '',
      };
      defaultState.licensePlateValidation = licensePlateValidation;
    }

    setCompanyChanged(
      initInfo?.company?.companyId &&
        defaultState?.detailItem?.driverInfo?.company?.companyId !==
          initInfo?.company?.companyId
    );
    setState(defaultState);
  };

  const initVehicleUpdate = (vehDetail) => {
    let typeId = isVhicleNoType ? state.carType : vehDetail.type.typeId;
    return {
      fleetId: props.auth.selectedFleet.fleetId,
      companyId: state.detailItem.driverInfo.company.companyId,
      vehicleId: state.detailItem.driverInfo.vehicleId,
      typeId: typeId,
      makeId:
        vehDetail && vehDetail.vehicleModel
          ? vehDetail.vehicleModel.vehicleMakeId
          : '',
      modelId:
        vehDetail && vehDetail.vehicleModel
          ? vehDetail.vehicleModel.modelId
          : '',
      licenseExp: vehDetail && vehDetail.licenseExp ? vehDetail.licenseExp : '',
      isActive: vehDetail && vehDetail.isActive ? vehDetail.isActive : '',
      listRoles: vehDetail && vehDetail.listRoles ? vehDetail.listRoles : '',
      caseNumber: vehDetail && vehDetail.caseNumber ? vehDetail.caseNumber : '',
      plateNumber:
        vehDetail && vehDetail.plateNumber ? vehDetail.plateNumber : '',
      hwMetered: vehDetail && vehDetail.hwMetered ? vehDetail.hwMetered : false,
      maxPassengers:
        vehDetail && vehDetail.maxPassengers ? vehDetail.maxPassengers : 0,
      maxLuggage: vehDetail && vehDetail.year ? vehDetail.year : 0,
      color: vehDetail && vehDetail.color ? vehDetail.color : '',
      phone: vehDetail && vehDetail.phone ? vehDetail.phone : '',
      year: vehDetail && vehDetail.year ? vehDetail.year : '',
      vhcId: vehDetail && vehDetail.vhcId ? vehDetail.vhcId : '',
    };
  };

  const uploadStripeVerificationDocument = async (file) => {
    const stripe = window.Stripe(state.relativeInfoData.stripe.publicKey);
    let formData = new FormData();
    formData.append('file', file);
    formData.append('purpose', 'identity_document');
    return fetch('https://uploads.stripe.com/v1/files', {
      method: 'POST',
      headers: { Authorization: `Bearer ${stripe._apiKey}` },
      body: formData,
    }).then(async (response) => {
      const o = await response.json();
      return o.id;
    });
  };

  const uploadStripeAdditionalDocument = async (file) => {
    const stripe = window.Stripe(state.relativeInfoData.stripe.publicKey);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('purpose', 'additional_verification');
    return fetch('https://uploads.stripe.com/v1/files', {
      method: 'POST',
      headers: { Authorization: `Bearer ${stripe._apiKey}` },
      body: formData,
    }).then(async (response) => {
      const o = await response.json();
      return o.id;
    });
  };

  const handleZoneChange = (value, action) => {
    const newValue = value.map((item) => item.value);
    const defaultState = { ...state };
    defaultState.detailItem.driverInfo.zoneId = newValue;
    setState(defaultState);
  };

  const handleNoteChange = (notes) => {
    let defaultNoteList = [...noteList];
    defaultNoteList = notes;
    setNoteList(defaultNoteList);
  };

  const handleTopUpOtherDriverChange = (e) => {
    const defaultState = { ...state };
    defaultState.detailItem.driverInfo.hasTopUpOtherDriver = e.target.checked;
    setState(defaultState);
  };

  const handleUsernameChange = (e) => {
    let username = e.target.value;
    const defaultState = { ...state };
    defaultState.detailItem = {
      ...defaultState.detailItem,
      username: username,
    };
    setState(defaultState);
  };

  const handleAcceptChange = (e, keyName) => {
    const valueParse = (e.target.value || '')
      .toString()
      .replace(/[^.0-9]+/, '');
    let valueParseFloat = parseFloat(valueParse);
    if (isNaN(valueParseFloat)) valueParseFloat = 0;
    const defaultState = { ...state };
    defaultState.detailItem = {
      ...defaultState.detailItem,
      [keyName]: valueParseFloat,
    };
    setState(defaultState);
  };

  const handleTopDriverChange = (e) => {
    const defaultState = { ...state };
    defaultState.detailItem.rank = e.target.checked ? 1 : 0;
    setState(defaultState);
  };

  const handleRideSharingChange = (e) => {
    const defaultState = { ...state };
    defaultState.detailItem.rideSharing = e.target.checked;
    setState(defaultState);
  };

  const handleConfirmCloseClick = () => {
    const defaultState = { ...state };
    defaultState.confirm = null;
    defaultState.isShowDriverDeposit = false;
    defaultState.isShowCashWalletBalance = false;
    defaultState.vhcPhoneValidation = true;
    setState(defaultState);
  };

  const handleCreditBalanceSuccess = (creditBalances) => {
    const defaultState = { ...state };
    let {
      detailItem: { driverWallet = {} },
      selectedCurrency,
    } = defaultState;

    driverWallet.creditWallet = _.find(
      creditBalances,
      (o) => o.currencyISO === selectedCurrency
    );

    defaultState.confirm = null;
    defaultState.isShowDriverDeposit = false;
    defaultState.detailItem = {
      ...defaultState.detailItem,
      driverInfo: {
        ...defaultState.detailItem.driverInfo,
        creditBalances,
      },
      driverWallet,
    };
    setState(defaultState);
    // props.onUpdateDriver(
    //   { userId: defaultState.detailItem.userId, driverWallet },
    //   true
    // );
  };

  const handleResetPasswordClick = () => {
    const defaultState = { ...state };
    defaultState.confirm = {
      id: 'RESET_PASSWORD',
      title: I18n.t('user.Reset_password'),
      body: I18n.t('user.CONFIRM_RESET_PASSWORD'),
      buttonTitle: 'OK',
    };
    setState({ ...defaultState });
  };

  const handleChangeCashBalanceSuccess = (cashBalance) => {
    const defaultState = { ...state };
    let {
      detailItem: { driverWallet = {} },
    } = defaultState;
    if (!cashBalance || !cashBalance.newBalance) return;
    if (driverWallet.cashWallet && driverWallet.cashWallet.currentBalance) {
      driverWallet.cashWallet.currentBalance = cashBalance.newBalance;
    } else {
      driverWallet = {
        cashWallet: {
          currentBalance: cashBalance.newBalance,
          currencyISO: cashBalance.currencyISO,
        },
      };
    }

    defaultState.confirm = null;
    defaultState.isShowCashWalletBalance = false;
    defaultState.detailItem = {
      ...defaultState.detailItem,
      driverWallet,
    };
    setState(defaultState);
    // props.onUpdateDriver(
    //   {
    //     userId: defaultState.detailItem.userId,
    //     driverWallet,
    //   },
    //   true
    // );
  };

  const handleCommissionTypeChangeAndValue = (e, serviceType = '', key) => {
    let regrex = /^(\d*\.?\d{0,2})$/;
    const defaultState = { ...state };
    //if(key == 'value' && !regrex.test(e.target.value)) return
    let commissionDriverValue =
      defaultState.detailItem.driverInfo.commissionDriverValue;
    let newValueCommissions = [];
    if (serviceType) {
      newValueCommissions = commissionDriverValue.map((commission) => {
        if (
          commission.serviceType === serviceType &&
          (key === 'type' || (key !== 'type' && regrex.test(e.target.value)))
        ) {
          return {
            ...commission,
            [key]: e.target.value,
          };
        }
        return commission;
      });
      if (newValueCommissions.length > 0) {
        defaultState.detailItem.driverInfo.commissionDriverValue =
          newValueCommissions;
        setState(defaultState);
      }
    }
  };

  const saveCompanyCommissionEdit = () => {
    const defaultState = { ...state };
    const resultCommission = handleCommissionCompanyChange(
      defaultState.detailItem.driverInfo.commissionCompanyValue,
      'value'
    );
    resultCommission.showCompanyCommission = false;
    setState(resultCommission);
  };

  const handleCommissionCompanyChange = (data, key = 'type') => {
    const defaultState = { ...state };
    if (key == 'value') {
      defaultState.detailItem.driverInfo.commissionCompanyValue = data.filter(
        function (c) {
          return c.value !== 'N/A';
        }
      );
      return defaultState;
    } else {
      defaultState.detailItem.driverInfo.commissionCompanyType =
        data.target.value;
    }
    setState(defaultState);
  };

  const showCompanyCommissionClick = () => {
    const defaultState = { ...state };
    defaultState.showCompanyCommission = !defaultState.showCompanyCommission;
    setState(defaultState);
  };

  const handleCompanyCommissionTypeChangeAndValue = (
    e,
    serviceType = '',
    key
  ) => {
    let regrex = /^(\d*\.?\d{0,2})$/;
    const defaultState = { ...state };
    let commissionCompanyValue =
      defaultState.detailItem.driverInfo.commissionCompanyValue;
    let newValueCommissions = [];
    if (serviceType) {
      newValueCommissions = commissionCompanyValue.map((commission) => {
        if (
          commission.serviceType === serviceType &&
          (key === 'type' || (key !== 'type' && regrex.test(e.target.value)))
        ) {
          return {
            ...commission,
            [key]: e.target.value,
          };
        }
        return commission;
      });
      if (newValueCommissions.length > 0) {
        defaultState.detailItem.driverInfo.commissionCompanyValue =
          newValueCommissions;

        setState(defaultState);
      }
    }
  };

  const handleSelectVehicleChange = (e) => {
    setState({
      ...state,
      detailItem: {
        ...state.detailItem,
        driverInfo: {
          ...state.detailItem.driverInfo,
          allowDriverSelectVehicleForBooking: e.target.checked,
        },
      },
    });
  };

  const handleStripeAccountIdChange = (e) => {
    const defaultState = { ...state };
    defaultState.stripeAccountId = e.target.value;
    setState(defaultState);
  };

  const handleNameOfBankChange = (e) => {
    let nameOfBank = e.target.value;
    const defaultState = { ...state };
    defaultState.detailItem.driverInfo.nameOfBank = nameOfBank;
    setState(defaultState);
  };

  const handleNameOfAccountChange = (e) => {
    let nameOfAccount = e.target.value;
    const defaultState = { ...state };
    defaultState.detailItem.driverInfo.nameOfAccount = nameOfAccount;
    setState(defaultState);
  };

  const handleRTBNumberChange = (routingNumber) => {
    const defaultState = { ...state };
    defaultState.detailItem.driverInfo.routingNumber = routingNumber;
    setState(defaultState);
  };

  const handleAccountNumberChange = (e) => {
    let accountNumber = e.target.value;
    const defaultState = { ...state };
    defaultState.detailItem.driverInfo.accountNumber = accountNumber;
    setState(defaultState);
  };

  const handleIFSCCodeChange = (e) => {
    let IFSCCode = e.target.value;
    const defaultState = { ...state };
    defaultState.detailItem.driverInfo.IFSCCode = IFSCCode;
    setState(defaultState);
  };

  const handleCheckNumberChange = (e) => {
    if (Validation.isDigitOnly(e.target.value) || e.target.value === '') {
      const defaultState = { ...state };
      defaultState.detailItem.driverInfo.checkNumber = e.target.value;
      setState(defaultState);
    }
  };

  const handleSwiftCodeNumberChange = (e) => {
    const defaultState = { ...state };
    defaultState.detailItem.driverInfo.swiftCodeNumber = e.target.value;
    setState(defaultState);
  };

  const handleSSNChange = (e) => {
    let ssn = e.target.value;
    if (ssn.length <= 50) {
      const defaultState = { ...state };
      defaultState.detailItem.driverInfo.ssn = ssn;
      setState(defaultState);
    }
  };

  const handleSortCodeChange = (e) => {
    let sortCode = e.target.value;
    if (reg.test(sortCode) || sortCode.trim() === '') {
      if (sortCode.length <= 50) {
        const defaultState = { ...state };
        defaultState.detailItem.driverInfo.sortCode = sortCode;
        setState(defaultState);
      }
    }
  };

  const handleVerificationDocumentChange = (data, error) => {
    if (error) {
      context.notification('error', I18n.t('driver.' + error));
    }
    const defaultState = { ...state };
    defaultState.verificationDocument = data ? data.file : null;
    setState(defaultState);
  };

  const handleVerificationDocumentBackChange = (data, error) => {
    if (error) {
      context.notification('error', I18n.t('driver.' + error));
    }

    const defaultState = { ...state };
    defaultState.verificationDocumentBack = data ? data.file : null;
    setState(defaultState);
  };

  const handleAdditionalDocumentChange = (data, error) => {
    if (error) {
      context.notification('error', I18n.t('driver.' + error));
    }

    const defaultState = { ...state };
    defaultState.additionalDocument = data ? data.file : null;
    setState(defaultState);
  };

  const handleAdditionalDocumentBackChange = (data, error) => {
    if (error) {
      context.notification('error', I18n.t('driver.' + error));
    }

    const defaultState = { ...state };
    defaultState.additionalDocumentBack = data ? data.file : null;
    setState(defaultState);
  };

  const handleRemoveBankInfo = () => {
    const defaultState = { ...state };
    defaultState.confirm = {
      id: 'REMOVE_BANK_INFOR',
      title: I18n.t('driver.REMOVE_BANKING_INFOR'),
      body: I18n.t('driver.CONFIRM_REMOVE_BANKING_INFOR'),
      buttonTitle: I18n.t('newbooking.Yes'),
      closeButtonText: I18n.t('newbooking.No'),
    };
    setState(defaultState);
  };

  const handleRemoveBankDocumentChange = (key) => {
    const defaultState = { ...state };
    defaultState.detailItem = {
      ...defaultState.detailItem,
      driverInfo: {
        ...defaultState.detailItem.driverInfo,
        [key]: '',
      },
    };
    defaultState[key] = '';
    setState(defaultState);
  };

  const handeChangeIsBankAccountOwner = (e) => {
    const value = e.target.value === 'true';
    const defaultState = { ...state };
    defaultState.detailItem.driverInfo.isBankAccountOwner = value;
    setState(defaultState);
  };

  const handleChangebeneficiary = (key, e) => {
    let value = e.target.value;
    const defaultState = { ...state };
    defaultState.detailItem.driverInfo[key] = value;
    setState(defaultState);
  };

  const doResetPassword = () => {
    props
      .resestPassword({
        userId: props.params.driverId,
        fleetId: props.auth.selectedFleet.fleetId,
      })
      .then((data) => {
        const defaultState = { ...state };
        defaultState.confirm = null;
        setState(defaultState);
        if (data.ok) {
          context.notification('info', I18n.t('driver.Reset_password_success'));
        } else {
          context.notification('error', I18n.t('errors.undefined'));
        }
      });
  };

  const doRemoveBankInfo = () => {
    const {
      auth: {
        selectedFleet: { fleetId },
      },
      params: { driverId },
    } = props;
    props.removeBankInfo({ fleetId, driverId }).then(({ res }) => {
      const detailItem = _.assign({}, state.detailItem);
      const bankInfo = {
        nameOfBank: '',
        nameOfAccount: '',
        routingNumber: '',
        accountNumber: '',
        checkNumber: '',
        ssn: '',
        swiftCodeNumber: '',
        verificationDocument: '',
        additionalDocument: '',
        verificationDocumentBack: '',
        additionalDocumentBack: '',
        isBankVerified: false,
      };
      detailItem.driverInfo = _.assign({}, detailItem.driverInfo, bankInfo);
      detailItem.achTokenVerified = false;
      const defaultState = { ...state };
      defaultState.detailItem = detailItem;
      defaultState.confirm = null;
      setState(defaultState);
    });
  };

  const handleConfirmButtonClick = (id) => {
    switch (id) {
      case 'RESET_PASSWORD': {
        doResetPassword();
        break;
      }
      case 'REMOVE_BANK_INFOR': {
        doRemoveBankInfo();
        break;
      }
      default:
        break;
    }
  };

  const handleDriverInfoBackupFieldChange = (data, error, keyName) => {
    const defaultState = { ...state };
    if (data) {
      defaultState.driverInfoDynamic = {
        ...defaultState.driverInfoDynamic,
        [keyName]: data.file,
      };
    } else {
      if (error) {
        context.notification('error', I18n.t('driver.' + error));
        defaultState.driverInfoDynamic = {
          ...defaultState.driverInfoDynamic,
          [keyName]: null,
        };
      }
    }
    setState(defaultState);
  };

  const handleRemoveDriverInfoBackupFieldChange = (keyName) => {
    const defaultState = { ...state };
    const { driverInfoDynamic } = defaultState;
    defaultState.driverInfoDynamic = {
      ...driverInfoDynamic,
      [keyName]: '',
    };
    setState(defaultState);
  };

  const handleCommissionTypeChange = (e) => {
    let commissionByCurrencies = [];
    const defaultState = { ...state };

    let valid = defaultState.valid;
    props.auth.selectedFleet &&
      props.auth.selectedFleet.currencies.map((item) => {
        commissionByCurrencies.push({
          commissionValue: 0,
          currencyISO: item.iso,
        });
        valid[item.iso] = true;
      });
    valid['percentCommission'] = true;
    defaultState.detailItem.driverInfo.commissionType = e.target.value;
    defaultState.detailItem.driverInfo.commissionByCurrencies =
      commissionByCurrencies;
    defaultState.commission = 0;
    defaultState.valid = valid;
    setState(defaultState);
  };

  const handleLoadNotes = async () => {
    const params = {
      str: '',
      fleetId: props.auth.selectedFleet.fleetId,
      limit: 9999,
      page: 0,
    };
    const resultNotes = await props.settingActions.driverNoteAutocomplete(
      params
    );

    if (resultNotes?.res?.list?.length > 0) {
      setNoteOptions(
        resultNotes.res.list.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        })
      );
    }
  };

  const handleValidTabs = async () => {
    const defaultValidTab = {
      info: false,
      document: false,
      payment: false,
      setting: false,
    };
    const { selectedFleet } = props.auth;

    const defaultState = { ...state };

    const {
      detailItem,
      driverFiledList,
      documentsOrigin,
      isSubmited,
      valid,
      stripeAccountId,
      enableStripeConnect,
      accountConnectedStripe,
      bankRequirement,
    } = defaultState;
    const statusDriver = defaultState.detailItem.statusDriver;

    if (enableStripeConnect) {
      if (!stripeAccountId) {
        if (accountConnectedStripe) {
          defaultValidTab.payment = true;
        }
      } else {
        const dataResponse = await props.settingActions.checkExistingStripe({
          fleetId: selectedFleet.fleetId,
          token: stripeAccountId,
        });
        const status = _.get(dataResponse, 'res.response.status', '');
        if (status === 'activated') {
          defaultState.stripeAccountIdVerified = stripeAccountId;
        } else {
          props.loadingBarActions.hideLoadingSpiner();
          if (status === 'pending') {
            defaultValidTab.payment = true;
          }
          defaultValidTab.payment = true;
        }
      }
    }

    valid.serviceType = true;
    valid.driverType = true;

    valid.type = true;
    valid.licensePlate = true;

    if (props.action === 'add') {
      valid.zone = true;
      valid.vehicle = true;
    } else if (detailItem.driverInfo.zoneId.length > 0) {
      valid.zone = true;
    }

    let dataValid = Object.assign({}, valid);
    let keys = Object.keys(dataValid);
    let dataDetailItem = detailItem;
    let documentsMissing = [];

    if (!isSubmited) {
      defaultState.isSubmited = true;
    }

    let newDriverFiledList = driverFiledList.filter((item) => {
      return item.type !== 'File';
    });

    let fieldFileList = driverFiledList.filter((item) => {
      return item.type === 'File' && item.isRequired;
    });

    let listFile = [];
    listFile = keys.filter(
      (key) => key.toLowerCase().search('document') !== -1
    );
    if (fieldFileList.length > 0) {
      fieldFileList.forEach((field) => {
        listFile.push(field.key);
      });
    }

    if (listFile.length > 0) {
      listFile.forEach((key) => {
        if (!dataValid[key]) {
          let original = documentsOrigin.find((doc) => doc.key === key);
          documentsMissing.push({
            key,
            originalName: original ? original.originalName : '',
            originalURL: original ? original.originalURL : '',
          });
        }
      });
    }

    if (_.get(selectedFleet, 'generalSetting.compulsoryCarInsurance', false)) {
      newDriverFiledList = [
        ...newDriverFiledList,
        { key: 'policyNumber' },
        { key: 'expiredDate' },
        { key: 'effectiveDate' },
      ];
    }

    if (!_.get(detailItem, 'driverInfo.driverType', '')) {
      defaultValidTab.info = true;
    }
    const keysArr = Object.keys(valid);

    const listKeyOfBank = [
      'nameOfBank',
      'IFSCCode',
      'nameOfAccount',
      'routingNumber',
      'accountNumber',
      'checkNumber',
      'ssn',
      'sortCode',
      'verificationDocument',
      'verificationDocumentBack',
      'additionalDocument',
      'additionalDocumentBack',
      'beneficiaryIDIC',
      'bankRelationship',
      'relationshipOtherName',
    ];

    if (!CCLiteCommonFunc.isFormValidDriver(valid, newDriverFiledList)) {
      if (valid.zone === false) {
        defaultValidTab.setting = true;
      }
      const keysArrInfo = keysArr.filter(
        (item) => !listKeyOfBank.includes(item)
      );

      const removeDocumnetArr = keysArrInfo.filter(
        (key) => !key.includes('document') && key !== 'zone'
      );

      for (let item of removeDocumnetArr) {
        if (typeof valid[item] === 'boolean' && valid[item] === false) {
          defaultValidTab.info = true;
          break;
        }
      }
    }

    const keysArrBank = keysArr.filter((item) => listKeyOfBank.includes(item));
    for (let item of keysArrBank) {
      if (valid[item] === false) {
        defaultValidTab.payment = true;
        break;
      }
    }

    if (
      documentsMissing.filter(
        (item) => !listKeyOfBank.includes(item?.key) && item.key !== 'avatar'
      )?.length > 0
    ) {
      defaultValidTab.document = true;
    }

    // handleSaveDriverClick function
    const { auth } = props;
    let results = keys.filter((key) => key.search('document') !== -1);
    let dataValidDocuments = {};
    const companyCommission = _.get(
      auth,
      'selectedFleet.generalSetting.companyCommission',
      false
    );

    if (results.length > 0) {
      for (var i = 0; i < results.length; i++) {
        dataValidDocuments[results[i]] = dataValid[results[i]];
      }
    }

    if (!_.get(detailItem, 'driverInfo.driverType', '')) {
      defaultValidTab.info = true;
    }
    let validBannkAccountOwner = _.pick(dataValid, [
      'beneficiaryIDIC',
      'bankRelationship',
      'relationshipOtherName',
    ]);
    if (
      !CCLiteCommonFunc.isFormValid(validBannkAccountOwner) &&
      !detailItem.driverInfo.isBankAccountOwner
    ) {
      defaultValidTab.payment = true;
    }

    if (
      !CCLiteCommonFunc.isFormValidDriver(
        dataValid,
        defaultState.driverFiledList
      ) &&
      defaultState.detailItem.driverInfo.statusReview === 'completed'
    ) {
      if (dataValid.zone === false) {
        defaultValidTab.setting = true;
      }
    }

    if (
      !CCLiteCommonFunc.isFormValidDocument(
        dataValidDocuments,
        defaultState.driverDocumentExpiry
      ) &&
      defaultState.detailItem.driverInfo.statusReview === 'completed'
    ) {
    }

    if (
      !(defaultState?.vehicleIds?.length > 0) && 
      props.params?.driverId && 
      !defaultState.switchToAnyVehicle &&
      !companyChanged
    ) {
      defaultValidTab.vehicle = true
    }
    setValidTab(defaultValidTab);
    return defaultValidTab
  };

  const saveDialogForm = async () => {
    const defaultState = { ...state };
    const statusDriver = defaultState.detailItem.statusDriver;
    const checkValidTabs = await handleValidTabs();
    if (statusDriver !== 'inReviewInProgress' && Object.values(checkValidTabs).includes(true)) {
      context.notification('error', I18n.t('errors.missing_some_field'));
      return;
    }
    const { selectedFleet } = props.auth;

    const { auth } = props;

    const {
      detailItem,
      driverFiledList,
      documentsOrigin,
      isSubmited,
      valid,
      stripeAccountId,
      enableStripeConnect,
      accountConnectedStripe,
    } = defaultState;

    const s3Host = auth.selectedFleet.s3Host || '';
    const groupBrand = auth.selectedFleet.syncPaxProfile || { enable: false };
    const companyCommission = _.get(
      auth,
      'selectedFleet.generalSetting.companyCommission',
      false
    );

    if (statusDriver != 'inReviewInProgress') {
      if (enableStripeConnect) {
        if (!stripeAccountId && accountConnectedStripe) {
          context.notification(
            'error',
            'Can not remove existed stripe account id'
          );
          return;
        }

        if (stripeAccountId) {
          const dataResponse = await props.settingActions.checkExistingStripe({
            fleetId: selectedFleet.fleetId,
            token: stripeAccountId,
          });
          const status = _.get(dataResponse, 'res.response.status', '');
          if (status === 'activated') {
            defaultState.stripeAccountIdVerified = stripeAccountId;
          } else {
            props.loadingBarActions.hideLoadingSpiner();
            if (status === 'pending') {
              context.notification(
                'error',
                'The stripe account id you provided is in-review, please try again'
              );
              return;
            }
            context.notification(
              'error',
              'The stripe account id you provided is incorrect, please try again'
            );
            return;
          }
        }
      }

      valid.serviceType = true;
      valid.driverType = true;

      valid.type = true;
      valid.licensePlate = true;

      if (props.action === 'add') {
        valid.zone = true;
        valid.vehicle = true;
      } else if (detailItem.driverInfo.zoneId.length > 0) {
        valid.zone = true;
      }

      if (
        props?.auth?.user?.roles?.isSupplier &&
        defaultState?.detailItem?.driverInfo?.company?.companyId
      ) {
        valid.company = true;
      }

      let dataValid = Object.assign({}, valid);
      let keys = Object.keys(dataValid);
      let dataDetailItem = detailItem;
      let documentsMissing = [];

      if (!isSubmited) {
        defaultState.isSubmited = true;
      }

      let newDriverFiledList = driverFiledList.filter((item) => {
        return item.type !== 'File';
      });

      let fieldFileList = driverFiledList.filter((item) => {
        return item.type === 'File' && item.isRequired;
      });

      let listFile = [];
      listFile = keys.filter(
        (key) => key.toLowerCase().search('document') !== -1
      );
      if (fieldFileList.length > 0) {
        fieldFileList.forEach((field) => {
          listFile.push(field.key);
        });
      }
   

      const checkValidUserName =
        auth.selectedFleet &&
        auth.selectedFleet.password &&
        auth.selectedFleet.password.driver &&
        auth.selectedFleet.password.regBy == 1
          ? true
          : false;
      if (checkValidUserName && !valid.username) {
        context.notification('error', I18n.t('errors.missing_some_field'));
        setState(defaultState);

        return;
      }

      if (
        !(defaultState?.vehicleIds?.length > 0) &&
        props.params?.driverId &&
        !defaultState.switchToAnyVehicle &&
        !companyChanged
      ) {
        context.notification('error', I18n.t('errors.missing_some_field'));
        return;
      }

      if (!CCLiteCommonFunc.isFormValidDriver(valid, newDriverFiledList)) {
        context.notification('error', I18n.t('errors.missing_some_field'));
        const result = checkTabError();
        if (result) {
          defaultState.activeTabKey = result;
        }

        if (
          _.get(selectedFleet, 'generalSetting.compulsoryCarInsurance', false)
        ) {
          newDriverFiledList = [
            ...newDriverFiledList,
            { key: 'policyNumber' },
            { key: 'expiredDate' },
            { key: 'effectiveDate' },
          ];
        }

        if (!_.get(detailItem, 'driverInfo.driverType', '')) {
          context.notification(
            'error',
            I18n.t('driver.ERROR_INPUT_DRIVER_TYPE')
          );
          setState(defaultState);

          return;
        }

        let isRequiredAvatar = false;

        if (defaultState.relativeInfoData && defaultState.driverFiledList) {
          const avatar = defaultState.driverFiledList.find((item) => {
            return item.key === 'avatar';
          });
          if (avatar) {
            isRequiredAvatar = avatar.isRequired;
          }
        }
        if (isRequiredAvatar && !_.get(detailItem, 'avatar', '')) {
          context.notification('error', I18n.t('errors.missing_some_field'));
          setState(defaultState);
          return;
        }

        const checkValidUserName =
          auth.selectedFleet &&
          auth.selectedFleet.password &&
          auth.selectedFleet.password.driver &&
          auth.selectedFleet.password.regBy == 1
            ? true
            : false;
        if (checkValidUserName && !valid.username) {
          context.notification('error', I18n.t('errors.missing_some_field'));
          setState(defaultState);

          return;
        }

        if (!(defaultState?.vehicleIds?.length > 0) && props.params?.driverId) {
          context.notification('error', I18n.t('errors.missing_some_field'));
          setState(defaultState);
          return;
        }

        if (!CCLiteCommonFunc.isFormValidDriver(valid, newDriverFiledList)) {
          context.notification('error', I18n.t('errors.missing_some_field'));
          const result = checkTabError();
          if (result) {
            defaultState.activeTabKey = result;
          }
          setState(defaultState);

          document.getElementById('page-content').scrollTop = 0;
          return;
          // }
        }

        if (
          documentsMissing.length === 0 &&
          statusDriver === 'inReviewInProgress'
        ) {
          defaultState.detailItem.statusDriver = 'active';
          dataDetailItem.driverInfo.statusReview = 'completed';
          dataDetailItem.isActive = true;
        } else {
          switch (statusDriver) {
            case 'inReviewInProgress':
              dataDetailItem.driverInfo.statusReview = 'inProgress';
              dataDetailItem.isActive = false;
              break;
            case 'inactive':
              if (dataDetailItem.driverInfo.statusReview !== 'completed') {
                dataDetailItem.driverInfo.statusReview = 'inComplete';
                dataDetailItem.isActive = false;
              } else {
                dataDetailItem.driverInfo.statusReview = 'completed';
                dataDetailItem.isActive = false;
              }
              break;
            case 'active':
              dataDetailItem.driverInfo.statusReview = 'completed';
              dataDetailItem.isActive = true;
              break;
            default:
              break;
          }
        }

        if (props.action === 'add') {
          dataDetailItem.driverInfo.statusReview = 'inComplete';
        }

        defaultState.detailItem = dataDetailItem;
        defaultState.documentsMissing = documentsMissing;



        // handleSaveDriverClick function
        let results = keys.filter((key) => key.search('document') !== -1);
        let dataValidDocuments = {};

        // SL-20827: Filter data document to validate
        if (results.length > 0) {
          for (var i = 0; i < results.length; i++) {
            dataValidDocuments[results[i]] = dataValid[results[i]];
          }
        }

        // KAN-1456: Hide required documents if car type is 'Package Delivery' or 'Shop For You'
        // if (
        //   carType &&
        //   (auth.selectedFleet.fleetId === 'gojosg' || auth.selectedFleet.fleetId === 'demo-gojo')
        // ) {
        //   let carTypeIndex = commonData.carTypeDelivery.findIndex(e => e._id === carType);
        //   if (carTypeIndex !== -1) {
        //     for (var i = 0; i < results.length; i++) {
        //       delete dataValidDocuments[results[i]]
        //     }
        //   }
        // };

        if (!_.get(detailItem, 'driverInfo.driverType', '')) {
          context.notification(
            'error',
            I18n.t('driver.ERROR_INPUT_DRIVER_TYPE')
          );
          setState(defaultState);
          return;
        }
        let validBannkAccountOwner = _.pick(dataValid, [
          'beneficiaryIDIC',
          'bankRelationship',
          'relationshipOtherName',
        ]);
        if (
          !CCLiteCommonFunc.isFormValid(validBannkAccountOwner) &&
          !detailItem.driverInfo.isBankAccountOwner
        ) {
          context.notification('error', I18n.t('errors.missing_some_field'));
          setState(defaultState);
          return;
        }

        if (
          !CCLiteCommonFunc.isFormValidDriver(
            dataValid,
            defaultState.driverFiledList
          ) &&
          defaultState.detailItem.driverInfo.statusReview === 'completed'
        ) {
          context.notification('error', I18n.t('errors.missing_some_field'));
          const result = checkTabError();
          if (result) {
            defaultState.activeTabKey = result;
          }
          setState(defaultState);
          document.getElementById('page-content').scrollTop = 0;
          return;
        }

        if (
          !CCLiteCommonFunc.isFormValidDocument(
            dataValidDocuments,
            defaultState.driverDocumentExpiry
          ) &&
          defaultState.detailItem.driverInfo.statusReview === 'completed'
        ) {
          document.getElementById('page-content').scrollTop = 0;
          setState(defaultState);
          return;
        }
      }
    }

    var data = new FormData();
    data.append('fleetId', props.auth.selectedFleet.fleetId);
    props.params.driverId && data.append('driverId', props.params.driverId);
    data.append('driverType', defaultState.detailItem.driverInfo.driverType);
    data.append('action', 'add');
    if (typeof defaultState.detailItem?.avatar !== 'string') {
      defaultState.detailItem?.avatar &&
        data.append('avatar', defaultState.detailItem.avatar);
    }
    data.append('username', defaultState.detailItem.username);
    if (defaultState.stripeAccountIdVerified) {
      data.append(
        'stripeAccountIdVerified',
        defaultState.stripeAccountIdVerified
      );
    }
    if (groupBrand.enable) {
      data.append('groupId', groupBrand.groupId);
    }
    _.forEach(defaultState.driverFiledList, (item) => {
      if (item.key !== 'company') {
        let dbKey =
          DriverInfoFields[item.key] && DriverInfoFields[item.key].dbKey
            ? DriverInfoFields[item.key].dbKey
            : item.key;
        let value = defaultState.driverInfoDynamic[item.key];

        /**
         * backup10 used for the EVP expiry date (Mycar)
         */
        if (value && item.type === 'Date') {
          value = moment(value).format('MM/DD/YYYY');
        }

        if (item.type === 'Phone') {
          //hardcode for mycar
          if (
            props.auth.selectedFleet.fleetId === 'mycar' &&
            defaultState.detailItem.driverInfo.statusView === 'In review' &&
            defaultState.phoneFrefix &&
            defaultState.phoneFrefix.includes('+00')
          ) {
            value = defaultState.phoneFrefix;
          } else {
            value = trimPhoneNumber(value);
          }
        }
        if (item.key === 'gender') {
          if (value == 0) {
            value = 0;
          } else {
            value = value || -1;
          }
        }

        if (item.key === 'idType') {
          value = value || '';
        }

        if (item.key === 'state') {
          value = typeof value === 'string' ? value.trim() : '';
        }

        if (
          item.key.substring(0, 6) === 'backup' &&
          item.type === 'File' &&
          typeof value === 'string'
        ) {
          value = value.replace(s3Host, '');
        }

        data.append(dbKey, value);
      }
    });

    data.append('document', defaultState.document);
    data.append('link', defaultState.detailItem.driverInfo.link);
    data.append('notes', noteList?.map((i) => i.value).join(', '));
    data.append(
      'bankRequirement',
      JSON.stringify(defaultState.bankRequirement)
    );
    defaultState.driverDocument.document1 &&
      data.append('document1', defaultState.driverDocument.document1);
    defaultState.driverDocument.document2 &&
      data.append('document2', defaultState.driverDocument.document2);
    defaultState.driverDocument.document3 &&
      data.append('document3', defaultState.driverDocument.document3);
    defaultState.driverDocument.document4 &&
      data.append('document4', defaultState.driverDocument.document4);
    defaultState.driverDocument.document5 &&
      data.append('document5', defaultState.driverDocument.document5);
    defaultState.driverDocument.document6 &&
      data.append('document6', defaultState.driverDocument.document6);
    defaultState.driverDocument.document7 &&
      data.append('document7', defaultState.driverDocument.document7);
    defaultState.driverDocument.document8 &&
      data.append('document8', defaultState.driverDocument.document8);
    defaultState.driverDocument.document9 &&
      data.append('document9', defaultState.driverDocument.document9);
    defaultState.driverDocument.document10 &&
      data.append('document10', defaultState.driverDocument.document10);
    defaultState.driverDocument.document11 &&
      data.append('document11', defaultState.driverDocument.document11);
    defaultState.driverDocument.document12 &&
      data.append('document12', defaultState.driverDocument.document12);
    defaultState.driverDocument.document13 &&
      data.append('document13', defaultState.driverDocument.document13);
    defaultState.driverDocument.document14 &&
      data.append('document14', defaultState.driverDocument.document14);
    defaultState.driverDocument.document15 &&
      data.append('document15', defaultState.driverDocument.document15);
    defaultState.driverDocument.document16 &&
      data.append('document16', defaultState.driverDocument.document16);
    defaultState.driverDocument.document17 &&
      data.append('document17', defaultState.driverDocument.document17);
    defaultState.driverDocument.document18 &&
      data.append('document18', defaultState.driverDocument.document18);
    defaultState.driverDocument.document19 &&
      data.append('document19', defaultState.driverDocument.document19);
    defaultState.driverDocument.document20 &&
      data.append('document20', defaultState.driverDocument.document20);
    if (
      defaultState.driverDocumentExpiry &&
      defaultState.driverDocumentExpiry.length > 0
    ) {
      data.append(
        `driverDocumentExpiry`,
        JSON.stringify(defaultState.driverDocumentExpiry)
      );
    }
    data.append('timezone', props.auth.selectedFleet.timezone);
    if (
      props.auth.selectedFleet.bankingInfo &&
      props.auth.selectedFleet.bankingInfo.enable
    ) {
      data.append('nameOfBank', defaultState.detailItem.driverInfo.nameOfBank);
      data.append('IFSCCode', defaultState.detailItem.driverInfo.IFSCCode);
      data.append(
        'nameOfAccount',
        defaultState.detailItem.driverInfo.nameOfAccount
      );
      // if (!europeCountry.includes(props.auth.selectedFleet.countryCode)) {
      if (
        defaultState.bankRequirement &&
        !defaultState.bankRequirement.useIBAN
      ) {
        data.append(
          'routingNumber',
          defaultState.detailItem.driverInfo.routingNumber
        );
      }
      data.append(
        'accountNumber',
        defaultState.detailItem.driverInfo.accountNumber
      );
      data.append(
        'checkNumber',
        defaultState.detailItem.driverInfo.checkNumber
          ? defaultState.detailItem.driverInfo.checkNumber
          : ''
      );
      data.append(
        'swiftCodeNumber',
        defaultState.detailItem.driverInfo.swiftCodeNumber
          ? defaultState.detailItem.driverInfo.swiftCodeNumber
          : ''
      );
      data.append(
        'ssn',
        defaultState.detailItem.driverInfo.ssn
          ? defaultState.detailItem.driverInfo.ssn
          : ''
      );
      data.append(
        'sortCode',
        defaultState.detailItem.driverInfo.sortCode
          ? defaultState.detailItem.driverInfo.sortCode
          : ''
      );
      defaultState.verificationDocument &&
        data.append('verificationDocument', defaultState.verificationDocument);
      defaultState.additionalDocument &&
        data.append('additionalDocument', defaultState.additionalDocument);
      defaultState.verificationDocumentBack &&
        data.append(
          'verificationDocumentBack',
          defaultState.verificationDocumentBack
        );
      defaultState.additionalDocumentBack &&
        data.append(
          'additionalDocumentBack',
          defaultState.additionalDocumentBack
        );

      // SL-24302 Need to remove verificationDocument and adddtionalDocument out of driverInfo when delete on CC
      if (
        detailItem.driverInfo.verificationDocument === '' &&
        !defaultState.verificationDocument
      ) {
        data.append('verificationDocument', '');
      } else {
        data.append(
          'verificationDocument',
          detailItem.driverInfo.verificationDocument
        );
      }
      if (
        detailItem.driverInfo.additionalDocument === '' &&
        !defaultState.additionalDocument
      ) {
        data.append('additionalDocument', '');
      } else {
        data.append(
          'additionalDocument',
          detailItem.driverInfo.additionalDocument
        );
      }
      if (
        detailItem.driverInfo.verificationDocumentBack === '' &&
        !defaultState.verificationDocumentBack
      ) {
        data.append('verificationDocumentBack', '');
      } else {
        data.append(
          'verificationDocumentBack',
          detailItem.driverInfo.verificationDocumentBack
        );
      }
      if (
        detailItem.driverInfo.additionalDocumentBack === '' &&
        !defaultState.additionalDocumentBack
      ) {
        data.append('additionalDocumentBack', '');
      } else {
        data.append(
          'additionalDocumentBack',
          detailItem.driverInfo.additionalDocumentBack
        );
      }

      // SL-23737
      data.append(
        'isBankAccountOwner',
        detailItem.driverInfo.isBankAccountOwner ? 'true' : 'false'
      );
      data.append(
        'beneficiaryIDIC',
        detailItem.driverInfo.beneficiaryIDIC
          ? detailItem.driverInfo.beneficiaryIDIC
          : ''
      );
      data.append(
        'bankRelationship',
        detailItem.driverInfo.bankRelationship
          ? detailItem.driverInfo.bankRelationship
          : ''
      );
      data.append(
        'relationshipOtherName',
        detailItem.driverInfo.relationshipOtherName
          ? detailItem.driverInfo.relationshipOtherName
          : ''
      );
    }

    defaultState.detailItem.driverInfo.policyNumber &&
      data.append(
        'policyNumber',
        defaultState.detailItem.driverInfo.policyNumber
      );
    defaultState.detailItem.driverInfo.effectiveDate &&
      data.append(
        'effectiveDate',
        defaultState.detailItem.driverInfo.effectiveDate
      );
    defaultState.detailItem.driverInfo.expiredDate &&
      data.append(
        'expiredDate',
        defaultState.detailItem.driverInfo.expiredDate
      );
    data.append(
      'companyId',
      defaultState.detailItem?.driverInfo?.company?.companyId
    );
    data.append('existingCar', defaultState.isExistingCar);
    defaultState.detailItem.driverInfo.vehicleId &&
      data.append('vehicleId', defaultState.detailItem.driverInfo.vehicleId);
    defaultState.isVehicleDidntAssign &&
      data.append('isVehicleDidntAssign', defaultState.isVehicleDidntAssign);
    defaultState.carType && data.append('vehicleTypeId', defaultState.carType);
    data.append('licensePlate', defaultState.licensePlate);
    defaultState.vehicleOwner &&
      data.append('vehicleOwner', defaultState.vehicleOwner);
    defaultState.carMake && data.append('makeId', defaultState.carMake);
    defaultState.carModel && data.append('modelId', defaultState.carModel);
    data.append('year', defaultState.year);
    defaultState.color && data.append('color', defaultState.color);
    data.append('passengers', defaultState.passengers);
    data.append('luggage', defaultState.luggage);
    data.append('caseNumber', defaultState.caseNumber);
    data.append('vhcPhone', trimPhoneNumber(defaultState.vhcPhone));
    defaultState.licenseExpiry &&
      data.append(
        'licenseExpiry',
        moment(defaultState.licenseExpiry).format('MM/DD/YYYY')
      );
    data.append('zoneId', defaultState.detailItem.driverInfo.zoneId);
    data.append('shiftTemplateId', defaultState.detailItem.shift._id);

    if (
      defaultState.detailItem.driverInfo.commissionCompanyValue &&
      companyCommission
    ) {
      data.append(
        'commissionCompanyType',
        defaultState.detailItem.driverInfo.commissionCompanyType
      );
      data.append(
        'commissionCompanyValue',
        JSON.stringify(
          defaultState.detailItem.driverInfo.commissionCompanyValue
        )
      );
    }

    // Apply new flow commission
    if (
      auth.selectedFleet.generalSetting &&
      auth.selectedFleet.generalSetting.differentFleetCommission
    ) {
      data.append(
        'commissionDriverType',
        defaultState.detailItem.driverInfo.commissionDriverType
      );
      if (defaultState.detailItem.driverInfo.commissionDriverValue) {
        data.append(
          'commissionDriverValue',
          JSON.stringify(
            defaultState.detailItem.driverInfo.commissionDriverValue.filter(
              function (c) {
                return c.value !== 'N/A';
              }
            )
          )
        );
      }
    } else {
      // old flow commission
      data.append(
        'commissionType',
        defaultState.detailItem.driverInfo.commissionType
      );
      if (
        defaultState.detailItem.driverInfo.commissionType == 'percent' ||
        props.auth.selectedFleet.currencies.length <= 1
      ) {
        data.append(
          'commission',
          defaultState.commission ? defaultState.commission : 0
        );
      } else {
        var commissions = {};
        defaultState.detailItem.driverInfo.commissionByCurrencies.map((c) => {
          commissions[c.currencyISO] = c.commissionValue;
        });
        data.append('commissions', JSON.stringify(commissions));
      }
    }

    data.append(
      'deleteDocument',
      JSON.stringify(defaultState.documentDeleteList)
    );
    data.append('terminalId', defaultState.detailItem.driverInfo.terminalId);
    data.append('authKey', defaultState.detailItem.driverInfo.authKey);
    
    if (props.action === 'add') {
      data.append('statusReview', 'inComplete');
      data.append('active', defaultState.detailItem.isActive?.toString());
    } else {
      data.append(
        'statusReview',
        defaultState.detailItem.isActive
          ? 'completed'
          : defaultState.detailItem.driverInfo.statusReview
      );
      data.append('active', statusDriver == 'inReviewInProgress' ? 'review' : defaultState.detailItem.isActive?.toString());
    }

    data.append('topDriver', defaultState.detailItem.rank);
    data.append('forceMeter', defaultState.detailItem.driverInfo.forceMeter);
    data.append('rideSharing', defaultState.detailItem.rideSharing);
    data.append('marketplace', defaultState.detailItem.marketplace);
    data.append(
      'marketplaceSettingMode',
      defaultState.detailItem.marketplaceSettingMode
    );
    data.append(
      'maximumAcceptReservationPerDay',
      defaultState.detailItem.maximumAcceptReservationPerDay
    );
    data.append(
      'allowDriverSelectVehicleForBooking',
      defaultState.detailItem.driverInfo.allowDriverSelectVehicleForBooking ||
        false
    );
    data.append(
      'maximumAcceptReservation',
      defaultState.detailItem.maximumAcceptReservation
    );
    data.append(
      'hasTopUpOtherDriver',
      detailItem.driverInfo.hasTopUpOtherDriver ? 'true' : 'false'
    );
    /**
     * Generate object for update vehicle not assign yet
     */
    let vehicleNeedAssign = defaultState.vehicleDetails[
      defaultState.detailItem.driverInfo.vehicleId
    ]
      ? initVehicleUpdate(
          defaultState.vehicleDetails[
            defaultState.detailItem.driverInfo.vehicleId
          ]
        )
      : {};

    const fleet = props.auth.selectedFleet;
    props.loadingBarActions.showLoadingSpiner();
    if (
      fleet.bankingInfo &&
      fleet.bankingInfo.enable &&
      fleet.bankingInfo.verifyAccount == SettelementSetting.verifyAccount &&
      fleet.creditConfig.configGateway &&
      // fleet.countryCode == 'FR' &&
      defaultState.bankRequirement.legalToken &&
      fleet.creditConfig.configGateway.gateway == 'Stripe' &&
      window.Stripe &&
      defaultState.relativeInfoData.stripe &&
      defaultState.relativeInfoData.stripe.publicKey &&
      !defaultState.detailItem.driverInfo.isBankVerified
    ) {
      // Upload document to Stripe;
      let verificationFile = null;
      let additionalFile = null;
      let verificationBackFile = null;
      let additionalBackFile = null;
      if (defaultState.verificationDocument) {
        verificationFile = await uploadStripeVerificationDocument(
          defaultState.verificationDocument
        );
      } else {
        verificationFile =
          defaultState.detailItem.driverInfo.verificationDocumentId;
      }
      if (defaultState.verificationDocumentBack) {
        verificationBackFile = await uploadStripeVerificationDocument(
          defaultState.verificationDocumentBack
        );
      } else {
        verificationBackFile =
          defaultState.detailItem.driverInfo.verificationDocumentBackId;
      }
      if (defaultState.additionalDocument) {
        additionalFile = await uploadStripeAdditionalDocument(
          defaultState.additionalDocument
        );
      } else {
        additionalFile =
          defaultState.detailItem.driverInfo.additionalDocumentId;
      }
      if (defaultState.additionalDocumentBack) {
        additionalBackFile = await uploadStripeAdditionalDocument(
          defaultState.additionalDocumentBack
        );
      } else {
        additionalBackFile =
          defaultState.detailItem.driverInfo.additionalDocumentBackId;
      }

      await Promise.all([
        verificationFile,
        verificationBackFile,
        additionalFile,
        additionalBackFile,
      ])
        .then((files) => {
          const [
            verificationDocumentId,
            verificationDocumentBackId,
            additionalDocumentId,
            additionalDocumentBackId,
          ] = files;
          data.append('verificationDocumentId', verificationDocumentId);
          data.append('verificationDocumentBackId', verificationDocumentBackId);
          data.append('additionalDocumentId', additionalDocumentId);
          data.append('additionalDocumentBackId', additionalDocumentBackId);
          if (!defaultState.detailItem.isActive) {
            return sendOperateDriverRequest(
              data,
              vehicleNeedAssign,
              defaultState
            );
          }
          // Get Stripe token before submit form
          if (!legalToken || isChangeZipcode) {
            createStripeToken(files).then((result) => {
              if (result) {
                setLegalToken(result);
                data.append('legalToken', result);
                sendOperateDriverRequest(data, vehicleNeedAssign, defaultState);
              } else {
                props.loadingBarActions.hideLoadingSpiner();
              }
            });
          } else {
            data.append('legalToken', legalToken);
            sendOperateDriverRequest(data, vehicleNeedAssign, defaultState);
          }
        })
        .catch((err) => {
          console.error(err);
          props.loadingBarActions.hideLoadingSpiner();
        });
    } else {
      sendOperateDriverRequest(data, vehicleNeedAssign, defaultState);
    }
  };

  const sendOperateDriverRequest = async (
    data,
    vehicleNeedAssign,
    stateUpdated
  ) => {
    const { syncPaxProfile } = props.auth.selectedFleet;
    await props
      .operateDriver(data, !!props.params.driverId)
      .then(async (respone) => {
        /**
         * update setting car mgmt
         */
        props.loadingBarActions.hideLoadingSpiner();
        if (respone.ok && respone.error === null) {
          if (
            stateUpdated.isExistingCar &&
            (!stateUpdated.isVehicleDidntAssign || isVhicleNoType) &&
            !_.isEmpty(vehicleNeedAssign)
          ) {
            await props.settingActions
              .updateCars(vehicleNeedAssign)
              .then((data) => {
                if (!data.ok) {
                  if (data.error) {
                    context.notification(
                      'error',
                      I18n.t('errors.' + data.error.errorCode)
                    );
                  } else if (data.message) {
                    context.notification(
                      'error',
                      I18n.t('errors.' + data.message.errorCode)
                    );
                  } else {
                    context.notification(
                      'error',
                      I18n.t('carSetting.Update_car_fail')
                    );
                  }
                }
              });
          }

          // Update Documents Missing
          await props
            .setDocumentsMissing({
              fleetId: respone?.res?.fleetId,
              userId: respone?.res?.userId,
              documentsMissing: stateUpdated.documentsMissing,
            })
            .then(({ res }) => {});

          await props.updateDriverInfo(respone.res);

          context.notification(
            'success',
            I18n.t(
              !!props.params.driverId
                ? 'driver.DRIVER_UPDATE_SUCCESS'
                : 'driver.DRIVER_CREATE_SUCCESS'
            )
          );
          const { isActive } = stateUpdated.detailItem;
          const { isComplete, statusReview } =
            stateUpdated.detailItem.driverInfo;

          // tranform is active
          respone.res.isActive = isActive;
          if (isActive) {
            respone.res.driverInfo.isActivate = true;
            respone.res.driverInfo.isComplete = true;
            respone.res.driverInfo.statusReview = 'completed';
          }

          // transform is complete profile when keep InReview
          if (!isActive && !isComplete) {
            respone.res.driverInfo.isComplete = true;
          }

          // transform is In-review and profile in-progress
          if (!isActive && statusReview === 'inProgress') {
            respone.res.driverInfo.isActivate = false;
            respone.res.driverInfo.isComplete = false;
            respone.res.driverInfo.statusReview = 'inProgress';
          }

          // await props.onUpdateDriver({
          //   ...respone.res,
          //   driverWallet: stateUpdated.detailItem.driverWallet,
          // });
          if (props.action === 'add') {
            props.navigate.push({
              pathname: `/driver/view/${respone?.res?.userId}/info`,
              state: {
                edit: false,
              },
            });
          } else {
            setValidTab({
              info: false,
              vehicle: false,
              document: false,
              payment: false,
              setting: false,
              vehicle: false,
            });
            stateUpdated.editable = false;
            stateUpdated.vehicleIds = respone?.res.vehicles?.map(vhc => vhc.vehicleId) || []
            setState(stateUpdated);
            setInitInfo({
              company: stateUpdated?.detailItem?.driverInfo?.company || {},
              zone: stateUpdated?.detailItem?.driverInfo?.zoneId || []
            })
            setCompanyChanged(false)
          }

          yearsElement = [];
        } else if (respone.error) {
          let message = I18n.t('errors.' + respone.error.errorCode);
          if (respone.error.errorCode === 3040) {
            message = I18n.t('errors.' + respone.error.errorCode, {
              brandName: syncPaxProfile.groupName
                ? syncPaxProfile.groupName
                : '',
            });
          }
          if (respone.error.paymentReturnCode == 407 && respone.error.message) {
            message += `Please check (${respone.error.message})`;
          }
          if (
            respone.error.paymentReturnCode === 493 &&
            respone.error.paymentMessage
          ) {
            message += `(${respone.error.paymentMessage})`;
          }
          let messageRes = respone.error.message || '';
          context.notification(
            'error',
            messageRes || I18n.t('messages.commonMessages.Error_message'),
            message
          );
        } else {
          console.error('Error: ', respone);
          context.notification(
            'error',
            I18n.t('messages.commonMessages.Error_message'),
            respone
          );
        }
      });
  };

  const getDayMonthYear = (birthday) => {
    var d = new Date(birthday),
      month = d.getMonth() + 1,
      day = d.getDate(),
      year = d.getFullYear();
    return {
      day: day,
      month: month,
      year: year,
    };
  };

  const createStripeToken = (files) => {
    const { commonData } = props;
    const { driverInfoDynamic, detailItem } = state;
    const stripePublicKey = _.get(
      commonData,
      'relativeInfo.stripe.publicKey',
      ''
    );
    const stripe = window.Stripe(stripePublicKey);
    const [
      verificationFile,
      verificationBackFile,
      additionalFile,
      additionalBackFile,
    ] = files;

    let form = {
      business_type: 'individual',
      individual: {
        first_name: driverInfoDynamic.firstName || '',
        last_name: driverInfoDynamic.lastName || '',
        phone: driverInfoDynamic.phone || '',
        email: driverInfoDynamic.email || '',
        address: {
          line1: driverInfoDynamic.address || '',
          city: driverInfoDynamic.city || '',
          state: driverInfoDynamic.state || '',
          postal_code: driverInfoDynamic.zipcode || '',
        },
      },
      tos_shown_and_accepted: true,
    };
    let dob = _.clone(driverInfoDynamic.birthday);
    dob = moment(dob, 'YYYY/MM/DD');
    form.individual.dob =
      typeof driverInfoDynamic.birthday == 'string'
        ? getDayMonthYear(driverInfoDynamic.birthday)
        : {
            day: dob.format('D'),
            month: dob.format('M'),
            year: dob.format('YYYY'),
          };

    /* Split firstname, lastname by accountName */
    if (detailItem?.driverInfo?.nameOfAccount) {
      const arrName = detailItem.driverInfo.nameOfAccount.split(' ');
      form.individual.first_name = arrName[0];
      form.individual.last_name = detailItem.driverInfo.nameOfAccount
        .substring(form.individual.first_name.length)
        .trim();
    }

    if (verificationFile || verificationBackFile) {
      form.individual.verification = {
        document: {
          front: verificationFile,
          back: verificationBackFile,
        },
      };
    }

    if (additionalFile || additionalBackFile) {
      form.individual.verification = form.individual.verification || {};
      form.individual.verification.additional_document = {
        front: additionalFile,
        back: additionalBackFile,
      };
    }

    return stripe
      .createToken('account', form)
      .then((data) => {
        if (data.error) {
          context.notification('error', data.error.message);
          return null;
        }
        return data.token.id;
      })
      .catch((err) => {
        context.notification('error', err.message);
        return null;
      });
  };

  const handleSwitchToAnyVehicleChange = async (value) => {
    try {
      props.loadingBarActions.showLoadingSpiner();
      const rs = await props.driverActions.switchVehicle({
        fleetId: props.auth?.selectedFleet.fleetId,
        switchToAnyVehicle: value,
        userId: props.params?.driverId,
      });
      if (rs?.ok && rs.res.status === 200) {
        const defaultState = { ...state };
        setState({
          ...defaultState,
          switchToAnyVehicle: rs.res?.switchToAnyVehicle,
        });
        context.notification('success', I18n.t('carTypeSetting.updateSuccess'));
      }
      props.loadingBarActions.hideLoadingSpiner();
    } catch (error) {
      props.loadingBarActions.hideLoadingSpiner();
      context.notification('error', I18n.t('errors.systemError'));
      throw error;
    }
  };

  useEffect(() => {
    if (props.auth.selectedFleet.fleetId) {
      handleInitialData();
      getAllSupplier();
      handleLoadNotes();
      handleSearchLicensePlate('', null, true);
    }
  }, [props.auth.selectedFleet.fleetId, props.params?.driverId]);

  useEffect(() => {
    if (initDriver) {
      handleLicensePlateComboChange(initDriver);
    } else {
      handleLicensePlateComboChange(null);
    }
  }, [initDriver]);

  const updateVehicleIds = (vhc = [], firstDrv) => {
    let newState = { ...state };
    newState.vehicleIds = vhc;
    // newState.detailItem.driverInfo.vehicleId = vhc?.length > 0 ? vhc[0] : '';
    // newState.detailItem.driverInfo.licensePlate = vhc?.length > 0 ? firstDrv?.plateNumber : '';
    setState(newState);
  };

  const renderFromInfo = () => {
    return (
      <FormInfo
        props={props}
        action={props.action}
        state={state}
        handleActiveChange={handleActiveChange}
        handleChangeImages={handleChangeImages}
        handleDeleteImages={handleDeleteImages}
        handleVisibilityImages={handleVisibilityImages}
        handleDriverTypeChange={handleDriverTypeChange}
        validatorCallback={validatorCallback}
        handleServiceTypeChange={handleServiceTypeChange}
        handleCompanyChange={handleCompanyChange}
        companyChanged={companyChanged}
        getDriverValidationInfo={getDriverValidationInfo}
        handleDriverInfoChange={handleDriverInfoChange}
        handlePhoneChange={handlePhoneChange}
        handlePhoneFieldChange={handlePhoneFieldChange}
        closeDialogForm={closeDialogForm}
        saveDialogForm={saveDialogForm}
        onEditable={onEditable}
        handleDriverInfoBackupFieldChange={handleDriverInfoBackupFieldChange}
        handleRemoveDriverInfoBackupFieldChange={
          handleRemoveDriverInfoBackupFieldChange
        }
        handleResetPasswordClick={handleResetPasswordClick}
        handleUsernameChange={handleUsernameChange}
        supplierList={supplierList}
      />
    );
  };

  const renderFormDocument = () => {
    return (
      <FormDocument
        props={props}
        action={props.action}
        state={state}
        validatorCallback={validatorCallback}
        notification={context.notification}
        handleDriverDocumentChange={handleDriverDocumentChange}
      />
    );
  };

  const renderFormSetting = () => {
    return (
      <FormSetting
        props={props}
        action={props.action}
        state={state}
        validatorCallback={validatorCallback}
        handleZoneChange={handleZoneChange}
        handleNoteChange={handleNoteChange}
        searchNote={searchNote}
        handleSearchNote={handleSearchNote}
        handleAddNote={handleAddNote}
        noteList={noteList}
        handleCommissionTypeChangeAndValue={handleCommissionTypeChangeAndValue}
        handleMarketplaceChange={handleMarketplaceChange}
        showCommissionClick={showCommissionClick}
        handleCommissionDriverChange={handleCommissionDriverChange}
        saveCommissionEdit={saveCommissionEdit}
        editFleetCommission={
          state.editable === true
            ? props.auth.user.isAdmin
              ? false
              : !props.permissions.editfleetcommission
            : !state.editable
        }
        handleMarketplaceTypeChange={handleMarketplaceTypeChange}
        handleCommissionChange={handleCommissionChange}
        handleCommissionCompanyChange={handleCommissionCompanyChange}
        showCompanyCommissionClick={showCompanyCommissionClick}
        saveCompanyCommissionEdit={saveCompanyCommissionEdit}
        handleCompanyCommissionTypeChangeAndValue={
          handleCompanyCommissionTypeChangeAndValue
        }
        handleSelectVehicleChange={handleSelectVehicleChange}
        handleAcceptChange={handleAcceptChange}
        handleTopUpOtherDriverChange={handleTopUpOtherDriverChange}
        handleTopDriverChange={handleTopDriverChange}
        handleRideSharingChange={handleRideSharingChange}
        closeDialogForm={closeDialogForm}
        saveDialogForm={saveDialogForm}
        onEditable={onEditable}
        handleCommissionTypeChange={handleCommissionTypeChange}
        noteOptions={noteOptions}
      />
    );
  };

  const renderFormPayment = () => {
    return (
      <FormPayment
        props={props}
        action={props.action}
        state={state}
        handleChangeBalanceClick={handleChangeBalanceClick}
        handleClickShowCashWalletBalance={handleClickShowCashWalletBalance}
        handleConfirmCloseClick={handleConfirmCloseClick}
        handleCreditBalanceSuccess={handleCreditBalanceSuccess}
        handleChangeCashBalanceSuccess={handleChangeCashBalanceSuccess}
        handleStripeAccountIdChange={handleStripeAccountIdChange}
        handleNameOfBankChange={handleNameOfBankChange}
        validatorCallback={validatorCallback}
        handleNameOfAccountChange={handleNameOfAccountChange}
        handleRTBNumberChange={handleRTBNumberChange}
        handleAccountNumberChange={handleAccountNumberChange}
        handleIFSCCodeChange={handleIFSCCodeChange}
        handleCheckNumberChange={handleCheckNumberChange}
        handleSwiftCodeNumberChange={handleSwiftCodeNumberChange}
        handleSSNChange={handleSSNChange}
        handleSortCodeChange={handleSortCodeChange}
        handleVerificationDocumentChange={handleVerificationDocumentChange}
        handleVerificationDocumentBackChange={
          handleVerificationDocumentBackChange
        }
        handleAdditionalDocumentChange={handleAdditionalDocumentChange}
        handleAdditionalDocumentBackChange={handleAdditionalDocumentBackChange}
        handleRemoveBankInfo={handleRemoveBankInfo}
        handleRemoveBankDocumentChange={handleRemoveBankDocumentChange}
        handeChangeIsBankAccountOwner={handeChangeIsBankAccountOwner}
        handleChangebeneficiary={handleChangebeneficiary}
        handleConfirmButtonClick={handleConfirmButtonClick}
        closeDialogForm={closeDialogForm}
        saveDialogForm={saveDialogForm}
        onEditable={onEditable}
      />
    );
  };

  const handleTabSelect = (key) => {
    props.navigate.push({
      pathname: `/driver/view/${props.params?.driverId}/${key}`,
    });
    
  };
  if (props?.params?.tabActive === 'addNewVehicle') {
    return (
      <AddNewVehicle
        driverId={props.params?.driverId}
        companyId={state?.detailItem?.driverInfo?.company?.companyId}
        navigate={props.navigate}
        updateVehicleIds={updateVehicleIds}
      />
    );
  }
  return (
    <div className={styles['layout']}>
      {props.params?.driverId ? (
        <Tabs
          id="driverDetailId"
          className="driverDetail"
          defaultActiveKey={props?.params?.tabActive || 'info'}
          onSelect={handleTabSelect}
        >
          <Tab
            eventKey={'info'}
            title={
              <div style={{ color: validTab?.info ? '#a94442' : '' }}>
                {I18n.t('driver.ContactInfo')}
              </div>
            }
          >
            {renderFromInfo()}
          </Tab>
          <Tab
            eventKey={'vehicle'}
            disabled={companyChanged}
            title={
              <div style={{ color: validTab?.vehicle ? '#a94442' : '' }}>
                {I18n.t('cue.Vehicle')}
              </div>
            }
          >
            <VehicleDriver
              vehicleIds={state?.vehicleIds || []}
              navigate={props.navigate}
              switchToAnyVehicle={state?.switchToAnyVehicle}
              handleSwitchToAnyVehicleChange={handleSwitchToAnyVehicleChange}
              userId={props.params?.driverId}
              auth={props.auth}
              settingActions={props.settingActions}
              driverActions={props.driverActions}
              loadingActions={props.loadingBarActions}
              updateVehicleIds={updateVehicleIds}
              companyId={state?.detailItem?.driverInfo?.company?.companyId}
              commonData={props.commonData}
            />
          </Tab>
          <Tab
            eventKey={'documents'}
            title={
              <div style={{ color: validTab?.document ? '#a94442' : '' }}>
                {I18n.t('cue.Documents')}
              </div>
            }
          >
            {renderFormDocument()}
          </Tab>
          {!props.auth.selectedFleet?.SOS?.driver ? null : (
            <Tab
              eventKey={'emergency-contact'}
              title={I18n.t('driver.emergencyContacts')}
            >
              <DriverEmergencyContacts
                selectedFleet={props.auth.selectedFleet}
                getEmergencyContactDriver={props.getEmergencyContactDriver}
                userId={props.params.driverId}
              />
            </Tab>
          )}
          {!props.auth?.user?.roles?.isSupplier &&
            (!props.auth.selectedFleet?.bankingInfo?.enable &&
            !state.enableStripeConnect &&
            !state.relativeInfoData?.pricingSettings?.driverDeposit?.enable &&
            !props.auth.selectedFleet.driverCashWallet ? null : (
              <Tab
                eventKey={'payment'}
                title={
                  <div style={{ color: validTab?.payment ? '#a94442' : '' }}>
                    {I18n.t('cue.Payment')}
                  </div>
                }
              >
                {renderFormPayment()}
              </Tab>
            ))}
          <Tab
            eventKey={'settings'}
            title={
              <div style={{ color: validTab?.setting ? '#a94442' : '' }}>
                {I18n.t('generalSetting.settings')}
              </div>
            }
          >
            {renderFormSetting()}
          </Tab>
        </Tabs>
      ) : (
        <>{renderFromInfo()}</>
      )}
      <Confirm
        confirm={state.confirm}
        handleConfirmButtonClick={handleConfirmButtonClick}
        handleConfirmCloseClick={handleConfirmCloseClick}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
    menuHandle: state.menuHandle,
    language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-US',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchVehicleByCompany: (options) => {
      return dispatch(options);
    },
    findOneVehicle: (options) => {
      return dispatch(findOneVehicle(options));
    },
    findOneDriver: (options) => {
      return dispatch(findOneDriver(options));
    },
    resestPassword: (options) => {
      return dispatch(resestPassword(options));
    },
    checkExistDriverPhone: (options) => {
      return dispatch(checkExistDriverPhone(options));
    },
    fetchRelativeInfo: (options) => {
      return dispatch(fetchRelativeInfo(options));
    },
    operateDriver: (options, isEdit) => {
      return dispatch(operateDriver(options, isEdit));
    },
    updateDriverDocument: (options) => {
      return dispatch(updateDriverDocument(options));
    },
    updateDriverInfo: (options) => {
      return dispatch(updateDriverInfo(options));
    },
    getBankAccountRequireField: (options) => {
      return dispatch(getBankAccountRequireField(options));
    },
    removeBankInfo: (options) => {
      return dispatch(removeBankInfo(options));
    },
    setDocumentsMissing: (options) => {
      return dispatch(setDocumentsMissing(options));
    },
    getEmergencyContactDriver: (options) => {
      return dispatch(getEmergencyContactDriver(options));
    },
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    loadMoreVehicles: bindActionCreators(loadMoreVehicles, dispatch),
    loadMoreVehiclesByType: bindActionCreators(
      loadMoreVehiclesByType,
      dispatch
    ),
    settingActions: bindActionCreators(settingActions, dispatch),
    driverActions: bindActionCreators(driverActions, dispatch),
  };
}

FormDriver.contextTypes = {
  notification: PropTypes.func,
};
FormDriver.prototype = {
  action: PropTypes.string.isRequired,
  navigate: PropTypes.bool.isRequired,
  params: PropTypes.bool.isRequired,
};

FormDriver.defaultProps = {
  action: '',
  navigate: {},
  params: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDriver);
