/* global google */
import React, { Component } from 'react';
import uuidv4 from 'uuid/v4';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Row, FormControl, Button, Modal, Image, FormGroup, Form, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import currencyFormatter from 'currency-formatter';
import moment from 'moment';
import _ from 'lodash';
import {
  geoPlaceDetailMapProvider,
  getDirectionMapProviderFromJupiter,
} from '../../utils/mapUtils';
import * as settingActions from '../../actions/settingActions';
import * as deliveryActions from '../../actions/deliveryAction';
import * as newbookingActions from '../../actions/newbookingAction';
import * as corporateActions from '../../actions/corporateAction';
import * as paymentMethodActions from '../../actions/paymentMethodActions';
import * as driverAutocompleteActions from '../../actions/driverAutocompleteActions';
import * as etaFareActions from '../../actions/etaFareActions';
import * as customerActions from '../../actions/customerAction';
import * as creditCardActions from '../../actions/creditCardActions';
import * as bookingDetailActions from '../../actions/bookingDetailAction';
import * as messageAction from '../../actions/messageAction';
import * as tencentMapActions from '../../actions/tencentMapActions';
import * as uploadActions from '../../actions/uploadActions';
import * as cueActions from '../../actions/cueActions';
import { socketConfigs } from '../../constants/socketConfigs';
import { socketDispatchApi } from '../../utils/socketUtils';
import {
  bookingStatusMapping,
  paymentMethodNumber,
  travelerType,
  activeStatus,
  userType,
  locationType,
  EDITABLE_FARE,
  finishedStatusList,
  STATUS_COMPLETED_DELIVERY,
} from '../../constants/commondata';

import {
  CCLiteCommonFunc,
  checkMultiPuDoNotEmpty,
  filterCompanyList,
  checkDropOffCountry,
  roundOff,
  trimPhoneNumber,
  regexpDriverAutoComplete,
  Validation,
  checkLastActiveRecipient,
  checkShowChargeInvoicingBtn,
  checkPaymentDirectInvoicing,
  checkBookingPrePaided,
  checkShowViewPaymentLogsBtn,
  handleResultUpdateFinishedBooking,
  getLanguageAutoCompleteSearch
} from '../../utils/commonFunctions';
import * as loadingActions from '../../actions/loadingBarActions';
import * as driverActions from '../../actions/driverAction';
import * as customerAutocompleteActions from '../../actions/customerAutocompleteAction';
import '../../components/bookingDetail/bookingDetail.scss';
import closeIcon from '../../assets/images/icons/close.svg';
import {
  onBookingDetailChangePickUpHour,
  onBookingDetailChangePickUpMin,
  handleDeliveryCompanyDriverChange,
  calculateFareWithPromoChange,
  getLocationWhenDrapEnd,
  checkLocationHasChange,
  filterPointEmpty,
} from '../../components/bookingDetail/bookFunction/bookingInfo';
import {
  getRecipientFromMenuData,
  deliveryBookingDetailEtaMultiFareCalculator,
  getServiceAndCalculateLogicBookingDetail,
  carTypeBaseLocation,
  getTravelerModeByCarType,
  isCanAssignOfflineDriver,
  getMenuDataBookingDetailFromRecipient,
  checkCashOnDelivery,
} from '../../components/bookingDetail/bookFunction/serviceRequest';
import {
  getDefaultTip,
  canEditPromo,
} from '../../components/bookingDetail/bookFunction/tipPromo';
import Confirm from '../../components/confirm/Confirm';
import { QQWebservice, QQUltis } from '../../components/qqMap';
import DispatchLogs from '../../components/DispatchLogs';
import TrackLink from '../../components/trackLink/TrackLink';
import RouteInfo from '../../components/RouteInfo';
import DateMode from '../../components/DateModeBooking';
import { initExtraService } from '../../components/bookingDetail/bookFunction/additionalService';
import GroupInfoBooking from '../../components/bookingDetail/bookComponent/GroupInfoBooking'
import ChargeInvoicingModal from '../../components/bookingDetail/bookComponent/ChargeInvoicingModal'
import PaymentLogs from '../../components/bookingDetail/bookComponent/PaymentLogs'
import ViewPhotoCapture from '../../components/ViewPhotoCapture';
import CompleteButton from './BookingAction/CompleteButton';
import TrailNotes from '../../components/bookingDetail/bookComponent/trailNotes';

import {
  BookingInfoDelivery,
  PromoInfo,
  DispatchInfoDelivery,
  DriverCalendarInfo,
  PaymentInfo,
  TripEstimateDelivery,
  UpdateConfirmationModal,
  SendSMSBookingModal,
  IncidentModal,
  CancelConfirmationModal,
  CompleteWithPaymentModal,
  ConfirmEditRecurringModal,
  BookingMapChauffeur,
} from '../../components/bookingDetail';
import EditFareComponent from '../../components/bookingDetail/bookComponent/EditFareComponent';

const isHydraBooking = 1;
class DeliveryBookingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.componentGetDataFromProp(props.data, true),
      dateMode: 'single',
      weekDays: {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
      },
      statusText: '',
      showTrailNote: false,
      fromDate: '',
      toDate: '',
      recurringTime: '00:00',
      driversuggestions: [],
      driver: '',
      mapCenter: null,
      cartypeSelected: null,
      driverCompany: { _id: '' },
      customer: null,
      credit: {},
      addOnPrice: 0,
      showConfirmCancel: false,
      showConfirmUpdate: false,
      showWarningResetEditFare: false,
      showConfirmIncident: false,
      showConfirmComplete: false,
      showSMSBooking: false,
      smsBookingTemplateQuery: {
        list: [],
        listAll: [],
        page: 0,
        limit: 10,
        total: 0,
        selectedObj: null,
        hasMore: true,
        search: '',
      },
      disabledSpam: false,
      isSend: false,
      smsWillSend: {
        id: '',
        phone: '',
        name: '',
        content: '',
        subject: '',
        typeMessage: 'Inbox',
        typeRecipient: '',
        bookingId: '',
      },
      paymentStep: 0,
      optionAutocomplete: {},
      paymentComplete: {
        tax: 0,
        subTotal: 0,
        total: 0,
        promo: 0,
        isMinFare: false,
        paymentType: 0,
      },
      dataTemp: null,
      valid: {},
      isSubmited: false,
      isFormDirty: false,
      mapDefaultBound: null,
      isShowDriverCalendar: false,
      driverReservationBookings: [],
      driverReservationDatePriview: 0,
      driverCalendarViewing: null,
      pickupFrom: null,
      destinationFrom: null,
      showMap: false,
      isShowmDispatcherCard: false,
      isCompleteWithPaymentShow3rdBooking: false,
      isRetryPaymentCharge: true,
      isCheckSupportLocation: false,
      recurringDays: [],
      promoCodeMessageContent: {
        paymentMethods: [],
        minimumEstFare: '',
        schedules: false,
      },
      editAll: false,
      showConfirmRecurring: false,
      isShowRemoveBookingModal: false,
      dataConfirmRecurring: {
        title: '',
        dataConfirmRecurring: '',
        funcHandleConfirmRecurring: () => {},
      },
      isShowMarkFailedRecipientModal: false
    };
    this.close = this.close.bind(this);
    this.getDirectionCallback = this.getDirectionCallback.bind(this);
    this.checkLocationHasChange = checkLocationHasChange.bind(this)
    this.handleResultUpdateFinishedBooking = handleResultUpdateFinishedBooking.bind(this)
    this.calculatorTotal = this.calculatorTotal.bind(this);
    this.getDirectionAndDraw = this.getDirectionAndDraw.bind(this);
    this.handleDriverChanged = this.handleDriverChanged.bind(this);
    this.handleChangeDispatchType = this.handleChangeDispatchType.bind(this);

    this.handleChangePickUp = this.handleChangePickUp.bind(this);
    this.handleAddressRemovedPickUp =
      this.handleAddressRemovedPickUp.bind(this);
    this.handleSelectThirdPartyPickUp =
      this.handleSelectThirdPartyPickUp.bind(this);
    this.handleChangeThirdPartyPickUp =
      this.handleChangeThirdPartyPickUp.bind(this);

    this.handleDestinationDrapEnd = this.handleDestinationDrapEnd.bind(this);
    this.handlePickupDrapEnd = this.handlePickupDrapEnd.bind(this);
    this.pickupDrapEndCallback = this.pickupDrapEndCallback.bind(this);
    this.destinationDrapEndCallback =
      this.destinationDrapEndCallback.bind(this);

    this.handleChangeDestination = this.handleChangeDestination.bind(this);
    this.handleAddressRemovedDestination =
      this.handleAddressRemovedDestination.bind(this);
    this.handleSelectThirdPartyDestination =
      this.handleSelectThirdPartyDestination.bind(this);
    this.handleChangeThirdPartyDestination =
      this.handleChangeThirdPartyDestination.bind(this);
    this.handleChangeNote = this.handleChangeNote.bind(this);
    this.handleChangeDispatchType = this.handleChangeDispatchType.bind(this);

    this.handleChangePromo = this.handleChangePromo.bind(this);
    this.handleCarTypeChanged = this.handleCarTypeChanged.bind(this);
    this.handleDriverChanged = this.handleDriverChanged.bind(this);
    this.handleTimeChanged = this.handleTimeChanged.bind(this);
    this.handleCreditCardChange = this.handleCreditCardChange.bind(this);
    this.handleAddCardSuccess = this.handleAddCardSuccess.bind(this);
    this.handlePromoApplyClick = this.handlePromoApplyClick.bind(this);
    this.handleChangeDispatchType = this.handleChangeDispatchType.bind(this);
    this.handleDriverChanged = this.handleDriverChanged.bind(this);
    this.handleDriverChanged = this.handleDriverChanged.bind(this);
    this.handleIncidentReasonChanged =
      this.handleIncidentReasonChanged.bind(this);
    this.handleCompleteBasicFareChange =
      this.handleCompleteBasicFareChange.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.handleCompletePaymentTypeChange =
      this.handleCompletePaymentTypeChange.bind(this);
    this.handleCompletePaymentIsPendingChange =
      this.handleCompletePaymentIsPendingChange.bind(this);
    this.handleClientCaseMatterChange =
      this.handleClientCaseMatterChange.bind(this);
    this.handleChangeOperatorNote = this.handleChangeOperatorNote.bind(this);
    this.handleChargeCodeChange = this.handleChargeCodeChange.bind(this);
    this.handleDispatch3rdOnchange = this.handleDispatch3rdOnchange.bind(this);
    this.cancelBookingButtonClick = this.cancelBookingButtonClick.bind(this);
    this.newBookingButtonClick = this.newBookingButtonClick.bind(this);
    this.incidentButtonClick = this.incidentButtonClick.bind(this);
    this.incidentOkButtonClick = this.incidentOkButtonClick.bind(this);
    this.completeWithPaymentOkButtonClick =
      this.completeWithPaymentOkButtonClick.bind(this);
    this.completeWithPaymentCloseButtonClick =
      this.completeWithPaymentCloseButtonClick.bind(this);
    this.cancelBooking = this.cancelBooking.bind(this);
    this.cancelBookingOkClick = this.cancelBookingOkClick.bind(this);
    this.updateInfoBookingClick = this.updateInfoBookingClick.bind(this);
    this.updateInfoAndDispatchBookingClick =
      this.updateInfoAndDispatchBookingClick.bind(this);
    this.handleDriverCalendarClick = this.handleDriverCalendarClick.bind(this);
    this.handleCloseDriverCalendarClick =
      this.handleCloseDriverCalendarClick.bind(this);
    this.handleDriverReservationBookingDateChange =
      this.handleDriverReservationBookingDateChange.bind(this);
    this.handleChangeETAFare = this.handleChangeETAFare.bind(this);
    this.handleChangeMarkupPrice = this.handleChangeMarkupPrice.bind(this);

    this.driverAutocompleteSlectedHandle =
      this.driverAutocompleteSlectedHandle.bind(this);
    this.getDriverSuggestionValue = this.getDriverSuggestionValue.bind(this);
    this.renderDriverSuggestion = this.renderDriverSuggestion.bind(this);

    this.getDefaultPaymentMethod = this.getDefaultPaymentMethod.bind(this);
    this.componentGetDataFromProp = this.componentGetDataFromProp.bind(this);
    this.paymentCompleteSubtotalCalculator =
      this.paymentCompleteSubtotalCalculator.bind(this);
    this.paymentCompleteTotalCalculator =
      this.paymentCompleteTotalCalculator.bind(this);
    this.getCrossZoneBaseLocation = this.getCrossZoneBaseLocation.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.isNotesEnable = this.isNotesEnable.bind(this);
    this.getFareSettings = this.getFareSettings.bind(this);
    this.completeWithPayment3rdBooking =
      this.completeWithPayment3rdBooking.bind(this);
    this.handleSelectRecentPickup = this.handleSelectRecentPickup.bind(this);
    this.handleSelectRecentDestination =
      this.handleSelectRecentDestination.bind(this);
    this.handleChangeRetryPaymentMethod =
      this.handleChangeRetryPaymentMethod.bind(this);
    this.handleRetryPaymentCharge = this.handleRetryPaymentCharge.bind(this);
    // location info
    this.isPickupEditable = this.isPickupEditable.bind(this);
    this.isDestinationEditable = this.isDestinationEditable.bind(this);
    // book info
    this.onChangePickUpHour = onBookingDetailChangePickUpHour.bind(this);
    this.onChangePickUpMin = onBookingDetailChangePickUpMin.bind(this);
    this.handleCompanyDriverChange =
      handleDeliveryCompanyDriverChange.bind(this);
    // get from bookFunction/serviceRequest
    this.etaFareCalculator = deliveryBookingDetailEtaMultiFareCalculator.bind(this);
    this.getRecipientFromMenuData = getRecipientFromMenuData.bind(this);
    this.getServiceAndCalculateLogic =
      getServiceAndCalculateLogicBookingDetail.bind(this);
    this.initExtraService = initExtraService.bind(this);
    this.carTypeBaseLocation = carTypeBaseLocation.bind(this);

    this.getDefaultTip = getDefaultTip.bind(this);
    this.canEditPromo = canEditPromo.bind(this);
    //
    this.getServiceAndCalculateLogic();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (!this.state.data) {
      this.componentGetDataFromProp(data);
    }
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;
    if (data) {
      this.componentGetDataFromProp(data);
      this.props.paymentMethodActions.paymentMethod(
        this.props.auth.selectedFleet.fleetId
      );
      if (
        data.travelerType == travelerType.Individual ||
        (data.travelerType == travelerType.Corporate &&
          data.request.paymentType == paymentMethodNumber.personalCard)
      ) {
        if (data.psgInfo && data.psgInfo.creditInfo) {
          this.setState({
            credit: data.psgInfo.creditInfo,
            customer: data.psgInfo,
          });
        }
      } else if (data.corporateInfo && data.corporateInfo.creditInfo) {
        this.setState({
          credit: data.corporateInfo.creditInfo,
          customer: data.psgInfo,
        });
      }
      if (this.props.commonData.location.isChina) return;
      const latLng = new google.maps.LatLngBounds();
      const defaultMap =
        this.props.commonData.mapZone &&
        this.props.commonData.mapZone.filter((m) => m.isDefault)[0];
      if (defaultMap) {
        defaultMap.geo.coordinates.map((cor) => {
          cor.map((z) => {
            latLng.extend(new google.maps.LatLng(z[1], z[0]));
          });
        });
        const center = latLng.getCenter();
        const bound = new google.maps.Circle({
          radius: 100000,
          center,
        }).getBounds();
        let centerLocation = `${center.lat()},${center.lng()}`;
        this.setState({
          mapDefaultBound: bound,
          optionAutocomplete: {
            radius: '100000',
            location: centerLocation,
            fleetId: this.props.auth.selectedFleet.fleetId,
          },
          center: {
            lat: data.request.pickup.geo[1],
            lng: data.request.pickup.geo[0],
          },
        });
      }

      if (data.request && data.request.promo && data.request.promo !== '') {
        const { promoCodeMessageContent } = this.state;
        this.props.bookingDetailActions
          .getPromotionCodeInfo({
            fleetId: this.props.auth.selectedFleet.fleetId,
            code: data.request.promo,
          })
          .then((data) => {
            if (data.ok && data.res) {
              let paymentMethods = [];
              let minimumEstFare = '';
              let schedules = false;
              if (data.res.paymentMethodsApply.length > 0) {
                paymentMethods = data.res.paymentMethodsApply;
              }

              if (data.res.minimumEstFareApply.isLimited) {
                minimumEstFare = currencyFormatter.format(
                  data.res.minimumEstFareApply.valueByCurrencies[0].value,
                  {
                    code: data.res.minimumEstFareApply.valueByCurrencies[0]
                      .currencyISO,
                  }
                );
              }

              if (data.res.scheduleEnable) {
                schedules = data.res.scheduleEnable;
              }

              this.setState({
                promoCodeMessageContent: {
                  ...promoCodeMessageContent,
                  paymentMethods,
                  minimumEstFare,
                  schedules,
                },
              });
            }
          });
      }
    }
  }

  componentDidMount = () => {
    this.getSMSBookingTemplateList(false, () => {}, true)
    const batchId = _.get(this.props.bookingDetail, 'data.recurring.batchId')
    if(batchId) {
      this.props.bookingDetailActions
      .getStatusRecurring({
        fleetId: this.props.auth.selectedFleet.fleetId,
        batchId: batchId,
      })
      .then((result) => {
        if (result.ok && result.res) {
          this.setState({
            recurringBookingList: result.res.list
          }) 
        }  
      });
      const dataBooking = this.props.data
      if (dataBooking.recurring && dataBooking.recurring.mode === 'recurring') {
        this.setState({
          data: {
            ...this.state.data,
            batchId: dataBooking.recurring.batchId
          },
          dateMode: 'recurring',
          recurringDays: dataBooking.recurring.selectedDates || [],
          recurringTime: dataBooking.recurring.pickupTime || '12:00'   
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.prevEtaFare !== prevState.prevEtaFare &&
      prevState.prevEtaFare &&
      (prevState.prevEtaFare.isFareEdited ||
        prevState.data.request.estimate.forceMarkupPrice)
    ) {
      this.setState({ showWarningResetEditFare: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (
      !this.state.data ||
      (data.bookId != this.state.data.bookId && data.groupId) // Apply new data when click other booking in group booking
    ) {
      this.componentGetDataFromProp(data);
    }
  }

  handleResultUpdateBooking = (data) => {
    this.props.loadingActions.hideLoadingSpiner();
    if (data.code == 200 || data.returnCode == 200) {
      this.context.notification(
        'success',
        I18n.t('messages.booking.Update_booking_success')
      );
      this.props.bookingDetailActions.bookingDetailClosed();
    } else {
      const errMgs = this.showMessageErrorBooking(data);
      if (errMgs) {
        this.context.notification('error', errMgs);
      }
    }
  }

  showMessageErrorBooking = (data) => {
    let msg = (
      <Translate value="messages.booking.data_incorect" dangerousHTML />
    );
    if(data?.error?.errorCode === "EXTERNAL_ID_EXISTED") {
      msg = I18n.t('messages.booking.EXTERNAL_ID_EXISTED');
      return msg;
    }
    if (data.code) {
      msg = I18n.t('messages.booking.' + data.code);
      const dataInfo = data.info || data.error;
      if (data.code == 303) {
        if (data.error && !data.error.removeRecipient) {
          if (
            [
              'REMOVE_RECIPIENT_DELIVERED',
              'REMOVE_LAST_ACTIVE_RECIPIENT',
            ].includes(data.error.errorCode)
          ) {
            msg = I18n.t(`messages.booking.${data.error.errorCode}`);
          }
        }
        if (data.error.minimumBookAhead) {
          let hour = 0;
          const min = data.error.minimumBookAhead % 60;
          if (data.error.minimumBookAhead > 0) {
            hour = Math.floor(data.error.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (data.error.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            data.error.maximumBookAhead
          );
        }
        if (data.error.overlapTime) {
          this.showConfirmForceOverlap();
          msg = null;
        } else if (data.error.cannotAssignDriver) {
          msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated');
        }
      } else if (dataInfo && dataInfo.exDoDiffDo === false) {
        msg = I18n.t('messages.booking.exDoDiffDo');
      }
    } else {
      const dataInfo = data.info || data.error;
      if (dataInfo.limit === false) {
        msg = I18n.t('messages.booking.booking_limited');
      } else if (dataInfo.sameZone === false && dataInfo.crossZone === false) {
        msg = I18n.t('messages.booking.area_service_unavailable');
      } else if (!dataInfo.rate) {
        msg = I18n.t(
          'messages.booking.Please_reload_page_or_check_your_connection'
        );
      } else if (!dataInfo.pickUpTime) {
        msg = I18n.t('messages.booking.time_invalid');
        if (dataInfo.minimumBookAhead) {
          let hour = 0;
          let min = dataInfo.minimumBookAhead % 60;
          if (dataInfo.minimumBookAhead > 0) {
            hour = Math.floor(dataInfo.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (dataInfo.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            dataInfo.maximumBookAhead
          );
        }
        if (dataInfo.exDoDiffDo === false) {
          msg = I18n.t('messages.booking.exDoDiffDo');
        }
      } else if (!dataInfo.recurringTimeRange) {
        msg = I18n.t('messages.booking.recurringTimeRange').format(1);
      } else if (
        dataInfo.psgInfo === false ||
        dataInfo.psgInfo.isActive === false
      ) {
        msg = I18n.t('messages.booking.psg_inactivate');
      } else if (dataInfo.preAuthorized === false) {
        if (dataInfo.preAuthorizedCode) {
          msg = I18n.t('messages.credits.' + dataInfo.preAuthorizedCode);
        }
      } else if (dataInfo.cannotAssignDriver) {
        msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated');
      } else if (dataInfo.overlapTime) {
        this.showConfirmForceOverlap();
        msg = null;
      } else if (!dataInfo.recipientsLimit) {
        msg = I18n.t('messages.booking.recipientsLimit').format(
          dataInfo.recipientsLimitNumber
        );
      } else if (dataInfo.promo) {
        switch (dataInfo.promo) {
          case 10:
            msg = I18n.t('messages.promoMsg.430');
            break;
          case 11:
            msg = I18n.t('messages.promoMsg.431');
            break;
          case 12:
            msg = I18n.t('messages.promoMsg.432');
            break;
          case 13:
            msg = I18n.t('messages.promoMsg.433');
            break;
          default:
            break;
        }
      }
    }
    return msg;
  };

  completeWithPayment3rdBooking() {
    const params = {
      bookId: this.state.data.bookId,
      paymentType: parseInt(this.state.retryPaymentMethod),
      fleetId: this.props.auth.selectedFleet.fleetId,
    };

    if (params.paymentType == 1 || params.paymentType == 7) {
      params.isCharge = this.state.isRetryPaymentCharge;
      if (this.state.data.request.paymentType == 6) {
        params.paymentType = 3;
      }
      if (this.state.data.request.paymentType == 4) {
        params.paymentType = 7;
      }
      if (this.state.data.request.paymentType == 2) {
        params.paymentType = 1;
      }
    }

    if (params.paymentType == 10) {
      const object = {
        bookId: this.state.retryCompleteBookingInfo.bookId,
        total: this.state.retryCompleteBookingInfo.total,
        fare: this.state.retryCompleteBookingInfo.fare,
        tip: this.state.retryCompleteBookingInfo.tip,
        fleetId: this.props.auth.selectedFleet.fleetId,
        tax: this.state.retryCompleteBookingInfo.tax,
        promoCode: this.state.retryCompleteBookingInfo.promoCode,
        promoAmount: this.state.retryCompleteBookingInfo.promoAmount,
        techFee: this.state.retryCompleteBookingInfo.techFee,
        subTotal: this.state.retryCompleteBookingInfo.subTotal,
        paymentType: params.paymentType,
        isMinimum: this.state.retryCompleteBookingInfo.isMinimum,
        psgId: this.state.data.psgInfo.userId,
        currencyISO: this.state.retryCompleteBookingInfo.currencyISOCharged,
        address: this.state.data.request.destination.address,
        geo: this.state.data.request.destination.geo,
        zipCode: this.state.data.request.destination.zipCode,
        operator: {
          userId: this.props.auth.user._id,
          name: `${this.props.auth.user.firstName || ''} ${
            this.props.auth.user.lastName || ''
          }`,
        },
      };
      this.props.bookingDetailActions.completeBooking(object)
      .then(res => {
        this.handleResultCompleteBooking(res)
      })
    } else {
      this.props.bookingDetailActions.retryCompleteBooking(params)
      .then(res => {
        this.handleResultRetryCompleteBooking(res)
      })
    }

    this.props.loadingActions.showLoadingSpiner();
  }

  componentGetDataFromProp(data, isInit = false) {
    // Initialize Booking Data
    if (!data) return null;
    let state = Object.assign({}, data);
    let { auth } = this.props;

    state.puPoints = [
      {
        order: 1,
        id: new Date().getTime(),
        hasLocation: true,
        address: {
          ...state.deliveryInfo.pickup.address,
          lat: state.deliveryInfo.pickup.address.geo[1],
          lng: state.deliveryInfo.pickup.address.geo[0],
        },
        sender: {
          traveler_type: state.travelerType,
          travelerType: state.travelerType,
          phone: state.deliveryInfo.pickup.phone,
          firstname: state.deliveryInfo.pickup.firstName || '',
          lastname: state.deliveryInfo.pickup.lastName || '',
          email: state.deliveryInfo.pickup.email || '',
        },
      },
    ];
    state.sessionKeyETAFare = state?.request?.sessionKey || ''
    state.doPoints = state.deliveryInfo.recipients.map((item) => {
      let recipient = getRecipientFromMenuData(item.menuData);
      return {
        order: item.order + 1,
        address: {
          ...item.address,
          lat: item.address.geo[1],
          lng: item.address.geo[0],
        },
        id: new Date().getTime() + item.order,
        hasLocation: true,
        recipient: {
          ...recipient,
          _id: item._id,
          status: item.status,
          name: item.name,
          phone: item.phone,
          menuData: item.menuData,
          orderIdServer: item.orderId,
          finishedInfo: item.finishedInfo
        },
      };
    });

    let timezone =
      state.request.pickup.timezone ||
      (auth.selectedFleet && auth.selectedFleet.timezone);
    state.promoApplied = false;
    state.request.pickUpTime =
      state.request.pickUpTime == 'Now'
        ? 'Now'
        : moment.tz(state.request.pickUpTime, timezone);
    state.rideSharing = state.request.rideSharing;
    state.packageRateId = state.request.packageRateId;
    if (!state.request.estimate.fare) {
      state.request.estimate.fare = {};
    }
    if (state.request.promo && state.request.promo.length > 0) {
      state.promoApplied = true;
    }
    state.originPromo = state.request.promo;
    state.originPromoInfo = state.request.promoInfo;
    state.farmOut = false;
    if (state.pricingType === 1) {
      if (state.request.psgFleetId === this.props.auth.selectedFleet.fleetId) {
        state.farmOut = true;
      }
    }

    if (
      CCLiteCommonFunc.isBookingStatusCompleted(state.status) &&
      !CCLiteCommonFunc.isDisplayCancelNoShowAmount(state)
    ) {
      if (state.completedInfo) {
        state.request.paymentType =
          CCLiteCommonFunc.paymentMappingCompletedToActive(
            state.completedInfo.paymentType
          );
      }
    }

    if (this.state) {
      this.state.locationPickUp = {
        lat: this.state.data.request.pickup.geo[1],
        lng: this.state.data.request.pickup.geo[0],
        city: this.state.data.request.pickup.city,
        cityName: this.state.data.request.pickup.cityName,
        zipCode: this.state.data.request.pickup.zipCode,
        address: this.state.data.request.pickup.address,
        businessName: this.state.data.request.pickup.businessName,
        from: this.state.data.request.pickup.from,
        offset: this.state.data.request.pickup.offset,
        timezone: this.state.data.request.pickup.timezone,
        countryCode: this.state.data.request.pickup.country,
        instructionLink: this.state.data.request.pickup.instructionLink || '',
        addressDetails: this.state.data.request.pickup.addressDetails || {},
      };
      if (
        this.state.data.request.destination &&
        this.state.data.request.destination.address &&
        this.state.data.request.destination.address.length > 0
      ) {
        this.state.locationDestination = {
          lat: this.state.data.request.destination.geo[1],
          lng: this.state.data.request.destination.geo[0],
          city: this.state.data.request.destination.city,
          cityName: this.state.data.request.destination.cityName,
          zipCode: this.state.data.request.destination.zipCode,
          address: this.state.data.request.destination.address,
          from: this.state.data.request.destination.from,
          countryCode: this.state.data.request.destination.country,
          instructionLink:
            this.state.data.request.destination.instructionLink || '',
          addressDetails:
            this.state.data.request.destination.addressDetails || {},
        };
      } else {
        this.state.directions = null;
        this.state.disDur = null;
      }
      if (
        this.state.locationPickUp &&
        this.state.locationPickUp.address &&
        this.state.locationPickUp.address.length > 0 &&
        (!this.state.locationPickUp.from ||
          this.state.locationPickUp.from == locationType.tencent)
      ) {
        this.state.locationPickUp.from = locationType.google;
      }
      if (
        this.state.locationDestination &&
        this.state.locationDestination.address.length > 0 &&
        (!this.state.locationDestination.from ||
          this.state.locationDestination.from == locationType.tencent)
      ) {
        this.state.locationDestination.from = locationType.google;
      }

      this.state.paymentComplete = {
        tax: 0,
        subTotal: 0,
        total: 0,
        isMinFare: false,
        paymentType: 0,
      };
      if (state.request.paymentType == 3) {
        this.state.isShowmDispatcherCard = true;
      }
      if (!state.drvInfo) {
        state.drvInfo = {};
      }
      if (!state.request.travelerType) {
        if (state.psgInfo.creditInfo) {
          this.state.credit = state.psgInfo.creditInfo;
        }
      } else if (state.corporateInfo.creditInfo) {
        this.state.credit = state.psgInfo.creditInfo;
      }

      if (state.request.estimate.fare) {
        if (state.request.estimate.fare.etaFare) {
          const markupDifference = state.request.estimate.fare.markupDifference;
          if (markupDifference) {
            state.request.estimate.fare.etaFare =
              state.request.estimate.fare.etaFare - markupDifference;
            state.request.estimate.fare.unroundedTotalAmt =
              state.request.estimate.fare.unroundedTotalAmt - markupDifference;
            state.request.estimate.fare.totalWithoutPromo =
              state.request.estimate.fare.totalWithoutPromo - markupDifference;
          }
        }
        if (
          state.request.estimate.fare.addOnPrice &&
          state.request.estimate.fare.addOnPrice !== 0
        ) {
          this.state.addOnPrice = state.request.estimate.fare.addOnPrice;
        }
      }

      if (
        data.request.estimate.isFareEdited ||
        data.request.estimate.fare.markupDifference
      ) {
        this.state.isFareEditedBefore = true;
        this.state.prevEtaFare = {
          originFare: data.request.estimate.originFare || {},
          isFareEdited: data.request.estimate.isFareEdited,
          reasonEditFare: data.request.estimate.reasonEditFare || '',
        };
      } else {
        this.state.prevEtaFare = {
          reasonEditFare: data.request?.estimate?.reasonEditFare || ''
        }
      }
      if (
        data.request.estimate.isFareEdited &&
        !data.request.estimate.fare.markupDifference
      ) {
        this.state.editFare = {};
        for (var key in data.request.estimate.fare) {
          if (EDITABLE_FARE.includes(key)) {
            if (data.request.estimate.fare.hasOwnProperty(key)) {
              if (
                data.request.estimate.fare[key] !==
                data.request.estimate.originFare[key]
              ) {
                this.state.editFare[key] = data.request.estimate.fare[key];
              }
            }
          }
        }
      }
      let statusText;
      if (state.status === 'booked' || state.status === 'arrived') {
        statusText = `statusDisplay.delivery_${state.status}`;
      } else {
        statusText = `statusDisplay.${state.status}`;
      }
      state = Object.assign(this.state.data || {}, state);
      let originCompanyId = ''
      const userTypeCurrent = _.get(this.props.auth, 'user.userType', '')
      const companyIdOfUser = _.get(this.props.auth, 'user.roles.companyId', '')
      if(userTypeCurrent === userType.FleetUser && companyIdOfUser)  {
        originCompanyId = companyIdOfUser
      } else {
        if (data.request.companyId)
        originCompanyId = data.request.companyId
      }
      this.setState(
        {
          showConfirmComplete: false,
          paymentStep: 0,
          statusText,
          etaFare: state.request.estimate.fare,
          data: state,
          mapCenter:
            this.props.commonData.location &&
            this.props.commonData.location.isChina
              ? new window.qq.maps.LatLng(
                  this.state.locationPickUp.lat,
                  this.state.locationPickUp.lng
                )
              : this.state.locationPickUp,
          driverCompany: {
            _id: originCompanyId,
          },
        },
        () => {
          this.getServiceAndCalculateLogic();
        }
      );
    }
    return state;
  }

  paymentCompleteSubtotalCalculator() {
    const { addOnPrice, data } = this.state;
    const estimateFare =
      data.pricingType == isHydraBooking
        ? data.request.estimate.fare.estimateFareBuy
        : data.request.estimate.fare;
    const isFareEdited =
      data.request.estimate && data.request.estimate.isFareEdited
        ? data.request.estimate.isFareEdited
        : false;

    this.state.paymentComplete.subTotal = 0;
    if (estimateFare) {
      this.state.paymentComplete.subTotal += parseFloat(
        estimateFare.deliveryFee
      );

      this.state.paymentComplete.isMinFare = false;
      if (this.state.paymentComplete.subTotal < estimateFare.minFare) {
        this.state.paymentComplete.subTotal = estimateFare.minFare;
        this.state.paymentComplete.isMinFare = true;
      }
    }
    if (
      this.props.auth.selectedFleet.fleetFareId &&
      this.props.auth.selectedFleet.fleetFareId.taxActive
    ) {
      this.state.paymentComplete.tax =
        ((this.state.data.pricingType == 1
          ? this.state.fareSettings.tax
            ? this.state.fareSettings.tax.surcharge
            : 0
          : this.state.fareSettings.fare.tax) *
          this.state.paymentComplete.subTotal) /
        100;
    }

    // Calculation Adjusted price
    if (addOnPrice !== 0) {
      this.state.paymentComplete.addOnPrice = addOnPrice;
      this.state.paymentComplete.subTotal += addOnPrice;
    }

    this.setState({ paymentComplete: this.state.paymentComplete });
  }

  reloadCustomerTSYSGateway = (number) => {
    const travelerType =
      this.state.data.travelerType == 0 ? 'individual' : 'corporation';
    this.props.customerAutocompleteActions
      .customerAutocomplete({
        fleetId: this.props.auth.selectedFleet.fleetId,
        str: trimPhoneNumber(number).replace(/\D/g, ''),
        travelerType,
      })
      .then((data) => {
        if (data.res) {
          let suggestions = (data.res && data.res.list) || [];
          if (suggestions[0]) {
            const suggestData = suggestions[0];
            if (
              this.state.data.request.paymentType ===
              paymentMethodNumber.personalCard
            ) {
              const { customer } = this.state;
              customer.credits = suggestData.credits;
              this.setState({ customer });
            }
            if (
              this.state.data.request.paymentType ===
              paymentMethodNumber.corporateCard
            ) {
              const { company } = this.state;
              // company.credits.push(cardAdded);
              this.setState({ company });
            }
          }
        }
      });
  };

  sendSMSBooking = e => {
    this.setState({
      showSMSBooking: !this.state.showSMSBooking,
      smsWillSend: {
        id: "",
        phone: !_.isString(this.props.data.psgInfo) && this.props.data.psgInfo.userId && this.props.data.psgInfo.userId.length > 0 ? (this.props.data.psgInfo.phone || '') : (this.props.data.drvInfo.phone || ''),
        name: "",
        content: "",
        subject: '',
        typeMessage: 'Inbox',
        typeRecipient: !_.isString(this.props.data.psgInfo) && this.props.data.psgInfo.userId && this.props.data.psgInfo.userId.length > 0 ? 'passenger': 'driver',
      },
      isSend: false,
      smsBookingTemplateQuery: {
        ...this.state.smsBookingTemplateQuery,
        selectedObj: null
      }
    });
  };

  getSMSBookingTemplateList = (loadMore = false, callback = null, firstLoad = false) => {
    let bodyRequest = {
      fleetId: this.props.auth.selectedFleet.fleetId,
    };

    this.props.settingActions
      .getAllBookingSMSTemplates(bodyRequest)
      .then((data) => {
        if (data.ok && data.res) {
          let listFiter = data.res;
          const newData = listFiter.map((item) => {
            item.value = item.name;
            item.id = item._id;
            return item;
          });
          this.setState({
            smsBookingTemplateQuery: {
              ...this.state.smsBookingTemplateQuery,
              ...data.res,
              list: newData,
              listAll: newData,
            },
          });
        }
      });
  };

  handleSelectSMSBookingTemplate = (templateId) => {
    const smsBookingTemplateQuery = Object.assign(
      {},
      this.state.smsBookingTemplateQuery
    );
    smsBookingTemplateQuery.selectedObj = _.find(
      this.state.smsBookingTemplateQuery.list,
      (item) => item.id === templateId
    );

    if((templateId && templateId.length === 0) || !templateId) {
      smsBookingTemplateQuery.list = this.state.smsBookingTemplateQuery.listAll
    }

    this.setState({
      smsBookingTemplateQuery,
      smsWillSend: {
        ...this.state.smsWillSend,
        id: templateId || "",
        name: smsBookingTemplateQuery.selectedObj && smsBookingTemplateQuery.selectedObj.name ? smsBookingTemplateQuery.selectedObj.name : "",
        subject: smsBookingTemplateQuery.selectedObj && smsBookingTemplateQuery.selectedObj.name ? smsBookingTemplateQuery.selectedObj.name : "",
        content: smsBookingTemplateQuery.selectedObj && smsBookingTemplateQuery.selectedObj.content ? smsBookingTemplateQuery.selectedObj.content : "",
      },
    });
  };

  handleChangeMessageType = (value) => {
    this.setState({
      smsWillSend: {
        ...this.state.smsWillSend,
        typeMessage: value,
      }
    })
  }

  handleSendMessageClick = () => {
      let data = Object.assign({}, this.state.smsWillSend)
      data.phone = trimPhoneNumber(data.phone);
      data.bookingId = (this.state.data && this.state.data.bookId)
      const keyListArr = [
        '[FLEET_NAME]',
        '[FLEET_PHONE]',
        '[FLEET_EMAIL]',
        '[BOOKING_ID]',
        '[PASSENGER_FIRST_NAME]',
        '[PASSENGER_LAST_NAME]',
        '[PASSENGER_PHONE]',
        '[DRIVER_FIRST_NAME]',
        '[DRIVER_LAST_NAME]',
        '[DRIVER_PHONE]',
        '[PICKUP_TIME]',
        '[TRACK_LINK]'
      ]
      keyListArr.forEach((item) => {
        if(data.content.includes(item)) {
          let value = ''
          switch (item) {
            case '[FLEET_NAME]':
              value = this.props.auth.selectedFleet.name || ''
              break;            
            case '[FLEET_PHONE]':
              value = this.props.auth.selectedFleet.phone || ''
              break;            
            case '[FLEET_EMAIL]':
              value = this.props.auth.selectedFleet.email || ''
              break;            
            case '[BOOKING_ID]':
              value = this.state.data.bookId || ''
              break;            
            case '[PASSENGER_FIRST_NAME]':
              value = this.state.data.psgInfo && this.state.data.psgInfo.firstName && this.state.data.psgInfo.firstName || ''
              break;            
            case '[PASSENGER_LAST_NAME]':
              value = this.state.data.psgInfo && this.state.data.psgInfo.lastName && this.state.data.psgInfo.lastName || ''
              break;            
            case '[PASSENGER_PHONE]':
              value = (this.state.data.psgInfo && this.state.data.psgInfo.phone && this.state.data.psgInfo.phone !== 'No Phone' ? this.state.data.psgInfo.phone : '') || ''
              break;            
            case '[DRIVER_FIRST_NAME]':
              value = this.state.data.drvInfo && this.state.data.drvInfo.firstName && this.state.data.drvInfo.firstName || ''
              break;            
            case '[DRIVER_LAST_NAME]':
              value = this.state.data.drvInfo && this.state.data.drvInfo.lastName && this.state.data.drvInfo.lastName || ''
              break;            
            case '[DRIVER_PHONE]':
              value = (this.state.data.drvInfo && this.state.data.drvInfo.phone && this.state.data.drvInfo.phone !== 'No Phone' ? this.state.data.drvInfo.phone : '') || ''
              break;            
            case '[PICKUP_TIME]':
              let result = ''
              if(this.props.data.request.pickUpTime !== 'Now') {
                let timePickup = moment.tz(this.props.data.request.pickUpTime, this.props.auth.selectedFleet.timezone).toString()
                result = timePickup.slice(0, timePickup.indexOf('GMT') - 1)
              }
              value = this.props.data.request.pickUpTime === 'Now' ? 'Now' : result        
              break;  
            case '[TRACK_LINK]':
              value = `${this.props.auth.selectedFleet.trackLink}/${this.state.data.token}` || ''
              break;
          }
          data.content = data.content.replaceAll(item, value)
        }
      })
      this.setState({isSend: true});
      if (this.state.smsWillSend.content.length === 0 || this.state.smsWillSend.phone.length === 0 || this.state.smsWillSend.subject.length === 0) return;
      if (Validation.validateSearchingPhone(data.phone) === false) {
        return this.context.notification(
          'error',
          I18n.t('errors.24001')
        );
      } else {
        data.fleetId = this.props.auth.selectedFleet.fleetId;
        this.setState({disabledSpam: true})
        this.props.settingActions
          .sendMessageBooking(data)
          .then((data) => {
            if(data && data.error === null) {
              this.context.notification(
                'success',
                I18n.t('report.result.bookingDetails.successSendMessage')
              )
              this.sendSMSBooking()
              return this.setState({disabledSpam: false})
            } else {
              this.context.notification(
                'error',
                data.error.message
              )
              return this.setState({disabledSpam: false})
            }
          })
      }
  }

  handleSearchSMSTemplate = (inputValue, callback, forceUpdate = false) => {
    let params = _.pick(this.state.smsBookingTemplateQuery, [
      'search',
      'listAll',
      'list',
      'selectedObj'
    ]);
    if(params.listAll.length === 0 ) return
    if ((inputValue && inputValue.length > 0)) {
      params.search = inputValue;
      params.list = params.listAll.filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()))
      this.setState({smsBookingTemplateQuery: {...this.state.smsBookingTemplateQuery, ...params}})
    } else {
      params.search = '';
      params.list = params.listAll
      this.setState({smsBookingTemplateQuery: {...this.state.smsBookingTemplateQuery, ...params}})
    }
    return callback()
  };

  handleChangeRecipientType = (value) => {
    this.setState({
      smsWillSend: {
        ...this.state.smsWillSend,
        typeRecipient: value,
        phone: value === 'driver' ? this.props.data.drvInfo.phone : this.props.data.psgInfo.phone
      }
    })
  }

  handleContentChange = (e) => {
    this.setState({ smsWillSend: {...this.state.smsWillSend, content: e} });  
  }

  handlePhoneChange = (e) => {
    this.setState({ smsWillSend: {...this.state.smsWillSend, phone: e.target.value} });
  }

  handleSubjectChange = (e) => {
    this.setState({ smsWillSend: {...this.state.smsWillSend, subject: e.target.value} });
  }

  paymentCompleteTotalCalculator() {
    const estimateFare =
      this.state.data.pricingType == isHydraBooking
        ? this.state.data.request.estimate.fare.estimateFareBuy
        : this.state.data.request.estimate.fare;

    this.state.paymentComplete.total = this.state.paymentComplete.subTotal;
    if (this.props.auth.selectedFleet.techFeeActive) {
      this.state.paymentComplete.total += parseFloat(estimateFare.techFee || 0);
    }
    if (
      this.props.auth.selectedFleet.fleetFareId &&
      this.props.auth.selectedFleet.fleetFareId.taxActive
    ) {
      this.state.paymentComplete.total += parseFloat(
        this.state.paymentComplete.tax || 0
      );
    }
    this.state.paymentComplete.total = CCLiteCommonFunc.currencyNumberFormat(
      this.state.paymentComplete.total,
      this.state.data.currencyISO
    );
    this.setState({ paymentComplete: this.state.paymentComplete });
  }

  calculatePromoAmount(payment, promo) {
    if (promo) {
      const totalWithPromo = payment.total;
      if (promo.type) {
        if (promo.type == 'Amount') {
          return promo.value;
        }
        let valuePromo = (payment.subTotal * promo.value) / 100;
        // SL-22051: Support check maximum value/use  in form completed booking
        if (
          promo.type === 'Percent' &&
          promo.maximumValue &&
          promo.maximumValue.isLimited
        ) {
          return valuePromo >= promo.maximumValue.value
            ? promo.maximumValue.value
            : valuePromo;
        } else {
          return valuePromo;
        }
      }
      if (promo.value && promo.value.indexOf('%') >= 0) {
        const proValue = parseFloat((promo.value || '').replace(/^\D+/g, ''));
        let valuePromo = (payment.subTotal * proValue) / 100;
        if (promo.maximumValue && promo.maximumValue.isLimited) {
          return valuePromo >= promo.maximumValue.value
            ? promo.maximumValue.value
            : valuePromo;
        } else {
          return valuePromo;
        }
      }
      const proValue = parseFloat((promo.value || '').replace(/^\D+/g, ''));
      return proValue;
    }
    return 0;
  }

  calculatorTotal(payment, promo, isRoundOff) {
    const { selectedFleet } = this.props.auth;
    let total = payment.total;
    if (promo) {
      let totalWithPromo = payment.total;
      if (promo.type) {
        if (promo.type == 'Amount') {
          totalWithPromo = payment.total - promo.value;
        } else {
          let valuePromo = (payment.subTotal * promo.value) / 100;
          if (promo.maximumValue && promo.maximumValue.isLimited) {
            let newValuePromo =
              valuePromo >= promo.maximumValue.value
                ? promo.maximumValue.value
                : valuePromo;
            totalWithPromo = payment.total - newValuePromo;
          } else {
            totalWithPromo = payment.total - valuePromo;
          }
        }
      } else if (promo.value && promo.value.indexOf('%') >= 0) {
        const proValue = parseInt((promo.value || '').replace(/^\D+/g, ''));
        let valuePromo = (payment.subTotal * proValue) / 100;
        if (promo.maximumValue && promo.maximumValue.isLimited) {
          let newValuePromo =
            valuePromo >= promo.maximumValue.value
              ? promo.maximumValue.value
              : valuePromo;
          totalWithPromo = payment.total - newValuePromo;
        } else {
          totalWithPromo = payment.total - valuePromo;
        }
      } else if (!_.isEmpty(promo.value)) {
        const proValue = parseInt((promo.value || '').replace(/^\D+/g, ''));
        totalWithPromo = payment.total - proValue;
      }
      if (totalWithPromo < 0) {
        total = 0;
      } else {
        total = totalWithPromo;
      }
    } else {
      total = payment.total;
    }

    return isRoundOff
      ? roundOff(total, this.state.data.currencyISO, selectedFleet.rounding)
      : total;
  }

  close = () => {
    this.props.closeBookingDialog();
  };

  cannelBookingButtonRecurring = (hasRecurring = false, cancelAll = false) => {
    let dataEmit = {
      'x-request-id': uuidv4({ msecs: new Date().getTime() }),
      bookId: this.state.data.bookId,
      reason: '',
      fleetId: this.props.auth.selectedFleet.fleetId,
      canceller:
        this.props.auth.user.roles.roleName == 'corporateAdmin'
          ? 'CorpAD'
          : 'CC',
      operator: {
        userId: this.props.auth.user._id,
        name: `${this.props.auth.user.firstName || ''} ${
          this.props.auth.user.lastName || ''
        }`,
      },
    };
    if (hasRecurring) {
      dataEmit.cancelAll = cancelAll;
    }
    this.props.loadingActions.showLoadingSpiner();
    this.props.bookingDetailActions.cancelBooking(dataEmit)
    .then(res => {
      this.props.loadingActions.hideLoadingSpiner();
      if (res.returnCode == 200) {
        this.context.notification(
          'success',
          I18n.t('messages.booking.Cancel_booking_success')
        );
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        this.context.notification('error', I18n.t('messages.booking.cancle_fail'));
      }
    })
  };

  cancelBookingButtonClick(e) {
    this.setState({ showConfirmCancel: !this.state.showConfirmCancel });
  }

  cancelBookingOkClick(e) {
    let { batchId } = this.state.data;
    //nếu là book recurring
    if (batchId) {
      this.setState({
        showConfirmRecurring: true,
        dataConfirmRecurring: {
          title: 'Cancel recurring order',
          content:
            'Do you only want to cancel this order, or cancel this and all future orders?',
          funcHandleConfirmRecurring: this.cannelBookingButtonRecurring,
        },
      });
    } else {
      this.cannelBookingButtonRecurring();
    }
  }

  newBookingButtonClick(e) {
    const NewBK = {
      booking: this.state.data,
      customer: this.state.customer,
      cartypeSelected: this.state.cartypeSelected,
      company: this.state.company,
      credit: this.state.credit,
      isDelivery: true,
      isShuttle: false,
    };
    this.props.deliveryActions.newDeliveryBookingFromExistedBooking(NewBK);
    this.props.closeBookingDialog();
  }

  showDetailBookingGroup = (bookId) => {
    this.props.bookingDetailActions
      .getBookingDetail(bookId, this.state.selectedTab == 1)
      .then((data) => {
        if (!data.res || data.error) {
          this.context.notification('error', I18n.t('cue.Error_load_booking'));
        }
      });
  };

  closeRemoveBookingModal = () => {
    this.setState({
      isShowRemoveBookingModal: false,
      bookingRemoving: '',
    });
  };

  showRemoveBookingModal = (e, bookId) => {
    e.stopPropagation();
    this.setState({
      isShowRemoveBookingModal: true,
      bookingRemoving: bookId,
    });
  };

  handleUngroupBooking = () => {
    const { auth, data } = this.props;
    const groupId = data && data.groupId;
    if (groupId) {
      this.props.loadingActions.showLoadingSpiner()
      this.props.cueActions
        .ungroupBooking({
          fleetId: auth.selectedFleet.fleetId,
          groupId: groupId,
        })
        .then((data) => {
          this.props.loadingActions.hideLoadingSpiner()
          if (data.success) {
            this.context.notification(
              'success',
              I18n.t('messages.booking.upgroup_success')
            );
            this.close();
          }
        });
    }
  };

  removeBookingGroup = (bookingRemoving = '') => {
    if (!bookingRemoving) return;
    const {
      auth,
      data,
      data: { listGroup = [] },
    } = this.props;
    const groupId = data && data.groupId;
    if (bookingRemoving && groupId) {
      this.props.loadingActions.showLoadingSpiner()
      // Remove Booking out of group
      this.props.cueActions
        .removeBookingGroup({
          fleetId: auth.selectedFleet.fleetId,
          groupId: groupId,
          bookId: bookingRemoving,
        })
        .then((data) => {
          if (data.success) {
            this.context.notification(
              'success',
              I18n.t('messages.booking.remove_booking_success')
            );
            let newListGroup =
              (listGroup &&
                listGroup.filter((book) => book.bookId != bookingRemoving)) ||
              [];
            // Switch to other booking in group
            if (newListGroup.length > 0 && newListGroup[0].bookId) {
              this.props.bookingDetailActions
                .getBookingDetail(
                  newListGroup[0].bookId,
                  this.state.selectedTab == 1
                )
                .then((data) => {
                  if (!data.res || data.error) {
                    this.context.notification(
                      'error',
                      I18n.t('cue.Error_load_booking')
                    );
                  }
                });
            } else {
              this.close();
            }
            this.closeRemoveBookingModal();
          } else {
            this.context.notification(
              'error',
              I18n.t('messages.booking.remove_booking_false')
            );
          }
        });
      this.props.loadingActions.hideLoadingSpiner()
    }
  };

  updateBookingButtonClick = (e) => {
    const {
      data,
      credit,
      valid,
      cartypeSelected,
      locationPickUp,
      locationDestination,
    } = this.state;
    let { paymentType } = data.request;

    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }

    if (!CCLiteCommonFunc.isFormValid(valid)) {
      return;
    }
    let validDropCountry = checkDropOffCountry(
      cartypeSelected && cartypeSelected.dropOffCountry,
      locationPickUp,
      locationDestination
    );
    if (!validDropCountry.valid) {
      validDropCountry.err == 0
        ? this.context.notification(
            'error',
            I18n.t('messages.booking.DropOff_country')
          )
        : this.context.notification(
            'error',
            I18n.t('messages.booking.Different_Country')
          );
      return;
    }
    if (!paymentType || !parseInt(paymentType)) {
      return this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_payment_method')
      );
    }

    if (
      ((this.props.newBooking &&
        this.props.newBooking.locationService &&
        this.props.newBooking.locationService.crossZone) ||
        paymentType === paymentMethodNumber.personalCard ||
        paymentType === paymentMethodNumber.corporateCard) &&
      (!credit || credit.cardMask.length == 0)
    ) {
      return this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_or_add_a_card')
      );
    }

    this.setState({
      showConfirmUpdate: !this.state.showConfirmUpdate,
      data: {
        ...data,
        request: {
          ...data.request,
          paymentType,
        },
      },
    });
  };

  showHideConfirmRecurringModal = () => {
    this.setState({ showConfirmRecurring: !this.state.showConfirmRecurring });
  };

  handleErrorCheckPromoWithRecurring = (result) => {
    if (!result.returnCode) {
      this.context.notification(
        'error',
        I18n.t('bookingdetail.Check_promo_error')
      );
      return false
    }
    if (result.returnCode !== 200) {
      this.context.notification(
        'error',
        I18n.t(`messages.promoMsg.${result.returnCode}`)
      );
      return false
    }
    return true
  }

  updateInfoBookingConfirmed = async (hasRecurring = false, isEditAll = false) => {
    if (this.isFinished(this.state.data)) {
      // Call dispatch API to update booking finished
      const updateParams = {
        bookId: this.state.data.bookId,
        fleetId: this.state.data.fleetId,
        notes: _.get(this.state.data, 'request.note'),
        operatorNote: _.get(this.state.data, 'request.operatorNote'),
        bookingReference: this.state.data?.externalInfo?.bookingReference ?? '',
        clientCaseMatter: _.get(
          this.state.data,
          'corporateInfo.clientCaseMatter'
        ),
        chargeCode: _.get(this.state.data, 'corporateInfo.chargeCode'),
      };
      this.props.bookingDetailActions
        .updateFinishedBooking(updateParams)
        .then((res) => {
          this.handleResultUpdateFinishedBooking(res)
        });
    } else {
      const object = this.updateBookingRequestObjectBuilder(
        false,
        this.state.forceAssign
      );
      if (object) {
        // if (hasRecurring) {
        //   object.editAll = isEditAll;
        // }
        if (hasRecurring) {
          object.editAll = isEditAll;
          if(object.editAll && this.state.promo && this.state.promo.value > 0) {
            const result = await this.checkValidPromoCode(true)
            if(!this.handleErrorCheckPromoWithRecurring(result)) return 
          }
        }
        if (
          this.state.data.status == 'engaged' ||
          this.state.data.status == 'droppedOff'
        ) {
          const changePaymentObject = {
            paymentType: object.request.paymentType,
            bookId: object.bookId,
            fleetId: this.props.auth.selectedFleet.fleetId,
            operator: object.operator,
          };
          if (object.request.paymentType == paymentMethodNumber.corporateCard) {
            changePaymentObject.creditInfo = object.corporateInfo.creditInfo;
          }
          if (object.request.paymentType == paymentMethodNumber.personalCard) {
            changePaymentObject.creditInfo = object.psgInfo.creditInfo;
          }
          this.props.bookingDetailActions.changePaymentTypeBooking(changePaymentObject)
          .then(res => {
            this.handleResultUpdateBooking(res)
          })
        } else {
          this.props.bookingDetailActions.updateBooking(object)
          .then(res => {
            this.handleResultUpdateBooking(res)
          })
        }
        this.setState({
          showConfirmUpdate: !this.state.showConfirmUpdate,
          forceAssign: false,
        });
        this.props.loadingActions.showLoadingSpiner();
      }
    }
  };

  updateInfoBookingClick() {
    let { batchId } = this.state.data;

    // if book recurring then show modal edit all?
    if (batchId) {
      this.setState({
        showConfirmRecurring: true,
        dataConfirmRecurring: {
          title: 'Update recurring order',
          content:
            'Do you only want to update this order or update this and all future orders?',
          funcHandleConfirmRecurring: this.updateInfoBookingConfirmed,
        },
      });
    } else {
      this.updateInfoBookingConfirmed();
    }
  }

  updateInfoAndDispatchBookingConfirmed = (
    hasRecurring = false,
    isEditAll = false
  ) => {
    const object = this.updateBookingRequestObjectBuilder(
      true,
      this.state.forceAssign
    );
    if (hasRecurring) {
      object.editAll = isEditAll;
    }
    if (object) {
      this.setState({ retryDispatch: true, forceAssign: false });
      this.props.bookingDetailActions.updateBooking(object)
        .then(res => {
          this.handleResultUpdateBooking(res)
        })
      this.props.loadingActions.showLoadingSpiner();
    }
  };

  updateInfoAndDispatchBookingClick() {
    let { batchId } = this.state.data;

    if (batchId) {
      this.setState({
        showConfirmRecurring: true,
        dataConfirmRecurring: {
          title: 'Update recurring order',
          content:
            'Do you only want to update this order or update this and all future orders?',
          funcHandleConfirmRecurring:
            this.updateInfoAndDispatchBookingConfirmed,
        },
      });
    } else {
      this.updateInfoAndDispatchBookingConfirmed();
    }
  }

  handleDriverCalendarClick(driver) {
    const fromDate = moment(this.state.data.request.pickUpTime).add(-1, 'days');
    const toDate = moment(this.state.data.request.pickUpTime).add(1, 'days');
    this.props.newbookingActions
      .getDriverReservationBooking({
        fleetId: this.props.auth.selectedFleet.fleetId,
        driverId: driver.driver._id,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.state.driverReservationBookings = data.res;
          this.state.driverCalendarViewing = driver;
          this.setState({ isShowDriverCalendar: true });
        } else {
        }
      });
  }

  handleCloseDriverCalendarClick(e) {
    this.setState({
      isShowDriverCalendar: false,
      driverReservationBookings: [],
      driverReservationDatePriview: 0,
      driverCalendarViewing: null,
    });
  }

  handleDriverReservationBookingDateChange(num) {
    this.setState({
      driverReservationDatePriview:
        this.state.driverReservationDatePriview + num,
    });
  }

  completeWithPaymentButtonClick = (e) => {
    const paymentMethod = _.get(this.state.data, 'request.paymentType', 0);

    if (!parseInt(paymentMethod)) {
      return this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_payment_method')
      );
    }

    e.preventDefault();
    if (this.state.showConfirmComplete) {
      if (this.state.paymentStep == 0) {
        this.props.bookingDetailActions
          .getBookingDetail(
            this.state.data.bookId,
            !this.isActiveBooking(this.state.data)
          )
          .then((data) => {
            if (data.ok && data.res) {
              this.componentGetDataFromProp(data.res);
            } else {
              this.setState(
                {
                  showConfirmComplete: false,
                  paymentStep: 0,
                  data: _.cloneDeep(this.state.dataTemp),
                },
                () => {
                  this.componentGetDataFromProp(this.state.dataTemp);
                }
              );
            }
          });
      } else {
        this.setState({ paymentStep: 0 });
      }
    } else {
      if (
        this.state.data.request.destination &&
        this.state.data.request.destination.address.trim().length == 0 &&
        this.state.data.request.destination.geo.length == 2
      ) {
        const latLng = {
          lat: this.state.data.request.destination.geo[1],
          lng: this.state.data.request.destination.geo[0],
        };
        this.handleDestinationDrapEnd({ latLng });
      }
      if (
        this.state.data.dispatch3rd &&
        this.state.isCompleteWithPaymentShow3rdBooking
      ) {
        let paymentType = 1;
        if (this.state.data.travelerType) {
          paymentType = 7;
        }
        if (
          this.state.data.request.paymentType == 9 &&
          this.state.data.status == 'droppedOff'
        ) {
          paymentType = 10;
        }
        this.setState({
          showConfirmComplete: true,
          retryPaymentMethod: paymentType,
        });
      } else {
        let requestPaymentType = _.get(
          this.state.data,
          'request.paymentType',
          1
        );
        this.state.paymentComplete.paymentType = requestPaymentType;
        if (
          (requestPaymentType == 9 && this.state.data.status != 'droppedOff') ||
          requestPaymentType == 21
        ) {
          this.state.paymentComplete.paymentType = 1;
        }
        if (
          !this.state.locationDestination ||
          !this.state.locationDestination.lat ||
          this.state.locationDestination.address.length == 0
        ) {
          this.getFareSettings();
        } else {
          this.getDirectionAndDraw((response) => {
            if (
              this.props.commonData.location &&
              this.props.commonData.location.isChina
            ) {
              this.setState(
                {
                  disDur: QQUltis.getMinWayFromTencentResults(
                    response.result,
                    this.state.cartypeSelected,
                    this.props.auth.selectedFleet.unitDistance
                  ),
                  directions: QQUltis.PolylineDecompression(
                    response.result.routes[0].polyline
                  ),
                },
                () => {
                  if (this.state.data.pricingType == 1) {
                    this.getFareSettings();
                  } else {
                    this.etaFareCalculator((data) => {
                      this.getFareSettings();
                    }, 1);
                  }
                  // this.etaFareCalculator(data => {
                  //   this.getFareSettings();
                  // }, 1);
                }
              );
            } else {
              this.setState(
                {
                  disDur: {
                    distance: {
                      value: response.distanceValue,
                      text: response.distance
                    },
                    duration: {
                      value: response.estimateValue,
                      text: response.time
                    }
                  },
                },
                () => {
                  if (this.state.data.pricingType == 1) {
                  } else {
                    if (
                      this.state.data.request &&
                      this.state.data.request.estimate &&
                      this.state.data.request.estimate.isFareEdited
                    ) {
                      this.getFareSettings();
                    } else {
                      this.etaFareCalculator((data) => {
                        this.getFareSettings();
                      }, 1);
                    }
                  }
                  // this.etaFareCalculator(data => {
                  //   this.getFareSettings();
                  // }, 1);
                }
              );
            }
          }, true);
        }
      }
    }
  };

  getFareSettings() {
    const zoneId = _.get(this.state.data, 'request.pickup.zoneId');
    const ob = { fleetId: this.state.data.request.psgFleetId, zoneId };
    this.props.bookingDetailActions.getFleetFareSetting(ob).then((data) => {
      if (data.ok && data.res) {
        this.setState(
          {
            showConfirmComplete: true,
            paymentStep: 0,
            fareSettings: data.res,
            dataTemp: _.cloneDeep(this.state.data),
          },
          this.paymentCompleteSubtotalCalculator
        );
      } else {
        this.context.notification(
          'error',
          I18n.t('bookingdetail.Can_not_get_fare_setting')
        );
      }
    });
    if (this.state.data.pricingType) {
      ob.vehicleTypeId = this.state.data.request.vehicleType[0];
      ob.pickup = [
        this.state.locationPickUp.lng,
        this.state.locationPickUp.lat,
      ];
      this.props.bookingDetailActions
        .getFleetFareSetting(ob, true)
        .then((data) => {
          if (data.ok && data.res && data.res.response) {
            this.setState(
              {
                showConfirmComplete: true,
                paymentStep: 0,
                fareSettings: Object.assign(
                  this.state.fareSettings,
                  data.res.response
                ),
                dataTemp: _.cloneDeep(this.state.data),
              },
              this.paymentCompleteSubtotalCalculator
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('bookingdetail.Can_not_get_affilate_fare_setting')
            );
          }
        });
    }
  }

  isBookingFromPaxApp = (booking = {}) => {
    let bookFrom = this.props.commonData.bookingFrom.filter(
      (a) => a.value != 'PaxApp'
    );
    bookFrom = bookFrom.find(
      (a) => a.value == booking.bookFrom || a.label == booking.bookFrom
    );
    if (bookFrom) {
      return true;
    }
    return false;
  };

  completeWithPaymentOkButtonClick(e) {
    e.preventDefault();
    let paymentObject = {};
    if (this.state.disableCompletePaymentNextClick) return false;

    if (this.state.data.pricingType === isHydraBooking) {
      return this.handleCompleteBookingAffiliate();
    }
    if (this.state.paymentStep == 0) {
      const userId = this.state.data.mDispatcherInfo
        ? this.state.data.mDispatcherInfo.userId || ''
        : '';
      const corporateId = this.state.data.corporateInfo
        ? this.state.data.corporateInfo.corporateId || ''
        : '';
      if (userId || corporateId) {
        this.props.bookingDetailActions
          .getCommission({
            fleetId: this.props.auth.selectedFleet.fleetId,
            userId,
            corporateId,
          })
          .then((data) => {
            if (data.ok && data.res && data.res.res) {
              this.state.paymentStep = 1;
              this.state.commisson = data.res.res;
              // data.res.map(fee=>{
              //     if(fee.currencyISO==this.state.data.currencyISO){
              //         this.state.commisson = fee;
              //     }
              // });
              this.setState(this.state, this.paymentCompleteTotalCalculator);
            } else {
              this.context.notification(
                'error',
                I18n.t('bookingdetail.Can_not_check_commisson')
              );
            }
          });
      } else {
        this.state.paymentStep = 1;
        this.state.commisson = null;
        this.setState(this.state, this.paymentCompleteTotalCalculator);
      }
    } else {
      const estimateFare =
        this.state.data.pricingType == isHydraBooking
          ? this.state.data.request.estimate.fare.estimateFareBuy
          : this.state.data.request.estimate.fare;

      const isFareEdited =
        this.state.data.request.estimate &&
        this.state.data.request.estimate.isFareEdited
          ? this.state.data.request.estimate.isFareEdited
          : false;

      if (isFareEdited) {
        paymentObject = {
          address: this.state.locationDestination.address,
          geo: [
            this.state.locationDestination.lng,
            this.state.locationDestination.lat,
          ],
          zipCode: this.state.locationDestination.zipCode,
          bookId: this.state.data.bookId,
          total: parseFloat(estimateFare.etaFare || 0),
          fare: parseFloat(estimateFare.basicFare || 0),
          tip: parseFloat(estimateFare.tip || 0),
          tax: parseFloat(estimateFare.tax || 0),
          promoCode: estimateFare.promoCode,
          promoAmount: parseFloat(estimateFare.promoAmount || 0),
          techFee: parseFloat(
            this.props.auth.selectedFleet.techFeeActive
              ? estimateFare.techFee
              : 0
          ),
          subTotal: parseFloat(estimateFare.subTotal || 0),
          partnerCommission: parseFloat(estimateFare.bookingFee || 0),
          paymentType: CCLiteCommonFunc.paymentMappingActiveToCompleted(
            this.state.paymentComplete.paymentType
          ),
          isMinimum: 0,
          isPending: this.state.paymentComplete.isPending || false,
        };
      } else {
        paymentObject = {
          address: this.state.locationDestination.address,
          geo: [
            this.state.locationDestination.lng,
            this.state.locationDestination.lat,
          ],
          zipCode: this.state.locationDestination.zipCode,
          bookId: this.state.data.bookId,
          total: parseFloat(
            this.calculatorTotal(
              this.state.paymentComplete,
              this.state.promo
            ) || 0
          ),
          fare: parseFloat(estimateFare.basicFare || 0),
          tip: parseFloat(this.state.paymentComplete.tip || 0),
          tax: parseFloat(this.state.paymentComplete.tax || 0),
          promoCode: '',
          promoAmount: 0,
          techFee: parseFloat(
            this.props.auth.selectedFleet.techFeeActive
              ? estimateFare.techFee
              : 0
          ),
          subTotal: parseFloat(this.state.paymentComplete.subTotal || 0),
          partnerCommission: this.isBookingFromPaxApp(this.state.data)
            ? parseFloat(this.state.commissionValue || 0)
            : 0,
          paymentType: CCLiteCommonFunc.paymentMappingActiveToCompleted(
            this.state.paymentComplete.paymentType
          ),
          isMinimum: parseFloat(this.state.paymentComplete.isMinFare ? 1 : 0),
          isPending: this.state.paymentComplete.isPending || false,
        };

        if (
          this.state.paymentComplete.total >
            this.calculatorTotal(
              this.state.paymentComplete,
              this.state.promo
            ) &&
          this.state.promo
        ) {
          paymentObject.promoCode =
            this.state.promo.promo || this.state.promo.promotionCode;
          paymentObject.promoAmount = parseFloat(
            this.calculatePromoAmount(
              this.state.paymentComplete,
              this.state.promo
            ) || 0
          );
        }
      }

      // send more distance tour if status is not droppedOff
      if (this.state.data.status !== 'droppedOff') {
        paymentObject.distanceTour = _.get(
          this.state.disDur,
          'distance.value',
          0
        );
      }

      paymentObject.fleetId = this.props.auth.selectedFleet.fleetId

      /* SL-9475 recheck valid promotion code */
      if (
        paymentObject.promoCode &&
        paymentObject.promoCode !== this.state.data.originPromo
      ) {
        return this.checkValidPromoCode().then((res) => {
          this.props.loadingActions.showLoadingSpiner();
          if (!res.returnCode) {
            this.context.notification(
              'error',
              I18n.t('bookingdetail.Check_promo_error')
            );
          }
          if (res.returnCode !== 200) {
            this.context.notification(
              'error',
              I18n.t(`messages.promoMsg.${res.returnCode}`)
            );
          } else {
            this.props.bookingDetailActions.completeBooking(paymentObject)
            .then(res => {
              this.handleResultCompleteBooking(res)
            })
            return 
          }
        });
      }

      /**
       * Log promotion code in use [Payment -> Report]
       * https://issues.qup.vn/browse/SL-8851 Fix bug duplicate add promotionCode
       */
      this.props.loadingActions.showLoadingSpiner();
      this.props.bookingDetailActions.completeBooking(paymentObject)
      .then(res => {
        this.handleResultCompleteBooking(res)
      })
    }
  }

  checkValidPromoCode = (isCheckPromoWithRecurring = false) => {
    const { data, customer, locationPickUp } = this.state;
    const {
      auth: { selectedFleet },
      newbookingActions,
      bookingDetail,
    } = this.props;
    const jobType = _.get(bookingDetail, 'data.jobType', '');
    const localNow = moment().tz(data.request.pickup.timezone);
    const timeWithoutZone = moment(data.request.pickUpTime).format(
      'YYYY-MM-DD HH:mm'
    );
    const localPickup = moment
      .tz(timeWithoutZone, data.request.pickup.timezone)
      .format();
    const pickupTime =
      data.request.pickUpTime == 'Now' ? localNow : localPickup;
    const paxAppVersion = _.get(data, 'psgInfo.rv', '');
    const promotionObject = {
      fleetId: selectedFleet.fleetId,
      pickupTime: moment.utc(pickupTime).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
      promotionCode: data.request.promo,
      userId: customer.userId,
      bookFrom: data.bookFrom,
      currencyISO: data.currencyISO,
      geo: [locationPickUp.lng, locationPickUp.lat],
      serviceType: jobType === 'shuttle' ? 5 : 0, // 0: transport, 1: intercity, 2: parcel, 3: food, 4: mart
      etaFare:
        data.request.estimate.fare && data.request.estimate.fare.etaFare
          ? data.request.estimate.fare.etaFare
          : 0,
      rv: paxAppVersion,
      paymentType: data.request.paymentType,
    };
    if(isCheckPromoWithRecurring) {
      promotionObject.recurring = {
        mode: 'recurring',
        pickupTime: this.state.recurringTime,
        selectedDates: this.state.recurringDays.map(dateOb => moment(dateOb).format('YYYY-MM-DD'))
      }
    }
    return newbookingActions.checkpromoCode(promotionObject);
  };

  completeWithPaymentCloseButtonClick(e) {
    if (this.state.isCompleteWithPaymentShow3rdBooking) {
      this.setState({
        showConfirmComplete: false,
        paymentStep: 0,
        isSubmited: false,
      });
      this.props.bookingDetailActions.bookingDetailClosed();
    } else {
      this.setState({
        showConfirmComplete: false,
        paymentStep: 0,
        isSubmited: false,
      });
      this.props.bookingDetailActions
        .getBookingDetail(
          this.state.data.bookId,
          !this.isActiveBooking(this.state.data)
        )
        .then((data) => {
          if (data.ok && data.res) {
            this.componentGetDataFromProp(data.res);
          }
        });
    }
  }

  handleResultCompleteBooking = (data) => {
    this.props.loadingActions.hideLoadingSpiner();
    if (data.returnCode == 200) {
      this.props.bookingDetailActions.bookingDetailClosed();
    } else {
      var msg = '';
      if (
        data.response &&
        data.response.response &&
        data.response.response.message
      ) {
        msg += ' (' + data.response.response.message + ' )';
      }

      const errorCode = _.get(data, 'response.returnCode');
      if (errorCode && errorCode === 521) {
        msg = '(' + I18n.t(`errors.completeBooking.${errorCode}`) + ')';
      }

      if (errorCode && errorCode === 113) {
        msg = '(' + I18n.t(`errors.completeBooking.${errorCode}`) + ')';
        // return this.verifyStripeSCA(data.response.response.clientSecret);
      }

      this.context.notification(
        'error',
        I18n.t('messages.booking.complete_fail'),
        msg
      );
    }
  }

  handleResultRetryCompleteBooking = (data) => {
    this.props.loadingActions.hideLoadingSpiner();
    if (data.returnCode == 200) {
      this.props.bookingDetailActions.bookingDetailClosed();
    } else {
      var msg = '';
      if (
        data.response &&
        data.response.response &&
        data.response.response.message
      ) {
        msg += ' (' + data.response.response.message + ' )';
      }
      this.context.notification(
        'error',
        I18n.t('messages.booking.complete_fail'),
        msg
      );
    }
  
  }

  handleCompleteBookingAffiliate = () => {
    const estimateFare = this.state.data.request.estimate.fare.estimateFareBuy;
    const { locationDestination, data } = this.state;
    const paymentObject = {
      address: locationDestination.address,
      geo: [locationDestination.lng, locationDestination.lat],
      zipCode: locationDestination.zipCode,
      bookId: data.bookId,
      total: estimateFare.etaFare,
      fleetId: this.props.auth.selectedFleet.fleetId,
      fare: parseFloat(estimateFare.basicFare || 0),
      tip: parseFloat(estimateFare.tip || 0),
      tax: parseFloat(estimateFare.tax || 0),
      promoCode: '',
      promoAmount: 0,
      techFee: parseFloat(estimateFare.techFee || 0),
      subTotal: 0,
      partnerCommission: this.isBookingFromPaxApp(this.state.data)
        ? parseFloat(this.state.commissionValue || 0)
        : 0,
      paymentType: CCLiteCommonFunc.paymentMappingActiveToCompleted(
        this.state.paymentComplete.paymentType
      ),
      isMinimum: parseFloat(this.state.paymentComplete.isMinFare ? 1 : 0),
      isPending: false,
    };
    paymentObject.subTotal = paymentObject.fare;

    /**
     * Log promotion code in use [Payment -> Report]
     * https://issues.qup.vn/browse/SL-8851 Fix bug duplicate add promotionCode
     */
    this.props.bookingDetailActions.completeBooking(paymentObject)
    .then(res => {
      this.handleResultCompleteBooking(res)
    })
    this.props.loadingActions.showLoadingSpiner();
  };

  incidentButtonClick(e) {
    this.setState({
      showConfirmIncident: !this.state.showConfirmIncident,
      incidentReason: '',
    });
  }

  incidentOkButtonClick() {
    const dataRequest = {
      bookId: this.state.data.bookId,
      reason: this.state.incidentReason,
      fleetId: this.props.auth.selectedFleet.fleetId,
      operator: {
        userId: this.props.auth.user._id,
        name: `${this.props.auth.user.firstName || ''} ${
          this.props.auth.user.lastName || ''
        }`,
      },
    };
    this.props.loadingActions.showLoadingSpiner();
    this.props.bookingDetailActions.incidentBooking(dataRequest)
    .then(res => {
      this.props.loadingActions.hideLoadingSpiner();
      if (res.returnCode == 200) {
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        this.context.notification('error', I18n.t('messages.booking.incident_fail'));
      }
    })
  }

  cancelBooking() {}

  reOrderPuPoint = (listOrder) => {
    let pointOrdered = [];
    // set order methoad of Point
    if (listOrder.length > 0) {
      pointOrdered = listOrder.map((point, id) => {
        point.order = id + 1;
        return point;
      });
    }
    return pointOrdered;
  };

  reOrderDoPoint = (puPoints, doPoints) => {
    let pointOrdered = [];
    if (doPoints.length > 0) {
      pointOrdered = doPoints.map((point, id) => {
        point.order = puPoints.length + id + 1;
        return point;
      });
    }
    return pointOrdered;
  };

  reOrderPointToSubmit = (points = []) => {
    if (points && points.length === 0) return [];
    return points.map((ob, index) => {
      return {
        ...ob,
        order: index + 1,
      };
    });
  };

  setNewDoPuPointAddTraveler = (puPoints, doPoints) => {
    this.setState({
      data: {
        ...this.state.data,
        puPoints: puPoints,
        doPoints: doPoints,
      },
    });
  };

  setNewPuPoint = (newPoints = []) => {
    let hasEmptyPoint = newPoints.some((ob) =>
      _.isEmpty(_.get(ob, 'address.address', ''))
    );
    let { doPoints } = this.state.data;
    if (
      newPoints.length > 0 &&
      !(newPoints.length === 1 && hasEmptyPoint) &&
      newPoints.some((ob) => !_.isEmpty(_.get(ob, 'address.address', '')))
    ) {
      let firstPuPoint = newPoints[0];
      if (hasEmptyPoint) {
        let newPointsNoEmpty = filterPointEmpty(newPoints);
        firstPuPoint = newPointsNoEmpty[0];
      }
      this.setState(
        {
          mapCenter: firstPuPoint.address,
          locationPickUp: firstPuPoint.address,
          data: {
            ...this.state.data,
            email: firstPuPoint.sender.email,
            phone: firstPuPoint.sender.phone,
            firstname: firstPuPoint.sender.firstname,
            lastname: firstPuPoint.sender.lastname,
            isCustomerVip: firstPuPoint.sender.isCustomerVip,
            outStanding: firstPuPoint.sender.outStanding,
            customer: firstPuPoint.sender.customer,
            credits: firstPuPoint.sender.credits,
            travelerType: firstPuPoint.sender.traveler_type,
            traveler_type: firstPuPoint.sender.traveler_type,
            puPoints: this.reOrderPuPoint(newPoints),
            doPoints: this.reOrderDoPoint(newPoints, doPoints),
          },
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            let { newBooking } = this.props;
            let { cartypeSelected } = this.state;
            let vehicleType = _.get(
              newBooking,
              'locationService.vehicleType',
              []
            );
            if (vehicleType.length > 0) {
              if (cartypeSelected) {
                cartypeSelected = newBooking.locationService.vehicleType.filter(
                  (vh) => vh.vehicleType == cartypeSelected.vehicleType
                )[0];
              }
              if (!cartypeSelected) {
                cartypeSelected = newBooking.locationService.vehicleType[0];
              }

              this.setState(
                {
                  data: {
                    ...this.state.data,
                    carType:
                      cartypeSelected && cartypeSelected.vehicleType
                        ? cartypeSelected.vehicleType
                        : null,
                  },
                },
                () => {
                  if (
                    newPoints.length > 1 ||
                    (doPoints.length > 0 && newPoints.length > 0)
                  ) {
                    this.getDirectionAndDraw();
                  } else {
                    this.etaFareCalculator();
                  }
                  const newData = this.state.data;
                  if (data.res.crossZone) {
                    this.state.data.paymentMethod =
                      paymentMethodNumber.personalCard;
                    this.getCrossZoneBaseLocation().then((data) => {
                      newData.request.tip = this.getDefaultTip();
                      this.setState({
                        data: newData,
                      });
                    });
                  } else {
                    newData.request.tip = this.getDefaultTip();
                    this.setState({
                      data: newData,
                    });
                  }
                }
              );
            }
          });
        }
      );
    } else {
      this.setState(
        {
          locationPickUp: null,
          addressPickUp: '',
          loadingPickUp: false,
          isCheckSupportLocation: false,
          data: {
            ...this.state.data,
            puPoints: this.reOrderPuPoint(newPoints),
            doPoints: this.reOrderDoPoint(newPoints, doPoints),
          },
        },
        () => {
          if (
            newPoints.length > 1 ||
            (doPoints.length > 0 && newPoints.length > 0)
          ) {
            this.getDirectionAndDraw();
          }
        }
      );
    }
  };

  handleSelectRecentPickup(location) {
    if (location) {
      this.setState(
        {
          locationPickUp: {
            lng: location.coordinates[0],
            lat: location.coordinates[1],
            address: location.address,
            from: locationType.google,
            city: location.city,
            zipCode: location.zipCode,
            countryCode: location.country,
            instructionLink: location.instructionLink || '',
            addressDetails: location.addressDetails,
          },
          isFormDirty: true,
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (
              this.props.newBooking.locationService &&
              this.props.newBooking.locationService.vehicleType &&
              this.props.newBooking.locationService.vehicleType != []
            ) {
              if (this.state.cartypeSelected) {
                this.state.cartypeSelected =
                  this.props.newBooking.locationService.vehicleType.filter(
                    (vh) =>
                      vh.vehicleType == this.state.cartypeSelected.vehicleType
                  )[0];
              }
              if (!this.state.cartypeSelected) {
                this.state.cartypeSelected =
                  this.props.newBooking.locationService.vehicleType[0];
              }

              this.setState({
                data: {
                  ...this.state.data,
                  carType: this.state.cartypeSelected.vehicleType,
                },
              });
              this.carTypeBaseLocation().then((data) => {
                this.etaFareCalculator();
                if (data.res.crossZone) {
                  this.state.data.paymentMethod =
                    paymentMethodNumber.personalCard;
                  this.getCrossZoneBaseLocation();
                }
              });
            }
          });
          this.getDirectionAndDraw();
        }
      );
    } else {
    }
  }

  handleSelectThirdPartyPickUp(address, data) {
    const cityCountry = data.location.city;
    let cityName = '';
    if (cityCountry) {
      const citySplit = cityCountry.split('_');
      if (citySplit && citySplit.length >= 3) {
        if (citySplit[1]) {
          cityName = citySplit[1];
        } else if (citySplit[2]) {
          cityName = citySplit[2];
        }
      }
    }
    this.setState(
      {
        data: this.state.data,
        mapCenter:
          this.props.commonData.location &&
          this.props.commonData.location.isChina
            ? new window.qq.maps.LatLng(
                data.location.latitude,
                data.location.longitude
              )
            : { lat: data.location.latitude, lng: data.location.longitude },
        locationPickUp: {
          lng: data.location.coordinates[0],
          lat: data.location.coordinates[1],
          address: address,
          from: locationType.thirdParty,
          city: data.location.city,
          zipCode: data.location.zipCode,
          countryCode: data.location.countryCode,
          cityName,
          instructionLink: data.location.instructionLink,
        },
        isFormDirty: true,
      },
      () => {
        this.carTypeBaseLocation().then((data) => {
          if (
            this.props.newBooking &&
            this.props.newBooking.locationService &&
            this.props.newBooking.locationService.vehicleType &&
            this.props.newBooking.locationService.vehicleType != []
          ) {
            if (this.state.cartypeSelected) {
              this.state.cartypeSelected =
                this.props.newBooking.locationService.vehicleType.filter(
                  (vh) =>
                    vh.vehicleType == this.state.cartypeSelected.vehicleType
                )[0];
            }
            if (!this.state.cartypeSelected) {
              this.state.cartypeSelected =
                this.props.newBooking.locationService.vehicleType[0];
            }

            this.setState({
              data: {
                ...this.state.data,
                carType: this.state.cartypeSelected.vehicleType,
              },
            });
            this.carTypeBaseLocation().then((data) => {
              this.etaFareCalculator();
              if (data.res.crossZone) {
                this.state.data.paymentMethod =
                  paymentMethodNumber.personalCard;
                this.getCrossZoneBaseLocation();
              }
            });
          }
        });
        this.getDirectionAndDraw();
      }
    );
  }

  handleChangePickUp(addressPickUp) {
    this.setState({
      locationPickUp: {
        ...this.state.locationPickUp,
        address: addressPickUp,
        from: locationType.google,
      },
      isFormDirty: true,
    });
    if (addressPickUp && addressPickUp.length > 0) {
    } else {
      this.handleAddressRemovedPickUp();
    }
  }

  handleChangeThirdPartyPickUp(addressPickUp) {
    if (addressPickUp && addressPickUp.length > 0) {
      this.setState({
        locationPickUp: {
          address: addressPickUp,
          from: locationType.thirdParty,
        },
        isFormDirty: true,
      });
    } else {
      this.handleAddressRemovedPickUp();
    }
  }

  handleAddressRemovedPickUp() {
    this.state.package = null;
    this.state.data.packageRateId = null;
    this.setState(
      {
        data: this.state.data,
        locationPickUp: null,
        isCheckSupportLocation: false,
        isFormDirty: true,
      },
      () => {
        this.etaFareCalculator();
        this.getDirectionAndDraw();
      }
    );
  }

  handleSelectRecentDestination(location) {
    if (location) {
      this.setState(
        {
          locationDestination: {
            lat: location.coordinates[1],
            lng: location.coordinates[0],
            zipCode: location.zipCode,
            city: location.city,
            from: locationType.google,
            address: location.address,
            countryCode: location.country,
            instructionLink: location.instructionLink || '',
            addressDetails: location.addressDetails,
          },
          isFormDirty: true,
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (data.ok) {
              if (data.res.crossZone) {
                this.getCrossZoneBaseLocation();
              }
            }
            this.etaFareCalculator();
          });
          this.getDirectionAndDraw();
        }
      );
    } else {
    }
  }

  handleSelectDestination = (
    addressDestination,
    placeId,
    pointOfInterest,
    sessionToken
  ) => {
    this.state.locationDestination.address = addressDestination;
    const callback = (err, localtion, results = {}) => {
      if (err || !localtion) {
        return;
      }
      let doPoints = (this.state.data && this.state.data.doPoints) || [];
      let addressObj = localtion || {};
      addressObj.geo = [addressObj.lng, addressObj.lat];
      addressObj.from = 'gg';
      addressObj.address = results && results.formatted_address;
      if (doPoints.length == 0) {
        this.setNewDoPoint([
          {
            address: addressObj,
            addressPointText: results && results.formatted_address,
            from: 'gg',
            id: new Date().getTime(),
            typePoint: 'Drop off point',
          },
        ]);
      } else {
        doPoints[doPoints.length - 1].address = addressObj;
        doPoints[doPoints.length - 1].addressPointText =
          results && results.formatted_address;
        doPoints[doPoints.length - 1].from = 'gg';
        this.setNewDoPoint(doPoints);
      }
    };
    geoPlaceDetailMapProvider({
      auth: this.props.auth,
      placeid: placeId,
      sessionToken,
      callback,
      language: this.props.language,
      bookId: this.state?.data?.bookId
    });
  };

  setNewDoPoint = (newPoints = []) => {
    let hasEmptyPoint = newPoints.some((ob) =>
      _.isEmpty(_.get(ob, 'address.address', ''))
    );

    let { puPoints } = this.state.data;
    if (
      newPoints.length > 0 &&
      !hasEmptyPoint &&
      newPoints.some((ob) => !_.isEmpty(_.get(ob, 'address.address', '')))
    ) {
      let lastPoPoint = newPoints[newPoints.length - 1];
      if (hasEmptyPoint) {
        let newPointsNoEmpty = filterPointEmpty(newPoints);
        lastPoPoint = newPointsNoEmpty[newPointsNoEmpty.length - 1];
      }
      let newDoPoints = this.reOrderDoPoint(puPoints, newPoints);
      this.setState(
        {
          mapCenter: lastPoPoint.address,
          locationDestination: lastPoPoint.address,
          disableCompletePaymentNextClick: true,
          isFormDirty: true,
          data: {
            ...this.state.data,
            request: {
              ...this.state.data.request,
              paymentType:
                newDoPoints.length > 1 &&
                this.state.data.request.paymentType == 17
                  ? 0
                  : this.state.data.request.paymentType,
            },
            doPoints: newDoPoints,
          },
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (data.ok) {
              if (data.res.crossZone) {
                this.getCrossZoneBaseLocation();
              }
            }
            this.getDirectionAndDraw();
          });
        }
      );
    } else {
      let newDoPoints = this.reOrderDoPoint(puPoints, newPoints);
      this.setState(
        {
          locationDestination: null,
          disableCompletePaymentNextClick: true,
          isFormDirty: true,
          data: {
            ...this.state.data,
            request: {
              ...this.state.data.request,
              paymentType:
                newDoPoints.length > 1 &&
                this.state.data.request.paymentType == 17
                  ? 0
                  : this.state.data.request.paymentType,
            },
            doPoints: newDoPoints,
          },
        },
        () => {
          if (
            newPoints.length > 1 ||
            (puPoints.length > 0 && newPoints.length > 0)
          ) {
            this.getDirectionAndDraw();
          }
        }
      );
    }
  };

  handleSelectThirdPartyDestination(addressDestination, data) {
    this.setState(
      {
        data: this.state.data,
        mapCenter:
          this.props.commonData.location &&
          this.props.commonData.location.isChina
            ? new window.qq.maps.LatLng(
                data.location.coordinates[0],
                data.location.coordinates[1]
              )
            : {
                lng: data.location.coordinates[0],
                lat: data.location.coordinates[1],
              },
        locationDestination: {
          lat: data.location.coordinates[1],
          lng: data.location.coordinates[0],
          zipCode: data.location.zipCode,
          city: data.location.city,
          address: addressDestination,
          from: locationType.thirdParty,
          countryCode: data.location.countryCode,
          instructionLink: data.location.instructionLink,
        },
        isFormDirty: true,
      },
      () => {
        this.carTypeBaseLocation().then((data) => {
          if (data && data.ok && data.res) {
            if (data.res.crossZone) {
              this.getCrossZoneBaseLocation();
            }
          }
          this.getDirectionAndDraw();
        });
      }
    );
  }

  handleChangeDestination(addressDestination) {
    if (!addressDestination || addressDestination.length == 0) {
      this.handleAddressRemovedDestination();
    } else {
      if (!this.state.locationDestination) {
        this.state.locationDestination = {};
      }
      this.state.locationDestination.address = addressDestination;
      this.state.locationDestination.from = locationType.google;
      this.setState({ data: this.state.data, isFormDirty: true });
    }
  }

  handleChangeThirdPartyDestination(addressDestination) {
    if (!addressDestination || addressDestination.length == 0) {
      this.handleAddressRemovedDestination();
    } else {
      if (!this.state.locationDestination) {
        this.state.locationDestination = {};
      }
      this.state.locationDestination.address = addressDestination;
      this.state.locationDestination.from = locationType.thirdParty;
      this.setState({ data: this.state.data, isFormDirty: true });
    }
  }

  handleAddressRemovedDestination() {
    this.state.locationDestination = null;
    this.state.paymentComplete = {
      tax: 0,
      subTotal: 0,
      total: 0,
      isMinFare: false,
      paymentType: 0,
    };
    this.state.disDur = null;
    this.state.directions = null;
    this.setState({ data: this.state.data, isFormDirty: true }, () => {
      this.carTypeBaseLocation().then((data) => {
        if (data && data.ok && data.res) {
          if (data.res.crossZone) {
            this.getCrossZoneBaseLocation();
          }
        }
      });
      this.etaFareCalculator();
    });
  }

  handlePickupDrapEnd(results, status, id) {
    getLocationWhenDrapEnd({
      results, 
      id, 
      callback: this.pickupDrapEndCallback, 
      auth: this.props.auth, 
      language: this.props?.language,
      bookId: this.state.data.bookId,
      isTencent: this.props?.commonData?.location?.isChina
    })
  }

  pickupDrapEndCallback(place, localtion, id) {
    if (id) {
      this.dragEditPoint(
        place,
        localtion,
        id,
        this.state.data.puPoints,
        this.setNewPuPoint
      );
    } else {
      this.state.locationPickUp = {
        address: place.formatted_address,
        from: locationType.google,
        addressDetails: localtion.addressDetails,
      };
      this.setState(
        {
          locationPickUp: Object.assign(this.state.locationPickUp, localtion),
          mapCenter: localtion,
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (
              this.props.newBooking.locationService &&
              this.props.newBooking.locationService.vehicleType &&
              this.props.newBooking.locationService.vehicleType != []
            ) {
              if (this.state.cartypeSelected) {
                this.state.cartypeSelected =
                  this.props.newBooking.locationService.vehicleType.filter(
                    (vh) =>
                      vh.vehicleType == this.state.cartypeSelected.vehicleType
                  )[0];
              }
              if (!this.state.cartypeSelected) {
                this.state.cartypeSelected =
                  this.props.newBooking.locationService.vehicleType[0];
              }

              this.setState({
                data: {
                  ...this.state.data,
                  carType: this.state.cartypeSelected.vehicleType,
                },
              });
              this.carTypeBaseLocation().then((data) => {
                this.etaFareCalculator();
                const newData = this.state.data;
                if (data.res.crossZone) {
                  this.state.data.paymentMethod =
                    paymentMethodNumber.personalCard;
                  this.getCrossZoneBaseLocation().then((data) => {
                    newData.request.tip = this.getDefaultTip();
                    this.setState({
                      data: newData,
                    });
                  });
                } else {
                  newData.request.tip = this.getDefaultTip();
                  this.setState({
                    data: newData,
                  });
                }
              });
            }
          });
          this.getDirectionAndDraw();
        }
      );
    }
  }

  dragEditPoint = (place = {}, location = {}, id, pointsCurrent, callback) => {
    let updatedPoint = [...pointsCurrent];
    let pointIndex = updatedPoint.findIndex((obj) => obj.id == id);
    location.geo = [location.lng, location.lat];
    location.address = place.formatted_address || '';
    updatedPoint[pointIndex].address = location;
    updatedPoint[pointIndex].addressPointText = place.formatted_address || '';
    callback(updatedPoint);
  };

  handleDestinationDrapEnd(results, status, id) {
    getLocationWhenDrapEnd({
      results, 
      id, 
      callback: this.destinationDrapEndCallback, 
      auth: this.props.auth,
      language: this.props?.language,
      bookId: this.state.data.bookId,
      isTencent: this.props.commonData.location.isChina
    })
  }

  destinationDrapEndCallback(place, localtion, id) {
    if (id) {
      this.dragEditPoint(
        place,
        localtion,
        id,
        this.state.data.doPoints,
        this.setNewDoPoint
      );
    } else {
      this.state.locationDestination = Object.assign(
        {
          address: place.formatted_address,
          from: locationType.google,
          addressDetails: localtion.addressDetails,
        },
        localtion
      );
      this.carTypeBaseLocation().then((data) => {
        if (data.ok) {
          if (data.res.crossZone) {
            this.getCrossZoneBaseLocation();
          }
        }
      });
      this.getDirectionAndDraw();
    }
  }

  handleChangeDispatchType(e) {
    this.state.data.dispatchType = parseInt(e.target.value);
    if (this.state.data.dispatchType == 0 && this.state.data.drvInfo) {
      this.state.data.drvInfo.fullName = '';
    }
    this.setState({ data: this.state.data, isFormDirty: true, isDetachDrv: false });
  }

  handleChangePromo(e) {
    this.state.data.request.promo = (e.target.value || '').toUpperCase();
    this.setState({ data: this.state.data, isFormDirty: true });
  }

  handleDriverChanged(e) {
    if (this.state.data.drvInfo) {
      this.state.data.drvInfo = {};
    }
    this.state.data.drvInfo.fullName = e.target.value;
    if (
      !this.state.data.drvInfo.fullName ||
      this.state.data.drvInfo.fullName.length == 0
    ) {
      this.state.driver = null;
    }
    this.setState({ data: this.state.data, isFormDirty: true, driver: null });
  }

  handleChangeBookingReference = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        externalInfo: {
          ...this.state.data.externalInfo,
          bookingReference: e.target.value,
        },
      },
    });
  };

  handlePaymentMethodChange = (e) => {
    const { data } = this.state;
    const paymentType = parseInt(e.target.value);
    let { corporateInfo } = data;
    if (paymentType === 5 && !corporateInfo) corporateInfo = {};

    this.setState(
      {
        valid: {},
        credit: null,
        data: {
          ...data,
          request: {
            ...data.request,
            paymentType,
          },
        },
        isFormDirty: true,
      },
      () => {
        this.etaFareCalculator();
      }
    );
  };

  handleCreditCardChange(e) {
    if (e.target.value == 0) {
      this.setState({ credit: null, isFormDirty: true });
    } else if (
      this.state.data.request.paymentType == paymentMethodNumber.corporateCard
    ) {
      this.state.company.credits.map((cre) => {
        if (cre.localToken == e.target.value) {
          this.setState({
            credit: cre,
            data: { ...this.state.data },
            isFormDirty: true,
          });
        }
      });
    } else if (
      this.state.customer.credits &&
      this.state.customer.credits.length > 0
    ) {
      this.state.customer.credits.map((cre) => {
        if (cre.localToken == e.target.value) {
          this.setState({
            credit: cre,
            data: { ...this.state.data },
            isFormDirty: true,
          });
        }
      });
    } else if (
      this.state.data.psgInfo &&
      this.state.data.psgInfo.creditInfo &&
      (e.target.value == this.state.data.psgInfo.creditInfo.crossToken ||
        e.target.value == this.state.data.psgInfo.creditInfo.localToken)
    ) {
      this.setState({
        credit: this.state.data.psgInfo.creditInfo,
        data: { ...this.state.data },
        isFormDirty: true,
      });
    }
  }

  handleAddCardSuccess(cardAdded) {
    if (
      this.state.data.request.paymentType === paymentMethodNumber.personalCard
    ) {
      const { customer } = this.state;
      customer.credits.push(cardAdded);
      this.setState({ customer, credit: cardAdded });
    }
    if (
      this.state.data.request.paymentType === paymentMethodNumber.corporateCard
    ) {
      const { company } = this.state;
      company.credits.push(cardAdded);
      this.setState({ company, credit: cardAdded });
    }
  }

  handleCaculateEditFarePromo = (promoInfo) => {
    if(this.state.data?.request?.estimate?.isFareEdited) {
      this.state.data.request.estimate.fare = calculateFareWithPromoChange({
        auth: this.props.auth,
        promoInfo: promoInfo,
        etaFare: this.state?.data.request?.estimate?.fare,
        data: this.state.data,
        tipPercent: this.state.data.request.tip
      })
      this.setState({
        data: this.state.data
      })
    } else {
      this.etaFareCalculator()
    }
  }

  handlePromoApplyClick(e) {
    const { request = {} } = this.state.data;
    if (!!this.state.data.promoApplied || !!this.state.promo) {
      request.promo = '';
      this.state.data.promoApplied = false;
      this.state.data.request.promoInfo = null;
      this.state.promo = null;
      if (this.state.showConfirmComplete) {
        this.setState({
          data: this.state.data,
          promo: null,
          isFormDirty: true,
        });
      } else {
        this.setState(
          { data: this.state.data, promo: null, isFormDirty: true },
          () => {
            this.handleCaculateEditFarePromo(null)
          }
        );
      }
    } else if (request.promo === this.state.data.originPromo) {
      if (!request.promo) {
        return;
      }
      this.state.data.promoApplied = true;

      const promoUpdate = this.state.data.originPromoInfo;
      if (this.state.showConfirmComplete) {
        this.setState(
          { promo: promoUpdate, data: this.state.data },
          this.paymentCompleteTotalCalculator
        );
      } else {
        this.setState(
          { promo: promoUpdate, data: this.state.data },
          () => {
            this.handleCaculateEditFarePromo(promoUpdate)
          }
        );
      }
    } else {
      if (!request.promo) {
        return;
      }
      const localNow = moment().tz(request.pickup.timezone);
      const jobType = _.get(this.props.bookingDetail, 'data.jobType', '');
      const timeWithoutZone = moment(request.pickUpTime).format(
        'YYYY-MM-DD HH:mm'
      );
      const localPickup = moment
        .tz(timeWithoutZone, request.pickup.timezone)
        .format();
      const etaFare =
        request.estimate.fare && request.estimate.fare.etaFare
          ? request.estimate.fare.etaFare
          : 0;
      const paxAppVersion = _.get(this.state.data, 'psgInfo.rv', '');
      const promotionObject = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        pickupTime:
          request.pickUpTime === 'Now'
            ? moment.utc(localNow).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
            : moment.utc(localPickup).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        promotionCode: request.promo,
        userId: this.state.customer.userId,
        bookFrom: this.state.data.bookFrom,
        currencyISO: this.state.data.currencyISO,
        geo: [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
        serviceType: jobType === 'shuttle' ? 5 : 0, // 0: transport, 1: intercity, 2: parcel, 3: food, 4: mart
        etaFare: etaFare,
        paymentType: request.paymentType,
        rv: paxAppVersion,
      };
      if (request.paymentType === 21) {
        promotionObject.gateway = request.gateway || '';
      }
      this.props.newbookingActions
        .checkpromoCode(promotionObject)
        .then((data) => {
          if (data.returnCode === 200) {
            const newPromo = data.response;
            newPromo.promotionCode = request.promo;
            if (this.state.showConfirmComplete) {
              this.setState(
                { promo: newPromo, promoApplied: true },
                this.paymentCompleteTotalCalculator
              );
            } else {
              this.setState(
                { promo: newPromo, promoApplied: true },
                () => {
                  this.handleCaculateEditFarePromo(newPromo)
                }
              );
            }
          } else if (data.returnCode) {
            this.context.notification(
              'error',
              I18n.t(`messages.promoMsg.${data.returnCode}`)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('bookingdetail.Check_promo_error')
            );
          }
        });
    }
  }

  handleCarTypeChanged(vehicleType) {
    if (this.props.newBooking.locationService)
      this.props.newBooking.locationService.vehicleType.map((car) => {
        if ((car.vehicleType || car.ty) == vehicleType) {
          this.state.cartypeSelected = car;
          this.state.driver = null;
          this.state.data.drvInfo.fullName = '';
        }
      });
    this.state.driverCompany._id =
      this.state.data.request && this.state.data.request.companyId
        ? this.state.data.request.companyId
        : '';
    this.state.data.request.vehicleTypeRequest = vehicleType;
    this.setState(
      {
        data: this.state.data,
        cartypeSelected: this.state.cartypeSelected,
        isFormDirty: true,
      },
      () => {
        this.getDirectionAndDraw();
        this.carTypeBaseLocation().then((data) => {
          if (data.ok) {
            this.etaFareCalculator();
            if (data.res.crossZone) {
              this.getCrossZoneBaseLocation();
            }
          }
        });
      }
    );
  }

  handleTimeChanged(e) {
    if (e === 'Now') {
      if (this.state.data.request.pickUpTime === e) return;
      this.state.data.request.pickUpTime = 'Now';
      this.state.data.request.dispatchType = 0;
      this.state.data.drvInfo.fullName = '';
      this.setState(
        { data: this.state.data, isFormDirty: true },
        this.etaFareCalculator
      );
    } else {
      const { data } = this.state;
      const prevTime = moment(data.request.pickUpTime);
      if (
        data.request.pickUpTime &&
        data.request.pickUpTime !== 'Now' &&
        prevTime.isSame(e, 'day')
      )
        return false;
      this.state.data.request.pickUpTime = e;
      if (this.state.isShowDriverCalendar && this.state.driverCalendarViewing) {
        this.handleDriverCalendarClick(this.state.driverCalendarViewing);
      }
      this.setState(
        { data: this.state.data, isFormDirty: true },
        this.etaFareCalculator
      );
    }
  }

  handleChangeNote(e) {
    this.state.data.request.note = e.target.value;
    const newData = Object.assign({}, this.state.data);
    this.setState({ data: newData, isFormDirty: true });
  }

  handleChangeNoteSuperHelper = (e) => {
    let instructions = this.state.data.request.instructions;
    instructions.content = e.target.value;
    this.setState({
      data: {
        ...this.state.data,
        instructions: instructions,
        isFormDirty: true,
      },
    });
  };

  handleCompleteBasicFareChange(e) {
    this.state.data.request.estimate.fare =
      this.state.data.request.estimate.fare || {};
    this.state.data.request.estimate.fare.basicFare = e.target.value;
    this.setState(
      { data: this.state.data, isFormDirty: true },
      this.paymentCompleteSubtotalCalculator
    );
  }

  handleTaxChange(e) {
    this.state.paymentComplete.tax = e.target.value;
    this.setState(
      { paymentComplete: this.state.paymentComplete, isFormDirty: true },
      this.paymentCompleteTotalCalculator
    );
  }

  handleCompletePaymentTypeChange(e) {
    this.state.paymentComplete.paymentType = parseFloat(e.target.value);
    this.setState({ paymentComplete: this.state.paymentComplete });
  }

  handleCompletePaymentIsPendingChange(e) {
    this.state.paymentComplete.isPending = e.target.checked;
    this.setState({
      paymentComplete: this.state.paymentComplete,
      isFormDirty: true,
    });
  }

  handleClientCaseMatterChange(e) {
    if (!this.state.data.corporateInfo) {
      this.state.data.corporateInfo = {};
    }
    this.state.data.corporateInfo.clientCaseMatter = e.target.value;
    this.setState({ data: this.state.data, isFormDirty: true });
  }
  handleChangeOperatorNote(e) {
    this.state.data.request.operatorNote = e.target.value;
    this.setState({ data: this.state.data, isFormDirty: true });
  }
  handleChargeCodeChange(e) {
    if (!this.state.data.corporateInfo) {
      this.state.data.corporateInfo = {};
    }
    this.state.data.corporateInfo.chargeCode = e.target.value;
    this.setState({ data: this.state.data, isFormDirty: true });
  }

  handleDispatch3rdOnchange(e) {
    this.state.data.dispatch3rd = e.target.checked;
    this.setState({ data: this.state.data, isFormDirty: true });
  }

  driverAutocompleteSlectedHandle(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId);
    }
    this.state.data.drvInfo = Object.assign(
      this.state.data.drvInfo || {},
      suggestion
    );
    this.state.data.drvInfo.fullName = `${suggestion.firstName || ''} ${
      suggestion.lastName || ''
    }`;
    this.setState({
      driver: Object.assign(this.state.driver || {}, suggestion),
      isDetachDrv: false,
    }, this.etaFareCalculator);
  }

  handleIncidentReasonChanged(e) {
    this.setState({ incidentReason: e.target.value, isFormDirty: true });
  }

  handleChangeETAFare(fareEdited, originFare, reasonEditFare, isFareEdited) {
    let shortTrip = this.props?.auth?.selectedFleet?.waiveOffCommission?.enable ? 
      (fareEdited?.etaFare > this.props?.auth?.selectedFleet?.waiveOffCommission?.amountByCurrencies[0]?.value
      ? false : true) : originFare?.shortTrip;

    fareEdited.shortTrip = shortTrip;

    this.state.data.request.estimate = this.state.data.request.estimate || {};
    this.state.data.request.estimate.fare.markupDifference = 0;
    this.state.data.request.estimate.reasonMarkup = null;
    this.state.data.request.estimate.markupValue = 0;
    this.state.data.request.estimate.fare = fareEdited;
    this.state.data.request.estimate.originFare = originFare;
    this.state.data.request.estimate.isFareEdited = isFareEdited;
    this.state.data.request.estimate.reasonEditFare = reasonEditFare;
    this.setState({
      data: this.state.data,
      isFormDirty: true,
      prevEtaFare: {
        originFare,
        isFareEdited,
        reasonEditFare,
      },
    });
  }

  handleChangeMarkupPrice(
    originFare,
    markupDifference,
    reasonMarkup,
    markupType,
    markupValue
  ) {
    this.state.data.request.estimate.fare.markupDifference = markupDifference;
    this.state.data.request.estimate.reasonMarkup = reasonMarkup;
    this.state.data.request.estimate.markupValue = parseFloat(markupValue);
    this.state.data.request.estimate.markupType = markupType;
    this.state.data.request.estimate.isFareEdited = false;
    this.state.data.request.estimate.reasonEditFare = null;
    this.state.data.request.estimate.originFare = originFare;
    this.setState({
      data: this.state.data,
      prevEtaFare: {
        originFare,
        isFareEdited: false,
        reasonEditFare: null,
      },
    });
  }

  getDefaultPaymentMethod() {
    let paymentMethod = null;
    if (this.state.data.traveler_type == 0) {
      this.props.paymentMethod.individualTypes.map((pm) => {
        if (pm.type == 'fleetCard') {
          paymentMethod = pm;
        }
      });
      if (!paymentMethod) {
        paymentMethod = this.props.paymentMethod.individualTypes[0];
      }
    } else {
      this.props.paymentMethod.corporateTypes.map((pm) => {
        if (pm.type == 'fleetCard') {
          paymentMethod = pm;
        }
      });
      paymentMethod = this.props.paymentMethod.corporateTypes[0];
    }
    return paymentMethod;
  }

  getCrossZoneBaseLocation(isInit) {
    const options = {
      fleetId: this.state.data.request.psgFleetId,
      pricingType: 1,
      userId: this.props.auth.user._id,
    };
    if (this.state.cartypeSelected && this.state.data.carType != '') {
      options.vehicleType = this.state.cartypeSelected._id;
    }
    return this.props.newbookingActions.getCrossZoneRate(options);
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getDirectionCallback(response, extraResponse) {
    this.setState(
      {
        directions: response.overviewPolyline,
        disDur: {
          distance: {
            value: response.distanceValue,
            text: response.distance
          },
          duration: {
            value: response.estimateValue,
            text: response.time
          }
        },
      },
      this.etaFareCalculator
    );
  }

  checkDirtyLocation = () => {
    const { puPoints = [], doPoints = [] } =
      (this.state && this.state.data) || {};
    if (
      puPoints.some((ob) => _.isEmpty(_.get(ob, 'address', {}))) ||
      doPoints.some((ob) => _.isEmpty(_.get(ob, 'address', {})))
    ) {
      return true;
    }
    return false;
  };

  getDirectionAndDraw(
    callback = this.getDirectionCallback,
    isCompleteWithPayment = false
  ) {
    const { puPoints = [], doPoints = [] } = this.state.data || {};
    // if(this.checkDirtyLocation()) return
    let puPointsNotEmpty = filterPointEmpty(puPoints);
    let doPointsNotEmpty = filterPointEmpty(doPoints);
    const points = [];
    const travelMode = getTravelerModeByCarType(
      this.state.cartypeSelected,
      this.props.commonData.location.isChina
    );
    if (
      (this.state.locationPickUp && this.state.locationDestination) ||
      puPointsNotEmpty.length > 0 ||
      doPointsNotEmpty.length > 0
    ) {
      // points.push(this.state.locationPickUp);
      // points.push(this.state.locationDestination);
      puPointsNotEmpty.forEach((point) => {
        let geo = _.get(point, 'address.geo', []);
        points.push({
          lat: geo[1],
          lng: geo[0],
        });
      });
      doPointsNotEmpty.forEach((point) => {
        let geo = _.get(point, 'address.geo', []);
        points.push({
          lat: geo[1],
          lng: geo[0],
        });
      });
      if(this.checkLocationHasChange(points)) {
        getDirectionMapProviderFromJupiter({
          points,
          travelMode,
          unit: this.props.auth.selectedFleet.unitDistance == 'km' ? 0 : 1,
          shortestPathEstimation: this.props.auth.selectedFleet.shortestPathEstimation,
          auth: this.props.auth,
          callback,
          isTencent: this.props?.commonData?.location?.isChina,
          vehicleType: this.state?.cartypeSelected?.vehicleType,
          jobType: 'delivery',
          deliveryData: {
            deliveryType: 0,
            cashOnPickup: false,
            cashOnDelivery: checkCashOnDelivery(doPointsNotEmpty),
          }
        });
      }
      
    } else {
      this.setState({ directions: null, disDur: null }, this.etaFareCalculator);
    }
  }

  isEditable = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    if (booking && !booking.farmOut) {
      return bookingStatusMapping.canEdit[booking.status];
    }
    if (booking.status === 'action') {
      return true;
    }
    return false;
  };

  isFinished = (booking) => {
    return (
      !!booking.finishedAt ||
      finishedStatusList.some((i) => i.value === booking.status)
    );
  };

  isAbleUpdateBooking = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) {
      return false;
    }

    if (this.isFinished(booking)) {
      return true;
    } else {
      if (booking && !booking.farmOut) {
        return bookingStatusMapping.canUpdateBooking[booking.status];
      }
      if (booking.status === 'action') {
        return true;
      }
    }
    return false;
  };

  isPickupEditable = (booking) => {
    if (!this.state.data) {
      return false;
    }
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    if (this.state.data && this.state.data.pricingType) {
      return false;
    }
    return (
      this.isEditable(this.state.data) && !this.state.data.dispatchRideSharing
    );
  };

  isExtraSerivicesEditable = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    if (
      booking.status == 'droppedOff' ||
      booking.status == 'completed' ||
      booking.status == 'engaged' ||
      booking.status == 'canceled' ||
      booking.status == 'noShow'
    ) {
      return false;
    }
    return true;
  };

  isDestinationEditable = () => {
    let booking = this.state.data;
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    if (!booking) {
      return false;
    }
    if (
      !this.isEditable(booking) ||
      booking.dispatchRideSharing ||
      (booking.request.localDestination &&
        booking.request.localDestination._address) ||
      (booking.pricingType && !booking.farmOut)
    ) {
      return false;
    }
    return true;
  };

  isBookingEditable = () => {
    let booking = this.state.data;
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    if (!booking) {
      return false;
    }
    if (
      booking.dispatchRideSharing ||
      (booking.pricingType && !booking.farmOut)
    ) {
      return false;
    }
    return true;
  };

  isNewBookingAble = (booking) => {
    const { permissions, auth } = this.props;
    if (booking.pricingType == 1) {
      return false;
    }
    if (permissions && !permissions.actions) return false;
    if (!booking.pricingType) {
      return true;
    }

    return false;
  };

  isDispatchAble = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    if (!booking.pricingType) {
      return bookingStatusMapping.canReDispatch[booking.status];
    }
    if (!booking.farmOut) {
      return bookingStatusMapping.canReDispatch[booking.status];
    }
    return false;
  };

  isNotesEnable = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) {
      return false;
    }
    if (booking.farmOut) {
      if (booking.status === 'action') return true;
    } else {
      /**
       * Is Affilate Booking
       */
      if (booking.pricingType === 1) {
        return bookingStatusMapping.canUpdateNoteForAffilate[booking.status];
      }
      if (
        booking.status == 'booked' ||
        booking.status == 'arrived' ||
        booking.status == 'confirmed' ||
        booking.status == 'accepted'
      )
        return true;
    }

    return true;
  };

  isPickupTimeEditable = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    if (booking.dispatchRideSharing) {
      return false;
    }
    if (!booking.pricingType) {
      if (
        booking.status == 'pending' ||
        booking.status == 'action' ||
        booking.status == 'confirmed' ||
        booking.status == 'pre'
      ) {
        return true;
      }
    } else if (booking.farmOut) {
      if (booking.status == 'action') return true;
    }
    return false;
  };

  isCancelable = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    if (booking.pricingType == 1) {
      return false;
    }
    if (booking && !booking.farmOut) {
      return bookingStatusMapping.canCancelDelivery[booking.status];
    }
    if (
      booking.status === 'action' ||
      booking.status === 'queue' ||
      booking.status === 'offered'
    ) {
      return true;
    }
    return false;
  };

  canReject = (booking) => {
    if (booking.pricingType && !booking.farmOut) {
      if (
        booking.status === 'accepted' ||
        booking.status === 'confirmed' ||
        booking.status === 'action'
      ) {
        return true;
      }
    }
    return false;
  };

  rejectBookingButtonClick = () => {
    this.props.bookingDetailActions
      .getRejectInfo({ bookId: this.state.data.bookId })
      .then((data) => {
        if (data.ok && data.res) {
          const content =
            data.res.response.cancelPolicy > 0
              ? I18n.t(
                  'bookingdetail.Confirm_reject_booking_with_charge'
                ).format(data.res.response.cancelPolicy)
              : I18n.t('bookingdetail.Confirm_reject_message');
          this.setState({
            showConfirmReject: {
              title: I18n.t('bookingdetail.Confirm_reject'),
              body: content,
              buttonTitle: I18n.t('bookingdetail.Yes'),
              closeButtonClass: 'btn-cancel',
              closeButtonText: I18n.t('bookingdetail.No'),
            },
          });
        } else {
          this.context.notification('error', I18n.t('errors.500000'));
        }
      });
  };

  handleCloseRejectModal = () => {
    this.setState({ showConfirmReject: null });
  };

  handleConfirmRejectClick = () => {
    this.props.loadingActions.showLoadingSpiner();
    this.props.bookingDetailActions.rejectBooking({
      bookId: this.state.data.bookId,
      fleetId: this.props.auth.selectedFleet.fleetId
    })
    .then(res => {
      this.props.loadingActions.hideLoadingSpiner();
      if (res.code == 200) {
        this.context.notification(
          'success',
          I18n.t('messages.booking.Reject_booking_success')
        );
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        this.context.notification('error', I18n.t('messages.booking.Reject_fail'));
      }
    })
  };

  showConfirmForceOverlap = () => {
    this.setState({
      showConfirmForceOverlap: {
        title: I18n.t('bookingdetail.confirm_force_overlap'),
        body: I18n.t('bookingdetail.confirm_force_overlap_message'),
        buttonTitle: I18n.t('bookingdetail.Yes'),
        closeButtonClass: 'btn-cancel',
        closeButtonText: I18n.t('bookingdetail.No'),
      },
    });
  };

  handleCloseConfirmForceOverlapModal = () => {
    this.setState({ showConfirmForceOverlap: null });
  };

  handleConfirmForceOverlapClick = () => {
    const { retryDispatch } = this.state;

    this.setState({ showConfirmForceOverlap: null, forceAssign: true }, () => {
      if (retryDispatch) {
        this.updateInfoAndDispatchBookingClick();
      } else {
        this.updateInfoBookingClick();
      }
    });
  };

  isCompletable = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    // if (booking.pricingType == 1) {
    //   return false;
    // }
    // if (booking.dispatch3rd) {
    //     if (this.state.isCompleteWithPaymentShow3rdBooking) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }  //enable to payment for 3rd booking
    if (
      this.props.auth.user.userType == userType.CorporateAdmin ||
      this.props.auth.user.userType == userType.CorporateUser
    ) {
      return false;
    }
    if (!booking.pricingType) {
      return bookingStatusMapping.canComplete[booking.status];
    }
    if (!booking.farmOut) {
      return bookingStatusMapping.canComplete[booking.status];
    }
    return false;
  };

  isReBookingAble(booking) {
    return true;
  }

  isActiveBooking = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    return bookingStatusMapping.active[booking.status];
  };

  isPaymentMethodEditable = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    const status = activeStatus.filter(
      (data) => data.code == booking.status
    )[0];
    if (!booking.pricingType) {
      return status && status.paymentMethodEditable;
    }
    if (!booking.farmOut) {
      return status && status.paymentMethodEditable;
    }
    return false;
  };

  // driver auto complete render and handleAddressRemoved
  renderDriverSuggestion(suggestion, { query }) {
    let { status } = suggestion;
    if (suggestion.status == 'inProgress') {
      status = suggestion.currentJob[0]
        ? suggestion.currentJob[0].status
        : suggestion.status;
    }
    return (
      <div className={`suggestion-content-driver ${status}`}>
        <div className="name">
          {`${suggestion.firstName || ''} ${suggestion.lastName || ''}`}
        </div>
        <div className="email">
          {suggestion.vehicle.plateNumber}
          {suggestion.company ? ` / ${suggestion.company.companyName}` : null}
        </div>
        {this.state.data.request.pickUpTime != 'Now' ? (
          <div className="calendar-container">
            <span
              className="calendar"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.handleDriverCalendarClick(suggestion);
              }}
            >
              {I18n.t('bookingdetail.Calendar')}
            </span>
          </div>
        ) : null}
      </div>
    );
  }

  getDriverSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId);
    }

    this.lastAutoCompleteRequestId = setTimeout(() => {
      /**
       * Handle assign driver for the Affiliate booking
       */
      if (this.state.data.pricingType == 1) {
        this.getListDriverOnlineforAffiliate(escapedValue, regex);
      } else {
        this.getListDriverOnline(escapedValue);
      }
    }, 300);
    const regex = new RegExp(`\\b${escapedValue}`, 'i');
    const data = regexpDriverAutoComplete(
      this.props.driverAutocomplete.data,
      escapedValue
    );
    return data;
  }

  getListDriverOnline(escapedValue) {
    const {
      auth: { selectedFleet },
    } = this.props;
    const offlineDriver = isCanAssignOfflineDriver(
      selectedFleet,
      this.state.data.request.pickUpTime
    );
    this.props.driverAutocompleteActions
      .driverAutocomplete({
        fleetId: this.props.auth.selectedFleet.fleetId,
        str: escapedValue,
        pickUpTime:
          this.state.data.request.pickUpTime == 'Now'
            ? 'Now'
            : moment(this.state.data.request.pickUpTime).format(
                'YYYY-MM-DD HH:mm'
              ),
        vehicleTypeId: this.state.cartypeSelected
          ? this.state.cartypeSelected._id
          : this.state.data.request.vehicleType[0],
        companyId: this.state.data.request.companyId,
        offlineDriver,
      })
      .then(() => {
        this.setState({
          driversuggestions: regexpDriverAutoComplete(
            this.props.driverAutocomplete.data,
            escapedValue
          ),
        });
      });
  }

  getListDriverOnlineforAffiliate(escapedValue, regex) {
    this.props.driverAutocompleteActions
      .driverAutocompleteAffiliate({
        fleetId: this.props.auth.selectedFleet.fleetId,
        str: escapedValue,
        pickUpTime:
          this.state.data.request.pickUpTime == 'Now'
            ? 'Now'
            : moment(this.state.data.request.pickUpTime).format(
                'YYYY-MM-DD HH:mm'
              ),
        vehicleTypeAffiliateId: this.state.data.request.vehicleType[0],
        companyId: this.state.data.request.companyId,
      })
      .then(() => {
        this.setState({
          driversuggestions: this.props.driverAutocomplete.data.filter(
            (person) =>
              regex.test(this.getDriverSuggestionValue(person)) ||
              regex.test(person.vehicle.plateNumber) ||
              regex.test(person.phone) ||
              regex.test(person.firstName) ||
              regex.test(person.lastName)
          ),
        });
      });
  }

  getDriverSuggestionValue(suggestion) {
    return suggestion.driver.name;
  }

  onDriverSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      driversuggestions: this.getDriverSuggestions(value),
    });
  };

  onDriverSuggestionsClearRequested = () => {
    this.setState({
      driversuggestions: [],
    });
  };

  updateBookingRequestObjectBuilder(retryDispatch = false, forceAssign) {
    if (!this.state.locationPickUp) {
      return;
    }
    if (
      this.props.newBooking &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.crossZone &&
      !this.state.credit
    ) {
      this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_or_add_a_card')
      );
      return;
    }
    const bookingCurrency =
      this.props.newBooking &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.currency
        ? this.props.newBooking.locationService.currency
        : this.props.auth.selectedFleet.currency;

    let { data } = this.state;
    let { bookingDetail } = this.props;

    let pickUpTimeState = (data.request && data.request.pickUpTime) || 'Now';
    let recurring = (bookingDetail.data && bookingDetail.data.recurring) || {
      mode: 'single',
      pickupTime: pickUpTimeState,
    };

    if (recurring.mode === 'recurring') {
      recurring.pickupTime = moment(pickUpTimeState).format('HH:mm');
      recurring.selectedDates = this.state.recurringDays.map(dateOb => moment(dateOb).format('YYYY-MM-DD'))
      recurring.pickupTime = this.state.recurringTime
    } else {
      recurring.pickupTime =
        pickUpTimeState == 'Now'
          ? 'Now'
          : moment(pickUpTimeState).format('YYYY-MM-DD HH:mm');
    }

    if (!recurring.mode) {
      recurring.mode = 'single';
    }

    let puPointsNotEmpty = filterPointEmpty(data.puPoints);
    puPointsNotEmpty = this.reOrderPointToSubmit(puPointsNotEmpty);
    let doPointsNotEmpty = filterPointEmpty(data.doPoints);
    doPointsNotEmpty = this.reOrderPointToSubmit(doPointsNotEmpty);

    const object = {
      'x-request-id': uuidv4({ msecs: new Date().getTime() }),
      bookId: this.state.data.bookId,
      dispatch3rd: this.state.data.dispatch3rd,
      recurring: recurring,
      retryDispatch,
      forceAssign,
      fleetId: this.props.auth.selectedFleet.fleetId,
      dispatchType: this.state.data.dispatchType,
      externalInfo: {
        bookingReference: this.state.data?.externalInfo?.bookingReference ?? ''
      },
      drvInfo: {
        phone: '',
      },
      psgInfo: {
        creditInfo: {},
      },
      mDispatcherInfo: this.state.data.mDispatcherInfo,
      request: {
        pickUpTime:
          this.state.data.request.pickUpTime == 'Now'
            ? 'Now'
            : moment(this.state.data.request.pickUpTime).format(
                'YYYY-MM-DD HH:mm'
              ),
        note: this.state.data.request.note,
        operatorNote: this.state.data.request.operatorNote,
        vehicleType:
          this.state.data.pricingType == 1
            ? this.state.data.request.vehicleType
            : this.state.cartypeSelected === null
            ? this.state.data.request.vehicleType
            : this.state.cartypeSelected.dispatch || [
                this.state.cartypeSelected._id,
              ],
        vehicleTypeRequest:
          this.state.data.pricingType == 1
            ? this.state.data.request.vehicleTypeRequest
            : this.state.cartypeSelected === null
            ? this.state.data.request.vehicleTypeRequest
            : this.state.cartypeSelected.vehicleType ||
              this.state.cartypeSelected.ty,
        destination: null,
        moreInfo: {},
        estimate: {},
        type: 0,
        typeRate: 0,
        paymentType: parseInt(this.state.data.request.paymentType),
        packageRateId: '',
        duration: 0,
        packageRateName: '',
        tip: this.state.data.request.tip,
        promo:
          this.state.promo && this.state.promo.value > 0
            ? this.state.promo.promotionCode
            : '',
        promoValue:
          this.state.promo && this.state.promo.value > 0
            ? this.state.promo.type == 'Amount'
              ? this.state.promo.value
              : (this.state.paymentComplete.subTotal * this.state.promo.value) /
                100
            : '',
        rideSharing: this.state.data.rideSharing,
        companyId: this.state.data.request.companyId,
        companyName: !this.state.data.request?.companyId 
          ? '' 
          : this.state.data.request?.companyName,
      },
      operator: {
        userId: this.props.auth.user_id,
        name: `${this.props.auth.user.firstName || ''} ${
          this.props.auth.user.lastName || ''
        }`,
      },
    };

    if (this.state.driverCompany) {
      object.request.companyId = this.state.driverCompany._id;
      object.supplierCompanies = [this.state.driverCompany._id];
      object.request.companyName = this.state.driverCompany.name;
    }

    if (this.state.promo) {
      if (!this.state.promo.type) {
        object.request.promo = this.state.data.request.promo;
        object.request.promoValue = this.state.data.request.promoValue;
      } else {
        object.request.promo = this.state.promo.promotionCode;
        object.request.promoValue =
          this.state.promo.type == 'Amount'
            ? currencyFormatter.format(-this.state.promo.value, {
                code: bookingCurrency.iso,
              })
            : `${-this.state.promo.value}%`;
      }
    }
    if (
      this.state.locationDestination &&
      this.state.locationDestination.address &&
      this.state.locationDestination.address.length > 0
    ) {
      const markupDifference =
        (this.state.data.request.estimate.fare &&
          this.state.data.request.estimate.fare.markupDifference) ||
        0;
      object.request.markupDifference = markupDifference;
      object.request.estimate = {
        distance: this.state.disDur ? this.state.disDur.distance.text : '',
        time: this.state.disDur ? this.state.disDur.duration.text : '',
        estimateValue: this.state.disDur
          ? this.state.disDur.duration.value
          : null,
        // fare: this.state.data.request.estimate.fare
        //   ? {
        //       ...this.state.data.request.estimate.fare,
        //       unroundedTotalAmt:
        //         this.state.data.request.estimate.fare.unroundedTotalAmt +
        //         markupDifference,
        //       totalWithoutPromo:
        //         this.state.data.request.estimate.fare.totalWithoutPromo +
        //         markupDifference,
        //       etaFare:
        //         this.state.data.request.estimate.fare.etaFare +
        //         markupDifference,
        //     }
        //   : {},
        isNormalFare: this.state.data.request.estimate.fare
          ? this.state.data.request.estimate.fare.isNormalFare
          : 1,
        originFare: this.state.data.request.estimate.originFare || {},
        isFareEdited: this.state.data.request.estimate.isFareEdited || false,
        reasonEditFare: this.state.data.request.estimate.reasonEditFare || '',
        reasonMarkup: this.state.data.request.estimate.reasonMarkup || '',
        markupType: this.state.data.request.estimate.markupType,
        markupValue: this.state.data.request.estimate.markupValue,
      };
    } else {
      delete object.request.destination;
    }


      if (this.state.isDetachDrv){
        object.isDetachDrv = true;
        object.drvInfo = {
          phone: '',
        };
      } else {
        if (
          this.state.data.dispatchType == 1 ||
          this.state.data.dispatchType == 3
        ) {
            object.drvInfo = {
              phone: this.state.driver.phone,
            };
        } else {
          object.drvInfo = {
            phone: '',
          };
        }
      }

    if (this.state.data.travelerType == travelerType.Corporate) {
      object.corporateInfo = this.state.data.corporateInfo;
    }
    if (
      this.state.data.request.paymentType == paymentMethodNumber.corporateCard
    ) {
      object.corporateInfo.creditInfo = {
        localToken: this.state.credit.localToken,
        crossToken: this.state.credit.crossToken,
        cardMask: this.state.credit.cardMask,
        cardType: this.state.credit.cardType,
      };
    }
    if (
      this.state.data.request.paymentType == paymentMethodNumber.personalCard
    ) {
      object.psgInfo.creditInfo = {
        localToken: this.state.credit.localToken,
        crossToken: this.state.credit.crossToken,
        cardMask: this.state.credit.cardMask,
        cardType: this.state.credit.cardType,
      };
    }
    if (
      this.state.data.pricingType != 1 &&
      (this.state.data.request.paymentType == 5 ||
        this.state.data.request.paymentType == 7)
    ) {
      if (!object.corporateInfo) {
        object.corporateInfo = {};
      }
      object.corporateInfo.clientCaseMatter = this.state.data.corporateInfo
        ? this.state.data.corporateInfo.clientCaseMatter || ''
        : '';
      object.corporateInfo.chargeCode = this.state.data.corporateInfo
        ? this.state.data.corporateInfo.chargeCode || ''
        : '';
    }

    let deliveryInfo = {
      cashOnPickup: false,
      cashOnDelivery: doPointsNotEmpty.some(
        (item) =>
          !!(
            item.recipient &&
            item.recipient.amount &&
            parseFloat(item.recipient.amount)
          )
      ),
      pickup: {
        accepted: true,
        isPreferred: false,
        phone: data.psgInfo.phone,
        name: `${data.psgInfo.firstName} ${data.psgInfo.lastName}`,
        firstName: data.psgInfo.firstName,
        lastName: data.psgInfo.lastName,
        email: data.psgInfo.email,
        address: puPointsNotEmpty[0].address,
      },
    };
    deliveryInfo.recipients = doPointsNotEmpty.map((item) => {
      return {
        _id: item.recipient._id,
        accepted: true,
        isPreferred: false,
        address: item.address,
        status: item.recipient.status || '',
        orderId: item.recipient.orderIdServer,
        finishedInfo: item.recipient.finishedInfo,
        order: item.order,
        name: item.recipient.name,
        phone: item.recipient.phone,
        menuData: getMenuDataBookingDetailFromRecipient(
          item.recipient,
          this.props.newBooking.locationService.currency
        ),
      };
    });
    object.deliveryInfo = deliveryInfo;
    if (!object.estimate) {
      object.estimate = {}
    }
    if(puPointsNotEmpty?.length > 0 && doPointsNotEmpty?.length >0) {
      const markupDifference = this.state.data?.request?.estimate?.fare?.markupDifference
      object.request.sessionKey = this.state.sessionKeyETAFare
      object.estimate.fare = this.state.data.request?.estimate?.fare
        ? {
            ...this.state.data.request.estimate.fare,
            unroundedTotalAmt:
              this.state.data.request.estimate.fare.unroundedTotalAmt +
              markupDifference,
            totalWithoutPromo:
              this.state.data.request.estimate.fare.totalWithoutPromo +
              markupDifference,
            etaFare:
              this.state.data.request.estimate.fare.etaFare +
              markupDifference,
          }
        : {}
    } else {
      object.estimate.fare = {}
    }
    return object;
  }

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      let oldMessages = this.state.errorMessages;
      if (!valid) {
        if (!oldMessages) oldMessages = [];
        oldMessages = oldMessages.concat(messages);
      }
      this.setState({
        valid: this.state.valid,
        errorMessages: oldMessages,
      });
    }
  };

  handleDetachDrv = (e) => {
    const defaultState = {...this.state}
    defaultState.data.drvInfo = {};
    defaultState.data.dispatchType = 0;
    defaultState.driver = null;
    defaultState.isDetachDrv = true;
    this.setState(defaultState);
  }

  handleChangeRetryPaymentMethod(e) {
    const { value } = e.target;
    this.setState({
      retryPaymentMethod: value,
      isFormDirty: true,
    });
  }

  handleRetryPaymentCharge(e) {
    const { value } = e.target;
    this.setState({
      isRetryPaymentCharge: value == 1,
      isFormDirty: true,
    });
  }

  handleShowMoreCustomer = (show) => {
    this.setState({
      showMoreCustomer: show,
    });
  };

  changeDateMode = (value) => {
    if (value == 'single') {
      this.handleTimeChanged('Now');
    } else {
      this.handleTimeChanged(moment());
    }
    this.setState({ dateMode: value });
  };

  onChangeWeekDays = (weekDays) => {
    if (!weekDays) return;
    this.setState({ weekDays: weekDays });
  };

  updateFromDate = (value) => {
    let dateText = (value && value.format('YYYY-MM-DD')) || '';
    if (dateText) {
      this.setState({ fromDate: dateText });
    }
  };

  updateToDate = (value) => {
    let dateText = (value && value.format('YYYY-MM-DD')) || '';
    if (dateText) {
      this.setState({ toDate: dateText });
    }
  };

  handleRideSharingChange = (e) => {
    this.setState({
        data: { 
          ...this.state.data, 
          rideSharing: e.target.checked 
        }
    });
  };

  handleAssignDriverGroup = () => {
    const { auth, data } = this.props;
    const driverId = _.get(this.state.driver, 'driver._id', '');
    const groupId = data && data.groupId;
    if (driverId) {
      this.props.cueActions
        .assignDriverGroup({
          fleetId: auth.selectedFleet.fleetId,
          groupId: groupId,
          driverId: driverId,
        })
        .then((data) => {
          if (data.driverId) {
            this.context.notification(
              'success',
              I18n.t('messages.booking.assign_driver_success')
            );
            this.closeAssignDriverModal();
          } else {
            this.context.notification(
              'error',
              I18n.t(`messages.booking.${data.code}`)
            );
          }
        });
    } else {
      this.props.cueActions
      .detachDriverGroup({
        fleetId: auth.selectedFleet.fleetId,
        groupId: groupId,
      })
      .then((data) => {
        if (data) {
          this.context.notification(
            'success',
            I18n.t('messages.booking.detach_driver_success')
          );
          this.closeAssignDriverModal();
        } else {
          this.context.notification(
            'error',
            I18n.t(`messages.booking.${data.code}`)
          );
        }
      });
    }
  };

  closeModalMarkFailCompleted = () => {
    this.setState({ showCompletedWhenMarkFailedRecipient: false})
  }

  submitMarkFailedRecipient = (paymentObject = {}) => {
    const infoMarkupFailed = this.state.infoMarkupFailed || {}
    const recipient = infoMarkupFailed.recipient || {}
    let params = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      bookId: this.props.data.bookId,
      orderId: recipient.orderIdServer,
      reason: infoMarkupFailed.reason,
      ...paymentObject
    }
    this.props.loadingActions.showLoadingSpiner();
    this.props.bookingDetailActions
    .markFailedRecipient(params)
    .then(response => {
      this.props.loadingActions.hideLoadingSpiner();
      if(response && response.code === 1) {
        if(response.isLastPoint) {
          // trường hợp jupiter complete booking => đóng form booking
          this.close()
        }
        let currentRecipient = _.get(this.state.data, 'deliveryInfo.recipients', [])
        let newDoPoints = this.state.data.doPoints || []
        currentRecipient = currentRecipient.map(recipient => {
          if(recipient.orderId === response.orderId) {
            recipient.status = 'failed'
            return recipient
          }
          return recipient
        })
        newDoPoints = newDoPoints.map(point => {
          if(point.recipient && point.recipient.orderIdServer === response.orderId) {
            point.recipient.status = 'failed'
            return point
          }
          return point
        })
        this.setState({
          data: {
            ...this.state.data,
            doPoints: newDoPoints,
            deliveryInfo: {
              ...this.state.data.deliveryInfo,
              recipients: currentRecipient
            }
          },
          infoMarkupFailed: {},
          isShowMarkFailedRecipientModal: false
        })
        this.context.notification(
          'success',
          I18n.t('messages.booking.marked_failed_ok')
        );
      } else {
        if(response && response.errorCode === 'ORDER_HAS_BEEN_FINISHED') {
            this.context.notification('error', I18n.t(`messages.booking.ORDER_HAS_BEEN_FINISHED`));
        }
      }
    })
  }

  handleMarkFailedRecipient = () => {
    const recipient = this.state.infoMarkupFailed && this.state.infoMarkupFailed.recipient
    if(!recipient || _.isEmpty(recipient)) return 
    const recipients = _.get(this.state.data, 'deliveryInfo.recipients', [])
    if(checkLastActiveRecipient(recipients)) {
      this.setState({ 
        showCompletedWhenMarkFailedRecipient: true,
        isShowMarkFailedRecipientModal: false
      })
    } else {
      this.submitMarkFailedRecipient()
    }
  }

  handleShowHideMarkFailed = (value, recipient) => {
    if(!value) {
      this.setState({
        isShowMarkFailedRecipientModal: false, 
        infoMarkupFailed: {}
      })
      return
    }
    this.setState({ 
      isShowMarkFailedRecipientModal: value, 
      infoMarkupFailed: {
        ...this.state.infoMarkupFailed,
        recipient: recipient
      }
    })
  }

  viewPaymentLogButtonClick = () => {
    this.setState({ showPaymentLogsModal: true });
  };

  renderAssignDriverBookingModal = () => {
    const { driver = {} } = this.state;
    const bookingCurrency =
      this.props.newBooking &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.currency
        ? this.props.newBooking.locationService.currency
        : this.props.auth.selectedFleet.currency;
    return (
      <Modal
        show={this.state.isShowAssignDriverModal}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="confirm groupOrderModal"
        onHide={this.closeAssignDriverModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {driver && driver._id ? 'Edit Driver' : 'Assign Driver'}
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.closeAssignDriverModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <DispatchInfoDelivery
            user={this.props.auth && this.props.auth.user}
            data={this.state.data}
            handleChangeDispatchType={this.handleChangeDispatchType}
            handleRideSharingChange={this.handleRideSharingChange}
            newBooking={this.props.newBooking}
            isSubmited={this.state.isSubmited}
            driversuggestions={this.state.driversuggestions}
            onDriverSuggestionsFetchRequested={
              this.onDriverSuggestionsFetchRequested
            }
            onDriverSuggestionsClearRequested={
              this.onDriverSuggestionsClearRequested
            }
            driverAutocompleteSlectedHandle={
              this.driverAutocompleteSlectedHandle
            }
            handleDriverChanged={this.handleDriverChanged}
            cartypeSelected={this.state.cartypeSelected}
            driver={this.state.driver}
            bookingCurrency={bookingCurrency}
            selectedFleet={
              this.props.auth && this.props.auth.selectedFleet
            }
            handleDispatch3rdOnchange={this.handleDispatch3rdOnchange}
            ValidatorCallback={this.ValidatorCallback}
            valid={this.state.valid}
            handleDriverCalendarClick={this.handleDriverCalendarClick}
            isEditable={this.isEditable}
            isDispatchAble={this.isDispatchAble}
            handleChangeNoteSuperHelper={this.handleChangeNoteSuperHelper}
            handleChangeNote={this.handleChangeNote}
            isNotesEnable={this.isNotesEnable}
            groupId={this.state.data.groupId}
            isInGroupModal
          />
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button
            className="btn-save mr-md"
            onClick={this.handleAssignDriverGroup}
          >
            {I18n.t('bookingdetail.Save')}
          </Button>
          <Button className="btn-cancel" onClick={this.closeAssignDriverModal}>
            {I18n.t('bookingdetail.Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderConfirmMarkFailedRecipient = () => {
    const recipient = this.state.infoMarkupFailed && this.state.infoMarkupFailed.recipient
    const recipientsData = _.get(this.state.data, 'deliveryInfo.recipients', [])
    if(!recipient || _.isEmpty(recipient)) return null
    return (
        <Modal
        show={this.state.isShowMarkFailedRecipientModal}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="confirm"
        onHide={() => this.handleShowHideMarkFailed(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`Order #${recipient.orderIdServer} - Fail to deliver`}
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => this.handleShowHideMarkFailed(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>
              {I18n.t('bookingdetail.InputReason')}
            </Form.Label>
            <FormControl
              className="form-custom"
              as="textarea"
              rows={3}
              maxLength={1000}
              value={this.state.infoMarkupFailed && this.state.infoMarkupFailed.reason || ''}
              onChange={(e) => this.setReasonMarkFail(e.target.value)}
            />
            {
              checkLastActiveRecipient(recipientsData) &&
              <span className='text-soft-warning mr-t-5 block'>
                <i>{I18n.t('messages.booking.warning_markFail_lastRecipient')}</i>
              </span>
            }
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button className="btn-cancel" onClick={() => this.handleShowHideMarkFailed(false)}>
            {I18n.t('bookingdetail.Cancel')}
          </Button>
          <Button
            className="btn-save mr-md"
            disabled={!_.get(this.state.infoMarkupFailed, 'reason', '')}
            onClick={this.handleMarkFailedRecipient}
          >
            {I18n.t('bookingdetail.Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  setReasonMarkFail = (value) => {
    this.setState({
      infoMarkupFailed: {
        ...this.state.infoMarkupFailed,
        reason: value
      }
    })
  }

  closeAssignDriverModal = () => {
    this.setState({
      isShowAssignDriverModal: false,
    });
  };

  showAssignDriverModal = () => {
    this.setState({
      isShowAssignDriverModal: true,
    });
  };

  setRecurringDays = (days = []) => {
    this.setState({ recurringDays: days });
  };

  setRecurringTime = (time) => {
    this.setState({ recurringTime: time });
  };

  showChargeInvoicingModal = () => {
    this.setState({
      openChargeInvoicing: true,
    });
  };

  render() {
    if (this.props.data == null) {
      return '';
    }
    let { corporateTypes } = this.props.paymentMethod;
    const isPaymentDirectInvoicing = checkPaymentDirectInvoicing(this.props.data);
    if (this.props.auth.selectedFleet.corporate) {
      if (!this.props.auth.selectedFleet.corporate.corporateCard) {
        corporateTypes = corporateTypes.filter(
          (obj) => obj.type != 'corpCredit'
        );
      }

      if (!this.props.auth.selectedFleet.corporate.corporatePrepaid) {
        corporateTypes = corporateTypes.filter((obj) => obj.type != 'prepaid');
      }
    }
    const prepaided = checkBookingPrePaided(this.props.data);
    const showNavigationPUDO = _.get(this.props.auth, 'selectedFleet.generalSetting.showNavigationPUDO', false);
    const bookingCurrency =
      this.props.newBooking &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.currency
        ? this.props.newBooking.locationService.currency
        : this.props.auth.selectedFleet.currency;

    const { auth, data, commonData, data: { listGroup = [] }} = this.props;
    const isEnableGroupBooking = _.get(
      auth,
      'selectedFleet.batchDelivery',
      false
    );

    const {
      customer,
      locationPickUp,
      locationDestination,
      promoCodeMessageContent,
      company,
    } = this.state;
    let dataState = this.state.data || {};
    const { moduleSettings } = auth.selectedFleet;
    const isBookingFromPaxApp = !this.isBookingFromPaxApp(data);
    const companyList = filterCompanyList(
      data.travelerType,
      company,
      [...commonData.companies, ...this.props.commonData.suppliers],
      _.get(auth, 'user.userType', '')
    );
    const multiPointCalFareMode = _.get(
      auth,
      'selectedFleet.multiPointCalFareMode',
      ''
    );
    const recentPlaces = _.get(customer, 'passengerInfo.recentPlaces', {});
    const { location } = this.props.commonData;
    let isChina = location ? location.isChina : false;
    const isBookingCompleted =
      data.completedInfo &&
      data.completedInfo.total >= 0 &&
      data.status === 'completed'
        ? true
        : false;
    const isFareEdited =
      data.request.estimate && data.request.estimate.isFareEdited
        ? data.request.estimate.isFareEdited
        : false;
    const hasRecurring = _.get(auth, 'selectedFleet.recurring.enable', false);
    const bookingDetailDisable =
      !this.isPickupTimeEditable(data) || !data.reservation;
    const { fleetId } = (auth && auth.selectedFleet) || {};
    const jobType = _.get(this.props.bookingDetail, 'data.jobType', '');
    const walletName = _.get(
      this.props.bookingDetail,
      'data.request.walletName',
      ''
    );
    const isCommissionBookingApp = _.get(
      this.state.data,
      'corporateInfo.isCommissionBookingApp',
      false
    );
    const limitPoint = {
      maxDropOff: _.get(
        auth,
        'selectedFleet.delivery.maxRecipientsOperatorInput',
        1
      ),
    };

    let puPointsNotEmpty = filterPointEmpty(dataState.puPoints);
    let doPointsNotEmpty = filterPointEmpty(dataState.doPoints);

    const isMultiPuDoNotEmpty = checkMultiPuDoNotEmpty(
      puPointsNotEmpty,
      doPointsNotEmpty
    );

    return (
      <Modal
        show={!!this.props.show}
        onHide={this.close}
        backdrop
        dialogClassName="book-detail-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate
              value="bookingdetail.Order_detail"
              className="white-text"
            />
            <span className="white-text">:</span> #{this.props.data.bookId}{' '}
            <span className="white-text">-</span>{' '}
            <Translate
              className="white-text text-tranform-none"
              value={this.state.statusText}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`clearfix newbooking-form newbookingDelivery ${this.state.showTrailNote ? 'show-trail-note' : ''}`}>
          {this.renderAssignDriverBookingModal()}
          <DriverCalendarInfo
            isShowDriverCalendar={this.state.isShowDriverCalendar}
            driverReservationBookings={this.state.driverReservationBookings}
            driverReservationDatePriview={
              this.state.driverReservationDatePriview
            }
            handleDriverReservationBookingDateChange={
              this.handleDriverReservationBookingDateChange
            }
            data={this.state.data}
            onClose={this.handleCloseDriverCalendarClick}
            driverCalendarViewing={this.state.driverCalendarViewing}
            fieldKey="request.pickUpTime"
          />
          <UpdateConfirmationModal
            showConfirmUpdate={this.state.showConfirmUpdate}
            showWarningResetEditFare={this.state.showWarningResetEditFare}
            updateBookingButtonClick={this.updateBookingButtonClick}
            updateInfoBookingClick={this.updateInfoBookingClick}
            updateInfoAndDispatchBookingClick={
              this.updateInfoAndDispatchBookingClick
            }
            data={this.state.data}
            propsData={this.props.data}
            closeIcon={closeIcon}
            user={this.props.auth.user}
            userType={userType}
          />
            <SendSMSBookingModal
              showSMSBooking={this.state.showSMSBooking}
              sendSMSBooking={this.sendSMSBooking}
              smsBookingTemplateQuery={this.state.smsBookingTemplateQuery}
              getSMSBookingTemplateList={this.getSMSBookingTemplateList}
              handleSelectSMSBookingTemplate={this.handleSelectSMSBookingTemplate}
              handleChangeMessageType={this.handleChangeMessageType}
              handleChangeRecipientType={this.handleChangeRecipientType}
              handleContentChange={this.handleContentChange}
              handlePhoneChange={this.handlePhoneChange}
              handleSubjectChange={this.handleSubjectChange}
              smsWillSend={this.state.smsWillSend}
              handleSendMessageClick={this.handleSendMessageClick}
              checkDriver={this.props.data.drvInfo && !_.isString(this.props.data.drvInfo) && this.props.data.drvInfo.userId && this.props.data.drvInfo.userId.length > 0 ? true : false}
              checkCustomer={this.props.data.psgInfo && !_.isString(this.props.data.psgInfo) && this.props.data.psgInfo.userId && this.props.data.psgInfo.userId.length > 0 ? true : false}
              valid={this.state.valid}
              ValidatorCallback={this.ValidatorCallback}
              handleSearchSMSTemplate={this.handleSearchSMSTemplate}
              disabledSpam={this.state.disabledSpam}
              isSend={this.state.isSend}
            />
          <IncidentModal
            showConfirmIncident={this.state.showConfirmIncident}
            incidentButtonClick={this.incidentButtonClick}
            data={this.state.data}
            handleIncidentReasonChanged={this.handleIncidentReasonChanged}
            incidentReason={this.state.incidentReason}
            incidentOkButtonClick={this.incidentOkButtonClick}
          />
          <CancelConfirmationModal
            showConfirmCancel={this.state.showConfirmCancel}
            cancelBookingButtonClick={this.cancelBookingButtonClick}
            cancelBookingOkClick={this.cancelBookingOkClick}
          />
          <Confirm
            confirm={this.state.showConfirmReject}
            handleConfirmCloseClick={this.handleCloseRejectModal}
            handleConfirmButtonClick={this.handleConfirmRejectClick}
          />
          <Confirm
            confirm={this.state.showConfirmForceOverlap}
            handleConfirmCloseClick={this.handleCloseConfirmForceOverlapModal}
            handleConfirmButtonClick={this.handleConfirmForceOverlapClick}
          />

          <CompleteWithPaymentModal
            showConfirmComplete={this.state.showConfirmComplete}
            completeWithPaymentCloseButtonClick={
              this.completeWithPaymentCloseButtonClick
            }
            completeWithPaymentButtonClick={this.completeWithPaymentButtonClick}
            data={this.state.data}
            isCompleteWithPaymentShow3rdBooking={
              this.state.isCompleteWithPaymentShow3rdBooking
            }
            retryCompleteBookingInfo={this.state.retryCompleteBookingInfo}
            isBookingFromPaxApp={this.isBookingFromPaxApp}
            handleChangeRetryPaymentMethod={this.handleChangeRetryPaymentMethod}
            retryPaymentMethod={this.state.retryPaymentMethod}
            paymentMethod={this.props.paymentMethod}
            corporateTypes={corporateTypes}
            handleRetryPaymentCharge={this.handleRetryPaymentCharge}
            isRetryPaymentCharge={this.state.isRetryPaymentCharge}
            paymentStep={this.state.paymentStep}
            selectedFleet={this.props.auth && this.props.auth.selectedFleet}
            newBooking={this.props.newBooking}
            locationPickUp={this.state.locationPickUp}
            commonData={this.props.commonData}
            locationDestination={this.state.locationDestination}
            handleSelectDestination={this.handleSelectDestination}
            handleChangeDestination={this.handleChangeDestination}
            handleAddressRemovedDestination={
              this.handleAddressRemovedDestination
            }
            handleCompleteBasicFareChange={this.handleCompleteBasicFareChange}
            fareSettings={this.state.fareSettings}
            paymentComplete={this.state.paymentComplete}
            commisson={this.state.commisson}
            commissionValue={this.state.commissionValue}
            isCommissionBookingApp={isCommissionBookingApp}
            handleTaxChange={this.handleTaxChange}
            handleChangePromo={this.handleChangePromo}
            promo={this.state.promo}
            handlePromoApplyClick={this.handlePromoApplyClick}
            calculatePromoAmount={this.calculatePromoAmount}
            calculatorTotal={this.calculatorTotal}
            handleCompletePaymentTypeChange={
              this.handleCompletePaymentTypeChange
            }
            handleCompletePaymentIsPendingChange={
              this.handleCompletePaymentIsPendingChange
            }
            isShowmDispatcherCard={this.state.isShowmDispatcherCard}
            completeWithPayment3rdBooking={this.completeWithPayment3rdBooking}
            completeWithPaymentOkButtonClick={
              this.completeWithPaymentOkButtonClick
            }
            options={{ bounds: this.state.mapDefaultBound }}
            disableCompletePaymentNextClick={
              this.state.disableCompletePaymentNextClick
            }
          />
          <Container fluid>
            <Row>
              {isEnableGroupBooking && (
                <GroupInfoBooking
                  listGroup={listGroup}
                  groupId={this.state.data.groupId}
                  bookIdCurrent={this.props.data.bookId}
                  showDetailBookingGroup={this.showDetailBookingGroup}
                  showRemoveBookingModal={this.showRemoveBookingModal}
                  disableRemoveBtn={!bookingDetailDisable}
                  handleUngroupBooking={this.handleUngroupBooking}
                  removeBookingGroup={this.removeBookingGroup}
                  isDelivery
                />
              )}
              {/* <Col xs={12} md={12} className="mt">
                <Container fluid> */}
              {/* </Container>
              </Col> */}
              <Col xs={12} md={5} style={{ height: '100%' }}>
                <div style={{ clear: 'both' }} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4} className="info-content">
                <DateMode
                  pickupTime={this.state.data.request.pickUpTime}
                  getStatusRecurring={
                    this.props.bookingDetailActions.getStatusRecurring
                  }
                  cartypeSelected={this.state.cartypeSelected}
                  batchId={this.state.data.batchId}
                  nowButton={false}
                  format24Hour={
                    auth.selectedFleet && auth.selectedFleet.format24Hour
                  }
                  isSuperHelper={false}
                  changeDateMode={this.changeDateMode}
                  onChangePickUpHour={this.onChangePickUpHour}
                  onChangePickUpMin={this.onChangePickUpMin}
                  handleDateChanged={this.handleTimeChanged}
                  dateMode={this.state.dateMode}
                  onChangeWeekDays={this.onChangeWeekDays}
                  weekDays={this.state.weekDays}
                  hasRecurring={hasRecurring}
                  isPickupTimeEditable={this.isPickupTimeEditable}
                  bookDetails={!!this.state.data.bookId}
                  bookingDetailDisable={bookingDetailDisable}
                  updateToDate={this.updateToDate}
                  updateFromDate={this.updateFromDate}
                  toDate={this.state.toDate}
                  fromDate={this.state.fromDate}
                  recurringTime={this.state.recurringTime}
                  setRecurringTime={this.setRecurringTime}
                  bookingDetailOld={this.props.bookingDetail.data}
                  recurringDays={this.state.recurringDays}
                  setRecurringDays={this.setRecurringDays}
                  recurringBookingList={this.state.recurringBookingList}
                  jobType={jobType}
                />
                <RouteInfo
                  isBookDetails={!!this.state.data.bookId}
                  isPickupEditable={this.isPickupEditable}
                  format24Hour={
                    auth.selectedFleet && auth.selectedFleet.format24Hour
                  }
                  handleShowHideMarkFailed={this.handleShowHideMarkFailed}
                  isDestinationEditable={this.isDestinationEditable}
                  isBookingEditable={this.isBookingEditable}
                  handleMarkFailedRecipient={this.handleMarkFailedRecipient}
                  route={this.state.route}
                  puPoints={this.state.data.puPoints}
                  doPoints={this.state.data.doPoints}
                  isChina={isChina}
                  setNewPuPoint={this.setNewPuPoint}
                  setNewDoPoint={this.setNewDoPoint}
                  limitPoint={limitPoint}
                  recentPlaces={recentPlaces}
                  checkPointInZoneSupported={
                    this.props.newbookingActions.checkZoneSupportedWithPoint
                  }
                  fleetId={fleetId}
                  partyLocation={moduleSettings.partyLocation}
                  isBookingFromPaxApp={isBookingFromPaxApp}
                  jobType={jobType}
                  autocompleteCustomer={
                    this.props.messageAction.autocompleteCustomer
                  }
                  auth={auth}
                  data={this.state.data}
                  newbookingActions={this.props.newbookingActions}
                  customerActions={this.props.customerActions}
                  setNewDoPuPointAddTraveler={this.setNewDoPuPointAddTraveler}
                  corporateId={
                    (data.corporateInfo && data.corporateInfo.corporateId) || ''
                  }
                  // options={{ bounds: this.state.mapDefaultBound }}
                  options={this.state.optionAutocomplete}
                  newBooking={this.props.newBooking}
                  cueActions={this.props.cueActions}
                  currencyISO={this.state.data.currencyISO}
                />
              </Col>
              <Col xs={12} md={4} className="info-content">
                <BookingInfoDelivery
                  driverCompany={this.state.driverCompany}
                  nowButton={!this.state.data.reservation}
                  handleCompanyDriverChange={this.handleCompanyDriverChange}
                  commonData={this.props.commonData}
                  companyList={companyList}
                  locationPickUp={this.state.locationPickUp}
                  newBooking={this.props.newBooking} // new booking common data
                  user={this.props.auth && this.props.auth.user} // logged user
                  selectedFleet={
                    this.props.auth && this.props.auth.selectedFleet
                  } // current fleet
                  data={this.state.data} // booking form data
                  cartypeSelected={this.state.cartypeSelected}
                  handleTimeChanged={this.handleTimeChanged}
                  handleCarTypeChanged={this.handleCarTypeChanged}
                  etaFareMultiCar={this.state.etaFareMultiCar}
                  isHourly={false}
                  time={this.state.data.request.pickUpTime}
                  isRoundTrip={false}
                  etaFareProps={this.props.etaFare}
                  // etaFareState={this.state.etaFare}
                  etaFareState={this.state.data.request.estimate.fare}
                  locationDestination={this.state.locationDestination}
                  onChangePickUpHour={this.onChangePickUpHour}
                  onChangePickUpMin={this.onChangePickUpMin}
                  handleChangeNote={this.handleChangeNote}
                  handleChangeNoteSuperHelper={this.handleChangeNoteSuperHelper}
                  isEditable={this.isEditable}
                  isNotesEnable={this.isNotesEnable}
                  isPickupTimeEditable={this.isPickupTimeEditable}
                  isHasExtraDestination={false}
                  isMultiPuDoNotEmpty={isMultiPuDoNotEmpty}
                  multiPointCalFareMode={multiPointCalFareMode}
                  isShuttle={false}
                  corporate={this.state.company}
                  isDisableWhenPrepaided={prepaided}
                />
                <DispatchInfoDelivery
                  user={this.props.auth && this.props.auth.user}
                  data={this.state.data}
                  handleChangeDispatchType={this.handleChangeDispatchType}
                  handleRideSharingChange={this.handleRideSharingChange}
                  newBooking={this.props.newBooking}
                  isSubmited={this.state.isSubmited}
                  driversuggestions={this.state.driversuggestions}
                  onDriverSuggestionsFetchRequested={
                    this.onDriverSuggestionsFetchRequested
                  }
                  onDriverSuggestionsClearRequested={
                    this.onDriverSuggestionsClearRequested
                  }
                  driverAutocompleteSlectedHandle={
                    this.driverAutocompleteSlectedHandle
                  }
                  handleDriverChanged={this.handleDriverChanged}
                  cartypeSelected={this.state.cartypeSelected}
                  driver={this.state.driver}
                  bookingCurrency={bookingCurrency}
                  selectedFleet={
                    this.props.auth && this.props.auth.selectedFleet
                  }
                  handleDispatch3rdOnchange={this.handleDispatch3rdOnchange}
                  ValidatorCallback={this.ValidatorCallback}
                  valid={this.state.valid}
                  handleDriverCalendarClick={this.handleDriverCalendarClick}
                  isEditable={this.isEditable}
                  isDispatchAble={this.isDispatchAble}
                  isShuttle={jobType === 'shuttle' ? true : false}
                  handleChangeNoteSuperHelper={this.handleChangeNoteSuperHelper}
                  handleChangeNote={this.handleChangeNote}
                  isNotesEnable={this.isNotesEnable}
                  showAssignDriverModal={this.showAssignDriverModal}
                  groupId={this.state.data.groupId}
                  handleDetachDrv={this.handleDetachDrv}
                />
                <PromoInfo
                  handleChangePromo={this.handleChangePromo}
                  data={this.state.data}
                  promo={this.state.promo}
                  newBooking={this.props.newBooking}
                  locationPickUp={this.state.locationPickUp}
                  handlePromoApplyClick={this.handlePromoApplyClick}
                  valid={this.state.valid}
                  isSubmited={this.state.isSubmited}
                  selectedFleet={
                    this.props.auth && this.props.auth.selectedFleet
                  }
                  bookingCurrency={bookingCurrency}
                  ValidatorCallback={this.ValidatorCallback}
                  canEditPromo={this.canEditPromo}
                  isDispatchAble={this.isDispatchAble}
                  jobType={'delivery'}
                  isDisableWhenPrepaided={prepaided}
                />
                <PaymentInfo
                  data={this.state.data}
                  handleChangeBookingReference={
                    this.handleChangeBookingReference
                  }
                  handlePaymentMethodChange={this.handlePaymentMethodChange}
                  isEditable={this.isEditable}
                  reloadCustomerTSYSGateway={this.reloadCustomerTSYSGateway}
                  paymentMethod={this.props.paymentMethod}
                  newBooking={this.props.newBooking}
                  corporateTypes={this.props.paymentMethod.corporateTypes}
                  isSubmited={this.state.isSubmited}
                  valid={this.state.valid}
                  handleCreditCardChange={this.handleCreditCardChange}
                  handleAddCardSuccess={this.handleAddCardSuccess}
                  credit={this.state.credit}
                  customer={this.state.customer}
                  selectedFleet={
                    this.props.auth && this.props.auth.selectedFleet
                  }
                  auth={this.props.auth}
                  company={this.state.company}
                  companySelected={this.state.driverCompany}
                  handleClientCaseMatterChange={
                    this.handleClientCaseMatterChange
                  }
                  handleChangeOperatorNote={this.handleChangeOperatorNote}
                  handleChargeCodeChange={this.handleChargeCodeChange}
                  ValidatorCallback={this.ValidatorCallback}
                  locationPickUp={this.state.locationPickUp}
                  isDispatchAble={this.isDispatchAble}
                  isShowmDispatcherCard={this.state.isShowmDispatcherCard}
                  doPoints={this.state.data.doPoints}
                  isDelivery={true}
                  isDisableWhenPrepaided={prepaided}
                />
                <TrackLink
                  status={this.state.data && this.state.data.status}
                  pickupTime={this.props.data.request.pickUpTime}
                  delivery={this.props.data.delivery}
                  trackUrl={
                    this.props.auth &&
                    this.props.auth.selectedFleet &&
                    this.props.auth.selectedFleet.trackLink
                  }
                  tokenBooking={this.state.data && this.state.data.token}
                />
                <ViewPhotoCapture
                  listItems={
                    this.state.data.deliveryInfo &&
                    this.state.data.deliveryInfo.pickup
                      ? [this.state.data.deliveryInfo.pickup]
                      : []
                  }
                  keyValue="collectedPhotos"
                  title={'view_good_receipt'}
                  showLabel={false}
                />
                <ViewPhotoCapture
                  listItems={
                    this.state.data.deliveryInfo &&
                    this.state.data.deliveryInfo.recipients
                      ? this.state.data.deliveryInfo.recipients
                      : []
                  }
                  keyValue="deliveredPhotos"
                  title={'view_delivery_receipt'}
                />
                {isBookingCompleted && isFareEdited && (
                  <EditFareComponent
                    viewHistory={true}
                    etaFare={data.request.estimate.fare}
                    data={data}
                    prevEtaFare={this.state.prevEtaFare}
                    bookType={'DELIVERY'}
                  />
                )}
              </Col>
              <Col xs={12} md={4} className="info-content">
                <div className="booking-map-container">
                  <BookingMapChauffeur
                    showNavigationPUDO={showNavigationPUDO}
                    handleMapLoad={this.handleMapLoad}
                    center={this.state.center}
                    locationPickUp={this.state.locationPickUp}
                    puPoints={this.state.data.puPoints}
                    doPoints={this.state.data.doPoints}
                    isPickupCanDrag={this.isPickupEditable()}
                    isDestinationCanDrag={this.isDestinationEditable()}
                    handlePickupDrapEnd={this.handlePickupDrapEnd}
                    pickupFrom={this.state.pickupFrom}
                    locationDestination={this.state.locationDestination}
                    handleDestinationDrapEnd={this.handleDestinationDrapEnd}
                    destinationFrom={this.state.destinationFrom}
                    directions={this.state.directions}
                    isChina={location ? location.isChina : false}
                  />
                </div>
                {puPointsNotEmpty.length > 0 && doPointsNotEmpty.length > 0 && (
                  <TripEstimateDelivery
                    cartypeSelected={this.state.cartypeSelected}
                    newBooking={this.props.newBooking}
                    destinationFrom={this.state.destinationFrom}
                    addressDestination={this.state.addressDestination}
                    data={this.state.data}
                    auth={this.props.auth}
                    etaFare={this.state.etaFare}
                    promo={this.state.promo}
                    isFareEditedBefore={this.state.isFareEditedBefore}
                    selectedFleet={
                      this.props.auth && this.props.auth.selectedFleet
                    }
                    user={this.props.auth && this.props.auth.user}
                    disDur={this.state.disDur}
                    locationPickUp={this.state.locationPickUp}
                    locationDestination={this.state.locationDestination}
                    etaProps={this.props.etaFare}
                    bookingPermission={this.props.bookingPermission}
                    prevEtaFare={this.state.prevEtaFare}
                    handleChangeETAFare={this.handleChangeETAFare}
                    settingActions={this.props.settingActions}
                    handleChangeMarkupPrice={this.handleChangeMarkupPrice}
                    driver={this.state.driver}
                    commonData={this.props.commonData}
                  />
                )}
                {this.state.data.jobType !== 'food' &&
                  this.state.data.jobType !== 'mart' && (
                    <div style={{ marginTop: '20px' }}>
                      <TrailNotes
                        notification={this.context.notification}
                        settingActions={this.props.settingActions}
                        bookId={this.state.data.bookId}
                        fleetId={this.props.auth.selectedFleet.fleetId}
                        uploadService={this.props.uploadService}
                        setShowTrailNote={(active) =>
                          this.setState({ showTrailNote: active })
                        }
                      />
                    </div>
                  )}

                {this.state.openChargeInvoicing && (
                  <ChargeInvoicingModal
                    closeChargeModal={() =>
                      this.setState({ openChargeInvoicing: false })
                    }
                    paymentMethodSetting={this.props.paymentMethod}
                    selectedFleet={this.props.auth.selectedFleet}
                    auth={this.props.auth}
                    customer={this.state.customer}
                    corpInfo={this.state.company}
                    bookInfo={this.state.data}
                    bookingDetailActions={this.props.bookingDetailActions}
                    isBookingCompleted={CCLiteCommonFunc.isBookingStatusCompleted(
                      this.state.data.status
                    )}
                  />
                )}
                <div className="text-center btnBookingDetail">
                  {this.isNewBookingAble(this.state.data) ? (
                    <Button
                      onClick={this.newBookingButtonClick}
                      className="btn-send"
                    >
                      <Translate value="bookingdetail.New_Order" />
                    </Button>
                  ) : (
                    ''
                  )}
                  {this.state.data && <DispatchLogs data={this.state.data} />}
                  {this.isAbleUpdateBooking(this.state.data) ||
                  this.isDispatchAble(this.state.data) ? (
                    <Button
                      disabled={
                        (this.state.data.dispatchType == 1 &&
                          this.state.data.status !== 'confirmed' &&
                          !this.state.driver &&
                          this.state.data.drvInfo) ||
                        _.isEmpty(locationPickUp) ||
                        _.isEmpty(locationDestination) ||
                        this.state.data.request.paymentType == 0
                      }
                      onClick={this.updateBookingButtonClick}
                      className="btn-send"
                    >
                      <Translate value="bookingdetail.Update_Booking" />
                    </Button>
                  ) : (
                    ''
                  )}
                  {checkShowChargeInvoicingBtn(this.props.data) &&
                    this.props?.permissions?.actions && (
                      <Button
                        onClick={this.showChargeInvoicingModal}
                        className="btn-send"
                      >
                        <Translate value="bookingdetail.Charge" />
                      </Button>
                    )}
                  {this.props.auth.user.userType !== userType.CorporateAdmin &&
                    this.props.auth.user.userType !== userType.CorporateUser &&
                    ((this.props.data.drvInfo &&
                      !_.isString(this.props.data.drvInfo) &&
                      this.props.data.drvInfo.userId &&
                      this.props.data.drvInfo.userId.length > 0) ||
                      (this.props.data.psgInfo &&
                        !_.isString(this.props.data.psgInfo) &&
                        this.props.data.psgInfo.userId &&
                        this.props.data.psgInfo.userId.length > 0)) && (
                      <Button
                        onClick={this.sendSMSBooking}
                        className="btn-send"
                      >
                        <Translate value="bookingdetail.sendMessage" />
                      </Button>
                    )}
                  {this.isCancelable(this.state.data) ? (
                    <Button
                      onClick={this.cancelBookingButtonClick}
                      className="btn-cancel"
                    >
                      <Translate value="bookingdetail.Cancel_Booking" />
                    </Button>
                  ) : (
                    ''
                  )}
                  {this.isCompletable(this.state.data) ? (
                    !this.state.data.dispatch3rd ? (
                      this.state.data.pricingType != 1 ? (
                        <Button
                          onClick={this.incidentButtonClick}
                          className="btn-cancel"
                        >
                          <Translate value="bookingdetail.Incident" />
                        </Button>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  {this.isCompletable(this.state.data) ? (
                    <CompleteButton
                      bookInfo={this.state.data}
                      statusText={this.state.statusText}
                      pickups={this.state.data.puPoints}
                      destinations={this.state.data.doPoints}
                    />
                  ) : (
                    ''
                  )}
                </div>
                {this.state.showCompletedWhenMarkFailedRecipient && (
                  <CompleteButton
                    completedMarkFailed
                    closeModalMarkFailCompleted={
                      this.closeModalMarkFailCompleted
                    }
                    submitMarkFailedRecipient={this.submitMarkFailedRecipient}
                    bookInfo={this.state.data}
                    statusText={this.state.statusText}
                    pickups={this.state.data.puPoints}
                    destinations={this.state.data.doPoints}
                  />
                )}
                {this.renderConfirmMarkFailedRecipient()}

                <ul className="promotion_note">
                  {promoCodeMessageContent.schedules ? (
                    <li className="text-soft-warning">
                      <Translate value="bookingdetail.promotion_note_schedules" />
                    </li>
                  ) : null}
                  {promoCodeMessageContent.minimumEstFare !== '' ? (
                    <li className="text-soft-warning">
                      <Translate
                        value="bookingdetail.promotion_note_minimum_fare"
                        fare={promoCodeMessageContent.minimumEstFare}
                      />
                    </li>
                  ) : null}
                  {promoCodeMessageContent.paymentMethods.length > 0 ? (
                    <li className="text-soft-warning">
                      <Translate
                        value="bookingdetail.promotion_note_paymentMethod"
                        method={promoCodeMessageContent.paymentMethods
                          .map((p) =>
                            p === `21`
                              ? walletName
                              : I18n.t(`General.patmentMethod_${p}`)
                          )
                          .join(', ')}
                        //method={promoCodeMessageContent.paymentMethods.map(p => I18n.t(`General.patmentMethod_${p}`)).join(', ')}
                      />
                    </li>
                  ) : null}
                </ul>
                {checkShowViewPaymentLogsBtn(this.state.data) && (
                  <Button // btn view payment log
                    onClick={this.viewPaymentLogButtonClick}
                    variant="link"
                    className="paymentLogBtn"
                  >
                    <Translate value="bookingdetail.ViewPaymentLogs" />
                  </Button>
                )}
                {this.state.showPaymentLogsModal && (
                  <PaymentLogs // modal payment log
                    bookInfo={this.state.data}
                    hasPermissionAction={this.props?.permissions?.actions}
                    handleClose={() => {
                      this.setState({ showPaymentLogsModal: false });
                    }}
                  />
                )}
                <ConfirmEditRecurringModal
                  showConfirmRecurring={this.state.showConfirmRecurring}
                  showHideConfirmRecurringModal={
                    this.showHideConfirmRecurringModal
                  }
                  dataConfirmRecurring={this.state.dataConfirmRecurring}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}
DeliveryBookingDetail.propTypes = {
  show: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};
DeliveryBookingDetail.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    newBooking: state.newBooking,
    auth: state.auth,
    customerAutocomplete: state.customerAutocomplete,
    corporateCompany: state.corporateCompany,
    driverAutocomplete: state.driverAutocomplete,
    paymentMethod: state.paymentMethod,
    etaFare: state.etaFare,
    socket: state.socket,
    permissions: state.menuHandle.modulePermission,
    commonData: state.commonData,
    bookingDetail: state.bookingDetail,
    bookingPermission: state.menuHandle.bookingPermission,
    language: state.i18n
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deliveryActions: bindActionCreators(deliveryActions, dispatch),
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
    driverAutocompleteActions: bindActionCreators(
      driverAutocompleteActions,
      dispatch
    ),
    customerAutocompleteActions: bindActionCreators(
      customerAutocompleteActions,
      dispatch
    ),
    etaFareActions: bindActionCreators(etaFareActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
    creditCardActions: bindActionCreators(creditCardActions, dispatch),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
    driverActions: bindActionCreators(driverActions, dispatch),
    corporateActions: bindActionCreators(corporateActions, dispatch),
    tencentMapActions: bindActionCreators(tencentMapActions, dispatch),
    messageAction: bindActionCreators(messageAction, dispatch),
    cueActions: bindActionCreators(cueActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    uploadService: bindActionCreators(uploadActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryBookingDetail);
