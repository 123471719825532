export const fr = {
  name: 'French',
  flagCode: 'fr',
  locale: 'fr-fr',
  project: {
    name: 'QUp DMC '
  },
  Sidebar: {
    Setting: 'Paramètres',
    Map: 'Carte',
    Cue: 'File d\'attribution',
    ImportBookings: 'Import Bookings',
    New_booking: 'Nouvelle réservation',
    Customer: 'Clients',
    Driver: 'Pilotes',
    REPORTS: 'Rapports',
    Corporate: 'Entreprise',
    mDispatcher: 'MDispatcher',
    Driver_Settlement: 'Règlement du conducteur',
    Reports: {
      Booking_details: 'Détails réservations',
      Financial: 'Financier',
      Financial_Driver: 'Pilotes',
      Financial_company: 'Compagnie',
      Driver_activity: 'Activité du Driver',
      Revenue_summary: 'Récapitulatif des recettes',
      Revenue_fleet: 'Flotte',
      Revenue_driver: 'Pilotes',
      Revenue_monthly: 'Monsuel',
      Total_adjustment: 'Ajustement total',
      Promotion: 'Promotion',
      Incident_Cancellation: 'Incident & annulation',
      Booking_logs: 'Logs réservations',
      Rating: 'Évaluation',
      Operator_logs: 'Logs opérateur',
      Daily: 'Quotidien',
      Daily_Driver: 'Chauffeur (quotidien)',
      Daily_Car: 'Voiture (quotidien)',
      Daily_Driver_Car: 'Chauffeur Voiture (quotidien)',
      mDispatcher: 'MDispatcher',
      Corporate: 'Entreprise',
      Driver_login_status: 'Statut de connexion du chauffeur',
      Car_activity: 'Activité du véhicule',
      Partner: 'Partenaire',
      Prepaid_top_up: 'Réapprovisionner la carte prépayée',
      Affiliation: 'Affiliation',
      Booking_summary: 'Résumé des réservations',
      Cash_Wallet: 'Cash wallet',
      Credit_Wallet: 'Credit wallet',
      Driver_wallet: 'Driver wallet',
      Driver_Deposit: 'Driver deposit',
      Document_expiry: 'Document expiry',
      Settlement: 'Installation',
      Unapproved_Driver: 'Driver non approuvé',
      Approved_Driver: 'Driver approuvé',
      Pay_to_driver: 'Payé au chauffeur',
      Settlement_history: 'Historique d\'implantation'
    },
    Settings: {
      Fleet_info: 'Informations de la flotte',
      Permission: 'Autorisation',
      User: 'Utilisateur',
      General: 'Général',
      Regular: 'Ordinaire',
      Flat: 'Forfaits',
      Hourly: 'Tarif de mise à disposition',
      Company: 'Compagnie',
      Dispatch: 'Dispatch',
      Voice_SMS: 'Voix & SMS',
      Rate: 'Tarif',
      Car: 'Véhicule',
      Car_type: 'Type de véhicule',
      Car_mgmt: 'Gestion des véhicules',
      Car_Make: ' Marque et modèle',
      Partner_type: 'Type mDispatcher',
      Shift_template: 'Modèle Shift',
      Shift_settings: 'Paramètres Shift',
      Operation: 'Opération',
      Promotion_code: 'Code promo',
      Campaign: 'Campagne',
      Partner_type: 'Type de partenaire',
      Queuing_area: 'Zone de file d\'attente',
      Referral_code: 'Code Parrainage',
      City: 'Ville',
      '3rd_party_location': 'Emplacement de la 3ème partie',
      Zone: 'Zone',
      App_banner: 'Banière de l\'application'
    }
  },
  messages: {
    credits: {
      '-1': 'Ce client a été désactivé',
      421: 'Le numéro de la carte n\'est pas valide.',
      422: 'Date d\'expiration non valide.',
      429: 'Code postal invalide.',
      430: 'L\'AVS et le Code postal ne correspondent pas',
      431: 'Code postal non vérifié par AVS',
      432: 'CVV non valide.',
      433: 'CVV ne correspond pas',
      434: 'CVV non vérifié',
      435: 'L\'émetteur du CVV ne participe pas',
      436: 'Cette carte a été déclinée par l\'émetteur. Veuillez le contacter pour plus d\'informations',
      // '437': 'Une erreur est survenue lors du traitement de votre carte. Essayez à nouveau dans quelques instants',
      437: 'Fonds insuffisants. S\'il vous plaît vérifier votre solde!',
      439: 'CVV non traité',
      407: 'L\'action a échoué',
      406: 'L\'action a échoué',
      441: 'Aucune donnée CVV de l\'émetteur',
      443: 'Pas de données de l\'émetteur/Banknet switch',
      445: 'Système AVS dans l\'impossibilité d\'exécuter',
      446: 'La banque émettrice ne prend pas en charge les AVS',
      447: 'Erreur, AVS non pris en charge pour votre entreprise',
      448: 'Cette carte a été bloquée à cause de plusieurs tentatives de paiement refusées. Veuillez changer de moyen de paiement ou réessayer plus tard',
      452: 'Votre carte est refusée. Veuillez saisir une autre carte de crédit enregistrée dans la même zone que votre numéro de téléphone',
      453: 'Non supporté',
      454: 'La vérification d\'adresse ne correspond pas',
      455: 'Les vérifications d\'adresse et de Code Postal ne correspondent pas',
      458: 'Authentification échouée',
      459: 'La banque ne prend pas en charge',
      461: 'Cette carte n\'est pas prise en charge',
      462: 'Nom du titulaire invalide',
      463: 'Code de vérification invalide',
      464: 'La banque ne prend pas en charge l\'envoi et la vérification de code',
      470: 'Please enter the traveler\'s email address before make a booking with credit card',
      474: ' Please enter the traveler\'s name before make a booking with credit card',
      2000: 'N\'honorez pas',
      2001: 'Le compte n\'a pas les fonds suffisants pour couvrir le montant de la transaction.',
      2002: 'Limite dépassée',
      2003: 'Le détenteur de la carte a dépassé sa limite d\'activité',
      2004: 'Carte périmée',
      2005: 'Numéro de carte de crédit non valide',
      2006: 'Date d\'expiration non valide',
      2007: 'Pas de compte',
      2008: 'Problème de longueur de numéro de compte',
      2009: 'Cet emetteur n\'existe pas',
      2010: 'CVV refusé',
      2011: 'Voice Authorization Required. The cardholder&#39;s bank is requesting that the merchant calls to obtain a special authorization code in order to complete this transaction',
      2012: 'Paiement décliné - Possibilité que la carte soit perdue',
      2013: 'Paiement décliné - Possibilité que la carte soit volée',
      2014: 'Paiement décliné - Fraude suspectée.',
      2015: 'Transaction refusée',
      2016: 'Dupliquer la transaction',
      2017: 'Le détenteur a annulé la transaction',
      2018: 'Le détenteur de la carte a annulé toutes ses transactions',
      2019: 'Transaction invalide',
      2020: 'Violation',
      2021: 'Violation de sécurité',
      2022: 'Refusé - Nouveau détenteur disponible',
      2023: 'Cette fonction n\'est pas prise en charge ',
      2024: 'Type de carte non activé',
      2025: 'Erreur de set-up - Commercant',
      2026: 'Numéro de commercant invalide',
      2027: 'Erreur de set-up - Montant',
      2028: 'Erreur de set-up - Hierarchie',
      2029: 'Erreur de set-up - Carte',
      2030: 'Erreur de set-up - Terminal',
      2031: 'Erreur d\'encryptage',
      2032: 'Supplément refusé',
      2033: 'Donnée incompatible',
      2034: 'Pas d\'action effectuée',
      2035: 'Acceptation Partielle pour le montant en version Group III',
      2036: 'L\'autorisation est introuvable pour l\'annulation',
      2037: 'Déjà annulé',
      2038: 'Processor Declined. The customer&#39;s bank is unwilling to accept the transaction, need to contact their bank for more details.',
      2039: 'Code d\'autorisation invalide',
      2040: 'Store invalide',
      2041: 'Refusé - Appeler pour autorisation',
      2042: 'Numéro de client invalide',
      2043: 'Erreur - ne pas réessayer et appeler l\'émetteur',
      2044: 'Refusé - Appeler l\'émetteur',
      2045: 'Numéro de Commercant invzlide',
      2046: 'Declined. The customer&#39;s bank is unwilling to accept the transaction, need to contact their bank for more details.',
      2047: 'Appelez l\'émetteur. Récupérez la Carte.Cette erreur indique que la carte a été reportée volée ou perdue par le titulaire',
      2048: 'Montant invalide',
      2049: 'SKU invalide',
      2050: 'Plan de crédit invalide',
      2051: 'Le numéro de carte bleue ne correspond pas à la méthode de paiement',
      2052: 'Achat Niveau III invalide',
      2053: 'Carte reportée perdue ou volée',
      2054: 'Le montant ne correspond pas à l\'autorisation',
      2055: 'Numéro de transaction invalide',
      2056: 'Le montant de la transaction excède la limite de division',
      2057: 'L\'émetteur ou le titulaire a indiqué une restriction sur la carte',
      2058: 'Le commerçant n\'a pas activé le code de sécurité Master CardMerchant not MasterCard SecureCode enabled.',
      2060: 'Vérification de l\'Adresse et Code de Sécurité de la Carte échoués',
      2061: 'Données de transaction invalides',
      2062: 'Montant de Taxe invalide',
      2064: 'Code de devise invalide',
      2078: 'Donnée de sécurisation de paiement invalide',
      2080: 'Crédits utilisateur invalides',
      3000: 'Réseau indisponiblle - Réessayez',
      4001: 'Règlement refusé',
      4006: 'Le montant excède la limite autorisée',
      81813: 'Le code postal ne peut contenir que des lettres, chiffres, espaces, et tirets',
      81809: 'Le code postal ne peut contenir plus de 9 caractères',
      91826: 'Code postal invalide. Il doit être composé de 5 à 9 caractères, qui peuvent être séparés par un tiret ou un espace',
      81723: 'Le nom du titulaire est trop long. Maximum 175 caractères.',
      81703: 'Type de carte de crédit non accepté par ce compte marchand.',
      81718: 'Le numéro de carte de crédit ne peut pas être mis à jour vers un type de carte non pris en charge tant qu\'il est associé à des inscriptions.',
      81717: 'Le numéro de carte de crédit n\'a pas un nombre de test accepté.',
      91723: 'Mis à jour du jeton existant invalide',
      91730: 'Vérification non prise en charge sur ce compte marchand.',
      91734: 'Type de carte de crédit non accepté par ce compte marchand.',
      91738: 'Le mode de paiement spécifié n\'est pas une carte de crédit.',
      91745: 'Paramètres non valides pour la mise à jour de carte de crédit.',
      91722: 'Le mode de paiement jeton est nécessaire.',
      81706: 'CVV nécessaire',
      81707: 'CVV doit être 3 ou 4 chiffres.',
      81709: 'Date d\'expiration nécessaire.',
      81710: 'Date d\'expiration invalide.',
      81714: 'Un numéro de carte de crédit est nécessaire.',
      81715: 'Le numéro de carte de crédit est invalide.',
      81716: 'Le numéro de carte de crédit doit être de 12 à 19 chiffres.'
    },

    reBookingMsg: {
      304: 'Voulez-vous terminer cette réservation sans paiement?',
      303: 'L\'heure que vous avez sélectionné doit être postérieure à l\'heure actuelle!',
      500: 'Certaines informations de réservation sont invalides ou manquantes. Veuillez vérifier et réessayez!'
    },

    promoMsg: {
      phoneInvalid: 'Veuillez entrer le numéro de téléphone du passager avant d\'appliquer le code promo',
      304: 'Le code promo est pas invalide',
      413: 'Désolé, ce code promo n\'est pas disponible en ce moment',
      414: 'Le code promo est pas invalide',
      415: 'Le code promo est pas invalide',
      416: 'Le code promo a été utilisé.',
      419: 'Désolé, ce code promo n\'est pas disponible en ce moment',
      420: 'Sorry, you have reached your limited promo uses',
      421: 'Désolé, ce code promo a atteint sa quantité maximale ',
      422: 'Ce code promo ne peut pas être utilisé avec votre compte ',
      423: 'Désolé, vous avez atteint votre limite de budget de promotions '
    },

    updateBookingMsg: {
      304: 'Cette réservation n\'a pas été trouvée',
      303: 'La mise à jour de la réservation a échoué, Veuillez vérifier et essayer à nouveau!',
      pickUpTime: 'L\'heure que vous avez sélectionné doit être postérieure à l\'heure actuelle!',
      pickup: 'Il n\'y a pas de service de réservation disponible dans cette zone',
      destination: 'Certaines informations de réservation sont invalides ou manquantes. Veuillez vérifier et réessayez!',
      dispatchType: 'Certaines informations de réservation sont invalides ou manquantes. Veuillez vérifier et réessayez!'
    },

    sessionExpired: {
      403: 'Vous avez été deconnecté car votre compte est connecté depuis un autre appareil, ou votre session a expiré'
    },
    not_found: {
      404: 'The page you requested could not be found'
    },
    booking: {
      fare_gt_zero: 'Le tarif doit être supérieur à 0!',
      edit_total_fail: 'Echec de la modification de la valeur totale!',
      complete_fail: 'The booking cannot be completed!',
      cancle_fail: 'Echec de l\'annulation',
      incident_fail: 'Echec de l\'incident',
      phone_invalid: 'Numéro de téléphone non valide!',
      add_psg_fail: 'Impossible d\'ajouter de nouveaux passagers',
      end_booking_leave: 'Quitter cette page mettra fin à votre réservation',
      update_booking_fail: 'La mise à jour de la réservation a échoué',
      area_service_unavailable: 'Il n\'y a pas de service de réservation disponible dans cette zone',
      time_invalid: 'L\'heure que vous avez sélectionné doit être postérieure à l\'heure actuelle!',
      psg_inactivate: 'Votre compte a été inactif. Veuillez contacter notre administrateur',
      data_incorect: 'Un ou plusieurs champs obligatoires sont incorrectes. Veuillez vérifier vos informations de réservation et réessayez!',

      preAuthorize: 'Please check your account as an amount of {0} is required to guarantee your booking',
      formBookingShow: 'Veuillez fermer /terminer votre réservation en cours avant de recevoir une nouvelle demande client',
      booking_limited: 'Sorry, you have reached the maximum number of bookings per minute/day. Please try again later.',
      minimumBookAhead: 'Reservation pickup time must be at least {0} hour(s) {1} minute(s) from current time',
      maximumBookAhead: 'Reservation pickup time cannot be later than {0} day(s) from current time',
      Please_select_or_add_a_card: 'Please select or add a card!',
      304: 'Cette réservation n\'a pas été trouvée',
      303: 'L\'heure que vous avez sélectionné doit être postérieure à l\'heure actuelle!',
      500: 'Certaines informations de réservation sont invalides ou manquantes. Veuillez vérifier et réessayez!',
      Unable_to_process_by_fare_zero: 'We are unable to process your request, please change car type or booking type and try again. If you need any assistance, please contact our support team',
      Can_not_connect_to_server: 'Cannot connect to server!',
      Check_connection_or_contact_system_support: 'Please check your connection or contact system support for help!',
      Create_new_booking_success: 'A new booking has been created successfully!',
      Update_booking_success: 'This booking has been updated successfully!',
      Booking_drop_of_success: 'Booking drop off Sucessful',
      Cancel_booking_success: 'This booking has been cancelled successfully!',
      Booking_detail_not_found: 'Booking detail is not found!',
      Check_promo_error: 'Check promoCode error!',
      Invalid_format: 'Invalid format',
      Flight_not_found: 'Flight not found!',
      Add_new_credit_error: 'Add new credit error!',
      Socket_server_disconnected: 'Socket server disconnected!',
      Please_reload_page_or_check_your_connection: 'Please reload page or check your connection!',
      Check_customer_error: 'Check customer error!',
      Please_select_car_type: 'Please select car type',
      Please_enter_destination_for_ridesharing_booking: 'Please enter destination for ride-sharing booking',
      Extra_detination_must_be_difference_from_destination: 'Extra detination must be difference from destination',
      Pickup_address_must_be_different_from_destination: 'Pick-up address must be different from destination',
      Best_price: 'Best price',
      You_may_be_charged_extra_fee_if_your_trip_excceds: 'You may be charged extra fee if your trip exceeds',
      You_may_be_charged_extra_fee_if_your_trip_excceds_with_params: 'You may be charged extra fee if your trip exceeds {0} {1}, {2} {3}'
    },
    item_no_found: 'Aucun élément n\'a été trouvé.',
    commonMessages: {
      greater_than_0: 'Veuillez entrer une valeur supérieure à 0.',
      Required_field: 'Ce champ est requis',
      distance_from_equa_or_smaller: 'The distance \'from\' must be smaller than or equal to the distance \'to\'',
      must_be_number: 'Veuillez entrer un nombre valide',
      equal_or_greater_than_0: 'Please enter a value equal or greater than 0.',
      must_be_integer: 'Veuillez entrer un nombre entier',
      invalid_email_format: 'Veuillez entrer un email valide',
      greater_or_equa: 'Veuillez entrer une valeur supérieure ou égale à {0}.',
      Error_message: 'Error!',
      callcenter_newbooking_message: 'Demande de réservation par téléphone <span class=\'bold\'>{0}</span>',
      callcenter_existed_booking_message: 'Une réservation active <span class=\'bold\'>#{0}</span> existe avec téléphone <span class=\'bold\'>{1}</span>',
      callcenter_close_newbooking_form_warning: 'Veuillez fermer /terminer votre réservation en cours avant de recevoir une nouvelle demande client',
      Integer_from_range: 'Veuillez entrer un nombre entier entre {0} et {1}',
      Please_enter_a_future_date: 'Please enter a future date',
      Card_holder_is_required: 'Card holder is required',
      Card_number_is_required: 'Card number is required',
      Expired_date_is_required: 'Expired date is required',
      Expired_date_is_invalid: 'Expiration date is invalid. Please enter expiration date in format mm/yy',
      cvv_is_required: 'CVV is required',
      Card_holder_is_required: 'Card holder is required',
      Address_is_required: 'Address is required',
      Zipcode_is_required: 'Zip code is required',
      Country_is_required: 'Country is required',
      City_is_required: 'City is required',
      State_is_required: 'State is required',
    }
  },
  errors: {
    303: 'Utilisateur non trouvé',
    // Driver
    3001: 'Numéro de téléphone déjà existant',
    3002: 'Date de naissance invalide',
    3003: 'Date d\'expiration du permis de conduire non valide',
    3004: 'Chauffeur non trouvé',
    3005: 'Informations sur le règlement du chauffeur manquantes (adresse de la banque, ville, état, code postal)',
    3006: 'Informations de compte bancaire manquantes (nom du compte, type de compte, numéro de routing, numéro de compte, ssn)',
    3007: 'Vous ne pouvez pas modifier ce chauffeur, qui est en course',
    3008: 'Ce Driver est en train de se connecter. Veuillez lui demander de se déconnecter afin de modifier ses informations.',
    3009: 'Ce Driver a accepté une ou plusieurs réservation(s). Veuillez affecter lales réservation(s) à d\'autre(s) Driver(s) avant de désactiver le Driver.',
    3010: 'Échec de la connexion au dispatch.',
    3011: 'Ce chauffeur doit être configuré pour fonctionner avant d\'être activé.',
    3012: 'Ce chauffeur doit être configuré pour fonctionner avant d\'être activé.',
    3013: 'Seul un élément qui a un statut d\'inactif peut être supprimé.',
    3014: 'Les champs obligatoires sont manquants. Veuillez vérifier',
    3015: 'Année de naissance non valide. Veuillez vérifier',
    3016: 'Code postal invalide. Veuillez vérifier',
    3017: 'Etat/Province invalide. Veuillez vérifier',
    3018: 'Nom de compte invalide. Veuillez vérifier',
    3019: 'Numéro de routage invalide. Veuillez vérifier',
    3020: 'La date de naissance est obligatoire. Veuillez vérifier',
    3021: 'Numéro de ssn invalide. Veuillez vérifier',
    3022: 'Le compte bancaire du chauffeur ne peut recevoir de fonds. Vérifiez s\'il vous plaît.',
    3023: 'La connexion des Drivers ne peut pas être effectuée. Veuillez réessayer plus tard.',
    3026: 'Le chauffeur n\'existe plus.',
    3027: 'Le compte bancaire du conducteur n\'est pas configuré. Veuillez vous rendre sur le module Chauffeurs pour configurer son compte bancaire.',
    3028: 'Échec du paiement du (des) chauffeur (s) sélectionné (s). Veuillez réessayer.',
    3029: 'Echec de l\'effacement des données de gain de chauffeur. Veuillez réessayer.',
    3032: 'Ce nom d\'utilisateur existe déjà dans notre système.Veuillez en entrer un autre.',
    3033: 'Invalid BSB. The number should be 6 digits in the format xxxxxx.',
    // Vehicle
    4001: 'Votre plaque d\'immatriculation existe déjà sur le système. Veuillez en saisir un autre.',
    4002: 'Invalid expired date',
    4003: 'Vehicle is active (must deactive before deleting)',
    4004: 'Vehicle not found',
    4005: 'Vehicle is inactive (and no need to deactive any more)',
    4006: 'Vehicle is in using (cannot delete or deactive)',
    4007: 'Plate number is required',

    // Company
    5001: 'Company existed',
    5004: 'Company not found',

    // Vehicle Model
    6001: 'Vehicle model existed',
    6004: 'Vehicle model not found',

    // Vehicle make
    7001: 'Your car make name has been existed on system. Please input another one.',
    7004: 'Vehicle make not found',

    // Vehicle Type
    8001: 'Vehicle type existed',
    8004: 'Vehicle type not found',

    // Flat Route
    9001: 'Flat route existed',
    9004: 'Flat route not found',

    // Zone
    10001: 'Zone existed',
    10004: 'Zone not found',

    // FZONE
    11001: 'Zone existed',
    11002: 'Zone conflict',
    11003: 'Zone must be a polygon object',
    11004: 'Zone not found',
    11005: 'Zone is in using',

    // Inbox
    12001: 'Inbox existed',
    12002: 'Veuillez configurer SMS Twilio.',
    12004: 'Inbox not found',

    // Promo code
    13001: 'This promotion code has been existing on the system. Please input another one',
    13002: 'Invalid Date',
    13003: 'Private',
    13004: 'Promotion code not found',
    13005: 'Only New Customer',
    13006: 'Over Quantity Limit',
    13007: 'Over Budget Limit',
    13008: 'Over Total Uses Limit',
    13011: 'Promoction campaign has been deactivated or deleted',
    13012: 'Date range is invalid',

    // Booking
    14004: 'Booking not found',

    // Fleet
    15004: 'Fleet not found',
    15002: 'Votre flotte a été désactivé. Veuillez contacter notre administrateur',

    // Fare flat
    16001: 'Fare name existed',
    16004: 'Fare not found',

    // Package Rate
    17001: 'Name is existed',
    17004: 'Package rate not found',

    // Normal Fare
    18001: 'Name is existed',
    18002: 'Fare in use',
    18004: 'Fare not found',

    // Fare Hourly
    19001: 'Name is existed',
    19002: 'Fare in use',
    19004: 'Fare not found',

    // User
    20001: 'This username already exists on our system. Please enter another one.',
    20002: 'Votre compte a été désactivé. Veuillez contacter notre administrateur',
    20003: 'Le nom d\'utilisateur ou le mot de passe que vous avez tapé est incorrect. Veuillez réessayer',
    20004: 'Le nom d\'utilisateur n\'est pas enregistré dans le système.',
    20006: 'Current password does not match. Please try again.',
    20007: 'User# has been existed',
    // Role
    21001: 'Votre nom de permission existe déjà sur le système. Veuillez en saisir un autre.',
    21002: 'Impossible de désactiver cette permission, car elle est attribué à un utilisateur ou plus.',
    21003: 'Role active',
    21004: 'Role not found',
    20007: 'User # has been existed',
    // Custmer
    22001: 'Customer is existed',
    22004: 'Customer not found',
    22005: 'Invalid File Format.',

    // Voice & SMS
    24001: 'Le numéro de téléphone est invalide',

    // Queuing area
    30001: 'Queuing area not found',
    30002: 'Queuing area in using',

    // System
    500000: 'Internal system error.',
    500001: 'Veuillez configurer SMS Twilio.',
    undefined: 'Une erreur est survenue avec notre serveur, veuillez réessayer.',

    // Shift
    25001: 'Ce nom existe déjà sur le système. Veuillez en saisir un autre.',
    25002: 'Plage de temps incorrecte',
    25004: 'Shift non trouvé',
    26001: 'Ce nom existe déjà sur le système. Veuillez en saisir un autre.',
    26002: 'Ce shift est actuellement utilisé en exécution, veuillez lui retirer son assignation avant de l\'effacer',
    26004: 'Modèle de shift non trouvé',

    // Corporate
    31001: 'Votre nom de compagnie existe déjà sur le système. Veuillez en saisir un autre.',
    31002: 'Ce nom d\'utilisateur existe déjà dans notre système.Veuillez en entrer un autre.',
    31003: 'Corporate not found.',
    31004: 'Corporate\'s User not found.',
    31005: 'Seules les sociétés inactives peuvent être supprimées.',
    31006: 'Ce téléphone est déjà existant sur le voyageur entreprise. Veuillez en saisir un autre.',
    31007: 'Corporate\'s Traveler not found.',
    31008: 'Card not found.',
    31009: 'Ce téléphone existe déjà sur le système. Veuillez en saisir un autre',
    31010: 'This corporate has been deactivated. Please contact administrator for more information',
    31011: 'This traveller cannot be activated because it belongs to an inactive corporate account',
    // Additional service
    32001: 'Your service name has been existed on system. Please input another one.',
    32004: 'Service not found',
    33002: 'Les intervalles de temps ne doivent pas se chevaucher les uns avec les autres',
    // Import file :
    400005: 'File content is invalid',
    400003: 'Permission denied',
    400000: 'Numéro de téléphone non valide!',
    // third party location
    600001: 'Location not found',
    600002: 'This Longitude & Latitude coordinates have existed on the system. Please add another one',
    // mDispatcher
    29002: 'Phone number is existed',
    // mDispatcher Type
    700001: 'Type is inuse',
    700002: 'mDispatcher not found',
    700003: 'Your mDispatcher type name has been existed on system. Please input another one.',
    // promotion
    27001: 'Campaign is existed',
    27002: 'Campaign is activated',
    27004: 'Campaign not found',
    // City
    28001: 'City is existed',
    28002: 'City is beeing active',
    28003: 'City is in use',
    28004: 'City not found',
  },
  statusDisplay: {
    completedWithoutService: 'Complete without driver',
    confirmed: 'Réservation confirmée',
    pending: 'En attente',
    queue: 'En cours de dispatch',
    offered: 'En cours de dispatch',
    booked: 'Driver en route',
    engaged: 'P.O.B',
    droppedOff: 'Déposé',
    pre: 'En attente',
    recurrent: 'Récurrent',
    vacant: '',
    vacantTime: '',
    arrived: 'Arrivé et en attente',
    completed: 'Terminé',
    canceled: 'Annulé',
    noShow: 'Non présentation',
    action: 'En attente d\'exécution',
    incident: 'Incident',
    pickupTimeExceeded: 'Temps libre',
    canceledByCC: 'Annulé par CC',
    canceledByPassenger: 'Annulé par passenger',
    canceledByPartner: 'Annulé par partner',
    canceledBymDispatcher: 'Annulé par mDispatcher',
    canceledByCorporateAdmin: 'Annulé par l\'Administrateur Entreprise',
    canceledByAPI: 'Annulé par API',
    canceledByWebBooking: 'Annulé par reservation web',
    canceledByTimeout: 'Canceled by timeout'
  },
  login: {
    Username: 'Nom d\'utilisateur',
    Password: 'Mot de passe',
    Remember_me: 'Se rappeler de moi',
    Forgot_your_password: 'Mot de passe oublié',
    Login: 'S\'identifier',
  },
  cue: {
    Active: 'Actif',
    Finished: 'Terminé',
    Home_affiliate: 'Ma flotte/Affilié',
    Booking_type: 'Type de réservation',
    From: 'De',
    To: 'À',
    Status: 'Statut',
    Select_Status: 'Sectionner un statut',
    Operator: 'Opérateur',
    Car_types: 'Type de véhicule',
    Booking_from: 'Réservé de',
    All: 'Tout',
    Pending: 'En attente',
    Dispatching: 'En cours de dispatch',
    Waiting_for_process: 'En attente d\'exécution',
    Confirmed_reservation: 'Réservation confirmée',
    Driver_on_the_way: 'Driver en route',
    Arrived_and_waiting: 'Arrivé et en attente',
    Passenger_on_board: 'Passager à bord',
    Dropped_off: 'Déposé',
    combobox_select: 'Sélectionnez...',
    Search_here: 'Rechercher ici',
    Bookingid: 'Réservation #',
    Pickup_Drop_Off: 'Départ/Déposé',
    Pickup_Location_Destination: 'Lieu de départ/Destination',
    Passenger: 'Passager',
    Airport_Pickup: 'Accueil à l\'aéroport',
    Payment: 'Paiement',
    Car_type: 'Type de véhicule',
    Vehicle: 'Véhicule',
    Driver: 'Pilotes',
    Estimated_Fare: 'Tarif estimées',
    Notes: 'Remarques',
    Type: 'Type',
    Home: 'Ma flotte',
    Affiliate: 'Affilié',
    Now: 'Maintenant',
    Reservation: 'Réservation',
    No_show: 'Non présentation',
    Canceled_by_CC: 'Annulé par CC',
    Canceled_by_passenger: 'Annulé par le passager',
    Canceled_by_partner: 'Annulé par le partenaire',
    Canceled_by_mDispatcher: 'Annulé par le mDispatcher',
    Canceled_by_Corporate_Admin: 'Annulé par l\'Administrateur Entreprise',
    Canceled_by_API: 'Annulé par API',
    canceled_By_Web_Booking: 'Annulé par reservation web',
    canceled_By_Time_Out: 'Canceled by timeout',
    Completed: 'Terminé',
    Incident: 'Incident',
    Payment_Method: 'Mode de paiement',
    Passenger_type: 'Passager type',
    Payment: 'Paiement',
    Error_load_booking: 'Cannot load the booking, Please try again or refresh page!',
    Edit_total_success: 'Edit total successful!',
    Edit_total_fail: 'Edit total fail!',
    Time_out: 'Temps dépassé',
    Ride_Service: 'Service de transport',
    Regulation: 'Règlement',
    Ride_Sharing: 'Ride-sharing',
    Unmatching: 'Unmatching',
    Matching: 'Matching',
    all: 'All',
    matching: 'Matching',
    unmatching: 'Unmatching',
    regulation: 'Règlement',
    rideSharing: 'Ride-sharing',
    Support_service: 'Support service',
    SOS: 'SOS',
    Non_SOS: 'Non-SOS',
    Corporate: 'Entreprise',
    Individual: 'Individuel',
    On_curb: 'Sur le trottoir',
    Meet_Greet: 'Accueil personnalisé',
    Close: 'Fermer',
    auto_refrest_cue: 'Auto-refresh ({0} seconds)',
    Ride_type: 'Ride type',
    Edit_total_note: 'Note: subtotal, tech fee, booking fee, tax and tip will be updated when changing the total amount',
    Booking: 'Booking',
    Edit_fare: 'Edit fare',
    Adjust_price: 'Adjust price',
    Total: 'Total',
    Reason: 'Reason',
    Please_enter_reason: 'Please enter reason.',
    Save: 'Sauvegarder',
    Cancel: 'Annuler'
  },
  newbooking: {
    Pick_up_time: 'Pick up time',
    Drop_off_time: 'Drop off time',
    New_Booking: 'Nouvelle réservation',
    GoogleMapRoute: 'Google Map',
    BOOKING_INFORMATION: 'Informations réservation',
    TIP_PROMO: 'Pourboire - Promo',
    Pick_up: 'Départ',
    Enter_a_location: 'Entrez un lieu',
    Enter_3rd_party_location: 'Emplacement de la 3ème partie',
    Destination: 'Destination',
    Pickup_time: 'Heure de départ',
    Note: 'Remarques',
    TRAVELER_INFORMATION: 'Information du voyageur',
    Traveler_type: 'Type de voyageur',
    Individual: 'Individuel',
    Corporation: 'Entreprise',
    Phone_number: 'Numéros de téléphone',
    First_name: 'Prénom',
    Last_name: 'Nom',
    Email_address: 'Adresse e-mail',
    Corp_id: 'Numéro d\'entreprise',
    Manager_name: 'Nom du gestionnaire',
    Manager_email: 'Courriel du directeur',
    Cost_centre: 'Centre de coûts',
    Department: 'Département',
    Corp_division: 'Division d\'entreprise',
    Tip: 'Pourboire',
    Promo_code: 'Code promo',
    Apply: 'Appliquer',
    Promo: 'Promo:',
    DISPATCH: 'Dispatch',
    Auto_dispatch: 'Dispatch automatique',
    Assign_driver: 'Attribuer Driver',
    Driver: 'Pilotes',
    PAYMENT_METHOD: 'Moyens de paiement',
    TRIP_ESTIMATE: 'Estimation du trajet',
    Choose_a_stored_card: 'Choose a stored card',
    Use_new_card: 'Utiliser une nouvelle carte',
    Card_holder: 'Nom du titulaire de la carte',
    Card_number: 'Numéro de carte',
    Expired_date: 'Date d\'expiration(mm/yyyy)',
    ccv: 'CVV',
    Distance: 'Distance',
    Duration: 'Durée',
    Fare: 'Tarif',
    Route: 'Routes',
    Create: 'Créer',
    Cancel: 'Annuler',
    Meet_driver: 'Rencontrez Driver',
    Airline: 'Compagnie aérienne',
    Flight: 'Vol #',
    FLY_INFO: 'Informations du vol',
    Ride_Sharing: 'Partage de course',
    Round_trip: 'Aller-retour',
    Car_type: 'Type de véhicule',
    Address: 'Adresse',
    City: 'Ville',
    State: 'Etat',
    Zip_code: 'Code postal',
    Remove: 'Enlever',
    Client_case_matter: 'Client case matter / Trip description',
    Charge_code: 'Charge code / Trip code',
    CANCEL_BOOKING: 'Feremer la confirmation de réservation',
    CANCEL_BOOKING_CONFIRM: 'Voulez vous quitter? Les données de réservation seront effacées',
    Search_here: 'Rechercher ici',
    No_support_location: 'Désolé,Il n\'y a pas de service de réservation dans cette zone',
    Closest_driver_message: 'Votre chauffeur le plus proche est à {0} minutes',
    No_cars_available: 'Aucun véhicule disponible dans le rayon de dispatch.',
    processed_by_affiliate: 'Vos requêtes seront traitées par nos partenaires affiliés',
    FlightExample: 'Vol#, p. ex. AA1097',
    Yes: 'Oui',
    No: 'Non',
    dispatch_3rd: 'Dispatcher via une 3ème partie',
    Company: 'Compagnie',
    Card_required: 'Please select or add a card!',
    Tip_value: 'N/A',
    Calendar: 'Calendar',
    Extra_Destination: 'Additional Destination',
    Company: 'Clients',
    Driver_credit_limited_balance_warning: 'This driver\'s balance is less than or equal limited amount',
    Meet_Greet_option: 'Meet & Greet',
    On_Curb_option: 'On Curb',
    Extra_Services: 'Additional Services',
    Addl_Services: 'Add\'l services',
    Save: 'Sauvegarder',
    Cancel: 'Annuler',
    Calendar: 'Calendar',
    Addl_Services: 'Add\'l Services',
    Please_fill_in_all_the_required_fields: 'Please fill in all the required fields.'
  },
  bookingdetail: {
    Booking_detail: 'Détails réservations',
    creditTransactionFee: 'Credit card fees',
    BOOKING_INFORMATION: 'Informations réservation',
    TIP_PROMO: 'Pourboire - Promo',
    Pick_up: 'Départ*',
    Enter_a_location: 'Entrez un lieu',
    Destination: 'Destination',
    Pickup_time: 'Heure de départ',
    Notes: 'Remarques',
    TRAVELER_INFORMATION: 'Information du voyageur',
    Traveler_type: 'Type de voyageur',
    Individual: 'Individuel',
    Corporation: 'Entreprise',
    Phone_number: 'Numéros de téléphone',
    First_name: 'Prénom',
    Last_name: 'Nom',
    Email_address: 'Adresse e-mail',
    Corp_id: 'Numéro d\'entreprise',
    Manager_name: 'Nom du gestionnaire',
    Manager_email: 'Courriel du directeur',
    Cost_centre: 'Centre de coûts',
    Department: 'Département',
    Corp_division: 'Division d\'entreprise',
    Tip: 'Pourboire',
    Promo_code: 'Code promo',
    Apply: 'Appliquer',
    Promo: 'Promo:',
    DISPATCH: 'Dispatch',
    Auto_dispatch: 'Dispatch automatique',
    Assign_driver: 'Attribuer Driver',
    Driver: 'Pilotes',
    PAYMENT_METHOD: 'Moyens de paiement',
    Payment_methods: 'Moyens de paiement',
    TRIP_ESTIMATE: 'Estimation du trajet',
    Choose_a_stored_card: 'Choose a stored card',
    Use_new_card: 'Utiliser une nouvelle carte',
    Card_holder: 'Nom du titulaire de la carte',
    Card_number: 'Numéro de carte',
    Expired_date: 'Date d\'expiration(mm/yyyy)',
    ccv: 'CVV',
    Distance: 'Distance',
    Duration: 'Durée',
    Fare: 'Tarif',
    Route: 'Routes',
    Save: 'Sauvegarder',
    Cancel: 'Annuler',
    Meet_driver: 'Rencontrez Driver',
    Airline: 'Compagnie aérienne',
    Flight: 'Vol #',
    FLY_INFO: 'Informations du vol',
    Ride_Sharing: 'Partage de course',
    Round_trip: 'Aller-retour',
    Car_type: 'Type de véhicule',
    Address: 'Adresse',
    City: 'Ville',
    State: 'Etat',
    Zip_code: 'Code postal',
    Remove: 'Enlever',
    New_Booking: 'Nouvelle réservation',
    Update_Booking: 'Mise à jour Réservation',
    Cancel_Booking: 'Annuler Réservation',
    Complete_with_payment: 'Terminé avec paiement',
    Incident: 'Incident',
    CONFIRM_CANCEL: 'Confirmer l\'annulation',
    CONFIRM_CANCEL_MESSAGE: 'Veuillez cliquer sur \'D\'accord\'  pour annuler cette réservation.Si vous ne voulez pas, cliquez sur \'Annuler\' pour fermer cette boîte de dialogue',
    CONFIRM_UPDATE: 'Confirmer la mise à jour',
    CONFIRM_UPDATE_MESSAGE: 'Voulez-vous vraiment mettre à jour cette réservation?',
    Close: 'Fermer',
    Update_info: 'Mettre à jour les infos',
    Update_info_dispatch: 'Mettre à jour les infos et le dispatch',
    BOOKING: 'Réservation',
    DROPPED_OFF: 'Déposé',
    Payment_Type: 'Type de paiement',
    Total: 'Total',
    Next: 'Suivant',
    Pay_here: 'Payez ici',
    Back: 'Retour',
    Tax: 'Taxe',
    Booking_fees: 'Frais de réservation',
    Tech_fees: 'Frais de Technique',
    Subtotal: 'Sous-total',
    Other_fees: 'Autres frais',
    Toll_fees: 'Frais de péage',
    Heavy_traffic: 'Circulation dense',
    Rush_hour: 'Heure de pointe',
    Basic_fare: 'Tarif de base',
    Ok: 'D\'accord',
    Input_reason: 'Saisir une raison',
    Client_case_matter: 'Client case matter / Trip description',
    Charge_code: 'Charge code / Trip code',
    Search_here: 'Rechercher ici',
    Enter_3rd_party_location: 'Enter 3rd party location',
    Company: 'Compagnie',
    Check_promo_error: 'Check promoCode error!',
    Add_new_credit_error: 'Add new credit error!',
    Can_not_get_fare_setting: 'Cannot get fare setting!',
    Can_not_get_affilate_fare_setting: 'Cannot get Affiliate fare settings!',
    Can_not_check_commisson: 'cannot check commisson!',
    No_flight_info: 'No flight info!',
    Socket_server_disconnected: 'Socket server disconnected!',
    Please_reload_page_or_check_your_connection: 'Please reload page or check your connection!',
    View_Logs: 'Voir  les actions',
    Bookingid: 'Réservation #',
    Calendar: 'Calendar',
    Extra_Destination: 'Additional Destination',
    Company: 'Company',
    Meet_Greet_option: 'Accueil personnalisé',
    On_Curb_option: 'Sur le trottoir',
    Credit_Card: 'Carte de crédit',
    Charge: 'Charge',
    No_charge: 'No charge',
    QR_Code_method_not_allow_error_message: 'Please select another payment type as QRCode is not available to pay on dashboard!',
    change_payment_method_note: 'If you want to change payment method, please go back to booking form.'
  },
  map: {
    Enter_a_location: 'Entrez un lieu',
    All_company: 'Toute entreprise',
    All_cars: 'Tous les véhicules',
    Show_all_cars: 'Montrer tous les véhicules',
    Hide_all_cars: 'Cacher tous les véhicules',
    Vacant: 'Available',
    Dispatching: 'En cours de dispatch',
    Driver_on_the_way: 'Driver en route',
    Arrived_and_waiting: 'Arrivé et en attente',
    P_O_B: 'P.O.B',
    Dropped_off: 'Déposé',
    Waiting_for_process: 'En attente d\'exécution',
    Unavailable: 'Indisponible',
    Total_Drivers: 'Total Chauffeurs',
    Estimate: 'Estimées',
    Destination: 'Destination',
    Pickup: 'Prise en charge',
    Start_point: 'Start point',
    Passenger: 'Passager',
    Driver_name: 'Nom du Driver',
    Search_car_here: 'Search car here',
    Error_load_booking: 'Cannot load the booking, Please try again or refresh page!'
  },
  customer: {
    Customers: 'Clients',
    ADD_CUSTOMER: 'AJOUTER CLIENT',
    EDIT_CUSTOMER: 'MODIFIER CLIENT',
    DETAIL_CUSTOMER: 'DETAIL TRAVELER',
    Add: 'Ajouter',
    Activate: 'Activer',
    Deactivate: 'Désactiver',
    Delete: 'Effacer',
    Import: 'Importer',
    View_Message: 'Message',
    Send_Message: 'Envoyer le message',
    Export_to_excel: 'Exporter vers Excel',
    Filter: 'Filter',
    Apply: 'Apply',
    Clear: 'Clear',
    Name: 'Nom',
    Phone_Number: 'Numéro De Téléphone',
    Email: 'Email',
    Vip: 'Vip',
    File: 'Fichier',
    Number_successful_imports: 'Nombre d\'importations réussies: ',
    Number_duplicate_phone_numbers: 'Nombre de numéros de téléphone identiques: ',
    Number_wrong_phone_numbers: 'Nombre de numéros de téléphone erronés: ',
    Corporate_Name: 'Corporation',
    Registered_From: 'Enregistré À Partir De',
    Registration_Date: 'Date D\'enregistrement',
    Registration_date_from: 'Date d\'inscription de',
    Registration_date_to: 'Date d\'inscription à',
    IOS_Last_Login_Version: 'Version IOS Lors De La Dernière Connexion',
    Android_Last_Login_Version: 'Version Android Lors De La Dernière Connexion',
    Last_Active_Date: 'Dernière date d\'activation',
    Days_Since_Last_Active: 'Days Since Last Active',
    Completed_Bookings: 'Réservation terminées',
    Status: 'Statut',
    Actions: 'Actions',
    Action: 'Action',
    Inactive: 'Inactif',
    Active: 'Actif',
    Edit: 'Modifier',
    View: 'Voir',
    ALERT_SELECT_ACTIVATE: 'Veuillez sélectionner un client à activer',
    ALERT_SELECT_DEACTIVATE: 'Veuillez sélectionner un client à désactiver',
    ALERT_SELECT_DELETE: 'Veuillez sélectionner un client à supprimer',
    CONFIRM_DELETE: 'Voulez-vous supprimer ce client?',
    CONFIRM_DEACTIVATE_MULTI: 'Voulez-vous désactiver ces clients?',
    CONFIRM_ACTIVATE_MULTI: 'Voulez-vous activer ces clients?',
    CONFIRM_DELETE_MULTI: 'Voulez-vous supprimer ces clients?',
    CONFIRM_EXPORT_TO_EXCEL: 'Etes vous sûr de vouloir exporter toutes les données dans un fichier .xls?',
    // Add, Edit Customer
    Add_card_top_up_success: 'Prepaid card has been topped up successfully',
    Add_card_success: 'La carte a été ajoutée avec succès!',
    Add_customer_success: 'Add customer success',
    Update_customer_success: 'Update customer success',
    ERROR_EXISTED_PHONE: 'Ce téléphone existe déjà sur le système. Veuillez en saisir un autre.',
    ERROR_INPUT_FIRSTNAME: 'Veuillez saisir le prénom.',
    ERROR_INPUT_PHONE: 'Veuillez saisir le numéro de téléphone',
    ERROR_INPUT_VALIDPHONE: 'Veuillez spécifier un numéro de téléphone valide',
    ERROR_INPUT_VALIDEMAIL: 'Veuillez spécifier un numéro de email valide',
    CONFIRM_DELETE_CARD: 'Etes vous sûr de vouloir effacer cette carte?',
    First_name: 'Prénom',
    Last_name: 'Nom',
    Phone_number: 'Numéro de téléphone',
    Email_address: 'Adresse e-mail',
    Notes: 'Remarques',
    Account: ' Compte',
    Card_management: 'Gestion de carte',
    Home: 'Ma flotte',
    Affiliate: 'Affilié',
    SHOW_PERSONAL_CARD: 'SHOW PERSONAL CARD',
    ADD_PERSONAL_CARD: 'AJOUTER UNE CARTE PERSONNELLE',
    SHOW_mDISPATCHER_CARD: 'SHOW mDISPATCHER CARD',
    ADD_mDISPATCHER_CARD: 'Ajouter la carte mDispatcher',
    Personal_card: 'Carte Personnelle',
    mDispatcher_card: 'Carte mDispatcher',
    Card_holder: 'Titulaire de la carte ',
    Card_number: 'Numéro de carte',
    Expired_date: 'Date d\'expiration',
    CVV: 'CVV',
    Address: 'Adresse',
    Country: 'Pays',
    City: 'Ville',
    State: 'Etat',
    Zip_code: 'Code postal',
    Required_fields: 'Champs requis',
    Cancel: 'Annuler',
    Save: 'Sauvegarder',
    // Add, Delete Card
    ERROR_INPUT_CARD_HOLDER: 'Veuillez entrer le titulaire de la carte',
    ERROR_INPUT_CARD_NUMBER: 'Veuillez entrer le numéro de carte',
    ERROR_INPUT_EXPIRED_DATE: 'Date d\'expiration non valide',
    ERROR_INPUT_CVV: 'Veuillez entrer le CVV',
    ERROR_INPUT_ADDRESS: 'Veillez entrer l\'adresse',
    ERROR_INPUT_CITY: 'Veuillez entrer la ville',
    ERROR_INPUT_ZIPCODE: 'Veuillez entrer le code postal',
    ERROR_INPUT_COUNTRY: 'Veuillez choisir un pays',
    Info_edit_error: 'Veuillez!',
    Gender: 'Gender',
    Select_Gender: 'Select',
    Male: 'Male',
    Female: 'Female',
    Date_of_birth: 'Date of birth',
    Street_address: 'Street address',
    City: 'City',
    State: 'State',
    Zip_code: 'Zip code',
    Country: 'Country',
    Select_Zone: 'Select Zone',
    ERROR_GET_GATEWAYS: 'ERROR !!! Get Gateways',
    Require_email: 'Please enter the email before adding new card',
    Require_lastName: 'Please enter the last name before adding new card',
    Require_phone_number: 'Please enter the traveler\'s phone number before make a booking with credit card',
    IMPORT_CUSTOMER_SUCCESS: 'import customers successfully',
    No_card: 'Pas de carte trouvée',
    No_Support: 'New card adding is currently not supported in this area',
    Card_Management: 'Gestion de carte',
    DETAIL_CUSTOMER: 'Détails du client',
    Select_Country: 'Choisissez un pays'
  },
  table_header: {
    Items_per_page: 'Eléments par page',
    Page: 'Page',
    Search_here: 'Rechercher ici',
    Shown_Active: '{0} - {1} de {2} Affiche ',
    Active: '{0} Actif ',
  },
  driver: {
    Drivers: 'Pilotes',
    Add: 'Ajouter',
    Activate: 'Activer',
    Deactivate: 'Désactiver',
    Delete: 'Effacer',
    View_Message: 'Message',
    Send_Message: 'Envoyer le message',
    Driver_Name: 'Nom Du Driver',
    Driver_ID: 'ID# / National IC#',
    Username: 'Username',
    Phone_Number: 'Numéro De Téléphone',
    Driver_Type: 'Type De Driver',
    TOP: 'TOP',
    Company: 'Compagnie',
    Driver_License_Number: 'Numéro De Permis De Conduire',
    License_Plate: 'Plaque D\'immatriculation',
    Car_Type: 'Type De Véhicule',
    Zone: 'Zone',
    Registered_From: 'Enregistré À Partir De',
    Registration_Date: 'Date D\'enregistrement',
    IOS_Last_Login_Version: 'Version IOS Lors De La Dernière Connexion',
    Android_Last_Login_Version: 'Version Android Lors De La Dernière Connexion',
    Last_Active_Date: 'Last Active Date',
    Days_Since_Last_Active: 'Nombre de jurs depuis la dernière activation',
    Completed_bookings: 'Completed bookings',
    Status: 'Statut',
    Actions: 'Actions',
    Action: 'Action',
    Inactive: 'Inactif',
    Active: 'Actif',
    Edit: 'Modifier',
    View: 'Voir',
    ALERT_SELECT_ACTIVATE: 'Veuillez sélectionner un Driver à activer.',
    ALERT_SELECT_DEACTIVATE: 'Veuillez sélectionner un Driver à désactiver',
    ALERT_SELECT_DELETE: 'Veuillez sélectionner un Driver à supprimer',
    CONFIRM_DELETE: 'Voulez-vous supprimer ce Driver?',
    CONFIRM_DEACTIVATE_MULTI: 'Voulez-vous vraiment désactiver le (s) chauffeur (s) sélectionné (s)? Le (s) compteur (s) physique (s) connecté (s) sera également désactivé.' + 'Seuls le(s) conducteur(s) qui n\'a pas de trajet actuel ou de réservation en cours peuvent être désactivés.',
    CONFIRM_ACTIVATE_MULTI: 'Voulez-vous pour activer ces Drivers? Seuls les chauffeurs qui sont complètement configurés pour le fonctionnement peuvent être activés.',
    CONFIRM_DELETE_MULTI: 'Voulez-vous supprimer ces Drivers? Seul un Driver qui a un statut d\'inactif peut être supprimé.',
    // Add, Edit Driver
    ADD_DRIVER: 'AJOUTER DRIVER',
    Avatar: 'Avatar',
    Individual_Driver: 'Driver individuel',
    Owner_Operator: 'Gérant de flotte',
    CONTACT_INFO: 'INFOMATIONS DE CONTACT',
    Home_address: 'Adresse du domicile',
    First_name: 'Prénom',
    Last_name: 'Nom',
    Phone_number: 'Numéro de téléphone',
    Email_address: 'Adresse e-mail',
    City: 'Ville',
    Select_city: 'Sélectionner une ville',
    State: 'Etat',
    Zip_code: 'Code postal',
    Date_of_birth: 'Date de naissance',
    City_driver_want_to_drive: 'Ville dans laquelle le Driver veut exercer',
    Driver_license_number: 'Numéro de permis de conduire',
    Driver_license_state: 'Pays du permis de conduire',
    Driver_license_expiry: 'Expiration du contrôle technique',
    FOR_INSPECTOR: 'POUR L\'INSPECTEUR',
    Document: 'Document',
    Link: 'Lien',
    Notes: 'Remarques',
    UPLOAD_DOCUMENT: 'TÉLÉCHARGER LE DOCUMENT',
    Click_here_to_download: 'Cliquer ici pour télécharger.',
    EXTENSION_REQUIREMENT: 'L\'extension du fichier doit être .jpg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip et la taille maximale est 5Mb',
    Invalid_file: 'Fichier non valide',
    // Bank Account
    BANK_ACCOUNT: 'COMPTE BANCAIRE',
    Name_of_bank: 'Nom de la banque',
    Name_of_account: 'Nom de compte',
    First_M_Last: 'First M. Last',
    RTBnumber: 'Numéro du routage / de la correspondance / de la succursale',
    Account_number: 'Numéro de compte',
    IBAN_number: 'Numéro de IBAN',
    Check_number: 'Numéro de vérification',
    Swift_code_number: 'Numéro de code Swift',
    SSNNI: 'SSNNI',
    Verification_document: 'Document de vérification',
    OPERATION: 'OPÉRATION',
    CAR_INSURANCE: 'ASSURANCE VOITURE',
    Policy_number: 'Numéro de police',
    Effective_date: 'Date d\'effet',
    Expired_date: 'Date dépassée',
    Select_company: 'Sélectionner une compagnie',
    Car: 'Véhicule',
    Existing_car: 'Véhicule existant',
    New_car: 'Nouveau véhicule',
    License_plate: 'Plaque d\'immatriculation',
    Select_license_plate: 'Sélectionnez la plaque d\'immatriculation',
    Select_Car_Type: 'Veuillez sélectionner le type de véhicule',
    Make: 'Faire',
    Select_Car_Make: 'Sélectionner marque de véhicule',
    Model: 'Modèle',
    Select_Car_Model: 'Sélectionner le modèle de véhicule',
    Year: 'Année',
    Select_Year: 'Sélectionnez l\'année',
    Color: 'Couleur',
    Passengers: 'Passagers',
    Luggage: 'Bagage',
    Phone_Number: 'Phone number',
    License_expiry: 'Expiration de la licence',
    Shift: 'Shift',
    Select_Shift_Template: 'Sélectionner un modèle Shift',
    Fleet_commission: 'Commission de la Flotte (rapport)',
    Percentage: 'Pourcentage',
    Amount: 'Montant',
    transaction: 'transaction',
    Terminal_ID: 'Identification de terminal',
    Auth_key: 'Clé d\'authentification',
    CREDIT_DEPOSIT: 'Credit wallet',
    Balance: 'Balance',
    Credit_Balance: 'Balance de crédit',
    Change_balance: 'Change balance',
    Select_balance: 'Select balance',
    Adjust_amount: 'Adjust amount',
    Adjust_value: 'Adjust value',
    New_point: 'New point',
    New_balance: 'New balance',
    Enter_reason: 'Enter reason',
    ERROR_INPUT_BALANCE: 'Veuillez sélectionner l\'équilibre.',
    ERROR_INPUT_AMOUNT: 'Veuillez entrer cette valeur',
    ERROR_INPUT_REASON: 'Veuillez entrer la raison.',
    ERROR_INPUT_VALID_AMOUNT: 'Veuillez entrer une valeur supérieure ou égale à 0.',
    INFO_ADD_BALANCE_SUCCESS: 'Le solde du pilote a été mis à jour avec succès',
    Force_meter: 'Force_meter',
    Force_meter_tooltip: 'L\'appli chauffeur doit être connectée au compteur avant d\'être utilisée. Appliqué pour les compteurs Pulsar & Centrodyne',
    ERROR_EXISTED_PHONE: 'Ce téléphone existe déjà sur le système. Veuillez en saisir un autre.',
    TOP_driver: 'TOP¨chauffeur',
    Ride_sharing: 'Partage de course',
    Note_password: 'Remarque: Après la création du compte, veuillez saisir "password" comme mot de passe par défaut pour vous connecter',
    Reset_password: 'Réinitialiser le mot de passe',
    CONFIRM_RESET_PASSWORD: 'Voulez-vous réinitialiser le mot de passe? \n Mot de passe par défaut: password',
    Reset_password_success: 'Réinitialisation de mot de passe effectuée',
    Required_fields: 'Champs requis',
    Cancel: 'Annuler',
    Save: 'Sauvegarder',
    ERROR_INPUT_USERNAME: 'Please input username.',
    ERROR_INPUT_VALID_USERNAME: 'Username may only contain lower letters & numbers and must be between 3 and 20 characters long',
    ERROR_INPUT_PHONE: 'Veuillez spécifier un numéro de téléphone valide.',
    ERROR_INPUT_VALID_EMAIL: 'Veuillez entrer un email valide',
    ERROR_INPUT_FRISTNAME: 'Veuillez saisir le prénom.',
    ERROR_INPUT_LASTNAME: 'Veuillez saisir un nom de famille.',
    ERROR_INPUT_HOME_ADDRESS: 'Veuillez saisir l\'adresse postale.',
    ERROR_INPUT_CITY: 'Veuillez entrer la ville.',
    ERROR_INPUT_STATE: 'Veuillez saisir l\'état.',
    ERROR_INPUT_ZIPCODE: 'Veuillez saisir le code postal.',
    ERROR_INPUT_BIRTHDAY: 'Veuillez entrer la date de naissance.',
    ERROR_INPUT_NAME_OF_BANK: 'Veuillez saisir le nom de la banque.',
    ERROR_INPUT_NAME_OF_ACCOUNT: 'Veuillez entrer le nom du compte.',
    ERROR_INPUT_ROUTING_NUMBER: 'Veuillez saisir le numéro de routage.',
    ERROR_INPUT_ACCOUNT_NUMBER: 'Veuillez saisir le numéro de compte.',
    ERROR_INPUT_CHECK_NUMBER: 'Veuillez entrer le numéro de vérification.',
    ERROR_INPUT_SSN: 'Veuillez saisir le Numéro de Sécurité sociale.',
    ERROR_INPUT_COMPANY: 'Veuillez sélectionner une compagnie à activer.',
    ERROR_INPUT_LICENSE_PLATE: 'Veuillez choisir une plaque d\'immatriculation.',
    ERROR_INPUT_ZONE: 'Veuillez sélectionner une zone à activer.',
    ERROR_INPUT_SHIFT: 'Veuillez sélectionner un shift à activer.',
    ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS: 'Ne doit contenir que des nombres.',
    ERROR_SSN_ONLY_NUMBERS: 'Ne doit contenir que des nombres.',
    ERROR_SSN_LENGTH: 'Le SSN doit comporter au moins 4 caractères',
    ERROR_COMMISSION_NUMBERS: 'S\'il vous plait, entrez un nombre valide',
    INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE: 'Le chauffeur a été désactivé mais le compteur intégré ne peut pas être désactivé pour des raisons imprévues.',
    INFO_DEACTIVAED_SUCCESS: 'Les {0} chauffeurs sélectionnés ont bien été désactivés.',
    INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS: 'Les {0} chauffeurs sélectionnés ont été désactivés avec succès. {1} compteur (s) connecté (s) ont été désactivés.',
    INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE: 'Les {0} pilotes sélectionnés ont bien été désactivés. {1} compteur (s) connecté (s) ont été désactivés. {2} ne l\'ont pas été à cause de raisons imprévues.',
    INFO_ACTIVAED_SUCCESS: 'Les {0} chauffeurs sélectionnés ont bien été activés.',
    DRIVER_UPDATE_SUCCESS: 'Le Driver a bien été mis à jour.',
    DRIVER_CREATE_SUCCESS: 'Les informations sur le pilote ont été ajoutées avec succès',
    Expiry_Date: 'Date d\'expiration ',
    Case_number: 'Case number',
    Export_to_csv: 'Export to CSV',
    CONFIRM_EXPORT_TO_CSV: 'Are you sure you want to export all data as a .csv file?',
    Ride_sharing_tooltip: 'Le partage de course est une fonctionnalité avancée, veuillez contacter notre équipe de support pour avoir plus d’informations.'
  },
  corporate: {
    Add: 'Ajouter',
    Activate: 'Activer',
    Deactivate: 'Désactiver',
    Delete: 'Effacer',
    Company_Name: 'Nom de la société',
    Admin_Name: 'Nom de l\'administrateur',
    Username: 'Nom d\'utilisateur',
    Phone_Number: 'Numéro De Téléphone',
    Email: 'Email',
    Registered_From: 'Enregistré À Partir De',
    Registration_Date: 'Date D\'enregistrement',
    Status: 'Statut',
    Actions: 'Actions',
    Action: 'Action',
    Inactive: 'Inactif',
    Active: 'Actif',
    In_Review: 'En revue',
    Edit: 'Modifier',
    View: 'Voir',
    ADD_CORPORATE_CARD: 'AJOUTER UNE CARTE D\'ENTREPRISE',
    CONFIRM_DELETE_CARD: 'Etes vous sûr de vouloir effacer cette carte?',
    ALERT_SELECT_ACTIVATE: 'Veuillez sélectionner l\'entreprise à activer.',
    ALERT_SELECT_DEACTIVATE: 'Veuillez sélectionner l\'entreprise à désactiver.',
    ALERT_SELECT_DELETE: 'Veuillez sélectionner l\'entreprise à supprimer.',
    CONFIRM_DELETE: 'Voulez-vous supprimer cette entreprise?',
    CONFIRM_DELETE_USER: 'Voulez-vous supprimer cet utilisateur entreprise?',
    CONFIRM_DEACTIVATE_MULTI: 'Voulez-vous désactiver ces entreprises?',
    CONFIRM_ACTIVATE_MULTI: 'Voulez-vous activer ces entreprises?',
    CONFIRM_DELETE_MULTI: 'Voulez-vous supprimer ces entreprises?\n ** Seules les sociétés inactives peuvent être supprimées.',
    // add
    ERROR_INPUT_PHONE: 'Veuillez saisir le numéro de téléphone',
    ERROR_INPUT_VALIDPHONE: 'Veuillez spécifier un numéro de téléphone valide',
    ERROR_EXISTED_PHONE: 'Ce téléphone existe déjà sur le système. Veuillez en saisir un autre.',
    Account: 'Compte',
    Corp_traveler: 'Voyageur entreprise',
    Payment_methods: 'Moyens de paiement',
    Corp_operators: 'Opérateurs Corporate ',
    Pricing: 'Tarification',
    COMPANY_INFORMATION: 'INFORMATIONS SUR LA SOCIÉTÉ',
    Company_name: 'Nom de la société',
    Company_ID: 'Compagnie ID (for Corp Users signup)',
    Company_address: 'Adresse de la société',
    Invoice_email: 'Email de facture',
    Confirmation_email: 'Email de confirmation',
    ADMIN_ACCOUNT: 'COMPTE ADMIN',
    First_name: 'Prénom',
    Last_name: 'Nom',
    Commission: 'Commission',
    Required_fields: 'Champs requis',
    Cancel: 'Annuler',
    Save: 'Sauvegarder',
    Reset_password: 'Réinitialiser le mot de passe',
    Employee_Name: 'Nom De L\'employé',
    Created_Date: '	Créer Date',
    Add_corporate_card: 'Ajouter une carte d\'entreprise',
    Corp_Users: 'Utilisateurs Entreprise',
    Pricing_Default: 'Par défaut (même tarification que les autres passagers)',
    Pricing_Discount: 'Pourcentage de réduction (appliqué pour les passagers de la société)',
    Pricing_Add_different_rates: 'Ajouter des tarifs différents',
    ERROR_INPUT_COMPANYNAME: 'Veuillez saisir le nom de l\'entreprise.',
    ERROR_INPUT_USERNAME: 'Veuillez saisir le nom d\'utilisateur',
    ERROR_INPUT_FIRSTNAME: 'Veuillez saisir le prénom.',
    ERROR_INPUT_EMAIL: 'Veuillez saisir l\'email',
    ERROR_INPUT_VALID_EMAIL: 'Veuillez entrer un l\'email valide .',
    ERROR_INPUT_VALID_NUMBER: 'Veuillez entrer un nombre valide',
    ERROR_INPUT_VALID_RANGE_AMOUNT: 'Please enter a value between {0} and {1}.',
    INFO_ADD_CORPORATE_SUCCESS: 'Le compte {0} a bien été créé. Veuillez vérifier le mail {1} pour les détails de certificat',
    INFO_UPDATE_CORPORATE_SUCCESS: 'La société a bien été mise à jour.',
    INFO_UPDATE_PRICING_SUCCESS: 'Update pricing successfuly',
    COMFIRM_RESET_PASSWORD: 'Voulez-vous réinitialiser le mot de passe?',
    Traveler_signature: 'Signature du voyageur',
    Tracking_log: 'Journal de suivi',
    Rating: 'Évaluation',
    // Corp Users
    Email_address: 'Adresse e-mail',
    Assistant_email: 'Assistant email',
    Corp_PO: 'Corp PO',
    Cost_centre: 'Centre de coûts',
    Corp_division: 'Division d\'entreprise',
    Corp_id: 'Numéro d\'entreprise',
    Department: 'Département',
    Manager_email: 'Mail du directeur',
    Manager_name: 'Nom du gestionnaire',
    Corporate_card: 'Carte entreprise ',
    Direct_invoicing: 'Facturation directe',
    Corporate_Prepaid: 'Prépayé entreprise',
    Cash: 'Paiement à bord',
    Car_Type_ID: 'Identification Du Type De Véhicule',
    App_Display_Name: 'Nom De L\'affichage De L\'application',
    Normal_Fare: 'Tarif Normal',
    Flat_Fare: 'Forfait',
    Hourly_Daily_Rate: 'Taux Horaire',
    Assign_rate: 'Attribuez tarif',
    Zone: 'Zone',
    Rate: 'Tarif',
    Zone_Rate: 'Zone - Tarif',
    Please_select_rate: 'Veuillez sélectionner un tarif',
    Paid_by: 'Payé par',
    Enter_new_credit_card: 'Entrez une nouvelle carte de crédit',
    Top_up: 'Top up',
    TOP_UP: 'Réapprovisionnement',
    Topup_amount: 'Topup amount',
    Currency: 'Devise',
    ERROR_INPUT_AMOUNT: 'Veuillez entrer un montant valide',
    VAT_number: 'Numéro de TVA',
    Gender: 'Gender',
    Select_Gender: 'Select',
    Male: 'Male',
    Female: 'Female',
    Date_of_birth: 'Date of birth',
    Street_address: 'Street address',
    City: 'City',
    State: 'State',
    Zip_code: 'Zip code',
    Country: 'Country',
    adding_new_card: 'You are adding a new card to use service in %{zoneName} area',
    No_Support: 'New card adding is currently not supported in this area',
    Username: 'Username'
  },
  message: {
    Add: 'Ajouter',
    Delete: 'Effacer',
    Items_per_page: 'Eléments par page',
    Page: 'Page',
    Search_here: 'Rechercher ici',
    Shown_Active: '{0} - {1} de {2} Affiche',
    Date: 'Date',
    Subject: 'Sujet',
    To: 'À',
    Type: 'Type',
    Result: 'Résultat',
    Actions: 'Actions',
    New_message: 'Nouveau message',
    MESSAGE_DETAILS: 'MESSAGE DETAILS',
    All: 'Tout',
    Company: 'Compagnie',
    Active: 'Actif',
    In_Active: 'Inactif',
    In_Review: 'En revue',
    Send_to: 'Envoyer à',
    All_drivers: 'Tous les driver',
    All_customers: 'Tous les clients',
    Customized_driver: 'Chauffeur personnalisé',
    Customized_customer: 'Client personnalisé',
    Receiver_list: 'Liste de destinataires',
    Content: 'Contenu',
    Required_fields: 'Champs requis',
    Search_driver_name_driver_phone_number: 'Rechercher nom du chauffeur, numéro de téléphone du chauffeur',
    Search_customer_name_customer_phone_number: 'Rechercher le nom du client, le numéro de téléphone du client',
    Search_car_type: 'Search car type',
    Searching: 'Recherche...',
    No_results: 'Aucun résultat',
    Send: 'Envoyer',
    Cancel: 'Annuler',
    SMS_failed_Inbox_succeeded: 'L\'envoi de SMS a échoué, le message a été envoyé par inbox',
    Failed: 'Échoué',
    Succeeded: 'Réussi',
    CONFIRM_DELETE: 'Voulez-vous supprimer ce message?',
    CONFIRM_DELETE_MULTI: 'Voulez-vous supprimer ces messages?',
    ALERT_SELECT_DELETE: 'Veuillez sélectionner un message à supprimer',
    ERROR_INPUT_RECEIVERLIST: 'Veuillez choisir au moins un Driver pour envoyer un message',
    ERROR_INPUT_SUBJECT: 'Veuillez saisir le sujet',
    ERROR_INPUT_CONTENT: 'Veuillez saisir le contenu',
    CONFIRM_SEND_SUCCESS: 'Votre message a été envoyé. Cela peut prendre plusieurs minutes. Veuillez patienter pendant que le système traite votre demande.',
    Load_message_and_sms_fail: 'Des erreurs sont survenues lors du chargement des détails de messages et SMS',
    Update_message_and_sms_fail: 'Des erreurs sont survenues lors de la mise à jour des détails de messages et SMS',
    Update_message_and_sms_success: 'Les détails des messages et des SMS ont bien été mis à jour',
    Driver_List: 'Liste de chauffeurs',
    Car_Type_List: 'Liste de catégories de véhicule',
    Search_vehicle_type_name: 'Search Vehicle Type Name',
    ALERT_PROCESSING_DELETE: 'Your request is being processed. This could take several seconds',
  },
  fleetInfo: {
    Fleet_name: 'Nom de la flotte',
    Phone_number: 'Numéro de téléphone',
    Email_address: 'Adresse e-mail',
    Country: 'Pays',
    Time_zone: 'Fuseau horaire',
    Address: 'Adresse',
    Website: 'Site Internet',
    Currency: 'Devise',
    Distance_unit: 'Unité de distance'
  },
  generalSetting: {
    AM: 'AM',
    PM: 'PM',
    RUSH_HOUR: 'Heure de pointe',
    Name: 'Nom',
    Date: 'Date',
    Mon: 'Lun',
    Tue: 'Mar',
    Wed: 'Mer',
    Thu: 'Jeu',
    Fri: 'Ven',
    Sat: 'Sam',
    Sun: 'Dim',
    Time_range: 'Intervalle de temps',
    From: 'De',
    To: 'À',
    Rush_hour_time_calculation: 'Calcul d\'heure de pointe',
    Rush_hour_surcharge: 'Heure de pointe',
    Required_fields: 'Champs requis',
    Save: 'Sauvegarder',
    Cancel: 'Annulé',
    MDISPATCHER_CANCELLATION: 'Annulation de réservation web/passagers/mDispatcher',
    Book_now: 'Réserver maintenant',
    Duration: 'Durée',
    Times: 'Fois',
    Book_later: 'Réserver plus tard',
    MDISPATCHER_NO_SHOW: 'Non présentation',
    AIRPORT_FEE: 'Frais Aéroport',
    From_airport: 'De l\'aéroport',
    To_airport: 'Vers l\'aéroport',
    MEET_DRIVER: 'Rencontrez Driver',
    On_curb: 'Sur le trottoir',
    Meet_Greet: 'Accueil personnalisé',
    Add: 'Ajouter',
    Edit: 'Edit',
    DELETE_SERVICE: 'Delete service',
    DELETE_CONFIRM: 'Do you want to delete this item?',
    HEAVY_TRAFFIC: 'Circulation dense',
    Heavy_traffic_surcharge: 'Supplément pour circulation dense',
    TOLL_FEE: 'Frais de péage',
    OTHER_FEES: 'Autres frais',
    Can_edit: 'Capable de modifier',
    Can_add_note: 'Capable de Ajouter une note',
    Other_fees_amount: 'Montant des autres frais',
    TAX: 'Taxe',
    Tax_surcharge: 'Supplément de taxe',
    TIP: 'TIP',
    Default_tip: 'Pourboire par défaut',
    RESERVATION_REMINDER: 'Rappel de réservation',
    Driver_app_SMS_alert_before: 'Application Chauffeur - SMS & alerte avant',
    Pax_app_alert_before: 'Application utilisateur - alerter avant',
    Sound_notification: 'Notification sonore',
    Advance_information: 'Information avancée du voyageur d\'entreprise',
    Load_general_fail: 'Des erreurs sont survenues lors du chargement des réglages généraux',
    Update_general_success: 'Les réglages généraux ont bien été mis à jour',
    Update_general_fail: 'Des erreurs sont survenues en mettant à jour les réglages généraux',
    Time_over_lap: 'Les intervalles de temps ne doivent pas se chevaucher les uns avec les autres',
    Allow_passenger_to_register_as_corp_traveler: 'Permettre au passager de s\'inscrire comme voyageur de corp',
    Not_Allow_Make_Booking_Without_Assign_Fare: 'Do not allow pax to make booking if basic fare = 0.00',
    Disable_Direct_Invoicing_Receipt_Email: 'Désactiver l\'e-mail de réception de facture directe',
    Other_settings: 'Autres réglages',
    Additional_services: 'Additional Services',
    Edit_additional_services: 'Edit Additional Services',
    Service_Fee_By_Currencies: 'Service fee',
    Service_type: 'Service Type',
    Optional: 'Optional',
    Compulsory: 'Compulsory',
    Vehicle_type: 'Vehicle Type',
    Last_Update: 'Dernière mise à jour',
    Adding_additional_service_success: 'Add Service success',
    Update_additional_service_success: 'Update service success',
    Adding_additional_service_fail: 'Add Service fail',
    Update_additional_service_fail: 'Update service fail',
    Operator_assign_error: 'Operator assign errors',
    Create_surcharge_success: 'Create surcharge success',
    Update_surcharge_success: 'Update surcharge success',
    Delete_surcharge_success: 'Delete surcharge success',
    Create_surcharge_fail: 'Create surcharge failed',
    Update_surcharge_fail: 'Update surcharge failed',
    Delete_surcharge_fail: 'Delete surcharge failed',
    Get_surcharge_detail_fail: 'Get surcharge detail failed',
    Repeat: 'Répéter',
    DayOfWeek: 'Jour de la semaine',
    SingleDay: 'Une seule fois',
    Yearly: 'Tous les ans',
    Backup_phone_number: 'Backup phone number',
    Backup_phone_number_hint: 'Direct to this number when driver and passenger are out of operation zones',
    Apply_countdown_clock: 'Apply countdown clock',
    No_show_duration_tooltip: 'Configurez l’intervalle de durée entre le moment où le chauffeur clique sur « Arrivé » et l’affichage du bouton « No Show »',
    Invalid_period_of_time: 'Période de temps invalide',
    hide_Provider_Filter_From_Corporate_Board: 'Hide provider filter from corporate board',
    customer_email_is_optional: 'Customer\'s email is optional on passenger app',
    disable_hourly_rate: 'Disable hourly rate when both hourly and flat rate are available'
  },
  user: {
    Show_per_page: 'Eléments par page',
    Page: 'Page',
    Search_here: 'Rechercher ici',
    show_pages: '{0} - {1} de {2} Affiche',
    Username: 'Nom D\'utilisateur',
    Name: 'Nom',
    Phone_number: 'Numéro De Téléphone',
    Email: 'Email',
    Permission: 'Permission',
    Status: 'Statut',
    Actions: 'Actions',
    Active: 'Actif',
    Inactive: 'Inactif',
    Edit: 'Modifier',
    Activate: 'Activer',
    Deactivate: 'Désactiver',
    Delete: 'Effacer',
    Add: 'Ajouter',
    ADD_USER: 'AJOUTER UN UTILISATEUR',
    First_name: 'Prénom',
    Last_name: 'Nom',
    AgendId: 'Utilisateur #',
    Email_address: 'Adresse e-mail ',
    Address: 'Adresse',
    Required_fields: 'Champs requis ',
    Save: 'Sauvegarder',
    Cancel: 'Annuler',
    EDIT_USER: 'MODIFIER L\'UTILISATEUR',
    Yes: 'Oui',
    DELETE_USER: 'EFFACER L\'UTILISATEUR',
    DETAIL_USER: 'INFOS UTILISATEUR',
    DELETE_CONFIRM: 'Êtes-vous sûr de supprimer l\'utilisateur?',
    Update_user_fail: 'Des erreurs sont survenues lors de la mise à jour de l\'utilisateur',
    Update_user_success: 'L\'utilisateur a bien été mis à jour',
    Create_user_fail: 'Des erreurs sont survenues lors de la création de l\'utilisateur',
    Create_user_success: 'Le compte {0} a bien été créé. Veuillez vérifier le mail {1} pour les détails de certificat',
    Load_user_fail: 'Des erreurs sont survenues lors du chargement de l\'utilisateur',
    Update_user_status_fail: 'Des erreurs sont survenues lors de la mise à jour du statut de l\'utilisateur',
    Delete_user_fail: 'Des erreurs sont survenues lors de la suppression de l\'utilisateur',
    Delete_user_success: 'L\'utilisateur a bien été supprimé',
    CONFIRM_RESET_PASSWORD: 'Voulez-vous réinitialiser le mot de passe?',
    RESET_PASSWORD: 'Voulez-vous réinitialiser le mot de passe?',
    Reset_password: ' Réinitialiser le mot de passe',
    Select_permission: 'Sélectionnez Permission ',
    Reset_password_success: 'Réinitialisation de mot de passe effectuée'
  },
  report: {
    query: {
      errorMessage: {
        bookFrom: 'Veuillez sélectionner le moyen de réservation',
        paymentMethod: 'Veuillez sélectionner un mode de paiement',
        bookingType: 'Veuillez sélectionner un type de réservation',
        ratingType: 'Veuillez sélectionner un type de tarification',
        stars: 'Veuillez sélectionner un type de tarification',
        transactionType: 'Veuillez sélectionner un type de transaction',
        serviceType: 'Veuillez sélectionner un type de service',
        rideService: 'Please select ride service',
        invalidEmail: 'Veuillez spécifier un numéro de email valide'
      },
      search: {
        bookingDetails: 'Rechercher: Numéro de réservation, Opérateur, Téléphone client, Nom du client, Nom du chauffeur, ID/ Permis du chauffeur, Plaque d\'immatriculation, Zone de file, Note',
        financial: 'Rechercher: Nom du chauffeur, Téléphone',
        financialDriver: 'Rechercher: Numéro de réservation, Nom du chauffeur, ID/ Permis du chauffeur',
        carActivity: 'Rechercher: Plaque d\'immatriculation',
        affiliation: 'Rechercher: Numéro de réservation',
        prepaidTopUp: 'Rechercher: Nom de la compagnie, ID d\'approvisionnement, ID transaction',
        operatorLogs: 'Rechercher: Nom de l\'Opérateur, nom d\'utilisateur',
        corporate: 'Rechercher: Numéro de réservation, Nom du chauffeur, Voyageur d\'entreprise, Référence du client, Code de frais',
        bookingLogs: 'Rechercher: Numéro de réservation, Nom du client, Nom du chauffeur, ID/ Permis du chauffeur',
        rating: 'Rechercher: Numéro de réservation, Numéro du client, Nom du client',
        mDispatcher: 'Rechercher: Numéro de réservation, Nom du mDispatcher',
        totalAdjustment: 'Rechercher: Numéro de réservation',
        incidentCancellation: 'Rechercher: Numéro de réservation, Numéro du client, Nom du client',
        revenueDriver: 'Rechercher: Nom du chauffeur',
        dailyDriver: 'Rechercher: Numéro du chauffeur, Nom du chauffeur, ID du chauffeur/Permis',
        dailyCar: 'Rechercher: Plaque d\'immatriculation, Permission',
        dailyDriverCar: 'Rechercher: Numéro du chauffeur, Nom du chauffeur, ID du chauffeur/Permis, Plaque d\'immatriculation',
        driverLoginStatus: 'Rechercher: Numéro du chauffeur, Nom du chauffeur, ID du chauffeur/Permis, Plaque d\'immatriculation',
        bookingSummary: 'Rechercher: Nom de l\'Opérateur, nom d\'utilisateur, numéro de réservation',
        creditWallet: 'Rechercher: Nom de la compagnie, Driver name, Booking ID, Operator',
        settlement: 'Rechercher: Nom du chauffeur, ID/Permis du chauffeur',
        driverRating: 'Rechercher: Numéro de réservation, Numéro du client, Nom du client'
      },
      common: {
        all: 'Tous',
      },
      today: 'Aujourd\'hui',
      thisMonth: 'Ce mois-ci',
      lastMonth: 'Le mois dernier',
      past30Days: 'Depuis 30 jours',
      thisYear: 'Cette année',
      custom: 'Personnalisé',
      fromDate: 'A partir de la date',
      toDate: 'Jusqu\'à',
      driver: 'Pilotes',
      company: 'Compagnie',
      farmType: 'Type de ferme',
      carLicensePlate: 'Plaque d\'immatriculation du véhicule',
      bookingService: 'Service de réservation',
      currency: 'Devise',
      bookingFrom: 'Réservé de',
      paymentMethod: 'Mode de paiement',
      paymentStatus: 'Payment status',
      ratingType: 'Rating type',
      stars: 'Rating',
      bookingType: 'Type de réservation',
      mDispatcherType: 'Type mDispatcher',
      mDispatcher: 'Dispatcher name',
      campaign: 'Campagne',
      promocode: 'Code promo',
      canceledBy: 'Canceled by',
      timezone: 'Fuseau horaire',
      month: 'Mois',
      year: 'Year',
      view: 'Voir',
      action: 'Action',
      status: 'Statut',
      module: 'Module',
      driverType: 'Type',
      vehicleType: 'Type de véhicule',
      serviceType: 'Type de service',
      expireDate: 'Expiry date',
      rideSharing: 'Partage de course',
      rideService: 'Service de transport',
      rideServiceItem: {
        all: 'Tous',
        regulation: 'Règlement',
        rideSharing: 'Partage de course'
      },
      actionItem: {
        all: 'Tous',
        login: 'S\'identifier',
        logout: 'Se déconnecter',
        bookIn: 'Disponible',
        bookOff: 'Indisponible',
        deny: 'Refuser',
        ignore: 'Ignorer',
        accept: 'Accepter',
        startTrip: 'En route',
        updateBooking: 'Mettre à jour',
        arrive: 'Arrivé',
        pickup: 'Pris en charge',
        drop: 'Déposer',
        complete: 'Terminer',
        noShow: 'Non présentation',
        rqJob: 'En dispatch',
        cancel: 'Annuler',
      },
      loginStatusItem: {
        all: 'Tous',
        online: 'Connexion',
        offline: 'Déconnexion'
      },
      payoutType: 'Payout type',
      affiliationPayoutTypeItem: {
        auto: 'Auto payout',
        manual: 'Manual payout',
      },
      affiliationSettlementItem: {
        payable: 'Payable',
        receivable: 'Receivable',
      },
      affiliationPaymentMethod: {
        online: 'Online',
        directInvoicing: 'Direct invoicing',
      },
      farmTypeItem: {
        all: 'Tous',
        farmIn: 'Ferme en',
        farmOut: 'Ferme hors',
        affiliationOwner: 'Propriétaire de l\'affiliation',
        farmInAndAffiliationOwner: 'Ferme en & propriétaire de l\'affiliationr',
        farmOutAndAffiliationOwner: 'Ferme hors & propriétaire de l\'affiliation',
      },
      settlementDriverTypeItem: {
        all: 'Tous les Driver',
        individual: 'Conducteur individuel'
      },
      paymentMethodItem: {
        B2BTerminal: 'B2BTerminal',
        webBooking: 'Web booking',
        pendingPayment: 'Pending payment',
        all: 'Tous',
        cash: 'Paiement à bord',
        personalCard: 'Carte bleue',
        corporateCard: 'Carte d\'entreprise',
        qrCodePayment: 'Paiement par code QR',
        directBilling: 'Facturation Entreprise',
        externalCard: 'Carte externe',
        mDispatcherCard: 'Carte YoConcierge',
        corporatePrepaid: 'Prépayé entreprise',
        canceled: 'Annulé',
        noShow: 'Non présentation',
        incident: 'Incident',
        applePay: 'Apple pay'
      },
      paymentStatusItem: {
        all: 'All',
        fullPayment: 'Full payment',
        partialPayment: 'Partial payment',
        pendingPayment: 'Pending payment'
      },
      companySettlementTypeItem: {
        all: 'All',
        fleetOweCompany: 'Fleet owe company',
        companyOweFleet: 'Company owe fleet'
      },
      bookingFromItem: {
        all: 'Tous',
        cc: 'Centre de commandes',
        paxApp: 'Pplication passager',
        kiosk: 'Kioske',
        webBooking: 'Réservation en ligne',
        mDispatcher: 'MDispatcher',
        carHailing: 'Héler un véhicule',
      },
      bookingTypeItem: {
        batchDelivery: 'Batch Delivery',
        all: 'Tous',
        now: 'Maintenant',
        reservation: 'Réservation',
      },
      bookingServiceItem: {
        all: 'Tous',
        homeFleet: 'Réservation domestique',
        localBooking: 'Ma flotte',
        farmIn: 'Ferme en',
        farmInAndAffiliationOwner: 'Ferme en & propriétaire de l\'affiliation',
        provideService: 'Ferme en'
      },
      ratingTypeItem: {
        all: 'All',
        like: 'Satisfait',
        dislike: 'Non satisfait'
      },
      starsItem: {
        all: 'Tous',
        star1: '1 Star',
        star2: '2 Stars',
        star3: '3 Stars',
        star4: '4 Stars',
        star5: '5 Stars'
      },
      incidentCanceledTypes: {
        all: 'All',
        incident: 'Incident',
        canceled: 'Canceled'
      },
      canceledByItem: {
        all: 'All',
        CorpAD: 'Corporate Admin',
        passenger: 'Passager',
        CC: 'CC',
        mDispatcher: 'MDispatcher',
        Partner: 'Partenaire',
        webBooking: 'Réservation en ligne',
        timeout: 'Timeout'
      },
      bookingSummaryActionItem: {
        all: 'All',
        create: 'Créer',
        update: 'Mettre à jour',
        cancel: 'Annuler',
        complete: 'Terminer',
      },
      transactionTypeItem: {
        bankAccount: 'Transferred to bank account',
        creditWallet: 'Transferred to credit wallet',
        netEarning: 'Net Earning',
        referralEarning: 'Referral Earning',
        all: 'All',
        topUp: 'Recharger',
        editBalance: 'Edit balance',
        bookingDeduction: 'Booking deduction',
      },
      moduleItem: {
        all: 'All',
        bookings: 'Bookings',
        customers: 'Customers',
        dispatch: 'Dispatch',
        general: 'General',
        promotion: 'Promotion',
        rate: 'Rate',
        car: 'Car',
        city: 'City',
        company: 'Compagnie',
        drivers: 'Pilotes',
        driverSettlement: 'Règlement du conducteur',
        login: 'Login',
        logout: 'Logout',
        mDispatcher: 'mDispatcher',
        mDispatcherType: 'Type mDispatcher',
        operation: 'Operation',
        partners: 'Partners',
        permission: 'Permission',
        queuingArea: 'Zone de file d\'attente',
        report: 'Report',
        shift: 'Shift',
        user: 'User',
        voiceSms: 'Voice & SMS',
      },
      serviceTypeItem: {
        all: 'Tous',
        oneWay: 'One way',
        fromAirport: 'From airport',
        toAirport: 'To airport',
        hourly: 'Hourly/daily',
        roundTrip: 'Round trip',
        fromAirportHourlyDaily: 'From airport - hourly/daily',
        fromAirportRoundTrip: 'From airport - round trip',
        toAirportHourlyDaily: 'To airport - hourly/daily',
        toAirportRoundTrip: 'To airport - round trip'
      }
    },
    error: {
      23005:
        'For system performance you may only view reports in %{interval} %{unitLabel}, please adjust your date-range',
    },
    result: {
      noResultFound: 'Aucun résultat trouvé',
      page: 'Page',
      itemsPerPage: 'Eléments par page',
      showPages: 'Affiche %{from} - %{to} de %{total}',
      exportToExcel: 'Exporter vers Excel',
      pay: 'Payé',
      bookingDetails: {
        transactionId: 'Numéro de transaction',
        bookingId: 'Réservation #',
        bookFrom: 'Réservé de',
        bookingService: 'Service de réservation',
        bookingType: 'Type de réservation',
        serviceType: 'Type de service',
        operator: 'Opérateur',
        customerPhone: 'Numéro de téléphone du passager',
        customerName: 'Nom du passager',
        corpDivision: 'Division',
        corpDept: 'Département',
        rideSharing: 'Partage de course',
        queueName: 'Zone de file d\'attente',
        bookingTime: 'Heure de réservation',
        pickupTime: 'Heure de prise en charge',
        dispatchTime: 'Temps de dispatch',
        onBoardTime: 'Heure à bord',
        droppedOffTime: 'Heure d\'arrivée',
        onBoardDuration: 'Durée à bord',
        dispatch: 'Dispatch #',
        completedTime: 'Heure de fin',
        driverName: 'Nom du Driver',
        driverIdPermit: 'ID# / National IC#',
        driverLicenseNumber: 'Numéro de permis de conduire',
        companyName: 'Compagnie',
        carType: 'Type de véhicule',
        licensePlate: 'Plaque d\'immatriculation',
        pickupLocation: 'Lieu de prise en charge',
        geoPickup: 'Lat/long de l\'emplacement de prise en charge',
        destination: 'Destination',
        geoDestination: 'Lat/long de l\'emplacement de prise en charge',
        pickupFrom: 'Emplacement de prise en charge de',
        distanceTour: 'Distance Passager A Bord',
        notes: 'Remarques',
        basicFareCalculator: 'Calculateur du prix de base',
        basicFare: 'Tarif de base',
        editFare: 'Editer le prix de base',
        oldBasicFare: 'Ancien prix de base',
        airportFee: 'Frais d\'aéroport',
        meetDriver: 'Meet driver',
        rushHour: 'Heure de pointe',
        heavyTraffic: 'Circulation dense',
        tollFee: 'Frais de péage',
        otherFees: 'Autres frais',
        subtotal: 'Sous-total',
        techFee: 'Frais de Technique',
        serviceFee: 'Add\'l services',
        bookingFee: 'Frais de réservation',
        salesTax: 'TVA',
        tipAmt: 'Montant du pourboire',
        promoCode: 'Code promo',
        promoAmt: 'Montant de la promo',
        requestedTotalAmount: 'Requested total amount',
        total: 'Montant total',
        actualReceived: 'Actual received',
        differenceAmt: 'Difference (received)',
        customerDebt: 'Customer Debt',
        markupDifference: 'Markup difference',
        requestedPaymentMethod: 'Mode de paiement demandé',
        paidBy: 'Payé par',
        instructions: 'Instructions',
        additionalServiceName: 'Name',
        additionalServiceValue: 'Value',
        sendReceiptHeader: '',
        sendReceipt: 'Envoyer facture',
        send: 'Envoyer',
        cancel: 'Annuler',
        sentReceiptSuccess: 'La facture a bien été envoyée',
        sentReceiptFailed: 'La facture ne peut pas être envoyée',
        coRider: 'Co-rider',
        coriderName: 'Name',
        stopAddress: 'Additional Destination',
        stopGeo: 'Lat/long'
      },
      financial: {
        driverName: 'Nom du Driver',
        driverPhone: 'Numéro de téléphone',
        driverUserName: 'Nom d\'utilisateur',
        companyName: 'Compagnie',
        bookingList: 'Liste de réservations',
        totalFare: 'Prix total sans la promo',
        netEarning: 'Résultat net',
      },
      financialDriver: {
        payoutType: 'Payout type',
        date: 'Date',
        bookingId: 'Réservation #',
        paidBy: 'Payé par',
        bookFrom: 'Réservé de',
        bookingType: 'Type de réservation',
        companyName: 'Compagnie',
        driverName: 'Pilotes',
        driverIdPermit: 'CNI du chauffeur / permis de conduire',
        totalFare: 'Prix total sans la promo',
        subtotal: 'Sous-total',
        transactionFee: 'Frais de transaction',
        techFee: 'Frais de Technique',
        salesTax: 'TVA',
        tipAmt: 'Montant du pourboire',
        bookingFee: 'Frais de réservation',
        commission: 'Commission',
        deduction: 'Déduction',
        promoAmt: 'Montant de la promo',
        ridePayment: 'Paiement de la course',
        grossEarning: 'Revenus bruts',
        driverDeduction: 'Driver deduction',
        netEarning: 'Résultat net',
      },
      financialCompany: {
        companyName: 'Compagnie',
        homeFleetNetEarning: 'Gain net total de la flotte',
        affiliateNetEarning: 'Gain Net de l\'Affilié',
        totalNetEarning: 'Gain net total',
        status: 'Statut',
        companyAmount: 'Company profit',
      },
      companySettlement: {
        companyName: 'Company',
        pendingSettlement: 'Pending settlement',
        viewDetails: 'View details',
        settlementType: 'Type',
      },
      companySettlementDetails: {
        bookId: "Booking ID",
        dateTime: "Date time",
        originalPaymentStatus: "Original payment status",
        subTotal: "Subtotal",
        fleetAmount: "Fleet amount",
        driverAmount: "Driver amount",
        companyAmount: "Company amount",
        companyOweFleet: "Company owe fleet",
        fleetOweCompany: "Fleet owe company",
        notes: "Notes"
      },
      promotion: {
        date: 'Date',
        promoCode: 'Code promo',
        users: '# Utilisateurs',
        uses: '# Utilisations',
        totalPromoAmount: 'Total du montant des promos',
        totalReceipt: 'Total du montant des factures with promo excluded'
      },
      carActivity: {
        plateNumber: 'Plaque d\'immatriculation du véhicule',
        vehicleType: 'Type de voiture',
        companyName: 'Compagnie',
        day1st: '1er',
        day2nd: '2',
        day3rd: '3',
        day4th: '4',
        day5th: '5',
        day6th: '6',
        day7th: '7',
        day8th: '8',
        day9th: '9',
        day10th: '10',
        day11th: '11',
        day12th: '12',
        day13th: '13',
        day14th: '14',
        day15th: '15',
        day16th: '16',
        day17th: '17',
        day18th: '18',
        day19th: '19',
        day20th: '20',
        day21st: '21',
        day22nd: '22',
        day23rd: '23',
        day24th: '24',
        day25th: '25',
        day26th: '26',
        day27th: '27',
        day28th: '28',
        day29th: '29',
        day30th: '30',
        day31st: '31',
        operationDays: 'Jours travaillés',
        activeCars: 'Total active car:'
      },
      carActivityLogsTitle: 'Logs',
      carActivityLogs: {
        licensePlate: 'Plaque d\'immatriculation du véhicule',
        companyName: 'Compagnie',
        bookingId: 'Réservation #',
        driverName: 'Nom du Driver',
        driverIDNumber: 'ID du chauffeur',
        date: 'Heure d\'acceptation',
        bookFrom: 'Réservé de',
      },
      affiliation: {
        date: 'Date',
        bookingId: 'Réservation #',
        bookingType: 'Type de réservation',
        homeFleet: 'Ma flotte',
        providerFleet: 'Fournisseur de la flotte',
        driver: 'Pilotes',
        passenger: 'Passager',
        paidBy: 'Payé par',

        airportFee: 'Frais Aéroport',
        meetDriver: 'Rencontre avec le chauffeur',
        rushHour: 'Heure de pointe',
        tollFee: 'Frais de péage',
        heavyTraffic: 'Circulation dense',
        otherFees: 'Autres frais',

        provider: 'Provider',
        basicFare: 'Tarif de base',
        subtotal: 'Sous-total',
        techFee: 'Frais de Technique',
        bookingFee: 'Frais de réservation',
        tax: 'Taxe',
        tip: 'Pourboire',
        totalFare: 'Total fare',
        payoutInLocalCurrency: 'Payout in local currency',
        payoutInUSD: 'Payout in USD',
        fleetCommInUSD: 'Fleet comm in USD',
        bookingFeeInUSD: 'Booking fee in USD',
        totalPayoutInUSD: 'Total payout in USD',
      },
      prepaidTopUp: {
        date: 'Date & heure',
        topUpId: 'Numéro de réapprovisionnement',
        transactionId: 'Numéro de transaction',
        name: 'Nom',
        company: 'Compagnie',
        paidBy: 'Payé par',
        amount: 'Montant'
      },
      operatorLogs: {
        date: 'Date',
        operator: 'Opérateur',
        username: 'Nom d\'utilisateur',
        module: 'Module',
        description: 'Description'
      },
      corporate: {
        date: 'Date',
        bookingId: 'Réservation #',
        corporateName: 'Compagnie',
        corpTraveler: 'Voyageur entreprise',
        travelerSignature: 'Signature du voyageur',
        corpId: 'Numéro d\'entreprise',
        corpDivision: 'Division d\'entreprise',
        costCentre: 'Centre de coûts',
        corpPO: 'Corp PO',
        managerName: 'Nom du gestionnaire',
        managerEmail: 'Mail du directeur',
        title: 'Titre',
        department: 'Département',
        clientCaseMatter: 'Référence du client',
        chargeCode: 'Charge code / Trip code',
        driverName: 'Pilotes',
        pickupLocation: 'Lieu de prise en charge',
        destination: 'Destination',
        total: 'Montant total',
        subtotal: 'Sous-total',
        commission: 'Commission',
        paidBy: 'Payé par',
        statusDisplay: 'Statut',
      },
      bookingLogs: {
        bookingId: 'Réservation #',
        bookingService: 'Service de réservation',
        bookingType: 'Type de réservation',
        driverName: 'Pilotes',
        driverIdPermit: 'CNI du chauffeur / permis de conduire',
        customerName: 'Passager',
        pickupTime: 'Heure de prise en charge',
        onBoardTime: 'Heure à bord',
        droppedOffTime: 'Heure d\'arrivée',
        pickupLocation: 'Lieu de prise en charge',
        destination: 'Destination',
        dispatchHistory: 'Historique du dispatch',
        distanceTour: 'Distance',
        duration: 'Durée',
        paidBy: 'Payé par',
        bookFrom: 'Réservé de',
        map: 'Carte',
        showDispatchHistory: 'Montrer',
        showMap: 'Montrer',
      },
      rating: {
        date: 'Date',
        bookingId: 'Réservation #',
        driverName: 'Pilotes',
        driverIdPermit: 'CNI du chauffeur / permis de conduire',
        customerName: 'Nom du passage',
        customerPhone: 'Numéro de téléphone du passager',
        like: 'Satisfait',
        dislike: 'Non satisfait',
        notes: 'Commentaire',
        stars: 'Évaluation'
      },
      mDispatcher: {
        date: 'Date',
        bookingId: 'Réservation #',
        mDispatcherName: 'Dispatcher name',
        mDispatcherType: 'Type mDispatcher',
        subtotal: 'Sous-total',
        commission: 'Commission',
        paidBy: 'Payé par',
      },
      totalAdjustment: {
        date: 'Date',
        bookingId: 'Réservation #',
        oldAmount: 'Ancien montant',
        type: 'Type',
        markupDifference: 'Markup difference',
        newAmount: 'Nouveau montant',
        username: 'Nom d\'utilisateur',
        reason: 'Raison'
      },
      incidentCancellation: {
        date: 'Date',
        bookingId: 'Réservation #',
        customerName: 'Nom du passage',
        customerPhone: 'Numéro de téléphone du passager',
        bookFrom: 'Réservé de',
        canceledBy: 'Annulé par',
        type: 'Type',
        reason: 'Raison',
        canceller: 'Annulé par'
      },
      revenueFleet: {
        date: 'Date',
        fareRevenue: 'Tarif de revenu',
        trip: '# Course',
        avgFareTrip: 'Tarif Moyen/ course',
        drivers: '# Pilotes',
        avgTripPerDriver: 'Course moyenne/ Driver',
        dispatcher: '# Dispatcher',
      },
      revenueDriver: {
        date: 'Date',
        driverName: 'Pilotes',
        fareRevenue: 'Tarif de revenu',
        trip: '# Course',
        avgFareTrip: 'Tarif Moyen/ course'
      },
      revenueMonthly: {
        month: 'Mois',
        trip: 'Trajets terminés',
        subtotal: 'Sous-total',
        tipAmt: 'Pourboire',
        techFee: 'Frais de Technique',
        salesTax: 'Taxe',
        partnerCommission: 'Commission du partenaire/ ODD',
        totalFare: 'Prix total sans la promo',
        netEarning: 'Résultat net',
      },
      dailyDriver: {
        driverName: 'Nom du Driver',
        driverIDPermit: 'CNI du chauffeur / permis de conduire',
        driverPhone: 'Numéro de téléphone',

        workingHoursOnline: 'En ligne (h)',
        workingHoursAvailable: 'Disponible (h)',
        workingHoursNotAvailable: 'Non disponible (h)',
        workingHoursPob: 'Passager A Bord (h)',
        workingHoursAccepted: 'Accepté',
        workingHoursReject: 'Refuser',
        workingHoursIgnore: 'Ignorer',
        workingHoursCancel: 'Annuler',

        completedTripsTotal: 'Total',
        completedTripsCc: 'Du Centre de Commandes',

        completedTripsCarHailing: 'Hélé dans la rue',
        completedTripsStreetSharing: 'From Street Sharing',
        completedTripsPaxApp: 'De l\'application client',
        completedTripsWebBooking: 'De la réservation web',
        completedTripsMDispatcher: 'De mDispatcher',
        completedTripsKiosk: 'Depuis le Kioske',
        completedTripsApi: 'Depuis l\'API',

        ccActivityDispatchedTrips: 'Trajets dispatchés',
        ccActivityAccepted: 'Accepté',
        ccActivityReject: 'Refuser',
        ccActivityIgnore: 'Ignorer',
        ccActivityCancelByPassenger: 'Annulé par passager',
        ccActivityCancelByDriver: 'Annulé par le chauffeur',
        ccActivityCancelByCc: 'Annulé par CC',
        ccActivityCancelByAPI: 'Canceled by API ',
        ccActivityNoShow: 'Non présentation',
        fareSummaryTotalCash: 'Paiement à bord',
        fareSummaryTotalCreditCard: 'Carte de crédit',
        fareSummaryTotalExternalCard: 'Carte externe',
        fareSummaryTotal: 'Total',

        ratingNotes: 'Nombre de notes',
        ratingDislike: 'Nombre de "je n\'aime pas"',
        ratingLike: 'Nombre de "j\'aime"',

        fareDetailsBasicFare: 'Tarif de base',
        fareDetailsAirportFee: 'Frais d\'aéroport',
        fareDetailsRushHour: 'Heure de pointe',
        fareDetailsHeavyTraffic: 'Heavy traffic',
        fareDetailsOtherFees: 'Autres frais',
        fareDetailsSubTotal: 'Sous-total',
        fareDetailsTechFee: 'Frais de Technique',
        fareDetailsBookingFee: 'Frais de réservation',
        fareDetailsSalesTax: 'Taxe',
        fareDetailsTip: 'Montant du pourboire',
        fareDetailsPromoAmount: 'Montant de la prom',
        fareDetailsTotalAmt: 'Montant total',
      },
      dailyCar: {
        carInfo: 'Infos sur la voiture',
        fareDetails: 'Détail du tarif',
        completedTrips: 'Trajets terminés',

        carInfoCompanyName: 'Compagnie',
        carInfoPermission: 'Autorisation',
        carInfoVehiclePlateNumber: 'Plaque d\'immatriculation',
        carInfoVhcId: 'Voiture #',
        carInfoCarType: 'Type de voiture',
        completedTripsTotal: 'Total',
        completedTripsCc: 'Du Centre de Commandes',
        completedTripsCarHailing: 'Hélé dans la rue',
        completedTripsStreetSharing: 'From Street Sharing',
        completedTripsPaxApp: 'De l\'application client',
        completedTripsWebBooking: 'De la réservation web',
        completedTripsMDispatcher: 'De mDispatcher',
        completedTripsKiosk: 'Depuis le Kioske',
        completedTripsAPI: 'Depuis l\'API',
        fareDetailsBasicFare: 'Tarif de base',
        fareDetailsAirportFee: 'Frais d\'aéroport',
        fareDetailsMeetDriver: 'Rencontre avec le chauffeur',
        fareDetailsRushHour: 'Heure de pointe',
        fareDetailsHeavyTraffic: 'Circulation dense',
        fareDetailsTollFee: 'Frais de péage',
        fareDetailsOtherFees: 'Autres frais',
        fareDetailsSubTotal: 'Sous-total',
        fareDetailsTechFee: 'Frais de Technique',
        fareDetailsBookingFee: 'Frais de réservation',
        fareDetailsSalesTax: 'Taxe',
        fareDetailsTip: 'Montant du pourboire',
        fareDetailsPromoAmount: 'Montant de la promo',
        fareDetailsTotalAmt: 'Montant total',
      },
      dailyDriverCar: {
        driverInfo: 'Informations du pilote',
        driverName: 'Nom du Driver',
        driverIDPermit: 'CNI du chauffeur / permis de conduire',
        driverPhone: 'Numéro de téléphone',
        companyName: 'Compagnie',
        actionInfo: 'Actions',
        date: 'Date',
        action: 'Action',
        bookingId: 'Réservation #',
        carInfo: 'Infos sur la voiture',
        permission: 'Autorisation',
        licensePlate: 'Plaque d\'immatriculation',
        carId: 'Voiture #',
        carType: 'Type de voiture',
      },
      driverLoginStatus: {
        driver: 'Pilotes',
        driverIdPermit: 'CNI du chauffeur / permis de conduire',
        phone: 'Numéro de téléphone',
        username: 'Nom d\'utilisateur',
        company: 'Compagnie',
        carType: 'Type de voiture',
        licensePlate: 'Plaque d\'immatriculation',
        status: 'Statut',
      },
      bookingSummary: {
        date: 'Date',
        operator: 'Opérateur',
        username: 'Nom d\'utilisateur',
        action: 'Action',
        bookingId: 'Réservation #',
      },
      creditWallet: {
        dateAndTime: 'Date & heure',
        driverName: 'Nom du Driver',
        phoneNumber: 'Numéro de téléphone',
        userName: 'Nom d\'utilisateur',
        company: 'Compagnie',
        type: 'Type',
        bookingId: 'Réservation #',
        operator: 'Opérateur',
        reason: 'Raison',
        amount: 'Montant',
        newAmount: 'New balance',
        currency: 'Devise',
      },
      dispatchHistory: {
        performer: 'Exécutant',
        name: 'Nom',
        action: 'Action',
        date: 'Date',
        location: 'Localisation du chaufferu',
        estimate: 'Estimation de durée entre le véhicule et le lieu de prise en charge',
      },
      payToDriver: {
        driverName: 'Nom du Driver',
        totalUnsettledTransactions: 'Total unsettled transactions',
        totalUnsettledAmount: 'Total unsettled amount',
        actions: 'Actions',
        pay: 'Payé',
        remove: 'Enlever',
        clearSuccess: 'Les gains du chauffeur selectionné ont bien été effacées',
        clearFailed: 'Echec de l\'effacement des données de gain de chauffeur. Veuillez réessayer.',
        selectDriver: {
          title: '',
          body: 'Veuillez sélectionner un chauffeur à payer.',
          buttonTitle: 'OK'
        },
        confirmClear: {
          title: '',
          body: 'Etes vous sûr de vouloir effacer les données de gains de ce chauffeur? Cette action ne peut pas être annulée.',
          buttonTitle: 'OK'
        },
        paymentSuccessfully: 'Paiement effectué',
      },
      settlementHistory: {
        date: 'Date',
        driverName: 'Nom du Driver',
        dateRange: 'Date range',
        totalTransaction: 'Total settled transactions',
        paidAmount: 'Total settled amount',
      },
      settlement: {
        driverName: 'Pilotes',
        driverIdPermit: 'CNI du chauffeur / permis de conduire',
        totalBookings: 'Réservation totale',
        total: 'Montant total',
        approved: 'Approuvé',
        acct: 'ACCT',
        gross: 'GROSS',
        percent: '3.0%',
        net: 'NET',
      },
      documentExpiry: {
        driverName: 'Nom du driver',
        driverPhone: 'Numéro de téléphone',
        company: 'Compagnie',
        documentName: 'Document',
        expiredDate: 'Date d\'expiration',
        status: 'Statut',
        statusStr: {
          NA: 'N/A',
          expired: 'Expired',
          active: 'Active'
        }
      }

    }
  },
  zoneSetting: {
    Add: 'Ajouter',
    Import: 'Importer',
    Name: 'Nom',
    Status: 'Statut',
    Active: 'Actif',
    Inactive: 'Inactif',
    Deactivate: 'Désactiver',
    Activate: 'Activer',
    Delete: 'Effacer',
    Cancel: 'Annuler',
    Input_zone_name: 'Entrer un nom de zone',
    Save_zone: 'Enregistrer la zone',
    Clear_draw: 'Effacer le dessin',
    Edit: 'Modifier',
    Delete_zone_success: 'La zone a bien été supprimée',
    Delete_zone_fail: 'Des erreurs sont survenues lors de la suppression de la zone',
    Update_zone_fail: 'Des erreurs sont survenues lors de la mise à jour de la zone',
    Update_zone_success: 'La nouvelle zone a bien été mise à jour',
    Create_zone_fail: 'Des erreurs sont survenues lors de la création de la zone',
    Create_zone_success: 'La nouvelle zone a bien été créée',
    Draw_zone: 'Veuillez dessiner la zone sur la carte',
    Import_zones_successful: 'Import zones successful',
    Import_zones_fail: 'Import zones fail',
    DELETE_ZONE: 'Delete Zone',
    ZONE_CONFIRM: 'Do you want to delete zone ?',
    Name_zone_required: 'Name and Zone is required!'
  },
  companySetting: {
    DELETE_COMPANY: 'DELETE COMPANY',
    DELETE_CONFIRM: 'Do you want to delete company!',
    DETAIL_COMPANY: 'DETAIL COMPANY',
    Yes: 'Oui',
    Cancel: 'Annuler',
    EDIT_COMPANY: 'MODIFIER UNE COMPAGNIE',
    ADD_COMPANY: 'AJOUTER UNE COMPAGNIE',
    Name: 'Nom',
    Required_fields: 'Champs requis',
    Save: 'Sauvegarder',
    Actions: 'Actions',
    Edit: 'Modifier',
    Add: 'Ajouter',
    Show_per_page: 'Eléments par page',
    Page: 'Page',
    show_pages: '{0} - {1} de {2} Affiche',
    Create_company_success: 'La nouvelle compagnie a bien été créée',
    Create_company_fail: 'Des erreurs sont survenues en créant la compagnie',
    Update_company_success: 'La compagnie a bien été mise à jour',
    Update_company_fail: 'Des erreurs sont survenues en mettant à jour la compagnie',
    Load_company_fail: 'Des erreurs sont survenues en chargeant la compagnie',
    Delete_company_success: 'La compagnie a bien été supprimée',
    Delete_company_fail: 'Des erreurs sont survenues en supprimant la compagnie',
  },
  dispatchSetting: {
    GENERAL: 'GÉNÉRAL',
    Offer_interval: 'Intervalle d\'offre',
    Seconds: 'Secondes',
    Auto_re_dispatch_interval: 'Re-dispatch automatique intervalle',
    Maximum_auto_dispatch_duration: 'Durée maximale d\'auto-dispatch',
    Time_out: 'Temps libre',
    mins: 'mins',
    minTimeBeforeReservation: 'Do not allow Driver to start reservation before',
    IN_ADVANCE: 'EN AVANCE',
    Assign_reservation_to_driver: 'Assigner la réservation à un chauffeur',
    Dispatcher_confirms_job_for_driver: 'Le dispatcher confirme la course pour le chauffeur',
    Minimum_book_ahead: 'Minimum de temps d\'avance pour la réservation',
    Maximum_book_ahead: 'Délai maximal de réservation en avance',
    Week: 'Semaine',
    Month: 'Mois',
    hrs: 'heures',
    Driver_cancellation_duration: 'Durée d\'annulation du Driver',
    Dispatch_mode: 'Mode de dispatch',
    Manual: 'Manuel',
    Immediate_auto_dispatch: 'Auto-dispatch immédiat',
    Delayed_auto_dispatch: 'Auto-dispatch retardé',
    Auto_dispatch_before_pickup_time: 'Auto-dispatch avant l\'heure de départ',
    Required_fields: 'Champs requis',
    Save: 'Sauvegarder',
    Process_in_advance: 'Exécuté en avance',
    Save_dispatch_success: 'Le dispatch a bien été mis à jour',
    Save_dispatch_fail: 'Des erreurs sont survenues en mettant à jour le dispatch',
    Dispatch_setting_general_tooltip: 'Postuler pour réservation à la demande, réservation en avance avec un auto-dispatch retardé',
    Dispatch_setting_Assign_reservation_to_driver_tooltip: 'La réservation est assignée et acceptée automatiquement si cette option est activée',
    Dispatch_setting_Driver_cancellation_duration_tooltip: 'La durée est l\'intervalle de temps entre l\'heure de départ et le moment où le Driver annule la réservation',
    Dispatch_setting_Dispatch_mode_tooltip: '- Mode manuel: La réservation sera enregistrée et restera sur Cue de votre Command Center sous le statut «en attente»  lorsque la réservation est créée. Après avoir cliqué sur "Mise à jour de la réservation" au détail de réservation, la réservation sera envoyée à vos Drivers affectés ou à tous les Drivers en cas de non attribution du Driver. Il sera intégré dans le calendrier du Driver,l et un rappel sera disponible. - Mode Retardement: la réservation sera enregistrée et restera sur Cue de votre Command Center sous le statut «en attente» lorsque la réservation est créée. La réservation sera envoyée automatiquement avant "valeur temps au Processus à l\'avance" de l\'heure de départ au Driver affecté ou du Driver le plus proche en cas de non attribution du Driver. - Mode auto-dispatch immédiat:la réservation sera automatiquement envoyée à tous les Drivers une fois la réservation créée. Elle sera intégrée dans le calendrier du Driver et un rappel sera disponible.',

  },
  voiceSMSSetting: {
    ACCOUNT_SETTINGS: 'PARAMÈTRES DE COMPTE',
    Account_SID: 'Compte SID',
    Auth_Token: 'Jeton d\'autorisation',
    Application_ID: 'Identifiant d\'application',
    SMS_number_1: 'Numéro de SMS 1',
    SMS_number_2: 'Numéro de SMS 2',
    SMS_SETTINGS: 'PARAMÈTRES SMS',
    MASKED_PHONE_SETTINGS: 'PARAMÈTRES DU TÉLÉPHONE MASQUÉ',
    SMS_MARKETING_SETTINGS: 'PARAMÈTRES DE MARKETING SMS',
    Phone_number: 'Numéro de téléphone',
    Save: 'Sauvegarder',
    bookingSMS: 'Message template',
    bookingSMSTemplate: {
      name: "Name",
      content: "Content",
      action: "Action",
    },
    bookingSMSModal: {
      title: 'Add SMS template',
      save: 'Save',
      cancel: 'Cancel'
    },
  },
  regularSetting: {
    Name: 'Nom',
    Last_Update: 'Dernière Mise À Jour',
    Status: 'Statut',
    Required_fields: 'Champs requis',
    Yes: 'Oui',
    Cancel: 'Annuler',
    Save: 'Sauvegarder',
    Actions: 'Actions',
    Edit: 'Modifier',
    Add: 'Ajouter',
    Show_per_page: 'Eléments par page',
    Page: 'Page',
    show_pages: '{0} - {1} de {2} Affiche',
    DELETE_CONFIRM: 'Voulez-vous supprimer cet élément?',
    ADD_REGULAR_RATE: 'AJOUTER TARIF NORMAL',
    EDIT_REGULAR_RATE: 'MODIFIER TARIF DE BASE',
    DETAIL_REGULAR_RATE: 'DÉTAIL TAUX RÉGULIER',
    DELETE_REGULAR_RATE: 'SUPPRIMER LE TARIF NORMAL',
    Delete_confirm_regular_rate: 'Voulez-vous supprimer cet élément?',
    STARTING: 'PRISE EN CHARGE',
    Starting_Now: 'Prise en charge (instantané)',
    Starting_Reservation: 'Prise en charge (réservation)',
    FIRST_DISTANCE_FEE: 'TARIF PREMIÈRE DISTANCE',
    First_distance_fee: 'Première distance',
    From: 'Distance De',
    To: 'A`',
    Fee_for_first_distance: 'Frais pour la première distance',
    SECOND_DISTANCE_FEE: 'TARIF 2ÈME DISTANCE',
    Second_distance_fee: '2ème distance',
    AFTER_SECOND_DISTANCE: 'APRÈS LA 2ÈME DISTANCE',
    Fee_after_second_distance: 'Frais de 2ème distance',
    PER_MINUTE: 'PAR MINUTE',
    Fee_per_minute: 'Tarif par minute',
    mi: 'Mi',
    MINIMUM: 'MINIMUM',
    Minimum_Now: 'Minimum (maintenant)',
    Minimum_Reservation: 'Minimum (réservation)',
    PAX_MDISPATCHER_CANCELLATION: 'ANNULATION DU PASSAGER',
    On_demand: 'Immédiat',
    Penalty: 'Pénalité',
    In_advance: 'En avance',
    PAX_MDISPATCHER_NO_SHOW: 'NO SHOW DU PASSAGER',
    Assign_car_type: 'Attribuer type de véhicule',
    Select_car_type: 'Sélectionner le type de véhicule',
    Active: 'Actif',
    Inactive: 'Inactif',
    Deactivate: 'Désactiver',
    Activate: 'Activer',
    DELETE_FLATE_RATE: 'SUPPRIMER FORFAIT',
    Delete_confirm_flat_rate: 'Voulez-vous supprimer cet élément?',
    DETAIL_FLAT_RATE: 'DÉTAIL FLAT RATE',
    EDIT_FLAT_RATE: 'MODIFIER PRIX FORFAITAIRE',
    ADD_FLAT_RATE: 'AJOUTER TARIF FORFAITAIRE',
    DELETE_HOURLY_RATE: 'SUPPRIMER TARIF DE MISE A DISPOSITION',
    Delete_confirm_hour_rate: 'Voulez-vous supprimer cet élément?',
    EDIT_HOURLY_RATE: 'Editer un tarif de mise à disposition',
    ADD_HOURLY_RATE: 'Ajouter un tarif de mise à disposition',
    DELETE_FLAT_ROUTE: 'DELETE_FLAT_ROUTE',
    DELETE_ROUTE_CONFIRM: 'Are you sure to delete route?',
    EDIT_ROUTE: 'MODIFIER L\'ITINÉRAIRE ',
    ADD_ROUTE: 'AJOUTER ITINÉRAIRE',
    Route_name: 'Nom de l\'itinéraire',
    Route_type: 'Type d\'itinéraire',
    Zone_to_zone: 'Zone à zone',
    Zipcode_to_zipcode: 'Code postal vers code postal',
    First_location: 'Point de départ',
    Second_location: 'Arrivée',
    First_location_zip_note: '* Remarques: Vous pouvez entrer plusieurs codes postaux en même temps en utilisant le symbole \',\'. Pour les codes consécutifs, utilisez le symbole \'->\' au lieu d\'entrer un par un (ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)',
    Second_location_zip_note: '* Remarques: Vous pouvez entrer plusieurs codes postaux en même temps en utilisant le symbole \',\'. Pour les codes consécutifs, utilisez le symbole \'->\' au lieu d\'entrer un par un (ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)',
    SINGLE_TRIP_SETUP: 'Réglage du tarif aller simple',
    Route_1: 'Trajet 1 (Premier emplacement -> Second emplacement)',
    Route_2: 'Trajet 2 (Second emplacement ->  Premier emplacement)',
    Limitation: 'Limitation',
    Unlimited: 'Illimité',
    Limited: 'Limité',
    Delete: 'Effacer',
    Distance_coverage: 'Couverture de distance',
    Time_coverage: 'Couverture temporelle',
    Extra_time: 'Temps supplémentaire',
    ROUND_TRIP_SETUP: 'Réglage du tarif aller-retour',
    Round_trip: 'Aller-retour',
    ROUTES: 'ROUTES',
    Route_1_Fare: 'Tarif du trajet 1',
    Route_2_Fare: 'Tarif du trajet 2',
    Round_Trip_Fare: 'Tarif de l\'aller-retour',
    Select_a_zone: 'Sélectionnez une zone',
    minute: 'Minute',
    VIEW_REGULAR_RATE: 'VIEW DETAIL REGULAR RATE',
    VIEW_FLAT_RATE: 'VIEW FLAT RATE',
    VIEW_HOURLY_RATE: 'VIEW HOURLY RATE',
    HOURLY_PACKAGE_RATE: 'TARIF HORAIRE',
    Package_name: 'Nom Du Package',
    Package_duration: 'Durée Du Package',
    Base_rate: 'Taux De Base',
    Extra_duration: 'Durée Supplémentaire',
    Extra_distance: 'Distance Supplémentaire',
    DELETE_HOURLY_PACKAGE: 'DELETE HOURLY PACKAGE',
    DELETE_PACKAGE_CONFIRM: 'Do you want to delete package rate?',
    EDIT_PACKAGE: 'MODIFIER LE PACKAGE',
    VIEW_PACKAGE: 'DÉTAIL DU PACKAGE',
    ADD_PACKAGE: 'AJOUTER UN PACKAGE',
    Fee_per_extra_duration: 'Tarif par durée supplémentaire',
    Fee_per_extra_distance: 'Frais par distance supplémentaire',
    hours: 'Hour(s)',
    days: 'Day(s)',
    Update_flat_rate_success: 'Les tarifs au forfait ont bien été mis à jour',
    Update_flat_rate_fail: 'Des erreurs sont survenues en mettant jour les tarifs au forfait',
    Created_flat_rate_success: 'Les tarifs au forfait ont bien été créés',
    Created_flat_rate_fail: 'Des erreurs sont survenues en créant les tarifs au forfait',
    Update_flat_rate_route_success: 'Le tarif au forfait a bien été mis à jour ',
    Update_flat_rate_route_fail: 'Des erreurs sont survenues en mettant à jour le tarif au forfait',
    Created_flat_rate_route_success: 'Le tarif au forfait a bien été créé',
    Created_flat_rate_route_fail: 'Des erreurs sont survenues en créant le tarif au forfait',
    Loading_flat_rate_fail: 'Des erreurs sont survenues en chargeant les détails du tarif au forfait',
    Delete_flat_rate_route_fail: 'Des erreurs sont survenues en supprimant un tarif au forfait',
    Delete_flat_rate_success: 'Les tarifs au forfait ont bien été supprimée',
    Delete_flat_rate_fail: 'Des erreurs sont survenues en supprimant un tarif au forfait',
    Update_regular_rate_success: 'Les tarifs normaux ont bien été mis à jour',
    Update_regular_rate_fail: 'Des erreurs sont survenues lors de la mise à jour des tarifs normaux',
    Create_regular_rate_success: 'Un nouveau tarif normal a bien été créé',
    Create_regular_rate_fail: 'Des erreurs sont survenues lors de la création du tarif normal',
    Update_hourly_rate_success: 'Les tarifs de mise à disposition ont bien été mis à jour',
    Update_hourly_rate_fail: 'Des erreurs sont survenues en mettant jour les tarifs de mise à disposition',
    Create_hourly_rate_success: 'Les tarifs de mise à disposition ont bien été créés',
    Create_hourly_rate_fail: 'Des erreurs sont survenues en créant les packages de mise à disposition',
    Update_hourly_rate_package_success: 'Les packages de mise à disposition ont bien été mis à jour',
    Update_hourly_rate_package_fail: 'Des erreurs sont survenues en mettant jour les packages de mise à disposition',
    Create_hourly_rate_package_success: 'Les packages de mise à disposition ont bien été créés',
    Create_hourly_rate_package_fail: 'Des erreurs sont survenues en créant les packages de mise à disposition',
    Load_hourly_rate_fail: 'Des erreurs sont survenues lors du chargement des détails de tarifs de mise à disposition',
    Update_hourly_status: 'Des erreurs sont survenues en mettant à jour le statut de tarif de mis à disposition',
    Delete_hourly_fail: 'Des erreurs sont survenues en supprimant des tarifs de mise à disposition',
    Delete_hourly_success: 'Le tarif de mise à disposition a bien été supprimé',
    Loading_regular_rate_fail: 'Des erreurs sont survenues lors du chargement du tarif normal',
    Update_regular_status_fail: 'Des erreurs sont survenues lors de la mise à jour du statut du tarif normal',
    Delete_regular_rate_success: 'Le tarif normal a bien été supprimé',
    Delete_regular_rate_fail: 'Des erreurs sont survenues lors de la suppression du tarif normal',
    Please_go_to_Car_Type_settings: 'Veuillez aller dans les paramètres de types de véhicule pour appliquer ce tarif à des types de véhicule',
    Delete_package_error: 'Delete package error!',
    Pax_mDispatcher_cancellation_tooltip: 'Politique d\'annulation appliquée pour les passagers',
    Penalty_tooltip: 'Montant facturé',
    Pax_mDispatcher_no_show_tooltip: 'Politique de non-présentation appliquée pour les passagers. Ces frais seront facturés si les Drivers cliquent "Non Présentation" lorsque le passager n\'est pas au point de départ'
  },
  carTypeSetting: {
    newService: 'New Service',
    editService: 'Edit Service',
    serviceInfo: 'Service Info',
    name: 'Name',
    namePH: 'Car-type name',
    enterNumber: 'Enter a number',
    appDisplayName: 'App display name',
    shortDescription: 'Short description',
    maxPassengers: 'Max passengers',
    maxLuggage: 'Max luggages',
    vehiclePhoto: 'Vehicle Photos',
    iconShowList: 'Icon to show in list',
    imageShowWeb: 'Image to show on web',
    carPinOnMap: 'Car PIN on map',
    recommendedSize: 'Recommended size:',
    serviceSetting: 'Service Settings',
    serviceType: 'Service type',
    reservation: 'Reservation',
    onDemand: 'On-demand',
    destinationSetting: 'Destination setting',
    requireDestination: 'Require destination',
    extraDestination: 'Extra destination',
    calculatorFareMode: 'Calculate fare mode',
    hideDestinationOnDemand: 'On-demand',    meteredFare: 'Metered fare',
    quotedFare: 'Quoted fare',
    availableZones: 'Available Zones',
    dispatchAssociation: 'Dispatch Association',
    Save: 'Save',
    Edit: 'Edit',
    Cancel: 'Cancel',
    validateName: 'Please input name',
    validateAppDisplayName: 'Please input app display name',
    validateSelectImage: 'Please select an image',
    updateSuccess: 'Update successfully',
    createSuccess: 'Create successfully',
    failApi: 'Something went wrong!',
    validateRequire: 'Please specify passenger count and double-check luggage.',
  },
  carSetting: {
    DELETE_CAR: 'AJOUTER',
    DELETE_CONFIRM: 'Voulez-vous supprimer le véhicule sélectionnée?',
    EDIT_CAR: 'MODIFIER',
    CREATE_CAR: 'AJOUTER',
    Plate_Number: 'Numéro De La Plaque',
    Car_Type: 'Type De Véhicule',
    Select_car_type: 'Sélectionner le type de véhicule',
    Select_car_make: 'Sélectionner marque de véhicule',
    Model: 'Modèle',
    Select_a_car_model: 'Sélectionner modèle de véhicule',
    Year: 'Année',
    Select_year: 'Sélectionnez l\'année',
    Hardware_Meter: 'Compteur physique',
    VIN: 'Numéro d\'identification du véhicule (NIV)',
    Company: 'Compagnie',
    Select_company: 'Sélectionner une compagnie',
    Max_Passengers: 'Nbe maximum de passagers',
    Max_Luggage: 'Nbe maximum de bagages',
    Color: 'Couleur',
    License_expiry: 'Expiration du permis de conduire',
    Required_fields: 'Champs requis',
    Yes: 'Oui',
    Cancel: 'Annuler',
    Save: 'Sauvegarder',
    Actions: 'Actions',
    Delete: 'Effacer',
    Add: 'Ajouter',
    Hardware_Meter: 'Compteur Physique',
    Make: 'Marque',
    Car_Model: 'Modèle De Véhicule',
    Created_Date: 'Créer Date',
    Status: 'Statut',
    Show_per_page: 'Eléments par page',
    Page: 'Page',
    show_pages: '{0} - {1} de {2} Affiche',
    Edit: 'Modifier',
    Activate: 'Activer',
    Active: 'Actif',
    Inactive: 'Inactif',
    Deactivate: 'Désactiver',
    Name: 'Nom',
    MODEL_LIST: 'MODÈLE',
    Model_Name: 'Modèle De Véhicule',
    Add_Model: 'AJOUTER UN MODÈLE DE VÉHICULE',
    DELETE_CAR_MODEL: 'DELETE CAR MODEL',
    DELETE_CONFIRM_MODEL: 'Do you want to delete car model?',
    EDIT_CAR_MAKE: 'MODIFIER MARQUE DE VÉHICULE',
    CREATE_CAR_MAKE: 'AJOUTER MARQUE DE VÉHICULE',
    Assign_Rate: 'Attribuez tarif',
    ASSIGN_RATE: 'ATTRIBUEZ TARIF',
    Car_type_ID: 'Identification Du Type De Véhicule',
    App_Display_Name: 'Nom De L\'affichage De L\'application',
    Affiliate_car_type: 'Type De Véhicule Affilié',
    Regular_rate: 'Tarif normal',
    Zone: 'Zone',
    Rate: 'Tarif',
    Zone_Rate: 'Zone - Tarif',
    Flat_Fare: 'Forfait',
    Select_flat_rate: 'Sélectionnez forfait.',
    Hourly_daily_rate: 'Tarif de mise à disposition',
    Dispatch_To: 'Dispatch À',
    Normal_Fare: 'Tarif Normal',
    Upload_an_image: 'Aucune image sélectionnée',
    DETAIL_CAR: 'CAR DETAIL',
    DELETE_MULTI_CAR: 'Delete selected cars',
    DELETE_MULTI_CAR_CONFIRM: 'Do you want to delete these vehicles ,Just only vehicles in the inactive status can be delete?',
    DETAIL_CAR_MAKE: 'Detail Car Make',
    Car_Make: 'Marque du véhicule',
    Create_car_success: 'Un nouveau véhicule a bien été créé',
    Update_car_success: 'Ce véhicule a bien été mis à jour',
    Update_car_fail: 'The errors occurred while updating car',
    Create_car_fail: 'Les erreurs se sont produites lors de la création du véhicule',
    Load_car_fail: 'Des erreurs sont survenues en chargeant le véhicule',
    Delete_car_fail: 'Des erreurs sont survenues en supprimant les véhicules',
    Delete_car_success: 'Le véhicule a bien été supprimé',
    Change_cars_status_success: 'Tous les statuts des véhicules ont bien été modifiés',
    Change_cars_status_fail: 'Des erreurs sont survenues en changeant les statuts des véhicules',
    Delete_cars_success: 'Tous les véhicules sélectionnés ont bien été supprimé',
    Delete_cars_fail: 'Des erreurs sont survenues en supprimant les véhicules',
    Update_car_type_success: 'Les catégories de véhicules ont bien été mis à jour',
    Update_car_type_fail: 'Des erreurs sont survenues en mettant à jour les catégories de véhicules',
    Load_car_type_details_fail: 'Les erreurs se sont produites lors du chargement des détails du type de véhicule',
    Update_car_make_fail: 'Des erreurs sont survenues lors de la mise à jour des marques de véhicules',
    Update_car_make_success: 'Les marques de véhicules ont bien été mises à jour',
    Create_car_make_fail: 'Des erreurs sont survenues lors de la création de marques de véhicules',
    Create_car_make_success: 'Une nouvelle marque de véhicule a bien été créée',
    Update_car_model_fail: 'Des erreurs sont survenues lors de la mise à jour des modèles de véhicules',
    Update_car_model_success: 'Les modèles de véhicules ont bien été mises à jour',
    Create_car_model_fail: 'Des erreurs sont survenues lors de la création de modèles de véhicules',
    Create_car_model_success: 'Un nouveau modèle de véhicule a bien été créée',
    Loading_car_model_fail: 'Des erreurs sont survenues lors du chargement du modèle de véhicule',
    Delete_car_make_success: 'La marque de véhicule a bien été supprimée',
    Delete_cat_make_fail: 'Des erreurs sont survenues lors de la suppression de la marque de véhicule',
    Car_type_filter: 'Filtre de type de véhicule',
    Delete_regular_rate_success: 'Delete Regular rate successfull',
    Delete_regular_rate_fail: 'Delete Regular rate error',
    Update_status_error: 'Update status error',
    Case_number: 'Case number',
    Phone_Number: 'Phone Number',
    ERROR_INPUT_VALIDPHONE: 'Veuillez spécifier un numéro de téléphone valide'
  },
  operationSetting: {
    Driver: 'Pilotes',
    Search_driver_name: 'Rechercher par nom de pilote',
    Company: 'Compagnie',
    License_plate: 'Plaque d\'immatriculation',
    Select_license_plate: 'Sélectionnez la plaque d\'immatriculation',
    Shift: 'Shift',
    Zone: 'Zone',
    Required_fields: 'Champs requis',
    Save: 'Sauvegarder',
    No: 'Non.',
    Car_Type: 'Type De Véhicule',
    Plate_Number: 'Numéro De La Plaque',
    Unassign: 'Annuler l\'attribution',
    Please_choose_driver: 'Veuillez choisir un pilote.',
    Please_license_plate: 'Veuillez choisir un plaque d\'immatriculation.',
    Please_choose_shift: 'Veuillez choisir un shift',
    Please_choose_zone: 'Veuillez choisir une zone.',
    CONFIRM_UNASSIGN: 'Voulez-vous annuler l\'attribution de ce pilote?',
    Assign_success: 'L\'opération a été assigné avec succès',
    Unassign_success: 'Le pilote a bien été affecté'
  },
  paymentMethod: {
    cash: 'Paiement à bord',
    personalCard: 'Carte bleue',
    corporateCard: 'Carte d\'entreprise',
    qrCodePayment: 'Paiement par code QR',
    directBilling: 'Facturation Entreprise',
    externalCard: 'Carte externe',
    mDispatcherCard: 'Carte YoConcierge',
    corporatePrepaid: 'Prépayé entreprise',
    fleetCard: 'Carte externe',
    applePay: 'Apple pay'
  },
  changePassword: {
    Change_password: 'Changer le mot de passe',
    Current_password: 'Mot de passe actuel',
    New_password: 'Nouveau mot de passe',
    Confirm_password: 'Confirmez le mot de passe',
    Save: 'Sauvegarder',
    Cancle: 'Annuler',
    ERROR_INPUT_PASSWORD: 'Veuillez saisir le mot de passe',
    ERROR_INPUT_VALID_PASSWORD: 'Password must contain at least 8 characters, one numeric digit and a special character',
    ERROR_INPUT_MATCH_PASSWORD: 'Le mot de passe ne correspond pas. Veuillez réessayer',
  },
  userProfile: {
    My_account: 'Mon compte',
    Username: 'Nom d\'utilisateur',
    First_name: 'Prénom',
    Last_name: 'Nom de famille',
    Phone_number: 'Numéro de téléphone',
    Email_address: 'Adresse e-mail',
    Address: 'Adresse',
    Save: 'Sauvegarder',
    Edit_profile: 'Changer le mot de passe',
    Change_password: 'Changer le mot de passe',
    ERROR_INPUT_USERNAME: 'Veuillez saisir le nom d\'utilisateur.',
    ERROR_INPUT_FIRSTNAME: 'Veuillez saisir le prénom.',
    ERROR_INPUT_VALID_EMAIL: 'Veuillez entrer un email valide',
    Required_fields: 'Champs requis',
  },
  General: {
    searchQueuingDriver: 'Search: Driver Name, Phone Number',
    search: 'Search',
    User: 'Utilisateur ',
    My_Account: 'Mon compte',
    Sign_out: 'Se déconnecter',
    My_site: 'Mon site',
    Command_center: 'Command center',
    Notifications: 'Notifications',
    You_dont_have_any_message: 'You dont have any message',
    Mon: 'Lun',
    Tue: 'Mar',
    Wed: 'Mer',
    Thu: 'Jeu',
    Fri: 'Ven',
    Sat: 'Sam',
    Sun: 'Dim',
    Name: 'Nom',
    Now: 'Maintenant',
    hrs: 'Heures',
    patmentMethod_1: 'Paiement à bord',
    patmentMethod_2: 'Carte bleue',
    patmentMethod_3: 'mDispatch card',
    patmentMethod_4: 'Carte d\'entreprise',
    patmentMethod_5: 'Facturation Entreprise',
    patmentMethod_6: 'Carte externe',
    patmentMethod_7: 'Prépayé entreprise',
    patmentMethod_8: 'Paiement par code QR',
    patmentMethod_9: 'Apple Pay',
    patmentMethod_10: 'Apple Pay',
    Name: 'Nom',
    Delete: 'Effacer',
    App_SOS_dialog_form_message_1: '{0} Emergency SOS!',
    App_SOS_dialog_form_message_2: 'I need help at ',
    App_SOS_dialog_form_message_3: 'Booking: {0}, Driver: {1}, Passenger: {2}, License Plate: {3} ({4})',
    mins: 'mins',
  },
  shiftSettings: {
    Add: 'Ajouter',
    Show_per_page: 'Eléments par page',
    Page: 'Page',
    Time_Range: 'Intervalle de temps',
    show_pages: '{0} - {1} of {2} shown',
    Actions: 'Actions',
    Edit: 'Modifier',
    Delete: 'Effacer',
    EDIT_SHIFT_SETTING: 'Modifier Shift',
    ADD_SHIFT_SETTING: 'Ajouter Shift',
    DETAIL_SHIFT_SETTING: 'Réglage du décalage de détail',
    DELETE_SHIFT_SETTING: 'SUPPRIMER LES PARAMETRES DE SHIFT',
    Delete_confirm_shift_setting: 'Voulez-vous supprimer cet élément?',
    Name: 'Nom',
    Required_fields: 'Champs requis',
    Save: 'Enregistrer',
    Cancel: 'Annuler',
    From: 'De',
    To: 'A',
    Edit_shift_template: 'Modifier modèle de Shift',
    Add_shift_template: 'Ajouter Shift de référence',
    Detail_shift_template: 'Modèle de décalage de détail',
    Delete_shift_template: 'Delete Shift Template',
    Delete_confirm_shift_template: 'Voulez-vous supprimer cet élément?',
    Working_time: 'Temps de travail',
    Create_shift_settings_success: 'Le nouveau paramètre de shift  a été créé avec succès',
    Create_shift_settings_fail: 'Les erreurs se sont produites lors de la création du shift',
    Update_shift_settings_success: 'Le paramètre de shift a été mis à jour avec succès',
    Update_shift_settings_fail: 'Les erreurs se sont produites lors de la mise à jour du shift',
    Delete_shift_settings_success: 'Le paramètre de shift a été supprimé avec succès',
    Delete_shift_settings_fail: 'Les erreurs sont survenues lors de la suppression du paramètre de shift',
    Create_shift_template_success: 'Le nouveau modèle de shift a été créé avec succès',
    Create_shift_template_fail: 'Les erreurs sont survenues lors de la création du modèle de shift',
    Update_shift_template_success: 'Le modèle de shift a été mis à jour avec succès',
    Update_shift_template_fail: 'Les erreurs se sont produites lors de la mise à jour du modèle de shift',
    Delete_shift_template_success: 'Le modèle de shift a été supprimé avec succès',
    Delete_shift_template_fail: 'Les erreurs se sont produites lors de la suppression du modèle de shift',
  },
  resetPassword: {
    New_Password: 'Nouveau mot de passe',
    New_Comfirm_Password: 'Confirmez le mot de passe',
    Save: 'Enregistrer',
    INFO_SUCCESS: 'Un lien de réinitialisation de mot de passe a été envoyé à votre email ',
    TOKEN_EXPIRED: 'Your reset password token has been expired',
    Back_To_Login_Page: 'Back To Login Page'
  },
  promotionSettings: {
    Detail_campaign: 'Campagne détaillée',
    Edit_campaign: 'Modifier campagne',
    Add_campaign: 'Ajouter campagne',
    Delete_campaign: 'Supprimer la campagne',
    Delete_confirm_campaign: 'Voulez-vous supprimer la campagne sélectionnée?',
    Add: 'Ajouter',
    Show_per_page: 'Eléments par page',
    Page: 'Page',
    Name: 'Nom',
    Actions: 'Actions',
    show_pages: '{0} - {1} of {2} shown',
    Edit: 'Modifier',
    Send_to_inbox: 'Envoyer à la boîte de reception',
    Delete: 'Effacer',
    Save: 'Sauvegarder',
    Cancel: 'Annuler',
    Required_fields: 'Champs requis',
    Promotion_code: 'Code promo',
    Promotion_type: 'Type',
    Campaign: 'Campagne',
    Type: 'Type',
    Value: 'Valeur',
    Valid_from: 'Valide à partir de',
    Valid_to: 'Valide jusqu\'à',
    Total_uses_user: 'Total utilisations/ utilisateur',
    Released_quantity: 'Quantité distribuée',
    Number_of_sending_inbox: 'Nombre de boîtes d\'envoi',
    Status: 'Statut',
    Activate: 'Activer',
    Deactivate: 'Désactiver',
    Add_promotion_code: 'Ajouter code de promotion',
    Edit_promotion_code: 'Modifier le code promo',
    Detail_promotion_code: 'Code de promotion détaillé',
    Delete_promotion_code: 'Supprimer le code promo',
    Delete_confirm_promotion_code: 'Voulez-vous supprimer le code promo sélectionné?',
    New_customer: 'Nouveau client',
    Budget_per_user: 'Budget/utilisateur',
    Unlimited: 'Illimité',
    Limited: 'Limité',
    Total_uses_per_user: 'Total utilisations/ utilisateur',
    Send_an_in_box_automatically_to_new_customers: 'Envoyer un message automatiquement pour un nouveau client',
    Notes: 'Notes',
    Private: 'Privé',
    Public: 'Public',
    Create_campaign_success: 'Une nouvelle campagne a été créée avec succès',
    Create_campaign_fail: 'Les erreurs se sont produites lors de la création de la campagne',
    Update_campaign_success: 'La campagne a été mise à jour avec succès',
    Update_campaign_fail: 'Les erreurs se sont produites lors de la mise à jour',
    Delete_campaign_success: 'Campaign has been deleted successfully',
    Delete_campaign_fail: 'The errors occurred while deleting campaign',
    Loading_campaign_fail: 'Les erreurs se sont produites lors du chargement de la campagne',
    Update_campaign_status_success: 'Le statut de la campagne a été mis à jour avec succès',
    Update_campaign_status_fail: 'Les erreurs se sont produites lors de la mise à jour du statut',
    Loading_promotion_code_fail: 'The errors occurred while loading promotion code',
    Select_campaign: 'Sélectionner campagne',
    Create_promotion_code_success: 'Le nouveau code de promotion a été créé avec succès',
    Create_promotion_code_fail: 'Les erreurs se sont produites lors de la création du code de promotion',
    Update_promotion_code_status_success: 'L\'état du code promo a été mis à jour avec succès',
    Update_promotion_code_status_fail: 'Les erreurs se sont produites lors de la mise à jour du code promo',
    Delete_promotion_code_confirmation: 'Do you want to deactivate these promotion codes?\n',
    Just_delete_inactive: ' ** Seul un code promo qui a un statut d\'inactif peut être supprimé.',
    Send_inbox_confirmation: 'Etes vous sûr de vouloir envoyer ce code promo à la boîte de réception du passager?',
    Send_inbox_success: 'Le code promo a bien été envoyé.',
    Send_inbox_fail: 'L\'envoi du code promo a échoué.Veuillez réessayer!',
    Confirmation: 'Confirmation',
    Delete_multiple_promotion_codes_success: 'Les codes promo ont été supprimés avec succès',
    Delete_multiple_promotion_codes_fail: 'The errors occurred while deleting promotion codes',
    Delete_multiple_promotion_code_success: 'Le code promo a été supprimé avec succès',
    Delete_multiple_promotion_code_fail: 'The errors occurred while deleting promotion code',
    Update_multiple_promotion_code_status_success: 'Les statuts du code promo ont été mis à jour avec succès',
    Update_multiple_promotion_code_status_fail: 'The errors occurred while deleting promotion code statuses',
    Search_here: 'Rechercher ici',
    Please_input_campaign_name: 'Veuillez saisir le nom de la campagne.',
    Please_input_promotion_code: 'Veuillez saisir le code promo',
    Please_input_promotion_code_at_least_4_characters: 'Please enter at least 4 characters.',
    Please_select_campaign: 'Veuillez sélectionner une campagne',
    Please_input_value: 'Veuillez saisir une valeur',
    Please_select_date: 'Veuillez sélectionner une date',
    Please_choose_at_least_one_customer: 'Veuillez choisir au moins un client',
    This_campaign_name_has_been_existing_on_the_system_Please_input_another_one: 'Ce nom de campagne a déjà existé sur le système. Veuillez saisir un autre.',
    This_promotion_code_has_been_existing_on_the_system_Please_input_another_one: 'Ce code promo existe déjà sur le système. Veuillez en saisir un autre.',
    Import_Promotion_code_success: 'Le code promo <b>{0}</b>  a bien été importé',
    Import_Promotion_code_fail: 'Le code promo <b>{0}</b> n\'a pas pu être importé. Veuillez réessayer'
  },
  permission: {
    View: 'Vue',
    Actions: 'Actions',
    delete_permission: 'Delete Permission',
    DELETE_CONFIRM: 'Do you want to delete permission!',
    Yes: 'Oui',
    Cancel: 'Annuler',
    DRIVERS: 'Pilotes',
    Driver_list: 'Liste de Pilotes',
    Enter_to_search: 'Entrer pour rechercher',
    Permitted_driver_list: 'Liste des Pilotes autorisés',
    Save: 'Enregistrer',
    CAR: 'CAR',
    Car_list: 'Liste des véhicules',
    Permitted_car_list: 'Liste des véhicules autorisés',
    Edit_permission: 'Modifier Permission',
    Add_permission: 'Ajouter Permission',
    Detail_permission: 'Autorisation détaillée',
    Name: 'Nom',
    Driver_Car: 'Chauffeur/ Véhicule',
    All: 'Tous',
    Company: 'Compagnie',
    By_specific: 'Par spécificité',
    Drivers: 'Pilotes',
    Cars: 'Véhicules',
    Show_per_page: 'Eléments par page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Edit: 'Modifier',
    Delete: 'Effacer',
    Status: 'Statut',
    Action: 'Action',
    Inactive: 'Inactif',
    Active: 'Actif',
    Add: 'Ajouter',
    Update_status_error: 'Update status error',
    All_tooltip: 'Tous les chauffeurs seront affichés sur la Carte, Cue, Chauffeurs, Opération et Rapports. Tous les véhicules seront affichés dans la Gestion de Véhicules',
    Company_tooltip: 'Les chauffeurs autorisés pour cette entreprise seront affichés dans les sections Carte, Cue, Chauffeurs,, Opérations et Rapports. Les véhicules autorisés de cette entreprise seront affichés dans la Gestion de Véhicules',
    By_specific_tooltip: 'Les chauffeurs autorisés de cette liste seront affichés dans les sections Carte, Cue, Chauffeurs,, Opérations et Rapports. Les véhicules autorisés de cette liste seront affichés dans la Gestion de Véhicules',
    Full_permissions: 'Full permissions',
    ChangeBalance: 'Change Balance'
  },
  thirdPartySettings: {
    Not_over_limit_subs: 'You can not create more than 10 sub-locations',
    Show_per_page: 'Eléments par page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Delete: 'Effacer',
    Edit: 'Modifier',
    Status: 'Status',
    Actions: 'Actions',
    Action: 'Action',
    Inactive: 'Inactif',
    Active: 'Actif',
    Check_Google_Address: 'Vérifier l\'adresse Google de la latitude et de la longitude',
    Add_Third_Party_location: 'Ajouter',
    Add: 'Ajouter',
    Edit_Third_Party_location: 'MODIFIER L\'EMPLACEMENT DE LA 3ÈME PARTIE',
    Location_Not_Found: 'L\'emplacement est invalide.',
    Create_third_party_success: 'L\'emplacement a été créé avec succès',
    Create_third_party_fail: 'Les erreurs sont survenues lors de la création du lieu',
    Update_third_party_success: 'L\'emplacement a été mis à jour avec succès',
    Update_third_party_fail: 'Les erreurs sont survenues lors de la mise à jour',
    Alias: 'Alias',
    Address: 'Adresse',
    Latitude: 'Latitude',
    Longitude: 'Longitude',
    Please_input_address: 'Veuillez entrer l\'adresse',
    Please_input_latitude: 'Veuillez entrer la latitue',
    Please_input_longitude: 'Veuillez entrer la longitude',
    Save: 'Sauvegarder',
    Cancel: 'Annuler',
    Required_fields: 'Champs requis',
    Last_update: 'Last Update',
    Username: 'Username',
    Error_message_lat_in_range: 'La latitude doit être comprise entre -90.0 et 90.0',
    Error_message_lng_in_range: 'La longitude doit être comprise entre -180.0 et 180.0',
    Delete_third_party_success: 'L\'emplacement a été supprimé avec succès',
    Delete_third_party_fail: 'Les erreurs se sont produites lors de la suppression de l\'emplacement',
  },
  city: {
    DELETE_CITY: 'Delete city',
    DELETE_CONFIRM: 'Do you want to delete city?',
    Yes: 'Oui',
    EDIT_CITY: 'Modifier la Ville',
    ADD_CITY: 'Ajouter Une Ville',
    Name: 'Nom De La Ville',
    Required_fields: 'Champs requis',
    Save: 'Enregistrer',
    Cancel: 'Annuler',
    Add: 'Ajouter',
    Show_per_page: 'Eléments par page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Edit: 'Modifier',
    Delete: 'Effacer',
    DETAIL_CITY: 'Détail de la ville'
  },
  flightIntegration: {
    Username: 'Nom d\'utilisateur',
    provider: 'Flight Provider',
    Key: 'API Key',
    apiId: 'API Id',
    Save: 'Enregistrer'
  },
  smsIntegration: {
    provider: 'SMS Provider',
    apiToken: 'API Token',
    subAccountId: 'Sub Account ID',
    senderIdSource: 'Sender ID (source):',
    apiKey: 'API Key',
    username: 'Username',
    shortCode: 'Short Code',
    key: 'Key',
    webService: 'Web service',
    bearerToken: 'Bearer token',
    keyId: 'Key ID',
    email: 'Email',
    appKey: 'App key',
    sender: 'Sender',
    password: 'Password',
    maskedPhone: 'Masked phone number',
    senderId: 'Sender ID',
    webservice: 'Web service',
    brandname: 'Brand name',
    appSecret: 'App secret',
    consumerKey: 'Consumer key',
    requestUrl: 'Request Url',
    authId: 'Auth ID',
    authToken: 'Auth Token',
    phloId: 'PHLO ID',
    dlcNumber: '10DLC Number',
    app_key: 'App key',
    api_key: 'API key',
    accountSID: 'Account SID',
    applicationId: 'Application ID',
    phoneNumber: 'SMS number 1',
    phoneNumber2: 'SMS number 2',
    SenderID_1: 'SenderID 1',
    SenderID_2: 'SenderID 2',
    smsMarketingNumber: 'SMS makerting phone number',
    updateSMSSuccess: 'The sms integration has been updated successfully'
  },
  mDispatcher: {
    mDispatchers: 'mDispatchers',
    Active: 'Actif',
    Inactive: 'Inactif',
    Deactivate: 'Désactiver',
    Activate: 'Activer',
    Delete: 'Effacer',
    Save: 'Enregistrer',
    Edit: 'Modifier',
    ADD_MDISPATCHER: 'Ajouter un mDispatcher',
    EDIT_MDISPATCHER: 'Modifier un mDispatcher',
    Phone_number: 'Numéro de téléphone',
    first_name: 'Prénom',
    last_name: 'Nom',
    email: 'Email',
    address: 'Adresse',
    mDispatcher_type: 'Type mDispatcher',
    mDispatcher: 'mDispatcher',
    card_management: 'Card Management',
    Select_mDispatcher_type: 'Sélectionner le type mDispatcher',
    Amount: 'Montant',
    Percent: 'Pour cent',
    Yes: 'Yes',
    Required_fields: 'Champs requis',
    Cancel: 'Annuler',
    commission_type: 'Type de Commission',
    commission_value: 'Montant de la Commission',
    Name: 'Name',
    Phone_Number: 'Numéro de téléphone',
    mdispatcher_type: 'Type mDispatcher',
    registered_from: 'Enregistré à partir de',
    registration_date: 'Date d\'enregistrement',
    status: 'Statut',
    actions: 'Actions',
    queuing_area: 'Zone de file d\'attente',
    Select_queue_area: 'Sélectionner la zone de file d\'attente',
    for_new_mDipatcher_account_password_default_is_password: 'Pour un nouveau compte mDispatcher, le mot de passe par défaut est "password"',
    Create_mDispatcher_success: 'Le nouveau mDipatcher a été créé avec succès',
    Create_mDispatcher_fail: 'Les erreurs sont survenues lors de la création de mDispatcher',
    Load_mDispatcher_fail: 'Les erreurs sont survenues lors du chargement de mDispatcher',
    Update_mDispatcher_success: 'mDipatcher a été mis à jour avec succès',
    Update_mDispatcher_fail: 'Les erreurs sont survenues lors de la mise à jour de mDispatcher',
    Update_mDispatcher_status_success: 'Le statut de mDipatcher a été mis à jour avec succès',
    Update_mDispatcher_status_fail: 'Les erreurs se sont produites lors de la mise à jour de l\'état de mDispatcher',
    Update_mDispatcher_statuses_success: 'Les statuts de mDipatcher ont été mis à jour avec succès',
    Update_mDispatcher_statuses_fail: 'Les erreurs se sont produites lors de la mise à jour des statuts de mDispatcher',
    Delete_mDispatcher_success: 'mDipatcher a été supprimé avec succès',
    Delete_mDispatcher_fail: 'Les erreurs se sont produites lors de la suppression de mDispatcher',
    Delete_mDispatchers_success: 'mDipatchers a été supprimé avec succès',
    Delete_mDispatchers_fail: 'Les erreurs se sont produites lors de la suppression de mDispatchers',
    Do_you_want_to_delete_these_mDipatchers: 'Do you want to delete these mDipatchers?',
    Only_mDistcher_in_the_inactive_status_can_be_deleted: '***Just only mDistcher in the inactive status can be deleted',
    No_card: 'Pas de carte trouvée',
    Reset_Password: 'Réinitialiser le mot de passe',
    Reset_password_success: 'mDispatcher password has been reset successfully',
    Reset_password_fail: 'Les erreurs se sont produites lors de la réinitialisation du mot de passe mDispatchers',
    Do_you_want_to_reset_password: 'Do you want to reset password ?',
    ERROR_EXISTED_PHONE: 'Ce téléphone existe déjà sur le système. Veuillez en saisir un autre.',
    ERROR_INPUT_VALIDPHONE: 'Veuillez spécifier un numéro de téléphone valide',
    DETAIL_MDISPATCHER_TYPE: 'DETAIL MDISPATCHER TYPE',
    ALERT_SELECT_ACTIVATE: 'Veuillez sélectionner le mDispatcher à activer',
    ALERT_SELECT_DEACTIVATE: 'Veuillez sélectionner le mDispatcher à désactiver',
    CONFIRM_DEACTIVATE_MULTI: 'Voulez-vous supprimer ces mDispatcher?',
    CONFIRM_ACTIVATE_MULTI: 'Voulez-vous activer ces mDispatcher?',
    ERROR_COMMISSION_NUMBERS: 'S\'il vous plait, entrez un nombre valide',
    No_Support: 'New card adding is currently not supported in this area',
    Add: 'Ajouter',
  },
  mDispatcherType: {
    Name: 'Nom De La Ville',
    Required_fields: 'Champs requis',
    Save: 'Enregistrer',
    Cancel: 'Annuler',
    Add: 'Ajouter',
    Show_per_page: 'Eléments par page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Edit: 'Modifier',
    Delete: 'Effacer',
    Yes: 'Oui',
    DELETE_MDISPATCHER_TYPE: 'Effacer Type mDispatcher',
    DELETE_CONFIRM: 'Are you sure to delete mDispatcher Type?',
    EDIT_MDISPATCHER_TYPE: 'Modifier Type mDispatcher',
    ADD_MDISPATCHER_TYPE: 'Ajouter Type mDispatcher',
    DETAIL_MDISPATCHER_TYPE: 'Détail mDispatcher Type',
    Actions: 'Actions',
    Delete_mDispatcherType_success: 'Le type mDispatcher a été supprimé avec succès',
    Delete_mDispatcherType_fail: 'Les erreurs se sont produites lors de la suppression du type mDispatchers',
    Load_mDispatcherType_fail: 'Les erreurs se sont produites lors du chargement du type mDispatchers',
    Create_mDispatcherType_success: 'Le nouveau type mDispatcher a été créé avec succès',
    Create_mDispatcherType_fail: 'Les erreurs se sont produites lors de la création du type mDispatchers',
    Update_mDispatcherType_success: 'Le type mDispatcher a été mis à jour avec succès',
    Update_mDispatcherType_fail: 'The errors occurred while updating mDispatchers type',
  },
  queuingArea: {
    queuing_area_info: 'Queue area info',
    drivers: 'Queuing drivers',
    Name: 'Nom',
    Required_fields: 'Champs requis',
    Save: 'Enregistrer',
    Cancel: 'Annuler',
    Yes: 'Oui',
    Add: 'Ajouter',
    Show_per_page: 'Eléments par page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Edit: 'Modifier',
    Delete: 'Effacer',
    Activate: 'Activer',
    Active: 'Actif',
    Inactive: 'Inactif',
    Deactivate: 'Désactiver',
    keepDriverPlaceCancelNoShow: 'Keep driver\'s place when booking is canceled / no-show',
    keepDriverPlaceUnavailable: 'Keep driver\'s place when driver is unavailable',
    range: 'Queue range',
    queueRangeTooltip: `This is how drivers will see their queuing position number on driver app. For example:
       - Setting queue range at 1: drivers will see their position in queue as a single number: 1, 2, 3, 4, etc.
       - Setting queue range at 5: drivers will see their position in queue as a range: 1-5, 6-10, 11-15, 16-20, etc.
       - Setting queue range at 10: drivers will see their position in queue as a range: 1-10, 11-20, 21-30, 31-40, etc.`,
    DELETE_QUEUING_AREA: 'Delete Queuing Area',
    DELETE_CONFIRM: 'Do you want to delete Queuing Area?',
    EDIT_QUEUING_AREA: 'MODIFIER LA ZONE DE FILE D\'ATTENTE',
    ADD_QUEUING_AREA: 'AJOUTER ZONE DE FILE D\'ATTENTE',
    DETAIL_QUEUING_AREA: 'Détail de la zone de mise en file d\'attente',
    Queuing_area: 'Zone de file d\'attente',
    Address: 'Address',
    Enter_a_location: 'Entrez un lieu',
    Entered_Address_is_incorrect: 'Le système ne peut pas déterminer votre position. Veuillez utiliser l\'adresse suggéré.',
    Not_supported_zone: 'La zone de file d\'attente n\'est pas dans',
    Active_mDispatcher_list: 'Liste des mDispatcher actifs',
    Allow_radius: 'Rayon autorisé',
    Min_drivers_required: '# Min Pilotes nécessaires ',
    Open_queuing_area: 'Ouvrir la zone de file d\'attente',
    Active_car_list: 'Liste des véhicules actifs',
    Queue_list: 'Liste de file d\'attente',
    mDispatcher: 'mDispatcher',
    Google_Address: 'Adresse Google',
    Status: 'Statut',
    Update_status_error: 'Update status error',
    Load_queuing_area_fail: 'The errors occurred while loading queuing area',
    Create_queuingArea_success: 'Queuing area has been created successfully',
    Update_queuingArea_success: 'Queuing area has been updated successfully'
  },
  appBanner: {
    Save: 'Enregistrer',
    Pax_app: 'Appli passager',
    Apply_to: 'Appliquer à',
    Please_select_view: 'Veuillez sélectionner une vue',
    Home_view: 'Vue d\'accueil',
    Other_view: 'Autre vue ',
    Banner_1: 'Banière #1',
    Upload_an_image: 'Aucune image sélectionnée',
    Action: 'Action',
    NA: 'N/A',
    Link_to_website: 'Lien vers un site',
    Make_a_call: 'Appeler',
    Send_an_email: 'Envoyer un mail',
    Banner_2: 'Banière #2',
    Banner_3: 'Banière #3',
    Driver_app: 'Appli Chauffeur',
    note_1: '*L\'extension de fichier doit être .jpg, .jpeg, .png, .gif et moins de 5MB.',
    note_2: '*Les images doivent être au format 16:9',
    note_3: '*Les autres vue comprennent Boîte de réception, Parrainer un ami, Factures, Mes réservations, Codes Promo, Ajouter une CB/Modes de paiement, Profil, En attente d\'execution/Réservation Confirmée, Vue complète.'
  },
  reservation: {
    name: 'Reservation',
    company: 'Company',
    carType: 'Car type',
    licensePlate: 'License Plate',
    driver: 'Driver',
    showAllDrivers: 'Show All Drivers',
    available: 'Available',
    reserved: 'Reserved',
    search_holder: 'Search here',
    all: 'All',
    today: 'Today',
    thisWeek: 'This Week',
    day: 'Day',
    week: 'Week',
    bookingID: 'Booking ID',
    pickupTime: 'Pickup time',
    pickupLocation: 'Pickup location',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    overlapped: 'Overlapped',
    noData: 'Sorry! Nothing to show',
    bookingDetails: 'Reservation Details',
    reservedHint: 'Reservation bookings are confirmed',
    availableHint: 'Don\'t have any confirmed reservation bookings',
    overlappedHint: 'Two or more confirmed reservation bookings overlap',
  }
};
