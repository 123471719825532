import React, { useMemo, useState } from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import pickupNew from '../../../assets/images/icons/pickupNew.svg';
import destinationNew from '../../../assets/images/icons/destinationNew.svg';
import '../dispatchlogs.scss';
import { QQMap } from '../../qqMap';

const MapGps = (props) => {
  const [initState, setInitState] = useState({
    center: null,
    traffic: null,
    bicycling: null,
  });

  const RenderItemListMap = ({ dataBooking }) => {
    let pupoints = JSON.parse(JSON.stringify(!props.typeDelivery ? (dataBooking?.puPointsGps || dataBooking?.puPoints) : (dataBooking?.deliveryInfo?.merchants?.length > 0 ? dataBooking?.deliveryInfo?.merchants : [dataBooking?.deliveryInfo?.pickup?.address])))
    let dopoints = JSON.parse(JSON.stringify(!props.typeDelivery ? (dataBooking?.doPointsGps || dataBooking?.doPoints) : dataBooking?.deliveryInfo?.recipients))
    Array.isArray(dopoints) && dopoints?.pop()
    Array.isArray(dopoints) && dopoints?.push(props.lastPoint)
    return (
      <>
        {pupoints?.length > 0 && pupoints?.map(
          (item, index) => {
            let geoData = item?.geo || item?.address?.geo;
            return (
              geoData?.length > 0 && (
                <div key={index}>
                  <Marker
                    key={`${index}-${item.location}`}
                    position={{
                      lat: geoData[1],
                      lng: geoData[0],
                    }}
                    icon={{
                      url: pickupNew,
                      scaledSize: new window.google.maps.Size(32, 42),
                    }}
                    label={{
                      text: (index + 1).toString(),
                      color: 'white',
                      anchor: new window.google.maps.Point(15, 15),
                    }}
                    zIndex={100}
                  ></Marker>
                </div>
              )
            );
          }
        )}
        {dopoints?.length > 0 && dopoints?.map(
          (item, index) => {
            let geoData = item?.geo || item?.address?.geo;
            return (
              geoData?.length > 0 && (
                <div key={index}>
                  <Marker
                    key={`${index}-${item.location}`}
                    position={{
                      lat: geoData[1],
                      lng: geoData[0],
                    }}
                    icon={{
                      url: destinationNew,
                      scaledSize: new window.google.maps.Size(32, 42),
                    }}
                    label={{
                      text: (
                        pupoints
                          .length +
                        index +
                        1
                      ).toString(),
                      color: 'white',
                      anchor: new window.google.maps.Point(15, 15),
                    }}
                    zIndex={100}
                  ></Marker>
                </div>
              )
            );
          }
        )}
      </>
    );
  };

  const GettingStartedGoogleMap = withGoogleMap((val) => {
    const customMapStyle = [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ];
    return (
      <GoogleMap
        ref={val.handleUpdateMap}
        defaultZoom={15}
        zoom={15}
        center={val.center}
        onClick={val.onMapClick}
        options={{
          fullscreenControl: false,
          gestureHandling: 'greedy',
          styles: customMapStyle,
        }}
      >
        <RenderItemListMap
          dataBooking={val.dataBooking}
        />
      </GoogleMap>
    );
  });

  const MemoizedGoogleMap = useMemo(
    () => (
      <GettingStartedGoogleMap
        containerElement={<div className="mapqup fill" />}
        mapElement={<div style={{ height: `100%` }} />}
        center={props.panToLocation || { lat: 16.059959, lng: 108.224258 }}
        dataBooking={props.dataBooking}
        handleUpdateMap={props.handleUpdateMap}
      />
    ),
    [props.dataBooking, props.logsDataList, props.lastPoint]
  );

  const MemoizedQQMap = useMemo(
    () => (
      <QQMap
        className="fill"
        onLoad={props.handleUpdateMap}
        options={{ center: props.panToLocation }}
      />
    ),
    [props.logsDataList, props.dataBooking]
  );

  return props.commonData.location && props.commonData.location.isChina ? (
    <>{MemoizedQQMap}</>
  ) : (
    <div className="fill">{MemoizedGoogleMap}</div>
  );
};

export default MapGps;
