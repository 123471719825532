import React from 'react';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  Button,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import _ from 'lodash';

import { ValidCase, Validator } from '../../../components/validator';
import Select from '../../../components/select/Select';
import { Validation } from '../../../utils/commonFunctions';

import styles from '../styles/formVehicle.module.scss';
import IntlTelInputApp from '../../../components/intlTelInputCustome/IntlTelInputApp';
import DateTime from '../../../components/dateTime/DateTime';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import QuestionCircleTooltip from '../../../components/questionCircleTooltip/QuestionCircleTooltip';

const FormInfo = (valProps) => {
  const {
    isSubmitted,
    dialogData,
    state,
    ValidatorCallback,
    handleChangeVehicleMake,
    renderCarColorNoResult,
    handleLoadOption,
    handleCarColorChange,
    handleInputColorChange,
    handleInputBlur,
    handlePhoneChange,
    closeDialogForm,
    saveDialogForm,
    handleSelectRef,
    props,
    onEditable,
    handleInputChange,
    selectChange,
    action,
    handleChangeCarInsurance,
    supplierList,
  } = valProps;

  const generalSetting = props.auth?.selectedFleet?.generalSetting;
  
  let newCompaniesList = [...props?.commonData?.companies, ...supplierList];
  if (state.editable) {
    newCompaniesList = [...props?.commonData?.companies, ...supplierList.filter(item => item.isActive)]
    if (dialogData?.company?._id && supplierList.length && !newCompaniesList.find(item => item._id === dialogData?.company?._id)) {
      selectChange('company', '');    
    }
  }

  return (
    <>
      {props.vehicleId && 
        <Row>
          <Col xs={12} md={6} lg={4}>
            <CcCheckbox
              disabled={!state.editable}
              checked={dialogData ? dialogData.activeVehicle : false}
              onChange={(e) => {
                handleInputChange('activeVehicle', e);
              }}
              text={I18n.t('carSetting.activeVehicle')}
            />
          </Col>
        </Row>
      }
      <Row>
        <Col xs={12} md={6} lg={4}>
          <FormGroup
            className={
              isSubmitted
                ? state.valid.plateNumber === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="carSetting.Plate_Number" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <FormControl
              type="text"
              className="form-custom"
              onChange={(e) => {
                handleInputChange('plateNumber', e);
              }}
              value={dialogData.plateNumber || ''}
              placeholder={I18n.t('carSetting.Plate_Number')}
              maxLength={25}
              // required={true}
              disabled={!state.editable}
            />
            <Validator
              id="plateNumber"
              callback={ValidatorCallback}
              disabled={!state.editable}
            >
              <ValidCase
                hide={!isSubmitted}
                valid={dialogData.plateNumber.trim().length > 0}
                message={I18n.t('messages.commonMessages.Required_field')}
              />
            </Validator>
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroup
            className={
              isSubmitted ? (state.valid.type === false ? 'error' : null) : null
            }
          >
            <Form.Label>
              <Translate value="carSetting.Car_Type" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <FormControl
              as="select"
              className="form-custom"
              value={dialogData.type.typeId}
              onChange={(e) => {
                selectChange('type', e);
              }}
              disabled={!state.editable}
            >
              <option value="">{I18n.t('carSetting.Select_car_type')}</option>
              {state.carTypeList?.map((z) => (
                <option key={z._id} value={z._id}>
                  {z.vehicleType}
                </option>
              ))}
            </FormControl>
            <Validator
              id="type"
              callback={ValidatorCallback}
              disabled={!state.editable}
            >
              <ValidCase
                hide={!isSubmitted}
                valid={
                  dialogData.type &&
                  dialogData.type.typeId != '' &&
                  Object.keys(dialogData.type).length !== 0
                }
                message={I18n.t('messages.commonMessages.Required_field')}
              />
            </Validator>
          </FormGroup>
        </Col>
        {!props.auth?.user?.roles?.isSupplier && <Col xs={12} md={6} lg={4}>
          <FormGroup
            className={
              isSubmitted
                ? state.valid.company === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="carSetting.Company" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <FormControl
              disabled={
                !state.editable ||
                (!props.auth?.user?.roles?.isSupplier && props.auth?.user?.roles?.companyId && !props.companyIdFromDriverModule ? false :
                (valProps.isPermissionCompany ||
                props.companyIdFromDriverModule))
              }
              as="select"
              className="form-custom"
              onChange={(e) => {
                selectChange('company', e.target.value);
              }}
              value={dialogData.company ? dialogData.company._id : ''}
            >
              <option value="">{I18n.t('carSetting.Select_company')}</option>
              {newCompaniesList?.map((z) => (
                <option key={z._id} value={z._id}>
                  {z.name}
                </option>
              ))}
            </FormControl>
            <Validator
              id="company"
              callback={ValidatorCallback}
              disabled={!state.editable}
            >
              <ValidCase
                hide={!isSubmitted}
                valid={
                  dialogData.company &&
                  dialogData.company != null &&
                  Object.keys(dialogData.company).length !== 0
                }
                message={I18n.t('messages.commonMessages.Required_field')}
              />
            </Validator>
          </FormGroup>
        </Col>}
        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="carSetting.Make" />
            </Form.Label>
            <FormControl
              as="select"
              className="form-custom"
              disabled={!state.editable}
              onChange={handleChangeVehicleMake}
              value={
                dialogData.vehicleModel
                  ? dialogData.vehicleModel.vehicleMakeId
                  : ''
              }
            >
              <option>{I18n.t('carSetting.Select_car_make')}</option>
              {state.makeList?.map((z) => (
                <option key={z._id} value={z._id}>
                  {z.name}
                </option>
              ))}
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="carSetting.Model" />
            </Form.Label>
            <FormControl
              as="select"
              className="form-custom"
              disabled={!state.editable}
              onChange={(e) => {
                selectChange('vehicleModel_modelId', e);
              }}
              value={
                dialogData.vehicleModel ? dialogData.vehicleModel.modelId : ''
              }
            >
              <option>{I18n.t('carSetting.Select_a_car_model')}</option>
              {state.modelList?.map((z) => (
                <option key={z._id} value={z._id}>
                  {z.name}
                </option>
              ))}
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="carSetting.Year" />
            </Form.Label>
            <FormControl
              as="select"
              className="form-custom"
              disabled={!state.editable}
              onChange={(e) => {
                selectChange('year', e);
              }}
              value={dialogData.year}
            >
              <option>{I18n.t('carSetting.Select_year')}</option>
              {state.yearList
                ? state.yearList.map((z) => (
                    <option key={z} value={z}>
                      {z}
                    </option>
                  ))
                : ''}
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="carSetting.Color" />
            </Form.Label>
            {state.editable ? (
              <Select.Async
                multi={false}
                valueKey="_id"
                labelKey="name"
                cache={false}
                ref={handleSelectRef}
                placeholder={I18n.t('messages.car_mgmt.Search_car_color')}
                searchPromptText={''}
                loadingPlaceholder={I18n.t('messages.car_mgmt.Searching')}
                noResultsText={renderCarColorNoResult()}
                className="receiver-list form-custom custom-select-control none-bg-arrow car-color-suggest"
                value={state?.colorSelected?.name && state?.colorSelected}
                loadOptions={handleLoadOption}
                onChange={handleCarColorChange}
                disabled={!state.editable}
                ignoreAccents={false}
                // ignoreCase={false}
                onInputChange={handleInputColorChange}
                onBlur={handleInputBlur}
              />
            ) : (
              <FormControl
                type="text"
                className="form-custom"
                onChange={(e) => {
                  handleInputChange('color', e);
                }}
                value={dialogData ? dialogData.color : ''}
                placeholder={I18n.t('carSetting.Color')}
                disabled={!state.editable}
              />
            )}
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroup
            className={
              isSubmitted
                ? state.valid.maxPassengers === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="carSetting.Max_Passengers" />
            </Form.Label>
            <FormControl
              type="number"
              className="form-custom"
              onChange={(e) => {
                handleInputChange('maxPassengers', e);
              }}
              value={dialogData ? dialogData.maxPassengers : ''}
              placeholder={I18n.t('carSetting.Max_Passengers')}
              disabled={!state.editable}
            />
            <Validator
              id="maxPassengers"
              callback={ValidatorCallback}
              disabled={!state.editable}
            >
              <ValidCase
                hide={!isSubmitted}
                valid={Validation.isInteger(dialogData.maxPassengers)}
                message={I18n.t('messages.commonMessages.must_be_integer')}
              />
              <ValidCase
                hide={!isSubmitted}
                valid={Validation.isGreaterOrEqual(dialogData.maxPassengers, 0)}
                message={I18n.t('messages.commonMessages.greater_than_0')}
              />
            </Validator>
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroup
            className={
              isSubmitted
                ? state.valid.maxLuggage === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="carSetting.Max_Luggage" />
            </Form.Label>
            <FormControl
              type="number"
              className="form-custom"
              onChange={(e) => {
                handleInputChange('maxLuggage', e);
              }}
              value={dialogData ? dialogData.maxLuggage : ''}
              placeholder={I18n.t('carSetting.Max_Luggage')}
              disabled={!state.editable}
            />
            <Validator
              id="maxLuggage"
              callback={ValidatorCallback}
              disabled={!state.editable}
            >
              <ValidCase
                hide={!isSubmitted}
                valid={Validation.isInteger(dialogData.maxLuggage)}
                message={I18n.t('messages.commonMessages.must_be_integer')}
              />
              <ValidCase
                hide={!isSubmitted}
                valid={Validation.isGreaterOrEqual(dialogData.maxLuggage, 0)}
                message={I18n.t('messages.commonMessages.greater_than_0')}
              />
            </Validator>
          </FormGroup>
        </Col>
      </Row>

      <div className={styles['additional-title']}>
        <Translate value="carSetting.additionalVehicleTitle" />
      </div>

      <Row>
        <Col xs={12} md={6} lg={4}>
          <FormGroup
            className={
              isSubmitted
                ? state.valid.vehicleOwner === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="carSetting.vehicleOwner" />
            </Form.Label>
            <FormControl
              type="text"
              className="form-custom"
              onChange={(e) => {
                handleInputChange('vehicleOwner', e);
              }}
              value={dialogData ? dialogData.vehicleOwner : ''}
              placeholder={I18n.t('carSetting.vehicleOwner')}
              maxLength={100}
              disabled={!state.editable}
            />
          </FormGroup>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="carSetting.VIN" />
            </Form.Label>
            <FormControl
              type="text"
              className="form-custom"
              onChange={(e) => {
                handleInputChange('vhcId', e);
              }}
              value={dialogData ? dialogData.vhcId || '' : ''}
              placeholder={I18n.t('carSetting.VIN')}
              disabled={!state.editable}
            />
          </FormGroup>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="carSetting.Case_number" />
            </Form.Label>
            <FormControl
              type="text"
              className="form-custom"
              onChange={(e) => {
                handleInputChange('caseNumber', e);
              }}
              value={dialogData ? dialogData.caseNumber : ''}
              placeholder={I18n.t('carSetting.Case_number')}
              maxLength={50}
              disabled={!state.editable}
            />
          </FormGroup>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <FormGroup
            className={!isSubmitted ? null : state.valid.phone ? null : 'error'}
          >
            <Form.Label>
              <Translate value="carSetting.Phone_Number" />
              <QuestionCircleTooltip
                text={<Translate value="carSetting.Phone_Number_Tooltip" />}
              />
            </Form.Label>
            <FormGroup>
              <IntlTelInputApp
                css={['intl-tel-input', 'form-control form-custom']}
                utilsScript="libphonenumber.js"
                value={dialogData ? dialogData.phone : ''}
                onPhoneNumberChange={handlePhoneChange}
                disabled={!state.editable}
              />
              <Validator id="phone" callback={ValidatorCallback}>
                <ValidCase
                  hide={!isSubmitted}
                  valid={state.phoneValidation}
                  message={I18n.t('carSetting.ERROR_INPUT_VALIDPHONE')}
                />
              </Validator>
            </FormGroup>
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="carSetting.License_expiry" />
            </Form.Label>
            <DateTime
              inputProps={{
                readOnly: true,
                disabled: !state.editable,
              }}
              value={
                dialogData.licenseExp
                  ? moment(dialogData.licenseExp)
                      .locale(props.language.toLowerCase())
                      .format('L')
                  : ''
              }
              timeFormat={false}
              dateFormat={true}
              onChange={(e) => {
                handleInputChange('licenseExp', e);
              }}
              placeholder={I18n.t('driver.placeholderDate')}
              closeOnSelect
            />
          </FormGroup>
        </Col>
      </Row>

      {generalSetting?.showInInsuranceFieldsAndVhcLicenseExp && (
        <>
          <div className={styles['additional-title']}>
            <Translate value="driver.CAR_INSURANCE" />
          </div>

          <Row>
            <Col xs={12} md={6} lg={4}>
              <FormGroup
                className={
                  !isSubmitted || !generalSetting.compulsoryCarInsurance
                    ? null
                    : !state.valid.policyNumber
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="driver.Policy_number" />
                  {generalSetting.compulsoryCarInsurance && (
                    <span className="require"> *</span>
                  )}
                </Form.Label>
                <FormControl
                  type="text"
                  value={dialogData?.insuranceVehicle?.policyNumber}
                  onChange={(e) => handleChangeCarInsurance('policyNumber', e)}
                  disabled={!state.editable}
                  className="form-custom"
                />
                <Validator id="policyNumber" callback={ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={
                      !generalSetting.compulsoryCarInsurance ||
                      !Validation.isStringEmpty(
                        dialogData?.insuranceVehicle?.policyNumber
                      )
                    }
                    message={'Please input policy number.'}
                  />
                </Validator>
              </FormGroup>
            </Col>

            <Col xs={12} md={6} lg={4}>
              <FormGroup
                className={
                  !isSubmitted || !generalSetting.compulsoryCarInsurance
                    ? null
                    : !state.valid.effectiveDate
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="driver.Effective_date" />
                  {generalSetting.compulsoryCarInsurance && (
                    <span className="require"> *</span>
                  )}
                </Form.Label>
                <FormControl
                  type="text"
                  value={dialogData?.insuranceVehicle?.effectiveDate}
                  onChange={(e) => handleChangeCarInsurance('effectiveDate', e)}
                  disabled={!state.editable}
                  className="form-custom"
                  placeholder={I18n.t('driver.placeholderDate')}
                />
                <Validator id="effectiveDate" callback={ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={
                      !generalSetting.compulsoryCarInsurance ||
                      !Validation.isStringEmpty(
                        dialogData?.insuranceVehicle?.effectiveDate
                      )
                    }
                    message={'Please input effective date.'}
                  />
                </Validator>
              </FormGroup>
            </Col>

            <Col xs={12} md={6} lg={4}>
              <FormGroup
                className={
                  !isSubmitted || !generalSetting.compulsoryCarInsurance
                    ? null
                    : !state.valid.expiredDate
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="driver.Expired_date" />
                  {generalSetting.compulsoryCarInsurance && (
                    <span className="require"> *</span>
                  )}
                </Form.Label>
                <FormControl
                  type="text"
                  value={dialogData?.insuranceVehicle?.expiredDate}
                  onChange={(e) => handleChangeCarInsurance('expiredDate', e)}
                  disabled={!state.editable}
                  className="form-custom"
                  placeholder={I18n.t('driver.placeholderDate')}
                />
                {generalSetting.compulsoryCarInsurance && (
                  <Validator id="expiredDate" callback={ValidatorCallback}>
                    <ValidCase
                      hide={!isSubmitted}
                      valid={
                        !Validation.isStringEmpty(
                          dialogData?.insuranceVehicle?.expiredDate
                        )
                      }
                      message={'Please input expired date'}
                    />
                  </Validator>
                )}
              </FormGroup>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col xs={12} md={6} lg={4}>
          <CcCheckbox
            disabled={!state.editable}
            checked={dialogData ? dialogData.hwMetered : false}
            onChange={(e) => {
              handleInputChange('hwMetered', e);
            }}
            text={I18n.t('carSetting.Hardware_Meter')}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6} lg={4}>
          <Button
            style={{ padding: '6px 15px' }}
            className="btn-cancel mt-md"
            onClick={closeDialogForm}
          >
            <Translate value="carSetting.discardChange" />
          </Button>
          {!props.permissions.actions && props.permissions.add && action === 'add' && (
            <Button
              style={{ padding: '6px 15px', minWidth: 'unset' }}
              className="btn-save ml-md mt-md"
              onClick={saveDialogForm}
            >
              <Translate
                value={'carSetting.Save'}
              />
            </Button>
          )}
          {!props.permissions || props.permissions.actions ? (
            state.editable ? (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={saveDialogForm}
              >
                <Translate
                  value={
                    action === 'add' ? 'carSetting.Save' : 'carSetting.Update'
                  }
                />
              </Button>
            ) : (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={onEditable}
              >
                <Translate value="carSetting.Edit" />
              </Button>
            )
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default FormInfo;
