/* global google */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Button,
  ButtonToolbar,
  
  Modal,
  Dropdown,
  Form,
  Tabs,
  Tab
} from 'react-bootstrap';
import TimeZonePicker from '../../../components/timeZonePicker/timeZonePicker';
import TimeZones from '../../../components/timeZonePicker/timezones';

import _ from 'lodash';
import { geoPlaceDetailMapProvider } from '../../../utils/mapUtils';
import PlacesAutocomplete from '../../../components/placesAutocomplete/PlacesAutocomplete';
import * as intercityZoneActions from '../../../actions/intercityZoneActions';
import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import * as uploadActions from '../../../actions/uploadActions';
import { CCLiteCommonFunc, Validation, ObjectId, getLanguageAutoCompleteSearch } from '../../../utils/commonFunctions';
import { Validator, ValidCase } from '../../../components/validator';
import { QQAutocomplete, QQUltis } from '../../../components/qqMap';
import TencentMap from '../../../components/ZoneTencentMap';
import GettingStartedGoogleMap from '../../../components/ZoneGoogleMap';
import { ZoneActionStatus as ActionStatus } from '../../../constants/commondata';
import UploadFile from '../../../components/upload/upload';
import UploadImageTemplate from '../../../components/upload/UploadImageTemplate';
import QuestionCircleTooltip from '../../../components/questionCircleTooltip/QuestionCircleTooltip';
import CcCheckbox from "../../../components/ccCheckbox/CcCheckbox";
import AutocompleteWith3rd from '../../../components/qqMap/AutocompleteWith3rd';
class IntercityZone extends Component {

  constructor() {
    super();
    this.state = {
      center: {
        lat: 16.059959,
        lng: 108.224258
      },
      addressPickUp: '',
      selectedItem: {
        name: '',
        timezone: ''
      },
      ActionStatus: ActionStatus.View,
      polyline: false,
      zoneDraw: {
        activeZone: null,
        Zones: []
      },
      valid: {},
      activePolylinePath: [],
      isSubmitted: false,
      defaultZoom: 10,
      searchZone: '',
      key: 'importFlatZone',
      zoneFlats: [],
      zoneFlatSelect: [],
      showConfirmImport: false,
      dataResponse: {},
      showImportButton: false
    };
    this.handleSelectPickUp = this.handleSelectPickUp.bind(this);
    this.handleChangePickUp = this.handleChangePickUp.bind(this);
    this.handleAddressRemovedPickUp = this.handleAddressRemovedPickUp.bind(
      this
    );
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleZoneRowClick = this.handleZoneRowClick.bind(this);
    this.onPolygonComplete = this.onPolygonComplete.bind(this);
    this.handlePolylineClick = this.handlePolylineClick.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleMapLoad = this.handleMapLoad.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleClearDrawClick = this.handleClearDrawClick.bind(this);
    this.handleZoneNameChange = this.handleZoneNameChange.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleimportButtonClick = this.handleimportButtonClick.bind(this);
    this.handleImportFileChange = this.handleImportFileChange.bind(this);
    this.handleRemoveSelectedFile = this.handleRemoveSelectedFile.bind(this);
    this.confirmDeleteCity = this.confirmDeleteCity.bind(this);
    this.closeDialogForm = this.closeDialogForm.bind(this);
    this.setKey = this.setKey.bind(this);
    this.handleAddFlatZone = this.handleAddFlatZone.bind(this);
    this.avatarRef = React.createRef();
  }

  componentDidMount() {
    this.props.intercityZoneActions
      .getAllMapZone(this.props.auth.selectedFleet.fleetId)
      .then(data => {
        if (data.res) {
          this.state.zoneDraw.activeZone = null;

          const newZones = [];
          _.forEach(data.res, zone => {
            if (
              zone.geo &&
              zone.geo.coordinates &&
              zone.geo.coordinates[0] &&
              _.isArray(zone.geo.coordinates[0][0])
            ) {
              newZones.push({
                _id: zone._id,
                zoneName: zone.zoneName,
                timezone: zone.timezone,
                isActive: zone.activate,
                inUse: zone.inUse,
                avatar: zone.avatar,
                path: zone.geo.coordinates[0].map(pos => ({
                  lat: pos[1],
                  lng: pos[0]
                }))
              });
            }
          });
          this.state.zoneDraw.Zones = newZones;

          // this.state.zoneDraw.Zones = data.res.map(zone => {
          //   return {
          //     _id: zone._id,
          //     zoneName: zone.zoneName,
          //     isActive: zone.activate,
          //     inUse: zone.inUse,
          //     path: zone.geo.coordinates[0].map(pos => {
          //       return {
          //         lat: pos[1],
          //         lng: pos[0]
          //       };
          //     })
          //   };
          // });

          const center = {
            lat: 16.059959,
            lng: 108.224258
          };
          let allPoints = [];
          this.state.zoneDraw.Zones.map(obj => {
            allPoints = allPoints.concat(obj.path);
          });
          if (allPoints && allPoints.length <= 0) {
            const { mapZone } = this.props.commonData;
            if (mapZone && mapZone.length > 0) {
              const defaultZone = mapZone.find(obj => obj.isDefault);
              if (defaultZone) {
                let { center } = defaultZone;
                if (
                  defaultZone.center.coordinates &&
                  defaultZone.center.coordinates.length <= 0
                ) {
                  center = defaultZone.geo;
                }
                allPoints =
                  center.coordinates[0] &&
                  center.coordinates[0].map(obj => ({
                    lat: obj[1],
                    lng: obj[0]
                  }));
              }
            }
          }
          if (allPoints && allPoints.length > 0) {
            this.mapZoneLoaded = true;
            if (
              this.props.commonData.location &&
              this.props.commonData.location.isChina
            ) {
              var latLng = new window.qq.maps.LatLngBounds();
              allPoints.map(pos => {
                latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
              });
              // for some reason i dont know but it needs to setTimeout for function this.map.fitBounds(latLng) working
              setTimeout(() => {
                this.map.fitBounds(latLng);
                if (allPoints.length == 1) {
                  this.map.setZoom(15);
                }
              });
            } else {
              var latLng = new google.maps.LatLngBounds();
              allPoints.map(pos => {
                latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
              });
              if (this._map) {
                this._map.fitBounds(latLng);
                if (allPoints.length == 1) {
                  this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
                    15
                  );
                }
              } else {
                setTimeout(() => {
                  this._map && this._map.fitBounds(latLng);
                  if (allPoints.length == 1) {
                    this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
                      15
                    );
                  }
                }, 100);
              }
            }
          }

          this.setState({
            zoneDraw: this.state.zoneDraw,
            ActionStatus: ActionStatus.View
          });
        }
      });
    this.props.settingActions.getAllMapZone(this.props.auth.selectedFleet.fleetId).then(data => {
      if (data.res) {
        const newZones = [];
        _.forEach(data.res, zone => {
          newZones.push({
            _id: zone._id,
            zoneName: zone.zoneName
          });
        });
        this.state.zoneFlats = newZones;
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.mapZoneLoaded) {
      let allPoints = [];
      const { mapZone } = this.props.commonData;
      if (mapZone && mapZone.length > 0) {
        const defaultZone = mapZone.find(obj => obj.isDefault);
        if (defaultZone) {
          allPoints =
            defaultZone.center.coordinates[0] &&
            defaultZone.center.coordinates[0].map(obj => ({
              lat: obj[1],
              lng: obj[0]
            }));
        }
      }
      if (allPoints && allPoints.length > 0) {
        this.mapZoneLoaded = true;
        if (
          this.props.commonData.location &&
          this.props.commonData.location.isChina
        ) {
          var latLng = new window.qq.maps.LatLngBounds();
          allPoints.map(pos => {
            latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
          });
          // for some reason i dont know but it needs to setTimeout for function this.map.fitBounds(latLng) working
          setTimeout(() => {
            this.map.fitBounds(latLng);
            this.map.setZoom(15);
          });
        } else {
          var latLng = new google.maps.LatLngBounds();
          allPoints.map(pos => {
            latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
          });
          if (this._map) {
            this._map.fitBounds(latLng);
            this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
              15
            );
          } else {
            setTimeout(() => {
              this._map.fitBounds(latLng);
              this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
                15
              );
            }, 100);
          }
        }
      }
    }
  }
  setKey(k) {
    this.setState({ key: k });
  }
  handleSelectPickUp(addressPickUp, placeId, pointOfInterest, sessionToken) {
    this.setState({ addressPickUp });
    if (
      this.props.commonData.location &&
      this.props.commonData.location.isChina
    ) {
      const callback = (err, location, results) => {
        if (location) {
          this.map.setCenter(location);
          this.map.setZoom(18);
        } else {
          this.context.notification('warning', 'location not found!');
        }
      }
      geoPlaceDetailMapProvider({
        auth: this.props.auth,
        placeid: placeId,
        sessionToken,
        callback,
        isTencent: true,
        language: this.props.language
      });
    } else if (addressPickUp && addressPickUp != '') {
      this.setState({
        addressPickUp
      });

      const callback = (err, location, results) => {
        if (err) {
        }
        this.setState({ center: location, defaultZoom: 18 });
        if (this._map) {
          this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
            18
          );
        }
      };

      geoPlaceDetailMapProvider({
        auth: this.props.auth,
        placeid: placeId,
        sessionToken,
        callback,
        language: this.props.language
      });
    }
  }

  handleChangePickUp(addressPickUp) {
    this.setState({ addressPickUp });
  }
  handleAddFlatZone = (e) => {
    const newZonesFlat = this.state.zoneFlatSelect;
    if (e.target.checked) {
      let checkZone = newZonesFlat.filter(function (n) {
        return n === e.target.defaultValue;
      });
      if (checkZone.length === 0) {
        newZonesFlat.push(e.target.defaultValue);
      }
    } else {
      let checkZone = newZonesFlat.indexOf(e.target.defaultValue);
      if (checkZone !== -1) {
        newZonesFlat.splice(checkZone, 1);
      };
    };
    this.setState({ zoneFlatSelect: newZonesFlat });
  };

  handleAddressRemovedPickUp() {
    if (window.searchPlaceMarked) {
      window.searchPlaceMarked.setMap(null);
    }
    this.setState({ addressPickUp: '' });
  }

  handleMenuClick(action, zone, e) {
    switch (action) {
      case ActionStatus.Edit: {
        let center = null;
        if (
          this.props.commonData.location &&
          this.props.commonData.location.isChina
        ) {
          this.state.zoneDraw.activeZone = Object.assign({}, zone);
          var latLng = new window.qq.maps.LatLngBounds();
          zone.path.map(pos => {
            latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
          });
          if (this._map) {
            this._map.fitBounds(latLng);
          }
          center = latLng.getCenter();
        } else {
          var latLng = new google.maps.LatLngBounds();
          this.state.zoneDraw.activeZone = Object.assign({}, zone);
          zone.path.map(pos => {
            latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
          });
          if (this._map) {
            this._map.fitBounds(latLng);
          }
          center = latLng.getCenter();
        }

        if (!zone.timezone) {
          zone.timezone = this.props.selectedFleet.timezone
        };

        this.setState({
          selectedItem: zone,
          ActionStatus: ActionStatus.Edit,
          center
        });
        break;
      }
      case ActionStatus.Delete: {
        if (!zone.isActive) {
          this.setState({ dialogData: zone, showConfirm: true });
        }
        break;
      }
      case ActionStatus.Status:
        {
          e && e.stopPropagation();
          if (zone.isActive) {
            this.props.intercityZoneActions
              .deactiveZone({
                fleetId: this.props.auth.selectedFleet.fleetId,
                zoneId: zone._id
              })
              .then(data => {
                if (data.ok && !data.error) {
                  this.componentDidMount();
                } else if (data.error) {
                  this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
                }
              });
          } else {
            this.props.intercityZoneActions
              .activeZone({
                fleetId: this.props.auth.selectedFleet.fleetId,
                zoneId: zone._id
              })
              .then(data => {
                if (data.ok && !data.error) {
                  this.componentDidMount();
                } else if (data.error) {
                  this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
                }
              });
          }
        }
        break;
    }
  }

  handleZoneRowClick(zone) {
    let center = null;
    if (
      this.props.commonData.location &&
      this.props.commonData.location.isChina
    ) {
      this.state.zoneDraw.activeZone = zone;
      var latLng = new window.qq.maps.LatLngBounds();
      zone.path.map(pos => {
        latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
      });
      if (this._map) {
        this._map.fitBounds(latLng);
      }
      center = latLng.getCenter();
    } else {
      this.state.zoneDraw.activeZone = zone;
      var latLng = new google.maps.LatLngBounds();
      zone.path.map(pos => {
        latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
      });
      if (this._map) {
        this._map.fitBounds(latLng);
      }
      center = latLng.getCenter();
    }

    this.setState({
      selectedItem: zone,
      ActionStatus: ActionStatus.View,
      center,
      polyline: false
    });
  }

  onPolygonComplete(data) {
    console.log('===================', data);
  }

  handlePolylineClick(e) {
    if (e.vertex == 0) {
      const isChina =
        this.props.commonData.location &&
        this.props.commonData.location.isChina;
      if (isChina) {
        const lastPath = this.state.zoneDraw.activeZone.path[
          this.state.zoneDraw.activeZone.path.length - 1
        ];
        if (!(e.latLng.lat == lastPath.lat && e.latLng.lng == lastPath.lng)) {
          this.state.zoneDraw.activeZone.path.push({
            lat: e.latLng.lat,
            lng: e.latLng.lng
          });
          this.Polygon = this.state.zoneDraw.activeZone.path;
        }
      } else {
        this.state.zoneDraw.activeZone.path.push(e.latLng.toJSON());
      }
      this.setState({ polyline: false, zoneDraw: this.state.zoneDraw });
    }
  }

  handleMapClick(e) {
    if (
      this.state.ActionStatus == ActionStatus.Add ||
      this.state.ActionStatus == ActionStatus.Edit
    ) {
      const isChina =
        this.props.commonData.location &&
        this.props.commonData.location.isChina;
      if (isChina) {
        const { latLng } = e;
        if (latLng) {
          let polylinePath = this.Polyline
            ? this.Polyline.getPath().elems.map(obj => ({
              lat: obj.lat,
              lng: obj.lng
            }))
            : null;
          if (!this.state.polyline) {
            // this.Polygon = {
            //     path:
            // }
          } else {
            if (polylinePath) {
              polylinePath.push({
                lat: latLng.lat,
                lng: latLng.lng
              });
            } else {
              polylinePath = [];
              polylinePath.push({
                lat: latLng.lat,
                lng: latLng.lng
              });
            }
            this.state.zoneDraw.activeZone.path = polylinePath;
            this.setState({ zoneDraw: this.state.zoneDraw });
          }
        }
      } else {
        if (!this.state.polyline) {
          this.state.zoneDraw.activeZone.path = this.Polygon.getPaths()
            .getAt(0)
            .getArray()
            .map(p => p.toJSON());
        } else {
          this.state.zoneDraw.activeZone.path = this.Polyline.getPath()
            .getArray()
            .map(p => p.toJSON());
          this.state.zoneDraw.activeZone.path.push(e.latLng.toJSON());
        }
        this.setState({ zoneDraw: this.state.zoneDraw });
      }
    }
  }

  handleAddButtonClick() {
    this.state.zoneDraw.activeZone = { path: [], zoneName: '' };
    this.setState({
      ActionStatus: ActionStatus.Add,
      selectedItem: {},
      polyline: true
    });
  }

  handleimportButtonClick() {
    this.state.zoneDraw.activeZone = {};
    this.setState({
      ActionStatus: ActionStatus.Import,
      selectedItem: null,
      polyline: false
    });
  }

  handleCancelButtonClick() {
    this.state.zoneDraw.activeZone = null;
    this.setState({
      ActionStatus: ActionStatus.View,
      zoneDraw: this.state.zoneDraw,
      importFile: null,
      importContent: null,
      polyline: false,
      isSubmitted: false
    });
  }

  handleZoneNameChange(e) {
    this.state.selectedItem.zoneName = e.target.value;
    const isChina =
      this.props.commonData.location && this.props.commonData.location.isChina;
    if (!this.state.polyline) {
      if (isChina) {
      } else {
        this.state.zoneDraw.activeZone.path = this.Polygon.getPaths()
          .getAt(0)
          .getArray()
          .map(p => p.toJSON());
      }
    } else if (isChina) {
    } else {
      this.state.zoneDraw.activeZone.path = this.Polyline.getPath()
        .getArray()
        .map(p => p.toJSON());
    }
    this.setState({ selectedItem: { ...this.state.selectedItem } });
  }



  handleImageChange = (changeFile, error) => {
    const { selectedItem } = this.state;
    const data = changeFile || {};

    // validate file size 8MB
    if (error && error === 'INVALID_FILE_SIZE') {
      this.context.notification('error', I18n.t('upload_document.Limit_size_upload').format(5));
      if (this.avatarRef && this.avatarRef.current) {
        this.avatarRef.current.inputElement.current.inputElement.value = ''
      }
      return;
    }
    selectedItem.avatar = data.result || null;
    this.setState({
      selectedItem: { ...selectedItem },
      file: data.file || null
    });
  };

  handleImportFileChange(e) {
    e.preventDefault();
    this.state.importFile = null;
    this.state.importContent = null;
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsText(file);
    reader.onloadend = event => {
      const dataInFile = JSON.parse(event.target.result);
      const checkFile = new Promise(
        function (resolve, reject) {
          dataInFile.features.forEach(e => {
            if (!e.properties.timezone || e.properties.timezone === "") {
              return reject(false);
            };
            let searchLower = e.properties.timezone.trim().toLowerCase();
            const query = TimeZones.filter(item => item.timezone.toLowerCase().indexOf(searchLower) !== -1)
            if (query.length <= 0) {
              return reject(false);
            };
          });
          return resolve(true);
        }
      );

      checkFile.then(result => {
        if (result) {
          try {
            this.setState({ importFile: file, showImportButton: true });
          } catch (error) { }
        }
      }).catch(reason => {
        this.context.notification('error', I18n.t('errors.upload_file_fail'));
      });
    };
    // reader.readAsDataURL(file);
  }

  handleUploadFile = zoneObject => {
    const { file, selectedItem } = this.state;
    // console.log("IntercityZone -> handleImportFileChange -> selectedItem", selectedItem)
    const {
      uploadService,
      auth: { selectedFleet }
    } = this.props;

    if (file) {
      const form = new FormData();
      form.append('photo', file);
      form.append('fleetId', selectedFleet.fleetId);
      form.append('forcePath', `images/${selectedFleet.fleetId}/zone/${zoneObject.zoneId}.png`);
      return uploadService.uploadPhoto(form).then(res => {
        if (!res.error) {
          return Promise.resolve(_.get(res, 'res.Location'));
        }
        this.context.notification('error', I18n.t('errors.upload_photo_fail'));
        return Promise.resolve({ error: res.error });
      });
    }
    return Promise.resolve(selectedItem.avatar);
  };

  handleSaveButtonClick() {
    if (this.state.ActionStatus == ActionStatus.Import) {
      let params = {}
      if(this.state.key === 'importFile') {
        params = new FormData();
        params.append('fleetId', this.props.auth.selectedFleet.fleetId);
        params.append('timezone', this.state.timezoneImport || this.props.auth.selectedFleet.timezone);
        params.append('zones', this.state.importFile);
        params.append('zoneFlatSelect', this.state.zoneFlatSelect);
        params.append('type', this.state.key);
      } else {
        params = {
          fleetId: this.props.auth.selectedFleet.fleetId,
          timezone: this.state.timezoneImport || this.props.auth.selectedFleet.timezone,
          zoneFlatSelect: this.state.zoneFlatSelect,
          type: this.state.key
        }
      }
      this.props.intercityZoneActions.importMapZone(params, this.state.key === 'importFile').then(data => {
        if (data.ok && data.res) {
          this.componentDidMount();
          this.setState({ dataResponse: data.res, showConfirmImport: true, importFile: null, zoneFlatSelect: [] });
        } else {
          this.context.notification(
            'error',
            I18n.t('zoneSetting.Import_zones_fail')
          );
        }
      });
    } else {
      let path = null;
      const isChina =
        this.props.commonData.location &&
        this.props.commonData.location.isChina;
      this.setState({ isSubmitted: true });
      if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
        return;
      }
      if (this.state.polyline) {
        if (isChina) {
          const polylinePath = this.Polyline
            ? this.Polyline.getPath().elems.map(obj => ({
              lat: obj.lat,
              lng: obj.lng
            }))
            : null;
          if (!polylinePath) {
            this.context.notification(
              'error',
              I18n.t('zoneSetting.Draw_zone'),
              ''
            );
            return;
          }
          if (polylinePath.length > 1) {
            polylinePath.push(polylinePath[0]);
          }
          path = [polylinePath.map(obj => [obj.lng, obj.lat])];
        } else {
          path = this.Polyline.getPath()
            .getArray()
            .map(p => [p.toJSON().lng, p.toJSON().lat]);
          if (!path[0]) {
            this.context.notification(
              'error',
              I18n.t('zoneSetting.Draw_zone'),
              ''
            );
            return;
          }
          if (
            path[0][0] != path[path.length - 1][0] ||
            path[0][1] != path[path.length - 1][1]
          ) {
            path.push(path[0]);
          }
          path = [path];
        }
      } else if (isChina) {
        // get Polygon from zoneId
        let qqPath = [];
        let editingPolygon = null;
        if (this.state.ActionStatus == ActionStatus.Edit) {
          editingPolygon = this[`Polygon_${this.state.selectedItem._id}`];
        } else if (this.state.ActionStatus == ActionStatus.Add) {
          editingPolygon = this.Polygon;
        }
        if (editingPolygon) {
          qqPath = editingPolygon.getPath().elems;
          if (qqPath && qqPath.length > 0) {
            path = [qqPath.map(obj => [obj.lng, obj.lat])];
            const firstPoint = path[0][0];
            const lastPoint = path[0][path[0].length - 1];
            if (
              !(firstPoint[0] == lastPoint[0] && firstPoint[1] == lastPoint[1])
            ) {
              path[0].push(firstPoint.slice());
            }
          }
        }
      } else {
        path = this.Polygon.getPaths()
          .getArray()
          .map(path => {
            const poly = path
              .getArray()
              .map(p => [p.toJSON().lng, p.toJSON().lat]);
            if (
              poly[0][0] != poly[poly.length - 1][0] ||
              poly[0][1] != poly[poly.length - 1][1]
            ) {
              poly.push(poly[0]);
            }
            return poly;
          });
        this.state.zoneDraw.activeZone.path = path[0].map(pos => ({
          lat: pos[1],
          lng: pos[0]
        }));
      }

      const zoneObject = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        zoneName: this.state.selectedItem.zoneName,
        timezone: this.state.selectedItem.timezone || this.props.selectedFleet.timezone,
        geo: {
          coordinates: path,
          type: 'Polygon'
        },
        avatar: this.state.selectedItem.avatar
      }

      if (this.state.ActionStatus == ActionStatus.Add) {
        if (
          this.state.selectedItem &&
          this.state.selectedItem.zoneName &&
          path[0].length >= 3
        ) {
          this.props.loadingBarActions.showLoadingSpiner();
          zoneObject.zoneId = ObjectId();
          this.handleUploadFile(zoneObject).then(imageUpload => {
            if (imageUpload && imageUpload.error) {
              this.props.loadingBarActions.hideLoadingSpiner();
              return;
            }
            zoneObject.avatar = imageUpload;

            this.props.intercityZoneActions
              .createZone(zoneObject)
              .then(data => {
                this.props.loadingBarActions.hideLoadingSpiner();
                if (data.ok && !data.error) {
                  this.componentDidMount();
                  this.context.notification(
                    'success',
                    I18n.t('zoneSetting.Create_zone_success')
                  );
                  this.Polyline = null;
                  this.setState({
                    isSubmitted: false,
                    zoneDraw: {
                      ...this.state.zoneDraw,
                      activeZone: {
                        zoneName: '',
                        path: []
                      }
                    }
                  });
                } else if (data.error) {
                  this.context.notification(
                    'error',
                    I18n.t(`errors.${data.error.errorCode}`)
                  );
                } else {
                  this.context.notification(
                    'error',
                    I18n.t('zoneSetting.Create_zone_fail')
                  );
                }
              });
          })
        } else {
          this.context.notification(
            'error',
            I18n.t('zoneSetting.Name_zone_required')
          );
        }
      } else if ((this.state.ActionStatus = ActionStatus.Edit)) {
        if (this.state.selectedItem && this.state.selectedItem.zoneName) {
          this.props.loadingBarActions.showLoadingSpiner();

          zoneObject.zoneId = this.state.selectedItem._id;
          this.handleUploadFile(zoneObject).then(imageUpload => {
            if (imageUpload && imageUpload.error) {
              this.props.loadingBarActions.hideLoadingSpiselectedItemner();
              return;
            }
            zoneObject.avatar = imageUpload;
            this.props.intercityZoneActions
              .updateZone(zoneObject)
              .then(data => {
                this.props.loadingBarActions.hideLoadingSpiner();
                if (data.ok && !data.error) {
                  this.componentDidMount();
                  this.Polyline = null;
                  this.context.notification(
                    'success',
                    I18n.t('zoneSetting.Update_zone_success')
                  );
                  this.setState({
                    isSubmitted: false,
                    zoneDraw: {
                      ...this.state.zoneDraw,
                      activeZone: {
                        zoneName: '',
                        path: []
                      }
                    }
                  });
                } else if (data.error) {
                  this.context.notification(
                    'error',
                    I18n.t(`errors.${data.error.errorCode}`)
                  );
                } else {
                  this.context.notification(
                    'error',
                    I18n.t('zoneSetting.Update_zone_fail')
                  );
                }
              });
          })
        } else {
          this.context.notification(
            'error',
            I18n.t('zoneSetting.Name_zone_required')
          );
        }
      }
      this.setState({
        activePolylinePath: this.state.activePolylinePath,
        polyline: false
      });
    }
  }

  confirmDeleteCity() {
    if (!this.state.dialogData.isActive) {
      this.props.intercityZoneActions
        .deleteZone({
          fleetId: this.props.auth.selectedFleet.fleetId,
          zoneId: this.state.dialogData._id
        })
        .then(data => {
          if (data.ok && !data.error) {
            this.context.notification(
              'success',
              I18n.t('zoneSetting.Delete_zone_success')
            );
            this.componentDidMount();
            this.closeDialogForm();
          } else if (data.error) {
            this.context.notification(
              'error',
              I18n.t(`errors.${data.error.errorCode}`)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('zoneSetting.Delete_zone_fail')
            );
          }
        });
    }
  }

  closeDialogForm() {
    this.setState({ dialogData: null, showConfirm: false, showConfirmImport: false });
  }

  handleRemoveSelectedFile(e) {
    this.setState({ importContent: null, importFile: null });
  }

  handleClearDrawClick() {
    this.state.zoneDraw.activeZone.path = [];
    this.Polyline = null;
    this.setState({ zoneDraw: this.state.zoneDraw, polyline: true });
  }

  handleMapLoad(key, ref) {
    if (ref) {
      this[key] = ref;
    }
    if (key == 'map' && this.map && this.props.commonData?.location?.isChina) {
      window.qq.maps.event.addListener(this.map, 'bounds_changed', () => {
        this.state.center = this.map.center;
      });
    }
  }

  onDragEnd(e) {
    if (e._id && this.state.ActionStatus == ActionStatus.View) {
      this.handleZoneRowClick(e);
    }
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handleSearchChange = e => this.setState({ searchZone: e.target.value });

  isDropup = index => {
    const containerHeight = window.innerHeight - 200; // height of zone list container

    if (containerHeight - 200 - index * 40 < 80) {
      return true;
    }

    return false;
  };

  renderZoneList = () => {
    const { zoneDraw, selectedItem, searchZone } = this.state;
    const { permissions } = this.props;

    const searchLowercase = searchZone.toLowerCase();
    const zoneList = _.filter(zoneDraw.Zones || [], zone =>
      (zone.zoneName || '').toLowerCase().includes(searchLowercase)
    );

    return (
      <React.Fragment>
        <FormGroup>
          <FormControl
            type="text"
            className="form-custom"
            value={searchZone}
            onChange={this.handleSearchChange}
            placeholder={I18n.t('queuingArea.Enter_to_search')}
          />
        </FormGroup>
        <div className="zone-list">
          <table className="table table-no-pagination cc-table-striped">
            <thead className="table-header">
              <tr className="">
                <th>
                  <Translate value="zoneSetting.Name" />
                </th>
                <th>
                  <Translate value="zoneSetting.Status" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {zoneList.map((m, index) => (
                <tr
                  key={m._id}
                  className={
                    selectedItem && m._id == selectedItem._id
                      ? 'zone-item selected'
                      : 'zone-item'
                  }
                >
                  <td
                    onClick={() => {
                      this.handleZoneRowClick(m);
                    }}
                  >
                    {m.zoneName}
                  </td>
                  <td
                    onClick={() => {
                      this.handleZoneRowClick(m);
                    }}
                    className={m.isActive ? 'Active' : 'Inactive'}
                  >
                    {' '}
                    {!permissions || permissions.actions ? (
                      <a
                        onClick={e => {
                          this.handleMenuClick('Status', m, e);
                        }}
                        className='cursor-pointer'
                      >
                        {m.isActive ? (
                          <Translate value="zoneSetting.Active" />
                        ) : (
                            <Translate value="zoneSetting.Inactive" />
                          )}
                      </a>
                    ) : m.isActive ? (
                      <Translate value="zoneSetting.Active" />
                    ) : (
                          <Translate value="zoneSetting.Inactive" />
                        )}
                  </td>
                  <td>
                    {!permissions || permissions.actions ? (
                      <Dropdown
                        id="bg-nested-dropdown"
                        className="table-actions-dropdown"
                        onSelect={eventKey => {
                          this.handleMenuClick(eventKey, m);
                        }}
                        dropup={this.isDropup(index)}
                        pullRight
                      >
                        <Dropdown.Toggle>
                          <i className="fa fa-reorder" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="actions">
                          <Dropdown.Item eventKey={ActionStatus.Edit}>
                            <Translate value="zoneSetting.Edit" />
                          </Dropdown.Item>
                          {m.isActive ? null : (
                            <Dropdown.Item eventKey={ActionStatus.Status}>
                              {m.isActive ? (
                                <Translate value="zoneSetting.Deactivate" />
                              ) : (
                                  <Translate value="zoneSetting.Activate" />
                                )}
                            </Dropdown.Item>
                          )}
                          {m.isActive ? null : (
                            <Dropdown.Item eventKey={ActionStatus.Delete}>
                              <Translate value="zoneSetting.Delete" />
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                        ''
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  };

  handleChangeTimezone = (event, suggestion) => {
    const { selectedItem } = this.state;
    if(event) {
      this.setState({
        selectedItem: {
          ...selectedItem,
          timezone: suggestion.timezone
        }
      })
    }
  };

  handleChangeTimezoneImport = (event, suggestion) => {
    if (event) {
      this.setState({
        timezoneImport: suggestion.timezone
      })
    }
  };

  render() {
    const AutocompleteItem = ({ suggestion }) => (
      <div>
        <i className="fa fa-map-marker" />
        {suggestion}
      </div>
    );
    const myStyles = {
      // label: { color: "red" },
      input: { width: '100%' },
      autocompleteContainer: { padding: '5px 12px' },
      autocompleteItem: { color: '#e6e6e6', margin: '5px 0' },
      autocompleteItemActive: { color: '#04BE76' }
      // color: "#04BE76",
    };

    const { timezone } = this.props.selectedFleet;

    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeDialogForm} show backdrop className="confirm">
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="zoneSetting.DELETE_ZONE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="zoneSetting.ZONE_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-save mr-md"
                onClick={this.confirmDeleteCity}
              >
                <Translate value="zoneSetting.Yes" />
              </Button>
              <Button className="btn-cancel" onClick={this.closeDialogForm}>
                <Translate value="zoneSetting.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
            ''
          )}
        {this.state.showConfirmImport ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={"confirm"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="zoneSetting.Import zones" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              {
                this.state.dataResponse.ignored === 0 ? I18n.t('zoneSetting.Import_zones_intercity_successful').format(this.state.dataResponse.created, this.state.dataResponse.ignored) :
                  I18n.t('zoneSetting.Import_zones_intercity_successful_failed').format(this.state.dataResponse.created, this.state.dataResponse.ignored)
              }
            </Modal.Body>

          </Modal>
        ) : (
            ""
          )}
        <Row style={{ height: '100%' }}>
          <Col lg={3} md={6} sm={6} style={{ height: '100%' }}>
            {this.state.ActionStatus == ActionStatus.View ||
              this.state.ActionStatus == ActionStatus.Import ? (
                <div className="zone-left-side">
                  {(!this.props.permissions || this.props.permissions.actions) &&
                    this.state.ActionStatus == ActionStatus.View ? (
                      <ButtonToolbar className="text-center header-button-group">
                        <div className="group-left btn-group">
                          <Button
                            className="btn-header text-add-header ml0"
                            onClick={this.handleAddButtonClick}
                          >
                            <Translate value="zoneSetting.Add" />
                          </Button>
                          <Button
                            className="btn-header text-delete-header"
                            onClick={this.handleimportButtonClick}
                          >
                            <Translate value="zoneSetting.Import" />
                          </Button>
                        </div>
                      </ButtonToolbar>
                    ) : (
                      ''
                    )}
                  <div className="zone-list-container">
                    {this.state.ActionStatus == ActionStatus.Import ? (
                      <Tabs id="controlled-tab-example"
                        activeKey={this.state.key}
                        onSelect={(k) => this.setKey(k)}
                      >

                        <Tab eventKey="importFlatZone" title={I18n.t('zoneSetting.Flat_zone')}>
                          <div class="group-general intercityZone">
                            <div className='zoneList'>
                              {
                                this.state.zoneFlats.map((m, index) => (
                                  <CcCheckbox
                                    disabled={false}
                                    className={"mr-b-0"}
                                    text={m.zoneName}
                                    value={m._id}
                                    onChange={this.handleAddFlatZone}
                                    key={index}
                                  />
                                ))
                              }
                            </div>
                            <div style={{ marginTop: '20px' }}>
                              {/* <FormGroup> */}
                                <Form.Label>
                                  <Translate value="zoneSetting.timezone" />{' '}
                                  <span className="require">*</span>
                                </Form.Label>
                                <TimeZonePicker
                                  defaultValue={timezone}
                                  placeholder="Select timezone..."
                                  onSelected={this.handleChangeTimezoneImport}
                                />
                              {/* </FormGroup> */}
                            </div>
                          </div>
                          <div style={{ marginTop: '10px' }}>
                            <Button
                              className="btn-save mr-md"
                              onClick={this.handleSaveButtonClick}
                            >
                              <Translate value="zoneSetting.Import" />
                            </Button>
                            <Button
                              className="btn-cancel"
                              onClick={this.handleCancelButtonClick}
                            >
                              <Translate value="zoneSetting.Cancel" />
                            </Button>
                          </div>
                        </Tab>
                        <Tab eventKey="importFile" title={I18n.t('zoneSetting.Json_file')}>
                          <div className="import-container mb-md pd-10">
                            {this.state.importFile ? (
                              <div className="upload-btn-wrapper">
                                <span
                                  className="btn mr-r-5"
                                  onClick={this.handleRemoveSelectedFile}
                                >
                                  <i
                                    className="fa fa-remove text-danger"
                                    aria-hidden="true"
                                  />
                                </span>
                                {this.state.importFile.name}
                              </div>

                            ) : (
                                <div className="upload-btn-wrapper">
                                  <Button className="btn-header text-delete-header cursor-pointer">
                                    <i className="fa fa-upload" aria-hidden="true" />
                                    <Translate value="zoneSetting.Select_file" />
                                  </Button>
                                  <FormControl
                                    type="file"
                                    label="Upload"
                                    onChange={this.handleImportFileChange}
                                    accept=".json"
                                  />
                                </div>
                              )}
                            <Button
                              className="btn-save mr-md"
                              onClick={this.handleSaveButtonClick}
                              disabled={!this.state.showImportButton}
                            >
                              <Translate value="zoneSetting.Import" />
                            </Button>
                            <Button
                              className="btn-cancel"
                              onClick={this.handleCancelButtonClick}
                            >
                              <Translate value="zoneSetting.Cancel" />
                            </Button>
                          </div>
                        </Tab>
                      </Tabs>
                    ) : (
                        this.renderZoneList()
                      )}
                  </div>
                </div>
              ) : (
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <ButtonToolbar className="text-center header-button-group">
                        <Button
                          className="btn-header text-delete-header"
                          onClick={this.handleCancelButtonClick}
                        >
                          <i className="fa fa-times" />
                          <Translate value="zoneSetting.Cancel" />
                        </Button>
                      </ButtonToolbar>
                    </FormGroup>
                    <FormGroup
                      className={
                        !this.state.isSubmitted
                          ? null
                          : this.state.valid.zoneName === false
                            ? 'error'
                            : null
                      }
                    >
                      <Form.Label>
                        <Translate value="zoneSetting.Name" />
                        <span className="require">*</span>
                        <QuestionCircleTooltip text={I18n.t('intercityBooking.zoneNameHint')} />
                      </Form.Label>
                      <FormControl
                        type="text"
                        className="form-custom"
                        placeholder={I18n.t('zoneSetting.Input_zone_name')}
                        value={this.state.selectedItem.zoneName}
                        onChange={this.handleZoneNameChange}
                      />
                      <Validator id="zoneName" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!this.state.isSubmitted}
                          valid={
                            !Validation.isStringEmpty(
                              this.state.selectedItem.zoneName
                            )
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                        />
                      </Validator>
                    </FormGroup>

                    <FormGroup>
                      <Form.Label>
                        <Translate value="zoneSetting.timezone" />{' '}
                        <span className="require">*</span>
                      </Form.Label>
                      <TimeZonePicker
                        defaultValue={this.state.selectedItem.timezone || timezone}
                        placeholder="Select timezone..."
                        onSelected={this.handleChangeTimezone}
                      />
                    </FormGroup>

                    <FormGroup
                      className={`app-banner-wrapper ${this.state.isSubmitted ? (this.state.valid.avatar === false ? 'error' : null) : null}`}
                    >

                      <UploadFile
                        id="banner.image"
                        name="banner.image"
                        onChange={this.handleImageChange}
                        customTemplate={UploadImageTemplate}
                        className="app-banner-upload-image"
                        customTemplateProps={{
                          fileResult: this.state.selectedItem.avatar || null,
                          accepts: '.jpg, .jpeg, .png'
                        }}
                        accepts='.jpg, .jpeg, .png'
                        onlyImage
                        maxFileSize={5}
                        ref={this.avatarRef}
                      />
                      <Form.Label>
                        <i>
                          <Translate value="intercityBooking.zoneUploadNote_2" />
                        </i>
                      </Form.Label>
                    </FormGroup>
                    <div>
                      <Button
                        className="btn-save mr-md mb-md"
                        onClick={this.handleSaveButtonClick}
                      >
                        <Translate value="zoneSetting.Save_zone" />
                      </Button>
                      <Button
                        className="btn-cancel mb-md"
                        onClick={this.handleClearDrawClick}
                      >
                        <Translate value="zoneSetting.Clear_draw" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            <p className="noteZone">
              (<span class="require">*</span>)<Translate value="intercityBooking.intercityZoneNote" />
            </p>
          </Col>
          <Col lg={9} md={6} sm={6} className="zone-map">
            {this.props.commonData.location &&
              this.props.commonData.location.isChina ? (
                <TencentMap
                  containerElement={<div className="mapqup fill" />}
                  mapElement={<div style={{ height: '100%' }} />}
                  center={this.state.center}
                  onMapLoad={this.handleMapLoad}
                  onPolygonComplete={this.onPolygonComplete}
                  handlePolylineClick={this.handlePolylineClick}
                  zoneDraw={JSON.parse(JSON.stringify(this.state.zoneDraw))}
                  status={this.state.ActionStatus}
                  onDragEnd={this.onDragEnd}
                  handleMapClick={this.handleMapClick}
                  editable={
                    this.state.ActionStatus == ActionStatus.Add ||
                    this.state.ActionStatus == ActionStatus.Edit
                  }
                  polyline={this.state.polyline}
                  defaultZoom={this.state.defaultZoom}
                />
              ) : (
                <GettingStartedGoogleMap
                  containerElement={<div className="mapqup fill" />}
                  mapElement={<div style={{ height: '100%' }} />}
                  center={this.state.center}
                  onMapLoad={this.handleMapLoad}
                  onPolygonComplete={this.onPolygonComplete}
                  handlePolylineClick={this.handlePolylineClick}
                  zoneDraw={JSON.parse(JSON.stringify(this.state.zoneDraw))}
                  status={this.state.ActionStatus}
                  onDragEnd={this.onDragEnd}
                  handleMapClick={this.handleMapClick}
                  editable={
                    this.state.ActionStatus == ActionStatus.Add ||
                    this.state.ActionStatus == ActionStatus.Edit
                  }
                  polyline={this.state.polyline}
                  defaultZoom={this.state.defaultZoom}
                />
              )}

            <div className="zone-location">
              <FormGroup>
                {this.props.commonData.location &&
                  this.props.commonData.location.isChina ? (
                    <AutocompleteWith3rd
                      className="form-custom form-control"
                      value={this.state.addressPickUp}
                      onChange={this.handleChangePickUp}
                      onSelect={this.handleSelectPickUp}
                      handleAddressRemoved={this.handleAddressRemovedPickUp}
                    />
                  ) : (
                    <PlacesAutocomplete
                      value={this.state.addressPickUp}
                      onSelect={this.handleSelectPickUp}
                      onChange={this.handleChangePickUp}
                      autocompleteItem={AutocompleteItem}
                      className="location-select form-custom"
                      styles={myStyles}
                      placeholder={I18n.t('map.Enter_a_location')}
                      onEnterKeyDown={this.handleSelectPickUp}
                      handleAddressRemoved={this.handleAddressRemovedPickUp}
                    />
                  )}
              </FormGroup>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

IntercityZone.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet,
    permissions: state.menuHandle.modulePermission,
    language: state.i18n
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadService: bindActionCreators(uploadActions, dispatch),
    intercityZoneActions: bindActionCreators(intercityZoneActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(IntercityZone);
