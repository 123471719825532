import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  FLEET_REQUEST,
  FLEET_SUCCESS,
  FLEET_FAILURE,
  CHANGE_FLEET,
  CHANGE_PASSWORD_SUCCESS,
  FIND_FLEET_REQUEST
} from "../actions/auth";

import { loadUserProfile } from "../utils/apiUtils";

const initialState = {
  user: null,
  password: null,
  userRole: null,
  loggingIn: false,
  loggingOut: false,
  loginError: null,
  token: '',
  selectedFleet: null
};

function initializeState() {
  const userProfile = loadUserProfile();
  return Object.assign({}, initialState, userProfile);
}

export default function auth(state = initializeState(), action = {}) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, { loggingIn: true });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loggingIn: false,
        user: action.user,
        token: action.token
      });
    case LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        user: null,
        role: null,
        loginError: action.error
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        loggingOut: true,
        user: null
      };
    case LOGOUT_SUCCESS:
      window.location.href = '/login'
      return initialState;
    case LOGOUT_FAILURE:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error
      };
    case FLEET_SUCCESS: {
      return {
        ...state, selectedFleet: {
          ...action.data,
          localeCode: action.data.language || 'en'
        }
      }
    }
    case CHANGE_FLEET: {
      localStorage.removeItem('location');
      localStorage.setItem("fleet", JSON.stringify(action.data))
      setTimeout(() => {
        window.location.href = '/';
      }, 100)
      return {
        ...state, selectedFleet: {
          ...state.selectedFleet,
          ...action.data,
          localeCode: action.data.language || 'en'
        }
      }
    }
    case CHANGE_PASSWORD_SUCCESS: {
      window.location.href = '/'
      return initialState;
    }
    default:
      return state;
  }
}
