import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingBarActions from './../../actions/loadingBarActions';
import { Validator, ValidCase } from '../../components/validator';
import { getUrlLoadAddCardTSYS, Validation, CCLiteCommonFunc, handleResultAddCard, checkGateWayAddCardFromURL } from '../../utils/commonFunctions';
import { Translate, I18n } from 'react-redux-i18n';
import { trimPhoneNumber } from '../../utils/commonFunctions';
import {
  ButtonToolbar,
  Button,
  Modal,
  Checkbox,
  FormGroup,
  Form,
  ListGroupItem,
  InputGroup,
  FormControl
} from 'react-bootstrap';
import CreditCard from '../../components/creditCard/CreditCard';
import IframeAddCard from '../../components/IframeAddCard';
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle';
import AddCard from '../customers/AddCard';
import * as settingActions from '../../actions/settingActions';
import { paymentGetwayDisabledAddNew, NAME_GATEWAY, userType } from '../../constants/commondata';
import {
  deleteCardCorporate,
  updatePaymentCorporate,
  addCardCorporate,
  topUpCorporate
} from '../../actions/corporateAction';
import { getSecureFieldSessionTSYS, getCheckoutPage } from '../../actions/newbookingAction'
import {
  socketDispatchApi
} from '../../utils/socketUtils';
import { socketConfigs } from '../../constants/socketConfigs';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';

var currencyFormatter = require('currency-formatter');
let credit;
class PaymentMethod extends Component {
  constructor() {
    super();
    this.state = {
      zoneId: '',
      isShowIframeAddCardModal: false,
      urlIframe: '',
      isShowEditBalance: false,
      isSubmittedEditBalance: false,
      validEditBalnce: {},
      operator: 0,
      balance: 0,
      reason: ''
    };
    this.handleAddCardClick = this.handleAddCardClick.bind(this);
    this.handleTopUpClick = this.handleTopUpClick.bind(this);
    this.handleCancelTopUpClick = this.handleCancelTopUpClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleDeleteCardClick = this.handleDeleteCardClick.bind(this);
    this.deleteCreditCard = this.deleteCreditCard.bind(this);
    this.handleCancelDeleteCardClick = this.handleCancelDeleteCardClick.bind(
      this
    );
    this.handleSavePaymentClick = this.handleSavePaymentClick.bind(this);
    this.handleAddCardSuccess = this.handleAddCardSuccess.bind(this);
    this.handleTopUpSuccess = this.handleTopUpSuccess.bind(this);
    this.handleSelectZone = this.handleSelectZone.bind(this);
    this.handleSelectCreditCardByZone = this.handleSelectCreditCardByZone.bind(
      this
    );
  }

  componentDidMount() {
    if (!this.props.auth.selectedFleet.creditConfig.multiGateway) {
      this.setState({
        gateWay: this.props.auth.selectedFleet.creditConfig.configGateway
      });
    } else {
      this.setState({
        gateWay: !_.isEmpty(this.props.commonData.gatewayZones)
          ? this.props.commonData.gatewayZones[0]
          : {},
        zoneId: !_.isEmpty(this.props.commonData.gatewayZones)
          ? this.props.commonData.gatewayZones[0].zoneId
          : null
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      _.isEmpty(this.state.gateWay) &&
      !_.isEmpty(nextProps.commonData.gatewayZones)
    ) {
      this.setState({
        gateWay: !_.isEmpty(nextProps.commonData.gatewayZones)
          ? nextProps.commonData.gatewayZones[0]
          : {},
        zoneId: !_.isEmpty(nextProps.commonData.gatewayZones)
          ? nextProps.commonData.gatewayZones[0].zoneId
          : null
      });
    }
  }

  handleAddCardClick() {
    let { gateWay = {} } = this.state
    if(gateWay.gateway === 'TSYS') {
      this.handleLoadSessionTSYS()
      return
    }
    if(gateWay.gateway === NAME_GATEWAY.Paymaya) {
      this.handleAddPaymayaGateway()
      return
    }
    if(checkGateWayAddCardFromURL(gateWay.gateway)) {
      this.handleLoadURLAddCardPayWay()
      return
    }
    this.setState({ isShowAddCard: true });
  }

  closeModalIframeAddCard = () => {
    if(this.state.isShowIframeAddCardModal) this.setState({isShowIframeAddCardModal: false})
    this.props.getCorporateDetails(true)
  }

  handleLoadURLAddCardPayWay = () => { // use for payway and ecpay
    const request_params = {
      fleetId: this.props.auth?.selectedFleet?.fleetId,
      corpId: this.props.corporateId
    };
    this.props.addCardCorporate(request_params)
    .then((result) => {
      const { res = {} } = result
      if (res?.returnCode === 200 && res?.response?.['3ds_url']) {
        if(this.state?.gateWay?.gateway === NAME_GATEWAY.ECPay) {
          handleResultAddCard(res?.response?.['3ds_url'], this.handleAddCardSuccess)
        } else {
          this.setState({
            urlIframe: res?.response?.['3ds_url'],
            isShowIframeAddCardModal: true
          })
        }
      } else if (res.returnCode) {
        this.context.notification('error', I18n.t(`messages.credits.${res.returnCode}`));
      }
    })
    .catch(err => {
      this.context.notification('error', 'Catch Error');
    })
  }

  handleAddPaymayaGateway() {
    let { auth, corporateId } = this.props
    this.props.getCheckoutPage({
      fleetId: auth && auth.selectedFleet && auth.selectedFleet.fleetId || '',
      userId: "",
      corporateId: corporateId,
      type: 'corporate'
    }).then((data) => {
      let { res = {} } = data
      if (res.returnCode === 200) {
        let response = res.response || {}
        let url = response['3ds_url'] || ''
        if(url) {
          window.open(url, '_blank').focus();
          socketDispatchApi.remove(socketConfigs.receive.registeredCard);
          socketDispatchApi.on(socketConfigs.receive.registeredCard, data => {
            if(data && data.returnCode === 200) {
              this.handleAddCardSuccess(data.credit);
            } else {
              this.context.notification('error', I18n.t(`messages.credits.${res.returnCode}`));
            }
          });
        }
      } else if (res.returnCode) {
        this.context.notification('error', I18n.t(`messages.credits.${res.returnCode}`));
      }
    })
  }

  handleLoadSessionTSYS = () => {
    const {
      auth
    } = this.props;
    this.props.getSecureFieldSessionTSYS({
      fleetId: auth && auth.selectedFleet && auth.selectedFleet.fleetId || '',
      userId: this.props.corporateId
    }).then((data) => {
      let { res = {} } = data
      if (res.returnCode === 200) {
        let response = res.response || {}
        let { secureID = '', sessionId = '', showAddress = false } = response
        this.setState({
          urlIframe: getUrlLoadAddCardTSYS(secureID, sessionId, showAddress),
          isShowIframeAddCardModal: true
        })
      } else if (res.returnCode) {
        this.context.notification('error', I18n.t(`messages.credits.${res.returnCode}`));
      }
    }) 
  }

  handleInputEditBlanceChange(e, key) {
    this.setState({ [key]: e.target.value });
  }

  handleTopUpClick() {
    const { credits } = this.props;
    const creditsValid = credits.filter(obj => {
      return this.state.gateWay && obj.gateway == this.state.gateWay.gateway;
    });
    const directBilling = this.props.paymentMethods.filter(
      p => p.type === 'directBilling'
    )[0];
    if (!creditsValid.length && !directBilling.isActive) {
      this.context.notification(
        'error',
        I18n.t('corporate.AddCardBeforeTopup')
      );
      return;
    }
    this.setState({ isShowTopUp: true });
  }

  handleEditBalanceClick = () => {
    this.setState({ isShowEditBalance: true})
  }

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.validEditBalnce[id] != valid) {
      this.setState({ validEditBalnce: {...this.state.validEditBalnce, [id]: valid} });
    }
  }

  handleCancelAddCardClick = () => {
    this.setState({ isShowAddCard: false });
  };

  handleCancelTopUpClick() {
    this.setState({ isShowTopUp: false });
  }

  handleAddCardSuccess(credit) {
    this.props.handleAddCardSuccess(credit, this.handleCancelAddCardClick);
  }

  handleTopUpSuccess(credit, amount, is3ds) {
    this.props.handleTopUpSuccess(amount, this.handleCancelTopUpClick, is3ds);
  }

  handleSelectCreditCardByZone(e) {
    let gateway = _.find(
      this.props.commonData.gatewayZones,
      item => item.zoneId == e.target.value
    );
    this.setState({
      zoneId: e.target.value,
      gateWay: gateway
    });
  }

  handleSelectZone(e) {
    let gateway = _.find(
      this.props.commonData.gatewayZones,
      item => item.zoneId == e.target.value
    );
    this.setState({
      zoneId: e.target.value,
      gateWay: gateway
    });
  }

  handleDeleteCardClick(cre) {
    credit = cre;
    this.setState({ isShowConfirmDelete: true });
  }

  deleteCreditCard() {
    let deleteCredit = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      corpId: this.props.corporateId,
      _id: credit._id
    };
    this.props.deleteCardCorporate(deleteCredit).then(data => {
      if (data.res.isDeleted) {
        this.props.handleDeleteCardSuccess(credit, () => {
          this.handleCancelDeleteCardClick();
        });
      } else {
        this.context.notification(
          'error',
          I18n.t('errors.' + data.error.errorCode)
        );
      }
    });
  }

  handleCancelDeleteCardClick() {
    this.setState({ isShowConfirmDelete: false });
  }

  handleCloseEditBalanceModal = () => {
    this.setState({ 
      isShowEditBalance : false,
      operator: 0,
      balance: 0,
      reason: '',
      amount: 0
    });
  }

  handleTopUpWith3ds = (url) => {
    if(!url) return
    this.handleCancelTopUpClick()
    window.open(url, '_blank').focus();
    socketDispatchApi.remove(socketConfigs.receive.topupPrepaid);
    socketDispatchApi.on(socketConfigs.receive.topupPrepaid, data => {
      if(data?.returnCode === 200) {
        this.handleTopUpSuccess(null, data.response, true)
        this.context.notification(
          'success',
          I18n.t('customer.Add_card_top_up_success')
        )
      } else {
        this.context.notification('error', I18n.t(`messages.credits.error`));
      }
    });
  }

  editBalance = () => {
    this.setState({ isSubmittedEditBalance: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.validEditBalnce)) return;
    let amount = parseFloat(this.state.amount);
    if (this.state.operator == "1") {
      amount = 0 - amount;
    }
    const dataRequest = {
      amount: amount,
      reason: this.state.reason,
      corpId: this.props.corporateId,
      currencyISO: this.props.auth.selectedFleet.currencyISOValidation,
      fleetId: this.props.auth.selectedFleet.fleetId,
      type: "editBalance"
    }
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.topUpCorporate(dataRequest)  
    .then(response => {
      this.props.loadingBarActions.hideLoadingSpiner();
      if(response?.res && response?.res['3ds_Url']) {
        this.handleTopUpWith3ds(response?.res['3ds_Url'])
        return
      }

      if(response?.res?.isSuccess) {
        this.props.handleEditBalanceSuccess(response.res.total)
        this.setState({
          isShowEditBalance: false,
          amount: 0,
          reason: '',
          isSubmittedEditBalance: false,
          operator: 0
        })
        return
      }
    })
  }

  handleCancelClick() {
    this.props.router.push({
      pathname: '/corporate'
    });
  }

  handleSavePaymentClick() {
    let directBilling = this.props.paymentMethods.filter(
      p => p.type === 'directBilling'
    )[0];
    let prepaidBilling = this.props.paymentMethods.filter(
      p => p.type === 'prepaid'
    )[0];
    const cashSetting = this.props.paymentMethods.filter(
      p => p.type === 'cash'
    )[0];
    this.props
      .updatePaymentCorporate({
        fleetId: this.props.auth.selectedFleet.fleetId,
        corpId: this.props.corporateId,
        directInvoice: directBilling.checked,
        corporatePrepaid: prepaidBilling.checked,
        cash: cashSetting.checked,
      })
      .then(data => {
        if (data.ok) {
          this.context.notification(
            'success',
            I18n.t('corporate.INFO_UPDATE_CORPORATE_SUCCESS')
          );
          this.props.router.push({
            pathname: '/corporate'
          });
        } else {
          this.context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          );
        }
      });
  }

  render() {
    let directBilling = this.props.paymentMethods.filter(
      p => p.type === 'directBilling'
    )[0];
    let prepaidBilling = this.props.paymentMethods.filter(
      p => p.type === 'prepaid'
    )[0];
    const cashSetting = this.props.paymentMethods.filter(
      p => p.type === 'cash'
    )[0];
    const { creditConfig } = this.props.auth.selectedFleet;
    const { isSubmittedEditBalance } = this.state
    const supportWeb = _.get(this.state.gateWay, 'supportWeb', false);
    const hasPermission =
      !this.props.permissions || this.props.permissions.actions;
    const isCorporateBoard = [userType.CorporateAdmin, userType.CorporateUser].includes(this.props.auth.user.userType)
    let operatorValue = null;
    let amount = Validation.isNumber(this.state.amount)
      ? parseFloat(this.state.amount)
      : 0.0;
    if (this.state.operator) {
      operatorValue = (parseFloat(prepaidBilling.balance) - amount).toFixed(2);
    } else {
      operatorValue = (amount + parseFloat(prepaidBilling.balance)).toFixed(2);
    }

    return (
      <div className="text paymentMethods-container">
        {this.props.auth.selectedFleet.creditConfig &&
        this.props.auth.selectedFleet.creditConfig.multiGateway &&
        this.props.commonData.gatewayZones &&
        this.props.commonData.gatewayZones.length ? (
          <FormGroup className="select-zone">
            <Form.Label>
              <Translate value="mDispatcher.Default_zone" />
              <span className="require">*</span>
            </Form.Label>
            <select
              onChange={this.handleSelectCreditCardByZone}
              value={this.state.zoneId}
              className={'form-control form-custom'}
            >
              {this.props.commonData.gatewayZones &&
                this.props.commonData.gatewayZones.map(obj => {
                  return (
                    <option value={obj.zoneId} key={obj.zoneId}>
                      {obj.zoneName}
                    </option>
                  );
                })}
            </select>
          </FormGroup>
        ) : null}
        <div className="credits-wrapper mb-md">
          {this.props.auth.selectedFleet.corporate &&
          this.props.auth.selectedFleet.corporate.corporateCard
            ? creditConfig && creditConfig.multiGateway
              ? _.map(
                  _.filter(this.props.credits, credit => {
                    let gatewayZone = _.find(
                      this.props.commonData.gatewayZones,
                      zone => {
                        return zone.zoneId === this.state.zoneId;
                      }
                    );
                    return (
                      gatewayZone && gatewayZone.gateway === credit.gateway
                    );
                  }),
                  (filteredCredit, index) => {
                    return (
                      <CreditCard
                        handleDeleteCardClick={this.handleDeleteCardClick}
                        data={filteredCredit}
                        cardName={I18n.t('corporate.Corporate_card')}
                        isShowFP={true}
                        showCardHolder={true}
                        key={index}
                        onlyView={!hasPermission}
                      />
                    );
                  }
                )
              : _.map(
                _.filter(this.props.credits, credit => {
                  return (this.state.gateWay && this.state.gateWay.gateway) === credit.gateway
                }),
                (filteredCredit, index) => {
                  return (
                    <CreditCard
                      handleDeleteCardClick={this.handleDeleteCardClick}
                      data={filteredCredit}
                      cardName={I18n.t('corporate.Corporate_card')}
                      isShowFP={true}
                      showCardHolder={true}
                      onlyView={!hasPermission}
                    />
                  );
                })
            : null}
          <div className="credit-item">
            <div className="select-box">
              <CcCheckbox
                checked={directBilling.checked}
                onChange={this.props.handleDirectBillingChange}
                disabled={!hasPermission || isCorporateBoard}
              />
            </div>
            <div className="card-icon">
              <i id={'creditcard'} className={'directBilling'} />
            </div>
            <div className="name">
              <Translate value="corporate.Direct_invoicing" /> -{' '}
              {directBilling.corporateName}
            </div>
          </div>
          {this.props.auth.selectedFleet.corporate &&
          this.props.auth.selectedFleet.corporate.corporatePrepaid ? (
            <div className="credit-item">
              <div className="select-box">
                <CcCheckbox
                  checked={prepaidBilling.checked}
                  onChange={this.props.handlePrepaidBillingChange}
                  disabled={!hasPermission || isCorporateBoard}
                />
              </div>
              <div className="card-icon">
                <i id={'creditcard'} className={'prepaid'} />
              </div>
              <div className="name">
                <Translate value="corporate.Corporate_Prepaid" /> -{' '}
                {currencyFormatter.format(prepaidBilling.balance, {
                  code: this.props.auth.selectedFleet.currencyISOValidation
                })}
              </div>
              {this.props.auth.user.userType === 'CorporateAdmin' ||
              this.props.auth.user.userType === 'CorporateUser' ? (
                <Button className="btn-save" onClick={this.handleTopUpClick}>
                  <Translate value="corporate.Top_up" />
                </Button>
              ) : null}
              {
                (
                  this.props.auth.user.userType === userType.FleetAdmin ||
                  (
                    this.props.auth.user.userType === userType.FleetUser &&
                    this.props.permissions && this.props.permissions.editbalance
                  )
                ) &&
                <Button className="btn-save" onClick={this.handleEditBalanceClick}>
                  <Translate value="corporate.Edit_Balance" />
                </Button>
              }
            </div>
          ) : null}
          <div className="credit-item">
            <div className="select-box">
              <CcCheckbox
                checked={cashSetting.checked}
                onChange={this.props.handleCashChange}
                disabled={!hasPermission || isCorporateBoard}
              />
            </div>
            <div className="card-icon">
              <i id={'creditcard'} className={'cash'} />
            </div>
            <div className="name">
              <Translate value="corporate.Cash" />
            </div>
          </div>
        </div>

        <ButtonToolbar className="text-center">
          {hasPermission ? (
            <React.Fragment>
              {(this.props.auth.selectedFleet.creditConfig &&
                (!this.props.auth.selectedFleet.creditConfig.enable ||
                  (!this.props.auth.selectedFleet.creditConfig.multiGateway &&
                    paymentGetwayDisabledAddNew.includes(
                      this.props.auth.selectedFleet.creditConfig.configGateway
                        .gateway
                    )))) ||
              (this.state.gateWay &&
                paymentGetwayDisabledAddNew.includes(
                  this.state.gateWay.gateway
                )) ||
              (this.props.auth.selectedFleet.corporate &&
                !this.props.auth.selectedFleet.corporate.corporateCard) ? null : supportWeb ? (
                <Button
                  onClick={this.handleAddCardClick}
                  className="btn-save mr-md"
                >
                  <Translate value="corporate.Add_corporate_card" />
                </Button>
              ) : null}
              <Button
                className="btn-save mr-md"
                onClick={this.handleSavePaymentClick}
              >
                <Translate value="corporate.Save" />
              </Button>
            </React.Fragment>
          ) : null}
          <Button className="btn-cancel" onClick={this.handleCancelClick}>
            <Translate value="corporate.Cancel" />
          </Button>
        </ButtonToolbar>
        <Modal
          show={this.state.isShowAddCard}
          onHide={this.handleCancelAddCardClick}
          dialogClassName="card-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="corporate.ADD_CORPORATE_CARD" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="clearfix">
            {creditConfig && creditConfig.multiGateway ? (
              !_.isUndefined(this.state.gateWay) &&
              this.state.zoneId &&
              !paymentGetwayDisabledAddNew.includes(
                this.state.gateWay.gateway
              ) ? (
                <Fragment>
                  <ListGroupItem variant="info">
                    <Translate
                      value="corporate.adding_new_card"
                      zoneName={this.state.gateWay.zoneName}
                    />
                  </ListGroupItem>
                  <AddCard
                    corpId={this.props.corporateId}
                    zoneId={this.state.zoneId}
                    gateWay={this.state.gateWay}
                    addCardWebservice={this.props.addCardCorporate}
                    handleAddCardSuccess={this.handleAddCardSuccess}
                    currentUser={this.props.currentUser}
                    handleCloseAddCard={this.handleCancelAddCardClick}
                    supportWeb={supportWeb}
                  />
                </Fragment>
              ) : null
            ) : !_.isUndefined(this.state.gateWay) &&
              !paymentGetwayDisabledAddNew.includes(
                this.state.gateWay.gateway
              ) ? (
              <AddCard
                corpId={this.props.corporateId}
                addCardWebservice={this.props.addCardCorporate}
                handleAddCardSuccess={this.handleAddCardSuccess}
                currentUser={this.props.currentUser}
                gateWay={this.state.gateWay}
                handleCloseAddCard={this.handleCancelAddCardClick}
                supportWeb={supportWeb}
                isAddNewCorporateCard={true}
              />
            ) : null}
          </Modal.Body>
        </Modal>

        <IframeAddCard 
          isShowModal={this.state.isShowIframeAddCardModal}
          urlIframe={this.state.urlIframe}
          closeModal={this.closeModalIframeAddCard}
        />

        <Modal
          show={this.state.isShowTopUp}
          onHide={this.handleCancelTopUpClick}
          className="confirm"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="corporate.TOP_UP" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="clearfix">
            {creditConfig && creditConfig.multiGateway ? (
              <FormGroup>
                <Form.Label>
                  <Translate value="mDispatcher.Default_zone" />
                  <span className="require">*</span>
                </Form.Label>
                <select
                  onChange={this.handleSelectZone}
                  value={this.state.zoneId}
                  className={'form-control form-custom'}
                >
                  {Array.isArray(this.props.commonData.gatewayZones) &&
                    this.props.commonData.gatewayZones.length > 0 &&
                    this.props.commonData.gatewayZones.map(obj => {
                      return (
                        <option value={obj.zoneId} key={obj.zoneId}>
                          {obj.zoneName}
                        </option>
                      );
                    })}
                </select>
              </FormGroup>
            ) : null}
            {creditConfig &&
            creditConfig.multiGateway &&
            this.state.gateWay &&
            this.state.zoneId ? (
              <AddCard
                corpId={this.props.corporateId}
                zoneId={this.state.zoneId}
                gateWay={this.state.gateWay}
                addCardWebservice={this.props.topUpCorporate}
                handleTopUpWith3ds={this.handleTopUpWith3ds}
                handleAddCardSuccess={this.handleTopUpSuccess}
                isTopUp={true}
                credits={
                  this.props.credits
                    ? this.props.credits.filter(obj => {
                        if (this.state.gateWay)
                          return obj.gateway == this.state.gateWay.gateway;
                        return false;
                      })
                    : []
                }
                isDirectBilling={directBilling.isActive}
                currentUser={this.props.currentUser}
                supportWeb={supportWeb}
                isAddNewCorporateCard={true}
              />
            ) : (
              <AddCard
                corpId={this.props.corporateId}
                addCardWebservice={this.props.topUpCorporate}
                handleTopUpWith3ds={this.handleTopUpWith3ds}
                handleAddCardSuccess={this.handleTopUpSuccess}
                isTopUp={true}
                credits={
                  this.props.credits
                    ? this.props.credits.filter(obj => {
                        if (this.state.gateWay)
                          return obj.gateway == this.state.gateWay.gateway;
                        return false;
                      })
                    : []
                }
                isDirectBilling={directBilling.isActive}
                currentUser={this.props.currentUser}
                gateWay={this.state.gateWay}
                supportWeb={supportWeb}
                isAddNewCorporateCard={true}
              />
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.isShowConfirmDelete}
          backdrop={true}
          className="confirm"
          size='sm'
          onHide={this.handleCancelDeleteCardClick}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="corporate.Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Translate value="corporate.CONFIRM_DELETE_CARD" />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-save mr-md" onClick={this.deleteCreditCard}>
              <Translate value="corporate.Yes" />
            </Button>
            <Button
              className="btn-cancel"
              onClick={this.handleCancelDeleteCardClick}
            >
              <Translate value="corporate.No" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.isShowEditBalance}
          className="confirm"
          backdrop="static"
          onHide={this.handleCloseEditBalanceModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="corporate.Edit_Balance" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              className="qup-input-group"
            >
              <Form.Label>
                <Translate value="corporate.Balance" />
              </Form.Label>
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                      {this.props.auth.selectedFleet.currencyISOValidation}
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  value={prepaidBilling.balance}
                  className="form-custom"
                  disabled={true}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={
                isSubmittedEditBalance ? (this.state.validEditBalnce.amount === false ? "error" : null) : null
              }
            >
              <Form.Label>
                <Translate value="driver.Adjust_amount" />{" "}
                <span className="require"> *</span>
              </Form.Label>
              <FormGroup className="qup-input-group">
                <InputGroup className="single-addon-left">
                  <FormControl
                    style={{ width: "90%" }}
                    type="text"
                    value={this.state.amount}
                    onChange={(e) => this.handleInputEditBlanceChange(e, 'amount')}
                  />
                  <FormControl
                    as="select"
                    value={this.state.operator}
                    style={{ width: "10%" }}
                    onChange={(e) => this.handleInputEditBlanceChange(e, 'operator')}
                    className="form-custom input-group-addon-custom"
                  >
                    <option key={0} value={"0"}>
                      +
                    </option>
                    <option key={1} value={"1"}>
                      -
                    </option>
                  </FormControl>
                </InputGroup>
                <Validator id="amount" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmittedEditBalance}
                    valid={!Validation.isStringEmpty(this.state.amount)}
                    message={I18n.t("driver.ERROR_INPUT_AMOUNT")}
                  />
                  <ValidCase
                    hide={!isSubmittedEditBalance}
                    valid={
                      Validation.isNumber(this.state.amount) &&
                      parseFloat(this.state.amount) > 0
                    }
                    message={I18n.t("driver.ERROR_INPUT_VALID_AMOUNT")}
                  />
                </Validator>
              </FormGroup>
              <FormGroup>
                <Form.Label>
                  <Translate value="driver.New_balance" />
                </Form.Label>
                <FormGroup>
                  <span className="text-active">
                    {this.props.auth.selectedFleet.currencyISOValidation} : { operatorValue }
                  </span>
                </FormGroup>
              </FormGroup>
              <FormGroup
                className={`mb0 ${
                  isSubmittedEditBalance ? (this.state.validEditBalnce.reason === false ? "error" : null) : null
                }`}
              >
                <Form.Label>
                  <Translate value="driver.Enter_reason" />
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  as="textarea"
                  value={this.state.reason}
                  onChange={(e) => this.handleInputEditBlanceChange(e, 'reason')}
                  className="form-custom"
                />
                <Validator id="reason" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmittedEditBalance}
                    valid={!Validation.isStringEmpty(this.state.reason)}
                    message={I18n.t("driver.ERROR_INPUT_REASON")}
                  />
                </Validator>
              </FormGroup>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-save mr-md" onClick={this.editBalance}>
              <Translate value="corporate.Save" />
            </Button>
            <Button
              className="btn-cancel"
              onClick={this.handleCloseEditBalanceModal}
            >
              <Translate value="corporate.Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

PaymentMethod.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
    commonData: state.commonData
  };
}
function mapDispatchToProps(dispatch) {
  return {
    addCardCorporate: options => {
      return dispatch(addCardCorporate(options));
    },
    deleteCardCorporate: options => {
      return dispatch(deleteCardCorporate(options));
    },
    updatePaymentCorporate: options => {
      return dispatch(updatePaymentCorporate(options));
    },
    topUpCorporate: options => {
      return dispatch(topUpCorporate(options));
    },
    getSecureFieldSessionTSYS: options => {
      return dispatch(getSecureFieldSessionTSYS(options));
    },
    getCheckoutPage: options => {
      return dispatch(getCheckoutPage(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethod);
