import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import { I18n, Translate } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';
import {
  bookingDetailsPaymentStatus,
  bookingDetailsPaymentMethods,
  paymentStatusRefund,
  APPLY_SERVICE_TYPE,
} from '../../../constants/commondata';
import { checkEnableTechFee } from '../../../utils/commonFunctions';

function numberFormat (
  value,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
) {
  return I18n.l(value, { maximumFractionDigits, minimumFractionDigits });
}

function formatTime (value, doc, params = {}, timeZoneParsed) {
  if (!value) {
    return value;
  }
  let formatStr = 'MM/DD/YYYY hh:mm A';
  if (params.formatStr) {
    formatStr = params.formatStr;
  }
  return moment(value).format(formatStr);
}

function convertPrepaidTransaction (value, doc, params = {}) {
  if (!value) {
    return value;
  }
  let textTransaction = value;
  switch (value) {
    case 'editBalance':
      textTransaction = 'Edit Balance';
      break;
    case 'deposit':
      textTransaction = 'Top up';
      break;
    default:
      break;
  }
  return textTransaction;
}

function isShowFareFields (params) {
  const ratingSetting = _.get(params.fleet, 'passenger.drvRating', 0);

  if(params.fleet?.fleetFareId?.applyType === APPLY_SERVICE_TYPE.CUSTOM_ZONE) {
    params.fleet.fleetFareId = {
      ...(params?.fleet.fleetFareId || {}),
      ...(params?.fleetFareIdByZone || {})
    }
  }

  switch (params.fieldName) {
    case 'meetDriver':
      return _.get(
        params.fleet,
        ['additionalService', 'fromAirport', 'meetDriver'],
        false
      );
    case 'homeFleetCommission':
      return _.get(params.fleet, params.fieldName, 0) != 0;
    case 'driverDeposit':
      return _.get(params.fleet, 'driverDeposit');
    case 'driverUserName':
      return (
        _.get(params.fleet, 'password.driver', false) &&
        _.get(params.fleet, 'password.regBy', -1) === 1
      );
    case 'preAuthorized':
      return _.get(params.fleet, 'preAuthorized.isActive', false);
    case 'techFee':
      return checkEnableTechFee({ selectedFleet: params.fleet, fleetFareCustom: params.fleet.fleetFareId })
    case 'carRating':
      return _.get(params.fleet, 'passenger.carRating', false);
    case 'driverStarRating':
      return ratingSetting == 0;
    case 'driverThumbRating':
      return ratingSetting == 1;
    case 'referral':
      return _.get(params.fleet, 'referral.enable', false);
    case 'rushHourActive':
      var rushHourActive = _.get(
        params.fleet,
        'fleetFareId.rushHourActive',
        false
      );
      var surchargeParameter = _.find(
        _.get(params.fleet, 'modules', []),
        (m) => m.name === 'Dynamic Surcharge'
      );
      rushHourActive = rushHourActive || (surchargeParameter ? true : false);
      return rushHourActive;
    default:
      return _.get(params.fleet, ['fleetFareId', params.fieldName], false);
  }
}

function fareFiedSettings (params) {
  return {
    meetDriver: _.get(
      params.fleet,
      ['additionalService', 'fromAirport', 'meetDriver'],
      false
    ),
    rushHourActive: _.get(
      params.fleet,
      ['fleetFareId', 'rushHourActive'],
      false
    ),
    heavyTrafficActive: _.get(
      params.fleet,
      ['fleetFareId', 'heavyTrafficActive'],
      false
    ),
    tollFeeActive: _.get(params.fleet, ['fleetFareId', 'tollFeeActive'], false),
    parkingFeeActive: _.get(params.fleet, ['fleetFareId', 'parkingFeeActive'], false),
    gasFeeActive: _.get(params.fleet, ['fleetFareId', 'gasFeeActive'], false),
    otherFeeActive: _.get(
      params.fleet,
      ['fleetFareId', 'otherFeeActive'],
      false
    ),
    taxActive: _.get(params.fleet, ['fleetFareId', 'taxActive'], false),
    tipActive: _.get(params.fleet, ['fleetFareId', 'tipActive'], false),
    homeFleetCommission:
      _.get(params.fleet, ['affiliate', 'homeFleetCommission'], 0) != 0,
    driverDeposit: _.get(params.fleet, 'driverDeposit', false),
    driverUserName:
      _.get(params.fleet, 'password.driver', false) &&
      _.get(params.fleet, 'password.regBy', -1) === 1,
  };
}

function isShowCorporateAdvanceInfo (params) {
  return _.get(params.settings, ['generalSetting', 'advanceInfoCorp'], false);
}

function isShowByFilterValue ({ criteria, showByFilters }) {
  const { filterName, value } = showByFilters;
  if (criteria[filterName]) {
    if (value && value.length > 0) {
      return !!value.find((obj) => obj == criteria[filterName]);
    }
  }
  return true;
}

function isShowDriverReferralField (params) {
  switch (params.fieldName) {
    case 'commission':
      return !_.get(
        params.settings,
        'referralInfo.isDistributeIncentiveToDriver',
        false
      );
    case 'expectedReferralEarning':
      return _.get(
        params.settings,
        'referralInfo.isDistributeIncentiveToDriver',
        false
      );
    default:
      return true;
  }
}

const initCriteriaFields = (props) => {
  const criteriaFields = [];
  criteriaFields.push('timezone');
  criteriaFields.push('unitDistance');
  props.month && criteriaFields.push('month');
  props.year && criteriaFields.push('year');
  props.dateRange && criteriaFields.push('fromDate', 'toDate');
  props.company && criteriaFields.push('companyId');
  props.corporate && criteriaFields.push('corporateId');
  props.operator && criteriaFields.push('userId');
  props.plate && criteriaFields.push('vehicleId');
  props.driver && criteriaFields.push('driverId');
  props.search && criteriaFields.push('txtSearch');
  props.paymentMethod && criteriaFields.push('paymentMethod');
  props.finishedStatus && criteriaFields.push('finishedBookingStatus');
  props.paymentStatus && criteriaFields.push('paymentStatus');
  props.payoutPaymentStatus && criteriaFields.push('payoutPaymentStatus');
  props.paymentStatusRefund && criteriaFields.push('paymentStatusRefund');
  props.bookingStatus && criteriaFields.push('bookingStatus');
  props.bookingService && criteriaFields.push('bookingService');
  props.bookingType && criteriaFields.push('bookingType');
  props.currency && criteriaFields.push('currency');
  props.bookFrom && criteriaFields.push('bookFrom');
  props.campaign && criteriaFields.push('campaignId');
  props.promocode && criteriaFields.push('promoCodeId');
  props.ratingType && criteriaFields.push('ratingType');
  props.stars && criteriaFields.push('stars');
  props.vehicleRate && criteriaFields.push('vehicleRate');
  props.mDispatcherType && criteriaFields.push('mDispatcherTypeId');
  props.mDispatcher && criteriaFields.push('mDispatcherId');
  props.canceledBy && criteriaFields.push('canceledBy');
  props.incidentCanceledTypes && criteriaFields.push('incidentCanceledTypes');
  props.action && criteriaFields.push('action');
  props.loginStatus && criteriaFields.push('status');
  (props.transactionType || props.withdrawalTransactionType) &&
    criteriaFields.push('transactionType');
  props.module && criteriaFields.push('module');
  props.driverType && criteriaFields.push('driverType');
  props.carType && criteriaFields.push('vehicleType');
  props.carTypeMultiple && criteriaFields.push('carTypeMulti');
  props.serviceType && criteriaFields.push('serviceType');
  props.expiredDate && criteriaFields.push('expiredDate');
  props.rideService && criteriaFields.push('rideService');
  props.withdrawStatus && criteriaFields.push('withdrawStatus');
  props.paymentCurrentStatus && criteriaFields.push('currentStatus');
  props.paymentOriginalStatus && criteriaFields.push('originalStatus');
  props.pickUpZone && criteriaFields.push('pickUpZone');
  props.customer && criteriaFields.push('userId');
  props.tipAfterCompleted && criteriaFields.push('tip');
  props.usedPromocodes && criteriaFields.push('promotion');
  props.intercityRoute && criteriaFields.push('routes');
  props.refererId && criteriaFields.push('refererId');
  props.refereeId && criteriaFields.push('refereeId');
  props.referralType && criteriaFields.push('referralType');
  props.referralCode && criteriaFields.push('referralCode');
  props.enablePayoutTime && criteriaFields.push('payoutId');
  props.filterDate && criteriaFields.push('filterDate');
  props.minPayout && criteriaFields.push('minPayout');
  props.minPayout && criteriaFields.push('holdAmount');
  props.payoutOption && criteriaFields.push('payoutOption');
  props.minPayout && criteriaFields.push('payoutDate');
  props.enablePayoutTime && criteriaFields.push('type');
  props.event && criteriaFields.push('eventId');
  props.event && criteriaFields.push('eventState');
  props.event && criteriaFields.push('userType');
  props.event && criteriaFields.push('eventType');
  props.voucherCodes && criteriaFields.push('redeemCodeId');
  props.driverReferrerId && criteriaFields.push('driverId');
  props.driverReferrerId && criteriaFields.push('driverReferrerId');
  props.refereeId &&
    props.driverReferrerId &&
    criteriaFields.push('referralStatus');
  props.adjustPrice && criteriaFields.push('adjustPrice');
  props.airline && criteriaFields.push('corporateId');
  props.airline && criteriaFields.push('isAirline');
  props.merchantType && criteriaFields.push('merchantType');
  props.jobType && criteriaFields.push('jobType');
  props.storeMerchant && criteriaFields.push('merchantId');
  props.documentExpiryStatus && criteriaFields.push('documentExpiryStatus');
  props.affiliationPayoutType && criteriaFields.push('payoutType');
  props.settlement && criteriaFields.push('settlement');
  props.networkType && criteriaFields.push('networkType');
  props.settlementType && criteriaFields.push('settlementType');
  props.thirdPartyAction && criteriaFields.push('thirdPartyAction');

  return criteriaFields;
};

const checkRequestBody = (requestBody) => {
  const {
    paymentMethod,
    bookingService,
    bookingType,
    bookFrom,
    ratingType,
    stars,
    serviceType,
    transactionType,
    rideService,
    vehicleRate,
  } = requestBody.criteria;

  let rs = true;

  if (
    (paymentMethod && paymentMethod.length === 0) ||
    (bookingService && bookingService.length === 0) ||
    (bookingType && bookingType.length === 0) ||
    (bookFrom && bookFrom.length === 0) ||
    (ratingType && ratingType.length === 0) ||
    (stars && stars.length === 0) ||
    (serviceType && serviceType.length === 0) ||
    (transactionType && transactionType.length === 0) ||
    (rideService && rideService.length === 0)
  ) {
    rs = false;
  }

  return rs;
};

const mappingPaymentStatus = (value) => {
  const foundStatus = bookingDetailsPaymentStatus.find(
    (o) => o.value.toLowerCase() === value.toLowerCase()
  );
  return foundStatus ? <Translate value={foundStatus.label} /> || '' : '';
};
const mappingPaymentStatusRefund = (value) => {
  const foundStatus = paymentStatusRefund.find(
    (o) => o.value.toLowerCase() === value.toLowerCase()
  );
  return foundStatus ? <Translate value={foundStatus.label} /> || '' : '';
};

const formatCurrency = (value, doc) =>
  currencyFormatter.format(value, {
    code: doc.currencyISO,
  });

const formatCurrencyUSD = (value, doc) =>
  currencyFormatter.format(value, {
    code: 'USD',
  });
const mappingPaymentMethod = (value, doc) => {
  if(value.indexOf('Payment link') >= 0) {
    return value
  }
  const foundStatus = bookingDetailsPaymentMethods.find(
    (o) => o.value.toLowerCase() === value.toLowerCase()
  );
  return foundStatus ? <Translate value={foundStatus.label} /> || '' : value;
};

const renderBookingStatus = (value) => {
  let label = '';
  switch (value) {
    case 'completed':
      label = 'report.query.bookingStatusItem.completed';
      break;
    case 'canceled':
      label = 'report.query.paymentMethodItem.canceled';
      break;
    case 'noShow':
      label = 'report.query.paymentMethodItem.noShow';
      break;
    case 'incident':
      label = 'report.query.paymentMethodItem.incident';
      break;
    case 'completedWithoutService':
      label = 'report.query.paymentMethodItem.completedWithoutService';
      break;
    case 'partialCompleted':
      label = 'dashboard.partialCompleted';
      break;
    case 'failed':
      label = 'dashboard.failed';
      break;
    default:
      return value;
  }
  return <Translate value={label} />;
};

const formatFare = (value) => {
  value = value || 0;
  if (value >= 0) {
    return `${value.toFixed(2)}`;
  } else {
    return `(${(-value).toFixed(2)})`;
  }
};

export default {
  numberFormat,
  formatTime,
  isShowFareFields,
  isShowCorporateAdvanceInfo,
  fareFiedSettings,
  isShowByFilterValue,
  isShowDriverReferralField,
  initCriteriaFields,
  checkRequestBody,
  mappingPaymentStatus,
  formatCurrency,
  formatCurrencyUSD,
  mappingPaymentMethod,
  mappingPaymentStatusRefund,
  convertPrepaidTransaction,
  renderBookingStatus,
  formatFare,
};
