import React, { useState, useEffect } from 'react'
import { Translate, I18n } from 'react-redux-i18n';
import LocationInput3rdGG from '../bookingDetail/bookComponent/LocationInput3rdGG'
import LocationInput3rdTencent from '../bookingDetail/bookComponent/LocationInput3rdTencent'
import { LOCATION_TYPE } from '../../constants/commondata';
import { QQUltis } from '../qqMap';
import { geoPlaceDetailMapProvider } from '../../utils/mapUtils';
import { Validator, ValidCase } from '../validator';
import { FormGroup } from "react-bootstrap";
import { getLanguageAutoCompleteSearch } from '../../utils/commonFunctions';
import { connect } from 'react-redux';

const LocationInputContainer = ({
  setShowAddPoint,
  isShow,
  hasPartyLocation,
  setNewPoints,
  recentPlaces,
  fleetId,
  checkPointInZoneSupported,
  checkSupportZone,
  titleTime,
  format24Hour,
  options,
  address,
  noValidate = false,
  setValidLocationInfo,
  validLocationInfo,
  setIsDirtyLocation,
  isEditDisabled,
  changePlaceOrder,
  pickUp,
  isChina,
  corporate,
  inputTencent,
  input3rd,
  ...props
}) => {
  const [addressText, setAddressText] = useState('')
  const [loadingPickUp, setLoadingPickUp] = useState(false)
  const [location, setLocation] = useState(null)
  const [from, setFrom] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [dataMeta, setDataMeta] = useState({
    seats: 1,
    luggage: 0,
    time: '',
    notes: ''
  })

  useEffect(() => {
    // if(location.address)
    handleAddNewPuPoints()
  }, [location]);

  useEffect(() => {
    if(address && address.address) {
      setAddressText(address.address)
    }
  }, [address && address.address])

  const setValidLocation = (valid) => {
    if(noValidate) {
      setValidLocationInfo({
        ...validLocationInfo,
        valid: valid,
        isSupportZone: valid,
        hasTextNotDetailInfo: valid ? true : validLocationInfo.hasTextNotDetailInfo
      })
    } 
    setIsValid(valid)
  }

  const handleSelectRecentPickUp = (location) => {
    if(location && location.coordinates) {
      location.lat = location.coordinates[1]
      location.lng = location.coordinates[0]
      location.country = location.country || ""
      if(location.city == null) location.city = ''
      setAddressText(location.address)
      setFrom('gg')
      setLocation(location)
    }
  }

  const handleSelectPickUp = (address, placeId, pointOfInterest = '', sessionToken = '') => {
    if (address && address != '') {
      setAddressText(address)
      setLoadingPickUp(true)
      setFrom(LOCATION_TYPE.Google)
      const callback = (err, location, results) => {
        if (err) {
          setLoadingPickUp(false)
          setLocation(null)
        } else {
          setLoadingPickUp(false)
          setLocation(location)
          if(setValidLocationInfo) {
            setValidLocationInfo({
              ...validLocationInfo,
              valid: true,
              hasTextNotDetailInfo: true
            })
          }
        }
      };
      geoPlaceDetailMapProvider({
        auth: props.auth,
        placeid: placeId,
        sessionToken,
        callback,
        language: props?.language,
        isTencent: isChina,
        bookId: props?.data?.bookId
      });
    } else {
      setAddressText(address)
      setLoadingPickUp(false)
    }
  };
        
  const handleChangePickUp = (address) => {
    if (address && address.length > 0) {
      setAddressText(address)
      if(setValidLocationInfo) {
        setValidLocationInfo({
          ...validLocationInfo,
          valid: false,
          hasTextNotDetailInfo: false
        })
      }
    } else {
      handleAddressRemovedPickUp();
    }
  };

  const handleAddressRemovedPickUp = () => {
    setAddressText('')
    setFrom('')
    setLoadingPickUp(false)
    setLocation(null)
    setValidLocation(true)
    setIsValid(true)
    if(setIsDirtyLocation) {
      setIsDirtyLocation(true)
    }
    setNewPoints({
      id: props.id,
      order: props.order || 0
    })
  };

  const handleSelectThirdPartyPickUp = (address, data) => {
    if ((addressText && addressText !== '') || address) {
      if (data && data.location) {
        const cityCountry = data.location.city;
        let cityName = '';
        if (cityCountry) {
          const citySplit = cityCountry.split('_');
          if (citySplit && citySplit.length >= 3) {
            if (citySplit[1]) {
              cityName = citySplit[1];
            } else if (citySplit[2]) {
              cityName = citySplit[2];
            }
          }
        }
        let newPickUpLocation = {
          lat: data.location.coordinates[1],
          lng: data.location.coordinates[0],
          zipCode: data.location.zipCode,
          countryCode: data.location.countryCode,
          city: data.location.city,
          cityName,
          instructionLink: data.location.instructionLink,
          addressDetails: data.location.addressDetails
        }
        
        setAddressText(address)
        setLocation(newPickUpLocation)
        setFrom('3rd')
      }
    } else {
      setAddressText(address)
      setLocation(null)
      setLoadingPickUp(false)
    }
  };

  const handleAddNewPuPoints = () => {
    if(location == null) return
    if(checkSupportZone) { // dành cho pickup
      checkPointInZoneSupported({
        fleetId: fleetId,
        lat: location.lat,
        lng: location.lng
      })
      .then((checkData) => {
        const res = checkData.res || {}
        if (res.valid 
          || ((res.crossZone && !corporate) // Trường hợp book hydra không chọn corporate
              || (res.crossZone && corporate && corporate.isCreateRoaming) // Trường hợp book hydra chọn corporate có on Create roaming
            )
        ) {
          setValidLocation(true)
          setNewPoints({
            address: location,
            addressPointText: addressText,
            from: from,
            id: props.id,
            supportHydraBooking: res.crossZone || false,
            supportLocalBooking: res.valid || false,

            // shuttle
            seats: dataMeta.seats,
            time: dataMeta.time,
            notes: dataMeta.notes,
            luggage: dataMeta.luggage
          })
        } else {
          setValidLocation(false)
        }
      })
    } else { // dành cho destination
      setValidLocation(true)
      setNewPoints({
        address: location,
        addressPointText: addressText,
        from: from,
        id: props.id,

        // shuttle
        seats: dataMeta.seats,
        time: dataMeta.time,
        notes: dataMeta.notes,
        luggage: dataMeta.luggage
      })
    }
  }

  let disableAddBtn = true
  let pickUpEditable = true

  if(from == 'gg' && location && location.addressDetails) disableAddBtn = false
  if(from == '3rd' && location && location.lat && location.lng) disableAddBtn = false

  if(noValidate) return (
    <>
      {
        isChina ? (
          <LocationInput3rdTencent
            recents={pickUpEditable && recentPlaces}
            address={addressText}
            changePlaceOrder={changePlaceOrder}
            onSelectRecent={handleSelectRecentPickUp}
            onChange={handleChangePickUp}
            onRemove={handleAddressRemovedPickUp}
            onSelect={handleSelectPickUp}
            onSelectParty={handleSelectThirdPartyPickUp}
            value={addressText}
            isChina={isChina}
            options={options}
            hasPartyLocation={hasPartyLocation}
            disabled={isEditDisabled}
            className="mb0"
            id={props.id}
            pickUp={pickUp}
            inputTencent={inputTencent}
            input3rd={input3rd}
            data={props.data}
          />
        ) : (
          <LocationInput3rdGG
            recents={pickUpEditable && recentPlaces}
            address={addressText}
            changePlaceOrder={changePlaceOrder}
            onSelectRecent={handleSelectRecentPickUp}
            onChange={handleChangePickUp}
            onRemove={handleAddressRemovedPickUp}
            onSelect={handleSelectPickUp}
            onSelectParty={handleSelectThirdPartyPickUp}
            value={addressText}
            isChina={isChina}
            options={options}
            hasPartyLocation={hasPartyLocation}
            disabled={isEditDisabled}
            className="mb0"
            id={props.id}
            data={props.data}
            pickUp={pickUp}
          />
        )
      }
    </>
  )

  return (
    <FormGroup className={ 
      isValid ?  null : 'warning'
    }>
      {
        isChina ? (
          <LocationInput3rdTencent
            recents={pickUpEditable && recentPlaces}
            address={addressText}
            changePlaceOrder={changePlaceOrder}
            onSelectRecent={handleSelectRecentPickUp}
            onChange={handleChangePickUp}
            onRemove={handleAddressRemovedPickUp}
            onSelect={handleSelectPickUp}
            onSelectParty={handleSelectThirdPartyPickUp}
            value={addressText}
            isChina={isChina}
            options={options}
            hasPartyLocation={hasPartyLocation}
            disabled={isEditDisabled}
            data={props.data}
            className="mb0"
            id={props.id}
            pickUp={pickUp}
            auth={props.auth}
          />
        ) : (
          <LocationInput3rdGG
            recents={pickUpEditable && recentPlaces}
            address={addressText}
            changePlaceOrder={changePlaceOrder}
            onSelectRecent={handleSelectRecentPickUp}
            onChange={handleChangePickUp}
            onRemove={handleAddressRemovedPickUp}
            onSelect={handleSelectPickUp}
            onSelectParty={handleSelectThirdPartyPickUp}
            value={addressText}
            isChina={isChina}
            options={options}
            hasPartyLocation={hasPartyLocation}
            disabled={isEditDisabled}
            className="mb0"
            id={props.id}
            data={props.data}
            pickUp={pickUp}
            auth={props.auth}
          />
        )
      }
      <Validator
        id="addressPickUp"
      >
        <ValidCase
          valid={isValid}
          message={I18n.t('newbooking.No_support_location')}
        />
      </Validator>
    </FormGroup>   
  )
}

LocationInputContainer.defaultProps = {
  isShow: false,
  isChina: false,
  hasPartyLocation: true,
  recentPlaces: {},
  checkSupportZone: false,

  //show/hide modal
  setShowAddPoint: () => {},
  checkPointInZoneSupported: () => {},
  setNewPoints: () => {}
}

function mapStateToProps(state) {
  return {
    language: state.i18n
  };
}

export default connect(
  mapStateToProps,
  null
)(LocationInputContainer);
