import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import '../../settings.scss';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import IntlTelInputApp from '../../../../components/intlTelInputCustome/IntlTelInputApp';
import { trimPhoneNumber } from '../../../../utils/commonFunctions';
import { Translate, I18n } from 'react-redux-i18n';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from './../../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
  distanceConvert,
} from '../../../../utils/commonFunctions';
import { Validator, ValidCase } from '../../../../components/validator';
import UploadFile from '../../../../components/upload/upload';
import {
  additionalServiceType,
  repeatType,
  mapStatus,
  showFare,
  FEE_TYPE,
} from '../../../../constants/commondata';
import { ModalConfirmDeleteSurcharge, ModalAdditionalService } from '../modals';
import AirportFeeFormGroup from './AirportFeeFormGroup';
import ReservationPolicyGroup from './ReservationPolicyGroup';
import TipFromGroup from './TipFromGroup';
import NoShowFormGroup from './NoShowFormGroup';
import HeatMapFormGroup from './HeatMapFormGroup';
import CancelPolicyIntercityFormGroup from './CancelPolicyIntercityFormGroup';
import IntercityConfirmEmailFormGroup from './IntercityConfirmEmailFormGroup';
import FaceMaskFormGroup from './FaceMaskFormGroup';
import BookingValidationGroup from './BookingValidationGroup';
import TransactionFee from './TransactionFee';

class GeneralSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData || {},
      formDataInitial: JSON.parse(JSON.stringify(props.formData)),
      fleet: props.fleet,
      showDialog: false,
      dialogData: {},
      showDialogDelete: false,
      showConfirmDelete: false,
      valid: {},
      additionalServicesValid: {},
      isSubmited: false,
      showAdditionalServicesDialog: false,
      isAddtionalServicesSubmitted: false,
      phoneValidation: [],
      phoneValidationSummary: {
        status: true,
        validatedNumber: {},
      },
    };
    this.handleBooknowCheckChange = this.handleBooknowCheckChange.bind(this);
    this.handleBookNowDurationChange =
      this.handleBookNowDurationChange.bind(this);
    this.handleBookNowTimeChange = this.handleBookNowTimeChange.bind(this);
    this.handleBooklaterCheckChange =
      this.handleBooklaterCheckChange.bind(this);
    this.handleBooklaterDurationChange =
      this.handleBooklaterDurationChange.bind(this);
    this.handleTipCheckChange = this.handleTipCheckChange.bind(this);
    this.handleDefaultTipChange = this.handleDefaultTipChange.bind(this);
    this.handleDriveAppSMSBeforeChange =
      this.handleDriveAppSMSBeforeChange.bind(this);
    this.handlePaxAppAlertBeforeChange =
      this.handlePaxAppAlertBeforeChange.bind(this);
    this.handleSoundNotificationCheckChange =
      this.handleSoundNotificationCheckChange.bind(this);
    this.handleAdvanceInfoCorpCheckChange =
      this.handleAdvanceInfoCorpCheckChange.bind(this);
    this.handlePasengerAsTravelerCheckChange =
      this.handlePasengerAsTravelerCheckChange.bind(this);
    this.handleCancelInputMultiCurrenciesChange =
      this.handleCancelInputMultiCurrenciesChange.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.checkDayIsOverlaps = this.checkDayIsOverlaps.bind(this);
    this.handlenotAllowMakeBookingWithoutAssignFareCheckChange =
      this.handlenotAllowMakeBookingWithoutAssignFareCheckChange.bind(this);
    this.handleDisableDirectInvoicingReceiptEmailCheckChange =
      this.handleDisableDirectInvoicingReceiptEmailCheckChange.bind(this);
    this.onAdditionalServiceChange = this.onAdditionalServiceChange.bind(this);
    this.additionalServicesAddItemClick =
      this.additionalServicesAddItemClick.bind(this);
    this.additionalServiceEditItemClick =
      this.additionalServiceEditItemClick.bind(this);
    this.additionalServiceRemoveItemClick =
      this.additionalServiceRemoveItemClick.bind(this);
    this.additionalServicesAddItemSaveClick =
      this.additionalServicesAddItemSaveClick.bind(this);
    this.additionalServicesAddItemCloseClick =
      this.additionalServicesAddItemCloseClick.bind(this);
    this.closeDeleteServiceDialog = this.closeDeleteServiceDialog.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.handleDateChanged = this.handleDateChanged.bind(this);
    this.handleMapStatusSettingChanged =
      this.handleMapStatusSettingChanged.bind(this);
    this.checkMapStatus = this.checkMapStatus.bind(this);
    this.handleZoneSOSPhoneNumberChanged =
      this.handleZoneSOSPhoneNumberChanged.bind(this);
    this.handleHideProviderFromCorpCheckChange =
      this.handleHideProviderFromCorpCheckChange.bind(this);
    this.handleOptionalEmailPaxAppCheckChange =
      this.handleOptionalEmailPaxAppCheckChange.bind(this);
    this.handleDisableHourlyRateCheckChange =
      this.handleDisableHourlyRateCheckChange.bind(this);
    this.handleTextIHrsMinTypeChange =
      this.handleTextIHrsMinTypeChange.bind(this);
    this.handleGenderRequired = this.handleGenderRequired.bind(this);
  }

  componentDidMount() {
    this.additionalServicesList();
  }

  additionalServicesList = () => {
    this.props.settingActions
      .getAdditionalServicesList({
        fleetId: this.props.auth.selectedFleet.fleetId,
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.state.formData.services = data.res;
          this.setState(
            { formData: this.state.formData },
            this.validateOnStart()
          );
        } else if (data.error) {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('generalSetting.Load_additional_service_fail')
            );
          }
        }
      });
  };

  handleUpdateFormData = (key, value) => {
    const { formData } = this.state;
    _.set(formData, key, value);
    this.setState({ formData });
  };

  handleUpdateFleet = (key, value) => {
    const { fleet } = this.state;
    var valueUpdate = value;
    var keyUpdate = key;
    if (key == 'generalSetting.workingTimeFromHour') {
      keyUpdate = 'generalSetting.workingTimeFrom';
      if (fleet.generalSetting.workingTimeFrom !== undefined) {
        valueUpdate =
          value + ':' + fleet.generalSetting.workingTimeFrom.split(':')[1];
      } else {
        valueUpdate = value + ':00';
      }
    }
    if (key == 'generalSetting.workingTimeFromMin') {
      keyUpdate = 'generalSetting.workingTimeFrom';

      if (fleet.generalSetting.workingTimeFrom !== undefined) {
        valueUpdate =
          fleet.generalSetting.workingTimeFrom.split(':')[0] + ':' + value;
      } else {
        valueUpdate = +':00';
      }
    }
    if (key == 'generalSetting.workingTimeToHour') {
      keyUpdate = 'generalSetting.workingTimeTo';
      if (fleet.generalSetting.workingTimeTo !== undefined) {
        valueUpdate =
          value + ':' + fleet.generalSetting.workingTimeTo.split(':')[1];
      } else {
        valueUpdate = value + ':00';
      }
    }
    if (key == 'generalSetting.workingTimeToMin') {
      keyUpdate = 'generalSetting.workingTimeTo';
      if (fleet.generalSetting.workingTimeTo !== undefined) {
        valueUpdate =
          fleet.generalSetting.workingTimeTo.split(':')[0] + ':' + value;
      } else {
        valueUpdate = value + ':00';
      }
    }
    _.set(fleet, keyUpdate, valueUpdate);
    this.setState({ fleet });
  };
  handleBooknowCheckChange(e) {
    this.state.formData.cancellationPolicy.onDemand = e.target.checked;
    if (!e.target.checked) {
      this.state.formData.cancellationPolicy.duration = '0';
      this.state.formData.cancellationPolicy.times = '0';
    }
    this.setState({ formData: this.state.formData, valid: {} });
  }
  handleDrvCancelCheckChange = (e, key) => {
    const { formData = {} } = this.state;
    const keyActive = key === 'cancelBtnTime' ? 'isHide' : 'isActive';
    this.setState({
      formData: {
        ...formData,
        driverCancelPolicy: {
          ...formData.driverCancelPolicy,
          [key]: {
            ...formData.driverCancelPolicy[key],
            [keyActive]: e.target.checked,
            value: e.target.checked
              ? _.get(formData, `driverCancelPolicy.${key}.value`, 0)
              : 0,
          },
        },
      },
    });
  };
  handleDrvCancelValueChange = (e, key) => {
    const valueParse = (e.target.value || '')
      .toString()
      .replace(/[^.0-9]+/, '');
    let valueParseFloat = parseFloat(valueParse);
    if (isNaN(valueParseFloat)) valueParseFloat = 0;
    const keyActive = key === 'cancelBtnTime' ? 'isHide' : 'isActive';
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        driverCancelPolicy: {
          ...formData.driverCancelPolicy,
          [key]: {
            ...formData.driverCancelPolicy[key],
            [keyActive]: _.get(
              formData,
              `driverCancelPolicy.${key}.${keyActive}`,
              false
            ),
            value: e.target.value,
          },
        },
      },
    });
  };
  handleDrvCancelFreeTimeChange = (e) => {
    const valueParse = (e.target.value || '')
      .toString()
      .replace(/[^.0-9]+/, '');
    let valueParseFloat = parseFloat(valueParse);
    if (isNaN(valueParseFloat)) valueParseFloat = 0;
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        driverCancelPolicy: {
          ...formData.driverCancelPolicy,
          freeCancelTimes: valueParse,
        },
      },
    });
  };
  handleBookNowDurationChange(e) {
    this.state.formData.cancellationPolicy.duration = e.target.value;
    this.setState({ formData: this.state.formData });
  }

  handleDrvCancelUnitChange = (e, key) => {
    const { formData = {} } = this.state;
    const keyActive = key === 'cancelBtnTime' ? 'isHide' : 'isActive';
    const unitCurrent = _.get(
      formData,
      `driverCancelPolicy.${key}.unit`,
      'hour'
    );
    let valueCurrent = _.get(formData, `driverCancelPolicy.${key}.value`, 0);
    if (unitCurrent != e.target.value) {
      if (e.target.value == 'hour') {
        valueCurrent = valueCurrent / 60;
      } else {
        valueCurrent = valueCurrent * 60;
      }
      this.setState({
        formData: {
          ...formData,
          driverCancelPolicy: {
            ...formData.driverCancelPolicy,
            [key]: {
              ...formData.driverCancelPolicy[key],
              [keyActive]: _.get(
                formData,
                `driverCancelPolicy.${key}.${keyActive}`,
                false
              ),
              unit: e.target.value,
              value: valueCurrent,
            },
          },
        },
      });
    }
  };

  handleBookNowTimeChange(e) {
    this.state.formData.cancellationPolicy.times = e.target.value;
    this.setState({ formData: this.state.formData });
  }

  handleBooklaterCheckChange(e) {
    this.state.formData.cancellationPolicy.inAdvance = e.target.checked;
    if (!e.target.checked) {
      this.state.formData.cancellationPolicy.durationIn = '0';
    }
    this.setState({ formData: this.state.formData, valid: {} });
  }

  handleBooklaterDurationChange(e) {
    let regrex = /^(\d*\.?\d?|\d+\.)$/;
    if (regrex.test(e.target.value)) {
      this.state.formData.cancellationPolicy.durationIn = e.target.value;
      this.setState({ formData: this.state.formData });
    }
  }

  handleTipCheckChange(e) {
    this.state.formData.tipActive = e.target.checked;
    if (!e.target.checked) {
      this.state.formData.tips = '0';
    }
    this.setState({ formData: this.state.formData, valid: {} });
  }
  handleDefaultTipChange(e) {
    this.state.formData.tips = e.target.value;
    this.setState({ formData: this.state.formData });
  }
  handleDriveAppSMSBeforeChange(e) {
    let regrex = /^(\d*\.?\d?|\d+\.)$/;
    if (regrex.test(e.target.value)) {
      this.state.fleet.driverAppAlert = e.target.value;
      this.setState({ formData: this.state.formData });
    }
  }

  handlePaxAppAlertBeforeChange(e) {
    let regrex = /^(\d*\.?\d?|\d+\.)$/;
    if (regrex.test(e.target.value)) {
      this.state.fleet.paxAppAlert = e.target.value;
      this.setState({ formData: this.state.formData });
    }
  }
  handleSoundNotificationCheckChange(e) {
    this.state.fleet.generalSetting.soundNotify = e.target.checked;
    this.setState({ fleet: this.state.fleet });
  }
  handleAdvanceInfoCorpCheckChange(e) {
    this.state.fleet.generalSetting.advanceInfoCorp = e.target.checked;
    this.setState({ fleet: this.state.fleet });
  }
  handlePasengerAsTravelerCheckChange(e) {
    this.state.fleet.generalSetting.regAsCorpTraveler = e.target.checked;
    this.setState({ fleet: this.state.fleet });
  }

  handlenotAllowMakeBookingWithoutAssignFareCheckChange(e) {
    this.state.fleet.generalSetting.notAllowMakeBookingWithoutAssignFare =
      e.target.checked;
    this.setState({ fleet: this.state.fleet });
  }

  handlenotAllowShowCarTypeCCWithoutRateChange = (e) => {
    this.state.fleet.generalSetting.notShowCarTypeCCWithoutRate =
      e.target.checked;
    this.setState({ fleet: this.state.fleet });
  };

  handleDisableDirectInvoicingReceiptEmailCheckChange(e) {
    this.state.fleet.generalSetting.disableInvoicingReceipt = e.target.checked;
    this.setState({ fleet: this.state.fleet });
  }

  handleHideProviderFromCorpCheckChange(e) {
    this.state.fleet.generalSetting.hideProviderFromCorp = e.target.checked;
    this.setState({ fleet: this.state.fleet });
  }

  handleOptionalEmailPaxAppCheckChange(e) {
    this.state.fleet.generalSetting.optionalEmailPaxApp = e.target.checked;
    this.setState({ fleet: this.state.fleet });
  }

  handleDisableHourlyRateCheckChange(e) {
    this.state.fleet.generalSetting.disableHourlyRate = e.target.checked;
    this.setState({ fleet: this.state.fleet });
  }

  handleTextIHrsMinTypeChange(type, e) {
    switch (type) {
      case 'book_later': {
        if (
          this.state.formData.cancellationPolicy.durationInUnit !=
          e.target.value
        ) {
          this.state.formData.cancellationPolicy.durationInUnit =
            e.target.value;
          if (this.state.formData.cancellationPolicy.durationInUnit == 'hour') {
            this.state.formData.cancellationPolicy.durationIn =
              this.state.formData.cancellationPolicy.durationIn / 60;
          } else {
            this.state.formData.cancellationPolicy.durationIn =
              this.state.formData.cancellationPolicy.durationIn * 60;
          }
          this.setState({ formData: this.state.formData });
        }
        break;
      }
      case 'driverAppAlert': {
        if (this.state.fleet.driverAppAlertUnit != e.target.value) {
          this.state.fleet.driverAppAlertUnit = e.target.value;
          if (this.state.fleet.driverAppAlertUnit == 'hour') {
            this.state.fleet.driverAppAlert =
              this.state.fleet.driverAppAlert / 60;
          } else {
            this.state.fleet.driverAppAlert =
              this.state.fleet.driverAppAlert * 60;
          }
          this.setState({ fleet: this.state.fleet });
        }
        break;
      }
      case 'paxAppAlert': {
        if (this.state.fleet.paxAppAlertUnit != e.target.value) {
          this.state.fleet.paxAppAlertUnit = e.target.value;
          if (this.state.fleet.paxAppAlertUnit == 'hour') {
            this.state.fleet.paxAppAlert = this.state.fleet.paxAppAlert / 60;
          } else {
            this.state.fleet.paxAppAlert = this.state.fleet.paxAppAlert * 60;
          }
          this.setState({ fleet: this.state.fleet });
        }
        break;
      }
    }
  }

  onAdditionalServiceChange(e) {
    this.state.formData.additionalServicesActive = e.target.checked;
    this.setState({ formData: this.state.formData });
  }

  additionalServicesAddItemClick() {
    let dialogData = {
      serviceName: '',
      serviceType: additionalServiceType.Optional,
      serviceFeeType: FEE_TYPE.Amount,
      serviceFeeByCurrencies: this.props.auth.selectedFleet.currencies.map(
        (data) => {
          return {
            value: 0,
            currencyISO: data.iso,
          };
        }
      ),
      vehicleType: [],
      isActive: true,
    };
    this.state.dialogData.service = dialogData;
    this.setState({
      showAdditionalServicesDialog: true,
      dialogData: this.state.dialogData,
    });
  }

  additionalServiceEditItemClick(item, index) {
    this.props.settingActions
      .additionalServicesGetOne({
        fleetId: this.props.auth.selectedFleet.fleetId,
        _id: item._id,
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.state.dialogData.service = data.res;
          this.setState({
            dialogData: this.state.dialogData,
            showAdditionalServicesDialog: true,
          });
        } else if (data.error) {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('generalSetting.Adding_additional_service_fail')
            );
          }
        }
      });
  }

  additionalServiceRemoveItemClick(item, index) {
    this.state.dialogData.service = item;
    this.itemConfirm = 'service';
    this.setState({
      showConfirmDelete: true,
      dialogData: this.state.dialogData,
      itemConfirm: 'service',
    });
  }

  additionalServicesAddItemSaveClick(service) {
    this.props.loadingBarActions.showLoadingSpiner();
    if (service && service._id) {
      const serviceData = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        _id: service._id,
        serviceName: service.serviceName,
        serviceType: service.serviceType,
        serviceFeeByCurrencies: service.serviceFeeByCurrencies,
        serviceFeeType: service.serviceFeeType,
        vehicleType: service.vehicleType.map((item) => item.value),
        isActive: true,
      };
      this.props.settingActions
        .additionalServicesUpdate(serviceData)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (data.ok && data.res) {
            let index = _.findIndex(
              this.state.formData.services,
              function (service) {
                return service._id == data.res._id;
              }
            );
            const service = {
              fleetId: data.res.fleetId,
              serviceName: data.res.serviceName,
              serviceType: data.res.serviceType,
              serviceFeeByCurrencies: data.res.serviceFeeByCurrencies,
              serviceFeeType: data.res.serviceFeeType,
              vehicleType: data.res.vehicleType,
              _id: data.res._id,
              isActive: data.res.isActive,
            };
            this.state.formData.services[index] = service;
            this.setState({
              formData: this.state.formData,
              isAddtionalServicesSubmitted: false,
            });
            this.context.notification(
              'success',
              I18n.t('generalSetting.Update_additional_service_success')
            );
          } else if (data.error) {
            if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('generalSetting.Update_additional_service_fail')
              );
            }
          }
        });
    } else {
      const serviceData = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        serviceName: service.serviceName,
        serviceType: service.serviceType,
        serviceFeeByCurrencies: service.serviceFeeByCurrencies,
        serviceFeeType: service.serviceFeeType,
        vehicleType: service.vehicleType.map((item) => item.value),
        isActive: true,
      };
      this.props.settingActions
        .createAdditionalService(serviceData)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (data.ok && data.res) {
            const service = {
              fleetId: data.res.fleetId,
              serviceName: data.res.serviceName,
              serviceType: data.res.serviceType,
              serviceFeeByCurrencies: data.res.serviceFeeByCurrencies,
              serviceFeeType: data.res.serviceFeeType,
              vehicleType: data.res.vehicleType,
              _id: data.res._id,
              isActive: data.res.isActive,
            };
            this.state.formData.services.unshift(service);
            this.setState({
              formData: this.state.formData,
              isAddtionalServicesSubmitted: false,
            });
            this.context.notification(
              'success',
              I18n.t('generalSetting.Adding_additional_service_success')
            );
          } else if (data.error) {
            if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('generalSetting.Adding_additional_service_fail')
              );
            }
          }
        });
    }
    service = {};
    this.setState({
      showAdditionalServicesDialog: false,
      dialogData: this.state.dialogData,
    });
  }

  additionalServicesAddItemCloseClick() {
    this.state.dialogData.service = {};
    this.setState({
      showAdditionalServicesDialog: false,
      dialogData: this.state.dialogData,
      isAddtionalServicesSubmitted: false,
    });
  }

  closeDeleteServiceDialog() {
    this.setState({ showConfirmDelete: false });
  }

  confirmDelete(e) {
    if (this.state.itemConfirm == 'service') {
      const { formData } = this.state;
      let services = formData.services;
      this.props.loadingBarActions.showLoadingSpiner();
      const dialogData = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        _id: this.state.dialogData.service._id,
      };
      this.props.settingActions
        .deleteAdditionalService(dialogData)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (data.ok && data.res) {
            this.context.notification(
              'success',
              I18n.t('generalSetting.Delete_additional_service_success')
            );
            this.closeDeleteServiceDialog();
            services = services.filter(
              (sv) => sv._id !== this.state?.dialogData?.service?._id
            );
            this.setState({
              formData: {
                ...formData,
                services,
              },
            });
          } else if (data.error) {
            if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('generalSetting.Adding_additional_service_fail')
              );
            }
          }
        });
    } else if (this.state.itemConfirm == 'surcharge') {
      this.props.loadingBarActions.showLoadingSpiner();
      this.props.settingActions.deleteSurcharge(this.options).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.ok && data.res) {
          this.closeDeleteServiceDialog();
          this.state.formData.rushHours = data.res;
          this.setState({ formData: this.state.formData });
          this.context.notification(
            'success',
            I18n.t('generalSetting.Delete_surcharge_success')
          );
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t('generalSetting.Delete_surcharge_fail')
          );
        }
      });
    }
  }

  /*
   ** Name: checkDayIsOverlaps
   ** Description: with the surcharge type is single/yearly, check startDate is overlaped, it's different with day of week
   ** Parameters: object(selected Surcharge)
   ** Return: boolean(overlapped or not)
   */
  checkDayIsOverlaps(selectedSurcharge) {
    const surchargeList = this.state.formData.rushHours.slice();
    let isValid = true;
    let startDateOfSelectedSurcharge = moment(selectedSurcharge.startDate);
    _.map(surchargeList, (surcharge) => {
      if (
        selectedSurcharge.repeat === surcharge.repeat &&
        (!selectedSurcharge.rushHourId ||
          selectedSurcharge.rushHourId !== surcharge.rushHourId)
      ) {
        let startDateOfSurcharge = moment(surcharge.startDate);
        if (selectedSurcharge.repeat === repeatType.SingleDay) {
          if (
            startDateOfSelectedSurcharge.isSame(surcharge.startDate, 'day') &&
            Validation.checkTimeOverlapInDate(
              selectedSurcharge.start,
              selectedSurcharge.end,
              surcharge.start,
              surcharge.end
            )
          ) {
            isValid = false;
            return false;
          }
        } else if (selectedSurcharge.repeat === repeatType.Yearly) {
          if (
            startDateOfSelectedSurcharge.date() ===
              startDateOfSurcharge.date() &&
            startDateOfSelectedSurcharge.month() ===
              startDateOfSurcharge.month() &&
            Validation.checkTimeOverlapInDate(
              selectedSurcharge.start,
              selectedSurcharge.end,
              surcharge.start,
              surcharge.end
            )
          ) {
            isValid = false;
            return false;
          }
        }
      }
    });
    return isValid;
  }

  handleCancelInputMultiCurrenciesChange(group, currency, e) {
    let cur = {
      currencyISO: currency,
      value: e.target.value,
    };
    let isAdd = true;
    switch (group) {
      case 'surchargeByCurrencies': {
        let numberWithDotReg = /^(\d*\.?\d+|\d+\.)$/;
        let numberWithCommaReg = /^(\d*\,?\d+|\d+\,)$/;
        if (
          numberWithDotReg.test(e.target.value) ||
          numberWithCommaReg.test(e.target.value) ||
          Validation.isStringEmpty(e.target.value)
        ) {
          this.state.dialogData.surchargeByCurrencies =
            this.state.dialogData.surchargeByCurrencies ||
            this.props.auth.selectedFleet.currencies.map((data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            });
          this.state.dialogData.surchargeByCurrencies =
            this.state.dialogData.surchargeByCurrencies.map((c) => {
              if (c.currencyISO == currency) {
                isAdd = false;
                return { ...c, value: e.target.value };
              }
              return c;
            });
          if (isAdd) {
            this.state.dialogData.surchargeByCurrencies.push(cur);
          }
          this.setState({ dialogData: this.state.dialogData });
        }
        break;
      }
      case 'fromAirportByCurrencies': {
        this.state.formData.airport.fromAirportByCurrencies =
          this.state.formData.airport.fromAirportByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          });
        this.state.formData.airport.fromAirportByCurrencies =
          this.state.formData.airport.fromAirportByCurrencies.map((c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          });
        if (isAdd) {
          this.state.formData.airport.fromAirportByCurrencies.push(cur);
        }
        this.setState({ formData: this.state.formData });
        break;
      }
      case 'toAirportByCurrencies': {
        this.state.formData.airport.toAirportByCurrencies =
          this.state.formData.airport.toAirportByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          });
        this.state.formData.airport.toAirportByCurrencies =
          this.state.formData.airport.toAirportByCurrencies.map((c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          });
        if (isAdd) {
          this.state.formData.airport.toAirportByCurrencies.push(cur);
        }
        this.setState({ formData: this.state.formData });
        break;
      }
      case 'onCurbByCurrencies': {
        this.state.formData.meetDriver.onCurbByCurrencies =
          this.state.formData.meetDriver.onCurbByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          });
        this.state.formData.meetDriver.onCurbByCurrencies =
          this.state.formData.meetDriver.onCurbByCurrencies.map((c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          });
        if (isAdd) {
          this.state.formData.meetDriver.onCurbByCurrencies.push(cur);
        }
        this.setState({ formData: this.state.formData });
        break;
      }
      case 'meetDrvByCurrencies': {
        this.state.formData.meetDriver.meetDrvByCurrencies =
          this.state.formData.meetDriver.meetDrvByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          });
        this.state.formData.meetDriver.meetDrvByCurrencies =
          this.state.formData.meetDriver.meetDrvByCurrencies.map((c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          });
        if (isAdd) {
          this.state.formData.meetDriver.meetDrvByCurrencies.push(cur);
        }
        this.setState({ formData: this.state.formData });
        break;
      }
      case 'heavyTrafficByCurrencies': {
        this.state.formData.heavyTrafficByCurrencies =
          this.state.formData.heavyTrafficByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          });
        this.state.formData.heavyTrafficByCurrencies =
          this.state.formData.heavyTrafficByCurrencies.map((c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          });
        if (isAdd) {
          this.state.formData.heavyTrafficByCurrencies.push(cur);
        }
        this.setState({ formData: this.state.formData });
        break;
      }
      case 'serviceFeeByCurrencies': {
        this.state.dialogData.service.serviceFeeByCurrencies =
          this.state.dialogData.service.serviceFeeByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          });
        this.state.dialogData.service.serviceFeeByCurrencies =
          this.state.dialogData.service.serviceFeeByCurrencies.map((c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          });
        if (isAdd) {
          this.state.dialogData.service.serviceFeeByCurrencies.push(cur);
        }
        this.setState({ dialogData: this.state.dialogData });
        break;
      }
      case 'otherFee': {
        this.state.formData.otherFee.valueByCurrencies =
          this.state.formData.otherFee.valueByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          });
        this.state.formData.otherFee.valueByCurrencies =
          this.state.formData.otherFee.valueByCurrencies.map((c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          });
        if (isAdd) {
          this.state.formData.otherFee.valueByCurrencies.push(cur);
        }
        this.setState({ formData: this.state.formData });
        break;
      }
    }
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handleImageChange(data, error) {
    let result = '',
      file = null;

    if (data) {
      result = data.result;
      file = data.file;
    }
    this.setState({
      logoCustomize: result,
    });
  }

  handleSaveClick = () => {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    const {
      fleet: { generalSetting },
      formData,
    } = this.state;
    const unitDistance = _.get(
      this.props.auth.selectedFleet,
      'unitDistance',
      'km'
    );
    const data = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      cancellationPolicy: {
        onDemand: this.state.formData.cancellationPolicy.onDemand,
        duration: this.state.formData.cancellationPolicy.duration,
        times: this.state.formData.cancellationPolicy.times,
        inAdvance: this.state.formData.cancellationPolicy.inAdvance,
        durationIn: this.state.formData.cancellationPolicy.durationIn,
        durationInUnit: this.state.formData.cancellationPolicy.durationInUnit,
        intercity: this.state.formData.cancellationPolicy.intercity,
        intercityDuration:
          this.state.formData.cancellationPolicy.intercityDuration,
        intercityDurationUnit:
          this.state.formData.cancellationPolicy.intercityDurationUnit,
      },
      logoCustomize: this.state.logoCustomize,
      logoCustomizeSaved: this.state.fleet.logoCustomize,
      driverCancelPolicy: this.state.formData.driverCancelPolicy,
      noShow: {
        isActive: this.state.formData.noShow.isActive,
        applyCountDown: this.state.formData.noShow.applyCountDown,
        countdownDuration: this.state.formData.noShow.applyCountDown
          ? this.state.formData.noShow.countdownDuration
          : 0,
        maxWaitTime: this.state.formData.noShow.applyCountDown
          ? this.state.formData.noShow.maxWaitTime
          : 0,
        isLimitRadius: this.state.formData.noShow.isLimitRadius,
        limitRadiusValue: this.state.formData.noShow.limitRadiusValue,
      },
      limitRadiusDriverCanArriveAndPickup: {
        enable: formData.limitRadiusDriverCanArriveAndPickup.enable,
        value: formData.limitRadiusDriverCanArriveAndPickup.enable
          ? distanceConvert(
              formData.limitRadiusDriverCanArriveAndPickup.value,
              unitDistance,
              true
            )
          : 0,
      },
      airport: {
        isCustomized: this.state.formData.airport.isCustomized,
        fromAirportActive: this.state.formData.airport.fromAirportActive,
        fromAirport: this.state.formData.airport.fromAirport,
        toAirportActive: this.state.formData.airport.toAirportActive,
        toAirport: this.state.formData.airport.toAirport,
        payTo: this.state.formData.airport.payTo,
        fromAirportByCurrencies:
          this.state.formData.airport.fromAirportByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }),
        toAirportByCurrencies:
          this.state.formData.airport.toAirportByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }),
      },
      meetDriver: {
        onCurb: this.state.formData.meetDriver.onCurb,
        meetDrv: this.state.formData.meetDriver.meetDrv,
        payTo: this.state.formData.meetDriver.payTo,
        meetDrvByCurrencies:
          this.state.formData.meetDriver.meetDrvByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }),
        onCurbByCurrencies:
          this.state.formData.meetDriver.onCurbByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }),
      },
      additionalServicesActive: this.state.formData.additionalServicesActive,
      rushHourActive: this.state.formData.rushHourActive,
      otherFeeActive: this.state.formData.otherFeeActive,
      otherFee: {
        value: this.state.formData.otherFee.value,
        isEdit: this.state.formData.otherFee.isEdit,
        addNote: this.state.formData.otherFee.addNote,
        limitDriverInputActive:
          this.state.formData.otherFee.limitDriverInputActive,
        otherFeeDriverCanInput:
          this.state.formData.otherFee.otherFeeDriverCanInput ||
          this.props.auth.selectedFleet.currencies.map((c) => ({
            value: 0,
            currencyISO: c.iso,
          })),
        valueByCurrencies:
          this.state.formData.otherFee.valueByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => ({
            value: 0,
            currencyISO: data.iso,
          })),
      },
      taxActive: this.state.formData.taxActive,
      tax: this.state.formData.tax,
      tipActive: this.state.formData.tipActive,
      tips: this.state.formData.tips,
      tipPaymentMethod: this.state.formData.tipPaymentMethod,
      tipDefaultValues: this.state.formData.tipDefaultValues,
      tipDefaultType: this.state.formData.tipDefaultType || 'amount',
      tollFeeActive: this.state.formData.tollFeeActive,
      tollFeePayTo: this.state.formData.tollFeePayTo,
      tollFeeDriverCanInput: this.state.formData.tollFeeDriverCanInput,
      tollFeeLimitDriverInputActive:
        this.state.formData.tollFeeLimitDriverInputActive,
      parkingFeeActive: this.state.formData.parkingFeeActive,
      parkingFeePayTo: this.state.formData.parkingFeePayTo,
      parkingFeeDriverCanInput: this.state.formData.parkingFeeDriverCanInput,
      parkingFeeLimitDriverInputActive:
        this.state.formData.parkingFeeLimitDriverInputActive,
      gasFeeActive: this.state.formData.gasFeeActive,
      gasFeePayTo: this.state.formData.gasFeePayTo,
      gasFeeDriverCanInput: this.state.formData.gasFeeDriverCanInput,
      gasFeeLimitDriverInputActive:
        this.state.formData.gasFeeLimitDriverInputActive,
      heavyTrafficActive: this.state.formData.heavyTrafficActive,
      heavyTrafficSurcharge: this.state.formData.heavyTrafficSurcharge,
      heavyTrafficByCurrencies:
        this.state.formData.heavyTrafficByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: 0,
            currencyISO: data.iso,
          };
        }),
      driverAppAlert: this.state.fleet.driverAppAlert,
      driverAppAlertUnit: this.state.fleet.driverAppAlertUnit,
      paxAppAlert: this.state.fleet.paxAppAlert,
      paxAppAlertUnit: this.state.fleet.paxAppAlertUnit,
      intercityEmailConfirmBefore: this.state.fleet.intercityEmailConfirmBefore,
      intercityEmailConfirmBeforeUnit:
        this.state.fleet.intercityEmailConfirmBeforeUnit,
      generalSetting: {
        advanceInfoCorp: generalSetting.advanceInfoCorp,
        soundNotify: generalSetting.soundNotify,
        regAsCorpTraveler: generalSetting.regAsCorpTraveler,
        notAllowMakeBookingWithoutAssignFare:
          generalSetting.notAllowMakeBookingWithoutAssignFare,
        notShowCarTypeCCWithoutRate:
          generalSetting.notShowCarTypeCCWithoutRate || false,
        disableInvoicingReceipt: generalSetting.disableInvoicingReceipt,
        hideProviderFromCorp: !!generalSetting.hideProviderFromCorp,
        optionalEmailPaxApp: !!generalSetting.optionalEmailPaxApp,
        disableHourlyRate: !!generalSetting.disableHourlyRate,
        genderRequireInPaxApp: !!generalSetting.genderRequireInPaxApp,
        compulsoryAddressInPaxApp:
          generalSetting.compulsoryAddressInPaxApp || false,
        compulsoryIdNationalIcInPaxApp:
          generalSetting.compulsoryIdNationalIcInPaxApp || false,
        allowPassengerToUploadDocument:
          generalSetting.allowPassengerToUploadDocument || false,
        compulsoryNationalIcDocumentInPaxApp:
          (generalSetting.allowPassengerToUploadDocument &&
            generalSetting.compulsoryNationalIcDocumentInPaxApp) ||
          false,
        doNotAllowAddDriverWhenNationalIdDuplicated:
          generalSetting.doNotAllowAddDriverWhenNationalIdDuplicated || false,
        showTotalFareWithoutPromotionOnDriverApp:
          generalSetting.showTotalFareWithoutPromotionOnDriverApp || false,
        hideFareOnPaxAppForAllCorporateBookings:
          generalSetting.hideFareOnPaxAppForAllCorporateBookings || false,
        hidePaxNameAvatarOnDispatchScreen:
          generalSetting.hidePaxNameAvatarOnDispatchScreen || false,
        showFullCustomerNameOnDriverApp:
          generalSetting.showFullCustomerNameOnDriverApp || false,
        hideDriverLogoutBtn: generalSetting.hideDriverLogoutBtn || false,
        showMeetAndGreetButtonForEveryBooking:
          generalSetting.showMeetAndGreetButtonForEveryBooking || false,
        intercityShowRequestTabOnDriverApp:
          generalSetting.intercityShowRequestTabOnDriverApp || false,
        intercityShowPreferredTabOnDriverApp:
          generalSetting.intercityShowPreferredTabOnDriverApp || false,
        driverStartTripBeforeSetTime:
          generalSetting.driverStartTripBeforeSetTime || 30,
        workingTimeTo: generalSetting.workingTimeTo || '22:00',
        workingTimeFrom: generalSetting.workingTimeFrom || '05:00',
        intercityTripTimeRange: generalSetting.intercityTripTimeRange || 30,
        vehicleAgeLimit: generalSetting.vehicleAgeLimit
          ? generalSetting.vehicleAgeLimit
          : { enable: false, years: 10 },
        founderFund: generalSetting.founderFund
          ? generalSetting.founderFund
          : { firstBooking: false },
        forceCustomerToUseLiveLocation:
          generalSetting.forceCustomerToUseLiveLocation || false,
        disableMarketPlace: generalSetting.disableMarketPlace || false,
        dontShowSecondStageImmediately:
          generalSetting.dontShowSecondStageImmediately || false,
        compulsoryCarInsurance: generalSetting.compulsoryCarInsurance || false,
        cancelTimeout: generalSetting.cancelTimeout || false,
        allowStripePaymentLink: generalSetting.allowStripePaymentLink || false,
        reservation: generalSetting.reservation || {
          policy: 'samePickup',
          expected: 'before',
          minutesToArrive: 0,
        },
      },
      statusOnMap: this.state.fleet.statusOnMap,
      SOSNumber: {
        defaultNumber: trimPhoneNumber(
          this.state.fleet.SOSNumber.defaultNumber
        ),
        zoneNumber: this.state.fleet.SOSNumber.zoneNumber.map((zp) => {
          return {
            zoneId: zp.zoneId,
            phone: trimPhoneNumber(zp.phone),
          };
        }),
      },
      heatMapSetting: this.state.fleet.heatMapSetting,
      showFare: this.state.fleet.showFare,
      creditCardTransactionFee: this.state.fleet.creditCardTransactionFee,
      faceMaskAuth: {
        isCustomized: this.state.fleet.faceMaskAuth.isCustomized || false,
        isTurnOnVerify: this.state.fleet.faceMaskAuth.isTurnOnVerify || false,
        isForce: this.state.fleet.faceMaskAuth.isForce || false,
        timeRangeToVerify:
          this.state.fleet.faceMaskAuth.timeRangeToVerify || 1440,
        skipVerifyTimes: this.state.fleet.faceMaskAuth.skipVerifyTimes || 2,
        needToVerify: {
          openApp: this.state.fleet.faceMaskAuth.needToVerify.openApp || false,
          startReservation:
            this.state.fleet.faceMaskAuth.needToVerify.startReservation ||
            false,
        },
      },
    };
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.settingActions.saveGeneralSetting(data).then((data) => {
      this.props.loadingBarActions.hideLoadingSpiner();
      this.setState({ isSubmited: false });

      if (data.ok && data.res) {
        this.context.notification(
          'success',
          I18n.t('generalSetting.Update_general_success')
        );
        setTimeout(function () {
          window.location.reload();
        }, 1 * 1000);
      } else {
        if (data.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('generalSetting.Update_general_fail')
          );
        }
      }
    });
  };

  handleDateChanged(e) {
    if (e._d) {
      this.setState({
        dialogData: {
          ...this.state.dialogData,
          dateSurcharge: e.format('MM/DD/YYYY'),
        },
      });
    }
  }

  handleMapStatusSettingChanged(code) {
    if (!this.state.fleet.statusOnMap) {
      this.state.fleet.statusOnMap = [];
    }
    if (code == 'All') {
      if (this.state.fleet.statusOnMap.length == mapStatus.length) {
        this.state.fleet.statusOnMap = [];
      } else {
        this.state.fleet.statusOnMap = mapStatus.map((status) => {
          return status.code;
        });
      }
    } else {
      if (this.state.fleet.statusOnMap.includes(code)) {
        this.state.fleet.statusOnMap = this.state.fleet.statusOnMap.filter(
          (status) => status != code
        );
      } else {
        this.state.fleet.statusOnMap.push(code);
      }
    }
    this.setState({ fleet: this.state.fleet });
  }

  checkMapStatus(code) {
    if (code == 'All') {
      return this.state.fleet.statusOnMap.length == mapStatus.length;
    } else {
      return (
        this.state.fleet.statusOnMap &&
        this.state.fleet.statusOnMap.includes(code)
      );
    }
  }

  validateOnStart = () => {
    const { SOSNumber: { zoneNumber, defaultNumber } = {} } =
      this.state.fleet || {};
    this.handleValidateSOSNumber('defaultNumber', defaultNumber);
    if (zoneNumber)
      _.forEach(zoneNumber, (number) => {
        const { zoneId, phone } = number;
        this.handleValidateSOSNumber(zoneId, phone);
      });
  };

  handleValidateSOSNumber = (key, phoneNumber) => {
    const phoneNumberRegex =
      /^[+]{0,1}[0-9]{1,14}$/g; /* ^[+]{0,1}[0-9]{1,4}[-\s\./0-9]*$ => Support special character like -, space, . */
    const status =
      !phoneNumber || phoneNumber.trim() === ''
        ? true
        : phoneNumberRegex.test(phoneNumber);
    const updateNumber = !phoneNumber
      ? ''
      : phoneNumber.replace(/[^+0-9]+|\s+/gim, '');
    this.handleZoneSOSPhoneNumberChanged(
      key,
      status,
      updateNumber,
      null,
      updateNumber,
      null
    );
  };

  handleZoneSOSPhoneNumberChanged(
    key,
    status,
    value,
    countryData,
    number,
    id
  ) /* (e, key) */ {
    const newState = {};
    if (key === 'defaultNumber') {
      newState.fleet = {
        ...this.state.fleet,
        SOSNumber: {
          ...this.state.fleet.SOSNumber,
          defaultNumber: number,
        },
      };
      newState.phoneValidation = {
        ...this.state.phoneValidation,
        defaultNumber: status,
      };
    } else {
      const zoneNumber = this.state.fleet.SOSNumber.zoneNumber.map((zp) => {
        if (zp.zoneId === key)
          return {
            ...zp,
            phone: number,
          };
        return zp;
      });
      newState.fleet = {
        ...this.state.fleet,
        SOSNumber: {
          ...this.state.fleet.SOSNumber,
          zoneNumber,
        },
      };
      newState.phoneValidation = {
        ...this.state.phoneValidation,
        [key]: status,
      };
    }
    const phoneValidationSummary = ((phoneValidation) => {
      for (let pvkey in phoneValidation) {
        if (!phoneValidation[pvkey])
          return {
            status: false,
            validatedNumber: phoneValidation,
          };
      }
      return {
        status: true,
        validatedNumber: {},
      };
    })(newState.phoneValidation);
    newState.phoneValidationSummary = phoneValidationSummary;
    this.setState({
      ...newState,
    });
  }

  handleGenderRequired(e) {
    this.state.fleet.generalSetting.genderRequireInPaxApp = e.target.checked;
    this.setState({ fleet: this.state.fleet });
  }

  handleRemoveLogoCustomize = () => {
    this.setState({
      fleet: {
        ...this.state.fleet,
        logoCustomize: '',
      },
    });
  };

  handleGeneralSettingChange = (e) => {
    let key = e.target.id,
      value = e.target.checked;
    this.setState({
      fleet: {
        ...this.state.fleet,
        generalSetting: {
          ...this.state.fleet.generalSetting,
          [key]: value,
        },
      },
    });
  };

  handleGeneralSettingFounderFundChange = (e) => {
    let key = e.target.id,
      value = e.target.checked;
    this.setState({
      fleet: {
        ...this.state.fleet,
        generalSetting: {
          ...this.state.fleet.generalSetting,
          founderFund: {
            ...this.state.fleet.generalSetting.founderFund,
            [key]: value,
          },
        },
      },
    });
  };

  handleChangeVehicleAgeLimit = (e) => {
    const { vehicleAgeLimit } = this.state.fleet.generalSetting;
    let key = e.target.id,
      value = e.target.checked;
    this.setState({
      fleet: {
        ...this.state.fleet,
        generalSetting: {
          ...this.state.fleet.generalSetting,
          vehicleAgeLimit: {
            ...vehicleAgeLimit,
            enable: value,
          },
        },
      },
    });
  };

  handleChangeVehicleAgeLimitYears = (e) => {
    const { vehicleAgeLimit } = this.state.fleet.generalSetting;
    let value = e.target.value;
    this.setState({
      fleet: {
        ...this.state.fleet,
        generalSetting: {
          ...this.state.fleet.generalSetting,
          vehicleAgeLimit: {
            ...vehicleAgeLimit,
            years: value,
          },
        },
      },
    });
  };

  render() {
    const { selectedFleet } = this.props.auth;
    const editable = this.props.permissions && !this.props.permissions.actions;
    const {
      dialogData,
      formData,
      fleet: { countryCode, logoCustomize },
      phoneValidationSummary: { status, validatedNumber },
    } = this.state;
    const { service } = dialogData;
    const isHideEmergencyContacts =
      !this.state.fleet.SOS ||
      (!this.state.fleet.SOS.driver && !this.state.fleet.SOS.passenger);
    const hasPermission =
      !this.props.permissions || this.props.permissions.actions;
    const enableFaceMaskAuth =
      this.state.fleet.faceMaskAuth && this.state.fleet.faceMaskAuth.enable
        ? this.state.fleet.faceMaskAuth.enable
        : false;
    const enableReservationPolicy =
      this.state.fleet.cue && this.state.fleet.cue.showETA
        ? this.state.fleet.cue.showETA
        : false;
    const enableReferral = selectedFleet.referral
      ? selectedFleet.referral.enable || selectedFleet.referral.paxReferPax
      : false;
    const enableFleetService =
      _.get(this.props.auth, 'selectedFleet.additionalFees', '') == 1;
    const enablePenalty = _.get(
      this.props.auth,
      'selectedFleet.drvApp.enablePenalty',
      false
    );
    const isStripeGateway =
      _.get(selectedFleet, 'creditConfig.configGateway.gateway', '') ===
      'Stripe';
    const isAllCarHideCancelBtn = _.get(
      this.props.auth,
      'selectedFleet.isAllCarHideCancelBtn',
      false
    );
    let fileName = '';
    if (logoCustomize && logoCustomize.length > 0) {
      let splitUrl = logoCustomize.split('/');
      fileName = splitUrl[splitUrl.length - 1];
    }
    return this.state.formData ? (
      <div className="content custom-form overflow-auto general-setting pl0">
        {this.state.showConfirmDelete ? (
          <ModalConfirmDeleteSurcharge
            closeDeleteServiceDialog={this.closeDeleteServiceDialog}
            confirmDelete={this.confirmDelete}
            itemConfirm={this.state.itemConfirm}
          />
        ) : null}
        {this.state.showAdditionalServicesDialog ? (
          <ModalAdditionalService
            additionalServicesAddItemCloseClick={
              this.additionalServicesAddItemCloseClick
            }
            additionalServicesAddItemSaveClick={
              this.additionalServicesAddItemSaveClick
            }
            service={service}
            auth={this.props.auth}
            permissions={this.props.permissions}
            editable={editable}
            settingActions={this.props.settingActions}
          />
        ) : null}
        <Row>
          <Col md={4} xs={12}>
            <div className="group-general">
              <FormGroupTitle>
                <Translate value="generalSetting.MDISPATCHER_CANCELLATION" />
                <QuestionCircleTooltip
                  text={
                    <Translate value="generalSetting.MDISPATCHER_CANCELLATION_TOOLTIP" />
                  }
                />
              </FormGroupTitle>
              <div>
                <CcCheckbox
                  disabled={editable}
                  checked={
                    this.state.formData
                      ? this.state.formData.cancellationPolicy.onDemand
                      : false
                  }
                  onChange={this.handleBooknowCheckChange}
                  text={
                    <Fragment>
                      {I18n.t('generalSetting.Book_now')}
                      <QuestionCircleTooltip
                        text={I18n.t('generalSetting.Book_now_toolTip')}
                      />
                    </Fragment>
                  }
                  labelClassName={'fs-16'}
                  className={'jc-end'}
                />
              </div>
              {this.state.formData &&
              this.state.formData.cancellationPolicy.onDemand ? (
                <Row className="sub-controls">
                  <Col md={12}>
                    <FormGroup
                      className={`mb0 ${
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.cancellationPolicy_duration ===
                            false
                          ? 'error'
                          : null
                      }`}
                    >
                      <Form.Label>
                        <Translate value="generalSetting.Duration" />
                        <span className="require"> *</span>
                      </Form.Label>
                      <FormGroup className="qup-input-group">
                        <InputGroup
                          className={
                            'single-addon-right ' + (editable ? 'disabled' : '')
                          }
                        >
                          <FormControl
                            type="text"
                            className={'form-custom'}
                            onChange={this.handleBookNowDurationChange}
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(
                                e,
                                this.handleBookNowDurationChange
                              );
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(
                                e,
                                this.handleBookNowDurationChange
                              );
                            }}
                            value={
                              this.state.formData
                                ? this.state.formData.cancellationPolicy
                                    .duration
                                : ''
                            }
                            placeholder={I18n.t('generalSetting.Duration')}
                            disabled={editable}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>
                              {I18n.t('generalSetting.mins')}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </FormGroup>
                      <Validator
                        id="cancellationPolicy_duration"
                        callback={this.ValidatorCallback}
                      >
                        <ValidCase
                          valid={
                            !Validation.isStringEmpty(
                              this.state.formData.cancellationPolicy.duration
                            )
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                          hide={!this.state.isSubmited}
                        />
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={Validation.isInteger(
                            this.state.formData.cancellationPolicy.duration
                          )}
                          message={I18n.t(
                            'messages.commonMessages.must_be_integer'
                          )}
                        />
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={Validation.isGreaterOrEqual(
                            this.state.formData.cancellationPolicy.duration,
                            0
                          )}
                          message={I18n.t(
                            'messages.commonMessages.greater_or_equa'
                          ).format(0)}
                        />
                      </Validator>
                    </FormGroup>
                    <FormGroup
                      className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.cancellationPolicy_times === false
                          ? 'error'
                          : null
                      }
                    >
                      <Form.Label>
                        <Translate value="generalSetting.Times" />
                        <span className="require">*</span>
                      </Form.Label>
                      <FormControl
                        type="text"
                        className={'form-custom'}
                        onChange={this.handleBookNowTimeChange}
                        onBlur={(e) => {
                          textboxNumberHelper.onBlurHandle(
                            e,
                            this.handleBookNowTimeChange
                          );
                        }}
                        onFocus={(e) => {
                          textboxNumberHelper.onfocusHandle(
                            e,
                            this.handleBookNowTimeChange
                          );
                        }}
                        value={
                          this.state.formData
                            ? this.state.formData.cancellationPolicy.times
                            : ''
                        }
                        placeholder={I18n.t('generalSetting.Times')}
                        disabled={editable}
                      />
                      <Validator
                        id="cancellationPolicy_times"
                        callback={this.ValidatorCallback}
                      >
                        <ValidCase
                          valid={
                            !Validation.isStringEmpty(
                              this.state.formData.cancellationPolicy.times
                            )
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                          hide={!this.state.isSubmited}
                        />
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={Validation.isInteger(
                            this.state.formData.cancellationPolicy.times
                          )}
                          message={I18n.t(
                            'messages.commonMessages.must_be_integer'
                          )}
                        />
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={Validation.isGreaterOrEqual(
                            this.state.formData.cancellationPolicy.times,
                            0
                          )}
                          message={I18n.t(
                            'messages.commonMessages.greater_or_equa'
                          ).format(0)}
                        />
                      </Validator>
                    </FormGroup>
                  </Col>
                </Row>
              ) : null}

              <CcCheckbox
                disabled={editable}
                checked={
                  this.state.formData
                    ? this.state.formData.cancellationPolicy.inAdvance
                    : false
                }
                onChange={this.handleBooklaterCheckChange}
                text={
                  <Fragment>
                    {I18n.t('generalSetting.Book_later')}
                    <QuestionCircleTooltip
                      text={I18n.t('generalSetting.Book_later_toolTip')}
                    />
                  </Fragment>
                }
                labelClassName={'fs-16'}
                className={'jc-end'}
              />
              {this.state.formData &&
              this.state.formData.cancellationPolicy.inAdvance ? (
                <FormGroup
                  className={`sub-controls ${
                    !this.state.isSubmited
                      ? null
                      : this.state.valid.cancellationPolicy_durationIn === false
                      ? 'error'
                      : null
                  }`}
                >
                  <Form.Label>
                    <Translate value="generalSetting.Duration" />
                    <span className="require">*</span>
                  </Form.Label>
                  <FormGroup className="qup-input-group">
                    <InputGroup className="single-addon-right">
                      <FormControl
                        type="text"
                        className="form-custom"
                        onChange={this.handleBooklaterDurationChange}
                        onBlur={(e) => {
                          textboxNumberHelper.onBlurHandle(
                            e,
                            this.handleBooklaterDurationChange
                          );
                        }}
                        onFocus={(e) => {
                          textboxNumberHelper.onfocusHandle(
                            e,
                            this.handleBooklaterDurationChange
                          );
                        }}
                        value={
                          this.state.formData
                            ? this.state.formData.cancellationPolicy.durationIn
                            : ''
                        }
                        placeholder={I18n.t('generalSetting.Duration')}
                        disabled={editable}
                      />
                      <FormControl
                        as="select"
                        className="input-group-addon-custom form-custom"
                        value={
                          this.state.formData
                            ? this.state.formData.cancellationPolicy
                                .durationInUnit || 0
                            : 'hour'
                        }
                        onChange={(e) => {
                          this.handleTextIHrsMinTypeChange('book_later', e);
                        }}
                        disabled={editable}
                      >
                        <option value={'hour'}>{I18n.t('General.hrs')}</option>
                        <option value={'minute'}>
                          {I18n.t('General.mins')}
                        </option>
                      </FormControl>
                    </InputGroup>
                  </FormGroup>
                  <Validator
                    id="cancellationPolicy_durationIn"
                    callback={this.ValidatorCallback}
                  >
                    <ValidCase
                      valid={
                        !Validation.isStringEmpty(
                          this.state.formData.cancellationPolicy.durationIn
                        )
                      }
                      message={I18n.t('messages.commonMessages.Required_field')}
                      hide={!this.state.isSubmited}
                    />
                    {this.state.formData.cancellationPolicy.durationInUnit ==
                      'hour' && (
                      <ValidCase
                        hide={!this.state.isSubmited}
                        valid={Validation.isDecimal(
                          this.state.formData.cancellationPolicy.durationIn
                        )}
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                      />
                    )}
                    {this.state.formData.cancellationPolicy.durationInUnit ==
                      'minute' && (
                      <ValidCase
                        hide={!this.state.isSubmited}
                        valid={Validation.isInteger(
                          this.state.formData.cancellationPolicy.durationIn
                        )}
                        message={I18n.t(
                          'messages.commonMessages.must_be_integer'
                        )}
                      />
                    )}

                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isGreaterOrEqual(
                        this.state.formData.cancellationPolicy.durationIn,
                        0
                      )}
                      message={I18n.t(
                        'messages.commonMessages.greater_or_equa'
                      ).format(0)}
                    />

                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isGreaterOrEqual(
                        this.state.formData.cancellationPolicy.durationIn,
                        0
                      )}
                      message={I18n.t(
                        'messages.commonMessages.greater_or_equa'
                      ).format(0)}
                    />
                  </Validator>
                </FormGroup>
              ) : null}

              <CancelPolicyIntercityFormGroup
                ValidatorCallback={this.ValidatorCallback}
                editable={editable}
                formData={this.state.formData}
                isSubmited={this.state.isSubmited}
                valid={this.state.valid}
                handleUpdateFormData={this.handleUpdateFormData}
                selectedFleet={this.props.auth.selectedFleet}
              />
              <NoShowFormGroup
                ValidatorCallback={this.ValidatorCallback}
                editable={editable}
                formData={this.state.formData}
                isSubmited={this.state.isSubmited}
                valid={this.state.valid}
                handleUpdateFormData={this.handleUpdateFormData}
                selectedFleet={this.props.auth.selectedFleet}
              />
            </div>

            {(enablePenalty || !isAllCarHideCancelBtn) && (
              <div className="group-general">
                <FormGroupTitle>
                  <Translate value="generalSetting.Driver_cancellation" />
                </FormGroupTitle>
                {enablePenalty && (
                  <>
                    <CcCheckbox
                      disabled={editable}
                      checked={_.get(
                        formData,
                        'driverCancelPolicy.bookNow.isActive',
                        false
                      )}
                      onChange={(e) =>
                        this.handleDrvCancelCheckChange(e, 'bookNow')
                      }
                      text={
                        <Fragment>
                          {I18n.t('generalSetting.Book_now')}
                          <QuestionCircleTooltip
                            text={I18n.t('generalSetting.Drv_Book_now_toolTip')}
                          />
                        </Fragment>
                      }
                      labelClassName={'fs-16'}
                      className={'jc-end'}
                    />
                    {_.get(
                      formData,
                      'driverCancelPolicy.bookNow.isActive',
                      false
                    ) && (
                      <FormGroup
                        className={`mb0 ${
                          !this.state.isSubmited
                            ? null
                            : this.state.valid.cancelBookNow === false
                            ? 'error'
                            : null
                        }`}
                      >
                        <Form.Label>
                          <Translate value="generalSetting.Free_cancel" />
                          <span className="require"> *</span>
                        </Form.Label>
                        <FormGroup className="qup-input-group">
                          <InputGroup
                            className={
                              'single-addon-right ' +
                              (editable ? 'disabled' : '')
                            }
                          >
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) =>
                                this.handleDrvCancelValueChange(e, 'bookNow')
                              }
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) =>
                                  this.handleDrvCancelValueChange(e, 'bookNow')
                                );
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) =>
                                  this.handleDrvCancelValueChange(e, 'bookNow')
                                );
                              }}
                              value={_.get(
                                formData,
                                'driverCancelPolicy.bookNow.value',
                                0
                              )}
                              placeholder={I18n.t('generalSetting.Duration')}
                              disabled={editable}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>
                                {I18n.t('generalSetting.mins')}
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <Validator
                          id="cancelBookNow"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            hide={!this.state.isSubmited}
                            valid={Validation.isInteger(
                              _.get(
                                formData,
                                'driverCancelPolicy.bookNow.value',
                                0
                              )
                            )}
                            message={I18n.t(
                              'messages.commonMessages.must_be_integer'
                            )}
                          />
                        </Validator>
                      </FormGroup>
                    )}
                    <CcCheckbox
                      disabled={editable}
                      checked={_.get(
                        formData,
                        'driverCancelPolicy.bookReservation.isActive',
                        false
                      )}
                      onChange={(e) =>
                        this.handleDrvCancelCheckChange(e, 'bookReservation')
                      }
                      text={
                        <Fragment>
                          {I18n.t('generalSetting.Book_later')}
                          <QuestionCircleTooltip
                            text={I18n.t(
                              'generalSetting.Drv_Book_later_toolTip'
                            )}
                          />
                        </Fragment>
                      }
                      labelClassName={'fs-16'}
                      className={'jc-end'}
                    />
                    {_.get(
                      formData,
                      'driverCancelPolicy.bookReservation.isActive',
                      false
                    ) && (
                      <FormGroup
                        className={`mb0 ${
                          !this.state.isSubmited
                            ? null
                            : this.state.valid.bookReservation === false
                            ? 'error'
                            : null
                        }`}
                      >
                        <Form.Label>
                          <Translate value="generalSetting.Free_cancel" />
                          <span className="require"> *</span>
                        </Form.Label>
                        <FormGroup className="qup-input-group">
                          <InputGroup
                            className={
                              'single-addon-right ' +
                              (editable ? 'disabled' : '')
                            }
                          >
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) =>
                                this.handleDrvCancelValueChange(
                                  e,
                                  'bookReservation'
                                )
                              }
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) =>
                                  this.handleDrvCancelValueChange(
                                    e,
                                    'bookReservation'
                                  )
                                );
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) =>
                                  this.handleDrvCancelValueChange(
                                    e,
                                    'bookReservation'
                                  )
                                );
                              }}
                              value={_.get(
                                formData,
                                'driverCancelPolicy.bookReservation.value',
                                0
                              )}
                              placeholder={I18n.t('generalSetting.Duration')}
                              disabled={editable}
                            />
                            <FormControl
                              as="select"
                              className="input-group-addon-custom form-custom"
                              value={_.get(
                                formData,
                                'driverCancelPolicy.bookReservation.unit',
                                'hour'
                              )}
                              onChange={(e) =>
                                this.handleDrvCancelUnitChange(
                                  e,
                                  'bookReservation'
                                )
                              }
                              disabled={editable}
                            >
                              <option value={'hour'}>
                                {I18n.t('General.hrs')}
                              </option>
                              <option value={'minute'}>
                                {I18n.t('General.mins')}
                              </option>
                            </FormControl>
                          </InputGroup>
                        </FormGroup>
                        <Validator
                          id="bookReservation"
                          callback={this.ValidatorCallback}
                        >
                          {_.get(
                            formData,
                            'driverCancelPolicy.bookReservation.unit',
                            'hour'
                          ) == 'hour' && (
                            <ValidCase
                              valid={Validation.isDecimal(
                                _.get(
                                  formData,
                                  'driverCancelPolicy.bookReservation.value',
                                  0
                                )
                              )}
                              message={I18n.t(
                                'messages.commonMessages.must_be_number'
                              )}
                              hide={!this.state.isSubmited}
                            />
                          )}
                          {_.get(
                            formData,
                            'driverCancelPolicy.bookReservation.unit',
                            'hour'
                          ) == 'minute' && (
                            <ValidCase
                              hide={!this.state.isSubmited}
                              valid={Validation.isInteger(
                                _.get(
                                  formData,
                                  'driverCancelPolicy.bookReservation.value',
                                  0
                                )
                              )}
                              message={I18n.t(
                                'messages.commonMessages.must_be_integer'
                              )}
                            />
                          )}
                        </Validator>
                      </FormGroup>
                    )}
                    <FormGroup
                      className={`mb0 ${
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.freeCancelTimes === false
                          ? 'error'
                          : null
                      }`}
                    >
                      <Form.Label>
                        <Translate value="generalSetting.freeCancelTime" />
                        <span className="require"> *</span>
                      </Form.Label>
                      <FormGroup className="qup-input-group">
                        <InputGroup
                          className={
                            'single-addon-right ' + (editable ? 'disabled' : '')
                          }
                        >
                          <FormControl
                            type="text"
                            className={'form-custom'}
                            onChange={(e) =>
                              this.handleDrvCancelFreeTimeChange(e)
                            }
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(e, (e) =>
                                this.handleDrvCancelValueChange(e)
                              );
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(e, (e) =>
                                this.handleDrvCancelValueChange(e)
                              );
                            }}
                            value={_.get(
                              formData,
                              'driverCancelPolicy.freeCancelTimes',
                              0
                            )}
                            placeholder={I18n.t('generalSetting.Times')}
                            disabled={editable}
                          />
                        </InputGroup>
                      </FormGroup>
                      <Validator
                        id="freeCancelTimes"
                        callback={this.ValidatorCallback}
                      >
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={Validation.isInteger(
                            _.get(
                              formData,
                              'driverCancelPolicy.freeCancelTimes',
                              0
                            )
                          )}
                          message={I18n.t(
                            'messages.commonMessages.must_be_integer'
                          )}
                        />
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={Validation.isGreaterOrEqual(
                            _.get(
                              formData,
                              'driverCancelPolicy.freeCancelTimes',
                              0
                            ),
                            0
                          )}
                          message={I18n.t(
                            'messages.commonMessages.greater_or_equa'
                          ).format(0)}
                        />
                      </Validator>
                    </FormGroup>
                  </>
                )}
                {!isAllCarHideCancelBtn && (
                  <>
                    <CcCheckbox
                      disabled={editable}
                      checked={_.get(
                        formData,
                        'driverCancelPolicy.cancelBtnTime.isHide',
                        false
                      )}
                      onChange={(e) =>
                        this.handleDrvCancelCheckChange(e, 'cancelBtnTime')
                      }
                      text={I18n.t('generalSetting.Hide_cancel_btn')}
                      labelClassName={'fs-16'}
                      className={'jc-end'}
                      hasTooltip
                      tooltipText={
                        'Apply for any car type which allows driver to cancel booking'
                      }
                    />
                    {_.get(
                      formData,
                      'driverCancelPolicy.cancelBtnTime.isHide',
                      false
                    ) && (
                      <FormGroup
                        className={`mb0 ${
                          !this.state.isSubmited
                            ? null
                            : this.state.valid.cancelBtnTime === false
                            ? 'error'
                            : null
                        }`}
                      >
                        <Form.Label>
                          <Translate value="generalSetting.Time_day" />
                          <span className="require"> *</span>
                        </Form.Label>
                        <FormGroup className="qup-input-group">
                          <InputGroup
                            className={
                              'single-addon-right ' +
                              (editable ? 'disabled' : '')
                            }
                          >
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) =>
                                this.handleDrvCancelValueChange(
                                  e,
                                  'cancelBtnTime'
                                )
                              }
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) =>
                                  this.handleDrvCancelValueChange(
                                    e,
                                    'cancelBtnTime'
                                  )
                                );
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) =>
                                  this.handleDrvCancelValueChange(
                                    e,
                                    'cancelBtnTime'
                                  )
                                );
                              }}
                              value={_.get(
                                formData,
                                'driverCancelPolicy.cancelBtnTime.value',
                                0
                              )}
                              placeholder={I18n.t('generalSetting.Times')}
                              disabled={editable}
                            />
                          </InputGroup>
                        </FormGroup>
                        <Validator
                          id="cancelBtnTime"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            hide={!this.state.isSubmited}
                            valid={Validation.isInteger(
                              _.get(
                                formData,
                                'driverCancelPolicy.cancelBtnTime.value',
                                0
                              )
                            )}
                            message={I18n.t(
                              'messages.commonMessages.must_be_integer'
                            )}
                          />
                          <ValidCase
                            hide={!this.state.isSubmited}
                            valid={Validation.isGreaterOrEqual(
                              _.get(
                                formData,
                                'driverCancelPolicy.cancelBtnTime.value',
                                0
                              ),
                              1
                            )}
                            message={I18n.t(
                              'messages.commonMessages.greater_or_equa'
                            ).format(1)}
                          />
                        </Validator>
                      </FormGroup>
                    )}
                  </>
                )}
              </div>
            )}

            <div className="group-general">
              <FormGroupTitle>
                <Translate value="generalSetting.SHOW_MAP_STATUS" />
              </FormGroupTitle>
              <Row className="sub-controls">
                <Col md={4}>
                  <FormGroup>
                    <CcCheckbox
                      checked={this.checkMapStatus('All')}
                      onChange={() => {
                        this.handleMapStatusSettingChanged('All');
                      }}
                      text={I18n.t('generalSetting.All')}
                      disabled={!hasPermission}
                    />
                  </FormGroup>
                </Col>
                {mapStatus.map((status) => {
                  return (
                    <Col md={4} key={status.code}>
                      <FormGroup>
                        <CcCheckbox
                          checked={this.checkMapStatus(status.code)}
                          onChange={() => {
                            this.handleMapStatusSettingChanged(status.code);
                          }}
                          text={I18n.t(status.text)}
                          disabled={!hasPermission}
                        />
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
              {isHideEmergencyContacts ? null : (
                <Fragment>
                  <FormGroupTitle>
                    <Translate
                      className="text-tranform-none"
                      value="generalSetting.EMERGENCY_SOS"
                    />
                  </FormGroupTitle>
                  <Row className="sub-controls">
                    {this.state.fleet.SOSNumber &&
                      this.state.fleet.SOSNumber.zoneNumber.map((zone) => {
                        const validState = status
                          ? true
                          : validatedNumber[zone.zoneId] ||
                            validatedNumber[zone.zoneId] === undefined ||
                            validatedNumber[zone.zoneId] === null
                          ? true
                          : false;
                        return (
                          <Col xs={12} md={6} lg={4} key={zone.zoneId}>
                            <FormGroup className="no-marginBottom">
                              <Form.Label
                                className="text-trim-ellipsis"
                                title={zone.zoneName}
                                alt={zone.zoneName}
                              >
                                {zone.zoneName}
                              </Form.Label>

                              <FormGroup
                                className={
                                  !this.state.isSubmited
                                    ? null
                                    : validState
                                    ? null
                                    : 'error'
                                }
                              >
                                <FormControl
                                  type="text"
                                  className="form-custom no-marginBottom"
                                  onChange={(e) => {
                                    this.handleValidateSOSNumber(
                                      zone.zoneId,
                                      e.target.value
                                    );
                                  }}
                                  value={zone ? zone.phone : ''}
                                  disabled={
                                    isHideEmergencyContacts || !hasPermission
                                  }
                                />
                              </FormGroup>
                            </FormGroup>
                          </Col>
                        );
                      })}
                    <Col xs={12} md={6} lg={4}>
                      <FormGroup>
                        <Form.Label
                          className="text-trim-ellipsis"
                          title={I18n.t('generalSetting.Backup_phone_number')}
                          alt={I18n.t('generalSetting.Backup_phone_number')}
                        >
                          <Translate value="generalSetting.Backup_phone_number" />
                          <QuestionCircleTooltip
                            text={
                              <Translate value="generalSetting.Backup_phone_number_hint" />
                            }
                          />
                        </Form.Label>

                        <FormGroup
                          className={
                            !this.state.isSubmited
                              ? null
                              : status
                              ? null
                              : validatedNumber['defaultNumber'] ||
                                validatedNumber['defaultNumber'] ===
                                  undefined ||
                                validatedNumber['defaultNumber'] === null
                              ? null
                              : 'error'
                          }
                        >
                          <FormControl
                            type="text"
                            className="form-custom no-marginBottom"
                            onChange={(e) => {
                              this.handleValidateSOSNumber(
                                'defaultNumber',
                                e.target.value
                              );
                            }}
                            value={
                              this.state.fleet.SOSNumber
                                ? this.state.fleet.SOSNumber.defaultNumber
                                : ''
                            }
                            disabled={isHideEmergencyContacts || !hasPermission}
                          />
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup
                        className={
                          !this.state.isSubmited
                            ? null
                            : status
                            ? null
                            : 'error'
                        }
                      >
                        <Validator id="phone" callback={this.ValidatorCallback}>
                          <ValidCase
                            hide={!this.state.isSubmited}
                            valid={status}
                            message={I18n.t(
                              'mDispatcher.ERROR_INPUT_VALIDPHONE'
                            )}
                          />
                        </Validator>
                      </FormGroup>
                    </Col>
                  </Row>
                </Fragment>
              )}
              <HeatMapFormGroup
                auth={this.props.auth}
                formData={this.state.formData}
                fleet={this.state.fleet}
                handleUpdateFleet={this.handleUpdateFleet}
                handleCancelInputMultiCurrenciesChange={
                  this.handleCancelInputMultiCurrenciesChange
                }
                isSubmited={this.state.isSubmited}
                valid={this.state.valid}
                ValidatorCallback={this.ValidatorCallback}
                editable={editable}
              />
              <FormGroupTitle className={'title'}>
                <Translate value="generalSetting.RESERVATION_REMINDER" />
              </FormGroupTitle>
              <FormGroup
                className={
                  !this.state.isSubmited
                    ? null
                    : this.state.valid.driverAppAlert === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="generalSetting.Driver_app_SMS_alert_before" />
                  <span className="require">*</span>
                </Form.Label>
                <FormGroup className={'qup-input-group'}>
                  <InputGroup
                    className={
                      'single-addon-right ' + (editable ? 'disabled' : '')
                    }
                  >
                    <FormControl
                      type="text"
                      className={'form-custom'}
                      onChange={this.handleDriveAppSMSBeforeChange}
                      onBlur={(e) => {
                        textboxNumberHelper.onBlurHandle(
                          e,
                          this.handleDriveAppSMSBeforeChange
                        );
                      }}
                      onFocus={(e) => {
                        textboxNumberHelper.onfocusHandle(
                          e,
                          this.handleDriveAppSMSBeforeChange
                        );
                      }}
                      value={
                        this.state.fleet ? this.state.fleet.driverAppAlert : ''
                      }
                      disabled={editable || !hasPermission}
                    />
                    <FormControl
                      as="select"
                      className="input-group-addon-custom form-custom"
                      value={
                        this.state.fleet
                          ? this.state.fleet.driverAppAlertUnit || 0
                          : 'hour'
                      }
                      onChange={(e) => {
                        this.handleTextIHrsMinTypeChange('driverAppAlert', e);
                      }}
                      disabled={editable || !hasPermission}
                    >
                      <option value={'hour'}>{I18n.t('General.hrs')}</option>
                      <option value={'minute'}>{I18n.t('General.mins')}</option>
                    </FormControl>
                  </InputGroup>
                </FormGroup>
                <Validator
                  id="driverAppAlert"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    valid={
                      !Validation.isStringEmpty(this.state.fleet.driverAppAlert)
                    }
                    message={I18n.t('messages.commonMessages.Required_field')}
                    hide={!this.state.isSubmited}
                  />
                  {this.state.fleet.driverAppAlertUnit == 'hour' && (
                    <ValidCase
                      valid={Validation.isDecimal(
                        this.state.fleet.driverAppAlert
                      )}
                      message={I18n.t('messages.commonMessages.must_be_number')}
                      hide={!this.state.isSubmited}
                    />
                  )}
                  {this.state.fleet.driverAppAlertUnit == 'minute' && (
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isInteger(
                        this.state.fleet.driverAppAlert
                      )}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      )}
                    />
                  )}

                  <ValidCase
                    valid={Validation.isGreaterOrEqual(
                      this.state.fleet.driverAppAlert,
                      0
                    )}
                    message={I18n.t(
                      'messages.commonMessages.greater_or_equa'
                    ).format(0)}
                    hide={!this.state.isSubmited}
                  />
                </Validator>
              </FormGroup>
              <FormGroup
                className={`no-marginBottom ${
                  !this.state.isSubmited
                    ? null
                    : this.state.valid.paxAppAlert === false
                    ? 'error'
                    : null
                }`}
              >
                <Form.Label>
                  <Translate value="generalSetting.Pax_app_alert_before" />
                  <span className="require">*</span>
                </Form.Label>
                <FormGroup className={'qup-input-group no-marginBottom'}>
                  <InputGroup
                    className={
                      'single-addon-right ' + (editable ? 'disabled' : '')
                    }
                  >
                    <FormControl
                      type="text"
                      className={'form-custom'}
                      onChange={this.handlePaxAppAlertBeforeChange}
                      onBlur={(e) => {
                        textboxNumberHelper.onBlurHandle(
                          e,
                          this.handlePaxAppAlertBeforeChange
                        );
                      }}
                      onFocus={(e) => {
                        textboxNumberHelper.onfocusHandle(
                          e,
                          this.handlePaxAppAlertBeforeChange
                        );
                      }}
                      value={
                        this.state.fleet ? this.state.fleet.paxAppAlert : ''
                      }
                      placeholder={I18n.t(
                        'generalSetting.Pax_app_alert_before'
                      )}
                      disabled={editable}
                    />
                    <FormControl
                      as="select"
                      className="input-group-addon-custom form-custom"
                      value={
                        this.state.fleet
                          ? this.state.fleet.paxAppAlertUnit || 0
                          : 'hour'
                      }
                      onChange={(e) => {
                        this.handleTextIHrsMinTypeChange('paxAppAlert', e);
                      }}
                      disabled={editable}
                    >
                      <option value={'hour'}>{I18n.t('General.hrs')}</option>
                      <option value={'minute'}>{I18n.t('General.mins')}</option>
                    </FormControl>
                  </InputGroup>
                </FormGroup>
                <Validator id="paxAppAlert" callback={this.ValidatorCallback}>
                  <ValidCase
                    valid={
                      !Validation.isStringEmpty(this.state.fleet.paxAppAlert)
                    }
                    message={I18n.t('messages.commonMessages.Required_field')}
                    hide={!this.state.isSubmited}
                  />
                  {this.state.fleet.paxAppAlertUnit == 'hour' && (
                    <ValidCase
                      valid={Validation.isDecimal(this.state.fleet.paxAppAlert)}
                      message={I18n.t('messages.commonMessages.must_be_number')}
                      hide={!this.state.isSubmited}
                    />
                  )}
                  {this.state.fleet.paxAppAlertUnit == 'minute' && (
                    <ValidCase
                      valid={Validation.isInteger(this.state.fleet.paxAppAlert)}
                      message={I18n.t('messages.commonMessages.must_be_number')}
                      hide={!this.state.isSubmited}
                    />
                  )}
                  <ValidCase
                    valid={Validation.isGreaterOrEqual(
                      this.state.fleet.paxAppAlert,
                      0
                    )}
                    message={I18n.t(
                      'messages.commonMessages.greater_or_equa'
                    ).format(0)}
                    hide={!this.state.isSubmited}
                  />
                </Validator>
              </FormGroup>
            </div>
            <IntercityConfirmEmailFormGroup
              selectedFleet={this.props.auth.selectedFleet}
              fleet={this.state.fleet}
              handleUpdateFleet={this.handleUpdateFleet}
              isSubmited={this.state.isSubmited}
              valid={this.state.valid}
              ValidatorCallback={this.ValidatorCallback}
              editable={editable}
              hasPermission={hasPermission}
            />
          </Col>
          <Col md={4} xs={12}>
            {enableReservationPolicy && (
              <ReservationPolicyGroup
                auth={this.props.auth}
                formData={this.state.formData}
                fleet={this.state.fleet}
                handleUpdateFormData={this.handleUpdateFleet}
                isSubmited={this.state.isSubmited}
                valid={this.state.valid}
                ValidatorCallback={this.ValidatorCallback}
                editable={editable}
              />
            )}
            <AirportFeeFormGroup
              auth={this.props.auth}
              formData={this.state.formData}
              handleUpdateFormData={this.handleUpdateFormData}
              handleCancelInputMultiCurrenciesChange={
                this.handleCancelInputMultiCurrenciesChange
              }
              isSubmited={this.state.isSubmited}
              valid={this.state.valid}
              ValidatorCallback={this.ValidatorCallback}
              editable={editable}
            />
            {!enableFleetService && (
              <div className="group-general">
                <CcCheckbox
                  disabled={editable}
                  checked={
                    this.state.formData &&
                    this.state.formData.additionalServicesActive
                      ? this.state.formData.additionalServicesActive
                      : false
                  }
                  onChange={this.onAdditionalServiceChange}
                  text={I18n.t('generalSetting.Additional_services')}
                  labelClassName={'title'}
                  className="form-group-title mr-t-10 no-marginBottom"
                />
                {this.state.formData &&
                this.state.formData.additionalServicesActive ? (
                  <Row className="sub-controls">
                    <Col md={12}>
                      <div className="additional-service-table-container">
                        <table className="table table-no-pagination">
                          <tbody>
                            {this.state.formData.services &&
                            this.state.formData.services.length > 0
                              ? this.state.formData.services.map(
                                  (service, index) => {
                                    return (
                                      <tr key={service.serviceName}>
                                        <td>
                                          <OverlayTrigger
                                            placement="top"
                                            delayShow={300}
                                            overlay={
                                              <Tooltip id="sevice-name-tooltip">
                                                <span>
                                                  {service.serviceType ==
                                                  additionalServiceType.Compulsory
                                                    ? service.serviceName + ' *'
                                                    : service.serviceName}
                                                </span>
                                              </Tooltip>
                                            }
                                          >
                                            <div className="text-ellipsis">
                                              {service.serviceType ==
                                              additionalServiceType.Compulsory
                                                ? service.serviceName + ' *'
                                                : service.serviceName}
                                            </div>
                                          </OverlayTrigger>
                                        </td>
                                        <td>{service.serviceType}</td>
                                        <td>
                                          {service &&
                                          service.serviceFeeByCurrencies
                                            ? service.serviceFeeByCurrencies
                                                .map((d) => {
                                                  return (
                                                    d.currencyISO +
                                                    ' ' +
                                                    d.value
                                                  );
                                                })
                                                .join(', ')
                                            : ''}
                                        </td>
                                        <td>
                                          {service && service.vehicleType
                                            ? service.vehicleType
                                                .map((d) => {
                                                  return d.vehicleType;
                                                })
                                                .join(', ')
                                            : ''}
                                        </td>

                                        <td className="actions">
                                          {!this.props.permissions ||
                                          this.props.permissions.actions ? (
                                            <div>
                                              <a
                                                onClick={() => {
                                                  this.additionalServiceRemoveItemClick(
                                                    service,
                                                    index
                                                  );
                                                }}
                                              >
                                                <i className="fa fa-trash fa-2x" />
                                              </a>
                                              <a
                                                onClick={() => {
                                                  this.additionalServiceEditItemClick(
                                                    service,
                                                    index
                                                  );
                                                }}
                                              >
                                                <i className="fa fa-edit fa-2x" />
                                              </a>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              : null}
                          </tbody>
                        </table>
                      </div>
                      {(!this.props.permissions ||
                        this.props.permissions.actions) &&
                      this.state.formData.services &&
                      this.state.formData.services.length < 20 ? (
                        <ButtonToolbar className="text-center mv-md">
                          <Button
                            className={'btn-save'}
                            onClick={this.additionalServicesAddItemClick}
                          >
                            <Translate value="generalSetting.Add" />
                          </Button>
                        </ButtonToolbar>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
              </div>
            )}

            <TipFromGroup
              formData={this.state.formData}
              formDataInitial={this.state.formDataInitial}
              valid={this.state.valid}
              ValidatorCallback={this.ValidatorCallback}
              handleUpdateFormData={this.handleUpdateFormData}
              editable={editable}
              hasPermission={hasPermission}
              isSubmited={this.state.isSubmited}
              selectedFleet={this.props.auth.selectedFleet}
            />

            {enableFaceMaskAuth && (
              <FaceMaskFormGroup
                auth={this.props.auth}
                fleet={this.state.fleet}
                handleUpdateFormData={this.handleUpdateFleet}
                isSubmited={this.state.isSubmited}
                valid={this.state.valid}
                ValidatorCallback={this.ValidatorCallback}
                editable={editable}
              />
            )}

            <BookingValidationGroup
              formData={this.state.formData}
              selectedFleet={this.props.auth.selectedFleet}
              handleUpdateFormData={this.handleUpdateFormData}
              valid={this.state.valid}
              ValidatorCallback={this.ValidatorCallback}
              editable={editable}
              isSubmited={this.state.isSubmited}
            />
            <TransactionFee
              formData={this.state.formData}
              fleet={this.state.fleet}
              selectedFleet={this.props.auth.selectedFleet}
              handleUpdateFleet={this.handleUpdateFleet}
              valid={this.state.valid}
              ValidatorCallback={this.ValidatorCallback}
              editable={editable}
              isSubmited={this.state.isSubmited}
            />
            <div className="group-general">
              <FormGroupTitle>
                <Translate value="generalSetting.showFare.title" />
              </FormGroupTitle>
              <FormGroup>
                <FormControl
                  as="select"
                  className="form-custom no-marginBottom"
                  onChange={(e) => {
                    this.handleUpdateFleet('showFare', e.target.value);
                  }}
                  value={this.state.fleet.showFare}
                  disabled={editable}
                >
                  {showFare.map((mode) => (
                    <option value={mode.value} key={mode.value}>
                      {I18n.t(mode.label)}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
            </div>
            <div className="group-general">
              <FormGroupTitle>
                <Translate value="generalSetting.logo" />
              </FormGroupTitle>
              <FormGroup>
                <Form.Label>
                  <Translate value={`generalSetting.customizeLogo`} />
                </Form.Label>
                <UploadFile
                  id={'logoCustomize'}
                  name={'logoCustomize'}
                  disabled={editable}
                  accepts="image/*"
                  onlyImage
                  onChange={(data, error) => {
                    this.handleImageChange(data, error);
                  }}
                  existFile={
                    fileName
                      ? {
                          fileName,
                          onRemove: () => this.handleRemoveLogoCustomize(),
                        }
                      : null
                  }
                />
              </FormGroup>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <FormGroupTitle className="formGroupTitle-otherSetting">
              <Translate
                value="generalSetting.Other_settings"
                className="formGroupTitle-otherSetting"
              />
            </FormGroupTitle>
            <FormGroup>
              <CcCheckbox
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting.soundNotify
                    : false
                }
                onChange={this.handleSoundNotificationCheckChange}
                text={I18n.t('generalSetting.Sound_notification')}
              />
            </FormGroup>
            {this.props.auth.selectedFleet.moduleSettings &&
            this.props.auth.selectedFleet.moduleSettings.corporate ? (
              <FormGroup>
                <CcCheckbox
                  disabled={editable}
                  checked={
                    this.state.fleet
                      ? this.state.fleet.generalSetting.advanceInfoCorp
                      : false
                  }
                  onChange={this.handleAdvanceInfoCorpCheckChange}
                  text={I18n.t('generalSetting.Advance_information')}
                />
              </FormGroup>
            ) : null}
            {this.props.auth.selectedFleet.moduleSettings &&
            this.props.auth.selectedFleet.moduleSettings.corporate ? (
              <FormGroup>
                <CcCheckbox
                  disabled={editable}
                  checked={
                    this.state.fleet
                      ? this.state.fleet.generalSetting.regAsCorpTraveler
                      : false
                  }
                  onChange={this.handlePasengerAsTravelerCheckChange}
                  text={I18n.t(
                    'generalSetting.Allow_passenger_to_register_as_corp_traveler'
                  )}
                />
              </FormGroup>
            ) : null}
            <FormGroup>
              <CcCheckbox
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting
                        .notAllowMakeBookingWithoutAssignFare
                    : false
                }
                onChange={
                  this.handlenotAllowMakeBookingWithoutAssignFareCheckChange
                }
                text={I18n.t(
                  'generalSetting.Not_Allow_Make_Booking_Without_Assign_Fare'
                )}
              />
            </FormGroup>
            <FormGroup>
              <CcCheckbox
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting
                        .notShowCarTypeCCWithoutRate
                    : false
                }
                onChange={this.handlenotAllowShowCarTypeCCWithoutRateChange}
                text={I18n.t('generalSetting.Not_Allow_show_car_type_on_cc')}
              />
            </FormGroup>
            <FormGroup>
              <CcCheckbox
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting.disableInvoicingReceipt
                    : false
                }
                onChange={
                  this.handleDisableDirectInvoicingReceiptEmailCheckChange
                }
                text={I18n.t(
                  'generalSetting.Disable_Direct_Invoicing_Receipt_Email'
                )}
              />
            </FormGroup>
            {this.props.auth.selectedFleet.moduleSettings.corporate && (
              <FormGroup>
                <CcCheckbox
                  disabled={editable}
                  checked={
                    this.state.fleet
                      ? this.state.fleet.generalSetting.hideProviderFromCorp
                      : false
                  }
                  onChange={this.handleHideProviderFromCorpCheckChange}
                  text={I18n.t(
                    'generalSetting.hide_Provider_Filter_From_Corporate_Board'
                  )}
                />
              </FormGroup>
            )}
            <FormGroup>
              <CcCheckbox
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting.optionalEmailPaxApp
                    : false
                }
                onChange={this.handleOptionalEmailPaxAppCheckChange}
                text={I18n.t('generalSetting.customer_email_is_optional')}
              />
            </FormGroup>
            <FormGroup>
              <CcCheckbox
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting.disableHourlyRate
                    : false
                }
                onChange={this.handleDisableHourlyRateCheckChange}
                text={I18n.t('generalSetting.disable_hourly_rate')}
              />
            </FormGroup>
            <FormGroup>
              <CcCheckbox
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting.genderRequireInPaxApp
                    : false
                }
                onChange={this.handleGenderRequired}
                text={I18n.t('generalSetting.customer_gender_is_compulsory')}
              />
            </FormGroup>
            {/* SL-6166 */}
            {/* <FormGroup>
              <CcCheckbox
                id="compulsoryAddressInPaxApp"
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting.compulsoryAddressInPaxApp
                    : false
                }
                onChange={this.handleGeneralSettingChange}
                text={I18n.t("generalSetting.customer_address_is_compulsory")}
              />
            </FormGroup> */}
            <FormGroup>
              <CcCheckbox
                id="compulsoryIdNationalIcInPaxApp"
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting
                        .compulsoryIdNationalIcInPaxApp
                    : false
                }
                onChange={this.handleGeneralSettingChange}
                text={I18n.t('generalSetting.customer_id_is_compulsory')}
              />
            </FormGroup>
            <FormGroup>
              <CcCheckbox
                id="allowPassengerToUploadDocument"
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting
                        .allowPassengerToUploadDocument
                    : false
                }
                onChange={this.handleGeneralSettingChange}
                text={I18n.t('generalSetting.allowPassengerToUploadDocument')}
              />
            </FormGroup>
            {this.state.fleet.generalSetting.allowPassengerToUploadDocument ? (
              <FormGroup>
                <CcCheckbox
                  id="compulsoryNationalIcDocumentInPaxApp"
                  disabled={editable}
                  checked={
                    this.state.fleet
                      ? this.state.fleet.generalSetting
                          .compulsoryNationalIcDocumentInPaxApp
                      : false
                  }
                  onChange={this.handleGeneralSettingChange}
                  text={I18n.t(
                    'generalSetting.compulsoryNationalIcDocumentInPaxApp'
                  )}
                />
              </FormGroup>
            ) : null}
            <FormGroup>
              <CcCheckbox
                id="doNotAllowAddDriverWhenNationalIdDuplicated"
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting
                        .doNotAllowAddDriverWhenNationalIdDuplicated
                    : false
                }
                onChange={this.handleGeneralSettingChange}
                text={I18n.t(
                  'generalSetting.doNotAllowAddDriverWhenNationalIdDuplicated'
                )}
              />
            </FormGroup>
            <FormGroup>
              <CcCheckbox
                id="showTotalFareWithoutPromotionOnDriverApp"
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting
                        .showTotalFareWithoutPromotionOnDriverApp
                    : false
                }
                onChange={this.handleGeneralSettingChange}
                text={
                  <Fragment>
                    {I18n.t(
                      'generalSetting.showTotalFareWithoutPromotionOnDriverApp'
                    )}
                    <QuestionCircleTooltip
                      text={
                        <Translate
                          value="generalSetting.showTotalFareWithoutPromotionOnDriverApp_hint"
                          dangerousHTML
                        />
                      }
                    />
                  </Fragment>
                }
              />
            </FormGroup>
            <FormGroup>
              <CcCheckbox
                id="hideFareOnPaxAppForAllCorporateBookings"
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting
                        .hideFareOnPaxAppForAllCorporateBookings
                    : false
                }
                onChange={this.handleGeneralSettingChange}
                text={I18n.t(
                  'generalSetting.hideFareOnPaxAppForAllCorporateBookings'
                )}
              />
            </FormGroup>
            <FormGroup>
              <CcCheckbox
                id="hidePaxNameAvatarOnDispatchScreen"
                disabled={editable}
                checked={
                  this.state.fleet &&
                  this.state.fleet.generalSetting &&
                  this.state.fleet.generalSetting
                    .hidePaxNameAvatarOnDispatchScreen != null
                    ? this.state.fleet.generalSetting
                        .hidePaxNameAvatarOnDispatchScreen
                    : true
                }
                onChange={this.handleGeneralSettingChange}
                text={I18n.t(
                  'generalSetting.hidePaxNameAvatarOnDispatchScreen'
                )}
              />
            </FormGroup>
            <FormGroup>
              <CcCheckbox
                id="showFullCustomerNameOnDriverApp"
                disabled={editable}
                checked={_.get(
                  this.state.fleet,
                  'generalSetting.showFullCustomerNameOnDriverApp',
                  false
                )}
                onChange={this.handleGeneralSettingChange}
                text={I18n.t('generalSetting.showFullCustomerNameOnDriverApp')}
              />
            </FormGroup>
            {enableReferral ? (
              <FormGroup>
                <CcCheckbox
                  id="firstBooking"
                  disabled={editable}
                  checked={
                    this.state.fleet
                      ? this.state.fleet.generalSetting.founderFund.firstBooking
                      : false
                  }
                  onChange={this.handleGeneralSettingFounderFundChange}
                  text={I18n.t('generalSetting.founderFund_firstBooking')}
                />
              </FormGroup>
            ) : null}

            <FormGroup>
              <CcCheckbox
                id="forceCustomerToUseLiveLocation"
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting
                        .forceCustomerToUseLiveLocation
                    : false
                }
                onChange={this.handleGeneralSettingChange}
                text={I18n.t('generalSetting.forceCustomerToUseLiveLocation')}
              />
            </FormGroup>

            <FormGroup>
              <CcCheckbox
                id="disableMarketPlace"
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting.disableMarketPlace
                    : false
                }
                onChange={this.handleGeneralSettingChange}
                text={I18n.t('generalSetting.disableMarketPlace')}
              />
            </FormGroup>

            <FormGroup>
              <CcCheckbox
                id="dontShowSecondStageImmediately"
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting
                        .dontShowSecondStageImmediately
                    : false
                }
                onChange={this.handleGeneralSettingChange}
                text={I18n.t('generalSetting.dontShowSecondStageImmediately')}
              />
            </FormGroup>
            {this.state.fleet.generalSetting
              .showInInsuranceFieldsAndVhcLicenseExp && (
              <FormGroup>
                <CcCheckbox
                  id="compulsoryCarInsurance"
                  disabled={editable}
                  checked={
                    this.state.fleet
                      ? this.state.fleet.generalSetting.compulsoryCarInsurance
                      : false
                  }
                  onChange={this.handleGeneralSettingChange}
                  text={I18n.t('generalSetting.compulsoryCarInsurance')}
                />
              </FormGroup>
            )}
            <FormGroup>
              <CcCheckbox
                id="cancelTimeout"
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting.cancelTimeout
                    : false
                }
                onChange={this.handleGeneralSettingChange}
                text={I18n.t('generalSetting.cancelTimeout')}
              />
            </FormGroup>

            <FormGroup className="vehicle_age_limit">
              <CcCheckbox
                id="vehicleAgeLimit"
                disabled={editable}
                checked={
                  this.state.fleet
                    ? this.state.fleet.generalSetting.vehicleAgeLimit.enable
                    : false
                }
                onChange={this.handleChangeVehicleAgeLimit}
                text={I18n.t('generalSetting.vehicleAgeLimit')}
                className="mb0"
              />
              {this.state.fleet &&
              this.state.fleet.generalSetting.vehicleAgeLimit.enable ? (
                <FormGroup
                  className={`mb0 ${
                    !this.state.isSubmited
                      ? null
                      : this.state.valid.vehicleAgeLimit === false
                      ? 'error'
                      : null
                  }`}
                >
                  <FormGroup className="qup-input-group mb0">
                    <InputGroup className="single-addon-right vehicle_age_limit">
                      <FormControl
                        type="text"
                        className="form-custom no-marginBottom"
                        onChange={this.handleChangeVehicleAgeLimitYears}
                        onBlur={(e) => {
                          textboxNumberHelper.onBlurHandle(
                            e,
                            this.handleChangeVehicleAgeLimitYears
                          );
                        }}
                        onFocus={(e) => {
                          textboxNumberHelper.onfocusHandle(
                            e,
                            this.handleChangeVehicleAgeLimitYears
                          );
                        }}
                        maxLength={3}
                        value={
                          this.state.fleet.generalSetting.vehicleAgeLimit.years
                        }
                        disabled={editable}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>
                          {I18n.t('generalSetting.vehicleAgeLimit_years')}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </FormGroup>
                  <Validator
                    id="vehicleAgeLimit"
                    callback={this.ValidatorCallback}
                  >
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isGreaterThan(
                        this.state.fleet.generalSetting.vehicleAgeLimit.years,
                        0
                      )}
                      message={I18n.t(
                        'messages.commonMessages.greater_than'
                      ).format(0)}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isLessOrEqual(
                        this.state.fleet.generalSetting.vehicleAgeLimit.years,
                        100
                      )}
                      message={I18n.t(
                        'messages.commonMessages.less_or_equal'
                      ).format(100)}
                    />
                  </Validator>
                </FormGroup>
              ) : null}
            </FormGroup>
            {isStripeGateway && (
              <FormGroup>
                <CcCheckbox
                  id="allowStripePaymentLink"
                  disabled={editable}
                  checked={
                    this.state.fleet
                      ? this.state.fleet.generalSetting.allowStripePaymentLink
                      : false
                  }
                  onChange={this.handleGeneralSettingChange}
                  text={I18n.t('generalSetting.allowStripePaymentLink')}
                />
              </FormGroup>
            )}
            <FormGroup>
              <CcCheckbox
                id="hideDriverLogoutBtn"
                disabled={editable}
                checked={_.get(
                  this.state.fleet,
                  'generalSetting.hideDriverLogoutBtn',
                  false
                )}
                onChange={this.handleGeneralSettingChange}
                text={I18n.t('generalSetting.hideDriverLogoutBtn')}
              />
            </FormGroup>
            <FormGroup>
              <CcCheckbox
                id="showMeetAndGreetButtonForEveryBooking"
                disabled={editable}
                checked={_.get(
                  this.state.fleet,
                  'generalSetting.showMeetAndGreetButtonForEveryBooking',
                  false
                )}
                onChange={this.handleGeneralSettingChange}
                text={I18n.t(
                  'generalSetting.showMeetAndGreetButtonForEveryBooking'
                )}
              />
            </FormGroup>
            <ButtonToolbar className="text-center mb-md">
              {!this.props.permissions || this.props.permissions.actions ? (
                <Button className={'btn-save'} onClick={this.handleSaveClick}>
                  <Translate value="generalSetting.Save" />
                </Button>
              ) : (
                ''
              )}
            </ButtonToolbar>
          </Col>
        </Row>
      </div>
    ) : null;
  }
}
GeneralSetting.contextTypes = {
  notification: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(GeneralSetting);
