/* global google */
import React from 'react';
import {
  FormGroup,
  Modal,
  Button,
  Image,
  Row,
  Col,
  FormControl,
  InputGroup,
  Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TencentMap from '../../../../../components/ZoneTencentMap';
import GettingStartedGoogleMap from '../../../../../components/ZoneGoogleMap';
import { ZoneActionStatus } from '../../../../../constants/commondata';
import { Validator, ValidCase } from '../../../../../components/validator';
import {
  Validation,
  CCLiteCommonFunc,
  getLanguageAutoCompleteSearch,
} from '../../../../../utils/commonFunctions';
import InputRequireInline from '../../../../../components/InputRequireInline';
import { getPaths } from '../../../dynamicFare/components/utils';
import Confirm from '../../../../../components/confirm/Confirm';
import { QQAutocomplete, QQUltis } from '../../../../../components/qqMap';
import PlacesAutocomplete from '../../../../../components/placesAutocomplete/PlacesAutocomplete';
import { geoPlaceDetailMapProvider } from '../../../../../utils/mapUtils';
import CcCheckbox from '../../../../../components/ccCheckbox/CcCheckbox';

import * as settingActions from '../../../../../actions/settingActions';
import * as loadingBarActions from '../../../../../actions/loadingBarActions';
import * as zoneNoShowActions from '../../../../../actions/zoneNoShowActions';
import * as commonActions from "../../../../../actions/commonDataAction";

import ZoneNoShowList from './ZoneNoShowList';

import closeIcon from '../../../../../assets/images/icons/close.svg';
import './ModalZoneNoShow.scss';
import QuestionCircleTooltip from '../../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import { BsSearch } from 'react-icons/bs';
import AutocompleteWith3rd from '../../../../../components/qqMap/AutocompleteWith3rd';

const AutocompleteItem = ({ suggestion }) => (
  <div>
    <i className="fa fa-map-marker" />
    {suggestion}
  </div>
);
const myStyles = {
  input: { width: '100%' },
  autocompleteContainer: { padding: '5px 12px' },
  autocompleteItem: { color: '#e6e6e6', margin: '5px 0' },
  autocompleteItemActive: { color: '#04BE76' },
};

class ModalZoneNoShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: 16.059959,
        lng: 108.224258,
      },
      textSearch: '',
      zoneAction: ZoneActionStatus.View,
      zoneDraw: {
        activeZone: null,
        Zones: [],
        parentZones: [],
      },
      defaultZoom: 12,
      polyline: false,
      isSubmitted: false,
      valid: {
        name: true,
        duration: true,
        maxWaitTime: true,
      },
      fareModel: {
        name: '',
        duration: 1,
        maxWaitTime: 0,
        isActive: false,
      },
    };

    this.getPaths = getPaths.bind(this);
  }

  componentDidMount() {
    const { zoneNoShowService, selectedFleet } = this.props;
    zoneNoShowService
      .fetchZoneNoShow({
        fleetId: selectedFleet.fleetId,
      })
      .then((resp) => {
        const zoneNoShows = resp.res;
        const mapZoneData = this.initMapZone(zoneNoShows);
        this.setState({ ...mapZoneData });
      })
      .catch((error) => {
        this.context.notification('error', I18n.t('General.Can_not_get_data'));
      });
  }

  loadMapZoneFullGeo = async () => {
    loadingBarActions.showLoadingSpiner();
    const result = await this.props.commonActions.loadMapZoneFullGeo(this.props.auth.selectedFleet.fleetId)
    loadingBarActions.hideLoadingSpiner()
    if(result?.res?.length > 0) {
      return result?.res
    }
    return []
  }

  initMapZone = zoneNoShows => {
    const { commonData } = this.props;
    const isChina = _.get(commonData, 'location.isChina', false);
    const parsedFares = [];

    _.forEach(zoneNoShows, (zone) => {
      if (
        zone.geo &&
        zone.geo.coordinates &&
        zone.geo.coordinates[0] &&
        _.isArray(zone.geo.coordinates[0][0])
      ) {
        parsedFares.push({
          _id: zone._id,
          name: zone.name,
          isActive: zone.isActive,
          duration: zone.duration,
          maxWaitTime: zone.maxWaitTime,
          path: zone.geo.coordinates[0].map((pos) => ({
            lat: pos[1],
            lng: pos[0],
          })),
        });
      }
    });

    const allPoints = [];

    _.forEach(parsedFares, (item) => {
      allPoints.concat(item.path);
    });

    const { center } = this.state;

    if (allPoints.length) {
      this.mapZoneLoaded = true;

      if (isChina) {
        const latLng = new window.qq.maps.LatLngBounds();
        _.forEach(allPoints, (pos) => {
          latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
        });
        // COPY FROM Zone.js
        /* for some reason i dont know but it needs to setTimeout for function this.map.fitBounds(latLng)
         */
        setTimeout(() => {
          this.map.fitBounds(latLng);
          if (allPoints.length === 1) {
            this.map.setZoom(10);
          }
        });
      } else {
        const latLng = new google.maps.LatLngBounds();
        _.forEach(allPoints, (pos) => {
          latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
        });

        if (this._map) {
          this._map.fitBounds(latLng);
          if (allPoints.length === 1) {
            this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
              10
            );
          }
        } else {
          setTimeout(() => {
            if (this._map) {
              this._map.fitBounds(latLng);
            }
            if (allPoints.length === 1) {
              this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
                15
              );
            }
          }, 100);
        }
      }
    }

    // init zone active fleet
    const parentZones = [];
    let finalMapzone = commonData.mapZone
    if(!commonData.isZoneLoadFullGeo) {
      finalMapzone = this.loadMapZoneFullGeo()
    }
    _.forEach(finalMapzone, operationZone => {
      if(operationZone.isActive && operationZone.display) {
        parentZones.push({
          _id: operationZone._id,
          name: operationZone.zoneName,
          isParentZone: true,
          isActive: true,
          path: operationZone.geo.coordinates[0].map((pos) => {
            return {
              lat: pos[1],
              lng: pos[0],
            };
          }),
        });
      }
    });

    return {
      center,
      zoneNoShows,
      zoneAction: ZoneActionStatus.View,
      zoneDraw: {
        Zones: parsedFares,
        activeZone: null,
        parentZones,
      },
    };
  };

  handleSearchChange = (e) => this.setState({ textSearch: e.target.value });

  handleMapLoad = (key, ref) => {
    if (ref) {
      this[key] = ref;
    }
    if (key == 'map' && this.map && this.props.commonData?.location?.isChina) {
      window.qq.maps.event.addListener(this.map, 'bounds_changed', () => {
        this.state.center = this.map.center;
      });
    }
  };

  onDragEnd = (e) => {
    if (e._id && this.state.zoneAction === ZoneActionStatus.View) {
      this.handleZoneRowClick(e);
    }
  };

  handleZoneRowClick = (zone) => {
    const { commonData } = this.props;
    const { zoneDraw } = this.state;
    const isChina = _.get(commonData, 'location.isChina', false);
    let { center } = this.state;

    if (zone._id === zoneDraw._id) {
      return false;
    }

    if (isChina) {
      zoneDraw.activeZone = zone;
      const latLng = new window.qq.maps.LatLngBounds();
      zone.path.forEach((pos) => {
        latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
      });

      if (this._map) {
        this._map.fitBounds(latLng);
      }

      center = latLng.getCenter();
    } else {
      zoneDraw.activeZone = zone;
      const latLng = new google.maps.LatLngBounds();
      zone.path.forEach((pos) => {
        latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
      });

      if (this._map) {
        this._map.fitBounds(latLng);
      }

      center = latLng.getCenter();
    }

    this.setState({
      zoneDraw,
      fareModel: zone,
      zoneAction: ZoneActionStatus.View,
      center,
      polyline: false,
    });
  };

  ValidatorCallback = (id, value) => {
    const { valid } = this.state;

    if (valid[id] !== value) {
      this.setState({
        valid: {
          ...valid,
          [id]: value,
        },
      });
    }
  };

  handleAddButtonClick = () => {
    const { zoneDraw } = this.state;

    this.setState({
      zoneAction: ZoneActionStatus.Add,
      fareModel: {
        name: '',
        duration: 1,
        maxWaitTime: 0,
      },
      polyline: true,
      zoneDraw: {
        ...zoneDraw,
        activeZone: { path: [], name: '' },
      },
    });
  };

  handleFormFieldChange = (key, value) => {
    const { polyline, zoneDraw } = this.state;
    const isChina = this.isChina();

    if (!isChina) {
      if (polyline) {
        zoneDraw.activeZone.path = this.Polyline.getPath()
          .getArray()
          .map((p) => p.toJSON());
      } else {
        zoneDraw.activeZone.path = this.Polygon.getPaths()
          .getAt(0)
          .getArray()
          .map((p) => p.toJSON());
      }
    }

    this.setState({
      zoneDraw,
      fareModel: {
        ...this.state.fareModel,
        [key]: value,
      },
    });
  };

  handleSubmitFare = (e) => {
    e.preventDefault();
    // e.stopPropagation();
    this.setState({
      isSubmitted: true,
    });
    const { zoneNoShowService, selectedFleet } = this.props;
    const { valid, fareModel } = this.state;

    if (!CCLiteCommonFunc.isFormValid(valid)) {
      return false;
    }

    const paths = this.getPaths();

    if (!paths) {
      return this.context.notification(
        'error',
        I18n.t('zoneSetting.Draw_zone'),
        ''
      );
    }

    let submitAction = '';
    const requestParams = {
      fleetId: selectedFleet.fleetId,
      name: fareModel.name,
      duration: fareModel.duration,
      maxWaitTime: fareModel.maxWaitTime,
      isActive: fareModel.isActive,
      geo: {
        coordinates: paths,
        type: 'Polygon',
      },
    };

    if (!fareModel._id) {
      if (paths[0].length >= 3) {
        submitAction = 'create';
      }
    } else {
      requestParams.zoneNoShowId = fareModel._id;
      submitAction = 'update';
    }

    if (submitAction) {
      zoneNoShowService[`${submitAction}ZoneNoShow`](requestParams).then(
        (data) => {
          if (data.ok && data.res) {
            const { zoneNoShows } = this.state;

            if (submitAction === 'create') {
              zoneNoShows.unshift(data.res);
            } else {
              const index = _.findIndex(
                zoneNoShows,
                (item) => item._id === data.res._id
              );

              if (index !== -1) {
                zoneNoShows[index] = data.res;
              }
            }

            const mapZoneData = this.initMapZone(zoneNoShows);
            this.Polyline = null;
            this.setState({
              ...mapZoneData,
              isSubmitted: false,
              polyline: false,
            });

            this.context.notification(
              'success',
              I18n.t(`zoneSetting.${_.upperFirst(submitAction)}_zone_success`)
            );
          } else {
            this.setState({
              isSubmitted: false,
              polyline: false,
            });

            if (data.error) {
              this.context.notification(
                'error',
                I18n.t(`errors.${data.error.errorCode}`)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t(`zoneSetting.${_.upperFirst(submitAction)}_zone_fail`)
              );
            }
          }
        }
      );
    }
  };

  handleCloseForm = () => {
    this.setState({
      isSubmitted: false,
    });
  };

  handlePolylineClick = (e) => {
    console.log('---- polyline click ---', e.vertex);
    if (e.vertex === 0) {
      const { commonData } = this.props;
      const { zoneDraw } = this.state;
      const isChina = _.get(commonData, 'location.isChina', false);

      if (isChina) {
        const lastPath =
          zoneDraw.activeZone.path[zoneDraw.activeZone.path.length - 1];

        if (!(e.latLng.lat === lastPath.lat && e.latLng.lng == lastPath.lng)) {
          zoneDraw.activeZone.path.push({
            lat: e.latLng.lat,
            lng: e.latLng.lng,
          });

          this.Polygon = zoneDraw.activeZone.path;
        }
      } else {
        zoneDraw.activeZone.path.push(e.latLng.toJSON());
      }

      this.setState({ polyline: false, zoneDraw });
    }
  };

  handleMapClick = (e) => {
    const { zoneAction, polyline } = this.state;
    const { zoneDraw } = this.state;

    if (
      zoneAction === ZoneActionStatus.Add ||
      zoneAction === ZoneActionStatus.Edit
    ) {
      const isChina = this.isChina();
      if (isChina) {
        const { latLng } = e;

        if (latLng) {
          let polylinePath = this.Polyline
            ? this.Polyline.getPath().elems.map((obj) => ({
                lat: obj.lat,
                lng: obj.lng,
              }))
            : null;

          if (polyline) {
            if (polylinePath) {
              polylinePath.push({ lat: latLng.lat, lng: latLng.lng });
            } else {
              polylinePath = [];
              polylinePath.push({ lat: latLng.lat, lng: latLng.lng });
            }

            zoneDraw.activeZone.path = polylinePath;
            this.setState({ zoneDraw });
          }
        }
      } else {
        console.log('----------- map click ------------', polyline);
        if (!polyline) {
          zoneDraw.activeZone.path = this.Polygon.getPaths()
            .getAt(0)
            .getArray()
            .map((p) => p.toJSON());
        } else {
          zoneDraw.activeZone.path = this.Polyline.getPath()
            .getArray()
            .map((p) => p.toJSON());
          zoneDraw.activeZone.path.push(e.latLng.toJSON());
        }

        this.setState({ zoneDraw });
      }
    }
  };

  isChina = () => _.get(this.props.commonData, 'location.isChina', false);

  handleClearDrawClick = () => {
    this.Polyline = null;
    const { zoneDraw } = this.state;
    zoneDraw.activeZone.path = [];
    this.setState({ zoneDraw, polyline: true });
  };

  handleEditFare = (zone) => {
    let center = null;
    const { zoneDraw } = this.state;
    const isChina = this.isChina();
    const latLng = isChina
      ? new window.qq.maps.LatLngBounds()
      : new google.maps.LatLngBounds();
    zoneDraw.activeZone = Object.assign({}, zone);

    if (isChina) {
      _.forEach(zone.path, (pos) => {
        latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
      });
    } else {
      _.forEach(zone.path, (pos) => {
        latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
      });
    }

    if (this._map) {
      this._map.fitBounds(latLng);
    }

    center = latLng.getCenter();
    this.setState({
      zoneDraw,
      center,
      zoneAction: ZoneActionStatus.Edit,
      fareModel: zone,
    });
  };

  handleMenuItemClick = (eventKey, zone) => {
    switch (eventKey) {
      case ZoneActionStatus.Edit:
        return this.handleEditFare(zone);

      case ZoneActionStatus.Delete:
        if (!zone.isActive) {
          this.setState({
            confirmDelete: {
              zoneNoShowId: zone._id,
              title: I18n.t('zoneSetting.DELETE_ZONE'),
              body: I18n.t('zoneSetting.ZONE_CONFIRM'),
              buttonTitle: I18n.t('permission.Yes'),
              closeButtonText: I18n.t('permission.No'),
            },
          });
        }
        break;

      case ZoneActionStatus.Status:
        this.handleActivateFare(zone);

        break;
      default:
        break;
    }
  };

  handleActivateFare = (zoneNoShow) => {
    const { zoneNoShowService, selectedFleet } = this.props;
    const actionName = zoneNoShow.isActive ? 'deactive' : 'active';
    zoneNoShowService[`${actionName}ZoneNoShow`]({
      fleetId: selectedFleet.fleetId,
      zoneNoShowId: zoneNoShow._id,
    }).then((data) => {
      if (data.ok && data.res) {
        const { zoneNoShows } = this.state;
        const index = _.findIndex(
          zoneNoShows,
          (item) => item._id === data.res._id
        );

        if (index !== -1) {
          zoneNoShows[index] = data.res;
        }

        const mapZoneData = this.initMapZone(zoneNoShows);

        this.setState({ ...mapZoneData });

        this.context.notification(
          'success',
          I18n.t(`zoneSetting.Update_zone_success`)
        );
      } else {
        this.context.notification(
          'error',
          I18n.t(`zoneSetting.Update_zone_fail`)
        );
      }
    });
  };

  handleDeleteFare = () => {
    const { confirmDelete } = this.state;
    const { zoneNoShowService, selectedFleet } = this.props;

    if (confirmDelete.zoneNoShowId) {
      this.setState(
        {
          confirmDelete: {
            ...confirmDelete,
            disabledConfirm: true,
          },
        },
        () => {
          zoneNoShowService
            .deleteZoneNoShow({
              fleetId: selectedFleet.fleetId,
              zoneNoShowId: confirmDelete.zoneNoShowId,
            })
            .then((data) => {
              if (data.ok && data.res) {
                const { zoneNoShows } = this.state;
                _.remove(
                  zoneNoShows,
                  (item) => item._id === confirmDelete.zoneNoShowId
                );

                const mapZoneData = this.initMapZone(zoneNoShows);

                this.setState({
                  ...mapZoneData,
                  confirmDelete: null,
                });

                this.context.notification(
                  'success',
                  I18n.t(`dynamicFare.delete_fare_success`)
                );
              } else {
                this.setState({ confirmDelete: null });

                this.context.notification(
                  'error',
                  I18n.t(`dynamicFare.delete_fare_fail`)
                );
              }
            });
        }
      );
    }
  };

  handleConfirmCloseClick = () => this.setState({ confirmDelete: null });

  handleAddressChange = (address) => this.setState({ address });

  handleRemoveAddress = () => {
    if (window.searchPlaceMarked) {
      window.searchPlaceMarked.setMap(null);
    }

    this.setState({ address: '' });
  };

  handleSelectAddress = (address, placeId, poinOfInterest, sessionToken) => {
    const isChina = this.isChina();
    this.setState({ address });

    if (isChina) {
      let callback = (err, location) => {
        if (location) {
          this.map.setCenter(location);
          this.map.setZoom(18);
        } else {
          this.context.notification('warning', 'Location not found!');
        }
      }
      geoPlaceDetailMapProvider({
        auth: this.props.auth,
        placeid: placeId,
        sessionToken,
        callback,
        isTencent: true,
        language: this.props.language
      });
    } else if (address && address !== '') {
      this.setState({ address });

      let callback = (err, location) => {
        this.setState({ center: location, defaultZoom: 18 });

        if (this._map) {
          this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
            18
          );
        }
      };

      geoPlaceDetailMapProvider({
        auth: this.props.auth,
        placeid: placeId,
        sessionToken,
        callback,
        language: this.props.language
      });
    }
  };

  handleCancelButtonClick = () => {
    this.Polyline = null;
    this.setState({
      zoneAction: ZoneActionStatus.View,
      isSubmitted: false,
      polyline: false,
      zoneDraw: {
        ...this.state.zoneDraw,
        activeZone: null,
      },
    });
  };

  renderZoneNoShows = () => {
    const { textSearch, zoneDraw, zoneAction } = this.state;
    const { permissions } = this.props;
    const searchLowercase = textSearch.trim().toLowerCase();
    const filteredZones = _.filter(zoneDraw.Zones, (z) =>
      z.name.toLowerCase().includes(searchLowercase)
    );

    if (zoneAction === ZoneActionStatus.View) {
      return (
        <ZoneNoShowList
          zoneNoShows={filteredZones}
          hasPermission={!permissions || permissions.actions}
          handleActivateFare={this.handleActivateFare}
          handleZoneRowClick={this.handleZoneRowClick}
          handleMenuItemClick={this.handleMenuItemClick}
        />
      );
    }

    return null;
  };

  renderMap = () => {
    const { zoneDraw, zoneAction, defaultZoom, polyline, center, address } =
      this.state;
    const isChina = this.isChina();

    if (isChina) {
      return (
        <React.Fragment>
          <TencentMap
            containerElement={<div className="mapqup fill" />}
            mapElement={<div style={{ height: `100%` }} />}
            center={center}
            onMapLoad={this.handleMapLoad}
            onPolygonComplete={() => {}}
            handlePolylineClick={this.handlePolylineClick}
            zoneDraw={JSON.parse(JSON.stringify(zoneDraw))}
            status={zoneAction}
            onDragEnd={this.onDragEnd}
            handleMapClick={this.handleMapClick}
            editable={
              zoneAction == ZoneActionStatus.Add ||
              zoneAction == ZoneActionStatus.Edit
            }
            polyline={polyline}
            defaultZoom={defaultZoom}
          />
          <div className="zone-location">
            <AutocompleteWith3rd
              className="form-custom form-control"
              value={address}
              onChange={this.handleAddressChange}
              onSelect={this.handleSelectAddress}
              handleAddressRemoved={this.handleRemoveAddress}
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <GettingStartedGoogleMap
          containerElement={<div className="mapqup fill" />}
          mapElement={<div style={{ height: `100%` }} />}
          center={center}
          onMapLoad={this.handleMapLoad}
          onPolygonComplete={() => {}}
          handlePolylineClick={this.handlePolylineClick}
          zoneDraw={JSON.parse(JSON.stringify(zoneDraw))}
          status={zoneAction}
          onDragEnd={this.onDragEnd}
          handleMapClick={this.handleMapClick}
          editable={
            zoneAction == ZoneActionStatus.Add ||
            zoneAction == ZoneActionStatus.Edit
          }
          polyline={polyline}
          defaultZoom={defaultZoom}
        />
        <div className="zone-location">
          <PlacesAutocomplete
            value={address}
            onSelect={this.handleSelectAddress}
            onChange={this.handleAddressChange}
            autocompleteItem={AutocompleteItem}
            className="location-select form-custom"
            styles={myStyles}
            placeholder={I18n.t('map.Enter_a_location')}
            onEnterKeyDown={this.handleSelectAddress}
            handleAddressRemoved={this.handleRemoveAddress}
          />
        </div>
      </React.Fragment>
    );
  };

  renderFareForm = () => {
    const { fareModel, isSubmitted, valid } = this.state;

    return (
      <div className="fare-form">
        <form onSubmit={this.handleSubmitFare}>
          <InputRequireInline
            className={!isSubmitted || valid.name ? null : 'error'}
            >
            <FormControl
              placeholder={
                `${fareModel.name}`.length ? '' : I18n.t('zoneSetting.Name')
              }
              type="text"
              className="form-custom"
              value={fareModel.name}
              onChange={(e) =>
                this.handleFormFieldChange('name', e.target.value)
              }
              maxLength="50"
            />
            <Validator id="name" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!isSubmitted}
                valid={!Validation.isStringEmpty(fareModel.name)}
                message={I18n.t('messages.commonMessages.Required_field')}
              />
              <ValidCase
                hide={!isSubmitted}
                valid={!Validation.isMaxlength(fareModel.name, 50)}
                message={I18n.t('messages.commonMessages.Max_length').format(
                  50
                )}
              />
            </Validator>
          </InputRequireInline>
          <Form.Label>
            <Translate value="generalSetting.DurationRegularBooking" />
            <QuestionCircleTooltip
              text={I18n.t('generalSetting.DurationRegularBookingToolTip')}
            />
          </Form.Label>
          <InputRequireInline
            style={{ marginTop: '7px' }}
            className={!isSubmitted || valid.duration ? null : 'error'}
            >
            <InputGroup
              className={`single-addon-right`}
            >
              <FormControl
                type="number"
                step="0.1"
                placeholder={
                  `${fareModel.duration}`.length
                    ? ''
                    : I18n.t('generalSetting.DurationRegularBooking')
                }
                min={0}
                className="form-custom"
                value={fareModel.duration}
                onChange={(e) =>
                  this.handleFormFieldChange('duration', e.target.value)
                }
              />
              <InputGroup.Append>
                <InputGroup.Text>mins</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Validator id="duration" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!isSubmitted}
                valid={!Validation.isStringEmpty(fareModel.duration)}
                message={I18n.t('messages.commonMessages.Required_field')}
              />
              <ValidCase
                hide={!isSubmitted}
                valid={Validation.isInteger(fareModel.duration)}
                message={I18n.t('messages.commonMessages.must_be_integer')}
              />
              <ValidCase
                hide={!isSubmitted}
                valid={this.validateNoShowDuration(fareModel.duration)}
                message={I18n.t(
                  'messages.commonMessages.Integer_from_range'
                ).format('1', '9999')}
              />
            </Validator>
          </InputRequireInline>
          <Form.Label>
            <Translate value="generalSetting.maxWaitTime" />
            <QuestionCircleTooltip
              text={I18n.t('generalSetting.maxWaitTimeToolTip')}
            />
          </Form.Label>
          <InputRequireInline
            style={{ marginTop: '7px' }}
            className={!isSubmitted || valid.maxWaitTime ? null : 'error'}
            >
            <InputGroup
              className={`single-addon-right`}
              // style={{ border: 'none' }}
              >
              <FormControl
                type="number"
                placeholder={
                  `${fareModel.maxWaitTime}`.length
                    ? ''
                    : I18n.t('generalSetting.maxWaitTime')
                }
                step="0.1"
                min={0}
                className="form-custom"
                value={fareModel.maxWaitTime}
                onChange={(e) =>
                  this.handleFormFieldChange('maxWaitTime', e.target.value)
                }
              />
              <InputGroup.Append>
                <InputGroup.Text>mins</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Validator id="maxWaitTime" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!isSubmitted}
                valid={!Validation.isStringEmpty(fareModel.maxWaitTime)}
                message={I18n.t('messages.commonMessages.Required_field')}
              />
              <ValidCase
                hide={!isSubmitted}
                valid={Validation.isInteger(fareModel.maxWaitTime)}
                message={I18n.t('messages.commonMessages.must_be_integer')}
              />
              <ValidCase
                hide={!isSubmitted}
                valid={this.validateNoShowMaxWaitTime(fareModel.maxWaitTime)}
                message={I18n.t(
                  'messages.commonMessages.Integer_from_range'
                ).format('0', '9999')}
              />
            </Validator>
          </InputRequireInline>
          <CcCheckbox
            checked={fareModel.isActive || false}
            onChange={(e) =>
              this.handleFormFieldChange('isActive', e.target.checked)
            }
            text={I18n.t('zoneSetting.Active')}
            labelClassName="fs-16"
            className="jc-end"
          />
          <div>
            <Button className="btn-save mr-md" type="submit">
              <Translate value="zoneSetting.Save" />
            </Button>
            <Button className="btn-cancel" onClick={this.handleClearDrawClick}>
              <Translate value="zoneSetting.Clear_draw" />
            </Button>
          </div>
        </form>
      </div>
    );
  };

  validateNoShowDuration = (duration) => duration >= 1 && duration <= 9999;
  validateNoShowMaxWaitTime = (maxWaitTime) =>
    maxWaitTime >= 0 && maxWaitTime <= 9999;

  render() {
    const { textSearch, zoneAction, confirmDelete } = this.state;
    const { permissions } = this.props;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onCloseModal}
        backdrop
        dialogClassName="zone-no-show"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="generalSetting.noShowCountDown.customizedNoShowTitle" />
          </Modal.Title>
          {/* <Image
            src={closeIcon}
            onClick={this.props.onCloseModal}
            className="close"
          /> */}
        </Modal.Header>
        <Modal.Body className="clearfix zoneNoShow__boby">
          <div className="content noShowBoby__container">
            <Row style={{ height: `100%` }}>
              <Col lg={7} md={5}>
                <div className="header-button-group">
                  {zoneAction === ZoneActionStatus.View ? (
                    <React.Fragment>
                      {!permissions || permissions.actions ? (
                        <Button
                          className="btn-header text-add-header"
                          onClick={this.handleAddButtonClick}
                        >
                          <i className="fa fa-plus" />
                          <Translate value="driver.Add" />
                        </Button>
                      ) : null}
                      <FormGroup className="search-format">
                        <FormControl
                          type="text"
                          value={textSearch}
                          onChange={this.handleSearchChange}
                          className="form-custom search-form"
                          placeholder={I18n.t('General.search')}
                        />
                        <BsSearch className="search-icon" />
                      </FormGroup>
                    </React.Fragment>
                  ) : (
                    <Button
                      className="btn-header text-delete-header"
                      onClick={this.handleCancelButtonClick}
                    >
                      <i className="fa fa-times" />
                      <Translate value="zoneSetting.Cancel" />
                    </Button>
                  )}
                </div>
                {zoneAction !== ZoneActionStatus.View && this.renderFareForm()}
                {this.renderZoneNoShows()}
              </Col>
              <Col lg={5} md={7} style={{ height: '100%' }}>
                {this.renderMap()}
              </Col>
            </Row>
            <Confirm
              confirm={confirmDelete}
              handleConfirmButtonClick={this.handleDeleteFare}
              handleConfirmCloseClick={this.handleConfirmCloseClick}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ModalZoneNoShow.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission,
    language: state.i18n,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    zoneNoShowService: bindActionCreators(zoneNoShowActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalZoneNoShow);
