import React, { Component } from "react";
import PropTypes from "prop-types";
import { Translate, I18n } from "react-redux-i18n";
import { FormGroup, FormControl, Form, Modal, Button, Image } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import Datetime from '../../components/dateTime/DateTime';
import _ from 'lodash';

import excelIcon from "../../assets/images/excel.svg";
import commonDataAction from './../../actions/commonDataAction';
import { CCLiteCommonFunc, handleResultExport } from "../../utils/commonFunctions";
import Confirm from '../confirm/Confirm';
import { Validator, ValidCase } from "../../components/validator";
import { socketLocalApi } from "../../utils/socketUtils";
import { socketConfigs } from "./../../constants/socketConfigs";
import moment from "moment";

import VersionApp from './Components/VersionApp';
import ExportComponent from '../ExportComponent';
import LinkContentInbox from './Components/LinkContentInbox';
import HtmlContentInbox from './Components/HtmlContentInbox';

const ContentType = [
  "html", "link"
];

const PlatformType = [
  "all", "iOS", "android"
];

const ComparisonVersionAppOption = [
  'is',
  'is_not',
  'is_one_of',
  'is_not_one_of',
  'greater_or_equal',
  'less_or_equal'
];

class MessageDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      isSubmited: false,
      showPercent: false,
      exportPercent: 0,
      editable: false,
      detailItem: null
    }

    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleResultExport = handleResultExport.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount() {
    let data = this.props.detailItem || {};
    let editable = this.props.editable || false;
    if (data) {
      this.setState({
        detailItem: data,
        editable
      })
    };
  }

  handleCloseDialog() {
    this.props.closeDialog();
    this.setState({
      exportPercent: false,
      showPercent: 0,
      editable: false,
      detailItem: null
    })
    socketLocalApi.remove(
      socketConfigs.receive.report.downloadProgress
    );
  };

  handleEditClick = (key, e) => {
    switch (key) {
      case "edit":
        this.setState({ editable: true })
        break;
      case "cancel":
        this.handleCloseDialog();
        break;
      default:
        break;
    }
  };

  handleContentChange = (e) => {
    const { detailItem } = this.state;
    let content = e.target.value;
    this.setState({
      detailItem: {
        ...detailItem,
        inboxContent: content
      }
    });
    return;
  };

  handleChangeInput(language, key, e) {
    const { detailItem } = this.state;
    let value = e.target.value;
    let listMessages = _.cloneDeep(detailItem.languages);
    let indexItem = listMessages.findIndex(item => item.language === language);
    if (indexItem !== -1) {
      listMessages[indexItem][key] = value;
    } else {
      listMessages.push({
        language,
        [key]: value,
      })
    };
    this.setState({
      detailItem: {
        ...detailItem,
        languages: listMessages
      }
    });
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  saveDialogForm = () => {
    const { detailItem, isSubmited } = this.state;
    const timezone = this.props.auth && this.props.auth.selectedFleet && this.props.auth.selectedFleet.timezone
    if (!isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }

    if (
      (!detailItem.languages || detailItem.languages.length === 0) &&
      (!detailItem.contentType || detailItem.contentType === 'html')
    ) {
      if (!detailItem.inboxContent.trim()) {
        this.setState({
          errorMessage: I18n.t("message.ERROR_INPUT_CONTENT"),
          contentValidation: false
        });
        return;
      }
    };

    if (detailItem.inboxContentId) {
      let object = {
        fleetId: detailItem.fleetId,
        inboxId: detailItem.inboxContentId,
        content: detailItem.inboxContent
      };
      if (detailItem.languages && detailItem.languages.length > 0) {
        object.languages = detailItem.languages;
      };
      if(detailItem.expiryDate) {
        object.expiryDate = new Date(
          moment
            .tz(detailItem.expiryDate, timezone)
            .endOf('day')
            .toISOString()
        );
      }
      this.setState({ editable: false, detailItem: null },
        () => {
          this.props.handleEditMessage(object);
        })
    }
  }

  handleChangeExpiryDate = (e) => {
    let expiryDate = e._d;
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        expiryDate: moment(expiryDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
    });
  };

  validExpiryDate = (current) => {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  prepareParamExport = () => {
    return {
      userId: this.props.user._id,
      fleetId: this.props.fleet.fleetId,
      contentId: this.props.detailItem.inboxContentId,
      appType: this.props.detailItem.appType,
      inboxType: this.props.detailItem.type,
    }
  }

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null });
  }

  render() {
    const { detailItem } = this.state;
    const { permissions, fleet, auth: { user } = {} } = this.props;
    const { drvSignUpLanguages } = fleet;

    if (this.props.detailItem) {
      const { createdDate } = this.props.detailItem;
      const isAfterOneMonth = moment().subtract('months', 1).isAfter(moment(createdDate));
      return (
        <>
          <Confirm
            confirm={this.state.confirm}
            handleConfirmButtonClick={() => {}}
            handleConfirmCloseClick={this.handleConfirmCloseClick}
          />
          <Modal
            show={true}
            onHide={this.handleCloseDialog}
            backdrop="static"
            aria-labelledby="contained-modal-title-sm"
            dialogClassName="card-dialog"
          >
            <Modal.Header>
              <Modal.Title>
                <Translate value="message.MESSAGE_DETAILS" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.handleCloseDialog}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body className="clearfix">
              <FormGroup>
                <Form.Label>
                  <Translate value="message.Type" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={this.props.detailItem.type}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup>
                <Form.Label>
                  <Translate value="message.SendTo" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={
                    this.props.detailItem.appType == "driver"
                      ? (this.props.detailItem.sendTo == 1 ? I18n.t("message.Customized_driver") : I18n.t("message.All_drivers"))
                      :  this.props.detailItem.appType == "passenger" ?  (this.props.detailItem.sendTo == 1 ? I18n.t("message.Customized_customer") : I18n.t("message.All_customers"))
                      : (this.props.detailItem.sendTo == 1 ? I18n.t("message.Customized_merchant") : I18n.t("message.All_merchants"))
                  }
                  disabled={true}
                />
              </FormGroup>
              <FormGroup>
                <ExportComponent 
                  prepareParamExport={this.prepareParamExport}
                  urlExport={this.props.export}
                  user={user}
                />
                <div className="text-warning">
                  <i>
                    <Translate value="message.NoteDownloadReveverList" />
                  </i>
                </div>
              </FormGroup>

              <VersionApp
                versionApp={detailItem && detailItem.versionApp ? detailItem.versionApp : ''}
                ComparisonVersionAppOption={ComparisonVersionAppOption}
                comparisonVersionApp={detailItem && detailItem.versionApp ? detailItem.comparisonVersionApp : ComparisonVersionAppOption[0]}
                editable={false}
              />

              <FormGroup>
                <Form.Label>
                  <Translate value="message.platform" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  as="select"
                  value={detailItem && detailItem.platform ? detailItem.platform : PlatformType[0]}
                  disabled
                >
                  {PlatformType.map(item => {
                    return (<option key={item} value={item}>{I18n.t(`message.platformItem.` + item)}</option>)
                  })}
                </FormControl>
              </FormGroup>

              <FormGroup>
                <Form.Label>
                  <Translate value="message.contentType" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  as="select"
                  value={detailItem && detailItem.contentType ? detailItem.contentType : ContentType[0]}
                  disabled
                >
                  {ContentType.map(item => {
                    return (<option key={item} value={item}>{I18n.t(`message.contentTypeItem.` + item)}</option>)
                  })}
                </FormControl>
              </FormGroup>

              {detailItem ?
                !detailItem.contentType || detailItem.contentType === 'html' ?
                  <HtmlContentInbox
                    languageOption={detailItem.languages && detailItem.languages.length > 0 ? "multi" : "single"}
                    messages={detailItem.languages}
                    subject={detailItem.subject}
                    inboxContentId={detailItem.inboxContentId}
                    content={detailItem.inboxContent}
                    drvSignUpLanguages={drvSignUpLanguages}
                    // handleSubjectChange={this.handleSubjectChange}
                    handleContentChange={this.handleContentChange}
                    // handleChangeLanguageOption={this.handleChangeLanguageOption}
                    handleChangeInput={this.handleChangeInput}
                    // subjectValidation={this.state.subjectValidation}
                    contentValidation={this.state.contentValidation}
                    editable={false}
                    allowContentUpdate={this.state.editable}
                  />
                  :
                  <LinkContentInbox
                    languageOption={detailItem.languages && detailItem.languages.length > 0 ? "multi" : "single"}
                    messages={detailItem.languages}
                    url={detailItem && detailItem.url ? detailItem.url : ''}
                    subject={detailItem && detailItem.subject ? detailItem.subject : ''}
                    inboxContentId={detailItem && detailItem.inboxContentId ? detailItem.inboxContentId: ''}
                    body={detailItem && detailItem.inboxContent ? detailItem.inboxContent : ''}
                    image={detailItem && detailItem.image ? detailItem.image : ''}
                    drvSignUpLanguages={drvSignUpLanguages}
                    editable={false}
                  />
                : null
              }

              {detailItem &&
                detailItem.type === "Inbox" &&
                detailItem.expiryDate ? (
                <FormGroup>
                  <Form.Label>
                    <div className="requestTimeEnable mb">
                      <Translate value="message.expiryDate" />{' '}
                    </div>
                  </Form.Label>
                    <FormGroup
                    >
                      <Datetime
                        onChange={this.handleChangeExpiryDate}
                        inputProps={{ readOnly: true, disabled: !this.state.editable }}
                        value={detailItem.expiryDate ? moment(detailItem.expiryDate).format('l') : null}
                        isValidDate={this.validExpiryDate}
                        closeOnSelect
                        checkShowOnTop
                        parrentMarginBottom={200}
                        timeFormat={false}
                        placeholder={I18n.t('message.expiryDate_placeholder')}
                      />
                    </FormGroup>
                </FormGroup>
              ) : null}

            </Modal.Body>
            {permissions && permissions.actions && (detailItem && (!detailItem.contentType || detailItem.contentType !== 'link')) ? (
              <Modal.Footer>
                {this.state.editable ? (
                  <Button
                    className={"btn-save mr-md"}
                    onClick={this.saveDialogForm}
                  >
                    <Translate value="city.Save" />
                  </Button>
                ) : (
                  <Button
                    className={"btn-save mr-md"}
                    onClick={e => {
                      this.handleEditClick("edit", e);
                    }}
                  >
                    <Translate value="city.Edit" />
                  </Button>
                )}
              </Modal.Footer>
            ) : null}
          </Modal>
        </>
      );
    } else {
      return null;
    }
  }
}

MessageDetail.contextTypes = {
  notification: PropTypes.func
};


export default MessageDetail