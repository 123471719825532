import React, { useState } from 'react';
import Checkbox from 'react-custom-checkbox';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import { useEffect } from 'react';
import {
  checkCanAssignDriver,
  checkCanDetachDriver,
  checkCanUnassignVehicle,
} from '../../../utils/commonFunctions';
import { Button } from 'react-bootstrap';

const DriverColumn = ({
  booking,
  lineShort,
  isMobile,
  isCueFinished,
  handleAssignDriverOneBooking,
}) => {
  const [txtLabel, setTxtLabel] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isDisable, setIsDisable] = useState(false);
  useEffect(() => {
    setTxtLabel(
      booking?.drvInfo?.fullName ?? (isCueFinished ? '' : 'Unassigned')
    );
    setPhoneNumber(booking?.drvInfo?.phone ? booking?.drvInfo?.phone : '');
    setIsDisable(
      !checkCanAssignDriver(booking) && 
      !checkCanDetachDriver(booking) &&
      !checkCanUnassignVehicle(booking)
    );
  }, [booking]);

  // mobile
  if (isMobile) {
    const txtDrv = booking?.drvInfo?.fullName ?? '';
    let txtVhc = '' 
    if(booking?.vehicle?.plateNumber) {
      txtVhc = `${booking?.vehicle?.plateNumber}
      ${
        booking?.vehicle?.vehicleModelName
          ? ` ${'\u2022'} ${booking?.vehicle?.vehicleModelName}`
          : ''
      }
      ${
        booking?.vehicle?.vhcColor
          ? ` ${'\u2022'} ${booking?.vehicle?.vhcColor}`
          : ''
      }
    `
    } else if(booking?.drvInfo?.plateNumber) {
      txtVhc = booking?.drvInfo?.plateNumber
    }

    if (txtLabel) {
      return (
        <Button
          bsPrefix={
            booking?.drvInfo?.fullName && !isCueFinished
              ? 'assginDrv hasDriver'
              : 'assginDrv'
          }
          onClick={(e) => handleAssignDriverOneBooking(e, booking)}
          disabled={isDisable}
        >
          {!txtDrv && !txtVhc ? (
            'Unassigned'
          ) : (
            <>
              <span
                className={`${txtDrv ? '': 'noDriver'}`}
              >
                {txtDrv ? `${txtDrv} ${phoneNumber}` : 'No Driver'}
              </span>
              <span className={`txtVhc ${txtVhc ? '' : 'noVehicle'}`}>{txtVhc || 'No Vehicle'}</span>
            </>
          )}
        </Button>
      );
    }
    return null;
  }

  // desktop
  return (
    <div>
      {txtLabel && (
        <div
          className={`driver-info cueBtnContent ${
            lineShort ? ' custom__lineShort' : ''
          }`}
        >
          {isDisable || isCueFinished ? (
            <p className={`drv_btn_cue ${lineShort ? 'hasLineShort' : ''}`}>
              {/* tách ra nếu không sẽ lỗi tiếng ả rập */}
              <p>{txtLabel}</p>
              <span>{` ${phoneNumber}`}</span>
            </p>
          ) : (
            <p
              className={`drv_btn_cue canClick ${
                lineShort ? 'hasLineShort' : ''
              }`}
              onClick={(e) => handleAssignDriverOneBooking(booking, e)}
            >
              {/* tách ra nếu không sẽ lỗi tiếng ả rập */}
              <p>{txtLabel}</p>
              <span>{` ${phoneNumber}`}</span>
            </p>
          )}
        </div>
      )}
      {!lineShort &&
        booking.sos &&
        booking.sos.find((sos) => sos.fromApp != 'passenger') && (
          <div className="sos-image" />
        )}
    </div>
  );
};

export default DriverColumn;
