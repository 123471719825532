import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import { Validation } from '../../../utils/commonFunctions';
import { ValidCase, Validator } from '../../../components/validator';
// const paymentMethods = [1, 2];

function PaymentMethodSelect(props) {
  // only support Cash, Credit, Direct Invoicing
  const [paymentMethods, setPaymentMethods] = useState([1]);

  useEffect(() => {
    if (props.paymentMethods) {
      let personalCard = props.paymentMethods.find(m => m.value === 2);
      if(personalCard){
        setPaymentMethods([...paymentMethods, personalCard.value]);
      };
    };
  }, [props.paymentMethods]);

  const handleChange = (e) => {
    props.onChange(parseInt(e.target.value))
  };

  return (
    <FormGroup
      className={
        !props.isSubmited ? null : props.valid.payment_method === false ? 'error' : null
      }
    >
      <FormControl
        as="select"
        placeholder="select"
        className={'form-custom'}
        onChange={handleChange}
        value={props.value}
        style={{ marginBottom: 0 }}
        disabled={props.disabled}
      >
        <option value={0}>
          {I18n.t(`newbooking.Choose_a_payment_method`)}
        </option>
        {_.compact([...new Set([...paymentMethods, props.value])]).map(payment => {
          return (
            <option key={payment} value={payment}>
              {I18n.t(`General.patmentMethod_${payment}`)}
            </option>
          );
        })}
      </FormControl>
      <Validator
        callback={props.validatorCallback}
        id="payment_method"
      >
        <ValidCase
          valid={_.compact([...new Set([...paymentMethods, props.value])]).includes(props.value)}
          message={I18n.t('messages.commonMessages.Required_field')}
          hide={!props.isSubmited}
        />
      </Validator>
    </FormGroup>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    paymentMethods: state.paymentMethod.individualTypes,
    carType: state.intercityBooking.common.carType,
    routes: state.intercityBooking.common.routes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodSelect);
