import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import moment from 'moment';
import { Translate, I18n } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';
import _ from 'lodash';
import {
  FormGroup,
  FormControl,
  Button,
  ButtonToolbar,
  ButtonGroup,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { BeatLoader } from 'react-spinners';
import Confirm from '../../components/confirm/Confirm';
import AddEdit from './AddEdit';
import Import from './Import';
import { browserHistory } from 'react-router'
import StickyTable from '../../components/table/stickyTable/StickyTable';
import DateTime from '../../components/dateTime/DateTime';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import { Columns, CustomerActionMenuItem } from './tableHeaderColumns';
import TableActions from '../../components/table/tableAction/TableActions';
import {
  deleteCustomer,
  activateCustomer,
  getCustomer,
  editCustomer,
  addCustomer,
  findOneCustomer,
  exportCustomerToExcel,
  countCustomers
} from '../../actions/customerAction';
import CCDropDown from '../../components/dropdown/ccDropDown';
import ExportComponent from '../../components/ExportComponent';
import { CustomerSearchBy } from '../../constants/commondata';
import { handleResultExport } from '../../utils/commonFunctions';
import './customer.scss';
import TotalBalance from './components/TotalBalance';

import DateRange from './components/DateRange';
import TotalCustomer from './components/TotalCustomer';
import { BsSearch } from 'react-icons/bs';

require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

const CONFIRM_DELETE_ID = 1;
const CONFIRM_ACTIVATE_ID = 2;
const CONFIRM_DEACTIVATE_ID = 3;
const CONFIRM_DELETE_LINK_ID = 4;
const CONFIRM_EXPORT_ID = 5;
let deleteUserId;
class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailItem: null,
      activePage: 1,
      confirm: null,
      confirmDeactive: null,
      sortType: -1,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
        active: null,
        name: I18n.t('customer.Customers')
      },
      str: '',
      searchBy: CustomerSearchBy[0].value,
      selectedButton: 'custom',
      isLoadingFooter: true,
      incurredCustomer: 0,
      migrationStatus: 'all',
    };
    if (_.get(props.auth, 'selectedFleet.customerConfig.bigData')) {
      this.state.selectedButton = 'past7Days';
      this.state.startDate = moment()
        .subtract(6, 'days')
        .startOf('day')
        .toDate();
      this.state.endDate = moment()
        .endOf('day')
        .toDate();
    }

    this.getListCustomers = this.getListCustomers.bind(this);
    this.handleResultExport = handleResultExport.bind(this);
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchKeyPress = this.handleSearchKeyPress.bind(this);
    this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.handleSelectRowClick = this.handleSelectRowClick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleChangeBalance = this.handleChangeBalance.bind(this);
    this.changeCustomerStatus = this.changeCustomerStatus.bind(this);
    this.handleChangeAllCustomerStatus = this.handleChangeAllCustomerStatus.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSendMessageClick = this.handleSendMessageClick.bind(this);
    this.handleImportClick = this.handleImportClick.bind(this);
    this.handleConfirmCloseClick = this.handleConfirmCloseClick.bind(this);
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
    this.handleSortCustomerClick = this.handleSortCustomerClick.bind(this);
    this.handleDateStartDateChange = this.handleDateStartDateChange.bind(this);
    this.handleDateEndDateChange = this.handleDateEndDateChange.bind(this);
    this.handleDateApplyClick = this.handleDateApplyClick.bind(this);
    this.handleDateClearClick = this.handleDateClearClick.bind(this);
    this.processEditCustomer = this.processEditCustomer.bind(this);
    this.isFilteredDayValid = this.isFilteredDayValid.bind(this);
  }

  componentDidMount() {
    this.getListCustomers(0, null, true);
    if (this.props.routes[this.props.routes.length - 1].path === '/customer/add') {
      this.setState({
        detailItem: { isAdd: true, isShow: true, editable: true }
      });
    } else if (this.props.params.customerId) {
      this.processEditCustomer(this.props.params.customerId);
    }
  }

  getTableHeight = () => {
    const verticalPadding = 10;
    const toolbarMarginBottom = 10;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    const outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && tableHeight < 500) tableHeight = 550;
    this.state.tableHeight = tableHeight;
    return tableHeight;
  };

  getListCustomers(activePage, limit, countCustomer) {
    this.setState({
      isLoading: true,
      isLoadingFooter: countCustomer
    });
    const param = {
      limit: limit || this.state.footerData.limit,
      page: activePage || activePage === 0 ? activePage : this.state.footerData.page,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId
      }
    };
    if (this.state.sortName) {
      param.sort = { [this.state.sortName]: this.state.sortType };
    } else {
      param.sort = { createdDate: this.state.sortType };
    }
    if (this.state.startDate && this.state.endDate) {
      param.query.startDate = this.state.startDate.toLocalISOString(
        this.props.auth.selectedFleet.timezone
      );
      param.query.endDate = this.state.endDate
        .addDays(1)
        .toLocalISOString(this.props.auth.selectedFleet.timezone);
    }
    // if (this.state.migrationStatus && this.props.auth.selectedFleet.fleetId == "mycar") {
    //   param.query.migrationStatus = this.state.migrationStatus;
    // }

    if (this.state.companyId) {
      param.query.companyId = [this.state.companyId];
    };

    if (this.state.str) {
      param.query.str = this.state.str.trim();
      param.query.searchBy = this.state.searchBy;
      if (!activePage) {
        param.page = 0;
      }
    }
    this.props.getCustomer(param).then(data => {
      if (data.ok) {
        const { page, summary, limit } = data.res;
        if (summary) {
          this.setState({
            customer: data.res,
            footerData: {
              ...this.state.footerData,
              page,
              limit,
              total: summary.total,
              active: summary.totalActive
            },
            isLoadingFooter: false,
            isLoading: false
          });
        } else {
          this.setState({
            customer: data.res,
            footerData: { ...this.state.footerData, page, limit },
            isLoading: false
          }, () => {
            if (countCustomer) {
              this.loadTotalCustomer(param);
            }
          });
        }
      } else {
        this.setState({
          isLoading: false
        });
      }
    });
  }

  loadTotalCustomer = (params) => {
    this.props.countCustomers(params).then(data => {
      if (!data.error) {
        this.setState({
          isLoadingFooter: false,
          footerData: {
            ...this.state.footerData,
            total: data.res.total,
            active: data.res.totalActive
          }
        });
      }
    });
  }

  handleMenuClick(eventKey, detailItem) {
    if (eventKey == 0) {
      this.processEditCustomer(detailItem.userId);
    } else if (eventKey == 3) {
      this.processViewCustomer(detailItem.userId);
    } else if (eventKey == 1) {
      this.checkShowModalActiveCorporate([detailItem.userId], detailItem.isActive, detailItem)
    } else {
      deleteUserId = detailItem.userId;
      this.setState({
        confirm: {
          id: CONFIRM_DELETE_LINK_ID,
          title: I18n.t('customer.Delete'),
          body: I18n.t('customer.CONFIRM_DELETE'),
          buttonTitle: 'Yes',
          closeButtonText: 'No'
        }
      });
    }
  }

  handlePaginationSelect = page => this.getListCustomers(parseInt(page));

  handleNumItemsPerPageChange = limit => {
    this.getListCustomers(0, limit);
  };

  handleSelectRowClick(checked, id) {
    const listUser = _.cloneDeep(this.state.customer.list);
    _.forEach(listUser, user => {
      if (id === user._id) {
        user.checked = checked;
        return false;
      }
    });
    this.setState({
      customer: { ...this.state.customer, list: listUser }
    });
  }

  handleSelectAllClick = e => {
    const { target } = e;
    const listCust = _.cloneDeep(this.state.customer.list);
    _.forEach(listCust, user => {
      user.checked = target.checked;
    });
    this.setState({
      customer: { ...this.state.customer, list: listCust }
    });
  };

  closeDialog() {
    this.setState({
      detailItem: {
        isShow: false,
        isAdd: false,
        editable: false,
        firstName: null,
        lastName: null,
        phone: null,
        note: null,
        rank: null,
        email: null,
        userId: null,
        registerFrom: null,
        fleetId: null,
        outStanding: [],
        phoneValidation: null,
        referralCode: '',
        emailValidation: null,
        firstNameValidation: null,
        errorMessage: null,
        profile: {}
      },
      isShowImport: false
    });
    this.props.router.push('/customer');
  }

  handleChangeBalance() {
    this.handleDateApplyClick()
  };

  changeCustomerStatus(userIds, isActive) {
    this.props
      .activateCustomer(
        {
          userIds,
          fleetId: this.props.auth.selectedFleet.fleetId
        },
        isActive
      )
      .then(data => {
        if (data.ok) {
          const listUser = _.cloneDeep(this.state.customer.list);
          _.forEach(listUser, user => {
            if (userIds.includes(user.userId)) {
              user.isActive = !isActive;
            }
            user.checked = false;
          });
          const active = isActive
            ? this.state.footerData.active - data.res.nModified
            : this.state.footerData.active + data.res.nModified;
          this.setState({
            customer: {
              ...this.state.customer,
              list: listUser,
              active
            },
            confirmDeactive: null,
            footerData: { ...this.state.footerData, active }
          });
        } else {
        }
      });
  }

  handleSortCustomerClick(sortName) {
    this.setState(
      {
        sortName,
        sortType: this.state.sortType == 1 ? -1 : 1
      },
      function () {
        this.getListCustomers();
      }
    );
  }

  handleSearchChange(str) {
    this.setState({
      str
    });
  }

  handleSearchKeyPress(e) {
    if (e.key === 'Enter' && !this.state.isLoading) {
      this.getListCustomers(0, null, true);
    }
  }

  handleAddClick() {
    this.props.router.push('/customer/add');
    this.setState({
      detailItem: {
        isAdd: true, isShow: true, editable: true, profile: {}
      }
    });
  }

  processEditCustomer(customerId) {
    this.props
      .findOneCustomer({
        userId: customerId,
        fleetId: this.props.auth.selectedFleet.fleetId
      })
      .then(data => {
        if (data.ok && data.res) {
          // case open view from booking-detail report
          const onlyView = _.get(this.props.location, 'query.onlyView', '')
          if(onlyView !== 'TRUE') {
            this.props.router.push({
              pathname: `/customer/edit/${data.res.userId}`
            });
            data.res.editable = true;
          }
          data.res.isShow = true;
          this.setState({ detailItem: data.res });
        } else {
          this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
        }
      });
  }

  processViewCustomer = (customerId, isFormAddTSYSCard = false) => {
    this.props
      .findOneCustomer({
        userId: customerId,
        fleetId: this.props.auth.selectedFleet.fleetId
      })
      .then(data => {
        if (data.ok && data.res) {
          data.res.isShow = true;
          data.res.editable = false;
          if(!isFormAddTSYSCard) {
            this.props.router.push({
              pathname: `/customer/view/${data.res.userId}`
            });
          } else {
            data.res.isTSYS = isFormAddTSYSCard
          }
          this.setState({ detailItem: data.res });
        } else {
          this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
        }
      });
  }

  handleDateStartDateChange(e) {
    this.setState({ startDate: e._d });
    if (!this.state.endDate) {
      const today = new Date();
      this.setState({ endDate: today });
    }
  }

  handleDateEndDateChange(e) {
    this.setState({ endDate: e._d });
  }

  handleCompanyChange = companyId => {
    this.setState({ companyId });
  };

  handleDateApplyClick() {
    this.getListCustomers(0, null, true);
  }

  handleDateClearClick() {
    this.setState(
      {
        startDate: null,
        endDate: null,
        companyId: null,
        disableDatetimeSelection: false,
        selectedButton: 'custom'
      },
      () => {
        this.getListCustomers(0, null, true);
      }
    );
  }

  handleChangeAllCustomerStatus(isActive) {
    if (this.state.customer.list.filter(user => user.checked).length == 0) {
      this.setState({
        confirm: {
          title: isActive ? I18n.t('customer.Deactivate') : I18n.t('customer.Activate'),
          body: isActive
            ? I18n.t('customer.ALERT_SELECT_DEACTIVATE')
            : I18n.t('customer.ALERT_SELECT_ACTIVATE')
        }
      });
    } else {
      this.setState({
        confirm: {
          id: isActive ? CONFIRM_DEACTIVATE_ID : CONFIRM_ACTIVATE_ID,
          title: isActive ? I18n.t('customer.Deactivate') : I18n.t('customer.Activate'),
          body: isActive
            ? I18n.t('customer.CONFIRM_DEACTIVATE_MULTI')
            : I18n.t('customer.CONFIRM_ACTIVATE_MULTI'),
          buttonTitle: 'Yes',
          closeButtonText: 'No'
        }
      });
    }
  }

  handleDeleteClick() {
    if (this.state.customer.list.filter(user => user.checked).length == 0) {
      this.setState({
        confirm: {
          title: I18n.t('customer.Delete'),
          body: I18n.t('customer.ALERT_SELECT_DELETE')
        }
      });
    } else {
      this.setState({
        confirm: {
          id: CONFIRM_DELETE_ID,
          title: I18n.t('customer.Delete'),
          body: I18n.t('customer.CONFIRM_DELETE_MULTI'),
          buttonTitle: 'Yes',
          closeButtonText: 'No'
        }
      });
    }
  }

  handleSendMessageClick() {
    this.props.router.push({
      pathname: '/customer/message/'
    });
  }

  handleImportClick() {
    this.setState({ isShowImport: true });
  }

  prepareParamExport = () => {
    const query = { fleetId: this.props.auth.selectedFleet.fleetId };
    if (this.state.startDate && this.state.endDate) {
      let startDate = this.state.startDate.toLocalISOString(this.props.auth.selectedFleet.timezone)
      let endDate = this.state.endDate.addDays(1).toLocalISOString(this.props.auth.selectedFleet.timezone)
      query.startDate = moment(startDate).tz(this.props.auth.selectedFleet.timezone).format('YYYY[-]MM[-]DD');
      query.endDate = moment(endDate).tz(this.props.auth.selectedFleet.timezone).format('YYYY[-]MM[-]DD');

    }
    query.locale = localStorage.getItem('language');
    if (this.state.str) {
      query.str = this.state.str;
      query.searchBy = this.state.searchBy;
    }
    let sort = { createdDate: this.state.sortType };
    if (this.state.sortName) {
      sort = { [this.state.sortName]: this.state.sortType };
    }
    query.total = _.get(this.state, 'footerData.total', 0);
    query.referralCode = this.props.auth.selectedFleet.referral.paxReferPax ? "true" : "false";
    query.company = [this.state.companyId];
    query.showCompany = this.props.roles.companyId && this.props.roles.companyId !== '' ? "false" : "true";

    return { query }
  }

  handleConfirmCloseClick() {
    this.setState({ confirm: null });
  }

  handleConfirmButtonClick(id) {
    switch (id) {
      case CONFIRM_DELETE_ID:
        {
          if (this.state.customer.list) {
            const listUser = this.state.customer.list
              .filter(user => user.checked)
              .map(user => user.userId);
            if (listUser && listUser.length > 0) {
              this.props
                .deleteCustomer({
                  userIds: listUser,
                  fleetId: this.props.auth.selectedFleet.fleetId
                })
                .then(data => {
                  if (data.ok) {
                    // Deleted_customer_success
                    this.context.notification(
                      'success',
                      I18n.t('customer.Deleted_customer_success')
                    );
                    const deletedCustomerIds = data.res.map(o => o.userId)
                    const { footerData, customer, customer: { list } } = this.state;
                    this.setState({
                      customer: {
                        ...customer,
                        list: list.filter(o => deletedCustomerIds.indexOf(o._id) === -1),
                      },
                      footerData: {
                        ...footerData,
                        total: footerData.total - deletedCustomerIds.length
                      },
                      incurredCustomer: this.state.incurredCustomer - deletedCustomerIds.length
                    });
                  }
                });
            }
          }
        }
        break;
      case CONFIRM_ACTIVATE_ID:
        {
          if (this.state.customer.list) {
            const listUser = this.state.customer.list
              .filter(user => user.checked && !user.isActive)
              .map(user => user.userId);
            if (listUser && listUser.length > 0) {
              this.changeCustomerStatus(listUser, false);
            }
          }
        }
        break;
      case CONFIRM_DEACTIVATE_ID:
        {
          if (this.state.customer.list) {
            const listUser = this.state.customer.list
              .filter(user => user.checked && user.isActive)
              .map(user => user.userId);
            if (listUser && listUser.length > 0) {
              this.changeCustomerStatus(listUser, true);
            }
          }
        }
        break;
      case CONFIRM_DELETE_LINK_ID:
        {
          this.props
            .deleteCustomer({
              userIds: [deleteUserId],
              fleetId: this.props.auth.selectedFleet.fleetId
            })
            .then(data => {
              if (data.ok) {
                this.context.notification('success', I18n.t('customer.Deleted_customer_success'));

                const deletedCustomerIds = data.res.map(o => o.userId)
                const { footerData, customer, customer: { list } } = this.state;
                this.setState({
                  customer: {
                    ...customer,
                    list: list.filter(o => deletedCustomerIds.indexOf(o._id) === -1),
                  },
                  footerData: {
                    ...footerData,
                    total: footerData.total - deletedCustomerIds.length
                  },
                  incurredCustomer: this.state.incurredCustomer - deletedCustomerIds.length
                });
              }
            });
        }
        break;
      case CONFIRM_EXPORT_ID:
        {}
        break;
    }
    this.handleConfirmCloseClick();
  }

  isFilteredDayValid(current) {
    return !current.isAfter(DateTime.moment());
  }

  renderSelectBoxColumn = () => {
    let isCheckedAll = false;
    if (this.state.customer && this.state.customer.list && this.state.customer.list.length > 0) {
      isCheckedAll = _.filter(this.state.customer.list, item => item.checked).length
        === this.state.customer.list.length;
    }

    return {
      key: 'table-selectbox',
      label: '',
      width: 60,
      fixed: true,
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
      customHeader: columnKey => (
        <CcCheckbox
          className="table-selectbox all-checkbox"
          checked={isCheckedAll}
          onChange={this.handleSelectAllClick}
        />
      ),
      customCell: customer => (
        <CcCheckbox
          className="table-selectbox"
          checked={customer.checked}
          onChange={e => {
            this.handleSelectRowClick(e.target.checked, customer._id);
          }}
        />
      )
    };
  };

  handleConfirmDeactiveClick = (customerInfo) => {
    this.changeCustomerStatus([customerInfo._id], true);
  }

  handleDeactiveCloseClick = () => {
    this.setState({ confirmDeactive: null });
  }

  checkShowModalActiveCorporate = (userId, isActivate, customerInfo) => {
    if(_.get(customerInfo, 'corporateInfo.status', 0) === 2 && isActivate) {
      const corporateName = _.get(customerInfo, 'passengerInfo.corporateName', '')
      this.setState({
        confirmDeactive: {
          id: customerInfo,
          title: 'Confirm',
          body: `This customer has a business profile in ${corporateName}, do you want to deactivate this business account?`,
          buttonTitle: 'Yes',
          closeButtonText: 'No'
        }
      })
      return 
    }
    this.changeCustomerStatus(userId, isActivate);
  }

  getTableColumns = () => {
    let {auth} = this.props
    let isEnablePoint = _.get(auth,'selectedFleet.moduleSettings.point', false)
    let newColumns = auth.selectedFleet.fleetId === 'mycar'
      ? Columns
      : Columns.filter(function (col) { return col.key != "migrationStatus"; })
    if (!auth.selectedFleet.referral.paxReferPax) {
      newColumns = Columns.filter(function (col) { return col.key != "referralCode"; })
    }
    if(!isEnablePoint) {
      newColumns = Columns.filter(function (col) { return col.key != "loyalty.points.available"; })
    }
    const tableColumns = Object.assign([], newColumns);
    const { language: { locale: language }, commonData, roles } = this.props || {};
    // custom data by column
    const today = moment()
      .utcOffset(0)
      .startOf('day');

    _.forEach(tableColumns, col => {
      switch (col.key) {
        case 'fullNameSort':
          col.customCell = customer => (
            <a
              className="clickable"
              onClick={() => {
                this.handleMenuClick('3', customer);
              }}
            >
              {customer.firstName}
              {' '}
              {customer.lastName}
            </a>
          );
          break;
        case 'phone':
          col.customCell = customer => {
            var showPhone = customer.phone === customer.userId ? "" : customer.phone;
            return (
              <div>
                {showPhone}
              </div>
            )
          };
          break;
        case 'createdDate':
          col.customCell = customer => {
            customer.language = language;
            return (
              <Moment
                tz={this.props.auth.selectedFleet.timezone}
                format="MM/DD/YYYY"
                date={customer.createdDate}
                locale={customer.language}
              />
            )
          };
          break;

        case 'rank':
          col.customCell = customer => (customer.rank === 1 ? <i className="fa fa-check fa-2x activateIcon" /> : '');
          break;

        case 'passengerInfo.companies':
          col.customCell = customer => {
            let companies = customer.passengerInfo &&
              customer.passengerInfo.companies &&
              customer.passengerInfo.companies.length > 0
              ? customer.passengerInfo.companies.map(item => {
                let nameMapping = [...commonData.companies, ...commonData.suppliers].find(c => c._id === item.companyId);
                return nameMapping && nameMapping.name;
              }).join(', ')
              : '';
            return (
              <OverlayTrigger
                placement="top"
                delayShow={300}
                overlay={
                  <Tooltip id={`zone_tooltip_${customer._id}`}>
                    <p>
                      {companies}
                    </p>
                  </Tooltip>
                }
              >
                <span className="cursor-pointer">
                  {companies}
                </span>
              </OverlayTrigger>
            )
          };
          break;

        case 'lastActive':
          col.customCell = customer => {
            customer.language = language;
            return (customer.lastActive ? (
              <Moment
                tz={this.props.auth.selectedFleet.timezone}
                format="MMM DD, YYYY hh:mm A"
                date={customer.lastActive}
                locale={customer.language}
              />
            ) : (
              'N/A'
            ))
          };
          break;
        case 'verifications':
          col.customCell = customer => ((customer.verifications && customer.verifications.length > 0)
            ? customer.verifications.join(", ")
            : 'SMS');
          break;

        case 'DaySincelastActive':
          col.customCell = customer => (customer.lastActive
            ? Math.round(moment.duration(today - moment(customer.lastActive)).asDays())
            : 'N/A');
          break;

        case 'booking.completed':
          col.customCell = customer => (customer.booking && customer.booking.completed ? customer.booking.completed : '0');
          break;
        case 'customerStatus':
          col.customCell = customer => (
            <div className={customer.isActive ? 'Active' : 'Inactive'}>
              {!this.props.permissions || this.props.permissions.actions ? (
                <a
                  onClick={() => {
                    this.checkShowModalActiveCorporate([customer.userId], customer.isActive, customer)
                  }}
                  href="javascript:void(0)"
                >
                  {customer.isActive ? (
                    <Translate value="customer.Active" />
                  ) : (
                    <Translate value="customer.Inactive" />
                  )}
                </a>
              ) : customer.isActive ? (
                <Translate value="customer.Active" />
              ) : (
                <Translate value="customer.Inactive" />
              )}
            </div>
          );
          break;
        case 'paxWallet':
          col.customCell = customer => {
            const paxWallet = _.get(customer, 'paxWallet', []).filter(o => o.value);
            const text = paxWallet
              .map(o => currencyFormatter.format(o.value, {
                code: o.currencyISO
              }))
              .join('; ');
            return text || 'N/A';
          };
          break;
        case 'outStanding':
          col.customCell = customer => {
            const outStanding = _.get(customer, 'outStanding', []).filter(o => o.amount > 0);
            return outStanding
              .map(o => currencyFormatter.format(o.amount, {
                code: o.currencyISO
              }))
              .join('; ');
          };
          break;

        case 'actions':
          col.customCell = (customer, rowIndex) => {
            const status = customer.isActive ? 'Active' : 'Inactive';
            const actions = Object.assign([], CustomerActionMenuItem[status]);
            const { selectedFleet } = this.props.auth;
            const allowDeletePax = _.get(selectedFleet, 'generalSetting.allowDeletePax', false);
            if (!customer.isActive && (allowDeletePax || customer.registerFrom !== 'App')) {
              actions.push({ label: 'customer.Delete', eventKey: 2 });
            }
            return (
              <div className="driver-actions-container">
                {!this.props.permissions || this.props.permissions.actions ? (
                  <TableActions
                    rowIndex={rowIndex}
                    rowData={customer}
                    onSelect={this.handleMenuClick}
                    totalRow={this.state.customer.list.length}
                    rowHeight={this.state.rowHeight}
                    menuItems={actions}
                    tableHeight={this.state.tableHeight}
                  />
                ) : (
                  <a onClick={() => this.handleMenuClick('3', customer)} href="javascript:void(0)">
                    <Translate value="customer.View" />
                  </a>
                )}
              </div>
            );
          };
          break;
        default:
          break;
      }
    });

    const checkBoxCol = this.renderSelectBoxColumn();
    tableColumns.unshift(checkBoxCol);

    /* remove columns not permission */
    const {
      auth: { selectedFleet }
    } = this.props;
    const removeColumns = [];
    if (!selectedFleet.paxCreditWallet) {
      removeColumns.push('paxWallet');
    };

    if (roles.companyId && roles.companyId !== '') {
      removeColumns.push('passengerInfo.companies');
    };

    return tableColumns.filter(c => removeColumns.indexOf(c.key) === -1);
  };

  handleSearchByChange = value => {
    this.setState({ searchBy: value });
  };

  handleChangeDateRange = params => {
    let { disableDatetimeSelection } = this.state;
    disableDatetimeSelection = params.selectedButton !== 'custom';
    if (params.selectedButton !== 'custom') {
      this.setState({
        startDate: params.fromDate,
        endDate: params.toDate,
        selectedButton: params.selectedButton,
        disableDatetimeSelection
      });
    } else {
      this.setState({
        selectedButton: params.selectedButton,
        disableDatetimeSelection
      });
    }
  }

  handleCustomDateSelect = () => {
    if (this.dateFromInput) {
      this.setState({ showDateFrom: true }, () => this.dateFromInput.focus());
    }
  }

  isDisabledDeleteCustomers = () => {
    const { selectedFleet } = this.props.auth;
    const allowDeletePax = _.get(selectedFleet, 'generalSetting.allowDeletePax', false);
    const customers = _.get(this.state, 'customer.list', []);
    const customersCanNotDelete = customers.filter(customer => (
      customer.checked
      && (customer.isActive || (!allowDeletePax && customer.registerFrom === 'App'))
    ));
    return customersCanNotDelete.length > 0;
  };

  handleAddEditCustomerSuccess = (isAdd, newCustomer) => {
    if (isAdd && newCustomer?._id) {
      this.setState({
        customer: {
          ...this.state.customer,
          list: [
            newCustomer,
            ...(this.state?.customer?.list || []),
          ],
          total: this.state.total + 1
        },
        footerData: {
          ...this.state.footerData,
          total: this.state?.footerData?.total + 1
        },
        incurredCustomer: this.state.incurredCustomer + 1 
      });
    } else {
      this.getListCustomers();
    }
  }

  handleMigrationStatusChange = newValue => {
    this.setState({ migrationStatus: newValue })
  }

  render() {
    const bodyData = this.state.customer && this.state.customer.list ? this.state.customer.list : [];
    const { auth: { selectedFleet }, roles } = this.props;
    const ableToDownloadAccount = _.get(selectedFleet, 'generalSetting.ableToDownloadAccount', true);

    /* SL-9695 Hide "Export to excel" button for Tappcar fleet */
    /* let ignoreExport = false;
    if (ignoreExportCustomer) {
      const ignoreFleets = ignoreExportCustomer.split(';');
      ignoreExport = ignoreFleets.indexOf(selectedFleet.fleetId) !== -1;
    } */
    const { permissions = null, auth: { user } = {}, language: { locale: language } } = this.props || {};
    const { actions = false } = permissions || {};
    var exportPermission = user.isAdmin ? true : permissions.export;
    if (!user.isAdmin && selectedFleet.fleetId === "applecabssouth") {
      exportPermission = false;
    }
    const totalBalancePermission = user.isAdmin ? true : permissions.totalbalance;
    const isShowInbox = user.isAdmin
      ? true
      : (permissions.sendinbox || permissions.sendsms) ? true : false;

    const companies = _.cloneDeep([...this.props.commonData.companies, ...this.props.commonData.suppliers]);

    return (
      <div className="content content-cus">
        <div ref={node => (this.toobarContainer = node)} className="mb">
          <ButtonToolbar className="text-center header-button-group form-cus">
            <ButtonGroup className="group-left">
              <FormGroup className="search-format mb0 search-by">
                <div className="input-container">
                  <FormControl
                    className="search-form"
                    type="text"
                    placeholder={I18n.t('General.search')}
                    onChange={e => this.handleSearchChange(e.target.value)}
                    onKeyPress={this.handleSearchKeyPress}
                    disabled={this.state.isLoading}
                  />
                  {this.state.isLoading ? (
                    <i className="fa fa-circle-o-notch fa-spin spinner-icon" />
                  ) : (
                    <BsSearch className="search-icon" />
                  )}
                </div>
                <CCDropDown
                  id="customer-search-by-dropdown"
                  title=""
                  items={CustomerSearchBy}
                  selectedItems={[this.state.searchBy || CustomerSearchBy[0].value]}
                  valueKey="value"
                  labelKey="label"
                  onSelect={this.handleSearchByChange}
                  className="search-by-dropdown"
                  disabled={this.state.isLoading}
                  pullRight
                />
              </FormGroup>
              {
                (permissions && actions) && (
                  <React.Fragment>
                    <Button className="btn-header text-add-header" onClick={this.handleAddClick}>
                      <Translate value="customer.Add" />
                    </Button>
                    <Button
                      className="btn-header text-delete-header"
                      onClick={this.handleDeleteClick}
                      disabled={this.isDisabledDeleteCustomers()}
                    >
                      <Translate value="customer.Delete" />
                    </Button>
                    <Button
                      className="btn-header text-active-header"
                      onClick={() => {
                        this.handleChangeAllCustomerStatus(false);
                      }}
                    >
                      <Translate value="customer.Activate" />
                    </Button>
                    <Button
                      className="btn-header text-deactive-header"
                      onClick={() => {
                        this.handleChangeAllCustomerStatus(true);
                      }}
                    >
                      <Translate value="customer.Deactivate" />
                    </Button>
                  </React.Fragment>
                )
              }
            </ButtonGroup>
            <ButtonGroup className="group-right">
              {permissions && totalBalancePermission ? <TotalBalance /> : null}
              {
                (permissions && actions) && <Button className="btn-header text-add-header" onClick={this.handleImportClick}>
                  <Translate value="customer.Import" />
                </Button>
              }
              {isShowInbox ? (
                (permissions && actions) ? <Button
                  className="btn-header text-add-header"
                  onClick={this.handleSendMessageClick}
                >
                  <Translate value="driver.Send_Message" />
                </Button> :
                  <Button
                    className="btn-header text-add-header"
                    onClick={this.handleSendMessageClick}
                  >
                    <Translate value="driver.View_Message" />
                  </Button>
              ) : null}
              {permissions && exportPermission
                && ableToDownloadAccount
                && (
                  <ExportComponent 
                    prepareParamExport={this.prepareParamExport}
                    urlExport={this.props.exportCustomerToExcel}
                    user={this.props.auth.user}
                  />
                )
              }
            </ButtonGroup>
          </ButtonToolbar>
          <div className="customer-toolbar-extend">
            <div className="toolbarExtend--leftContent">
              <div className="mb0">
                <DateRange
                  selectedButton={this.state.selectedButton}
                  setDateRange={this.handleChangeDateRange}
                  customRangeSelectCallback={this.handleCustomDateSelect}
                />
              </div>
              <FormGroup className="mb0">
                <DateTime
                  value={moment(this.state.startDate).locale(language)}
                  timeFormat={false}
                  onChange={this.handleDateStartDateChange}
                  inputProps={{
                    readOnly: true,
                    className: 'date-readonly form-control',
                    placeholder: I18n.t('customer.Registration_date_from'),
                    value: this.state.startDate ? moment(this.state.startDate).locale(language).format('L') : '',
                    disabled: this.state.disableDatetimeSelection
                  }}
                  isValidDate={this.isFilteredDayValid}
                  closeOnSelect
                />
              </FormGroup>
              <FormGroup className="mb0">
                <DateTime
                  value={moment(this.state.endDate).locale(language)}
                  timeFormat={false}
                  onChange={this.handleDateEndDateChange}
                  inputProps={{
                    readOnly: true,
                    className: 'date-readonly form-control',
                    placeholder: I18n.t('customer.Registration_date_to'),
                    value: this.state.endDate ? moment(this.state.endDate).locale(language).format('L') : '',
                    disabled: this.state.disableDatetimeSelection
                  }}
                  isValidDate={this.isFilteredDayValid}

                  closeOnSelect
                />
              </FormGroup>
              {/* {this.props.auth.selectedFleet.fleetId == "mycar"
              && <CCDropDown
                  id="car-type-dropdown"
                  items={migrationStatus}
                  title={I18n.t("cue.Migration_Status")}
                  selectedItems={this.state.migrationStatus}
                  labelKey="label"
                  valueKey="value"
                  onSelect={this.handleMigrationStatusChange}
                />
              } */}

              {!roles.companyId &&
                <CCDropDown
                  id="company-dropdown"
                  items={companies}
                  title={I18n.t('report.query.company')}
                  selectedItems={this.state.companyId || []}
                  valueKey="_id"
                  labelKey="name"
                  onSelect={this.handleCompanyChange}
                  noTranslateLabel
                  searchable
                  enableClearAll
                />
              }

              <Button className="btn-header text-add-header" onClick={this.handleDateApplyClick}>
                <Translate value="customer.Apply" />
              </Button>
              <Button
                className="btn-header text-deactive-header"
                onClick={this.handleDateClearClick}
              >
                <Translate value="customer.Clear" />
              </Button>
            </div>
            <div>
              <TotalCustomer incurredCustomer={this.state.incurredCustomer} />
            </div>
          </div>
        </div>

        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            sortHandle={this.handleSortCustomerClick}
            sortType={this.state.sortType}
            sortName={this.state.sortName}
            getTableHeight={this.getTableHeight}
            footerLoading={this.state.isLoadingFooter}
            language={language}
          />
        </div>
        <AddEdit
          detailItem={this.state.detailItem}
          onSuccess={this.handleAddEditCustomerSuccess}
          closeDialog={this.closeDialog}
          permission={this.props.permission}
          router={this.props.router}
          handleChangeBalance={this.handleChangeBalance}
          processViewCustomer={this.processViewCustomer}
        />
        <Import
          isShow={this.state.isShowImport}
          onSuccess={this.getListCustomers}
          closeDialog={this.closeDialog}
          permission={this.props.permission}
        />
        {/* confirm deactive */}
        <Confirm
          confirm={this.state.confirmDeactive}
          handleConfirmButtonClick={this.handleConfirmDeactiveClick}
          handleConfirmCloseClick={this.handleDeactiveCloseClick}
        />
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    );
  }
}

Customer.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    roles: state.auth.user.roles,
    language: state.i18n,
    permissions: state.menuHandle.modulePermission,
    commonData: state.commonData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCustomer: options => dispatch(getCustomer(options)),
    editCustomer: options => dispatch(editCustomer(options)),
    addCustomer: options => dispatch(addCustomer(options)),
    findOneCustomer: options => dispatch(findOneCustomer(options)),
    exportCustomerToExcel: options => dispatch(exportCustomerToExcel(options)),
    deleteCustomer: listUserId => dispatch(deleteCustomer(listUserId)),
    activateCustomer: (listUserId, isActivate) => dispatch(activateCustomer(listUserId, isActivate)),
    countCustomers: options => dispatch(countCustomers(options))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer);
