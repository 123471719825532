export const pickupDropoff = [
    'Pick up',
    'Drop off'
];

export const greenGroup = [
    'Pick up',
    'Accept',
    'Arrived',
    'Arrive',
    'arrived',
    'Arrived to buyer',
    'Arrived to sender',
    'Arrived to merchant',
    'Collected goods from merchant',
    'Collected goods from sender',
    'Arrived to recipient',
    'On my way',
    'Complete',
    'Accepted',
]

export const redGroup = [
    'Drop off',
    'Delivered',
    'droppedOff'
]

export const greyGroup = [
    'Canceled by timeout',
    'Cancel booking',
    'Cancel',
    'No show',
    'Incident',
    'Reject',
    'Ignore',
    'Mark as failed',
    'Deny',
    'Affiliate deny',
    'Affiliate reject',
    'Rejected',
    'Cancel by driver',
    'Cancel by Passenger'
]

export const orangeGroup = [
    'Driver on the way',
    'otwMerchant',
    'Driver on the way to sender'
]

export const blueGroup = [
    'Passenger on board',
    'Delivering',
    'delivering',
]

export const purpleGroup = [
    'Dispatching'
]

