import React, { Component } from 'react';
import BookingMobileItem from './BookingMobileItem';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

class BookingMobile extends Component {

  getMoreBooking = () => {
    this.props.scrollBottomCallback()
  }

  render() {
    const {
      bookingList = [],
      lineShort,
      selectedTab,
      checkedGroup,
      isEnableGroupSetting,
      checkEnableSelectMultiBook,
      handleClickGroup,
      auth,
      handleAssignDriverOneBooking,
      handleAcceptBooking,
      scrollBottomCallback,
      hancleViewDetailBooking,
      getTableHeight,
      ...props
    } = this.props;

    return (
      <BottomScrollListener
        onBottom={this.getMoreBooking}
      >
        {(scrollRef) => (
            <div
              id="scrollableCueMobile"
              style={{
                height: '70vh',
                minHeight: '400px',
                maxHeight: '800px',
                overflow: 'auto',
              }}
              ref={scrollRef}
            >
              {bookingList.map((bk) => (
                <BookingMobileItem
                  key={bk.bookId}
                  hancleViewDetailBooking={hancleViewDetailBooking}
                  booking={bk}
                  lineShort={lineShort}
                  selectedTab={selectedTab}
                  checkedGroup={checkedGroup}
                  isEnableGroupSetting={isEnableGroupSetting}
                  checkEnableSelectMultiBook={checkEnableSelectMultiBook}
                  handleClickGroup={handleClickGroup}
                  auth={auth}
                  handleAssignDriverOneBooking={(e, book) => {
                    e?.stopPropagation();
                    handleAssignDriverOneBooking(book);
                  }}
                  handleAcceptBooking={handleAcceptBooking}
                />
              ))}
            </div>
          )}
      </BottomScrollListener>
    );
  }
}

export default BookingMobile