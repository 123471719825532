import React, { useState } from 'react';

const VehicleColumn = ({
  booking,
  lineShort,
  isMobile,
  handleAssignDriverOneBooking,
  isCueFinished
}) => {
  if (isMobile) {
    return (
      <div
        className="info-item vehicle"
        onClick={(e) => handleAssignDriverOneBooking(booking, e)}
      >
        {booking.request.vehicleTypeRequest}
      </div>
    );
  }
  return (
    <div
      className={`vehicle-info ${lineShort ? ' custom__lineShort' : ''}`}
      style={{ cursor: `${isCueFinished ? '' : 'pointer'}`}}
      onClick={(e) => handleAssignDriverOneBooking(booking, e)}
    >
      <p className={'text-ellipsis'}>
        {booking?.vehicle?.vehicleType || booking?.request?.vehicleTypeRequest}
      </p>
      {!lineShort && (
        <p className={'text-ellipsis'}>
          {booking?.vehicle?.plateNumber
            ? booking?.vehicle?.plateNumber
            : booking?.drvInfo?.plateNumber
            ? booking?.drvInfo?.plateNumber
            : '---'}
        </p>
      )}
    </div>
  );
};

export default VehicleColumn;
