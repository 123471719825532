import { STATUS_BEFORE_START } from "../../constants/commondata"

export const verifyStatusShowUnassignAllBtn = (books = []) => {
  if(!(books?.length > 0)) return false
  if(books.every(bk => !STATUS_BEFORE_START.includes(bk.status))) return false

  if(books?.length === 1) {
    return STATUS_BEFORE_START.includes(books[0].status)
  }

  return true
}