import React, { PureComponent, Fragment } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import _ from 'lodash';

class ConfirmUpdateModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {}
  }

  closeModal = () => {
    this.props.onClose()
  }

  render() {
    const {
      bookInfo,
      data,
      visible,
      handleUpdateInfoAndDispatch
    } = this.props;
    const propsDriver = _.get(bookInfo, 'drvInfo.phone');
    const stateDriver = _.get(data, 'drvInfo.phone');
    let isChangeDriver = false;
    if (propsDriver) {
      if (data.dispatchType === 1) {
        isChangeDriver = propsDriver !== stateDriver;
      }
    };
    return (
      <Modal
        show={visible}
        backdrop
        dialogClassName="confirm-dialog update-book-detail-modal"
        onHide={this.closeModal}
        className={'confirm'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="bookingdetail.CONFIRM_UPDATE" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Translate value="bookingdetail.CONFIRM_UPDATE_MESSAGE" />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.closeModal} className="btn-reset">
            <Translate value="bookingdetail.No" />
          </Button>
          <Button onClick={this.props.onUpdate} className="btn-save" disabled={isChangeDriver}>
            {isChangeDriver ? (
              <OverlayTrigger
                overlay={<Tooltip id="Update_info"><Translate value="bookingdetail.Update_info_tooltip" /></Tooltip>}
                placement="top"
                delayShow={300}
                delayHide={150}
              >
                <span className="dropdown-title"><Translate value="bookingdetail.Update_info" /></span>
              </OverlayTrigger>
            ) : (
              <Translate value="bookingdetail.Update_info" />
            )}          </Button>
          <Button
            className="btn-save"
            onClick={handleUpdateInfoAndDispatch}
          >
            <Translate value="bookingdetail.Update_info_dispatch" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmUpdateModal;
