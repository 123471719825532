/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Translate, I18n } from 'react-redux-i18n';
import './newbooking.scss';
import './newDeliveryBooking.scss';
import {
  Col, Row, Button, ButtonToolbar, Modal, Container
} from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import currencyFormatter from 'currency-formatter';
import { QQWebservice, QQUltis } from '../../components/qqMap';
import {
  convertDirectionParam,
  getChannelMap,
  findDriverNearest,
  getDirectionMapProviderFromJupiter
} from '../../utils/mapUtils.js';
import DateMode from '../../components/DateModeBooking'
import RouteInfo from '../../components/RouteInfo'

import * as newbookingActions from '../../actions/newbookingAction';
import * as deliveryAction from '../../actions/deliveryAction';
import * as customerAutocompleteActions from '../../actions/customerAutocompleteAction';
import * as corporateCompanyActions from '../../actions/corporateCompanyAction';
import * as paymentMethodActions from '../../actions/paymentMethodActions';
import * as driverAutocompleteActions from '../../actions/driverAutocompleteActions';
import * as etaFareActions from '../../actions/etaFareActions';
import * as creditCardActions from '../../actions/creditCardActions';
import * as queuingAreaActions from '../../actions/queuingAreaActions';
import * as customerActions from '../../actions/customerAction';
import * as messageAction from '../../actions/messageAction';
import { socketConfigs } from '../../constants/socketConfigs';
import { socketDispatchApi } from '../../utils/socketUtils';
import * as cueActions from '../../actions/cueActions';
import * as uploadActions from '../../actions/uploadActions';
import * as settingActions from '../../actions/settingActions';

import {
  CCLiteCommonFunc,
  Validation,
  trimPhoneNumber,
  filterCompanyList,
  checkDropOffCountry,
  regexpDriverAutoComplete,
  filterDeliveryCarTypes
} from '../../utils/commonFunctions';
import {
  paymentGetwayDisabledNewBooking,
  userType,
  locationType,
  travelerType,
  paymentMethodNumber,
  NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD,
  SUPPLIER_TYPE,
  PROVIDER_MAP_TYPE
} from '../../constants/commondata';
import * as loadingActions from '../../actions/loadingBarActions';
import * as corporateActions from '../../actions/corporateAction';
import * as tencentMapActions from '../../actions/tencentMapActions';
import {
  BookingMapChauffeur,
  BookingInfoDelivery,
  DispatchInfoDelivery,
  PromoInfo,
  PaymentInfo,
  TripEstimateDelivery
} from '../../components/bookingDetail';

import {
  calculateFareWithPromoChange,
  checkLocationHasChange,
  filterPointEmpty,
  getLocationWhenDrapEnd,
  onChangePickUpHour,
  onChangePickUpMin
} from '../../components/bookingDetail/bookFunction/bookingInfo';
import {
  newDeliveryBookingEtaMultiFareCalculator,
  getMenuDataFromRecipient,
  getTravelerModeByCarType,
  isCanAssignOfflineDriver,
  checkCashOnDelivery
} from '../../components/bookingDetail/bookFunction/serviceRequest';
import { onSelectAdditionalService } from '../../components/bookingDetail/bookFunction/additionalService';
import tzlookup from '../../components/tzlookup/tz-lookup';
import Confirm from '../../components/confirm/Confirm';
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle';
import TrailNotesNewBooking from '../../components/bookingDetail/bookComponent/trailNotes/trailNoteNewBooking.js';

require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

class NewBooking extends Component {
  constructor(props) {
    super();
    this.state = {
      data: {
        note: '',
        rideSharing: true,
        operatorNote: '',
        phone: '',
        firstname: '',
        lastname: '',
        email: '',
        outStanding: [],
        traveler_type:
          props.auth.user.userType == userType.FleetAdmin
            || props.auth.user.userType == userType.FleetUser
            ? travelerType.Individual
            : travelerType.Corporate,
        dispatch_type: 0,
        duration: false,
        carType: '',
        driver: '',
        time: 'Now',
        zipCodeFrom: '',
        zipCodeTo: '',
        duration: false,
        isCustomerVip: false,
        dispatch3rd: props.auth.selectedFleet.partyDispatching || false,
        paymentMethod: 0
      },
      phoneStatus: true,
      customer: {
        credits: []
      },
      cartypeSelected: null,
      addressPickUp: '',
      addressDestination: '',
      locationPickUp: null,
      locationDestination: '',
      addressDetailsPickUp: {},
      addressDetailsDestination: {},
      geocodeResultsPickUp: null,
      loadingPickUp: false,
      loadingDestination: false,
      center: null,
      markerPickUp: null,
      markerDestination: null,
      suggestions: [],
      driversuggestions: [],
      driver: {},
      optionAutocomplete: {},
      promoAplied: false,
      isShowCancelMessage: false,
      etaFare: null,
      valid: {},
      isSubmited: false,
      mapDefaultBound: null,
      pickupFrom: null,
      destinationFrom: null,
      driverCompany: null,
      destinationRecentThirdParty: false,
      isCheckSupportLocation: false,
      dateMode: 'single',
      weekDays: {
        sun: true,
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true
      },
      puPoints: [{ order: 1, id: new Date().getTime(), hasLocation: false }],
      doPoints: [{ order: 2, id: new Date().getTime(), hasLocation: false }],
      fromDate: '',
      toDate: '',
      recurringTime: '',
      recurringDays: [],
      trailNotes: [],

    };
    this.checkLocationHasChange = checkLocationHasChange.bind(this)
    this.onChangePickUpHour = onChangePickUpHour.bind(this);
    this.onChangePickUpMin = onChangePickUpMin.bind(this);
    this.etaFareCalculator = newDeliveryBookingEtaMultiFareCalculator.bind(this);
    this.getMenuDataFromRecipient = getMenuDataFromRecipient.bind(this);
    this.onSelectAdditionalService = onSelectAdditionalService.bind(this);
    this.onPickupTimeFocus = this.onPickupTimeFocus.bind(this);
    this.handleAddCardSuccess = this.handleAddCardSuccess.bind(this);
    this.handleChangeETAFare = this.handleChangeETAFare.bind(this);
    this.handleChangeMarkupPrice = this.handleChangeMarkupPrice.bind(this);
  }

  handleAddTrailNotes = (data) => {
    let currentTrailNotes = [...this.state.trailNotes]
    if (currentTrailNotes?.length === 20) return this.context.notification('error', I18n.t('trailNotes.maxComments'))
    const newTrailNote = {
        availability: {
          operator: data.operator,
          driver: data.driver,
          passenger: data.passenger,
        },
        text: data.text,
        files: data.files,
        createdBy: {
          firstName: this.props.auth.user.firstName,
          lastName: this.props.auth.user.lastName,
          userName: this.props.auth.user.userName,
          _id: this.props.auth.user._id
        },
    }
    currentTrailNotes = [newTrailNote, ...currentTrailNotes]
    this.setState({
      trailNotes: currentTrailNotes
    });
  }

  handleDeleteTrailNotes = (index) => {
    const currentTrailNotes = [...this.state.trailNotes]
    currentTrailNotes.splice(index, 1)
    this.setState({
      trailNotes: currentTrailNotes
    });
  }


  UNSAFE_componentWillMount() {
    let { auth } = this.props
    let moduleSettings = _.get(auth, 'selectedFleet.moduleSettings')
    let center = null;
    let bound = null;
    if (this.props.commonData.location && this.props.commonData.location.isChina) {
      var latLng = new window.qq.maps.LatLngBounds();
      let defaultMap = (this.props.commonData.mapZone || []).filter(m => m.isDefault)[0];
      defaultMap = defaultMap
        || (this.props.commonData
          && this.props.commonData.mapZone
          && this.props.commonData.mapZone[0]);
      if (defaultMap) {
        defaultMap.geo.coordinates.map(cor => {
          cor.map(z => {
            latLng.extend(new window.qq.maps.LatLng(z[1], z[0]));
          });
        });
      }
      const center = latLng.getCenter();
      let bound = null;
      if (
        (this.props.commonData.mapZone || []).filter(m => m.isDefault)[0]
      ) {
        bound = new window.qq.maps.Circle({
          radius: 100000,
          center
        }).getBounds();
      }
    } else {
      var latLng = new google.maps.LatLngBounds();
      let defaultMap = (this.props.commonData.mapZone || []).filter(m => m.isDefault)[0];
      defaultMap = defaultMap
        || (this.props.commonData
          && this.props.commonData.mapZone
          && this.props.commonData.mapZone[0]);
      if (defaultMap) {
        defaultMap.geo.coordinates.map(cor => {
          cor.map(z => {
            latLng.extend(new google.maps.LatLng(z[1], z[0]));
          });
        });
      }
      center = latLng.getCenter();
      bound = null;
      if (
        (this.props.commonData.mapZone || []).filter(m => m.isDefault)[0]
      ) {
        bound = new google.maps.Circle({
          radius: 100000,
          center
        }).getBounds();
      }
    }

    if (
      this.props.auth.user.userType == userType.FleetAdmin
      || this.props.auth.user.userType == userType.FleetUser
    ) {
      if (moduleSettings.airlineBusiness) {
        this.props.corporateCompanyActions.corporateCompany(this.props.auth.selectedFleet.fleetId, true);
      } else if (moduleSettings.corporate) {
        this.props.corporateCompanyActions.corporateCompany(this.props.auth.selectedFleet.fleetId, false);
      }
    }

    const { user } = this.props.auth;
    const isPermissionCompany = !!user.roles.companyId;
    let driverCompany = null;
    if (isPermissionCompany) {
      driverCompany = _.find([...this.props.commonData.companies, ...this.props.commonData.suppliers], cp => cp._id === user.roles.companyId)
    }
    let centerLocation = `${center.lat()},${center.lng()}`
    this.setState({
      mapDefaultBound: bound,
      optionAutocomplete: {
        radius: '10000',
        location: centerLocation,
        fleetId: this.props.auth.selectedFleet.fleetId
      },
      data: { ...this.state.data },
      center,
      driverCompany
    });
    this.props.paymentMethodActions.paymentMethod(this.props.auth.selectedFleet.fleetId);
  }

  parseExistedBookingToNewBooking = data => {
    let bookingOj = {
      note: data.booking.request.note,
      operatorNote: data.booking.request.operatorNote,
      phone: data.booking.psgInfo.phone,
      firstname: data.booking.psgInfo.firstName || '',
      lastname: data.booking.psgInfo.lastName || '',
      email: data.booking.psgInfo.email || '',
      isCustomerVip: data.booking.psgInfo.rank === 1,
      traveler_type: data.booking.travelerType || travelerType.Individual,
      dispatch_type: 0,
      carType: data.booking.request.vehicleTypeRequest,
      driver: '',
      time:
        data.booking.request.pickUpTime == 'Now'
          ? 'Now'
          : data.booking.request.pickUpTime
          || moment.tz(data.booking.request.pickUpTime, data.booking.request.pickup.timezone),
    };
    this.state.data.dispatch3rd = !!data.booking.dispatch3rd;
    if (data.customer && data.customer.phone !== 'No Phone') {
      this.state.customer = data.customer;
      bookingOj = {
        ...bookingOj,
        firstname: data.customer.firstName,
        lastname: data.customer.lastName,
        phone: data.customer.phone === data.customer.userId ? "No Phone" : data.customer.phone,
        email: data.customer.email
      };
    } else {
      bookingOj = {
        ...bookingOj,
        firstname: '',
        lastname: '',
        phone: '',
        email: ''
      };
    }

    this.state.data.paymentMethod = 1;
    if (data.booking.request.paymentType !== 9 &&
      data.booking.request.paymentType !== 13
    ) {
      this.state.data.paymentMethod = data.booking.request.paymentType;
    }
    if (
      data.booking.request.paymentType == paymentMethodNumber.personalCard
      || data.booking.request.paymentType == paymentMethodNumber.corporateCard
    ) {
      // SL-17711 Need to clear credit token (customer have deleted) when rebooking
      const creditValue = data.credit;
      if (creditValue) {
        const findIndexCredit = this.state.customer.credits.findIndex(x => x.localToken === creditValue.localToken && x.crossToken === creditValue.crossToken)
        if (findIndexCredit !== -1) {
          this.state.credit = creditValue;
        };
        if (data.company && data.company.credits) {
          const findIndexCreditCompany = data.company.credits.findIndex(x => x.localToken === creditValue.localToken && x.crossToken === creditValue.crossToken)
          if (findIndexCreditCompany !== -1) {
            this.state.credit = creditValue;
          };
        };
      }
    }
    if (
      data.booking.pricingType != 1
      && (data.booking.request.paymentType == paymentMethodNumber.corporateCard
        || data.booking.request.paymentType == 5
        || data.booking.request.paymentType == 7)
      && data.booking.corporateInfo
    ) {
      this.state.data.clientCaseMatter = data.booking.corporateInfo.clientCaseMatter;
      this.state.data.chargeCode = data.booking.corporateInfo.chargeCode;
    }
    if (data.company) {
      this.state.company = data.company;
    }
    this.state.data.rideSharing = _.get(data, 'booking.rideSharing', true)
    if (data.booking.travelerType == 1 && data.booking.corporateInfo) {
      bookingOj.company = data.booking.corporateInfo.corporateId;
      this.state.customer.corporateSelectedInfo = this.state.customer.corporateInfo = data.booking.corporateInfo;
    }
    this.state.locationPickUp = {
      lat: data.booking.request.pickup.geo[1],
      lng: data.booking.request.pickup.geo[0],
      zipCode: data.booking.request.pickup.zipCode,
      city: data.booking.request.pickup.city || '',
      countryCode: data.booking.request.pickup.country || '',
      timezone: data.booking.request.pickup.timezone,
      from: data.booking.request.pickup.from,
      address: data.booking.request.pickup.address,
      cityName: data.booking.request.pickup.cityName
    };
    this.state.addressPickUp = data.booking.request.pickup.address;
    this.state.pickupFrom = data.booking.request.pickup.from;
    this.state.center = this.state.locationPickUp;
    this.state.addressDetailsPickUp = data.booking.request.pickup.addressDetails;

    if (data.booking.request.destination && data.booking.request.destination.address.length > 0) {
      this.state.locationDestination = {
        lat: data.booking.request.destination.geo[1],
        lng: data.booking.request.destination.geo[0],
        zipCode: data.booking.request.destination.zipCode,
        cityName: data.booking.request.destination.cityName,
        city: data.booking.request.destination.city || '',
        countryCode: data.booking.request.destination.country || '',
        timezone: data.booking.request.destination.timezone,
        from: data.booking.request.destination.from,
        address: data.booking.request.destination.address
      };
      this.state.addressDestination = data.booking.request.destination.address;
      this.state.destinationFrom = data.booking.request.destination.from;
      this.state.addressDetailsDestination = data.booking.request.destination.addressDetails;
    }
    if (
      this.state.locationPickUp
      && this.state.locationPickUp.address.length > 0
      && (!this.state.locationPickUp.from || this.state.locationPickUp.from == locationType.tencent)
    ) {
      this.state.pickupFrom = locationType.google;
    }
    if (
      this.state.locationDestination
      && this.state.locationDestination.address.length > 0
      && (!this.state.locationDestination.from
        || this.state.locationDestination.from == locationType.tencent)
    ) {
      this.state.destinationFrom = locationType.google;
    }
    if (data.cartypeSelected) {
      this.state.cartypeSelected = data.cartypeSelected;
    }
    let driverCompany = null;
    if (data.booking.request.companyId) {
      [...this.props.commonData.companies, ...this.props.commonData.suppliers].map(cp => {
        if (cp._id == data.booking.request.companyId) {
          driverCompany = cp;
        }
      });
    }

    let puPoints = [];
    if (data.booking && data.booking.puPoints) {
      puPoints = data.booking.puPoints.map(item => {
        item.addressPointText = item.address.address;
        if (data.company) {
          item.sender.company = data.company;
        }
        if(this.state.customer){
          item.sender.customer = this.state.customer;
        }
        return item;
      });
    }

    let doPoints = [];
    if (data.booking && data.booking.doPoints) {
      doPoints = data.booking.doPoints.map(item => {
        item.recipient._id = undefined;
        item.recipient.status = undefined;
        return item;
      })
    }

    this.setState(
      {
        data: Object.assign(this.state.data, bookingOj),
        driverCompany,
        puPoints,
        doPoints,
        cartypeSelected: data.cartypeSelected
      },
      () => {
        this.carTypeBaseLocation().then(service => {
          if (service.ok && service.res) {
            if (service.res.vehicleType && service.res.vehicleType.length > 0) {
              this.carTypeBaseLocationDataPaser(service, data.booking.request.vehicleTypeRequest);
            }
          }
        });
        this.getDirectionAndDraw();
      }
    );
  };

  componentDidMount() {
    if (this.props.deliveryBooking.bookingData) {
      this.parseExistedBookingToNewBooking(this.props.deliveryBooking.bookingData);
    }
    if (this.state.data.traveler_type == travelerType.Corporate) {
      if (!this.state.customer) {
        this.state.customer = {};
      }
      if (!this.state.customer.corporateInfo) {
        this.state.customer.corporateSelectedInfo = this.state.customer.corporateInfo = {};
      }
    }
    if(this.props.auth.user.userType == userType.CorporateAdmin ||
      this.props.auth.user.userType == userType.CorporateUser) {
      this.props.corporateActions
        .detailCorporate({
          fleetId: this.props.auth.selectedFleet.fleetId,
          _id: this.props.auth.user.corporateId,
        })
        .then((data) => {
          if (data.ok) {
            let driverCompany = null
            if(_.get(data.res, 'supplier.type', '') === SUPPLIER_TYPE.customized) {
              // list company được setting cho fleet
              const companiesSetting = _.get(this.props.commonData, 'companies', [])
              // Company đầu tiên được custome cho corp
              const firstCompanyAssigned = _.get(data.res, 'supplier.value[0]', '')
              let driverCompanyState = companiesSetting.find(c => c._id === firstCompanyAssigned)
              if(!_.isEmpty(driverCompanyState)) this.state.driverCompany = driverCompanyState
            }
            this.setState({
              company: data.res,
              data: {
                ...this.state.data,
                company: this.props.auth.user.corporateId
              },
              driverCompany
            })
          }
        });
    }
    this.onSocketDispatch();
  }

  handleResultCreateBooking = (data) => {
    if (data.code) {
      this.props.loadingActions.hideLoadingSpiner();
      this.context.notification(
        "success",
        I18n.t("messages.booking.Create_new_booking_success")
      );
      this.setState({ preAuthBookId: null })
      this.props.deliveryAction.closeDeliveryBookingForm();
    } else {
      // verify card pre-auth
      const preAuthCode = _.get(data, 'info.preAuthorizedCode');
      if (preAuthCode === 113) {
        this.setState({ clientSecret: data.info.clientSecret })
        return this.verifyStripeSCA(data.info.clientSecret);
      }
      this.props.loadingActions.hideLoadingSpiner();
      const errMgs = this.showMessageErrorBooking(data);
      if (errMgs) {
        this.context.notification('error', errMgs);
      }
    }
  }

  onSocketDispatch = () => {
    socketDispatchApi.remove(socketConfigs.receive.booking.preauthResult);
    socketDispatchApi.on(socketConfigs.receive.booking.preauthResult, data => {
      const { clientSecret } = this.state;
      if (!clientSecret || !data.clientSecret || clientSecret !== data.clientSecret) {
        return;
      };

      if (data.code) {
        this.props.loadingActions.hideLoadingSpiner();
        this.context.notification(
          "success",
          I18n.t("messages.booking.Create_new_booking_success")
        );
        this.setState({ preAuthBookId: null })
        this.props.deliveryAction.closeDeliveryBookingForm();
      } else {
        // verify card pre-auth
        const preAuthCode = _.get(data, 'info.preAuthorizedCode');
        if (preAuthCode === 113) {
          return this.verifyStripeSCA(data.info.clientSecret);
        }
        this.props.loadingActions.hideLoadingSpiner();
        if (data.info.preAuthorizedCode !== 911) {
          this.context.notification("error", this.showMessageErrorBooking(data));
        };
      }
    });
  }

  verifyStripeSCA = (clientSecret) => {
    const { auth } = this.props;
    const stripe = window.Stripe(auth.selectedFleet.stripePublicKey);
    stripe.handleCardPayment(
      clientSecret
    ).then((result) => {
      if (result.error) {
        // Display error.message in your UI.
        this.props.loadingActions.hideLoadingSpiner();
        const errorMessage = result.error.message || result.error.code;
        this.context.notification('error', errorMessage);
      };
    });
  }

  showMessageErrorBooking = (data) => {
    let msg = <Translate value="messages.booking.data_incorect" dangerousHTML />
    if(data.errorCode === "EXTERNAL_ID_EXISTED") {
      msg = I18n.t('messages.booking.EXTERNAL_ID_EXISTED');
      return msg;
    }
    if (data.code) {
      msg = I18n.t('messages.booking.' + data.code);
      const dataInfo = data.info || data.error;
      if (data.code == 303) {
        if (data.error.minimumBookAhead) {
          let hour = 0;
          let min = data.error.minimumBookAhead % 60;
          if (data.error.minimumBookAhead > 0) {
            hour = Math.floor(data.error.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (data.error.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            data.error.maximumBookAhead
          );
        }
      }
      else if (dataInfo.exDoDiffDo === false) {
        msg = I18n.t('messages.booking.exDoDiffDo');
      }
    } else {
      console.log(data)
      const dataInfo = data.info || data.error;
      if (dataInfo.limit === false) {
        msg = I18n.t('messages.booking.booking_limited');
      } else if (
        dataInfo.sameZone === false &&
        dataInfo.crossZone === false
      ) {
        msg = I18n.t('messages.booking.area_service_unavailable');
      } else if (!dataInfo.rate) {
        msg = I18n.t(
          'messages.booking.Please_reload_page_or_check_your_connection'
        );
      } else if (!dataInfo.pickUpTime) {
        msg = I18n.t('messages.booking.time_invalid');
        if (dataInfo.minimumBookAhead) {
          let hour = 0;
          let min = dataInfo.minimumBookAhead % 60;
          if (dataInfo.minimumBookAhead > 0) {
            hour = Math.floor(dataInfo.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (dataInfo.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            dataInfo.maximumBookAhead
          );
        }
        if (dataInfo.exDoDiffDo === false) {
          msg = I18n.t('messages.booking.exDoDiffDo');
        }
      } else if (!dataInfo.recurringTimeRange) {
        msg = I18n.t('messages.booking.recurringTimeRange').format(1);
      } else if (
        dataInfo.psgInfo === false ||
        dataInfo.psgInfo.isActive === false
      ) {
        msg = I18n.t('messages.booking.psg_inactivate');
      } else if (dataInfo.preAuthorized === false) {
        if (dataInfo.preAuthorizedCode) {
          msg = I18n.t('messages.credits.' + dataInfo.preAuthorizedCode);
        }
      } else if (dataInfo.cannotAssignDriver) {
        msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated');
      } else if (dataInfo.overlapTime) {
        this.showConfirmForceOverlap();
        msg = null
      } else if (!dataInfo.recipientsLimit) {
        msg = I18n.t('messages.booking.recipientsLimit').format(dataInfo.recipientsLimitNumber);
      } else if (dataInfo.promo) {
        switch (dataInfo.promo) {
          case 10:
            msg = I18n.t('messages.promoMsg.430')
            break;
          case 11:
            msg = I18n.t('messages.promoMsg.431')
            break;
          case 12:
            msg = I18n.t('messages.promoMsg.432')
            break;
          case 13:
            msg = I18n.t('messages.promoMsg.433')
            break;
          default:
            break;
        }
      }
    }
    return msg;
  }

  newBookingFromCallcenterSupport(phone) {
    this.state.data.phone = phone;
    this.handleBlurPhone(true, phone, null, phone);
  }

  getDefaultZone = () => {
    if (this.props.commonData.mapZone && this.props.commonData.mapZone.length > 0) {
      const defaultMap = this.props.commonData.mapZone.filter(m => m.isDefault)[0];
      return defaultMap;
    }
    return null;
  };

  checkNearestDriver = () => {
    if (
      !this.state.locationPickUp
      || !this.state.cartypeSelected
      || this.state.data.time != 'Now'
      || !this.props.newBooking.locationService
    ) {
      this.setState({ nearestDriver: null });
      return;
    }
    let vehicleIdAvailable = filterDeliveryCarTypes(
      _.get(this.props.newBooking, 'locationService.vehicleType', [])
    );
    vehicleIdAvailable = vehicleIdAvailable.map((vhc) => {
        return vhc._id
    })
    let object = {};
    if (this.props.newBooking.locationService.crossZone) {
      object = {
        fleetSupport: this.props.newBooking.locationService.fleetSupport,
        geo: [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
        status: 'bookIn',
        typeIds: [this.state.cartypeSelected._id],
        zoneIds: this.props.newBooking.locationService.zoneIds,
        fleetId: this.props.auth.selectedFleet.fleetId
      };
    } else {
      object = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        geo: [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
        status: this.state.data.time == 'Now' ? 'bookIn' : 'online',
        vehicleTypeIds: vehicleIdAvailable ? vehicleIdAvailable : [],
        companyId: this.state.driverCompany ? this.state.driverCompany._id : null
      };
    }
    if (
      this.state.data.traveler_type == travelerType.Corporate &&
      this.state.company
    ) {
      object.corporateId = this.state.company._id;
      let supplier = this.state.company && this.state.company.supplier || {}
      if (supplier.type == 'custom') {
        object.limitCompany = supplier.value;
      }
    }
    this.props.newbookingActions
      .checkNearestDriver(object, this.props.newBooking.locationService.crossZone)
      .then(data => {
        if(data.res) {
          this.handleNearestMultiCartype(data.res)
          return
        }
        this.setState({ nearestDriver: null });
      });
  };

  handleNearestMultiCartype = async (response) => {
    let promiseList = [],
      paramRequest = []
    const vehicleAvailable = filterDeliveryCarTypes(
      _.get(this.props.newBooking, 'locationService.vehicleType', [])
    );
    for (const vehicleId in response) {
      const vehicleItem = response[vehicleId],
        vehicleDetail = vehicleAvailable.find((vhc) => vhc._id === vehicleId);
      const destinations = vehicleItem.list && vehicleItem.list.map((drv) => {
        if (this.state.isChina) {
          return new window.qq.maps.LatLng(
            drv.loc.coordinates[1],
            drv.loc.coordinates[0]
          );
        }
        return new google.maps.LatLng(
          drv.loc.coordinates[1],
          drv.loc.coordinates[0]
        );
      });
      if(destinations && destinations.length > 0) {
        paramRequest.push({
          origins: `${this.state.locationPickUp.lat},${this.state.locationPickUp.lng}`,
          destinations: convertDirectionParam(destinations),
          mode: getTravelerModeByCarType(vehicleDetail),
          fleetId: this.props.auth.selectedFleet.fleetId,
          channel: getChannelMap(this.props.auth.selectedFleet.fleetId),
          vehicleDetail: vehicleDetail,
          forceProvider: this.state.isChina ? PROVIDER_MAP_TYPE.tencent : '',
        })
      } 
    }

    const { cartypeSelected } = this.state
    if(this.state.isChina) {
      Promise.all(promiseList)
      .then(results => {
        if(results && results.length > 0) {
          const vehicleSelecting = results.find((vhc) => vhc.vehicleType === cartypeSelected.vehicleType) 
          this.setState({
            nearestDriver: vehicleSelecting,
            nearestDriverList: results
          })
        } else {
          this.setState({
            nearestDriver: null,
            nearestDriverList: []
          })
        }
      })
    } else {
      const nearestFinal = await findDriverNearest(paramRequest);
      if(nearestFinal && nearestFinal.length > 0) {
        const vehicleSelecting = nearestFinal.find((vhc) => vhc?.vehicleType === cartypeSelected?.vehicleType) 
        this.setState({
          nearestDriver: vehicleSelecting,
          nearestDriverList: nearestFinal
        })
      } else {
        this.setState({
          nearestDriver: null,
          nearestDriverList: []
        })
      }
    }
  }

  getDistanceMatrixCallback = (response, status) => {
    this.setState({ nearestDriver: response });
  };

  getDirectionCallback = (response, status) => {
    this.setState(
      {
        directions: response?.overviewPolyline,
        directionsRecipients: response?.recipients,
        disDur: {
          distance: {
            value: response?.totalEstimate?.distanceValue,
            text: response?.totalEstimate?.distanceText || ''
          },
          duration: {
            value: response?.totalEstimate?.estimateValue,
            text: response?.totalEstimate?.estimateText || ''
          }
        },
      },
      this.etaFareCalculator
    );
  };

  getDirectionAndDraw = () => {
    const { puPoints = [], doPoints = [] } = this.state
    const points = [],
      puPointsNotEmpty = filterPointEmpty(puPoints),
      doPointsNotEmpty = filterPointEmpty(doPoints)
    const travelMode = getTravelerModeByCarType(this.state.cartypeSelected, this.props.commonData.location.isChina);
    if (
      this.state.locationPickUp && this.state.locationDestination ||
      puPointsNotEmpty.length > 0 ||
      doPointsNotEmpty.length > 0

    ) {
      puPointsNotEmpty.forEach((point) => {
        let geo = point && point.address && point.address.geo || []
        points.push({
          lat: geo[1],
          lng: geo[0]
        });
      })
      doPointsNotEmpty.forEach((point) => {
        let geo = point && point.address && point.address.geo || []
        points.push({
          lat: geo[1],
          lng: geo[0]
        });
      })
      if(this.checkLocationHasChange(points)) {
        getDirectionMapProviderFromJupiter({
          points,
          travelMode,
          unit: this.props.auth.selectedFleet.unitDistance == 'km' ? 0 : 1,
          shortestPathEstimation: this.props.auth.selectedFleet.shortestPathEstimation,
          auth: this.props.auth,
          callback: this.getDirectionCallback,
          isTencent: this.props?.commonData?.location?.isChina,
          vehicleType: this.state?.cartypeSelected?.vehicleType,
          jobType: 'delivery',
          deliveryData: {
            deliveryType: 0,
            cashOnPickup: false,
            cashOnDelivery: checkCashOnDelivery(doPoints),
          }
        });
      }
    }
  };

  dragEditPoint = (
    place = {},
    location = {},
    id,
    pointsCurrent,
    callback
  ) => {
    let updatedPoint = [...pointsCurrent]
    let pointIndex = updatedPoint.findIndex((obj => obj.id == id));
    location.geo = [location.lng, location.lat]
    location.address = place.formatted_address || ''
    updatedPoint[pointIndex].address = location
    updatedPoint[pointIndex].addressPointText = place.formatted_address || ''
    callback(updatedPoint)
  }

  handlePickupDrapEnd = (results, status, id) => {
    getLocationWhenDrapEnd({
      results, 
      id, 
      callback: this.pickupDrapEndCallback, 
      auth: this.props.auth,
      language: this.props?.language,
      isTencent: this.props.commonData?.location?.isChina
    })
  };

  pickupDrapEndCallback = (place, location, id) => {
    if (id) {
      this.dragEditPoint(place, location, id, this.state.puPoints, this.setNewPuPoint)
    } else {
      this.setState(
        {
          addressPickUp: place ? place.formatted_address : '',
          locationPickUp: location,
          loadingPickUp: false,
          pickupFrom: locationType.google,
          center: location,
          addressDetailsPickUp: location.addressDetails
        },
        () => {
          this.carTypeBaseLocation().then(data => {
            if (data.ok && data.res) {
              this.carTypeBaseLocationDataPaser(data);
            }
          });
          this.getDirectionAndDraw();
        }
      );
    }
  };

  handleDestinationDrapEnd = (results, status, id) => {
    getLocationWhenDrapEnd({
      results, 
      id, 
      callback: this.destinationDrapEndCallback, 
      auth: this.props.auth,
      language: this.props?.language,
      isTencent: this.props.commonData.location.isChina
    })
  };

  destinationDrapEndCallback = (place, location, id) => {
    if (id) {
      this.dragEditPoint(place, location, id, this.state.doPoints, this.setNewDoPoint)
    } else {
      this.setState(
        {
          addressDestination: place ? place.formatted_address : '',
          loadingDestination: false,
          destinationFrom: locationType.google,
          locationDestination: location,
          center: location,
          addressDetailsDestination: location.addressDetails
        },
        () => {
          this.carTypeBaseLocation().then(data => {
            if (data.ok) {
              if (data.res.crossZone) {
                this.state.data.dispatch3rd = false;
                this.getCrossZoneBaseLocation();
              }
            }
          });
          this.getDirectionAndDraw();
        }
      );
    }
  };

  renderGeocodeSuccessPickUp = (lat, lng) => {
    this.setState({
      center: {
        lat,
        lng
      },
      markerPickUp: {
        lat,
        lng,
        icon: '/map/mk_pickup.png'
      }
    });
  };

  handleChangeNote = e => {
    this.setState({ data: { ...this.state.data, note: e.target.value } });
  };

  reloadCorporateCompany = () => {
    if (this.state.data.paymentMethod == paymentMethodNumber.corporateCard) {
      this.props.corporateCompanyActions.getCorporateCompany(this.props.auth.selectedFleet.fleetId)
        .then((data) => {
          let company = data.res && data.res[0] || {}
          if (company._id) {
            this.setState({ company });
          }
        });
    }
  }

  handleChangeDispatchType = e => {
    this.state.data.dispatch_type = parseInt(e.target.value);
    if (this.state.data.dispatch_type == 0) {
      this.state.data.driver = '';
    }
    this.setState({
      data: { ...this.state.data, dispatch_type: parseInt(e.target.value) }
    });
  };

  handleChangePromo = e => {
    this.setState({ data: { ...this.state.data, promo: (e.target.value || '').toUpperCase() } });
  };

  handleDuration = e => {
    if (e.target.checked) {
      this.setState(
        {
          data: {
            ...this.state.data,
            duration: e.target.checked
          }
          // package: this.props.newBooking.locationService.packagesRate[0]
        },
        this.etaFareCalculator
      );
    } else {
      this.setState(
        {
          data: { ...this.state.data, duration: e.target.checked },
          package: null
        },
        this.etaFareCalculator
      );
    }
  };

  handleDriverChanged = e => {
    this.setState({ data: { ...this.state.data, driver: e.target.value }, driver: {} });
  };

  handleMapLoad = map => {
    this._mapComponent = map;
    if (map) {
    }
  };

  handleChangeBookingReference = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        externalInfo: {
          ...this.state.data.externalInfo,
          bookingReference: e.target.value,
        },
      },
    });
  };

  handlePaymentMethodChange = e => {
    this.state.valid = {};
    this.setState({
      data: { ...this.state.data, paymentMethod: parseInt(e.target.value) }, originFare: this.state.etaFare
    }, () => {
      this.etaFareCalculator()
    });
    if (this.state.markupValue && NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(parseInt(e.target.value)) && this.state.data.paymentMethod != e.target.value) {
      this.setState({
        etaFare: {
          ...this.state.originFare,
          markupDifference: 0,
        },
        reasonMarkup: null,
        reasonMarkup: null,
        markupValue: 0,
        originFare: this.state.originFare,
        isFareEdited: false,
        reasonEditFare: null,
        prevEtaFare: {
          originFare: this.state.originFare,
          isFareEdited: false,
          reasonEditFare: null,
        }
      });
    }
  };

  handleCreditCardChange = e => {
    this.state.valid = {};
    if (e.target.value == 0) {
      this.setState({ credit: null });
    } else if (this.state.data.paymentMethod == paymentMethodNumber.personalCard) {
      this.state.customer.credits.map(cre => {
        if (cre.crossToken == e.target.value || cre.localToken == e.target.value) {
          this.state.credit = cre;
          this.setState(this.state);
        }
      });
    } else if (this.state.data.paymentMethod == paymentMethodNumber.corporateCard) {
      this.state.company.credits.map(cre => {
        if (cre.crossToken == e.target.value || cre.localToken == e.target.value) {
          this.state.credit = cre;
          this.setState(this.state);
        }
      });
    }
  };

  handleAddCardSuccess(cardAdded) {
    if (this.state.data.paymentMethod == paymentMethodNumber.personalCard) {
      const { customer } = this.state;
      customer.credits.push(cardAdded);
      this.setState({ customer, credit: cardAdded });
    }
    if (this.state.data.paymentMethod == paymentMethodNumber.corporateCard) {
      const { company } = this.state;
      company.credits.push(cardAdded);
      this.setState({ company, credit: cardAdded });
    }
  };

  reloadCustomerTSYSGateway = (number) => {
    const travelerType = this.state.data.traveler_type == 0 ? 'individual' : 'corporation';
    this.props.customerAutocompleteActions
      .customerAutocomplete({
        fleetId: this.props.auth.selectedFleet.fleetId,
        str: trimPhoneNumber(number).replace(/\D/g, ''),
        travelerType
      })
      .then(data => {
        if (data.res) {
          const suggestions = data.res.list.filter(item => trimPhoneNumber(item.phone) === trimPhoneNumber(this.state.data.phone));
          if (suggestions[0]) {
            const suggestData = suggestions[0];
            suggestData.phone = number;
            this.customerAutocompleteSlectedHandle(null, {
              suggestion: suggestData
            });
          }
          else {
            this.clearCustomerData(number);
          }
        } else {
          this.clearCustomerData(number);
        }
      });
  }

  handlePromoApplyClick = e => {
    if (this.state.promo) {
      this.setState(
        { promo: null, data: { ...this.state.data, promo: '' } },
        () => {
          if(this.state.isFareEdited) {
            this.setState({
              etaFare: calculateFareWithPromoChange({
                auth: this.props.auth,
                promoInfo: null,
                etaFare: this.state.etaFare,
                data: this.state.data,
              })
            })
          } else {
            this.etaFareCalculator
          }
        }
      );
    } else {
      const timezonePickUp = tzlookup(this.state.locationPickUp.lat, this.state.locationPickUp.lng);
      const localNow = moment().tz(timezonePickUp);
      const timeWithoutZone = moment(this.state.data.time).format('YYYY-MM-DD HH:mm');
      const localPickup = moment.tz(timeWithoutZone, timezonePickUp).format();
      const { recurringDays = [], dateMode } = this.state

      let pickupTimeReCurring = 'Now';
      if (dateMode == 'single') {
        pickupTimeReCurring =
          this.state.data.time == 'Now'
            ? 'Now'
            : moment(this.state.data.time).format('YYYY-MM-DD HH:mm');
      } else {
        pickupTimeReCurring = this.state.recurringTime;
      }
      const recurring = {
        mode: dateMode,
        pickupTime: pickupTimeReCurring,
        selectedDates: recurringDays.map(dateOb => moment(dateOb).format('YYYY-MM-DD'))
      };
      const promotionObject = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        recurring: recurring,
        pickupTime:
          this.state.data.time == 'Now'
            ? moment.utc(localNow).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
            : moment.utc(localPickup).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        promotionCode: this.state.data.promo,
        userId: this.state.customer ? this.state.customer.userId : '',
        bookFrom: this.state.options && this.state.options.bookFrom,
        currencyISO: this.props.newBooking.locationService.currency
          ? this.props.newBooking.locationService.currency.iso
          : this.props.auth.selectedFleet.currency.iso,
        geo: [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
        serviceType: 0, // 0: transport, 1: intercity, 2: parcel, 3: food, 4: mart
        etaFare: this.state.etaFare && this.state.etaFare.etaFare ? this.state.etaFare.etaFare : 0,
        paymentType: this.state.data.paymentMethod,
      };

      if (promotionObject.promotionCode) {
        this.props.newbookingActions.checkpromoCode(promotionObject).then(data => {
          if (data.returnCode === 200) {
            const newPromo = data.response;
            newPromo.promotionCode = this.state.data.promo;
            this.setState(
              { promo: newPromo },
              () => {
                if(this.state.isFareEdited) {
                  this.setState({
                    etaFare: calculateFareWithPromoChange({
                      auth: this.props.auth,
                      promoInfo: newPromo,
                      etaFare: this.state.etaFare,
                      data: this.state.data,
                    })
                  })
                } else {
                  this.etaFareCalculator()
                }
              }
            );
          } else if (data.returnCode) {
            this.context.notification('error', I18n.t(`messages.promoMsg.${data.returnCode}`));
          } else {
            this.context.notification('error', I18n.t('messages.booking.Check_promo_error'));
          }
        });
      }
    }
  };

  handleChangeETAFare(fareEdited, originFare, reasonEditFare, isFareEdited) {
    let shortTrip = this.props?.auth?.selectedFleet?.waiveOffCommission?.enable ? 
      (fareEdited?.etaFare > this.props?.auth?.selectedFleet?.waiveOffCommission?.amountByCurrencies[0]?.value
      ? false : true) : originFare?.shortTrip;
    this.setState({
      etaFare: {
        ...fareEdited,
        markupDifference: 0,
        shortTrip: shortTrip
      },
      reasonMarkup: null,
      markupValue: 0,
      originFare,
      isFareEdited,
      reasonEditFare,
      prevEtaFare: {
        originFare,
        isFareEdited,
        reasonEditFare
      }
    });
  };

  handleChangeMarkupPrice(originFare, markupDifference, reasonMarkup, markupType, markupValue) {
    this.setState({
      etaFare: {
        ...originFare,
        markupDifference
      },
      reasonMarkup,
      markupType,
      markupValue: parseFloat(markupValue),
      originFare,
      isFareEdited: false,
      reasonEditFare: null,
      prevEtaFare: {
        originFare,
        isFareEdited: false,
        reasonEditFare: null,
      }
    });
  }

  handleClientCaseMatterChange = e => {
    this.state.data.clientCaseMatter = e.target.value;
    this.setState({ data: this.state.data });
  };
  handleChangeOperatorNote = e => {
    this.state.data.operatorNote = e.target.value;
    this.setState({ data: this.state.data });
  }
  handleChargeCodeChange = e => {
    this.state.data.chargeCode = e.target.value;
    this.setState({ data: this.state.data });
  };

  handleCompanyDriverChange = e => {
    this.state.driverCompany = null;
    [...this.props.commonData.companies, ...this.props.commonData.suppliers].map(cp => {
      if (cp._id == e.target.value) {
        this.state.driverCompany = cp;
      }
    });
    this.setState(
      {
        driverCompany: this.state.driverCompany
      },
      () => {
        this.carTypeBaseLocation().then(service => {
          if (service.ok && service.res) {
            let cartypeSelected = null;
            if (service.res.vehicleType && service.res.vehicleType.length > 0) {
              cartypeSelected = service.res.vehicleType.find(car => (
                ((!car.dispatchRideSharing && this.state.data.time !== 'Now')
                  || this.state.data.time === 'Now')
                && (!car.availability || car.availability.cc) && car.delivery
              ));
            }
            if (cartypeSelected != null) {
              // update vehicle type
              this.setState(
                {
                  cartypeSelected
                },
                () => {
                  this.carTypeBaseLocation().then(res => {
                    this.etaFareCalculator();
                  });
                  this.checkNearestDriver();
                }
              );
            } else {
              this.setState(
                {
                  cartypeSelected
                },
                () => {
                  this.checkNearestDriver();
                }
              );
            }
          }
        });
      }
    );
  };

  driverAutocompleteSlectedHandle = (
    event,
    {
      suggestion, suggestionValue, suggestionIndex, sectionIndex, method
    }
  ) => {
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId);
    }
    this.setState({
      data: { ...this.state.data, driver: suggestion.driver.name },
      driver: Object.assign(this.state.driver, suggestion)
    }, this.etaFareCalculator);
  };

  handleCarTypeChanged = vehicleType => {
    this.props.newBooking.locationService.vehicleType.map(car => {
      if ((car.vehicleType || car.ty) == vehicleType) {
        this.state.cartypeSelected = car;
        this.state.data.driver = '';
        this.state.driver = '';
      }
    });

    this.setState(
      {
        data: { ...this.state.data, carType: vehicleType },
        cartypeSelected: this.state.cartypeSelected
      },
      () => {
        this.getDirectionAndDraw();
        this.carTypeBaseLocation().then(data => {
          if (data.ok) {
            this.etaFareCalculator();
            if (data.res.crossZone) {
              this.state.data.dispatch3rd = false;
              this.getCrossZoneBaseLocation();
            }
          }
        });
        this.checkNearestDriver();
      }
    );
  };

  handleDateChanged = e => {
    this.selectingDate = false;
    if (e === 'Now') {
      if (this.state.data.time === e) return;
      this.state.data.driver = '';
      this.state.driver = {};
      this.setState({ 
        data: { 
          ...this.state.data, 
          time: e,
          dispatch_type: 0 
        }
      }, () => {
        this.etaFareCalculator();
        this.checkNearestDriver();
      });
    } else {
      const { data } = this.state;
      const prevTime = moment(data.time);
      if (data.time && data.time !== 'Now' && prevTime.isSame(e, 'day')) return false;
      this.state.data.time = e;
      this.setState({ data: { ...this.state.data, time: e } }, () => {
        this.etaFareCalculator();
        this.checkNearestDriver();
      });
    }
  };

  onPickupTimeFocus() {
    this.selectingDate = true;
  }

  carTypeBaseLocation = () => {
    let options = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      companyId:
        !this.state.driverCompany || this.state.driverCompany._id === -1
          ? null
          : this.state.driverCompany._id
    };
    if (this.state.locationPickUp) {
      options = {
        ...options,
        from: {
          geo: [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
          zipCode: this.state.locationPickUp.zipCode
        },
        zipCodeFrom: this.state.locationPickUp.zipCode
      };
    }
    if (this.state.locationDestination && this.state.locationDestination.lat) {
      options = {
        ...options,
        to: {
          geo: [this.state.locationDestination.lng, this.state.locationDestination.lat],
          zipCode: this.state.locationDestination.zipCode
        },
        zipCodeTo: this.state.locationDestination.zipCode
      };
    }
    if (this.state.cartypeSelected && this.state.data.carType != '') {
      options = {
        ...options,
        vehicleType: this.state.cartypeSelected.vehicleType
      };
    }
    if (this.state.data.traveler_type == travelerType.Corporate && this.state.company) {
      options.corporateId = this.state.company._id;
      let supplier = this.state.company && this.state.company.supplier || {}
      if (supplier.type == 'custom') {
        options.limitCompany = supplier.value;
      }
    }

    return this.props.newbookingActions.getCarTypeBaseLocation(options).then(data => {
      this.setState({
        isCheckSupportLocation: true
      });
      return data;
    });
  };

  carTypeBaseLocationDataPaser = (service, selectdCar = null) => {
    if (service.res.vehicleType && service.res.vehicleType.length > 0) {
      const cartypes = service.res.vehicleType.filter(v => (
        ((!v.dispatchRideSharing && this.state.data.time !== 'Now')
          || this.state.data.time === 'Now')
        && (!v.availability || v.availability.cc) && v.delivery
      ));
      let carType = this.state.cartypeSelected;
      if (carType) {
        carType = cartypes.find(v => (carType.vehicleType || carType.ty) == (v.vehicleType || v.ty));
      }
      if (!carType) {
        if (this.state.data.time !== "Now") {
          const newCartypes = _.dropWhile(cartypes, { 'superHelper': true })
          carType = newCartypes[0];
        } else {
          carType = cartypes[0];
        }
      }
      if (selectdCar) {
        carType = cartypes.find(v => selectdCar == v.vehicleType || selectdCar == v.ty);
      }
      if (carType) {
        this.state.data.carType = carType.vehicleType || carType.ty;
        this.state.cartypeSelected = carType;
      }
    }
    return this.carTypeBaseLocation().then(data => {
      if (data && data.res) {
        this.etaFareCalculator();
        this.checkNearestDriver();
      }
    });
  };

  getCrossZoneBaseLocation = () => {
    const options = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      pricingType: 1,
      userId: this.props.auth.user._id
    };
    if (this.state.cartypeSelected && this.state.data.carType != '') {
      options.vehicleType = this.state.cartypeSelected._id;
    }
    return this.props.newbookingActions.getCrossZoneRate(options).then(data => {
      if (data.ok && data.res) {
        if (data.res.response) {
          this.setState({ data: this.state.data }, this.etaFareCalculator);
        }
      }
    });
  };

  newChauffeurRequestObjectBuilder = (forceAssign) => {
    const bookingCurrency = this.props.newBooking
      && this.props.newBooking.locationService
      && this.props.newBooking.locationService.currency
      ? this.props.newBooking.locationService.currency
      : this.props.auth.selectedFleet.currency;
    const parsedTravelerType = parseInt(this.state.data.traveler_type);

    let { puPoints, doPoints, dateMode, recurringDays } = this.state

    let pickupTime = 'Now'

    if (dateMode == 'single') {
      if (this.state.data.time != 'Now') {
        pickupTime = moment(this.state.data.time).format('YYYY-MM-DD HH:mm')
      }
    } else {
      pickupTime = this.state.recurringTime
    }

    const recurring = {
      mode: dateMode,
      pickupTime: pickupTime,
      selectedDates: recurringDays.map(dateOb => moment(dateOb).format('YYYY-MM-DD'))
    };

    let isMultiPoints = (puPoints.length > 1 || doPoints.length > 1) ? true : false
    const markupDifference = this.state.etaFare && this.state.etaFare.markupDifference || 0;

    const object = {
      mapProvider: 'google',
      platform: 'Web',
      bookFrom: 'CC',
      'x-request-id': uuidv4({ msecs: new Date().getTime() }),
      dispatchType: this.state.data.dispatch_type,
      dispatcherId: this.props.auth.user._id,
      isMultiPoints: isMultiPoints,
      forceAssign,
      fleetId: this.props.auth.selectedFleet.fleetId,
      externalInfo: {
        bookingReference: this.state.data?.externalInfo?.bookingReference ?? ''
      },
      jupiter: {
        jobType: 'delivery'
      },
      trailNotes: this.state.trailNotes,
      recurring: recurring,
      request: {
        vehicleTypeRequest: this.state.cartypeSelected.vehicleType || this.state.cartypeSelected.ty,
        pickUpTime:
          this.state.data.time == 'Now'
            ? 'Now'
            : moment(this.state.data.time).format('YYYY-MM-DD HH:mm'),
        vehicleType: this.state.cartypeSelected.dispatch || [this.state.cartypeSelected._id],
        fleetId: this.props.auth.selectedFleet.fleetId,
        special: '',
        promo: this.state.promo ? this.state.promo.promotionCode : '',
        promoValue: this.state.promo
          ? this.state.promo.type == 'Amount'
            ? currencyFormatter.format(-this.state.promo.value, {
              code: bookingCurrency.iso
            })
            : `${-this.state.promo.value}%`
          : '',
        estimate: {
          // distance: this.state.disDur ? this.state.disDur.distance.text : '',
          // time: this.state.disDur ? this.state.disDur.duration.text : '',
          // estimateValue: this.state.disDur ? this.state.disDur.duration.value : '',
          // fare: this.state.etaFare ? {
          //   ...this.state.etaFare,
          //   unroundedTotalAmt: this.state.etaFare.unroundedTotalAmt + markupDifference,
          //   totalWithoutPromo: this.state.etaFare.totalWithoutPromo + markupDifference,
          //   etaFare: this.state.etaFare.etaFare + markupDifference,
          // } : {},
          originFare: this.state.originFare || {},
          isFareEdited: this.state.isFareEdited || false,
          reasonEditFare: this.state.reasonEditFare || "",
          isNormalFare: this.state.etaFare ? this.state.etaFare.normalFare : true,
          reasonMarkup: this.state.reasonMarkup || '',
          markupType: this.state.markupType || 'amount',
          markupValue: this.state.markupValue || 0
        },
        markupDifference,
        type: 0,
        typeRate: 0,
        paymentType: this.state.data.paymentMethod,
        note: this.state.data.note,
        operatorNote: this.state.data.operatorNote,
        rideSharing: this.state.data.rideSharing
      },
      travelerType: parsedTravelerType,
      drvInfo: {},
      ack: 'vtdurh0n8gh',
      dispatch3rd: this.state.data.dispatch3rd,
      hwMetered: false,
      operator: {
        userId: this.props.auth.user._id,
        name: `${this.props.auth.user.firstName || ''} ${this.props.auth.user.lastName || ''}`,
        role:
          this.props.auth.user.userType == userType.CorporateAdmin
            || this.props.auth.user.userType == userType.CorporateUser
            ? 'corporate'
            : 'cc'
      },
      pricingType: this.props.newBooking.locationService.crossZone ? 1 : 0
    };
    if (this.state.driverCompany) {
      object.request.companyId = this.state.driverCompany._id;
      object.supplierCompanies = [this.state.driverCompany._id];
      object.request.companyName = this.state.driverCompany.name;
    }

    object.psgInfo = {
      email: this.state.data.email,
      firstName: this.state.data.firstname,
      lastName: this.state.data.lastname,
      fullName:
        (this.state.data.firstname ? `${this.state.data.firstname} ` : '')
        + (this.state.data.lastname ? this.state.data.lastname : ''),
      phone: '',
      rank: this.state.data.isCustomerVip ? 1 : 0
    };

    if (this.state.data.phone && this.state.data.phone.length >= 6) {
      let phone = '';
      for (let i = 0; i < this.state.data.phone.length; i++) {
        const c = this.state.data.phone[i];
        if (c == '+' || parseInt(c) == 0 || !!parseInt(c)) {
          phone += c;
        }
      }
      object.psgInfo.phone = phone;
    }

    if (parsedTravelerType == travelerType.Corporate) {
      object.corporateInfo = {
        division: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.corpDivision
          : '',
        managerEmail: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.managerEmail
          : '',
        corpId: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.corpId
          : '',
        managerName: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.managerName
          : '',
        costCentre: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.costCentre
          : '',
        department: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.department
          : '',
        clientCaseMatter: '',
        chargeCode: '',
        corporateId: this.state.company._id,
        name: this.state.company.companyInfo
          ? this.state.company.companyInfo.name
          : this.state.company.companyName
      };
    }
    if (
      this.state.data.dispatch_type == 1 ||
      this.state.data.dispatch_type == 3
    ) {
      object.drvInfo = {
        phone: this.state.driver.phone
      };
    }
    if (
      (this.state.data.paymentMethod == paymentMethodNumber.personalCard
        || this.state.data.paymentMethod == paymentMethodNumber.corporateCard)
      && !this.state.credit
    ) {
      this.context.notification('error', I18n.t('newbooking.Card_required'));
      return null;
    }
    if (this.state.data.paymentMethod == paymentMethodNumber.corporateCard) {
      if (!object.corporateInfo) {
        object.corporateInfo = {};
      }
      if (this.props.newBooking.locationService.crossZone) {
        object.psgInfo.creditInfo = {
          cardHolder: this.state.credit.cardHolder,
          localToken: this.state.credit.localToken,
          crossToken: this.state.credit.crossToken,
          cardMask: this.state.credit.cardMask
        };
      } else {
        object.corporateInfo.creditInfo = {
          cardHolder: this.state.credit.cardHolder,
          cardType: this.state.credit.cardType,
          gateway: this.state.credit.gateway,
          localToken: this.state.credit.localToken,
          crossToken: this.state.credit.crossToken,
          cardMask: this.state.credit.cardMask
        };
      }
    }
    if (this.state.data.paymentMethod == paymentMethodNumber.personalCard) {
      if (!object.psgInfo) {
        object.psgInfo = {};
      }
      object.psgInfo.creditInfo = {
        localToken: this.state.credit.localToken,
        crossToken: this.state.credit.crossToken,
        cardMask: this.state.credit.cardMask
      };
    }

    if (
      !this.props.newBooking.locationService.crossZone
      && (parsedTravelerType == travelerType.Corporate
        || this.state.data.paymentMethod == 4
        || this.state.data.paymentMethod == 5
        || this.state.data.paymentMethod == 7)
    ) {
      if (!object.corporateInfo) {
        object.corporateInfo = {};
      }
      object.corporateInfo.clientCaseMatter = this.state.data.clientCaseMatter;
      object.corporateInfo.chargeCode = this.state.data.chargeCode;
    }
    const doPointsNotEmpty =  filterPointEmpty(this.state.doPoints)
    let deliveryInfo = {
      cashOnPickup: false,
      cashOnDelivery: doPointsNotEmpty.some(item => !!(item.recipient && item.recipient.amount && parseFloat(item.recipient.amount))),
      pickup: {
        accepted: true,
        isPreferred: false,
        phone: object.psgInfo.phone,
        name: `${object.psgInfo.firstName} ${object.psgInfo.lastName}`,
        firstName: object.psgInfo.firstName,
        lastName: object.psgInfo.lastName,
        email: object.psgInfo.email,
        address: this.state.puPoints[0].address,
      }
    }

    deliveryInfo.recipients = doPointsNotEmpty.map(item => {
      return {
        accepted: true,
        isPreferred: false,
        address: item.address,
        order: item.order - this.state.puPoints.length,
        name: item.recipient.name,
        phone: item.recipient.phone,
        menuData: {
          menuId: "bfc-menuid",
          selectedItems: getMenuDataFromRecipient(item.recipient, this.props.newBooking.locationService.currency)
        }
      }
    });
    if((this.state?.puPoints?.length > 0 && doPointsNotEmpty?.length >0)) {
      object.request.sessionKey = this.state.sessionKeyETAFare
      object.request.estimate.fare = this.state.etaFare ? {
        ...this.state.etaFare,
        unroundedTotalAmt: this.state.etaFare.unroundedTotalAmt + markupDifference,
        totalWithoutPromo: this.state.etaFare.totalWithoutPromo + markupDifference,
        etaFare: this.state.etaFare.etaFare + markupDifference,
      } : {}
    } else {
      object.request.estimate.fare = {}
    }
    object.deliveryInfo = deliveryInfo;
    console.log('Boooking', object)
    return object;
  }

  handleCancelBookingClick = () => {
    this.setState({ isShowCancelMessage: !this.state.isShowCancelMessage });
  };

  escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // driver auto complete render and handleAddressRemoved
  getDriverSuggestions = value => {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId);
    }
    const { auth: { selectedFleet } } = this.props;

    const offlineDriver = isCanAssignOfflineDriver(selectedFleet, this.state.data.time);
    this.lastAutoCompleteRequestId = setTimeout(() => {
      this.props.driverAutocompleteActions
        .driverAutocomplete({
          fleetId: selectedFleet.fleetId,
          str: escapedValue,
          pickUpTime:
            this.state.data.time == 'Now'
              ? 'Now'
              : moment(this.state.data.time).format('YYYY-MM-DD HH:mm'),
          vehicleTypeId: this.state.cartypeSelected && this.state.cartypeSelected._id,
          companyId: this.state.driverCompany ? this.state.driverCompany._id : null,
          offlineDriver,
          corporateId: this.state.company && this.state.company._id
        })
        .then(() => {
          this.setState({
            driversuggestions: regexpDriverAutoComplete(this.props.driverAutocomplete.data, escapedValue)
          });
        });
    });

    return regexpDriverAutoComplete(this.props.driverAutocomplete.data, escapedValue);
  };

  getDriverSuggestionValue = suggestion => suggestion.driver.name;

  onDriverSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      driversuggestions: this.getDriverSuggestions(value)
    });
  };

  onDriverSuggestionsClearRequested = () => {
    this.setState({
      driversuggestions: []
    });
  };

  handleDispatch3rdOnchange = e => {
    this.state.data.dispatch3rd = e.target.checked;
    this.setState({ data: this.state.data });
  };

  setTimeOutCreateButton = () => {
    setTimeout(() => {
      if (this.props.loadingBar.isShowSpiner) {
        this.props.loadingActions.hideLoadingSpiner();
        this.context.notification(
          'error',
          I18n.t('messages.booking.request_timeout')
        );
        this.setState({ preAuthBookId: null })
        this.props.newbookingActions.closeNewBookingForm();
      };
    }, 60000);
  };

  handleSaveBookingClick = (e, forceAssign) => {
    e && e.preventDefault();
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!this.state.locationPickUp) {
      this.context.notification('error', I18n.t('newbooking.Enter_a_location'))
      return;
    }
    if (!this.state.cartypeSelected) {
      this.context.notification('error', I18n.t('messages.booking.Please_select_car_type'));
      return;
    } else {
      let validDropCountry = checkDropOffCountry(this.state.cartypeSelected.dropOffCountry,
        this.state.locationPickUp, this.state.locationDestination)
      if (!validDropCountry.valid) {
        validDropCountry.err == 0
          ? this.context.notification('error', I18n.t('messages.booking.DropOff_country'))
          : this.context.notification('error', I18n.t('messages.booking.Different_Country'))
        return;
      }
    }
    if (this.state.cartypeSelected.dispatchRideSharing && !this.state.locationDestination) {
      this.context.notification(
        'error',
        I18n.t('messages.booking.Please_enter_destination_for_ridesharing_booking')
      );
      return;
    }

    if (!this.state.data.paymentMethod || !parseInt(this.state.data.paymentMethod)) {
      return this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_payment_method')
      );
    }

    if (
      this.props.newBooking.locationService
      && this.props.newBooking.locationService.crossZone
      && (!this.state.credit)
    ) {
      this.context.notification('error', I18n.t('messages.booking.Please_select_or_add_a_card'));
      return;
    }
    if (
      (this.state.data.paymentMethod == paymentMethodNumber.personalCard
        || this.state.data.paymentMethod == paymentMethodNumber.corporateCard)
      && (!this.state.credit)
    ) {
      this.context.notification('error', I18n.t('messages.booking.Please_select_or_add_a_card'));
      return;
    }

    const { 
      recurringDays, 
      dateMode, 
    } = this.state

    if(dateMode === 'recurring') {
      if(recurringDays && recurringDays.length === 0) {
        this.context.notification(
          'error',
          'Please add days for recurring'
        );
        return
      }
    }

    //
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      const { valid } = this.state;
      if (!valid.phoneStatus) {
        this.context.notification('error', I18n.t('messages.booking.phone_invalid'));
      } else if (!valid.email) {
        this.context.notification(
          'error',
          I18n.t('messages.commonMessages.invalid_email_format')
        );
      } else {
        this.context.notification('error', I18n.t('messages.booking.500'));
      }
      return;
    }
    this.props.loadingActions.showLoadingSpiner();
    if (this.state.data.phone) {
      let customerObject = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        phone: this.state.data.phone
          .replace(new RegExp(' ', 'g'), '')
          .replace(new RegExp('-', 'g'), ''),
        firstName: this.state.data.firstname || '',
        lastName: this.state.data.lastname || '',
        email: this.state.data.email || ''
      };
      // SL-23585 Save 'company' for customer by Company of Booking request
      if (this.state.driverCompany && this.state.driverCompany._id) {
        customerObject.company = {
          companyId: this.state.driverCompany._id,
          name: this.state.driverCompany.name
        };
      };
      this.props.newbookingActions.checkCustomerBooking(customerObject).then(data => {
        if (data.ok && data.res && data.res.customer) {
          if (!this.state.customer) {
            this.state.customer = data.res.customer;
            if (this.state.data.traveler_type == travelerType.Corporate) {
              this.state.customer.corporateSelectedInfo = this.state.company;
            }
          }
          this.setState({ customer: this.state.customer }, () => {
            let bookingObj
            bookingObj = this.newChauffeurRequestObjectBuilder(forceAssign);
            if (bookingObj) {
              this.props.newbookingActions.createNewBooking(bookingObj)
              .then((res) => {
                this.handleResultCreateBooking(res)
              })
              this.setTimeOutCreateButton();
            } else {
              this.props.loadingActions.hideLoadingSpiner();
            }
          });

        } else {
          this.props.loadingActions.hideLoadingSpiner();
          this.context.notification(
            'error',
            I18n.t('messages.booking.Check_customer_error'),
            data.message
          );
        }
      });
    } else {
      let bookingObj
      bookingObj = this.newChauffeurRequestObjectBuilder(forceAssign);
      if (bookingObj) {
        this.props.newbookingActions.createNewBooking(bookingObj)
        .then((res) => {
          this.handleResultCreateBooking(res)
        })
        this.setTimeOutCreateButton();
      } else {
        this.props.loadingActions.hideLoadingSpiner();
      }
    }
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      let oldMessages = this.state.errorMessages;
      if (!valid) {
        if (!oldMessages) oldMessages = [];
        oldMessages = oldMessages.concat(messages);
      }
      this.setState({
        valid: this.state.valid,
        errorMessages: oldMessages
      });
    }
  };

  isValidManagerEmail = () => {
    if (
      this.state.customer.corporateSelectedInfo
      && this.state.customer.corporateSelectedInfo.managerEmail
    ) return Validation.validateMultiEmail(this.state.customer.corporateSelectedInfo.managerEmail);
    return true;
  };

  showConfirmForceOverlap = () => {
    this.setState({
      showConfirmForceOverlap: {
        title: I18n.t('bookingdetail.confirm_force_overlap'),
        body: I18n.t('bookingdetail.confirm_force_overlap_message'),
        buttonTitle: I18n.t('bookingdetail.Yes'),
        closeButtonClass: 'btn-cancel',
        closeButtonText: I18n.t('bookingdetail.No')
      }
    });
  }

  handleCloseConfirmForceOverlapModal = () => {
    this.setState({ showConfirmForceOverlap: null });
  };

  handleConfirmForceOverlapClick = () => {
    this.handleSaveBookingClick(null, true);
    this.setState({ showConfirmForceOverlap: null });
  };

  changeDateMode = (value) => {
    if (value == 'single') {
      this.handleDateChanged('Now')
      this.updateToDate('')
      this.updateFromDate('')
    } else {
      this.handleDateChanged(moment())
    }
    this.setState({ dateMode: value })
  }

  updateFromDate = (value) => {
    let newFromDate = value && value.format('YYYY-MM-DD') || ''
    let { toDate } = this.state
    if (newFromDate) {
      if (toDate && moment(newFromDate) > moment(toDate)) {
        this.setState({ fromDate: newFromDate, toDate: newFromDate })
        return
      }
      this.setState({ fromDate: newFromDate })
    }
  }

  updateToDate = (value) => {
    let newToDate = value && value.format('YYYY-MM-DD') || ''
    let { fromDate } = this.state
    if (newToDate) {
      if (fromDate && moment(newToDate) < moment(fromDate)) {
        this.setState({ toDate: newToDate, fromDate: newToDate })
        return
      }
      this.setState({ toDate: newToDate })
    }
  }

  onChangeWeekDays = (weekDays) => {
    if (!weekDays) return
    this.setState({ weekDays: weekDays })
  }

  reOrderPuPoint = (listOrder) => {
    let pointOrdered = []
    // set order methoad of Point
    if (listOrder.length > 0) {
      pointOrdered = listOrder.map((point, id) => {
        point.order = id + 1
        return point
      })
    }
    return pointOrdered
  }

  reOrderDoPoint = (puPoints, doPoints) => {
    let pointOrdered = []
    if (doPoints.length > 0) {
      pointOrdered = doPoints.map((point, id) => {
        point.order = puPoints.length + ++id
        return point
      })
    }
    return pointOrdered
  }

  setNewPuPoint = (newPoints = []) => {
    let { doPoints } = this.state
    if (newPoints.length > 0) {
      let firstPuPoint = newPoints[0]
      this.setState(
        {
          locationPickUp: firstPuPoint.address,
          addressPickUp: firstPuPoint.addressPointText,
          loadingPickUp: true,
          pickupFrom: firstPuPoint.pickupFrom,
          center: firstPuPoint.address,
          addressDetailsPickUp: firstPuPoint.addressDetails,
          company: (this.props.auth.user.userType == userType.FleetAdmin
            || this.props.auth.user.userType == userType.FleetUser) ?
            firstPuPoint.sender.company : this.state.company,
          customer: firstPuPoint.sender.customer,
          data: {
            ...this.state.data,
            email: firstPuPoint.sender.email,
            phone: firstPuPoint.sender.phone,
            firstname: firstPuPoint.sender.firstname,
            lastname: firstPuPoint.sender.lastname,
            isCustomerVip: firstPuPoint.sender.isCustomerVip,
            outStanding: firstPuPoint.sender.outStanding,
            customer: firstPuPoint.sender.customer,
            credits: firstPuPoint.sender.credits,
            traveler_type: firstPuPoint.sender.traveler_type,
          },
        },
        () => {
          this.carTypeBaseLocation().then(data => {
            if (data.ok && data.res) {
              this.carTypeBaseLocationDataPaser(data);
            }
          });
        }
      );
    } else {
      this.setState({
        locationPickUp: null,
        addressPickUp: '',
        loadingPickUp: false,
        isCheckSupportLocation: false
      });
    }
    this.setState(
      {
        puPoints: this.reOrderPuPoint(newPoints),
        doPoints: this.reOrderDoPoint(newPoints, doPoints)
      }, () => {
        this.getDirectionAndDraw();
      }
    )
  }

  handleAddNewCustomerSuccess = customer => {
    this.setState({
      customer,
      firstname: '',
      lastname: '',
      phone: '',
      email: ''
    })
  }

  setNewDoPoint = (newPoints = []) => {
    if (newPoints.length > 0) {
      let lastPoPoint = newPoints[newPoints.length - 1];
      let promo = this.state.promo;
      let promoText = this.state.data.promo;
      if (this.state.doPoints.length > 0 && this.state.doPoints[0].address) {
        promo = null;
        promoText = '';
      }
      this.setState(
        {
          promo: promo,
          data: { ...this.state.data, promo: promoText },
          locationDestination: lastPoPoint.address,
          loadingDestination: true,
          pickupFrom: lastPoPoint.pickupFrom,
          center: lastPoPoint.address,
          addressDetailsDestination: lastPoPoint.addressDetails
        },
        () => {
          this.carTypeBaseLocation().then(data => {
            if (data.ok) {
              if (data.res && data.res.crossZone) {
                this.state.data.dispatch3rd = false;
                this.getCrossZoneBaseLocation();
              }
            }
          });
        }
      );
    } else {
      this.setState({
        locationDestination: null,
        loadingDestination: false,
      });
    }
    const newDoPoints = this.reOrderDoPoint(this.state.puPoints, newPoints);
    if (newDoPoints.length > 1 && this.state.data.paymentMethod === 17) {
      this.setState({
        doPoints: newDoPoints,
        data: { ...this.state.data, paymentMethod: 0 },
      },
        () => {
          this.getDirectionAndDraw();
        }
      )
    } else {
      this.setState({
        doPoints: newDoPoints
      },
        () => {
          this.getDirectionAndDraw();
        }
      )
    }
  }

  setRecurringTime = (time) => {
    this.setState({ recurringTime: time })
  }

  setRecurringDays = (days = []) => {
    this.setState({ recurringDays: days });
  };

  handleAddtService = e => {
    const { value = 0 } = e.target || {};
    this.setState({
      data: {
        ...this.state.data,
        addtService: value
      }
    });
  };

  handleRideSharingChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        rideSharing: e.target.checked,
      },
    });
  };

  render() {
    const { auth, commonData } = this.props;
    const {
      customer,
      data,
      company
    } = this.state;
    const showNavigationPUDO = _.get(auth, 'selectedFleet.generalSetting.showNavigationPUDO', false);
    const limitPoint = { maxDropOff: _.get(auth, 'selectedFleet.delivery.maxRecipientsOperatorInput', 1) };
    const hasRecurring = _.get(auth, 'selectedFleet.recurring.enable', false)
    const { location } = commonData;
    const { fleetId } = auth && auth.selectedFleet || {}
    const userType = _.get(auth, 'user.userType', '')
    const companyList = filterCompanyList(data.traveler_type, company, [...commonData.companies, ...commonData.suppliers], userType)
    const bookingCurrency = this.props.newBooking
      && this.props.newBooking.locationService
      && this.props.newBooking.locationService.currency
      ? this.props.newBooking.locationService.currency
      : this.props.auth.selectedFleet.currency;
    return (
      <ReactCSSTransitionGroup
        transitionName="bounceOutLeft"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
        transitionAppear
        transitionLeave
      >
        {this.state.isShowCancelMessage ? (
          <Modal
            show
            backdrop
            key="modal-form"
            dialogClassName="confirm-dialog"
            className="confirm"
            onHide={this.handleCancelBookingClick}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="newbooking.CANCEL_BOOKING" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Translate value="newbooking.CANCEL_BOOKING_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.props.onClose();
                }}
                className="btn-save"
              >
                <Translate value="newbooking.Yes" />
              </Button>
              <Button className="btn-cancel" onClick={this.handleCancelBookingClick}>
                <Translate value="newbooking.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}

        <Confirm
          confirm={this.state.showConfirmForceOverlap}
          handleConfirmCloseClick={this.handleCloseConfirmForceOverlapModal}
          handleConfirmButtonClick={this.handleConfirmForceOverlapClick}
        />
        <div key="newbooking-form" className="newbooking-form newbookingDelivery">
          <div className="bookingTitle">
            <span className="bookingTitle__text"><Translate value="Sidebar.Delivery" /></span>
          </div>
          <Container fluid>
            <Row>
              <Col xs={12} md={12} className="mt">
                <Container fluid>
                  <Row>
                    <Col xs={12} md={4} className="info-content">
                      <DateMode
                        pickupTime={this.state.data.time}
                        cartypeSelected={this.state.cartypeSelected}
                        nowButton
                        data={this.state.data}
                        format24Hour={auth.selectedFleet && auth.selectedFleet.format24Hour}
                        changeDateMode={this.changeDateMode}
                        onChangePickUpHour={this.onChangePickUpHour}
                        onChangePickUpMin={this.onChangePickUpMin}
                        handleDateChanged={this.handleDateChanged}
                        dateMode={this.state.dateMode}
                        onChangeWeekDays={this.onChangeWeekDays}
                        weekDays={this.state.weekDays}
                        hasRecurring={hasRecurring}
                        isSubmited={this.state.isSubmited}
                        valid={this.state.valid}
                        jobType={'delivery'}
                        updateToDate={this.updateToDate}
                        updateFromDate={this.updateFromDate}
                        toDate={this.state.toDate}
                        fromDate={this.state.fromDate}
                        recurringTime={this.state.recurringTime}
                        setRecurringTime={this.setRecurringTime}
                        ValidatorCallback={this.ValidatorCallback}
                        setRecurringDays={this.setRecurringDays}
                        recurringDays={this.state.recurringDays}
                      />
                      <FormGroupTitle>
                        <Translate value="newbooking.BOOKING_INFORMATION" />
                      </FormGroupTitle>
                      <RouteInfo
                        route={this.state.route}
                        puPoints={this.state.puPoints}
                        doPoints={this.state.doPoints}
                        setNewPuPoint={this.setNewPuPoint}
                        limitPoint={limitPoint}
                        setNewDoPoint={this.setNewDoPoint}
                        jobType={'delivery'}
                        recentPlaces={
                          customer && customer.passengerInfo && customer.passengerInfo.recentPlaces
                        }
                        checkPointInZoneSupported={this.props.newbookingActions.checkZoneSupportedWithPoint}
                        fleetId={fleetId}
                        autocompleteCustomer={this.props.messageAction.autocompleteCustomer}
                        format24Hour={auth.selectedFleet && auth.selectedFleet.format24Hour}
                        auth={auth}
                        newBooking={this.props.newBooking}
                        cueActions={this.props.cueActions}
                        corporateCompany={this.props.corporateCompany}
                        newbookingActions={this.props.newbookingActions}
                        customerActions={this.props.customerActions}
                        data={this.state.data}
                        isSubmited={this.state.isSubmited}
                        valid={this.state.valid}
                        suggestions={this.state.suggestions}
                        promo={this.state.promo}
                        phoneStatus={this.state.phoneStatus}
                        customer={this.state.customer}
                        traveler_type={this.state.traveler_type}
                        cartypeSelected={this.state.cartypeSelected}
                        paymentMethod={this.props.paymentMethod}
                        customerAutocompleteActions={this.props.customerAutocompleteActions}
                        customerAutocomplete={this.props.customerAutocomplete}
                        options={this.state.optionAutocomplete}
                      />
                    </Col>
                    <Col xs={12} md={4} className="info-content">
                      <BookingInfoDelivery
                        driverCompany={this.state.driverCompany}
                        handleCompanyDriverChange={this.handleCompanyDriverChange}
                        companyList={companyList}
                        nearestDriverList={this.state.nearestDriverList || []}
                        etaFareMultiCar={this.state.etaFareMultiCar || []}
                        commonData={this.props.commonData}
                        locationPickUp={this.state.locationPickUp}
                        newBooking={this.props.newBooking} // new booking common data
                        user={this.props.auth && this.props.auth.user} // logged user
                        selectedFleet={this.props.auth && this.props.auth.selectedFleet} // current fleet
                        data={this.state.data} // booking form data
                        cartypeSelected={this.state.cartypeSelected}
                        handleCarTypeChanged={this.handleCarTypeChanged}
                        nearestDriver={this.state.nearestDriver}
                        time={this.state.data.time}
                        etaFareProps={this.props.etaFare}
                        etaFareState={this.state.etaFare}
                        locationDestination={this.state.locationDestination}
                      />

                      <DispatchInfoDelivery
                        user={this.props.auth && this.props.auth.user}
                        data={this.state.data}
                        handleRideSharingChange={this.handleRideSharingChange}
                        handleChangeDispatchType={this.handleChangeDispatchType}
                        newBooking={this.props.newBooking}
                        isSubmited={this.state.isSubmited}
                        driversuggestions={this.state.driversuggestions}
                        onDriverSuggestionsFetchRequested={this.onDriverSuggestionsFetchRequested}
                        onDriverSuggestionsClearRequested={this.onDriverSuggestionsClearRequested}
                        driverAutocompleteSlectedHandle={this.driverAutocompleteSlectedHandle}
                        handleDriverChanged={this.handleDriverChanged}
                        handleAddtService={this.handleAddtService}
                        cartypeSelected={this.state.cartypeSelected}
                        driver={this.state.driver}
                        bookingCurrency={bookingCurrency}
                        selectedFleet={this.props.auth && this.props.auth.selectedFleet}
                        handleDispatch3rdOnchange={this.handleDispatch3rdOnchange}
                        ValidatorCallback={this.ValidatorCallback}
                        valid={this.state.valid}
                        handleDriverCalendarClick={this.handleDriverCalendarClick}
                        handleChangeNote={this.handleChangeNote}
                      />
                      <PromoInfo
                        jobType={'delivery'}
                        handleChangePromo={this.handleChangePromo}
                        data={this.state.data}
                        promo={this.state.promo}
                        newBooking={this.props.newBooking}
                        locationPickUp={this.state.locationPickUp}
                        handlePromoApplyClick={this.handlePromoApplyClick}
                        valid={this.state.valid}
                        isSubmited={this.state.isSubmited}
                        selectedFleet={this.props.auth && this.props.auth.selectedFleet}
                        bookingCurrency={bookingCurrency}
                        ValidatorCallback={this.ValidatorCallback}
                      />
                      <PaymentInfo
                        data={this.state.data}
                        handleChangeBookingReference={this.handleChangeBookingReference}
                        handlePaymentMethodChange={this.handlePaymentMethodChange}
                        paymentMethod={this.props.paymentMethod}
                        newBooking={this.props.newBooking}
                        corporateTypes={this.props.paymentMethod.corporateTypes}
                        isSubmited={this.state.isSubmited}
                        valid={this.state.valid}
                        handleCreditCardChange={this.handleCreditCardChange}
                        handleAddCardSuccess={this.handleAddCardSuccess}
                        reloadCustomerTSYSGateway={this.reloadCustomerTSYSGateway}
                        reloadCorporateCompany={this.reloadCorporateCompany}
                        credit={this.state.credit}
                        customer={this.state.customer}
                        selectedFleet={this.props.auth && this.props.auth.selectedFleet}
                        auth={this.props.auth}
                        company={this.state.company}
                        handleClientCaseMatterChange={this.handleClientCaseMatterChange}
                        handleChangeOperatorNote={this.handleChangeOperatorNote}
                        handleChargeCodeChange={this.handleChargeCodeChange}
                        ValidatorCallback={this.ValidatorCallback}
                        locationPickUp={this.state.locationPickUp}
                        paymentGetwayDisabledNewBooking={paymentGetwayDisabledNewBooking}
                        handleAddNewCustomerSuccess={this.handleAddNewCustomerSuccess}
                        isDelivery={true}
                        doPoints={this.state.doPoints}
                        companySelected={this.state.driverCompany}
                      />
                    </Col>
                    <Col xs={12} md={4} className="info-content">
                      <div className="booking-map-container">
                        <BookingMapChauffeur
                          showNavigationPUDO={showNavigationPUDO}
                          handleMapLoad={this.handleMapLoad}
                          center={this.state.center}
                          locationPickUp={this.state.locationPickUp}
                          puPoints={this.state.puPoints}
                          doPoints={this.state.doPoints}
                          handlePickupDrapEnd={this.handlePickupDrapEnd}
                          pickupFrom={this.state.pickupFrom}
                          locationDestination={this.state.locationDestination}
                          handleDestinationDrapEnd={this.handleDestinationDrapEnd}
                          destinationFrom={this.state.destinationFrom}
                          directions={this.state.directions}
                          isChina={location ? location.isChina : false}
                        />
                      </div>
                      <TripEstimateDelivery
                        cartypeSelected={this.state.cartypeSelected}
                        newBooking={this.props.newBooking}
                        destinationFrom={this.state.destinationFrom}
                        addressDestination={this.state.addressDestination}
                        data={this.state.data}
                        etaFare={this.state.etaFare}
                        reasonMarkup={this.state.reasonMarkup}
                        markupType={this.state.markupType}
                        markupValue={this.state.markupValue}
                        promo={this.state.promo}
                        selectedFleet={this.props.auth && this.props.auth.selectedFleet}
                        user={this.props.auth && this.props.auth.user}
                        disDur={this.state.disDur}
                        locationPickUp={this.state.locationPickUp}
                        locationDestination={this.state.locationDestination}
                        bookingPermission={this.props.bookingPermission}
                        prevEtaFare={this.state.prevEtaFare}
                        doPoints={this.state.doPoints}
                        handleChangeETAFare={this.handleChangeETAFare}
                        handleChangeMarkupPrice={this.handleChangeMarkupPrice}
                        settingActions={this.props.settingActions}
                        commonData={this.props.commonData}
                        driver={this.state.driver}
                      />
                      <div style={{marginTop: "20px"}}>
                        <TrailNotesNewBooking
                            notification={this.context.notification}
                            listTrailNotes={this.state.trailNotes}
                            handleAddTrailNotes={this.handleAddTrailNotes}
                            handleDeleteTrailNotes={this.handleDeleteTrailNotes}
                            uploadService={this.props.uploadService}
                            fleetId={this.props.auth.selectedFleet.fleetId}
                          />
                      </div>
                      <div xs={12} className="new-booking-button-container">
                        <ButtonToolbar className="text-center">
                          <Button
                            className="btn-save"
                            onClick={e => this.handleSaveBookingClick(e)}
                            disabled={
                              Validation.isStringEmpty(this.state.addressPickUp)
                              || (this.state.data.dispatch_type == 1 && this.state.data.driver == null)
                              || (this.state.data.paymentMethod == 0)
                              || Validation.isStringEmpty(this.state.locationDestination)
                            }
                          >
                            <Translate value="newbooking.Create" />
                          </Button>
                          <Button className="btn-cancel" onClick={this.handleCancelBookingClick}>
                            <Translate value="newbooking.Cancel" />
                          </Button>
                        </ButtonToolbar>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs={12} md={5} style={{ height: '100%' }}>
                <div style={{ clear: 'both' }} />
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}
NewBooking.contextTypes = {
  notification: PropTypes.func
};
NewBooking.propTypes = {
  auth: PropTypes.object,
  newBooking: PropTypes.object,
  deliveryBooking: PropTypes.object
};

function mapStateToProps(state) {
  return {
    deliveryBooking: state.deliveryBooking,
    newBooking: state.newBooking,
    auth: state.auth,
    customerAutocomplete: state.customerAutocomplete,
    corporateCompany: state.corporateCompany,
    driverAutocomplete: state.driverAutocomplete,
    paymentMethod: state.paymentMethod,
    etaFare: state.etaFare,
    socket: state.socket,
    commonData: state.commonData,
    loadingBar: state.loadingBar,
    bookingPermission: state.menuHandle.bookingPermission,
    language: state.i18n
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    deliveryAction: bindActionCreators(deliveryAction, dispatch),
    queuingAreaActions: bindActionCreators(queuingAreaActions, dispatch),
    customerAutocompleteActions: bindActionCreators(customerAutocompleteActions, dispatch),
    corporateCompanyActions: bindActionCreators(corporateCompanyActions, dispatch),
    paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
    driverAutocompleteActions: bindActionCreators(driverAutocompleteActions, dispatch),
    etaFareActions: bindActionCreators(etaFareActions, dispatch),
    creditCardActions: bindActionCreators(creditCardActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
    cueActions: bindActionCreators(cueActions, dispatch),
    corporateActions: bindActionCreators(corporateActions, dispatch),
    tencentMapActions: bindActionCreators(tencentMapActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
    messageAction: bindActionCreators(messageAction, dispatch),
    uploadService: bindActionCreators(uploadActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBooking);
