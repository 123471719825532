import React, { PureComponent } from 'react';
import _ from 'lodash';
import currencyFormatter from 'currency-formatter';
import { Translate } from 'react-redux-i18n';
import { roundOff } from '../../../utils/commonFunctions';

export default class EstimatedFareColumn extends PureComponent {
  render() {
    const { booking, auth, lineShort, isMobile } = this.props;
    let estimateFare = '';

    // SL-9047 [New CC (N)] Show fare on CC when Driver already dropped off
    if (booking.status === 'droppedOff' && _.get(booking, 'droppedOffInfo.total')) {
      const total = _.get(booking, 'droppedOffInfo.total');
      const totalRoundOff = roundOff(total, booking.currencyISO, auth.selectedFleet.rounding);
      estimateFare = total ? currencyFormatter.format(totalRoundOff, { code: booking.currencyISO }) : '';
    } else if (booking.pricingType === 0) {
      const etaFare = _.get(booking, 'request.estimate.fare.etaFare', 0);
      const etaFareRoundOff = roundOff(etaFare, booking.currencyISO, auth.selectedFleet.rounding);
      estimateFare = booking.request.estimate.fare && !_.isEmpty(booking.request.estimate.fare)
        ? currencyFormatter.format(etaFareRoundOff, {
          code: booking.currencyISO
        })
        : '';
    } else if(booking.pricingType === 1) { // case Hydra booking
      // fare for Farm In
      let etaFare = _.get(booking, 'request.estimate.fare.etaFare', 0);
      if(booking.request.psgFleetId === auth.selectedFleet.fleetId) {
        // fare for farmOut or Roaming
        etaFare = _.get(booking, 'request.estimate.fare.sellPriceMarkup', 0);
      }
      const etaFareRoundOff = roundOff(etaFare, booking.currencyISO, auth.selectedFleet.rounding);
      estimateFare = booking.request.estimate.fare && !_.isEmpty(booking.request.estimate.fare)
        ? currencyFormatter.format(etaFareRoundOff, {
          code: booking.currencyISO
        })
        : '';
    }
    let walletName = _.get(booking, 'request.walletName', '')

    let isFareEdited = false;
    if (booking.request.estimate && (booking.request.estimate.isFareEdited || booking.request.estimate.markupValue)) isFareEdited = true;
    
    const paymentUI = (
      <p 
        className="text-ellipsis fareActive"
      >
        {
          booking.request.paymentType === 21 
          ? walletName
          : <Translate value={`paymentMethod.paymentMethod_${booking.request.paymentType}`} />
        }
      </p>
    )

    if(isMobile) {
      return (
        <>
          {paymentUI}
          <p className='fareActive'>{estimateFare}</p>
        </>
      )
    }

    return (
      <div className={`estimate-fare ${lineShort ? " custom__lineShort" : ""}`}>
        {booking.request && booking.request.paymentType ? (
          <p className="text-ellipsis">
            {
              booking.request.paymentType === 21 
              ? walletName
              : <Translate value={`paymentMethod.paymentMethod_${booking.request.paymentType}`} />
            }
          </p>
        ) : null}
        {estimateFare || parseInt(estimateFare) === 0 ?
          <p className={`text-ellipsis  ${booking.request.estimate.fare && booking.request.estimate.fare.addOnPrice && booking.request.estimate.fare.addOnPrice !== 0 ? booking.request.estimate.fare.addOnPrice > 0 ? 'adjust' : 'adjust-slow' : null}`}>
            {estimateFare} {' '}
            {isFareEdited && <i className="fa fa-pencil" />}
          </p>
          : null}
      </div>
    );
  }
}
