/* global google */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import IntercityBookingQQMap from './IntercityBookingQQMap';
import IntercityBookingGGMap from './IntercityBookingGGMap';
import { QQUltis } from '../../../components/qqMap';
import { LOCATION_TYPE } from '../../../constants/commondata';
import { getLocationWhenDrapEnd } from '../../../components/bookingDetail/bookFunction/bookingInfo';

class MapForm extends Component {
  constructor(e) {
    super(e);
  }

  componentWillMount() {
    let center = null;
    let bound = null;
    const {
      location: { isChina },
      commonData,
    } = this.props;
    if (isChina) {
      var latLng = new window.qq.maps.LatLngBounds();
      let defaultMap = (commonData.mapZone || []).filter((m) => m.isDefault)[0];
      defaultMap = defaultMap || commonData.mapZone[0];
      if (defaultMap) {
        defaultMap.geo.coordinates.map((cor) => {
          cor.map((z) => {
            latLng.extend(new window.qq.maps.LatLng(z[1], z[0]));
          });
        });
      }
      const center = latLng.getCenter();
      if ((commonData.mapZone || []).filter((m) => m.isDefault)[0]) {
        bound = new window.qq.maps.Circle({
          radius: 100000,
          center,
        }).getBounds();
      }
    } else {
      var latLng = new google.maps.LatLngBounds();
      let defaultMap = (commonData.mapZone || []).filter((m) => m.isDefault)[0];
      defaultMap =
        defaultMap ||
        (commonData && commonData.mapZone && commonData.mapZone[0]);
      if (defaultMap) {
        defaultMap.geo.coordinates.map((cor) => {
          cor.map((z) => {
            latLng.extend(new google.maps.LatLng(z[1], z[0]));
          });
        });
      }
      center = latLng.getCenter();
      if ((commonData.mapZone || []).filter((m) => m.isDefault)[0]) {
        bound = new google.maps.Circle({
          radius: 100000,
          center,
        }).getBounds();
      }
    }
    this.setState({ center, bound });
  }

  getCenter() {
    const { pickupLocation, destinationLocation } = this.props.common;
    const { location: { isChina } } = this.props;
    if (!pickupLocation && !destinationLocation) {
      return null;
    }
    if (isChina) {
      let latLng = new window.qq.maps.LatLngBounds();
      if(pickupLocation) {
        latLng.extend(new window.qq.maps.LatLng(pickupLocation.lat, pickupLocation.lng));
      }
      if(destinationLocation) {
        latLng.extend(new window.qq.maps.LatLng(destinationLocation.lat, destinationLocation.lng));
      }
      if (pickupLocation && destinationLocation) {
        this.map && this.map.fitBounds(latLng)
      }
      return latLng.getCenter();
    } else {
      let latLng = new google.maps.LatLngBounds();
      if(pickupLocation) {
        latLng.extend(new google.maps.LatLng(pickupLocation.lat, pickupLocation.lng));
      }
      if(destinationLocation) {
        latLng.extend(new google.maps.LatLng(destinationLocation.lat, destinationLocation.lng));
      }
      if (pickupLocation && destinationLocation) {
        this.map && this.map.fitBounds(latLng)
      }
      return latLng.getCenter();;
    }
  }

  handleMapLoad = ref => {
    this.map = ref
    if (this.map && this.props?.commonData?.location?.isChina) {
      window.qq.maps.event.addListener(this.map, 'bounds_changed', () => {
        this.state.center = this.map.center;
      });
    }
  }

  handlePickupDrapEnd = (results, status) => {
    getLocationWhenDrapEnd({
      results, 
      callback: this.pickupDrapEndCallback, 
      auth: this.props.auth, 
      language: this.props?.language,
      bookId: this.props?.bookInfo?.bookId,
      isTencent: this.props.commonData?.location?.isChina
    })
  };

  pickupDrapEndCallback = (place, location) => {
    this.props.intercityBookingActions.updateCommonData({
      pickupLocation: location,
      pickupAddress: place ? place.formatted_address : '',
      pickupFrom: location.isChina ? LOCATION_TYPE.Tencent : LOCATION_TYPE.Google,
    });
  };

  handleDestinationDrapEnd = (results, status) => {
    getLocationWhenDrapEnd({
      results, 
      callback: this.destinationDrapEndCallback, 
      auth: this.props.auth, 
      language: this.props?.language, 
      bookId: this.props?.bookInfo?.bookId,
      isTencent: this.props.commonData?.location?.isChina
    })
  };

  destinationDrapEndCallback = (place, location) => {
    this.props.intercityBookingActions.updateCommonData({
      destinationLocation: location,
      destinationAddress: place ? place.formatted_address : '',
      destinationFrom: location.isChina
        ? LOCATION_TYPE.Tencent
        : LOCATION_TYPE.Google,
    });
  };

  render() {
    const {
      common,
      location: { isChina },
    } = this.props;
    const { pickupLocation, destinationLocation, pickupFrom, destinationFrom } = common;
    const center = this.getCenter()
    if (isChina) {
      return (
        <IntercityBookingQQMap
          onMapLoad={this.handleMapLoad}
          pickupFrom={pickupFrom}
          pickupLocation={pickupLocation}
          center={center || this.state.center}
          destinationLocation={destinationLocation}
          destinationFrom={destinationFrom}
          handleDestinationDrapEnd={this.handleDestinationDrapEnd}
          handlePickupDrapEnd={this.handlePickupDrapEnd}
        />
      );
    }
    return (
      <IntercityBookingGGMap
        onMapLoad={this.handleMapLoad}
        center={center || this.state.center}
        pickupLocation={pickupLocation}
        pickupFrom={pickupFrom}
        destinationLocation={destinationLocation}
        destinationFrom={destinationFrom}
        handleDestinationDrapEnd={this.handleDestinationDrapEnd}
        handlePickupDrapEnd={this.handlePickupDrapEnd}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet,
    common: state.intercityBooking.common,
    location: state.commonData.location,
    commonData: state.commonData,
    language: state.i18n,
    bookInfo: state.bookingDetail.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MapForm);
