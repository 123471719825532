import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import qs from 'query-string';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import StickyTable from '../../components/table/stickyTable/StickyTable';
import { topDrivers, exportTopDrivers } from '../../actions/smartDataActions';
import { Columns } from './tableHeaderColums';
import SmartDataFilter from './SmartDataFilter';
import { bookingDetailsPaymentMethods } from '../../constants/commondata';
import { socketConfigs } from '../../constants/socketConfigs';
import { socketLocalApi } from '../../utils/socketUtils';
import './smartData.scss';

class SmartData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topDriversData: [],
      queryObject: {},
      sortType: -1,
      tableHeight: 500,
      rowHeight: 50
    };
  }

  getTableHeight = () => {
    const verticalPadding = 10;
    const toolbarMarginBottom = 10;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    const outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && tableHeight < 500) tableHeight = 550;
    this.state.tableHeight = tableHeight;
    return tableHeight;
  };

  handleSortClick = sortName => {
    if (this.state.isLoading) return;
    const { topDriversData, sortType } = this.state;
    const sortedList = _.orderBy(
      topDriversData,
      [sortName],
      [sortType === -1 ? 'asc' : 'desc']
    );
    this.setState({
      sortName,
      sortType: sortType === 1 ? -1 : 1,
      topDriversData: sortedList
    });
  };

  getShownColumns = () => {
    const { selectedFleet } = this.props;
    const { queryObject } = this.state;
    const columns = [];
    Columns.forEach(col => {
      if (col.key === 'earning' && selectedFleet.multiCurrencies) {
        return;
      }

      if (['completed', 'noShow', 'incident'].includes(col.key)) {
        col.customCell = doc => {
          const params = {
            driverId: doc.driverId,
            paymentMethod: [col.key],
            fromDate: queryObject.fromDate,
            toDate: queryObject.toDate
          };
          if (col.key === 'completed') {
            params.paymentMethod = _.map(bookingDetailsPaymentMethods, 'value').filter(s => !['all', 'canceled', 'noShow', 'incident'].includes(s));
          }
          return (
            <Link
              target="_blank"
              className="text-active"
              to={`/reports/Booking_details?${qs.stringify(params)}`}
            >
              {doc[col.key]}
            </Link>
          );
        };
      }

      columns.push(col);
    });
    return columns;
  };

  onExport = () => {
    const { selectedFleet } = this.props;
    const { queryObject } = this.state;
    const query = {
      fleetId: this.props.selectedFleet.fleetId,
      criteria: {
        ...queryObject,
        getEarning: !selectedFleet.multiCurrencies,
        timezone: selectedFleet.timezone
      }
    };
    this.setState({ showPercent: true, exportPercent: 0 });
    this.props.exportTopDriversData(query).then(resp => {
      if (!resp.error && resp.res) {
        socketLocalApi.remove(socketConfigs.receive.report.downloadProgress);
        socketLocalApi.on(socketConfigs.receive.report.downloadProgress, data => {
          const { showPercent } = this.state;
          if (data.sessionName === resp.res.sessionName && showPercent) {
            if (data.percent === 100) {
              socketLocalApi.remove(socketConfigs.receive.report.downloadProgress);
            }
            this.setState({
              exportPercent: data.percent,
              showPercent: data.percent !== 100
            });
            if (data && data.percent === 100) {
              window.location = data.url;
            }
          }
        });
      } else {
        this.context.notification('error', I18n.t(`errors.${resp.error.errorCode}`));
      }
    });
  }

  prepareParamExport = () => {
    const { selectedFleet } = this.props;
    const { queryObject } = this.state;
    const query = {
      fleetId: this.props.selectedFleet.fleetId,
      criteria: {
        ...queryObject,
        getEarning: !selectedFleet.multiCurrencies,
        timezone: selectedFleet.timezone
      },
      total: this.state.total || 0
    };
    return query
  }

  handleSubmit = params => {
    const { selectedFleet } = this.props;
    const query = {
      fleetId: this.props.selectedFleet.fleetId,
      criteria: {
        ...params,
        getEarning: !selectedFleet.multiCurrencies
      }
    };
    this.setState({ isLoading: true, queryObject: query.criteria });
    this.props.fetchTopDrivers(query).then(data => {
      if (data.ok) {
        this.setState({
          topDriversData: data.list,
          isLoading: false,
          sortName: '',
          total: data.total || 0
        });
      }
    });
  };

  onFilterRef = (ref) => {
    this.toobarContainer = ref;
  }

  render() {
    const { topDriversData, showPercent, exportPercent } = this.state;
    const {
      language: { locale: language }, permissions
    } = this.props || {};
    const exportPermit = !permissions || permissions.actions;
    return (
      <div className="content">
        <SmartDataFilter
          ref={this.onFilterRef}
          language={language}
          onSubmit={this.handleSubmit}
          auth={this.props.auth}
          urlExport={this.props.exportTopDriversData}
          prepareParamExport={this.prepareParamExport}
          exportPermit={exportPermit}
        />
        <div className="gridViewTable">
          <StickyTable
            columns={this.getShownColumns()}
            bodyData={topDriversData}
            noPagination
            rowHeight={this.state.rowHeight}
            sortHandle={this.handleSortClick}
            sortType={this.state.sortType}
            sortName={this.state.sortName}
            getTableHeight={this.getTableHeight}
            isLoading={this.state.isLoading}
          />
        </div>
      </div>
    );
  }
}

SmartData.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    auth: state.auth,
    language: state.i18n,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTopDrivers: options => dispatch(topDrivers(options)),
    exportTopDriversData: options => dispatch(exportTopDrivers(options))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SmartData);
