import React, { Component } from 'react';
import _ from 'lodash';
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Image
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';

import CcCheckbox from '../../ccCheckbox/CcCheckbox';
import { CCLiteCommonFunc, Validation, calFleetORAdditionalServicePayment, checkEnableTechFee, checkShowPendingPaymentCompleteBooking } from '../../../utils/commonFunctions';
import { Validator, ValidCase, WarningFeeInput } from '../../validator';
import LocationInput from './LocationInput';
import RadioButton from '../../radioButton/radio';
import destinationIcon from '../../../assets/images/icons/destination2.svg';
import pickupIcon from '../../../assets/images/icons/pickup2.svg';
import CompleteWithPayment from './CompleteWithPayment';
import { 
  checkRemoveGoogleOrApplePay, 
  calulatePreAuthoried 
} from '../bookFunction/payment';
import CompleteForBookEditedFare from './CompleteWithPayment/CompleteForBookEditedFare';
import { GroupFleetServiceSelected } from './ExtraServiceInfo';
import { REGULAR_MODE, TIME_OR_MILEAGE_BASIC_FARE_FIELD } from '../../../constants/commondata';

const currencyFormatter = require('currency-formatter');

const ServiceType = {
  Optional: 'Optional',
  Compulsory: 'Compulsory'
};

const PaymentMethodOption = ({
  method,
  bookData,
  selectedFleet,
  isAllwayShowGoogleApplePay,
}) => {
  const gateWay = _.get(selectedFleet, 'applePayConfig.gateway', '');

  // chỉ show google hoặc apple pay khi request là 1 trong 2 
  const paymentRemoves = isAllwayShowGoogleApplePay
    ? checkRemoveGoogleOrApplePay(
        bookData.request.paymentType,
        isAllwayShowGoogleApplePay
      )
    : [9, 23];
  if (
    method.value === bookData.request.paymentType &&
    (!paymentRemoves?.includes(method?.value) ||
      ([9].includes(method.value) &&
        bookData.status === 'droppedOff' &&
        gateWay === 'PayEase')) &&
    ![14].includes(method.value) &&
    method.value !== 1
  ) {
    return (
      <option key={method.value} value={method.value}>
        {I18n.t(`General.patmentMethod_${method.value}`)}
      </option>
    );
  }
  return null;
};

class CompleteWithPaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFocus: '',
      showExtraServices: false
    };
  }

  setFocus = focus => {
    this.setState({
      currentFocus: focus
    });
  };

  onBlur = () => {
    this.setState({
      currentFocus: ''
    });
  };

  parseInputValue = (inputName, value) => {
    const { currentFocus } = this.state;
    const { data } = this.props;
    if (value) {
      value = value.toString();
      if (inputName == currentFocus) return value.replace(/[^.0-9]+/, '') || 0;
      return currencyFormatter.format(value, {
        code: data.currencyISO,
        format: '%v'
      });
    }
    if (currentFocus == inputName) {
      return '';
    }

    return currencyFormatter.format(0, {
      code: data.currencyISO,
      format: '%v'
    });
  };

  getTextSubmitButton = paymentComplete => (paymentComplete.isPending
    ? I18n.t('bookingdetail.Complete_booking')
    : I18n.t('bookingdetail.Pay_here'));

  getSurChargeAmount = (estimateFare, isNumber = false) => {
    const { paymentComplete, rushHour, data } = this.props;
    let totalSurcharge = 0;
    if (paymentComplete.rushHourChecked && rushHour) {
      const surChargeAmount = this.getSurchargeFeeAmount(estimateFare);
      const dynamicSurchargeAmount = this.getDynamicSurchargeAmount(estimateFare);
      totalSurcharge = surChargeAmount + dynamicSurchargeAmount;
    }

    return isNumber
      ? totalSurcharge
      : currencyFormatter.format(totalSurcharge, { code: data.currencyISO });
  };

  getSurchargeFeeAmount = estimateFare => {
    const { rushHour } = this.props;
    return rushHour.surchargeType === 'amount'
      ? rushHour.surchargeFee
      : (rushHour.surchargeFee * (estimateFare ? estimateFare.basicFare : 0)) / 100;
  };

  getDynamicSurchargeAmount = estimateFare => {
    // Surcharge amount = basic_fare X (parameter - 1.0)
    const { rushHour } = this.props;
    const basicFare = _.get(estimateFare, 'basicFare', 0);
    const surchargeParameter = _.get(rushHour, 'surchargeParameter', 0);
    return surchargeParameter >= 1 ? basicFare * (surchargeParameter - 1) : 0;
  };

  renderShortTrip = estimateFare => {
    // check enable short trip
    const { fleet } = this.props.fareSettings;
    const isEnableShortTrip = _.get(fleet, 'waiveOffCommission.enable', false);
    if (!isEnableShortTrip) {
      return null;
    }

    const { calculatorTotal, paymentComplete } = this.props;
    const { currencyISO } = this.props.data;
    const applyZone = _.get(fleet, 'waiveOffCommission.applyZone', 'All');
    const zoneId = _.get(this.props.data, 'request.pickup.zoneId');
    let isShortTrip = false;
    let appliedSurcharge = false;
    let waiveOffValue = 0;

    /* SL-13295
    Need to check setting on admin if surcharge is required to consider a booking is short trip.
    * If surcharge is required then combine with other condition to consider a booking is short trip
    * If surcharge is NOT required then check other conditions, no need to check if surcharge > 0
    */

    if (applyZone === 'All') {
      appliedSurcharge = fleet.waiveOffCommission.applySurcharge;
      const shortTripCurrencies = _.get(fleet, 'waiveOffCommission.amountByCurrencies', []);
      const currencyFounded = _.find(shortTripCurrencies, o => o.currencyISO === currencyISO);
      waiveOffValue = currencyFounded ? currencyFounded.value : 0;
    } else {
      const waiveOffByZones = _.get(fleet, 'waiveOffCommission.waiveOffByZones', []);
      const waiveSetting = _.find(waiveOffByZones, o => o.zoneId === zoneId);
      if (waiveSetting) {
        appliedSurcharge = waiveSetting.applySurcharge;
        waiveOffValue = waiveSetting.amount;
      }
    }

    const { rushHourChecked } = paymentComplete;
    let isEnableSurcharge = rushHourChecked;
    if (isEnableSurcharge) {
      isEnableSurcharge = this.getSurChargeAmount(estimateFare, true) > 0;
    }

    const totalWithoutPromo = calculatorTotal(paymentComplete);
    if (((appliedSurcharge && isEnableSurcharge) || !appliedSurcharge) && waiveOffValue > 0 && parseInt(totalWithoutPromo) < waiveOffValue) {
      isShortTrip = true;
    }

    /*
    SL-13680
    check the customize time range which fleet want to apply short trip policy for drivers
      IF pickup time is belong to at least a customized time range THEN short trip can be applied
      ELSE this booking is NOT a short trip
     */
    const applyTimeRange = _.get(fleet, 'waiveOffCommission.applyTimeRange', false);
    if (applyTimeRange) {
      const timeRanges = _.get(fleet, 'waiveOffCommission.timeRanges', []);
      const tzOffset = _.get(this.props.data, 'request.pickup.offset', 'Z')
      const pickUptime = _.get(this.props.data, 'time.pickUpTime');
      const pickUpDate = moment(pickUptime).format('YYYY-MM-DD');
      isShortTrip = isShortTrip && _.findIndex(timeRanges, time => {
        const startTime = moment(`${pickUpDate} ${time.from} ${tzOffset}`);
        const endTime = moment(`${pickUpDate} ${time.to} ${tzOffset}`);
        return moment(pickUptime).isSameOrAfter(startTime) && moment(pickUptime).isSameOrBefore(endTime);
      }) !== -1;
    }

    return isShortTrip ? (
      <span className="ml-sm">
        {`(${I18n.t('bookingdetail.shortTrip')})`}
      </span>
    ) : null;
  };

  openSelectFleetFee = (data) => {
    this.setState({
      showExtraServices: data
    })
  }

  renderBasicFareBasedMode = (isDisable) => {
    const { isHydraBooking, data, handleCompleteBasicFareChange, completeTimeOrMileageFareChange } = this.props,
      estimateFare = isHydraBooking
        ? _.get(data, 'request.estimate.fare.estimateFareBuy', {})
        : data?.request?.estimate?.fare || {};
    return (
      <>
        {estimateFare.mode === REGULAR_MODE.timeOrMileage ? (
          <>
            {
              TIME_OR_MILEAGE_BASIC_FARE_FIELD.map(f => {
                return (
                  <FormGroup>
                    <Form.Label>
                      <Translate value={`cue.editFare.${f}`} />
                    </Form.Label>
                    <div className="input-with-validator">
                      <FormGroup className="qup-input-group">
                        <InputGroup className="single-addon-left">
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {data.currencySymbol}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            className="form-custom"
                            type="number"
                            step="0.1"
                            value={this.parseInputValue(
                              f,
                              estimateFare?.[f]
                            )}
                            onChange={(e) => completeTimeOrMileageFareChange(e, f)}
                            onFocus={() => this.setFocus(f)}
                            onBlur={() => this.onBlur('')}
                            disabled={data.pricingType === 1 || isDisable}
                          />
                        </InputGroup>
                      </FormGroup>
                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        disabled={data.pricingType === 1}
                        value={this.parseInputValue(
                          'basicFare',
                          estimateFare?.[f]
                        )}
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                    </div>
                  </FormGroup>
                )
              })
            }
          </>
        ) : (
          <FormGroup>
            <Form.Label>
              <Translate value="bookingdetail.Basic_fare" />
            </Form.Label>
            <div className="input-with-validator">
              <FormGroup className="qup-input-group">
                <InputGroup className="single-addon-left">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      {data.currencySymbol}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className="form-custom"
                    type="number"
                    step="0.1"
                    value={this.parseInputValue(
                      'basicFare',
                      estimateFare && estimateFare.basicFare
                    )}
                    onChange={handleCompleteBasicFareChange}
                    onFocus={() => this.setFocus('basicFare')}
                    onBlur={() => this.onBlur('')}
                    disabled={data.pricingType === 1 || isDisable}
                  />
                </InputGroup>
              </FormGroup>
              <WarningFeeInput
                message={I18n.t('message.warningInputChangeOver')}
                className="text-soft-warning"
                disabled={data.pricingType === 1}
                value={this.parseInputValue(
                  'basicFare',
                  estimateFare && estimateFare.basicFare
                )}
                timeDelay={500}
                typeOfCheck="higherOfLower"
              />
            </div>
          </FormGroup>
        )}
      </>
    )
  }

  render() {
    const {
      showConfirmComplete,
      completeWithPaymentCloseButtonClick,
      completeWithPaymentButtonClick,
      data,
      isCompleteWithPaymentShow3rdBooking,
      retryCompleteBookingInfo,
      isBookingFromPaxApp,
      handleChangeRetryPaymentMethod,
      retryPaymentMethod,
      paymentMethod,
      corporateTypes,
      handleRetryPaymentCharge,
      isRetryPaymentCharge,
      paymentStep,
      selectedFleet,
      newBooking,
      selectAdditionalServicCheckedHandle,
      additionalServiceType,
      checkAdditionalServiceSelected,
      locationPickUp,
      commonData,
      extraDestination,
      locationDestination,
      handleSelectExtraDestination,
      handleSelectDestination,
      handleChangeExtraDestination,
      handleChangeDestination,
      handleAddressRemovedExtraDestination,
      handleAddressRemovedDestination,
      handleCompleteBasicFareChange,
      fareSettings,
      paymentComplete,
      handleRushHourCheckboxChange,
      rushHour,
      handleHeavyTrafficCheckboxChange,
      handleCompleteOtherFeesChange,
      handleCompleteTollFeesChange,
      handleCompleteGasFeesChange,
      handleCompleteParkingFeesChange,
      commisson,
      commissionValue,
      isCommissionBookingApp,
      handleTaxChange,
      handleTransactionChange,
      handleChangeTip,
      handleChangePromo,
      promo,
      handlePromoApplyClick,
      calculatePromoAmount,
      calculatorTotal,
      handleCompletePaymentTypeChange,
      handleCompletePaymentIsPendingChange,
      isShowmDispatcherCard,
      completeWithPayment3rdBooking,
      completeWithPaymentOkButtonClick,
      options,
      disableCompletePaymentNextClick,
      customer,
      handleCompletePaymentPartialPayment,
      handleCompletePaymentWriteOffDebt,
      isHydraBooking,
      farmIn,
      isTransportBooking,
      isAllwayShowGoogleApplePay,
      isCompleteWithouservice,
      handleCompleteExtraWaitFeeChange
    } = this.props;
    const { showExtraServices } = this.state;

    const estimateFare = isHydraBooking
      ? _.get(data, 'request.estimate.fare.estimateFareBuy', {})
      : data.request.estimate.fare || {};

    const isDmcCorpBooking = data.bookFrom == 'dmc' || data.bookFrom == 'corp';

    // complete with book Hydra: Farm In
    if (farmIn) {
      return <CompleteWithPayment {...this.props} />;
    }

    const isFareEdited = data.request.estimate.isFareEdited || false;

    if (isFareEdited) {
      return (
        <CompleteForBookEditedFare 
          {...this.props}
          renderBasicFareBasedMode={this.renderBasicFareBasedMode}
        />
      ) 
    }

    let requestPaymentType = data.request.paymentType
    let completePaymentType = paymentComplete.paymentType
    let paymentMethodIndividualTypes = paymentMethod.individualTypes
    let paymentMethodCorporateTypes = paymentMethod.corporateTypes
    const primaryPartialMethod = _.get(data, 'request.primaryPartialMethod', -1)
    const walletCustomer = _.get(customer, 'paxWallet[0]', {})
    const walletCustomerName = _.get(selectedFleet, 'paxCreditWalletConfig.walletName', '')
    const enableOutstandingPayment = _.get(
      this.props.selectedFleet,
      'outstandingPayment.enable',
      false
    );
    const outStandingAmount = _.get(this.props.data, 'outStanding', []);
    const hasWriteOffDebt = outStandingAmount.length > 0 && 
      enableOutstandingPayment && 
      isTransportBooking
    if(requestPaymentType === 21) {
      requestPaymentType = 1
      completePaymentType = 1
      paymentMethodIndividualTypes = _.filter(paymentMethodIndividualTypes, (ob => ob.value !== 21))
      paymentMethodCorporateTypes = _.filter(paymentMethodCorporateTypes, (ob => ob.value !== 21))
    }

    return (
      <Modal
        show={showConfirmComplete}
        backdrop="static"
        dialogClassName="confirm-dialog"
        onHide={completeWithPaymentCloseButtonClick}
        className="complete-with-payment-modal"
      >
        {showConfirmComplete ? (
          <Form onSubmit={completeWithPaymentButtonClick}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="bookingdetail.Booking_detail" className="white-text" />
                <span className="white-text">:</span>
                {' '}
                #
                {this.props.data.bookId}
                <span className="white-text"> -</span>
                {' '}
                <Translate
                  className="white-text text-tranform-none"
                  value={`statusDisplay.${this.props.data.status}`}
                />
              </Modal.Title>
            </Modal.Header>
            {isCompleteWithPaymentShow3rdBooking ? (
              <Modal.Body className="newbooking-form complete-with-payment md-payment">
                <div className="fill payment-dialog step0">
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Basic_fare" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.fare, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Airport_Fee" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.airportSurcharge, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Meet_driver" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.meetDriverFee, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Surcharge" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.rushHour, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Heavy_traffic" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.heavyTraffic, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Toll_fees" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.tollFee, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="generalSetting.parkingFee" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.parkingFee, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="generalSetting.gasFee" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.gasFee, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Other_fees" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.otherFees, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Tech_fees" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.techFee, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  {isBookingFromPaxApp(data.bookFrom) && (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Booking_fees" />
                      </Form.Label>
                      <span className="amount">
                        {currencyFormatter.format(retryCompleteBookingInfo.partnerCommission, {
                          code: retryCompleteBookingInfo.currencyISOCharged
                        })}
                      </span>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Tax" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.tax, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Tip" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.tip, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Promo" />
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(retryCompleteBookingInfo.promoAmount, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Total" />
                    </Form.Label>
                    <span className="amount total">
                      {currencyFormatter.format(retryCompleteBookingInfo.total, {
                        code: retryCompleteBookingInfo.currencyISOCharged
                      })}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Payment_methods" />
                    </Form.Label>
                    <FormControl
                      as="select"
                      placeholder="select"
                      className="form-custom"
                      onChange={handleChangeRetryPaymentMethod}
                      value={retryPaymentMethod}
                    >
                      {/*
                                              // follow the description just support for type :
                                                // 'Cash', 'Credit card', 'Direct invoicing', 'External card', 'QRCode payment',
                                                // these options are indivisualTypes
                                             */}
                      <option value={0}>
                        {' '}
                        {I18n.t('General.patmentMethod_1')}
                      </option>
                      {' '}
                      {/* Cash */}
                      {!data.travelerType
                        ? paymentMethodIndividualTypes.find(payment => {
                          {
                            /* Credit : credit token */
                          }
                          return payment.value == 2;
                        }) && (
                          <option value={1}>
                            {' '}
                            {I18n.t('General.Credit_Card')}
                          </option>
                        )
                        : corporateTypes.find(payment => {
                          {
                            /* Credit : CorporateCard */
                          }
                          return payment.value == 4 || payment.value == 2;
                        }) && (
                          <option value={7}>
                            {' '}
                            {I18n.t('General.Credit_Card')}
                          </option>
                        )}
                      {// direct invoicing
                        paymentMethodIndividualTypes.find(payment => payment.value == 5) && (
                          <option value={6}>
                            {' '}
                            {I18n.t('General.patmentMethod_5')}
                          </option>
                        )}
                      {// external card
                        paymentMethodIndividualTypes.find(payment => payment.value == 6) && (
                          <option value={3}>
                            {' '}
                            {I18n.t('General.patmentMethod_6')}
                          </option>
                        )}
                      {// QRcode payment
                        paymentMethodIndividualTypes.find(payment => payment.value == 8) && (
                          <option value={9}>
                            {' '}
                            {I18n.t('General.patmentMethod_8')}
                          </option>
                        )}
                      {// Apple pay payment
                        data.status == 'droppedOff'
                        && requestPaymentType == 9
                        && paymentMethodIndividualTypes.find(payment => payment.value == 9) && (
                          <option value={10}>
                            {' '}
                            {I18n.t('General.patmentMethod_9')}
                          </option>
                        )}
                    </FormControl>
                  </FormGroup>
                  {retryPaymentMethod == 1 || retryPaymentMethod == 7 ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="Payment_methods.Total" />
                      </Form.Label>
                      <FormGroup className="radio-button-group">
                        <RadioButton
                          text={I18n.t('bookingdetail.Charge')}
                          value={1}
                          onChange={handleRetryPaymentCharge}
                          checked={isRetryPaymentCharge == true}
                          name="Auto_dispatch"
                        />
                        <RadioButton
                          text={I18n.t('bookingdetail.No_charge')}
                          value={0}
                          name="Auto_dispatch"
                          onChange={handleRetryPaymentCharge}
                          checked={isRetryPaymentCharge == false}
                        />
                      </FormGroup>
                    </FormGroup>
                  ) : (
                    ''
                  )}
                </div>
              </Modal.Body>
            ) : (
              <Modal.Body className="newbooking-form complete-with-payment md-payment">
                <div
                  className={paymentStep == 0 ? 'fill payment-dialog step0' : 'fill payment-dialog'}
                >
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Pick_up" />
                    </Form.Label>
                    <FormControl
                      disabled
                      type="text"
                      className="form-custom"
                      value={locationPickUp.address}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Destination" />
                    </Form.Label>
                    <LocationInput
                      address={
                        locationDestination
                          ? locationDestination.address
                          : ''
                      }
                      onChange={handleChangeDestination}
                      onRemove={handleAddressRemovedDestination}
                      onSelect={handleSelectDestination}
                      value={
                        locationDestination
                          ? locationDestination.address
                          : ''
                      }
                      isChina={commonData && commonData.location && commonData.location.isChina}
                      options={options}
                      disabled={data.pricingType === 1}
                      data={this.props.data}
                    />
                  </FormGroup>
                  {this.renderBasicFareBasedMode()}
                  {fareSettings.rushHour
                    || fareSettings.fare.rushHourActive
                    || _.get(rushHour, 'surchargeParameter', 0) > 0 ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Rush_hour" />
                      </Form.Label>
                      <CcCheckbox
                        checked={paymentComplete.rushHourChecked}
                        onChange={handleRushHourCheckboxChange}
                        text={this.getSurChargeAmount(estimateFare)}
                        disabled={data.pricingType === 1}
                      />
                    </FormGroup>
                  ) : null
                  }
                  {fareSettings.fare.heavyTrafficActive
                    || (fareSettings.heavyTraffic && fareSettings.heavyTraffic.enable) ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Heavy_traffic" />
                      </Form.Label>
                      <CcCheckbox
                        checked={paymentComplete.heavyTrafficChecked}
                        onChange={handleHeavyTrafficCheckboxChange}
                        text={currencyFormatter.format(
                          paymentComplete.heavyTrafficChecked
                            ? data.pricingType == 0
                              ? CCLiteCommonFunc.getValueOfMultiCurrencies(
                                fareSettings.fare.heavyTrafficByCurrencies,
                                data.currencyISO,
                                'value'
                              ) || ''
                              : fareSettings.heavyTraffic.surcharge
                            : '',
                          { code: data.currencyISO }
                        )}
                        disabled={data.pricingType === 1}
                      />
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  {selectedFleet.fleetFareId
                    && selectedFleet.fleetFareId.additionalServicesActive
                    && !data.isNewSettingAdditionFee ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Addi_service" />
                      </Form.Label>
                      {

                        <GroupFleetServiceSelected 
                          extraFee={calFleetORAdditionalServicePayment(data?.request?.services)}
                          services={newBooking.locationService.extraServices}
                          onSelectService={selectAdditionalServicCheckedHandle}
                          isCompletedPayment
                          data={data}
                          currency={newBooking.locationService?.currency}
                          disabled={data.pricingType === 1}
                          />
                        }
                    </FormGroup>
                  ) : null}

                  {fareSettings.fare.otherFeeActive
                    || (fareSettings.otherFees && fareSettings.otherFees.enable) ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Other_fees" />
                      </Form.Label>
                      <div className="input-with-validator">
                        <FormGroup className="qup-input-group">
                          <InputGroup className="single-addon-left">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {data.currencySymbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className="form-custom"
                              onChange={handleCompleteOtherFeesChange}
                              value={this.parseInputValue(
                                'otherFees',
                                estimateFare && estimateFare.otherFees
                              )}
                              onFocus={() => this.setFocus('otherFees')}
                              onBlur={() => this.onBlur('')}
                              disabled={!estimateFare || data.pricingType === 1}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={data.pricingType === 1}
                          value={this.parseInputValue(
                            'otherFees',
                            estimateFare && estimateFare.otherFees
                          )}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  {
                    !isCompleteWithouservice && (
                      <FormGroup>
                        <Form.Label>
                          <Translate value="bookingdetail.Waiting_fee" />
                        </Form.Label>
                        <div className="input-with-validator">
                          <FormGroup className="qup-input-group">
                            <InputGroup className="single-addon-left">
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {data.currencySymbol}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className="form-custom"
                                onChange={handleCompleteExtraWaitFeeChange}
                                value={this.parseInputValue(
                                  'extraWaitFee',
                                  estimateFare?.extraWaitFee
                                )}
                                onFocus={() => this.setFocus('extraWaitFee')}
                                onBlur={() => this.onBlur('')}
                                disabled={!estimateFare || data.pricingType === 1}
                              />
                            </InputGroup>
                          </FormGroup>
                        </div>
                      </FormGroup>
                    )
                  }
                  {paymentComplete.addOnPrice && paymentComplete.addOnPrice !== 0
                    ? <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.addOnPrice" />
                      </Form.Label>
                      <FormGroup className="qup-input-group">
                        <span className="amount">
                          {currencyFormatter.format(paymentComplete.addOnPrice, {
                            code: data.currencyISO
                          })}
                        </span>
                      </FormGroup>
                    </FormGroup>
                    : null}

                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Subtotal" />
                      {'\u00A0'}
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(paymentComplete.subTotal, {
                        code: data.currencyISO
                      })}
                    </span>
                  </FormGroup>
                </div>

                <div
                  className={paymentStep == 0 ? 'fill payment-dialog' : 'fill payment-dialog step1'}
                >
                  <FormGroup className="location-info">
                    <Image src={pickupIcon} />
                    <span>{locationPickUp.address}</span>
                  </FormGroup>
                  <FormGroup className="location-info">
                    <Image src={destinationIcon} />
                    <span>
                      {locationDestination
                        ? locationDestination.address
                        : ''}
                    </span>
                  </FormGroup>
                  {
                    locationDestination && extraDestination && <FormGroup className="location-info">
                      <Image src={destinationIcon} />
                      <span>
                        {extraDestination
                          ? extraDestination.address
                          : ''}
                      </span>
                    </FormGroup>
                  }
                  <FormGroup>
                    <Form.Label style={{ position: 'relative' }}>
                      <Translate value="bookingdetail.Subtotal" />
                      {'\u00A0'}
                      {paymentComplete.isMinFare && (
                        <Translate value="bookingdetail.Minimum" className="minimum" />
                      )}
                    </Form.Label>
                    <span className="amount">
                      {currencyFormatter.format(paymentComplete.subTotal, {
                        code: data.currencyISO
                      })}
                    </span>
                  </FormGroup>

                  {fareSettings
                    && ((data.pricingType == 1
                      && fareSettings.airport
                      && (fareSettings.airport.fromAirportActive
                        || fareSettings.airport.toAirportActive))
                      || (data.pricingType == 0
                        && fareSettings.fare
                        && (fareSettings.fare.airport.fromAirportActive
                          || fareSettings.fare.airport.toAirportActive))) ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Airport_Fee" />
                      </Form.Label>
                      <span className="amount">
                        {currencyFormatter.format(estimateFare ? estimateFare.airportFee : 0, {
                          code: data.currencyISO
                        })}
                      </span>
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  {data.request.type == 1
                    && fareSettings.fleet
                    && fareSettings.fleet.additionalService.fromAirport.meetDriver ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Meet_driver" />
                      </Form.Label>
                      <span className="amount">
                        {currencyFormatter.format(estimateFare ? estimateFare.meetDriverFee : 0, {
                          code: data.currencyISO
                        })}
                      </span>
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  {fareSettings.fare.tollFeeActive || fareSettings.tollFee ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Toll_fees" />
                      </Form.Label>
                      <div className="input-with-validator">
                        <FormGroup className="qup-input-group">
                          <InputGroup className="single-addon-left">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {data.currencySymbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className="form-custom"
                              onChange={handleCompleteTollFeesChange}
                              disabled={!estimateFare || (data.pricingType === 1)}
                              value={this.parseInputValue(
                                'tollFee',
                                estimateFare && estimateFare.tollFee
                              )}
                              onFocus={() => this.setFocus('tollFee')}
                              onBlur={() => this.onBlur('')}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={data.pricingType === 1}
                          value={this.parseInputValue(
                            'tollFee',
                            estimateFare && estimateFare.tollFee
                          )}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  {fareSettings.fare.parkingFeeActive || fareSettings.parkingFee ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="generalSetting.parkingFee" />
                      </Form.Label>
                      <div className="input-with-validator">
                        <FormGroup className="qup-input-group">
                          <InputGroup className="single-addon-left">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {data.currencySymbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className="form-custom"
                              onChange={handleCompleteParkingFeesChange}
                              disabled={!estimateFare || (data.pricingType === 1)}
                              value={this.parseInputValue(
                                'parkingFee',
                                estimateFare && estimateFare.parkingFee
                              )}
                              onFocus={() => this.setFocus('parkingFee')}
                              onBlur={() => this.onBlur('')}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={data.pricingType === 1}
                          value={this.parseInputValue(
                            'parkingFee',
                            estimateFare && estimateFare.parkingFee
                          )}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  {fareSettings.fare.gasFeeActive || fareSettings.gasFee ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="generalSetting.gasFee" />
                      </Form.Label>
                      <div className="input-with-validator">
                        <FormGroup className="qup-input-group">
                          <InputGroup className="single-addon-left">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {data.currencySymbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className="form-custom"
                              onChange={handleCompleteGasFeesChange}
                              disabled={!estimateFare || (data.pricingType === 1)}
                              value={this.parseInputValue(
                                'gasFee',
                                estimateFare && estimateFare.gasFee
                              )}
                              onFocus={() => this.setFocus('gasFee')}
                              onBlur={() => this.onBlur('')}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={data.pricingType === 1}
                          value={this.parseInputValue(
                            'gasFee',
                            estimateFare && estimateFare.gasFee
                          )}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  { data.isNewSettingAdditionFee ? (
                    <FormGroup>
                      <Form.Label>
                        Fleet service
                      </Form.Label>
                      {
                        <GroupFleetServiceSelected 
                          extraFee={calFleetORAdditionalServicePayment(data?.request?.services, paymentComplete.subTotal)}
                          services={newBooking.locationService?.fleetServices}
                          onSelectService={selectAdditionalServicCheckedHandle}
                          disabled={data.pricingType === 1}
                          isCompletedPayment
                          data={data}
                          currency={newBooking.locationService?.currency}
                        />
                      }
                    </FormGroup>
                  ) : null}
                  {checkEnableTechFee({ selectedFleet: this.props.selectedFleet, fleetFareCustom: fareSettings?.fare}) && (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Tech_fees" />
                      </Form.Label>
                      <span className="amount">
                        {currencyFormatter.format(estimateFare ? estimateFare.techFee : 0, {
                          code: data.currencyISO
                        })}
                      </span>
                    </FormGroup>
                  )}

                  { commisson &&
                    ( isBookingFromPaxApp(data) || 
                      (!isBookingFromPaxApp(data) && isCommissionBookingApp)
                    ) ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Booking_fees" />
                      </Form.Label>
                      <span className="amount">
                        {currencyFormatter.format(commissionValue, {
                          code: data.currencyISO
                        })}
                      </span>
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  {fareSettings.fare.taxActive ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Tax" />
                      </Form.Label>
                      <div className="input-with-validator">
                        <FormGroup className="qup-input-group">
                          <InputGroup className="single-addon-left">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {data.currencySymbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className="form-custom"
                              min={0}
                              onChange={handleTaxChange}
                              value={this.parseInputValue('tax', paymentComplete.tax)}
                              onFocus={() => this.setFocus('tax')}
                              onBlur={() => this.onBlur('')}
                              disabled={data.pricingType === 1}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={data.pricingType === 1}
                          value={this.parseInputValue('tax', paymentComplete.tax)}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    </FormGroup>
                  ) : null}
                  {paymentComplete.creditTransactionFee >= 0 ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.creditTransactionFee" />
                      </Form.Label>
                      <div className="input-with-validator">
                        <FormGroup className="qup-input-group">
                          <InputGroup className="single-addon-left">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {data.currencySymbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className="form-custom"
                              min={0}
                              onChange={handleTransactionChange}
                              value={this.parseInputValue('transaction', paymentComplete.creditTransactionFee)}
                              onFocus={() => this.setFocus('transaction')}
                              onBlur={() => this.onBlur('')}
                              disabled={true}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    </FormGroup>
                  ) : null}
                  {selectedFleet.fleetFareId && selectedFleet.fleetFareId.tipActive ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Tip" />
                      </Form.Label>
                      <div className="input-with-validator">
                        <FormGroup className="qup-input-group">
                          <InputGroup className="single-addon-left">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {data.currencySymbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className="form-custom"
                              onChange={handleChangeTip}
                              min={0}
                              value={this.parseInputValue('tip', paymentComplete.tip)}
                              onFocus={() => this.setFocus('tip')}
                              onBlur={() => this.onBlur('')}
                              disabled={data.pricingType === 1}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={data.pricingType === 1}
                          value={this.parseInputValue('tip', paymentComplete.tip)}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    </FormGroup>
                  ) : null}

                  {!data.pricingType && !paymentComplete.addOnPrice ? (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Promo_code" />
                      </Form.Label>
                      <FormGroup>
                        <div className="add-on-input-group">
                          <FormControl
                            type="text"
                            onChange={handleChangePromo}
                            value={(data.request.promo || '').toUpperCase()}
                            placeholder={I18n.t('newbooking.Promo_code')}
                            disabled={
                              !!data.promoApplied
                              || !!promo
                              || (!data.psgInfo
                                || Validation.isStringEmpty(data.psgInfo.phone)
                                || data.psgInfo.phone == 'No Phone')
                            }
                            className="form-custom"
                          />
                          <span
                            variant="success"
                            className="add-on-button"
                            disabled={
                              !!data.promoApplied
                              || !!promo
                              || (!data.psgInfo
                                || Validation.isStringEmpty(data.psgInfo.phone)
                                || data.psgInfo.phone == 'No Phone')
                            }
                            onClick={handlePromoApplyClick}
                          >
                            {promo ? I18n.t('newbooking.Remove') : I18n.t('newbooking.Apply')}
                          </span>
                        </div>
                      </FormGroup>
                    </FormGroup>
                  ) : (
                    ''
                  )}

                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Promo" />
                    </Form.Label>
                    <span className="amount">
                      {promo ? (
                        <span>
                          {currencyFormatter.format(calculatePromoAmount(paymentComplete, promo), {
                            code: data.currencyISO
                          })}
                        </span>
                      ) : (
                        currencyFormatter.format(0, { code: data.currencyISO })
                      )}
                    </span>
                  </FormGroup>
                  {
                    hasWriteOffDebt &&
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.Write_off_debt" />
                      </Form.Label>
                      <CcCheckbox
                        checked={paymentComplete.writeOffDebt}
                        onChange={handleCompletePaymentWriteOffDebt}
                        className="paymentModal__pendingPayment"
                        disabled={
                          paymentComplete.isPending ||
                          paymentComplete.partialPayment
                        }
                        text={currencyFormatter.format(data.outStanding[0].amount, {
                          code: data.outStanding[0].currencyISO,
                        })}
                      />
                    </FormGroup>
                  }
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Total" />
                      {
                        calculatorTotal(paymentComplete, promo, false, true) ? ' (minimum)' : ''
                      }
                    </Form.Label>
                    <span className="amount total">
                      {currencyFormatter.format(calculatorTotal(paymentComplete, promo, true), {
                        code: data.currencyISO
                      })}
                    </span>
                    {this.renderShortTrip(estimateFare)}
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Payment_Type" />
                    </Form.Label>
                    <FormControl
                      as="select"
                      type="text"
                      className="form-custom"
                      value={completePaymentType}
                      onChange={handleCompletePaymentTypeChange}
                      disabled={isDmcCorpBooking}
                    >
                      {data.pricingType == 1 ? (
                        isDmcCorpBooking ? ( // direct invoicing
                          requestPaymentType == 5 ? (
                            <option value={5}>{I18n.t('General.patmentMethod_5')}</option>
                          ) : (
                            <option value={12}>{I18n.t('General.patmentMethod_12')}</option>
                          )
                        ) : (
                          <option value={2}>{I18n.t('bookingdetail.Personal_Card')}</option>
                        )
                      ) : !data.travelerType ? (
                        [
                          <option key={1} value={1}>
                            {I18n.t('General.patmentMethod_1')}
                          </option>
                        ].concat(
                          paymentMethodIndividualTypes.map((pm) => (
                            <PaymentMethodOption
                              method={pm}
                              bookData={data}
                              selectedFleet={selectedFleet}
                              isAllwayShowGoogleApplePay={isAllwayShowGoogleApplePay}
                              isCompleteWithouservice={isCompleteWithouservice}
                            />
                          ))
                        )
                      ) : (
                        [
                          <option key={1} value={1}>
                            {I18n.t('General.patmentMethod_1')}
                          </option>
                        ].concat(
                          paymentMethodCorporateTypes.map((pm) => (
                            <PaymentMethodOption
                              method={pm}
                              bookData={data}
                              selectedFleet={selectedFleet}
                              isAllwayShowGoogleApplePay={isAllwayShowGoogleApplePay}
                              isCompleteWithouservice={isCompleteWithouservice}
                            />
                          ))
                        )
                      )}
                      {isShowmDispatcherCard
                        && paymentMethod.type3rd.map(pm => {
                          if (pm.value == 1 || pm.value == requestPaymentType) {
                            return (
                              <option key={pm.value} value={pm.value}>
                                {I18n.t(`General.patmentMethod_${pm.value}`)}
                              </option>
                            );
                          }
                        })}
                    </FormControl>
                    <Validator className="red">
                      <ValidCase
                        valid={paymentStep == 0 || completePaymentType != 8}
                        message={I18n.t('bookingdetail.QR_Code_method_not_allow_error_message')}
                      />
                    </Validator>
                  </FormGroup>
                  {
                    isAllwayShowGoogleApplePay &&
                    [9, 23].includes(completePaymentType) &&
                    calulatePreAuthoried(data) < calculatorTotal(paymentComplete, promo, true) &&
                    <i className='warningTotal'>
                      Total due exceeds pre-authorization. Remaining balance will be recorded on the customer's account.
                    </i>
                  }
                  {
                    primaryPartialMethod > -1 && 
                    <FormGroup>
                      <Form.Label>
                      </Form.Label>
                      {
                        I18n.t('cue.balanceWallet').format(
                          walletCustomerName ? walletCustomerName : I18n.t('generalSetting.wallet'),
                          currencyFormatter.format(walletCustomer.value, { code: walletCustomer.currencyISO })
                        )
                      }
                    </FormGroup>
                  }
                  {
                    checkShowPendingPaymentCompleteBooking(paymentComplete) &&
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.pending_payment" />
                      </Form.Label>
                      <CcCheckbox
                        checked={paymentComplete.isPending}
                        onChange={handleCompletePaymentIsPendingChange}
                        className="paymentModal__pendingPayment"
                        disabled={
                            paymentComplete.partialPayment || 
                            paymentComplete.writeOffDebt
                        }
                      />
                    </FormGroup>
                  }
                  {
                    primaryPartialMethod > -1 &&
                    walletCustomer.value > 0 &&
                    <FormGroup>
                      <Form.Label>
                        <Translate value="bookingdetail.partial_payment" />
                      </Form.Label>
                      <CcCheckbox
                        checked={paymentComplete.partialPayment}
                        onChange={handleCompletePaymentPartialPayment}
                        className="paymentModal__pendingPayment"
                        disabled={
                          paymentComplete.isPending ||
                          paymentComplete.writeOffDebt
                        
                        }
                      />
                    </FormGroup>
                  }
                  <Form.Label>
                    (
                    <span className="require">*</span>
                      ):
                    <Translate value="bookingdetail.change_payment_method_note" />
                  </Form.Label>
                </div>
              </Modal.Body>
            )}

            {isCompleteWithPaymentShow3rdBooking ? (
              <Modal.Footer className="pt0">
                <Button className="btn-cancel" onClick={completeWithPaymentCloseButtonClick}>
                  {I18n.t('bookingdetail.Cancel')}
                </Button>
                <Button className="btn-save" onClick={completeWithPayment3rdBooking}>
                  {I18n.t('bookingdetail.Pay')}
                </Button>
              </Modal.Footer>
            ) : (
              <Modal.Footer className="pt0">
                <Button className={paymentStep == 0 ? 'btn-cancel' : 'btn-reset'} type="submit">
                  {paymentStep == 0 ? I18n.t('bookingdetail.Cancel') : I18n.t('bookingdetail.Back')}
                </Button>
                <Button
                  className="btn-save"
                  disabled={
                    disableCompletePaymentNextClick
                    || (
                      !locationDestination
                      || locationDestination.lat == 0
                    )
                    || (extraDestination !== undefined
                      && (
                        !extraDestination
                        || !extraDestination.address
                        || !extraDestination.address.length
                        || !extraDestination.lat
                        || !extraDestination.lat === 0)
                    )
                    || (paymentStep != 0 && completePaymentType == 8)
                  }
                  onClick={completeWithPaymentOkButtonClick}
                >
                  {paymentStep == 0
                    ? I18n.t('bookingdetail.Next')
                    : this.getTextSubmitButton(paymentComplete)}
                </Button>
              </Modal.Footer>
            )}
          </Form>
        ) : (
          ''
        )}
      </Modal>
    );
  }
}

export default CompleteWithPaymentModal;
