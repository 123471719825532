import "./Login.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Validation } from "../../utils/commonFunctions";
import { setLocale, Translate, I18n } from "react-redux-i18n";
import { Button, Row, FormGroup, Col, Form } from "react-bootstrap";
import { login } from "../../actions/auth";
import CcCheckbox from "../../components/ccCheckbox/CcCheckbox";
import { findAliasUrl } from '../../actions/corporateAction';
import Helmet from "react-helmet";

class Login extends Component {
  constructor() {
    super();
    this.state = {};
    this.handleLogin = this.handleLogin.bind(this);
    this.languageSelected = this.languageSelected.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user) {
      // logged in, let's show redirect if any, or show home
      try {
        const redirect = this.props.location.query.redirect;
        this.context.router.replace(redirect);
      } catch (err) {
        this.context.router.replace("/");
      }
    }
  }

  handleClickForgotPass = () => {
    const { router } = this.context;
    const { search} = this.props.location;
    router.push(`/forgot-password${search}`);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      // logged in, let's show redirect if any, or show home
      try {
        const redirect = this.props.location.query.redirect;
        this.context.router.replace(redirect);
      } catch (err) {
        this.context.router.replace("/");
      }
    }
    if (nextProps.loginError) {
      this.setState({ errorCode: nextProps.loginError.errorCode });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("corp")) {
      const corpAlias = urlParams.get("corp")
      this.props.findAliasUrl({alias: corpAlias})
      .then(result => {
        const data = result.ok && result.res || {}
        let fleetLogo, backgroundCustomize = ''
        if(data.logoCustomize && data.s3Host) {
          fleetLogo = `${data.s3Host}${data.logoCustomize}`
        }
        if(data.backgroundCustomize && data.s3Host) {
          backgroundCustomize = `${data.s3Host}${data.backgroundCustomize}`
        }
        this.setState({ 
          fleetLogo: fleetLogo,
          bgCorpAlias: backgroundCustomize
        })
      })
    } else {
      let fleetLogo = localStorage.getItem('logoUrl') || ''
      this.setState({ fleetLogo: fleetLogo ? JSON.parse(fleetLogo) : ''})
    }
  }

  handleLogin(event) {
    event.preventDefault();
    let isValidated = true;
    const username = this.refs.username;
    this.setState({ errorCode: 0 });
    if (Validation.isStringEmpty(username.value)) {
      this.setState({ validationUsername: "error" });
      isValidated = false;
    } else {
      this.setState({ validationUsername: null });
    }
    const password = this.refs.password;
    if (Validation.isStringEmpty(password.value)) {
      this.setState({ validationPassword: "error" });
      isValidated = false;
    } else {
      this.setState({ validationPassword: null });
    }
    if (isValidated) {
      this.props.login(
        username.value,
        password.value,
        this.refs.rememberMe.checked
      );
      password.value = "";
    }
  }
  languageSelected(lang) {
    this.props.setLanguage(lang);
  }
  render() {
    const { bgCorpAlias } = this.state
    return (
      <div className={"login-body"} horizontal="true">
        <Helmet title={"Login"} />
        <form 
          className={"bg-image"} 
          onSubmit={this.handleLogin}
          // style={{backgroundImage: this.state.bgCorpAlias ? this.state.bgCorpAlias : '', color: 'red'}}
          style={{
            backgroundImage: bgCorpAlias ? 
              `url("${bgCorpAlias}")`
              : ''
          }}
        >
          <div horizontal="true" className={"login-form"}>
            {this.state.errorCode ? (
              <div className={"login-alert login-errors"}>
                <span>{I18n.t("errors." + this.state.errorCode)}</span>
              </div>
            ) : null}
            {
              this.state.fleetLogo &&
              <img src={this.state.fleetLogo} id="logoLogin"/>
            }
            <FormGroup className="text-header">
              <Form.Label>
                <Translate value="login.Login" />
              </Form.Label>
            </FormGroup>
            <FormGroup className={this.state.validationUsername}>
              <input
                type="text"
                ref="username"
                placeholder={I18n.t("login.Username")}
                required
                autoFocus
                className="form-control form-custom"
              />
            </FormGroup>
            <FormGroup className={this.state.validationPassword}>
              <input
                type="password"
                ref="password"
                placeholder={I18n.t("login.Password")}
                required
                className="form-control form-custom"
              />
            </FormGroup>
            <div className="mb-xl ">
              <Row>
                <Col xs={12} sm={6}>
                  <CcCheckbox
                    className="text-left cc"
                    ref="rememberMe"
                    text={I18n.t("login.Remember_me")}
                  />
                </Col>
                <Col className="pass-recovery" xs={12} sm={6}>
                  <span 
                    className="text-active"
                    onClick={this.handleClickForgotPass}
                  >
                    <Translate value="login.Forgot_your_password" />
                  </span>
                </Col>
              </Row>
            </div>
            <Button
              block
              variant="success"
              type="submit"
              className="text-center login-button btn-save"
              onClick={this.handleLogin}
            >
              <Translate value="login.Login" />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

Login.contextTypes = {
  router: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

Login.propTypes = {
  user: PropTypes.object,
  loginError: PropTypes.object,
  location: PropTypes.object
};

function mapStateToProps(state) {
  const { auth, i18n } = state;
  if (auth) {
    return {
      user: auth.user,
      loginError: auth.loginError,
      language: i18n
    };
  }

  return { user: null };
}
function mapDispatchToProps(dispatch) {
  return {
    setLanguage: lang => {
      localStorage.setItem("language", lang);
      dispatch(setLocale(lang));
    },
    login: (username, pass, rememeberMe) => {
      dispatch(login(username, pass, rememeberMe));
    },
    findAliasUrl: options => dispatch(findAliasUrl(options)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
