import copy from 'copy-to-clipboard';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar,
  Modal,
  ListGroupItem,
  Tab,
  Tabs,
  OverlayTrigger,
  InputGroup,
  Tooltip,
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {
  filter,
  isEmpty,
  get,
  forEach,
  cloneDeep,
  find,
  map,
  isUndefined,
} from 'lodash';
import ReactDOM from 'react-dom';

import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle';
import * as mDispatcherActions from '../../actions/mDispatcherActions';
import * as settingActions from '../../actions/settingActions';
import * as loadingBarActions from '../../actions/loadingBarActions';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  trimPhoneNumber,
  textboxNumberHelper,
  checkFleetEnablePWA,
} from '../../utils/commonFunctions';
import {
  CommissionType,
  paymentGetwayDisabledAddNew,
} from '../../constants/commondata';
import IntlTelInputApp from '../../components/intlTelInputCustome/IntlTelInputApp';
import AddCard from '../customers/AddCard';
import './mDispatcher.scss';
import CreditCard from '../../components/creditCard/CreditCard';
import * as creditCardActions from '../../actions/creditCardActions';
import Confirm from '../../components/confirm/Confirm';

import StickyTable from '../../components/table/stickyTable/StickyTable';
import TableActions from '../../components/table/tableAction/TableActions';
import { PartnerColumns } from './tableColumns';
import QuestionCircleTooltip from '../../components/questionCircleTooltip/QuestionCircleTooltip';
import LocationInputContainer from '../../components/RouteInfo/LocationInputContainer.js';
import QRCode from 'react-qr-code';
import { BsSearch } from 'react-icons/bs';

const numItemsPerPage = 20;
let phone;
const CONFIRM_ACTIVATE_ID = 1;
const CONFIRM_DEACTIVATE_ID = 2;
const CONFIRM_RESET_PASSWORD = 3;
class MDispatcher extends Component {
  constructor() {
    super();
    this.state = {
      dialogData: {
        commissionValue: 0,
      },
      valid: {},
      isSubmitted: false,
      showDialog: false,
      queuingAreaList: [],
      mDispatcherTypeList: [],
      phoneExistValidation: true,
      phoneValidation: true,
      confirmDialog: false,
      sortType: -1,
      conformDeleteCard: false,
      editable: false,
      confirm: null,
      zoneId: '',
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
        active: null,
        name: I18n.t('mDispatcher.mDispatchers'),
      },
      footerLoading: true,
    };
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
    this.handleConfirmCloseClick = this.handleConfirmCloseClick.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.closeFormModal = this.closeFormModal.bind(this);
    this.saveDialogForm = this.saveDialogForm.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.getListmDispatchers = this.getListmDispatchers.bind(this);
    this.loadMDispatcherTypeList = this.loadMDispatcherTypeList.bind(this);
    this.loadQueuingAreaList = this.loadQueuingAreaList.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.mergeValueByCurrenciesToFleetCurrency =
      this.mergeValueByCurrenciesToFleetCurrency.bind(this);
    this.onChangeComissionType = this.onChangeComissionType.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeCommissionValue = this.onChangeCommissionValue.bind(this);
    this.onChangeMDispatcherType = this.onChangeMDispatcherType.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.handleValueByCurrencies = this.handleValueByCurrencies.bind(this);
    this.handleChangeQueuingArea = this.handleChangeQueuingArea.bind(this);
    this.activeMultipleMDispatcher = this.activeMultipleMDispatcher.bind(this);
    this.openConfirmDeleteDialog = this.openConfirmDeleteDialog.bind(this);
    this.deleteMultipleMDispatcher = this.deleteMultipleMDispatcher.bind(this);
    this.closeConfirmDialogForm = this.closeConfirmDialogForm.bind(this);
    this.handleAddCardSuccess = this.handleAddCardSuccess.bind(this);
    this.handleDeleteCardClick = this.handleDeleteCardClick.bind(this);
    this.deleteCreditCard = this.deleteCreditCard.bind(this);
    this.handleClickResetPasswordButton =
      this.handleClickResetPasswordButton.bind(this);
    this.checkTabError = this.checkTabError.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleSelectCreditCardByZone =
      this.handleSelectCreditCardByZone.bind(this);
  }

  componentDidMount() {
    this.getListmDispatchers(0, null, true);
    this.loadQueuingAreaList();
    this.loadMDispatcherTypeList();
    this.props.settingActions
      .getPWASetting({ fleetId: this.props.auth?.selectedFleet?.fleetId })
      .then((data) => {
        if (data.res?.pwa && data.ok) {
          this.setState({
            pwaInfo: data.res?.pwa,
          });
        }
      });
  }

  handleAddCardSuccess(data) {
    let { credits } = this.state.dialogData;
    if (!credits) credits = [];
    credits.push(data);
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        credits: credits.slice(),
      },
    });
  }

  deleteCreditCard() {
    const deleteCredit = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      userId: this.state.dialogData._id,
      gateway: this.credit.gateway,
      localToken: this.credit.localToken,
      crossToken: this.credit.crossToken,
    };
    this.props.creditCardActions.deleteCreditCard(deleteCredit).then((data) => {
      if (data.res.returnCode == 200) {
        const remainCredits = this.state.dialogData.credits;
        const index = remainCredits.indexOf(this.credit);
        remainCredits.splice(index, 1);
        this.setState({
          conformDeleteCard: false,
          dialogData: {
            ...this.state.dialogData,
            credits: remainCredits.slice(),
          },
        });
      }
    });
  }

  handleDeleteCardClick(cre) {
    this.credit = cre;
    this.setState({ conformDeleteCard: true });
  }

  mergeValueByCurrenciesToFleetCurrency(valueByCurrencies, currencies) {
    let result = [];
    if (valueByCurrencies) {
      result = currencies.map((obj) => {
        let index = valueByCurrencies.findIndex((tmp) => {
          return tmp.currencyISO == obj.iso;
        });
        if (index >= 0) {
          return {
            ...obj,
            commissionValue: valueByCurrencies[index].commissionValue,
            currencyISO: obj.iso,
          };
        }
        return {
          ...obj,
          currencyISO: obj.iso,
        };
      });
      return result;
    }
    return currencies.map((obj) => {
      return {
        ...obj,
        currencyISO: obj.iso,
      };
    });
  }

  getListmDispatchers(activePage, limit, countPartner) {
    let param = {
      limit: limit
        ? limit
        : this.state.mdispatcher
        ? this.state.mdispatcher.limit
        : numItemsPerPage,
      // page: activePage
      //   ? activePage - 1
      //   : this.state.mdispatcher
      //     ? this.state.mdispatcher.page
      //     : 0,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId,
      },
    };
    if (this.state.sortName) {
      param.sort = { [this.state.sortName]: this.state.sortType };
    } else {
      param.sort = { createdDate: this.state.sortType };
    }
    if (this.state.str) {
      param.query.str = this.state.str.trim();
      if (!activePage) {
        param.page = 0;
      }
    }
    this.setState({
      isLoading: true,
      footerLoading: countPartner,
    });
    this.props.mDispatcherActions.find(param).then((data) => {
      if (data.ok) {
        let { page, total, limit } = data.res;
        if (!_.isUndefined(total)) {
          this.setState({
            mdispatcher: data.res,
            footerData: {
              ...this.state.footerData,
              limit,
              page,
              total,
            },
            footerLoading: false,
            isLoading: false,
          });
        } else {
          this.setState(
            {
              mdispatcher: data.res,
              footerData: { ...this.state.footerData, limit, page },
              isLoading: false,
            },
            () => {
              if (countPartner) {
                this.loadTotalMDispatcher(param);
              }
            }
          );
        }
      }
    });
  }

  loadTotalMDispatcher = (param) => {
    this.props.mDispatcherActions.count(param).then((data) => {
      if (data.ok) {
        this.setState({
          footerLoading: false,
          footerData: { ...this.state.footerData, total: data.res },
        });
      }
    });
  };

  loadMDispatcherTypeList() {
    const { auth, settingActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    settingActions.fetchMDispatcherType(fleetId).then((data) => {
      if (data.res) {
        this.setState({
          mDispatcherTypeList: data.res,
        });
      }
    });
  }

  handlePhoneChange(status, value, countryData, number, id, isBlur) {
    if (isBlur) {
      if (!status) return;
      let newPhone;
      if (
        countryData &&
        '+' + countryData.dialCode == trimPhoneNumber(number)
      ) {
        newPhone = '';
      } else {
        newPhone = trimPhoneNumber(number);
      }
      if (newPhone !== phone) {
        this.props.mDispatcherActions
          .checkExistmDispatcherPhone({
            fleetId: this.props.auth.selectedFleet.fleetId,
            phone: newPhone,
          })
          .then((data) => {
            if (data.ok) {
              this.setState({ phoneExistValidation: !data.res.existed });
            }
          });
      }
    } else {
      this.setState({
        dialogData: { ...this.state.dialogData, phoneNumber: number },
        phoneExistValidation: true,
        phoneValidation: status,
      });
    }
  }

  loadQueuingAreaList() {
    const { auth, settingActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    settingActions.fetchAllQueuingArea(fleetId).then((data) => {
      if (data.res) {
        this.setState({
          queuingAreaList: data.res,
        });
      }
    });
  }

  saveDialogForm() {
    const { dialogData, valid } = this.state;
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(valid)) {
      this.checkTabError();
      return;
    }

    const { auth, settingActions, loadingBarActions, mDispatcherActions } =
      this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let body = {
      fleetId,
      ...dialogData,
      registerFrom: 'CC',
      phoneNumber: trimPhoneNumber(dialogData.phoneNumber),
      commissionValue: !!!dialogData.commissionValue
        ? 0
        : dialogData.commissionValue,
      commissionValueByCurrencies:
        dialogData.commissionValueByCurrencies &&
        dialogData.commissionValueByCurrencies.map((cr) => {
          if (!!!cr.commissionValue) {
            cr.commissionValue = 0;
          }
          return cr;
        }),
    };
    loadingBarActions.showLoadingSpiner();
    if (dialogData._id && dialogData._id.length > 0) {
      mDispatcherActions.update(body).then((data) => {
        loadingBarActions.hideLoadingSpiner();
        if (data.ok && !data.error) {
          this.context.notification(
            'success',
            I18n.t('mDispatcher.Update_mDispatcher_success')
          );
          this.closeFormModal();
          const {
            mdispatcher,
            mdispatcher: { list },
          } = this.state;
          const listItem = cloneDeep(list);
          const index = listItem.findIndex((o) => o._id === data.res._id);
          listItem[index] = data.res;
          this.setState({
            isSubmitted: false,
            mdispatcher: {
              ...mdispatcher,
              list: listItem,
            },
          });
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('mDispatcher.Update_mDispatcher_fail')
            );
          }
          this.setState({ isChanged: true });
        }
      });
    } else {
      mDispatcherActions.create(body).then((data) => {
        loadingBarActions.hideLoadingSpiner();
        if (data.ok && !data.error) {
          this.context.notification(
            'success',
            I18n.t('mDispatcher.Create_mDispatcher_success')
          );
          this.closeFormModal();
          // this.getListmDispatchers(null, null, true);
          const { mdispatcher } = this.state;
          this.setState({
            isSubmitted: false,
            mdispatcher: {
              ...mdispatcher,
              list: [data.res, ...mdispatcher.list],
            },
          });
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('mDispatcher.Create_mDispatcher_fail')
            );
          }
          this.setState({ isChanged: true });
        }
      });
    }
  }

  handleAddButtonClick() {
    this.openFormDialog();
    var currencies = [];
    this.props.auth.selectedFleet.currencies.map((currency) => {
      currency.commissionValue = 0;
      currencies.push(currency);
    });
    this.setState({
      isSubmitted: false,
      dialogData: {
        commissionType: CommissionType.Percent,
        commissionValueByCurrencies: this.mergeValueByCurrenciesToFleetCurrency(
          [],
          currencies
        ),
        commissionValue: 0,
      },
      editable: true,
    });
  }

  handleMenuClick = (key, id, obj) => {
    // e.preventDefault()

    switch (key) {
      case 'Edit': {
        const { auth, mDispatcherActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;

        mDispatcherActions
          .details({
            fleetId,
            id,
          })
          .then((data) => {
            if (data.res) {
              phone = trimPhoneNumber(data.res.phone);
              this.setState(
                {
                  dialogData: {
                    ...data.res,
                    phoneNumber: data.res.phone,
                    mDispatcherType: data.res.mDispatcherInfo.partnerType
                      ? data.res.mDispatcherInfo.partnerType.partnerTypeId
                      : '',
                    commissionType: data.res.mDispatcherInfo.commissionType,
                    queuingArea: data.res.mDispatcherInfo.queuingArea
                      ? data.res.mDispatcherInfo.queuingArea.queuingAreaId
                      : '',
                    commissionValue: data.res.mDispatcherInfo.commissionValue,
                    commissionValueByCurrencies:
                      this.mergeValueByCurrenciesToFleetCurrency(
                        data.res.mDispatcherInfo.commissionByCurrencies,
                        this.props.auth.selectedFleet.currencies
                      ),
                  },
                  currentUser: {
                    ...data.res,
                    phoneNumber: data.res.phone,
                  },
                  editable: true,
                  gateWay: !isEmpty(this.props.commonData.gatewayZones)
                    ? this.props.commonData.gatewayZones[0]
                    : {},
                  zoneId: !isEmpty(this.props.commonData.gatewayZones)
                    ? this.props.commonData.gatewayZones[0].zoneId
                    : null,
                },
                this.openFormDialog
              );
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('mDispatcher.Load_mDispatcher_fail')
                );
              }
            }
          });
        break;
      }
      case 'View': {
        const { auth, settingActions, loadingBarActions, mDispatcherActions } =
          this.props;
        let fleetId = auth.selectedFleet.fleetId;
        mDispatcherActions
          .details({
            fleetId,
            id,
          })
          .then((data) => {
            if (data.res) {
              phone = trimPhoneNumber(data.res.phone);
              this.setState(
                {
                  dialogData: {
                    ...data.res,
                    phoneNumber: data.res.phone,
                    mDispatcherType: data.res.mDispatcherInfo.partnerType
                      ? data.res.mDispatcherInfo.partnerType.partnerTypeId
                      : '',
                    commissionType: data.res.mDispatcherInfo.commissionType,
                    queuingArea: data.res.mDispatcherInfo.queuingArea
                      ? data.res.mDispatcherInfo.queuingArea.queuingAreaId
                      : '',
                    commissionValue: data.res.mDispatcherInfo.commissionValue,
                    commissionValueByCurrencies:
                      this.mergeValueByCurrenciesToFleetCurrency(
                        data.res.mDispatcherInfo.commissionByCurrencies,
                        this.props.auth.selectedFleet.currencies
                      ),
                  },
                  currentUser: data.res,
                  editable: false,
                  gateWay: !isEmpty(this.props.commonData.gatewayZones)
                    ? this.props.commonData.gatewayZones[0]
                    : {},
                  zoneId: !isEmpty(this.props.commonData.gatewayZones)
                    ? this.props.commonData.gatewayZones[0].zoneId
                    : null,
                },
                this.openFormDialog
              );
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('mDispatcher.Load_mDispatcher_fail')
                );
              }
            }
          });
        break;
      }
      case 'changeStatus': {
        this.setState({ editable: true });
        break;
      }
      case 'UpdateStatus':
        this.handleUpdateStatusClick(id, !obj.isActive);
        break;
      case 'Delete':
        this.deleteMDispatcher(id);
        break;
      case 'ViewGuestQR': {
        this.setState({
          openQuestQRModal: true,
          viewQuestQRId: id,
          viewQuestQRFullName: obj.fullName || obj.firstName,
        });
      }
      default:
        break;
    }
  };

  handleUpdateStatusClick = (id, status) => {
    const { auth, mDispatcherActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let body = {
      fleetId,
      id,
      status,
    };
    mDispatcherActions.active(body).then((data) => {
      if (data.ok && !data.error) {
        this.context.notification(
          'success',
          I18n.t('mDispatcher.Update_mDispatcher_status_success')
        );
        const {
          mdispatcher,
          mdispatcher: { list },
        } = this.state;
        const listItem = cloneDeep(list);
        const index = listItem.findIndex((o) => o._id === data.res._id);
        listItem[index] = data.res;
        this.setState({
          isSubmitted: false,
          mdispatcher: {
            ...mdispatcher,
            list: listItem,
          },
        });
      } else {
        if (data.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('mDispatcher.Update_mDispatcher_status_fail')
          );
        }
      }
    });
  };

  deleteMDispatcher = (id) => {
    const { auth, mDispatcherActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    mDispatcherActions
      .deleteMdispatcher({
        fleetId,
        id,
      })
      .then((data) => {
        if (data.ok && !data.error) {
          this.context.notification(
            'success',
            I18n.t('mDispatcher.Delete_mDispatcher_success')
          );
          const {
            mdispatcher,
            mdispatcher: { list },
            footerData,
          } = this.state;
          this.setState({
            isSubmitted: false,
            mdispatcher: {
              ...mdispatcher,
              list: list.filter((o) => o._id !== id),
            },
            footerData: {
              ...footerData,
              total: footerData.total - 1,
            },
          });
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('mDispatcher.Delete_mDispatcher_fail')
            );
          }
        }
      });
  };

  deleteMultipleMDispatcher() {
    const { mdispatcher } = this.state;
    const { auth, mDispatcherActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let ids = [];
    if (mdispatcher.list) {
      ids = mdispatcher.list
        .filter((obj) => obj.selected)
        .map((obj) => obj._id);
    }
    mDispatcherActions
      .deleteMdispatchers({
        fleetId,
        ids,
      })
      .then((data) => {
        if (data.ok && !data.error) {
          if (data.res && data.res.length > 0) {
            let isDeletedMDispatchers = data.res.filter(
              (obj) => obj.deleteAccount
            );
            let isNotDeletedMDispatchers =
              data.res.length - isDeletedMDispatchers.length;
            this.context.notification(
              'success',
              I18n.t('mDispatcher.Delete_mDispatchers_success'),
              <div>
                <p>Success: {isDeletedMDispatchers.length} mDispatcher(s)</p>
                <p className="text-warning bold">
                  Failure:
                  {isNotDeletedMDispatchers} mDispatcher(s)
                </p>
              </div>
            );
          }
          this.getListmDispatchers(null, null, true);
          this.closeConfirmDialogForm();
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('mDispatcher.Delete_mDispatchers_fail')
            );
          }
        }
      });
  }

  openConfirmDeleteDialog() {
    this.setState({
      confirmDialog: true,
    });
  }

  closeConfirmDialogForm() {
    this.setState({
      confirmDialog: false,
    });
  }

  handleClickResetPasswordButton() {
    this.setState({
      confirm: {
        id: CONFIRM_RESET_PASSWORD,
        title: I18n.t('mDispatcher.Reset_Password'),
        body: I18n.t('mDispatcher.Do_you_want_to_reset_password'),
        buttonTitle: 'Yes',
        closeButtonText: 'No',
      },
    });
  }

  activeMultipleMDispatcher(status) {
    if (
      this.state.mdispatcher.list.filter((user) => user.selected).length == 0
    ) {
      this.setState({
        confirm: {
          title: !status
            ? I18n.t('mDispatcher.Deactivate')
            : I18n.t('mDispatcher.Activate'),
          body: !status
            ? I18n.t('mDispatcher.ALERT_SELECT_DEACTIVATE')
            : I18n.t('mDispatcher.ALERT_SELECT_ACTIVATE'),
        },
      });
    } else {
      this.setState({
        confirm: {
          id: !status ? CONFIRM_DEACTIVATE_ID : CONFIRM_ACTIVATE_ID,
          title: !status
            ? I18n.t('mDispatcher.Deactivate')
            : I18n.t('mDispatcher.Activate'),
          body: !status
            ? I18n.t('mDispatcher.CONFIRM_DEACTIVATE_MULTI')
            : I18n.t('mDispatcher.CONFIRM_ACTIVATE_MULTI'),
          buttonTitle: 'Yes',
          closeButtonText: 'No',
        },
      });
    }
  }

  handleConfirmButtonClick(id) {
    const { mdispatcher } = this.state;
    const { auth, mDispatcherActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let ids = [];
    if (mdispatcher.list) {
      ids = mdispatcher.list
        .filter((obj) => obj.selected)
        .map((obj) => obj._id);
    }
    switch (id) {
      case CONFIRM_ACTIVATE_ID: {
        mDispatcherActions
          .multiActive({
            fleetId,
            ids,
            status: true,
          })
          .then((data) => {
            if (data.ok && !data.error) {
              if (data.res && data.res.activated >= 0) {
                this.context.notification(
                  'success',
                  I18n.t('mDispatcher.Update_mDispatcher_statuses_success'),
                  <div>
                    <p>Success: {data.res.activated} mDispatcher(s)</p>
                  </div>
                );
              }
              this.getListmDispatchers(null, null, true);
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('mDispatcher.Update_mDispatcher_statuses_fail')
                );
              }
            }
          });
        break;
      }
      case CONFIRM_DEACTIVATE_ID: {
        mDispatcherActions
          .multiActive({
            fleetId,
            ids,
            status: false,
          })
          .then((data) => {
            if (data.ok && !data.error) {
              if (data.res && data.res.deactivated >= 0) {
                this.context.notification(
                  'success',
                  I18n.t('mDispatcher.Update_mDispatcher_statuses_success'),
                  <div>
                    <p>Success: {data.res.deactivated} mDispatcher(s)</p>
                  </div>
                );
              }
              this.getListmDispatchers(null, null, true);
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('mDispatcher.Update_mDispatcher_statuses_fail')
                );
              }
            }
          });
        break;
      }
      case CONFIRM_RESET_PASSWORD: {
        mDispatcherActions
          .resetPassword({
            fleetId,
            id: this.state.dialogData._id,
          })
          .then((data) => {
            if (data.ok && !data.error) {
              this.context.notification(
                'success',
                I18n.t('mDispatcher.Reset_password_success')
              );
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('mDispatcher.Reset_password_fail')
                );
              }
            }
          });
      }
    }
    this.handleConfirmCloseClick();
  }

  handleConfirmCloseClick() {
    this.setState({ confirm: null });
  }

  openFormDialog() {
    this.setState({
      showDialog: true,
      phoneExistValidation: true,
      phoneValidation: true,
    });
  }

  closeFormModal() {
    this.setState({ showDialog: false, editable: false });
  }

  handleSearchKeyPress = (e) => {
    if (this.state.isLoading) return;
    if (e.key === 'Enter') {
      this.getListmDispatchers(null, null, true);
    }
  };

  handlePaginationSelect = (page) => this.getListmDispatchers(parseInt(page));

  handleNumItemsPerPageChange = (limit) => this.getListmDispatchers(0, limit);

  handleSearchChange = (str) => this.setState({ str });

  handleSortmDispatcherClick = (sortName) => {
    if (this.state.isLoading) return;
    this.setState(
      {
        sortName: sortName,
        sortType: this.state.sortType == 1 ? -1 : 1,
      },
      function () {
        this.getListmDispatchers();
      }
    );
  };

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handleItemSelectedChange = (id, e) => {
    const { mdispatcher } = this.state;
    let listItem = cloneDeep(mdispatcher.list);
    if (id !== null) {
      let index = listItem.findIndex((obj) => id === obj._id);
      if (index >= 0) listItem[index].selected = e.target.checked;
    } else {
      let value = e.target.checked;
      listItem = listItem.map((obj) => {
        return {
          ...obj,
          selected: value,
        };
      });
    }
    this.setState({
      mdispatcher: {
        ...mdispatcher,
        list: listItem,
      },
    });
  };

  onChangeComissionType(e) {
    var currencies = [];
    let valid = this.state.valid;
    this.props.auth.selectedFleet.currencies.map((currency) => {
      currency.commissionValue = 0;
      currencies.push(currency);
      valid[currency.iso] = true;
    });
    valid.percentCommission = true;
    valid.amountCommission = true;
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        commissionType: e.target.value,
        commissionValueByCurrencies: this.mergeValueByCurrenciesToFleetCurrency(
          [],
          currencies
        ),
        commissionValue: 0,
      },
      valid,
    });
  }

  onChangeFirstName(e) {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        firstName: e.target.value,
      },
    });
  }

  onChangeLastName(e) {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        lastName: e.target.value,
      },
    });
  }

  onChangeEmail(e) {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        email: e.target.value,
      },
    });
  }

  onChangeAddress(e) {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        address: e.target.value,
      },
    });
  }

  onChangeMDispatcherType(e) {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        mDispatcherType: e.target.value,
      },
    });
  }

  onChangeCommissionValue(e) {
    let numberWithDotReg = /^(\d*\.?\d+|\d+\.)$/;
    let numberWithCommaReg = /^(\d*\,?\d+|\d+\,)$/;
    if (
      numberWithDotReg.test(e.target.value) ||
      numberWithCommaReg.test(e.target.value) ||
      e.target.value === ''
    ) {
      this.setState({
        dialogData: {
          ...this.state.dialogData,
          commissionValue: e.target.value,
        },
      });
    }
  }

  handleChangeQueuingArea(e) {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        queuingArea: e.target.value,
      },
    });
  }

  handleSelectCreditCardByZone(e) {
    let gateway = find(
      this.props.commonData.gatewayZones,
      (item) => item.zoneId == e.target.value
    );
    this.setState({
      zoneId: e.target.value,
      gateWay: gateway,
    });
  }

  handleValueByCurrencies(iso, e) {
    let numberWithDotReg = /^(\d*\.?\d+|\d+\.)$/;
    let numberWithCommaReg = /^(\d*\,?\d+|\d+\,)$/;
    if (
      numberWithDotReg.test(e.target.value) ||
      numberWithCommaReg.test(e.target.value) ||
      e.target.value === ''
    ) {
      const { dialogData } = this.state;
      let valueByCurrencies = dialogData.commissionValueByCurrencies;
      let index = valueByCurrencies.findIndex((obj) => {
        return iso === obj.currencyISO;
      });
      if (index >= 0) {
        valueByCurrencies[index].commissionValue = e.target.value;
      }
      this.setState({
        dialogData: {
          ...dialogData,
          commissionValueByCurrencies: valueByCurrencies,
          //commissionValue: dialogData.commissionValue ? dialogData.commissionValue : 0
        },
      });
    }
  }

  handleAddressChange = (result) => {
    let address = {};
    if (result.address && result.addressPointText) {
      address = {
        ...result.address,
        address: result.addressPointText,
      };
    }
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        addressDetail: {
          ...address,
        },
      },
    });
  };

  checkTabError() {
    if (this.state.valid[this.state.activeTabKey]) {
      var errorTab = this.props.auth.selectedFleet.currencies.find((c, i) => {
        return !this.state.valid[c.iso];
      });
      if (errorTab) {
        this.setState({
          activeTabKey: errorTab.iso,
        });
      }
    }
  }

  handleTabSelect(key) {
    this.setState({ activeTabKey: key });
  }

  renderAddEditForm = () => {
    const { dialogData, valid, mDispatcherTypeList, queuingAreaList } =
      this.state;
    return (
      <div>
        <div className="form-detail-inner partner-form-detail row">
          <Col xs={12} md={6}>
            <FormGroup
              className={
                !this.state.isSubmitted
                  ? null
                  : valid.firstName
                  ? null
                  : 'error'
              }
            >
              <Form.Label>
                <Translate value="mDispatcher.first_name" />
                <span className="require"> *</span>
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                onChange={this.onChangeFirstName}
                value={dialogData ? dialogData.firstName : ''}
                placeholder={I18n.t('mDispatcher.first_name')}
                disabled={!this.state.editable}
                autoFocus
              />
              <Validator id="firstName" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmitted}
                  valid={!Validation.isStringEmpty(dialogData.firstName)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
              </Validator>
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="mDispatcher.last_name" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                onChange={this.onChangeLastName}
                value={dialogData ? dialogData.lastName : ''}
                placeholder={I18n.t('mDispatcher.last_name')}
                disabled={!this.state.editable}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup
              className={
                !this.state.isSubmitted ? null : valid.phone ? null : 'error'
              }
            >
              <Form.Label>
                <Translate value="mDispatcher.Phone_number" />
                <span className="require"> *</span>
              </Form.Label>
              <IntlTelInputApp
                css={['intl-tel-input', 'form-control']}
                utilsScript={'libphonenumber.js'}
                value={dialogData.phoneNumber}
                onPhoneNumberBlur={(status, value, countryData, number, id) => {
                  this.handlePhoneChange(
                    status,
                    value,
                    countryData,
                    number,
                    id,
                    true
                  );
                }}
                onPhoneNumberChange={(
                  status,
                  value,
                  countryData,
                  number,
                  id
                ) => {
                  this.handlePhoneChange(
                    status,
                    value,
                    countryData,
                    number,
                    id,
                    false
                  );
                }}
                disabled={!this.state.editable}
              />
              <Validator id="phone" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmitted}
                  valid={!Validation.isStringEmpty(dialogData.phoneNumber)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
                <ValidCase
                  hide={!this.state.isSubmitted}
                  valid={this.state.phoneValidation}
                  message={I18n.t('mDispatcher.ERROR_INPUT_VALIDPHONE')}
                />
                <ValidCase
                  hide={!this.state.isSubmitted}
                  valid={this.state.phoneExistValidation}
                  message={I18n.t('mDispatcher.ERROR_EXISTED_PHONE')}
                />
              </Validator>
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup
              className={
                !this.state.isSubmitted ? null : valid.email ? null : 'error'
              }
            >
              <Form.Label>
                <Translate value="mDispatcher.email" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                onChange={this.onChangeEmail}
                value={dialogData ? dialogData.email : ''}
                placeholder={I18n.t('mDispatcher.email')}
                disabled={!this.state.editable}
              />
              <Validator id="email" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmitted}
                  valid={
                    Validation.isStringEmpty(
                      dialogData ? dialogData.email : ''
                    ) || Validation.validateEmail(dialogData.email)
                  }
                  message={I18n.t(
                    'messages.commonMessages.invalid_email_format'
                  )}
                />
              </Validator>
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="mDispatcher.address" />
              </Form.Label>
              <LocationInputContainer
                setNewPoints={this.handleAddressChange}
                fleetId={this.props.auth?.selectedFleet?.fleetId}
                address={dialogData?.addressDetail || {}}
                isEditDisabled={!this.state.editable}
                auth={this.props.auth}
              />
              {/* <FormControl
                className="form-custom"
                type="text"
                onChange={this.onChangeAddress}
                value={dialogData ? dialogData.address : ''}
                placeholder={I18n.t('mDispatcher.address')}
                disabled={!this.state.editable}
              /> */}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="mDispatcher.mDispatcher_type" />
                <QuestionCircleTooltip
                  text={I18n.t('mDispatcher.mDispatcher_type_toolTip')}
                />
              </Form.Label>
              <select
                onChange={this.onChangeMDispatcherType}
                value={dialogData ? dialogData.mDispatcherType : ''}
                placeholder={I18n.t('mDispatcher.mDispatcher_type')}
                className={'form-control form-custom'}
                disabled={!this.state.editable}
              >
                <option value="">
                  {I18n.t('mDispatcher.Select_mDispatcher_type')}
                </option>
                {mDispatcherTypeList.map((obj) => {
                  return (
                    <option value={obj._id} key={obj._id}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="mDispatcher.queuing_area" />
                <QuestionCircleTooltip
                  text={I18n.t('mDispatcher.queuing_area_toolTip')}
                />
              </Form.Label>
              <select
                onChange={this.handleChangeQueuingArea}
                value={
                  this.state.dialogData ? this.state.dialogData.queuingArea : ''
                }
                placeholder={I18n.t('mDispatcher.commission_type')}
                className={'form-control form-custom'}
                disabled={!this.state.editable}
              >
                <option value="">
                  {I18n.t('mDispatcher.Select_queue_area')}
                </option>
                {queuingAreaList.map((obj) => {
                  return (
                    <option value={obj._id} key={obj._id}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="mDispatcher.commission_type" />
              </Form.Label>
              <select
                onChange={this.onChangeComissionType}
                value={
                  dialogData ? dialogData.commissionType : CommissionType.Amount
                }
                placeholder={I18n.t('mDispatcher.commission_type')}
                className={'form-control form-custom'}
                disabled={!this.state.editable}
              >
                <option value={CommissionType.Amount}>
                  {I18n.t('mDispatcher.Amount')}
                </option>
                <option value={CommissionType.Percent}>
                  {I18n.t('mDispatcher.Percent')}
                </option>
              </select>
            </FormGroup>
          </Col>
          {dialogData.commissionType == CommissionType.Amount ? (
            <Col xs={12} md={6}>
              <FormGroup>
                <Form.Label>
                  <Translate value="mDispatcher.commission_value" />
                </Form.Label>
                {this.props.auth.selectedFleet.multiCurrencies ? (
                  <div className="input-with-validator">
                    <Tabs
                      id="currencies-tabs-Ondemand"
                      className="currencies-tabs"
                      activeKey={this.state.activeTabKey}
                      onSelect={this.handleTabSelect}
                    >
                      {this.props.auth.selectedFleet.currencies.map(
                        (c, index) => {
                          let currentValue =
                            dialogData.commissionValueByCurrencies.filter(
                              (d) => {
                                return d.currencyISO == c.iso;
                              }
                            )[0];
                          return (
                            <Tab
                              className="currencies-tab-item"
                              eventKey={c.iso}
                              title={c.iso}
                              key={index}
                            >
                              <FormGroup
                                className={
                                  this.state.isSubmitted
                                    ? this.state.valid[c.iso]
                                      ? null
                                      : 'error'
                                    : null
                                }
                              >
                                <div className="addon-inner">
                                  <div className="addon-icon"> {c.symbol}</div>
                                  <FormControl
                                    className="form-custom"
                                    type="text"
                                    onChange={(e) => {
                                      this.handleValueByCurrencies(c.iso, e);
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handleValueByCurrencies(
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handleValueByCurrencies(
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    value={
                                      currentValue
                                        ? currentValue.commissionValue
                                        : 0
                                    }
                                    placeholder={I18n.t('mDispatcher.Value')}
                                    disabled={!this.state.editable}
                                  />
                                </div>
                                <Validator
                                  id={c.iso}
                                  callback={this.ValidatorCallback}
                                >
                                  <ValidCase
                                    hide={!this.state.isSubmitted}
                                    valid={
                                      Validation.isStringEmpty(
                                        currentValue
                                          ? currentValue.commissionValue
                                          : ''
                                      ) ||
                                      Validation.isDecimal(
                                        currentValue
                                          ? currentValue.commissionValue
                                          : ''
                                      )
                                    }
                                    message={I18n.t(
                                      'mDispatcher.ERROR_COMMISSION_NUMBERS'
                                    )}
                                  />
                                </Validator>
                              </FormGroup>
                            </Tab>
                          );
                        }
                      )}
                    </Tabs>

                    <WarningFeeInput
                      message={I18n.t('message.warningInputChangeOver')}
                      className="text-soft-warning"
                      disabled={!this.state.editable}
                      isReset={true}
                      value={
                        !dialogData ||
                        !dialogData.commissionValueByCurrencies ||
                        !dialogData.commissionValueByCurrencies.length
                          ? null
                          : dialogData.commissionValueByCurrencies.map(
                              (fees) => {
                                const { currencyISO, commissionValue } = fees;
                                return {
                                  [currencyISO]: commissionValue,
                                };
                              }
                            )
                      }
                      timeDelay={500}
                      typeOfCheck="higherOfLower"
                    />
                  </div>
                ) : (
                  <div className="input-with-validator">
                    <FormGroup
                      className={
                        `group-addon ${
                        this.state.isSubmitted
                          ? this.state.valid.amountCommission
                            ? null
                            : 'error'
                          : null
                      }`}
                    >
                      <div className="addon-inner">
                        <div className="addon-icon">
                          {this.props.auth.selectedFleet.currencies[0].symbol}
                        </div>
                        <FormControl
                          type="text"
                          className="form-custom"
                          onChange={(e) => {
                            this.handleValueByCurrencies(
                              this.props.auth.selectedFleet.currencies[0].iso,
                              e
                            );
                          }}
                          onBlur={(e) => {
                            textboxNumberHelper.onBlurHandle(e, (e) => {
                              this.handleValueByCurrencies(
                                this.props.auth.selectedFleet.currencies[0].iso,
                                e
                              );
                            });
                          }}
                          onFocus={(e) => {
                            textboxNumberHelper.onfocusHandle(e, (e) => {
                              this.handleValueByCurrencies(
                                this.props.auth.selectedFleet.currencies[0].iso,
                                e
                              );
                            });
                          }}
                          disabled={!this.state.editable}
                          value={
                            dialogData.commissionValueByCurrencies &&
                            dialogData.commissionValueByCurrencies.length > 0
                              ? dialogData.commissionValueByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                )[0].commissionValue
                              : 0
                          }
                        />
                      </div>
                      <Validator
                        id="amountCommission"
                        callback={this.ValidatorCallback}
                      >
                        <ValidCase
                          hide={!this.state.isSubmitted}
                          valid={
                            Validation.isStringEmpty(
                              dialogData.commissionValueByCurrencies &&
                                dialogData.commissionValueByCurrencies.length >
                                  0
                                ? dialogData.commissionValueByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0]
                                  ? dialogData.commissionValueByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    )[0].commissionValue
                                    ? dialogData.commissionValueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].commissionValue
                                    : 0
                                  : 0
                                : 0
                            ) ||
                            Validation.isDecimal(
                              dialogData.commissionValueByCurrencies &&
                                dialogData.commissionValueByCurrencies.length >
                                  0
                                ? dialogData.commissionValueByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0]
                                  ? dialogData.commissionValueByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    )[0].commissionValue
                                    ? dialogData.commissionValueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].commissionValue
                                    : 0
                                  : 0
                                : 0
                            )
                          }
                          message={I18n.t(
                            'mDispatcher.ERROR_COMMISSION_NUMBERS'
                          )}
                        />
                      </Validator>
                    </FormGroup>

                    <WarningFeeInput
                      message={I18n.t('message.warningInputChangeOver')}
                      className="text-soft-warning"
                      isReset={true}
                      disabled={!this.state.editable}
                      value={
                        dialogData.commissionValueByCurrencies &&
                        dialogData.commissionValueByCurrencies.length > 0
                          ? dialogData.commissionValueByCurrencies.filter(
                              (d) => {
                                return (
                                  d.currencyISO ==
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso
                                );
                              }
                            )[0].commissionValue
                          : 0
                      }
                      timeDelay={500}
                      typeOfCheck="higherOfLower"
                    />
                  </div>
                )}
              </FormGroup>
            </Col>
          ) : (
            <Col xs={12} md={6}>
              <FormGroup
                className={
                  this.state.isSubmitted
                    ? this.state.valid.percentCommission
                      ? null
                      : 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="mDispatcher.commission_value" />
                </Form.Label>

                <div className="addon-inner">
                  <div className="addon-icon">%</div>
                  <FormControl
                    className="form-custom"
                    type="text"
                    onChange={this.onChangeCommissionValue}
                    onBlur={(e) => {
                      textboxNumberHelper.onBlurHandle(
                        e,
                        this.onChangeCommissionValue
                      );
                    }}
                    onFocus={(e) => {
                      textboxNumberHelper.onfocusHandle(
                        e,
                        this.onChangeCommissionValue
                      );
                    }}
                    disabled={!this.state.editable}
                    value={dialogData.commissionValue}
                  />
                </div>
                <WarningFeeInput
                  message={I18n.t('message.warningInputChangeOver')}
                  className="text-soft-warning"
                  disabled={!this.state.editable}
                  value={dialogData.commissionValue}
                  timeDelay={500}
                  typeOfCheck="higherOfLower"
                />
                <Validator
                  id="percentCommission"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={
                      Validation.isStringEmpty(
                        dialogData.commissionValue
                          ? dialogData.commissionValue
                          : ''
                      ) ||
                      Validation.isDecimal(
                        dialogData.commissionValue
                          ? dialogData.commissionValue
                          : ''
                      )
                    }
                    message={I18n.t('mDispatcher.ERROR_COMMISSION_NUMBERS')}
                  />
                </Validator>
              </FormGroup>
            </Col>
          )}
        </div>
        <Row className="mb-lg">
          <Col md={12}>
            <p className="text">
              <Translate value="mDispatcher.for_new_mDipatcher_account_password_default_is_password" />
            </p>
          </Col>
          <Col md={12}>
            <Form.Label>
              (<span className="require"> *</span>
              ): <Translate value="mDispatcher.Required_fields" />
            </Form.Label>
          </Col>
        </Row>
        <div className="text-center">
          {!this.props.permissions || this.props.permissions.actions ? (
            this.state.editable ? (
              <Button className="btn-save mr-md" onClick={this.saveDialogForm}>
                <Translate value="mDispatcher.Save" />
              </Button>
            ) : (
              <Button
                onClick={(e) => {
                  this.handleMenuClick('changeStatus', e);
                }}
                className="btn-save mr-md"
              >
                <Translate value="mDispatcher.Edit" />
              </Button>
            )
          ) : null}
          <Button className="btn-cancel" onClick={this.closeFormModal}>
            <Translate value="mDispatcher.Cancel" />
          </Button>
          {(!this.props.permissions || this.props.permissions.actions) &&
          this.state.dialogData._id ? (
            <Button
              onClick={this.handleClickResetPasswordButton}
              className="ml-md btn-reset"
            >
              <Translate value="mDispatcher.Reset_Password" />
            </Button>
          ) : null}
        </div>
      </div>
    );
  };

  getTableColumns = () => {
    let tableColums = Object.assign([], PartnerColumns);
    const canUpdate = !this.props.permissions || this.props.permissions.actions;
    forEach(tableColums, (col) => {
      switch (col.key) {
        case 'table-selectbox':
          let isCheckedAll =
            this.state.mdispatcher &&
            this.state.mdispatcher.list &&
            filter(this.state.mdispatcher.list, (item) => item.selected)
              .length === this.state.mdispatcher.list.length;

          col.customHeader = (columnKey) => {
            return (
              <CcCheckbox
                className="table-selectbox all-checkbox"
                checked={isCheckedAll}
                onChange={(e) => this.handleItemSelectedChange(null, e)}
              />
            );
          };
          col.customCell = (obj) => {
            return (
              <CcCheckbox
                className="table-selectbox"
                checked={obj.inUse ? false : obj.selected}
                disabled={obj.inUse}
                onChange={(e) => this.handleItemSelectedChange(obj._id, e)}
              />
            );
          };
          break;
        case 'fullName':
          col.customCell = (obj) => (
            <OverlayTrigger
              placement="top"
              delayShow={300}
              overlay={
                <Tooltip id="tooltip">
                  <p>{obj.fullName}</p>
                </Tooltip>
              }
            >
              <a
                onClick={(e) => {
                  this.handleMenuClick('View', obj._id);
                }}
                className="text-base cursor-pointer"
              >
                {obj.fullName}
              </a>
            </OverlayTrigger>
          );
          break;
        case 'createdDate':
          col.customCell = (obj) => (
            <p className="text-ellipsis">
              {moment(obj.createdDate).format('DD/MM/YYYY')}
            </p>
          );
          break;
        case 'status':
          col.customCell = (obj) => (
            <div className={obj.isActive ? 'Active' : 'Inactive'}>
              {canUpdate ? (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleUpdateStatusClick(obj._id, !obj.isActive);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {obj.isActive ? (
                    <Translate value="mDispatcher.Active" />
                  ) : (
                    <Translate value="mDispatcher.Inactive" />
                  )}
                </a>
              ) : obj.isActive ? (
                <Translate value="mDispatcher.Active" />
              ) : (
                <Translate value="mDispatcher.Inactive" />
              )}
            </div>
          );
          break;
        case 'actions':
          col.customCell = (obj, rowIndex) => {
            if (
              canUpdate &&
              obj.firstName != 'Apple Review' &&
              obj.lastName != 'No Delete'
            ) {
              let actionItems = [
                {
                  label: 'mDispatcher.Edit',
                  eventKey: 'Edit',
                },
                {
                  label: obj.isActive
                    ? 'mDispatcher.Deactivate'
                    : 'mDispatcher.Activate',
                  eventKey: 'UpdateStatus',
                },
              ];

              if (checkFleetEnablePWA(this.props.auth?.selectedFleet)) {
                actionItems.push({
                  label: 'mDispatcher.View_Guest_QR',
                  eventKey: 'ViewGuestQR',
                });
              }

              if (!obj.isActive) {
                actionItems.push({
                  label: 'mDispatcher.Delete',
                  eventKey: 'Delete',
                });
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={obj}
                  onSelect={(eventKey) =>
                    this.handleMenuClick(eventKey, obj._id, obj)
                  }
                  totalRow={
                    this.state.mdispatcher
                      ? this.state.mdispatcher.list.length
                      : 0
                  }
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              let actionItems = [
                {
                  label: 'mDispatcher.View',
                  eventKey: 'View',
                },
              ];
              if (checkFleetEnablePWA(this.props.auth?.selectedFleet)) {
                actionItems.push({
                  label: 'mDispatcher.View_Guest_QR',
                  eventKey: 'ViewGuestQR',
                });
              }
              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={obj}
                  onSelect={(eventKey) =>
                    this.handleMenuClick(eventKey, obj._id, obj)
                  }
                  totalRow={
                    this.state.mdispatcher
                      ? this.state.mdispatcher.list.length
                      : 0
                  }
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            }
          };
          break;
        default:
          break;
      }
    });
    return tableColums;
  };

  getTableHeight = () => {
    let parentContentVerticalPadding = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight + 10
        : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = parentContentVerticalPadding + toolbarheight;
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && this.state.tableHeight < 500)
      tableHeight = 550;
    this.state.tableHeight = tableHeight;
    return tableHeight;
  };

  onImageDownload = () => {
    const svg = document.getElementById('QRCodePartner');
    if (!svg) return;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      const downloadName = this.state?.viewQuestQRFullName
        ? this.state?.viewQuestQRFullName?.replace(
            /[D&\/\\#,+()$~%.'":*?<>{}]/g,
            ''
          )
        : 'QRCodePartner.png';
      downloadLink.download = downloadName;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  handleCopyClick = (contentId) => {
    copy(contentId);
  };

  closeQuestModal = () => {
    this.setState({
      openQuestQRModal: false,
      viewQuestQRId: '',
      viewQuestQRFullName: '',
    });
  };

  setIsCopied = (isCopied) => {
    this.setState({
      isCopied: isCopied,
    });
  };

  renderQuestQR = () => {
    if (!this.state.viewQuestQRId) return null;
    let mDispatcherLink = this.state.pwaInfo?.pwaLink;
    if (this.state.pwaInfo?.customDomain) {
      mDispatcherLink = `${this.state.pwaInfo?.customUrl}/?f=${this.props.auth?.selectedFleet?.fleetToken}`;
    }
    mDispatcherLink = `${mDispatcherLink}&p=${this.state.viewQuestQRId}`;
    return (
      <Modal
        show={this.state.openQuestQRModal}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="confirm"
        onHide={this.closeQuestModal}
      >
        <Modal.Header closeButton onHide={this.closeQuestModal}>
          <Modal.Title>
            <Translate value="mDispatcher.titleGuestQR" />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.closeQuestModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Translate value="mDispatcher.questQRContent" />
          <FormGroup className="qup-input-group questLink">
            <InputGroup className="single-addon-right">
              <FormControl
                className="form-custom"
                type="text"
                value={mDispatcherLink}
                disabled={true}
              />
              <OverlayTrigger
                overlay={
                  this.state.isCopied ? (
                    <Tooltip id="Update_info">
                      <Translate value="bookingdetail.Copied" />
                    </Tooltip>
                  ) : (
                    <span></span>
                  )
                }
                show={true}
                placement="top"
                delayShow={300}
                delayHide={150}
              >
                <InputGroup.Append className="addonPartner">
                  <InputGroup.Text>
                    <i
                      class="fa fa-copy"
                      onClick={() => {
                        this.handleCopyClick(mDispatcherLink);
                        this.setIsCopied(true);
                      }}
                      onMouseLeave={() => {
                        this.setIsCopied(false);
                      }}
                    />
                  </InputGroup.Text>
                </InputGroup.Append>
              </OverlayTrigger>
            </InputGroup>
          </FormGroup>
          <div id="qrContainer">
            <QRCode value={mDispatcherLink} id="QRCodePartner" size={250} />
            <Button onClick={this.onImageDownload} id="btnDownloadQR">
              Download QR
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    const { dialogData, valid, mDispatcherTypeList, queuingAreaList } =
      this.state;
    const { creditConfig } = this.props.auth.selectedFleet;

    const bodyData =
      this.state.mdispatcher && this.state.mdispatcher.list
        ? this.state.mdispatcher.list
        : [];

    const supportWeb = get(creditConfig, 'configGateway.supportWeb', false);

    const canUpdate = !this.props.permissions || this.props.permissions.actions;
    const { permissions = null } = this.props || {};
    const { actions = false } = permissions || {};
    return (
      <div className="content">
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
        {this.renderQuestQR()}
        <Modal
          show={this.state.conformDeleteCard}
          backdrop={true}
          dialogClassName="confirm-dialog"
          className="confirm"
          onHide={() => {
            this.setState({ conformDeleteCard: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="customer.Card_holder" />
              <Translate value="customer.Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Translate value="customer.Card_holder" />
            <Translate value="customer.CONFIRM_DELETE_CARD" />
          </Modal.Body>
          <Modal.Footer>
            <Button className={'btn-save'} onClick={this.deleteCreditCard}>
              <Translate value="customer.Yes" />
            </Button>
            <Button
              className={'btn-cancel'}
              onClick={() => {
                this.setState({ conformDeleteCard: false });
              }}
            >
              <Translate value="customer.No" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          onHide={this.closeConfirmDialogForm}
          show={this.state.confirmDialog}
          dialogClassName="custom-modal"
          className="confirm"
        >
          <Modal.Header>
            <Modal.Title>
              <Translate value="mDispatcher.Confirmation" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text">
            <p>
              <Translate value="mDispatcher.Do_you_want_to_delete_these_mDipatchers" />
            </p>
            <p>
              <Translate value="mDispatcher.Only_mDistcher_in_the_inactive_status_can_be_deleted" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={'btn-save mr-md'}
              onClick={this.deleteMultipleMDispatcher}
            >
              <Translate value="mDispatcher.Yes" />
            </Button>
            <Button
              className="btn-cancel"
              onClick={this.closeConfirmDialogForm}
            >
              <Translate value="customer.No" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          backdrop="static"
          dialogClassName={
            'card-dialog' +
            (dialogData._id && dialogData._id.length > 0
              ? ' modal-width-tabs'
              : '')
          }
          onHide={this.closeFormModal}
          show={this.state.showDialog}
        >
          <Modal.Header className="text-center pd-t-30 pd-b-30" closeButton>
            <Modal.Title>
              {dialogData._id ? (
                this.state.editable ? (
                  <Translate value="mDispatcher.EDIT_MDISPATCHER" />
                ) : (
                  <Translate value="mDispatcher.Partner_detail" />
                )
              ) : (
                <Translate value="mDispatcher.ADD_MDISPATCHER" />
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="clearfix">
            {dialogData._id && dialogData._id.length > 0 ? (
              <Tabs
                defaultActiveKey={1}
                animation={true}
                onSelect={this.handleTabChange}
              >
                <Tab eventKey={1} title={I18n.t('mDispatcher.mDispatcher')}>
                  {this.renderAddEditForm()}
                </Tab>
                {this.props.auth.selectedFleet.creditConfig &&
                this.props.auth.selectedFleet.creditConfig.multiGateway
                  ? this.props.auth.selectedFleet.creditConfig.enable &&
                    Array.isArray(this.props.commonData.gatewayZones) &&
                    this.props.commonData.gatewayZones.length > 0 && (
                      <Tab
                        eventKey={2}
                        title={I18n.t('mDispatcher.card_management')}
                      >
                        <div className="row card-content">
                          <div className="col-md-6">
                            <FormGroupTitle>
                              {I18n.t('customer.SHOW_mDISPATCHER_CARD')}
                            </FormGroupTitle>
                            <FormGroup>
                              <Form.Label>
                                <Translate value="mDispatcher.Default_zone" />
                                <span className="require"> *</span>
                              </Form.Label>
                              <select
                                onChange={this.handleSelectCreditCardByZone}
                                value={this.state.dialogData.zone}
                                placeholder={I18n.t(
                                  'mDispatcher.commission_type'
                                )}
                                className={'form-control form-custom'}
                              >
                                {this.props.commonData.gatewayZones.map(
                                  (obj) => {
                                    return (
                                      <option
                                        value={obj.zoneId}
                                        key={obj.zoneId}
                                      >
                                        {obj.zoneName}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </FormGroup>
                            {dialogData &&
                            dialogData.credits.length > 0 &&
                            filter(dialogData.credits, (credit) => {
                              let gatewayZone = find(
                                this.props.commonData.gatewayZones,
                                (zone) => {
                                  return zone.zoneId === this.state.zoneId;
                                }
                              );
                              return (
                                gatewayZone &&
                                gatewayZone.gateway === credit.gateway
                              );
                            }).length > 0 ? (
                              <div className="no-record-inform">
                                {map(
                                  filter(dialogData.credits, (credit) => {
                                    let gatewayZone = find(
                                      this.props.commonData.gatewayZones,
                                      (zone) => {
                                        return (
                                          zone.zoneId === this.state.zoneId
                                        );
                                      }
                                    );
                                    return (
                                      gatewayZone &&
                                      gatewayZone.gateway === credit.gateway
                                    );
                                  }),
                                  (filteredCredit, index) => {
                                    return (
                                      <CreditCard
                                        handleDeleteCardClick={
                                          this.handleDeleteCardClick
                                        }
                                        data={filteredCredit}
                                        cardName={I18n.t(
                                          'customer.mDispatcher_card'
                                        )}
                                        onlyView={!canUpdate}
                                      />
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              <div className="no-record-inform">
                                <ListGroupItem variant="warning">
                                  <Translate value="mDispatcher.No_card" />
                                </ListGroupItem>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6">
                            {creditConfig &&
                            creditConfig.multiGateway &&
                            !isUndefined(this.state.gateWay) &&
                            this.state.zoneId &&
                            !paymentGetwayDisabledAddNew.includes(
                              this.state.gateWay.gateway
                            ) ? (
                              <Fragment>
                                <FormGroupTitle>
                                  {I18n.t('customer.ADD_mDISPATCHER_CARD')}
                                </FormGroupTitle>
                                <AddCard
                                  isCrossZone={false}
                                  userId={dialogData._id}
                                  handleAddCardSuccess={
                                    this.handleAddCardSuccess
                                  }
                                  gateWay={this.state.gateWay}
                                  currentUser={this.state.currentUser}
                                  zoneId={this.state.zoneId}
                                  phone={trimPhoneNumber(
                                    dialogData.phoneNumber
                                  )}
                                  addCardWebservice={
                                    this.props.creditCardActions
                                      .addNewCreditCard
                                  }
                                  ismDispatcher={true}
                                />
                              </Fragment>
                            ) : (
                              <ListGroupItem variant="warning">
                                <Translate value="mDispatcher.No_Support" />
                              </ListGroupItem>
                            )}
                          </div>
                        </div>
                      </Tab>
                    )
                  : this.props.auth.selectedFleet.creditConfig.enable && (
                      <Tab
                        eventKey={2}
                        title={I18n.t('mDispatcher.card_management')}
                      >
                        <Row className="partner-card-content">
                          <Col xs={12} sm={6}>
                            {dialogData.credits &&
                            dialogData.credits.length > 0 ? (
                              <React.Fragment>
                                {dialogData.credits.map((obj) => (
                                  <CreditCard
                                    handleDeleteCardClick={
                                      this.handleDeleteCardClick
                                    }
                                    data={obj}
                                    cardName={I18n.t(
                                      'customer.mDispatcher_card'
                                    )}
                                    onlyView={!canUpdate}
                                  />
                                ))}
                              </React.Fragment>
                            ) : (
                              <div className="no-record-inform">
                                <ListGroupItem variant="warning">
                                  <Translate value="mDispatcher.No_card" />
                                </ListGroupItem>
                              </div>
                            )}
                          </Col>
                          <Col xs={12} sm={6}>
                            {this.state.currentUser &&
                            ((creditConfig.multiGateway &&
                              dialogData &&
                              dialogData.gateWay &&
                              !paymentGetwayDisabledAddNew.includes(
                                dialogData.gateWay.gateway
                              )) ||
                              (!creditConfig.multiGateway &&
                                !paymentGetwayDisabledAddNew.includes(
                                  creditConfig.configGateway.gateway
                                ))) ? (
                              supportWeb ? (
                                <AddCard
                                  isCrossZone={false}
                                  userId={dialogData._id}
                                  handleAddCardSuccess={
                                    this.handleAddCardSuccess
                                  }
                                  currentUser={this.state.currentUser}
                                  phone={trimPhoneNumber(
                                    dialogData.phoneNumber
                                  )}
                                  title={I18n.t(
                                    'customer.ADD_mDISPATCHER_CARD'
                                  )}
                                  addCardWebservice={
                                    this.props.creditCardActions
                                      .addNewCreditCard
                                  }
                                  gateWay={
                                    this.props.auth.selectedFleet.creditConfig
                                      .configGateway
                                  }
                                  ismDispatcher={true}
                                />
                              ) : null
                            ) : null}
                          </Col>
                        </Row>
                      </Tab>
                    )}
              </Tabs>
            ) : (
              this.renderAddEditForm()
            )}
          </Modal.Body>
        </Modal>
        <ButtonToolbar
          className="text-center header-button-group"
          ref={(node) => (this.toobarContainer = node)}
        >
          <div className="group-left btn-group">
            <FormGroup className="search-format">
              <FormControl
                className="search-form"
                type="text"
                placeholder={I18n.t('General.search')}
                onChange={(e) => this.handleSearchChange(e.target.value)}
                onKeyPress={this.handleSearchKeyPress}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {canUpdate && (
              <React.Fragment>
                <Button
                  className="btn-header text-add-header"
                  onClick={this.handleAddButtonClick}
                >
                  <Translate value="mDispatcher.Add" />
                </Button>
                <Button
                  className="btn-header text-delete-header"
                  disabled={false}
                  onClick={this.openConfirmDeleteDialog}
                >
                  <Translate value="promotionSettings.Delete" />
                </Button>
                <Button
                  className="btn-header text-active-header"
                  onClick={() => {
                    this.activeMultipleMDispatcher(true);
                  }}
                >
                  <Translate value="mDispatcher.Activate" />
                </Button>
                <Button
                  className="btn-header text-deactive-header"
                  onClick={() => {
                    this.activeMultipleMDispatcher(false);
                  }}
                >
                  <Translate value="mDispatcher.Deactivate" />
                </Button>
              </React.Fragment>
            )}
          </div>
        </ButtonToolbar>

        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            sortHandle={this.handleSortmDispatcherClick}
            sortType={this.state.sortType}
            sortName={this.state.sortName}
            footerLoading={this.state.footerLoading}
            isLoading={this.state.isLoading}
          />
        </div>
      </div>
    );
  }
}

MDispatcher.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    mDispatcherActions: bindActionCreators(mDispatcherActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    creditCardActions: bindActionCreators(creditCardActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MDispatcher);
