import React, { Component } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from 'react-google-maps';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
// import pickupIcon from './../../../constants/staticFiles/mk_pickup.png'
// import distinationIcon from './../../../constants/staticFiles/mk_destination.png'
import pickupIcon from './../../../assets/images/icons/pickup.svg';
import distinationIcon from './../../../assets/images/icons/destination.svg';
import des1_Icon from './../../../assets/images/icons/destination1.svg';
import des2_Icon from './../../../assets/images/icons/des2.svg';
import { locationType } from './../../../constants/commondata';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';

const GettingStartedGoogleMap = withGoogleMap((props) => {
  const pickUpPos = props.markerPickUp
    ? {
        lat: props.markerPickUp.post.lat,
        lng: props.markerPickUp.post.lng,
      }
    : null;
  let desPos = props.markerDestination
    ? {
        lat: props.markerDestination.post.lat,
        lng: props.markerDestination.post.lng,
      }
    : null;
  let des1 = null;
  let des2 = null;
  if (props.markerExtraDestination) {
    desPos = null;
    des1 = {
      lat: props.markerDestination.post.lat,
      lng: props.markerDestination.post.lng,
    };
    des2 = {
      lat: props.markerExtraDestination.post.lat,
      lng: props.markerExtraDestination.post.lng,
    };
  }
  return (
    <GoogleMap
      ref={props.onMapLoad}
      defaultZoom={14}
      center={props.center}
      options={{ gestureHandling: 'greedy' }}
    >
      {props.markerPickUp && (
        <Marker
          draggable={props.markerPickUp.draggable}
          onDragEnd={props.markerPickUp.drapend}
          position={pickUpPos}
          icon={pickupIcon}
          key={1}
        />
      )}
      {desPos && (
        <Marker
          draggable={props.markerDestination.draggable}
          onDragEnd={props.markerDestination.drapend}
          position={desPos}
          icon={distinationIcon}
          key={2}
        />
      )}
      {des1 && (
        <Marker
          draggable={props.markerDestination.draggable}
          onDragEnd={props.markerDestination.drapend}
          position={des1}
          icon={des1_Icon}
          key={2}
        />
      )}
      {des2 && (
        <Marker
          draggable={props.markerExtraDestination.draggable}
          onDragEnd={props.markerExtraDestination.drapend}
          position={des2}
          icon={des2_Icon}
          key={3}
        />
      )}
    </GoogleMap>
  );
});

class IntercityBookingGGMap extends Component {
  constructor(e) {
    super(e);
  }

  render() {
    const {
      center,
      pickupLocation,
      handlePickupDrapEnd,
      pickupFrom,
      destinationLocation,
      handleDestinationDrapEnd,
      destinationFrom,
      onMapLoad
    } = this.props;
    return (
      <GettingStartedGoogleMap
        containerElement={<div className="fill booking-map" />}
        mapElement={<div style={{ height: `100%` }} />}
        center={center}
        onMapLoad={onMapLoad}
        markerPickUp={
          pickupLocation
            ? {
                post: pickupLocation,
                drapend: handlePickupDrapEnd,
                draggable: pickupFrom != locationType.thirdParty,
              }
            : null
        }
        markerDestination={
          destinationLocation
            ? {
                post: destinationLocation,
                drapend: handleDestinationDrapEnd,
                draggable: destinationFrom != locationType.thirdParty,
              }
            : null
        }
      />
    );
  }
}

export default IntercityBookingGGMap;
