import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { translations } from "../../constants/languages/languages";
import { Translate, Localize, I18n } from "react-redux-i18n";
import { Line, Doughnut, Bar, Chart, Pie, HorizontalBar } from "react-chartjs-2";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";
import {
  Button,
  Row,
  Col,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Image
} from "react-bootstrap";
import bookCompleteIcon from "../../assets/images/dashboard/BookComplete.svg";
import bookReqIcon from "../../assets/images/dashboard/Bookrequest.svg";
import customerIcon from "../../assets/images/dashboard/Customer.svg";
import revenueIcon from "../../assets/images/dashboard/Revenue.svg";
// , bookReqIcon,customerIcon}
import Datetime from "../../components/dateTime/DateTime";
import * as dashboardActions from "./../../actions/dashboardActions";
import "./dashboard.scss";
import {
  CCLiteCommonFunc as common,
  toLocaleNumber,
  checkEnable3rdPartyIntegration,
  checkEnableHolidayTaxis,
  checkEnableKarhoo
} from "./../../utils/commonFunctions";
import $ from "jquery";

var moment = require("moment-timezone");
var currencyFormatter = require("currency-formatter");

Chart.defaults.global.defaultFontColor = "black";
Chart.defaults.global.defaultFontSize = 16;
var theHelp = Chart.helpers;
const doughnutOptions = {
  elements: {
    arc: {
      borderWidth: 0
    }
  },
  maintainAspectRatio: false,
  title: {
    display: true,
    position: "top",
    padding: 20
  },
  legend: {
    display: true,
    position: "right",
    // generateLabels changes from chart to chart,  check the source,
    // this one is from the doughut :
    // https://github.com/chartjs/Chart.js/blob/master/src/controllers/controller.doughnut.js#L42
    labels: {
      generateLabels: function (chart) {
        var data = chart.data;
        if (data.labels.length && data.datasets.length) {
          return data.labels.map(function (label, i) {
            var meta = chart.getDatasetMeta(0);
            var ds = data.datasets[0];
            var arc = meta.data[i];
            var custom = (arc && arc.custom) || {};
            var getValueAtIndexOrDefault = theHelp.getValueAtIndexOrDefault;
            var arcOpts = chart.options.elements.arc;
            var fill = custom.backgroundColor
              ? custom.backgroundColor
              : getValueAtIndexOrDefault(
                ds.backgroundColor,
                i,
                arcOpts.backgroundColor
              );
            var stroke = custom.borderColor
              ? custom.borderColor
              : getValueAtIndexOrDefault(
                ds.borderColor,
                i,
                arcOpts.borderColor
              );
            var bw = custom.borderWidth
              ? custom.borderWidth
              : getValueAtIndexOrDefault(
                ds.borderWidth,
                i,
                arcOpts.borderWidth
              );
            var total = ds.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return previousValue + currentValue;
            });
            return {
              // And finally :
              text: I18n.t("dashboard." + label) + " (" + ds.data[i] + ")",
              fillStyle: fill,
              strokeStyle: stroke,
              lineWidth: bw,
              hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
              index: i
            };
          });
        }
        return [];
      },
      usePointStyle: true,
      fontSize: 12,
      boxWidth: 20,
      fontColor: "#e6e6e6"
    }
  },
  onAnimationProgress: e => {
    console.log("onAnimationProgress", e);
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        //get the concerned dataset
        var dataset = data.datasets[tooltipItem.datasetIndex];
        //calculate the total of this data set
        var total = dataset.data.reduce(function (
          previousValue,
          currentValue,
          currentIndex,
          array
        ) {
          return previousValue + currentValue;
        });
        //get the current items value
        var currentValue = dataset.data[tooltipItem.index];
        //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
        var percentage = Math.floor((currentValue / total) * 100 + 0.5);

        return currentValue + " (" + percentage + "%)";
      }
    }
  }
};
const viewModes = {
  day: "day",
  week: "week",
  month: "month"
};
const chartColors = {
  requestChartColors: {
    API: "#40BE76",
    carHailing: "#40BE76",
    cc: "#40BE76",
    dmc: "#40BE76",
    kiosk: "#40BE76",
    mDispatcher: "#40BE76",
    paxApp: "#40BE76",
    PWA: "#40BE76",
    webBooking: "#40BE76",
    streetSharing: "#40BE76",
    'booking.com': "#40BE76",
    'HolidayTaxis': "#40BE76",
    'Karhoo': "#40BE76"
  },
  finished: {
    completed: "#80BB00",
    noShow: "#979797",
    canceled: "#8B331D",
    incident: "#4752A2",
    failed: "#f52518",
    partialCompleted: "#00572d",
  },
  rating: {
    rate1: "#80BB00",
    rate2: "#979797",
    rate3: "#4752A2",
    rate4: "#8B331D",
    rate5: "#E5793B"
  }
};
class Dashboard extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: true,
      rawData: null,
      criteria: {
        viewMode: viewModes.day,
        date: new Date(),
        currency:
          props.auth.selectedFleet.currencies &&
          props.auth.selectedFleet.currencies[0]
      },

      bookFromChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
            hoverBackgroundColor: "#04BE76",
            hoverBorderColor: "#04BE76",
            data: []
          }
        ]
      },
      bookStatusChartData: null,
      ratingChartData: null,
      revenueChartData: {
        labels: [],
        datasets: [
          {
            label: I18n.t("dashboard.Profit"),
            fill: false,
            lineTension: 0.1,
            backgroundColor: "#04BE76",
            borderColor: "#04BE76",
            borderWidth: 0,
            borderJoinStyle: "miter",
            pointBorderColor: "#04BE76",
            pointBackgroundColor: "#04BE76",
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#04BE76",
            pointHoverBorderColor: "#04BE76",
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 5,
            data: [],
            yAxisID: "y-axis-1"
          },
          {
            label: I18n.t("dashboard.Finished_booking"),
            fill: false,
            lineTension: 0.1,
            backgroundColor: "#E5793B",
            borderColor: "#E5793B",
            borderWidth: 0,
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "#E5793B",
            pointBackgroundColor: "#E5793B",
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#E5793B",
            pointHoverBorderColor: "#E5793B",
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 5,
            data: [],
            yAxisID: "y-axis-2"
          }
        ]
      },
      overal: {
        profit: {
          total: 0,
          revenue: 0,
          dailyChanged: 0,
          dailyPercentChange: 0
        },
        booking: {
          total: 0,
          acceptancePercent: 0,
          dailyChanged: 0,
          dailyPercentChange: 0
        },
        completed: {
          total: 0,
          satisfationPercent: 0,
          dailyChanged: 0,
          dailyPercentChange: 0
        },
        customer: {
          total: 0,
          newCustomers: 0,
          dailyChanged: 0,
          dailyPercentChange: 0
        }
      }
    };
    this.createDefaultTimeLine = this.createDefaultTimeLine.bind(this);
  }

  UNSAFE_componentWillMount() { }
  componentDidMount() {
    this.updateDashboardData();
  }

  customePercentDoughnutChart = element => { };
  chartRendered = e => {
    //console.log("chart", e);
  };

  createDefaultTimeLine() {
    let timezone = this.props.auth.selectedFleet.timezone;
    let currentTime = moment().tz(timezone);
    let viewMode = this.state.criteria.viewMode;
    let date = this.state.criteria.date;
    switch (viewMode) {
      case viewModes.day:
        return common.getHourTimeLine(date, timezone);
      case viewModes.week:
        return common.getDateOfWeekTimeLine(date, timezone);
      case viewModes.month:
        return common.getDayOfMonthTimeLine(date, timezone);
    }
  }

  updateDashboardData = () => {
    let timezone = this.props.auth.selectedFleet.timezone;
    let ob = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      criteria: {
        date: moment(this.state.criteria.date)
          .tz(timezone)
          .format("YYYY-MM-DD HH:mm"),
        currencyISO: this.state.criteria.currency.iso,
        viewMode: this.state.criteria.viewMode,
        timezone
      }
    };
    // Creation time line
    let timeline = this.createDefaultTimeLine();
    if (!this.state.isLoading) {
      this.setState({ isLoading: true })
    }
    this.props.dashboardActions.getBookingStatistic(ob).then(data => {
      //update calendar style when hover date in view mode weekly
      if (this.state.criteria.viewMode == viewModes.week) {
        this.drawWeekActive();
      } else {
        this.removeWeekCss();
      }

      if (data && data.ok && data.res) {
        this.props.dashboardActions
          .getOtherInfo({ fleetId: this.props.auth.selectedFleet.fleetId })
          .then(othersData => {

            this.state.isLoading = false;
            //build revenue chart
            this.state.rawData = data.res;
            this.state.revenueChartData.labels = [];
            this.state.revenueChartData.datasets[0].data = [];
            this.state.revenueChartData.datasets[1].data = [];
            if (data.res.profitAndBooking) {
              timeline.map(item => {
                let label = item;
                if (label == "24") label = "23:59";
                if (label.length == 2) label = label + ":00";
                this.state.revenueChartData.labels.push(label);
                let findValue = data.res.profitAndBooking.find(
                  obj => obj.time == item
                );

                this.state.revenueChartData.datasets[0].data.push(
                  findValue ? findValue.profit.toFixed(2) : 0
                );
                this.state.revenueChartData.datasets[1].data.push(
                  findValue ? findValue.booking : 0
                );
              });
            }

            //Requested from chart data
            this.state.bookFromChartData.labels = [];
            this.state.bookFromChartData.datasets[0].data = [];
            this.state.bookFromChartData.datasets[0].backgroundColor = [];
            this.state.bookFromChartData.datasets[0].borderColor = [];
            this.state.bookFromChartData.datasets[0].hoverBackgroundColor = [];

            if(
              !checkEnable3rdPartyIntegration(this.props.auth.selectedFleet)
              && !_.isEmpty(data.res.requested)
            ) {
              delete data.res.requested["booking.com"]
            }

            if(
              !checkEnableHolidayTaxis(this.props.auth.selectedFleet)
              && !_.isEmpty(data.res.requested)
            ) {
              delete data.res.requested["HolidayTaxis"]
            }

            if(
              !checkEnableKarhoo(this.props.auth.selectedFleet)
              && !_.isEmpty(data.res.requested)
            ) {
              delete data.res.requested["Karhoo"]
            }
            
            Object.keys(data.res.requested).map(key => {
              if(key === 'booking.com') {
                this.state.bookFromChartData.labels.push(
                  I18n.t("dashboard." + "booking_com")
                );
              } else {
                this.state.bookFromChartData.labels.push(
                  I18n.t("dashboard." + key)
                );
              }
              this.state.bookFromChartData.datasets[0].data.push(
                data.res.requested[key]
              );
              this.state.bookFromChartData.datasets[0].backgroundColor.push(
                chartColors.requestChartColors[key]
              );
              this.state.bookFromChartData.datasets[0].borderColor.push(
                chartColors.requestChartColors[key]
              );
              // this.state.bookFromChartData.datasets[0].hoverBackgroundColor.push(
              //   chartColors.finished[key]
              // );
            });

            //book status chart
            this.state.bookStatusChartData = {
              labels: [],
              datasets: [
                {
                  data: [],
                  backgroundColor: [],
                  hoverBackgroundColor: []
                }
              ]
            };
            let finishedRes = _.get(data.res, 'finished', {})
            if(!_.get(this.props.auth, 'selectedFleet.delivery.enable', false)) {
              delete finishedRes['failed']
              delete finishedRes['partialCompleted']
            }
            Object.keys(finishedRes).map(key => {
              this.state.bookStatusChartData.labels.push(key);
              this.state.bookStatusChartData.datasets[0].data.push(
                data.res.finished[key]
              );
              this.state.bookStatusChartData.datasets[0].backgroundColor.push(
                chartColors.finished[key]
              );
              this.state.bookStatusChartData.datasets[0].hoverBackgroundColor.push(
                chartColors.finished[key]
              );
            });
            //rating status chart
            this.state.ratingChartData = {
              labels: [],
              datasets: [
                {
                  data: [],
                  backgroundColor: [],
                  hoverBackgroundColor: []
                }
              ]
            };
            Object.keys(data.res.rating.stars).map(key => {
              if (key != "total") {
                this.state.ratingChartData.labels.push(key);
                this.state.ratingChartData.datasets[0].data.push(
                  data.res.rating.stars[key]
                );
                this.state.ratingChartData.datasets[0].backgroundColor.push(
                  chartColors.rating[key]
                );
                this.state.ratingChartData.datasets[0].hoverBackgroundColor.push(
                  chartColors.rating[key]
                );
              }
            });

            //overal

            let profitChange = this.percentCalculate(
              data.res.total.profit,
              data.res.lastTime.profit
            );
            // make sure profit change is negative if today smaller than last time
            if (data.res.total.profit < data.res.lastTime.profit) {
              if (profitChange > 0) {
                profitChange = -profitChange;
              }
            }
            // make sure profit change is greater than 0 if today greater than last time
            if (data.res.total.profit > data.res.lastTime.profit) {
              if (profitChange < 0) {
                profitChange = -profitChange;
              }
            }
            this.state.overal = {
              profit: {
                total: data.res.total.profit,
                revenue: data.res.total.revenue,
                dailyChanged: data.res.total.profit - data.res.lastTime.profit,
                dailyPercentChange: profitChange
              },
              booking: {
                total: data.res.total.requested,
                acceptancePercent: data.res.total.requested
                  ? (
                    (data.res.total.accepted * 100) /
                    data.res.total.requested
                  ).toFixed(2)
                  : 0,
                dailyChanged:
                  data.res.total.accepted - data.res.lastTime.accepted,
                dailyPercentChange: this.percentCalculate(
                  data.res.total.requested,
                  data.res.lastTime.requested
                )
              },
              completed: {
                total: data.res.total.completed,
                satisfationPercent: data.res.rating.stars.total
                  ? (
                    ((data.res.rating.stars.rate4 +
                      data.res.rating.stars.rate5) *
                      100) /
                    data.res.rating.stars.total
                  ).toFixed(2)
                  : 0,
                dailyChanged:
                  data.res.total.completed - data.res.lastTime.completed,
                dailyPercentChange: this.percentCalculate(
                  data.res.total.completed,
                  data.res.lastTime.completed
                )
              },
              customer: {
                newCustomers: data.res.customer.totalRegisters,
                dailyChanged:
                  data.res.customer.totalRegisters -
                  data.res.lastTime.totalRegisters,
                dailyPercentChange: this.percentCalculate(
                  data.res.customer.totalRegisters,
                  data.res.lastTime.totalRegisters
                ),
                paxApp: 0,
                web: 0,
                cc: 0,
                other: 0,
                total: othersData ? othersData.res : 0
              }
            };

            this.setState(this.state);
          });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };
  viewModeChangeHandle = key => {
    this.state.criteria.viewMode = key;
    this.setState({ criteria: this.state.criteria }, this.updateDashboardData);
  };

  percentCalculate(current, last) {
    if (last == 0) {
      return current == 0 ? 0 : 100;
    }

    return (((current - last) * 100) / last).toFixed(2);
  }

  currencyChangeHandle = cr => {
    this.state.criteria.currency = cr;
    this.setState({ criteria: this.state.criteria }, this.updateDashboardData);
  };
  dateChangeHandle = e => {
    let viewMode = this.state.criteria.viewMode;
    let date = e;
    if (viewMode == viewModes.month && _.isObject(date)) {
      date = date.add("days", 1);
    }
    this.state.criteria.date = date;
    this.setState(
      {
        criteria: {
          ...this.state.criteria
        }
      },
      this.updateDashboardData
    );
  };

  renderHintLabel(label, hint, placement) {
    return (
      <OverlayTrigger
        overlay={<Tooltip id={"tooltip"}>{hint}</Tooltip>}
        placement={placement || "top"}
        delayShow={300}
        delayHide={150}
      >
        <span>{label}</span>
      </OverlayTrigger>
    );
  }

  validDate(date) {
    let current = moment();
    let startDate = moment("2018-08", "YYYY-MM");
    if (date.isAfter(startDate) && current.isAfter(date)) return true;
    return false;
  }

  removeWeekCss() {
    $("tr:has(td.rdtDay)").css({ "background-color": "" });
    $("tr:has(td.rdtDay.rdtActive)").css({
      "background-color": ""
    });
    $("tr:has(td.rdtDay.rdtToday)").css({
      "background-color": ""
    });
  }

  drawWeekActive = () => {
    $("tr:has(td.rdtDay)").css({ "background-color": "#fff0" });
    if ($("tr:has(td.rdtDay.rdtActive)").length) {
      $("tr:has(td.rdtDay.rdtToday)").css({
        "background-color": ""
      });
      $("tr:has(td.rdtDay.rdtActive)").css({
        "background-color": "#428bca"
      });
    }
    else {
      $("tr:has(td.rdtDay.rdtToday)").css({
        "background-color": "#428bca"
      });
    }
  };

  getDateValue = (localeCode) => {
    let timezone = this.props.auth.selectedFleet.timezone;
    let date = moment(this.state.criteria.date).tz(timezone);
    const { language: { locale: language = 'en-US' } = {} } = this.props;
    switch (this.state.criteria.viewMode) {
      case viewModes.day:
        return moment(date).locale(language).format("MM/DD/YYYY");
      case viewModes.week:
        let startDate = moment(date)
          .locale(localeCode)
          .startOf('week')
          .startOf('day')
          .locale(language);
        let endDate = moment(date)
          .locale(localeCode)
          .endOf('week')
          .endOf('day')
          .locale(language);
        return `${startDate.format("MM/DD/YYYY")} - ${endDate.format(
          "MM/DD/YYYY"
        )}`;
      case viewModes.month:
        return moment(date).locale(language).format("MM/YYYY");
    }
    return moment(date).locale(language).format("MM/YYYY");
  };

  render() {
    const { /* localeCode = 'en',  */timezone = 'BST' } = this.props.auth.selectedFleet || {};
    const localeCode = 'vi';
    const { isLoading } = this.state;
    const { language: { locale: language = 'en-US' } = {} } = this.props;
    return (
      <div className="fill dashboard-content">
        <div
          className={
            this.state.criteria.viewMode == viewModes.week
              ? "db-header pd-20 calendar nav-dashboard"
              : "db-header pd-20 nav-dashboard"
          }
        >
          <ButtonGroup>
            <Button
              onClick={() => {
                this.viewModeChangeHandle(viewModes.day);
              }}
              className={
                this.state.criteria.viewMode == viewModes.day ? "active" : ""
              }
              disabled={isLoading}
            >
              <Translate value="dashboard.Daily" />
            </Button>
            <Button
              onClick={() => {
                this.viewModeChangeHandle(viewModes.week);
              }}
              className={
                this.state.criteria.viewMode == viewModes.week ? "active" : ""
              }
              disabled={isLoading}
            >
              <Translate value="dashboard.Weekly" />
            </Button>
            <Button
              onClick={() => {
                this.viewModeChangeHandle(viewModes.month);
              }}
              className={
                this.state.criteria.viewMode == viewModes.month ? "active" : ""
              }
              disabled={isLoading}
            >
              <Translate value="dashboard.Monthly" />
            </Button>
          </ButtonGroup>
        </div>
        <div className="calendar-inner">
          <div className="calendar-select">
            <Datetime
              viewMode={
                this.state.criteria.viewMode == viewModes.month
                  ? "months"
                  : "days"
              }
              className="mr-r-20"
              timeFormat={false}
              onChange={this.dateChangeHandle}
              //locale={localeCode}
              closeOnSelect
              isValidDate={this.validDate}
              dateFormat={
                this.state.criteria.viewMode == viewModes.month
                  ? "MM/YYYY"
                  : "MM/DD/YYYY"
              }
              value={moment(this.state.criteria.date).tz(timezone).locale(language)}
              inputProps={{
                placeholder: "mm/dd/yyyy",
                value: this.getDateValue(localeCode),
                readOnly: true
              }}
              disabled={isLoading}
            />
            {this.props.auth.selectedFleet.currencies &&
              this.props.auth.selectedFleet.currencies.length > 1 ? (
                <DropdownButton
                  className="curency-format"
                  title={
                    this.state.criteria.currency &&
                    this.state.criteria.currency.iso
                  }
                  disabled={isLoading}
                >
                  {this.props.auth.selectedFleet.currencies &&
                    this.props.auth.selectedFleet.currencies.map(cr => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            this.currencyChangeHandle(cr);
                          }}
                          eventKey={cr.iso}
                        >
                          {cr.iso}
                        </Dropdown.Item>
                      );
                    })}
                </DropdownButton>
              ) : null}
          </div>
          <div className="export-pdf">
            {/* <Image src={pdfIcon} /> <span>Export to PDF</span> */}
          </div>
        </div>
        <div className="db-body db-main-contain pd-20">
          <Row className="db-body-overal">
            <Col sm={12} md={6} lg={3}>
              <div className="db-body-overal-item db-items">
                <div className="db-item-icon revenue">
                  <Image src={revenueIcon} />
                </div>
                <div className="db-item-info">
                  <div>
                    <div className="revenue-text-color item-title">
                      {this.renderHintLabel(
                        I18n.t("dashboard.Profit"),
                        I18n.t("dashboard.ProfitToolTips")
                      )}
                    </div>
                    <div className="overal-item-value revenue-text-color">
                      {currencyFormatter.format(
                        this.state.overal.profit.total,
                        {
                          code: this.state.criteria.currency.iso
                        }
                      )}
                    </div>
                    <div className="overal-item-bottom">
                      {this.renderHintLabel(
                        I18n.t(
                          "dashboard." +
                          this.state.criteria.viewMode +
                          "_change"
                        ),
                        I18n.t("dashboard.ProfitChangeToolTips")
                      )}
                      <div
                        className={
                          this.state.overal.profit.dailyPercentChange < 0
                            ? "negative mr-l-5"
                            : "positive mr-l-5"
                        }
                      >
                        {this.state.overal.profit.dailyPercentChange}%
                      </div>
                    </div>
                  </div>
                  <div className="overal-item-bottom">
                    {this.renderHintLabel(
                      `${currencyFormatter.format(
                        this.state.overal.profit.revenue,
                        { code: this.state.criteria.currency.iso }
                      )} ${I18n.t("dashboard.Revenue")}`,
                      I18n.t("dashboard.RevenueToolTips"),
                      "bottom"
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="db-body-overal-item db-items">
                <div className="db-item-icon booking-request">
                  <Image src={bookReqIcon} />
                </div>
                <div className="db-item-info">
                  <div>
                    <div className="book-req-text-color item-title">
                      {this.renderHintLabel(
                        I18n.t("dashboard.Booking_requests"),
                        I18n.t("dashboard.BookingRequestsToolTips")
                      )}
                    </div>
                    <div className="overal-item-value book-req-text-color">
                      {toLocaleNumber(this.state.overal.booking.total)}
                    </div>
                    <div className="overal-item-bottom">
                      {this.renderHintLabel(
                        I18n.t(
                          "dashboard." +
                          this.state.criteria.viewMode +
                          "_change"
                        ),
                        I18n.t("dashboard.BookingChangeHint")
                      )}
                      <div
                        className={
                          this.state.overal.booking.dailyPercentChange < 0
                            ? "negative mr-l-5"
                            : "positive mr-l-5"
                        }
                      >
                        {this.state.overal.booking.dailyPercentChange}%
                      </div>
                    </div>
                  </div>
                  <div className="overal-item-bottom">
                    {this.renderHintLabel(
                      `${this.state.overal.booking.acceptancePercent}% ${I18n.t(
                        "dashboard.acceptance"
                      )}`,
                      I18n.t("dashboard.PercentageOfAcceptanceToolTips"),
                      "bottom"
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="db-body-overal-item db-items">
                <div className="db-item-icon completed-booking">
                  <Image src={bookCompleteIcon} />
                </div>
                <div className="db-item-info">
                  <div>
                    <div className="comple-book-text-color item-title">
                      {this.renderHintLabel(
                        I18n.t("dashboard.Complete_bookings"),
                        I18n.t("dashboard.CompletedBookingHint")
                      )}
                    </div>
                    <div className="overal-item-value comple-book-text-color">
                      {toLocaleNumber(this.state.overal.completed.total)}
                    </div>
                    <div className="overal-item-bottom">
                      {this.renderHintLabel(
                        I18n.t(
                          "dashboard." +
                          this.state.criteria.viewMode +
                          "_change"
                        ),
                        I18n.t("dashboard.CompleteBookingDailyChangeHint")
                      )}
                      <div
                        className={
                          this.state.overal.completed.dailyPercentChange < 0
                            ? "negative mr-l-5"
                            : "positive mr-l-5"
                        }
                      >
                        {this.state.overal.completed.dailyPercentChange}%
                      </div>
                    </div>
                  </div>
                  <div>
                    {this.renderHintLabel(
                      `${
                      this.state.overal.completed.satisfationPercent
                      }% ${I18n.t("dashboard.satisfaction")}`,
                      I18n.t("dashboard.PercentageOfSatisfactionHint"),
                      "bottom"
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="db-body-overal-item db-items">
                <div className="db-item-icon customer">
                  <Image src={customerIcon} />
                </div>
                <div className="db-item-info">
                  <div>
                    <div className="customer-text-color item-title">
                      {this.renderHintLabel(
                        I18n.t("dashboard.New_customers"),
                        I18n.t("dashboard.NewCustomersHint")
                      )}
                    </div>
                    <div className="overal-item-value customer-text-color">
                      {toLocaleNumber(this.state.overal.customer.newCustomers)}
                    </div>
                    <div className="overal-item-bottom">
                      {this.renderHintLabel(
                        I18n.t(
                          "dashboard." +
                          this.state.criteria.viewMode +
                          "_change"
                        ),
                        I18n.t("dashboard.CustomerChangeHint")
                      )}
                      <div
                        className={
                          this.state.overal.customer.dailyPercentChange < 0
                            ? "negative mr-l-5"
                            : "positive mr-l-5"
                        }
                      >
                        {this.state.overal.customer.dailyPercentChange}%
                      </div>
                    </div>
                  </div>
                  <div>
                    {this.renderHintLabel(
                      I18n.t("dashboard.Customer_count").format(
                        toLocaleNumber(this.state.overal.customer.total || 0)
                      ),
                      I18n.t("dashboard.TotalCustomer"),
                      "bottom"
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} className="mr-t-20">
              <div className="db-body chart-container">
                <div className="db-chart-title">
                  <span className="title">
                    {this.renderHintLabel(
                      I18n.t("dashboard.Profit_finish_booking"),
                      I18n.t("dashboard.ProfitAndFinishedBookingHint"),
                      "bottom"
                    )}
                  </span>
                </div>
                <div className="db-chart pd-20">
                  <Line
                    data={this.state.revenueChartData}
                    options={{
                      maintainAspectRatio: false,

                      scales: {
                        yAxes: [
                          {
                            type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            display: true,
                            position: "right",
                            id: "y-axis-2",
                            ticks: {
                              fontSize: 12,
                              beginAtZero: true,
                              fontColor: "#E5793B"
                            }
                          },
                          {
                            type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            gridLines: {},
                            ticks: {
                              fontSize: 12,
                              beginAtZero: true,
                              fontColor: "#04BE76"
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              fontSize: 12,
                              beginAtZero: true,
                              fontColor: "#e6e6e6"
                            },
                            gridLines: {}
                          }
                        ]
                      },
                      legend: {
                        display: true,
                        position: "bottom",
                        labels: {
                          usePointStyle: true,
                          fontSize: 12,
                          boxWidth: 20,
                          fontColor: "#e6e6e6"
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} className="mr-t-20">
              <div className="db-body chart-container">
                <div className="db-chart-title">
                  <span className="title">
                    {this.renderHintLabel(
                      I18n.t("dashboard.Booking_requests"),
                      I18n.t("dashboard.BookingRequestHint")
                    )}
                  </span>
                </div>
                <div className="db-chart">
                  <HorizontalBar
                    data={this.state.bookFromChartData}
                    options={{
                      maintainAspectRatio: false,
                      legend: {
                        display: false
                      },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              fontColor: "#e6e6e6",
                              fontSize: 12
                            },
                            gridLines: {
                              // color: "#363a44",
                              // drawOnChartArea: true
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              fontColor: "#e6e6e6",
                              fontSize: 12
                            },
                            gridLines: {
                              // color: "#363a44",
                              // drawOnChartArea: true
                            }
                          }
                        ]
                      }
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} className="mr-t-20">
              <div className="db-body chart-container">
                <div className="db-chart-title">
                  <span className="title">
                    {this.renderHintLabel(
                      I18n.t("dashboard.Finished_booking"),
                      I18n.t("dashboard.FinishedBookingChartsHint")
                    )}
                  </span>
                </div>
                <Row>
                  <Col
                    sm={
                      !this.props.auth.selectedFleet.passenger.drvRating
                        ? 6
                        : 12
                    }
                  >
                    {!_.isEmpty(this.state.bookStatusChartData) && (
                      <div className="db-chart">
                        <Pie
                          ref={this.chartRendered}
                          data={this.state.bookStatusChartData}
                          options={{
                            ...doughnutOptions,
                            title: {
                              ...doughnutOptions.title,
                              text: I18n.t("dashboard.Finished_booking_char_header").format(
                                this.state.bookStatusChartData
                                  ? this.state.bookStatusChartData.datasets[0].data.sum()
                                  : 0
                              ),
                              fontColor: "#e6e6e6"
                            }
                          }}
                        />
                      </div>
                    )}
                  </Col>
                  {!this.props.auth.selectedFleet.passenger.drvRating && 
                    !_.isEmpty(this.state.ratingChartData) ? (
                    <Col sm={6}>
                      <div className="db-chart">
                        <Pie
                          ref={this.chartRendered}
                          data={this.state.ratingChartData}
                          options={{
                            ...doughnutOptions,
                            title: {
                              ...doughnutOptions.title,
                              text: I18n.t("dashboard.Ratings").format(
                                this.state.ratingChartData
                                  ? this.state.ratingChartData.datasets[0].data.sum()
                                  : 0
                              ),
                              fontColor: "#e6e6e6"
                            }
                          }}
                        />
                      </div>
                    </Col>
                  ) : (
                      ""
                    )}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth, i18n } = state;
  return {
    auth: auth,
    language: i18n
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
