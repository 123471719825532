import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import {
  textboxNumberHelper,
  removeDuplicateArray,
} from '../../../../utils/commonFunctions';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';

const offlinePayment = [0, 16, 17];
const onlinePayment = [
  1, 3, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 18, 19, 20, 21, 23, 24,
];

function ConvertSpent(props) {
  const { spentAmount, handleChangeData, paymentMethod, hasPermission } = props;
  const [showOfflinePayment, SetShowOfflinePayment] = useState(true);
  const [spentAmountOfflinePayment, setSpentAmountOfflinePayment] = useState(
    []
  );
  const [spentAmountOnlinePayment, setSpentAmountOnlinePayment] = useState([]);
  const [isShowTransport, setIsShowTransport] = useState(false);
  const [isShowDelivery, setIsShowDelivery] = useState(false);
  const [isShowFood, setIsShowFood] = useState(false);
  const [isShowMart, setIsShowMart] = useState(false);
  const [isShowIntercity, setIsShowIntercity] = useState(false);
  const [applyCash, setApplyCash] = useState(false);

  useEffect(() => {
    const selectedFleet = props.selectedFleet;
    if (selectedFleet) {
      setIsShowTransport(
        selectedFleet.transport && selectedFleet.transport.enable
          ? selectedFleet.transport.enable
          : false
      );
      setIsShowDelivery(
        selectedFleet.delivery && selectedFleet.delivery.enable
          ? selectedFleet.delivery.enable
          : false
      );
      setIsShowFood(
        selectedFleet.food && selectedFleet.food.enable
          ? selectedFleet.food.enable
          : false
      );
      setIsShowMart(
        selectedFleet.mart && selectedFleet.mart.enable
          ? selectedFleet.mart.enable
          : false
      );
      setIsShowIntercity(
        selectedFleet.intercity && selectedFleet.intercity.enable
          ? selectedFleet.intercity.enable
          : false
      );
    }
  }, []);

  useEffect(() => {
    let paymentMethods = [...paymentMethod.individualTypes];
    if (
      paymentMethods.length > 0 &&
      paymentMethods.findIndex((item) => item.type === 'cash') === -1
    ) {
      SetShowOfflinePayment(false);
    }
    setSpentAmountOnlinePayment(initSpentAmountPayment(true));
    setSpentAmountOfflinePayment(initSpentAmountPayment(false));
  }, [paymentMethod]);

  useEffect(() => {
    if (spentAmount.length > 0) {
      let checkDataOnlineMethod = removeDuplicateArray(
        spentAmount.filter((item) => item.method.some((m) => m !== 0)),
        'service'
      );
      let checkDataOfflineMethod = removeDuplicateArray(
        spentAmount.filter((item) => item.method.some((m) => m === 0)),
        'service'
      );
      if (checkDataOnlineMethod.length > 0) {
        const mapOnlineMethod = spentAmountOnlinePayment.map((ele) => {
          let data = checkDataOnlineMethod.find(
            (item) => item.service === ele.service
          );
          if (data) {
            ele.value = data.value;
          }
          return ele;
        });
        setSpentAmountOnlinePayment(mapOnlineMethod);
      }

      if (checkDataOfflineMethod.length > 0) {
        const mapOfflineMethod = spentAmountOfflinePayment.map((ele) => {
          let data = checkDataOfflineMethod.find(
            (item) => item.service === ele.service
          );
          if (data) {
            ele.value = data.value;
          }
          return ele;
        });
        setApplyCash(true);
        setSpentAmountOfflinePayment(mapOfflineMethod);
      }
    }
  }, [spentAmount]);

  useEffect(() => {
    if (applyCash) {
      handleChangeData(
        'ConvertSpent',
        spentAmountOnlinePayment.concat(spentAmountOfflinePayment)
      );
    } else {
      handleChangeData('ConvertSpent', spentAmountOnlinePayment);
    }
  }, [applyCash]);

  const initSpentAmountPayment = (online = true) => {
    let spentData = [];
    isShowTransport &&
      spentData.push({
        service: 'transport',
        method: online ? onlinePayment : offlinePayment,
        value: 0,
      });
    isShowDelivery &&
      spentData.push({
        service: 'delivery',
        method: online ? onlinePayment : offlinePayment,
        value: 0,
      });
    isShowFood &&
      spentData.push({
        service: 'food',
        method: online ? onlinePayment : offlinePayment,
        value: 0,
      });
    isShowMart &&
      spentData.push({
        service: 'mart',
        method: online ? onlinePayment : offlinePayment,
        value: 0,
      });
    isShowIntercity &&
      spentData.push({
        service: 'intercity',
        method: online ? onlinePayment : offlinePayment,
        value: 0,
      });
    return spentData;
  };

  const handleChangeValueOnlinePayment = (key, e) => {
    let spentData = spentAmountOnlinePayment || [];
    spentData = spentData.map((item) => {
      if (item.service === key) {
        item.value = e.target.value;
      }
      return item;
    });
    handleChangeData(
      'ConvertSpent',
      applyCash ? spentData.concat(spentAmountOfflinePayment) : spentData
    );
  };

  const handleChangeValueOfflinePayment = (key, e) => {
    let spentData = spentAmountOfflinePayment || [];
    spentData = spentData.map((item) => {
      if (item.service === key) {
        item.value = e.target.value;
      }
      return item;
    });
    handleChangeData(
      'ConvertSpent',
      spentAmountOnlinePayment.concat(spentData)
    );
  };

  const handleChangeApplyCash = (e) => {
    let spentData =
      spentAmountOfflinePayment.length > 0
        ? spentAmountOfflinePayment
        : initSpentAmountPayment(false);
    let value = e.target.checked;
    if (value) {
      setSpentAmountOfflinePayment(spentData);
    }
    setApplyCash(value);
  };

  return (
    <div className="form-group-info">
      <FormGroupTitle className="default-font-size">
        <Translate value="pointConfig.Convert_spent_amount_to_points" />
      </FormGroupTitle>
      <>
        <FormGroup className="qup-input-group">
          <Form.Label>
            <Translate value="pointConfig.Online_Payment" />
          </Form.Label>
        </FormGroup>
        {spentAmountOnlinePayment.length > 0 &&
          spentAmountOnlinePayment.map((item, index) => {
            return (
                <Row key={index}>
                  <Col xs={2}>
                    <Form.Label>
                      {I18n.t(`pointConfig.${item.service}`)}
                      {/* {' '}
                                        {'\u00A0'}
                                        <span className="danger"> *</span> */}
                    </Form.Label>
                  </Col>
                  <Col xs={5}>
                    <FormGroup className="qup-input-group">
                      <InputGroup className="single-addon-left">
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            {props.selectedFleet.currencies[0].symbol}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          className="form-custom"
                          type="number"
                          onChange={(e) =>
                            handleChangeValueOnlinePayment(item.service, e)
                          }
                          onBlur={(e) => {
                            textboxNumberHelper.onBlurHandle(e, (e) => {
                              handleChangeValueOnlinePayment(item.service, e);
                            });
                          }}
                          onFocus={(e) => {
                            textboxNumberHelper.onfocusHandle(e, (e) => {
                              handleChangeValueOnlinePayment(item.service, e);
                            });
                          }}
                          value={item.value}
                          disabled={!hasPermission}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs={5}>
                    <FormGroup className="qup-input-group">
                      <InputGroup className="single-addon-right">
                        <FormControl
                          className="form-custom"
                          type="number"
                          min={0}
                          // onChange={e => this.onInputChange(e)}
                          value={'1'}
                          disabled={true}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>
                            <Translate value="pointConfig.point" />
                          </InputGroup.Text>
                        </InputGroup.Append>{' '}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
            );
          })}
      </>
      {showOfflinePayment && (
        <>
          <FormGroup>
            <CcCheckbox
              checked={applyCash ? applyCash : false}
              onChange={(e) => handleChangeApplyCash(e)}
              text={I18n.t('pointConfig.applyCash')}
              disabled={!hasPermission}
            />
          </FormGroup>
          <>
            {applyCash &&
              spentAmountOfflinePayment.length > 0 &&
              spentAmountOfflinePayment.map((item, index) => {
                return (
                  <Row key={index}>
                    <Col xs={2}>
                      <Form.Label>
                        {I18n.t(`pointConfig.${item.service}`)}
                        {/* {' '}
                                      {'\u00A0'}
                                      <span className="danger"> *</span> */}
                      </Form.Label>
                    </Col>
                    <Col xs={5}>
                      <FormGroup className="qup-input-group">
                        <InputGroup className="single-addon-left">
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {props.selectedFleet.currencies[0].symbol}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            className="form-custom"
                            type="number"
                            // min={0}
                            onChange={(e) =>
                              handleChangeValueOfflinePayment(item.service, e)
                            }
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(e, (e) => {
                                handleChangeValueOfflinePayment(
                                  item.service,
                                  e
                                );
                              });
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(e, (e) => {
                                handleChangeValueOfflinePayment(
                                  item.service,
                                  e
                                );
                              });
                            }}
                            value={item.value}
                            onWheel={(e) => e.preventDefault()}
                            disabled={!hasPermission}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={5}>
                      <FormGroup className="qup-input-group">
                        <InputGroup className="single-addon-right">
                          <FormControl
                            className="form-custom"
                            type="number"
                            min={0}
                            // onChange={e => this.onInputChange(e)}
                            value={'1'}
                            // onWheel={e => e.preventDefault()}
                            disabled={true}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <Translate value="pointConfig.point" />
                            </InputGroup.Text>
                          </InputGroup.Append>{' '}
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                );
              })}
          </>
        </>
      )}
    </div>
  );
}

export default ConvertSpent;
