import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  FormGroup,
  Form,
  FormControl,
  Button,
  Modal,
  InputGroup,
  ButtonToolbar,
} from 'react-bootstrap';
import TableActions from '../../../components/table/tableAction/TableActions';
import { connect } from 'react-redux';
import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import StickyTable from '../../../components/table/stickyTable/StickyTable';
import { tableWebhookColumns } from './tableWebhookColumns';
import { forEach } from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import copy from 'copy-to-clipboard';
import CloseIcon from '@icons/material/CloseIcon';
import {
  MultiSelectCommonFunc,
  isValidHttpsUrl,
} from '../../../utils/commonFunctions';
import Confirm from '../../../components/confirm/Confirm';
import './index.scss';

const ACTIONS_TYPE = {
  updateInfo: 'updateInfo',
  viewInfo: 'viewInfo',
  delete: 'delete',
};

const ACTION_ITEMS = [
  {
    eventKey: ACTIONS_TYPE.updateInfo,
    label: 'Update Info',
    hasPermissionAction: true,
  },
  {
    eventKey: ACTIONS_TYPE.viewInfo,
    label: 'View Info',
    hasPermissionAction: false,
  },
  {
    eventKey: ACTIONS_TYPE.delete,
    label: 'Delete',
    hasPermissionAction: true,
  },
];

const Webhook = ({ apiKeyId, ...props }, context) => {
  const [hasPermissionAction, setHasPermissionAction] = useState(true);
  const [showCreateWebhookModal, setShowCreateWebhookModal] = useState(false);

  const [showApiKeyInfoModal, setShowApiKeyInfoModal] = useState(false);
  const [keyInfo, setKeyInfo] = useState({});
  const [rowHeight, setRowHeight] = useState(50);

  const [confirm, setConfirm] = useState();
  const [infoUpdating, setInfoUpdating] = useState();
  const [webhookList, setWebhookList] = useState([]);
  const [apiKeyDetail, setAPIKeysDetail] = useState();
  const [endpointInfo, setEndpointInfo] = useState({
    url: '',
    signingSecret: '',
    events: [],
  });

  useEffect(() => {
    setHasPermissionAction(props.permissions?.actions || false);
    getWebhookList();
  }, []);

  const getWebhookList = () => {
    if (apiKeyId) {
      props.settingActions
        .detailApiKey({
          fleetId: props.auth.selectedFleet.fleetId,
          apiKeyId: apiKeyId,
        })
        .then((response) => {
          if (response?.res?._id && response?.res?.webhooks) {
            setAPIKeysDetail(response?.res);
            setWebhookList(response?.res?.webhooks);
          } else {
            context.notification('error', '');
          }
        })
        .catch(() => {});
    }
  };

  const openKeyModal = (data) => {
    setKeyInfo({
      signingSecret: data.signingSecret,
    });
    setShowApiKeyInfoModal(true);
  };

  const getTableColumns = () => {
    let tableColums = Object.assign([], tableWebhookColumns);
    forEach(tableColums, (col) => {
      switch (col.key) {
        case 'actions':
          col.customCell = (obj, rowIndex) => {
            let checkActionPermission = ACTION_ITEMS.filter(
              (ob) => ob.hasPermissionAction == hasPermissionAction
            );
            return (
              <TableActions
                rowIndex={rowIndex}
                rowData={obj}
                onSelect={(eventKey) => handleMenuClick(eventKey, obj)}
                totalRow={webhookList ? webhookList.length : 0}
                rowHeight={rowHeight}
                menuItems={checkActionPermission}
                tableHeight={getTableHeight()}
                tableId={'table_webhook'}
              />
            );
          };
          break;
        case 'signingSecret':
          col.customCell = (data) => {
            return (
              <span
                onClick={() => {
                  openKeyModal(data);
                }}
                className={'columnCopy'}
                style={{ cursor: 'pointer' }}
              >
                {data.signingSecret}
              </span>
            );
          };
          break;
        default:
          break;
      }
    });
    return tableColums;
  };

  const handleMenuClick = (key, obj) => {
    switch (key) {
      case ACTIONS_TYPE.updateInfo: {
        const webhookUpdate = apiKeyDetail?.webhooks?.find(
          (wh) => wh._id === obj._id
        );
        if (webhookUpdate?._id) {
          setEndpointInfo(webhookUpdate);
          setShowCreateWebhookModal(true);
          setInfoUpdating(obj._id);
        }
        break;
      }
      case ACTIONS_TYPE.viewInfo: {
        const webhookUpdate = apiKeyDetail?.webhooks?.find(
          (wh) => wh._id === obj._id
        );
        if (webhookUpdate?._id) {
          setEndpointInfo(webhookUpdate);
          setShowCreateWebhookModal(true);
          setInfoUpdating(obj._id);
        }
        break;
      }
      case ACTIONS_TYPE.delete: {
        setConfirm({
          id: ACTIONS_TYPE.delete,
          title: 'Delete',
          body: 'Are you sure you want to delete this endpoint?',
          buttonTitle: 'Delete',
          closeButtonText: 'Cancel',
          webhookId: obj?._id,
          url: obj?.url,
        });
        break;
      }
      default:
        break;
    }
  };

  const handleConfirmButtonClick = (id, confirm) => {
    switch (id) {
      case ACTIONS_TYPE.delete: {
        props.settingActions
          .deleteEndpoint({
            fleetId: props.auth.selectedFleet.fleetId,
            apiKeyId: apiKeyId,
            webhookId: confirm.webhookId,
            url: confirm.url,
            appName: apiKeyDetail?.appName,
          })
          .then((response) => {
            if (response.res._id) {
              context.notification(
                'success',
                'Endpoint has been deleted successfully.'
              );
              handleConfirmCloseClick();
              getWebhookList();
            } else {
              context.notification('error', '');
            }
          })
          .catch(() => {});
        break;
      }
    }
  };

  const handleConfirmCloseClick = () => {
    setConfirm(null);
  };

  const getTableHeight = () => {
    return 400;
  };

  const handleCreateOrUpdateEndpoint = async () => {
    const checkUrl = isValidHttpsUrl(endpointInfo.url);
    if (!checkUrl.valid) {
      context.notification('error', I18n.t(`APIKeySetting.${checkUrl.msgErr}`));
      return;
    }
    const param = {
      fleetId: props.auth.selectedFleet.fleetId,
      url: endpointInfo.url,
      signingSecret: endpointInfo.signingSecret,
      events: endpointInfo.events,
      apiKeyId: apiKeyId,
      appName: apiKeyDetail?.appName,
    };
    if (infoUpdating) param.webhookId = infoUpdating;
    props.loadingBarActions.showLoadingSpiner();
    try {
      const response = infoUpdating
        ? await props.settingActions.updateEndpoint(param)
        : await props.settingActions.createEndpoint(param);
      props.loadingBarActions.hideLoadingSpiner();
      if (response?.res?._id) {
        context.notification(
          'success',
          infoUpdating
            ? 'API has been updated successfully.'
            : 'API has been created successfully.'
        );
        getWebhookList();
        closeCreateWebhookModal();
        return;
      } else {
        context.notification('error', response?.error?.message);
      }
    } catch (error) {
      props.loadingBarActions.hideLoadingSpiner();
    }
  };

  const handleCopyClick = (key) => {
    copy(key);
    context.notification('success', 'Copied!');
  };

  const closeCreateWebhookModal = () => {
    setShowCreateWebhookModal(false);
    setEndpointInfo({});
    setInfoUpdating();
  };

  const renderApiKeyInfo = () => {
    return (
      <Modal
        show={showApiKeyInfoModal}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="KeyApiModal"
        onHide={() => {
          setShowApiKeyInfoModal(false);
          setKeyInfo();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('APIKeySetting.signingSecret')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{I18n.t('APIKeySetting.titleSigningSecret')}</p>
          <FormGroup>
            <InputGroup className="single-addon-right">
              <FormControl
                className={'form-custom textareaEmail'}
                value={keyInfo?.signingSecret}
                disabled={true}
              />

              <InputGroup.Append>
                <InputGroup.Text>
                  <i
                    class="fa fa-copy"
                    onClick={() => {
                      handleCopyClick(keyInfo?.signingSecret);
                    }}
                  />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </FormGroup>
          <div className="btnGroup">
            <Button
              className="btn-save view-button"
              onClick={() => {
                setShowApiKeyInfoModal(false);
                setKeyInfo();
              }}
            >
              <Translate value="APIKeySetting.Done" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const handleChangeWebhook = (value, key) => {
    setEndpointInfo({
      ...endpointInfo,
      [key]: value,
    });
  };

  const renderCreateOrUpdateWebhookModal = () => {
    return (
      <Modal
        show={showCreateWebhookModal}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="createApiModal"
        onHide={closeCreateWebhookModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {infoUpdating
              ? I18n.t('APIKeySetting.ManageWebhooks')
              : I18n.t('APIKeySetting.CreateWebhook')}
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeCreateWebhookModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {infoUpdating && (
            <FormGroup>
              <Form.Label>
                <Translate value={`APIKeySetting.subscriptionID`} />
              </Form.Label>
              <FormControl
                className={'form-custom'}
                value={endpointInfo?._id || ''}
                disabled={true}
                maxLength={100}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Form.Label>
              <Translate value={`APIKeySetting.webhookURL`} />
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              className={'form-custom textareaEmail'}
              value={endpointInfo?.url || ''}
              disabled={!hasPermissionAction}
              onChange={(e) => {
                handleChangeWebhook(e.target.value, 'url');
              }}
              maxLength={100}
            />
          </FormGroup>
          <FormGroup>
            <Form.Label>
              <Translate value={`APIKeySetting.signingSecret`} />
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              className={'form-custom textareaEmail'}
              value={endpointInfo?.signingSecret || ''}
              disabled={!hasPermissionAction}
              onChange={(e) => {
                handleChangeWebhook(e.target.value, 'signingSecret');
              }}
              // placeholder={I18n.t('invoice.Content')}
              maxLength={100}
            />
          </FormGroup>
          <FormGroup controlId="formControlsSelectMultiple">
            <Form.Label>
              <Translate value="APIKeySetting.eventsToSend" />
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              className="form-custom select-zone"
              as="select"
              disabled={!hasPermissionAction}
              multiple
              onChange={(e) => {
                handleChangeWebhook(
                  MultiSelectCommonFunc.getSelectValues(e),
                  'events'
                );
              }}
              value={endpointInfo?.events || []}
              // disabled={disable}
            >
              {apiKeyDetail?.webhooksDefined?.map((wh) => {
                return (
                  <option key={wh} value={wh}>
                    {I18n.t(`APIKeySetting.${wh}`)}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
          <div className="btnGroup">
            <Button
              className="btn-save view-button"
              onClick={handleCreateOrUpdateEndpoint}
              disabled={
                !endpointInfo?.url ||
                !endpointInfo?.signingSecret ||
                !(endpointInfo?.events?.length > 0) ||
                !hasPermissionAction
              }
            >
              {infoUpdating
                ? I18n.t('APIKeySetting.updateEndpoint')
                : I18n.t('APIKeySetting.addEndpoint')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const closeModal = () => {
    setWebhookList([]);
    props.handleClose();
  };

  return (
    <>
      <Modal
        show={true}
        backdrop={true}
        bsSize="large"
        dialogClassName="confirm-dialog"
        className="bookingPopup"
      >
        <Modal.Body>
          <div className="titlePopup apiKeysWH" style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between'}}>
            <h2>{apiKeyDetail?.appName}</h2>
            <Button
              type="button"
              className="btn-header text-add-header"
              // aria-label="Close"
              onClick={closeModal}
            >
              <CloseIcon color={'white'} />
            </Button>
          </div>
          <p className="whDescription">
            {I18n.t('APIKeySetting.maxEndpoints')}
          </p>
          <div className="btn_add">
            <Button
              className="btn-save"
              onClick={() => {
                setShowCreateWebhookModal(true);
              }}
              disabled={!hasPermissionAction || webhookList?.length >= 5}
            >
              {I18n.t('APIKeySetting.addEndpoint')}
            </Button>
          </div>
          <div className="gridViewTable" id="table_webhook">
            <StickyTable
              columns={getTableColumns()}
              bodyData={webhookList || []}
              noPagination={true}
              rowHeight={50}
              getTableHeight={getTableHeight}
              settings={props.auth?.selectedFleet}
            />
          </div>
        </Modal.Body>
      </Modal>
      {renderCreateOrUpdateWebhookModal()}
      {renderApiKeyInfo()}
      <Confirm
        confirm={confirm}
        className={'confirmInvoice'}
        handleConfirmButtonClick={handleConfirmButtonClick}
        handleConfirmCloseClick={handleConfirmCloseClick}
      />
    </>
  );
};

Webhook.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Webhook);
