import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router';
import _ from 'lodash';
import {
  OverlayTrigger,
  Tooltip,
  Dropdown,
  NavDropdown,
} from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { useRef } from 'react';
import styled from 'styled-components';
const WrapperDropdown = styled(NavDropdown)`
  .dropdown-menu {
    border-radius: 0;
    background-color:  $menu-background;
    margin-left: -1px;
    padding: 0px;
    top: 110px !imporatant;
    left: 65px !imporatant;
    position: fixed !important;
    z-index: 2000;
    inset: inherit !important;
    transform: translate(65px, -45px) !important;
    ${props => props.menuStyle}
  }
`

export default function NewBookingMenu({
  menu,
  addNewClick,
  bookingFormShow,
  intercityBookingShow,
  deliveryBookingShow,
  cityBookingShow,
  shuttleBookingShow,
  collapsed,
  isShowSubmenu,
  collapedClickHandle,
  className,
  auth,
  menuHandle,
  isShuttleNewBooking
}) {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [menuStyle, setMenuStyle] = useState({
    top: '110px',
    left: '65px'
  });
  const menuRef = useRef(null);

  useEffect(() => {
    if (menuRef && menuRef.current) {
      const client = menuRef.current.getBoundingClientRect();
      setMenuStyle({
        top: client.top,
        left: client.width
      })
    }
  }, [collapsed])

  useEffect(() => {
    if (showSubMenu) {
      setShowSubMenu(false)
    }
  }, [menuHandle.key])

  const handleToggle = (isOpen, event) => {
    if (collapsed && isShowSubmenu) {
      collapedClickHandle();
      setShowSubMenu(true)
    } else {
      setShowSubMenu(isOpen);
    }
    if (menuRef && menuRef.current) {
      const client = menuRef.current.getBoundingClientRect();
      setMenuStyle({
        top: client.top,
        left: client.width
      })
    }
  };

  const renderMenuText = () => {
    return collapsed ? (
      <OverlayTrigger
        placement="right"
        delayShow={300}
        overlay={
          <Tooltip id="tooltip">
            <strong>
              <Translate value={menu.text} />
            </strong>
          </Tooltip>
        }
      >
        <Link className="menu-addnew">
          <div
            className={'menu-icon ' + className}
            src={bookingFormShow ? menu.icon_active : menu.icon}
          />
          <div className={'menu-text'}>
            <Translate value={'Sidebar.New_booking'} />
          </div>
        </Link>
      </OverlayTrigger>
    ) : (
      <Link className="menu-addnew">
        <div
          className={'menu-icon ' + className}
          src={bookingFormShow ? menu.icon_active : menu.icon}
        />
        <div className={'menu-text'}>
          <Translate value={'Sidebar.New_booking'} />
        </div>
      </Link>
    );
  };

  const isShowIntercity = useMemo(() => {
    return _.get(auth, 'selectedFleet.intercity.enable') && auth.user.userType !== 'CorporateAdmin' && auth.user.userType !== "CorporateUser"
  }, []);

  const isEnableTransport = _.get(auth, 'selectedFleet.transport.enable', false)

  const isShowShuttle = useMemo(() => {
    return _.get(auth, 'selectedFleet.shuttle.enable') && auth.user.userType !== 'CorporateAdmin' && auth.user.userType !== "CorporateUser"
  }, []);

  const isShowDelivery = useMemo(() => {
    return _.get(auth, 'selectedFleet.delivery.enable') && (auth.user.userType !== 'CorporateAdmin' && auth.user.userType !== "CorporateUser") ||
        _.get(auth, 'selectedFleet.delivery.corporate') && (auth.user.userType == 'CorporateAdmin' || auth.user.userType == "CorporateUser") 
        && auth.user.enableParcelDelivery
  }, []);

  // const isAirlineBusiness = useMemo(() => {
  //   return _.get(auth, 'selectedFleet.airlineBusiness.enable')
  // }, []);

  // if (isAirlineBusiness) {
  //   return (
  //     <li
  //       key={1}
  //       className={bookingFormShow ? 'menu-item active' : 'menu-item'}
  //       onClick={() => addNewClick()}
  //       ref={menuRef}
  //     >
  //       {renderMenuText()}
  //     </li>
  //   )
  // }
  return (
    <li
      key={1}
      className={bookingFormShow ? 'menu-item active' : 'menu-item'}
      onClick={() => isShowIntercity || isShowShuttle || isShowDelivery ? null : addNewClick()}
      ref={menuRef}
    >
      {isShowIntercity || isShowShuttle || isShowDelivery ? (
        collapsed ? (
          <WrapperDropdown
            title={renderMenuText()}
            noCaret
            id="dropdown-no-caret"
            className="dropdownMenuNewbooking"
            onToggle={handleToggle}
            open={showSubMenu}
            menuStyle={menuStyle}
          >
            {
              isEnableTransport &&
              <Dropdown.Item
                className="dropdownMenuNewbooking__subMenu"
                eventKey="city"
                onSelect={addNewClick}
              >
                <Translate value="Sidebar.Chauffeur" />
              </Dropdown.Item>
            }
            {isShowIntercity &&
              <Dropdown.Item
                className="dropdownMenuNewbooking__subMenu"
                eventKey="intercity"
                onSelect={addNewClick}
              >
                <Translate value="Sidebar.Intercity" />
              </Dropdown.Item>
            }
            {isShowShuttle &&
              <Dropdown.Item
                className="dropdownMenuNewbooking__subMenu"
                eventKey="shuttle"
                onSelect={addNewClick}
              >
                <Translate value="Sidebar.Shuttle" />
              </Dropdown.Item>
            }
            {isShowDelivery &&
              <Dropdown.Item
                className="dropdownMenuNewbooking__subMenu"
                eventKey="delivery"
                onSelect={addNewClick}
              >
                <Translate value="Sidebar.Delivery" />
              </Dropdown.Item>
            }
          </WrapperDropdown>
        ) : (
          <Link
            className="menu-addnew"
            onClick={() => handleToggle(!showSubMenu)}
          >
            <div
              className={'menu-icon ' + className}
              src={bookingFormShow ? menu.icon_active : menu.icon}
            />
            <div className={'menu-text'}>
              <Translate value={'Sidebar.New_booking'} />
            </div>
          </Link>
        )
      ) : (
        renderMenuText()
      )}
      {showSubMenu && !collapsed ? (
        <div className="menu-addnew__sub-menu">
          {
            isEnableTransport &&
            <Dropdown.Item
              className="newbookingSubMenu__item"
              eventKey="city"
              onSelect={addNewClick}
              active={cityBookingShow && !isShuttleNewBooking}
            >
              <Translate value="Sidebar.Chauffeur" />
            </Dropdown.Item>
          }
          {isShowIntercity &&
            <Dropdown.Item
              className="newbookingSubMenu__item"
              eventKey="intercity"
              onSelect={addNewClick}
              active={intercityBookingShow}
            >
              <Translate value="Sidebar.Intercity" />
            </Dropdown.Item>
          }
          {isShowShuttle &&
            <Dropdown.Item
              className="newbookingSubMenu__item"
              eventKey="shuttle"
              onSelect={addNewClick}
              active={shuttleBookingShow || (cityBookingShow && isShuttleNewBooking)}
            >
              <Translate value="Sidebar.Shuttle" />
            </Dropdown.Item>
          }
          {isShowDelivery &&
            <Dropdown.Item
              className="newbookingSubMenu__item"
              eventKey="delivery"
              onSelect={addNewClick}
              active={deliveryBookingShow}
            >
              <Translate value="Sidebar.Delivery" />
            </Dropdown.Item>
          }
        </div>
      ) : null}
    </li>
  );
}
