import React, { PureComponent } from 'react';
import { I18n } from "react-redux-i18n";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { parserIntNumberInString } from '../../../utils/commonFunctions';

export default class LocationColumn extends PureComponent {

  getTotalStops = () => {
    const { booking } = this.props
    if(booking?.deliveryInfo?.merchants?.length > 1) {
      return booking?.deliveryInfo?.merchants?.length - 1
    }
    const stopTransport = [
      ...(booking?.puPoints || []),
      ...(booking?.doPoints || [])
    ]
    return stopTransport?.length >= 3 ? stopTransport?.length - 1 : 0
  }
  
  render() {
    const { booking, booking: { request } = {}, lineShort, isMobile } = this.props,
      estimate = booking?.request?.estimate || {}
    if(isMobile) {
      return (
        <div className='location-info'>
          <div>
            <p className='title-mb'>
              {
                `
                  ${I18n.t('newbooking.Pick_up')}
                  ${estimate.distance ? ` - ${estimate.distance}` : ''}
                  ${estimate.time ? ` - ${parserIntNumberInString(estimate.time)}` : ''}
                  ${this.getTotalStops() > 0 ? ` - ${this.getTotalStops()} stops` : ''}
                `
              }
            </p>
            <p className='adr'>{booking?.request?.pickup?.address}</p>
          </div>
          {
            booking?.request?.destination?.address && (
              <div>
                <p className='title-mb'>
                  Drop-off
                </p>
                <p className='adr'>{booking?.request?.destination?.address}</p>
              </div>
            )
          }
        </div>
      )
    }
    return (
      <div>
        <OverlayTrigger
          placement="top"
          delayShow={300}
          overlay={
            <Tooltip id="tooltip">
              <p>{booking.request?.pickup?.address}</p>
              {lineShort && <p>{booking.request?.destination?.address}</p>}
            </Tooltip>
          }
        >
          <p className={"text-ellipsis cursor-pointer address"}>
            {booking.request?.pickup?.address}
          </p>
        </OverlayTrigger>
        {
          !lineShort 
          && booking.request?.destination?.address?.length > 0
          && (booking.request.extraDestination || []).map(ed => (
            <OverlayTrigger
              placement="top"
              delayShow={300}
              overlay={
                <Tooltip id="tooltip">
                  <p>{ed.address}</p>
                </Tooltip>
              }
            >
              <p
                key={booking._id + ed.bookId}
                className="text-ellipsis cursor-pointer address"
              >
                {ed.address}
              </p>
            </OverlayTrigger>
          ))
        }
        {
          !lineShort &&
          booking.deliveryInfo && booking.deliveryInfo.merchants
          && (booking.deliveryInfo.merchants || []).map(ed => (
            typeof ed.address !== 'undefined' && ed.address.address !== booking.request.pickup.address ?
              <OverlayTrigger
                placement="top"
                delayShow={300}
                overlay={
                  <Tooltip id="tooltip">
                    <p>{ed.address.address}</p>
                  </Tooltip>
                }
              >
                <p
                  key={booking._id + ed.order}
                  className="text-ellipsis cursor-pointer address"
                >
                  {ed.address.address}
                </p>
              </OverlayTrigger>
              : ""
          ))
        }
        {
          !lineShort &&
          <OverlayTrigger
            placement="top"
            delayShow={300}
            overlay={
              <Tooltip id="tooltip">
                <p>{booking.request?.destination?.address}</p>
              </Tooltip>
            }
          >
            <p className={"text-ellipsis cursor-pointer address"}>
              {booking.request?.destination?.address}
            </p>
          </OverlayTrigger>
        }
      </div>
    );
  }
}
