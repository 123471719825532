import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import CurrencyFormatter from 'currency-formatter';
import { Translate} from 'react-redux-i18n';

import moment from 'moment';
import { CCLiteCommonFunc } from '../../utils/commonFunctions';
import FareDeeplink from '../../components/bookingDetail/bookComponent/FareDeeplink';

class TripEstimate extends PureComponent {
  state = {};

  render() {
    const { bookInfo, distanceDuration } = this.props;
    const estimate = _.get(bookInfo, 'request.estimate', {})
    return (
      <div className="trip-estimate-container-delivery">
        <div className="estimate-header">
          <div className="estimate-title">
            <Translate value="newbooking.TRIP_ESTIMATE" />
            <FareDeeplink 
              fareInfo={estimate?.fare} 
              puPoints={[]}
              doPoints={[]}
            />
          </div>
        </div>

        <div className="estimateInfo">
          <div className="item">
            <Translate value="newbooking.Distance" />
            <span>{distanceDuration.distance && distanceDuration.distance.text}</span>
          </div>
          <div className="item">
            <Translate value="newbooking.Duration" />
            <span>{distanceDuration.duration && distanceDuration.duration.text}</span>
          </div>
          <div className="item">
            <Translate value="newbooking.Fare" />
            <span>
              {CurrencyFormatter.format(estimate.fare.etaFare, {
                code: bookInfo.currencyISO,
              })}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

TripEstimate.defaultProps = {
  distanceDuration: {}
}

function mapStateToProps(state) {
  return {
    bookInfo: state.bookingDetail.data
  };
}

export default connect(mapStateToProps)(TripEstimate);
