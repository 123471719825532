import React, { Component } from 'react';
import _ from 'lodash';
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Form,
    InputGroup,
    Image
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';

import destinationIcon from '../../../../assets/images/icons/destination2.svg';
import pickupIcon from '../../../../assets/images/icons/pickup2.svg';

import CcCheckbox from '../../../ccCheckbox/CcCheckbox';
import { Validator, ValidCase } from '../../../validator';


const PaymentMethodOption = ({ method, bookData }) => {
    if (
        method.value === bookData.request.paymentType
        && (![9].includes(method.value) || ([9].includes(method.value) && bookData.status === 'droppedOff'))
        && ![14].includes(method.value)
        && method.value !== 1
    ) {
        return (
            <option key={method.value} value={method.value}>
                {I18n.t(`General.patmentMethod_${method.value}`)}
            </option>
        );
    }
    return null;
};

class CompleteWithPaymentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentFocus: '',
            showExtraServices: false
        };
    }

    parseInputValue = (inputName, value) => {
        const { currentFocus } = this.state;
        const { data } = this.props;
        if (value) {
            value = value.toString();
            if (inputName == currentFocus) return value.replace(/[^.0-9]+/, '') || 0;
            return currencyFormatter.format(value, {
                code: data.currencyISO,
                format: '%v'
            });
        }
        if (currentFocus == inputName) {
            return '';
        }

        return currencyFormatter.format(0, {
            code: data.currencyISO,
            format: '%v'
        });
    };


    getTextSubmitButton = paymentComplete => (paymentComplete.isPending
        ? I18n.t('bookingdetail.Complete_booking')
        : I18n.t('bookingdetail.Pay_here'));

    render() {
        const {
            showConfirmComplete,
            completeWithPaymentCloseButtonClick,
            completeWithPaymentButtonClick,
            data,
            paymentMethod,
            paymentStep,
            selectedFleet,
            locationPickUp = {},
            extraDestination,
            locationDestination = {},
            fareSettings = {},
            paymentComplete,
            handleRushHourCheckboxChange,
            handleCompletePaymentTypeChange,
            handleCompletePaymentIsPendingChange,
            isShowmDispatcherCard,
            completeWithPaymentOkButtonClick,
            disableCompletePaymentNextClick
        } = this.props;

        const estimateFare = data.pricingType == 1
            ? data.request.estimate.fare.estimateFareBuy
            : data.request.estimate.fare || {};
        return (
            <Modal
                show={showConfirmComplete}
                backdrop="static"
                dialogClassName="confirm-dialog"
                onHide={completeWithPaymentCloseButtonClick}
                className="complete-with-payment-modal"
            >
                <Form onSubmit={completeWithPaymentButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Translate value="bookingdetail.Booking_detail" className="white-text" />
                            <span className="white-text">:</span>
                            {' '}
                            #
                            {this.props.data.bookId}
                            <span className="white-text"> -</span>
                            {' '}
                            <Translate
                                className="white-text text-tranform-none"
                                value={`statusDisplay.${this.props.data.status}`}
                            />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="newbooking-form complete-with-payment md-payment">
                        <div className={paymentStep == 0 ? 'fill payment-dialog step0' : 'fill payment-dialog'}>
                            <FormGroup>
                                <Form.Label>
                                    <Translate value="bookingdetail.Pick_up" />
                                </Form.Label>
                                <FormControl
                                    disabled
                                    type="text"
                                    className="form-custom"
                                    value={locationPickUp && locationPickUp.address}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Form.Label>
                                    <Translate value="bookingdetail.Destination" />
                                </Form.Label>
                                <FormControl
                                    disabled
                                    type="text"
                                    className="form-custom"
                                    value={locationDestination && locationDestination.address}
                                />
                            </FormGroup>

                            {extraDestination && extraDestination.address &&
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="newbooking.Extra_Destination" />
                                    </Form.Label>
                                    <FormControl
                                        disabled
                                        type="text"
                                        className="form-custom"
                                        value={extraDestination.address}
                                    />
                                </FormGroup>
                            }

                            {this.props.renderBasicFareBasedMode(true)}

                            {fareSettings.fare && fareSettings.fare.rushHourActive && estimateFare.rushHourFee > 0 ?
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="bookingdetail.Rush_hour" />
                                    </Form.Label>
                                    <CcCheckbox
                                        checked={paymentComplete.rushHourChecked}
                                        text={estimateFare.rushHourFee ? currencyFormatter.format(estimateFare.rushHourFee, { code: data.currencyISO }) : 0}
                                        onChange={handleRushHourCheckboxChange}
                                        disabled={true}
                                    />
                                </FormGroup>
                                : null}

                            {selectedFleet.fleetFareId &&
                                selectedFleet.fleetFareId.additionalServicesActive &&
                                estimateFare.serviceFee > 0 &&
                                !data.isNewSettingAdditionFee ? (
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="bookingdetail.Addi_service" />
                                    </Form.Label>
                                    <FormGroup className="additional-service-form qup-input-group">
                                        <InputGroup className="single-addon-left">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                {data.currencySymbol}
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                readOnly
                                                value={estimateFare.serviceFee}
                                                className="form-custom additional-service-input"
                                                disabled={true}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </FormGroup>
                            ) : null}

                            {fareSettings.fare &&
                                fareSettings.fare.otherFeeActive &&
                                estimateFare.otherFees > 0 ? (
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="bookingdetail.Other_fees" />
                                    </Form.Label>
                                    <FormGroup className="qup-input-group">
                                        <InputGroup className="single-addon-left">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                {data.currencySymbol}
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                type="text"
                                                className="form-custom"
                                                value={estimateFare.otherFees}
                                                disabled={true}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </FormGroup>
                            ) : null}

                            {paymentComplete.addOnPrice && paymentComplete.addOnPrice !== 0 ?
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="bookingdetail.addOnPrice" />
                                    </Form.Label>
                                    <FormGroup className="qup-input-group">
                                        <span className="amount">
                                            {currencyFormatter.format(paymentComplete.addOnPrice, {
                                                code: data.currencyISO
                                            })}
                                        </span>
                                    </FormGroup>
                                </FormGroup>
                                : null}

                            <FormGroup>
                                <Form.Label>
                                    <Translate value="bookingdetail.Subtotal" />
                                    {'\u00A0'}
                                </Form.Label>
                                <span className="amount">
                                    {currencyFormatter.format(estimateFare.subTotal, {
                                        code: data.currencyISO
                                    })}
                                </span>
                            </FormGroup>

                        </div>

                        <div className={paymentStep == 0 ? 'fill payment-dialog' : 'fill payment-dialog step1'} >
                            <FormGroup className="location-info">
                                <Image src={pickupIcon} />
                                <span>{locationPickUp && locationPickUp.address}</span>
                            </FormGroup>
                            <FormGroup className="location-info">
                                <Image src={destinationIcon} />
                                <span>
                                    {locationDestination
                                        ? locationDestination.address
                                        : ''}
                                </span>
                            </FormGroup>
                            {locationDestination && extraDestination &&
                                <FormGroup className="location-info">
                                    <Image src={destinationIcon} />
                                    <span>
                                        {extraDestination
                                            ? extraDestination.address
                                            : ''}
                                    </span>
                                </FormGroup>
                            }
                            <FormGroup>
                                <Form.Label style={{ position: 'relative' }}>
                                    <Translate value="bookingdetail.Subtotal" />
                                    {'\u00A0'}
                                    {/* {paymentComplete.isMinFare && (
                                        <Translate value="bookingdetail.Minimum" className="minimum" />
                                    )} */}
                                </Form.Label>
                                <span className="amount">
                                    {currencyFormatter.format(estimateFare.subTotal, {
                                        code: data.currencyISO
                                    })}
                                </span>
                            </FormGroup>
                            {fareSettings
                                && ((data.pricingType == 1
                                    && fareSettings.airport
                                    && (fareSettings.airport.fromAirportActive
                                        || fareSettings.airport.toAirportActive))
                                    || (data.pricingType == 0
                                        && fareSettings.fare
                                        && (fareSettings.fare.airport.fromAirportActive
                                            || fareSettings.fare.airport.toAirportActive))) &&
                                estimateFare.airportFee > 0 ? (
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="bookingdetail.Airport_Fee" />
                                    </Form.Label>
                                    <span className="amount">
                                        {currencyFormatter.format(estimateFare ? estimateFare.airportFee : 0, {
                                            code: data.currencyISO
                                        })}
                                    </span>
                                </FormGroup>
                            ) : null}
                            {data.request.type == 1 &&
                                fareSettings.fleet &&
                                fareSettings.fleet.additionalService.fromAirport.meetDriver &&
                                estimateFare.meetDriverFee > 0
                                ? (
                                    <FormGroup>
                                        <Form.Label>
                                            <Translate value="bookingdetail.Meet_driver" />
                                        </Form.Label>
                                        <span className="amount">
                                            {currencyFormatter.format(estimateFare ? estimateFare.meetDriverFee : 0, {
                                                code: data.currencyISO
                                            })}
                                        </span>
                                    </FormGroup>
                                ) : null}

                            {fareSettings.fare &&
                                fareSettings.fare.tollFeeActive &&
                                estimateFare.tollFee > 0 ? (
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="bookingdetail.Toll_fees" />
                                    </Form.Label>
                                    <FormGroup className="qup-input-group">
                                        <InputGroup className="single-addon-left">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                {data.currencySymbol}
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                type="text"
                                                className="form-custom"
                                                disabled={true}
                                                value={estimateFare.tollFee}
                                            />
                                        </InputGroup>
                                    </FormGroup>

                                </FormGroup>
                            ) : null}

                            {fareSettings.fare &&
                                fareSettings.fare.parkingFeeActive &&
                                estimateFare.parkingFee > 0 ? (
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="generalSetting.parkingFee" />
                                    </Form.Label>
                                    <FormGroup className="qup-input-group">
                                        <InputGroup className="single-addon-left">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                {data.currencySymbol}
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                type="text"
                                                className="form-custom"
                                                disabled={true}
                                                value={estimateFare.parkingFee}
                                            />
                                        </InputGroup>
                                    </FormGroup>

                                </FormGroup>
                            ) : null}

                            {fareSettings.fare &&
                                fareSettings.fare.gasFeeActive &&
                                estimateFare.gasFee > 0 ? (
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="generalSetting.gasFee" />
                                    </Form.Label>
                                    <FormGroup className="qup-input-group">
                                        <InputGroup className="single-addon-left">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                {data.currencySymbol}
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                type="text"
                                                className="form-custom"
                                                disabled={true}
                                                value={estimateFare.gasFee}
                                            />
                                        </InputGroup>
                                    </FormGroup>

                                </FormGroup>
                            ) : null}

                            {selectedFleet.fleetFareId &&
                                data.isNewSettingAdditionFee &&
                                estimateFare.serviceFee > 0 ? (
                                <FormGroup>
                                    <Form.Label>
                                        Fleet Services
                                    </Form.Label>
                                    <FormGroup className="additional-service-form qup-input-group">
                                        <InputGroup className="single-addon-left">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                {data.currencySymbol}
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                readOnly
                                                value={estimateFare.serviceFee}
                                                className="form-custom additional-service-input"
                                                disabled={true}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </FormGroup>
                            ) : null}

                            {selectedFleet.techFeeActive ? (
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="bookingdetail.Tech_fees" />
                                    </Form.Label>
                                    <span className="amount">
                                        {currencyFormatter.format(estimateFare ? estimateFare.techFee : 0, {
                                            code: data.currencyISO
                                        })}
                                    </span>
                                </FormGroup>
                            ) : null}

                            {estimateFare.bookingFee > 0 ? (
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="bookingdetail.Booking_fees" />
                                    </Form.Label>
                                    <span className="amount">
                                        {currencyFormatter.format(estimateFare.bookingFee, {
                                            code: data.currencyISO
                                        })}
                                    </span>
                                </FormGroup>
                            ) : null}

                            {fareSettings.fare && fareSettings.fare.taxActive ? (
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="bookingdetail.Tax" />
                                    </Form.Label>
                                    <FormGroup className="qup-input-group">
                                        <InputGroup className="single-addon-left">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                {data.currencySymbol}
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                type="text"
                                                min={0}
                                                className="form-custom"
                                                value={this.parseInputValue('tax', estimateFare.tax)}
                                                disabled={true}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </FormGroup>
                            ) : null}
                            {paymentComplete.creditTransactionFee >= 0 ? (
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="bookingdetail.creditTransactionFee" />
                                    </Form.Label>
                                    <FormGroup className="qup-input-group">
                                        <InputGroup className="single-addon-left">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                {data.currencySymbol}
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                type="text"
                                                min={0}
                                                className="form-custom"
                                                value={this.parseInputValue('transaction', paymentComplete.creditTransactionFee)}
                                                disabled={true}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </FormGroup>
                            ) : null}
                            {selectedFleet.fleetFareId && selectedFleet.fleetFareId.tipActive ? (
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="bookingdetail.Tip" />
                                    </Form.Label>
                                    <FormGroup className="qup-input-group">
                                        <InputGroup className="single-addon-left">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                {data.currencySymbol}
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                type="text"
                                                className="form-custom"
                                                min={0}
                                                value={this.parseInputValue('tax', estimateFare.tip)}
                                                disabled={true}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </FormGroup>
                            ) : null}

                            <FormGroup>
                                <Form.Label>
                                    <Translate value="bookingdetail.Promo" />
                                </Form.Label>
                                <span className="amount">
                                    {currencyFormatter.format(estimateFare.promoAmount, {
                                        code: data.currencyISO
                                    })}
                                </span>
                            </FormGroup>

                            <FormGroup>
                                <Form.Label>
                                    <Translate value="bookingdetail.Total" />
                                </Form.Label>
                                <span className="amount total">
                                    {currencyFormatter.format(estimateFare.etaFare, {
                                        code: data.currencyISO
                                    })}
                                </span>
                            </FormGroup>

                            <FormGroup>
                                <Form.Label>
                                    <Translate value="bookingdetail.Payment_Type" />
                                </Form.Label>
                                <FormControl
                                    as="select"
                                    type="text"
                                    className="form-custom"
                                    value={paymentComplete.paymentType}
                                    onChange={handleCompletePaymentTypeChange}
                                >
                                    {data.pricingType == 1 ? (
                                        <option value={2}>{I18n.t('bookingdetail.Personal_Card')}</option>
                                    ) : !data.travelerType ? (
                                        [
                                            <option key={1} value={1}>
                                                {I18n.t('General.patmentMethod_1')}
                                            </option>
                                        ].concat(
                                            paymentMethod.individualTypes.map(pm => <PaymentMethodOption method={pm} bookData={data} />)
                                        )
                                    ) : (
                                        [
                                            <option key={1} value={1}>
                                                {I18n.t('General.patmentMethod_1')}
                                            </option>
                                        ].concat(
                                            paymentMethod.corporateTypes.map(pm => <PaymentMethodOption method={pm} bookData={data} />)
                                        )
                                    )}
                                    {isShowmDispatcherCard
                                        && paymentMethod.type3rd.map(pm => {
                                            if (pm.value == 1 || pm.value == data.request.paymentType) {
                                                return (
                                                    <option key={pm.value} value={pm.value}>
                                                        {I18n.t(`General.patmentMethod_${pm.value}`)}
                                                    </option>
                                                );
                                            }
                                        })}
                                </FormControl>
                                <Validator className="red">
                                    <ValidCase
                                        valid={paymentStep == 0 || paymentComplete.paymentType != 8}
                                        message={I18n.t('bookingdetail.QR_Code_method_not_allow_error_message')}
                                    />
                                </Validator>
                            </FormGroup>

                            <FormGroup>
                                <Form.Label>
                                    <Translate value="bookingdetail.pending_payment" />
                                </Form.Label>
                                <CcCheckbox
                                    checked={paymentComplete.isPending}
                                    onChange={handleCompletePaymentIsPendingChange}
                                    className="paymentModal__pendingPayment"
                                />
                            </FormGroup>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="pt0">
                        <Button className={paymentStep == 0 ? 'btn-cancel' : 'btn-reset'} type="submit">
                            {paymentStep == 0 ? I18n.t('bookingdetail.Cancel') : I18n.t('bookingdetail.Back')}
                        </Button>
                        <Button
                            className="btn-save"
                            disabled={
                                disableCompletePaymentNextClick
                                || (
                                    !locationDestination
                                    || locationDestination.lat == 0
                                )
                                || (extraDestination !== undefined
                                    && (
                                        !extraDestination
                                        || !extraDestination.address
                                        || !extraDestination.address.length
                                        || !extraDestination.lat
                                        || !extraDestination.lat === 0)
                                )
                                || (paymentStep != 0 && paymentComplete.paymentType == 8)
                            }
                            onClick={completeWithPaymentOkButtonClick}
                        >
                            {paymentStep == 0
                                ? I18n.t('bookingdetail.Next')
                                : this.getTextSubmitButton(paymentComplete)}
                        </Button>
                    </Modal.Footer>
                </Form>


            </Modal>
        )
    }
}

export default CompleteWithPaymentModal