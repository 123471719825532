import copy from 'copy-to-clipboard';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import CCDropDown from '../../components/dropdown/ccDropDown';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import {
  FormGroup,
  FormControl,
  Button,
  ButtonToolbar,
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import DateTime from '../../components/dateTime/DateTime';
import { get, forEach, cloneDeep, find } from 'lodash';
import ReactDOM from 'react-dom';

import * as mDispatcherActions from '../../actions/mDispatcherActions';
import * as settingActions from '../../actions/settingActions';
import * as loadingBarActions from '../../actions/loadingBarActions';
import { CCLiteCommonFunc, trimPhoneNumber } from '../../utils/commonFunctions';
import './invoice.scss';
import * as creditCardActions from '../../actions/creditCardActions';
import Confirm from '../../components/confirm/Confirm';

import StickyTable from '../../components/table/stickyTable/StickyTable';
import TableActions from '../../components/table/tableAction/TableActions';
import { PartnerColumns } from './tableColumns';
import QuestionCircleTooltip from '../../components/questionCircleTooltip/QuestionCircleTooltip';
import currencyFormatter from 'currency-formatter';
import DotsHorizontalIcon from '@icons/material/DotsHorizontalIcon';
import DateRange from '../../components/DateRange';
import CreateInvoiceForm from './CreateInvoiceForm';
import EmailPopup from './EmailPopup';
import { BsSearch } from 'react-icons/bs';

const numItemsPerPage = 20;
const SHOW_STATUS = [
  { value: -1, title: 'invoice.All' },
  { value: 0, title: 'invoice.pending' },
  { value: 1, title: 'invoice.pastDue' },
  { value: 2, title: 'invoice.paid' },
];
const ACTIONS_TYPE = {
  sendInvoice: 'sendInvoice',
  copyInvoice: 'copyInvoice',
  viewInvoice: 'viewInvoice',
  downloadPDF: 'downloadPDF',
  paymentLink: 'paymentLink',
  editInvoice: 'editInvoice',
  deleteInvoice: 'deleteInvoice',
};
class Invoice extends Component {
  constructor(props) {
    super();
    this.state = {
      dialogData: {
        commissionValue: 0,
      },
      valid: {},
      isSubmitted: false,
      confirmDialog: false,
      confirm: null,
      showStatus: -1,
      tableHeight: 500,
      rowHeight: 50,
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
        active: null,
        name: I18n.t('invoice.mDispatchers'),
      },
      footerLoading: true,

      currencies: props.auth?.selectedFleet?.currencies[0],
      selectedButton: 'thisMonth',
      invoiceDetail: {},
      hasPermissionAction: false,
      actionItems: [],
    };
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
    this.handleConfirmCloseClick = this.handleConfirmCloseClick.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
  }

  componentDidMount() {
    let actionItems = [
      {
        eventKey: ACTIONS_TYPE.sendInvoice,
        label: `invoice.${ACTIONS_TYPE.sendInvoice}`,
        hideWhenPaided: true,
      },
      {
        eventKey: ACTIONS_TYPE.copyInvoice,
        label: `invoice.${ACTIONS_TYPE.copyInvoice}`,
      },
      {
        eventKey: ACTIONS_TYPE.viewInvoice,
        label: `invoice.${ACTIONS_TYPE.viewInvoice}`,
      },
      {
        eventKey: ACTIONS_TYPE.downloadPDF,
        label: `invoice.${ACTIONS_TYPE.downloadPDF}`,
      },
      {
        eventKey: ACTIONS_TYPE.paymentLink,
        label: `invoice.${ACTIONS_TYPE.paymentLink}`,
        hideWhenPaided: true,
      },
      {
        eventKey: ACTIONS_TYPE.editInvoice,
        label: `invoice.${ACTIONS_TYPE.editInvoice}`,
        hideWhenPaided: true,
      },
      {
        eventKey: ACTIONS_TYPE.deleteInvoice,
        label: `invoice.${ACTIONS_TYPE.deleteInvoice}`,
        hideWhenPaided: true,
      },
    ];
    if(_.get(this.props.auth.selectedFleet, 'creditConfig.configGateway.gateway') !== 'Stripe') {
      actionItems = actionItems.filter(
        (item) =>
          ![
            ACTIONS_TYPE.paymentLink,
          ].includes(item.eventKey)
      );
    }
    if (!this.props.permissions?.actions) {
      actionItems = actionItems.filter(
        (item) =>
          ![
            ACTIONS_TYPE.sendInvoice,
            ACTIONS_TYPE.editInvoice,
            ACTIONS_TYPE.deleteInvoice,
          ].includes(item.eventKey)
      );
    }
    this.props.settingActions
      .getPWASetting({ fleetId: this.props.auth.selectedFleet.fleetId })
      .then((data) => {
        if (data.res && data.res.pwa && data.res.pwa && data.ok) {
          const { pwa } = data.res;
          let pwaLink = '';
          if (pwa.customDomain) {
            pwaLink = pwa.customUrl;
          } else {
            pwaLink = (pwa?.pwaLink?.split('?') || [])[0];
          }
          this.setState({ pwaLink });
        }
      });
    this.setState({
      hasPermissionAction: this.props.permissions?.actions || false,
      actionItems,
    });
    this.getListInvoices(0, null, true);
  }

  getListInvoices = (activePage, limit, countPartner) => {
    let param = {
      limit: limit
        ? limit
        : this.state.invoice
        ? this.state.invoice.limit
        : numItemsPerPage,
      page: _.isNumber(activePage)
        ? activePage
        : this.state.invoice
        ? this.state.invoice.page
        : 0,
      fleetId: this.props.auth.selectedFleet.fleetId,
      timezone: 'Asia/Singapore',
      fromDate: this.state.startDate
        ? moment(this.state.startDate).format('YYYY-MM-DD')
        : '',
      toDate: this.state.endDate
        ? moment(this.state.endDate).format('YYYY-MM-DD')
        : '',
    };
    if(this.state.showStatus !== -1) {
      param.status = this.state.showStatus
    }
    if (this.state.str) {
      param.search = this.state.str.trim();
      if (!activePage) {
        param.page = 0;
      }
    }
    if (this.state.sortName) {
      param.sortBy = this.state.sortName;
      param.sortOrder = this.state.sortType
    }
    this.setState({
      isLoading: true,
      footerLoading: countPartner,
    });
    this.props.settingActions.getListInvoice(param).then((data) => {
      if (data.ok) {
        let { page, total, limit } = data;
        if (!_.isUndefined(total)) {
          this.setState({
            invoice: {
              list: data.data,
              ...data,
            },
            footerData: {
              ...this.state.footerData,
              limit,
              page,
              total,
            },
            footerLoading: false,
            isLoading: false,
          });
        } else {
          this.setState(
            {
              invoice: data,
              footerData: { ...this.state.footerData, limit, page },
              isLoading: false,
            },
            () => {
              if (countPartner) {
                // this.loadTotalMDispatcher(param);
              }
            }
          );
        }
      }
    });
  };

  deleteInvoice = (id) => {
    const { settingActions } = this.props;
    return settingActions.deleteInvoice({ invoiceId: id }).then((result) => {
      if (result?.status) {
        this.getListInvoices();
        this.setState({
          showCreateIVModal: false,
        });
        return true;
      } else {
        if (result.message) {
          this.context.notification('error', result.message);
        } else {
          this.context.notification(
            'error',
            I18n.t('invoice.Load_mDispatcher_fail')
          );
        }
      }
    });
  };

  handleMenuClick = (key, id, obj) => {
    switch (key) {
      case ACTIONS_TYPE.editInvoice: {
        const { settingActions } = this.props;
        settingActions.detailInvoice(id).then((result) => {
          if (result?.data?.invoiceId) {
            this.setState({
              invoiceDetail: result?.data,
              showCreateIVModal: true,
            });
          } else {
            if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('invoice.Load_mDispatcher_fail')
              );
            }
          }
        });
        break;
      }

      case ACTIONS_TYPE.deleteInvoice: {
        this.setState({
          confirm: {
            id: ACTIONS_TYPE.deleteInvoice,
            title: I18n.t('invoice.Delete'),
            body: I18n.t('invoice.Delete_content'),
            buttonTitle: 'Delete',
            closeButtonText: 'Cancel',
            invoiceId: id,
          },
        });
        break;
      }

      case ACTIONS_TYPE.viewInvoice:
        {
          const { settingActions } = this.props;
          settingActions.detailInvoice(id).then((result) => {
            if (result?.data?.invoiceId) {
              this.setState({
                invoiceDetail: result?.data,
                showCreateIVModal: true,
              });
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('invoice.Load_mDispatcher_fail')
                );
              }
            }
          });
          settingActions.getPDFInvoice(id).then((result) => {
            if (result?.data?.invoiceId) {
              this.setState({
                invoiceDetail: result?.data,
                showCreateIVModal: true,
              });
            } else {
            }
          });
        }
        break;
      case ACTIONS_TYPE.downloadPDF: {
        if (obj.pdfFile) {
          window.open(obj.pdfFile, 'DOWNLOAD');
        } else {
          this.props.settingActions.getPDFInvoice(id).then((result) => {
            if (result?.data) {
              window.open(result?.data, 'DOWNLOAD');
            }
          });
        }
        
        break;
      }
      case ACTIONS_TYPE.sendInvoice: {
        this.setState({ 
          showEmailInvoiceModal: true, 
          idSendingEmail: id,
          billingEmailSelecting: obj.billingEmail
        })
        break;
      }
      case ACTIONS_TYPE.paymentLink: {
        this.openPaymentLink(obj)
        break;
      }
      case ACTIONS_TYPE.copyInvoice: {
        this.copyInvoice(obj)
        break;
      }
      default:
        break;
    }
  };

  sendEmailInvoice = (emailInfo) => {
    this.props.settingActions.sendMailInvoice({
      id: this.state.idSendingEmail,
      to: emailInfo?.to?.map(obj => obj.value) || [],
      cc: emailInfo?.cc?.map(obj => obj.value) || [],
      content: emailInfo.content
    })
    .then((result) => {
      if(result) {
        this.context.notification('success', 'This email has been send successfully!');
        this.setState({ showEmailInvoiceModal: false, idSendingEmail: ''})
      } else {
        this.context.notification('error', I18n.t(`General.ServerError`));
      }
    })
    .catch(() => {this.context.notification('error', I18n.t(`General.ServerError`));})
  }

  openPaymentLink = (obj) => {
    if (obj.invoiceToken) {
      window.open(`${this.state.pwaLink}/invoice?token=${obj.invoiceToken}&page=payment`, '_blank');
    }
  }

  copyInvoice = (obj) => {
    if (obj.invoiceToken) {
      copy(`${this.state.pwaLink}/invoice?token=${obj.invoiceToken}`)
      this.context.notification('success', 'Copied');
    }
  }

  handleConfirmButtonClick(id, confirm) {
    switch (id) {
      case ACTIONS_TYPE.deleteInvoice: {
        this.deleteInvoice(confirm.invoiceId);
        break;
      }
    }
    this.handleConfirmCloseClick();
  }

  handleConfirmCloseClick() {
    this.setState({ confirm: null });
  }

  handleSearchKeyPress = (e) => {
    if (this.state.isLoading) return;
    if (e.key === 'Enter') {
      this.getListInvoices(null, null, true);
    }
  };

  handlePaginationSelect = (page) => this.getListInvoices(parseInt(page));

  handleNumItemsPerPageChange = (limit) => this.getListInvoices(0, limit);

  handleSearchChange = (str) => this.setState({ str });

  handleSortInvoice = (sortName) => {
    if (this.state.isLoading) return;
    this.setState(
      {
        sortName: sortName,
        sortType: this.state.sortType == 1 ? -1 : 1,
      },
      () => {
        this.getListInvoices();
      }
    );
  };

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handleItemSelectedChange = (id, e) => {
    const { invoice } = this.state;
    let listItem = cloneDeep(invoice.list);
    if (id !== null) {
      let index = listItem.findIndex((obj) => id === obj._id);
      if (index >= 0) listItem[index].selected = e.target.checked;
    } else {
      let value = e.target.checked;
      listItem = listItem.map((obj) => {
        return {
          ...obj,
          selected: value,
        };
      });
    }
    this.setState({
      invoice: {
        ...invoice,
        list: listItem,
      },
    });
  };

  handleAddressChange = (result) => {
    let address = {};
    if (result.address && result.addressPointText) {
      address = {
        ...result.address,
        address: result.addressPointText,
      };
    }
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        addressDetail: {
          ...address,
        },
      },
    });
  };

  getTableColumns = () => {
    let tableColums = Object.assign([], PartnerColumns);
    forEach(tableColums, (col) => {
      const style = {
        color: 'white',
        display: 'inline-block',
        padding: '5px',
        'border-radius': '5px',
      };
      switch (col.key) {
        case 'status':
          col.customCell = (data) => {
            let status;
            switch (data.status) {
              case 0:
                status = (
                  <span
                    style={{
                      ...style,
                      'background-color': 'rgba(255, 255, 255, 0.12)',
                    }}
                  >
                    Pending
                  </span>
                );
                break;
              case 1:
                status = (
                  <span
                    style={{
                      ...style,
                      color: '#F97066',
                      'background-color': 'rgba(249, 112, 102, 0.12)',
                    }}
                    className="pastDue"
                  >
                    Past due
                  </span>
                );
                break;
              case 2:
                status = (
                  <span
                    style={{
                      ...style,
                      color: '#04BE76',
                      'background-color': 'rgba(4, 190, 118, 0.12)',
                    }}
                    className="paid"
                  >
                    Paid
                  </span>
                );
                break;
              default:
                break;
            }
            return status;
          };
          break;
        case 'actions':
          col.customCell = (obj, rowIndex) => {
            return (
              <TableActions
                rowIndex={rowIndex}
                rowData={obj}
                customeIcon={<DotsHorizontalIcon />}
                onSelect={(eventKey) =>
                  this.handleMenuClick(eventKey, obj.invoiceId, obj)
                }
                totalRow={
                  this.state.invoice ? this.state.invoice.list.length : 0
                }
                rowHeight={this.state.rowHeight}
                menuItems={
                  obj.status === 0 || obj.status === 1
                    ? this.state.actionItems?.filter(
                        (ob) => ob.eventKey !== ACTIONS_TYPE.viewInvoice
                      )
                    : this.state.actionItems?.filter((ob) => !ob.hideWhenPaided)
                }
                tableHeight={this.state.tableHeight}
              />
            );
          };
          break;
        default:
          break;
      }
    });
    return tableColums;
  };

  getTableHeight = () => {
    let parentContentVerticalPadding = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight + 10
        : 0,
      totolAmountHeight = this.totolAmount
        ? ReactDOM.findDOMNode(this.totolAmount).clientHeight + 20
        : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = parentContentVerticalPadding + toolbarheight;
    let tableHeight = parentHeight
      ? parentHeight - outerHeight - totolAmountHeight
      : 0;
    if (window.innerHeight < 850 && this.state.tableHeight < 500)
      tableHeight = 550;
    this.state.tableHeight = tableHeight
    return tableHeight;
  };

  closeQuestModal = () => {
    this.setState({
      openQuestQRModal: false,
      viewQuestQRId: '',
      viewQuestQRFullName: '',
    });
  };

  setIsCopied = (isCopied) => {
    this.setState({
      isCopied: isCopied,
    });
  };

  renderTotalAmount = () => {
    const {
      pendingAmount = 0,
      pastdueAmount = 0,
      paidAmount = 0,
    } = this.state.invoice || {};
    return (
      <div className="totolAmount" ref={(node) => (this.totolAmount = node)}>
        <p>
          <span>Pending</span>
          <span>
            {currencyFormatter.format(pendingAmount || 0, {
              code: this.state.currencies.iso,
            })}
          </span>
        </p>
        <p>
          <span>Past due</span>
          <span>
            {currencyFormatter.format(pastdueAmount, {
              code: this.state.currencies.iso,
            })}
          </span>
        </p>
        <p>
          <span>Paid</span>
          <span>
            {currencyFormatter.format(paidAmount || 0, {
              code: this.state.currencies.iso,
            })}
          </span>
        </p>
      </div>
    );
  };

  handleChangeDateRange = (params) => {
    let { disableDatetimeSelection } = this.state;
    // disableDatetimeSelection = params.selectedButton !== 'custom';
    if (params.selectedButton !== 'custom') {
      this.setState(
        {
          startDate: params.fromDate,
          endDate: params.toDate,
          selectedButton: params.selectedButton,
          disableDatetimeSelection,
        },
        () => this.getListInvoices()
      );
    } else {
      this.setState(
        {
          selectedButton: params.selectedButton,
          disableDatetimeSelection,
        },
        () => this.getListInvoices()
      );
    }
  };

  handleCustomDateSelect = () => {
    if (this.dateFromInput) {
      this.setState({ showDateFrom: true }, () => this.dateFromInput.focus());
    }
  };

  handleDateStartDateChange = (e) => {
    this.setState({ startDate: e._d, selectedButton: 'custom' }, this.getListInvoices);
  };

  handleDateEndDateChange = (e) => {
    this.setState({ endDate: e._d, selectedButton: 'custom' }, this.getListInvoices);
  };

  isFilteredDayValid(current) {
    return !current.isAfter(DateTime.moment());
  }

  handleShowHideCreateInvoce = (isShow, hasReset) => {
    if (!isShow) {
      this.setState({
        invoiceDetail: {},
      });
    }
    if (hasReset) this.getListInvoices();
    this.setState({ showCreateIVModal: isShow });
  };

  handleSelectShowStatus = (value) => {
    this.setState({ showStatus: value }, this.getListInvoices);
  };

  render() {
    const { dialogData, valid, mDispatcherTypeList, queuingAreaList, showEmailInvoiceModal } =
      this.state;
    const {
      permissions = null,
      auth: { user } = {},
      language: { locale: language },
    } = this.props || {};
    const { creditConfig } = this.props.auth.selectedFleet;

    const bodyData =
      this.state.invoice && this.state.invoice.list
        ? this.state.invoice.list
        : [];

    return (
      <div className="invoice content" id="invoiceComponent">
        <Confirm
          confirm={this.state.confirm}
          className={'confirmInvoice'}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
        {
        // Email Invoice
          showEmailInvoiceModal &&
          <EmailPopup 
            handleSaveConfirmed={this.sendEmailInvoice}
            billingEmail={this.state.billingEmailSelecting}
            closePopup={() => this.setState({ 
              showEmailInvoiceModal: false,
              idSendingEmail: '',
              billingEmailSelecting: ''
            })}
          />
        }
        {this.renderTotalAmount()}
        {this.state.showCreateIVModal && (
          <CreateInvoiceForm
            ACTION_ITEMS={this.state.actionItems}
            hasPermissionAction={this.state.hasPermissionAction}
            openPaymentLink={this.openPaymentLink}
            copyInvoice={this.copyInvoice}
            handleClose={(hasReset) =>
              this.handleShowHideCreateInvoce(false, hasReset)
            }
            language={language}
            ACTIONS_TYPE={ACTIONS_TYPE}
            bodyData={bodyData}
            invoiceDetail={this.state.invoiceDetail}
            deleteInvoice={this.deleteInvoice}
            resetInvoiceList={this.getListInvoices}
          />
        )}
        <ButtonToolbar
          className="text-center header-button-group"
          ref={(node) => (this.toobarContainer = node)}
        >
          <div className="group-left btn-group">
            <DateRange
              selectedButton={this.state.selectedButton}
              setDateRange={this.handleChangeDateRange}
              customRangeSelectCallback={this.handleCustomDateSelect}
            />
            <FormGroup className="search-format">
              <FormControl
                className="search-form"
                type="text"
                placeholder={I18n.t('invoice.searchPlInvoice')}
                onChange={(e) => this.handleSearchChange(e.target.value)}
                onKeyPress={this.handleSearchKeyPress}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            <FormGroup className="mb0">
              <DateTime
                value={moment(this.state.startDate)?.locale(language)}
                timeFormat={false}
                onChange={this.handleDateStartDateChange}
                inputProps={{
                  readOnly: true,
                  className: 'date-readonly form-control',
                  placeholder: I18n.t('report.query.fromDate'),
                  value: this.state.startDate
                    ? moment(this.state.startDate)
                        ?.locale(language)
                        ?.format('L')
                    : '',
                  disabled: this.state.disableDatetimeSelection,
                }}
                isValidDate={this.isFilteredDayValid}
                closeOnSelect
              />
            </FormGroup>
            <FormGroup className="mb0">
              <DateTime
                value={moment(this.state.endDate)?.locale(language)}
                timeFormat={false}
                onChange={this.handleDateEndDateChange}
                inputProps={{
                  readOnly: true,
                  className: 'date-readonly form-control',
                  placeholder: I18n.t('report.query.toDate'),
                  value: this.state.endDate
                    ? moment(this.state.endDate)?.locale(language)?.format('L')
                    : '',
                  disabled: this.state.disableDatetimeSelection,
                }}
                isValidDate={this.isFilteredDayValid}
                closeOnSelect
              />
            </FormGroup>
            <CCDropDown
              id="createInvoice-dropdown"
              items={SHOW_STATUS}
              title={'Status'}
              selectedItems={[
                this.state.showStatus
              ]}
              valueKey="value"
              labelKey="title"
              onSelect={this.handleSelectShowStatus}
              className="text-active"
            />
          </div>
          <div className="group-right">
            <Button
              className="btn-save view-button createBtn"
              onClick={() => this.handleShowHideCreateInvoce(true)}
              disabled={!this.state.hasPermissionAction}
            >
              +
              <Translate value="invoice.createBtn" />
            </Button>
          </div>
        </ButtonToolbar>

        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            sortHandle={this.handleSortInvoice}
            sortType={this.state.sortType}
            sortName={this.state.sortName}
            footerLoading={this.state.footerLoading}
            isLoading={this.state.isLoading}
            settings={this.props.auth?.selectedFleet}
          />
        </div>
      </div>
    );
  }
}

Invoice.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    language: state.i18n,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    mDispatcherActions: bindActionCreators(mDispatcherActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    creditCardActions: bindActionCreators(creditCardActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
