import React, { Component } from 'react';
import {
  Button,
  Modal,
  FormGroup,
  Form,
  FormControl,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import { Fragment } from 'react';

const CancelStatus = ['confirmed', 'booked']

class CancelButton extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleIncidentReasonChanged = (e) => {
    this.setState({
      reason: e.target.value,
    });
  };

  handleRefundFareChanged = (e) => {
    this.setState({
      refundFare: e.target.checked,
    });
  };

  closeModal = () => {
    this.setState({ visible: false });
  }

  showConfirm = () => {
    this.setState({ visible: true, reason: '', refundFare: false });
  }

  handleSubmit = (e) => {
    this.props.onSubmit({
      reason: this.state.reason,
      refundFare: this.state.refundFare,
    })
  }

  render() {
    const {
      bookInfo,
    } = this.props;

    const { reason, refundFare, visible } = this.state;
    if (!bookInfo || !CancelStatus.includes(bookInfo.status)) {
      return null;
    }
    const isOfflinePayment = [1].includes(bookInfo.request.paymentType);
    return (
      <Fragment>
        <Button onClick={this.showConfirm} className="btn-cancel mr mb">
          <Translate value="bookingdetail.Cancel" />
        </Button>
        <Modal
          show={visible}
          backdrop={true}
          dialogClassName="confirm-dialog"
          onHide={this.closeModal}
          className={'confirm'}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="intercityBooking.ConfirmCancel" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Form.Label>
                <Translate value="bookingdetail.Input_reason" />
              </Form.Label>
              <FormControl
                onChange={this.handleIncidentReasonChanged}
                type="text"
                value={reason}
                className="form-custom"
              />
            </FormGroup>
            {isOfflinePayment ? null : (
              <FormGroup>
                <CcCheckbox
                  checked={refundFare}
                  onChange={this.handleRefundFareChanged}
                  text={I18n.t('intercityBooking.RefundFare')}
                />
              </FormGroup> 
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-cancel" onClick={this.closeModal}>
              <Translate value="bookingdetail.Cancel" />
            </Button>
            <Button
              className="btn-save"
              disabled={!reason}
              onClick={this.handleSubmit}
            >
              <Translate value="bookingdetail.Ok" />
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default CancelButton;
