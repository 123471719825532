import { 
    cueFindApi, 
    cueCompletedFindApi,
    editTotalBookingApi, 
    getMenuItemsOutputDataApi, 
    searchGroupBookingAPI,
    ungroupBookingAPI,
    groupBookingAPI,
    changeSupplierAPI,
    assignDriverGroupAPI,
    detachDriverGroupAPI,
    removeBookingGroupAPI,
    syncThirdPartyBookingAPI,
    detachDriverAPI,
    assignDriverAPI,
    offerDriverAPI,
    detachVehicleAPI,
    unassignAllAPI
} from '../constants/ApiConfigs'
import {
    callApi
} from "../utils/apiUtils";
const queryString = require('query-string');

export const CUE_REQUEST = "CUE_REQUEST";
export const CUE_SUCCESS = "CUE_SUCCESS";
export const CUE_FAILURE = "CUE_FAILURE";
export const CUE_UPDATE_FILLTER = 'CUE_UPDATE_FILLTER'
export const CUE_ACTIVE_LOADMORE = 'CUE_ACTIVE_LOADMORE'
export const CUE_BOOKING_FROM_LIST = 'CUE_BOOKING_FROM_LIST'
export const UPDATE_DATA_POOL_CHANGES = 'UPDATE_DATA_POOL_CHANGES'
export const BOOKING_UPDATE_TOTAL = 'BOOKING_UPDATE_TOTAL'
export const RESET_CUE_FILTER = 'RESET_CUE_FILTER'
export const SYNCING_THIRD_PARTY_BOOKING = 'SYNCING_THIRD_PARTY_BOOKING'

const defaultFillter = {
    txtSearch: '',
    "bookingService": 'all',
    "bookingType": 'all',
    "dateFrom": null,
    "dateTo": null,
    "operator": "",
    "bookFrom": [],
    "carType": [],
    fleetId: null,
    status: {
            all: true,
            pending: true,
            dispatching: true,
            action: true,
            confirmed: true,
            booked: true,
            arrived: true,
            engaged: true,
            droppedOff: true,
            orderAccepted:true,
            driverSender:true,
            driverCash:true,
            driverGoods:true,
            arrivedSender:true,
            arrivedCash:true,
            delivering:true,
            completedDelivery:true,
            otwMerchant:true,
            arrivedAndWaitingToCollectItem:true
        },
    offerStatus: {
        all: true,
        pending: true,
        dispatching: true,
        action: true,
        confirmed: true,
        allocated: true
    },
    finishedStatus: {
            all: true,
            completed: true,
            noShow: true,
            incident: true,
            completedWithoutService: true,
            canceledByCC: true,
            canceledByPassenger: true,
            // canceledByPartner: true,
            canceledBymDispatcher: true,
            canceledByCorpAd: true,
            canceledByAPI: true,
            canceledByWebBooking:true,
            partialCompleted:true,
            failed:true
        }
}
const defaultOptions = {
    "limit": 50,
    "page": 0,
    sort: {},
    "query": {
        txtSearch: '',
        "bookingService": 'all',
        "bookingType": 'all',
        "dateFrom": null,
        "dateTo": null,
        "operator": "",
        "bookFrom": [],
        "carType": [],
        fleetId: null,
        status: {
                all: true,
                pending: true,
                dispatching: true,
                action: true,
                confirmed: true,
                booked: true,
                arrived: true,
                engaged: true,
                droppedOff: true,
                orderAccepted:true,
                driverSender:true,
                driverCash:true,
                driverGoods:true,
                arrivedSender:true,
                arrivedCash:true,
                delivering:true,
                completedDelivery:true,
                otwMerchant:true,
                arrivedAndWaitingToCollectItem:true
            },
            offerStatus: {
                all: true,
                pending: true,
                dispatching: true,
                action: true,
                confirmed: true,
                allocated: true
            },
            finishedStatus: {
                all: true,
                completed: true,
                noShow: true,
                incident: true,
                canceledByCC: true,
                canceledByPassenger: true,
                // canceledByPartner: true,
                canceledBymDispatcher: true,
                canceledByCorpAd: true,
                canceledByWebBooking: true
            }
    }
}

function cueRequest(isCompleted) {
    return {
        type: CUE_REQUEST,
        isCompleted: isCompleted
    }
}

function cueSuccess(data) {
    return {
        type: CUE_SUCCESS,
        data: data.res
    }
}

function cueFailure(error) {
    return {
        type: CUE_FAILURE,
        error
    }
}

export function cueFind(options = {}, isCompleted,isloading=true) {
    defaultOptions.query.txtSearch.toLocaleLowerCase();
    options = Object.assign({}, defaultOptions, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        isCompleted ? cueCompletedFindApi : cueFindApi,
        config,
        cueRequest(isCompleted),
        cueSuccess,
        cueFailure,
        isloading
    );

}

export function groupBooking(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        groupBookingAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function changeSuppliers(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        changeSupplierAPI,
        config,
        null,
        null,
        null,
        false,
        true
    );
}

export function detachDriver(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        detachDriverAPI,
        config,
        null,
        null,
        null,
        true,
        true
    );
}

export function detachVehicle(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        detachVehicleAPI,
        config,
        null,
        null,
        null,
        true,
        true
    );
}

export function unassignAll(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        unassignAllAPI,
        config,
        null,
        null,
        null,
        true,
        true
    );
}

export function assignDriver(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        assignDriverAPI,
        config,
        null,
        null,
        null,
        true,
        true
    );
}

export function offerDriver(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        offerDriverAPI,
        config,
        null,
        null,
        null,
        true,
        true
    );
}

export function removeBookingGroup(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        removeBookingGroupAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function syncThirdPartyBooking(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };

    return callApi(
        syncThirdPartyBookingAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function assignDriverGroup(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        assignDriverGroupAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function detachDriverGroup(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        detachDriverGroupAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function ungroupBooking(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        ungroupBookingAPI,
        config,
        null,
        null,
        null,
        true
    );
}

export function searchGroup(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };
    const stringified = queryString.stringify(options);
    let uri = searchGroupBookingAPI + stringified;
    return callApi(
        uri,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateStatusSyncThirdPartyBooking(options) {
    return {
        type: SYNCING_THIRD_PARTY_BOOKING,
        data: options,
    }
}

export function updateCueFillter(options,isCompleted = false) {
    options = options || defaultFillter;
    return {
        type: CUE_UPDATE_FILLTER,
        data: options,
        isCompleted:isCompleted
    }
}
export function cueActiveLoadmore(){
    return{
        type:CUE_ACTIVE_LOADMORE
    }
}

export function editTotal(options={}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        editTotalBookingApi,
        config,
        null,
        null,
        null,
        true
    );

}

export function getMenuItemsOutputData(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(getMenuItemsOutputDataApi, config, null, null, null, true);
}

export function cueBookingFromFilterList(data) {
    return {
        type: CUE_BOOKING_FROM_LIST,
        data: data
    }
}

export function noShowCue(data) {
    return {
        type: CUE_SUCCESS,
        data: data,
    }
}
export function cueBookingUpdateBookingChanged(data) {
    return {
        type: UPDATE_DATA_POOL_CHANGES
    }
}
export function cueBookingUpdateTotal(data){
    return {
        type:BOOKING_UPDATE_TOTAL,
        data:data
    }
}