export const Columns = [
  {
    key: "companyInfo.name",
    label: "corporate.Company_Name",
    sortName: "companyInfo.nameSort",
    fixed: true,
    width: 300,
    textEllipsis: true
  },
  {
    key: "adminAccount.fullName",
    label: "corporate.Admin_Name",
    sortName: "adminAccount.fullNameSort"
  },
  {
    key: "adminAccount.userName",
    label: "corporate.Username",
    sortName: "adminAccount.userName"
  },
  {
    key: "adminAccount.phone",
    label: "corporate.Phone_Number",
    sortName: "adminAccount.phone"
  },
  {
    key: "adminAccount.email",
    label: "corporate.Email",
    sortName: "adminAccount.email",
    width: 300,
    textEllipsis: true
  },
  {
    key: "isAirline",
    label: "corporate.Company_Type",
    width: 300,
    textEllipsis: true,
  },
  {
    key: "registeredFrom",
    label: "corporate.Registered_From",
    sortName: "registeredFrom"
  },
  {
    key: "createdDate",
    label: "corporate.Registration_Date",
    sortName: "createdDate"
  },
  {
    key: "CorporateStatus",
    label: "corporate.Status",
    width: 100,
    sortName: "isActive"
  },
  {
    key: "actions",
    label: "corporate.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];

export const CorporateActionMenuItem = {
  Active: [
    { label: "corporate.Edit", eventKey: 0 },
    { label: "corporate.Deactivate", eventKey: 1 }
  ],
  Inactive: [
    { label: "corporate.Edit", eventKey: 0 },
    { label: "corporate.Activate", eventKey: 1 },
    { label: "corporate.Delete", eventKey: 2 }
  ]
};

// traveler
export const CorpTravelerColumns = [
  {
    key: "fullName",
    label: "corporate.Employee_Name",
    fixed: true,
    sortName: "fullNameSort",
    width: 250,
    cellClass: "cell-align-toolbar", // canh deu cell voi header toolbar : search (padding left 20)
    headerClass: "header-align-toolbar"
  },
  {
    key: "phone",
    label: "corporate.Phone_Number",
    sortName: "phone",
    width: 200
  },
  {
    key: "email",
    label: "corporate.Email",
    sortName: "email",
    textEllipsis: true,
    width: 300
  },
  {
    key: "createdDate",
    label: "corporate.Created_Date",
    sortName: "createdDate",
    width: 200
  },
  {
    key: "status",
    label: "corporate.Status",
    width: 100,
    sortName: "corporateInfo.status"
  },
  {
    key: "actions",
    width: 100,
    label: "corporate.Actions",
    align: "right",
    cellClass: "table-actions"
  }
]

export const CorpTravelerActions = {
  Active: [
    { label: "corporate.Edit", eventKey: 0 },
    { label: "corporate.Deactivate", eventKey: 1 }
  ],
  Inreview: [
    { label: "corporate.Edit", eventKey: 0 },
    { label: "corporate.Activate", eventKey: 1 },
    { label: "corporate.Deactivate", eventKey: 2 },
    { label: "corporate.Delete", eventKey: 3 }
  ],
  Inactive: [
    { label: "corporate.Edit", eventKey: 0 },
    { label: "corporate.Activate", eventKey: 1 },
    { label: "corporate.Delete", eventKey: 3 }
  ]
};

// operators
export const CorpOperatorColumns = [
  {
    key: "fullName",
    label: "corporate.Corp_Users",
    width: 200,
    fixed: true,
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar"
  },
  {
    key: "userName",
    label: "corporate.Username",
    width: 200
  },
  {
    key: "phone",
    label: "corporate.Phone_Number",
    width: 200
  },
  {
    key: "email",
    label: "corporate.Email",
    textEllipsis: true,
    width: 300
  },
  {
    key: "createdDate",
    label: "corporate.Created_Date",
    width: 200
  },
  {
    key: "actions",
    label: "corporate.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
]

export const CorpOperatorActions = [
  {
    label: "corporate.Edit",
    eventKey: 0
  },
  {
    label: "corporate.Delete",
    eventKey: 1
  }
];

export const CorpPricingColumns = [
  {
    key: "vehicleType",
    label: "corporate.Car_Type_ID",
    width: 200,
    textEllipsis: true,
    fixed: true
  },
  {
    key: "appDisplayName",
    label: "corporate.App_Display_Name",
    textEllipsis: true,
    width: 200
  },
  {
    key: "regularFare",
    label: "corporate.Normal_Fare",
    width: 300,
    textEllipsis: true
  },
  {
    key: "flatFare",
    label: "corporate.Flat_Fare",
    width: 200
  },
  {
    key: "hourlyFare",
    label: "corporate.Hourly_Daily_Rate",
    width: 200,
    textEllipsis: true
  },
  {
    key: "deliveryFare",
    label: "corporate.Delivery_Rate",
    width: 200,
    textEllipsis: true
  },
  {
    key: "actions",
    label: "corporate.Actions",
    width: 120,
    align: "right",
    cellClass: "table-actions"
  }
]

