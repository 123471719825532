/* global google */
import React, { Component } from 'react'
import uuidv4 from 'uuid/v4'
import { Translate, I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Col,
  Row,
  FormControl,
  Button,
  Modal,
  Image,
  Container,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import currencyFormatter from 'currency-formatter'
import moment from 'moment'
import _, { debounce, template } from 'lodash'
import {
  geoPlaceDetailMapProvider,
  getDirectionMapProviderFromJupiter,
} from '../../utils/mapUtils'
import * as newbookingActions from '../../actions/newbookingAction'
import * as corporateActions from '../../actions/corporateAction'
import * as cueActions from '../../actions/cueActions'
import * as paymentMethodActions from '../../actions/paymentMethodActions'
import * as driverAutocompleteActions from '../../actions/driverAutocompleteActions'
import * as etaFareActions from '../../actions/etaFareActions'
import * as customerActions from '../../actions/customerAction'
import * as creditCardActions from '../../actions/creditCardActions'
import * as bookingDetailActions from '../../actions/bookingDetailAction'
import * as settingActions from '../../actions/settingActions'
import * as messageAction from '../../actions/messageAction'
import * as flightActions from '../../actions/flightActions'
import * as uploadActions from '../../actions/uploadActions'
import { socketConfigs } from '../../constants/socketConfigs'
import { socketDispatchApi } from '../../utils/socketUtils'
import remove_booking from '../../assets/images/icons/remove_bookingGR.svg'
import Notes from './bookComponent/Notes'
import Accept3rdButton from './bookComponent/Accept3rdButton'
import {
  bookingStatusMapping,
  additionalServiceType,
  paymentMethodNumber,
  travelerType,
  activeStatus,
  userType,
  locationType,
  EDITABLE_FARE,
  finishedStatusList,
  BookingHydraType,
  HYDRA_STATUS,
  BOOK_TYPE_FEE,
  booking3rdPartyStatus,
  HIDE_OVERRIDE_BUTTON_STATUS,
  HYDRA_STATUS_CAN_UPDATE,
  SUPPLIER_RESPONSE,
  CAN_ASSIGN_DRIVER_3RD_STATUS,
  thirdPartyIntegration,
  STATUS_EXTENAL_INFO,
  TIME_OR_MILEAGE_BASIC_FARE_FIELD,
  REGULAR_MODE,
} from '../../constants/commondata'

import {
  CCLiteCommonFunc,
  checkMultiPuDoNotEmpty,
  checkDropOffCountry,
  roundOff,
  trimPhoneNumber,
  regexpDriverAutoComplete,
  getCreditTransactionFeeSetting,
  filterCompanyList,
  convertTravelerTypeToText,
  getPermissionViewByName,
  filterCarTypeWithoutAssignRate,
  Validation,
  getSupplierInfoFromID,
  checkCompanyHasSupportPayment,
  checkShowChargeInvoicingBtn,
  checkBookingPrePaided,
  checkPaymentDirectInvoicing,
  checkShowViewPaymentLogsBtn,
  getTaxFleetService,
  getCompanyOfOperator,
  handleMultiSelectCompanyAll,
  checkCanRemoveLastOptionCorporate,
  checkAllSupplierHasSupportPickup,
  checkResetSupplierByZone,
  getOutStandingCustomer,
  handleResultUpdateFinishedBooking,
  getSuppilerPermission,
  calFleetORAdditionalServicePayment,
  checkResetCarWhenGetNewService,
  getLanguageAutoCompleteSearch,
  convertDataWhenChangeSupplier,
  handleRemoveKey,
  removeKeySupplierPermission,
} from '../../utils/commonFunctions'
import ChargeInvoicingModal from './bookComponent/ChargeInvoicingModal'
import {
  checkShowGoogleApplePay,
  convertPaymentTypeForCompleteWithoutService,
} from './bookFunction/payment'
import * as loadingActions from '../../actions/loadingBarActions'
import * as driverActions from '../../actions/driverAction'
import * as customerAutocompleteActions from '../../actions/customerAutocompleteAction'
import './bookingDetail.scss'
import closeIcon from '../../assets/images/icons/close.svg'
import {
  onBookingDetailChangePickUpHour,
  onBookingDetailChangePickUpMin,
  checkResetDrvWhenChangeCompany,
  calculateFareWithTipChange,
  calculateFareWithPromoChange,
  calculateFareWithServiceFeeChange,
  confirmBooking3rdBookingStatus,
  getVehicleWhenChangeDriver,
  checkResetVehicleWhenChangeCompany,
  checkRequiredDispatch,
  getLocationWhenDrapEnd,
  checkRoundTrip,
  checkLocationHasChange,
  resetVehicleWhenChangeDispatchType,
  getMsgCreateUpdateBook,
  validateWhenUpdateBook,
  getAllPointNotEmpty
} from './bookFunction/bookingInfo'
import {
  bookingDetailsEtaFareCalculator,
  bookingDetailsEtaFareMultiCarCalculator,
  getServiceAndCalculateLogicBookingDetail,
  carTypeBaseLocation,
  getTravelerModeByCarType,
  isCanAssignOfflineDriver,
  handleFinalServicesRequest,
  resetService,
} from './bookFunction/serviceRequest'
import {
  isBookingDetailFromAirPort,
  isBookingDetailToAirPort,
  isBookingDetailHaveExtraAirPort,
} from './bookFunction/flight'
import { getDefaultTip, canEditPromo } from './bookFunction/tipPromo'
import {
  calculationAdditionalService,
  initExtraService,
  onSelectAdditionalService,
} from './bookFunction/additionalService'
import Confirm from '../confirm/Confirm'
import PassengerLuggageNumber from '../../components/bookingDetail/bookComponent/PassengerLuggageNumber'
import FarmInConfirmationModal from '../../components/bookingDetail/bookComponent/FarmInConfirmationModal'
import OverrideButton from '../../components/bookingDetail/bookComponent/OverrideButton'
import { QQWebservice, QQUltis } from '../qqMap'
import DispatchLogs from '../DispatchLogs'
import TrackLink from '../trackLink/TrackLink'
import RouteInfo from '../RouteInfo'
import DateMode from '../../components/DateModeBooking'
import PaymentLogs from './bookComponent/PaymentLogs'
import { getTimezone } from '../../actions/mapProviderAction'

import {
  BookingInfoChauffeur,
  FlightInfo,
  TravellerInfo,
  PromoInfo,
  DispatchInfoChauffeur,
  DriverCalendarInfo,
  PaymentInfo,
  TripEstimate,
  ExtraServiceInfo,
  UpdateConfirmationModal,
  IncidentModal,
  CancelConfirmationModal,
  CompleteWithPaymentModal,
  ConfirmEditRecurringModal,
  BookingMapChauffeur,
  SendSMSBookingModal,
} from './index'
import EditFareComponent from './bookComponent/EditFareComponent'
import GroupInfoBooking from './bookComponent/GroupInfoBooking'
import TrailNotes from './bookComponent/trailNotes'

class BookingDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isChina: _.get(this.props.commonData, 'location.isChina', false),
      data: this.componentGetDataFromProp(props.data),
      dateMode: 'single',
      weekDays: {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
      },
      fromDate: '',
      toDate: '',
      recurringTime: '00:00',
      driversuggestions: [],
      driver: '',
      mapCenter: null,
      cartypeSelected: null,
      customer: null,
      credit: {},
      addOnPrice: 0,
      showConfirmCancel: false,
      showConfirmUpdate: false,
      showSMSBooking: false,
      showTrailNote: false,
      smsBookingTemplateQuery: {
        list: [],
        listAll: [],
        page: 0,
        limit: 10,
        total: 0,
        selectedObj: null,
        hasMore: true,
        search: '',
      },
      disabledSpam: false,
      isSend: false,
      smsWillSend: {
        id: '',
        phone: '',
        name: '',
        content: '',
        subject: '',
        typeMessage: 'Inbox',
        typeRecipient: '',
        bookingId: '',
      },
      showWarningResetEditFare: false,
      showConfirmIncident: false,
      showConfirmComplete: false,
      showConfirmCompleteWithoutService: false,
      paymentStep: 0,
      roundTrip: false,
      recurringDays: [],
      optionAutocomplete: {},
      paymentComplete: {
        rushHourChecked: true,
        heavyTrafficChecked: false,
        tax: 0,
        subTotal: 0,
        total: 0,
        promo: 0,
        creditTransactionFee: 0,
        isMinFare: false,
        paymentType: 0,
        totalWithoutPromo: 0,
        creditTransactionFee: 0,
        isMinimumTotal: false,
      },
      dataTemp: null,
      flightSuggestions: [],
      flightScheduleSuggestions: [],
      valid: {},
      isSubmited: false,
      isFormDirty: false,
      mapDefaultBound: null,
      isShowDriverCalendar: false,
      driverReservationBookings: [],
      driverReservationDatePriview: 0,
      driverCalendarViewing: null,
      pickupFrom: null,
      destinationFrom: null,
      showMap: false,
      additionalServices: [],
      isShowmDispatcherCard: false,
      isCompleteWithPaymentShow3rdBooking: false,
      rushHour: null,
      isShowedExtraDestination: false,
      isRetryPaymentCharge: true,
      isCheckSupportLocation: false,
      promoCodeMessageContent: {
        paymentMethods: [],
        minimumEstFare: '',
        schedules: false,
      },
      supplierCompanies: [],
      editAll: false,
      showConfirmRecurring: false,
      dataConfirmRecurring: {
        title: '',
        dataConfirmRecurring: '',
        funcHandleConfirmRecurring: () => {},
      },
      listDirtyLocation: [],
      isShowUngroupModal: false,
      isShowRemoveBookingModal: false,
      bookingIdRemoving: '',
      disabledCompleteWithoutServiceButton: true,
      supplierId: '',
      deactivatePaymentLink: false,
      isSupplierPermission: getSuppilerPermission(props.auth),
      isShuttle:
        this.props.bookingDetail?.data?.jobType === 'shuttle' ? true : false,
      resetCounterVehicleTextSearch: 0,
    }
    this.close = this.close.bind(this)
    this.getDirectionCallback = this.getDirectionCallback.bind(this)
    this.checkLocationHasChange = checkLocationHasChange.bind(this)
    this.handleResultUpdateFinishedBooking =
      handleResultUpdateFinishedBooking.bind(this)
    this.calculatorTotal = this.calculatorTotal.bind(this)
    this.getDirectionAndDraw = this.getDirectionAndDraw.bind(this)
    this.handleDriverChanged = this.handleDriverChanged.bind(this)
    this.handleChangeDispatchType = this.handleChangeDispatchType.bind(this)

    this.handleChangePickUp = this.handleChangePickUp.bind(this)
    this.handleAddressRemovedPickUp = this.handleAddressRemovedPickUp.bind(this)
    this.handleSelectThirdPartyPickUp =
      this.handleSelectThirdPartyPickUp.bind(this)
    this.handleChangeThirdPartyPickUp =
      this.handleChangeThirdPartyPickUp.bind(this)

    this.handleDestinationDrapEnd = this.handleDestinationDrapEnd.bind(this)
    this.handlePickupDrapEnd = this.handlePickupDrapEnd.bind(this)
    this.pickupDrapEndCallback = this.pickupDrapEndCallback.bind(this)
    this.destinationDrapEndCallback = this.destinationDrapEndCallback.bind(this)

    this.handleChangeDestination = this.handleChangeDestination.bind(this)
    this.handleAddressRemovedDestination =
      this.handleAddressRemovedDestination.bind(this)
    this.handleSelectThirdPartyDestination =
      this.handleSelectThirdPartyDestination.bind(this)
    this.handleChangeThirdPartyDestination =
      this.handleChangeThirdPartyDestination.bind(this)

    this.handleAddExtraDestinationButtunClick =
      this.handleAddExtraDestinationButtunClick.bind(this)
    this.handleSelectThirdPartyExtraDestination =
      this.handleSelectThirdPartyExtraDestination.bind(this)
    this.handleChangeThirdPartyExtraDestination =
      this.handleChangeThirdPartyExtraDestination.bind(this)

    this.handleChangeExtraDestination =
      this.handleChangeExtraDestination.bind(this)
    this.handleSelectRecentExtraDestination =
      this.handleSelectRecentExtraDestination.bind(this)
    this.handleChangeExtraDestination =
      this.handleChangeExtraDestination.bind(this)
    this.handleAddressRemovedExtraDestination =
      this.handleAddressRemovedExtraDestination.bind(this)
    this.handleExtraDestinationDrapEnd =
      this.handleExtraDestinationDrapEnd.bind(this)
    this.extraDestinationDrapEndCallback =
      this.extraDestinationDrapEndCallback.bind(this)

    this.handleChangeNote = this.handleChangeNote.bind(this)
    this.handleChangeTip = this.handleChangeTip.bind(this)
    this.handleChangeDispatchType = this.handleChangeDispatchType.bind(this)

    this.handleChangePromo = this.handleChangePromo.bind(this)
    this.handleBookingRateChange = this.handleBookingRateChange.bind(this)
    this.handleCarTypeChanged = this.handleCarTypeChanged.bind(this)
    this.handleTimeChanged = this.handleTimeChanged.bind(this)
    this.handleRoundtripChange = this.handleRoundtripChange.bind(this)
    this.handleRideSharingChange = this.handleRideSharingChange.bind(this)
    this.handleMeetdriverChange = this.handleMeetdriverChange.bind(this)
    this.handleDurationPackageChange =
      this.handleDurationPackageChange.bind(this)
    this.handleCreditCardChange = this.handleCreditCardChange.bind(this)
    this.handleAddCardSuccess = this.handleAddCardSuccess.bind(this)
    this.handlePromoApplyClick = this.handlePromoApplyClick.bind(this)
    this.handleAirlineChange = this.handleAirlineChange.bind(this)
    this.handleFlightNumerChange = this.handleFlightNumerChange.bind(this)
    this.handleChangeDispatchType = this.handleChangeDispatchType.bind(this)
    this.handleIncidentReasonChanged =
      this.handleIncidentReasonChanged.bind(this)
    this.handleCompleteBasicFareChange =
      this.handleCompleteBasicFareChange.bind(this)
    this.handleCompleteTollFeesChange =
      this.handleCompleteTollFeesChange.bind(this)
    this.handleCompleteGasFeesChange =
      this.handleCompleteGasFeesChange.bind(this)
    this.handleCompleteParkingFeesChange =
      this.handleCompleteParkingFeesChange.bind(this)
    this.handleRushHourCheckboxChange =
      this.handleRushHourCheckboxChange.bind(this)
    this.handleHeavyTrafficCheckboxChange =
      this.handleHeavyTrafficCheckboxChange.bind(this)
    this.handleCompleteOtherFeesChange =
      this.handleCompleteOtherFeesChange.bind(this)
    this.handleTaxChange = this.handleTaxChange.bind(this)
    this.handleCompletePaymentTypeChange =
      this.handleCompletePaymentTypeChange.bind(this)
    this.handleCompletePaymentIsPendingChange =
      this.handleCompletePaymentIsPendingChange.bind(this)
    this.handleClientCaseMatterChange =
      this.handleClientCaseMatterChange.bind(this)
    this.handleChangeOperatorNote = this.handleChangeOperatorNote.bind(this)
    this.handleChargeCodeChange = this.handleChargeCodeChange.bind(this)
    this.handleDispatch3rdOnchange = this.handleDispatch3rdOnchange.bind(this)
    this.cancelBookingButtonClick = this.cancelBookingButtonClick.bind(this)
    this.newBookingButtonClick = this.newBookingButtonClick.bind(this)
    this.googleMapRouteButtonClick = this.googleMapRouteButtonClick.bind(this)
    this.incidentButtonClick = this.incidentButtonClick.bind(this)
    this.incidentOkButtonClick = this.incidentOkButtonClick.bind(this)
    this.completeWithPaymentOkButtonClick =
      this.completeWithPaymentOkButtonClick.bind(this)
    this.completeWithPaymentCloseButtonClick =
      this.completeWithPaymentCloseButtonClick.bind(this)
    this.completeWithoutServiceCloseButtonClick =
      this.completeWithoutServiceCloseButtonClick.bind(this)
    this.cancelBooking = this.cancelBooking.bind(this)
    this.cancelBookingOkClick = this.cancelBookingOkClick.bind(this)
    this.updateInfoBookingClick = this.updateInfoBookingClick.bind(this)
    this.updateInfoAndDispatchBookingClick =
      this.updateInfoAndDispatchBookingClick.bind(this)
    this.handleDriverCalendarClick = this.handleDriverCalendarClick.bind(this)
    this.handleCloseDriverCalendarClick =
      this.handleCloseDriverCalendarClick.bind(this)
    this.handleDriverReservationBookingDateChange =
      this.handleDriverReservationBookingDateChange.bind(this)
    this.handleChangeETAFare = this.handleChangeETAFare.bind(this)
    this.handleChangeMarkupPrice = this.handleChangeMarkupPrice.bind(this)

    this.driverAutocompleteSlectedHandle =
      this.driverAutocompleteSlectedHandle.bind(this)
    this.getDriverSuggestionValue = this.getDriverSuggestionValue.bind(this)
    this.renderDriverSuggestion = this.renderDriverSuggestion.bind(this)

    this.getDefaultPaymentMethod = this.getDefaultPaymentMethod.bind(this)
    this.componentGetDataFromProp = this.componentGetDataFromProp.bind(this)
    this.paymentCompleteSubtotalCalculator =
      this.paymentCompleteSubtotalCalculator.bind(this)
    this.paymentCompleteTotalCalculator =
      this.paymentCompleteTotalCalculator.bind(this)

    this.flightScheduleAplyButtonClick =
      this.flightScheduleAplyButtonClick.bind(this)
    this.flightScheduleAutocompleteSlectedHandle =
      this.flightScheduleAutocompleteSlectedHandle.bind(this)
    this.getFlightSuggestions = this.getFlightSuggestions.bind(this)
    this.flightAutocompleteSlectedHandle =
      this.flightAutocompleteSlectedHandle.bind(this)
    this.getCrossZoneBaseLocation = this.getCrossZoneBaseLocation.bind(this)
    this.ValidatorCallback = this.ValidatorCallback.bind(this)
    this.getFareSettings = this.getFareSettings.bind(this)
    this.completeWithPayment3rdBooking =
      this.completeWithPayment3rdBooking.bind(this)
    this.handleSelectRecentPickup = this.handleSelectRecentPickup.bind(this)
    this.handleSelectRecentDestination =
      this.handleSelectRecentDestination.bind(this)

    this.renderIntelHourlyPackageRate =
      this.renderIntelHourlyPackageRate.bind(this)
    this.renderHourlyPackagesNote = this.renderHourlyPackagesNote.bind(this)
    this.resetPackageHourlyOption = this.resetPackageHourlyOption.bind(this)
    this.handleChangeRetryPaymentMethod =
      this.handleChangeRetryPaymentMethod.bind(this)
    this.handleRetryPaymentCharge = this.handleRetryPaymentCharge.bind(this)
    this.updateExtraServiceForEta = this.updateExtraServiceForEta.bind(this)
    // location info
    this.isPickupEditable = this.isPickupEditable.bind(this)
    this.isDestinationEditable = this.isDestinationEditable.bind(this)
    // book info
    this.onChangePickUpHour = onBookingDetailChangePickUpHour.bind(this)
    this.onChangePickUpMin = onBookingDetailChangePickUpMin.bind(this)
    // get from bookFunction/serviceRequest
    this.etaFareCalculator = bookingDetailsEtaFareMultiCarCalculator.bind(this)
    this.debounceEtaFareCalculator = debounce(this.etaFareCalculator, 300)
    this.getServiceAndCalculateLogic =
      getServiceAndCalculateLogicBookingDetail.bind(this)
    this.initExtraService = initExtraService.bind(this)
    this.carTypeBaseLocation = carTypeBaseLocation.bind(this)

    // get from bookFunction/flight
    this.isFromAirPort = isBookingDetailFromAirPort.bind(this)
    this.isToAirPort = isBookingDetailToAirPort.bind(this)
    this.isToExtraAirPort = isBookingDetailHaveExtraAirPort.bind(this)
    // get from bookfunction/tipPromo
    this.getDefaultTip = getDefaultTip.bind(this)
    this.canEditPromo = canEditPromo.bind(this)
    //
    this.getServiceAndCalculateLogic()
  }

  componentDidMount() {
    const {
      fleetId: fleetOfBook,
      isFarmOut = false,
      pricingType = 0,
      request,
    } = (this.props.bookingDetail && this.props.bookingDetail.data) || {}
    const currentFleetId = _.get(this.props.auth, 'selectedFleet.fleetId', '')
    const psgFleetId = _.get(request, 'psgFleetId', '')
    const fareEstimate = _.get(request, 'estimate.fare', {})
    const fleetMarkup = _.get(request, 'estimate.fare.fleetMarkup', 0)
    if (
      request.paymentType === 5 &&
      !checkBookingPrePaided(this.props.bookingDetail.data)
    ) {
      this.setState({ deactivatePaymentLink: true })
    }
    // Use for change form local to hydra Booking (Show on Trip estimate)
    let etaFareLocalOrigin = _.get(fareEstimate, 'etaFare', -1)
    if (_.get(request, 'estimate.markupValue', 0) > 0) {
      etaFareLocalOrigin += _.get(request, 'estimate.markupValue', 0)
    }
    // check hydra booking
    this.getSMSBookingTemplateList(false, () => {}, true)

    if (pricingType === 1) {
      if (currentFleetId === psgFleetId) {
        if (isFarmOut) {
          // Farm Out
          this.setState({
            farmOut: true,
            supplierVehicle: fleetOfBook,
            supplierDefault: fleetOfBook,
            supplierId: fleetOfBook,
            isHydraBooking: true,
            etaFareLocalOrigin:
              fleetMarkup !== 0 && _.isNumber(fleetMarkup)
                ? fareEstimate.sellPriceMarkup
                : 0,
            fleetMarkup: fleetMarkup,
            data: {
              ...this.state.data,
            },
          })
        } else {
          // Roaming
          this.setState({
            roaming: true,
            etaFareLocalOrigin: -1,
            isHydraBooking: true,
          })
        }
      } else {
        const bookFrom = _.get(this.props.bookingDetail, 'data.bookFrom', '')
        // Farm In
        this.setState({
          farmIn: true,
          isHydraBooking: false,
          etaFareLocalOrigin: -1,
          isBookFromPWA: bookFrom === 'PWA',
          data: {
            ...this.state.data,
            dispatchType: 0,
          },
        })
      }
    } else {
      const nameThirdParty =
        this.props.bookingDetail?.data?.externalInfo?.thirdParty
      const is3rdBooking = [
        thirdPartyIntegration.bookingAPI,
        thirdPartyIntegration.holidaytaxis,
        thirdPartyIntegration.karhoo,
      ].includes(nameThirdParty) // is booking from Third Party: Booking, Mozio, Karhoo
      this.setState({
        etaFareLocalOrigin: etaFareLocalOrigin,
        is3rdBooking,
        nameThirdParty: nameThirdParty,
        isApiDemandBooking: !!nameThirdParty && !is3rdBooking, // is booking from api demand
        isAcceptConfirm3rdBookingStatus: confirmBooking3rdBookingStatus(
          this.props.bookingDetail.data,
          is3rdBooking,
          nameThirdParty,
          this.props.auth.selectedFleet
        ),
      })
    }
    const batchId = _.get(this.props.bookingDetail, 'data.recurring.batchId')
    const pickup = _.get(this.props.bookingDetail, 'data.puPoints[0]', {})
    if (batchId) {
      this.props.bookingDetailActions
        .getStatusRecurring({
          fleetId: this.props.auth.selectedFleet.fleetId,
          batchId: batchId,
        })
        .then((result) => {
          if (result.ok && result.res) {
            this.setState({
              recurringBookingList: result.res.list,
            })
          }
        })
    }
    if (!_.isEmpty(pickup) && pickup.address && pickup.address.geo) {
      this.checkZoneSupportedWithPointComponent({
        fleetId: this.props.auth.selectedFleet.fleetId,
        lat: pickup.address.geo[1],
        lng: pickup.address.geo[0],
      }).then((results) => {
        const res = results.res || {}
        this.setState({
          supportHydraBooking: res.crossZone || false,
          supportLocalBooking: res.valid || false,
        })
      })
    }
    this.getVehiclesDriver()
    this.checkOpenBookFromURL()
  }

  getVehiclesDriver = async () => {
    if (this.props.bookingDetail?.data?.drvInfo?.userId) {
      try {
        const result = await this.props.driverActions.operationFindById({
          fleetId: this.props.auth.selectedFleet.fleetId,
          driverIds: [this.props.bookingDetail?.data?.drvInfo?.userId],
        })
        if (result.ok && result.res.list?.length > 0) {
          this.setState({
            data: {
              ...this.state.data,
              drvInfo: {
                ...this.state?.data?.drvInfo,
                ...result.res?.list[0],
              },
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  checkOpenBookFromURL = () => {
    // open book from url with user company permission
    const supplierCompanies = this.props.bookingDetail?.data?.supplierCompanies,
      companyUserId = this.props.auth.user.roles.companyId,
      companyDriverAssigned = this.props.bookingDetail.data?.drvInfo?.companyId
    if (
      companyUserId &&
      (this.props.auth.selectedFleet?.process?.broadcastBooking || true)
    ) {
      const companyUserInfo = this.props.commonData?.companies?.[0] || {}

      const listCompanySupplier = [
        ...this.props.commonData?.companies,
        ...this.props.commonData?.suppliers,
      ]
      const listCompanySupplierId = listCompanySupplier.map((ob) => ob._id)
      let showError = false
      if (companyUserInfo.broadcastBooking) {
        if (
          supplierCompanies?.length > 0 &&
          // !supplierCompanies.some((ob) => ob === companyUserId)
          !supplierCompanies.some((ob) => listCompanySupplierId.includes(ob))
        ) {
          showError = true
        }
      } else {
        if (
          // !supplierCompanies.some((ob) => ob === companyUserId) &&
          !supplierCompanies.some((ob) => listCompanySupplierId.includes(ob)) &&
          (!companyDriverAssigned || companyDriverAssigned !== companyUserId)
        ) {
          showError = true
        }
      }
      if (showError) {
        this.context.notification('error', I18n.t('cue.Error_load_booking'))
        this.props.bookingDetailActions.bookingDetailClosed()
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps
    if (
      !this.state.data ||
      (data.bookId != this.state.data.bookId && data.groupId) // Apply new data when click other booking in group booking
    ) {
      this.componentGetDataFromProp(data)
    }
  }

  setDirtyLocation = (newData) => {
    this.setState({ listDirtyLocation: newData })
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props
    if (data) {
      this.componentGetDataFromProp(data)
      this.props.paymentMethodActions.paymentMethod(
        this.props.auth.selectedFleet.fleetId
      )
      if (
        data.travelerType == travelerType.Individual ||
        (data.travelerType == travelerType.Corporate &&
          data.request.paymentType == paymentMethodNumber.personalCard)
      ) {
        if (data.psgInfo && data.psgInfo.creditInfo) {
          this.setState({
            credit: data.psgInfo.creditInfo,
            customer: data.psgInfo,
          })
        }
      } else if (data.corporateInfo && data.corporateInfo.creditInfo) {
        this.setState({
          credit: data.corporateInfo.creditInfo,
          customer: data.psgInfo,
        })
      }
      if (this.props.commonData.location.isChina) {
        this.setState({
          center: {
            lat: data.request.pickup.geo[1],
            lng: data.request.pickup.geo[0],
          },
        })
        return
      }
      const latLng = new google.maps.LatLngBounds()
      const defaultMap =
        this.props.commonData.mapZone &&
        this.props.commonData.mapZone.filter((m) => m.isDefault)[0]
      if (defaultMap) {
        defaultMap.geo.coordinates.map((cor) => {
          cor.map((z) => {
            latLng.extend(new google.maps.LatLng(z[1], z[0]))
          })
        })
        const center = latLng.getCenter()
        const bound = new google.maps.Circle({
          radius: 100000,
          center,
        }).getBounds()
        let centerLocation = `${center.lat()},${center.lng()}`
        this.setState({
          mapDefaultBound: bound,
          optionAutocomplete: {
            radius: '100000',
            location: centerLocation,
            fleetId: this.props.auth.selectedFleet.fleetId,
          },
          center: {
            lat: data.request.pickup.geo[1],
            lng: data.request.pickup.geo[0],
          },
        })
      }

      if (data.request && data.request.promo && data.request.promo !== '') {
        const { promoCodeMessageContent } = this.state
        this.props.bookingDetailActions
          .getPromotionCodeInfo({
            fleetId: this.props.auth.selectedFleet.fleetId,
            code: data.request.promo,
          })
          .then((data) => {
            if (data.ok && data.res) {
              let paymentMethods = []
              let minimumEstFare = ''
              let schedules = false
              if (data.res.paymentMethodsApply.length > 0) {
                paymentMethods = data.res.paymentMethodsApply
              }

              if (data.res.minimumEstFareApply.isLimited) {
                minimumEstFare = currencyFormatter.format(
                  data.res.minimumEstFareApply.valueByCurrencies[0].value,
                  {
                    code: data.res.minimumEstFareApply.valueByCurrencies[0]
                      .currencyISO,
                  }
                )
              }

              if (data.res.scheduleEnable) {
                schedules = data.res.scheduleEnable
              }

              this.setState({
                promoCodeMessageContent: {
                  ...promoCodeMessageContent,
                  paymentMethods,
                  minimumEstFare,
                  schedules,
                },
              })
            }
          })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.prevEtaFare !== prevState.prevEtaFare &&
      prevState.prevEtaFare &&
      (prevState.prevEtaFare.isFareEdited ||
        prevState.data.request.estimate.forceMarkupPrice)
    ) {
      this.setState({ showWarningResetEditFare: true })
    }
  }

  handleDeactivatePmLinkPrePaid = async () => {
    if (this.state.data.request.paymentType === 5) return
    try {
      const result =
        await this.props.bookingDetailActions.deactivatePaymentLinkForBooking({
          fleetId: this.props.auth.selectedFleet.fleetId,
          bookId: this.state.data.bookId,
        })
    } catch (error) {}
  }

  checkZoneSupportedWithPointComponent = async (params) => {
    let result =
      await this.props.newbookingActions.checkZoneSupportedWithPoint(params)

    if (
      !checkAllSupplierHasSupportPickup(result, this.props.auth, [
        ...this.props.commonData.companies,
        ...this.props.commonData.suppliers,
      ])
    ) {
      result = {}
    }

    if (
      result?.res?.valid ||
      (result?.res?.crossZone && !this.state?.company) || // Trường hợp book hydra không chọn corporate
      (result?.res?.crossZone && this.state?.company?.isCreateRoaming) // Trường hợp book hydra chọn corporate có on Create roaming
    ) {
      // Khac zone hien tai thi reset nhung cai lien quan company
      const newZoneId = result?.res?.zonesLocal?.[0]?._id
      if (this.state?.zonePickup?._id !== newZoneId) {
        const compannyAvailable = this.getCompanyList(
          result?.res?.zonesLocal?.[0]
        )
        const { auth, commonData = {} } = this.props,
          { supplierCompanies } = this.state,
          checkReset = checkResetSupplierByZone(
            newZoneId,
            auth,
            supplierCompanies,
            commonData,
            compannyAvailable,
            {}
          )
        if (checkReset.hasReset) {
          this.setState({
            supplierCompanies: checkReset.suppliers,
            cartypeSelected: null,
            data: {
              ...this.state.data,
              request: {
                ...this.state.data.request,
                paymentType: 1,
                companyName: '',
              },
              drvInfo: {},
            },
          })
        }
      }
      this.setState({
        zonePickup: result?.res?.zonesLocal?.[0] || {},
      })
    } else {
      this.setState({
        zonePickup: null,
      })
    }
    return result
  }

  handleForceInfoWhenChangeSupplier = (suppliers, supplierInfoFirst) => {
    let resetPayment = false,
      paymentType = this.state.data?.request?.paymentType
    let { resetCounterVehicleTextSearch } = this.state
    const hasResetDrvInfo = checkResetDrvWhenChangeCompany(
      this.state?.data?.drvInfo.companyId ||
        this.state?.data?.drvInfo.company?.companyId,
      suppliers
    )
    const hasResetVehicle = checkResetVehicleWhenChangeCompany(
      this.state?.data?.vehicle,
      suppliers
    )
    if (!this.state.broadcastBooking) {
      if (
        !checkCompanyHasSupportPayment(
          this.props.paymentMethod,
          supplierInfoFirst,
          this.state.data?.request?.paymentType
        )
      ) {
        paymentType = 0
        resetPayment = true
      }
    }

    this.setState(
      {
        supplierCompanies: suppliers,
        data: {
          ...this.state.data,
          request: {
            ...this.state.data.request,
            paymentType,
            companyName: supplierInfoFirst?.name,
          },
          drvInfo: hasResetDrvInfo ? {} : this.state.data.drvInfo,
          vehicle: hasResetVehicle ? {} : this.state.data.vehicle,
        },
        resetCounterVehicleTextSearch: hasResetVehicle
          ? ++resetCounterVehicleTextSearch
          : resetCounterVehicleTextSearch,
      },
      () => {
        this.carTypeBaseLocation().then((service) => {
          if (service.ok && service.res) {
            const { hasReset, newVehicle } = checkResetCarWhenGetNewService(
              service.res.vehicleType,
              this.state.data.request.vehicleTypeRequest,
              this.state.is3rdBooking,
              this.state.isShuttle
            )
            // kiểm tra có thay đổi vehicle hoặc payment mới gọi lại ETA
            if (hasReset) {
              this.setState(
                {
                  cartypeSelected: newVehicle,
                  data: {
                    ...this.state?.data,
                    request: {
                      ...this.state?.data?.request,
                      vehicleTypeRequest:
                        newVehicle?.vehicleType || newVehicle?.ty || '',
                    },
                  },
                },
                () => {
                  if (newVehicle?.vehicleType) {
                    this.carTypeBaseLocation().then((res) => {
                      if (this.updateExtraServiceForEta) {
                        this.updateExtraServiceForEta(res, true)
                      }
                      this.etaFareCalculator()
                    })
                  } else {
                    this.setState({
                      data: {
                        ...this.state.data,
                        request: {
                          ...this.state?.data?.request,
                          estimate: {
                            ...this.state?.data?.request?.estimate,
                            fare: {},
                            originFare: {},
                            isFareEdited: false,
                          },
                        },
                      },
                      etaFare: null,
                      etaFareMultiCar: [],
                    })
                  }
                }
              )
            } else if (resetPayment) {
              this.etaFareCalculator()
            } else if (suppliers.length == 1 && this.state?.data?.request?.estimate?.fare?.supplierEarningType == 'default') {
              this.etaFareCalculator()
            }
          }
        })
      }
    )
  }

  handleChangeSupplierCompanies = (newArrOption, selectingOption, isMulti) => {
    const { supplierIds, supplierInfoFirst } =
      convertDataWhenChangeSupplier(
        newArrOption,
        selectingOption,
        isMulti,
        this.props.auth,
        this.state.company,
        this.props.commonData
      ) || {}

    if (!supplierIds || !supplierInfoFirst) return
    this.handleForceInfoWhenChangeSupplier(supplierIds, supplierInfoFirst)
  }

  handleResultUpdateBooking = async (data) => {
    this.props.loadingActions.hideLoadingSpiner()
    if (data.code == 200 || data.returnCode == 200) {
      this.context.notification(
        'success',
        I18n.t('messages.booking.Update_booking_success')
      )
      if (this.state.deactivatePaymentLink) this.handleDeactivatePmLinkPrePaid()
      this.props.bookingDetailActions.bookingDetailClosed()
    } else {
      const errMgs = this.showMessageErrorBooking(data)
      if (errMgs) {
        this.context.notification('error', errMgs)
      }
    }
  }

  showMessageErrorBooking = (data) => {
    let msg = <Translate value="messages.booking.data_incorect" dangerousHTML />
    
    const msgCommon = getMsgCreateUpdateBook(data)
    if(msgCommon) return msgCommon

    if (data.code) {
      msg = I18n.t('messages.booking.' + data.code)
      const dataInfo = data.info || data.error
      if (data.code === 305) {
        // 3rd party booking: Booking has been canceled/Completed from booking.com
        this.close()
        return msg
      }
      if (data.code == 307) {
        return msg
      }
      if (data.code == 303) {
        if (data.error.minimumBookAhead) {
          let hour = 0
          const min = data.error.minimumBookAhead % 60
          if (data.error.minimumBookAhead > 0) {
            hour = Math.floor(data.error.minimumBookAhead / 60)
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min)
        }
        if (data.error.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            data.error.maximumBookAhead
          )
        }
        if (data.error.overlapTime) {
          this.showConfirmForceOverlap()
          msg = null
        } else if (data.error.cannotAssignDriver) {
          msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated')
        }
        if (data.errorCode === 'INVALID_PROMOTION') {
          switch (data.error && data.error.promo) {
            case 10:
              msg = I18n.t('messages.promoMsg.430')
              break
            case 11:
              msg = I18n.t('messages.promoMsg.431')
              break
            case 12:
              msg = I18n.t('messages.promoMsg.432')
              break
            case 13:
              msg = I18n.t('messages.promoMsg.433')
              break
            case 7:
              msg = I18n.t('messages.promoMsg.434')
              break
            default:
              break
          }
        }
      } else if (dataInfo && dataInfo.exDoDiffDo === false) {
        msg = I18n.t('messages.booking.exDoDiffDo')
      } else if (data.code === 304) {
        msg = I18n.t('messages.booking.304').format(
          `#${this.state.data.bookId}`
        )
      }
    } else {
      const dataInfo = data.info || data.error
      if (data.errorCode === 'CANNOT_FARM_OUT') {
        msg = "This booking can't be farm out: no permission"
        return msg
      }
      if (dataInfo.limit === false) {
        msg = I18n.t('messages.booking.booking_limited')
      } else if (dataInfo.sameZone === false && dataInfo.crossZone === false) {
        msg = I18n.t('messages.booking.area_service_unavailable')
      } else if (!dataInfo.rate) {
        msg = I18n.t(
          'messages.booking.Please_reload_page_or_check_your_connection'
        )
        if (dataInfo.rateErrorCode) {
          switch (dataInfo.rateErrorCode) {
            case 306:
              msg = I18n.t('messages.booking.306')
              break
            case 407:
              msg = I18n.t('messages.booking.407')
              break
            default:
              break
          }
        }
      } else if (!dataInfo.pickUpTime) {
        msg = I18n.t('messages.booking.time_invalid')
        if (dataInfo.minimumBookAhead) {
          let hour = 0
          let min = dataInfo.minimumBookAhead % 60
          if (dataInfo.minimumBookAhead > 0) {
            hour = Math.floor(dataInfo.minimumBookAhead / 60)
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min)
        }
        if (dataInfo.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            dataInfo.maximumBookAhead
          )
        }
        if (dataInfo.exDoDiffDo === false) {
          msg = I18n.t('messages.booking.exDoDiffDo')
        }
      } else if (
        dataInfo.psgInfo === false ||
        dataInfo.psgInfo.isActive === false
      ) {
        msg = I18n.t('messages.booking.psg_inactivate')
      } else if (dataInfo.preAuthorized === false) {
        if (dataInfo.preAuthorizedCode) {
          msg = I18n.t('messages.credits.' + dataInfo.preAuthorizedCode)
        }
      } else if (dataInfo.cannotAssignDriver) {
        msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated')
      } else if (dataInfo.overlapTime) {
        this.showConfirmForceOverlap()
        msg = null
      }
    }
    return msg
  }

  viewPaymentLogButtonClick = () => {
    this.setState({ showPaymentLogsModal: true })
  }

  closeShowMap = () => {
    this.setState({ showMap: false })
  }

  checkAdditionalServiceSelected = (id) =>
    !!_.find(
      this.state.data.request.services,
      (o) => o.active && o.serviceId === id
    )

  // select in completed payment form
  selectAdditionalServicCheckedHandle = (sv) => {
    const svId = sv._id
    const index = _.findIndex(
      this.state.data.request.services,
      (o) => o.serviceId === svId
    )
    const { newBooking = {} } = this.props
    if (index !== -1 && this.state.data.request.services[index].active) {
      // case: exist and active
      this.state.data.request.services[index].active = false
    } else {
      // case: not exist yet or not active yet
      if (this.state.data.request.services[index]) {
        // not active yet
        this.state.data.request.services[index].active = true
      } else {
        // not exist yet
        this.state.data.request.services.push({
          active: true,
          serviceId: svId,
        })
      }
    }

    _.remove(this.state.data.request.services, (o) => !o.active)
    let extraServices =
      newBooking.locationService && newBooking.locationService.extraServices
    if (this.state.data.isNewSettingAdditionFee) {
      extraServices =
        newBooking.locationService && newBooking.locationService.fleetServices
    }
    this.state.data.request.services = _.map(
      this.state.data.request.services,
      (o) => {
        const lcSV = extraServices
          ? extraServices.find((s) => s._id == o.serviceId)
          : null
        let fee = 0
        if (!lcSV) {
          return {
            active: false,
          }
        }
        const currency = lcSV.serviceFeeByCurrencies.find(
          (c) => c.currencyISO == this.state.data.currencyISO
        )
        if (currency) {
          fee = currency.value
        }
        // sv =
        return {
          fee,
          serviceId: lcSV._id,
          active: true,
          type: lcSV.serviceType,
          name: lcSV.serviceName,
          applyTax: lcSV.applyTax,
          serviceName: lcSV.serviceName,
          serviceFeeType: lcSV.serviceFeeType,
          serviceFeePercent: lcSV.serviceFeePercent,
          serviceFeeByCurrencies: lcSV.serviceFeeByCurrencies,
        }
      }
    )

    _.remove(this.state.data.request.services, (o) => !o.active)
    if (this.state.data.isNewSettingAdditionFee) {
      this.paymentCompleteTotalCalculator()
    } else {
      this.paymentCompleteSubtotalCalculator()
    }
  }

  closeAssignDriverModal = () => {
    this.setState({
      isShowAssignDriverModal: false,
    })
  }

  showAssignDriverModal = () => {
    this.setState({
      isShowAssignDriverModal: true,
    })
  }

  completeWithPayment3rdBooking() {
    const params = {
      bookId: this.state.data.bookId,
      paymentType: parseInt(this.state.retryPaymentMethod),
      fleetId: this.props.auth.selectedFleet.fleetId,
    }

    if (params.paymentType == 1 || params.paymentType == 7) {
      params.isCharge = this.state.isRetryPaymentCharge
      if (this.state.data.request.paymentType == 6) {
        params.paymentType = 3
      }
      if (this.state.data.request.paymentType == 4) {
        params.paymentType = 7
      }
      if (this.state.data.request.paymentType == 2) {
        params.paymentType = 1
      }
    }

    if (params.paymentType == 10) {
      const object = {
        bookId: this.state.retryCompleteBookingInfo.bookId,
        total: this.state.retryCompleteBookingInfo.total,
        fare: this.state.retryCompleteBookingInfo.fare,
        tip: this.state.retryCompleteBookingInfo.tip,
        fleetId: this.props.auth.selectedFleet.fleetId,
        tax: this.state.retryCompleteBookingInfo.tax,
        promoCode: this.state.retryCompleteBookingInfo.promoCode,
        promoAmount: this.state.retryCompleteBookingInfo.promoAmount,
        heavyTraffic: this.state.retryCompleteBookingInfo.heavyTraffic,
        airportSurcharge: this.state.retryCompleteBookingInfo.airportSurcharge,
        rushHour: this.state.retryCompleteBookingInfo.rushHour,
        techFee: this.state.retryCompleteBookingInfo.techFee,
        services: this.state.retryCompleteBookingInfo.services || [],
        otherFees: this.state.retryCompleteBookingInfo.otherFees,
        extraWaitFee: this.state.retryCompleteBookingInfo.extraWaitFee,
        subTotal: this.state.retryCompleteBookingInfo.subTotal,
        paymentType: params.paymentType,
        isMinimum: this.state.retryCompleteBookingInfo.isMinimum,
        psgId: this.state.data.psgInfo.userId,
        currencyISO: this.state.retryCompleteBookingInfo.currencyISOCharged,
        address: this.state.data.request.destination.address,
        geo: this.state.data.request.destination.geo,
        zipCode: this.state.data.request.destination.zipCode,
        operator: {
          userId: this.props.auth.user._id,
          name: `${this.props.auth.user.firstName || ''} ${
            this.props.auth.user.lastName || ''
          }`,
        },
      }
      this.props.bookingDetailActions.completeBooking(object).then((res) => {
        this.handleResultCompleteBooking(res)
      })
    } else {
      this.props.bookingDetailActions
        .retryCompleteBooking(params)
        .then((res) => {
          this.handleResultRetryCompleteBooking(res)
        })
    }
    this.props.loadingActions.showLoadingSpiner()
  }

  handleResultRetryCompleteBooking = (data) => {
    this.props.loadingActions.hideLoadingSpiner()
    if (data.returnCode == 200) {
      this.props.bookingDetailActions.bookingDetailClosed()
    } else {
      var msg = ''
      if (
        data.response &&
        data.response.response &&
        data.response.response.message
      ) {
        msg += ' (' + data.response.response.message + ' )'
      }
      this.context.notification(
        'error',
        I18n.t('messages.booking.complete_fail'),
        msg
      )
    }
  }

  componentGetDataFromProp(data) {
    // Initialize Booking Data
    if (!data) return null
    let state = Object.assign({}, data)
    let { auth } = this.props

    // booking cũ không có puPoint
    if (!state.puPoints && state.request.pickup) {
      state.puPoints = [
        {
          address: state.request.pickup,
          id: new Date().getTime(),
          order: 1,
          hasLocation: true,
        },
      ]
    }

    // booking cũ không có doPoint
    let { extraDestination = [], destination } = state.request || {}
    if (!state.doPoints || (state.doPoints && state.doPoints.length == 0)) {
      let newDoPoints = []
      if (destination && destination.address) {
        let newPoint = {
          address: state.request.destination,
          id: new Date().getTime(),
          hasLocation: true,
        }
        newDoPoints.push(newPoint)
      }

      if (extraDestination.length > 0) {
        extraDestination.forEach((extDestination) => {
          let newAddress = {
            address: {
              ...extDestination,
            },
            _id: extDestination._id,
            passengers: [],
            status: '',
            id: new Date().getTime(),
            hasLocation: true,
          }
          newDoPoints.push(newAddress)
        })
      }
      state.doPoints = newDoPoints
    }

    state.puPoints =
      state.puPoints &&
      state.puPoints.map((point) => ({
        ...point,
        id: point._id || point.id,
      }))
    state.doPoints =
      state.doPoints &&
      state.doPoints.map((point, index) => ({
        ...point,
        id: point._id || point.id,
        order: state.puPoints.length + ++index,
      }))

    if (state.doPoints && state.doPoints.length === 0) {
      state.doPoints = [
        {
          order: state.puPoints.length + 1,
          id: new Date().getTime(),
          hasLocation: false,
        },
      ]
    }
    state.sessionKeyETAFare = state?.request?.sessionKey || ''
    if (data.recurring && data.recurring.mode === 'recurring') {
      state.batchId = data.recurring.batchId
      this.setState({
        dateMode: 'recurring',
        recurringDays: data.recurring.selectedDates || [],
        recurringTime: data.recurring.pickupTime || '12:00',
      })
    }
    let timezone =
      state.request.pickup.timezone ||
      (auth.selectedFleet && auth.selectedFleet.timezone)
    state.promoApplied = false
    state.promoApplied = false
    state.request.pickUpTime =
      state.request.pickUpTime == 'Now'
        ? 'Now'
        : moment.tz(state.request.pickUpTime, timezone)
    state.rideSharing = state.request.rideSharing
    state.packageRateId = state.request.packageRateId
    if (!state.request.estimate.fare) {
      state.request.estimate.fare = {}
    }
    if (state.request.promo && state.request.promo.length > 0) {
      state.promoApplied = true
    }
    state.originPromo = state.request.promo
    state.farmOut = false
    if (state.isHydraBooking) {
      if (state.request.psgFleetId === this.props.auth.selectedFleet.fleetId) {
        state.farmOut = true
      }
    }

    if (
      CCLiteCommonFunc.isBookingStatusCompleted(state.status) &&
      !CCLiteCommonFunc.isDisplayCancelNoShowAmount(state)
    ) {
      if (state.completedInfo) {
        state.request.paymentType =
          CCLiteCommonFunc.paymentMappingCompletedToActive(
            state.completedInfo.paymentType
          )
      }
    }

    if (this.state) {
      this.state.locationPickUp = {
        lat: this.state.data.request.pickup.geo[1],
        lng: this.state.data.request.pickup.geo[0],
        city: this.state.data.request.pickup.city,
        cityName: this.state.data.request.pickup.cityName,
        zipCode: this.state.data.request.pickup.zipCode,
        address: this.state.data.request.pickup.address,
        businessName: this.state.data.request.pickup.businessName,
        from: this.state.data.request.pickup.from,
        offset: this.state.data.request.pickup.offset,
        timezone: this.state.data.request.pickup.timezone,
        countryCode: this.state.data.request.pickup.country,
        instructionLink: this.state.data.request.pickup.instructionLink || '',
        addressDetails: this.state.data.request.pickup.addressDetails || {},
      }
      if (
        this.state.data.request.destination &&
        this.state.data.request.destination.address &&
        this.state.data.request.destination.address.length > 0
      ) {
        this.state.locationDestination = {
          lat: this.state.data.request.destination.geo[1],
          lng: this.state.data.request.destination.geo[0],
          city: this.state.data.request.destination.city,
          cityName: this.state.data.request.destination.cityName,
          zipCode: this.state.data.request.destination.zipCode,
          address: this.state.data.request.destination.address,
          from: this.state.data.request.destination.from,
          countryCode: this.state.data.request.destination.country,
          instructionLink:
            this.state.data.request.destination.instructionLink || '',
          addressDetails:
            this.state.data.request.destination.addressDetails || {},
        }
        if (
          this.state.data.request.extraDestination &&
          this.state.data.request.extraDestination.length > 0
        ) {
          this.state.extraDestination = this.state.locationDestination
          // this.state.locationDestination = {
          //   from: this.state.data.request.extraDestination[0].from,
          //   lat: this.state.data.request.extraDestination[0].geo[1],
          //   lng: this.state.data.request.extraDestination[0].geo[0],
          //   city: this.state.data.request.extraDestination[0].city,
          //   cityName: this.state.data.request.extraDestination[0].cityName,
          //   zipCode: this.state.data.request.extraDestination[0].zipCode,
          //   countryCode: this.state.data.request.extraDestination[0].country,
          //   address: this.state.data.request.extraDestination[0].address,
          //   instructionLink:
          //     this.state.data.request.extraDestination[0] &&
          //     this.state.data.request.extraDestination[0].instructionLink
          //       ? this.state.data.request.extraDestination[0].instructionLink
          //       : '',
          // }
          this.state.addressDestination =
            this.state.data.request.extraDestination[0].address
        }
      } else {
        this.state.directions = null
        this.state.disDur = null
      }
      if (
        this.state.locationPickUp &&
        this.state.locationPickUp.address &&
        this.state.locationPickUp.address.length > 0 &&
        (!this.state.locationPickUp.from ||
          this.state.locationPickUp.from == locationType.tencent)
      ) {
        this.state.locationPickUp.from = locationType.google
      }
      if (
        this.state.locationDestination &&
        this.state.locationDestination.address.length > 0 &&
        (!this.state.locationDestination.from ||
          this.state.locationDestination.from == locationType.tencent)
      ) {
        this.state.locationDestination.from = locationType.google
      }
      if (
        this.state.extraDestination &&
        this.state.extraDestination.address.length > 0 &&
        (!this.state.extraDestination.from ||
          this.state.extraDestination.from == locationType.tencent)
      ) {
        this.state.extraDestination.from = locationType.google
      }

      this.state.paymentComplete = {
        rushHourChecked: true,
        heavyTrafficChecked: false,
        tax: 0,
        subTotal: 0,
        total: 0,
        isMinFare: false,
        minFare: 0,
        totalWithoutPromo: 0,
        paymentType: 0,
      }
      if (
        state.request.extraDestination &&
        state.request.extraDestination.length > 0
      ) {
        this.state.isShowedExtraDestination = true
      }
      if (state.request.paymentType == 3) {
        this.state.isShowmDispatcherCard = true
      }
      if (state.request.moreInfo.flightInfo) {
        if (state.request.moreInfo.flightInfo.airline) {
          this.state.flight = {
            iata: state.request.moreInfo.flightInfo.airlineIATA,
            icao: state.request.moreInfo.flightInfo.airlineIcao,
            name: state.request.moreInfo.flightInfo.airline,
          }
        }
        if (
          state.request.moreInfo.flightInfo.flightNumber &&
          state.request.moreInfo.flightInfo.ident
        ) {
          this.state.flightSchedule = state.request.moreInfo.flightInfo
        }
        state.oldFlight = Object.assign({}, state.request.moreInfo.flightInfo)
      }
      if (state.request && _.isNumber(state.request.minutesToArrive)) {
        const minutesToArrive = state.request.minutesToArrive
        let minutesToArriveObject = {
          value: 0,
          expected: 'samePickup',
        }
        if (minutesToArrive > 0) {
          minutesToArriveObject = {
            value: minutesToArrive,
            expected: 'after',
          }
        }

        if (minutesToArrive < 0) {
          minutesToArriveObject = {
            value: minutesToArrive * -1,
            expected: 'before',
          }
        }
        this.state.data.minutesToArrive = minutesToArriveObject
      } else {
        const reservation = _.get(
          auth,
          'selectedFleet.generalSetting.reservation'
        )
        if (reservation) {
          this.state.data.minutesToArrive = {
            value: reservation.minutesToArrive,
            expected:
              reservation.policy === 'samePickup'
                ? 'samePickup'
                : reservation.expected,
          }
        }
      }
      if (state.request.typeRate == 1 || state.request.type == 3) {
        this.state.data.duration = true
      }
      if (state.request.typeRate == 2 || state.request.type == 4) {
        this.state.data.roundTrip = true
      }
      if (!state.drvInfo) {
        state.drvInfo = {}
      }
      if (!state.request.travelerType) {
        if (state.psgInfo.creditInfo) {
          this.state.credit = state.psgInfo.creditInfo
        }
      } else if (state.corporateInfo.creditInfo) {
        this.state.credit = state.psgInfo.creditInfo
      }
      if (
        state.request.fleetServices &&
        state.request.fleetServices.length > 0
      ) {
        state.extraServices = state.request.fleetServices
        state.request.services = state.request.fleetServices
      } else {
        state.extraServices = state.request.services
      }
      if (state.request.estimate.fare) {
        if (state.request.estimate.fare.etaFare) {
          const markupDifference = state.request.estimate.fare.markupDifference
          if (markupDifference) {
            state.request.estimate.fare.etaFare =
              state.request.estimate.fare.etaFare - markupDifference
            state.request.estimate.fare.unroundedTotalAmt =
              state.request.estimate.fare.unroundedTotalAmt - markupDifference
            state.request.estimate.fare.totalWithoutPromo =
              state.request.estimate.fare.totalWithoutPromo - markupDifference
          }
        }
        if (
          state.request.estimate.fare.addOnPrice &&
          state.request.estimate.fare.addOnPrice !== 0
        ) {
          this.state.addOnPrice = state.request.estimate.fare.addOnPrice
        }
      }

      if (
        data.request.estimate.isFareEdited ||
        data.request.estimate.fare.markupDifference
      ) {
        this.state.isFareEditedBefore = true
        this.state.prevEtaFare = {
          originFare: data.request.estimate.originFare || {},
          isFareEdited: data.request.estimate.isFareEdited,
          reasonEditFare: data.request?.estimate?.reasonEditFare || '',
          tip: data.request.tip,
        }
      } else {
        this.state.prevEtaFare = {
          reasonEditFare: data.request?.estimate?.reasonEditFare || '',
        }
      }
      if (
        data.request.estimate.isFareEdited &&
        !data.request.estimate.fare.markupDifference
      ) {
        this.state.editFare = {}
        for (var key in data.request.estimate.fare) {
          if (EDITABLE_FARE.includes(key)) {
            if (data.request.estimate.fare.hasOwnProperty(key)) {
              if (
                data.request.estimate.fare[key] !==
                data.request.estimate.originFare[key]
              ) {
                this.state.editFare[key] = data.request.estimate.fare[key]
              }
            }
          }
        }
      }

      state = Object.assign(this.state.data || {}, state)

      // check có show google pay hoặc apple pay trong list payment method
      const isAllwayShowGoogleApplePay = checkShowGoogleApplePay(state)
      const companyIdOfOperator = getCompanyOfOperator(this.props.auth, [
        ...this.props.commonData.companies,
        ...this.props.commonData.suppliers,
      ])?._id
      const supplierCompanies =
        data.supplierCompanies || data?.request?.companyId || []
      // const supplierCompanies = companyIdOfOperator
      //   ? [companyIdOfOperator] // user has permission company
      //   : data.supplierCompanies || (data.request.companyId
      //   ? [data.request.companyId] // book from app
      //   : []);
      const broadcastBooking =
        this.props.auth?.selectedFleet?.process?.broadcastBooking || true
      if (
        !checkCompanyHasSupportPayment(
          this.props.paymentMethod,
          getSupplierInfoFromID(
            [
              ...this.props.commonData.companies,
              ...this.props.commonData.suppliers,
            ],
            supplierCompanies[0]
          ),
          state?.request?.paymentType,
          broadcastBooking
        )
      ) {
        state.request.paymentType = 0
      }
      this.setState(
        {
          showConfirmComplete: false,
          showConfirmCompleteWithoutService: false,
          isAllwayShowGoogleApplePay: isAllwayShowGoogleApplePay,
          paymentStep: 0,
          data: state,
          mapCenter:
            this.props.commonData.location &&
            this.props.commonData.location.isChina
              ? new window.qq.maps.LatLng(
                  this.state.locationPickUp.lat,
                  this.state.locationPickUp.lng
                )
              : this.state.locationPickUp,
          broadcastBooking,
          companyIdOfOperator,
          supplierCompanies,
        },
        () => {
          this.getServiceAndCalculateLogic(true)
          this.checkOutStandingCustomer()
        }
      )
    }
    state.puPoints = this.addMoreKeyForPoint(state.puPoints)
    state.doPoints = this.addMoreKeyForPoint(state.doPoints)
    return state
  }

  addMoreKeyForPoint = (pointList = []) => {
    if (pointList.length > 0) {
      return pointList.map((point) => {
        if (point.address && point.address.geo) {
          return {
            ...point,
            address: {
              ...point.address,
              lat: point.address.geo[1],
              lng: point.address.geo[0],
            },
          }
        }
        return point
      })
    }
    return pointList
  }

  checkOutStandingCustomer = async () => {
    const { data } = this.state
    const { selectedFleet } = this.props.auth
    const result = await getOutStandingCustomer(
      data?.psgInfo?.userId,
      selectedFleet,
      this.props.customerActions.getPendingPaymentCustomer
    )
    this.setState({
      data: {
        ...data,
        outStanding: result.filter((o) => o.amount > 0),
      },
    })
  }

  getDynamicSurchargeAmount = (estimateFare) => {
    // Surcharge amount = basic_fare X (parameter - 1.0)
    const { rushHour } = this.state
    const basicFare = _.get(estimateFare, 'basicFare', 0)
    const surchargeParameter = _.get(rushHour, 'surchargeParameter', 0)
    return surchargeParameter >= 1 ? basicFare * (surchargeParameter - 1) : 0
  }

  paymentCompleteSubtotalCalculator() {
    const { addOnPrice, data, isHydraBooking } = this.state
    const estimateFare = isHydraBooking
      ? data.request.estimate.fare.estimateFareBuy
      : data.request.estimate.fare
    const isFareEdited =
      data.request.estimate && data.request.estimate.isFareEdited
        ? data.request.estimate.isFareEdited
        : false

    this.state.paymentComplete.subTotal = 0
    if (estimateFare) {
      this.state.paymentComplete.minFare = estimateFare.minFare || 0
      this.state.paymentComplete.totalWithoutPromo =
        estimateFare.totalWithoutPromo
      if (estimateFare.mode === REGULAR_MODE.timeOrMileage) {
        estimateFare.basicFare =
          (parseFloat(estimateFare.startingFee) || 0) +
          (parseFloat(estimateFare.moveFeeInJourney) || 0) +
          (parseFloat(estimateFare.waitFeeInJourney) || 0)
      }
      this.state.paymentComplete.subTotal += parseFloat(
        estimateFare.basicFare || 0
      )
      if (this.state.paymentComplete.rushHourChecked) {
        if (isFareEdited) {
          this.state.paymentComplete.subTotal += estimateFare.rushHourFee
        } else {
          this.state.paymentComplete.subTotal += parseFloat(
            this.state.rushHour
              ? this.state.rushHour.surchargeType === 'amount'
                ? this.state.rushHour.surchargeFee
                : (this.state.rushHour.surchargeFee * estimateFare.basicFare) /
                  100
              : 0
          )
          const dynamicSurchargeAmount =
            this.getDynamicSurchargeAmount(estimateFare)
          this.state.paymentComplete.subTotal += dynamicSurchargeAmount
        }
      }

      if (this.state.paymentComplete.heavyTrafficChecked) {
        this.state.paymentComplete.subTotal += parseFloat(
          isHydraBooking
            ? this.state.fareSettings.heavyTraffic.surcharge || 0
            : CCLiteCommonFunc.getValueOfMultiCurrencies(
                this.state.fareSettings.fare.heavyTrafficByCurrencies,
                this.state.data.currencyISO,
                'value'
              ) || 0
        )
      }

      if (this.state.fareSettings.fare.otherFeeActive) {
        this.state.paymentComplete.subTotal += parseFloat(
          estimateFare.otherFees || 0
        )
      }

      this.state.paymentComplete.subTotal += parseFloat(
        estimateFare?.extraWaitFee || 0
      )

      if (
        this.props.auth.selectedFleet.fleetFareId &&
        this.props.auth.selectedFleet.fleetFareId.additionalServicesActive &&
        !this.state.data.isNewSettingAdditionFee
      ) {
        this.state.paymentComplete.subTotal +=
          calFleetORAdditionalServicePayment(
            this.state?.data?.request?.services
          )
      }
      this.state.paymentComplete.isMinFare = false
      if (this.state.paymentComplete.subTotal < estimateFare.minFare) {
        this.state.paymentComplete.subTotal = estimateFare.minFare
        this.state.paymentComplete.isMinFare = true
      }
      if (estimateFare.creditTransactionFee >= 0) {
        this.state.paymentComplete.creditTransactionFee =
          estimateFare.creditTransactionFee
      }
    }
    if (
      this.props.auth.selectedFleet.fleetFareId &&
      this.props.auth.selectedFleet.fleetFareId.tipActive
    ) {
      this.state.paymentComplete.tip =
        ((this.state.data.request.tip || 0) *
          this.state.paymentComplete.subTotal) /
        100
    }

    // Calculation Adjusted price
    if (addOnPrice !== 0) {
      this.state.paymentComplete.addOnPrice = addOnPrice
      this.state.paymentComplete.subTotal += addOnPrice
    }

    this.setState({
      paymentComplete: this.state.paymentComplete,
    })
  }

  getTravelerTypeText = () => {
    const { data } = this.props
    const travelerType =
      this.state.data.travelerType == 0 ? 'individual' : 'corporation'
    const isAirlineCorporate =
      (data && data.corporateInfo && data.corporateInfo.isAirline) || false
    return convertTravelerTypeToText(travelerType, isAirlineCorporate)
  }

  reloadCustomerTSYSGateway = (number) => {
    this.props.customerAutocompleteActions
      .customerAutocomplete({
        fleetId: this.props.auth.selectedFleet.fleetId,
        str: trimPhoneNumber(number).replace(/\D/g, ''),
        travelerType: this.getTravelerTypeText(),
      })
      .then((data) => {
        if (data.res) {
          let suggestions = (data.res && data.res.list) || []
          if (suggestions[0]) {
            const suggestData = suggestions[0]
            if (
              this.state.data.request.paymentType ===
              paymentMethodNumber.personalCard
            ) {
              const { customer } = this.state
              customer.credits = suggestData.credits
              this.setState({ customer })
            }
            if (
              this.state.data.request.paymentType ===
              paymentMethodNumber.corporateCard
            ) {
              const { company } = this.state
              // company.credits.push(cardAdded);
              this.setState({ company })
            }
          }
        }
      })
  }

  paymentCompleteTotalCalculator() {
    const { isHydraBooking } = this.state
    const isCommissionBookingApp = _.get(
      this.state.data,
      'corporateInfo.isCommissionBookingApp',
      false
    )
    const estimateFare = this.state.data.request.estimate.fare || {}

    this.state.paymentComplete.total = this.state.paymentComplete.subTotal
    if (this.props.auth.selectedFleet.techFeeActive) {
      this.state.paymentComplete.total += parseFloat(estimateFare.techFee || 0)
    }
    // Update formula when calculate fleet deduction (airport, meet greet, toll fee pay to fleet or driver)
    // https://issues.qup.vn/browse/KAN-223
    if (
      this.state.fareSettings.fare.airport.fromAirportActive ||
      this.state.fareSettings.fare.airport.toAirportActive ||
      (isHydraBooking && estimateFare && estimateFare.airportFee)
    ) {
      this.state.paymentComplete.total += parseFloat(
        estimateFare ? estimateFare.airportFee || 0 : 0
      )
    }
    if (!this.state.taxEdited) {
      if (
        this.props.auth.selectedFleet.fleetFareId &&
        this.props.auth.selectedFleet.fleetFareId.taxActive
      ) {
        this.state.paymentComplete.tax =
          ((this.state.isHydraBooking
            ? this.state.fareSettings.tax
              ? this.state.fareSettings.tax.surcharge
              : 0
            : this.state.fareSettings.fare.tax) *
            this.state.paymentComplete.subTotal) /
          100
        this.state.paymentComplete.tax +=
          getTaxFleetService(
            this.state?.data?.request?.services || [],
            this.state.data.isNewSettingAdditionFee,
            this.state.fareSettings,
            this.state.paymentComplete.subTotal
          ) || 0
      }
    }
    if (
      this.state.fareSettings.fleet.additionalService.fromAirport.meetDriver &&
      this.state.data.request.type == 1
    ) {
      this.state.paymentComplete.total += parseFloat(
        estimateFare ? estimateFare.meetDriverFee || 0 : 0
      )
    }

    if (this.state.fareSettings.fare.tollFeeActive) {
      this.state.paymentComplete.total += parseFloat(estimateFare.tollFee || 0)
    }

    if (this.state.fareSettings.fare.gasFeeActive) {
      this.state.paymentComplete.total += parseFloat(estimateFare.gasFee || 0)
    }

    if (this.state.fareSettings.fare.parkingFeeActive) {
      this.state.paymentComplete.total += parseFloat(
        estimateFare.parkingFee || 0
      )
    }
    //-------------------
    if (
      this.state.commisson &&
      (this.isBookingFromPaxApp(this.state.data) ||
        (!this.isBookingFromPaxApp(this.state.data) && isCommissionBookingApp))
    ) {
      let value = 0
      if (this.state.commisson.type == 'Percent') {
        value =
          (this.state.paymentComplete.subTotal *
            ((this.state.commisson.commissionByCurrencies[0] &&
              this.state.commisson.commissionByCurrencies[0].commissionValue) ||
              0)) /
          100
      } else if (
        this.state.commisson.commissionByCurrencies &&
        this.state.commisson.commissionByCurrencies.length > 0
      ) {
        this.state.commisson.commissionByCurrencies.map((cm) => {
          if (this.state.data.currencyISO == cm.currencyISO) {
            value = cm.commissionValue
          }
        })
      } else {
        value = this.state.commisson.value || 0
      }
      this.state.paymentComplete.total += parseFloat(value || 0)
      this.state.commissionValue = value
    }
    if (
      this.props.auth.selectedFleet.fleetFareId &&
      this.props.auth.selectedFleet.fleetFareId.tipActive
    ) {
      this.state.paymentComplete.total += parseFloat(
        this.state.paymentComplete.tip || 0
      )
    }
    if (
      this.props.auth.selectedFleet.fleetFareId &&
      this.props.auth.selectedFleet.fleetFareId.taxActive
    ) {
      this.state.paymentComplete.total += parseFloat(
        this.state.paymentComplete.tax || 0
      )
    }
    if (this.state.data && this.state.data.isNewSettingAdditionFee) {
      this.state.paymentComplete.total += parseFloat(
        calFleetORAdditionalServicePayment(
          this.state?.data?.request?.services,
          this.state.paymentComplete.subTotal
        )
      )
    }
    if (this.state.paymentComplete && this.state.paymentComplete.writeOffDebt) {
      this.state.paymentComplete.total += parseFloat(
        _.get(this.state.data, 'outStanding[0].amount', 0)
      )
    }
    this.state.paymentComplete.total = CCLiteCommonFunc.currencyNumberFormat(
      this.state.paymentComplete.total,
      this.state.data.currencyISO
    )
    let creditCardTransactionFee = _.get(
      this.props.auth,
      'selectedFleet.creditCardTransactionFee.feeByCurrencies[0]',
      {}
    )
    if (creditCardTransactionFee.currencyISO) {
      let settingTransaction = getCreditTransactionFeeSetting(
        creditCardTransactionFee,
        this.state.paymentComplete.paymentType
      )
      let creditTransactionFeeValue =
        parseFloat(this.state.paymentComplete.total) *
          settingTransaction.creditTransactionFeePercent *
          0.01 +
        settingTransaction.creditTransactionFeeAmount
      this.state.paymentComplete.total =
        parseFloat(this.state.paymentComplete.total) +
        parseFloat(creditTransactionFeeValue)
      this.state.paymentComplete.creditTransactionFee = parseFloat(
        creditTransactionFeeValue
      )
    }
    this.setState({ paymentComplete: this.state.paymentComplete })
  }

  calculatePromoAmount(payment, promo) {
    // When complete with payment
    if (promo) {
      if (promo.type) {
        if (promo.type == 'Amount') {
          return promo.value
        }
        let valuePromo = (payment.subTotal * promo.value) / 100
        // SL-22051: Support check maximum value/use  in form completed booking
        if (
          promo.type === 'Percent' &&
          promo.maximumValue &&
          promo.maximumValue.isLimited
        ) {
          return valuePromo >= promo.maximumValue.value
            ? promo.maximumValue.value
            : valuePromo
        } else {
          return valuePromo
        }
      }
      if (promo.value && promo.value.indexOf('%') >= 0) {
        const proValue = parseFloat((promo.value || '').replace(/^\D+/g, ''))
        let valuePromo = (payment.subTotal * proValue) / 100
        if (promo.maximumValue && promo.maximumValue.isLimited) {
          return valuePromo >= promo.maximumValue.value
            ? promo.maximumValue.value
            : valuePromo
        } else {
          return valuePromo
        }
      }

      const proValue = parseFloat((promo.value || '').replace(/^\D+/g, ''))
      return proValue
    }
    return 0
  }

  calculatorTotal(payment, promo, isRoundOff, onlyCheckMinimum = false) {
    const { selectedFleet } = this.props.auth
    let total = payment.total
    if (promo) {
      let totalWithPromo = payment.total
      const proValue = this.calculatePromoAmount(payment, promo) || 0
      if (
        promo.keepMinFee &&
        payment.minFare > 0 &&
        payment.total - proValue < payment.minFare
      ) {
        totalWithPromo = payment.minFare
        if (onlyCheckMinimum) return true
      } else {
        totalWithPromo = payment.total - proValue
      }
      if (totalWithPromo < 0) {
        total = 0
      } else {
        total = totalWithPromo
      }
    } else {
      total = payment.total
    }
    if (onlyCheckMinimum) return false
    total = isRoundOff
      ? roundOff(total, this.state.data.currencyISO, selectedFleet.rounding)
      : total
    return total.toFixed(2)
  }

  close = () => {
    this.props.closeBookingDialog()
  }

  cannelBookingButtonRecurring = (hasRecurring = false, cancelAll = false) => {
    let dataEmit = {
      'x-request-id': uuidv4({ msecs: new Date().getTime() }),
      bookId: this.state.data.bookId,
      reason: '',
      fleetId: this.props.auth.selectedFleet.fleetId,
      canceller:
        this.props.auth.user.roles.roleName == 'corporateAdmin'
          ? 'CorpAD'
          : 'CC',
      operator: {
        userId: this.props.auth.user._id,
        name: `${this.props.auth.user.firstName || ''} ${
          this.props.auth.user.lastName || ''
        }`,
      },
    }
    if (hasRecurring) {
      dataEmit.cancelAll = cancelAll
    }

    this.props.loadingActions.showLoadingSpiner()
    this.props.bookingDetailActions.cancelBooking(dataEmit).then((res) => {
      this.props.loadingActions.hideLoadingSpiner()
      if (res.returnCode == 200) {
        this.context.notification(
          'success',
          I18n.t('messages.booking.Cancel_booking_success')
        )
        this.props.bookingDetailActions.bookingDetailClosed()
      } else {
        this.context.notification(
          'error',
          I18n.t('messages.booking.cancle_fail')
        )
      }
    })
  }

  cancelBookingButtonClick() {
    if (this.state.isHydraBooking) {
      this.props.loadingActions.showLoadingSpiner()
      this.props.bookingDetailActions
        .checkChargeCCCancel({
          fleetId: _.get(this.props.auth, 'selectedFleet.fleetId', ''),
          bookId: this.state.data.bookId,
        })
        .then((res) => {
          this.props.loadingActions.hideLoadingSpiner()
          if (res && res.returnCode === 200 && res.charge) {
            this.setState({
              msgChargeCancel:
                'Are you sure you want to cancel this affiliate booking? You will need to pay a penalty for this action.',
              showConfirmCancel: !this.state.showConfirmCancel,
            })
          } else {
            this.setState({
              msgChargeCancel:
                'Are you sure you want to cancel this affiliate booking?',
              showConfirmCancel: !this.state.showConfirmCancel,
            })
          }
        })
    } else {
      this.setState({ showConfirmCancel: !this.state.showConfirmCancel })
    }
  }

  cancelBookingOkClick() {
    let { batchId } = this.state.data
    //nếu là book recurring
    if (batchId) {
      this.setState({
        showConfirmRecurring: true,
        dataConfirmRecurring: {
          title: 'Cancel recurring order',
          content:
            'Do you only want to cancel this order, or cancel this and all future orders?',
          funcHandleConfirmRecurring: this.cannelBookingButtonRecurring,
        },
      })
    } else {
      this.cannelBookingButtonRecurring()
    }
  }

  newBookingButtonClick() {
    const jobType = _.get(this.props.bookingDetail, 'data.jobType', '')
    const NewBK = {
      booking: this.state.data,
      customer: this.state.customer,
      // cartypeSelected: CCLiteCommonFunc.isBookingStatusCompleted(this.state.data.status)
      //   ? null
      //   : this.state.cartypeSelected,
      cartypeSelected: this.state.cartypeSelected,
      company: this.state.company,
      credit: this.state.credit,
      isShuttle: jobType === 'shuttle' ? true : false,
      supplierId: this.state.supplierId,
      isHydraBooking: this.state.isHydraBooking,
    }
    this.props.newbookingActions.newBookingFromExistedBooking(NewBK)
    this.props.closeBookingDialog()
  }
  googleMapRouteButtonClick(e) {
    const puPoints = _.get(this.state, 'data.puPoints', [])
    const doPoints = _.get(this.state, 'data.doPoints', [])
    let pu = _.map(puPoints, function (item) {
      return item.address.geo[1] + ',' + item.address.geo[0]
    })
    let dropoff = _.map(doPoints, function (item) {
      return item.address.geo[1] + ',' + item.address.geo[0]
    })
    let urlGGMap = `https://www.google.com/maps/dir/${_.concat(
      pu,
      dropoff
    ).join('/')}`

    window.open(urlGGMap)
  }
  updateBookingButtonClick = (e) => {
    const {
      data,
      credit,
      valid,
      cartypeSelected,
      locationPickUp,
      locationDestination,
      nameThirdParty
    } = this.state
    const { paymentMethod } = this.props
    const isDispatcher = data.bookFrom === 'mDispatcher'
    const isBookFromDMC = data.bookFrom === 'dmc' || data.bookFrom === 'corp'
    let { paymentType } = data.request
    const msg = validateWhenUpdateBook({
      bookInfo: this.state.data,
      nameThirdParty
    })
    if(msg) {
      this.context.notification(
        'error',
        msg
      )
      return;
    }
    if(
      nameThirdParty === thirdPartyIntegration.bookingAPI &&
      this.state.data.drvInfo.phone &&
      !this.state?.data?.vehicle?.vehicleId
    ) {
      this.context.notification(
        'error',
        'The vehicle is required'
      )
      return 
    }
    if (this.state.data && this.state.data.rideSharing) {
      const { doPoints, puPoints } = this.state.data
      const puPointsNotEmpty = this.filterPointEmpty(puPoints)
      const doPointsNotEmpty = this.filterPointEmpty(doPoints)
      if (puPointsNotEmpty.length > 1 || doPointsNotEmpty.length > 1) {
        this.context.notification(
          'error',
          I18n.t('newbooking.rideSharing_multi_point')
        )
        return
      }
    }

    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true })
    }

    if (!CCLiteCommonFunc.isFormValid(valid)) {
      return
    }
    let validDropCountry = checkDropOffCountry(
      cartypeSelected && cartypeSelected.dropOffCountry,
      locationPickUp,
      locationDestination,
      this.state.isChina
    )
    if (!validDropCountry.valid) {
      validDropCountry.err == 0
        ? this.context.notification(
            'error',
            I18n.t('messages.booking.DropOff_country')
          )
        : this.context.notification(
            'error',
            I18n.t('messages.booking.Different_Country')
          )
      return
    }

    if (!paymentType || !parseInt(paymentType)) {
      return this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_payment_method')
      )
    }

    if (data.travelerType) {
      if (
        !paymentMethod.corporateTypes.find(
          (pm) => pm.value === parseInt(paymentType)
        )
      ) {
        paymentType = paymentMethod.corporateTypes[0].value
      }
    } else {
      const { individualTypes, type3rd } = paymentMethod
      const paymentMethodIndividuals = isDispatcher
        ? [...individualTypes, ...type3rd]
        : individualTypes
      const foundedMethod = _.find(
        paymentMethodIndividuals,
        (pm) => pm.value === parseInt(paymentType)
      )
      if (!foundedMethod && !isBookFromDMC) {
        paymentType = paymentMethod.individualTypes[0].value
      }
    }

    if (
      ((this.props.newBooking &&
        this.props.newBooking.locationService &&
        this.props.newBooking.locationService.crossZone) ||
        paymentType === paymentMethodNumber.personalCard ||
        paymentType === paymentMethodNumber.corporateCard) &&
      (!credit || credit.cardMask.length == 0)
    ) {
      return this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_or_add_a_card')
      )
    }

    if (
      checkRequiredDispatch({
        dispatchType: data.dispatchType,
        isBookNow: this.props.data?.request?.pickUpTime === 'Now',
        driverPhone: this.state?.driver?.phone,
        vehicleId: this.state?.data?.vehicle?.vehicleId,
        isShuttle: this.props.bookingDetail?.data?.jobType === 'shuttle',
      })
    ) {
      return this.context.notification('error', 'Please select driver')
    }

    this.setState({
      showConfirmUpdate: !this.state.showConfirmUpdate,
      data: {
        ...data,
        request: {
          ...data.request,
          paymentType,
        },
      },
    })
  }

  showChargeInvoicingModal = () => {
    this.setState({
      openChargeInvoicing: true,
    })
  }

  sendSMSBooking = (e) => {
    this.setState({
      showSMSBooking: !this.state.showSMSBooking,
      smsWillSend: {
        id: '',
        phone:
          !_.isString(this.props.data.psgInfo) &&
          this.props.data.psgInfo.userId &&
          this.props.data.psgInfo.userId.length > 0
            ? this.props.data.psgInfo.phone || ''
            : this.props.data.drvInfo.phone || '',
        name: '',
        content: '',
        subject: '',
        typeMessage: 'Inbox',
        typeRecipient:
          !_.isString(this.props.data.psgInfo) &&
          this.props.data.psgInfo.userId &&
          this.props.data.psgInfo.userId.length > 0
            ? 'passenger'
            : 'driver',
      },
      isSend: false,
      smsBookingTemplateQuery: {
        ...this.state.smsBookingTemplateQuery,
        selectedObj: null,
      },
    })
  }

  getSMSBookingTemplateList = (
    loadMore = false,
    callback = null,
    firstLoad = false
  ) => {
    let bodyRequest = {
      fleetId: this.props.auth.selectedFleet.fleetId,
    }

    this.props.settingActions
      .getAllBookingSMSTemplates(bodyRequest)
      .then((data) => {
        if (data.ok && data.res) {
          let listFiter = data.res
          const newData = listFiter.map((item) => {
            item.value = item.name
            item.id = item._id
            return item
          })
          this.setState({
            smsBookingTemplateQuery: {
              ...this.state.smsBookingTemplateQuery,
              ...data.res,
              list: newData,
              listAll: newData,
            },
          })
        }
      })
  }
  handleSelectSMSBookingTemplate = (templateId) => {
    const smsBookingTemplateQuery = Object.assign(
      {},
      this.state.smsBookingTemplateQuery
    )
    smsBookingTemplateQuery.selectedObj = _.find(
      this.state.smsBookingTemplateQuery.list,
      (item) => item.id === templateId
    )

    if ((templateId && templateId.length === 0) || !templateId) {
      smsBookingTemplateQuery.list = this.state.smsBookingTemplateQuery.listAll
    }

    this.setState({
      smsBookingTemplateQuery,
      smsWillSend: {
        ...this.state.smsWillSend,
        id: templateId || '',
        name:
          smsBookingTemplateQuery.selectedObj &&
          smsBookingTemplateQuery.selectedObj.name
            ? smsBookingTemplateQuery.selectedObj.name
            : '',
        subject:
          smsBookingTemplateQuery.selectedObj &&
          smsBookingTemplateQuery.selectedObj.name
            ? smsBookingTemplateQuery.selectedObj.name
            : '',
        content:
          smsBookingTemplateQuery.selectedObj &&
          smsBookingTemplateQuery.selectedObj.content
            ? smsBookingTemplateQuery.selectedObj.content
            : '',
      },
    })
  }

  handleChangeMessageType = (value) => {
    this.setState({
      smsWillSend: {
        ...this.state.smsWillSend,
        typeMessage: value,
      },
    })
  }

  handleSendMessageClick = () => {
    let data = Object.assign({}, this.state.smsWillSend)
    data.phone = trimPhoneNumber(data.phone)
    data.bookingId = this.state.data && this.state.data.bookId
    const keyListArr = [
      '[FLEET_NAME]',
      '[FLEET_PHONE]',
      '[FLEET_EMAIL]',
      '[BOOKING_ID]',
      '[PASSENGER_FIRST_NAME]',
      '[PASSENGER_LAST_NAME]',
      '[PASSENGER_PHONE]',
      '[DRIVER_FIRST_NAME]',
      '[DRIVER_LAST_NAME]',
      '[DRIVER_PHONE]',
      '[PICKUP_TIME]',
      '[TRACK_LINK]',
    ]
    keyListArr.forEach((item) => {
      if (data.content.includes(item)) {
        let value = ''
        switch (item) {
          case '[FLEET_NAME]':
            value = this.props.auth.selectedFleet.name || ''
            break
          case '[FLEET_PHONE]':
            value = this.props.auth.selectedFleet.phone || ''
            break
          case '[FLEET_EMAIL]':
            value = this.props.auth.selectedFleet.email || ''
            break
          case '[BOOKING_ID]':
            value = this.state.data.bookId || ''
            break
          case '[PASSENGER_FIRST_NAME]':
            value =
              (this.state.data.psgInfo &&
                this.state.data.psgInfo.firstName &&
                this.state.data.psgInfo.firstName) ||
              ''
            break
          case '[PASSENGER_LAST_NAME]':
            value =
              (this.state.data.psgInfo &&
                this.state.data.psgInfo.lastName &&
                this.state.data.psgInfo.lastName) ||
              ''
            break
          case '[PASSENGER_PHONE]':
            value =
              (this.state.data.psgInfo &&
              this.state.data.psgInfo.phone &&
              this.state.data.psgInfo.phone !== 'No Phone'
                ? this.state.data.psgInfo.phone
                : '') || ''
            break
          case '[DRIVER_FIRST_NAME]':
            value =
              (this.state.data.drvInfo &&
                this.state.data.drvInfo.firstName &&
                this.state.data.drvInfo.firstName) ||
              ''
            break
          case '[DRIVER_LAST_NAME]':
            value =
              (this.state.data.drvInfo &&
                this.state.data.drvInfo.lastName &&
                this.state.data.drvInfo.lastName) ||
              ''
            break
          case '[DRIVER_PHONE]':
            value =
              (this.state.data.drvInfo &&
              this.state.data.drvInfo.phone &&
              this.state.data.drvInfo.phone !== 'No Phone'
                ? this.state.data.drvInfo.phone
                : '') || ''
            break
          case '[PICKUP_TIME]':
            let result = ''
            if (this.props.data.request.pickUpTime !== 'Now') {
              let timePickup = moment
                .tz(
                  this.props.data.request.pickUpTime,
                  this.props.auth.selectedFleet.timezone
                )
                .toString()
              result = timePickup.slice(0, timePickup.indexOf('GMT') - 1)
            }
            value =
              this.props.data.request.pickUpTime === 'Now' ? 'Now' : result
            break
          case '[TRACK_LINK]':
            value =
              `${this.props.auth.selectedFleet.trackLink}/${this.state.data.token}` ||
              ''
            break
        }
        data.content = data.content.replaceAll(item, value)
      }
    })
    this.setState({ isSend: true })
    if (
      this.state.smsWillSend.content.length === 0 ||
      this.state.smsWillSend.phone.length === 0 ||
      this.state.smsWillSend.subject.length === 0
    )
      return
    if (Validation.validateSearchingPhone(data.phone) === false) {
      return this.context.notification('error', I18n.t('errors.24001'))
    } else {
      data.fleetId = this.props.auth.selectedFleet.fleetId
      this.setState({ disabledSpam: true })
      this.props.settingActions.sendMessageBooking(data).then((data) => {
        if (data && data.error === null) {
          this.context.notification(
            'success',
            I18n.t('report.result.bookingDetails.successSendMessage')
          )
          this.sendSMSBooking()
          return this.setState({ disabledSpam: false })
        } else {
          this.context.notification('error', data.error.message)
          return this.setState({ disabledSpam: false })
        }
      })
    }
  }

  handleSearchSMSTemplate = (inputValue, callback, forceUpdate = false) => {
    let params = _.pick(this.state.smsBookingTemplateQuery, [
      'search',
      'listAll',
      'list',
      'selectedObj',
    ])
    if (params.listAll.length === 0) return
    if (inputValue && inputValue.length > 0) {
      params.search = inputValue
      params.list = params.listAll.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      this.setState({
        smsBookingTemplateQuery: {
          ...this.state.smsBookingTemplateQuery,
          ...params,
        },
      })
    } else {
      params.search = ''
      params.list = params.listAll
      this.setState({
        smsBookingTemplateQuery: {
          ...this.state.smsBookingTemplateQuery,
          ...params,
        },
      })
    }
    return callback()
  }

  handleChangeRecipientType = (value) => {
    this.setState({
      smsWillSend: {
        ...this.state.smsWillSend,
        typeRecipient: value,
        phone:
          value === 'driver'
            ? this.props.data.drvInfo.phone
            : this.props.data.psgInfo.phone,
      },
    })
  }

  handleContentChange = (e) => {
    this.setState({ smsWillSend: { ...this.state.smsWillSend, content: e } })
  }

  handlePhoneChange = (e) => {
    this.setState({
      smsWillSend: { ...this.state.smsWillSend, phone: e.target.value },
    })
  }

  handleSubjectChange = (e) => {
    this.setState({
      smsWillSend: { ...this.state.smsWillSend, subject: e.target.value },
    })
  }

  showHideConfirmRecurringModal = () => {
    this.setState({ showConfirmRecurring: !this.state.showConfirmRecurring })
  }

  handleErrorCheckPromoWithRecurring = (result) => {
    if (!result.returnCode) {
      this.context.notification(
        'error',
        I18n.t('bookingdetail.Check_promo_error')
      )
      return false
    }
    if (result.returnCode !== 200) {
      this.context.notification(
        'error',
        I18n.t(`messages.promoMsg.${result.returnCode}`)
      )
      return false
    }
    return true
  }

  saveHolidayTaxisCompletedEditFare = (value) => {
    this.setState({
      data: {
        ...this.state.data,
        completedInfo: {
          ...this.state.data.completedInfo,
          total: value,
        },
      },
    })
  }

  updateInfoBookingConfirmed = async (
    hasRecurring = false,
    isEditAll = false
  ) => {
    if (this.isFinished(this.state.data)) {
      // Call dispatch API to update booking finished
      const updateParams = {
        bookId: this.state.data.bookId,
        fleetId: this.state.data.fleetId,
        notes: _.get(this.state.data, 'request.note'),
        operatorNote: _.get(this.state.data, 'request.operatorNote'),
        bookingReference: this.state.data?.externalInfo?.bookingReference || '',
        clientCaseMatter: _.get(
          this.state.data,
          'corporateInfo.clientCaseMatter'
        ),
        chargeCode: _.get(this.state.data, 'corporateInfo.chargeCode'),
      }

      if (this.state.nameThirdParty === thirdPartyIntegration.holidaytaxis) {
        const total = _.toNumber(this.state.data?.completedInfo?.total, 0)
        updateParams.etaFare = total
        updateParams.basicFare = total
      }

      this.props.bookingDetailActions
        .updateFinishedBooking(updateParams)
        .then((res) => {
          this.handleResultUpdateFinishedBooking(res)
        })
    } else {
      const object = this.updateBookingRequestObjectBuilder(
        false,
        this.state.forceAssign
      )
      if (object) {
        if (hasRecurring) {
          object.editAll = isEditAll
          if (
            object.editAll &&
            this.state.promo &&
            this.state.promo.value > 0
          ) {
            const result = await this.checkValidPromoCode(true)
            if (!this.handleErrorCheckPromoWithRecurring(result)) return
          }
        }
        if (
          this.state.data.status == 'engaged' ||
          this.state.data.status == 'droppedOff'
        ) {
          const changePaymentObject = {
            paymentType: object.request.paymentType,
            bookId: object.bookId,
            fleetId: this.props.auth.selectedFleet.fleetId,
            operator: object.operator,
            notes: _.get(this.state.data, 'request.note'),
            operatorNote: _.get(this.state.data, 'request.operatorNote'),
            clientCaseMatter: _.get(
              this.state.data,
              'corporateInfo.clientCaseMatter'
            ),
            chargeCode: _.get(this.state.data, 'corporateInfo.chargeCode'),
          }
          if (object.request.paymentType == paymentMethodNumber.corporateCard) {
            changePaymentObject.creditInfo = object.corporateInfo.creditInfo
          }
          if (object.request.paymentType == paymentMethodNumber.personalCard) {
            changePaymentObject.creditInfo = object.psgInfo.creditInfo
          }
          this.props.bookingDetailActions
            .changePaymentTypeBooking(changePaymentObject)
            .then((res) => {
              this.handleResultUpdateBooking(res)
            })
        } else {
          this.props.bookingDetailActions.updateBooking(object).then((res) => {
            this.handleResultUpdateBooking(res)
          })
        }
        this.setState({
          showConfirmUpdate: !this.state.showConfirmUpdate,
          forceAssign: false,
        })
        this.props.loadingActions.showLoadingSpiner()
      }
    }
  }

  updateInfoBookingClick() {
    let { batchId } = this.state.data

    // if book recurring then show modal edit all?
    if (batchId) {
      this.setState({
        showConfirmRecurring: true,
        dataConfirmRecurring: {
          title: 'Update recurring order',
          content:
            'Do you only want to update this order or update this and all future orders?',
          funcHandleConfirmRecurring: this.updateInfoBookingConfirmed,
        },
      })
    } else {
      this.updateInfoBookingConfirmed()
    }
  }

  updateInfoAndDispatchBookingConfirmed = async (
    hasRecurring = false,
    isEditAll = false
  ) => {
    const object = this.updateBookingRequestObjectBuilder(
      true,
      this.state.forceAssign
    )
    if (hasRecurring) {
      object.editAll = isEditAll
      if (object.editAll && this.state.promo && this.state.promo.value > 0) {
        const result = await this.checkValidPromoCode(true)
        if (!this.handleErrorCheckPromoWithRecurring(result)) return
      }
    }
    if (object) {
      this.setState({ retryDispatch: true, forceAssign: false })
      this.props.bookingDetailActions.updateBooking(object).then((res) => {
        this.handleResultUpdateBooking(res)
      })
      this.props.loadingActions.showLoadingSpiner()
    }
  }

  updateInfoAndDispatchBookingClick() {
    let { batchId } = this.state.data

    if (batchId) {
      this.setState({
        showConfirmRecurring: true,
        dataConfirmRecurring: {
          title: 'Update recurring order',
          content:
            'Do you only want to update this order or update this and all future orders?',
          funcHandleConfirmRecurring:
            this.updateInfoAndDispatchBookingConfirmed,
        },
      })
    } else {
      this.updateInfoAndDispatchBookingConfirmed()
    }
  }

  handleDriverCalendarClick(driver) {
    const fromDate = moment(this.state.data.request.pickUpTime).add(-1, 'days')
    const toDate = moment(this.state.data.request.pickUpTime).add(1, 'days')
    this.props.newbookingActions
      .getDriverReservationBooking({
        fleetId: this.props.auth.selectedFleet.fleetId,
        driverId: driver.driver._id,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.state.driverReservationBookings = data.res
          this.state.driverCalendarViewing = driver
          this.setState({ isShowDriverCalendar: true })
        } else {
        }
      })
  }

  handleCloseDriverCalendarClick() {
    this.setState({
      isShowDriverCalendar: false,
      driverReservationBookings: [],
      driverReservationDatePriview: 0,
      driverCalendarViewing: null,
    })
  }

  handleDriverReservationBookingDateChange(num) {
    this.setState({
      driverReservationDatePriview:
        this.state.driverReservationDatePriview + num,
    })
  }

  completeWithPaymentButtonClick = (e) => {
    const paymentMethod = _.get(this.state.data, 'request.paymentType', 0)
    if (!parseInt(paymentMethod)) {
      return this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_payment_method')
      )
    }

    if (e) e.preventDefault()
    if (this.state.showConfirmComplete) {
      if (this.state.paymentStep == 0) {
        this.props.bookingDetailActions
          .getBookingDetail(
            this.state.data.bookId,
            !this.isActiveBooking(this.state.data)
          )
          .then((data) => {
            if (data.ok && data.res) {
              this.componentGetDataFromProp(data.res)
            } else {
              this.setState(
                {
                  showConfirmComplete: false,
                  paymentStep: 0,
                  data: _.cloneDeep(this.state.dataTemp),
                },
                () => {
                  this.componentGetDataFromProp(this.state.dataTemp)
                }
              )
            }
          })
      } else {
        this.setState({
          paymentStep: 0,
          taxEdited: false,
        })
      }
    } else {
      if (
        this.state.data.request.destination &&
        this.state.data.request.destination.address.trim().length == 0 &&
        this.state.data.request.destination.geo.length == 2
      ) {
        const latLng = {
          lat: this.state.data.request.destination.geo[1],
          lng: this.state.data.request.destination.geo[0],
        }
        this.handleDestinationDrapEnd({ latLng })
      }
      this.props.etaFareActions
        .getRushHourFee({
          bookId: this.state.data.bookId,
          fleetId: this.state.data.request.psgFleetId,
        })
        .then((data) => {
          if (data.ok && data.res) {
            this.state.rushHour = data.res.response
          }
        })
      if (
        this.state.data.dispatch3rd &&
        this.state.isCompleteWithPaymentShow3rdBooking
      ) {
        let paymentType = 1
        if (this.state.data.travelerType) {
          paymentType = 7
        }
        if (
          this.state.data.request.paymentType == 9 &&
          this.state.data.status == 'droppedOff'
        ) {
          paymentType = 10
        }
        this.setState({
          showConfirmComplete: true,
          retryPaymentMethod: paymentType,
        })
      } else {
        this.state.paymentComplete.paymentType = this.forcePaymentTypeToCash()
        if (
          !this.state.locationDestination ||
          !this.state.locationDestination.lat ||
          this.state.locationDestination.address.length == 0
        ) {
          this.getFareSettings()
        } else {
          this.getDirectionAndDraw((response) => {
            if (
              this.props.commonData.location &&
              this.props.commonData.location.isChina
            ) {
              this.setState(
                {
                  directions: response.overviewPolyline,
                  disDur: {
                    distance: {
                      value: response.distanceValue,
                      text: response.distance
                    },
                    duration: {
                      value: response.estimateValue,
                      text: response.time
                    }
                  },
                },
                () => {
                  if (
                    this.state.isHydraBooking ||
                    this.state?.data?.request?.estimate?.isFareEdited
                  ) {
                    this.getFareSettings()
                  } else {
                    this.etaFareCalculator(() => {
                      this.getFareSettings()
                    }, 1)
                  }
                }
              )
            } else {
              this.setState(
                {
                  directions: response.overviewPolyline,
                  disDur: {
                    distance: {
                      value: response.distanceValue,
                      text: response.distance
                    },
                    duration: {
                      value: response.estimateValue,
                      text: response.time
                    }
                  },
                },
                () => {
                  if (
                    this.state.data.request &&
                    this.state.data.request.estimate &&
                    this.state.data.request.estimate.isFareEdited
                  ) {
                    this.getFareSettings()
                  } else {
                    if (this.state.data.status == 'droppedOff') {
                      const droppedOffInfo = _.get(
                        this.state.data,
                        'droppedOffInfo',
                        {}
                      )
                      this.state.data.request.estimate.fare = {
                        ...this.state.data.request.estimate.fare,
                        creditTransactionFee:
                          droppedOffInfo.creditTransactionFee,
                        basicFare: droppedOffInfo.fare,
                        otherFees: droppedOffInfo.otherFees,
                        extraWaitFee: droppedOffInfo?.extraWaitFee || 0,
                        subTotal: droppedOffInfo.subTotal,
                        airportFee: droppedOffInfo.airportSurcharge,
                        techFee: droppedOffInfo.techFee,
                        tip: droppedOffInfo.tip,
                        tollFee: droppedOffInfo.tollFee,
                        gasFee: droppedOffInfo.gasFee,
                        startingFee: droppedOffInfo.startingFee || 0,
                        moveFeeInJourney: droppedOffInfo.moveFeeInJourney || 0,
                        waitFeeInJourney: droppedOffInfo.waitFeeInJourney || 0,
                        parkingFee: droppedOffInfo.parkingFee,
                        total: droppedOffInfo.total,
                        minFare: droppedOffInfo.isMinimum
                          ? droppedOffInfo.minimum
                          : 0,
                        isMinimum: droppedOffInfo.isMinimum,
                      }
                      if (this.state.showConfirmComplete) {
                        this.paymentCompleteSubtotalCalculator()
                      }
                      this.getFareSettings()
                    } else {
                      this.etaFareCalculator(() => {
                        this.getFareSettings()
                      }, 1)
                    }
                  }
                }
              )
            }
          }, true)
        }
      }
    }
  }

  getFareSettings(action = 'withPayment') {
    const { data = {} } = this.state
    const zoneId = this.state.zonePickup?._id
    const fleetId = this.state.farmIn ? data.fleetId : data.request.psgFleetId
    const ob = { fleetId: fleetId, zoneId }
    this.props.bookingDetailActions.getFleetFareSetting(ob).then((data) => {
      if (data.ok && data.res) {
        const newState = {
          paymentStep: 0,
          fareSettings: data.res,
          dataTemp: _.cloneDeep(this.state.data),
        }
        if (action === 'withoutService') {
          newState.showConfirmCompleteWithoutService = true
        } else {
          newState.showConfirmComplete = true
        }
        this.setState(newState, this.paymentCompleteSubtotalCalculator)
      } else {
        this.context.notification(
          'error',
          I18n.t('bookingdetail.Can_not_get_fare_setting')
        )
      }
    })
  }

  isBookingFromPaxApp = (booking = {}) => {
    let bookFrom = this.props.commonData.bookingFrom.filter(
      (a) => a.value != 'PaxApp'
    )
    bookFrom = bookFrom.find(
      (a) => a.value == booking.bookFrom || a.label == booking.bookFrom
    )
    if (bookFrom) {
      return true
    }
    return false
  }

  completeWithPaymentOkButtonClick(
    e,
    tollFeeHydra = 0,
    parkingFeeHydra = 0,
    gasFeeHydra = 0
  ) {
    const { farmIn, isHydraBooking } = this.state
    e.preventDefault()
    let paymentObject = {}
    if (this.state.disableCompletePaymentNextClick) return false

    if (farmIn) {
      return this.handleCompleteBookingAffiliate(
        tollFeeHydra,
        parkingFeeHydra,
        gasFeeHydra
      )
    }
    if (this.state.paymentStep == 0) {
      const userId = this.state.data.mDispatcherInfo
        ? this.state.data.mDispatcherInfo.userId || ''
        : ''
      const corporateId = this.state.data.corporateInfo
        ? this.state.data.corporateInfo.corporateId || ''
        : ''
      if (userId || corporateId) {
        this.props.bookingDetailActions
          .getCommission({
            fleetId: this.props.auth.selectedFleet.fleetId,
            userId,
            corporateId,
          })
          .then((data) => {
            if (data.ok && data.res && data.res.res) {
              this.state.paymentStep = 1
              this.state.commisson = data.res.res
              // data.res.map(fee=>{
              //     if(fee.currencyISO==this.state.data.currencyISO){
              //         this.state.commisson = fee;
              //     }
              // });
              this.setState(this.state, this.paymentCompleteTotalCalculator)
            } else {
              this.context.notification(
                'error',
                I18n.t('bookingdetail.Can_not_check_commisson')
              )
            }
          })
      } else {
        this.state.paymentStep = 1
        this.state.commisson = null
        this.setState(this.state, this.paymentCompleteTotalCalculator)
      }
    } else {
      const estimateFare = isHydraBooking
        ? this.state.data.request.estimate.fare.estimateFareBuy || {}
        : this.state.data.request.estimate.fare || {}
      const surchargeTime = parseFloat(
        this.state.paymentComplete.rushHourChecked && this.state.rushHour
          ? this.state.rushHour.surchargeType === 'amount'
            ? this.state.rushHour.surchargeFee
            : (this.state.rushHour.surchargeFee * estimateFare.basicFare) / 100
          : 0
      )
      const dynamicSurchargeAmount = this.state.paymentComplete.rushHourChecked
        ? this.getDynamicSurchargeAmount(estimateFare)
        : 0

      const isFareEdited =
        this.state.data.request.estimate &&
        this.state.data.request.estimate.isFareEdited
          ? this.state.data.request.estimate.isFareEdited
          : false
      const rushHourFee =
        this.state.data.request.estimate &&
        this.state.data.request.estimate.fare &&
        this.state.data.request.estimate.fare.rushHourFee &&
        this.state.paymentComplete.rushHourChecked
          ? this.state.data.request.estimate.fare.rushHourFee
          : 0

      if (isFareEdited) {
        paymentObject = {
          address: this.state.locationDestination.address,
          geo: [
            this.state.locationDestination.lng,
            this.state.locationDestination.lat,
          ],
          zipCode: this.state.locationDestination.zipCode,
          bookId: this.state.data.bookId,
          total: parseFloat(estimateFare.etaFare || 0),
          fare: parseFloat(estimateFare.basicFare || 0),
          tip: parseFloat(estimateFare.tip || 0),
          tax: parseFloat(estimateFare.tax || 0),
          creditTransactionFee: parseFloat(estimateFare.creditTransactionFee),
          promoCode: estimateFare.promoCode,
          promoAmount: parseFloat(estimateFare.promoAmount || 0),
          heavyTraffic: 0,
          airportSurcharge: parseFloat(estimateFare.airportFee || 0),
          meetDriverFee: parseFloat(estimateFare.meetDriverFee || 0),
          rushHour: rushHourFee,
          dynamicSurcharge: 0,
          techFee: parseFloat(
            this.props.auth.selectedFleet.techFeeActive
              ? estimateFare.techFee
              : 0
          ),
          otherFees: parseFloat(estimateFare.otherFees || 0),
          extraWaitFee: parseFloat(estimateFare.extraWaitFee || 0),
          subTotal: parseFloat(estimateFare.subTotal || 0),
          partnerCommission: parseFloat(estimateFare.bookingFee || 0),
          paymentType: CCLiteCommonFunc.paymentMappingActiveToCompleted(
            this.state.paymentComplete.paymentType,
            this.state.isAllwayShowGoogleApplePay
          ),
          primaryPartialMethod:
            this.state.data.request.primaryPartialMethod || -1,
          isMinimum: 0,
          tollFee: parseFloat(estimateFare.tollFee || 0),
          gasFee: parseFloat(estimateFare.gasFee || 0),
          parkingFee: parseFloat(estimateFare.parkingFee || 0),
          dynamicFare: parseFloat(estimateFare.dynamicFare || 0),
          isPending: this.state.paymentComplete.isPending || false,
          isPartialPayment: this.state.paymentComplete.partialPayment || false,
          writeOffDebt: this.state.paymentComplete.writeOffDebt || false,
        }
      } else {
        paymentObject = {
          address: this.state.locationDestination.address,
          geo: [
            this.state.locationDestination.lng,
            this.state.locationDestination.lat,
          ],
          zipCode: this.state.locationDestination.zipCode,
          bookId: this.state.data.bookId,
          total: parseFloat(
            this.calculatorTotal(
              this.state.paymentComplete,
              this.state.promo
            ) || 0
          ),
          fare: parseFloat(estimateFare.basicFare || 0),
          tip: parseFloat(this.state.paymentComplete.tip || 0),
          tax: parseFloat(this.state.paymentComplete.tax || 0),
          promoCode: '',
          promoAmount: 0,
          heavyTraffic: 0,
          airportSurcharge: parseFloat(estimateFare.airportFee || 0),
          meetDriverFee: parseFloat(estimateFare.meetDriverFee || 0),
          rushHour: surchargeTime + dynamicSurchargeAmount,
          dynamicSurcharge: dynamicSurchargeAmount,
          techFee: parseFloat(
            this.props.auth.selectedFleet.techFeeActive
              ? estimateFare.techFee
              : 0
          ),
          otherFees: parseFloat(estimateFare.otherFees || 0),
          extraWaitFee: parseFloat(estimateFare.extraWaitFee || 0),
          subTotal: parseFloat(this.state.paymentComplete.subTotal || 0),
          partnerCommission: this.isBookingFromPaxApp(this.state.data)
            ? parseFloat(this.state.commissionValue || 0)
            : 0,
          paymentType: CCLiteCommonFunc.paymentMappingActiveToCompleted(
            this.state.paymentComplete.paymentType,
            this.state.isAllwayShowGoogleApplePay
          ),
          primaryPartialMethod:
            this.state.data.request.primaryPartialMethod || -1,
          isMinimum: parseFloat(this.state.paymentComplete.isMinFare ? 1 : 0),
          tollFee: parseFloat(estimateFare.tollFee || 0),
          gasFee: parseFloat(estimateFare.gasFee || 0),
          parkingFee: parseFloat(estimateFare.parkingFee || 0),
          dynamicFare: parseFloat(estimateFare.dynamicFare || 0),
          isPending: this.state.paymentComplete.isPending || false,
          isPartialPayment: this.state.paymentComplete.partialPayment || false,
          writeOffDebt: this.state.paymentComplete.writeOffDebt || false,
        }

        if (this.state.paymentComplete.creditTransactionFee >= 0) {
          paymentObject.creditTransactionFee = parseFloat(
            this.state.paymentComplete.creditTransactionFee
          )
        }

        if (
          this.state.paymentComplete.total >
            this.calculatorTotal(
              this.state.paymentComplete,
              this.state.promo
            ) &&
          this.state.promo
        ) {
          paymentObject.promoCode =
            this.state.promo.promo || this.state.promo.promotionCode
          paymentObject.promoAmount = parseFloat(
            this.calculatePromoAmount(
              this.state.paymentComplete,
              this.state.promo
            ) || 0
          )
        }

        if (this.state.paymentComplete.heavyTrafficChecked) {
          paymentObject.heavyTraffic += parseFloat(
            CCLiteCommonFunc.getValueOfMultiCurrencies(
              this.state.fareSettings.fare.heavyTrafficByCurrencies,
              this.state.data.currencyISO,
              'value'
            ) || 0
          )
        }
      }

      if (this.state.extraDestination && this.state.extraDestination.lat) {
        paymentObject.address = this.state.extraDestination.address
        paymentObject.geo = [
          this.state.extraDestination.lng,
          this.state.extraDestination.lat,
        ]
        paymentObject.zipCode = this.state.extraDestination.zipCode
      }
      let servicesData = _.get(this.state.data, 'request.services', [])
      let extraServices = _.get(this.state.data, 'extraServices', [])
      if (servicesData.length > 0 && extraServices.length > 0) {
        if (this.state.data.isNewSettingAdditionFee) {
          paymentObject.fleetServices = servicesData.map((fleetService) => {
            let infoSetting =
              extraServices.find(
                (sr) => sr.serviceId === fleetService.serviceId
              ) || {}
            return {
              ...fleetService,
              ...infoSetting.infoService,
            }
          })
        } else {
          paymentObject.services = this.state.data.request.services
        }
        paymentObject.serviceFee = calFleetORAdditionalServicePayment(
          this.state.data?.request?.services,
          this.state?.paymentComplete?.subTotal
        )
      }

      // send more distance tour if status is not droppedOff
      if (this.state.data.status !== 'droppedOff') {
        paymentObject.distanceTour = _.get(
          this.state.disDur,
          'distance.value',
          0
        )
      }

      paymentObject.fleetId = this.props.auth.selectedFleet.fleetId

      /* SL-9475 recheck valid promotion code */
      if (
        paymentObject.promoCode &&
        paymentObject.promoCode !== this.state.data.originPromo
      ) {
        return this.checkValidPromoCode().then((res) => {
          this.props.loadingActions.showLoadingSpiner()
          if (!res.returnCode) {
            this.context.notification(
              'error',
              I18n.t('bookingdetail.Check_promo_error')
            )
          }
          if (res.returnCode !== 200) {
            this.context.notification(
              'error',
              I18n.t(`messages.promoMsg.${res.returnCode}`)
            )
          } else {
            this.props.bookingDetailActions
              .completeBooking(paymentObject)
              .then((res) => {
                this.handleResultCompleteBooking(res)
              })
            return
          }
        })
      }
      if (this.state.isCompleteWithouservice) {
        ;(paymentObject.paymentType =
          convertPaymentTypeForCompleteWithoutService(
            this.state.paymentComplete.paymentType
          )),
          (paymentObject.completeWithoutService = true)
        this.props.bookingDetailActions
          .overrideCustomerWithoutService(paymentObject)
          .then((res) => {
            if (res && res.ok) {
              if (res?.receipt?.returnCode === 517) {
                return this.context.notification(
                  'error',
                  'The booking cannot be completed!'
                )
              }
              this.context.notification(
                'success',
                'Booking has been completed successfully'
              )
              this.close()
            } else {
              this.context.notification('error', 'Can not complete booking')
            }
          })
          .catch((err) => {
            this.context.notification(
              'error',
              'Can not complete booking (Catch)'
            )
          })
      } else {
        /**
         * Log promotion code in use [Payment -> Report]
         * https://issues.qup.vn/browse/SL-8851 Fix bug duplicate add promotionCode
         */
        this.props.bookingDetailActions
          .completeBooking(paymentObject)
          .then((res) => {
            this.handleResultCompleteBooking(res)
          })
          .catch((err) => {
            this.context.notification(
              'error',
              'Can not complete booking (Catch)'
            )
          })
        this.props.loadingActions.showLoadingSpiner()
      }
    }
  }

  checkValidPromoCode = (isCheckPromoWithRecurring = false) => {
    const { data, customer, locationPickUp } = this.state
    const {
      auth: { selectedFleet },
      newbookingActions,
      bookingDetail,
    } = this.props
    const jobType = _.get(bookingDetail, 'data.jobType', '')
    const localNow = moment().tz(data.request.pickup.timezone)
    const timeWithoutZone = moment(data.request.pickUpTime).format(
      'YYYY-MM-DD HH:mm'
    )
    const localPickup = moment
      .tz(timeWithoutZone, data.request.pickup.timezone)
      .format()
    const pickupTime = data.request.pickUpTime == 'Now' ? localNow : localPickup
    const paxAppVersion = _.get(data, 'psgInfo.rv', '')
    const promotionObject = {
      fleetId: selectedFleet.fleetId,
      pickupTime: moment.utc(pickupTime).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
      promotionCode: data.request.promo,
      userId: customer.userId,
      bookFrom: data.bookFrom,
      currencyISO: data.currencyISO,
      geo: [locationPickUp.lng, locationPickUp.lat],
      serviceType: jobType === 'shuttle' ? 5 : 0, // 0: transport, 1: intercity, 2: parcel, 3: food, 4: mart
      etaFare:
        data.request.estimate.fare && data.request.estimate.fare.etaFare
          ? data.request.estimate.fare.etaFare
          : 0,
      rv: paxAppVersion,
      paymentType: data.request.paymentType,
    }
    if (isCheckPromoWithRecurring) {
      promotionObject.recurring = {
        mode: 'recurring',
        pickupTime: this.state.recurringTime,
        selectedDates: this.state.recurringDays.map((dateOb) =>
          moment(dateOb).format('YYYY-MM-DD')
        ),
      }
    }
    return newbookingActions.checkpromoCode(promotionObject)
  }

  completeWithPaymentCloseButtonClick() {
    if (this.state.isCompleteWithPaymentShow3rdBooking) {
      this.setState({
        showConfirmComplete: false,
        paymentStep: 0,
        isSubmited: false,
        isCompleteWithouservice: false,
      })
      this.props.bookingDetailActions.bookingDetailClosed()
    } else {
      this.setState({
        showConfirmComplete: false,
        paymentStep: 0,
        isSubmited: false,
        isCompleteWithouservice: false,
      })
      this.props.bookingDetailActions
        .getBookingDetail(
          this.state.data.bookId,
          !this.isActiveBooking(this.state.data)
        )
        .then((data) => {
          if (data.ok && data.res) {
            this.componentGetDataFromProp(data.res)
          }
        })
    }
  }

  completeWithoutServiceCloseButtonClick() {
    this.setState({
      showConfirmCompleteWithoutService: false,
    })
  }

  handleCompleteBookingAffiliate = (
    tollFeeHydra = 0,
    parkingFeeHydra = 0,
    gasFeeHydra = 0
  ) => {
    const fareHydra = _.get(this.state.data, 'request.estimate.fare', {})
    const { locationDestination, data } = this.state
    const tollFeeHydraParse = !isNaN(parseFloat(tollFeeHydra))
      ? parseFloat(tollFeeHydra)
      : 0
    const parkingFeeHydraParse = !isNaN(parseFloat(parkingFeeHydra))
      ? parseFloat(parkingFeeHydra)
      : 0
    const gasFeeHydraParse = !isNaN(parseFloat(gasFeeHydra))
      ? parseFloat(gasFeeHydra)
      : 0
    let totalAffiliate = fareHydra.qupSellPrice
    if (tollFeeHydraParse) {
      totalAffiliate += tollFeeHydraParse
    }
    if (parkingFeeHydraParse) {
      totalAffiliate += parkingFeeHydraParse
    }
    if (gasFeeHydraParse) {
      totalAffiliate += gasFeeHydraParse
    }
    const paymentObject = {
      address: locationDestination.address,
      geo: [locationDestination.lng, locationDestination.lat],
      zipCode: locationDestination.zipCode,
      bookId: data.bookId,
      fleetId: this.props.auth.selectedFleet.fleetId,
      ...fareHydra,
      total: fareHydra.qupSellPrice,
      tollFee: tollFeeHydraParse,
      gasFee: gasFeeHydraParse,
      parkingFee: parkingFeeHydraParse,
      totalAffiliate: totalAffiliate,
      isPending: false,
      paymentType: CCLiteCommonFunc.paymentMappingActiveToCompleted(
        this.state.paymentComplete.paymentType
      ),
    }
    // const subTotal =
    //   paymentObject.fare + paymentObject.rushHour + paymentObject.otherFees;
    // paymentObject.subTotal = subTotal;

    if (this.state.extraDestination && this.state.extraDestination.lat) {
      paymentObject.address = this.state.extraDestination.address
      paymentObject.geo = [
        this.state.extraDestination.lng,
        this.state.extraDestination.lat,
      ]
      paymentObject.zipCode = this.state.extraDestination.zipCode
    }

    /**
     * Log promotion code in use [Payment -> Report]
     * https://issues.qup.vn/browse/SL-8851 Fix bug duplicate add promotionCode
     */
    this.props.bookingDetailActions
      .completeBooking(paymentObject)
      .then((res) => {
        this.handleResultCompleteBooking(res)
      })
    this.props.loadingActions.showLoadingSpiner()
  }

  handleResultCompleteBooking = (data) => {
    this.props.loadingActions.hideLoadingSpiner()
    if (data.returnCode == 200) {
      this.props.bookingDetailActions.bookingDetailClosed()
    } else {
      var msg = ''
      if (
        data.response &&
        data.response.response &&
        data.response.response.message
      ) {
        msg += ' (' + data.response.response.message + ' )'
      }

      const errorCode = _.get(data, 'response.returnCode')
      if (errorCode && [521, 113].includes(errorCode)) {
        msg = '(' + I18n.t(`errors.completeBooking.${errorCode}`) + ')'
      }

      this.context.notification(
        'error',
        I18n.t('messages.booking.complete_fail'),
        msg
      )
    }
  }

  forcePaymentTypeToCash = () => {
    const requestPaymentType = _.get(this.state.data, 'request.paymentType', 1)
    if (
      !this.state.isAllwayShowGoogleApplePay &&
      ((requestPaymentType == 9 && this.state.data.status != 'droppedOff') ||
        requestPaymentType == 21)
    ) {
      return 1
    }
    return requestPaymentType
  }

  completeWithoutServiceWith3rdBooking = (e) => {
    const { isHydraBooking } = this.state
    const estimateFare = isHydraBooking
      ? this.state.data.request.estimate.fare.estimateFareBuy || {}
      : this.state.data.request.estimate.fare || {}
    const paymentObject = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      address: this.state.locationDestination.address,
      geo: [
        this.state.locationDestination.lng,
        this.state.locationDestination.lat,
      ],
      zipCode: this.state.locationDestination.zipCode,
      bookId: this.state.data.bookId,
      total: estimateFare.etaFare,
      fare: parseFloat(estimateFare.basicFare || 0),
      tip: parseFloat(this.state.data.request.tip || 0),
      tax: 0,
      promoCode: '',
      promoAmount: 0,
      heavyTraffic: 0,
      airportSurcharge: parseFloat(estimateFare.airportFee || 0),
      meetDriverFee: parseFloat(estimateFare.meetDriverFee || 0),
      rushHour: estimateFare.rushHourFee,
      dynamicSurcharge: estimateFare.dynamicSurcharge,
      techFee: parseFloat(
        this.props.auth.selectedFleet.techFeeActive ? estimateFare.techFee : 0
      ),
      otherFees: parseFloat(estimateFare.otherFees || 0),
      extraWaitFee: parseFloat(estimateFare.extraWaitFee || 0),
      subTotal: parseFloat(estimateFare.subTotal || 0),
      partnerCommission: this.isBookingFromPaxApp(this.state.data)
        ? parseFloat(this.state.commissionValue || 0)
        : 0,
      paymentType: convertPaymentTypeForCompleteWithoutService(
        this.state.data.request.paymentType
      ),
      isMinimum: parseFloat(estimateFare.isMinimumTotal ? 1 : 0),
      tollFee: parseFloat(estimateFare.tollFee || 0),
      gasFee: parseFloat(estimateFare.gasFee || 0),
      parkingFee: parseFloat(estimateFare.parkingFee || 0),
      dynamicFare: parseFloat(estimateFare.dynamicFare || 0),
      isPending: false,
      completeWithoutService: true,
    }
    this.props.bookingDetailActions
      .overrideCustomerWithoutService(paymentObject)
      .then((res) => {
        if (res && res.ok) {
          this.context.notification(
            'success',
            'Booking has been completed successfully'
          )
          this.close()
        } else {
          this.context.notification('error', 'Can not complete booking')
        }
      })
  }

  completeWithoutServiceButtonClick = (e) => {
    // 3rdBooking when charge full fare
    if (this.state.is3rdBooking) {
      this.completeWithoutServiceWith3rdBooking()
    } else {
      // other booking then same complete with driver
      this.setState(
        {
          isCompleteWithouservice: true,
        },
        this.completeWithPaymentButtonClick
      )
    }
  }

  incidentButtonClick() {
    this.setState({
      showConfirmIncident: !this.state.showConfirmIncident,
      incidentReason: '',
    })
  }

  incidentOkButtonClick() {
    const dataRequest = {
      bookId: this.state.data.bookId,
      reason: this.state.incidentReason,
      fleetId: this.props.auth.selectedFleet.fleetId,
      operator: {
        userId: this.props.auth.user._id,
        name: `${this.props.auth.user.firstName || ''} ${
          this.props.auth.user.lastName || ''
        }`,
      },
    }
    this.props.loadingActions.showLoadingSpiner()
    this.props.bookingDetailActions.incidentBooking(dataRequest).then((res) => {
      this.props.loadingActions.hideLoadingSpiner()
      if (res.returnCode == 200) {
        this.props.bookingDetailActions.bookingDetailClosed()
      } else {
        this.context.notification(
          'error',
          I18n.t('messages.booking.incident_fail')
        )
      }
    })
  }

  cancelBooking() {}

  reOrderPuPoint = (listOrder) => {
    let pointOrdered = []
    // set order methoad of Point
    if (listOrder.length > 0) {
      pointOrdered = listOrder.map((point, id) => {
        point.order = id + 1
        return point
      })
    }
    return pointOrdered
  }

  reOrderDoPoint = (puPoints, doPoints) => {
    let pointOrdered = []
    if (doPoints.length > 0) {
      pointOrdered = doPoints.map((point, id) => {
        point.order = puPoints.length + id + 1
        return point
      })
    }
    return pointOrdered
  }

  reOrderPointToSubmit = (points = []) => {
    if (points && points.length === 0) return []
    return points.map((ob, index) => {
      return {
        ...ob,
        order: index + 1,
      }
    })
  }

  setNewDoPuPointAddTraveler = (puPoints, doPoints) => {
    this.setState({
      data: {
        ...this.state.data,
        puPoints: puPoints,
        doPoints: doPoints,
      },
    })
  }

  filterPointEmpty = (points = []) => {
    if (points && points.length === 0) return []
    return points.filter((ob) => !_.isEmpty(_.get(ob, 'address.address', '')))
  }

  checkForceDispatching = (supportLocalBooking, supportHydraBooking) => {
    const user = this.props.auth && this.props.auth.user
    const { dispatchType = 0 } = this.state.data
    // Nếu user là corporate thì ưu tiên book local
    if (user.userType === userType.CorporateAdmin && supportLocalBooking) {
      return 0
    }
    if (
      (!supportLocalBooking && !supportHydraBooking) ||
      (!supportHydraBooking && this.state.isHydraBooking)
    )
      return 0
    if (
      (!supportLocalBooking && supportHydraBooking) ||
      (supportHydraBooking && this.state.isHydraBooking)
    )
      return -1
    return dispatchType
  }

  setNewPuPoint = (newPoints = []) => {
    let hasEmptyPoint = newPoints.some((ob) =>
      _.isEmpty(_.get(ob, 'address.address', ''))
    )
    let { doPoints } = this.state.data
    if (
      newPoints.length > 0 &&
      !(newPoints.length === 1 && hasEmptyPoint) &&
      newPoints.some((ob) => !_.isEmpty(_.get(ob, 'address.address', '')))
    ) {
      let firstPuPoint = newPoints[0]
      if (hasEmptyPoint) {
        let newPointsNoEmpty = this.filterPointEmpty(newPoints)
        firstPuPoint = newPointsNoEmpty[0]
      }
      const dispatchType = this.checkForceDispatching(
        firstPuPoint.supportLocalBooking,
        firstPuPoint.supportHydraBooking
      )
      this.setState(
        {
          mapCenter: firstPuPoint.address,
          locationPickUp: firstPuPoint.address,
          supportHydraBooking: firstPuPoint.supportHydraBooking || false,
          supportLocalBooking: firstPuPoint.supportLocalBooking || false,
          isHydraBooking: dispatchType === -1 ? true : false,
          data: {
            ...this.state.data,
            puPoints: this.reOrderPuPoint(newPoints),
            doPoints: this.reOrderDoPoint(newPoints, doPoints),
            dispatchType: dispatchType,
          },
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            const { supplierId, cartypeSelected } = this.state
            const { newBooking } = this.props
            let newCartype = {}
            let newSupplierId = ''
            let vehicleType = _.get(
              newBooking,
              'locationService.vehicleType',
              []
            )
            if (this.state.isHydraBooking) {
              vehicleType = _.get(
                newBooking,
                'locationService.affiliateService.vehicleTypeHydraSplited'
              )
            }

            const notShowCarTypeCCWithoutRate = _.get(
              this.props.auth,
              'selectedFleet.generalSetting.notShowCarTypeCCWithoutRate',
              false
            )
            if (notShowCarTypeCCWithoutRate) {
              const jobType = _.get(
                this.props.bookingDetail,
                'data.jobType',
                ''
              )
              vehicleType = filterCarTypeWithoutAssignRate(
                vehicleType,
                this.state.company,
                jobType === 'shuttle' ? true : false
              )
            }

            if (vehicleType.length > 0) {
              newCartype = vehicleType[0]
              if (cartypeSelected) {
                if (this.state.isHydraBooking) {
                  const fleetSupport = _.get(
                    newBooking,
                    'locationService.affiliateService.fleetSupport'
                  )
                  if (_.some(fleetSupport, (ob) => ob._id === supplierId)) {
                    newCartype = cartypeSelected
                  } else {
                    newCartype = vehicleType[0]
                    newSupplierId = newCartype.providerOfVhc.fleetId
                  }
                } else {
                  newCartype = vehicleType.filter(
                    (vh) => vh.vehicleType == cartypeSelected.vehicleType
                  )[0]
                }
              }

              this.setState(
                {
                  cartypeSelected: newCartype,
                  supplierId: newSupplierId ? newSupplierId : supplierId,
                  data: {
                    ...this.state.data,
                    carType:
                      newCartype && newCartype.vehicleType
                        ? newCartype.vehicleType
                        : null,
                  },
                },
                () => {
                  if (
                    newPoints.length > 1 ||
                    (doPoints.length > 0 && newPoints.length > 0)
                  ) {
                    this.getDirectionAndDraw()
                  } else {
                    this.etaFareCalculator()
                  }
                  this.updateExtraServiceForEta(data)
                  const newData = this.state.data
                  if (data?.res?.crossZone) {
                    this.state.data.paymentMethod = 1 // cash;
                    this.getCrossZoneBaseLocation().then(() => {
                      newData.request.tip = this.getDefaultTip()
                      this.setState({
                        data: newData,
                      })
                    })
                  } else {
                    newData.request.tip = this.getDefaultTip()
                    this.setState({
                      data: newData,
                    })
                  }
                }
              )
            }
          })
        }
      )
    } else {
      this.setState(
        {
          locationPickUp: null,
          addressPickUp: '',
          loadingPickUp: false,
          isCheckSupportLocation: false,
          data: {
            ...this.state.data,
            puPoints: this.reOrderPuPoint(newPoints),
            doPoints: this.reOrderDoPoint(newPoints, doPoints),
          },
        },
        () => {
          if (
            newPoints.length > 1 ||
            (doPoints.length > 0 && newPoints.length > 0)
          ) {
            this.getDirectionAndDraw()
          }
        }
      )
    }
  }

  handleSelectRecentPickup(location) {
    if (location) {
      this.setState(
        {
          locationPickUp: {
            lng: location.coordinates[0],
            lat: location.coordinates[1],
            address: location.address,
            from: locationType.google,
            city: location.city,
            zipCode: location.zipCode,
            countryCode: location.country,
            instructionLink: location.instructionLink || '',
            addressDetails: location.addressDetails,
          },
          isFormDirty: true,
        },
        () => {
          this.carTypeBaseLocation().then(() => {
            if (
              this.props.newBooking.locationService &&
              this.props.newBooking.locationService.vehicleType &&
              this.props.newBooking.locationService.vehicleType != []
            ) {
              if (this.state.cartypeSelected) {
                this.state.cartypeSelected =
                  this.props.newBooking.locationService.vehicleType.filter(
                    (vh) =>
                      vh.vehicleType == this.state.cartypeSelected.vehicleType
                  )[0]
              }
              if (!this.state.cartypeSelected) {
                this.state.cartypeSelected =
                  this.props.newBooking.locationService.vehicleType[0]
              }

              this.setState({
                data: {
                  ...this.state.data,
                  carType: this.state.cartypeSelected.vehicleType,
                },
              })
              this.carTypeBaseLocation().then((data) => {
                this.etaFareCalculator()
                this.updateExtraServiceForEta(data)
                if (data.res.crossZone) {
                  this.state.data.paymentMethod =
                    paymentMethodNumber.personalCard
                  this.getCrossZoneBaseLocation()
                }
              })
            }
          })
          this.getDirectionAndDraw()
        }
      )
    } else {
    }
  }

  handleSelectThirdPartyPickUp(address, data) {
    this.resetPackageHourlyOption()
    const cityCountry = data.location.city
    let cityName = ''
    if (cityCountry) {
      const citySplit = cityCountry.split('_')
      if (citySplit && citySplit.length >= 3) {
        if (citySplit[1]) {
          cityName = citySplit[1]
        } else if (citySplit[2]) {
          cityName = citySplit[2]
        }
      }
    }
    this.setState(
      {
        data: this.state.data,
        mapCenter:
          this.props.commonData.location &&
          this.props.commonData.location.isChina
            ? new window.qq.maps.LatLng(
                data.location.latitude,
                data.location.longitude
              )
            : { lat: data.location.latitude, lng: data.location.longitude },
        locationPickUp: {
          lng: data.location.coordinates[0],
          lat: data.location.coordinates[1],
          address: address,
          from: locationType.thirdParty,
          city: data.location.city,
          zipCode: data.location.zipCode,
          countryCode: data.location.countryCode,
          cityName,
          instructionLink: data.location.instructionLink,
        },
        isFormDirty: true,
      },
      () => {
        this.carTypeBaseLocation().then(() => {
          if (
            this.props.newBooking &&
            this.props.newBooking.locationService &&
            this.props.newBooking.locationService.vehicleType &&
            this.props.newBooking.locationService.vehicleType != []
          ) {
            if (this.state.cartypeSelected) {
              this.state.cartypeSelected =
                this.props.newBooking.locationService.vehicleType.filter(
                  (vh) =>
                    vh.vehicleType == this.state.cartypeSelected.vehicleType
                )[0]
            }
            if (!this.state.cartypeSelected) {
              this.state.cartypeSelected =
                this.props.newBooking.locationService.vehicleType[0]
            }

            this.setState({
              data: {
                ...this.state.data,
                carType: this.state.cartypeSelected.vehicleType,
              },
            })
            this.carTypeBaseLocation().then((data) => {
              this.etaFareCalculator()
              this.updateExtraServiceForEta(data)
              if (data.res.crossZone) {
                this.state.data.paymentMethod = paymentMethodNumber.personalCard
                this.getCrossZoneBaseLocation()
              }
            })
          }
        })
        this.getDirectionAndDraw()
      }
    )
  }

  handleChangePickUp(addressPickUp) {
    this.setState({
      locationPickUp: {
        ...this.state.locationPickUp,
        address: addressPickUp,
        from: locationType.google,
      },
      isFormDirty: true,
    })
    if (addressPickUp && addressPickUp.length > 0) {
    } else {
      this.handleAddressRemovedPickUp()
    }
  }

  handleChangeThirdPartyPickUp(addressPickUp) {
    if (addressPickUp && addressPickUp.length > 0) {
      this.setState({
        locationPickUp: {
          address: addressPickUp,
          from: locationType.thirdParty,
        },
        isFormDirty: true,
      })
    } else {
      this.handleAddressRemovedPickUp()
    }
  }

  handleAddressRemovedPickUp() {
    this.state.package = null
    this.state.data.packageRateId = null
    this.setState(
      {
        data: this.state.data,
        locationPickUp: null,
        isCheckSupportLocation: false,
        isFormDirty: true,
      },
      () => {
        this.etaFareCalculator()
        this.getDirectionAndDraw()
      }
    )
  }

  handleSelectRecentDestination(location) {
    this.resetPackageHourlyOption()
    if (location) {
      this.setState(
        {
          locationDestination: {
            lat: location.coordinates[1],
            lng: location.coordinates[0],
            zipCode: location.zipCode,
            city: location.city,
            from: locationType.google,
            address: location.address,
            countryCode: location.country,
            instructionLink: location.instructionLink || '',
            addressDetails: location.addressDetails,
          },
          isFormDirty: true,
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (data.ok) {
              this.updateExtraServiceForEta(data)
              if (data.res.crossZone) {
                this.getCrossZoneBaseLocation()
              }
            }
            this.etaFareCalculator()
          })
          this.getDirectionAndDraw()
        }
      )
    } else {
    }
  }

  handleSelectDestination = (
    addressDestination,
    placeId,
    pointOfInterest,
    sessionToken
  ) => {
    this.resetPackageHourlyOption()
    if (this.state.locationDestination) {
      this.state.locationDestination.address = addressDestination
    }
    const callback = (err, localtion, results = {}) => {
      if (err || !localtion) {
        return
      }
      let doPoints = (this.state.data && this.state.data.doPoints) || []
      let addressObj = localtion || {}
      const addressText = results.formatted_address
      addressObj.geo = [addressObj.lng, addressObj.lat]
      addressObj.from = 'gg'
      addressObj.address = addressText
      if (doPoints.length == 0) {
        this.setNewDoPoint([
          {
            address: addressObj,
            addressPointText: addressText,
            from: 'gg',
            id: new Date().getTime(),
            typePoint: 'Drop off point',
          },
        ])
      } else {
        doPoints[doPoints.length - 1].address = addressObj
        doPoints[doPoints.length - 1].addressPointText = addressText
        doPoints[doPoints.length - 1].from = 'gg'
        this.setNewDoPoint(doPoints)
      }
    }
    geoPlaceDetailMapProvider({
        auth: this.props.auth,
        placeid: placeId,
        sessionToken,
        callback,
        language: this.props?.language,
        isTencent: this.state.isChina,
        bookId: this.state.data.bookId
    });
  };

  setNewDoPoint = (newPoints = []) => {
    let hasEmptyPoint = newPoints.some((ob) =>
      _.isEmpty(_.get(ob, 'address.address', ''))
    )

    let { puPoints } = this.state.data
    if (
      newPoints.length > 0 &&
      !(newPoints.length === 1 && hasEmptyPoint) &&
      newPoints.some((ob) => !_.isEmpty(_.get(ob, 'address.address', '')))
    ) {
      let lastPoPoint = newPoints[newPoints.length - 1]
      if (hasEmptyPoint) {
        let newPointsNoEmpty = this.filterPointEmpty(newPoints)
        lastPoPoint = newPointsNoEmpty[newPointsNoEmpty.length - 1]
      }
      this.setState(
        {
          mapCenter: lastPoPoint.address,
          // locationDestination: Object.assign(this.state.locationDestination, lastPoPoint.address)
          locationDestination: lastPoPoint.address,
          disableCompletePaymentNextClick: true,
          isFormDirty: true,
          data: {
            ...this.state.data,
            doPoints: this.reOrderDoPoint(puPoints, newPoints),
          },
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (data.ok) {
              this.updateExtraServiceForEta(data)
              if (data.res.crossZone) {
                this.getCrossZoneBaseLocation()
              }
            }
            this.getDirectionAndDraw()
          })
        }
      )
    } else {
      this.setState(
        {
          locationDestination: null,
          disableCompletePaymentNextClick: true,
          isFormDirty: true,
          data: {
            ...this.state.data,
            doPoints: this.reOrderDoPoint(puPoints, newPoints),
          },
        },
        () => {
          if (
            newPoints.length > 1 ||
            (puPoints.length > 0 && newPoints.length > 0)
          ) {
            this.getDirectionAndDraw()
          }
        }
      )
    }
  }

  handleSelectThirdPartyDestination(addressDestination, data) {
    this.resetPackageHourlyOption()
    this.setState(
      {
        data: this.state.data,
        mapCenter:
          this.props.commonData.location &&
          this.props.commonData.location.isChina
            ? new window.qq.maps.LatLng(
                data.location.coordinates[0],
                data.location.coordinates[1]
              )
            : {
                lng: data.location.coordinates[0],
                lat: data.location.coordinates[1],
              },
        locationDestination: {
          lat: data.location.coordinates[1],
          lng: data.location.coordinates[0],
          zipCode: data.location.zipCode,
          city: data.location.city,
          address: addressDestination,
          from: locationType.thirdParty,
          countryCode: data.location.countryCode,
          instructionLink: data.location.instructionLink,
        },
        isFormDirty: true,
      },
      () => {
        this.carTypeBaseLocation().then((data) => {
          if (data && data.ok && data.res) {
            this.updateExtraServiceForEta(data)
            if (data.res.crossZone) {
              this.getCrossZoneBaseLocation()
            }
          }
          this.getDirectionAndDraw()
        })
      }
    )
  }

  handleChangeDestination(addressDestination) {
    if (!addressDestination || addressDestination.length == 0) {
      this.handleAddressRemovedDestination()
    } else {
      if (!this.state.locationDestination) {
        this.state.locationDestination = {}
      }
      this.state.locationDestination.address = addressDestination
      this.state.locationDestination.from = locationType.google
      this.setState({ data: this.state.data, isFormDirty: true })
    }
  }

  handleChangeThirdPartyDestination(addressDestination) {
    if (!addressDestination || addressDestination.length == 0) {
      this.handleAddressRemovedDestination()
    } else {
      if (!this.state.locationDestination) {
        this.state.locationDestination = {}
      }
      this.state.locationDestination.address = addressDestination
      this.state.locationDestination.from = locationType.thirdParty
      this.setState({ data: this.state.data, isFormDirty: true })
    }
  }

  handleAddressRemovedDestination() {
    this.resetPackageHourlyOption()
    this.state.locationDestination = null
    if (this.state.extraDestination) {
      this.state.extraDestination = null
    }
    this.state.isShowedExtraDestination = false
    this.state.paymentComplete = {
      rushHourChecked: false,
      heavyTrafficChecked: false,
      tax: 0,
      subTotal: 0,
      total: 0,
      isMinFare: false,
      minFare: 0,
      paymentWithoutPromo: 0,
      paymentType: 0,
    }
    this.state.disDur = null
    this.state.directions = null
    this.setState({ data: this.state.data, isFormDirty: true }, () => {
      this.carTypeBaseLocation().then((data) => {
        if (data && data.ok && data.res) {
          if (data.res.crossZone) {
            this.getCrossZoneBaseLocation()
          }
        }
      })
      this.etaFareCalculator()
    })
  }

  handleAddExtraDestinationButtunClick() {
    if (!this.state.extraDestination) {
      this.state.extraDestination = {}
    }
    this.setState({ isShowedExtraDestination: true })
  }

  handleSelectThirdPartyExtraDestination(address, data) {
    this.resetPackageHourlyOption()
    if (address && address !== '') {
      if (data && data.location) {
        this.setState(
          {
            center: {
              lat: data.location.coordinates[1],
              lng: data.location.coordinates[0],
            },
            extraDestination: {
              lat: data.location.coordinates[1],
              lng: data.location.coordinates[0],
              zipCode: data.location.zipCode,
              city: data.location.city,
              from: locationType.thirdParty,
              countryCode: data.location.countryCode,
              address,
              instructionLink: data.location.instructionLink,
            },
            data: {
              ...this.state.data,
              duration: false,
              roundTrip: false,
            },
            isFormDirty: true,
          },
          () => {
            this.carTypeBaseLocation().then((data) => {
              if (data.ok) {
                this.updateExtraServiceForEta(data)
                if (data.res.crossZone) {
                  this.getCrossZoneBaseLocation()
                }
              }
              this.getDirectionAndDraw()
            })
          }
        )
      }
    } else {
      this.setState({
        extraDestination: null,
        isFormDirty: true,
      })
    }
  }

  handleChangeThirdPartyExtraDestination(address) {
    if (address && address.length > 0) {
      this.state.extraDestination = {
        ...this.state.extraDestination,
        address,
        from: locationType.thirdParty,
      }
      this.setState({
        extraDestination: this.state.extraDestination,
        isFormDirty: true,
      })
    } else {
      this.handleAddressRemovedExtraDestination()
    }
  }

  handleChangeExtraDestination(address) {
    if (address && address.length > 0) {
      this.state.extraDestination = {
        ...this.state.extraDestination,
        address,
        from: locationType.google,
      }
      this.setState({
        extraDestination: this.state.extraDestination,
        isFormDirty: true,
      })
    } else {
      this.handleAddressRemovedExtraDestination()
    }
  }

  handleSelectRecentExtraDestination(location) {
    if (location) {
      this.resetPackageHourlyOption()
      this.setState(
        {
          center: {
            lat: location.coordinates[1],
            lng: location.coordinates[0],
          },
          extraDestination: {
            lat: location.coordinates[1],
            lng: location.coordinates[0],
            zipCode: location.zipCode,
            city: location.city,
            countryCode: location.country,
            from: locationType.google,
            address: location.address,
            instructionLink: location.instructionLink || '',
          },
          data: {
            ...this.state.data,
            duration: false,
            roundTrip: false,
          },
          isFormDirty: true,
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (data.ok) {
              this.updateExtraServiceForEta(data)
              if (data.res.crossZone) {
                this.getCrossZoneBaseLocation()
              }
            }
            this.getDirectionAndDraw()
          })
        }
      )
    } else {
    }
  }

  handleAddressRemovedExtraDestination() {
    this.resetPackageHourlyOption()
    if (this.state.extraDestination) {
      this.setState(
        {
          extraDestination: null,
          isFormDirty: true,
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (data.ok) {
              if (data.res.crossZone) {
                this.getCrossZoneBaseLocation()
              }
            }
            this.getDirectionAndDraw()
          })
        }
      )
    }
  }

  handleExtraDestinationDrapEnd(results) {
    getLocationWhenDrapEnd({
      results,
      callback: this.extraDestinationDrapEndCallback,
      auth: this.props.auth,
      language: this.props?.language,
      bookId: this.state.data.bookId,
      isTencent: this.props.commonData.location.isChina
    })
  }

  extraDestinationDrapEndCallback(place, location) {
    this.resetPackageHourlyOption()
    this.state.extraDestination = {
      ...location,
      address: place.formatted_address,
      from: locationType.google,
    }

    this.carTypeBaseLocation().then((data) => {
      if (data.ok) {
        if (data.res.crossZone) {
          this.getCrossZoneBaseLocation()
        }
      }
      this.getDirectionAndDraw()
    })
  }

  handlePickupDrapEnd(results, __, id) {
    getLocationWhenDrapEnd({
      results,
      id,
      callback: this.pickupDrapEndCallback,
      auth: this.props.auth,
      language: this.props?.language,
      bookId: this.state.data.bookId,
      isTencent: this.state.isChina
    })
  }

  pickupDrapEndCallback(place, localtion, id) {
    if (id || (this.state.isChina && localtion)) {
      this.checkZoneSupportedWithPointComponent({
        fleetId: this.props.auth.selectedFleet.fleetId,
        lat: localtion.lat,
        lng: localtion.lng,
      }).then((checkData) => {
        const res = checkData.res || {}
        this.dragEditPoint(
          place,
          localtion,
          id,
          this.state.data.puPoints,
          {
            supportHydraBooking: res.crossZone || false,
            supportLocalBooking: res.valid || false,
          },
          this.setNewPuPoint
        )
      })
    } else {
      this.state.locationPickUp = {
        address: place.formatted_address,
        from: locationType.google,
        addressDetails: localtion.addressDetails,
      }
      this.setState(
        {
          locationPickUp: Object.assign(this.state.locationPickUp, localtion),
          mapCenter: localtion,
        },
        () => {
          this.carTypeBaseLocation().then(() => {
            if (
              this.props.newBooking.locationService &&
              this.props.newBooking.locationService.vehicleType &&
              this.props.newBooking.locationService.vehicleType != []
            ) {
              if (this.state.cartypeSelected) {
                this.state.cartypeSelected =
                  this.props.newBooking.locationService.vehicleType.filter(
                    (vh) =>
                      vh.vehicleType == this.state.cartypeSelected.vehicleType
                  )[0]
              }
              if (!this.state.cartypeSelected) {
                this.state.cartypeSelected =
                  this.props.newBooking.locationService.vehicleType[0]
              }

              this.setState({
                data: {
                  ...this.state.data,
                  carType: this.state.cartypeSelected.vehicleType,
                },
              })
              this.carTypeBaseLocation().then((data) => {
                this.etaFareCalculator()
                const newData = this.state.data
                if (data.res.crossZone) {
                  this.state.data.paymentMethod =
                    paymentMethodNumber.personalCard
                  this.getCrossZoneBaseLocation().then(() => {
                    newData.request.tip = this.getDefaultTip()
                    this.setState({
                      data: newData,
                    })
                  })
                } else {
                  newData.request.tip = this.getDefaultTip()
                  this.setState({
                    data: newData,
                  })
                }
              })
            }
          })
          this.getDirectionAndDraw()
        }
      )
    }
  }

  dragEditPoint = (
    place = {},
    location = {},
    id,
    pointsCurrent,
    serviceSupport = {},
    callback
  ) => {
    let updatedPoint = [...pointsCurrent]
    let pointIndex = updatedPoint.findIndex((obj) => obj.id == id)
    location.geo = [location.lng, location.lat]
    location.address = place.formatted_address || ''
    if (this.state.isChina) {
      // neu la china chỉ support 1 point
      pointIndex = 0
      location.from = 'gg'
    }
    updatedPoint[pointIndex].address = location
    updatedPoint[pointIndex].addressPointText = place.formatted_address || ''
    if (!_.isEmpty(serviceSupport)) {
      updatedPoint[pointIndex].supportHydraBooking =
        serviceSupport.supportHydraBooking
      updatedPoint[pointIndex].supportLocalBooking =
        serviceSupport.supportLocalBooking
    }
    callback(updatedPoint)
  }

  handleDestinationDrapEnd(results, _, id) {
    getLocationWhenDrapEnd({
      results,
      id,
      callback: this.destinationDrapEndCallback,
      auth: this.props.auth,
      language: this.props?.language,
      bookId: this.state.data.bookId,
      isTencent: this.state.isChina,
    })
  }

  destinationDrapEndCallback(place, localtion, id) {
    this.resetPackageHourlyOption()
    if (id || (this.state.isChina && localtion)) {
      this.dragEditPoint(
        place,
        localtion,
        id,
        this.state.data.doPoints,
        {},
        this.setNewDoPoint
      )
    } else {
      this.state.locationDestination = Object.assign(
        {
          address: place.formatted_address,
          from: locationType.google,
          addressDetails: localtion.addressDetails,
        },
        localtion
      )
      this.carTypeBaseLocation().then((data) => {
        if (data.ok) {
          if (data.res.crossZone) {
            this.getCrossZoneBaseLocation()
          }
        }
      })
      this.getDirectionAndDraw()
    }
  }

  handleChangeDispatchType(e) {
    const { newBooking } = this.props
    const newDispatchType = parseInt(e.target.value)
    let {
      supplierVehicle,
      cartypeSelected,
      isHydraBooking: isHydraBookingNew,
      data = {},
      supplierId,
    } = this.state
    let isETA = false

    if (newDispatchType === -1) {
      // set cartype hydra đầu tiên trong list
      const vehicleTypeHydraSplited =
        _.get(
          newBooking,
          'locationService.affiliateService.vehicleTypeHydraSplited'
        ) || []
      isHydraBookingNew = true
      cartypeSelected = vehicleTypeHydraSplited[0] || {}
      supplierVehicle = _.get(
        vehicleTypeHydraSplited[0],
        'providerOfVhc.fleetId'
      )
      if (supplierId) {
        cartypeSelected = vehicleTypeHydraSplited.find(
          (car) => car.providerId.fleetId === supplierId
        )
        supplierVehicle = supplierId
      }
      isETA = true
    } else {
      // Đang ở hydra mà chuyển về  transport thì set cartype đầu tiên của transport
      if (this.state.isHydraBooking) {
        const vehicleTypeTransport =
          _.get(newBooking, 'locationService.vehicleType') || []
        cartypeSelected = vehicleTypeTransport[0] || {}
        supplierVehicle = ''
        isETA = true
      }
      isHydraBookingNew = false
    }
    const newVehicle = resetVehicleWhenChangeDispatchType({
      pickupTime: this.state.data?.request?.pickUpTime,
      currentVehicle: this.state.data?.vehicle
    })
    this.setState(
      {
        data: {
          ...this.state.data,
          fullName: '',
          dispatchType: newDispatchType,
          drvInfo: {},
          vehicle: newVehicle,
          resetCounterVehicleTextSearch: newVehicle.plateNumber
            ? this.state.resetCounterVehicleTextSearch
            : ++this.state.resetCounterVehicleTextSearch
        },
        isHydraBooking: isHydraBookingNew,
        cartypeSelected: cartypeSelected,
        isFormDirty: true,
        supplierVehicle: supplierVehicle,
        supplierId: '',
        isDetachDrv: false,
      },
      () => {
        if (isETA) {
          this.getDirectionAndDraw((response) => {
            this.setState(
              {
                directions: response.overviewPolyline,
                disDur: {
                  distance: {
                    value: response.distanceValue,
                    text: response.distance
                  },
                  duration: {
                    value: response.estimateValue,
                    text: response.time
                  }
                },
              },
              () => {
                this.carTypeBaseLocation().then(() => {
                  this.etaFareCalculator()
                })
              }
            )
          })
        }
      }
    )
  }

  handleChangePromo(e) {
    this.state.data.request.promo = (e.target.value || '').toUpperCase()
    this.setState({ data: this.state.data, isFormDirty: true })
  }

  handleChangePaxNumber = (value) => {
    value = parseInt(value || 0)
    this.state.data.request.paxNumber = value
    this.setState({ data: this.state.data, isFormDirty: true })
  }

  handleChangeLuggageNumber = (value) => {
    value = parseInt(value || 0)
    this.state.data.request.luggageNumber = value
    this.setState({ data: this.state.data, isFormDirty: true })
  }

  handleBookingRateChange(e) {
    if (e.target.value == 0) {
      this.resetPackageHourlyOption()
    } else {
      this.state.data.duration = true
      this.state.data.roundTrip = false
    }
    this.setState(
      {
        data: this.state.data,
        isFormDirty: true,
      },
      () => {
        this.getDirectionAndDraw()
        this.etaFareCalculator()
      }
    )
  }

  handleDriverChanged(e) {
    if (this.state.data.drvInfo) {
      this.state.data.drvInfo = {}
      this.state.data.vehicle = getVehicleWhenChangeDriver({
        oldVehicle: this.state.data?.vehicle,
        pickupTime: this.state.data?.request?.pickUpTime,
        drvInfo: {},
        vehicleTypes: [this.state.cartypeSelected.vehicleType],
        associateCarType: this.state?.cartypeSelected?.dispatch
      })
    }
    this.state.data.drvInfo.fullName = e.target.value
    if (
      !this.state.data.drvInfo.fullName ||
      this.state.data.drvInfo.fullName.length == 0
    ) {
      this.state.driver = null
    }
    this.setState({
      data: this.state.data,
      isFormDirty: true,
      driver: null,
      resetCounterVehicleTextSearch: this.state.data?.vehicle?.vehicleType
        ? this.state.resetCounterVehicleTextSearch
        : ++this.state.resetCounterVehicleTextSearch,
    })
  }

  setVehicleAutoComplete = (vehicle) => {
    this.setState({
      data: {
        ...this.state.data,
        vehicle: vehicle || {},
      },
    })
  }

  handleDetachDrv = (e) => {
    const defaultState = { ...this.state }
    defaultState.data.drvInfo = {}
    defaultState.driver = null
    defaultState.data.dispatchType = 0
    defaultState.isDetachDrv = true
    this.setState(defaultState)
  }

  handleUnassignVehicle = (e) => {
    const newData = _.cloneDeep(this.state.data) || {}
    newData.vehicle = {}
    this.setState({
      data: newData,
    })
  }

  handleRideSharingChange = (e) => {
    this.setState(
      {
        data: { ...this.state.data, rideSharing: e.target.checked },
        isFormDirty: true,
      },
      this.etaFareCalculator
    )
  }

  handleDurationPackageChange(e) {
    if (this.props.newBooking.locationService)
      this.props.newBooking.locationService.packagesRate.map((pk) => {
        if (pk._id == e.target.value) {
          this.state.package = pk
          this.state.data.packageRateId = pk._id
        }
      })
    this.etaFareCalculator()
  }

  handleMeetdriverChange(e) {
    if (!this.state.data.request.moreInfo.flightInfo) {
      this.state.data.request.moreInfo.flightInfo = {}
    }
    this.state.data.request.moreInfo.flightInfo.type = e.target.value
    this.setState(
      { data: this.state.data, isFormDirty: true },
      this.etaFareCalculator
    )
  }

  handleWelcomeMessageChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        request: {
          ...this.state.data.request,
          welcomeMessage: e.target.value,
        },
      },
    })
  }

  handlePaymentMethodChange = (e) => {
    const { data } = this.state
    const paymentType = parseInt(e.target.value)
    let { corporateInfo } = data
    if (paymentType === 5 && !corporateInfo) corporateInfo = {}

    this.setState(
      {
        valid: {},
        credit: null,
        data: {
          ...data,
          request: {
            ...data.request,
            paymentType,
          },
        },
        isFormDirty: true,
      },
      () => {
        this.etaFareCalculator()
      }
    )
  }

  handleChangeBookingReference = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        externalInfo: {
          ...this.state.data.externalInfo,
          bookingReference: e.target.value,
        },
      },
    })
  }

  handleCreditCardChange(e) {
    if (e.target.value == 0) {
      this.setState({ credit: null, isFormDirty: true })
    } else if (
      this.state.data.request.paymentType == paymentMethodNumber.corporateCard
    ) {
      this.state.company.credits.map((cre) => {
        if (cre.localToken == e.target.value) {
          this.setState({
            credit: cre,
            data: { ...this.state.data },
            isFormDirty: true,
          })
        }
      })
    } else if (
      this.state.customer.credits &&
      this.state.customer.credits.length > 0
    ) {
      this.state.customer.credits.map((cre) => {
        if (cre.localToken == e.target.value) {
          this.setState({
            credit: cre,
            data: { ...this.state.data },
            isFormDirty: true,
          })
        }
      })
    } else if (
      this.state.data.psgInfo &&
      this.state.data.psgInfo.creditInfo &&
      (e.target.value == this.state.data.psgInfo.creditInfo.crossToken ||
        e.target.value == this.state.data.psgInfo.creditInfo.localToken)
    ) {
      this.setState({
        credit: this.state.data.psgInfo.creditInfo,
        data: { ...this.state.data },
        isFormDirty: true,
      })
    }
  }

  handleAddCardSuccess(cardAdded) {
    if (
      this.state.data.request.paymentType === paymentMethodNumber.personalCard
    ) {
      const { customer } = this.state
      customer.credits.push(cardAdded)
      this.setState({ customer, credit: cardAdded })
    }
    if (
      this.state.data.request.paymentType === paymentMethodNumber.corporateCard
    ) {
      const { company } = this.state
      company.credits.push(cardAdded)
      this.setState({ company, credit: cardAdded })
    }
  }

  handleCaculateEditFarePromo = (promoInfo) => {
    if (this.state.data?.request?.estimate?.isFareEdited) {
      this.state.data.request.estimate.fare = calculateFareWithPromoChange({
        auth: this.props.auth,
        promoInfo: promoInfo,
        etaFare: this.state?.data.request?.estimate?.fare,
        data: this.state.data,
        tipPercent: this.state.data.request.tip,
      })
      this.setState({
        data: this.state.data,
      })
    } else {
      this.etaFareCalculator()
    }
  }

  handlePromoApplyClick() {
    const { request = {} } = this.state.data
    const isFareEdited = this.state.data.request.estimate?.isFareEdited
    if (!!this.state.data.promoApplied || !!this.state.promo) {
      request.promo = ''
      this.state.data.promoApplied = false
      this.state.promo = null
      if (this.state.showConfirmComplete) {
        this.setState({
          data: this.state.data,
          promo: null,
          isFormDirty: true,
        })
      } else {
        this.updateExtraServiceForEta({
          res: this.props.newBooking.locationService,
        })
        this.setState(
          { data: this.state.data, promo: null, isFormDirty: true },
          () => {
            this.handleCaculateEditFarePromo(null)
          }
        )
      }
    } else if (request.promo === this.state.data.originPromo) {
      if (!request.promo) {
        return
      }
      this.state.data.promoApplied = true

      let maximumValue =
        request.promoInfo &&
        request.promoInfo.maximumValue &&
        request.promoInfo.maximumValue > 0
          ? request.promoInfo.maximumValue
          : 0

      const promoUpdate = {
        value: request.promoValue,
        promo: request.promo,
        keepMinFee: _.get(request, 'promoInfo.keepMinFee', false),
        maximumValue,
      }
      if (this.state.showConfirmComplete) {
        this.setState(
          { promo: promoUpdate, data: this.state.data },
          this.paymentCompleteTotalCalculator
        )
      } else {
        this.updateExtraServiceForEta({
          res: this.props.newBooking.locationService,
        })
        this.setState(
          { promo: promoUpdate, data: this.state.data },
          () => {
            this.handleCaculateEditFarePromo(request.promoInfo)
          }
          // this.etaFareCalculator
        )
      }
    } else {
      if (!request.promo) {
        return
      }
      const localNow = moment().tz(request.pickup.timezone)
      const jobType = _.get(this.props.bookingDetail, 'data.jobType', '')
      const timeWithoutZone = moment(request.pickUpTime).format(
        'YYYY-MM-DD HH:mm'
      )
      const localPickup = moment
        .tz(timeWithoutZone, request.pickup.timezone)
        .format()
      const etaFare =
        request.estimate.fare && request.estimate.fare.etaFare
          ? request.estimate.fare.etaFare
          : 0
      const paxAppVersion = _.get(this.state.data, 'psgInfo.rv', '')
      const promotionObject = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        pickupTime:
          request.pickUpTime === 'Now'
            ? moment.utc(localNow).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
            : moment.utc(localPickup).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        promotionCode: request.promo,
        userId: this.state.customer.userId,
        bookFrom: this.state.data.bookFrom,
        currencyISO: this.state.data.currencyISO,
        geo: [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
        serviceType: jobType === 'shuttle' ? 5 : 0, // 0: transport, 1: intercity, 2: parcel, 3: food, 4: mart
        etaFare: etaFare,
        paymentType: request.paymentType,
        rv: paxAppVersion,
      }
      if (request.paymentType === 21) {
        promotionObject.gateway = request.gateway || ''
      }

      const isFareEdited = this.state.data.request.estimate?.isFareEdited
      if (isFareEdited) {
        promotionObject
      }

      this.props.newbookingActions
        .checkpromoCode(promotionObject)
        .then((data) => {
          if (data.returnCode === 200) {
            const newPromo = data.response
            newPromo.promotionCode = request.promo
            if (this.state.showConfirmComplete) {
              this.setState(
                { promo: newPromo, promoApplied: true },
                this.paymentCompleteTotalCalculator
              )
            } else {
              this.updateExtraServiceForEta({
                res: this.props.newBooking.locationService,
              })
              this.setState({ promo: newPromo, promoApplied: true }, () => {
                this.handleCaculateEditFarePromo(newPromo)
              })
            }
          } else if (data.returnCode) {
            this.context.notification(
              'error',
              I18n.t(`messages.promoMsg.${data.returnCode}`)
            )
          } else {
            this.context.notification(
              'error',
              I18n.t('bookingdetail.Check_promo_error')
            )
          }
        })
    }
  }

  handleAirlineChange(e) {
    if (!this.state.data.request.moreInfo.flightInfo) {
      this.state.data.request.moreInfo.flightInfo = {}
    }
    this.state.data.request.moreInfo.flightInfo.airline = e.target.value
    this.setState({ data: this.state.data, isFormDirty: true })
  }

  handleFlightNumerChange(e, suggest) {
    if (!this.state.data.request.moreInfo.flightInfo) {
      this.state.data.request.moreInfo.flightInfo = {}
    }
    if (suggest) {
      this.state.data.request.moreInfo.flightInfo.flightNumber =
        suggest.newValue.replace(/\s/g, '').toUpperCase()
    } else {
      this.state.data.request.moreInfo.flightInfo.flightNumber = (
        e.target.value || ''
      )
        .replace(/\s/g, '')
        .toUpperCase()
    }
    this.setState({ data: this.state.data, isFormDirty: true })
  }

  handleRoundtripChange(e) {
    this.setState(
      {
        data: {
          ...this.state.data,
          roundTrip: e.target.checked,
        },
        isFormDirty: true,
      },
      this.getDirectionAndDraw,
      this.etaFareCalculator
    )
  }

  handleCarTypeChanged(value) {
    if (this.props.newBooking.locationService)
      this.props.newBooking.locationService.vehicleType.map((car) => {
        if ((car.vehicleType || car.ty) == value) {
          this.state.cartypeSelected = car
          this.state.data.isRoundTrip = false
          this.resetPackageHourlyOption()
          this.state.driver = null
          this.state.data.drvInfo.fullName = ''
          this.state.data.vehicle = {}
          if (!car.extraDestination) {
            this.state.isShowedExtraDestination = false
            this.state.extraDestination = null
          }
        }
      })
    this.state.data.request.vehicleTypeRequest = value
    this.setState(
      {
        data: this.state.data,
        cartypeSelected: this.state.cartypeSelected,
        resetCounterVehicleTextSearch: ++this.state
          .resetCounterVehicleTextSearch,
        isFormDirty: true,
      },
      () => {
        this.getDirectionAndDraw()
        this.carTypeBaseLocation().then((data) => {
          if (data.ok) {
            this.updateExtraServiceForEta(data, true)
            this.etaFareCalculator()
            if (data.res.crossZone) {
              this.getCrossZoneBaseLocation()
            }
          }
        })
      }
    )
  }

  handleTimeChanged(e) {
    if (e === 'Now') {
      if (this.state.data.request.pickUpTime === e) return
      this.state.data.request.pickUpTime = 'Now'
      this.state.data.request.dispatchType = 0
      this.state.data.drvInfo.fullName = ''
      this.setState(
        { data: this.state.data, isFormDirty: true },
        this.etaFareCalculator
      )
    } else {
      const { data } = this.state
      const prevTime = moment(data.request.pickUpTime)
      if (
        data.request.pickUpTime &&
        data.request.pickUpTime !== 'Now' &&
        prevTime.isSame(e, 'day')
      )
        return false
      this.state.data.request.pickUpTime = e
      if (this.state.isShowDriverCalendar && this.state.driverCalendarViewing) {
        this.handleDriverCalendarClick(this.state.driverCalendarViewing)
      }
      this.setState(
        { data: this.state.data, isFormDirty: true },
        this.etaFareCalculator
      )
    }
  }

  handleChangeNote(e) {
    this.state.data.request.note = e.target.value
    const newData = Object.assign({}, this.state.data)
    this.setState({ data: newData, isFormDirty: true })
  }

  handleChangeAffiliateNote = (e) => {
    this.state.data.request.affiliateNote = e.target.value
    const newData = Object.assign({}, this.state.data)
    this.setState({ data: newData, isFormDirty: true })
  }

  handleChangeNoteSuperHelper = (e) => {
    let instructions = this.state.data.request.instructions
    instructions.content = e.target.value
    this.setState({
      data: {
        ...this.state.data,
        instructions: instructions,
        isFormDirty: true,
      },
    })
  }

  handleCompleteBasicFareChange(e) {
    this.state.data.request.estimate.fare =
      this.state.data.request.estimate.fare || {}
    this.state.data.request.estimate.fare.basicFare = e.target.value
    this.setState(
      { data: this.state.data, isFormDirty: true },
      this.paymentCompleteSubtotalCalculator
    )
  }

  completeTimeOrMileageFareChange = (e, type) => {
    const newValue = e.target.value
    this.setState((prevState) => {
      let dataCopy = { ...prevState.data }
      dataCopy.request.estimate.fare = dataCopy.request?.estimate?.fare || {}
      dataCopy.request.estimate.fare[type] = newValue
      return { data: dataCopy, isFormDirty: true }
    }, this.paymentCompleteSubtotalCalculator)
  }

  handleCompleteTollFeesChange(e) {
    this.state.data.request.estimate.fare =
      this.state.data.request.estimate.fare || {}
    this.state.data.request.estimate.fare.tollFee = e.target.value
    this.setState(
      { data: this.state.data, isFormDirty: true },
      this.paymentCompleteTotalCalculator
    )
  }

  handleCompleteGasFeesChange(e) {
    this.state.data.request.estimate.fare =
      this.state.data.request.estimate.fare || {}
    this.state.data.request.estimate.fare.gasFee = e.target.value
    this.setState(
      { data: this.state.data, isFormDirty: true },
      this.paymentCompleteTotalCalculator
    )
  }

  handleCompleteParkingFeesChange(e) {
    this.state.data.request.estimate.fare =
      this.state.data.request.estimate.fare || {}
    this.state.data.request.estimate.fare.parkingFee = e.target.value
    this.setState(
      { data: this.state.data, isFormDirty: true },
      this.paymentCompleteTotalCalculator
    )
  }

  handleRushHourCheckboxChange() {
    this.state.paymentComplete.rushHourChecked =
      !this.state.paymentComplete.rushHourChecked
    this.setState(
      { paymentComplete: this.state.paymentComplete, isFormDirty: true },
      this.paymentCompleteSubtotalCalculator
    )
  }

  handleHeavyTrafficCheckboxChange() {
    this.state.paymentComplete.heavyTrafficChecked =
      !this.state.paymentComplete.heavyTrafficChecked
    this.setState(
      { paymentComplete: this.state.paymentComplete, isFormDirty: true },
      this.paymentCompleteSubtotalCalculator
    )
  }

  handleCompleteOtherFeesChange(e) {
    this.state.data.request.estimate.fare =
      this.state.data.request.estimate.fare || {}
    this.state.data.request.estimate.fare.otherFees = e.target.value
    this.setState(
      { data: this.state.data, isFormDirty: true },
      this.paymentCompleteSubtotalCalculator
    )
  }

  handleCompleteExtraWaitFeeChange = (e) => {
    this.state.data.request.estimate.fare =
      this.state.data.request.estimate.fare || {}
    this.state.data.request.estimate.fare.extraWaitFee = e.target.value
    this.setState(
      { data: this.state.data, isFormDirty: true },
      this.paymentCompleteSubtotalCalculator
    )
  }

  handleTaxChange(e) {
    this.state.paymentComplete.tax = e.target.value
    this.setState(
      {
        paymentComplete: this.state.paymentComplete,
        isFormDirty: true,
        taxEdited: true,
      },
      this.paymentCompleteTotalCalculator
    )
  }

  handleTransactionChange = (e) => {
    this.state.paymentComplete.creditTransactionFee = e.target.value
    this.setState(
      { paymentComplete: this.state.paymentComplete, isFormDirty: true },
      this.paymentCompleteTotalCalculator
    )
  }

  handleCompletePaymentTypeChange(e) {
    this.state.paymentComplete.paymentType = parseFloat(e.target.value)
    this.setState({ paymentComplete: this.state.paymentComplete }, () => {
      this.paymentCompleteTotalCalculator()
    })
  }

  handleCompletePaymentIsPendingChange(e) {
    this.state.paymentComplete.isPending = e.target.checked
    this.setState({
      paymentComplete: this.state.paymentComplete,
      isFormDirty: true,
    })
  }

  handleCompletePaymentPartialPayment = (e) => {
    this.state.paymentComplete.partialPayment = e.target.checked
    this.setState({
      paymentComplete: this.state.paymentComplete,
      isFormDirty: true,
    })
  }

  handleCompletePaymentWriteOffDebt = (e) => {
    this.state.paymentComplete.writeOffDebt = e.target.checked
    this.setState(
      {
        paymentComplete: this.state.paymentComplete,
        isFormDirty: true,
      },
      this.paymentCompleteTotalCalculator
    )
  }

  handleChangeTip(e) {
    if (this.state.showConfirmComplete) {
      this.state.paymentComplete.tip = e.target.value
      this.setState(
        { paymentComplete: this.state.paymentComplete },
        this.paymentCompleteTotalCalculator
      )
    } else {
      this.state.data.request.tip = e.target.value
      this.updateExtraServiceForEta({
        res: this.props.newBooking.locationService,
      })
      this.setState({ data: this.state.data, isFormDirty: true }, () => {
        if (this.state.data.request.estimate?.isFareEdited) {
          const newData = _.cloneDeep(this.state.data) || {}
          newData.request.estimate.fare = calculateFareWithTipChange({
            auth: this.props.auth,
            promoInfo: this.state.promo,
            etaFare: this.state?.data.request?.estimate?.fare,
            data: this.state.data,
            tipPercent: this.state.data.request.tip,
          })
          this.setState({
            data: newData,
          })
        } else {
          this.debounceEtaFareCalculator()
        }
      })
    }
  }

  handleAdditionalService = (itemId) => {
    const { data, promo } = this.state,
      { auth } = this.props
    this.setState(
      {
        data: {
          ...data,
          extraServices: calculationAdditionalService(itemId, data),
        },
      },
      () => {
        if (data.request.estimate?.isFareEdited) {
          // const newData = _.cloneDeep(data) || {}
          // newData.request.estimate.fare = calculateFareWithServiceFeeChange({
          //   auth,
          //   data,
          //   promoInfo: promo,
          //   etaFare: newData?.request?.estimate.fare,
          // })
          // this.setState({
          //   data: newData
          // })
        } else {
          this.etaFareCalculator()
        }
      }
    )
  }

  handleChangeOperatorNote(e) {
    this.state.data.request.operatorNote = e.target.value
    this.setState({ data: this.state.data, isFormDirty: true })
  }
  handleClientCaseMatterChange(e) {
    if (!this.state.data.corporateInfo) {
      this.state.data.corporateInfo = {}
    }
    this.state.data.corporateInfo.clientCaseMatter = e.target.value
    this.setState({ data: this.state.data, isFormDirty: true })
  }

  handleChargeCodeChange(e) {
    if (!this.state.data.corporateInfo) {
      this.state.data.corporateInfo = {}
    }
    this.state.data.corporateInfo.chargeCode = e.target.value
    this.setState({ data: this.state.data, isFormDirty: true })
  }

  handleDispatch3rdOnchange(e) {
    this.state.data.dispatch3rd = e.target.checked
    this.setState({ data: this.state.data, isFormDirty: true })
  }

  driverAutocompleteSlectedHandle(__, { suggestion }) {
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId)
    }
    this.state.data.drvInfo = Object.assign(
      this.state.data.drvInfo || {},
      suggestion
    )
    this.state.data.drvInfo.fullName = `${suggestion.firstName || ''} ${
      suggestion.lastName || ''
    }`

    this.setState({
      driver: Object.assign(this.state.driver || {}, suggestion),
      isDetachDrv: false,
      data: {
        ...this.state.data,
        vehicle: getVehicleWhenChangeDriver({
          oldVehicle: this.state.data?.vehicle,
          pickupTime: this.state.data?.request?.pickUpTime,
          drvInfo: suggestion,
          vehicleTypes: [this.state.cartypeSelected.vehicleType],
          associateCarType: this.state?.cartypeSelected?.dispatch
        }),
      },
    }, this.debounceEtaFareCalculator)
  }

  handleIncidentReasonChanged(e) {
    this.setState({ incidentReason: e.target.value, isFormDirty: true })
  }

  handleChangeETAFare(
    fareEdited,
    originFare,
    reasonEditFare,
    isFareEdited,
    isReseted
  ) {
    let shortTrip = this.props?.auth?.selectedFleet?.waiveOffCommission?.enable
      ? fareEdited?.etaFare >
        this.props?.auth?.selectedFleet?.waiveOffCommission
          ?.amountByCurrencies[0]?.value
        ? false
        : true
      : originFare?.shortTrip

    fareEdited.shortTrip = shortTrip

    this.state.data.request.estimate = this.state.data.request.estimate || {}
    this.state.data.request.estimate.fare.markupDifference = 0
    this.state.data.request.estimate.reasonMarkup = null
    this.state.data.request.estimate.markupValue = 0
    this.state.data.request.estimate.fare = fareEdited
    this.state.data.request.estimate.originFare = originFare
    this.state.data.request.estimate.isFareEdited = isFareEdited
    this.state.data.request.estimate.reasonEditFare = reasonEditFare
    this.state.data.extraServices = resetService(
      isReseted,
      this.state.data.extraServices,
      originFare
    )
    this.setState({
      data: this.state.data,
      isFormDirty: true,
      fleetMarkup: 0,
      prevEtaFare: {
        originFare,
        isFareEdited,
        reasonEditFare,
      },
    })
  }

  handleChangeMarkupPrice(
    originFare,
    markupDifference,
    reasonMarkup,
    markupType,
    markupValue,
    isReseted,
    makeupPriceTotal,
    supplierEarning
  ) {
    this.state.data.request.estimate.fare.markupDifference = markupDifference
    this.state.data.request.estimate.fare.editedSupplierEarning =
      supplierEarning.editedSupplierEarning
    this.state.data.request.estimate.fare.supplierEarningType =
      supplierEarning.supplierEarningType
    if (supplierEarning.totalPayout) {
      this.state.data.request.estimate.fare.totalPayout = parseFloat(supplierEarning.totalPayout || 0).toFixed(2)
    }
    this.state.data.request.estimate.reasonMarkup = reasonMarkup
    this.state.data.request.estimate.markupValue = parseFloat(markupValue)
    this.state.data.request.estimate.markupType = markupType
    this.state.data.request.estimate.isFareEdited = false
    this.state.data.request.estimate.reasonEditFare = null
    this.state.data.request.estimate.originFare = originFare
    this.setState({
      data: this.state.data,
      fleetMarkup: parseFloat(markupValue) || 0,
      prevEtaFare: {
        originFare,
        isFareEdited: false,
        reasonEditFare: null,
      },
    })
  }

  getDefaultPaymentMethod() {
    let paymentMethod = null
    if (this.state.data.traveler_type == 0) {
      this.props.paymentMethod.individualTypes.map((pm) => {
        if (pm.type == 'fleetCard') {
          paymentMethod = pm
        }
      })
      if (!paymentMethod) {
        paymentMethod = this.props.paymentMethod.individualTypes[0]
      }
    } else {
      this.props.paymentMethod.corporateTypes.map((pm) => {
        if (pm.type == 'fleetCard') {
          paymentMethod = pm
        }
      })
      paymentMethod = this.props.paymentMethod.corporateTypes[0]
    }
    return paymentMethod
  }

  getCrossZoneBaseLocation() {
    const options = {
      fleetId: this.state.data.request.psgFleetId,
      pricingType: 1,
      userId: this.props.auth.user._id,
    }
    if (this.state.data.request.pickup) {
      options.airport = [
        [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
      ]
      if (this.state.locationDestination) {
        let to = [
          this.state.locationDestination.lng,
          this.state.locationDestination.lat,
        ]
        if (this.state.extraDestination && this.state.extraDestination.lat) {
          to = [
            this.state.extraDestination.lng,
            this.state.extraDestination.lat,
          ]
        }
        options.airport.push(to)
      }
    }
    if (this.state.cartypeSelected && this.state.data.carType != '') {
      options.vehicleType = this.state.cartypeSelected._id
    }
    return this.props.newbookingActions.getCrossZoneRate(options)
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  getDirectionCallback(response) {
    this.setState(
      {
        directions: response.overviewPolyline,
        disDur: {
          distance: {
            value: response.distanceValue,
            text: response.distance
          },
          duration: {
            value: response.estimateValue,
            text: response.time
          }
        },
      },
      this.etaFareCalculator
    )
  }

  checkDirtyLocation = () => {
    const { puPoints = [], doPoints = [] } =
      (this.state && this.state.data) || {}
    if (
      puPoints.some((ob) => _.isEmpty(_.get(ob, 'address', {}))) ||
      doPoints.some((ob) => _.isEmpty(_.get(ob, 'address', {})))
    ) {
      return true
    }
    return false
  }

  getDirectionAndDraw(callback = this.getDirectionCallback, skipCheckLocationChange) {
    const { puPoints = [], doPoints = [] } = this.state.data || {}
    const travelMode = getTravelerModeByCarType(
      this.state.cartypeSelected,
      this.props.commonData.location.isChina
    )
    const points = getAllPointNotEmpty({ puPoints, doPoints, roundTrip: this.state?.data?.roundTrip })
    if(points?.length > 0 && this.checkLocationHasChange(points) || skipCheckLocationChange) {
      getDirectionMapProviderFromJupiter({
        points,
        travelMode,
        unit: this.props.auth.selectedFleet.unitDistance == 'km' ? 0 : 1,
        shortestPathEstimation: this.props.auth.selectedFleet.shortestPathEstimation,
        auth: this.props.auth,
        fleetId: this.props.auth.selectedFleet.fleetId,
        callback,
        isTencent: this.props?.commonData?.location?.isChina,
        bookId: this.state.data.bookId,
        vehicleType: this.state?.cartypeSelected?.vehicleType,
      });
    } else {
      this.setState({ directions: null, disDur: null }, this.etaFareCalculator)
    }
    // if (
    //   (this.state.locationPickUp && this.state.locationDestination) ||
    //   puPointsNotEmpty.length > 0 ||
    //   doPointsNotEmpty.length > 0
    // ) {
    //   // points.push(this.state.locationPickUp);
    //   // points.push(this.state.locationDestination);
    //   puPointsNotEmpty.forEach((point) => {
    //     let geo = _.get(point, 'address.geo', [])
    //     points.push({
    //       lat: geo[1],
    //       lng: geo[0],
    //     })
    //   })
    //   doPointsNotEmpty.forEach((point) => {
    //     let geo = _.get(point, 'address.geo', [])
    //     points.push({
    //       lat: geo[1],
    //       lng: geo[0],
    //     })
    //   })
    //   if (this.state.data.roundTrip && puPointsNotEmpty[0]) {
    //     let geo =
    //       (puPointsNotEmpty[0] &&
    //         puPointsNotEmpty[0].address &&
    //         puPointsNotEmpty[0].address.geo) ||
    //       []
    //     points.push({
    //       lat: geo[1],
    //       lng: geo[0],
    //     })
    //   }
    //   let fleetId = this.props.auth.selectedFleet.fleetId
    //   if (this.state.isHydraBooking) {
    //     fleetId = this.state.supplierVehicle
    //   }
    //   if(this.checkLocationHasChange(points) || skipCheckLocationChange) {
    //     getDirectionMapProviderFromJupiter({
    //       points,
    //       travelMode,
    //       unit: this.props.auth.selectedFleet.unitDistance == 'km' ? 0 : 1,
    //       shortestPathEstimation: this.props.auth.selectedFleet.shortestPathEstimation,
    //       auth: this.props.auth,
    //       fleetId: this.props.auth.selectedFleet.fleetId,
    //       callback,
    //       isTencent: this.props?.commonData?.location?.isChina,
    //       bookId: this.state.data.bookId,
    //       vehicleType: this.state?.cartypeSelected?.vehicleType,
    //     });
    //   }
    // } else {
    //   this.setState({ directions: null, disDur: null }, this.etaFareCalculator)
    // }
  }

  checkCanEditWithBookingOtherCompany = () => {
    const { auth, data } = this.props
    const editFarmBooking = _.get(auth, 'user.roles.companyOperator', false)
    const roleCompanyIdUser = _.get(auth, 'user.roles.companyId', '')
    const operatorBooking = _.get(data, 'createdByOperator', {})
    const hydraStatus = _.get(data, 'hydraInfo.status', 'none')
    if (this.state.farmIn && hydraStatus === HYDRA_STATUS.pending) return false
    // Truong hop User cua 1 company ma edit 1 booking duoc tao tu operator cua 1 cong ty khac
    if (!editFarmBooking && !auth.user.isAdmin && roleCompanyIdUser) {
      if (operatorBooking.isAdmin) return false
      const listCompanySupplier = [
        ...this.props.commonData?.companies,
        ...this.props.commonData?.suppliers,
      ]
      const listCompanySupplierId = listCompanySupplier.map((ob) => ob._id)
      if (!listCompanySupplierId.includes(roleCompanyIdUser)) return false
      // if (
      //   operatorBooking.companyId &&
      //   roleCompanyIdUser !== operatorBooking.companyId
      // )
      //   return false;
    }
    return true
  }

  isEditable = (booking, skip3rdBooking = false) => {
    const { permissions } = this.props
    const hydraStatus = _.get(this.state.data, 'hydraInfo.status', 'none')
    if (!skip3rdBooking && this.state.is3rdBooking) return false
    if (this.state.farmIn && hydraStatus === HYDRA_STATUS.pending) return false
    if (permissions && !permissions.actions) return false
    if (!this.checkCanEditWithBookingOtherCompany()) return false
    if (booking) {
      return bookingStatusMapping.canEdit[booking.status]
    }

    if (booking.status === 'action') {
      return true
    }
    return false
  }

  isFinished = (booking) => {
    return (
      finishedStatusList.some((i) => i.value === booking.status) ||
      !!booking.finishedAt
    )
  }

  isAbleUpdateBooking = (booking) => {
    const { permissions } = this.props
    if ((permissions && !permissions.actions) || this.state.roaming)
      return false
    if (this.isFinished(booking)) {
      return true
    } else {
      if (booking) {
        return bookingStatusMapping.canUpdateBooking[booking.status]
      }
      if (booking.status === 'action') {
        return true
      }
    }
    return false
  }

  isPickupEditable = () => {
    if (!this.state.data || this.state.farmIn || this.state.roaming) {
      return false
    }
    const { permissions } = this.props
    const { isHydraBooking, data = {} } = this.state
    if (this.state.is3rdBooking) return false
    if (permissions && !permissions.actions) return false
    if (
      isHydraBooking &&
      !HYDRA_STATUS_CAN_UPDATE.includes(_.get(data, 'hydraInfo.status'))
    ) {
      return false
    }
    return (
      this.isEditable(this.state.data) && !this.state.data.dispatchRideSharing
    )
  }

  isExtraSerivicesEditable = (booking) => {
    const { permissions } = this.props
    if (permissions && !permissions.actions) return false
    if (
      booking.status == 'droppedOff' ||
      booking.status == 'completed' ||
      booking.status == 'engaged' ||
      booking.status == 'canceled' ||
      booking.status == 'noShow'
    ) {
      return false
    }
    return true
  }

  isDestinationEditable = () => {
    let booking = this.state.data
    const { permissions } = this.props
    if (this.state.is3rdBooking) return false
    if (
      this.state.isHydraBooking &&
      !HYDRA_STATUS_CAN_UPDATE.includes(_.get(booking, 'hydraInfo.status'))
    ) {
      return false
    }
    if (
      (permissions && !permissions.actions) ||
      this.state.farmIn ||
      this.state.roaming
    )
      return false
    if (!booking) {
      return false
    }
    if (
      !this.isEditable(booking) ||
      booking.dispatchRideSharing ||
      (booking.request.localDestination &&
        booking.request.localDestination._address)
    ) {
      return false
    }
    return true
  }

  isNewBookingAble = (booking) => {
    const { permissions } = this.props
    if (bookingStatusMapping.canNotNewBooking[booking.status]) {
      return false
    }
    if (permissions && !permissions.actions) return false
    return true
  }

  isDispatchAble = (booking) => {
    const { permissions } = this.props
    if (
      this.state.is3rdBooking &&
      !CAN_ASSIGN_DRIVER_3RD_STATUS.includes(booking.status)
    )
      return false
    if (permissions && !permissions.actions) return false
    return bookingStatusMapping.canReDispatch[booking.status]
  }

  isNotesEnable = () => {
    const { permissions } = this.props
    const hydraStatus = _.get(this.state.data, 'hydraInfo.status', 'none')
    if (this.state.farmIn && hydraStatus === HYDRA_STATUS.pending) return false
    if (permissions && !permissions.actions) {
      return false
    }
    return true
  }

  isPickupTimeEditable = (booking) => {
    const { permissions } = this.props
    if (permissions && !permissions.actions) return false
    if (booking.dispatchRideSharing) {
      return false
    }
    if (!booking.pricingType) {
      if (
        booking.status == 'pending' ||
        booking.status == 'action' ||
        booking.status == 'confirmed' ||
        booking.status == 'pre'
      ) {
        return true
      }
    } else if (booking.farmOut) {
      if (booking.status == 'action') return true
    }
    return false
  }

  isCancelable = (booking) => {
    const { is3rdBooking, nameThirdParty, data } = this.state
    const { permissions } = this.props
    if (permissions && !permissions.actions) return false

    if (
      booking.status === 'allocated' &&
      is3rdBooking &&
      nameThirdParty === thirdPartyIntegration.holidaytaxis
    ) {
      return true
    }

    if (booking) {
      return bookingStatusMapping.canCancel[booking.status]
    }
    if (
      booking.status === 'action' ||
      booking.status === 'queue' ||
      booking.status === 'offered'
    ) {
      return true
    }
    return false
  }

  canReject = (booking) => {
    if(!this.props.permissions.actions) return false
    if (booking.pricingType && this.state.farmIn) {
      if (
        booking.status === 'accepted' ||
        booking.status === 'confirmed' ||
        booking.status === 'pending' ||
        booking.status === 'pre' ||
        booking.status === 'action'
      ) {
        return true
      }
    }
    return false
  }

  rejectBookingButtonClick = () => {
    this.props.loadingActions.showLoadingSpiner()
    this.props.bookingDetailActions
      .checkChargeCCCancel({
        fleetId: this.state.data.fleetId,
        bookId: this.state.data.bookId,
      })
      .then((res) => {
        let content = ''
        this.props.loadingActions.hideLoadingSpiner()
        if (res && res.returnCode === 200 && res.charge) {
          content =
            'Are you sure you want to reject this affiliate booking? You will need to pay a penalty for this action.'
        } else {
          content = I18n.t('bookingdetail.Confirm_reject_message')
        }
        this.setState({
          showConfirmReject: {
            title: I18n.t('bookingdetail.Confirm_reject'),
            body: content,
            buttonTitle: I18n.t('bookingdetail.Yes'),
            closeButtonClass: 'btn-cancel',
            closeButtonText: I18n.t('bookingdetail.No'),
          },
        })
      })
    // this.props.bookingDetailActions
    //   .getRejectInfo({ bookId: this.state.data.bookId })
    //   .then((data) => {
    //     if (data.ok && data.res) {
    //       const content =
    //         data.res.response.cancelPolicy > 0
    //           ? I18n.t(
    //               'bookingdetail.Confirm_reject_booking_with_charge'
    //             ).format(data.res.response.cancelPolicy)
    //           : I18n.t('bookingdetail.Confirm_reject_message');
    //       this.setState({
    //         showConfirmReject: {
    //           title: I18n.t('bookingdetail.Confirm_reject'),
    //           body: content,
    //           buttonTitle: I18n.t('bookingdetail.Yes'),
    //           closeButtonClass: 'btn-cancel',
    //           closeButtonText: I18n.t('bookingdetail.No'),
    //         },
    //       });
    //     } else {
    //       this.context.notification('error', I18n.t('errors.500000'));
    //     }
    //   });
  }

  handleCloseRejectModal = () => {
    this.setState({ showConfirmReject: null })
  }

  handleConfirmRejectClick = () => {
    this.props.loadingActions.showLoadingSpiner()
    this.props.bookingDetailActions
      .rejectBooking({
        bookId: this.state.data.bookId,
        fleetId: this.props.auth.selectedFleet.fleetId,
      })
      .then((res) => {
        this.props.loadingActions.hideLoadingSpiner()
        if (res.code == 200) {
          this.context.notification(
            'success',
            I18n.t('messages.booking.Reject_booking_success')
          )
          this.props.bookingDetailActions.bookingDetailClosed()
        } else {
          this.context.notification(
            'error',
            I18n.t('messages.booking.Reject_fail')
          )
        }
      })
  }

  showConfirmForceOverlap = () => {
    this.setState({
      showConfirmForceOverlap: {
        title: I18n.t('bookingdetail.confirm_force_overlap'),
        body: I18n.t('bookingdetail.confirm_force_overlap_message'),
        buttonTitle: I18n.t('bookingdetail.Yes'),
        closeButtonClass: 'btn-cancel',
        closeButtonText: I18n.t('bookingdetail.No'),
      },
    })
  }

  handleCloseConfirmForceOverlapModal = () => {
    this.setState({ showConfirmForceOverlap: null })
  }

  handleConfirmForceOverlapClick = () => {
    const { retryDispatch } = this.state

    this.setState({ showConfirmForceOverlap: null, forceAssign: true }, () => {
      if (retryDispatch) {
        this.updateInfoAndDispatchBookingClick()
      } else {
        this.updateInfoBookingClick()
      }
    })
  }

  isCompletable = (booking) => {
    const { permissions } = this.props
    if (permissions && !permissions.actions) return false
    if (
      this.props.auth.user.userType == userType.CorporateAdmin ||
      this.props.auth.user.userType == userType.CorporateUser
    ) {
      return false
    }
    if (!booking.pricingType) {
      return bookingStatusMapping.canComplete[booking.status]
    }
    if (!booking.farmOut) {
      return bookingStatusMapping.canComplete[booking.status]
    }
    return false
  }

  isReBookingAble() {
    return true
  }

  isActiveBooking = (booking) => {
    const { permissions } = this.props
    if (permissions && !permissions.actions) return false
    return bookingStatusMapping.active[booking.status]
  }

  isPaymentMethodEditable = (booking) => {
    const { permissions } = this.props
    if (permissions && !permissions.actions) return false
    const status = activeStatus.filter((data) => data.code == booking.status)[0]
    if (!booking.pricingType) {
      return status && status.paymentMethodEditable
    }
    if (!booking.farmOut) {
      return status && status.paymentMethodEditable
    }
    return false
  }

  // driver auto complete render and handleAddressRemoved
  renderDriverSuggestion(suggestion, {}) {
    let { status } = suggestion
    if (suggestion.status == 'inProgress') {
      status = suggestion.currentJob[0]
        ? suggestion.currentJob[0].status
        : suggestion.status
    }
    return (
      <div className={`suggestion-content-driver ${status}`}>
        <div className="name">
          {`${suggestion.firstName || ''} ${suggestion.lastName || ''}`}
        </div>
        <div className="email">
          {suggestion.vehicle.plateNumber}
          {suggestion.company ? ` / ${suggestion.company.companyName}` : null}
        </div>
        {this.state.data.request.pickUpTime != 'Now' ? (
          <div className="calendar-container">
            <span
              className="calendar"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                this.handleDriverCalendarClick(suggestion)
              }}
            >
              {I18n.t('bookingdetail.Calendar')}
            </span>
          </div>
        ) : null}
      </div>
    )
  }

  getDriverSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim())

    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId)
    }

    this.lastAutoCompleteRequestId = setTimeout(() => {
      this.getListDriverOnline(escapedValue)
    }, 300)
    const regex = new RegExp(`\\b${escapedValue}`, 'i')
    const data = regexpDriverAutoComplete(
      this.props.driverAutocomplete.data,
      escapedValue
    )
    return data
  }

  getListDriverOnline(escapedValue) {
    const {
      auth: { selectedFleet },
    } = this.props
    const offlineDriver = isCanAssignOfflineDriver(
      selectedFleet,
      this.state.data.request.pickUpTime
    )
    const supplierCompanies = this.state?.data?.vehicle?.companyId
      ? [this.state?.data?.vehicle?.companyId]
      : this.state.supplierCompanies
    this.props.driverAutocompleteActions
      .driverAutocomplete({
        fleetId: this.props.auth.selectedFleet.fleetId,
        str: escapedValue,
        pickUpTime:
          this.state.data.request.pickUpTime == 'Now'
            ? 'Now'
            : moment(this.state.data.request.pickUpTime).format(
                'YYYY-MM-DD HH:mm'
              ),
        vehicleTypeId: this.state.cartypeSelected
          ? this.state.cartypeSelected._id
          : this.state.data.request.vehicleType[0],
        vehicleId: this.state.data?.vehicle?.vehicleId || '',
        supplierCompanies: supplierCompanies,
        offlineDriver,
        corporateId: this.state.company && this.state.company._id,
      })
      .then(() => {
        this.setState({
          driversuggestions: regexpDriverAutoComplete(
            this.props.driverAutocomplete.data,
            escapedValue
          ),
        })
      })
  }

  getListDriverOnlineforAffiliate(escapedValue, regex) {
    this.props.driverAutocompleteActions
      .driverAutocompleteAffiliate({
        fleetId: this.props.auth.selectedFleet.fleetId,
        str: escapedValue,
        pickUpTime:
          this.state.data.request.pickUpTime == 'Now'
            ? 'Now'
            : moment(this.state.data.request.pickUpTime).format(
                'YYYY-MM-DD HH:mm'
              ),
        vehicleTypeAffiliateName: this.state.data.request.vehicleTypeRequest,
        supplierCompanies: this.state.supplierCompanies,
      })
      .then(() => {
        this.setState({
          driversuggestions: this.props.driverAutocomplete.data.filter(
            (person) =>
              regex.test(this.getDriverSuggestionValue(person)) ||
              regex.test(person.vehicle.plateNumber) ||
              regex.test(person.phone) ||
              regex.test(person.firstName) ||
              regex.test(person.lastName)
          ),
        })
      })
  }

  getDriverSuggestionValue(suggestion) {
    return suggestion.driver.name
  }

  onDriverSuggestionsFetchRequested = ({ value }) => {
    this.getDriverSuggestions(value)
  }

  onDriverSuggestionsClearRequested = () => {
    this.setState({
      driversuggestions: [],
    })
  }

  // Flight name auto complete
  flightAutocompleteSlectedHandle(_, { suggestion }) {
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId)
    }
    this.state.data.request.moreInfo.flightInfo.airline = suggestion.name
    this.setState({
      data: this.state.data,
      flight: suggestion,
    })
  }

  getFlightSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim())

    if (escapedValue === '') {
      return []
    }
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId)
    }
    const searchObject = {
      text: escapedValue,
      fleetId: this.props.auth.selectedFleet.fleetId,
    }

    if (escapedValue.length == 0) {
      return []
    }
    this.lastAutoCompleteRequestId = setTimeout(() => {
      this.props.flightActions.getAllFlightNames(searchObject).then((data) => {
        if (data.ok && data.res) {
          this.setState({
            flightSuggestions: data.res,
          })
        }
      })
    }, 400)
    const regex = new RegExp(`\\b${escapedValue}`, 'i')

    return this.state.flightSuggestions.filter((flight) =>
      regex.test(this.getFlightSuggestionValue(flight))
    )
  }

  getFlightSuggestionValue(suggestion) {
    return suggestion.name
  }

  renderFlightSuggestion(suggestion, {}) {
    return (
      <div className="suggestion-content">
        <span className="name">{suggestion.name}</span>
      </div>
    )
  }

  onFlightSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      flightSuggestions: this.getFlightSuggestions(value),
    })
  }

  onFlightSuggestionsClearRequested = () => {
    this.setState({
      flightSuggestions: [],
    })
  }

  // Flight name auto complete
  flightScheduleAutocompleteSlectedHandle(_, { suggestion }) {
    this.setState({
      flightScheduleSuggestions: [],
      flightSchedule: suggestion,
    })
  }

  getFlightScheduleSuggestions() {
    return this.state.flightScheduleSuggestions
  }

  onFlightScheduleSuggestionsFetchRequested = ({}) => {
    this.setState({
      flightScheduleSuggestions: this.getFlightScheduleSuggestions(),
    })
  }

  onFlightScheduleSuggestionsClearRequested = () => {
    this.setState({
      flightScheduleSuggestions: [],
    })
  }

  flightScheduleAplyButtonClick() {
    if (this.state.flightSchedule) {
      this.state.flight = null
      this.state.data.request.moreInfo.flightInfo.airline = ''
      this.state.data.request.moreInfo.flightInfo.flightNumber = ''
      this.state.flightSchedule = null
      this.state.flightScheduleSuggestions = []
      this.setState(this.state)
    } else {
      const escapedValue = this.escapeRegexCharacters(
        this.state.data.request.moreInfo.flightInfo.flightNumber.replace(
          /\s/g,
          ''
        ) || ''
      ).toUpperCase()

      if (escapedValue === '') {
        return []
      }
      const regex = /^[\D]*[0-9]+$/g
      if (!regex.test(escapedValue)) {
        this.context.notification(
          'error',
          I18n.t('bookingdetail.No_flight_info')
        )
        return
      }

      const IATA = escapedValue.replace(new RegExp('[0-9]', 'g'), '')
      if (IATA.length > 0) {
        if (this.lastAutoCompleteRequestId !== null) {
          clearTimeout(this.lastAutoCompleteRequestId)
        }
        const searchObject = {
          fleetId: this.props.auth.selectedFleet.fleetId,
          airportIcao: this.props.newBooking.locationService.fromAirport
            ? this.props.newBooking.locationService.fromAirport.icao
            : this.props.newBooking.locationService.toAirport.icao,
          direction: this.props.newBooking.locationService.fromAirport
            ? 'destination'
            : 'origin',
          airlineIcao: IATA,
          pickUpTime:
            this.state.data.request.pickUpTime == 'Now'
              ? 'Now'
              : moment(this.state.data.request.pickUpTime).format(
                  'YYYY-MM-DD HH:mm'
                ),
          flightNumber: escapedValue.replace(IATA, ''),
          timezone: this.props.newBooking.locationService.fromAirport
            ? this.props.newBooking.locationService.fromAirport.timezone
            : this.props.newBooking.locationService.toAirport.timezone,
        }

        if (escapedValue.length == 0) {
          this.setState({
            flightScheduleSuggestions: [],
          })
        }
        this.props.loadingActions.showLoadingSpiner()
        this.props.flightActions
          .getFlightSchedules(searchObject)
          .then((data) => {
            if (data.ok && data.res) {
              let flights = data.res
              const hidePreviousDateFlight = _.get(
                this.props.auth,
                'selectedFleet.flightAware.hidePreviousDateFlight',
                false
              )
              if (hidePreviousDateFlight) {
                const { locationPickUp, locationDestination } = this.state
                let location = `${locationPickUp.lat},${locationPickUp.lng}`
                if (this.isToAirPort()) {
                  location = `${locationDestination.lat},${locationDestination.lng}`
                }
                getTimezone({
                  location,
                  fleetId: this.props.auth.selectedFleet.fleetId,
                }).then((res) => {
                  const timezone = _.get(res, 'res.response.tz', '')
                  if (timezone) {
                    let time = moment().tz(timezone)
                    if (
                      this.state.data.time !== 'Now' &&
                      this.state.data.time
                    ) {
                      time = moment(this.state.data.time.pickUpTime).tz(
                        timezone
                      )
                    }
                    flights = flights.filter((ob) => {
                      const scheduleTime = this.isFromAirPort()
                        ? ob.arrivaltime
                        : ob.departuretime
                      if (time < moment(scheduleTime * 1000)) return true
                      return false
                    })
                    this.setState({
                      flightScheduleSuggestions: flights,
                    })
                  } else {
                    this.context.notification(
                      'error',
                      I18n.t('messages.booking.Can_not_get_timeZone')
                    )
                  }
                })
              } else {
                this.setState({
                  flightScheduleSuggestions: flights,
                })
              }
            }
            this.props.loadingActions.hideLoadingSpiner()
          })
      } else {
        this.context.notification(
          'error',
          I18n.t('bookingdetail.No_flight_info')
        )
      }
    }
  }

  isFromAirPortHydra = () => {
    if (CCLiteCommonFunc.isBookingStatusCompleted(this.state.data.status)) {
      if (this.state.data.request.type == 1) {
        return true
      }
      return false
    }
    return (
      this.state.locationPickUp &&
      this.state.locationPickUp.address &&
      this.state.locationPickUp.address.length > 0 &&
      this.props.newBooking &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.airport &&
      this.props.newBooking.locationService.airport[0]
    )
  }

  isToAirPortHydra = () => {
    if (CCLiteCommonFunc.isBookingStatusCompleted(this.state.data.status)) {
      if (this.state.data.request.type == 2) {
        return true
      }
      return false
    }
    return (
      this.state.locationDestination &&
      this.state.locationDestination.address &&
      this.state.locationDestination.address.length > 0 &&
      this.props.newBooking &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.airport &&
      this.props.newBooking.locationService.airport[1]
    )
  }

  updateBookingRequestObjectBuilder(retryDispatch = false, forceAssign) {
    if (!this.state.locationPickUp) {
      return
    }
    const { data, isHydraBooking, supplierVehicle } = this.state
    const { bookingDetail } = this.props
    if (isHydraBooking) {
      if (parseInt(this.state.data.request.paymentType) === 1) {
        this.context.notification(
          'error',
          "This booking can't be farm out: selected payment type is not supported"
        )
        return
      }
      if (data.request.pickUpTime === 'Now') {
        this.context.notification(
          'error',
          "This booking can't be farm out: support reservation only"
        )
        return
      }
    }
    if (_.isEmpty(this.state.cartypeSelected)) {
      this.context.notification(
        'error',
        'Please select a car type to proceed the booking'
      )
      return
    }
    const bookingCurrency =
      this.props.newBooking &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.currency
        ? this.props.newBooking.locationService.currency
        : this.props.auth.selectedFleet.currency

    let pickUpTimeState = (data.request && data.request.pickUpTime) || 'Now'
    let recurring = (bookingDetail.data && bookingDetail.data.recurring) || {
      mode: 'single',
      pickupTime: pickUpTimeState,
    }

    if (recurring.mode === 'recurring') {
      recurring.pickupTime = moment(pickUpTimeState).format('HH:mm')
      recurring.selectedDates = this.state.recurringDays.map((dateOb) =>
        moment(dateOb).format('YYYY-MM-DD')
      )
      recurring.pickupTime = this.state.recurringTime
    } else {
      recurring.pickupTime =
        pickUpTimeState == 'Now'
          ? 'Now'
          : moment(pickUpTimeState).format('YYYY-MM-DD HH:mm')
    }

    if (!recurring.mode) {
      recurring.mode = 'single'
    }

    let puPointsNotEmpty = this.filterPointEmpty(data.puPoints)
    puPointsNotEmpty = this.reOrderPointToSubmit(puPointsNotEmpty)
    let doPointsNotEmpty = this.filterPointEmpty(data.doPoints)
    doPointsNotEmpty = this.reOrderPointToSubmit(doPointsNotEmpty)

    let object = {
      'x-request-id': uuidv4({ msecs: new Date().getTime() }),
      bookId: this.state.data.bookId,
      dispatch3rd: this.state.data.dispatch3rd,
      recurring: recurring,
      puPoints: puPointsNotEmpty,
      doPoints: doPointsNotEmpty,
      externalInfo: {
        bookingReference: this.state.data?.externalInfo?.bookingReference ?? '',
      },
      fleetId: isHydraBooking
        ? supplierVehicle
        : this.props.auth.selectedFleet.fleetId,
      // pricingType: isHydraBooking ? 1 : 0,
      retryDispatch,
      forceAssign,
      dispatchType: isHydraBooking ? 0 : data.dispatchType,
      // isFarmOut: isHydraBooking,
      drvInfo: {
        phone: '',
      },
      psgInfo: {
        creditInfo: {},
      },
      mDispatcherInfo: this.state.data.mDispatcherInfo,
      request: {
        pickUpTime:
          this.state.data.request.pickUpTime == 'Now'
            ? 'Now'
            : moment(this.state.data.request.pickUpTime).format(
                'YYYY-MM-DD HH:mm'
              ),
        note: this.state.data.request.note,
        operatorNote: this.state.data.request.operatorNote,
        affiliateNote: this.state.data.request.affiliateNote,
        primaryPartialMethod:
          this.state.data.request.primaryPartialMethod || -1,
        instructions: this.state.data.instructions,
        vehicleType: isHydraBooking
          ? [this.state.cartypeSelected.providerOfVhc.vehicleTypeId]
          : this.state.cartypeSelected === null
          ? this.state.data.request.vehicleType
          : this.state.cartypeSelected?.dispatch || [
              this.state.cartypeSelected._id,
            ],
        vehicleTypeRequest: isHydraBooking
          ? this.state.cartypeSelected.providerOfVhc.vehicleType
          : this.state.cartypeSelected === null
          ? this.state.data.request.vehicleTypeRequest
          : this.state.cartypeSelected?.vehicleType ||
            this.state.cartypeSelected.ty,
        pickup: isHydraBooking
          ? this.state.data.request.pickup
          : {
              geo:
                this.state.locationPickUp.lng === null ||
                this.state.locationPickUp.lng === undefined
                  ? []
                  : [
                      this.state.locationPickUp.lng,
                      this.state.locationPickUp.lat,
                    ],
              city: this.state.locationPickUp.city,
              cityName: this.state.locationPickUp.cityName,
              address: this.state.locationPickUp.address,
              zipCode: this.state.locationPickUp.zipCode,
              country: this.state.locationPickUp.countryCode,
              from:
                this.state.locationPickUp.from == locationType.google &&
                this.props.commonData.location &&
                this.props.commonData.location.isChina
                  ? locationType.tencent
                  : this.state.locationPickUp.from,
              instructionLink: this.state.locationPickUp.instructionLink || '',
              addressDetails: this.state.locationPickUp.addressDetails || {},
            },
        destination: null,
        moreInfo: {
          // flightInfo: {
          //   type: -1,
          //   airline: '',
          //   flightNumber: ''
          // }
        },
        estimate: {},
        type: 0,
        typeRate: 0,
        paymentType: parseInt(this.state.data.request.paymentType),
        packageRateId: '',
        duration: 0,
        packageRateName: '',
        tip: this.state.data.request.tip,
        promo:
          this.state.promo && this.state.promo.value > 0
            ? this.state.promo.promotionCode
            : '',
        promoValue:
          this.state.promo && this.state.promo.value > 0
            ? this.state.promo.type == 'Amount'
              ? this.state.promo.value
              : (this.state.paymentComplete.subTotal * this.state.promo.value) /
                100
            : '',
        rideSharing: this.state.data.rideSharing,
        companyName: isHydraBooking
          ? ''
          : this.state.data?.request?.companyName || '',
      },
      supplierCompanies: this.state.supplierCompanies,
      operator: {
        userId: this.props.auth.user_id,
        name: `${this.props.auth.user.firstName || ''} ${
          this.props.auth.user.lastName || ''
        }`,
      },
    }

    if (
      this.props?.auth?.user?.roles?.companyId &&
      this.state.supplierCompanies.length == 0
    ) {
      let listCompanies = this.getCompanyList()
      object.supplierCompanies = listCompanies.map((company) => company._id)
    }

    if (!this.state.farmIn) {
      object.pricingType = isHydraBooking ? 1 : 0
      object.isFarmOut = isHydraBooking
    }

    if (this.state.promo) {
      if (!this.state.promo.type) {
        object.request.promo = this.state.data.request.promo
        object.request.promoValue = this.state.data.request.promoValue
      } else {
        object.request.promo = this.state.promo.promotionCode
        object.request.promoValue =
          this.state.promo.type == 'Amount'
            ? currencyFormatter.format(-this.state.promo.value, {
                code: bookingCurrency.iso,
              })
            : `${-this.state.promo.value}%`
      }
    }
    if (
      this.state.locationDestination &&
      this.state.locationDestination.address &&
      this.state.locationDestination.address.length > 0
    ) {
      if(puPointsNotEmpty?.length > 0 && doPointsNotEmpty?.length > 0) {
        object.request.sessionKey = this.state.sessionKeyETAFare || '';
      }
      const markupDifference =
        (this.state.data.request.estimate.fare &&
          this.state.data.request.estimate.fare.markupDifference) ||
        0
      object.request.markupDifference = markupDifference
      object.request.estimate = {
        distance: this.state.disDur ? this.state.disDur.distance.text : '',
        time: this.state.disDur ? this.state.disDur.duration.text : '',
        estimateValue: this.state.disDur
          ? this.state.disDur.duration.value
          : null,
        distanceValue: _.get(this.state.disDur, 'distance.value', null),
        fare: this.state.data.request.estimate.fare
          ? {
              ...this.state.data.request.estimate.fare,
              unroundedTotalAmt:
                this.state.data.request.estimate.fare.unroundedTotalAmt +
                markupDifference,
              totalWithoutPromo:
                this.state.data.request.estimate.fare.totalWithoutPromo +
                markupDifference,
              etaFare:
                this.state.data.request.estimate.fare.etaFare +
                markupDifference,
              fleetMarkup: this.state.fleetMarkup
                ? parseFloat(this.state.fleetMarkup)
                : 0,
            }
          : {},
        isNormalFare: this.state.data.request.estimate.fare
          ? this.state.data.request.estimate.fare.isNormalFare
          : 1,
        originFare: this.state.data.request.estimate.originFare || {},
        isFareEdited: this.state.data.request.estimate.isFareEdited || false,
        reasonEditFare: this.state.data.request.estimate.reasonEditFare || '',
        reasonMarkup: this.state.data.request.estimate.reasonMarkup || '',
        markupType: this.state.data.request.estimate.markupType,
        markupValue: this.state.data.request.estimate.markupValue,
      }
      object.request.destination = {
        geo:
          this.state.locationDestination.lng === null ||
          this.state.locationDestination.lng === undefined
            ? []
            : [
                this.state.locationDestination.lng,
                this.state.locationDestination.lat,
              ],
        city: this.state.locationDestination.city,
        cityName: this.state.locationDestination.cityName,
        address: this.state.locationDestination.address,
        zipCode: this.state.locationDestination.zipCode,
        country: this.state.locationDestination.countryCode,
        from:
          this.state.locationDestination.from == locationType.google &&
          this.props.commonData.location &&
          this.props.commonData.location.isChina
            ? locationType.tencent
            : this.state.locationDestination.from,
        instructionLink: this.state.locationDestination.instructionLink || '',
        addressDetails: this.state.locationDestination.addressDetails || {},
      }
      if (
        this.state.extraDestination &&
        this.state.extraDestination.lat &&
        this.state.extraDestination.lng
      ) {
        object.request.extraDestination = [
          {
            geo:
              this.state.locationDestination.lng === null ||
              this.state.locationDestination.lng === undefined
                ? []
                : [
                    this.state.locationDestination.lng,
                    this.state.locationDestination.lat,
                  ],
            city: this.state.locationDestination.city,
            cityName: this.state.locationDestination.cityName,
            address: this.state.locationDestination.address,
            zipCode: this.state.locationDestination.zipCode,
            country: this.state.locationDestination.countryCode,
            from:
              this.state.locationDestination.from == locationType.google &&
              this.props.commonData.location &&
              this.props.commonData.location.isChina
                ? locationType.tencent
                : this.state.locationDestination.from,
            instructionLink:
              this.state.locationDestination.instructionLink || '',
          },
        ]
        object.request.destination = {
          from:
            this.state.extraDestination.from == locationType.google &&
            this.props.commonData.location &&
            this.props.commonData.location.isChina
              ? locationType.tencent
              : this.state.extraDestination.from,
          address: this.state.extraDestination.address,
          timezone: this.state.extraDestination.timezone,
          cityName: this.state.extraDestination.cityName,
          geo:
            this.state.extraDestination.lng === null ||
            this.state.extraDestination.lng === undefined
              ? []
              : [
                  this.state.extraDestination.lng,
                  this.state.extraDestination.lat,
                ],
          zipCode: this.state.extraDestination.zipCode,
          offset: '',
          instructionLink: this.state.extraDestination.instructionLink || '',
        }
      } else {
        object.request.extraDestination = []
      }
    } else {
      delete object.request.destination
      object.request.extraDestination = []
    }

    // check request type
    const isFromAirPort = this.isFromAirPort(),
      isToAirPort = this.isToAirPort(),
      isToAirPortHydra = this.isToAirPortHydra(),
      isToExtraAirPort = this.isToExtraAirPort()
    if (isFromAirPort || (isToAirPortHydra && this.state.isHydraBooking)) {
      object.request.type = 1
    }
    if (
      (isToAirPort || (isToAirPortHydra && this.state.isHydraBooking)) &&
      object.request.type != 1
    ) {
      object.request.type = 2
    }

    if (this.state.data.roundTrip) {
      object.request.typeRate = 2
      if (!(object.request.type == 1 || object.request.type == 2)) {
        object.request.type = 4
      }
    }
    if (this.state.data.duration) {
      object.request.typeRate = 1
      if (!(object.request.type == 1 || object.request.type == 2)) {
        object.request.type = 3
      }
      if (this.state.package) {
        object.request.packageRateId = this.state.package._id
        object.request.packageRateName = this.state.package.name
        object.request.duration = this.state.package.duration
      } else {
        object.request.packageRateId = this.state.data.request.packageRateId
        object.request.packageRateName = this.state.data.request.packageRateName
        object.request.duration = this.state.data.request.duration
      }
    }
    const oldFlightInfo = _.get(this.state.data, 'oldFlight', {})
    const newFlightInfo = _.get(
      this.state.data,
      'request.moreInfo.flightInfo',
      {}
    )
    // if((this.isFromAirPortHydra() ||  this.isToAirPortHydra())
    if ((isFromAirPort || isToAirPort) && this.state.isHydraBooking) {
      object.request.moreInfo = {
        flightInfo: {
          flightNumber: newFlightInfo.flightNumber || '',
          type:
            _.get(this.state.data, 'request.moreInfo.flightInfo.type') || -1,
        },
      }
    }

    object.vehicle = {
      vehicleId: this.state?.data?.vehicle?.vehicleId || '',
    }

    if (
      isFromAirPort &&
      parseInt(
        _.get(this.state.data, 'request.moreInfo.flightInfo.type', 0)
      ) === 1
    ) {
      object.request.welcomeMessage = this.state.data.request.welcomeMessage
    } else {
      object.request.welcomeMessage = ''
    }
    if (
      (!oldFlightInfo.departuretime ||
        oldFlightInfo.flightNumber != newFlightInfo.flightNumber) &&
      !this.state.isHydraBooking
    ) {
      if (
        object.request.type == 1 ||
        object.request.type == 2 ||
        isToExtraAirPort
      ) {
        object.request.moreInfo = {
          flightInfo: {
            flightNumber: newFlightInfo.flightNumber,
            type: -1,
          },
        }
        if (this.state.flight) {
          object.request.moreInfo.flightInfo.airlineIcao =
            this.state.flight.icao
          object.request.moreInfo.flightInfo.airlineIATA =
            this.state.flight.iata
        }
        if (
          this.props.auth.selectedFleet?.process?.flight &&
          !_.isEmpty(this.state.flightSchedule)
        ) {
          object.request.moreInfo.flightInfo = this.state.flightSchedule
          if (
            !/^[A-Za-z]/.test(object.request.moreInfo.flightInfo.flightNumber)
          ) {
            object.request.moreInfo.flightInfo.flightNumber =
              object.request.moreInfo.flightInfo.iata +
              object.request.moreInfo.flightInfo.flightNumber
          }
        }

        if (
          (object.request.type == 1 || isToExtraAirPort) &&
          this.props.newBooking.locationService &&
          this.props.newBooking.locationService.additionalService.fromAirport
            .meetDriver
        ) {
          object.request.moreInfo.flightInfo.type = parseInt(
            this.state.data.request.moreInfo.flightInfo.type || 0
          )
        }
      }
    }

    if (
      !isFromAirPort &&
      !isToAirPort &&
      !isToAirPortHydra &&
      !isToExtraAirPort
    ) {
      if (object.request.moreInfo) {
        object.request.moreInfo.flightInfo = {
          flightNumber: '',
        }
      }
    }

    if (this.state.data && this.state.data.minutesToArrive) {
      let { expected, value } = this.state.data.minutesToArrive
      value = parseInt(value)
      if (expected === 'before') value *= -1
      if (expected === 'samePickup') value = 0
      object.request.minutesToArrive = value
    }
    if (this.state.isDetachDrv) {
      object.isDetachDrv = true
      object.drvInfo = {
        phone: '',
      }
    } else {
      if (
        this.state.data.dispatchType == 1 ||
        this.state.data.dispatchType == 3
      ) {
        object.drvInfo = {
          phone: this.state?.driver?.phone,
        }
      } else {
        object.drvInfo = {
          phone: '',
        }
      }
    }

    if (this.state.data.travelerType == travelerType.Corporate) {
      object.corporateInfo = this.state.data.corporateInfo
    }
    if (
      this.state.data.request.paymentType == paymentMethodNumber.corporateCard
    ) {
      object.corporateInfo.creditInfo = {
        localToken: this.state.credit.localToken,
        crossToken: this.state.credit.crossToken,
        cardMask: this.state.credit.cardMask,
        cardType: this.state.credit.cardType,
      }
    }
    if (
      this.state.data.request.paymentType == paymentMethodNumber.personalCard
    ) {
      object.psgInfo.creditInfo = {
        localToken: this.state.credit.localToken,
        crossToken: this.state.credit.crossToken,
        cardMask: this.state.credit.cardMask,
        cardType: this.state.credit.cardType,
      }
    }
    if (
      !this.state.isHydraBooking &&
      (this.state.data.request.paymentType == 5 ||
        this.state.data.request.paymentType == 7)
    ) {
      if (!object.corporateInfo) {
        object.corporateInfo = {}
      }
      object.corporateInfo.clientCaseMatter = this.state.data.corporateInfo
        ? this.state.data.corporateInfo.clientCaseMatter || ''
        : ''
      object.corporateInfo.chargeCode = this.state.data.corporateInfo
        ? this.state.data.corporateInfo.chargeCode || ''
        : ''
    }

    const { serviceName, value } = handleFinalServicesRequest(
      this.state.data?.extraServices,
      this.state.data?.isNewSettingAdditionFee,
      this.state.data?.request?.estimate?.originFare,
      this.state.data.request?.estimate?.isFareEdited
    )
    object.request[serviceName] = value

    //  check car type allow luggage
    const { cartypeSelected } = this.state
    if (this.state.isHydraBooking) {
      object.request.paxNumber = this.state.data.request.paxNumber
      object.request.luggageNumber = this.state.data.request.luggageNumber
    } else if (
      cartypeSelected &&
      cartypeSelected.allowPaxNumber &&
      cartypeSelected.allowLuggageNumber
    ) {
      object.request.paxNumber = this.state.data.request.paxNumber
      object.request.luggageNumber = this.state.data.request.luggageNumber
    }

    if (this.state.isSupplierPermission) {
      object = removeKeySupplierPermission(object)
    }
    return object
  }

  checkShowUngroup = () => {
    const {
      data: { listGroup = [] },
    } = this.props
    return listGroup.some(
      (booking) => booking.status === 'confirmed' || booking.status === 'action'
    )
  }

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid
      let oldMessages = this.state.errorMessages
      if (!valid) {
        if (!oldMessages) oldMessages = []
        oldMessages = oldMessages.concat(messages)
      }
      this.setState({
        valid: this.state.valid,
        errorMessages: oldMessages,
      })
    }
  }

  setMinutesToArrive = (data) => {
    this.setState({
      data: {
        ...this.state.data,
        minutesToArrive: data,
      },
    })
  }

  renderIntelHourlyPackageRate() {
    const etaFare = this.state.data.request.estimate.fare || this.props.etaFare
    if (this.state.data.duration) {
      let selectList = []
      if (etaFare && etaFare.packages && etaFare.packages.length > 0) {
        // intel package available
        const intelPackages = etaFare.packages.sort(
          (a, b) =>
            // sort for the best price
            a.value - b.value
        )
        selectList = intelPackages.map((obj, index) => {
          let { name } = obj
          if (this.state.locationDestination) {
            name =
              index == 0
                ? `${obj.name} - ${I18n.t(
                    'newbooking.Best_price'
                  )} ${currencyFormatter.format(obj.value, {
                    code: etaFare.currencyISO,
                  })}`
                : `${obj.name} - ${
                    etaFare.currencyISO
                  } ${currencyFormatter.format(obj.value, {
                    code: etaFare.currencyISO,
                  })}`
          }

          return {
            name,
            value: obj.value,
            _id: obj._id,
          }
        })
      } else {
        selectList = this.props.newBooking.locationService
          ? this.props.newBooking.locationService.packagesRate || []
          : []
      }
      return (
        <FormControl
          as="select"
          value={this.state.data.packageRateId}
          disabled={
            !this.state.data.duration ||
            !this.isEditable(this.state.data) ||
            this.state.isHydraBooking ||
            !this.state.data.duration
          }
          placeholder="select"
          onChange={this.handleDurationPackageChange}
        >
          {selectList.map((packa) => (
            <option value={packa._id} key={packa._id}>
              {packa.name}
            </option>
          ))}
        </FormControl>
      )
    }
    return ''
  }

  renderHourlyPackagesNote() {
    // const {} = this.state
    const { cartypeSelected, isHydraBooking } = this.state
    const destination = this.state.locationDestination
    if (this.state.data.duration && destination) {
      // should be a hourly booking that have destination
      // car type must be estimated by action fare type and booking is not cross zone type
      if (cartypeSelected && cartypeSelected.actualFare && !isHydraBooking) {
        const message = I18n.t(
          'messages.booking.You_may_be_charged_extra_fee_if_your_trip_excceds'
        )
        const limitUnits = []
        const selectedPackageId = this.state.data.packageRateId
        const { etaFare } = this.props
        if (etaFare) {
          const selectedPackage = etaFare.packages
            ? etaFare.packages.find((obj) => obj._id == selectedPackageId)
            : null
          if (selectedPackage) {
            const currencyValue = selectedPackage.feesByCurrencies.find(
              (obj) => obj.currencyISO == etaFare.currencyISO
            )
            if (currencyValue.extraDistance || currencyValue.extraDuration) {
              if (selectedPackage.duration) {
                limitUnits.push(
                  `${selectedPackage.duration} ${selectedPackage.type}(s)`
                )
              }
              if (selectedPackage.coveredDistance) {
                const distanceUnit = this.props.auth.selectedFleet.unitDistance
                limitUnits.push(
                  `${selectedPackage.coveredDistance} ${distanceUnit}`
                )
              }
              return <span> {`* ${message} ${limitUnits.join(', ')}`} </span>
            }
          }
        }
      }
    }
    return ''
  }

  resetPackageHourlyOption() {
    this.state.data.duration = false
    this.state.data.packageRateId = null
    this.state.package = null
  }

  handleChangeRetryPaymentMethod(e) {
    const { value } = e.target
    this.setState({
      retryPaymentMethod: value,
      isFormDirty: true,
    })
  }

  handleRetryPaymentCharge(e) {
    const { value } = e.target
    this.setState({
      isRetryPaymentCharge: value == 1,
      isFormDirty: true,
    })
  }

  updateExtraServiceForEta(data = {}, force) {
    let extraServices = []
    const currentCurrency = this.props.newBooking.locationService
      ? this.props.newBooking.locationService.currency
      : this.props.auth.selectedFleet.currency
    if (!data.res) return
    let extraServicesResponse = data.res.extraServices
    if (data.res.fleetInfo && data.res.fleetInfo.additionalFees) {
      extraServicesResponse = data.res.fleetServices
    }
    if (force) {
      if (!extraServicesResponse) return
      extraServicesResponse.forEach((service) => {
        let fee = 0
        const serviceCurrency = service.serviceFeeByCurrencies.find(
          (c) => c.currencyISO == currentCurrency.iso
        )
        if (serviceCurrency) {
          fee = serviceCurrency.value
        }
        let active = false
        if (service.serviceType == 'Compulsory') {
          active = true
        }
        extraServices.push({
          fee,
          serviceId: service._id,
          active,
          type: service.serviceType,
          name: service.serviceName,
          infoService: service,
          serviceName: service.serviceName,
          serviceType: service.serviceType,
          serviceFeeType: service.serviceFeeType,
          serviceFeePercent: service.serviceFeePercent,
          serviceFeeByCurrencies: service.serviceFeeByCurrencies,
        })
      })
    } else {
      if (!extraServicesResponse) return
      extraServices = this.state.data.extraServices
      // update value
      extraServices = extraServices.map((service) => {
        let fee = 0
        const serviceMaster = extraServicesResponse.find(
          (obj) => obj._id == service.serviceId
        )
        if (serviceMaster) {
          const serviceCurrency = serviceMaster.serviceFeeByCurrencies.find(
            (c) => c.currencyISO == currentCurrency.iso
          )
          if (serviceCurrency) {
            fee = serviceCurrency.value
          }
        } else {
          fee = service.fee
        }
        return {
          ...service,
          fee,
        }
      })
    }
    this.setState({
      data: {
        ...this.state.data,
        extraServices,
      },
    })
  }

  handleShowMoreCustomer = (show) => {
    this.setState({
      showMoreCustomer: show,
    })
  }

  changeDateMode = (value) => {
    if (value == 'single') {
      this.handleTimeChanged('Now')
    } else {
      this.handleTimeChanged(moment())
    }
    this.setState({ dateMode: value })
  }

  onChangeWeekDays = (weekDays) => {
    if (!weekDays) return
    this.setState({ weekDays: weekDays })
  }

  updateFromDate = (value) => {
    let dateText = (value && value.format('YYYY-MM-DD')) || ''
    if (dateText) {
      this.setState({ fromDate: dateText })
    }
  }

  updateToDate = (value) => {
    let dateText = (value && value.format('YYYY-MM-DD')) || ''
    if (dateText) {
      this.setState({ toDate: dateText })
    }
  }

  closeRemoveBookingModal = () => {
    this.setState({
      isShowRemoveBookingModal: false,
      bookingRemoving: '',
    })
  }

  showRemoveBookingModal = (e, bookId) => {
    e.stopPropagation()
    this.setState({
      isShowRemoveBookingModal: true,
      bookingRemoving: bookId,
    })
  }

  showDetailBookingGroup = (bookId) => {
    this.props.bookingDetailActions
      .getBookingDetail(bookId, this.state.selectedTab == 1)
      .then((data) => {
        if (!data.res || data.error) {
          this.context.notification('error', I18n.t('cue.Error_load_booking'))
        }
      })
  }

  handleUngroupBooking = () => {
    const { auth, data } = this.props
    const groupId = data && data.groupId
    if (groupId) {
      this.props.cueActions
        .ungroupBooking({
          fleetId: auth.selectedFleet.fleetId,
          groupId: groupId,
        })
        .then((data) => {
          if (data.success) {
            this.context.notification(
              'success',
              I18n.t('messages.booking.upgroup_success')
            )
            this.close()
          }
        })
    }
  }

  handleAssignDriverGroup = () => {
    const { auth, data } = this.props
    let originDriverId = this.state.originDriverId
    let originVehicleId = this.state.originVehicleId
    const { drvInfo: currentDriver, vehicle: currentVehicle } = this.props.data
    const { drvInfo: newDriver, vehicle: newVehicle } = this.state.data
    if (originDriverId === undefined) {
      originDriverId = _.get(currentDriver, 'userId', '') || ''
    }
    if (originVehicleId === undefined) {
      originVehicleId = _.get(currentVehicle, 'vehicleId', '') || ''
    }
    const driverId =
      _.get(newDriver, 'driver._id', '') || _.get(newDriver, 'userId', '') || ''
    const vehicleId = _.get(newVehicle, 'vehicleId', '') || ''
    const changeDriver = originDriverId !== driverId
    const changeVehicle = originVehicleId !== vehicleId
    const groupId = data && data.groupId
    const getMessage = (success) => {
      let message = success ? 'Update_booking_success' : '307'
      if (changeDriver) {
        if (driverId) {
          message = success ? 'assign_driver_success' : 'notAssignDriver'
        } else {
          message = success ? 'detach_driver_success' : 'notUnassignDriver'
        }
      } else if (changeVehicle) {
        if (vehicleId) {
          message = success
            ? 'assign_driver_vehicle_success'
            : 'notAssignVehicle'
        } else {
          message = success ? 'detach_vehicle_success' : 'notUnassignVehicle'
        }
      }
      return message
    }
    this.props.cueActions
      .assignDriverGroup({
        fleetId: auth.selectedFleet.fleetId,
        groupId,
        driverId,
        vehicleId,
      })
      .then((data) => {
        const success = data && data._id
        if (success) {
          this.context.notification(
            'success',
            I18n.t('messages.booking.' + getMessage(true))
          )
          this.setState({
            originDriverId: driverId,
            originVehicleId: vehicleId,
          })
          this.closeAssignDriverModal()
        } else {
          this.context.notification(
            'error',
            I18n.t(`cue.${getMessage(false)}`)
          )
        }
      })
      .catch((e) => {
        this.context.notification(
          'error',
          I18n.t(`cue.${getMessage(false)}`)
        )
      })
  }

  removeBookingGroup = (bookingRemoving = '') => {
    if (!bookingRemoving) return
    const {
      auth,
      data,
      data: { listGroup = [] },
    } = this.props
    const groupId = data && data.groupId
    if (bookingRemoving && groupId) {
      // Remove Booking out of group
      this.props.cueActions
        .removeBookingGroup({
          fleetId: auth.selectedFleet.fleetId,
          groupId: groupId,
          bookId: bookingRemoving,
        })
        .then((data) => {
          if (data.success) {
            this.context.notification(
              'success',
              I18n.t('messages.booking.remove_booking_success')
            )
            let newListGroup =
              (listGroup &&
                listGroup.filter((book) => book.bookId != bookingRemoving)) ||
              []
            // Switch to other booking in group
            if (newListGroup.length > 0 && newListGroup[0].bookId) {
              this.props.bookingDetailActions
                .getBookingDetail(
                  newListGroup[0].bookId,
                  this.state.selectedTab == 1
                )
                .then((data) => {
                  if (!data.res || data.error) {
                    this.context.notification(
                      'error',
                      I18n.t('cue.Error_load_booking')
                    )
                  }
                })
            } else {
              this.close()
            }
            this.closeRemoveBookingModal()
          } else {
            this.context.notification(
              'error',
              I18n.t('messages.booking.remove_booking_false')
            )
          }
        })
    }
  }

  renderAssignDriverBookingModal = () => {
    const { driver = {} } = this.state
    const bookingCurrency =
      this.props.newBooking &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.currency
        ? this.props.newBooking.locationService.currency
        : this.props.auth.selectedFleet.currency
    const jobType = _.get(this.props.bookingDetail, 'data.jobType', '')
    return (
      <Modal
        show={this.state.isShowAssignDriverModal}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="confirm groupOrderModal"
        onHide={this.closeAssignDriverModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {driver && driver._id ? 'Edit Driver' : 'Assign Driver'}
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.closeAssignDriverModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <DispatchInfoChauffeur
            setVehicleAutoComplete={this.setVehicleAutoComplete}
            supplierCompanies={this.state.supplierCompanies}
            user={this.props.auth && this.props.auth.user}
            data={this.state.data}
            handleChangeDispatchType={this.handleChangeDispatchType}
            farmIn={this.state.farmIn}
            supportLocalBooking={this.state.supportLocalBooking}
            farmOut={this.state.farmOut}
            newBooking={this.props.newBooking}
            settingActions={this.props.settingActions}
            isSubmited={this.state.isSubmited}
            driversuggestions={this.state.driversuggestions}
            onDriverSuggestionsFetchRequested={
              this.onDriverSuggestionsFetchRequested
            }
            onDriverSuggestionsClearRequested={
              this.onDriverSuggestionsClearRequested
            }
            driverAutocompleteSlectedHandle={
              this.driverAutocompleteSlectedHandle
            }
            handleDriverChanged={this.handleDriverChanged}
            cartypeSelected={this.state.cartypeSelected}
            driver={this.state.driver}
            bookingCurrency={bookingCurrency}
            selectedFleet={this.props.auth && this.props.auth.selectedFleet}
            handleDispatch3rdOnchange={this.handleDispatch3rdOnchange}
            ValidatorCallback={this.ValidatorCallback}
            valid={this.state.valid}
            handleDriverCalendarClick={this.handleDriverCalendarClick}
            handleRideSharingChange={this.handleRideSharingChange}
            isEditable={this.isEditable}
            isDispatchAble={this.isDispatchAble}
            handleChangeNoteSuperHelper={this.handleChangeNoteSuperHelper}
            handleChangeNote={this.handleChangeNote}
            groupId={this.state.data.groupId}
            isInGroupModal
            handleDetachDrv={this.handleDetachDrv}
            handleUnassignVehicle={this.handleUnassignVehicle}
          />
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button
            className="btn-save mr-md"
            onClick={this.handleAssignDriverGroup}
          >
            {I18n.t('bookingdetail.Save')}
          </Button>
          <Button className="btn-cancel" onClick={this.closeAssignDriverModal}>
            {I18n.t('bookingdetail.Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  getBookingTypeText = () => {
    const { farmOut, farmIn, roaming } = this.state
    if (farmOut) return 'Farm out'
    if (farmIn) return 'Farm In'
    if (roaming) return 'Roaming'
    return ''
  }

  setRecurringDays = (days = []) => {
    this.setState({ recurringDays: days })
  }

  setRecurringTime = (time) => {
    this.setState({ recurringTime: time })
  }

  setSupplier = (e) => {
    const supplierId = e.target.value != -1 ? e.target.value : ''
    this.setState(
      {
        supplierId: supplierId,
      },
      () => {
        let cartypeSelected = null
        let vehicleType =
          _.get(
            this.props.newBooking,
            'locationService.affiliateService.vehicleTypeHydraSplited'
          ) || []
        if (vehicleType.length > 0) {
          if (supplierId) {
            cartypeSelected = vehicleType.find(
              (car) =>
                ((!car.dispatchRideSharing && !car.availability) ||
                  car.availability.cc) &&
                car.providerOfVhc.fleetId === this.state.supplierId
            )
          } else {
            cartypeSelected = vehicleType[0]
          }
        }
        if (cartypeSelected != null) {
          // update vehicle type
          const providerId = _.get(cartypeSelected, 'providers[0].fleetId', '')
          this.setState(
            {
              cartypeSelected,
              supplierVehicle: providerId,
            },
            () => {
              this.carTypeBaseLocation().then((res) => {
                this.updateExtraServiceForEta(res)
                this.etaFareCalculator()
              })
            }
          )
        } else {
          this.setState(
            {
              cartypeSelected,
            },
            () => {
              // this.checkNearestDriver();
            }
          )
        }
      }
    )
  }

  handleCarTypeChangedHydra = (vhc) => {
    if (vhc && !vhc.vehicleType) return ''
    const vehicleType = _.get(
      this.props.newBooking,
      'locationService.affiliateService.vehicleTypeHydraSplited',
      []
    )
    if (vehicleType) {
      vehicleType.map((car) => {
        if (
          (car.vehicleType || car.ty) == vhc.vehicleType &&
          car.providerOfVhc.vehicleType === vhc.providerOfVhc.vehicleType &&
          car.providerOfVhc.fleetName === vhc.providerOfVhc.fleetName
        ) {
          this.state.cartypeSelected = car
          this.state.data.isRoundTrip = false
          this.resetPackageHourlyOption()
          this.state.driver = null
          this.state.data.drvInfo.fullName = ''
          if (!car.extraDestination) {
            this.state.isShowedExtraDestination = false
            this.state.extraDestination = null
          }
        }
      })
    }
    this.state.data.request.vehicleTypeRequest = vhc
    this.setState(
      {
        data: this.state.data,
        cartypeSelected: {
          ...this.state.cartypeSelected,
          carTypeLocalName: vhc.providerOfVhc && vhc.providerOfVhc.vehicleType,
        },
        supplierVehicle: vhc.providerOfVhc && vhc.providerOfVhc.fleetId,
        isFormDirty: true,
      },
      () => {
        this.getDirectionAndDraw()
        this.carTypeBaseLocation().then((data) => {
          if (data.ok) {
            this.updateExtraServiceForEta(data, true)
            this.etaFareCalculator()
            if (data.res.crossZone) {
              this.getCrossZoneBaseLocation()
            }
          }
        })
      }
    )
  }

  handleResultETA = (fareCarSelecting, fareMulti, options, callback) => {
    const { etaFareLocalOrigin, etaFareFarmOutOrigin, isHydraBooking } =
      this.state
    if (
      (!this.state.data.request.estimate.markupValue &&
        (!this.state.data.request.estimate.isFareEdited ||
          (this.state.data.request.estimate.isFareEdited &&
            this.state.isUpdateETAFirst))) ||
      isHydraBooking
    ) {
      this.state.data.request.estimate.fare = fareCarSelecting
    }
    const isRoundTrip =
      fareCarSelecting &&
      fareCarSelecting.normalFare == false &&
      fareCarSelecting.route != ''
    let isReCal = false

    if (!isRoundTrip && this.state.data.roundTrip) {
      this.state.data.roundTrip = false
      isReCal = true
    }

    if (this.state.data.duration) {
      // set default for package
      if (
        fareCarSelecting &&
        fareCarSelecting.packages &&
        fareCarSelecting.packages.length > 0
      ) {
        let packageDefault = fareCarSelecting.packages.sort(function (a, b) {
          // sort for the best price
          return a.value - b.value
        })[0]
        if (!this.state.data.packageRateId && packageDefault) {
          this.state.data.packageRateId = packageDefault._id
          this.state.package = packageDefault
          isReCal = true
        }
      }
    }
    let newFleetMarkup = this.state.fleetMarkup

    // chuyển từ local booking tới hydra booking thì tự markup ETAFare của homeFleet
    // hoặc từ farmout fleet nay sang fleet khác
    if (
      etaFareLocalOrigin > -1 &&
      this.state.supplierDefault !== this.state.supplierVehicle &&
      isHydraBooking
    ) {
      const newQupSellPrice = _.get(
        this.state.data,
        'request.estimate.fare.qupSellPrice',
        0
      )
      newFleetMarkup = parseFloat(
        (etaFareLocalOrigin - newQupSellPrice).toFixed(2)
      )
    }

    if (isReCal) {
      this.setState(
        {
          options: options,
          data: this.state.data,
        },
        this.etaFareCalculator
      )
    } else {
      this.setState({
        options: options,
        data: this.state.data,
        isUpdateETAFirst: true,
        etaFareMultiCar: fareMulti || [],
        disableCompletePaymentNextClick: false,
        disabledCompleteWithoutServiceButton: false,
        sessionKeyETAFare: options.sessionKey,
        fleetMarkup: newFleetMarkup,
      })
    }
    if (this.state.showConfirmComplete) {
      this.paymentCompleteSubtotalCalculator()
    }
    if (callback) {
      callback(fareMulti)
    }
  }

  handleRejecttHydraBookingConfirmed = () => {
    this.props.loadingActions.showLoadingSpiner()
    this.props.bookingDetailActions
      .rejectHydraBooking({
        bookId: this.props.data.bookId,
        fleetId: this.props.auth.selectedFleet.fleetId,
      })
      .then((res) => {
        this.props.loadingActions.hideLoadingSpiner()
        if (res.ok && res.code === 200) {
          this.context.notification(
            'success',
            I18n.t('messages.booking.Reject_booking_success')
          )
          this.props.bookingDetailActions.bookingDetailClosed()
        } else {
          this.context.notification(
            'error',
            'Failed to Reject the booking. Please try again!'
          )
        }
      })
  }

  handle3rdPartyConfirmed = (type, reason) => {
    const { data, auth } = this.props
    this.props.loadingActions.showLoadingSpiner()
    this.props.bookingDetailActions
      .thirPartyBooking({
        bookId: data.bookId,
        fleetId: auth.selectedFleet.fleetId,
        supplierResponse: type,
        thirdParty: data?.externalInfo?.thirdParty,
        externalStatus: data?.externalInfo?.status,
        cancellationReason: reason ? reason.key : '',
        cancellationReasonMessage: reason ? reason.text : '',
      })
      .then((res) => {
        this.props.loadingActions.hideLoadingSpiner()
        if (res.ok && res.code === 200) {
          this.context.notification(
            'success',
            type === SUPPLIER_RESPONSE.accept
              ? I18n.t('newbooking.ConfirmedTo').format(
                  this.state.nameThirdParty
                )
              : I18n.t('newbooking.Deny_booking_success')
          )
          this.props.bookingDetailActions.bookingDetailClosed()
        } else {
          this.context.notification(
            'error',
            'Failed to update the booking. Please try again!'
          )
        }
      })
  }

  handleDenyHydraBookingConfirmed = () => {
    this.props.loadingActions.showLoadingSpiner()
    this.props.bookingDetailActions
      .denytHydraBooking({
        bookId: this.props.data.bookId,
        fleetId: this.props.auth.selectedFleet.fleetId,
      })
      .then((res) => {
        this.props.loadingActions.hideLoadingSpiner()
        if (res.ok && res.code === 200) {
          this.context.notification(
            'success',
            I18n.t('newbooking.Deny_booking_success')
          )
          this.props.bookingDetailActions.bookingDetailClosed()
        } else {
          this.context.notification(
            'error',
            'Failed to deny the booking. Please try again!'
          )
        }
      })
  }

  closeConfirmFarmIn = () => {
    this.setState({
      farmInInfo: {
        messageConfirm: '',
        showConfirmFarmIn: false,
        title: '',
        callback: () => {},
        closeConfirmFarmIn: this.closeConfirmFarmIn,
        textOkBtn: '',
      },
    })
  }

  handleRejectHydraBooking = () => {
    this.setState({
      farmInInfo: {
        messageConfirm: I18n.t('newbooking.rejectModalBody'),
        showConfirmFarmIn: true,
        title: I18n.t('newbooking.rejectModal'),
        callback: this.handleRejecttHydraBookingConfirmed,
        closeConfirmFarmIn: this.closeConfirmFarmIn,
        textOkBtn: I18n.t('newbooking.rejectButton'),
      },
    })
  }

  handleDenyHydraBooking = () => {
    this.setState({
      farmInInfo: {
        messageConfirm: I18n.t('newbooking.denyModalBody'),
        showConfirmFarmIn: true,
        title: I18n.t('newbooking.denyModal'),
        callback: this.handleDenyHydraBookingConfirmed,
        closeConfirmFarmIn: this.closeConfirmFarmIn,
        textOkBtn: I18n.t('newbooking.denyButton'),
      },
    })
  }

  handleAcceptHydraBooking = () => {
    this.setState({
      farmInInfo: {
        messageConfirm: I18n.t('newbooking.acceptModalBody'),
        showConfirmFarmIn: true,
        title: I18n.t('newbooking.acceptModal'),
        callback: this.handleAcceptHydraBookingConfirmed,
        closeConfirmFarmIn: this.closeConfirmFarmIn,
        textOkBtn: I18n.t('newbooking.acceptButton'),
      },
    })
  }

  handleAcceptOrConfirm3rdPartyBooking = () => {
    this.setState({
      farmInInfo: {
        messageConfirm: I18n.t('newbooking.proceedAction'),
        showConfirmFarmIn: true,
        title: I18n.t('importBookings.confirm'),
        callback: () => this.handle3rdPartyConfirmed(SUPPLIER_RESPONSE.accept),
        closeConfirmFarmIn: this.closeConfirmFarmIn,
        textOkBtn: I18n.t('cue.Yes'),
      },
    })
  }

  handleReject3rdPartyBooking = () => {
    this.setState({
      farmInInfo: {
        messageConfirm: I18n.t('newbooking.confirmCancelMSG'),
        showConfirmFarmIn: true,
        title: I18n.t('newbooking.confirmReject'),
        callback: (reason) =>
          this.handle3rdPartyConfirmed(SUPPLIER_RESPONSE.reject, reason),
        closeConfirmFarmIn: this.closeConfirmFarmIn,
        isCancel3rdBooking: true,
        textOkBtn: I18n.t('newbooking.Yes'),
        textCloseBtn: I18n.t('newbooking.No'),
      },
    })
  }

  handleAcceptHydraBookingConfirmed = () => {
    this.props.loadingActions.showLoadingSpiner()
    this.props.bookingDetailActions
      .acceptHydraBooking({
        bookId: this.props.data.bookId,
        fleetId: this.props.auth.selectedFleet.fleetId,
      })
      .then((res) => {
        this.props.loadingActions.hideLoadingSpiner()
        if (res.ok && res.code === 200) {
          this.context.notification(
            'success',
            I18n.t('newbooking.Accept_booking_success')
          )
          this.props.bookingDetailActions.bookingDetailClosed()
        } else {
          this.context.notification(
            'error',
            'Failed to update the booking. Please try again!'
          )
        }
      })
  }

  getCompanyList = (newZone) => {
    const { data = {}, company, zonePickup } = this.state,
      { commonData, auth } = this.props,
      userType = _.get(auth, 'user.userType', '')
    return filterCompanyList(
      data.travelerType,
      company,
      [...commonData.companies, ...commonData.suppliers],
      userType,
      newZone ? newZone : zonePickup
    )
  }

  render() {
    if (this.props.data == null) {
      return ''
    }
    const { permissions } = this.props
    let { corporateTypes } = this.props.paymentMethod

    if (this.props.auth.selectedFleet.corporate) {
      if (!this.props.auth.selectedFleet.corporate.corporateCard) {
        corporateTypes = corporateTypes.filter(
          (obj) => obj.type != 'corpCredit'
        )
      }

      if (!this.props.auth.selectedFleet.corporate.corporatePrepaid) {
        corporateTypes = corporateTypes.filter((obj) => obj.type != 'prepaid')
      }
    }
    const prepaided = checkBookingPrePaided(this.props.data)
    const isPaymentDirectInvoicing = checkPaymentDirectInvoicing(
      this.props.data
    )
    const bookingCurrency =
      this.props.newBooking &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.currency
        ? this.props.newBooking.locationService.currency
        : this.props.auth.selectedFleet.currency
    const isRoundTrip =
      checkRoundTrip({
        fare: this.state.data?.request?.estimate?.fare,
        locationService: this.props.newBooking?.locationService,
      }) ||
      (CCLiteCommonFunc.isBookingStatusCompleted(this.state.data.status) &&
        this.state.data.request.type == 4);
    let isHourly
    if (this.state.data.pricingType == 1) {
      isHourly = !!this.state.data.request.packageRateId
    } else {
      isHourly =
        this.state.locationPickUp &&
        this.props.newBooking.locationService &&
        (this.props.newBooking.locationService.vehicleType ||
          this.props.newBooking.locationService.carTypeAffiliate) &&
        this.props.newBooking.locationService.additionalService &&
        this.props.newBooking.locationService.additionalService.hourly &&
        this.props.newBooking.locationService.packagesRate &&
        this.props.newBooking.locationService.packagesRate.length > 0 &&
        this.props.newBooking.locationService.packagesRate &&
        this.props.newBooking.locationService.packagesRate.length > 0
    }

    if (this.isFromAirPort() || this.isToExtraAirPort()) {
      if (!CCLiteCommonFunc.isBookingStatusCompleted(this.state.data.status)) {
        if (
          this.props.newBooking.locationService &&
          this.props.newBooking.locationService.additionalService.fromAirport
            .meetDriver
        ) {
        }
      }
    }

    const {
      auth,
      data,
      data: { listGroup = [] },
    } = this.props

    const {
      customer,
      locationPickUp,
      locationDestination,
      cartypeSelected,
      isShowedExtraDestination,
      extraDestination,
      promoCodeMessageContent,
      company = {},
      farmIn,
      farmOut,
      roaming,
      isHydraBooking,
      is3rdBooking,
      isApiDemandBooking,
      zonePickup,
      nameThirdParty,
      isSupplierPermission,
      isAcceptConfirm3rdBookingStatus,
    } = this.state
    const statusExtenalInfo = this.props.data?.externalInfo?.status
    const isRject3rdBookingDotCom =
      is3rdBooking &&
      nameThirdParty === thirdPartyIntegration.bookingAPI &&
      !['engaged', 'droppedOff'].includes(data.status) &&
      (isAcceptConfirm3rdBookingStatus ||
        !CCLiteCommonFunc.isBookingStatusCompleted(data.status))
    let dataState = this.state.data || {}
    const { moduleSettings } = auth.selectedFleet
    const hasPermissionAction = this.props?.permissions?.actions 
    const isBookingFromPaxApp = !this.isBookingFromPaxApp(data)
    const isEnableGroupBooking = _.get(
      auth,
      'selectedFleet.groupBooking',
      false
    )
    const overrideBookingStatus = _.get(
      auth,
      'selectedFleet.generalSetting.overrideBookingStatus',
      false
    )
    const companyList = this.getCompanyList()
    const driverCompanyDetail = getSupplierInfoFromID(
      companyList,
      this.state.supplierCompanies?.[0]
    )
    const showNavigationPUDO = _.get(
      auth,
      'selectedFleet.generalSetting.showNavigationPUDO',
      false
    )
    const limitPoint = _.get(auth, 'selectedFleet.transport', {})
    const multiPointCalFareMode = _.get(
      auth,
      'selectedFleet.multiPointCalFareMode',
      ''
    )
    const recentPlaces = _.get(customer, 'passengerInfo.recentPlaces', {})
    const { location } = this.props.commonData
    let isChina = location ? location.isChina : false
    const isHasExtraDestination =
      isShowedExtraDestination && extraDestination && extraDestination.lat
    const isBookingCompleted =
      data.completedInfo &&
      data.completedInfo.total >= 0 &&
      data.status === 'completed'
        ? true
        : false
    const isFareEdited =
      data.request.estimate && data.request.estimate.isFareEdited
        ? data.request.estimate.isFareEdited
        : false
    const hasRecurring = _.get(auth, 'selectedFleet.recurring.enable', false)
    const pickupTimeDisable =
      !this.isPickupTimeEditable(data) ||
      !data.reservation ||
      !this.checkCanEditWithBookingOtherCompany()
    const { fleetId } = (auth && auth.selectedFleet) || {}
    const jobType = _.get(this.props.bookingDetail, 'data.jobType', '')
    const isStreetSharingType = jobType === 'streetSharing' ? true : false
    const walletName = _.get(
      this.props.bookingDetail,
      'data.request.walletName',
      ''
    )
    const isCommissionBookingApp = _.get(
      this.state.data,
      'corporateInfo.isCommissionBookingApp',
      false
    )
    let isSuperHelper =
      CCLiteCommonFunc.isBookingStatusCompleted(this.props.data.status) &&
      this.state.data.superHelper
        ? true
        : cartypeSelected && cartypeSelected.superHelper

    const puPointsNotEmpty = this.filterPointEmpty(dataState.puPoints),
      doPointsNotEmpty = this.filterPointEmpty(dataState.doPoints)

    const isMultiPuDoNotEmpty = checkMultiPuDoNotEmpty(
      puPointsNotEmpty,
      doPointsNotEmpty
    )
    const hydraStatus = _.get(data, 'hydraInfo.status', 'none')
    const hydraStatusText = HYDRA_STATUS[hydraStatus]
    const isFarmInPendingStatus = farmIn && hydraStatus === HYDRA_STATUS.pending
    const isFarmInAcceptedStatus =
      farmIn && hydraStatus === HYDRA_STATUS.accepted
    const isAirlineCorporate =
      (data && data.corporateInfo && data.corporateInfo.isAirline) || false
    return (
      <div>
        {this.renderAssignDriverBookingModal()}
        <Modal
          show={!!this.props.show}
          onHide={this.close}
          backdrop
          dialogClassName="book-detail-model"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate
                value="bookingdetail.Booking_detail"
                className="white-text"
              />
              <span className="white-text">:</span> #{this.props.data.bookId}{' '}
              <span className="white-text">-</span>{' '}
              {this.props.data.delivery === false &&
              this.props.data.status === 'pre' ? (
                <Translate
                  className="white-text text-tranform-none"
                  value={`statusDisplay.pending`}
                />
              ) : (
                <Translate
                  className="white-text text-tranform-none"
                  value={`statusDisplay.${this.props.data.status}`}
                />
              )}
              {this.getBookingTypeText() && (
                <span style={{ color: 'rgb(250, 201, 64)' }}>
                  {` (${this.getBookingTypeText()})`}
                </span>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={`clearfix newbooking-form ${
              this.state.showTrailNote ? 'show-trail-note' : ''
            }`}
          >
            <FarmInConfirmationModal farmInInfo={this.state.farmInInfo} />
            <DriverCalendarInfo
              isShowDriverCalendar={this.state.isShowDriverCalendar}
              driverReservationBookings={this.state.driverReservationBookings}
              driverReservationDatePriview={
                this.state.driverReservationDatePriview
              }
              handleDriverReservationBookingDateChange={
                this.handleDriverReservationBookingDateChange
              }
              data={this.state.data}
              onClose={this.handleCloseDriverCalendarClick}
              driverCalendarViewing={this.state.driverCalendarViewing}
              fieldKey="request.pickUpTime"
            />
            <UpdateConfirmationModal
              showConfirmUpdate={this.state.showConfirmUpdate}
              isHydraBooking={this.state.isHydraBooking}
              farmOut={this.state.farmOut}
              showWarningResetEditFare={this.state.showWarningResetEditFare}
              updateBookingButtonClick={this.updateBookingButtonClick}
              updateInfoBookingClick={this.updateInfoBookingClick}
              updateInfoAndDispatchBookingClick={
                this.updateInfoAndDispatchBookingClick
              }
              listGroup={listGroup}
              data={this.state.data}
              propsData={this.props.data}
              closeIcon={closeIcon}
              user={this.props.auth.user}
              userType={userType}
            />
            {this.state.openChargeInvoicing && (
              <ChargeInvoicingModal
                closeChargeModal={() =>
                  this.setState({ openChargeInvoicing: false })
                }
                paymentMethodSetting={this.props.paymentMethod}
                selectedFleet={this.props.auth.selectedFleet}
                auth={this.props.auth}
                customer={this.state.customer}
                corpInfo={this.state.company}
                bookInfo={this.state.data}
                farmOut={farmOut}
                farmIn={farmIn}
                fleetMarkup={this.state.fleetMarkup}
                bookingDetailActions={this.props.bookingDetailActions}
                isBookingCompleted={CCLiteCommonFunc.isBookingStatusCompleted(
                  this.state.data.status
                )}
              />
            )}
            <SendSMSBookingModal
              showSMSBooking={this.state.showSMSBooking}
              sendSMSBooking={this.sendSMSBooking}
              smsBookingTemplateQuery={this.state.smsBookingTemplateQuery}
              getSMSBookingTemplateList={this.getSMSBookingTemplateList}
              handleSelectSMSBookingTemplate={
                this.handleSelectSMSBookingTemplate
              }
              handleChangeMessageType={this.handleChangeMessageType}
              handleChangeRecipientType={this.handleChangeRecipientType}
              handleContentChange={this.handleContentChange}
              handlePhoneChange={this.handlePhoneChange}
              handleSubjectChange={this.handleSubjectChange}
              smsWillSend={this.state.smsWillSend}
              handleSendMessageClick={this.handleSendMessageClick}
              checkDriver={
                this.props.data.drvInfo &&
                !_.isString(this.props.data.drvInfo) &&
                this.props.data.drvInfo.userId &&
                this.props.data.drvInfo.userId.length > 0
                  ? true
                  : false
              }
              checkCustomer={
                this.props.data.psgInfo &&
                !_.isString(this.props.data.psgInfo) &&
                this.props.data.psgInfo.userId &&
                this.props.data.psgInfo.userId.length > 0
                  ? true
                  : false
              }
              valid={this.state.valid}
              ValidatorCallback={this.ValidatorCallback}
              handleSearchSMSTemplate={this.handleSearchSMSTemplate}
              disabledSpam={this.state.disabledSpam}
              isSend={this.state.isSend}
            />
            <IncidentModal
              showConfirmIncident={this.state.showConfirmIncident}
              incidentButtonClick={this.incidentButtonClick}
              data={this.state.data}
              handleIncidentReasonChanged={this.handleIncidentReasonChanged}
              incidentReason={this.state.incidentReason}
              incidentOkButtonClick={this.incidentOkButtonClick}
            />
            <CancelConfirmationModal
              showConfirmCancel={this.state.showConfirmCancel}
              cancelBookingButtonClick={this.cancelBookingButtonClick}
              cancelBookingOkClick={this.cancelBookingOkClick}
              msgChargeCancel={this.state.msgChargeCancel}
            />
            <Confirm
              confirm={this.state.showConfirmReject}
              handleConfirmCloseClick={this.handleCloseRejectModal}
              handleConfirmButtonClick={this.handleConfirmRejectClick}
            />
            <Confirm
              confirm={this.state.showConfirmForceOverlap}
              handleConfirmCloseClick={this.handleCloseConfirmForceOverlapModal}
              handleConfirmButtonClick={this.handleConfirmForceOverlapClick}
            />

            <CompleteWithPaymentModal
              showConfirmComplete={this.state.showConfirmComplete}
              isHydraBooking={this.state.isHydraBooking}
              isCompleteWithouservice={this.state.isCompleteWithouservice}
              isTransportBooking={this.props.isTransportBooking}
              isAllwayShowGoogleApplePay={this.state.isAllwayShowGoogleApplePay}
              farmIn={farmIn}
              completeWithPaymentCloseButtonClick={
                this.completeWithPaymentCloseButtonClick
              }
              completeWithPaymentButtonClick={
                this.completeWithPaymentButtonClick
              }
              customer={this.state.customer}
              data={this.state.data}
              isCompleteWithPaymentShow3rdBooking={
                this.state.isCompleteWithPaymentShow3rdBooking
              }
              retryCompleteBookingInfo={this.state.retryCompleteBookingInfo}
              isBookingFromPaxApp={this.isBookingFromPaxApp}
              handleChangeRetryPaymentMethod={
                this.handleChangeRetryPaymentMethod
              }
              retryPaymentMethod={this.state.retryPaymentMethod}
              paymentMethod={this.props.paymentMethod}
              corporateTypes={corporateTypes}
              handleRetryPaymentCharge={this.handleRetryPaymentCharge}
              isRetryPaymentCharge={this.state.isRetryPaymentCharge}
              paymentStep={this.state.paymentStep}
              selectedFleet={this.props.auth && this.props.auth.selectedFleet}
              newBooking={this.props.newBooking}
              selectAdditionalServicCheckedHandle={
                this.selectAdditionalServicCheckedHandle
              }
              additionalServiceType={additionalServiceType}
              checkAdditionalServiceSelected={
                this.checkAdditionalServiceSelected
              }
              locationPickUp={this.state.locationPickUp}
              commonData={this.props.commonData}
              extraDestination={this.state.extraDestination}
              locationDestination={this.state.locationDestination}
              handleSelectDestination={this.handleSelectDestination}
              handleChangeExtraDestination={this.handleChangeExtraDestination}
              handleChangeDestination={this.handleChangeDestination}
              handleAddressRemovedExtraDestination={
                this.handleAddressRemovedExtraDestination
              }
              handleAddressRemovedDestination={
                this.handleAddressRemovedDestination
              }
              handleCompleteBasicFareChange={this.handleCompleteBasicFareChange}
              completeTimeOrMileageFareChange={
                this.completeTimeOrMileageFareChange
              }
              fareSettings={this.state.fareSettings}
              paymentComplete={this.state.paymentComplete}
              handleRushHourCheckboxChange={this.handleRushHourCheckboxChange}
              rushHour={this.state.rushHour}
              handleHeavyTrafficCheckboxChange={
                this.handleHeavyTrafficCheckboxChange
              }
              handleCompleteOtherFeesChange={this.handleCompleteOtherFeesChange}
              handleCompleteExtraWaitFeeChange={
                this.handleCompleteExtraWaitFeeChange
              }
              handleCompleteTollFeesChange={this.handleCompleteTollFeesChange}
              handleCompleteGasFeesChange={this.handleCompleteGasFeesChange}
              handleCompleteParkingFeesChange={
                this.handleCompleteParkingFeesChange
              }
              commisson={this.state.commisson}
              commissionValue={this.state.commissionValue}
              isCommissionBookingApp={isCommissionBookingApp}
              handleTaxChange={this.handleTaxChange}
              handleTransactionChange={this.handleTransactionChange}
              handleChangeTip={this.handleChangeTip}
              handleChangePromo={this.handleChangePromo}
              promo={this.state.promo}
              handlePromoApplyClick={this.handlePromoApplyClick}
              calculatePromoAmount={this.calculatePromoAmount}
              calculatorTotal={this.calculatorTotal}
              handleCompletePaymentTypeChange={
                this.handleCompletePaymentTypeChange
              }
              handleCompletePaymentIsPendingChange={
                this.handleCompletePaymentIsPendingChange
              }
              handleCompletePaymentPartialPayment={
                this.handleCompletePaymentPartialPayment
              }
              handleCompletePaymentWriteOffDebt={
                this.handleCompletePaymentWriteOffDebt
              }
              isShowmDispatcherCard={this.state.isShowmDispatcherCard}
              completeWithPayment3rdBooking={this.completeWithPayment3rdBooking}
              completeWithPaymentOkButtonClick={
                this.completeWithPaymentOkButtonClick // submit data complete payment
              }
              options={{ bounds: this.state.mapDefaultBound }}
              disableCompletePaymentNextClick={
                this.state.disableCompletePaymentNextClick
              }
            />
            <Container fluid>
              <Row>
                {isEnableGroupBooking && (
                  <GroupInfoBooking
                    listGroup={listGroup}
                    groupId={this.state.data.groupId}
                    bookIdCurrent={this.props.data.bookId}
                    showDetailBookingGroup={this.showDetailBookingGroup}
                    showRemoveBookingModal={this.showRemoveBookingModal}
                    disableRemoveBtn={!pickupTimeDisable}
                    handleUngroupBooking={this.handleUngroupBooking}
                    removeBookingGroup={this.removeBookingGroup}
                  />
                )}
                <Col xs={12} md={9} className="info-content">
                  <Container fluid>
                    <Row>
                      <Col xs={12} md={4} className="info-content">
                        <DateMode
                          pickupTime={this.state.data.request.pickUpTime}
                          cartypeSelected={this.state.cartypeSelected}
                          getStatusRecurring={
                            this.props.bookingDetailActions.getStatusRecurring
                          }
                          loadingActions={this.props.loadingActions}
                          fleetId={this.props.auth.selectedFleet.fleetId}
                          recurringBookingList={this.state.recurringBookingList}
                          setRecurringDays={this.setRecurringDays}
                          recurringDays={this.state.recurringDays}
                          batchId={this.state.data.batchId}
                          isFinished={this.isFinished(this.state.data)}
                          nowButton
                          format24Hour={
                            auth.selectedFleet &&
                            auth.selectedFleet.format24Hour
                          }
                          showETA={
                            auth.selectedFleet.cue &&
                            auth.selectedFleet.cue.showETA
                          }
                          setMinutesToArrive={this.setMinutesToArrive}
                          isSuperHelper={isSuperHelper}
                          changeDateMode={this.changeDateMode}
                          onChangePickUpHour={this.onChangePickUpHour}
                          onChangePickUpMin={this.onChangePickUpMin}
                          handleDateChanged={this.handleTimeChanged}
                          dateMode={this.state.dateMode}
                          onChangeWeekDays={this.onChangeWeekDays}
                          weekDays={this.state.weekDays}
                          hasRecurring={hasRecurring}
                          bookDetails
                          pickupTimeDisableBkDetail={pickupTimeDisable}
                          updateToDate={this.updateToDate}
                          updateFromDate={this.updateFromDate}
                          toDate={this.state.toDate}
                          fromDate={this.state.fromDate}
                          recurringTime={this.state.recurringTime}
                          setRecurringTime={this.setRecurringTime}
                          jobType={jobType}
                          minutesToArrive={this.state.data.minutesToArrive}
                          bookingDetailOld={this.props.bookingDetail.data}
                          isHydraBooking={isHydraBooking}
                          farmIn={farmIn}
                        />
                        <RouteInfo
                          isBookDetails={!!this.state.data.bookId}
                          isPickupEditable={this.isPickupEditable}
                          format24Hour={
                            auth.selectedFleet &&
                            auth.selectedFleet.format24Hour
                          }
                          isDestinationEditable={this.isDestinationEditable}
                          route={this.state.route}
                          puPoints={this.state.data.puPoints}
                          doPoints={this.state.data.doPoints}
                          isChina={isChina}
                          setNewPuPoint={this.setNewPuPoint}
                          setNewDoPoint={this.setNewDoPoint}
                          limitPoint={limitPoint}
                          recentPlaces={recentPlaces}
                          checkPointInZoneSupported={
                            this.checkZoneSupportedWithPointComponent
                          }
                          data={this.state.data}
                          fleetId={fleetId}
                          partyLocation={moduleSettings.partyLocation}
                          isBookingFromPaxApp={isBookingFromPaxApp}
                          jobType={jobType}
                          autocompleteCustomer={
                            this.props.messageAction.autocompleteCustomer
                          }
                          auth={auth}
                          newbookingActions={this.props.newbookingActions}
                          customerActions={this.props.customerActions}
                          setNewDoPuPointAddTraveler={
                            this.setNewDoPuPointAddTraveler
                          }
                          corporateId={
                            (data.corporateInfo &&
                              data.corporateInfo.corporateId) ||
                            ''
                          }
                          isSubmited={this.state.isSubmited}
                          listDirtyLocation={this.state.listDirtyLocation}
                          setDirtyLocation={this.setDirtyLocation}
                          isHydraBooking={this.state.isHydraBooking}
                          // options={{ bounds: this.state.mapDefaultBound }}
                          options={this.state.optionAutocomplete}
                        />
                      </Col>
                      <Col xs={12} md={4} className="info-content">
                        {(!farmIn || (farmIn && isFarmInAcceptedStatus)) && (
                          <TravellerInfo
                            user={this.props.auth && this.props.auth.user}
                            auth={this.props.auth}
                            data={this.state.data}
                            selectedFleet={
                              this.props.auth && this.props.auth.selectedFleet
                            }
                            corporateCompany={this.props.corporateCompany}
                            handleCompanyChange={this.handleCompanyChange}
                            isSubmited={this.state.isSubmited}
                            valid={this.state.valid}
                            handleChangePhone={this.handleChangePhone}
                            suggestions={this.state.suggestions}
                            onSuggestionsFetchRequested={
                              this.onSuggestionsFetchRequested
                            }
                            onSuggestionsClearRequested={
                              this.onSuggestionsClearRequested
                            }
                            customerAutocompleteSlectedHandle={
                              this.customerAutocompleteSlectedHandle
                            }
                            handleBlurPhone={this.handleBlurPhone}
                            promo={this.state.promo}
                            CustomerVipClickHandle={this.CustomerVipClickHandle}
                            ValidatorCallback={this.ValidatorCallback}
                            phoneStatus={this.state.phoneStatus}
                            handleChangeFirstNameCorporate={
                              this.handleChangeFirstNameCorporate
                            }
                            newBooking={this.props.newBooking}
                            handleChangeEmailCorporate={
                              this.handleChangeEmailCorporate
                            }
                            customer={this.state.customer}
                            handleChangeDepartment={this.handleChangeDepartment}
                            handleChangeCorpDivision={
                              this.handleChangeCorpDivision
                            }
                            handleChangeManagerName={
                              this.handleChangeManagerName
                            }
                            handleChangeManagerEmail={
                              this.handleChangeManagerEmail
                            }
                            handleChangeCostCentre={this.handleChangeCostCentre}
                            handleChangeCorpId={this.handleChangeCorpId}
                            handleChangeFirstName={this.handleChangeFirstName}
                            handleChangeLastNameCorporate={
                              this.handleChangeLastNameCorporate
                            }
                            traveler_type={this.state.traveler_type}
                            isShowAll={this.state.showMoreCustomer}
                            handleShowMoreCustomer={this.handleShowMoreCustomer}
                          />
                        )}
                        <DispatchInfoChauffeur
                          user={this.props.auth && this.props.auth.user}
                          data={this.state.data}
                          corporate={this.state.company}
                          handleChangeDispatchType={
                            this.handleChangeDispatchType
                          }
                          newBooking={this.props.newBooking}
                          farmIn={farmIn}
                          farmOut={farmOut}
                          roaming={roaming}
                          isHydraBooking={this.state.isHydraBooking}
                          supportLocalBooking={this.state.supportLocalBooking}
                          supportHydraBooking={this.state.supportHydraBooking}
                          settingActions={this.props.settingActions}
                          supplierCompanies={this.state.supplierCompanies}
                          isSubmited={this.state.isSubmited}
                          driversuggestions={this.state.driversuggestions}
                          onDriverSuggestionsFetchRequested={
                            this.onDriverSuggestionsFetchRequested
                          }
                          onDriverSuggestionsClearRequested={
                            this.onDriverSuggestionsClearRequested
                          }
                          driverAutocompleteSlectedHandle={
                            this.driverAutocompleteSlectedHandle
                          }
                          handleDriverChanged={this.handleDriverChanged}
                          cartypeSelected={this.state.cartypeSelected}
                          driver={this.state.driver}
                          setVehicleAutoComplete={this.setVehicleAutoComplete}
                          bookingCurrency={bookingCurrency}
                          selectedFleet={
                            this.props.auth && this.props.auth.selectedFleet
                          }
                          handleDispatch3rdOnchange={
                            this.handleDispatch3rdOnchange
                          }
                          ValidatorCallback={this.ValidatorCallback}
                          valid={this.state.valid}
                          handleDriverCalendarClick={
                            this.handleDriverCalendarClick
                          }
                          handleRideSharingChange={this.handleRideSharingChange}
                          isEditable={this.isEditable}
                          isDispatchAble={this.isDispatchAble}
                          isShuttle={jobType === 'shuttle' ? true : false}
                          handleChangeNoteSuperHelper={
                            this.handleChangeNoteSuperHelper
                          }
                          handleChangeNote={this.handleChangeNote}
                          groupId={this.state.data.groupId}
                          showAssignDriverModal={this.showAssignDriverModal}
                          handleDetachDrv={this.handleDetachDrv}
                          handleUnassignVehicle={this.handleUnassignVehicle}
                          resetCounterVehicleTextSearch={
                            this.state.resetCounterVehicleTextSearch
                          }
                        />
                        <BookingInfoChauffeur
                          supplierCompanies={this.state.supplierCompanies}
                          zonePickup={this.state.zonePickup}
                          is3rdBooking={this.state.is3rdBooking}
                          nameThirdParty={this.state.nameThirdParty}
                          companyList={companyList}
                          isHydraBooking={this.state.isHydraBooking}
                          farmIn={farmIn}
                          farmOut={farmOut}
                          corporate={this.state.company}
                          nowButton={!this.state.data.reservation}
                          handleCarTypeChangedHydra={
                            this.handleCarTypeChangedHydra
                          }
                          etaFareMultiCar={this.state.etaFareMultiCar}
                          handleChangeSupplierCompanies={
                            this.handleChangeSupplierCompanies
                          }
                          commonData={this.props.commonData}
                          locationPickUp={this.state.locationPickUp}
                          newBooking={this.props.newBooking} // new booking common data
                          user={this.props.auth && this.props.auth.user} // logged user
                          selectedFleet={
                            this.props.auth && this.props.auth.selectedFleet
                          } // current fleet
                          data={this.state.data} // booking form data
                          cartypeSelected={this.state.cartypeSelected}
                          handleTimeChanged={this.handleTimeChanged}
                          handleCarTypeChanged={this.handleCarTypeChanged}
                          nearestDriver={this.state.nearestDriver}
                          isHourly={isHourly}
                          time={this.state.data.request.pickUpTime}
                          isRoundTrip={isRoundTrip}
                          etaFareProps={this.props.etaFare}
                          // etaFareState={this.state.etaFare}
                          etaFareState={this.state.data.request.estimate.fare}
                          isFareEdited={isFareEdited}
                          handleRoundtripChange={this.handleRoundtripChange}
                          handleBookingRateChange={this.handleBookingRateChange}
                          handleRideSharingChange={this.handleRideSharingChange}
                          handleDurationPackageChange={
                            this.handleDurationPackageChange
                          }
                          locationDestination={this.state.locationDestination}
                          onChangePickUpHour={this.onChangePickUpHour}
                          onChangePickUpMin={this.onChangePickUpMin}
                          handleChangeNote={this.handleChangeNote}
                          handleChangeNoteSuperHelper={
                            this.handleChangeNoteSuperHelper
                          }
                          isEditable={this.isEditable}
                          isPickupTimeEditable={this.isPickupTimeEditable}
                          isHasExtraDestination={isHasExtraDestination}
                          isMultiPuDoNotEmpty={isMultiPuDoNotEmpty}
                          multiPointCalFareMode={multiPointCalFareMode}
                          isShuttle={jobType === 'shuttle' ? true : false}
                          supplierId={this.state.supplierId}
                          setSupplier={this.setSupplier}
                          roaming={roaming}
                          isDisableWhenPrepaided={prepaided}
                          auth={this.props.auth}
                        />
                      </Col>
                      <Col xs={12} md={4} className="info-content">
                        <FlightInfo
                          newBooking={this.props.newBooking}
                          hydraStatus={hydraStatus}
                          flightInfo={
                            this.state.data.request.moreInfo &&
                            this.state.data.request.moreInfo.flightInfo
                          }
                          faFlightID={
                            this.state.data.request.moreInfo &&
                            this.state.data.request.moreInfo.flightInfo &&
                            this.state.data.request.moreInfo.flightInfo
                              .faFlightID
                          }
                          flightActions={this.props.flightActions}
                          fleetId={
                            this.props.auth &&
                            this.props.auth.selectedFleet.fleetId
                          }
                          selectedFleet={
                            this.props.auth && this.props.auth.selectedFleet
                          }
                          flightScheduleSuggestions={
                            this.state.flightScheduleSuggestions
                          }
                          onFlightScheduleSuggestionsFetchRequested={
                            this.onFlightScheduleSuggestionsFetchRequested
                          }
                          onFlightScheduleSuggestionsClearRequested={
                            this.onFlightScheduleSuggestionsClearRequested
                          }
                          flightScheduleAutocompleteSlectedHandle={
                            this.flightScheduleAutocompleteSlectedHandle
                          }
                          flightSchedule={this.state.flightSchedule}
                          handleFlightNumerChange={this.handleFlightNumerChange}
                          flightScheduleAplyButtonClick={
                            this.flightScheduleAplyButtonClick
                          }
                          bookingCurrency={bookingCurrency}
                          handleMeetdriverChange={this.handleMeetdriverChange}
                          handleChangeWelcomeMessage={
                            this.handleWelcomeMessageChange
                          }
                          data={this.state.data}
                          locationPickUp={this.state.locationPickUp}
                          locationDestination={this.state.locationDestination}
                          extraDestination={this.state.extraDestination}
                          isHydraBooking={this.state.isHydraBooking}
                          meetDriver={
                            this.state.data.request.moreInfo &&
                            this.state.data.request.moreInfo.flightInfo &&
                            this.state.data.request.moreInfo.flightInfo.type
                          }
                          welcomeMessage={
                            this.state.data.request.welcomeMessage
                          }
                          flightNumber={
                            this.state.data.request.moreInfo &&
                            this.state.data.request.moreInfo.flightInfo &&
                            this.state.data.request.moreInfo.flightInfo
                              .flightNumber
                          }
                          isEditable={this.isEditable}
                          is3rdBooking={is3rdBooking}
                        />
                        <PassengerLuggageNumber
                          data={this.state.data}
                          selectedFleet={
                            this.props.auth && this.props.auth.selectedFleet
                          }
                          isHydraBooking={this.state.isHydraBooking}
                          newBooking={this.props.newBooking}
                          cartypeSelected={this.state.cartypeSelected}
                          onChangePaxNumber={this.handleChangePaxNumber}
                          onChangeLuggageNumber={this.handleChangeLuggageNumber}
                          isSubmited={this.state.isSubmited}
                          valid={this.state.valid}
                          ValidatorCallback={this.ValidatorCallback}
                          isEditable={this.isEditable}
                        />
                        {!isHydraBooking &&
                          !farmIn &&
                          !isSupplierPermission && (
                            <ExtraServiceInfo
                              newBooking={this.props.newBooking}
                              data={this.state.data}
                              etaFare={this.state.data?.request?.estimate?.fare}
                              locationPickUp={this.state.locationPickUp}
                              cartypeSelected={this.state.cartypeSelected}
                              selected={this.state.data.extraServices}
                              onSelectAdditionalService={
                                this.handleAdditionalService
                              }
                              disabled={
                                !this.isExtraSerivicesEditable(
                                  this.state.data
                                ) ||
                                !this.checkCanEditWithBookingOtherCompany() ||
                                this.state.is3rdBooking ||
                                prepaided
                              }
                              selectedFleet={
                                this.props.auth && this.props.auth.selectedFleet
                              }
                              handleChangeNoteSuperHelper={
                                this.handleChangeNoteSuperHelper
                              }
                              handleChangeNote={this.handleChangeNote}
                              isShuttle={jobType === 'shuttle' ? true : false}
                            />
                          )}
                        {!isHydraBooking &&
                          !farmIn &&
                          !this.state.is3rdBooking &&
                          !isSupplierPermission && (
                            <PromoInfo
                              groupId={this.state.data.groupId}
                              bookId={this.state.data.bookId}
                              handleChangePromo={this.handleChangePromo}
                              data={this.state.data}
                              promo={this.state.promo}
                              newBooking={this.props.newBooking}
                              locationPickUp={this.state.locationPickUp}
                              handlePromoApplyClick={this.handlePromoApplyClick}
                              valid={this.state.valid}
                              isSubmited={this.state.isSubmited}
                              selectedFleet={
                                this.props.auth && this.props.auth.selectedFleet
                              }
                              handleChangeTip={this.handleChangeTip}
                              bookingCurrency={bookingCurrency}
                              ValidatorCallback={this.ValidatorCallback}
                              canEditPromo={this.canEditPromo}
                              isDispatchAble={this.isDispatchAble}
                              isDisableOtherOperation={
                                !this.checkCanEditWithBookingOtherCompany()
                              }
                              isDisableWhenPrepaided={prepaided}
                            />
                          )}
                        {!isSupplierPermission && (
                          <PaymentInfo
                            data={this.state.data}
                            isEditable={this.isEditable}
                            handleChangeBookingReference={
                              this.handleChangeBookingReference
                            }
                            companySelected={driverCompanyDetail}
                            handlePaymentMethodChange={
                              this.handlePaymentMethodChange
                            }
                            isAllwayShowGoogleApplePay={
                              this.state.isAllwayShowGoogleApplePay
                            }
                            isBookFromPWA={this.state.isBookFromPWA}
                            reloadCustomerTSYSGateway={
                              this.reloadCustomerTSYSGateway
                            }
                            paymentMethod={this.props.paymentMethod}
                            isHydraBooking={isHydraBooking}
                            farmIn={farmIn}
                            farmOut={farmOut}
                            newBooking={this.props.newBooking}
                            corporateTypes={
                              this.props.paymentMethod.corporateTypes
                            }
                            airlineTypes={this.props.paymentMethod.airlineTypes}
                            isSubmited={this.state.isSubmited}
                            valid={this.state.valid}
                            handleCreditCardChange={this.handleCreditCardChange}
                            handleAddCardSuccess={this.handleAddCardSuccess}
                            credit={this.state.credit}
                            roaming={this.state.roaming}
                            customer={this.state.customer}
                            selectedFleet={
                              this.props.auth && this.props.auth.selectedFleet
                            }
                            auth={this.props.auth}
                            company={this.state.company}
                            handleClientCaseMatterChange={
                              this.handleClientCaseMatterChange
                            }
                            handleChangeOperatorNote={
                              this.handleChangeOperatorNote
                            }
                            handleChargeCodeChange={this.handleChargeCodeChange}
                            ValidatorCallback={this.ValidatorCallback}
                            locationPickUp={this.state.locationPickUp}
                            isDispatchAble={this.isDispatchAble}
                            isShowmDispatcherCard={
                              this.state.isShowmDispatcherCard
                            }
                            isShuttle={jobType === 'shuttle' ? true : false}
                            isAirlineCorporate={isAirlineCorporate}
                            is3rdBooking={this.state.is3rdBooking}
                            isDisableOtherOperation={
                              !this.checkCanEditWithBookingOtherCompany()
                            }
                            isDisableWhenPrepaided={prepaided}
                          />
                        )}
                        <Notes
                          farmIn={farmIn}
                          farmOut={farmOut}
                          handleChangeNote={this.handleChangeNote}
                          handleChangeAffiliateNote={
                            this.handleChangeAffiliateNote
                          }
                          data={this.state.data}
                          isNotesEnable={this.isNotesEnable}
                          cartypeSelected={this.state.cartypeSelected}
                          selectedFleet={
                            this.props.auth && this.props.auth.selectedFleet
                          }
                          isHydraBooking={isHydraBooking}
                          auth={this.props.auth}
                          handleChangeOperatorNote={
                            this.handleChangeOperatorNote
                          }
                        />
                        {isBookingCompleted &&
                          (isFareEdited ||
                            _.get(data, 'request.estimate.markupValue', 0) >
                              0) && (
                            <EditFareComponent
                              viewHistory={true}
                              etaFare={data.request.estimate.fare}
                              data={data}
                              markupValue={_.get(
                                this.state.data,
                                'request.estimate.markupValue',
                                0
                              )}
                              markupType={_.get(
                                this.state.data,
                                'request.estimate.markupType',
                                'amount'
                              )}
                              reasonMarkup={_.get(
                                this.state.data,
                                'request.estimate.reasonMarkup',
                                ''
                              )}
                              allowEditFare={isFareEdited}
                              locationPickUp={this.state.locationPickUp}
                              prevEtaFare={this.state.prevEtaFare}
                              bookType={BOOK_TYPE_FEE.transport}
                              isDisableOtherOperation={this.checkCanEditWithBookingOtherCompany()}
                              selectedFleet={
                                this.props.auth && this.props.auth.selectedFleet
                              }
                            />
                          )}
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs={12} md={3} className="map-container">
                  <Col xs={12} className="booking-map-container">
                    <BookingMapChauffeur
                      showNavigationPUDO={showNavigationPUDO}
                      handleMapLoad={this.handleMapLoad}
                      center={this.state.center}
                      locationPickUp={this.state.locationPickUp}
                      puPoints={this.state.data.puPoints}
                      doPoints={this.state.data.doPoints}
                      isPickupCanDrag={this.isPickupEditable()}
                      isDestinationCanDrag={this.isDestinationEditable()}
                      handlePickupDrapEnd={this.handlePickupDrapEnd}
                      pickupFrom={this.state.pickupFrom}
                      locationDestination={this.state.locationDestination}
                      handleDestinationDrapEnd={this.handleDestinationDrapEnd}
                      destinationFrom={this.state.destinationFrom}
                      extraDestination={this.state.extraDestination}
                      handleExtraDestinationDrapEnd={
                        this.handleExtraDestinationDrapEnd
                      }
                      directions={this.state.directions}
                      isChina={location ? location.isChina : false}
                    />
                  </Col>
                  <div style={{ clear: 'both' }} />
                  <Col xs={12}>
                    <TripEstimate
                      cartypeSelected={this.state.cartypeSelected}
                      isAcceptConfirm3rdBookingStatus={
                        isAcceptConfirm3rdBookingStatus
                      }
                      companyList={companyList}
                      supplierCompanies={this.state.supplierCompanies}
                      puPoints={puPointsNotEmpty}
                      doPoints={doPointsNotEmpty}
                      nameThirdParty={this.state.nameThirdParty}
                      etaFareMultiCar={this.state.etaFareMultiCar}
                      isTransportBooking={this.props.isTransportBooking}
                      etaFareLocalOrigin={this.state.etaFareLocalOrigin}
                      fleetMarkup={this.state.fleetMarkup}
                      hydraStatusText={hydraStatusText}
                      isDisableOtherOperation={this.checkCanEditWithBookingOtherCompany()}
                      saveHolidayTaxisCompletedEditFare={
                        this.saveHolidayTaxisCompletedEditFare
                      }
                      newBooking={this.props.newBooking}
                      isHydraBooking={isHydraBooking}
                      destinationFrom={this.state.destinationFrom}
                      addressDestination={_.get(
                        this.state.data,
                        'request.destination.address',
                        {}
                      )}
                      data={this.state.data}
                      farmIn={farmIn}
                      farmOut={farmOut}
                      roaming={roaming}
                      is3rdBooking={this.state.is3rdBooking}
                      isApiDemandBooking={this.state.isApiDemandBooking}
                      etaFare={this.state.etaFare}
                      promo={this.state.promo}
                      isFareEditedBefore={this.state.isFareEditedBefore}
                      selectedFleet={
                        this.props.auth && this.props.auth.selectedFleet
                      }
                      user={this.props.auth && this.props.auth.user}
                      auth={this.props.auth}
                      disDur={this.state.disDur}
                      locationPickUp={this.state.locationPickUp}
                      locationDestination={this.state.locationDestination}
                      isHasExtraDestination={isHasExtraDestination}
                      etaProps={this.props.etaFare}
                      bookingPermission={this.props.bookingPermission}
                      prevEtaFare={this.state.prevEtaFare}
                      handleChangeETAFare={this.handleChangeETAFare}
                      handleChangeMarkupPrice={this.handleChangeMarkupPrice}
                      driver={this.state.driver}
                      bookType={
                        jobType === 'shuttle'
                          ? BOOK_TYPE_FEE.shuttle
                          : BOOK_TYPE_FEE.transport
                      }
                      settingActions={this.props.settingActions}
                      commonData={this.props.commonData}
                    />
                    {jobType !== 'shuttle' && (
                      <TrailNotes
                        notification={this.context.notification}
                        settingActions={this.props.settingActions}
                        bookId={this.state.data.bookId}
                        auth={this.props.auth}
                        fleetId={this.props.auth.selectedFleet.fleetId}
                        uploadService={this.props.uploadService}
                        setShowTrailNote={(active) =>
                          this.setState({ showTrailNote: active })
                        }
                      />
                    )}
                    <div className="fill text-center btnBookingDetail">
                      {this.isNewBookingAble(this.state.data) &&
                      !isStreetSharingType &&
                      !is3rdBooking &&
                      this.props.permissions?.actions &&
                      !isSupplierPermission &&
                      !farmIn ? (
                        <Button
                          onClick={this.newBookingButtonClick}
                          className="btn-send"
                        >
                          <Translate value="bookingdetail.New_Booking" />
                        </Button>
                      ) : (
                        ''
                      )}
                      {this.state.data && !isSupplierPermission && (
                        <DispatchLogs data={this.state.data} />
                      )}
                      {!isStreetSharingType &&
                      !isFarmInPendingStatus &&
                      hasPermissionAction &&
                      (this.isAbleUpdateBooking(this.state.data) ||
                        this.isDispatchAble(this.state.data)) ? (
                        <Button
                          disabled={
                            (this.state.data.dispatchType == 1 &&
                              this.state.data.reservation === true &&
                              this.state.data.status !== 'confirmed' &&
                              this.state.data.status !== 'booked' &&
                              this.state.data.status !== 'arrived' &&
                              this.state.data.status !== 'action' &&
                              this.state.data.status !== 'pre' &&
                              this.state.data.status !== 'pending' &&
                              !this.state.driver &&
                              this.state.data.drvInfo) ||
                            _.isEmpty(locationPickUp) ||
                            (!this.isFinished(this.state.data) &&
                              jobType == 'shuttle' &&
                              _.isEmpty(locationDestination)) ||
                            (this.state.listDirtyLocation &&
                              this.state.listDirtyLocation.length > 0)
                          }
                          onClick={this.updateBookingButtonClick}
                          className="btn-send"
                        >
                          <Translate value="bookingdetail.Update_Booking" />
                        </Button>
                      ) : (
                        ''
                      )}
                      {auth.user.userType !== userType.CorporateAdmin &&
                        auth.user.userType !== userType.CorporateUser &&
                        hasPermissionAction &&
                        ((this.props.data.drvInfo &&
                          !_.isString(this.props.data.drvInfo) &&
                          this.props.data.drvInfo.userId &&
                          this.props.data.drvInfo.userId.length > 0) ||
                          (this.props.data.psgInfo &&
                            !_.isString(this.props.data.psgInfo) &&
                            this.props.data.psgInfo.userId &&
                            this.props.data.psgInfo.userId.length > 0)) && (
                          <Button
                            onClick={this.sendSMSBooking}
                            className="btn-send"
                          >
                            <Translate value="bookingdetail.sendMessage" />
                          </Button>
                        )}

                      {checkShowChargeInvoicingBtn(
                        this.props.data,
                        is3rdBooking,
                        isApiDemandBooking,
                        isSupplierPermission
                      ) &&
                        this.props?.permissions?.actions &&
                        hasPermissionAction && (
                          <Button
                            onClick={this.showChargeInvoicingModal}
                            className="btn-send"
                          >
                            <Translate value="bookingdetail.Charge" />
                          </Button>
                        )}

                      {overrideBookingStatus &&
                      this.props?.permissions?.actions &&
                      auth.user.userType !== userType.CorporateAdmin &&
                      auth.user.userType !== userType.CorporateUser &&
                      !HIDE_OVERRIDE_BUTTON_STATUS.includes(
                        this.state.data.status
                      ) &&
                      !farmOut &&
                      !(
                        is3rdBooking &&
                        ['pending', 'action', 'allocated', 'pre'].includes(
                          data.status
                        )
                      ) &&
                      !isFarmInAcceptedStatus &&
                      this.checkCanEditWithBookingOtherCompany() ? (
                        <OverrideButton
                          auth={auth}
                          fleetId={
                            this.props.auth &&
                            this.props.auth.selectedFleet.fleetId
                          }
                          is3rdBooking={is3rdBooking}
                          isSupplierPermission={isSupplierPermission}
                          booking={this.state.data}
                          isHydraBooking={isHydraBooking}
                          farmIn={farmIn}
                          bookId={this.state.data.bookId}
                          loadingActions={this.props.loadingActions}
                          bookingDetailActions={this.props.bookingDetailActions}
                          status={this.state.data.status}
                          customerId={
                            (data && data.psgInfo && data.psgInfo.userId) || ''
                          }
                          closeBooking={this.close}
                          notification={this.context.notification}
                          completeWithPayment={
                            this.completeWithPaymentButtonClick
                          }
                          completeWithoutService={
                            this.completeWithoutServiceButtonClick
                          }
                          paymentType={this.state.data.request.paymentType}
                        />
                      ) : this.isCompletable(this.state.data) &&
                        !isStreetSharingType &&
                        !farmOut &&
                        !isFarmInPendingStatus &&
                        !isSupplierPermission &&
                        hasPermissionAction &&
                        this.checkCanEditWithBookingOtherCompany() ? (
                        <Button
                          className="btn-reset"
                          onClick={this.completeWithPaymentButtonClick}
                          // disabled={this.state.isFormDirty}
                          title={
                            this.state.isFormDirty
                              ? I18n.t('bookingdetail.Complete_booking_tooltip')
                              : ''
                          }
                        >
                          <Translate value="bookingdetail.Complete_with_payment" />
                        </Button>
                      ) : (
                        ''
                      )}
                      {this.isCancelable(this.state.data) &&
                        !farmIn &&
                        !isStreetSharingType &&
                        !isFarmInPendingStatus &&
                        !(
                          is3rdBooking &&
                          nameThirdParty === thirdPartyIntegration.bookingAPI
                        ) &&
                        hasPermissionAction &&
                        !isSupplierPermission &&
                        hasPermissionAction &&
                        this.checkCanEditWithBookingOtherCompany() && (
                          <Button
                            onClick={this.cancelBookingButtonClick}
                            className="btn-cancel"
                          >
                            <Translate value="bookingdetail.Cancel_Booking" />
                          </Button>
                        )}

                      {this.isCompletable(this.state.data) &&
                      hasPermissionAction &&
                      !isAcceptConfirm3rdBookingStatus ? (
                        !this.state.data.dispatch3rd ? (
                          !isHydraBooking && !isFarmInPendingStatus ? (
                            <Button
                              onClick={this.incidentButtonClick}
                              className="btn-cancel"
                            >
                              <Translate value="bookingdetail.Incident" />
                            </Button>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                      {isFarmInPendingStatus && hasPermissionAction &&(
                        <Button
                          onClick={this.handleAcceptHydraBooking}
                          className="btn-send"
                        >
                          <Translate value="bookingdetail.Accept" />
                        </Button>
                      )}
                      {isFarmInPendingStatus && hasPermissionAction &&(
                        <Button
                          onClick={this.handleDenyHydraBooking}
                          className="btn-cancel"
                        >
                          <Translate value="bookingdetail.Deny" />
                        </Button>
                      )}

                      {is3rdBooking && !isSupplierPermission && hasPermissionAction && (
                        <Accept3rdButton
                          isAcceptConfirm3rdBookingStatus={
                            isAcceptConfirm3rdBookingStatus
                          }
                          statusExtenalInfo={statusExtenalInfo}
                          nameThirdParty={nameThirdParty}
                          bookInfo={this.state.data}
                          handleAcceptOrConfirm3rdPartyBooking={
                            this.handleAcceptOrConfirm3rdPartyBooking
                          }
                        />
                      )}
                      {isRject3rdBookingDotCom 
                        && !isSupplierPermission 
                        && hasPermissionAction && (
                        <Button
                          onClick={this.handleReject3rdPartyBooking}
                          className="btn-cancel"
                        >
                          <Translate value="bookingdetail.Reject" />
                        </Button>
                      )}

                      {(isFarmInAcceptedStatus || // farmIn accepted
                        (farmIn &&
                          this.state.isBookFromPWA &&
                          !_.get(
                            this.props.bookingDetail,
                            'data.isFarmOut',
                            false
                          ))) && // case is roaming
                        this.canReject(this.state.data) && (
                          <Button
                            onClick={this.rejectBookingButtonClick}
                            className="btn-cancel"
                          >
                            <Translate value="bookingdetail.Reject" />
                          </Button>
                        )}
                    </div>

                    <ul className="promotion_note">
                      {promoCodeMessageContent.schedules ? (
                        <li className="text-soft-warning">
                          <Translate value="bookingdetail.promotion_note_schedules" />
                        </li>
                      ) : null}
                      {promoCodeMessageContent.minimumEstFare !== '' ? (
                        <li className="text-soft-warning">
                          <Translate
                            value="bookingdetail.promotion_note_minimum_fare"
                            fare={promoCodeMessageContent.minimumEstFare}
                          />
                        </li>
                      ) : null}
                      {promoCodeMessageContent.paymentMethods.length > 0 &&
                      !this.state.data.groupId ? (
                        <li className="text-soft-warning">
                          <Translate
                            value="bookingdetail.promotion_note_paymentMethod"
                            method={promoCodeMessageContent.paymentMethods
                              .map((p) =>
                                p === `21`
                                  ? walletName
                                  : I18n.t(`General.patmentMethod_${p}`)
                              )
                              .join(', ')}
                            //method={promoCodeMessageContent.paymentMethods.map(p => I18n.t(`General.patmentMethod_${p}`)).join(', ')}
                          />
                        </li>
                      ) : null}
                    </ul>
                    <ConfirmEditRecurringModal
                      showConfirmRecurring={this.state.showConfirmRecurring}
                      showHideConfirmRecurringModal={
                        this.showHideConfirmRecurringModal
                      }
                      dataConfirmRecurring={this.state.dataConfirmRecurring}
                    />
                    {checkShowViewPaymentLogsBtn(
                      this.state.data,
                      is3rdBooking,
                      isSupplierPermission
                    ) && (
                      <Button
                        onClick={this.viewPaymentLogButtonClick}
                        variant="link"
                        className="paymentLogBtn"
                      >
                        <Translate value="bookingdetail.ViewPaymentLogs" />
                      </Button>
                    )}
                    {this.state.showPaymentLogsModal &&
                      !isSupplierPermission && (
                        <PaymentLogs
                          bookInfo={this.state.data}
                          hasPermissionAction={
                            permissions && permissions.actions
                          }
                          handleClose={() => {
                            this.setState({ showPaymentLogsModal: false })
                          }}
                        />
                      )}
                    {!isSupplierPermission && (
                      <TrackLink
                        status={this.state.data && this.state.data.status}
                        pickupTime={this.props.data.request.pickUpTime}
                        delivery={this.props.data.delivery}
                        trackUrl={
                          this.props.auth &&
                          this.props.auth.selectedFleet &&
                          this.props.auth.selectedFleet.trackLink
                        }
                        tokenBooking={this.state.data && this.state.data.token}
                      />
                    )}
                  </Col>
                </Col>
              </Row>
            </Container>

            <div style={{ clear: 'fixed' }} />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
BookingDetail.propTypes = {
  show: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}
BookingDetail.contextTypes = {
  notification: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    newBooking: state.newBooking,
    auth: state.auth,
    customerAutocomplete: state.customerAutocomplete,
    corporateCompany: state.corporateCompany,
    driverAutocomplete: state.driverAutocomplete,
    paymentMethod: state.paymentMethod,
    etaFare: state.etaFare,
    socket: state.socket,
    permissions: state.menuHandle.modulePermission,
    commonData: state.commonData,
    bookingDetail: state.bookingDetail,
    bookingPermission: state.menuHandle.bookingPermission,
    language: state.i18n,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
    driverAutocompleteActions: bindActionCreators(
      driverAutocompleteActions,
      dispatch
    ),
    customerAutocompleteActions: bindActionCreators(
      customerAutocompleteActions,
      dispatch
    ),
    etaFareActions: bindActionCreators(etaFareActions, dispatch),
    cueActions: bindActionCreators(cueActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
    creditCardActions: bindActionCreators(creditCardActions, dispatch),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    flightActions: bindActionCreators(flightActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
    driverActions: bindActionCreators(driverActions, dispatch),
    corporateActions: bindActionCreators(corporateActions, dispatch),
    messageAction: bindActionCreators(messageAction, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    uploadService: bindActionCreators(uploadActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BookingDetail)
