import React, { Component } from 'react';
import {
    Button,
    Modal,
    FormGroup,
    FormControl
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

class CancelConfirmationModal extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        const {
            showConfirmCancel,
            cancelBookingButtonClick,
            cancelBookingOkClick,
            msgChargeCancel
        } = this.props


        return <Modal show={showConfirmCancel} backdrop={true} dialogClassName='confirm-dialog'
            onHide={cancelBookingButtonClick}
            className={'confirm'}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate value='bookingdetail.CONFIRM_CANCEL' /></Modal.Title>
            </Modal.Header>
            <Modal.Body dangerouslySetInnerHTML={{ __html: msgChargeCancel ? msgChargeCancel : I18n.t('bookingdetail.CONFIRM_CANCEL_MESSAGE') }}>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-save" onClick={cancelBookingOkClick}><Translate value='bookingdetail.Yes' /></Button>
                <Button className="btn-cancel" onClick={cancelBookingButtonClick}><Translate value='bookingdetail.No' /></Button>
            </Modal.Footer>
        </Modal>
    }
}

export default CancelConfirmationModal