import React from "react";
import { withGoogleMap, GoogleMap, Polygon, Polyline } from "react-google-maps";
import { ZoneActionStatus } from "../../constants/commondata";

export default withGoogleMap(props => (
  <GoogleMap
    ref={map => {
      props.onMapLoad("_map", map);
    }}
    defaultZoom={10}
    zoom={props.defaultZoom}
    center={props.center || { lat: 16.059959, lng: 108.224258 }}
    onClick={props.handleMapClick}
    options={{ gestureHandling: "greedy" }}
    onCenterChanged={props.onCenterChanged}
  >
    {props.status == ZoneActionStatus.Add
      ? props.zoneDraw.Zones.map(zone => {
        if (
          props.zoneDraw.activeZone &&
          zone._id == props.zoneDraw.activeZone._id
        ) {
          return (
            <Polygon
              key={zone._id}
              ref={map => {
                props.onMapLoad("Polygon", map);
              }}
              paths={props.zoneDraw.activeZone.path}
              editable={props.editable}
              onMouseUp={props.onDragEnd}
              options={{
                strokeColor: "blue",
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: "#00b6fb",
                fillOpacity: 0.4,
                zIndex: 2
              }}
            />
          );
        } else {
          return (
            <Polygon
              key={zone._id}
              paths={zone.path}
              onMouseUp={() => {
                props.onDragEnd(zone);
              }}
              options={{
                strokeColor: "yellow",
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: "red",
                fillOpacity: 0.4,
                zIndex: 2
              }}
            />
          );
        }
      })
      : ""}
    {props.status == ZoneActionStatus.View ? (
      props.zoneDraw.Zones.map(zone => {
        if (
          props.zoneDraw.activeZone &&
          zone._id == props.zoneDraw.activeZone._id
        ) {
          return (
            <Polygon
              key={zone._id}
              paths={props.zoneDraw.activeZone.path}
              editable={props.editable}
              onMouseUp={props.onDragEnd}
              options={{
                strokeColor: "blue",
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: "#00b6fb",
                fillOpacity: 0.4,
                zIndex: 2
              }}
            />
          );
        } else {
          return (
            <Polygon
              key={zone._id}
              paths={zone.path}
              onMouseUp={() => {
                props.onDragEnd(zone);
              }}
              options={{
                strokeColor: "yellow",
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: "red",
                fillOpacity: 0.4,
                zIndex: 2
              }}
            />
          );
        }
      })
    ) : props.status == ZoneActionStatus.Edit ? (
      props.zoneDraw.Zones.map(zone => {
        if (
          props.zoneDraw.activeZone &&
          zone._id == props.zoneDraw.activeZone._id
        ) {
          return props.polyline ? (
            <Polyline
              ref={map => {
                props.onMapLoad("Polyline", map);
              }}
              onClick={props.handlePolylineClick}
              path={props.zoneDraw.activeZone.path}
              editable={props.editable}
              onMouseUp={props.onDragEnd}
              options={{
                strokeColor: "blue",
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: "#00b6fb",
                fillOpacity: 0.4,
                zIndex: 2
              }}
            />
          ) : (
              <Polygon
                key={zone._id}
                ref={map => {
                  props.onMapLoad("Polygon", map);
                }}
                paths={props.zoneDraw.activeZone.path}
                editable={props.editable}
                onMouseUp={props.onDragEnd}
                options={{
                  strokeColor: "blue",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  fillColor: "#00b6fb",
                  fillOpacity: 0.4,
                  zIndex: 2
                }}
              />
            );
        } else {
          return (
            <Polygon
              key={zone._id}
              paths={zone.path}
              onMouseUp={() => {
                props.onDragEnd(zone);
              }}
              options={{
                strokeColor: "yellow",
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: "red",
                fillOpacity: 0.4,
                zIndex: 2
              }}
            />
          );
        }
      })
    ) : props.polyline ? (
      <Polyline
        ref={map => {
          props.onMapLoad("Polyline", map);
        }}
        onClick={props.handlePolylineClick}
        events={{
          click: props.handlePolylineClick
        }}
        path={props.zoneDraw.activeZone.path}
        editable={props.editable}
        onMouseUp={props.onDragEnd}
        options={{
          strokeColor: "blue",
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: "#00b6fb",
          fillOpacity: 0.4,
          zIndex: 2
        }}
      />
    ) : (
            <Polygon
              ref={map => {
                props.onMapLoad("Polygon", map);
              }}
              paths={props.zoneDraw.activeZone.path}
              editable={props.editable}
              onMouseUp={props.onDragEnd}
              options={{
                strokeColor: "blue",
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: "#00b6fb",
                fillOpacity: 0.4,
                zIndex: 2
              }}
            />
          )}
    {props.zoneDraw.parentZone && (
      <Polygon
        key={props.zoneDraw.parentZone._id}
        paths={props.zoneDraw.parentZone.path}
        editable={false}
        onClick={props.handleMapClick}
        onMouseUp={props.onDragEnd}
        options={{
          strokeColor: "#e89110",
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: "#d3d3d3",
          fillOpacity: 0.4,
          zIndex: 1
        }}
      />
    )}
    {props.zoneDraw.parentZones && props.zoneDraw.parentZones.length ? (
      props.zoneDraw.parentZones.map(zone => (
        <Polygon
          key={zone._id}
          paths={zone.path}
          editable={false}
          onClick={props.handleMapClick}
          onMouseUp={props.onDragEnd}
          options={{
            strokeColor: "#e89110",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: "#d3d3d3",
            fillOpacity: 0.4,
            zIndex: 1
          }}
        />
      ))
    ) : null}
  </GoogleMap>
));
