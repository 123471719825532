import React from 'react';
import {
  FormGroup,
  FormControl,
  Form,
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import _ from 'lodash';
import {
  Validation,
  roundOff,
  getCompanyIdForETA,
  textboxNumberHelper,
  calculatorFleetORAdditionalService,
  CCLiteCommonFunc,
} from '../../../../utils/commonFunctions';
import { Validator, ValidCase } from '../../../validator';
import {
  userType,
  PAYOUT_CUSTOM_TYPE,
  BOOK_TYPE_FEE,
  thirdPartyIntegration,
  REGULAR_MODE,
  PAYOUT_TBD,
} from '../../../../constants/commondata';
import { getFareFields } from '../../../../utils/commonFunctions';
import FieldTextbox from './FieldTextbox';
import currencyFormatter from 'currency-formatter';
import {
  calculateFareWhenEdit,
  calculatePayout,
  calculatorPayoutWhenCustom,
} from '../../bookFunction/bookingInfo';
import QuestionCircleTooltip from '../../../questionCircleTooltip/QuestionCircleTooltip';
class ModalEditFare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editFare: {},
      preferredAdjustment: 1,
      markupType: 'default',
      markupValue: null,
      reasonMarkup: '',
      reasonEditFare: '',
      originFare: {},
      currency: {},
      changeFare: false,
      isFareEdited: false,
      isReseted: false,
      subField: [],
      mainField: [],
      supplierEarningType: 'default',
      editedSupplierEarning: 0,
      profitValue: 0,
      isHolidayTaxisCompletedBK:
        props.is3rdBooking &&
        props.data?.externalInfo?.thirdParty ===
          thirdPartyIntegration.holidaytaxis &&
        CCLiteCommonFunc.isBookingStatusCompleted(props.data.status),
    };
  }

  convertTransactonFee = (
    total = 0,
    creditTransactionFeeAmount = 0,
    creditTransactionFeePercent = 0
  ) => {
    return parseFloat(
      creditTransactionFeePercent * total * 0.01 + creditTransactionFeeAmount
    ).toFixed(2);
  };

  componentDidMount() {
    const {
      allowEditFare,
      allowMarkupPrice,
      enableEditDriverEarning,
      etaFare,
      selectedFleet,
      fareSettings,
      prevEtaFare,
      bookType,
      reasonMarkup,
      markupType,
      markupValue,
      forceMarkupPrice,
      data,
      isHydraBooking,
      fleetMarkup,
      user,
      driverCompany,
      driver,
      is3rdBooking,
    } = this.props;

    if (bookType) {
      const fareFields = getFareFields(
        selectedFleet,
        fareSettings,
        etaFare,
        bookType,
        data,
        is3rdBooking
      );
      this.setState({
        subField: fareFields.subField,
        mainField: fareFields.mainField,
      });
    }

    if (!allowEditFare && allowMarkupPrice) {
      this.setState({
        preferredAdjustment: 2,
      });
    }

    if (etaFare) {
      if (markupValue > 0 || forceMarkupPrice || fleetMarkup) {
        this.setState(
          {
            preferredAdjustment: 2,
            markupType: markupType,
            markupValue: isHydraBooking ? fleetMarkup : markupValue,
            reasonMarkup: reasonMarkup,
            editFare: etaFare,
            originFare:
              prevEtaFare && prevEtaFare.isFareEdited
                ? prevEtaFare.originFare
                : etaFare,
            reasonEditFare: '',
          },
          () => {
            if (isHydraBooking) {
              this.markupValueCalculatorHydra();
            } else {
              this.markupValueCalculator();
            }
          }
        );
      } else {
        this.setState({
          editFare: etaFare,
          originFare:
            prevEtaFare && prevEtaFare.isFareEdited
              ? prevEtaFare.originFare
              : etaFare,
          reasonEditFare:
            prevEtaFare && prevEtaFare.reasonEditFare
              ? prevEtaFare.reasonEditFare
              : '',
        });
      }
    }

    if (markupValue || fleetMarkup) {
      this.setState({
        isFareEdited: true,
      });
    } else if (prevEtaFare && prevEtaFare.isFareEdited) {
      this.setState({
        isFareEdited: prevEtaFare.isFareEdited,
      });
    }

    if (selectedFleet.currencies) {
      const currencySymbol =
        selectedFleet.currencies.find(
          (item) => item.iso === etaFare.currencyISO
        ) || {};
      this.setState({
        currency: currencySymbol,
      });
    }

    // case: Edit fare with completed booking of holidaytaxis
    if (this.state.isHolidayTaxisCompletedBK) {
      this.setState({
        editFare: {
          etaFare: data?.completedInfo?.total || 0,
        },
      });
    }

    const userTypeCurrent = _.get(user, 'userType', '');
    const companyIdOfUser = _.get(user, 'roles.companyId', '');
    const isFleetUser =
      userTypeCurrent === userType.FleetUser && companyIdOfUser;
    this.setState({}, () => {
      this.calculatorProfitDriver();
    });
    if (isFleetUser) {
      this.setState(
        {
          isFleetUser,
        },
        () => {
          this.calculatorProfitDriver();
        }
      );
    } else {
      const companyId = getCompanyIdForETA(driverCompany, driver);
      if (companyId) {
        this.props.settingActions
          .geDetailCompany({
            fleetId: selectedFleet.fleetId,
            companyId: companyId,
          })
          .then(({ error, res }) => {
            if (!error && !_.isEmpty(res)) {
              this.setState(
                {
                  companyInfoSelected: {
                    ...res,
                  },
                },
                () => {
                  this.calculatorProfitDriver();
                }
              );
            }
          });
      }
      this.calculatorProfitDriver();
    }
  }

  handleEditInputField = (e, key) => {
    let value = e.target.value;
    if (this.props.is3rdBooking && key === 'etaFare') {
      this.setState({
        editFare: {
          ...this.state.editFare,
          [key]: value,
          basicFare: value,
        },
        changeFare: true,
        isReseted: false,
      });
    } else {
      this.setState(
        {
          editFare: {
            ...this.state.editFare,
            [key]: value,
          },
          changeFare: true,
          isReseted: false,
        },
        () => {
          this.editFareCalculator(key);
        }
      );
    }
  };

  handleChangeReasonField = (e) => {
    let value = e.target.value;
    this.setState({
      reasonEditFare: value,
    });
  };

  handleChangeReasonMarkupField = (e) => {
    let value = e.target.value;
    this.setState({
      reasonMarkup: value,
    });
  };

  handleEditMakupValue = (e) => {
    let value = e.target.value;
    this.setState(
      {
        markupValue: value,
        changeMarkup: true,
        isReseted: false,
      },
      () => {
        if (this.props.isHydraBooking) {
          this.markupValueCalculatorHydra();
        } else {
          this.markupValueCalculator();
        }
      }
    );
  };

  markupValueCalculator = () => {
    const { markupValue, editFare, markupType } = this.state;
    let markupValueNumber = parseFloat(markupValue);
    let makeupPrice = editFare.etaFare;
    if (markupType === 'default') {
      this.setState({
        makeupPrice: null,
        markupDifference: 0,
      });
      return;
    }
    if (markupType == 'amount') {
      makeupPrice += markupValueNumber;
    } else {
      makeupPrice +=
        Math.round(
          ((makeupPrice * markupValueNumber) / 100 + Number.EPSILON) * 100
        ) / 100;
    }
    this.setState({
      makeupPrice: makeupPrice,
      markupDifference:
        Math.round(
          ((makeupPrice || editFare.etaFare) -
            editFare.etaFare +
            Number.EPSILON) *
            100
        ) / 100,
    });
  };

  handlePreferredAdjustmentChange = (e) => {
    const selected = parseInt(e.target.value);
    const { originFare } = this.state;
    this.setState({
      editFare: originFare,
      changeFare: false,
      preferredAdjustment: selected,
    });
  };
  markupValueCalculatorHydra = () => {
    const { markupValue, editFare, markupType } = this.state;
    let markupValueNumber = parseFloat(markupValue);
    let makeupPrice = editFare.qupSellPrice;
    makeupPrice += markupValueNumber;
    // if (markupType == 'amount') {
    // } else {
    //     makeupPrice += Math.round(((makeupPrice * markupValueNumber / 100) + Number.EPSILON) * 100) / 100;
    // }
    this.setState({
      makeupPrice: makeupPrice,
      markupDifference:
        Math.round(
          ((_.isNumber(makeupPrice) ? makeupPrice : editFare.qupSellPrice) -
            editFare.qupSellPrice +
            Number.EPSILON) *
            100
        ) / 100,
    });
  };

  handlePreferredAdjustmentChange = (e) => {
    const selected = parseInt(e.target.value);
    const { originFare } = this.state;
    this.setState({
      editFare: originFare,
      changeFare: false,
      preferredAdjustment: selected,
    });
  };

  handleMarkupTypeChange = (e) => {
    this.setState(
      {
        markupType: e.target.value,
      },
      () => {
        this.markupValueCalculator();
      }
    );
  };

  handleSupplierEarningTypeChange = (e) => {
    const value = e.target.value;
    this.setState({
      editFare: {
        ...this.state.editFare,
        supplierEarningType: value,
      },
      changeFare: true,
      profitValue:
        value === PAYOUT_CUSTOM_TYPE.default ? 0 : this.state.profitValue,
    });
  };

  handleEditSupplierEarningValue = (e) => {
    this.setState({
      editFare: {
        ...this.state.editFare,
        editedSupplierEarning: e.target.value,
      },
      changeFare: true,
    });
  };

  calculatorProfitDriver = () => {
    const { editFare } = this.state;
    if (editFare.supplierEarningType === PAYOUT_CUSTOM_TYPE.default) return 0;
    return editFare.etaFare - calculatorPayoutWhenCustom(editFare, this.props.serviceFeeZone, this.props.fareSettings?.fare, this.props.selectedFleet) + (parseFloat(this.state.markupDifference) || 0);
  };

  editFareCalculator = () => {
    const { editFare = {}, changeFare, currency } = this.state;
    const { selectedFleet, promoInfo, data, etaFare } = this.props;
    this.setState({
      editFare: calculateFareWhenEdit({
        editFare,
        changeFare,
        currency,
        selectedFleet,
        promoInfo,
        data,
        minFare: etaFare?.minFare || 0,
      }),
    });
  };

  handleClickResetValue = () => {
    this.setState(
      {
        editFare: {...this.state.originFare, supplierEarningType: 'default', editedSupplierEarning: 0},
        reasonEditFare: '',
        changeFare: true,
        isReseted: true,
        markupValue: 0,
        reasonMarkup: '',
        commissionCompanyType: PAYOUT_CUSTOM_TYPE.default,
        commissionCompanyValue: 0,
      },
      () => {
        if (this.props.isHydraBooking) {
          this.markupValueCalculatorHydra();
        } else {
          this.markupValueCalculator();
        }
      }
    );
  };

  renderEditDriverEarning = () => {
    const {
      isSubmitted,
      valid,
      ValidatorCallback,
      enableEditDriverEarning = false,
      viewHistory,
      data,
    } = this.props;
    const { currency, editFare } = this.state;
    const disable = viewHistory;
    const paymentType = data.bookId
      ? data?.request?.paymentType
      : data?.paymentMethod;

    return (
      <>
        {enableEditDriverEarning && (
          <>
            <Row>
              <Col xs={3}>
                <FormGroup>
                  <Form.Label>
                    <Translate value="newbooking.DriverPayOut" />
                  </Form.Label>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <FormControl
                    as="select"
                    className="form-custom"
                    placeholder="select"
                    onChange={this.handleSupplierEarningTypeChange}
                    value={editFare.supplierEarningType}
                    disabled={disable}
                  >
                    <option value={PAYOUT_CUSTOM_TYPE.default}>
                      {I18n.t('driver.Default')}
                    </option>
                    <option value={PAYOUT_CUSTOM_TYPE.amount}>
                      {I18n.t('driver.Amount')}
                    </option>
                    <option value={PAYOUT_CUSTOM_TYPE.percent}>
                      {I18n.t('driver.Percentage')}
                    </option>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
            {editFare.supplierEarningType &&
              editFare.supplierEarningType !== PAYOUT_CUSTOM_TYPE.default &&
              !this.props.isCorporateBoard && (
                <>
                  <Row key={'supplierEarningValue'}>
                    <Col xs={3}></Col>
                    <Col xs={6}>
                      <FormGroup
                        className={`qup-input-group form-edit-fare ${
                          isSubmitted
                            ? valid.supplierEarningValue === false
                              ? 'error'
                              : null
                            : null
                        }`}
                      >
                        <InputGroup className="single-addon-left">
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {editFare.supplierEarningType == 'amount'
                                ? currency.symbol
                                : '%'}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type="number"
                            className="form-custom"
                            onChange={this.handleEditSupplierEarningValue}
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(e, (e) =>
                                this.handleEditSupplierEarningValue(e)
                              );
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(e, (e) =>
                                this.handleEditSupplierEarningValue(e)
                              );
                            }}
                            value={editFare.editedSupplierEarning}
                            disabled={disable}
                          />
                        </InputGroup>
                        <Validator
                          id={'supplierEarningValue'}
                          callback={ValidatorCallback}
                        >
                          <ValidCase
                            valid={
                              !Validation.isStringEmpty(
                                editFare.editedSupplierEarning
                              )
                            }
                            message={I18n.t(
                              'messages.commonMessages.Required_field'
                            )}
                            hide={!isSubmitted}
                          />
                          <ValidCase
                            valid={Validation.isNumber(
                              editFare.editedSupplierEarning
                            )}
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!isSubmitted}
                          />
                          {editFare.supplierEarningType ===
                            PAYOUT_CUSTOM_TYPE.amount &&
                            paymentType === 1 && (
                              <ValidCase
                                valid={
                                  editFare.editedSupplierEarning <=
                                  editFare.etaFare
                                }
                                message={I18n.t(
                                  'messages.commonMessages.driver_earnings_warning'
                                )}
                                hide={!isSubmitted}
                              />
                            )}
                          {editFare.supplierEarningType ===
                            PAYOUT_CUSTOM_TYPE.percent &&
                            paymentType === 1 && (
                              <ValidCase
                                valid={editFare.editedSupplierEarning <= 100}
                                message={I18n.t(
                                  'messages.commonMessages.value_must_between'
                                ).format(0, 100)}
                                hide={!isSubmitted}
                              />
                            )}
                        </Validator>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row key={'difference'}>
                    <Col xs={3}>
                      <FormGroup className={'form-edit-fare'}>
                        <Form.Label className="edit-fare label-bold">
                          <Translate value="supplier.totalPayout" />
                          <QuestionCircleTooltip
                            text={I18n.t('supplier.totalPayoutDes')}
                          />
                        </Form.Label>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup className="form-edit-fare">
                        <Form.Label className="edit-fare label-bold total-fare">
                          <span>
                            {currencyFormatter.format(
                              calculatorPayoutWhenCustom(this.state.editFare, this.props.serviceFeeZone, this.props.fareSettings?.fare, this.props.selectedFleet),
                              {
                                code: currency.iso,
                              }
                            )}
                          </span>
                        </Form.Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row key={'difference'}>
                    <Col xs={3}>
                      <FormGroup className={'form-edit-fare'}>
                        <Form.Label className="edit-fare label-bold">
                          <Translate value="cue.editFare.Profit" />
                        </Form.Label>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup className="form-edit-fare">
                        <Form.Label className="edit-fare label-bold total-fare">
                          <span>
                            {currencyFormatter.format(
                              this.calculatorProfitDriver(),
                              {
                                code: currency.iso,
                              }
                            )}
                          </span>
                        </Form.Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
          </>
        )}
      </>
    );
  };

  hanldClickSaveButton = () => {
    const {
      preferredAdjustment,
      editFare,
      originFare,
      reasonEditFare,
      changeFare,
      isReseted,
      subField,
      mainField,
      markupDifference = 0,
      reasonMarkup,
      markupType,
      markupValue,
      makeupPrice,
      isHolidayTaxisCompletedBK,
    } = this.state;
    if (isHolidayTaxisCompletedBK) {
      this.props?.saveHolidayTaxisCompletedEditFare(editFare.etaFare);
      return;
    }
    if (preferredAdjustment == 1) {
      let convertEditFare = _.cloneDeep(editFare);
      subField.forEach((keyName) => {
        if (typeof convertEditFare[keyName] === 'string') {
          convertEditFare[keyName] = parseFloat(editFare[keyName]) || 0;
        }
      });
      mainField.forEach((keyName) => {
        if (typeof convertEditFare[keyName] === 'string') {
          convertEditFare[keyName] = parseFloat(editFare[keyName]) || 0;
        }
      });
      if (editFare.supplierEarningType !== PAYOUT_CUSTOM_TYPE.default) {
        convertEditFare.totalPayout = calculatorPayoutWhenCustom(editFare, this.props.serviceFeeZone, this.props.fareSettings?.fare, this.props.selectedFleet)
      } else {
        if (this.props.data?.supplierCompanies?.length === 1 || this.props.driver) {
          let supSelect = this.props.commonData?.suppliers?.find(item => item._id === (this.props?.data?.supplierCompanies?.[0] || this.props.driver?.company?.companyId));
            convertEditFare.totalPayout = calculatorPayoutWhenCustom(editFare, this.props.serviceFeeZone, this.props.fareSettings?.fare, this.props.selectedFleet, supSelect, 
              this.props.bookType,
              this.props.fareSettings || this.state.fareSettings,
              this.props.driver
            )

        }
      };
      this.props.handleSaveEditFare(
        convertEditFare,
        originFare,
        reasonEditFare,
        changeFare,
        isReseted
      );
    } else {
      const supplierEarning = {
        editedSupplierEarning: parseFloat(editFare.editedSupplierEarning) || 0,
        supplierEarningType:
          editFare.supplierEarningType || PAYOUT_CUSTOM_TYPE.default,
      };
      if (supplierEarning.supplierEarningType !== PAYOUT_CUSTOM_TYPE.default) {
        supplierEarning.totalPayout = calculatorPayoutWhenCustom(editFare, this.props.serviceFeeZone, this.props.fareSettings?.fare, this.props.selectedFleet)
      } else {
        if (this.props.data.supplierCompanies.length === 1 || this.props.driver) {
          let supSelect = this.props.commonData?.suppliers?.find(item => item._id === (this.props?.data?.supplierCompanies?.[0] || this.props.driver?.company?.companyId));
            convertEditFare.totalPayout = calculatorPayoutWhenCustom(editFare, this.props.serviceFeeZone, this.props.fareSettings?.fare, this.props.selectedFleet, supSelect,
              this.props.bookType,
              this.props.fareSettings || this.state.fareSettings,
              this.props.driver
            )
        }
      };
      this.props.handleSaveMarkupPrice(
        originFare,
        markupDifference,
        reasonMarkup,
        markupType,
        markupValue,
        isReseted,
        makeupPrice,
        supplierEarning
      );
    }
  };

  renderMarkupPrice = () => {
    const {
      isSubmitted,
      valid,
      ValidatorCallback,
      viewHistory = false,
      allowMarkupPrice,
    } = this.props;
    let { reasonMarkup } = this.state;
    const {
      isReseted,
      markupDifference,
      makeupPrice,
      editFare,
      markupType,
      markupValue,
      currency,
    } = this.state;
    const disable = viewHistory || !allowMarkupPrice;
    return (
      <>
        <Row key={'quotedPrice'}>
          <Col xs={3}>
            <FormGroup>
              <Form.Label>
                <Translate value="cue.editFare.quotedPrice" />
              </Form.Label>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Form.Label className="edit-fare label-bold total-fare">
                <span>
                  {currencyFormatter.format(editFare.etaFare, {
                    code: currency.iso,
                  })}
                </span>
              </Form.Label>
            </FormGroup>
          </Col>
        </Row>
        <Row key={'preferredEarning'}>
          <Col xs={3}>
            <FormGroup>
              <Form.Label>
                <Translate value="cue.editFare.preferredEarning" />
              </Form.Label>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <FormControl
                as="select"
                className="form-custom"
                placeholder="select"
                onChange={this.handleMarkupTypeChange}
                value={markupType}
                disabled={disable}
              >
                <option value="default">{I18n.t('driver.Default')}</option>
                <option value="amount">{I18n.t('driver.Amount')}</option>
                <option value="percent">{I18n.t('driver.Percentage')}</option>
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        {markupType !== 'default' && (
          <Row key={'preferredEarningValue'}>
            <Col xs={3}></Col>
            <Col xs={6}>
              <FormGroup
                className={`qup-input-group form-edit-fare ${
                  isSubmitted
                    ? valid.markupValue === false
                      ? 'error'
                      : null
                    : null
                }`}
              >
                <InputGroup className="single-addon-left">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      {markupType == 'amount' ? currency.symbol : '%'}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="text"
                    disabled={disable}
                    className="form-custom"
                    onChange={(e) => this.handleEditMakupValue(e)}
                    value={markupValue}
                  />
                </InputGroup>
                <Validator id={'markupValue'} callback={ValidatorCallback}>
                  <ValidCase
                    valid={!Validation.isStringEmpty(markupValue)}
                    message={I18n.t('messages.commonMessages.Required_field')}
                    hide={!isSubmitted}
                  />
                  <ValidCase
                    valid={Validation.isNumber(markupValue)}
                    message={I18n.t('messages.commonMessages.must_be_number')}
                    hide={!isSubmitted}
                  />
                  {markupType == 'percent' && (
                    <ValidCase
                      valid={markupValue <= 100}
                      message={I18n.t(
                        'messages.commonMessages.value_must_between'
                      ).format(0, 100)}
                      hide={!isSubmitted}
                    />
                  )}
                  <ValidCase
                    valid={0 < markupValue || isReseted}
                    message={I18n.t(
                      'messages.commonMessages.greater_than'
                    ).format(0)}
                    hide={!isSubmitted}
                  />
                  <ValidCase
                    valid={
                      makeupPrice < editFare.etaFare * 10 ||
                      editFare.etaFare === 0
                    }
                    message={I18n.t(
                      'messages.commonMessages.large_10x_markup_price'
                    ).format(0)}
                    hide={!isSubmitted}
                  />
                </Validator>
              </FormGroup>
            </Col>
          </Row>
        )}
        {markupType == 'percent' && (
          <Row key={'difference'}>
            <Col xs={3}></Col>
            <Col xs={6}>
              <FormGroup className="qup-input-group form-edit-fare">
                <Form.Label className="edit-fare difference-fare">
                  <span>
                    {I18n.t('cue.editFare.difference')}:{' '}
                    {currencyFormatter.format(markupDifference, {
                      code: currency.iso,
                    })}
                  </span>
                </Form.Label>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row key={'markupPrice'}>
          <Col xs={3}>
            <FormGroup>
              <Form.Label>
                <Translate value="cue.editFare.markupPrice" />
              </Form.Label>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Form.Label className="edit-fare label-bold total-fare">
                <span>
                  {currencyFormatter.format(makeupPrice || editFare.etaFare, {
                    code: currency.iso,
                  })}
                </span>
              </Form.Label>
            </FormGroup>
          </Col>
        </Row>
        {this.renderEditDriverEarning()}
        <FormGroup>
          <Form.Label>
            <Translate value="cue.Reason" />
          </Form.Label>
          <FormControl
            type="text"
            className="form-custom"
            onChange={(e) => this.handleChangeReasonMarkupField(e)}
            value={reasonMarkup || ''}
            maxLength={500}
            disabled={viewHistory || !this.props.allowMarkupPrice}
          />
        </FormGroup>
      </>
    );
  };

  renderMarkupPriceHydra = () => {
    const {
      isSubmitted,
      valid,
      ValidatorCallback,
      viewHistory = false,
      allowEditFare,
      allowMarkupPrice,
      isFareEditedBefore,
    } = this.props;
    const {
      isReseted,
      markupDifference,
      makeupPrice,
      editFare,
      markupType,
      reasonMarkup,
      markupValue,
      currency,
    } = this.state;
    return (
      <div className="markupHydra">
        <Row key={'preferredEdit'}>
          <Col xs={12}>
            <Form.Label>
              <Translate value="cue.editFare.preferredAdjustment" />
            </Form.Label>
            <FormGroup>
              <FormControl
                as="select"
                className="form-custom"
                placeholder="select"
                onChange={this.handlePreferredAdjustmentChange}
                value={this.state.preferredAdjustment}
                disabled={
                  isFareEditedBefore || !allowEditFare || !allowMarkupPrice
                }
              >
                <option value="1">{I18n.t('cue.editFare.editFareFee')}</option>
                <option value="2">{I18n.t('cue.editFare.markupPrice')}</option>
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        <Row key={'quotedPrice'} className={'showFare'}>
          <Col xs={5}>
            <Form.Label>
              <Translate value="cue.editFare.quotedPrice" />
            </Form.Label>
          </Col>
          <Col xs={7}>
            <FormGroup>
              <Form.Label className="edit-fare label-bold total-fare">
                <span>
                  {currencyFormatter.format(editFare.qupSellPrice, {
                    code: currency.iso,
                  })}
                </span>
              </Form.Label>
            </FormGroup>
          </Col>
        </Row>
        <Row key={'preferredEarning'}>
          <Col xs={12}>
            <Form.Label>
              <Translate value="cue.editFare.preferredEarning" />
            </Form.Label>
            <FormGroup>
              <FormControl
                as="select"
                className="form-custom"
                placeholder="select"
                onChange={this.handleMarkupTypeChange}
                value={markupType}
                disabled={true}
              >
                <option value="amount">{I18n.t('driver.Amount')}</option>
                {/* <option value="percent">
                                    {I18n.t('driver.Percentage')}
                                </option> */}
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        <Row key={'preferredEarningValue'}>
          <Col xs={12}>
            <FormGroup
              className={`qup-input-group form-edit-fare ${
                isSubmitted
                  ? valid.markupValue === false
                    ? 'error'
                    : null
                  : null
              }`}
            >
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {currency.symbol}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={(e) => this.handleEditMakupValue(e)}
                  value={markupValue}
                />
              </InputGroup>
              <Validator id={'markupValue'} callback={ValidatorCallback}>
                <ValidCase
                  valid={!Validation.isStringEmpty(markupValue)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                  hide={!isSubmitted}
                />
                <ValidCase
                  valid={Validation.isNumber(markupValue)}
                  message={I18n.t('messages.commonMessages.must_be_number')}
                  hide={!isSubmitted}
                />
                {markupType == 'percent' && (
                  <ValidCase
                    valid={markupValue <= 100}
                    message={I18n.t(
                      'messages.commonMessages.value_must_between'
                    ).format(0, 100)}
                    hide={!isSubmitted}
                  />
                )}
                <ValidCase
                  valid={
                    (_.isNumber(makeupPrice)
                      ? makeupPrice
                      : editFare.qupSellPrice) >= 0
                  }
                  message={'Invalid markup amount'}
                  hide={!isSubmitted}
                />
                <ValidCase
                  valid={
                    makeupPrice < editFare.qupSellPrice * 10 ||
                    editFare.qupSellPrice === 0
                  }
                  message={I18n.t(
                    'messages.commonMessages.large_10x_markup_price'
                  ).format(0)}
                  hide={!isSubmitted}
                />
              </Validator>
            </FormGroup>
          </Col>
        </Row>
        <Row key={'markupPrice'} className={'showFare showFareTop'}>
          <Col xs={5}>
            <FormGroup>
              <Form.Label>
                <Translate value="newbooking.totalFare" />
              </Form.Label>
            </FormGroup>
          </Col>
          <Col xs={7}>
            <FormGroup>
              <Form.Label className="edit-fare label-bold total-fare">
                <span>
                  {currencyFormatter.format(
                    _.isNumber(makeupPrice)
                      ? makeupPrice
                      : editFare.qupSellPrice,
                    {
                      code: currency.iso,
                    }
                  )}
                </span>
              </Form.Label>
            </FormGroup>
          </Col>
          <Col xs={5} className={'showFare'}>
            <FormGroup>
              <Form.Label>
                <Translate value="newbooking.Profit" />
              </Form.Label>
            </FormGroup>
          </Col>
          <Col xs={7}>
            <FormGroup>
              <Form.Label className="edit-fare label-bold total-fare">
                <span>
                  {currencyFormatter.format(
                    (_.isEmpty(makeupPrice)
                      ? makeupPrice
                      : editFare.qupSellPrice) - editFare.qupSellPrice,
                    {
                      code: currency.iso,
                    }
                  )}
                </span>
              </Form.Label>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  renderMainEdit = () => {
    const {
      isSubmitted,
      valid,
      ValidatorCallback,
      viewHistory = false,
      allowEditFare,
      data,
      is3rdBooking,
    } = this.props;
    const {
      editFare,
      reasonEditFare,
      originFare,
      currency,
      subField,
      mainField,
    } = this.state;
    return (
      <>
        <div>
          {subField?.map((item) => (
            <FieldTextbox
              key={item}
              keyField={item}
              valueField={editFare[item]}
              originValue={originFare[item] || 0}
              currencySymbol={currency.symbol}
              currencyISO={currency.iso}
              handleEditInputField={this.handleEditInputField}
              isSubmitted={isSubmitted}
              validField={valid[item]}
              ValidatorCallback={ValidatorCallback}
              disabled={
                viewHistory || this.props.is3rdBooking || !allowEditFare
              }
            />
          ))}
        </div>
        <div>
          {mainField?.map((item) => {
            let valueField = editFare[item];
            if (item === 'fleetService') valueField = editFare.serviceFee;

            return (
              <FieldTextbox
                key={item}
                keyField={item}
                valueField={valueField}
                isMinimumTotal={editFare.isMinimumTotal}
                originValue={
                  is3rdBooking && item === 'etaFare'
                    ? data?.completedInfo?.total || 0
                    : originFare[item] || 0
                }
                currencySymbol={currency.symbol}
                currencyISO={currency.iso}
                handleEditInputField={this.handleEditInputField}
                is3rdBooking={is3rdBooking}
                data={data}
                isSubmitted={isSubmitted}
                validField={valid[item]}
                ValidatorCallback={ValidatorCallback}
                disabled={
                  item === 'creditTransactionFee' ||
                  !allowEditFare ||
                  this.props.is3rdBooking
                    ? true
                    : viewHistory
                }
              />
            );
          })}
        </div>

        {viewHistory && (
          <FormGroup>
            <div className="promotion_note">
              <span className="text-soft-warning">
                <Translate value="bookingdetail.edited_fare_note" />
              </span>
            </div>
          </FormGroup>
        )}

        {this.renderEditDriverEarning()}

        <FormGroup>
          <Form.Label>
            <Translate value="cue.Reason" />
          </Form.Label>
          <FormControl
            type="text"
            className="form-custom"
            onChange={(e) => this.handleChangeReasonField(e)}
            value={reasonEditFare || ''}
            maxLength={500}
            disabled={viewHistory || !this.props.allowEditFare}
          />
        </FormGroup>
      </>
    );
  };

  render() {
    const {
      isFareEditedBefore,
      allowEditFare,
      allowMarkupPrice,
      selectedFleet,
      bookId = '',
      handleCloseModalEditFare,
      viewHistory = false,
      isHydraBooking = false,
    } = this.props;
    const { isFareEdited } = this.state;
    return (
      <Modal
        show={true}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="confirm"
        bsSize={`${isHydraBooking ? 'sm' : 'lg'}`}
        onHide={handleCloseModalEditFare}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {bookId && (
              <>
                <Translate value="cue.Booking" className="white-text" />
                <span className="white-text">:</span> #{bookId}
                <span className="white-text"> - </span>
              </>
            )}
            <Translate
              value={`${
                isHydraBooking ? 'newbooking.adjustFare' : 'cue.Adjust_price'
              }`}
              className="white-text transform-none"
            />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={handleCloseModalEditFare}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {selectedFleet.generalSetting.markupPrice &&
            !isHydraBooking &&
            !this.props.is3rdBooking && (
              <Row key={'preferredEdit'}>
                <Col xs={3}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="cue.editFare.preferredAdjustment" />
                    </Form.Label>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <FormControl
                      as="select"
                      className="form-custom"
                      placeholder="select"
                      onChange={this.handlePreferredAdjustmentChange}
                      value={this.state.preferredAdjustment}
                      disabled={
                        isFareEditedBefore ||
                        !allowEditFare ||
                        !allowMarkupPrice
                      }
                    >
                      <option value="1">
                        {I18n.t('cue.editFare.editFareFee')}
                      </option>
                      <option value="2">
                        {I18n.t('cue.editFare.markupPrice')}
                      </option>
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
            )}
          {this.state.preferredAdjustment == 1
            ? this.renderMainEdit()
            : isHydraBooking
            ? this.renderMarkupPriceHydra()
            : this.renderMarkupPrice()}
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button className="btn-cancel" onClick={handleCloseModalEditFare}>
            {viewHistory
              ? I18n.t('bookingdetail.Close')
              : I18n.t('bookingdetail.Cancel')}
          </Button>
          {!viewHistory && (
            <>
              {(isFareEdited || this.state?.editFare?.supplierEarningType != 'default') && (
                <Button
                  className={'btn-reset'}
                  onClick={this.handleClickResetValue}
                >
                  <Translate value="bookingdetail.Reset" />
                </Button>
              )}
              <Button
                className="btn-save mr-md"
                onClick={this.hanldClickSaveButton}
              >
                {I18n.t('bookingdetail.Save')}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalEditFare;
