import React, { Component } from 'react';
import {
  Button,
  Modal
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { Fragment } from 'react';

const CancelStatus = ['pre', 'confirmed', 'action', 'offered']

class CancelButton extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleIncidentReasonChanged = (e) => {
    this.setState({
      reason: e.target.value,
    });
  };

  handleRefundFareChanged = (e) => {
    this.setState({
      refundFare: e.target.checked,
    });
  };

  closeModal = () => {
    this.setState({ visible: false });
  }

  showConfirm = () => {
    this.setState({ visible: true, reason: '', refundFare: false });
  }

  handleSubmit = (e) => {
    this.props.onSubmit({
      reason: this.state.reason,
      refundFare: this.state.refundFare,
    })
  }

  render() {
    const {
      bookInfo,
    } = this.props;

    const { visible } = this.state;
    if (!bookInfo || !CancelStatus.includes(bookInfo.status)) {
      return null;
    }
    const isOfflinePayment = [1].includes(bookInfo.request.paymentType);
    return (
      <Fragment>
        <Button onClick={this.showConfirm} className="btn-cancel mr">
          <Translate value="bookingdetail.Cancel" />
        </Button>
        <Modal
          show={visible}
          backdrop={true}
          dialogClassName="confirm-dialog"
          onHide={this.closeModal}
          className={'confirm'}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="intercityBooking.ConfirmCancel" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body dangerouslySetInnerHTML={{ __html: I18n.t('bookingdetail.CONFIRM_CANCEL_MESSAGE') }}>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-cancel" onClick={this.closeModal}>
              <Translate value="bookingdetail.Cancel" />
            </Button>
            <Button
              className="btn-save"
              onClick={this.handleSubmit}
            >
              <Translate value="bookingdetail.Ok" />
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default CancelButton;
