import React, { PureComponent, Fragment } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import _ from 'lodash';

class ConfirmUpdateModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {}
  }

  closeModal = () => {
    this.props.onClose()
  }

  render() {
    const {
      bookInfo,
      visible,
      showWarningResetEditFare
    } = this.props;
    return (
      <Modal
        show={visible}
        backdrop
        dialogClassName="confirm-dialog update-book-detail-modal"
        onHide={this.closeModal}
        className={'confirm'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="bookingdetail.CONFIRM_UPDATE" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Translate value="bookingdetail.CONFIRM_UPDATE_MESSAGE" />
          {showWarningResetEditFare &&
            <div>
              <Translate value="bookingdetail.CONFIRM_RESET_EDIT_FARE_MESSAGE" />
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.closeModal} className="btn-reset">
            <Translate value="bookingdetail.No" />
          </Button>
          <Button onClick={this.props.onUpdate} className="btn-save">
            <Translate value="bookingdetail.Update_info" />
          </Button>
          {/* <Button
            variant="success"
            className="btn-save"
            onClick={this.props.onUpdateAndDispatch}
          >
            <Translate value="bookingdetail.Update_info_dispatch" />
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmUpdateModal;
