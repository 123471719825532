import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate, I18n } from "react-redux-i18n";
import IntlTelInputApp from "../../components/intlTelInputCustome/IntlTelInputApp";
import { Validator, ValidCase } from "../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../utils/commonFunctions";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import Confirm from "../../components/confirm/Confirm";
import {
  getOperator,
  operateOperator,
  deleteUserCorporate,
  resetPasswordCorporate
} from "../../actions/corporateAction";
import { trimPhoneNumber } from "../../utils/commonFunctions";
import Moment from "react-moment";
import _ from "lodash";

import {
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Row,
  Col
} from "react-bootstrap";
import PropTypes from "prop-types";
import StickyTable from "../../components/table/stickyTable/StickyTable";
import TableActions from "../../components/table/tableAction/TableActions";
import { CorpOperatorColumns, CorpOperatorActions } from "./tableHeaderColums";
import { BsSearch } from "react-icons/bs";

const CONFIRM_DELETE_LINK_ID = 1;
let deleteUserId;
class CorpOperator extends Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      isSubmitted: false,
      activePage: 1,
      detailItem: null,
      confirm: null,
      phoneValidation: true,
      detailItem: {},
      shouldRender: false,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        page: 0,
        limit: 20,
        total: 0
      }
    };
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleSaveOperatorClick = this.handleSaveOperatorClick.bind(this);

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleCancelAddingClick = this.handleCancelAddingClick.bind(this);
    this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
    this.handleConfirmCloseClick = this.handleConfirmCloseClick.bind(this);
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
  }
  componentDidMount() {
    this.getListOperators(0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isSubmitted: false,
      phoneValidation: true,
      shouldRenderTable:
        nextProps.contentHeight &&
        nextProps.contentWidth &&
        nextProps.contentHeight !== this.props.contentHeight &&
        nextProps.contentWidth !== this.props.contentWidth
    });
  }

  getListOperators = (activePage, limit) => {
    if (this.props.corporateId) {
      let param = {
        limit: limit || this.state.footerData.limit,
        page:
          activePage || activePage === 0
            ? activePage
            : this.state.footerData.page,
        query: {
          fleetId: this.props.auth.selectedFleet.fleetId
        },
        corpId: this.props.corporateId
      };
      if (this.state.str) {
        param.query.str = this.state.str.trim();
      }
      this.props.getOperator(param).then(data => {
        if (data.ok) {
          let { page, total, limit } = data.res;
          this.setState({
            operator: data.res,
            footerData: { page, total, limit }
          });
        }
      });
    }
  };

  handleMenuClick(eventKey, detailItem) {
    if (eventKey == 0) {
      this.setState({
        isAdding: true,
        detailItem: detailItem
      });
    } else {
      deleteUserId = detailItem._id;
      this.setState({
        confirm: {
          id: CONFIRM_DELETE_LINK_ID,
          title: I18n.t("corporate.Delete"),
          body: I18n.t("corporate.CONFIRM_DELETE_USER"),
          buttonTitle: "Yes",
          closeButtonText: "No"
        }
      });
    }
  }

  handleAddClick() {
    this.setState({ isAdding: true });
  }

  handleCancelClick() {
    this.props.router.push({
      pathname: "/corporate"
    });
  }

  handleCancelAddingClick() {
    this.setState({ isAdding: false, detailItem: {} });
  }
  handleUsernameChange(e) {
    this.setState({
      detailItem: { ...this.state.detailItem, userName: e.target.value }
    });
  }
  handleFirstNameChange(e) {
    this.setState({
      detailItem: { ...this.state.detailItem, firstName: e.target.value }
    });
  }
  handleLastNameChange(e) {
    this.setState({
      detailItem: { ...this.state.detailItem, lastName: e.target.value }
    });
  }
  handlePhoneChange(status, value, countryData, number, id) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        phone: number
      },
      phoneValidation: status
    });
  }
  handleEmailChange(e) {
    this.setState({
      detailItem: { ...this.state.detailItem, email: e.target.value }
    });
  }
  handleAddressChange(e) {
    this.setState({
      detailItem: { ...this.state.detailItem, address: e.target.value }
    });
  }

  handlePaginationSelect = page => this.getListOperators(parseInt(page));

  handleNumItemsPerPageChange = limit => this.getListOperators(0, limit);

  handleSearchChange = str => {
    this.setState({
      str: str
    });
  };

  handleSearchKeyPress = e => {
    if (e.key === "Enter") {
      this.getListOperators(0);
    }
  };

  handleSaveOperatorClick() {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.props.loadingBarActions.showLoadingSpiner();
    this.props
      .operateOperator({
        _id: this.state.detailItem._id,
        fleetId: this.props.auth.selectedFleet.fleetId,
        corpId: this.props.corporateId,
        username: this.state.detailItem.userName && this.state.detailItem.userName.trim(),
        firstName: this.state.detailItem.firstName,
        lastName: this.state.detailItem.lastName
          ? this.state.detailItem.lastName
          : "",
        phone: this.state.detailItem.phone
          ? trimPhoneNumber(this.state.detailItem.phone)
          : "",
        email: this.state.detailItem.email
      })
      .then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.ok) {
          if (this.state.detailItem._id) {
            this.context.notification(
              "success",
              I18n.t("corporate.INFO_UPDATE_CORPORATE_SUCCESS")
            );
          } else {
            this.context.notification(
              "success",
              I18n.t("corporate.INFO_ADD_CORPORATE_SUCCESS").format(
                this.state.detailItem.userName,
                this.state.detailItem.email
              )
            );
          }
          let newList;
          if (!this.state.detailItem._id) {
            newList = this.state.operator.list;
            newList.unshift(data.res);
          } else {
            newList = this.state.operator.list.map(op => {
              if (op._id == data.res._id) {
                return data.res;
              }
              return op;
            });
          }
          this.setState({
            isSubmitted: false,
            isAdding: false,
            detailItem: {},
            operator: { ...this.state.operator, list: newList }
          });
        } else {
          this.setState({ isSubmitted: false });
          if (data && data.message) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.message.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          }
        }
      });
  }

  handleResetPasswordClick() {
    this.setState({
      confirm: {
        title: I18n.t("corporate.Reset_password"),
        body: I18n.t("corporate.COMFIRM_RESET_PASSWORD"),
        buttonTitle: "Yes",
        closeButtonText: "No"
      }
    });
  }

  handleConfirmButtonClick(id) {
    if (id === CONFIRM_DELETE_LINK_ID) {
      this.props
        .deleteUserCorporate({
          fleetId: this.props.auth.selectedFleet.fleetId,
          corpId: this.props.corporateId,
          _id: deleteUserId
        })
        .then(data => {
          if (data.ok) {
            this.getListOperators();
          } else {
            this.setState({ isSubmitted: false });
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          }
        });
    } else {
      this.props
        .resetPasswordCorporate({
          fleetId: this.props.auth.selectedFleet.fleetId,
          corpId: this.props.corporateId,
          _id: this.state.detailItem._id
        })
        .then(data => {
          if (data.ok) {
            this.context.notification(
              "success",
              I18n.t("corporate.INFO_RESET_PASSWORD_SUCCESS").format(
                this.state.detailItem.userName,
                this.state.detailItem.email
              )
            );
          } else {
            this.setState({ isSubmitted: false });
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          }
        });
    }
    this.handleConfirmCloseClick();
  }

  handleConfirmCloseClick() {
    this.setState({
      confirm: null
    });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  getTableColumns = () => {
    let tableColumns = Object.assign([], CorpOperatorColumns);
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case "createdDate":
          col.customCell = data => (
            <p>
              <Moment
                tz={this.props.auth.selectedFleet.timezone}
                format="MM/DD/YYYY"
                date={data.createdDate}
              />
            </p>
          );
          break;
        case "actions":
          if (this.props.permissions && this.props.permissions.actions)
            col.customCell = (data, rowIndex) =>
              (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={data}
                  onSelect={this.handleMenuClick}
                  totalRow={
                    this.state.operator
                      ? this.state.operator.list
                        ? this.state.operator.list.length
                        : 0
                      : 0
                  }
                  rowHeight={this.state.rowHeight}
                  menuItems={CorpOperatorActions}
                  tableHeight={this.state.tableHeight}
                  tableId="corporate-operator-table"
                />
              );
          else tableColumns.splice(index, 1);
          break;
        default:
          break;
      }
    });
    return tableColumns;
  };

  getTableHeight = () => {
    let contentHeight =
      this.props.getContentHeight() || this.props.contentHeight;
    let toolbarHeight = this.toolbarContainer
      ? this.toolbarContainer.clientHeight || 64
      : 64;
    let toolbarMarginBottom = 10;
    let tableHeight = contentHeight
      ? contentHeight - (toolbarMarginBottom + toolbarHeight)
      : 0;
    if (window.innerHeight < 850 && tableHeight < 500) tableHeight = 550;
    this.state.tableHeight = tableHeight;
    return tableHeight;
  };

  render() {
    const bodyData =
      this.props.contentHeight < 1 || this.props.contentWidth < 1
        ? []
        : this.state.operator &&
          this.state.operator.list &&
          this.state.operator.list.length > 0
          ? this.state.operator.list
          : [];
    const hasPermission = !this.props.permissions || this.props.permissions.actions

    return (
      <div className="corp-operator-wrapper">
        {this.state.isAdding && hasPermission ? (
          <div className="w-100">
            <div className="content add-form form-detail-container">
              <Row>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup
                    className={
                      !this.state.isSubmitted
                        ? null
                        : this.state.valid.userName === false
                          ? "error"
                          : null
                    }
                  >
                    <Form.Label>
                      <Translate value="corporate.Username" />
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      onChange={this.handleUsernameChange}
                      value={this.state.detailItem.userName}
                      autoFocus
                    />
                    <Validator id="userName" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          !Validation.isStringEmpty(
                            this.state.detailItem.userName
                          )
                        }
                        message={I18n.t("corporate.ERROR_INPUT_USERNAME")}
                      />
                    </Validator>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup
                    className={
                      !this.state.isSubmitted
                        ? null
                        : this.state.valid.firstName === false
                          ? "error"
                          : null
                    }
                  >
                    <Form.Label>
                      <Translate value="corporate.First_name" />
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      onChange={this.handleFirstNameChange}
                      value={this.state.detailItem.firstName}
                    />
                    <Validator id="firstName" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          !Validation.isStringEmpty(
                            this.state.detailItem.firstName
                          )
                        }
                        message={I18n.t("corporate.ERROR_INPUT_FIRSTNAME")}
                      />
                    </Validator>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.Last_name" />
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      onChange={this.handleLastNameChange}
                      value={this.state.detailItem.lastName}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup
                    className={
                      !this.state.isSubmitted
                        ? null
                        : this.state.valid.phone
                          ? null
                          : "error"
                    }
                  >
                    <Form.Label>
                      <Translate value="corporate.Phone_Number" />
                    </Form.Label>
                    <IntlTelInputApp
                      css={["intl-tel-input", "form-control"]}
                      utilsScript={"libphonenumber.js"}
                      value={this.state.detailItem.phone}
                      onPhoneNumberChange={this.handlePhoneChange}
                    />
                    <Validator id="phone" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={this.state.phoneValidation}
                        message={I18n.t("customer.ERROR_INPUT_VALIDPHONE")}
                      />
                    </Validator>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup
                    className={
                      !this.state.isSubmitted
                        ? null
                        : this.state.valid.email === false
                          ? "error"
                          : null
                    }
                  >
                    <Form.Label>
                      <Translate value="corporate.Email_address" />
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      onChange={this.handleEmailChange}
                      value={this.state.detailItem.email}
                    />
                    <Validator id="email" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          !Validation.isStringEmpty(this.state.detailItem.email)
                        }
                        message={I18n.t("corporate.ERROR_INPUT_EMAIL")}
                      />
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          Validation.isStringEmpty(
                            this.state.detailItem.email
                          ) ||
                          Validation.validateEmail(this.state.detailItem.email)
                        }
                        message={I18n.t("corporate.ERROR_INPUT_VALID_EMAIL")}
                      />
                    </Validator>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <Form.Label>
                    (<span className="require">*</span>
                    ): <Translate value="corporate.Required_fields" />
                  </Form.Label>
                </Col>
                <Col xs={12}>
                  <ButtonToolbar className="pv-md">
                    {!this.props.permissions ||
                      this.props.permissions.actions ? (
                        <Button
                          onClick={this.handleSaveOperatorClick}
                          className="btn-save mr-md"
                        >
                          <Translate value="corporate.Save" />
                        </Button>
                      ) : null}
                    <Button
                      onClick={this.handleCancelAddingClick}
                      className="btn-cancel mr-md"
                    >
                      <Translate value="corporate.Cancel" />
                    </Button>
                    {this.state.detailItem._id ? (
                      <Button
                        className="btn-reset ml-md"
                        onClick={this.handleResetPasswordClick}
                      >
                        <Translate value="corporate.Reset_password" />
                      </Button>
                    ) : null}
                  </ButtonToolbar>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
            <div className="corp-operator-list">
              <ButtonToolbar
                className=" header-button-group"
                ref={node => (this.toolbarContainer = node)}
              >
                <ButtonGroup className="group-left">
                  <FormGroup className="search-format">
                    <FormControl
                      className="search-form"
                      type="text"
                      placeholder={I18n.t("General.search")}
                      onChange={e => this.handleSearchChange(e.target.value)}
                      onKeyPress={this.handleSearchKeyPress}
                      value={this.state.str}
                    />
                    <BsSearch className="search-icon" />
                  </FormGroup>
                  {
                    hasPermission && (
                      <Button
                        className="btn-header text-add-header"
                        onClick={this.handleAddClick}
                      >
                        {" "}

                        <Translate value="corporate.Add" />
                      </Button>
                    )
                  }
                  <Button
                    className="btn-header text-cancel-header"
                    onClick={this.handleCancelClick}
                  >
                    {" "}

                    <Translate value="corporate.Cancel" />
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
              <div className="gridViewTable" id="corporate-operator-table">
                <StickyTable
                  columns={this.getTableColumns()}
                  bodyData={bodyData}
                  footerData={this.state.footerData}
                  handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                  handlePaginationSelect={this.handlePaginationSelect}
                  rowHeight={this.state.rowHeight}
                  getTableHeight={this.getTableHeight}
                  getParentWidthManual={() => this.props.contentWidth}
                  shouldRender={this.state.shouldRenderTable}
                />
              </div>
            </div>
          )}
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    );
  }
}

CorpOperator.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOperator: options => {
      return dispatch(getOperator(options));
    },
    operateOperator: options => {
      return dispatch(operateOperator(options));
    },
    deleteUserCorporate: options => {
      return dispatch(deleteUserCorporate(options));
    },
    resetPasswordCorporate: options => {
      return dispatch(resetPasswordCorporate(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorpOperator);
