import "./Login.scss";
import Captcha from "../../components/reCaprcha/captcha";
import { Translate, I18n } from "react-redux-i18n";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import { connect } from "react-redux";
import { findAliasUrl } from '../../actions/corporateAction';

import {
  Button,
  Row,
  Col,
  FormControl,
  Checkbox,
  FormGroup,
  Form,
  InputGroup
} from "react-bootstrap";
import { forgotPassword } from "../../actions/auth";
import Helmet from "react-helmet";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      isHuman: false,
      username: "",
      isResetCaptcha: false,
      sitekey: process.env.REACT_APP_CAPTCHA_API_KEY || '6LeCz38UAAAAACxtnsEGGNDyxQmOhIVYM6ZwbjmB'
    };
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.captchaCallback = this.captchaCallback.bind(this);
    this.captchaExpiredCallback = this.captchaExpiredCallback.bind(this);
    this.usernameChange = this.usernameChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user) {
      // logged in, let's show redirect if any, or show home
      try {
        const redirect = this.props.location.query.redirect;
        this.context.router.replace(redirect);
      } catch (err) {
        this.context.router.replace("/");
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      // logged in, let's show redirect if any, or show home
      try {
        const redirect = this.props.location.query.redirect;
        this.context.router.replace(redirect);
      } catch (err) {
        this.context.router.replace("/");
      }
    }
  }

  handleClickLoginBtn = () => {
    const { router } = this.context;
    const {search} = this.props.location;
    router.push(`/${search}`);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("corp")) {
      const corpAlias = urlParams.get("corp")
      this.props.findAliasUrl({alias: corpAlias})
      .then(result => {
        const data = result.ok && result.res
        let backgroundCustomize = '' 
        if(data && data.backgroundCustomize && data.s3Host) {
          backgroundCustomize = `${data.s3Host}${data.backgroundCustomize}`
          this.setState({ 
            bgCorpAlias: backgroundCustomize
          })
        }
      })
    }
  }

  handleForgotPassword(event) {
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.forgotPassword({ userName: this.state.username }).then(data => {
      this.props.loadingBarActions.hideLoadingSpiner();
      if (data.ok) {
        this.setState({
          username: "",
          isHuman: false,
          isResetCaptcha: true,
          forgotPasswordMessage: I18n.t("resetPassword.INFO_SUCCESS"),
          forgotPasswordStyle: "success"
        });
      } else if (data.error) {
        this.setState({
          forgotPasswordMessage: I18n.t("errors." + data.error.errorCode),
          forgotPasswordStyle: "error"
        });
      }
    });
  }
  captchaCallback(value) {
    this.setState({ isHuman: true, isResetCaptcha: false });
  }

  captchaExpiredCallback(value) {
    this.setState({ isHuman: false, isResetCaptcha: false });
  }
  usernameChange(e) {
    this.setState({ username: e.target.value });
  }
  render() {
    const { bgCorpAlias } = this.state
    return (
      <div className={"login-body"}>
        <Helmet title={"Forgot password"} />
        <form 
          className={"bg-image"}
          style={{
            backgroundImage: bgCorpAlias ? 
              `url("${bgCorpAlias}")`
              : ''
          }}
        >
          <div className={"login-form mt-md-2 mt-lg-4 forgot-password"}>
            {this.state.forgotPasswordMessage ? (
              <div className={"login-alert login-" + this.state.forgotPasswordStyle}>
                <span>{this.state.forgotPasswordMessage}</span>
              </div>
            ) : null}
            <FormGroup>
              <input
                type="text"
                value={this.state.username}
                onChange={this.usernameChange}
                placeholder={I18n.t("login.Username")}
                required
                autoFocus
                className="form-control form-custom"
              />
            </FormGroup>
            <FormGroup>
              <Captcha
                sitekey={this.state.sitekey}
                lang="en"
                theme="light"
                type="image"
                isResetCaptcha={this.state.isResetCaptcha}
                callback={this.captchaCallback}
                expiredCallback={this.captchaExpiredCallback}
              />
            </FormGroup>
            <FormGroup className="text-right">
              <span 
                className="text-active login-href" 
                onClick={this.handleClickLoginBtn}
              >
                <Translate value="login.Login" />
              </span>
            </FormGroup>
            <Button
              block
              variant="success"
              disabled={
                !this.state.isHuman ||
                !this.state.username ||
                this.state.username.length == 0
              }
              className="text-center login-button btn-save"
              onClick={this.handleForgotPassword}
            >
              Send New Password
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
ForgotPassword.contextTypes = {
  router: PropTypes.object.isRequired,
};
function mapDispatchToProps(dispatch) {
  return {
    forgotPassword: options => {
      return dispatch(forgotPassword(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    findAliasUrl: options => dispatch(findAliasUrl(options)),
  };
}


export default connect(
  null,
  mapDispatchToProps
)(ForgotPassword);
