import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { UploadIcon } from '@icons/material';

import { FormGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import { I18n, Translate } from 'react-redux-i18n';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import CCDropDown from '../../../components/dropdown/ccDropDown';
import CCDropDownCueAction from '../../../components/dropdown/ccDropDownCueAction';
import IntercityDropDown from '../../../components/dropdown/IntercityDropDown';
import {
  BookingType,
  BookingStatus,
  FinishedStatus,
  PassengerType,
  RideSharing,
  SupportService,
  RideType,
  RideServices,
  BookingDeliveryStatus,
  BookingStatusAll,
  NetworkType,
  paidStatus,
  OfferStatus,
} from './FilterItems';

import {
  userType,
  paymentMethod,
  dateRangeActiveForCUE,
  dateRangeFinishForCUE,
  gateway_port_21,
  thirdPartyIntegration,
} from '../../../constants/commondata';
import {
  convertDateRangeString,
  getFilterFavorite,
  setFilterFavorite,
  checkEnable3rdPartyIntegration,
  filterPaymentMethods,
  checkEnableHolidayTaxis,
  checkEnableKarhoo,
  getSuppilerPermission,
} from '../../../utils/commonFunctions';

import * as commonActions from '../../../actions/commonDataAction';
import * as cueActions from '../../../actions/cueActions';
import * as settingActions from '../../../actions/settingActions';

import AddFavoriteFilterModal from './AddFavoriteFilterModal';
import SettingsFavoriteFilterModal from './SettingsFavoriteFilterModal';
import DateRange from './DateRange';
import './style.scss';
import setting from '../../../assets/images/icons/setting.svg';

import { CueColumns } from '../headerData';
import OverlayCC from '../../../components/OverlayCC';
import { BsSearch } from 'react-icons/bs';
import {
  cueActiveKey,
  cueFinishedKey,
  cueOfferRemoveKey,
} from '../../../components/supplierComponent/bookingDetail/constantKey';

class CueFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchText: this.props.txtSearch ? this.props.txtSearch : '',
      showFromDateDpk: false,
      showToDateDpk: false,
      filterTemplateId: '',
      filterTemplates: [],
      nameFilter: '',
      currentFilter: this.props.cue.fillter,
      showAddFavoriteFilterModal: false,
      showSettingsFavoriteFilterModal: false,
      isAirlineBusiness: false,
      dateRange: 'allTime',
    };
  }

  componentDidMount() {
    const { airlineBusiness = {} } = this.props.auth.selectedFleet;
    let isAirlineBusiness =
      airlineBusiness && airlineBusiness.enable ? true : false;
    if (isAirlineBusiness) {
      this.props.commonActions.loadAirline({
        fleetId: this.props.auth.selectedFleet.fleetId,
        isAirline: true,
      });
    }
    this.setState({
      isAirlineBusiness: isAirlineBusiness,
      isEnable3rdBookingCom: checkEnable3rdPartyIntegration(
        this.props.auth.selectedFleet
      ),
      isEnable3rdHolidayTaxis: checkEnableHolidayTaxis(
        this.props.auth.selectedFleet
      ),
      isEnable3rdKarhoo: checkEnableKarhoo(this.props.auth.selectedFleet),
    });
    this.getFilterTemplate(parseInt(this.props.selectedTab || 0));
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedTab != this.state.selectedTab) {
      this.setState({
        searchText: '',
        filterTemplateId: '',
        dateRange: 'allTime',
      });
      this.setState({selectedTab: this.props.selectedTab});
      this.getFilterTemplate(this.props.selectedTab);
    }
  }

  getFilterTemplate = (tabSelected = 0) => {
    this.props.commonActions
      .getFilterTemplate({
        fleetId: this.props.auth.selectedFleet.fleetId,
        type: 'cue',
        userId: this.props.auth.user._id,
        selectedTab: tabSelected,
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.setState(
            {
              filterTemplates: data.res,
            },
            () => {
              this.setFilterFavoriteFromLocalStorage();
            }
          );
        }
      });
  };

  setFilterFavoriteFromLocalStorage = () => {
    let favoriteId = getFilterFavorite('active_filter');
    if (this.props.selectedTab == 3) {
      favoriteId = getFilterFavorite('offer_filter');
    }
    if (this.props.selectedTab == 1) {
      favoriteId = getFilterFavorite('finish_filter');
    }
    if (favoriteId) {
      this.onClickFilterTemplate(favoriteId);
    }
  };

  openSaveFavoriteFilterModal = () => {
    this.setState({ showAddFavoriteFilterModal: true });
  };

  openSettingFavoriteFilterModal = () => {
    this.setState({ showSettingsFavoriteFilterModal: true });
  };

  handleChangeNameFavoriteFilter = (e) => {
    let value = e.target.value;
    this.setState({ nameFilter: value });
  };

  handleSaveFavoriteFilter = () => {
    const { nameFilter, currentFilter } = this.state;
    const { selectedFleet, user } = this.props.auth;
    if (nameFilter === '') return;
    let paramsRequest = currentFilter || {};
    paramsRequest.name = nameFilter;
    paramsRequest.fleetId = selectedFleet.fleetId;
    paramsRequest.type = 'cue';
    paramsRequest.selectedTab = parseInt(this.props.selectedTab || 0);
    paramsRequest.userId = user._id;
    paramsRequest.selectedColumns = this.props.selectedColumns || [];
    this.props.commonActions.addFilterTemplate(paramsRequest).then((data) => {
      if (data.ok && data.res) {
        this.getFilterTemplate(parseInt(this.props.selectedTab || 0));
        this.handleModalClose();
      }
      if (data.error) {
        this.context.notification(
          'error',
          I18n.t(
            `errors.${
              data.error.errorCode ? data.error.errorCode : 'undefined'
            }`
          )
        );
      }
    });
  };

  handleDeleteFavoriteFilter = (id) => {
    const { selectedFleet, user } = this.props.auth;
    let paramsRequest = {
      fleetId: selectedFleet.fleetId,
      id,
      userId: user._id,
    };
    this.props.commonActions
      .deleteFilterTemplate(paramsRequest)
      .then((data) => {
        if (data.ok && data.res) {
          this.getFilterTemplate(parseInt(this.props.selectedTab || 0));
          this.handleModalClose();
        }
        if (data.error) {
          this.context.notification(
            'error',
            I18n.t(
              `errors.${
                data.error.errorCode ? data.error.errorCode : 'undefined'
              }`
            )
          );
        }
      });
  };

  handleModalClose = () => {
    this.setState({
      nameFilter: '',
      showAddFavoriteFilterModal: false,
      showSettingsFavoriteFilterModal: false,
    });
  };

  handleChangeDateRange = (params) => {
    let { dateFrom, dateTo } = this.props.cue.fillter;
    const { fromDate: paramsFromDate, toDate: paramsToDate } = params || {};

    const selectedFromdate = paramsFromDate ? paramsFromDate : dateFrom;
    const selectedTodate = paramsToDate ? paramsToDate : dateTo;

    switch (params.selectedButton) {
      case 'allTime':
        dateTo = null;
        dateFrom = null;
        break;
      default:
        dateFrom = moment(selectedFromdate).toISOString();
        dateTo = moment(selectedTodate).toISOString();
        if (dateFrom > dateTo) {
          if (paramsFromDate)
            dateTo = moment(dateFrom).endOf('day').toISOString();
          else dateFrom = moment(dateTo).startOf('day').toISOString();
        }
        break;
    }

    this.setState(
      {
        dateRange: params.selectedButton,
      },
      () => {
        this.props.updateFillterDateRange(
          params.selectedButton,
          moment(dateFrom)._d,
          moment(dateTo)._d
        );
      }
    );
  };

  handleCustomDateSelect() {
    this.setState({ showFromDateDpk: true });
  }

  handleSearchChange = (e) => {
    let value = e.target ? e.target.value : this.state.searchText;
    this.setState({ searchText: value });
  };

  onSelectFromDate = (e) => {
    this.setState(
      {
        showFromDateDpk: false,
        dateRange: 'custom',
      },
      () => this.props.updateDatefrom(e._d)
    );
  };

  onSelectToDate = (e) => {
    this.setState(
      {
        showToDateDpk: false,
        dateRange: 'custom',
      },
      () => this.props.updateDateTo(e._d)
    );
  };

  onClickFilterTemplate = (id) => {
    const { filterTemplates } = this.state;
    if (id) {
      let filter = filterTemplates.find((e) => e._id === id);
      if (!filter) return;
      const columns = filter.selectedColumns || [];
      if (
        !filter.dateRange ||
        filter.dateRange === 'allTime' ||
        filter.dateRange === 'custom' ||
        filter.dateRange === 'tomorrow'
      ) {
        if (filter.dateFrom) {
          filter.dateFrom = moment(filter.dateFrom)._d;
        }
        if (filter.dateTo) {
          filter.dateTo = moment(filter.dateTo)._d;
        }
      } else {
        const dateConvert = convertDateRangeString(filter.dateRange);
        filter.dateFrom = moment(dateConvert.fromDate)._d;
        filter.dateTo = moment(dateConvert.toDate)._d;
      }

      if (filter.intercityRoutes && filter.intercityRoutes[0]) {
        filter.routeId = filter.intercityRoutes[0]._id;
        filter.routeNumber = filter.intercityRoutes[0].routeNumber;
      } else {
        filter.routeId = '';
        filter.routeNumber = null;
      }

      filter.driverCorporate =
        (this.props.auth?.user?.roles?.isSupplier &&
          this.props.auth.user.roles.companyId) ||
        '';

      this.setState(
        {
          searchText: filter.txtSearch,
          filterTemplateId: id,
          dateRange: filter.dateRange ? filter.dateRange : 'allTime',
        },
        () => {
          this.props.handleClickFliterTemplate(filter);
        }
      );
      if (this.props.selectedTab == 0) setFilterFavorite('active_filter', id);
      if (this.props.selectedTab == 1) setFilterFavorite('finish_filter', id);
      if (this.props.selectedTab == 3) setFilterFavorite('offer_filter', id);
      // const columnsAlwayShow = _.filter(CueColumns, (item) => item.isAlwayShow);
      // const checkColomns = (item) => columns.includes(item);
      // if (columnsAlwayShow.every((ele) => checkColomns(ele.key))) {
      //   this.props.handleSelectColumn(columns);
      // } else {
      //   this.props.handleSelectColumn([]);
      // }
    } else {
      this.setState(
        {
          searchText: '',
          filterTemplateId: '',
          dateRange: 'allTime',
        },
        () => {
          if (this.props.selectedTab == 0) {
            this.props.handleClearActiveFilter();
            setFilterFavorite('active_filter', '');
          } else if (this.props.selectedTab == 3) {
            this.props.handleClearOfferFilter();
            setFilterFavorite('offer_filter', '');
          } else {
            setFilterFavorite('finish_filter', '');
            this.props.handleClearFinishFilter();
          }
        }
      );
    }
  };

  handleClickClearFinishFilter = () => {
    this.setState(
      {
        searchText: '',
        filterTemplateId: '',
        dateRange: 'allTime',
      },
      () => {
        this.props.handleClearFinishFilter();
      }
    );
  };

  showFromDateDpk = (isShow) => {
    this.setState({ showFromDateDpk: isShow });
  };

  showToDateDpk = (isShow) => {
    this.setState({ showToDateDpk: isShow });
  };

  render() {
    const {
      selectedTab,
      auth: { selectedFleet },
      bookingType,
    } = this.props;
    const {
      showFromDateDpk,
      showToDateDpk,
      filterTemplates,
      showAddFavoriteFilterModal,
      showSettingsFavoriteFilterModal,
      isAirlineBusiness,
      dateRange,
      isEnable3rdBookingCom = false,
    } = this.state;
    const filterData = this.props.cue.fillter;
    let selectedStatus = [];
    const overrideBookingStatus = _.get(
      selectedFleet,
      'generalSetting.overrideBookingStatus',
      false
    );
    let newBookingStatusAll = [...BookingStatusAll],
      newBookingStatus = [...BookingStatus];
    if (!isEnable3rdBookingCom) {
      _.remove(newBookingStatus, (item) => item.value === 'allocated');
      _.remove(newBookingStatusAll, (item) => item.value === 'allocated');
    }
    if (selectedTab == 0) {
      if (bookingType === 'all') {
        if (
          (selectedFleet.delivery && selectedFleet.delivery.enable) ||
          (selectedFleet.food && selectedFleet.food.enable) ||
          (selectedFleet.mart && selectedFleet.mart.enable)
        ) {
          _.forEach(newBookingStatusAll, (item) => {
            if (filterData.status[item.value]) {
              selectedStatus.push(item.value);
            }
          });
        } else {
          _.forEach(newBookingStatus, (item) => {
            if (filterData.status[item.value]) {
              selectedStatus.push(item.value);
            }
          });
        }
      } else if (
        bookingType === 'delivery' ||
        bookingType === 'batchDelivery'
      ) {
        _.forEach(BookingDeliveryStatus, (item) => {
          if (filterData.status[item.value]) {
            selectedStatus.push(item.value);
          }
        });
      } else {
        _.forEach(newBookingStatus, (item) => {
          if (filterData.status[item.value]) {
            selectedStatus.push(item.value);
          }
        });
      }
    } else if (selectedTab == 3) {
      _.forEach(OfferStatus, (item) => {
        if (filterData.offerStatus[item.value]) {
          selectedStatus.push(item.value);
        }
      });
    } else {
      _.forEach(FinishedStatus, (item) => {
        if (filterData.finishedStatus[item.value]) {
          if (
            item.value === 'completedWithoutService' &&
            !overrideBookingStatus
          )
            return;
          if (
            ['partialCompleted', 'failed'].includes(item.value) &&
            !(selectedFleet.delivery && selectedFleet.delivery.enable)
          )
            return;
          selectedStatus.push(item.value);
        }
      });
    }
    const selectedCarTypes = _.map(
      filterData.carType,
      (item) => item.vehicleType
    );
    const selectedCompany = _.map(
      filterData.requestCompanyIds,
      (item) => item._id
    );

    const selectedIntercityRoutes = _.map(
      filterData.intercityRoutes,
      (item) => item._id
    );

    const selectedIntercityRouteNumber = _.map(
      filterData.intercityRoutes,
      (item) => item.routeNumber
    );

    const selectedBookFrom = _.map(filterData.bookFrom, (item) => item.value);

    const selectedPaymentMethod = _.map(
      filterData.paymentMethod,
      (item) => item.value
    );
    const getZoneBasedCompany = () => {
      const companyId = this.props.auth?.user?.roles?.companyId || '',
        fleetZones = this.props.commonData.mapZone,
        fleetCompany = [
          ...this.props.commonData.companies,
          ...this.props.commonData.suppliers,
        ],
        isSupplier = this.props.auth?.user?.roles?.isSupplier;

      if (isSupplier) {
        const cpInfo = fleetCompany.find((cp) => cp._id === companyId);
        if (cpInfo?.operationZone?.length > 0) {
          return fleetZones.filter((zone) => {
            if (!cpInfo?.operationZone?.length) {
              return true;
            }
            return cpInfo?.operationZone.includes(zone._id)
          });
        }
      }
      const newZones = _.filter(fleetZones, (z) => z.isActive && z.display);
      return newZones;
    };
    let zoneItems = getZoneBasedCompany() || [];

    // zoneItems.unshift({ zoneName: "cue.All", _id: "" });
    let bookedFrom = this.props.commonData.bookingFrom;
    if (selectedFleet.fleetConfigs && !selectedFleet.fleetConfigs.webLink) {
      _.remove(bookedFrom, (e) => {
        return e.value === 'Boost web link';
      });
    }
    let bookingTypeList = BookingType;
    const isSupplier = this.props.auth?.user?.roles?.isSupplier;
    let searchByOptions = this.props.searchByOptions;
    if (isSupplier) {
      bookingTypeList = _.filter(
        bookingTypeList,
        (o) => o.value == 'asap' || o.value == 'reservation'
      );
    } else {
      if (!_.get(selectedFleet, 'intercity.enable', false)) {
        bookingTypeList = _.filter(
          bookingTypeList,
          (o) => o.value !== 'intercity'
        );
        searchByOptions = _.filter(
          searchByOptions,
          (o) => o.value !== 'tripId'
        );
      }
      if (!_.get(selectedFleet, 'groupBooking', false)) {
        bookingTypeList = _.filter(
          bookingTypeList,
          (o) => o.value !== 'rideSharing'
        );
        searchByOptions = _.filter(
          searchByOptions,
          (o) => o.value !== 'groupId'
        );
      }
      if (!checkEnable3rdPartyIntegration(this.props.auth.selectedFleet)) {
        _.remove(bookedFrom, (e) => {
          return e.value === 'booking.com';
        });
      }
      if (!this.state.isEnable3rdHolidayTaxis) {
        bookedFrom = _.filter(
          bookedFrom,
          (e) => e.value !== thirdPartyIntegration.holidaytaxis
        );
      }
      if (!this.state.isEnable3rdKarhoo) {
        bookedFrom = _.filter(
          bookedFrom,
          (e) => e.value !== thirdPartyIntegration.karhoo
        );
      }
      if (
        !_.get(selectedFleet, 'delivery.enable', false) &&
        !_.get(selectedFleet, 'food.enable', false) &&
        !_.get(selectedFleet, 'mart.enable', false)
      ) {
        bookingTypeList = _.filter(
          bookingTypeList,
          (o) => o.value !== 'delivery' && o.value !== 'batchDelivery'
        );
      }
      if (!_.get(selectedFleet, 'shuttle.enable', false)) {
        bookingTypeList = _.filter(
          bookingTypeList,
          (o) => o.value !== 'shuttle'
        );
      }
    }

    let removeCol = selectedTab == 0 ? 'payment' : 'estimatedFare';
    const flightAPIIntegration = _.get(
      this.props.auth,
      'selectedFleet.process.flight',
      false
    );
    let filterColumnItems = [],
      newCueColumns = [...CueColumns];
    if (isSupplier) {
      if (selectedTab == 0 || selectedTab == 3) {
        newCueColumns = newCueColumns.filter((o) =>
          cueActiveKey.includes(o.key)
        );
        if (selectedTab == 3) {
          newCueColumns = newCueColumns.filter(
            (o) => !cueOfferRemoveKey.includes(o.key)
          );
        }
        newCueColumns = newCueColumns.concat({
          key: 'prices',
          label: 'supplier.prices',
          textEllipsis: true,
          width: 210,
        });
      } else {
        newCueColumns = newCueColumns.filter((o) =>
          cueFinishedKey.includes(o.key)
        );
      }
    }
    if (this.props.rearangeColumn?.length > 0) {
      newCueColumns = newCueColumns.sort((a, b) => {
        const keyAIndex = this.props.rearangeColumn.findIndex(
          (item) => item && item.key === a.key
        );
        const keyBIndex = this.props.rearangeColumn.findIndex(
          (item) => item && item.key === b.key
        );
        if (keyAIndex === -1) {
          return 1;
        }
        return keyAIndex - keyBIndex;
      });
    }
    _.forEach(newCueColumns, (item) => {
      if (item.key !== removeCol) {
        if (item.key === 'flightStatus' && flightAPIIntegration) {
          filterColumnItems.push({
            key: item.key,
            label: 'cue.FlightUpdate',
            disableSelect: item.isAlwayShow,
          });
          return;
        }
        filterColumnItems.push({
          key: item.key,
          label: item.label,
          disableSelect: item.isAlwayShow,
          fixed: item.fixed,
        });
      }
    });
    const permissionShowFare = _.get(this.props.permissions, 'showfare', true);
    if (!permissionShowFare) {
      filterColumnItems = filterColumnItems.filter(
        (ob) => ob.key != 'payment' && ob.key !== 'estimatedFare'
      );
    }
    const hasRecurring = _.get(
      this.props.auth,
      'selectedFleet.recurring.enable',
      false
    );
    if (!hasRecurring) {
      _.remove(filterColumnItems, (col) => {
        return col.key === 'recurring.batchId';
      });
    }
    _.remove(
      filterColumnItems,
      (ob) => ob.key === 'scheduled' || ob.key === 'actual'
    );
    if (!flightAPIIntegration) {
      _.remove(filterColumnItems, (col) => {
        return (
          col.key === 'pickupDropOffLocalTime' || col.key === 'expectedTime'
        );
      });
    }
    const isEnableGroupBooking = _.get(
      this.props.auth,
      'selectedFleet.groupBooking',
      false
    );
    if (
      selectedTab == 1 ||
      !_.get(this.props.auth, 'selectedFleet.cue.showETA', false)
    ) {
      _.remove(filterColumnItems, (ob) => ob.key === 'expectedTime');
    }
    if (!isEnableGroupBooking) {
      _.remove(filterColumnItems, (ob) => ob.key === 'groupId');
    }
    const showETA = _.get(this.props.auth, 'selectedFleet.cue.showETA', false);
    if (!showETA || selectedTab == 1) {
      _.remove(
        filterColumnItems,
        (ob) =>
          ob.key === 'spotTime' || ob.key === 'eta' || ob.key === 'spareTime'
      );
    }
    const affiliateSetting = selectedFleet.affiliate || {};
    if (
      !affiliateSetting.dispatching &&
      !affiliateSetting.receiveOndemandBooking &&
      !affiliateSetting.receiveReservationBooking
    ) {
      _.remove(filterColumnItems, (ob) => ob.key === 'networkType');
    }
    const paymentMethods = filterPaymentMethods(
      paymentMethod,
      this.props.auth.selectedFleet
    );
    const fromDatePickerValue = filterData.dateFrom
      ? moment(filterData.dateFrom).format('MM/DD/YYYY')
      : '';

    const toDatePickerValue = filterData.dateTo
      ? moment(filterData.dateTo).format('MM/DD/YYYY')
      : '';

    const intercityLabel = (item) => {
      return (
        <div>
          <OverlayTrigger
            overlay={
              <Tooltip id="intercity-label-tooltip">{`${item.firstLocation.name} - ${item.secondLocation.name}`}</Tooltip>
            }
            placement="top"
            delayShow={100}
            delayHide={150}
          >
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >{`${item.firstLocation.name} - ${item.secondLocation.name}`}</div>
          </OverlayTrigger>
          {_.map(item.carType, (cartype) => (
            <div className="text-more-info" key={cartype._id}>
              <span>{cartype.vehicleType}</span>
              <span> - </span>
              <span>
                {item.routeSetting.pricePerSeat[0].value.toLocaleString(
                  'us-US',
                  {
                    style: 'currency',
                    currency: item.routeSetting.pricePerSeat[0].currencyISO,
                  }
                )}
              </span>
            </div>
          ))}
        </div>
      );
    };
    let finishedStatusFiltered = FinishedStatus;
    if (!overrideBookingStatus) {
      finishedStatusFiltered = finishedStatusFiltered.filter(
        (o) => o.value !== 'completedWithoutService'
      );
    }
    if (!(selectedFleet.delivery && selectedFleet.delivery.enable)) {
      finishedStatusFiltered = finishedStatusFiltered.filter(
        (o) => o.value !== 'failed' && o.value !== 'partialCompleted'
      );
    }

    let OfferStatusFiltered = OfferStatus;
    return (
      <div className="cue-filter-container">
        <div className="header-button-group">
          <div className="group-left groupMB">
            <DateRange
              selectedButton={dateRange}
              setDateRange={this.handleChangeDateRange}
              customRangeSelectCallback={this.handleCustomDateSelect.bind(this)}
              buttons={
                selectedTab == 0 ? dateRangeActiveForCUE : dateRangeFinishForCUE
              }
              className="customeMb_dateRange"
            />

            <FormGroup className="search-format mb0 customeMb_search search-by">
              <div className="input-container">
                <input
                  type="text"
                  className="form-control search-form"
                  value={this.state.searchText}
                  onChange={this.handleSearchChange}
                  onKeyPress={this.props.txtSearchPressHandle}
                  placeholder={I18n.t('cue.Search_here')}
                  disabled={this.props.cue.isLoading}
                />
                <BsSearch className="search-icon" />
              </div>
              <CCDropDown
                id="cue-search-by-dropdown"
                title=""
                items={searchByOptions}
                selectedItems={[this.props.searchBy]}
                valueKey="value"
                labelKey="label"
                onSelect={this.props.handleSearchByChange}
                className="search-by-dropdown"
                pullRight
                disabled={this.props.cue.isLoading}
              />
            </FormGroup>
            <FormGroup className="cue-date-picker-group customeMb input">
              <OverlayCC
                childrenAction={
                  <div className="form-control form-custom datepicker-input">
                    {fromDatePickerValue ? (
                      <span>{fromDatePickerValue}</span>
                    ) : (
                      <Translate
                        value="report.query.fromDate"
                        className="placeholder"
                      />
                    )}
                  </div>
                }
                childrenOverlay={
                  <div className="datePicker-container">
                    <ReactDatetime
                      timeFormat={false}
                      value={filterData.dateFrom}
                      onChange={this.onSelectFromDate}
                      dateFormat="MM/DD/YYYY"
                      viewMode="days"
                      input={false}
                      open
                    />
                  </div>
                }
                setShowOverlay={this.showFromDateDpk}
                showOverlay={this.state.showFromDateDpk}
              />
            </FormGroup>
            <FormGroup className="cue-date-picker-group customeMb input">
              <OverlayCC
                childrenAction={
                  <div className="form-control form-custom datepicker-input">
                    {toDatePickerValue ? (
                      <span>{toDatePickerValue}</span>
                    ) : (
                      <Translate
                        value="report.query.toDate"
                        className="placeholder"
                      />
                    )}
                  </div>
                }
                childrenOverlay={
                  <div className="datePicker-container">
                    <ReactDatetime
                      timeFormat={false}
                      value={filterData.dateTo}
                      onChange={this.onSelectToDate}
                      dateFormat="MM/DD/YYYY"
                      viewMode="days"
                      input={false}
                      open
                    />
                  </div>
                }
                setShowOverlay={this.showToDateDpk}
                showOverlay={this.state.showToDateDpk}
              />
            </FormGroup>
            {selectedTab == 0 &&
              this.props.auth.selectedFleet.map &&
              this.props.auth.selectedFleet.map.isAutoRefresh && (
                <FormGroup className="auto-refresh-form">
                  <CcCheckbox
                    checked={this.props.commonData.isAutoRefreshCueAndMap}
                    className="table-selectbox all-checkbox"
                    onChange={(e) => {
                      this.props.commonActions.autoRefreshCueAndMapChange(
                        e.target.checked
                      );
                    }}
                    text={I18n.t('cue.auto_refrest_cue').format(
                      this.props.auth.selectedFleet.map.value
                    )}
                  />
                  <i
                    className="fa fa-refresh auto-refresh-icon"
                    onClick={() => {
                      this.props.cueDataRequest({}, selectedTab == 0);
                    }}
                  />
                </FormGroup>
              )}
          </div>
          <div className="group-right">
            <Button
              className="btn-header text-add-header"
              onClick={this.props.handleToggleFilter}
            >
              <Translate
                value={
                  this.props.showFilter
                    ? 'cue.collapseFilter'
                    : 'cue.expandFilter'
                }
              />
            </Button>
            <CCDropDownCueAction
              id="filter-column-dropdown"
              customTitle={<div className="column-filter-icon" />}
              items={filterColumnItems}
              valueKey="key"
              labelKey="label"
              auth={this.props.auth}
              onSelect={this.props.handleSelectColumn}
              selectedItems={this.props.selectedColumns}
              multiSelect={true}
              className="column-filter"
              enableAllItemSelect={true}
              hasActionBtn
              actionBtnFunc={this.props.showRearrangeModal}
              textActionBtn={I18n.t('cue.Rearrange')}
              hasShowUploadClick={this.props.hasShowUploadClick}
              handleUploadClick={this.props.handleUploadClick}
              isEnable3rdBookingCom={this.state.isEnable3rdBookingCom}
              isEnable3rdHolidayTaxis={this.state.isEnable3rdHolidayTaxis}
              cueActions={this.props.cueActions}
              settingActions={this.props.settingActions}
              fleetId={this.props.auth.selectedFleet.fleetId}
              selectedFleet={this.props.auth.selectedFleet}
              context={this.context}
              handleSaveRearrangeColumn={this.props.handleSaveRearrangeColumn}
              rearangeColumn={this.props.rearangeColumn}
            />
          </div>
        </div>
        {/* Filter template */}
        {filterTemplates.length > 0 ? (
          <div className="header-button-group filter-group">
            <div className="group-left">
              <div className="filter-tags">
                {filterTemplates.map((item) => {
                  return (
                    <span
                      key={item._id}
                      className={`filter-item ${
                        this.state.filterTemplateId === item._id ? 'active' : ''
                      }`}
                      onClick={(e) => this.onClickFilterTemplate(item._id)}
                    >
                      {item.name}
                    </span>
                  );
                })}
                {this.state.filterTemplateId !== '' && (
                  <span
                    className={'clear-filter'}
                    onClick={(e) => this.onClickFilterTemplate()}
                  >
                    {I18n.t('cue.clear_all_filters')}
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={'filter-wrapper' + (this.props.showFilter ? ' show' : '')}
        >
          {!isSupplier &&
            (affiliateSetting.dispatching ||
              affiliateSetting.receiveOndemandBooking ||
              affiliateSetting.receiveReservationBooking) && (
              <CCDropDown
                id="booking-type-dropdown"
                items={NetworkType}
                title={I18n.t('cue.networkType')}
                selectedItems={[filterData.networkType]}
                valueKey="value"
                labelKey="label"
                onSelect={this.props.networkTypeChange}
              />
            )}
          <CCDropDown
            id="booking-type-dropdown"
            items={bookingTypeList}
            title={I18n.t('cue.Booking_type')}
            selectedItems={[filterData.bookingType]}
            valueKey="value"
            labelKey="label"
            onSelect={this.props.bookingTypeHandle}
          />
          {!isSupplier &&
          this.props.auth.selectedFleet.intercity &&
          this.props.auth.selectedFleet.intercity.enable ? (
            <IntercityDropDown
              id="intercity-routes-dropdown"
              items={this.props.commonData.intercityRoutes}
              title={I18n.t('cue.Intercity_Route')}
              selectedItems={selectedIntercityRoutes}
              selectedRouteNumber={selectedIntercityRouteNumber}
              valueKey="_id"
              labelKey="routeName"
              routeNumber="routeNumber"
              onSelect={this.props.updateFillterIntercityRoute}
              enableClearAll={true}
              searchable={true}
              intercityRoutesMode={true}
              customLabel={intercityLabel}
            />
          ) : null}
          {selectedTab != 3 && (
            <CCDropDown
              id="booking-status-dropdown"
              items={
                selectedTab == 0
                  ? this.props.auth?.user?.roles?.isSupplier
                    ? newBookingStatus
                    : (selectedFleet.delivery &&
                        selectedFleet.delivery.enable) ||
                      (selectedFleet.food && selectedFleet.food.enable) ||
                      (selectedFleet.mart && selectedFleet.mart.enable)
                    ? bookingType === 'all'
                      ? newBookingStatusAll
                      : bookingType === 'delivery' ||
                        bookingType === 'batchDelivery'
                      ? BookingDeliveryStatus
                      : newBookingStatus
                    : newBookingStatus
                  : selectedTab == 3
                  ? OfferStatusFiltered
                  : finishedStatusFiltered
              }
              title={I18n.t('cue.Status')}
              selectedItems={selectedStatus}
              valueKey="value"
              labelKey="label"
              onSelect={this.props.statusItemClicked}
              multiSelect={true}
              returnOnSelect={true}
              onlyShowAllText={true}
            />
          )}
          {!isSupplier && (
            <CCDropDown
              id="operator-dropdown"
              items={this.props.commonData.operator}
              title={I18n.t('cue.Operator')}
              selectedItems={filterData.operator ? [filterData.operator] : []}
              valueKey="userId"
              labelKey="fullName"
              onSelect={this.props.updateFillterOperactor}
              enableClearAll={true}
              searchable={true}
            />
          )}
          <CCDropDown
            id="car-type-dropdown"
            items={this.props.commonData.carType}
            title={I18n.t(
              `${isSupplier ? 'cue.Service_class' : 'cue.Car_type'}`
            )}
            selectedItems={selectedCarTypes}
            valueKey="vehicleType"
            labelKey="vehicleType"
            noTranslateLabel
            onSelect={this.props.updateFillterCarTypes}
            multiSelect={true}
            enableClearAll={true}
            searchable={true}
          />
          {(this.props.auth.user.userType == userType.FleetAdmin ||
            this.props.auth.user.userType == userType.FleetUser) &&
            !getSuppilerPermission(this.props.auth) && (
              <CCDropDown
                id="corporate-dropdown"
                items={this.props.companyList}
                title={I18n.t('carSetting.Company')}
                valueKey="_id"
                labelKey="name"
                selectedItems={selectedCompany}
                onSelect={this.props.companyChangeHandle}
                searchable={true}
                multiSelect={true}
                enableClearAll={true}
                noTranslateLabel
              />
            )}
          {!isSupplier && (
            <>
              <CCDropDown
                id="passenger-type-dropdown"
                items={PassengerType}
                title={I18n.t('cue.Passenger_type')}
                selectedItems={[filterData.vip === null ? -1 : filterData.vip]}
                valueKey="value"
                labelKey="label"
                onSelect={this.props.passengerTypeChangeHandle}
              />

              <CCDropDown
                id="passenger-type-dropdown"
                items={paidStatus}
                title={I18n.t('bookingdetail.paidStatus')}
                selectedItems={[filterData.paidStatus]}
                valueKey="value"
                labelKey="label"
                onSelect={this.props.paidStatusTypeChangeHandle}
              />

              <CCDropDown
                id="booking-from-dropdown"
                // items={this.props.commonData.bookingFrom}
                items={bookedFrom}
                title={I18n.t('cue.Booking_from')}
                valueKey="value"
                labelKey="label"
                selectedItems={selectedBookFrom}
                onSelect={this.props.updateFillterBookingFrom}
                multiSelect={true}
                enableClearAll={true}
              />
              {!getSuppilerPermission(this.props.auth) && (
                <CCDropDown
                  id="ride-service-dropdown"
                  items={RideServices}
                  title={I18n.t('cue.Ride_Service')}
                  valueKey="value"
                  labelKey="label"
                  selectedItems={[filterData.rideService]}
                  onSelect={this.props.rideServiceHandle}
                />
              )}

              {this.props.showRideSharingFilter ? (
                <CCDropDown
                  id="ride-sharing-dropdown"
                  items={RideSharing}
                  title={I18n.t('cue.Ride_Sharing')}
                  valueKey="value"
                  labelKey="label"
                  selectedItems={[filterData.rideSharing]}
                  onSelect={this.props.rideSharingHandle}
                />
              ) : null}
              {selectedTab == 1 && (
                <CCDropDown
                  id="payment-method-dropdown"
                  items={paymentMethods}
                  title={I18n.t('cue.Payment_Method')}
                  selectedItems={selectedPaymentMethod}
                  valueKey="value"
                  labelKey="label"
                  onSelect={this.props.updateFillterPaymentMethod}
                  multiSelect={true}
                  onlyShowAllText={true}
                />
              )}
              <CCDropDown
                id="support-service-dropdown"
                items={SupportService}
                title={I18n.t('cue.Support_service')}
                valueKey="value"
                labelKey="label"
                selectedItems={[
                  filterData.supportService === null
                    ? 'all'
                    : filterData.supportService,
                ]}
                onSelect={this.props.supportServiceChangeHandle}
              />
            </>
          )}
          <CCDropDown
            id="zone-dropdown"
            items={zoneItems}
            title={I18n.t('cue.Zone')}
            valueKey="_id"
            labelKey="zoneName"
            selectedItems={filterData.zone ? [filterData.zone] : []}
            onSelect={this.props.onZoneChange}
            searchable
            enableClearAll
          />
          {!isSupplier && (
            <>
              {!getSuppilerPermission(this.props.auth) && (
                <CCDropDown
                  id="ride-type-dropdown"
                  items={RideType}
                  title={I18n.t('cue.Ride_type')}
                  valueKey="value"
                  labelKey="label"
                  selectedItems={[
                    filterData.rideType === null ? 'all' : filterData.rideType,
                  ]}
                  onSelect={this.props.rideTypeChangeHandle}
                />
              )}

              {(this.props.auth.user.userType == userType.FleetAdmin ||
                this.props.auth.user.userType == userType.FleetUser) &&
                !getSuppilerPermission(this.props.auth) &&
                filterData.rideType == 'corporate' && (
                  <CCDropDown
                    id="corporate-dropdown"
                    items={this.props.corporateCompany.map((a) => {
                      return { _id: a._id, name: a.companyInfo.name };
                    })}
                    title={I18n.t('cue.Corporate')}
                    valueKey="_id"
                    labelKey="name"
                    selectedItems={
                      filterData.corporateId ? [filterData.corporateId] : []
                    }
                    onSelect={this.props.corporateChangeHandle}
                    searchable={true}
                    enableClearAll={true}
                  />
                )}

              {(this.props.auth.user.userType === userType.FleetAdmin ||
                this.props.auth.user.userType === userType.FleetUser) &&
                isAirlineBusiness && (
                  <CCDropDown
                    id="airline-dropdown"
                    items={this.props.commonData.airline}
                    title={I18n.t('cue.Airline')}
                    selectedItems={
                      filterData.airline ? [filterData.airline] : []
                    }
                    valueKey="_id"
                    labelKey="name"
                    onSelect={this.props.updateFillterAirline}
                    enableClearAll={true}
                    searchable={true}
                  />
                )}
            </>
          )}
          {selectedTab !== 0 ? (
            <div className="finished-filter-buttons">
              <Button
                className="btn-header text-add-header"
                onClick={this.props.handleApplyFinishFilter}
              >
                <Translate value="customer.Apply" />
              </Button>
              <Button
                className="btn-header text-deactive-header"
                onClick={this.handleClickClearFinishFilter}
              >
                <Translate value="customer.Clear" />
              </Button>
            </div>
          ) : null}
          <div className="active-filter-buttons">
            <Button
              className="btn-save filter-btn"
              onClick={this.openSaveFavoriteFilterModal}
            >
              <Translate value="cue.Save_Filter" />
            </Button>
            <Button
              className="filter-btn-settings"
              onClick={this.openSettingFavoriteFilterModal}
            >
              <img className="filter-settings" src={setting}></img>
            </Button>
          </div>
        </div>

        {showAddFavoriteFilterModal && (
          <AddFavoriteFilterModal
            name={this.state.nameFilter}
            handleChangeName={this.handleChangeNameFavoriteFilter}
            handleSave={this.handleSaveFavoriteFilter}
            handleClose={this.handleModalClose}
          />
        )}

        {showSettingsFavoriteFilterModal && (
          <SettingsFavoriteFilterModal
            filterTemplates={filterTemplates}
            handleClose={this.handleModalClose}
            handleDelete={this.handleDeleteFavoriteFilter}
          />
        )}
      </div>
    );
  }
}

CueFilter.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    cue: state.cue,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    cueActions: bindActionCreators(cueActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CueFilter);
