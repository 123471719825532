/* global google */
import "./map.scss";
import { default as React, Component } from "react";
import ReactDOM from "react-dom";
import { browserHistory } from 'react-router'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from 'lodash';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  TrafficLayer,
  BicyclingLayer
} from "react-google-maps";
import { Translate, I18n } from "react-redux-i18n";
import { bindActionCreators } from "redux";
import {
  Form,
  Col,
  Row,
  FormGroup,
  FormControl,
  ButtonToolbar,
  DropdownButton,
  Dropdown,
  Image
} from "react-bootstrap";
import {
  QQMap,
  QQMarker,
  QQDirection,
  QQPolyline,
  QQRouteServices,
  QQAutocomplete,
  QQUltis,
  QQPolygon
} from "../../components/qqMap";
// import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import {
  geoPlaceDetailMapProvider
} from "../../utils/mapUtils.js";
import PlacesAutocomplete from "../../components/placesAutocomplete/PlacesAutocomplete";
import CcCheckbox from "../../components/ccCheckbox/CcCheckbox";
import * as mapActions from "../../actions/mapActions";
import * as menuActions from "../../actions/sidebarCollapsedAction";
import * as bookingDetailActions from "../../actions/bookingDetailAction";
import * as commonActions from "../../actions/commonDataAction";
import * as loadingBarActions from '../../actions/loadingBarActions';
import { 
  CCLiteCommonFunc, 
  toLocaleNumber, 
  getHasPermissionModule, 
  getSupplierInfoFromID, 
  checkBroadcastWithSupplierSelecting,
  checkSupplierSupportZonePickup, 
  getLanguageAutoCompleteSearch,
  getCompanyOfOperator,
  filterZonesBasedPermission
} from "../../utils/commonFunctions";
import {
  mapIcons,
  PermissionType,
  bookingInprogressStatus
} from "../../constants/commondata";
import { realtimeHelper } from "../../utils/realTimeHelper";
import * as cueActions from "../../actions/cueActions";
import edit_icon from "./../../assets/images/icons/icon-edit.svg";
import ride_sharing_icon from "./../../assets/images/icons/ride_sharing_icon.svg";
import { socketConfigs } from "../../constants/socketConfigs.js";
import { socketDispatchApi } from "../../utils/socketUtils.js";
import { BsCaretDownFill, BsCaretUpFill, BsChevronLeft, BsChevronRight, BsSearch } from "react-icons/bs";
import AutocompleteWith3rd from "../../components/qqMap/AutocompleteWith3rd.js";
/*
 * This is the modify version of:
 * https://developers.google.com/maps/documentation/javascript/examples/event-arguments
 *
 * Add <script src="https://maps.googleapis.com/maps/api/js"></script> to your HTML to provide google.maps reference
 */
var isWorking = false,
  itemPerPage = 30;

var loadedData = {};

const ignoreCase = ["Booking", "Report", "Setting"];

const GettingStartedGoogleMap = withGoogleMap(props => (
  <GoogleMap
    ref={props.onMapLoad}
    defaultZoom={15}
    zoom={15}
    center={props.center}
    onClick={props.onMapClick}
    options={{ fullscreenControl: false, gestureHandling: "greedy" }}
  >
    {props.traffic &&  <TrafficLayer autoUpdate />}
    {props.bicycling &&  <BicyclingLayer autoUpdate />}
  </GoogleMap>

));

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: null,
      markers: [],
      showControl: true,
      addressPickUp: "",
      driverShowring: {},
      finalZone: [],
      filter: {
        status: {
          bookIn:
            !props.auth.selectedFleet.statusOnMap ||
            props.auth.selectedFleet.statusOnMap.includes("bookIn"),
          arrived:
            !props.auth.selectedFleet.statusOnMap ||
            props.auth.selectedFleet.statusOnMap.includes("arrived"),
          offered:
            !props.auth.selectedFleet.statusOnMap ||
            props.auth.selectedFleet.statusOnMap.includes("offered"),
          booked:
            !props.auth.selectedFleet.statusOnMap ||
            props.auth.selectedFleet.statusOnMap.includes("booked"),
          collecting:
            !props.auth.selectedFleet.statusOnMap ||
            props.auth.selectedFleet.statusOnMap.includes("collecting"),
          otwMerchant:
            !props.auth.selectedFleet.statusOnMap ||
            props.auth.selectedFleet.statusOnMap.includes("otwMerchant"),
          engaged:
            !props.auth.selectedFleet.statusOnMap ||
            props.auth.selectedFleet.statusOnMap.includes("engaged"),
          delivering:
            !props.auth.selectedFleet.statusOnMap ||
             props.auth.selectedFleet.statusOnMap.includes("delivering"),
          droppedOff:
            !props.auth.selectedFleet.statusOnMap ||
            props.auth.selectedFleet.statusOnMap.includes("droppedOff"),
          bookOff:
            !props.auth.selectedFleet.statusOnMap ||
            props.auth.selectedFleet.statusOnMap.includes("bookOff"),
          action:
            !props.auth.selectedFleet.statusOnMap ||
            props.auth.selectedFleet.statusOnMap.includes("action"),
          pre:
          !props.auth.selectedFleet.statusOnMap ||
          props.auth.selectedFleet.statusOnMap.includes("pre")
        },
        showAll: true,
        hileAll: false,
        company: null,
        carType: null,
        textSearch: "",
        airline:null
      },
      driverList: {
        inProgress: [],
        bookFree: []
      },
      bookingListShowed: [],
      selectedZone: null,
      showInforMarker: null,
      loadmorePage: 1,
      endLoadMore: false,
      isDataPhaseLoaded: false,
      isShowAll: true,
      traffic:false,
      bicycling:false,
      isAirlineBusiness:false,
      collapseBookingStatus: false,
    };
    this.handleMapLoad = this.handleMapLoad.bind(this);
    this.mapControlShow = this.mapControlShow.bind(this);
    this.mapDriverItemClick = this.mapDriverItemClick.bind(this);
    this.mapWatingItemClick = this.mapWatingItemClick.bind(this);
    this.handleSelectPickUp = this.handleSelectPickUp.bind(this);
    this.handleChangePickUp = this.handleChangePickUp.bind(this);
    this.handleAddressRemovedPickUp = this.handleAddressRemovedPickUp.bind(
      this
    );
    this.handleChangeLayer = this.handleChangeLayer.bind(this);
    this.handleChangeBicyclingLayer = this.handleChangeBicyclingLayer.bind(this);
    this.handleTextSerchHandle = this.handleTextSerchHandle.bind(this);
    this.handleCarChange = this.handleCarChange.bind(this);
    this.handleChangeshowAllCars = this.handleChangeshowAllCars.bind(this);
    this.handleMapStatusChange = this.handleMapStatusChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.UpdateMapByFilter = this.UpdateMapByFilter.bind(this);
    this.bookingMapfilterValidator = this.bookingMapfilterValidator.bind(this);
    this.driverMapFilterValidator = this.driverMapFilterValidator.bind(this);
    this.rowClickHandle = this.rowClickHandle.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.getDefaultZone = this.getDefaultZone.bind(this);
    this.renderDriverList = this.renderDriverList.bind(this);
    this.renderBookingList = this.renderBookingList.bind(this);
    this.mapAutoUpdateCronjob = this.mapAutoUpdateCronjob.bind(this);
    this.attachScrollListener = this.attachScrollListener.bind(this);
    this.detachScrollListener = this.detachScrollListener.bind(this);
    this.scrollListener = this.scrollListener.bind(this);
    this.handleMapBoundChanged = this.handleMapBoundChanged.bind(this);
    this.initMapBookAndDriverData = this.initMapBookAndDriverData.bind(this);
    this.loadActiveBooking = this.loadActiveBooking.bind(this);
    this.LoadMapDrivers = this.LoadMapDrivers.bind(this);
    this.QQMapRef = this.QQMapRef.bind(this);
    this.addLoadedOrLoadingData = this.addLoadedOrLoadingData.bind(this);
    this.checkIfLoadingOrLoadedData = this.checkIfLoadingOrLoadedData.bind(
      this
    );
    this.handleAirlineChange = this.handleAirlineChange.bind(this);

  }

  componentDidMount() {
    realtimeHelper.MAP_DRIVER_RESET()
    const { permissions = {} } = this.props || {};
    this.getOut(permissions);
    this.attachScrollListener();
    realtimeHelper.setTimeout();
    this.props.commonActions.autoRefreshCueAndMapChange(true);
    this.UpdateMapByFilter(this.props);
    realtimeHelper.setDataChanged(true);
    let self = this
    window.openBookingDetail = function (bookId) {
      if (bookId) {
        if (_.get(self.props, 'auth.user.roles.isSupplier', false)) {
          if (!_.get(self.props, 'auth.user.roles.supplierPermission.booking', false)) {
            return
          }
          return self.props.router.push({pathname:`/supplier-booking-detail/${bookId}`, state: {selectedTab: 0}})
        }
        self.props.bookingDetailActions
          .getBookingDetail(bookId)
          .then((data) => {
            if (!data.res || data.error) {
              self.context.notification(
                'error',
                I18n.t('cue.Error_load_booking')
              );
            }
          });
      }
    }

    this.state.updateDataChangeCronJobId = this.mapAutoUpdateCronjob();
    if (window.windowInfo) {
      window.windowInfo.setMap(null);
    }
    if (!this.state.selectedZone) {
      this.getDefaultMap();
    }

    this.setState({
      finalZone: filterZonesBasedPermission(this.props.auth, this.props.commonData.mapZone, [...this.props.commonData.companies, ...this.props.commonData.suppliers])
    })

    this.initMapBookAndDriverData();
    const { airlineBusiness = {} } = this.props.auth.selectedFleet;
    let isAirlineBusiness = airlineBusiness && airlineBusiness.enable ? true : false;
    if (isAirlineBusiness) {
      this.props.commonActions.loadAirline({
        fleetId: this.props.auth.selectedFleet.fleetId,
        isAirline: true,
      });
      this.setState({ isAirlineBusiness });
    };
  }

  getOut = (permissions) => {
    const { selectedFleet = {} } = this.props.auth || {};
    const { modules = [] } = selectedFleet || {};
    const redirectComponent = getHasPermissionModule(permissions, modules);
    if (redirectComponent) {
      this.props.menuActions.sidebarLinkClickAction(
        redirectComponent.key,
        false
      );
      browserHistory.replace(redirectComponent.url);
    }
  }

  addLoadedOrLoadingData(status, type) {
    //loadedData[`${type}.${zone}.${status}`] = true
    loadedData[`${type}.${status}`] = true;
  }

  checkIfLoadingOrLoadedData(status, type) {
    //return loadedData[`${type}.${zone}.${status}`]
    return loadedData[`${type}.${status}`];
  }

  mapAutoUpdateCronjob() {
    return setInterval(() => {
      if (isWorking) {
        return;
      }
      if (realtimeHelper.isDataChanged()) {
        realtimeHelper.setDataChanged(false);
        if (
          this.props.auth.selectedFleet.map &&
          this.props.auth.selectedFleet.map.isAutoRefresh &&
          !this.props.commonData.isAutoRefreshCueAndMap
        ) {
          return;
        } else {
          this.UpdateMapByFilter();
        }
      }
      let newTime = 500;
      if (realtimeHelper.isMapDataLoaded()) {
        if (
          this.props.auth.selectedFleet.map &&
          this.props.auth.selectedFleet.map.isAutoRefresh
        ) {
          newTime = this.props.auth.selectedFleet.map.value * 1000;
        } else {
          newTime +=
            (realtimeHelper.listActiveSortedIndex().length +
              Object.keys(realtimeHelper.driverList()).length) /
            20;
        }
      }
      if (newTime > realtimeHelper.timeOut() + 50) {
        realtimeHelper.setTimeout(newTime);
        clearInterval(this.state.updateDataChangeCronJobId);
        this.state.updateDataChangeCronJobId = this.mapAutoUpdateCronjob();
      }
    }, realtimeHelper.timeOut());
  }

  initMapBookAndDriverData() {
    if (this.props.auth.selectedFleet.fleetId) {
      //check if status is loaded
      let isLoaded = false;
      isLoaded = this.props.auth.selectedFleet.statusOnMap.every(status => {
        return this.checkIfLoadingOrLoadedData(status, "book");
      });
      if (!isLoaded) {
        this.loadActiveBooking(this.props.auth.selectedFleet.statusOnMap);
      }
      this.LoadMapDrivers(this.props.auth.selectedFleet.statusOnMap);
      socketDispatchApi.emit(socketConfigs.send.subscribe, {
        channel: 'FleetDriverLocation',
        fleetId: this.props.auth.selectedFleet.fleetId
      })
      this.props.auth.selectedFleet.statusOnMap.map(obj => {
        this.addLoadedOrLoadingData(obj, "book");
      });
    }
  }

  LoadMapDrivers(status, page = 0) {
    let statusDriver = [];
    status.map(obj => {
      if (obj == "bookIn") {
        statusDriver.push(obj);
      } else if (obj == "bookOff") {
        statusDriver.push(obj);
      } else {
        statusDriver.push("inProgress");
      }
    });
    if (!statusDriver.length) return;
    let query = {
      limit: 1000,
      page: page,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId,
        online: true,
        // 'zoneId': {
        //   $in: [zoneId],
        //   $nin: driverZone
        // },
        status: {
          $in: _.uniq(statusDriver)
        }
      }
    };
    this.props.mapActions.loadMapDriver(query, false).then(data => {
      if (data.res && data.res.total > (data.res.page + 1) * data.res.limit) {
        console.log('LoadMapDrivers Again')
        this.LoadMapDrivers(status, data.res.page + 1);
      } else {
        if (this.state.isDataPhaseLoaded) {
          realtimeHelper.setMapDataLoaded(true);
        } else {
          this.state.isDataPhaseLoaded = true;
        }
      }
    });
  }

  QQMapRef(e) {
    this.state.autoComplete = e;
  }

  loadActiveBooking(status, page = 0) {
    status = status.filter(obj => {
      return obj != "bookIn" && obj != "bookOff";
    });
    if (!status.length) return;
    //status.push("pre");
    let query = {
      limit: 1000,
      page: page,
      query: {
        status,
        fleetId: this.props.auth.selectedFleet.fleetId
      }
    };
    this.props.mapActions.mapBoookingsFind(query, false).then(data => {
      if (data.res && data.res.total > (data.res.page + 1) * data.res.limit) {
        this.loadActiveBooking(status, data.res.page + 1);
      } else {
        if (this.state.isDataPhaseLoaded) {
          realtimeHelper.setMapDataLoaded(true);
        } else {
          this.state.isDataPhaseLoaded = true;
        }
      }
    });
  }

  shouldComponentUpdate(nextProps) {
    const { permissions: currentPermissions } = this.props;
    const { permissions } = nextProps;
    if (!_.isEqual(currentPermissions, permissions)) this.getOut(permissions);
    return true;
  }

  componentWillUnmount() {
    clearInterval(this.state.updateDataChangeCronJobId);
    this.detachScrollListener();
    socketDispatchApi.emit(socketConfigs.send.unSubscribe, {
      channel: 'FleetDriverLocation',
      fleetId: this.props.auth.selectedFleet.fleetId
    })
    realtimeHelper.MAP_DRIVER_RESET()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.selectedZone) {
      this.getDefaultMap();
    }
  }
  getDefaultMap() {
    let defaultMap = this.getDefaultZone();
    if (defaultMap && !this.state.selectedZone) {
      var latLng = null;
      if (
        this.props.commonData.location &&
        this.props.commonData.location.isChina
      ) {
        if(window.qq.maps) {
          latLng = new window.qq.maps.LatLngBounds();
          let coordinates = defaultMap.geo.coordinates;
          if (
            defaultMap.center &&
            defaultMap.center.coordinates &&
            defaultMap.center.coordinates.length > 0
          ) {
            coordinates = defaultMap.center.coordinates;
          }
          coordinates.map(cor => {
            cor.map(z => {
              latLng.extend(new window.qq.maps.LatLng(z[1], z[0]));
            });
          });
        }
      } else {
        latLng = new google.maps.LatLngBounds();
        let coordinates = defaultMap?.geo?.coordinates || [];
        if (
          defaultMap.center &&
          defaultMap.center.coordinates &&
          defaultMap.center.coordinates.length > 0
        ) {
          coordinates = defaultMap.center.coordinates;
        }
        coordinates?.map(cor => {
          cor.map(z => {
            latLng.extend(new google.maps.LatLng(z[1], z[0]));
          });
        });
      }

      let center = latLng.getCenter();
      if (this._mapComponent) {
        // this._mapComponent.fitBounds(latLng);
        // this.setState({ center: center, selectedZone: defaultMap });
        this._mapComponent.setCenter(center);
        this._mapComponent.setZoom(15);
        this.setState({ center: center, selectedZone: defaultMap });
      } else {
        setTimeout(() => {
          this.getDefaultMap();
        }, 100);
      }
    }
  }

  getDefaultZone() {
    if (
      this.state.finalZone?.length > 0
    ) {
      let defaultMap = this.state.finalZone?.find(m => {
        return m?.isDefault && m?.isActive;
      });
      return defaultMap || this.state.finalZone?.[0];
    }
    return null;
  }

  mapControlShow() {
    this.state.showControl = !this.state.showControl;
    this.setState(
      {
        showControl: this.state.showControl,
        loadmorePage: 1,
        endLoadMore: false
      },
      () => {
        if (
          this.props.commonData.location &&
          this.props.commonData.location.isChina
        ) {
          window.qq.maps.event.trigger(this._mapComponent, "resize");
        } else {
          google.maps.event.trigger(this._mapComponent, "resize");
        }
      }
    );
  }

  mapDriverItemClick = (driver) => {
    let marker = realtimeHelper.getMapMarkers()[driver._id];
    this.setState({ driverShowring: driver})
    //in case we use Tencent map
    if (
      this.props.commonData.location &&
      this.props.commonData.location.isChina
    ) {
      if (!marker) {
        
        let mk = CCLiteCommonFunc.createDriversMapItem(driver, true);
        mk.map = this._mapComponent;
        marker = new window.qq.maps.Marker(mk);
        window.windowInfo.close();
        window.qq.maps.event.addListener(marker, "click", function () {
          window.windowInfo.setOptions({
            content: this.infoContent,
            maxWidth: 300,
            map: this.getMap(),
            position: this.getPosition()
          });
          window.windowInfo.open();
          this.getMap().setCenter(this.getPosition());
        });
        realtimeHelper.addMarkers([marker]);
      }
      window.windowInfo.setOptions({
        content: marker.infoContent,
        maxWidth: marker.infoMaxWidth,
        map: this._mapComponent,
        position: marker.getPosition()
      });
      if (!marker.map) {
        marker.setMap(this._mapComponent);
      }
      window.windowInfo.open();
      this._mapComponent.setCenter(marker.getPosition());
    } else {
      //in case we use Google map
      let self = this
      if (!marker) {
        let mk = CCLiteCommonFunc.createDriversMapItem(driver, false);
        mk.map = this._mapComponent;
        marker = new google.maps.Marker(mk);
        marker.addListener("click", function () {
          window.windowInfo.setOptions({
            content: this.infoContent,
            maxWidth: 300
          });
          self.setState({ driverShowring: driver })
          window.windowInfo.open(this.map, this);
          this.map.setCenter(this.getPosition());
        });
        realtimeHelper.addMarkers([marker]);
      }
      window.windowInfo.setOptions({
        content: marker.infoContent,
        maxWidth: marker.infoMaxWidth
      });
      if (!marker.map) {
        marker.setMap(this._mapComponent);
      }
      window.windowInfo.open(marker.map, marker);
      marker.map.setCenter(marker.getPosition());
    }
  }

  mapWatingItemClick(booking) {
    let marker = realtimeHelper.getMapMarkers()[booking._id];
    this.setState({ driverShowring: {} })
    if (
      this.props.commonData.location &&
      this.props.commonData.location.isChina
    ) {
      if (!marker) {
        let mk = CCLiteCommonFunc.createMapItemFromBooking(booking, true);
        mk.map = this._mapComponent;
        marker = new window.qq.maps.Marker(mk);
        window.qq.maps.event.addListener(marker, "click", function () {
          window.windowInfo.setOptions({
            content: this.infoContent,
            maxWidth: 300,
            map: this.getMap(),
            position: this.getPosition()
          });
          window.windowInfo.open();
          this.getMap().setCenter(this.getPosition());
        });
        realtimeHelper.addMarkers([marker]);
      }
      window.windowInfo.setOptions({
        content: marker.infoContent,
        maxWidth: marker.infoMaxWidth,
        map: this._mapComponent,
        position: marker.getPosition()
      });

      if (!marker.map) {
        marker.setMap(this._mapComponent);
      }
      window.windowInfo.open();
      this._mapComponent.setCenter(marker.getPosition());
    } else {
      if (!marker) {
        let mk = CCLiteCommonFunc.createMapItemFromBooking(booking);
        mk.map = this._mapComponent;
        marker = new google.maps.Marker(mk);
        marker.addListener("click", function () {
          window.windowInfo.setOptions({
            content: this.infoContent,
            maxWidth: 300
          });
          window.windowInfo.open(this.map, this);
          this.map.setCenter(this.getPosition());
        });
        realtimeHelper.addMarkers([marker]);
      }
      window.windowInfo.setOptions({
        content: marker.infoContent,
        maxWidth: marker.infoMaxWidth
      });

      if (!marker.map) {
        marker.setMap(this._mapComponent);
      }
      window.windowInfo.open(marker.map, marker);
      marker.map.setCenter(marker.getPosition());
    }
  }

  handleMapLoad(map) {
    if (
      this.props.commonData.location &&
      this.props.commonData.location.isChina
    ) {
      this._mapComponent = map;
      window.qq.maps.event.addListener(
        this._mapComponent,
        "bounds_changed",
        this.handleMapBoundChanged
      );
      window.windowInfo = new window.qq.maps.InfoWindow({});
    } else {
      if (
        map &&
        map.context &&
        map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
      ) {
        this._mapComponent =
          map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
      }
      this._mapComponent.addListener(
        "bounds_changed",
        this.handleMapBoundChanged
      );
      if(!window?.windowInfo) {
        window.windowInfo = new google.maps.InfoWindow({});
      }
    }
    if (this._mapComponent) {
      realtimeHelper.setMapToAllMarkers(this._mapComponent);
      if (typeof this._mapComponent.addListener == "function") {
      } else {
      }
    }
  }
  handleMapBoundChanged(e) {
    clearTimeout(window.mapupdater);
    this.state.center = this._mapComponent.center;
    window.mapupdater = setTimeout(() => {
      if (
        this.props.auth.selectedFleet.map &&
        this.props.auth.selectedFleet.map.isAutoRefresh
      ) {
        this.UpdateMapByFilter();
      } else {
        realtimeHelper.setDataChanged(true);
      }
    }, 500);
  }

  handleSelectPickUp(data, placeName, pointOfInterest, sessionToken) {
    this.setState({
      addressPickUp: data
    });
    if (
      this.props.commonData.location &&
      this.props.commonData.location.isChina
    ) {
      const callback = (err, localtion, results) => {
        if (err) {
          return
        }
        if (!window.searchPlaceMarked) {
          window.searchPlaceMarked = new window.qq.maps.Marker({
            position: new window.qq.maps.LatLng(localtion.lat, localtion.lng),
            defaultAnimation: 0,
            icon: mapIcons.flag,
            map: this._mapComponent
          });
          this._mapComponent.setCenter(
            new window.qq.maps.LatLng(localtion.lat, localtion.lng)
          );
          this._mapComponent.setZoom(18);
        } else {
          window.searchPlaceMarked.setOptions({
            position: new window.qq.maps.LatLng(localtion.lat, localtion.lng),
            defaultAnimation: 0,
            icon: mapIcons.flag,
            map: this._mapComponent
          });
          this._mapComponent.setCenter(
            new window.qq.maps.LatLng(localtion.lat, localtion.lng)
          );
          this._mapComponent.setZoom(18);
        }
        this.setState({ center: localtion });
      }
      if(placeName) {
        geoPlaceDetailMapProvider({
          auth: this.props.auth,
          placeid: placeName,
          sessionToken,
          callback,
          language: this.props.language,
          isTencent: true
        })
      }
    } else {
      if (data && data != "") {
        const callback = (err, localtion, results) => {
          if (err) {
            return
          }
          if (!window.searchPlaceMarked) {
            window.searchPlaceMarked = new google.maps.Marker({
              position: new google.maps.LatLng(localtion.lat, localtion.lng),
              defaultAnimation: 0,
              icon: mapIcons.flag,
              map: this._mapComponent
            });
            this._mapComponent.setCenter(
              new google.maps.LatLng(localtion.lat, localtion.lng)
            );
            this._mapComponent.setZoom(18);
          } else {
            window.searchPlaceMarked.setOptions({
              position: new google.maps.LatLng(localtion.lat, localtion.lng),
              defaultAnimation: 0,
              icon: mapIcons.flag,
              map: this._mapComponent
            });
            this._mapComponent.setCenter(
              new google.maps.LatLng(localtion.lat, localtion.lng)
            );
            this._mapComponent.setZoom(18);
          }
          this.setState({ center: localtion });
        }
        if(placeName) {
          geoPlaceDetailMapProvider({
            auth: this.props.auth,
            placeid: placeName,
            sessionToken,
            callback,
            language: this.props.language,
            isTencent: false
          })
        }
      } else {
        this.setState({ locationPickUp: null });
      }
    }
  }

  handleChangePickUp(data) {
    if (
      this.props.commonData.location &&
      this.props.commonData.location.isChina
    ) {
      this.setState({ addressPickUp: data });
    } else {
      this.setState({ addressPickUp: data });
    }
  }

  handleAddressRemovedPickUp() {
    if (window.searchPlaceMarked) {
      window.searchPlaceMarked.setMap(null);
    }
    this.setState({ addressPickUp: "" });
  }
  handleChangeLayer(e){
    if (e === 'on') {
      this.setState({traffic: true});
    }else{
      this.setState({traffic: false});
    }
  }
  handleChangeBicyclingLayer(e){
    if (e === 'on') {
      this.setState({bicycling: true});
    }else{
      this.setState({bicycling: false});
    }
  }

  getCenterMap = async (zoneId) => {
    const { commonData, loadingBarActions, commonActions, auth } = this.props
    let finalMapzone = this.state.finalZone || []
    if(!commonData.isZoneLoadFullGeo) {
      // Trường hợp chưa load full geo thì load 
      loadingBarActions.showLoadingSpiner();
      const result = await commonActions.loadMapZoneFullGeo(auth.selectedFleet.fleetId)
      loadingBarActions.hideLoadingSpiner()
      if(result?.res?.length > 0) {
        finalMapzone = result?.res
      }
    }
    finalMapzone?.every(zone => {
      if (zone._id == zoneId) {
        this.state.selectedZone = zone;
        let latLng = null;
        if (
          this.props.commonData.location &&
          this.props.commonData.location.isChina
        ) {
          latLng = new window.qq.maps.LatLngBounds();
          if (
            zone.center &&
            zone.center.coordinates &&
            zone.center.coordinates.length > 0
          ) {
            zone.center.coordinates.map(cor => {
              cor.map(z => {
                latLng.extend(new window.qq.maps.LatLng(z[1], z[0]));
              });
            });
          } else {
            zone.geo.coordinates.map(cor => {
              cor.map(z => {
                latLng.extend(new window.qq.maps.LatLng(z[1], z[0]));
              });
            });
          }
        } else {
          latLng = new google.maps.LatLngBounds();
          if (
            zone.center &&
            zone.center.coordinates &&
            zone.center.coordinates.length > 0
          ) {
            zone.center.coordinates.map(cor => {
              cor.map(z => {
                latLng.extend(new google.maps.LatLng(z[1], z[0]));
              });
            });
          } else {
            zone?.geo?.coordinates.map(cor => {
              cor.map(z => {
                latLng.extend(new google.maps.LatLng(z[1], z[0]));
              });
            });
          }
        }
        let center = latLng.getCenter();
        if (this._mapComponent) {
          this._mapComponent.setCenter(center);
          this._mapComponent.setZoom(15);
        }
        this.setState({ center: center });
        return false;
      }
      return true;
    });
  }
  
  handleZoneChange = async (e) => {
    this.getCenterMap(e.target.value)
  }

  handleCarChange(e) {
    this.state.filter.carType = null;
    this.props.commonData.carType.map(vh => {
      if (vh.vehicleType == e.target.value) {
        this.state.filter.carType = vh;
      }
    });
    this.setState(
      { showInforMarker: null, loadmorePage: 1, endLoadMore: false },
      this.UpdateMapByFilter
    );
    if (this.refs.driverScroller) {
      ReactDOM.findDOMNode(this.refs.driverScroller).scrollTop = 0;
    }
  }
  handleShowAllCar = e => {
    if (e.target.checked) {
      this.state.filter.showAll = true;
      this.state.filter.hileAll = false;
      Object.keys(this.state.filter.status).map(k => {
        if (k !== "action" || k !== "pre") {
          this.state.filter.status[k] = true;
        }
      });

      const mapStatus = [
        "bookIn",
        "arrived",
        "offered",
        "booked",
        "engaged",
        'delivering',
        "droppedOff",
        "bookOff",
        "action",
        'pre',
        'collecting',
        'otwMerchant'
      ];
      mapStatus.map(s => {
        if (
          this.state.filter.status[s] &&
          !this.checkIfLoadingOrLoadedData(s, "book")
        ) {
          this.addLoadedOrLoadingData(s, "book");
          this.loadActiveBooking([s]);
        }
      });
      console.log('handleShowAllCar')
      this.LoadMapDrivers(_.filter(
        _.keys(this.state.filter.status),
        (key) => this.state.filter.status[key] === true
      ));
    } else {
      this.state.filter.showAll = false;
      this.state.filter.hileAll = true;
      Object.keys(this.state.filter.status).map(k => {
        if (k !== "action" || k !== "pre") {
          this.state.filter.status[k] = false;
        }
      });
    }
    this.setState(
      {
        showInforMarker: null,
        loadmorePage: 1,
        endLoadMore: false,
        isShowAll: !this.state.isShowAll
      },
      this.UpdateMapByFilter
    );
    if (this.refs.driverScroller) {
      ReactDOM.findDOMNode(this.refs.driverScroller).scrollTop = 0;
    }
  };
  handleChangeshowAllCars(e) {
    if (e.target.value == 0) {
      this.state.filter.showAll = true;
      this.state.filter.hileAll = false;
      Object.keys(this.state.filter.status).map(k => {
        if (k !== "action" || k !== "pre") {
          this.state.filter.status[k] = true;
        }
      });
    } else {
      this.state.filter.showAll = false;
      this.state.filter.hileAll = true;
      Object.keys(this.state.filter.status).map(k => {
        if (k !== "action" || k !== "pre") {
          this.state.filter.status[k] = false;
        }
      });
    }
    this.setState(
      { showInforMarker: null, loadmorePage: 1, endLoadMore: false },
      this.UpdateMapByFilter
    );
    if (this.refs.driverScroller) {
      ReactDOM.findDOMNode(this.refs.driverScroller).scrollTop = 0;
    }
  }

  handleMapStatusChange(status) {
    this.state.filter.status[status] = !this.state.filter.status[status];
    if(status === 'action'){
      this.state.filter.status['pre'] = !this.state.filter.status['pre'];
    }else if(status === 'booked'){
      this.state.filter.status['collecting'] = !this.state.filter.status['collecting'];
    }else if(status === 'engaged'){
      this.state.filter.status['delivering'] = !this.state.filter.status['delivering'];
    }
    this.setState(
      { showInforMarker: null, loadmorePage: 1, endLoadMore: false },
      this.UpdateMapByFilter
    );
    if (
      this.state.filter.status[status] &&
      !this.checkIfLoadingOrLoadedData(status, "book")
    ) {
      this.addLoadedOrLoadingData(status, "book");
      this.loadActiveBooking([status]);

    }
    console.log('handleMapStatusChange')
    this.LoadMapDrivers(
      _.filter(
        _.keys(this.state.filter.status),
        (key) => this.state.filter.status[key] === true
      )
    );
    if (this.refs.driverScroller) {
      ReactDOM.findDOMNode(this.refs.driverScroller).scrollTop = 0;
    }
  }
  handleCompanyChange(e) {
    this.state.filter.company = null;
    [...this.props.commonData.companies, ...this.props.commonData.suppliers].map(cp => {
      if (cp._id == e.target.value) {
        this.state.filter.company = cp;
      }
    });
    this.setState(
      { showInforMarker: null, loadmorePage: 1 },
      this.UpdateMapByFilter
    );
    if (this.refs.driverScroller) {
      ReactDOM.findDOMNode(this.refs.driverScroller).scrollTop = 0;
    }
  }
  handleAirlineChange(e) {
    this.state.filter.airline = null;
    this.props.commonData.airline.map(cp => {
      if (cp._id == e.target.value) {
        this.state.filter.airline = cp;
      }
    });
    this.setState(
        { showInforMarker: null, loadmorePage: 1 },
        this.UpdateMapByFilter
    );
    if (this.refs.driverScroller) {
      ReactDOM.findDOMNode(this.refs.driverScroller).scrollTop = 0;
    }
  }
  handleTextSerchHandle(e) {
    this.state.filter.textSearch = e.target.value;
    this.setState(
      { showInforMarker: null, loadmorePage: 1, endLoadMore: false },
      this.UpdateMapByFilter
    );
    if (this.refs.driverScroller) {
      ReactDOM.findDOMNode(this.refs.driverScroller).scrollTop = 0;
    }
  }

  UpdateMapByFilter(newProps) {
    if (isWorking) {
      return;
    }
    isWorking = true;
    const props = newProps || this.props;
    return new Promise(resolve => {
      let result = {
        driverList: {
          inProgress: [],
          bookFree: []
        },
        markers: {},
        bookingListShowed: []
      };
      let maxMarkerShowed = 1000,
        markerShowedCount = 0;
      if (this._mapComponent) {
        if (this._mapComponent.zoom > 15) {
          maxMarkerShowed = 2000;
        }
        if (this._mapComponent.zoom > 18) {
          maxMarkerShowed = 3000;
        }
        // console.log("status action: ",this.state.filter.status.action);
        if (this.state.filter.status.action === true) {
          Object.keys(realtimeHelper.mapBookings()).every(bookId => {
            let item = realtimeHelper.mapBookings()[bookId];
            // console.log("item",item);
            // hoang.dinh is remove reservation
            if (!item || item.status !== "action" ) {
              return true;
            }
            if (this.bookingMapfilterValidator(item)) {
              // console.log("bookingMapfilterValidator",item);
              let mk = CCLiteCommonFunc.createMapItemFromBooking(
                item,
                this.props.commonData.location &&
                this.props.commonData.location.isChina
              );
              if (mk) {
                window.qqmap = this._mapComponent;
                if (
                  this._mapComponent.getBounds() &&
                  this._mapComponent.getBounds().contains(mk.position)
                ) {
                  result.markers[mk.key] = mk;
                }
              }
              markerShowedCount++;
              result.bookingListShowed.push(item);
              if (maxMarkerShowed > -1 && markerShowedCount >= 700) {
                return false;
              }
            }
            return true;
          });
        }
        if (this.state.filter.status.pre === true) {
          Object.keys(realtimeHelper.mapBookings()).every(bookId => {
            let item = realtimeHelper.mapBookings()[bookId];
            if (!item || item.status !== "pre" || item.reservation ) {
              return true;
            }
            if (this.bookingMapfilterValidator(item)) {
              //console.log("item",item);
              let mk = CCLiteCommonFunc.createMapItemFromBooking(
                  item,
                  this.props.commonData.location &&
                  this.props.commonData.location.isChina
              );
              if (mk) {
                window.qqmap = this._mapComponent;
                if (
                    this._mapComponent.getBounds() &&
                    this._mapComponent.getBounds().contains(mk.position)
                ) {
                  result.markers[mk.key] = mk;
                }
              }
              markerShowedCount++;
              result.bookingListShowed.push(item);
              if (maxMarkerShowed > -1 && markerShowedCount >= 700) {
                return false;
              }
            }
            return true;
          });
        }
        Object.keys(realtimeHelper.driverList()).every(drKey => {
          let dr = realtimeHelper.driverList()[drKey];
          if (!dr) {
            return true;
          }

          let currentAgent = !this.props.auth?.user?.roles?.isSupplier && this.props.auth?.user?.roles?.companyId
          let listCompanySupplier = [...this.props?.commonData?.companies, ...this.props?.commonData?.suppliers]
          if (currentAgent && !listCompanySupplier.find(item => item._id == dr.company.companyId)) {
            return true
          }

          if (this.props.auth.user.roles.isSupplier &&
            this.props.auth.user.roles.companyId &&
            this.props.auth.user.roles.companyId != dr.company.companyId
          ) {
            return true;
          }
          if (this.props.auth.user.roles.isSupplier &&
            !!this.props.auth.user.roles.companyId &&
            this.props.auth.user.roles.companyId != dr.company.companyId
          ) {
            return true;
          }
          dr.currentJob = dr.currentJob.map(job => {
            //console.log("bookId",job.bookId);
            job.bookingDetail = realtimeHelper.mapBookings()[job.bookId];
           // console.log("bookingDetail: ",job.bookingDetail);
            return job;
          });
          if (this.driverMapFilterValidator(dr)) {
            // console.log("dr: ",dr);
            if (dr.status === "inProgress") {
              result.driverList.inProgress.push(dr);
            } else {
              result.driverList.bookFree.push(dr);
            }
            if (!dr.loc || !dr.loc.coordinates) {
              return true;
            }
            if (
              maxMarkerShowed === -1 ||
              markerShowedCount <= maxMarkerShowed
            ) {
              
              dr.s3Host = this.props.auth.selectedFleet.s3Host
              let mk = CCLiteCommonFunc.createDriversMapItem(
                dr,
                this.props.commonData.location &&
                this.props.commonData.location.isChina
              );
              if (
                mk &&
                this._mapComponent.getBounds() &&
                this._mapComponent.getBounds().contains(mk.position)
              ) {
                result.markers[mk.key] = mk;
                markerShowedCount++;
              }
            }
          }
          return true;
        });

        // Update position and icon marker
        let existedMarkers = realtimeHelper.getMapMarkers();
        Object.keys(existedMarkers).every(key => {
          let newMK = result.markers[key],
            existedMK = existedMarkers[key];
          if (!newMK) {
            existedMK.setMap(null);
            return true;
          }

          existedMK.setPosition(newMK.position);
          existedMK.setIcon(newMK.icon);
          if(
            newMK.infoContent
            && key === this.state?.driverShowring?._id
          ) {
            window.windowInfo.setOptions({
              content: newMK.infoContent,
              maxWidth: 300
            });
          }
          if (!existedMK.map) {
            existedMK.setMap(this._mapComponent);
          }
          delete result.markers[key];
          return true;
        });

        let newMarkers = [];
        Object.keys(result.markers).map(key => {
          let mk = result.markers[key];
          mk.map = this._mapComponent;

          //in case we use Tencent map
          if (
            this.props.commonData.location &&
            this.props.commonData.location.isChina
          ) {
            let newMK = new window.qq.maps.Marker(mk);
            window.qq.maps.event.addListener(newMK, "click", function () {
              window.windowInfo.setOptions({
                content: this.infoContent,
                maxWidth: 300,
                position: this.getPosition(),
                map: this.getMap()
              });
              window.windowInfo.open();
              this.map.setCenter(this.getPosition());
            });
            newMarkers.push(newMK);
          } else {
            //in case we use Google map
            let newMK = new google.maps.Marker(mk);
            let self = this
            newMK.addListener("click", function () {
              window.windowInfo.setOptions({
                content: this.infoContent,
                maxWidth: 300
              });
              window.windowInfo.open(this.map, this);
              self.setState({ driverShowring: mk.driver || {} })
              this.map.setCenter(this.getPosition());
            });
            newMarkers.push(newMK);
          }
        });
        realtimeHelper.addMarkers(newMarkers);
      }
      resolve(result);
    })
      .then(data => {
        this.setState(
          {
            driverList: data.driverList,
            bookingListShowed: data.bookingListShowed,
            loadmorePage: Math.abs(this.state.loadmorePage)
          },
          () => {
            isWorking = false;
          }
          );
        })
        .catch(err => {
          console.error(err)
          isWorking = false;
      });
  }

  bookingMapfilterValidator(item) {
    const companyId = _.get(this.props.auth, 'user.roles.companyId');
    if (this.state.isAirlineBusiness && companyId) {
      if (item.request.companyId) {
        if (companyId !== item.request.companyId) {
          return false;
        }
      } else {
        const bookStatus = ['action', 'pending', 'pre', 'accepted', 'queue', 'offered',"delivering","collecting" ,"otwMerchant"];
        const driverCompany = _.get(item, 'drvInfo.companyId');
        if (bookStatus.includes(item.status) && (companyId != driverCompany || item.dispatchType == 0)) {
          return false;
        }
      }
    }

    if(companyId) {
      const companyInfo = getSupplierInfoFromID(
        [...this.props.commonData.companies, ...this.props.commonData.suppliers],
        companyId
      );
      if(!checkSupplierSupportZonePickup(companyInfo, item)) {
        return false
      }
      if (this.props.auth?.selectedFleet?.process?.broadcastBooking || true) {
        if (!checkBroadcastWithSupplierSelecting(true, companyId, companyInfo, item.supplierCompanies, this.props.auth?.selectedFleet?.process)) {
          return false;
        }
      } else {
        const driverCompany = _.get(item, 'drvInfo.companyId');
        if (
          !item.supplierCompanies.includes(companyId) &&
          driverCompany !== companyId
        ) {
          return false;
        }
      }
    }

    if(this.state.isAirlineBusiness && this.state.filter.airline){
      if(item.corporateInfo && item.corporateInfo.corporateId === this.state.filter.airline._id) {
        return true;
      }else{
        return false
      }
    }
    if (this.state.filter.status[item.status] === false) {
      return false;
    }

    if (!!this.state.filter.textSearch) {
      let txt = this.state.filter.textSearch.toLowerCase();

      if (
        item.drvInfo &&
        item.drvInfo.plateNumber &&
        item.drvInfo.plateNumber.toLowerCase().indexOf(txt) >= 0
      ) {
        return true;
      }

      if (
        item.psgInfo &&
        ((item.psgInfo.firstName || "") + " " + (item.psgInfo.lastName || ""))
          .toLowerCase()
          .indexOf(txt) >= 0
      ) {
        return true;
      }

      return false;
    }

    return true;
  }

  driverMapFilterValidator(driver) {
  //  console.log("driver",driver);
    let isHave = true;
    if (!driver) {
      return false;
    }
    let isHaveJobBookingRideSharing = false;
    const { allowableData } = this.props.commonData;
    if (this.state.filter.status[driver.status] === false) {
      return false;
    }
    if (driver.company && !!this.state.filter.company) {
      if (driver.company.companyId !== this.state.filter.company._id) {
        return false;
      }
    }

    if (driver.driverId && allowableData) {
      //check if driver
      if (allowableData.type == PermissionType.DriversAndCars) {
        let drivertemp = allowableData.allowDrivers.find(
          obj => obj == driver.driverId
        );
        let vehicleTmp = allowableData.allowVehicles.find(
          obj => obj == (driver.vehicle && driver.vehicle.vehicleId)
        );
        if (!drivertemp && !vehicleTmp) return false;
      }
    }

    if (driver.status == "inProgress") {
      let isHaveStatus = false;
      driver.currentJob.map(job => {
        if (job) {
          //console.log("status",this.state.filter.status[job.status]);
          if (this.state.filter.status[job.status]) {
            isHaveStatus = true;
          }
          //console.log("isHaveStatus",isHaveStatus);
          if (job.bookingDetail && job.bookingDetail.dispatchRideSharing) {
            isHaveJobBookingRideSharing = true;
          }
        }
      });
      if (!isHaveStatus) {
        return false;
      }
    }

    if (driver.vehicle && this.state.filter.carType) {
      if (this.state.filter.carType.dispatchRideSharing) {
        if (!driver.rideSharing) {
          return false;
        }
        if (driver.status == "inProgress") {
          if (!isHaveJobBookingRideSharing) {
            return false;
          }
        }
      } else {
        if (driver.status == "inProgress") {
          if (isHaveJobBookingRideSharing) {
            return false;
          }
        }
      }
      isHave = false;
      this.state.filter.carType.dispatch.every(dp => {
        if (dp == driver.vehicle.vehicleType) {
          isHave = true;
          return false;
        }
        return true;
      });
    }
    if (!isHave) {
      return isHave;
    }

    if (!!this.state.filter.textSearch) {
      let txt = this.state.filter.textSearch.toLowerCase();
      if (
        ((driver.firstName || "") + " " + (driver.lastName || ""))
          .toLowerCase()
          .indexOf(txt) >= 0
      ) {
        return true;
      }

      if (
        driver.vehicle &&
        driver.vehicle.plateNumber &&
        driver.vehicle.plateNumber.toLowerCase().indexOf(txt) >= 0
      ) {
        return true;
      }

      isHave = false;
      driver.currentJob &&
        driver.currentJob.map(job => {
          if (
            job.bookingDetail &&
            job.bookingDetail.psgInfo &&
            (
              (job.bookingDetail.psgInfo.firstName || "") +
              " " +
              (job.bookingDetail.psgInfo.lastName || "")
            )
              .toLowerCase()
              .indexOf(txt) >= 0
          ) {
            isHave = true;
          }
        });

      return isHave;
    }

    return true;
  }

  rowClickHandle(selectedItem) {
    if (_.get(this.props, 'auth.user.roles.isSupplier', false)) {
      if (!_.get(this.props, 'auth.user.roles.supplierPermission.booking', false)) {
        return
      }
      return this.props.router.push({pathname:`/supplier-booking-detail/${selectedItem.bookId}`, state: {selectedTab: 0}})
    }
    this.props.bookingDetailActions
      .getBookingDetail(selectedItem.bookId, this.state.selectedTab == 1)
      .then(data => {
        if (!data.res || data.error) {
          this.context.notification("error", I18n.t("map.Error_load_booking"));
        }
      });
  }

  renderDriverList() {
    
    const { permissions = null } = this.props || {};
    const { actions = false } = permissions || {};
    let voipToken = localStorage.getItem('voipToken')
    let qd = localStorage.getItem('qd')
    let webvoipdomain = localStorage.getItem('webvoipdomain')
    let maxBookingListShowed =
      (Math.abs(this.state.loadmorePage) || 1) * itemPerPage -
      this.state.bookingListShowed.length;
    let driverBooks = [];
    if (maxBookingListShowed > 0) {
      this.state.driverList.inProgress.every(dr => {
        if (maxBookingListShowed <= driverBooks.length) {
          return false;
        }
        dr.currentJob.map(job => {
          if (maxBookingListShowed <= driverBooks.length) {
            return false;
          }
          if (
            !job.bookingDetail ||
            this.state.filter.status[job.status] === false
          ) {
            return true;
          }
          driverBooks.push(
            <Row
              key={job.bookingDetail._id}
              className={"driver-item " + job.status}
              onClick={() => {
                this.mapDriverItemClick(dr);
              }}
            >
              <div className="passenger-item">
                <span className="title">
                  <Translate value="map.Driver_name" />:
                </span>
                <span >
                  {dr.firstName +
                    " " +
                    dr.lastName +
                    "/ "
                  }
                </span>
                {
                  dr.vehicle && (
                    <span style={{ marginLeft: '5px'}}>
                      {` ${dr?.vehicle?.plateNumber}`}
                    </span>
                  )
                }
                {voipToken.length > 0?
                <div>
        
                    <div>
                    <span style={{ margin: `5px`,color: `#353944`}}>|</span>
                        <span style={{color: `#4b9cc3`}} onClick={() => {
                        var lengthDriverName =10*(dr.firstName.length+dr.lastName.length)+350
                        window.open(`${webvoipdomain}?token=${voipToken}&fleetId=${dr.fleetId}&driverName=${dr.firstName} ${dr.lastName}&phone=${dr.phone}&plateNumber=${dr.vehicle.plateNumber}&vehicleType=${dr.vehicle.vehicleType}&avatar=${dr.s3Host}${dr.avatar}&qd=${qd}`, "myWindow", "width="+lengthDriverName+",height=223")
          
                            }}>
                        <u>{dr.phone}</u>
                      </span>
                  </div>
              </div>
              :<div></div>
                }
                
              </div>
              <div>
                <div className="passenger-item">
                  <span className="title">
                    <Translate value="map.Passenger" />:
                  </span>
                  <span>
                    {job.bookingDetail.psgInfo
                      ? job.bookingDetail.psgInfo.firstName +
                      " " +
                      job.bookingDetail.psgInfo.lastName
                      : ""}
                  </span>

                  {job.bookingDetail.dispatchRideSharing ? (
                    <Image src={ride_sharing_icon} />
                  ) : null}
                </div>
                <div className="passenger-item">
                  <span className="title">
                    <Translate value="map.Trip" />:
                  </span>
                  <span>
                    {job.bookingDetail.intercityInfo
                        ? (job.bookingDetail.intercityInfo.routeFromZone || '') +
                    " - " +
                    (job.bookingDetail.intercityInfo.routeToZone || '')
                        : ""}
                  </span>

                </div>
                {job.bookingDetail.status !== "droppedOff" ? (
                  <div className="passenger-item">
                    <span className="title">
                      <Translate value="map.Start_point" />:
                    </span>

                    <span>
                      {job.bookingDetail.offeredInfo
                        ? job.bookingDetail.offeredInfo.address
                        : ""}
                    </span>
                  </div>
                ) : null}

                <div className="passenger-item">
                  <span className="title">
                    <Translate value="map.Pickup" />:
                  </span>
                  <span>
                    {job.bookingDetail.request.pickup
                      ? job.bookingDetail.request.pickup.address
                      : ""}
                  </span>
                </div>
                <div className="passenger-item">
                  <span className="title">
                    <Translate value="map.Destination" />:
                  </span>
                  <span>
                    {job.bookingDetail.request.destination
                      ? job.bookingDetail.request.destination.address
                      : ""}
                  </span>
                </div>
                {job.bookingDetail.status == "offered" ||
                  job.bookingDetail.status == "booked" ? (
                    <div className="passenger-item">
                      <span className="title">
                        <Translate value="map.Estimate" />:
                    </span>
                      <span>
                        {(job.bookingDetail.offeredInfo && job.bookingDetail.offeredInfo.estimateValue)
                          ? `${Math.round(job.bookingDetail.offeredInfo.estimateValue/60)} min(s)`
                          : ""}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                {
                  (_.get(this.props, 'auth.user.roles.isSupplier') && !_.get(this.props, 'auth.user.roles.supplierPermission.booking', false)) ? <></> :
                  actions && <div className="driver-action">
                    <Image
                      onClick={() => {
                        this.rowClickHandle(job.bookingDetail);
                      }}
                      src={edit_icon}
                    />
                    {/* <i
                    className="fa fa-pencil-square-o fa-4x block"
                    aria-hidden="true"
                    onClick={() => {
                      this.rowClickHandle(job.bookingDetail);
                    }}
                  /> */}
                  </div>
                }
              </div>
            </Row>
          );
          return true;
        });
        return true;
      });
    }
    if (maxBookingListShowed > driverBooks.length) {
      this.state.driverList.bookFree.every(driver => {
        // console.log("driver ", driver)
        driver &&
          driverBooks.push(
            <Row
              key={driver._id}
              className={"driver-item " + driver.status}
              onClick={() => {
                this.mapDriverItemClick(driver);
              }}
            >
              <div className="passenger-item">
                <span className="title">
                  <Translate value="map.Driver_name" />:
                
                </span>
                <span>
                  {driver.firstName +
                    " " +
                    driver.lastName +
                    "/ "
                    }
                </span>
                {
                  driver.vehicle && (
                    <span style={{ marginLeft: '5px'}}>
                      {` ${driver?.vehicle?.plateNumber}`}
                    </span>
                  )
                }
                {voipToken.length > 0?
                <div>
                    <span style={{ margin: `5px`,color: `#353944`}}>|</span>
                  
                        <span style={{color: `#4b9cc3`}} onClick={() => {
                        var lengthDriverName =10*(driver.firstName.length+driver.lastName.length)+350
                        window.open(`${webvoipdomain}?token=${voipToken}&fleetId=${driver.fleetId}&driverName=${driver.firstName} ${driver.lastName}&phone=${driver.phone}&plateNumber=${driver.vehicle.plateNumber}&vehicleType=${driver.vehicle.vehicleType}&avatar=${driver.s3Host}${driver.avatar}&qd=${qd}`, "myWindow", "width="+lengthDriverName+",height=230")
          
                            }}>
                        <u>{driver.phone}</u>
                        </span>
                  
                </div>:<div></div>
                }
              </div>
              
            </Row>
          );
        if (maxBookingListShowed <= driverBooks.length) {
          return false;
        }
        return true;
      });
    }
    if (
      maxBookingListShowed > itemPerPage &&
      maxBookingListShowed > driverBooks.length &&
      driverBooks.length > 0
    ) {
      this.state.endLoadMore = true;
    }

    return driverBooks;
  }

  renderBookingList() {
    let books = [];
    const { permissions = null } = this.props || {};
    const { actions = false } = permissions || {};
    if (!this.state.bookingListShowed) {
      return books;
    }
     //console.log("TCL: Map -> renderBookingList -> bookingListShowed", this.state.bookingListShowed)
    let maxBookingListShowed =
      ((this.state.loadmorePage || 0) + 1) * itemPerPage;

    Object.keys(this.state.bookingListShowed).every(bookingId => {
      let booking = this.state.bookingListShowed[bookingId];
     // console.log("----: booking", booking)
      if (maxBookingListShowed <= books.length) {
        return false;
      }
      if (booking) {
        booking &&
          books.push(
            <Row
              key={booking._id}
              className={"driver-item " + booking.status}
              onClick={() => {
                this.mapWatingItemClick(booking);
              }}
            >
              <div style={{ width: '100%'}}>
                <div className="passenger-item">
                  <span className="title">
                    <Translate value="map.Passenger" />:
                  </span>
                  <span>
                    {booking.psgInfo
                      ? booking.psgInfo.firstName +
                      " " +
                      booking.psgInfo.lastName
                      : ""}
                  </span>
                  {booking.dispatchRideSharing ? (
                    <Image src={ride_sharing_icon} />
                  ) : null}
                </div>
                <div className="passenger-item">
                  <span className="title">
                    <Translate value="map.Pickup" />:
                  </span>
                  <span>
                    {booking.request.pickup
                      ? booking.request.pickup.address
                      : ""}
                  </span>
                </div>
                <div className="passenger-item">
                  <span className="title">
                    <Translate value="map.Destination" />:
                  </span>
                  <span>
                    {booking.request.destination
                      ? booking.request.destination.address
                      : ""}
                  </span>
                </div>
                {
                  (_.get(this.props, 'auth.user.roles.isSupplier') && !_.get(this.props, 'auth.user.roles.supplierPermission.booking', false)) ? <></> :
                  actions && <div className="driver-action">
                    <Image
                      onClick={() => {
                        this.rowClickHandle(booking);
                      }}
                      src={edit_icon}
                    />
                    {/* <i
                    className="fa fa-pencil-square-o fa-4x block"
                    aria-hidden="true"
                    onClick={() => {
                      this.rowClickHandle(booking);
                    }}
                  /> */}
                    {/* {booking.dispatchRideSharing ? (
                    <i
                      className="fa fa fa-users fa-4x mr-t-5 block"
                      aria-hidden="true"
                    />
                  ) : null} */}
                  </div>
                }
              </div>
            </Row>
          );
      }
      return true;
    });
    return books;
  }
  scrollListener(e) {
    var elWidth = ReactDOM.findDOMNode(
      this.refs.scroll_bottom
    ).getBoundingClientRect();
    if (
      e.target.offsetHeight + e.target.scrollTop >=
      e.target.scrollHeight - 2
    ) {
      if (
        !isWorking &&
        !this.state.endLoadMore &&
        elWidth.top - e.target.offsetHeight < 450
      ) {
        isWorking = true;
        this.setState(
          { loadmorePage: (this.state.loadmorePage || 0) + 1 },
          () => {
            setTimeout(() => {
              isWorking = false;
            }, 100);
          }
        );
      }
    }
  }
  attachScrollListener() {
    window.addEventListener("scroll", this.scrollListener, true);
    window.addEventListener("resize", this.scrollListener, true);
  }
  detachScrollListener() {
    window.removeEventListener("scroll", this.scrollListener, true);
    window.removeEventListener("resize", this.scrollListener, true);
  }
  render() {
    const { rowClickHandle } = this;
    const AutocompleteItem = ({ suggestion }) => (
      <div className="map-inner">
        <i className="fa fa-map-marker" />
        <span>{suggestion}</span>
      </div>
    );
    const myStyles = {
      // label: { color: "red" },
      input: { width: "100%" },
      autocompleteContainer: { padding: "5px 12px" },
      autocompleteItem: { color: "#e6e6e6", margin: "5px 0" },
      autocompleteItemActive: { color: "#04BE76" }
      // color: "#04BE76",
    };

    const isAutoRefresh =
      this.props.auth.selectedFleet.map &&
      this.props.auth.selectedFleet.map.isAutoRefresh;

    let totalDriver =
      this.state.driverList.inProgress.length +
      this.state.driverList.bookFree.length;

    let totalDriverFontSize = 45;
    if (totalDriver > 9999) {
      let multi = parseInt(totalDriver / 10000).toString().length;
      if (multi > 2) {
        totalDriverFontSize = 22;
      } else {
        totalDriverFontSize = 45 - multi * 10;
      }
    }
    return (
      <div className={this.state.showControl ? "fill showcontrol" : "fill"}>
        {this.props.commonData.location &&
          this.props.commonData.location.isChina ? (
            <div className="mapqup fill">
              <QQMap
                className="fill"
                onLoad={this.handleMapLoad}
                options={{ center: this.state.center }}
              />
            </div>
          ) : (
            <div className="fill">
            <GettingStartedGoogleMap
              containerElement={<div className="mapqup fill" />}
              mapElement={<div style={{ height: `100%` }} />}
              center={this.state.center}
              onMapLoad={this.handleMapLoad}
              onMarkerClose={this.handleMarkerClose}
              traffic={this.state.traffic}
              bicycling={this.state.bicycling}
            />
              <ButtonToolbar className={
                this.state.showControl
                ? this.props.permissions && this.props.permissions.viewheatmap ?  "map-select-layer active" : "map-select-layer-tag active"
                : this.props.permissions && this.props.permissions.viewheatmap ?  "map-select-layer" : "map-select-layer-tag"
              }>
                <DropdownButton  id="dropdown-basic-button" title={this.state.traffic && this.state.bicycling ? I18n.t("map.trafficBicycling") : this.state.traffic ?  I18n.t("map.traffic") : this.state.bicycling ? I18n.t("map.bicyclingLayers") : I18n.t("map.layer") } className="btn-map">
                  <Dropdown.Item  eventKey={this.state.traffic}  onClick={() => {
                            this.handleChangeLayer(this.state.traffic ? 'off' : 'on');
                          }}>
                    <CcCheckbox
                        checked={this.state.traffic}
                        onChange={this.handleChangeLayer}
                        className="radio-show-all"
                        text={<Translate value="map.traffic" />}
                        />
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={this.state.bicycling}   onClick={() => {
                            this.handleChangeBicyclingLayer(this.state.bicycling ? 'off' : 'on');
                          }} >
                    <CcCheckbox
                        checked={this.state.bicycling}
                        onChange={this.handleChangeBicyclingLayer}
                        className="radio-show-all"
                        text={<Translate value="map.bicyclingLayers" />}
                        />
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonToolbar>
           </div>

          )}
        <div
          className={
            this.state.showControl
              ? "map-controlers-container active"
              : "map-controlers-container"
          }
        >
          <div className={`show-buton-container ${this.state.showControl ? "chevron-right" : "chevron-left"}`}>
            <div className={"show-button"} onClick={this.mapControlShow}>
              {
                this.state.showControl ? <BsChevronRight /> : <BsChevronLeft />
              }
            </div>
          </div>
          <form
              inline
              onSubmit={e => {
                e.preventDefault();
              }}
              className={
                this.state.showControl
                  ? "map-select-zone active"
                  : "map-select-zone"
              }
            >
              <FormGroup className="map-zone-group">
                {this.props.commonData.location &&
                  this.props.commonData.location.isChina ? (
                    <div className="place-container">
                      <AutocompleteWith3rd 
                        placeholder={I18n.t("map.Enter_a_location")}
                        value={this.state.addressPickUp}
                        onSelect={this.handleSelectPickUp}
                        handleAddressRemoved={this.handleAddressRemovedPickUp}
                        onChange={this.handleChangePickUp}
                        // disabled={disabled}
                      />
                    </div>
                  ) : (
                    <div className="place-container">
                      <PlacesAutocomplete
                        value={this.state.addressPickUp}
                        center={this.state?.selectedZone?.center?.coordinates}
                        onSelect={this.handleSelectPickUp}
                        onChange={this.handleChangePickUp}
                        autocompleteItem={AutocompleteItem}
                        styles={myStyles}
                        placeholder={I18n.t("map.Enter_a_location")}
                        onEnterKeyDown={this.handleSelectPickUp}
                        handleAddressRemoved={this.handleAddressRemovedPickUp}
                      />
                    </div>
                  )}

                <FormControl
                  className="select-map-zone-option form-custom"
                  as="select"
                  onChange={this.handleZoneChange}
                  value={
                    this.state.selectedZone
                      ? this.state.selectedZone._id
                      : this.getDefaultZone()
                        ? this.getDefaultZone()._id
                        : ""
                  }
                >
                  {this.state?.finalZone?.length > 0 && (
                        this.state?.finalZone?.map(z => {
                          if (z?.display) {
                            return (
                              <option key={z._id} value={z._id}>
                                {z.zoneName}
                              </option>
                            );
                          }
                          return "";
                        })
                  )}
                </FormControl>
              </FormGroup>
            </form>
          <div className="map-controlers">


            <div className="map-control-side-bar">
              <div className="select-top-map">
                <FormControl
                  className="form-custom-no-margin mr-r-5"
                  as="select"
                  value={
                    this.state.filter.carType
                      ? this.state.filter.carType.vehicleType
                      : -1
                  }
                  onChange={this.handleCarChange}
                >
                  <option value={-1}>{I18n.t("map.All_cars")}</option>
                  {this.props.commonData.carType
                    ? this.props.commonData.carType.map(c => {
                      return (
                        <option key={c.vehicleType} value={c.vehicleType}>
                          {c.vehicleType}
                        </option>
                      );
                    })
                    : ""}
                </FormControl>
                {this.state.isAirlineBusiness ? (
                    <FormControl
                        className="form-custom-no-margin mr-l-5"
                        as="select"
                        value={
                    this.state.filter.airline
                      ? this.state.filter.airline._id
                      : -1
                  }
                        onChange={this.handleAirlineChange}
                        >
                      <option value={-1}>{I18n.t("map.All_airline")}</option>
                      {this.props.commonData.airline.map(c => {
                        return (
                            <option key={c._id} value={c._id}>
                              {c.name}
                            </option>
                        );
                      })}
                    </FormControl>
                ) : (
                  !this.props?.auth?.user?.roles?.isSupplier && 
                    <FormControl
                        className="form-custom-no-margin mr-l-5"
                        as="select"
                        value={
                    this.state.filter.company
                      ? this.state.filter.company._id
                      : -1
                  }
                        onChange={this.handleCompanyChange}
                        >
                      <option value={-1}>{I18n.t("map.All_company")}</option>
                      {[...this.props.commonData.companies, ...this.props.commonData.suppliers].map(c => {
                        return (
                            <option key={c._id} value={c._id}>
                              {c.name}
                            </option>
                        );
                      })}
                    </FormControl>
                )}

              </div>
              <div className="total-show">
                <div className="total">
                  <Translate value="map.Total_Drivers" />
                  <div
                    className="total-number"
                    style={{ fontSize: `${totalDriverFontSize}px` }}
                  >
                    {toLocaleNumber(totalDriver)}
                  </div>
                </div>
                <div onClick={() => this.setState({collapseBookingStatus: !this.state.collapseBookingStatus})} className="button-show-all">
                  <div>{I18n.t("report.query.bookingStatus")}</div>
                  <div style={{color: '#04BE76', fontSize: '12px'}}>
                    {
                      this.state.collapseBookingStatus ? (
                        <BsCaretUpFill />
                      ) : (
                        <BsCaretDownFill />
                      )
                    }
                  </div>
                </div>
                <div className={`show-all ${this.state.collapseBookingStatus && "open-show-all"}`}>
                  <CcCheckbox
                    checked={this.state.isShowAll}
                    onChange={this.handleShowAllCar}
                    className="radio-show-all"
                    text={<Translate value="map.Show_all" />}
                  />
                  <div className="show-all-list">
                    <div className="mr-t-15 show-all-item-first">
                      <CcCheckbox
                        inputClassName="map-checkbox"
                        checked={this.state.filter.status.bookIn}
                        onChange={() => {
                          this.handleMapStatusChange("bookIn");
                        }}
                        labelClassName="bookIn"
                      />
                      <Translate value="map.Vacant" />
                    </div>
                    <div className="mr-t-15 show-all-item-first">
                      <CcCheckbox
                        inputClassName="map-checkbox"
                        checked={this.state.filter.status.offered}
                        onChange={() => {
                          this.handleMapStatusChange("offered");
                        }}
                        labelClassName="offered"
                      />
                      <Translate value="map.Dispatching" />
                    </div>
                    <div className="show-all-item">
                      <CcCheckbox
                        inputClassName="map-checkbox"
                        checked={this.state.filter.status.booked}
                        onChange={() => {
                          this.handleMapStatusChange("booked");
                        }}
                        labelClassName="booked "
                      />
                      <Translate value="map.Driver_on_the_way" />
                    </div>
                    <div className="show-all-item">
                      <CcCheckbox
                        inputClassName="map-checkbox"
                        checked={this.state.filter.status.arrived}
                        onChange={() => {
                          this.handleMapStatusChange("arrived");
                        }}
                        labelClassName="arrived "
                      />
                      <Translate value="map.Arrived_and_waiting" />
                    </div>
                    <div className="show-all-item">
                      <CcCheckbox
                        inputClassName="map-checkbox"
                        checked={ this.state.filter.status.engaged}
                        onChange={() => {
                          this.handleMapStatusChange("engaged") ;
                        }}
                        labelClassName="engaged "
                      />
                      <Translate value="map.P_O_B" />
                    </div>
                    <div className="show-all-item">
                      <CcCheckbox
                        inputClassName="map-checkbox"
                        checked={this.state.filter.status.droppedOff}
                        onChange={() => {
                          this.handleMapStatusChange("droppedOff");
                        }}
                        labelClassName="droppedOff "
                      />
                      <Translate value="map.Dropped_off" />
                    </div>

                    <div className="show-all-item">
                      <CcCheckbox
                        inputClassName="map-checkbox"
                        checked={ this.state.filter.status.action}
                        onChange={() => {
                          this.handleMapStatusChange("action");
                        }}
                        labelClassName="action"
                      />
                      <Translate value="map.Waiting_for_process" />
                    </div>
                    <div className="show-all-item">
                      <CcCheckbox
                        inputClassName="map-checkbox"
                        checked={this.state.filter.status.bookOff}
                        onChange={() => {
                          this.handleMapStatusChange("bookOff");
                        }}
                        labelClassName="bookOff "
                      />
                      <Translate value="map.Unavailable" />
                    </div>
                  </div>
                </div>
              </div>
              <Row className="item">
                <Col xs={isAutoRefresh ? 6 : 12}>
                  <FormGroup className="search-format-map">
                    <FormControl
                      className="search-form-map"
                      onChange={this.handleTextSerchHandle}
                      value={this.state.filter.textSearch}
                      placeholder={I18n.t("map.Search_car_here")}
                      type="text"
                    />
                    <BsSearch className="search-icon" />
                  </FormGroup>
                </Col>
                {isAutoRefresh ? (
                  <Col xs={6}>
                    <FormGroup className="auto-refresh-form">
                      <CcCheckbox
                        checked={this.props.commonData.isAutoRefreshCueAndMap}
                        onChange={e => {
                          this.props.commonActions.autoRefreshCueAndMapChange(
                            e.target.checked
                          );
                        }}
                        text={"Auto-refresh ({0} seconds)".format(
                          this.props.auth.selectedFleet.map.value
                        )}
                      />
                      <i
                        className="fa fa-refresh auto-refresh-icon"
                        onClick={() => {
                          this.UpdateMapByFilter();
                        }}
                      />
                    </FormGroup>
                  </Col>
                ) : (
                    ""
                  )}
              </Row>
            </div>
            <div className="drivers-container">
              <div className="list-drivers" ref="driverScroller">
                <div className="col-xs-12 col-md-12 col-lg-12 driver-item-container">
                  {this.renderBookingList()}
                  {this.renderDriverList()}
                  <div ref="scroll_bottom" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Map.contextTypes = {
  notification: PropTypes.func,
  reloadCueAnhMap: PropTypes.func
};
function mapStateToProps(state) {
  return {
    auth: state.auth,
    commonData: state.commonData,
    map: state.map,
    cue: state.cue,
    permissions: state.menuHandle.modulePermission,
    menuHandle: state.menuHandle,
    language: state.i18n
  };
}

function mapDispatchToProps(dispatch) {
  return {
    menuActions: bindActionCreators(menuActions, dispatch),
    mapActions: bindActionCreators(mapActions, dispatch),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    cueActions: bindActionCreators(cueActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);
