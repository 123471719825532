import { CCLiteCommonFunc, checkBroadcastWithSupplierSelecting, checkSupplierSupportZonePickup } from './commonFunctions'
import { activeStatusAll, activeStatus, finishedStatus, rideServiceSearchType, rideSharingSearchType, activeStatusDelivery, activeStatusFoodMart, HYDRA_STATUS, bookFrom, UNIT_TIME, offerStatus } from "../constants/commondata"
var _ = require('lodash');
var moment = require('moment');
const types = {
    MAP_DRIVER_SUCCESS: "MAP_DRIVER_SUCCESS",
    MAP_DRIVER_RESET: "MAP_DRIVER_RESET",
    MAP_BOOKING_SUCCESS: "MAP_BOOKING_SUCCESS",
    MAP_DRIVER_ADD: 'MAP_DRIVER_ADD',
    MAP_DRIVER_UPDATE: 'MAP_DRIVER_UPDATE',
    MAP_DRIVER_OFFLINE: 'MAP_DRIVER_OFFLINE',
    MAP_DRIVER_UPDATE_LOCATION: 'MAP_DRIVER_UPDATE_LOCATION',
    MAP_DRIVER_UPDATE_BOOKIN: 'MAP_DRIVER_UPDATE_BOOKIN',
    MAP_DRIVER_UPDATE_BOOKOFF: 'MAP_DRIVER_UPDATE_BOOKOFF',
    MAP_DRIVER_UPDATE_INFO: 'MAP_DRIVER_UPDATE_INFO',
    CUE_SUCCESS: "CUE_SUCCESS",
    CUE_UPDATE: 'CUE_UPDATE',
    CUE_EDIT: 'CUE_EDIT',
    CUE_UPDATE_FILLTER: 'CUE_UPDATE_FILLTER',
    CUE_ACTIVE_LOADMORE: 'CUE_ACTIVE_LOADMORE',
    CUE_ADD_NEW_BOOKING: 'CUE_ADD_NEW_BOOKING',
    CUE_REMOVE_BOOKING: 'CUE_REMOVE_BOOKING',
    CUE_REMOVE_BOOKING_HYDRA: 'CUE_REMOVE_BOOKING_HYDRA'
}


window.realtimeData = {
    cueBookings: {},
    isCueDataLoaded: false,
    listActiveSortedIndex: [],
    isCueDataSorted: false,
    driverList: {},
    mapBookings: {},
    mapMakers: {},
    dataPooolChanged: false,
    isCueChanged: false,
    realtimeUpdateDelay: 500,
    isMapDataLoaded: false
}
const PaxApp = "Pax App";
export const realtimeHelper = {
    MAP_DRIVER_SUCCESS: (data) => {
        updateRealTimeData(types.MAP_DRIVER_SUCCESS, data);
    },
    MAP_DRIVER_RESET: (data) => {
        updateRealTimeData(types.MAP_DRIVER_RESET, data);
    },
    MAP_BOOKING_SUCCESS: (data) => {
        updateRealTimeData(types.MAP_BOOKING_SUCCESS, data)
    },
    MAP_DRIVER_ADD: (data) => {
        updateRealTimeData(types.MAP_DRIVER_ADD, data);
    },
    MAP_DRIVER_UPDATE: (data) => {
        updateRealTimeData(types.MAP_DRIVER_UPDATE, data);
    },
    MAP_DRIVER_OFFLINE: (data) => {
        updateRealTimeData(types.MAP_DRIVER_OFFLINE, data);
    },
    MAP_DRIVER_UPDATE_LOCATION: (data) => {
        updateRealTimeData(types.MAP_DRIVER_UPDATE_LOCATION, data);
    },
    MAP_DRIVER_UPDATE_BOOKIN: (data) => {
        updateRealTimeData(types.MAP_DRIVER_UPDATE_BOOKIN, data);
    },
    MAP_DRIVER_UPDATE_BOOKOFF: (data) => {
        updateRealTimeData(types.MAP_DRIVER_UPDATE_BOOKOFF, data);
    },
    MAP_DRIVER_UPDATE_INFO: (data) => {
        updateRealTimeData(types.MAP_DRIVER_UPDATE_INFO, data);
    },
    CUE_SUCCESS: (data) => {
        updateRealTimeData(types.CUE_SUCCESS, data);
    },
    CUE_ADD_NEW_BOOKING: (data) => {
        updateRealTimeData(types.CUE_ADD_NEW_BOOKING, data);
    },
    CUE_UPDATE: (data) => {
        updateRealTimeData(types.CUE_UPDATE, data);
    },
    CUE_EDIT: (data) => {
        updateRealTimeData(types.CUE_EDIT, data);
    },
    CUE_REMOVE_BOOKING: (bookId) => {
        updateRealTimeData(types.CUE_REMOVE_BOOKING, bookId);
    },
    CUE_REMOVE_BOOKING_HYDRA: (bookId) => {
        updateRealTimeData(types.CUE_REMOVE_BOOKING_HYDRA, bookId);
    },
    sortBookingList: (sort, data = window.realtimeData.cueBookings) => {
        return sortList(data, sort);
    },
    filterBookingList: (filter, data = window.realtimeData.cueBookings) => {
        return filterBookingList(filter, data);
    },
    validateBookFilter: (book, filter) => {
        return validateBookFilter(book, filter)
    },
    isDataChanged: () => {
        return window.realtimeData.dataPooolChanged
    },
    setDataChanged: (value) => {
        window.realtimeData.dataPooolChanged = value || false;
    },
    isCueChanged: () => {
        return window.realtimeData.isCueChanged;
    },
    setIsCueChanged: (value) => {
        window.realtimeData.isCueChanged = value || false;
    },
    cueBookings: () => {
        return window.realtimeData.cueBookings || {}
    },
    cueBookingsSortIndex: (sort, callback) => {
        window.realtimeData.isCueDataSorted = true;
        new Promise(resolve => {
            window.realtimeData.cueBookingsSortIndex = sortList(window.realtimeData.cueBookings, sort).index;
            resolve(window.realtimeData.cueBookingsSortIndex);
        }).then(data => {
            if (callback && typeof callback == 'function') {
                callback(data);
            }
        })

    },
    isCueDataSorted: () => {
        return window.realtimeData.isCueDataSorted;
    },
    isCueDataLoaded: () => {
        return window.realtimeData.isCueDataLoaded || false;
    },
    setCueDataLoaded: (value) => {
        window.realtimeData.isCueDataLoaded = value || false;
    },
    mapBookings: () => {
        return window.realtimeData.mapBookings || {}
    },
    listActiveSortedIndex: () => {
        return window.realtimeData.listActiveSortedIndex || []
    },
    setListActiveSortedIndex: (data) => {
        window.realtimeData.listActiveSortedIndex = data || [];
    },
    driverList: () => {
        return window.realtimeData.driverList || {}
    },
    timeOut: () => {
        return window.realtimeData.realtimeUpdateDelay;
    },
    setTimeout: (timeout) => {
        window.realtimeData.realtimeUpdateDelay = timeout || 500
    },
    getMapMarkers: () => {
        return window.realtimeData.mapMakers || {};
    },
    setMapMarkers: (markers) => {
        window.realtimeData.mapMakers = markers || {};
    },
    addMarkers: (markers) => {
        markers && markers.map(mk => {
            window.realtimeData.mapMakers[mk.key] = mk;
        })
    },
    setMapToAllMarkers: (map) => {
        Object.keys(window.realtimeData.mapMakers).map(key => {
            let mk = window.realtimeData.mapMakers[key];
            if (map.getBounds() && map.getBounds().contains(mk.getPosition())) {
                mk.setMap(map);
            } else {
                if (mk.map) {
                    mk.setMap(null);
                }
            }

        })
    },
    isMapDataLoaded: () => {
        return window.realtimeData.isMapDataLoaded || false;
    },
    setMapDataLoaded: (value) => {
        window.realtimeData.isMapDataLoaded = value || false;
    },
    sortTripsList: (sort, data) => {
        return sortTrips(data, sort);
    },
    checkStatusTrip: (status, data) => {
        return checkStatus(data, status);
    },
    checkSeatsTrip: (status, data) => {
        return checkStatusSeat(data, status);
    },
    checkTripTypes: (status, data) => {
        return checkStatusTripType(data, status);
    },
    checkCompany: (companyId, data) => {
        return checkCompanyDriver(data, companyId);
    }
}
function updateRealTimeData(type, data) {
    window.realtimeData.dataPooolChanged = true;
    switch (type) {
        case types.MAP_DRIVER_SUCCESS: {
            data.list.map(dr => {
                window.realtimeData.driverList[dr.phone] = dr;
            });
            window.realtimeData.dataPooolChanged = true;
            break;
        }
        case types.MAP_BOOKING_SUCCESS: {
            if (data.list.length > 0) {
                if (!CCLiteCommonFunc.isBookingStatusCompleted(data.list[0].status)) {
                    data.list.map(bk => {
                        window.realtimeData.mapBookings[bk.bookId] = bk;
                    })
                }
            }
            break;
        }
        case types.MAP_DRIVER_ADD: {
            window.realtimeData.driverList[data.phone] = Object.assign(window.realtimeData.driverList[data.phone] || {}, data)
            window.realtimeData.dataPooolChanged = true;
            break;
        }
        case types.MAP_DRIVER_RESET: {
            window.realtimeData.driverList = {}
            window.realtimeData.dataPooolChanged = true;
            break;
        }
        case types.MAP_DRIVER_UPDATE: {
            if (['bookIn', 'bookOff', 'inProgress'].includes(data.param.status) && window.realtimeData.driverList[data.param.phone]) {
                window.realtimeData.driverList[data.param.phone] = Object.assign(window.realtimeData.driverList[data.param.phone] || {}, data.param)
                window.realtimeData.dataPooolChanged = true;
            }
            break;
        }
        case types.MAP_DRIVER_OFFLINE: {
            delete window.realtimeData.driverList[data]
            window.realtimeData.dataPooolChanged = true;
            break;
        }
        case types.MAP_DRIVER_UPDATE_LOCATION: {
            let dr = window.realtimeData.driverList[data[2]];
            if (dr) {
                if (!dr.loc) {
                    dr.loc = {}
                }
                dr.loc.coordinates = [data[1], data[0]]
                window.realtimeData.driverList[data[2]] = dr;
                window.realtimeData.dataPooolChanged = true;
            }
            break;
        }
        case types.MAP_DRIVER_UPDATE_BOOKIN: {
            if (window.realtimeData.driverList[data] && window.realtimeData.driverList[data].status !== undefined) {
                window.realtimeData.driverList[data].status = 'bookIn'
                window.realtimeData.dataPooolChanged = true;
            }
            break;
        }
        case types.MAP_DRIVER_UPDATE_BOOKOFF: {
            if (window.realtimeData.driverList[data] && window.realtimeData.driverList[data].status !== undefined) {
                window.realtimeData.driverList[data].status = 'bookOff'
                window.realtimeData.dataPooolChanged = true;
            }
            break;
        }
        case types.MAP_DRIVER_UPDATE_INFO: {
            let driver = window.realtimeData.driverList[data.phone];
            if (driver) {
                driver.firstName = data.firstName;
                driver.lastName = data.lastName;
                driver.driver.name = data.fullName;
                driver.vehicle.plateNumber = data.driverInfo.carType.licensePlateNumber;
                driver.vehicle.vehicleId = data.driverInfo.vehicleId;
                driver.vehicle.vehicleMakeId = data.driverInfo.carType.make;
                driver.vehicle.vehicleModelId = data.driverInfo.carType.model;
                driver.vehicle.vehicleType = data.driverInfo.carType.name;
                window.realtimeData.driverList[data.phone] = driver;
                window.realtimeData.dataPooolChanged = true;
            }
            break;
        }
        case types.CUE_SUCCESS: {
            if (data.list.length > 0) {
                if (!CCLiteCommonFunc.isBookingStatusCompleted(data.list[0].status)) {
                    data.list.map(bk => {
                        if (!window.realtimeData.mapBookings[bk.bookId]) {
                            window.realtimeData.listActiveSortedIndex.push(bk.bookId);
                        }
                        window.realtimeData.mapBookings[bk.bookId] = window.realtimeData.cueBookings[bk.bookId] = bk;

                    })
                    window.realtimeData.dataPooolChanged = true;
                    window.realtimeData.isCueChanged = true;
                    window.realtimeData.isCueDataSorted = false
                }
            }

            break;
        }
        case types.CUE_ADD_NEW_BOOKING: {
            if (!window.realtimeData.cueBookings[data.bookId]) {
                window.realtimeData.listActiveSortedIndex.unshift(data.bookId);
            }
            window.realtimeData.mapBookings[data.bookId] = window.realtimeData.cueBookings[data.bookId] = data;
            window.realtimeData.isCueDataSorted = false
            window.realtimeData.dataPooolChanged = true;
            window.realtimeData.isCueChanged = true;
            break;
        }
        case types.CUE_UPDATE: {
            if (CCLiteCommonFunc.isBookingStatusCompleted(data.bookInfo.status)) {
                delete window.realtimeData.cueBookings[data.bookInfo.bookId]
                delete window.realtimeData.mapBookings[data.bookInfo.bookId]
            } else {
                if (!data.bookInfo.drvInfo) data.bookInfo.drvInfo = {}
                const currentBooking = window.realtimeData.cueBookings[data.bookInfo.bookId];
                if (currentBooking && currentBooking.latestUpdate && data.bookInfo.latestUpdate && currentBooking.latestUpdate >= data.bookInfo.latestUpdate) {
                    break;
                }
                window.realtimeData.cueBookings[data.bookInfo.bookId] = _.assign({}, window.realtimeData.cueBookings[data.bookInfo.bookId], data.bookInfo);
                window.realtimeData.mapBookings[data.bookInfo.bookId] = window.realtimeData.cueBookings[data.bookInfo.bookId];
            }
            window.realtimeData.dataPooolChanged = true;
            window.realtimeData.isCueChanged = true;
            break;
        }
        case types.CUE_EDIT: {
            if (CCLiteCommonFunc.isBookingStatusCompleted(data.dt.status || data.status)) {
                delete window.realtimeData.cueBookings[data.bookId];
                delete window.realtimeData.mapBookings[data.bookId];
            } else {
                if (window.realtimeData.cueBookings[data.bookId] && data.dt) {
                    window.realtimeData.cueBookings[data.bookId] = CCLiteCommonFunc.mergeBooking(window.realtimeData.cueBookings[data.bookId], data.dt);
                    if (data.tripId) {
                        window.realtimeData.cueBookings[data.bookId] = CCLiteCommonFunc.mergeBooking(window.realtimeData.cueBookings[data.bookId], { tripId: data.tripId });
                    }
                }
                if (window.realtimeData.mapBookings[data.bookId]) {
                    window.realtimeData.mapBookings[data.bookId] = CCLiteCommonFunc.mergeBooking(window.realtimeData.mapBookings[data.bookId], data.dt);
                    if (data.tripId) {
                        window.realtimeData.mapBookings[data.bookId] = CCLiteCommonFunc.mergeBooking(window.realtimeData.mapBookings[data.bookId], { tripId: data.tripId });
                    }
                }
                window.realtimeData.isCueDataSorted = false
            }
            window.realtimeData.dataPooolChanged = true;
            window.realtimeData.isCueChanged = true;
            break;
        }
        case types.CUE_REMOVE_BOOKING: {
            delete window.realtimeData.cueBookings[data];
            delete window.realtimeData.mapBookings[data];
            window.realtimeData.listActiveSortedIndex = window.realtimeData.listActiveSortedIndex.filter(id => id != data);
            window.realtimeData.dataPooolChanged = true;
            window.realtimeData.isCueChanged = true;
            break;
        }
        case types.CUE_REMOVE_BOOKING_HYDRA: {
            delete window.realtimeData.cueBookings[data.bookId];
            delete window.realtimeData.mapBookings[data.bookId];
            window.realtimeData.listActiveSortedIndex = window.realtimeData.listActiveSortedIndex.filter(id => id != data.bookId);
            window.realtimeData.dataPooolChanged = true;
            window.realtimeData.isCueChanged = true;
            break;
        }
        default: {
            break;
        }
    }

}
function sortList(datSort, option) {
    if (!datSort) {
        return { data: {}, index: [] };
    }
    let list = Object.keys(datSort).map(bk => {
        return datSort[bk];
    })
    if (option) {
        if (option.booking) {
            list = list.sort((a, b) => {
                if (option.booking == 1) {
                    return b.bookId - a.bookId;
                } else {
                    return a.bookId - b.bookId;
                }
            })
        } else if (option.rideType) {
            list = list.sort((a, b) => {
                const nameA = (a.corporateInfo ? a.corporateInfo.name ? a.corporateInfo.name : '' : "").toLowerCase()
                const nameB = (b.corporateInfo ? b.corporateInfo.name ? b.corporateInfo.name : '' : '').toLowerCase()
                if (option.rideType == 1) {
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                } else {
                    if (nameA > nameB) return -1;
                    if (nameA < nameB) return 1;
                    return 0;
                }
            })
        } else if(option.createdDate) {
            list = list.sort((a, b) => {
                if (option.createdDate == -1) {
                    return moment(b.createdDate) - moment(a.createdDate);
                } else {
                    return moment(a.createdDate) - moment(b.createdDate);
                }
            })
        } else if(option.expectedTime) {
            list = list.sort((a, b) => {
                if (option.expectedTime == -1) {
                    return moment(b.time.expectedPickupTime) - moment(a.time.expectedPickupTime);
                } else {
                    return moment(a.time.expectedPickupTime) - moment(b.time.expectedPickupTime);
                }
            })
        } else {
            list = list.sort((a, b) => {
                if (option.time == -1) {
                    return moment(b?.time?.pickUpTime) - moment(a?.time?.pickUpTime);
                } else {
                    return moment(a?.time?.pickUpTime) - moment(b?.time?.pickUpTime);
                }
            })
        }
    } else {
        list = list.sort((a, b) => {
            let time = Math.abs(moment(a.time.pickUpTime) - (new Date())) - Math.abs(moment(b.time.pickUpTime) - (new Date()))
            return time;
        })
    }
    datSort = {};
    let index = []
    list.map(bk => {
        datSort[bk._id] = bk;
        index.push(bk.bookId);
    })
    return {
        data: datSort,
        index: index
    };
}
function filterBookingList(filter, data) {
    let result = {};
    Object.keys(data).map(bookId => {
        let book = data[bookId];
        if (validateBookFilter(book, filter)) {
            result[bookId] = book;
        }
    })
    return sortList(result, filter.sort).index
}
function validateBookFilter(book, filter) {
    if (!book) {
        return false;
    }

    const fleetCurrentStorage = localStorage.getItem('fleet') || {};
    const fleetCurrent = JSON.parse(fleetCurrentStorage) || {};
    const { 
        fleetId: fleetOfBook, 
        isFarmOut = false,
    } = book
    if (isFarmOut) {
        if (fleetCurrent.fleetId === fleetOfBook) {
            const statusHydra = book.hydraInfo && book.hydraInfo.status
            if(statusHydra === HYDRA_STATUS.denied || statusHydra === HYDRA_STATUS.rejected ) {
                return false
            }
        } else {
            // this.setState({
            // farmOut: true,
            // supplierVehicle: fleetOfBook,
            // isHydraBooking: true,
            // data: {
            //     ...this.state.data,
            // }
            // })
        }
    }
    if (filter.corporate) {
        if (book.travelerType == 0 || (!book.corporateInfo || book.corporateInfo.corporateId != filter.corporate)) {
            return false;
        }
    }

    if(filter.driverCorporate && !checkSupplierSupportZonePickup(filter?.companyOfOperator, book)) {
        return false;
    }

    if (filter.currentAgent && book?.supplierCompanies?.length === 0) {
        return false
    }

    if (filter.currentAgent && !filter.currentSupplierCompaniesList.find(item => book?.supplierCompanies?.includes(item._id))) {
        return false
    }

    if (
        filter.driverCorporate
    ) {
        /*
            BroadcastBooking flow
        */
        const isShowWithBroadcastFlow = checkBroadcastWithSupplierSelecting(
            filter.fleetEnableBroadcastBooking,
            filter.driverCorporate,
            filter?.companyOfOperator,
            book?.supplierCompanies,
            book,
            filter.process
        )
        if (book?.supplierCompanies?.length > 0 && !book?.supplierCompanies.includes(filter.driverCorporate)) {
            return false
        }
        if(!isShowWithBroadcastFlow) {
            if (book.request.companyId) { // Đã có tài xế nhận
                if (filter.driverCorporate != book.request.companyId) {
                    return false;
                }
            } else {
                const bookStatus = ['action', 'pending', 'pre', 'accepted', 'queue', 'offered']
                const driverCompany = _.get(book, 'drvInfo.companyId')
                if(book.supplierCompanies?.length > 0) {
                    if(!book.supplierCompanies.includes(filter.driverCorporate)) {
                        return false
                    }
                } else {
                    if (bookStatus.includes(book.status)) {
                        /*
                        User Owner tạo book với All company > Không hiển thị trên Cue/Map của user Permission ở các status chưa có Driver nhận ("Pending", "Waiting for process", "Dispaching", "Accepted", "Timeout")
                         */
                        if (!book.drvInfo || book.drvInfo.companyId != filter.driverCorporate) {
                            return false;
                        }
                    } else if (filter.driverCorporate !== driverCompany) {
                        /* Nếu có Driver nhận book không thuộc company được gán cho user Permission > Không Show */
                        return false;
                    }
                }
            }
        }
    }

    if (filter.rideService && filter.rideService != rideServiceSearchType.all) {
        if (filter.rideService == rideServiceSearchType.regulation && book.dispatchRideSharing) {
            return false;
        } else if (filter.rideService == rideServiceSearchType.rideSharing && book.dispatchRideSharing) {
            if (filter.rideSharing && filter.rideSharing != rideSharingSearchType.all) {
                if (!book.dispatchRideSharing) return false;

                if (filter.rideSharing == rideSharingSearchType.matching && !(book.bookingRideSharing && book.bookingRideSharing.length > 0)) {
                    return false;
                } else if (filter.rideSharing == rideSharingSearchType.unmatching && (book.bookingRideSharing && book.bookingRideSharing.length > 0)) {
                    return false;
                }
            }
        } else if (filter.rideService == rideServiceSearchType.rideSharing && !book.dispatchRideSharing) {
            return false;
        }
    }
    if (filter.dateFrom) {
        if (filter.dateFrom > moment.tz(book.time.pickUpTime, book.request.pickup.timezone).toDate()) {
            return false;
        }
    }

    if (filter.dateTo) {
        if (filter.dateTo < moment.tz(book.time.pickUpTime, book.request.pickup.timezone).toDate()) {
            return false;
        }
    }
    if (filter.operator && filter.operator.length > 0) {
        if (!book.operator || book.operator.length == 0 || filter.operator != book.operator.userId) {
            return false;
        }
    }
    if (filter.bookFrom.length > 0) {
        //get elements aren't into bookingFromList and filter list
        let NoMatchedFilter = _.difference(window.bookingFromList, filter.bookFrom);

        let bookFromMatcheds = _.filter(filter.bookFrom, bf => {
            if (bf.label === PaxApp) {
                let notFromPaxApp = NoMatchedFilter.filter(item => {
                    return item.value === book.bookFrom;
                })

                return notFromPaxApp.length === 0
            }

            if(bf.label === 'API') {
                return bf.value === book.bookFrom || book.bookFrom.includes('API')
            }

            return bf.value === book.bookFrom
        })

        if (bookFromMatcheds.length === 0) {
            return false;
        }
    }
    if (filter.carType.length > 0) {
        if (filter.carType.filter(ct => ct.vehicleType == book.request.vehicleTypeRequest).length == 0) {
            return false;
        }
    }
    if (filter.requestCompanyIds && filter.requestCompanyIds.length > 0) {
        const supplierCompanies = book?.supplierCompanies
        if(supplierCompanies?.length > 0) {
            if(filter.requestCompanyIds?.filter(ct => supplierCompanies.includes(ct._id)).length == 0) {
                return false
            }
        } else {
            const driverCompany = _.get(book, 'drvInfo.companyId', '') || _.get(book, 'request.companyId', '')
            if (filter.requestCompanyIds.filter(ct => ct._id == driverCompany).length == 0) {
                return false;
            }
        }
    }
    if (filter.networkType && filter.networkType) {
        const fleetCurrentStorage = localStorage.getItem('fleet') || {};
        const fleetCurrent = JSON.parse(fleetCurrentStorage) || {};
        const psgFleetId = _.get(book.request, 'psgFleetId', '')
        if (filter.networkType === 'farmIn' && 
           ((book.pricingType === 1 && fleetCurrent.fleetId === psgFleetId) || book.pricingType === 0)
        ) {
            return false
        }
        if (filter.networkType === 'farmOut' 
            && ((book.pricingType === 1 && fleetCurrent.fleetId !== psgFleetId)
                || (book.pricingType === 1 && fleetCurrent.fleetId === psgFleetId && !book.isFarmOut)
                || book.pricingType === 0)
        ) {
            return false
        }
        if (filter.networkType === 'roaming' 
            && ((book.pricingType === 1 && fleetCurrent.fleetId !== psgFleetId) 
                || (book.pricingType === 1 && fleetCurrent.fleetId === psgFleetId && book.isFarmOut)
                || book.pricingType === 0)
        ) {
            return false
        }
        if (filter.networkType === 'inHouse' && book.pricingType === 1) return false
    }
    if (filter.isCompleted) {
        if (!filter.finishedStatus.all) {
            for (let key in filter.finishedStatus) {
                if (!filter.finishedStatus[key]) {
                    if (finishedStatus.filter(s => { return s.code == book.status && s.group == key }).length > 0) {
                        return false;
                    }
                }
            }
        }
    } else {
        if (filter.companyOfOperator?.type === 'supplier') {
            if (book.status === 'allocated') {
                return false
            }
        }
        if (filter.companyOfOperator?.supplierJobRequired) {
            if (filter.isOffer) {
                if (book.supplierAccepted != false) {
                    return false
                }
            } else {
                if (book.supplierAccepted == false) {
                    return false
                }
            }
        }
        if (!filter.offerStatus.all) {
            for (let key in filter.offerStatus) {
                if (!filter.offerStatus[key]) {
                    if (offerStatus.filter(s => { return s.code == book.status && s.group == key }).length > 0) {
                        return false;
                    }
                }
            }
        }
        if (!filter.status.all) {
            for (let key in filter.status) {
                if (!filter.status[key]) {
                    if (filter.bookingType === 'all') {
                        // if delivery
                        if (book.delivery) {
                            if (book.jobType !== 'food' && book.jobType !== 'mart') {
                                if (activeStatusDelivery.filter(s => { return book.status == 'booked' || book.status == 'arrived' ? s.jobType === book.jobType && s.label == book.status && s.group == key && book.delivery === s.delivery : s.label == book.status && s.group == key && book.delivery === s.delivery }).length > 0) {
                                    return false;
                                }
                            } else {
                                let findFoodMart = activeStatusFoodMart.filter(s => {
                                    return s.label == book.status && s.group == key && book.delivery === s.delivery
                                }).length;
                                if (findFoodMart > 0) {
                                    return false;
                                };
                            };
                        } else if (book.intercity) {
                            if (activeStatusAll.filter(s => { return s.label == book.status && s.group == key && book.delivery === s.delivery }).length > 0) {
                                return false;
                            }
                        } else {
                            if (activeStatusAll.filter(s => { return book.status == 'completed' ? s.group == key && book.delivery === s.delivery : s.label == book.status && s.group == key && book.delivery === s.delivery }).length > 0) {
                                return false;
                            }
                        }

                    } else if (filter.bookingType === 'delivery') {
                        if (book.jobType !== 'food' && book.jobType !== 'mart') {
                            if (activeStatusDelivery.filter(s => { return book.status == 'booked' || book.status == 'arrived' ? s.jobType === book.jobType && s.label == book.status && s.group == key : s.label == book.status && s.group == key }).length > 0) {
                                return false;
                            }
                        } else {
                            let findFoodMart = activeStatusFoodMart.filter(s => {
                                return s.label == book.status && s.group == key && book.delivery === s.delivery
                            }).length;
                            if (findFoodMart > 0) {
                                return false;
                            };
                        };
                    } else if (filter.bookingType === 'batchDelivery') {
                        if (book.jobType === 'delivery') {
                            if(book.request && !book.request.rideSharing) {
                                return false;
                            }
                        } else {
                            return false;
                        };

                    } else {
                        if (activeStatus.filter(s => { return s.code == book.status && s.group == key }).length > 0) {
                            return false;
                        }
                    }

                }
            }
        }

    }
    // remove sprint 4500
    /*  if (filter.bookingService != 'all') {
     /!*if ((book.pricingType == 0 && filter.bookingService == 'affiliate') ||
     (book.pricingType == 1 && filter.bookingService == 'home')) {
     return false;
     }*!/
     if (((book.intercity === false  || book.jobType !=='intercity') && filter.bookingService === 'intercity')  ||
     ((book.delivery === false ||  book.jobType !=='delivery')  && filter.bookingService === 'delivery') ) {
     return false;
     }if(book.jobType !== 'transport' && filter.bookingService === 'transport'){
     return false;
     }
     }*/
    if (filter.supportService != 'all') {
        if (filter.supportService == 'sos') {
            if (!book.sos || book.sos.length == 0) {
                return false;
            }
        } else {
            if (book.sos && book.sos.length > 0) {
                return false;
            }
        }
    }
    if (filter.corporateId && filter.rideType != 'individual') {
        if (!book.corporateInfo) {
            return false;
        }
        if (book.corporateInfo.corporateId != filter.corporateId) {
            return false;
        }
    }
    if (filter.rideType != 'all') {
        if ((book.travelerType == 1 && filter.rideType == 'individual') ||
            ((book.travelerType == 0 || !!!book.travelerType) && filter.rideType == 'corporate')) {
            return false;
        }
    }
    if (filter.paidStatus != 'all') {
        if (book.paidStatus != filter.paidStatus) {
            return false;
        }
    }
    if (filter.bookingType != 'all') {
        if (filter.bookingType == 'shuttle' && book.jobType != 'shuttle') {
            return false;
        }
        if (((book.reservation || book.intercity || book.delivery || book.jobType == 'shuttle') && filter.bookingType == 'asap') ||
            ((!book.reservation || book.intercity || book.delivery || book.jobType == 'shuttle') && filter.bookingType == 'reservation')) {
            return false;
        }
        if (filter.bookingType == 'intercity' && !book.intercity) {
            return false;
        }
        if (filter.bookingType == 'delivery' && !book.delivery) {
            return false;
        }
        if (filter.bookingType === 'batchDelivery' && 
            ((book.request &&!book.request.rideSharing) ||
            !['delivery', 'food', 'mart'].includes(book.jobType))
        ) {
            return false;
        }
        if (
            filter.bookingType == 'rideSharing' &&
            ((book.request && !book.request.rideSharing) ||
            ['delivery', 'food', 'mart'].includes(book.jobType))
        ) {
            return false;
        }

    }

    if (filter.vip !== null && filter.vip > -1) {
        if (filter.vip != book.psgInfo.rank) {
            return false;
        }
    }

    if (filter.txtSearch && filter.txtSearch.length > 0) {
        if (filter.searchBy) {
            let bookValue = _.get(book, filter.searchBy, "")
            if (bookValue) {
                return bookValue.toLowerCase().indexOf(filter.txtSearch.toLowerCase()) >= 0
            }
        }
        return false;
    }

    if (filter.zone) {
        return book.request.pickup.zoneId == filter.zone
    }

    if (filter.routeId && filter.routeNumber) {
        if (!book.intercityInfo) {
            return false;
        } else if (book.intercityInfo.routeId === filter.routeId) {
            if (book.intercityInfo.routeNumber === filter.routeNumber) {
                return true
            } else {
                return false
            }
        }
        return false
    }

    if (filter.airline && filter.airline !== "") {
        if (!book.corporateInfo) {
            return false;
        };
        if (!book.corporateInfo.isAirline) {
            return false;
        };
        if (book.corporateInfo.corporateId !== filter.airline) {
            return false;
        };
    }

    return true;
};


function sortTrips(dataSort, optionSort) {
    if (!dataSort) {
        return { data: {} };
    };

    if (optionSort) {
        if (optionSort.time) {
            var dataSorted = dataSort.sort((a, b) => {
                var timeA = new Date(a.startTime);
                var timeB = new Date(b.startTime);
                if (optionSort.time === -1) {
                    return timeB - timeA;
                } else {
                    return timeA - timeB;;
                }
            })
        };

        if (optionSort.tripId) {
            var dataSorted = dataSort.sort((a, b) => {
                if (optionSort.tripId === -1) {
                    return b.tripId.localeCompare(a.tripId);
                } else {
                    return a.tripId.localeCompare(b.tripId);
                }
            })
        };
    };

    return {
        data: dataSorted
    };
};

function checkStatus(data, status) {
    var activeStatus = [
        { code: "confirmed" },
        { code: "started" },
        { code: "intransit" }
    ];

    if (!data) {
        return false;
    };

    var listStatus = Object.keys(status).map(key => {
        if (status[key]) return key;
    });
    listStatus = activeStatus.map(status => {
        if (listStatus.includes(status.code)) return status.code;
    });
    listStatus = listStatus.filter(function (el) {
        return el != null;
    });
    if (listStatus.indexOf(data.status) !== -1) {
        return true;
    } else {
        return false;
    };
};


function checkStatusSeat(data, status) {
    var activeStatus = [
        { code: "empty" },
        { code: "full" },
        { code: "live" },
        { code: "notLive" },
    ];
    if (!data) {
        return false;
    };
    var listStatus = Object.keys(status).map(key => {
        if (status[key]) return key;
    });
    listStatus = activeStatus.map(status => {
        if (listStatus.includes(status.code)) return status.code;
    });
    listStatus = listStatus.filter(function (el) {
        return el != null;
    });
    if (listStatus.indexOf(data.capacityStatus) !== -1) {
        return true;
    } else {
        return false;
    };
};


function checkStatusTripType(data, status) {
    var activeStatus = [
        { code: "scheduled" },
        { code: "requested" }
    ];
    if (!data) {
        return false;
    };
    var listStatus = Object.keys(status).map(key => {
        if (status[key]) return key;
    });
    listStatus = activeStatus.map(status => {
        if (listStatus.includes(status.code)) return status.code;
    });
    listStatus = listStatus.filter(function (el) {
        return el != null;
    });
    if (listStatus.indexOf(data.tripType) !== -1) {
        return true;
    } else {
        return false;
    };
}

function checkCompanyDriver(data, companyId) {
    if (data.drvInfo && data.drvInfo.companyId) {
        if (data.drvInfo.companyId === companyId) {
            return true;
        };
        return false;
    };
    return false;
}