import { Translate } from 'react-redux-i18n';
import defaultMapIcon from './staticFiles/driverIcons/bookIn.png';
import bookIn from './staticFiles/driverIcons/bookIn.png';
import arrived from './staticFiles/driverIcons/arrived.png';
import offered from './staticFiles/driverIcons/offered.png';
import booked from './staticFiles/driverIcons/booked.png';
import engaged from './staticFiles/driverIcons/engaged.png';
import droppedOff from './staticFiles/driverIcons/droppedOff.png';
import bookOff from './staticFiles/driverIcons/bookOff.png';
import action from './staticFiles/driverIcons/action.png';
import multiDeliveryOrder from './staticFiles/driverIcons/multiDeliveryOrder.png';
import steeringWheel from './staticFiles/steeringWheel.png';
import address from './staticFiles/address.png';
import car from './staticFiles/car.png';

import arrived_arrived from './staticFiles/driverIcons/arrived_arrived.png';
import arrived_booked from './staticFiles/driverIcons/arrived_booked.png';
import arrived_droppedOff from './staticFiles/driverIcons/arrived_droppedOff.png';
import arrived_engaged from './staticFiles/driverIcons/arrived_engaged.png';
import arrived_offered from './staticFiles/driverIcons/arrived_offered.png';
import booked_booked from './staticFiles/driverIcons/booked_booked.png';
import booked_droppedOff from './staticFiles/driverIcons/booked_droppedOff.png';
import booked_droppedOff_driver from './staticFiles/driverIcons/booked_droppedOff_driver.png';
import booked_engaged from './staticFiles/driverIcons/booked_engaged.png';
import booked_engaged_driver from './staticFiles/driverIcons/booked_engaged_driver.png';
import booked_offered from './staticFiles/driverIcons/booked_offered.png';
import droppedOff_droppedOff from './staticFiles/driverIcons/droppedOff_droppedOff.png';
import droppedOff_engaged from './staticFiles/driverIcons/droppedOff_engaged.png';
import droppedOff_engaged_driver from './staticFiles/driverIcons/droppedOff_engaged.png';
import droppedOff_offered from './staticFiles/driverIcons/droppedOff_offered.png';
import droppedOff_offered_driver from './staticFiles/driverIcons/droppedOff_offered.png';
import engaged_engaged from './staticFiles/driverIcons/engaged_engaged.png';
import engaged_engaged_driver from './staticFiles/driverIcons/engaged_engaged.png';
import engaged_offered from './staticFiles/driverIcons/engaged_offered.png';
import engaged_offered_driver from './staticFiles/driverIcons/engaged_offered_driver.png';
import offered_offered from './staticFiles/driverIcons/offered_offered.png';

import flag from './staticFiles/placeflag.png';
import _ from 'lodash';

export const STATUS_BOOKING = {
  pending: 'pending',
  arrived: 'arrived',
  pre: 'pre',
  allocated: 'allocated',
  action: 'action',
  confirmed: 'confirmed',
  booked: 'booked',
  confirmedDelivery: 'confirmed_delivery'
}

export const COMPANY_TYPE = {
  company: 'company',
  supplier: 'supplier',
};

export const NAME_GATEWAY = {
  Paymaya: 'PayMaya',
  TSYS: 'TSYS',
  PayWay: 'PayWay',
  ECPay: 'ECPay',
  OnePay: 'OnePay',
  Xendit: 'Xendit',
};

export const GROUP_ACTIONS = {
  groupManifest: 'groupManifest',
  assignSuppliers: 'assignSuppliers',
  assignDriver: 'assignDriver',
  unassignDriver: 'unassignDriver',
  unassignVehicle: 'unassignVehicle',
  unassignAll: 'unassignAll'
}

export const STATUS_EXTENAL_INFO = {
  'PCON': 'PCON',
  'PCAN': 'PCAN',
  'PAMM': 'PAMM'
}

export const gateway_port_21 = ['PayTM', 'PayDunya', 'BankOfGeorgia'];

export const repeatType = {
  DayOfWeek: 'weekly',
  SingleDay: 'single',
  Yearly: 'yearly',
};

export const PAYMENTS_ONLY_APP = ['paymentLink']

export const thirdPartyIntegration = {
  bookingAPI: 'booking.com',
  holidaytaxis: 'HolidayTaxis',
  karhoo: 'Karhoo',
};

export const BookingServiceType = {
  Local: 0,
  Home: 1,
  Provider: 2,
};

export const BookingHydraType = {
  FarmOut: 'Farm out',
  FarmIn: 'Farm in',
};

export const additionalServiceType = {
  Optional: 'Optional',
  Compulsory: 'Compulsory',
};

export const BOOK_TYPE_FEE = {
  transport: 'transport',
  shuttle: 'shuttle',
  parcel: 'parcel',
  intercity: 'intercity',
  streetSharing: 'streetSharing',
  rideHailing: 'rideHailing',
  food: 'food',
  mart: 'mart'
}

export const STATUS_FLEET_SERVICE = ['completed', 'completedWithoutService'] // status not calculator fleet service

export const AFFILIATE_BOOKING_CAR_TYPE = [
  {
    key: 'STANDARD',
    title: 'Standard'
  },{
    key: 'LUXURY',
    title: 'Luxury'
  },{
    key: 'EXECUTIVE',
    title: 'Executive'
  },{
    key: 'PEOPLE_CARRIER',
    title: 'People Carrier'
  },{
    key: 'LARGE_PEOPLE_CARRIER',
    title: 'Large People Carrier'
  },{
    key: 'EXECUTIVE_PEOPLE_CARRIER',
    title: 'Executive People Carrier'
  },{
    key: 'MINIBUS',
    title: 'Mini Bus'
  },{
    key: 'ELECTRIC_STANDARD',
    title: 'Electric Standard'
  },{
    key: 'ELECTRIC_LUXURY',
    title: 'Electric Luxury'
  }
]

export const UNIT_TIME = {
  Hour: 'hour',
  Minute: 'minute',
  Day: 'day'
}

export const langaugeCC = {
  en: 'en-US',
  it: 'it-IT',
  es: 'es-ES',
  fr: 'fr-FR',
  zh_CN: 'zh-CN',
  zh_HK: 'zh-HK',
  en_GB: 'en-GB',
};
// once way - 0, from airport - 1, to airport - 2, hourly - 3, round trip - 4

export const serviceTypes = [
  { value: 'all', label: 'report.query.serviceTypeItem.all' },
  { value: 'oneWay', label: 'report.query.serviceTypeItem.oneWay' },
  { value: 'fromAirport', label: 'report.query.serviceTypeItem.fromAirport' },
  { value: 'toAirport', label: 'report.query.serviceTypeItem.toAirport' },
  { value: 'hourly', label: 'report.query.serviceTypeItem.hourly' },
  { value: 'roundTrip', label: 'report.query.serviceTypeItem.roundTrip' },
  {
    value: 'fromAirportHourlyDaily',
    label: 'report.query.serviceTypeItem.fromAirportHourlyDaily',
  },
  {
    value: 'fromAirportRoundTrip',
    label: 'report.query.serviceTypeItem.fromAirportRoundTrip',
  },
  {
    value: 'toAirportHourlyDaily',
    label: 'report.query.serviceTypeItem.toAirportHourlyDaily',
  },
  {
    value: 'toAirportRoundTrip',
    label: 'report.query.serviceTypeItem.toAirportRoundTrip',
  },
];

export const merchantCashTransactionTypes = [
  { value: 'all', label: 'report.query.merchantTransactionTypeItem.all' },
  {
    value: 'editBalance',
    label: 'report.query.merchantTransactionTypeItem.editBalance',
  },
  { value: 'payout', label: 'report.query.merchantTransactionTypeItem.payout' },
  {
    value: 'earning',
    label: 'report.query.merchantTransactionTypeItem.earning',
  },
  {
    value: 'itemValue',
    label: 'report.query.merchantTransactionTypeItem.itemValue',
  },
];

export const STATUS_COMPLETED_DELIVERY = ['delivered', 'completed', 'failed']

export const merchantCreditTransactionTypes = [
  { value: 'all', label: 'report.query.merchantTransactionTypeItem.all' },
  {
    value: 'editBalance',
    label: 'report.query.merchantTransactionTypeItem.editBalance',
  },
  {
    value: 'orderDeduction',
    label: 'report.query.merchantTransactionTypeItem.orderDeduction',
  },
];

export const modules = [
  { value: 'all', label: 'report.query.moduleItem.all' },
  { value: 'Bookings', label: 'report.query.moduleItem.bookings' },
  { value: 'Customers', label: 'report.query.moduleItem.customers' },
  { value: 'Dispatch', label: 'report.query.moduleItem.dispatch' },
  { value: 'General', label: 'report.query.moduleItem.general' },
  { value: 'Promotion', label: 'report.query.moduleItem.promotion' },
  { value: 'Quest', label: 'report.query.moduleItem.quest' },
  { value: 'Code #', label: 'report.query.moduleItem.code#' },
  { value: 'Rate', label: 'report.query.moduleItem.rate' },
  { value: 'Car', label: 'report.query.moduleItem.car' },
  { value: 'City', label: 'report.query.moduleItem.city' },
  { value: 'Company', label: 'report.query.moduleItem.company' },
  { value: 'Drivers', label: 'report.query.moduleItem.drivers' },
  {
    value: 'Driver settlement',
    label: 'report.query.moduleItem.driverSettlement',
  },
  { value: 'Login', label: 'report.query.moduleItem.login' },
  { value: 'Logout', label: 'report.query.moduleItem.logout' },
  { value: 'mDispatcher', label: 'report.query.moduleItem.mDispatcher' },
  {
    value: 'mDispatcher type',
    label: 'report.query.moduleItem.mDispatcherType',
  },
  { value: 'Operation', label: 'report.query.moduleItem.operation' },
  { value: 'Partners', label: 'report.query.moduleItem.partners' },
  { value: 'Permission', label: 'report.query.moduleItem.permission' },
  { value: 'Queuing area', label: 'report.query.moduleItem.queuingArea' },
  { value: 'Report', label: 'report.query.moduleItem.report' },
  { value: 'Shift', label: 'report.query.moduleItem.shift' },
  { value: 'User', label: 'report.query.moduleItem.user' },
  { value: 'Voice & SMS', label: 'report.query.moduleItem.voiceSms' },
  { value: 'Withdrawal Requests', label: 'Sidebar.Withdrawal_Requests' },
  { value: 'Zone (intercity)', label: 'report.query.moduleItem.intercityZone' },
  { value: 'Payout', label: 'report.query.moduleItem.Payout' },
  { value: 'Merchants', label: 'report.query.moduleItem.Merchants' },
  { value: 'Invoice', label: 'report.query.moduleItem.Invoice' },
  { value: 'API management', label: 'report.query.moduleItem.ApiManagement' },
];

let transactionTypes = [
  { value: 'all', label: 'report.query.transactionTypeItem.all' },
  { value: 'topUp', label: 'report.query.transactionTypeItem.topUp' },
  {
    value: 'editBalance',
    label: 'report.query.transactionTypeItem.editBalance',
  },
  {
    value: 'bookingDeduction',
    label: 'report.query.transactionTypeItem.bookingDeduction',
  },
  { value: 'topUpGCash', label: 'report.query.transactionTypeItem.topUpGCash' },
  { value: 'cashWallet', label: 'report.query.transactionTypeItem.cashWallet' },
  {
    value: 'topUpMOLPay',
    label: 'report.query.transactionTypeItem.topUpMOLPay',
  },
  {
    value: 'cashExcess',
    label: 'report.query.transactionTypeItem.excessDeductionAmount',
  },
  {
    value: 'referralEarning',
    label: 'report.query.transactionTypeItem.referralEarning',
  },
  {
    value: 'ZainCash',
    label: 'report.query.transactionTypeItem.topUpZainCash',
  },
  {
    value: 'Xendit',
    label: 'report.query.transactionTypeItem.topUpXendit',
  },
  {
    value: 'Telebirr',
    label: 'report.query.transactionTypeItem.topUpTelebirr',
  },
  { value: 'Ksher', label: 'report.query.transactionTypeItem.topUpKsher' },
];

export const transactionTypesCorporateBalance = [
  { value: 'all', label: 'report.query.transactionTypeItem.all' },
  { value: 'deposit', label: 'report.query.transactionTypeItem.topUp' },
  {
    value: 'editBalance',
    label: 'report.query.transactionTypeItem.editBalance',
  },
];

export const transactionTypesDriverWallet = [
  { value: 'all', label: 'report.query.transactionTypeItem.all' },
  { value: 'topUp', label: 'report.query.transactionTypeItem.topUp' },
  {
    value: 'editBalance',
    label: 'report.query.transactionTypeItem.editBalance',
  },
  {
    value: 'bookingDeduction',
    label: 'report.query.transactionTypeItem.bookingDeduction',
  },
  { value: 'cashWallet', label: 'report.query.transactionTypeItem.cashWallet' },
  {
    value: 'topUpMOLPay',
    label: 'report.query.transactionTypeItem.topUpMOLPay',
  },
  {
    value: 'cashExcess',
    label: 'report.query.transactionTypeItem.excessDeductionAmount',
  },
  {
    value: 'referralEarning',
    label: 'report.query.transactionTypeItem.referralEarning',
  },
  {
    value: 'insurance',
    label: 'report.query.transactionTypeItem.driverInsurance',
  },
  {
    value: 'sentToDriver',
    label: 'report.query.transactionTypeItem.sentToDriver',
  },
  {
    value: 'receivedFromDriver',
    label: 'report.query.transactionTypeItem.receivedFromDriver',
  },
  {
    value: 'sentToCustomer',
    label: 'report.query.transactionTypeItem.sentToCustomer',
  },
  {
    value: 'cancellationPenalty',
    label: 'report.query.transactionTypeItem.cancellationPenalty',
  },
  {
    value: 'customerDebt',
    label: 'report.query.transactionTypeItem.customerDebt',
  },
  {
    value: 'merchantCommission',
    label: 'report.query.transactionTypeItem.merchantCommission',
  },
];

export const SURCHARGE_DEFAULT = {
  surchargeByServices: [
    {
      typeRate: 'regular',
      value: 0,
    },
    {
      typeRate: 'flat',
      value: 0,
    },
    {
      typeRate: 'hourly',
      value: 0,
    },
    {
      typeRate: 'shuttle',
      value: 0,
    },
    {
      typeRate: 'carHailing',
      value: 0,
    },
  ],
  distanceRange: [
    {
      from: 0,
      to: Number.MAX_SAFE_INTEGER,
      surchargeByServices: [
        {
          typeRate: 'regular',
          value: 0,
        },
        {
          typeRate: 'flat',
          value: 0,
        },
        {
          typeRate: 'hourly',
          value: 0,
        },
        {
          typeRate: 'shuttle',
          value: 0,
        },
        {
          typeRate: 'carHailing',
          value: 0,
        },
      ],
    },
  ],
};

export const defaultCommissionFleet = [
  { serviceType: 'transport', value: '0', type: 'percent' },
  { serviceType: 'rideHailing', value: '0', type: 'percent' },
  { serviceType: 'streetSharing', value: '0', type: 'percent' },
  { serviceType: 'intercity', value: '0', type: 'percent' },
  { serviceType: 'shuttle', value: '0', type: 'percent' },
  { serviceType: 'parcel', value: '0', type: 'percent' },
  { serviceType: 'food', value: '0', type: 'percent' },
  { serviceType: 'mart', value: '0', type: 'percent' },
];

export const settlementDriverTypes = [
  { value: 'all', label: 'report.query.settlementDriverTypeItem.all' },
  {
    value: 'individual',
    label: 'report.query.settlementDriverTypeItem.individual',
  },
];

export const bookingSummaryActions = [
  { value: 'all', label: 'report.query.bookingSummaryActionItem.all' },
  { value: 'create', label: 'report.query.bookingSummaryActionItem.create' },
  { value: 'update', label: 'report.query.bookingSummaryActionItem.update' },
  { value: 'cancel', label: 'report.query.bookingSummaryActionItem.cancel' },
  {
    value: 'complete',
    label: 'report.query.bookingSummaryActionItem.complete',
  },
  {
    value: 'incident',
    label: 'report.query.bookingSummaryActionItem.incident',
  },
];

export const actions = [
  { value: 'all', label: 'report.query.actionItem.all' },
  { value: 'login', label: 'report.query.actionItem.login' },
  { value: 'logout', label: 'report.query.actionItem.logout' },
  { value: 'bookIn', label: 'report.query.actionItem.bookIn' },
  { value: 'bookOff', label: 'report.query.actionItem.bookOff' },
  { value: 'deny', label: 'report.query.actionItem.deny' },
  { value: 'ignore', label: 'report.query.actionItem.ignore' },
  { value: 'accept', label: 'report.query.actionItem.accept' },
  { value: 'startTrip', label: 'report.query.actionItem.startTrip' },
  { value: 'updateBooking', label: 'report.query.actionItem.updateBooking' },
  { value: 'arrive', label: 'report.query.actionItem.arrive' },
  { value: 'pickup', label: 'report.query.actionItem.pickup' },
  { value: 'drop', label: 'report.query.actionItem.drop' },
  { value: 'complete', label: 'report.query.actionItem.complete' },
  { value: 'noShow', label: 'report.query.actionItem.noShow' },
  { value: 'rqJob', label: 'report.query.actionItem.rqJob' },
  { value: 'cancel', label: 'report.query.actionItem.cancel' },
];

export const loginStatus = [
  { value: 'all', label: 'report.query.loginStatusItem.all' },
  { value: 'online', label: 'report.query.loginStatusItem.online' },
  { value: 'offline', label: 'report.query.loginStatusItem.offline' },
];

export const ratingTypes = [
  { value: 'all', label: 'report.query.ratingTypeItem.all' },
  { value: 'like', label: 'report.query.ratingTypeItem.like' },
  { value: 'dislike', label: 'report.query.ratingTypeItem.dislike' },
];

export const stars = [
  { value: 0, label: 'report.query.starsItem.all' },
  { value: 1, label: 'report.query.starsItem.star1' },
  { value: 2, label: 'report.query.starsItem.star2' },
  { value: 3, label: 'report.query.starsItem.star3' },
  { value: 4, label: 'report.query.starsItem.star4' },
  { value: 5, label: 'report.query.starsItem.star5' },
];

export const canceledByTypes = [
  { value: 'all', label: 'report.query.canceledByItem.all' },
  { value: 'CorpAD', label: 'report.query.canceledByItem.CorpAD' },
  { value: 'driver', label: 'report.query.canceledByItem.driver' },
  { value: 'passenger', label: 'report.query.canceledByItem.passenger' },
  { value: 'merchant', label: 'report.query.canceledByItem.merchant' },
  { value: 'CC', label: 'report.query.canceledByItem.CC' },
  { value: 'mDispatcher', label: 'report.query.canceledByItem.mDispatcher' },
  { value: 'webBooking', label: 'report.query.canceledByItem.webBooking' },
  { value: 'timeout', label: 'report.query.canceledByItem.timeout' },
];

export const associatedApps = [
  { value: 'all', label: 'report.query.canceledByItem.all' },
  { value: 'driver', label: 'report.query.canceledByItem.driver' },
  { value: 'passenger', label: 'report.query.canceledByItem.passenger' },
  { value: 'merchant', label: 'report.query.canceledByItem.merchant' },
  { value: 'mDispatcher', label: 'report.query.canceledByItem.mDispatcher' },
  { value: 'crew', label: 'notifications.sos.statusItem.crew' },
];

export const incidentCanceledTypes = [
  { value: 'all', label: 'report.query.incidentCanceledTypes.all' },
  { value: 'incident', label: 'report.query.incidentCanceledTypes.incident' },
  { value: 'canceled', label: 'report.query.incidentCanceledTypes.canceled' },
];

export const USER_TYPE_ADD_CART = {
  corporate: 'corporate',
  driver: 'driver',
  passenger: 'passenger'
}

export const bookFrom = [
  { value: 'all', label: 'report.query.bookingFromItem.all' },
  { value: 'paxApp', label: 'report.query.bookingFromItem.paxApp' },
  { value: 'mDispatcher', label: 'report.query.bookingFromItem.mDispatcher' },
  { value: 'Kiosk', label: 'report.query.bookingFromItem.kiosk' },
  { value: 'CC', label: 'report.query.bookingFromItem.cc' },
  { value: 'Web booking', label: 'report.query.bookingFromItem.webBooking' },
  { value: 'Car-hailing', label: 'report.query.bookingFromItem.carHailing' },
  {
    value: 'streetSharing',
    label: 'report.query.bookingFromItem.streetSharing',
  },
  { value: 'dmc', label: 'report.query.bookingFromItem.dmc' },
  { value: 'corp', label: 'report.query.bookingFromItem.corp' },
  {
    value: 'Boost web link',
    label: 'report.query.bookingFromItem.Boost_web_link',
  },
  { value: 'PWA', label: 'report.query.bookingFromItem.PWA' },
  { value: 'booking.com', label: 'cue.bookingCom' },
  { value: 'HolidayTaxis', label: 'cue.HolidayTaxis' },
  { value: 'Karhoo', label: 'cue.Karhoo' },
  { value: 'API', label: 'cue.API' }
];

export const thirdPartyAction = [
  { value: 'all', label: 'report.query.thirdPartyActionItem.all' },
  { value: 'Searched', label: 'report.query.thirdPartyActionItem.searched' },
  { value: 'Allocated', label: 'report.query.thirdPartyActionItem.allocated' },
  { value: 'Updated', label: 'report.query.thirdPartyActionItem.updated' },
  { value: 'Cancelled', label: 'report.query.thirdPartyActionItem.cancelled' },
]

export const finishedStatusList = [
  { value: 'all', label: 'report.query.common.all' },
  { value: 'completed', label: 'report.query.bookingStatusItem.completed' },
  { value: 'canceled', label: 'report.query.paymentMethodItem.canceled' },
  { value: 'noShow', label: 'report.query.paymentMethodItem.noShow' },
  { value: 'incident', label: 'report.query.paymentMethodItem.incident' },
  {
    value: 'completedWithoutService',
    label: 'report.query.paymentMethodItem.completedWithoutService',
  },
  {
    value: 'partialCompleted',
    label: 'report.query.paymentMethodItem.partialCompleted',
  },
  {
    value: 'failed',
    label: 'report.query.paymentMethodItem.failed',
  },
];

let bookingDetailsPaymentMethods = [
  { value: 'all', label: 'report.query.common.all' },
  { value: 'cash', label: 'report.query.paymentMethodItem.cash' },
  {
    value: 'cashBySender',
    label: 'report.query.paymentMethodItem.cashBySender',
  },
  {
    value: 'cashByRecipient',
    label: 'report.query.paymentMethodItem.cashByRecipient',
  },
  { value: 'credit', label: 'report.query.paymentMethodItem.personalCard' },
  {
    value: 'corporateCard',
    label: 'report.query.paymentMethodItem.corporateCard',
  },
  { value: 'qrCode', label: 'report.query.paymentMethodItem.qrCodePayment' },
  {
    value: 'directBilling',
    label: 'report.query.paymentMethodItem.directBilling',
  },
  { value: 'fleetCard', label: 'report.query.paymentMethodItem.externalCard' },
  {
    value: 'mDispatcherCard',
    label: 'report.query.paymentMethodItem.mDispatcherCard',
  },
  {
    value: 'prePaid',
    label: 'report.query.paymentMethodItem.corporatePrepaid',
  },
  { value: 'applePay', label: 'report.query.paymentMethodItem.applePay' },
  {
    value: 'creditCardExternal',
    label: 'report.query.paymentMethodItem.creditCardExternal',
  },
  { value: 'paxWallet', label: 'report.query.paymentMethodItem.paxWallet' },
  { value: 'tngeWallet', label: 'report.query.paymentMethodItem.tngeWallet' },
  {
    value: 'vippseWallet',
    label: 'report.query.paymentMethodItem.vippseWallet',
  },
  { value: 'ZainCash', label: 'report.query.paymentMethodItem.zainCash' },
  { value: 'Xendit', label: 'report.query.paymentMethodItem.Xendit' },
  { value: 'GCash', label: 'report.query.paymentMethodItem.gCash' },
  { value: 'Telebirr', label: 'report.query.paymentMethodItem.Telebirr' },
  { value: 'Ksher', label: 'report.query.paymentMethodItem.Ksher' },
  { value: 'googlePay', label: 'report.query.paymentMethodItem.googlePay' },
  { value: 'paymentLink', label: 'report.query.paymentMethodItem.paymentLink' },
];

export const HIDE_OVERRIDE_BUTTON_STATUS = [
  'noShow',
  'canceled',
  'completed',
  'incident',
  'completedWithoutService',
  'canceledByCC',
  'canceledByPassenger'
];

export const HYDRA_STATUS_CAN_UPDATE = [
  'pending',
  'denied',
  'rejected'
]

export const SUPPLIER_RESPONSE = {
  accept: 'ACCEPT',
  reject: 'REJECT'
}

export const CAN_ASSIGN_DRIVER_3RD_STATUS = [
  'pending',
  'pre',
  'booked',
  'arrived',
  'action',
  'confirmed'
]

export const STATUS_BEFORE_START = [
  'pending',
  'pre',
  'action',
  'confirmed'
]

export const bookingDetailsPaymentStatus = [
  { value: 'all', label: 'report.query.paymentStatusItem.all' },
  { value: 'full', label: 'report.query.paymentStatusItem.fullPayment' },
  { value: 'partial', label: 'report.query.paymentStatusItem.partialPayment' },
  { value: 'pending', label: 'report.query.paymentStatusItem.pendingPayment' },
  {
    value: 'partialRefund',
    label: 'report.query.paymentStatusItem.partialRefund',
  },
  { value: 'fullRefund', label: 'report.query.paymentStatusItem.fullRefund' },
];

export const paymentStatusRefund = [
  { value: 'all', label: 'report.query.paymentStatusItem.all' },
  { value: 'fullRefund', label: 'report.result.refund.fullRefund' },
  { value: 'partialRefund', label: 'report.result.refund.partialRefund' },
];

export const TipAfterCompleted = [
  {
    value: 'tipAfterRide',
    label: 'report.result.financialDriver.driverTipAfterCompleted',
  },
];

export const originalPaymentStatus = [
  { value: 'all', label: 'report.query.paymentStatusItem.all' },
  { value: 'partial', label: 'report.query.paymentStatusItem.partialPayment' },
  { value: 'pending', label: 'report.query.paymentStatusItem.pendingPayment' },
];

export const prepaidPaymentMethods = [
  { value: 'all', label: 'report.query.common.all' },
  { value: 'credit', label: 'report.query.paymentMethodItem.personalCard' },
  {
    value: 'directBilling',
    label: 'report.query.paymentMethodItem.directBilling',
  }
];

export const companySettlementTypes = [
  { value: 'all', label: 'report.query.common.all' },
  {
    value: 'fleetOweCompany',
    label: 'report.query.companySettlementTypeItem.fleetOweCompany'
  },
  {
    value: 'companyOweFleet',
    label: 'report.query.companySettlementTypeItem.companyOweFleet',
  },
];

export const farmTypes = [
  { value: 'all', label: 'report.query.farmTypeItem.all' },
  { value: 'farmIn', label: 'report.query.farmTypeItem.farmIn' },
  { value: 'farmOut', label: 'report.query.farmTypeItem.farmOut' },
  { value: 'roaming', label: 'report.query.farmTypeItem.roaming' },
  {
    value: 'affiliationOwner',
    label: 'report.query.farmTypeItem.affiliationOwner',
  },
  {
    value: 'farmInAndAffiliationOwner',
    label: 'report.query.farmTypeItem.farmInAndAffiliationOwner',
  },
  {
    value: 'farmOutAndAffiliationOwner',
    label: 'report.query.farmTypeItem.farmOutAndAffiliationOwner',
  },
];

export const bookingServices = [
  {
    value: 'localBooking',
    label: 'report.query.bookingServiceItem.localBooking',
  },
  {
    value: 'roaming',
    label: 'report.query.bookingServiceItem.roaming',
  },
  {
    value: 'homeFleet',
    label: 'report.query.farmTypeItem.farmOut',
  },
  {
    value: 'provideService',
    label: 'report.query.bookingServiceItem.provideService',
  },
];
export const payoutOptions = [
  { value: 'all', label: 'report.query.common.all' },
  {
    value: 'hasBank',
    label: 'report.query.payoutDriver.driversHaveBankAccount',
  },
  {
    value: 'noBank',
    label: 'report.query.payoutDriver.driversDontHaveBankAccount',
  },
  {
    value: 'invalidBank',
    label: 'report.query.payoutDriver.driverHaveInvalidBankAccount',
  },
];
export const payoutMerchantOptions = [
  { value: 'all', label: 'report.query.common.all' },
  { value: 'hasBank', label: 'report.query.payoutMerchant.HaveBankAccount' },
  { value: 'noBank', label: 'report.query.payoutMerchant.DontHaveBankAccount' },
  {
    value: 'invalidBank',
    label: 'report.query.payoutMerchant.HaveInvalidBankAccount',
  },
];
export const bookingTypes = [
  { value: 'all', label: 'report.query.bookingTypeItem.all' },
  { value: 'asap', label: 'report.query.bookingTypeItem.now' },
  { value: 'reservation', label: 'report.query.bookingTypeItem.reservation' },
  { value: 'intercity', label: 'report.query.bookingTypeItem.intercity' },
  { value: 'delivery', label: 'report.query.bookingTypeItem.delivery' },
  { value: 'shuttle', label: 'report.query.bookingTypeItem.shuttle' },
];
export const adjustPrices = [
  { value: 'all', label: 'report.query.adjustPrices.all' },
  { value: 'normalPrice', label: 'report.query.adjustPrices.normalPrice' },
  { value: 'addOnPrice', label: 'report.query.adjustPrices.addonPrice' },
];
export const affiliationBookingStatus = [
  { value: 'all', label: 'report.query.bookingStatusItem.all' },
  { value: 'completed', label: 'report.query.bookingStatusItem.completed' },
  { value: 'incident', label: 'report.query.bookingStatusItem.incident' },
  { value: 'paxNoShow', label: 'report.query.bookingStatusItem.paxNoShow' },
  {
    value: 'canceledByTimeOut',
    label: 'report.query.bookingStatusItem.canceledByTimeOut',
  },
  {
    value: 'canceledByCustomer',
    label: 'report.query.bookingStatusItem.canceledByCustomer',
  },
  {
    value: 'canceledBySupplier',
    label: 'report.query.bookingStatusItem.canceledBySupplier',
  },
];

export const dateButtons = [
  {
    value: 'thisYear',
    key: 'thisYear',
    label: 'report.query.thisYear',
  },
  {
    value: 'thisMonth',
    key: 'thisMonth',
    label: 'report.query.thisMonth',
  },
  {
    value: 'lastMonth',
    key: 'lastMonth',
    label: 'report.query.lastMonth',
  },
  {
    value: 'past30Days',
    key: 'past30Days',
    label: 'report.query.past30Days',
  },
  {
    value: 'today',
    key: 'today',
    label: 'report.query.today',
  },
  {
    value: 'custom',
    key: 'custom',
    label: 'report.query.custom',
  },
];

export const dateButtonsForNotification = [
  {
    value: 'today',
    key: 'today',
    label: 'report.query.today',
  },
  {
    value: 'thisWeek',
    key: 'thisWeek',
    label: 'report.query.thisWeek',
  },
  {
    value: 'thisMonth',
    key: 'thisMonth',
    label: 'report.query.thisMonth',
  },
  {
    value: 'past30Days',
    key: 'past30Days',
    label: 'report.query.past30Days',
  },
  {
    value: 'custom',
    key: 'custom',
    label: 'report.query.customize',
  },
];

export const itemsPerPage = [5, 10, 20, 50, 70, 100];

export const finishedStatus = [
  { code: 'completed', label: 'Completed', group: 'completed' },
  { code: 'canceled', label: 'Canceled', group: 'canceled' },
  { code: 'canceledByCC', label: 'canceled By CC', group: 'canceledByCC' },
  {
    code: 'canceledByPassenger',
    label: 'canceledByPassenger',
    group: 'canceledByPassenger',
  },
  {
    code: 'canceledByDriver',
    label: 'canceledByDriver',
    group: 'canceledByDriver',
  },
  // { code: "canceledByPartner", label: "canceledByPartner", group: 'canceledByPartner' },
  {
    code: 'canceledBymDispatcher',
    label: 'canceledBymDispatcher',
    group: 'canceledBymDispatcher',
  },
  {
    code: 'canceledByCorpAd',
    label: 'canceledByCorpAd',
    group: 'canceledByCorpAd',
  },
  { code: 'canceledByAPI', label: 'canceledByAPI', group: 'canceledByAPI' },
  {
    code: 'canceledByWebBooking',
    label: 'canceledByWebBooking',
    group: 'canceledByWebBooking',
  },
  {
    code: 'canceledByTimeout',
    label: 'canceledByTimeout',
    group: 'canceledByTimeout',
  },
  { code: 'noShow', label: 'No show', group: 'noShow' },
  { code: 'incident', label: 'Incident', group: 'incident' },
  {
    code: 'completedWithoutService',
    label: 'completedWithoutService',
    group: 'completedWithoutService',
  },
  {
    code: 'partialCompleted',
    label: 'Partial Completed',
    group: 'partialCompleted',
  },
  {
    code: 'failed',
    label: 'Failed',
    group: 'failed',
  },
];

export const activeStatus = [
  {
    code: 'pending',
    label: 'Pending',
    group: 'pending',
    canEditPromo: true,
    paymentMethodEditable: true,
  },
  {
    code: 'pre',
    label: 'Pending',
    group: 'pending',
    canEditPromo: true,
    paymentMethodEditable: true,
  },
  {
    code: 'queue',
    label: 'Dispatching',
    group: 'dispatching',
    canEditPromo: false,
    paymentMethodEditable: false,
  },
  {
    code: 'offered',
    label: 'Dispatching',
    group: 'dispatching',
    canEditPromo: false,
    paymentMethodEditable: false,
  },
  {
    code: 'confirmed',
    label: 'Confirmed Reservation',
    group: 'confirmed',
    canEditPromo: true,
    paymentMethodEditable: true,
  },
  {
    code: 'booked',
    label: 'Driver on the way',
    group: 'booked',
    canEditPromo: true,
    paymentMethodEditable: true,
  },
  {
    code: 'engaged',
    label: 'P.O.B',
    group: 'engaged',
    canEditPromo: false,
    paymentMethodEditable: true,
  },
  {
    code: 'droppedOff',
    label: 'Dropped off',
    group: 'droppedOff',
    canEditPromo: false,
    paymentMethodEditable: true,
  },
  {
    code: 'arrived',
    label: 'Arrived and waiting',
    group: 'arrived',
    canEditPromo: true,
    paymentMethodEditable: true,
  },
  {
    code: 'action',
    label: 'Waiting for process',
    group: 'action',
    canEditPromo: true,
    paymentMethodEditable: true,
  },
  {
    code: 'allocated',
    label: 'allocated label',
    group: 'allocated',
    canEditPromo: true,
    paymentMethodEditable: true,
  },
];

export const offerStatus = [
  {
    code: 'pending',
    label: 'Pending',
    group: 'pending',
    canEditPromo: true,
    paymentMethodEditable: true,
  },
  {
    code: 'pre',
    label: 'Pending',
    group: 'pending',
    canEditPromo: true,
    paymentMethodEditable: true,
  },
  {
    code: 'queue',
    label: 'Dispatching',
    group: 'dispatching',
    canEditPromo: false,
    paymentMethodEditable: false,
  },
  {
    code: 'offered',
    label: 'Dispatching',
    group: 'dispatching',
    canEditPromo: false,
    paymentMethodEditable: false,
  },
  {
    code: 'action',
    label: 'action',
    group: 'action',
    canEditPromo: true,
    paymentMethodEditable: true,
  },
]
export const activeStatusAll = [
  {
    code: 'pending',
    label: 'pending',
    group: 'pending',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: false,
  },
  {
    code: 'pre',
    label: 'pre',
    group: 'pending',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: false,
  },
  {
    code: 'queue',
    label: 'queue',
    group: 'dispatching',
    canEditPromo: false,
    paymentMethodEditable: false,
    delivery: false,
  },
  {
    code: 'offered',
    label: 'offered',
    group: 'dispatching',
    canEditPromo: false,
    paymentMethodEditable: false,
    delivery: false,
  },
  {
    code: 'confirmed',
    label: 'confirmed',
    group: 'confirmed',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: false,
  },
  {
    code: 'booked',
    label: 'booked',
    group: 'booked',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: false,
  },
  {
    code: 'engaged',
    label: 'engaged',
    group: 'engaged',
    canEditPromo: false,
    paymentMethodEditable: true,
    delivery: false,
  },
  {
    code: 'droppedOff',
    label: 'droppedOff',
    group: 'droppedOff',
    canEditPromo: false,
    paymentMethodEditable: true,
    delivery: false,
  },
  {
    code: 'arrived',
    label: 'arrived',
    group: 'arrived',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: false,
  },
  {
    code: 'action',
    label: 'action',
    group: 'action',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: false,
  },
  {
    code: 'orderAccepted',
    label: 'pre',
    group: 'orderAccepted',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'confirmedAccepted',
    label: 'confirmed',
    group: 'confirmedAccepted',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'driverSender',
    label: 'booked',
    group: 'driverSender',
    canEditPromo: true,
    paymentMethodEditable: true,
    jobType: 'delivery',
    delivery: true,
  },
  {
    code: 'driverCash',
    label: 'booked',
    group: 'driverCash',
    canEditPromo: true,
    paymentMethodEditable: true,
    jobType: 'shopForYou',
    delivery: true,
  },
  {
    code: 'driverGoods',
    label: 'collecting',
    group: 'driverGoods',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'arrivedSender',
    label: 'arrived',
    group: 'arrivedSender',
    canEditPromo: true,
    paymentMethodEditable: true,
    jobType: 'delivery',
    delivery: true,
  },
  {
    code: 'arrivedCash',
    label: 'arrived',
    group: 'arrivedCash',
    canEditPromo: true,
    paymentMethodEditable: true,
    jobType: 'shopForYou',
    delivery: true,
  },
  {
    code: 'delivering',
    label: 'delivering',
    group: 'delivering',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'completedDelivery',
    label: 'completed',
    group: 'completedDelivery',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'otwMerchant',
    label: 'otwMerchant',
    group: 'otwMerchant',
    canEditPromo: true,
    paymentMethodEditable: true,
    foodMart: true,
  },
  {
    code: 'arrivedAndWaitingToCollectItem',
    label: 'collecting',
    group: 'arrivedAndWaitingToCollectItem',
    canEditPromo: true,
    paymentMethodEditable: true,
    foodMart: true,
  },
  {
    code: 'allocated',
    label: 'allocated',
    group: 'allocated',
    canEditPromo: false,
    paymentMethodEditable: true,
    delivery: false,
  },
];
export const activeStatusDelivery = [
  {
    code: 'orderAccepted',
    label: 'pre',
    group: 'orderAccepted',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'confirmedAccepted',
    label: 'confirmed',
    group: 'confirmedAccepted',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'driverSender',
    label: 'booked',
    group: 'driverSender',
    canEditPromo: true,
    paymentMethodEditable: true,
    jobType: 'delivery',
    delivery: true,
  },
  {
    code: 'driverCash',
    label: 'booked',
    group: 'driverCash',
    canEditPromo: true,
    paymentMethodEditable: true,
    jobType: 'shopForYou',
    delivery: true,
  },
  {
    code: 'driverGoods',
    label: 'collecting',
    group: 'driverGoods',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'arrivedSender',
    label: 'arrived',
    group: 'arrivedSender',
    canEditPromo: true,
    paymentMethodEditable: true,
    jobType: 'delivery',
    delivery: true,
  },
  {
    code: 'arrivedCash',
    label: 'arrived',
    group: 'arrivedCash',
    canEditPromo: true,
    paymentMethodEditable: true,
    jobType: 'shopForYou',
    delivery: true,
  },
  {
    code: 'delivering',
    label: 'delivering',
    group: 'delivering',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'completedDelivery',
    label: 'completed',
    group: 'completedDelivery',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'otwMerchant',
    label: 'otwMerchant',
    group: 'otwMerchant',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'arrivedAndWaitingToCollectItem',
    label: 'collecting',
    group: 'arrivedAndWaitingToCollectItem',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'action',
    label: 'action',
    group: 'action',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
];

export const activeStatusFoodMart = [
  {
    code: 'orderAccepted',
    label: 'pre',
    group: 'orderAccepted',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'confirmedAccepted',
    label: 'confirmed',
    group: 'confirmedAccepted',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'delivering',
    label: 'delivering',
    group: 'delivering',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'completedDelivery',
    label: 'completed',
    group: 'completedDelivery',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'otwMerchant',
    label: 'otwMerchant',
    group: 'otwMerchant',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'arrivedAndWaitingToCollectItem',
    label: 'collecting',
    group: 'arrivedAndWaitingToCollectItem',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
  {
    code: 'action',
    label: 'action',
    group: 'action',
    canEditPromo: true,
    paymentMethodEditable: true,
    delivery: true,
  },
];

export const bookingInprogressStatus = [
  'offered',
  'booked',
  'engaged',
  'droppedOff',
  'arrived',
  'delivering',
];

let paymentMethod = [
  { value: 'all', label: 'report.query.common.all' },
  { value: 'cash', label: 'report.query.paymentMethodItem.cash' },
  {
    value: 'cashBySender',
    label: 'report.query.paymentMethodItem.cashBySender',
  },
  {
    value: 'cashByRecipient',
    label: 'report.query.paymentMethodItem.cashByRecipient',
  },
  {
    value: 'personalCard',
    label: 'report.query.paymentMethodItem.personalCard',
  },
  {
    value: 'mDispatcherCard',
    label: 'report.query.paymentMethodItem.mDispatcherCard',
  },
  {
    value: 'externalCard',
    label: 'report.query.paymentMethodItem.externalCard',
  },
  {
    value: 'directBilling',
    label: 'report.query.paymentMethodItem.directBilling',
  },
  {
    value: 'corporateCard',
    label: 'report.query.paymentMethodItem.corporateCard',
  },
  {
    value: 'corporatePrepaid',
    label: 'report.query.paymentMethodItem.corporatePrepaid',
  },
  { value: 'qrCode', label: 'QR Code' },
  { value: 'applePay', label: 'report.query.paymentMethodItem.applePay' },
  { value: 'creditCardExternal', label: 'Credit Card' },
  { value: 'paxWallet', label: 'report.query.paymentMethodItem.paxWallet' },
  { value: 'tngeWallet', label: 'report.query.paymentMethodItem.tngeWallet' },
  {
    value: 'vippseWallet',
    label: 'report.query.paymentMethodItem.vippseWallet',
  },
  { value: 'ZainCash', label: 'report.query.paymentMethodItem.zainCash' },
  { value: 'Xendit', label: 'report.query.paymentMethodItem.Xendit' },
  { value: 'GCash', label: 'GCash' },
  { value: 'Telebirr', label: 'Telebirr' },
  { value: 'Ksher', label: 'Ksher' },
  { value: 'googlePay', label: 'report.query.paymentMethodItem.googlePay' },
  { value: 'paymentLink', label: 'report.query.paymentMethodItem.paymentLink' },
];

gateway_port_21.map((gateway) => {
  transactionTypes.push({
    value: gateway,
    label: `report.query.transactionTypeItem.topUp${gateway}`,
  });
  bookingDetailsPaymentMethods.push({
    value: gateway,
    label: `report.query.paymentMethodItem.${gateway}`,
  });
  paymentMethod.push({
    value: gateway,
    label: `report.query.paymentMethodItem.${gateway}`,
  });
});

export { bookingDetailsPaymentMethods };
export { transactionTypes };
export { paymentMethod };

// Using it to avoid the 'hack' code in project, easier to understand
export const paymentMethodNumber = {
  personalCard: 2,
  personalCardChangedOnApp: 22,
  corporateCard: 4,
};

export const paymentMapping = [
  {
    paymentType: 0,
    method: 'Cash',
    recceipt: 'cash',
    mapping: 1,
  },
  {
    paymentType: 1,
    method: 'Credit : credit token',
    recceipt: 'credit',
    mapping: 2,
  },
  {
    paymentType: 2,
    method: 'Credit : input credit',
    recceipt: 'credit',
    mapping: 2,
  },
  {
    paymentType: 3,
    method: 'Credit : Fleet card',
    recceipt: 'fleetCard',
    mapping: 6,
  },
  {
    paymentType: 4,
    method: 'Credit : swipe card',
    recceipt: 'credit',
    mapping: 2,
  },
  {
    paymentType: 5,
    method: 'mDispatch card',
    recceipt: 'mDispatcherCard',
    mapping: 3,
  },
  {
    paymentType: 6,
    method: 'DirectBilling',
    recceipt: 'directBilling',
    mapping: 5,
  },
  {
    paymentType: 7,
    method: 'CorporateCard',
    recceipt: 'corporateCard',
    mapping: 4,
  },
  {
    paymentType: 8,
    method: 'Prepaid',
    recceipt: 'prePaid',
    mapping: 7,
  },
  {
    paymentType: 12,
    method: 'Credit card',
    recceipt: 'creditCardExternal',
    mapping: 12,
  },
];
export const bookingStatusMapping = {
  active: {
    arrived: true,
    pending: true,
    queue: true,
    offered: true,
    booked: true,
    engaged: true,
    droppedOff: true,
    completed: false,
    canceled: false,
    noShow: false,
    action: true,
    pre: true,
    confirmed: true,
    delivering: true,
  },
  canEdit: {
    arrived: false,
    pending: true,
    queue: false,
    offered: false,
    booked: false,
    engaged: false,
    droppedOff: false,
    completed: false,
    canceled: false,
    noShow: false,
    action: true,
    pre: true,
    confirmed: true,
    delivering: false,
  },
  canReDispatch: {
    arrived: true,
    pending: true,
    queue: false,
    offered: false,
    booked: true,
    engaged: false,
    droppedOff: false,
    completed: false,
    canceled: false,
    noShow: false,
    action: true,
    pre: true,
    confirmed: true,
    accepted: true,
    delivering: false,
  },
  canUpdateBooking: {
    arrived: false,
    pending: true,
    queue: false,
    offered: false,
    booked: true,
    engaged: true,
    droppedOff: true,
    completed: false,
    canceled: false,
    noShow: false,
    action: true,
    pre: true,
    confirmed: true,
    accepted: true,
    delivering: true,
  },
  canUpdateBookingDelivery: {
    arrived: true,
    pending: false,
    queue: false,
    offered: false,
    booked: true,
    engaged: false,
    droppedOff: false,
    completed: false,
    canceled: false,
    noShow: false,
    action: true,
    pre: true,
    confirmed: true,
    accepted: false,
    otwMerchant: true,
    collecting: true,
    delivering: false,
  },
  canCompleteBookingDelivery: {
    arrived: false,
    pending: false,
    queue: false,
    offered: false,
    booked: false,
    engaged: false,
    droppedOff: false,
    completed: false,
    canceled: false,
    noShow: false,
    action: false,
    pre: false,
    confirmed: false,
    accepted: false,
    otwMerchant: false,
    collecting: false,
    delivering: true,
  },
  canCancel: {
    arrived: true,
    pending: true,
    queue: true,
    offered: true,
    booked: true,
    engaged: false,
    droppedOff: false,
    completed: false,
    canceled: false,
    noShow: false,
    action: true,
    pre: true,
    confirmed: true,
    delivering: false,
  },
  canCancelDelivery: {
    arrived: false,
    pending: true,
    queue: true,
    offered: true,
    booked: false,
    engaged: false,
    droppedOff: false,
    completed: false,
    canceled: false,
    noShow: false,
    action: true,
    pre: true,
    confirmed: true,
    delivering: false,
  },
  canComplete: {
    arrived: true,
    pending: false,
    queue: false,
    offered: false,
    booked: true,
    engaged: true,
    droppedOff: true,
    completed: false,
    canceled: false,
    noShow: false,
    action: false,
    pre: false,
    recurrent: false,
    delivering: true,
  },
  canUpdateNoteForAffilate: {
    arrived: true,
    pending: false,
    queue: false,
    offered: true,
    booked: true,
    engaged: true,
    droppedOff: true,
    completed: false,
    canceled: false,
    noShow: false,
    action: true,
    pre: false,
    confirmed: true,
    accepted: true,
    waitingPayment: true,
    rejected: false,
  },
  canNotNewBooking: {
    allocated: true
  }
};

export const booking3rdPartyStatus = {
  allocated: 'allocated'
}

export const mapIcons = {
  defaultMapIcon,
  action,
  pre: action,
  bookIn,
  arrived,
  offered,
  booked,
  engaged,
  droppedOff,
  bookOff,
  delivering: engaged,
  collecting: arrived,
  otwMerchant: booked,
  arrived_arrived,
  arrived_booked,
  arrived_droppedOff,
  arrived_droppedOff_driver: arrived_droppedOff,
  arrived_engaged,
  arrived_engaged_driver: arrived_engaged,
  arrived_offered,
  arrived_offered_driver: arrived_offered,
  booked_booked,
  booked_booked_driver: booked_booked,
  booked_droppedOff,
  booked_droppedOff_driver,
  booked_engaged,
  booked_engaged_driver,
  booked_offered,
  booked_offered_driver: booked_offered,
  droppedOff_droppedOff,
  droppedOff_droppedOff_driver: droppedOff_droppedOff,
  droppedOff_engaged,
  droppedOff_engaged_driver,
  droppedOff_offered,
  droppedOff_offered_driver,
  engaged_engaged,
  engaged_engaged_driver,
  engaged_offered,
  engaged_offered_driver,
  offered_offered,
  multiDeliveryOrder,
  offered_offered_driver: offered_offered,
  flag,
  steeringWheel,
  address,
  car,
};

export const avatar = {
  driver: '/icon/avatar.png',
};

export const DispatchMode = {
  Manual: 0,
  ImmediateAutoDispatch: 1,
  DelayedAutoDispatch: 2,
};

export const ShuttleDispatchMode = {
  Manual: 0,
  // ImmediateAutoDispatch: 1,
  // DelayedAutoDispatch: 2
};

export const RouteType = {
  Zone: 'zone',
  ZipCode: 'zipcode',
};

export const CheckMode = {
  checkInteger: 0,
  checkGreaterThan0: 1,
  checkNumber: 2,
  checkGreaterOrEqual: 3,
};

export const currencyFormat = {
  symbol: '@',
  decimal: '*',
  thousand: '^',
  precision: 2,
};

export const PromotionType = {
  Public: 'public',
  Private: 'private',
  All: 'all',
  PrivatePoint: 'redeemed',
};

export const PromotionTypeFilter = [
  { value: 'all', label: 'report.query.adjustPrices.all' },
  { value: 'public', label: 'Public' },
  { value: 'private', label: 'Private' },
  { value: 'redeemed', label: 'Private (Point system)' },
];

export const FEE_TYPE = {
  Amount: 'amount',
  Percent: 'percent'
}

export const PromotionAmountTypeFilter = [
  { value: 'all', label: 'All' },
  { value: 'Amount', label: 'Amount' },
  { value: 'Percent', label: 'Percent' },
];

export const PromotionStatusFilter = [
  { value: 'all', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

export const PromotionAmountType = {
  Amount: 'Amount',
  Percent: 'Percent',
};

export const CommissionType = {
  Amount: 'Amount',
  Percent: 'Percent',
};

export const shortTrip = [
  { value: 'all', label: 'report.query.shortTrip.all' },
  { value: 'yes', label: 'report.query.shortTrip.shortTrip' },
  { value: 'no', label: 'report.query.shortTrip.nonShortTrip' },
];

export const paymentGetwayDisabledAddNew = [
  'Payfort',
  'Expresspay',
  'PayCorp',
  'Flutterwave',
  'Omise',
];
export const paymentGetwayDisabledNewBooking = [
  'Payfort',
  'Expresspay',
  'PayCorp',
  'Avis',
  'Flutterwave',
];
export const paymentGetwaySettlement = {
  Stripe: 'Stripe',
  JetPay: 'JetPay',
};

export const PAYOUT_CUSTOM_TYPE = {
  default: 'default',
  amount: 'amount',
  percent: 'percent'
}

export const userType = {
  FleetAdmin: 'FleetAdmin',
  FleetUser: 'FleetUser',
  CorporateAdmin: 'CorporateAdmin',
  CorporateUser: 'CorporateUser',
};

export const PAYOUT_TBD = 'TBD'

export const DefaultCurenciesNumberInputField = '0';
export const DefaultCurenciesInputField = '';
export const DefaultCurenciesTopupAmountInputField = '0';

export const locationType = {
  google: 'gg',
  thirdParty: '3rd',
  tencent: 'tencent',
};

export const PROVIDER_MAP_TYPE = {
  google: 'goog',
  tencent: 'tenc'
}

export const PermissionType = {
  All: 0,
  Company: 1,
  DriversAndCars: 2,
};

export const SMS_INTERGRATIONS = {
  SMSEliteTelecom: 'SMSEliteTelecom',
  SMSTwilio: 'SMSTwilio',
  SMSInfobip: 'SMSInfobip',
  SMSExE: 'SMSExE',
  SMSAfricaTalking: 'SMSAfricaTalking',
  SMSDialog: 'SMSDialog',
  SMSMobitel: 'SMSMobitel',
  SMSOvh: 'SMSOvh',
  SMSOneway: 'SMSOneway',
  SMSRingCaptcha: 'SMSRingCaptcha',
  SMSMobi: 'SMSMobi',
  SMSPlivo: 'SMSPlivo',
  SMSiSendPro: 'SMSiSendPro',
  SMSh3techs: 'SMSh3techs',
  SMSAdsmediaApp: 'SMSAdsmediaApp',
}

export const DriverType = {
  IndividualDriver: 'Individual Driver',
  OwnerOperator: 'Fleet Owner',
  deliveryIndividual: 'deliveryIndividual',
  deliveryCompany: 'deliveryCompany',
};

export const rideServiceSearchType = {
  all: 'all',
  regulation: 'regulation',
  rideSharing: 'rideSharing',
};

export const rideSharingSearchType = {
  all: 'all',
  matching: 'matching',
  unmatching: 'unmatching',
};

export const rideServices = [
  { value: 'all', label: 'report.query.rideServiceItem.all' },
  { value: 'regulation', label: 'report.query.rideServiceItem.regulation' },
  { value: 'rideSharing', label: 'report.query.rideServiceItem.rideSharing' },
];
export const carTypes = [];

export const travelerType = {
  Individual: 0,
  Corporate: 1,
  Airline: 2,
};

export const mapStatus = [
  { code: 'bookIn', text: 'map.Vacant' },
  { code: 'offered', text: 'map.Dispatching' },
  { code: 'booked', text: 'map.Driver_on_the_way' },
  { code: 'arrived', text: 'map.Arrived_and_waiting' },
  { code: 'engaged', text: 'map.P_O_B' },
  { code: 'droppedOff', text: 'map.Dropped_off' },
  { code: 'action', text: 'map.Waiting_for_process' },
  { code: 'bookOff', text: 'map.Unavailable' },
];

export const showDriversInReservation = {
  all: 'all',
  available: 'available',
  reserved: 'reserved',
};

export const europeCountry = [
  'FR',
  'CH',
  'DE',
  'SE',
  'AT',
  'LU',
  'GR',
  'ES',
  'IT',
  'BE',
  'NO',
  'GB',
];

export const HourList = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const MinList = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
];

export const CustomerSearchBy = [
  {
    label: 'customer.Name',
    value: 'fullNameSort',
  },
  {
    label: 'customer.Phone_Number',
    value: 'phone',
  },
  {
    label: 'customer.Email',
    value: 'email',
  },
  {
    label: 'customer.Corporate_Name',
    value: 'passengerInfo.corporateNameSort',
  },
  {
    label: 'customer.Support_ID',
    value: 'supportId',
  },
];

export const DriverSearchBy = [
  {
    label: 'General.Name',
    value: 'fullNameSort',
  },
  {
    label: 'search_by.phone',
    value: 'phone',
  },
  {
    label: 'search_by.email',
    value: 'email',
  },
  {
    label: 'login.Username',
    value: 'username',
  },
  {
    label: 'search_by.license_plate',
    value: 'driverInfo.licensePlate',
  },
];
export const CUEOfferSearchBy = [
  {
    label: 'search_by.booking_id',
    value: 'bookId',
  },
  {
    label: 'search_by.destination',
    value: 'request.destination.address',
  },
  {
    label: 'search_by.pick_up',
    value: 'request.pickup.address',
  },
  {
    label: 'search_by.flight_number',
    value: 'request.moreInfo.flightInfo.flightNumber',
  },
  {
    label: 'search_by.note',
    value: 'request.note',
  },
]
export const CUESearchBy = [
  {
    label: 'search_by.booking_id',
    value: 'bookId',
  },
  {
    label: 'search_by.group_id',
    value: 'groupId',
  },
  {
    label: 'search_by.ExternalID',
    value: 'externalInfo.bookingReference',
  },
  {
    label: 'search_by.recurring_id',
    value: 'recurring.batchId',
  },
  {
    label: 'search_by.tripId',
    value: 'tripId',
  },
  {
    label: 'search_by.driver_name',
    value: 'drvInfo.fullName',
  },
  {
    label: 'search_by.driver_phone_number',
    value: 'drvInfo.phone',
  },
  {
    label: 'search_by.license_plate',
    value: 'drvInfo.plateNumber',
  },
  {
    label: 'search_by.vehicle',
    value: 'drvInfo.vehicleType',
  },
  {
    label: 'search_by.passenger_email',
    value: 'psgInfo.email',
  },
  {
    label: 'search_by.passenger_name',
    value: 'psgInfo.fullName',
  },
  {
    label: 'search_by.passenger_phone_number',
    value: 'psgInfo.phone',
  },
  {
    label: 'search_by.destination',
    value: 'request.destination.address',
  },
  {
    label: 'search_by.pick_up',
    value: 'request.pickup.address',
  },
  {
    label: 'search_by.flight_number',
    value: 'request.moreInfo.flightInfo.flightNumber',
  },
  {
    label: 'search_by.note',
    value: 'request.note',
  },
  {
    label: 'newbooking.instructions',
    value: 'request.instructions.content',
  },
  {
    label: 'customer.Support_ID',
    value: 'psgInfo.supportId',
  },
  {
    label: 'cue.chargeCode',
    value: 'corporateInfo.chargeCode',
  },
  {
    label: 'cue.operatorNote',
    value: 'request.operatorNote',
  },
  {
    label: 'cue.clientCaseMatter',
    value: 'corporateInfo.clientCaseMatter',
  },
];

export const TRIPSearchBy = [
  {
    label: 'search_by.tripId',
    value: 'tripId',
  },
  {
    label: 'search_by.driver_name',
    value: 'drvInfo.fullName',
  },
  {
    label: 'search_by.driver_phone_number',
    value: 'drvInfo.phone',
  },
  {
    label: 'search_by.license_plate',
    value: 'drvInfo.plateNumber',
  },
  {
    label: 'search_by.vehicle',
    value: 'drvInfo.vehicleType',
  },
];

export const HYDRA_STATUS = {
  none: 'none',
  pending: 'pending',
  accepted: 'accepted',
  denied: 'denied',
  rejected: 'rejected'
}

export const ZoneActionStatus = {
  View: 'View',
  Edit: 'Edit',
  Delete: 'Delete',
  Add: 'Add',
  Status: 'Status',
  Import: 'Import',
};

export const SettelementSetting = {
  verifyAccount: 1,
  withoutVerifyAccount: 0,
};

export const WithdrawalStatusItems = [
  { label: 'report.query.common.all', value: 'all' },
  { label: 'report.result.driverWithdrawal.approved', value: 'approved' },
  { label: 'report.result.driverWithdrawal.rejected', value: 'rejected' },
  { label: 'report.result.driverWithdrawal.failed', value: 'failed' },
  { label: 'report.result.driverWithdrawal.reversed', value: 'reversed' },
];

export const WithdrawalTransactionTypeItems = [
  { label: 'report.query.common.all', value: 'all' },
  {
    label: 'report.query.withdrawalTransactionType.bankAccount',
    value: 'bankAccount',
  },
  {
    label: 'report.query.withdrawalTransactionType.creditWallet',
    value: 'creditWallet',
  },
  {
    label: 'report.query.withdrawalTransactionType.WingBank',
    value: 'WingBank',
  },
  {
    value: 'eWallet',
    label: 'report.query.transactionTypeItem.eWallet',
  }
];

export const notificationsStatus = [
  { value: 'all', label: 'notifications.statusItem.all' },
  {
    value: 'canceledByDriver',
    label: 'notifications.statusItem.canceledByDriver',
  },
  {
    value: 'reservationReminder',
    label: 'notifications.statusItem.reservationReminder',
  },
  { value: 'localPending', label: 'notifications.statusItem.localPending' },
  {
    value: 'reservationIsNotStarted',
    label: 'notifications.statusItem.reservationIsNotStarted',
  },
  {
    value: 'partnerRequestsCars',
    label: 'notifications.statusItem.partnerRequestsCars',
  },
  { value: 'timeout', label: 'notifications.statusItem.timeout' },
  { value: 'noShow', label: 'notifications.statusItem.noShow' },
  {
    value: 'affiliatePending',
    label: 'notifications.statusItem.affiliatePending',
  },
  {
    value: 'affiliatePaymentFailed',
    label: 'notifications.statusItem.affiliatePaymentFailed',
  },
  {
    value: 'affiliateTimeout',
    label: 'notifications.statusItem.affiliateTimeout',
  },
  {
    value: 'canceledByPartner',
    label: 'notifications.statusItem.canceledByPartner',
  },
  { value: 'flightInfoUpdated', label: 'notifications.statusItem.flightApi' },
  { value: 'DebtWriteOff', label: 'notifications.statusItem.DebtWriteOff' },
  {
    value: 'driverCancelTrip',
    label: 'notifications.statusItem.driverCancelTrip',
  },
  {
    value: 'minimumSeatRequired',
    label: 'notifications.statusItem.minimumSeatRequired',
  },
  {
    value: 'newFarmInJobFrom3rd',
    label: 'notifications.statusItem.newFarmInJobFrom3rd',
  },
  {
    value: 'canceledJobBy3rd',
    label: 'notifications.statusItem.canceledJobBy3rd',
  },
  {
    value: 'updatedJobBy3rd',
    label: 'notifications.statusItem.updatedJobBy3rd',
  },
  {
    value: 'newFarmInJobFromHolidayTaxis',
    label: 'notifications.statusItem.newFarmInJobFromHolidayTaxis',
  },
  {
    value: 'canceledJobByHolidayTaxis',
    label: 'notifications.statusItem.canceledJobByHolidayTaxis',
  },
  {
    value: 'updatedJobByHolidayTaxis',
    label: 'notifications.statusItem.updatedJobByHolidayTaxis',
  },
  {
    value: 'newFarmInJobFromKarhoo',
    label: 'notifications.statusItem.newFarmInJobFromKarhoo',
  },
  {
    value: 'canceledJobByKarhoo',
    label: 'notifications.statusItem.canceledJobByKarhoo',
  },
  {
    value: 'updatedJobByKarhoo',
    label: 'notifications.statusItem.updatedJobByKarhoo',
  },
];

export const sosStatus = [
  { value: 'all', label: 'notifications.sos.statusItem.all' },
  { value: 'driver', label: 'notifications.sos.statusItem.driver' },
  { value: 'passenger', label: 'notifications.sos.statusItem.passenger' },
  { value: 'crew', label: 'notifications.sos.statusItem.crew' },
];

export const signUpNotificationsStatus = [
  { value: 'all', label: 'notifications.statusItem.all' },
  {
    value: 'newDriverSignedUp',
    label: 'notifications.statusItem.newDriverSignedUp',
  },
];

export const unionpayCreditType = [
  { value: 'IDCARD', label: 'IDCARD' },
  { value: 'PASSPORT', label: 'PASSPORT' },
  { value: 'OFFICERPASS', label: 'OFFICERPASS' },
  { value: 'HM_VISITORPASS', label: 'HM_VISITORPASS' },
  { value: 'T_VISITORPASS', label: 'T_VISITORPASS' },
  { value: 'OTHER', label: 'OTHER' },
];

export const driverStatus = [
  { value: 'all', label: 'notifications.sos.statusItem.all' },
  { value: 'active', label: 'driver.Active' },
  // { value: "in_review", label: "driver.In_Review" },
  { value: 'in_review_completed', label: 'driver.in_review_completed' },
  { value: 'in_review_incomplete', label: 'driver.in_review_incomplete' },
  { value: 'in_review_progress', label: 'driver.in_review_inProgress' },
  { value: 'inactive', label: 'driver.Inactive' },
];

export const marketPlaceFilter = [
  { value: 'all', label: 'notifications.sos.statusItem.all' },
  { value: 'enable', label: 'generalSetting.Enable' },
  { value: 'disable', label: 'generalSetting.Disable' },
];

export const cashBalance = [
  { value: 'all', label: 'notifications.sos.statusItem.all' },
  { value: 'unavailable', label: 'driver.cashBalance_unavailable' },
  { value: 'active', label: 'driver.cashBalance_active' },
  { value: 'negative', label: 'driver.cashBalance_negative' },
];
export const creditBalance = [
  { value: 'all', label: 'notifications.sos.statusItem.all' },
  { value: 'unavailable', label: 'driver.cashBalance_unavailable' },
  { value: 'active', label: 'driver.cashBalance_active' },
  { value: 'negative', label: 'driver.cashBalance_negative' },
];
export const migrationStatus = [
  { value: 'all', label: 'cue.All' },
  { value: 'pending', label: 'cue.Pending' },
  { value: 'migrating', label: 'Migrating' },
  { value: 'migrated', label: 'Migrated' },
];
export const stripeConnectStatus = [
  { value: 'all', label: 'cue.All' },
  { value: 'active', label: 'driver.Active' },
  { value: 'inProgress', label: 'driver.In_Review' },
  { value: 'inactive', label: 'driver.Inactive' },
];

export const heatMapMode = [
  {
    value: 'requestedBookings',
    label: 'generalSetting.heatMap.requestedBookings',
  },
  {
    value: 'activePassengers',
    label: 'generalSetting.heatMap.activePassengers',
  },
  {
    value: 'completedBookings',
    label: 'generalSetting.heatMap.completedBookings',
  },
  { value: 'timeoutBookings', label: 'generalSetting.heatMap.timeoutBookings' },
];

export const showFare = [
  {
    value: 0,
    label: 'generalSetting.showFare.totalFare'
  },
  {
    value: 1,
    label: 'generalSetting.showFare.estimateGross'
  }
]

export const flightProvider = [
  { value: '', label: 'None' },
  {
    value: 'flightaware',
    label: 'flightIntegration.FlightAware'
  },
  {
    value: 'flightAirlabs',
    label: 'flightIntegration.Airlabs'
  },
  {
    value: 'flightStats',
    label: 'flightIntegration.FlightStats'
  }
]

export const defaultHeatMapMode = 'timeoutBookings';

export const LOCATION_TYPE = {
  Google: 'gg',
  ThirdParty: '3rd',
  Tencent: 'tencent',
};

export const SERVICE_TYPE = {
  Optional: 'Optional',
  Compulsory: 'Compulsory',
};

export const tripStatus = [
  {
    code: 'all',
    label: 'All',
    group: 'all',
  },
  {
    code: 'confirmed',
    label: 'Confirmed',
    group: 'confirmed',
  },
  {
    code: 'started',
    label: 'Started',
    group: 'started',
  },
  {
    code: 'intransit',
    label: 'Intransit',
    group: 'intransit',
  },
  {
    code: 'pending',
    label: 'Pending',
    group: 'pending',
  },
];

export const capacityStatus = [
  {
    code: 'all',
    label: 'All',
    group: 'all',
  },
  {
    code: 'empty',
    label: 'Empty',
    group: 'empty',
  },
  {
    code: 'notLive',
    label: 'Partial_Not_Live',
    group: 'notLive',
  },
  {
    code: 'live',
    label: 'Partial_Live',
    group: 'live',
  },
  {
    code: 'full',
    label: 'Full',
    group: 'full',
  },
];

export const deliveryTypeFare = [
  {
    label: 'deliveryRate.distance',
    value: 'distance',
  },
  {
    label: 'deliveryRate.flat',
    value: 'flat',
  },
];

export const tripTypes = [
  {
    code: 'all',
    label: 'All',
    group: 'all',
  },
  {
    code: 'scheduled',
    label: 'Scheduled',
    group: 'scheduled',
  },
  {
    code: 'requested',
    label: 'Requested',
    group: 'requested',
  },
  {
    code: 'preferred',
    label: 'Preferred',
    group: 'preferred',
  },
];

export const UserEventType = {
  passenger: 'passenger',
  driver: 'driver',
};

export const EventType = {
  first_will_win: 'first_will_win',
  largest_will_win: 'largest_will_win',
};
export const usersType = [
  {
    label: 'report.result.customerQuest.customer',
    value: 'passenger',
  },
  {
    label: 'report.result.customerQuest.driver',
    value: 'driver',
  },
];
export const eventType = [
  { value: 'all', label: 'cue.All' },
  {
    label: 'report.result.customerQuest.firstWillWin',
    value: 'first_will_win',
  },
  {
    label: 'report.result.customerQuest.firstWillWinLuckyDraw',
    value: 'first_will_win_lucky_draw',
  },
  {
    label: 'report.result.customerQuest.largestWillWin',
    value: 'largest_will_win',
  },
];
export const eventState = [
  { value: 'all', label: 'cue.All' },
  {
    label: 'report.result.customerQuest.qualified',
    value: 'qualified',
  },
  {
    label: 'report.result.customerQuest.notQualified',
    value: 'notQualified',
  },
];

export const MetricType = {
  passenger: [
    {
      metricType: 'booking_distance',
      unit: 'meter',
    },
    {
      metricType: 'booking_time',
      unit: 'minutes',
    },
    {
      metricType: 'booking_amount_spent',
      unit: 'currency',
    },
    {
      metricType: 'num_of_ondemand',
      unit: 'booking',
    },
    {
      metricType: 'num_of_reservation',
      unit: 'booking',
    },
    {
      metricType: 'num_of_intercity',
      unit: 'booking',
    },
    {
      metricType: 'num_of_delivery',
      unit: 'booking',
    },
    {
      metricType: 'number_of_food_mart',
      unit: 'booking',
    },
    {
      metricType: 'num_of_referral',
      unit: 'Referral',
    },
    {
      metricType: 'num_of_complete_wallet',
      unit: 'booking',
    },
    {
      metricType: 'number_of_complete_booking',
      unit: 'booking',
    },
    {
      metricType: 'num_of_complete_booking_by_referee',
      unit: 'booking',
    },
  ],
  driver: [
    {
      metricType: 'booking_distance',
      unit: 'meter',
    },
    {
      metricType: 'booking_time',
      unit: 'minutes',
    },
    {
      metricType: 'num_of_referral',
      unit: 'Referral',
    },
    {
      metricType: 'num_of_complete',
      unit: 'booking',
    },
    {
      metricType: 'num_of_referral_driver',
      unit: 'Referral',
    },
    {
      metricType: 'num_of_complete_wallet',
      unit: 'booking',
    },
    {
      metricType: 'num_of_complete_booking_by_referee',
      unit: 'booking',
    },
    {
      metricType: 'available_time',
      unit: 'hours',
    },
  ],
};

export const CriteriaTypes = {
  passenger: [
    {
      criteriaType: 'driver_rating',
      unit: 'amount',
    },
    {
      criteriaType: 'acceptance_rating',
      unit: 'percent',
    },
    {
      criteriaType: 'cancellation_rating',
      unit: 'percent',
    },
  ],
  driver: [
    {
      criteriaType: 'driver_rating',
      unit: 'amount',
    },
    {
      criteriaType: 'acceptance_rating',
      unit: 'percent',
    },
    {
      criteriaType: 'cancellation_rating',
      unit: 'percent',
    },
  ],
};

export const referralStatus = [
  { value: 'all', label: 'notifications.sos.statusItem.all' },
  { value: 'activated', label: 'report.result.driverRefersDriver.activated' },
  { value: 'inReview', label: 'report.result.driverRefersDriver.inReview' },
];

export const allStatusCUE = {
  all: true,
  pending: true,
  dispatching: true,
  action: true,
  confirmed: true,
  booked: true,
  arrived: true,
  engaged: true,
  droppedOff: true,
  orderAccepted: true,
  confirmedAccepted: true,
  driverSender: true,
  driverRecipient: true,
  driverCash: true,
  driverGoods: true,
  arrivedSender: true,
  arrivedCash: true,
  delivering: true,
  otwMerchant: true,
  arrivedAndWaitingToCollectItem: true,
  allocated: true,
};

export const statusNotDeliveryCUE = {
  all: true,
  pending: true,
  dispatching: true,
  action: true,
  confirmed: true,
  booked: true,
  arrived: true,
  engaged: true,
  droppedOff: true,
  allocated: true,
};

export const dateRangeActiveForCUE = [
  {
    value: 'nextMonth',
    key: 'nextMonth',
    label: 'report.query.nextMonth',
  },
  {
    value: 'thisMonth',
    key: 'thisMonth',
    label: 'report.query.thisMonth',
  },
  {
    value: 'nextWeek',
    key: 'nextWeek',
    label: 'report.query.nextWeek',
  },
  {
    value: 'thisWeek',
    key: 'thisWeek',
    label: 'report.query.thisWeek',
  },
  {
    value: 'tomorrow',
    key: 'tomorrow',
    label: 'report.query.tomorrow',
  },
  {
    value: 'today',
    key: 'today',
    label: 'report.query.today',
  },
  {
    value: 'custom',
    key: 'custom',
    label: 'report.query.custom',
  },
  {
    value: 'allTime',
    key: 'allTime',
    label: 'report.query.allTime',
  },
];

export const dateRangeFinishForCUE = [
  {
    value: 'lastMonth',
    key: 'lastMonth',
    label: 'report.query.lastMonth',
  },
  {
    value: 'thisMonth',
    key: 'thisMonth',
    label: 'report.query.thisMonth',
  },
  {
    value: 'lastWeek',
    key: 'lastWeek',
    label: 'report.query.lastWeek',
  },
  {
    value: 'thisWeek',
    key: 'thisWeek',
    label: 'report.query.thisWeek',
  },
  {
    value: 'yesterday',
    key: 'yesterday',
    label: 'report.query.yesterday',
  },
  {
    value: 'today',
    key: 'today',
    label: 'report.query.today',
  },
  {
    value: 'custom',
    key: 'custom',
    label: 'report.query.custom',
  },
  {
    value: 'allTime',
    key: 'allTime',
    label: 'report.query.allTime',
  },
];
export const merchantService = [
  { value: 'all', label: 'notifications.sos.statusItem.all' },
  { value: 'food', label: 'report.result.merchant.food' },
  { value: 'mart', label: 'report.result.merchant.mart' },
];
export const merchantType = [
  { value: 'all', label: 'notifications.sos.statusItem.all' },
  { value: 'preferred', label: 'report.result.merchant.preferredMerchant' },
  { value: 'regular', label: 'report.result.merchant.regularMerchant' },
];
export const relationships = [
  { value: 1, label: 'driver.spouse' },
  { value: 2, label: 'driver.daughterOrSon' },
  { value: 3, label: 'driver.brotherOrSister' },
  { value: 4, label: 'driver.relative' },
  { value: 5, label: 'driver.friends' },
  { value: 6, label: 'driver.other' },
];

export const documentExpiryStatus = [
  { value: 'all', label: 'notifications.sos.statusItem.all' },
  { value: 'expired', label: 'report.result.documentExpiry.statusStr.expired' },
  {
    value: 'expireSoon',
    label: 'report.result.documentExpiry.statusStr.expireSoon',
  },
  { value: 'na', label: 'report.result.documentExpiry.statusStr.NA' },
];
export const REGULAR_MODE = {
  timeAndMileage: 'timeAndMileage',
  timeOrMileage: 'timeOrMileage'
}
export const TIME_OR_MILEAGE_BASIC_FARE_FIELD = ['startingFee', 'moveFeeInJourney', 'waitFeeInJourney']
export const ModalEditFareField = {
  subField: {
    [BOOK_TYPE_FEE.intercity]: ['basicFare', 'serviceFee'],
    [BOOK_TYPE_FEE.transport]: [...TIME_OR_MILEAGE_BASIC_FARE_FIELD, 'basicFare', 'rushHourFee', 'serviceFee', 'otherFees'],
    [BOOK_TYPE_FEE.shuttle]: ['basicFare', 'rushHourFee', 'serviceFee', 'otherFees'],
    [BOOK_TYPE_FEE.parcel]: ['deliveryFee'],
  },
  mainField: {
    [BOOK_TYPE_FEE.intercity]: [
      'subTotal',
      'tax',
      'promoAmount',
      'creditTransactionFee',
      'etaFare',
    ],
    [BOOK_TYPE_FEE.transport]: [
      'subTotal',
      'airportFee',
      'meetDriverFee',
      'tollFee',
      'parkingFee',
      'gasFee',
      'fleetService',
      'techFee',
      'creditTransactionFee',
      'tip',
      'bookingFee',
      'tax',
      'promoAmount',
      'etaFare',
    ],
    [BOOK_TYPE_FEE.shuttle]: [
      'subTotal',
      'airportFee',
      'meetDriverFee',
      'tollFee',
      'parkingFee',
      'gasFee',
      'fleetService',
      'techFee',
      'creditTransactionFee',
      'tip',
      'bookingFee',
      'tax',
      'promoAmount',
      'etaFare',
    ],
    [BOOK_TYPE_FEE.parcel]: [
      'techFee',
      'tax',
      'creditTransactionFee',
      'promoAmount',
      'etaFare',
    ],
  },
  thirdPartyField: {
    [thirdPartyIntegration.holidaytaxis]: ['etaFare']
  }
};

export const POINT_TYPE_TEXT = {
  pu: 'pick up Point',
  do: 'Drop off point',
};

export const EDITABLE_FARE = [
  'basicFare',
  'airportFee',
  'meetDriverFee',
  'rushHourFee',
  'techFee',
  'bookingFee',
  'tip',
  'otherFees',
  'promoAmount',
  'tollFee',
  'parkingFee',
  'gasFee',
  'serviceFee',
  'deliveryFee',
];

export const EVENT_DEEPLINK = {
  passenger: [
    {
      name: 'Transport',
      link: 'p://transport',
    },
    {
      name: 'T Now',
      link: 'p://transport?bookingType=now',
    },
    {
      name: 'T Later',
      link: 'p://transport?bookingType=later',
    },
    {
      name: 'T Hourly',
      link: 'p://transport?bookingType=hourly',
    },
    {
      name: 'Delivery',
      link: 'p://delivery',
    },
    {
      name: 'Intercity',
      link: 'p://intercity',
    },
    {
      name: 'Referral',
      link: 'p://referral',
    },
    {
      name: 'Refer',
      link: 'p://refer',
    },
    {
      name: 'Wallet',
      link: 'p://wallet',
    },
    {
      name: 'Topup',
      link: 'p://topup',
    },
    {
      name: 'Payment',
      link: 'p://payment',
    },
    {
      name: 'Promotion',
      link: 'p://promotion',
    },
    {
      name: 'Share',
      link: 'p://share?url=https%3A%2F%2Fevent.gojo.global%2Fevents-detail%3FeventId%3D{EVENT_ID}%26share%3Dtrue&const_var=USER_ID,APP_ID,APP_TYPE,REFERRAL_CODE&isShortLink=true',
    },
  ],
  driver: [
    {
      name: 'Top Up',
      link: 'd://wallet?type=credit&action=topup',
    },
    {
      name: 'T.Up Customer',
      link: 'd://wallet?type=credit&action=topupForCustomer',
    },
    {
      name: 'Credit History',
      link: 'd://wallet?type=credit&action=history',
    },
    {
      name: 'Cash History',
      link: 'd://wallet?type=cash&action=history',
    },
    {
      name: 'Wallet Transfer',
      link: 'd://wallet?type=cash&action=transfer',
    },
    {
      name: 'Job',
      link: 'd://job',
    },
    {
      name: 'Job Confirmed',
      link: 'd://job?type=confirmed',
    },
    {
      name: 'Job Nearby',
      link: 'd://job?type=nearby',
    },
    {
      name: 'Job Intercity',
      link: 'd://job?type=intercity',
    },
    {
      name: 'Map',
      link: 'd://map',
    },
    {
      name: 'Referral',
      link: 'd://refer',
    },
    {
      name: 'Referral History',
      link: 'd://refer?action=history',
    },
    {
      name: 'Share',
      link: 'd://share?url=https%3A%2F%2Fevent.gojo.global%2Fevents-detail%3FeventId%3D{EVENT_ID}%26share%3Dtrue&const_var=USER_ID,APP_ID,APP_TYPE,REFERRAL_CODE&isShortLink=true',
    },
  ],
};

export const affiliationPayoutType = [
  { value: 'all', label: 'report.query.common.all' },
  { value: 'auto', label: 'report.query.affiliationPayoutTypeItem.auto' },
  { value: 'manual', label: 'report.query.affiliationPayoutTypeItem.manual' },
];

export const settlement = [
  { value: 'all', label: 'report.query.common.all' },
  { value: 'payable', label: 'report.query.affiliationSettlementItem.payable' },
  {
    value: 'receivable',
    label: 'report.query.affiliationSettlementItem.receivable',
  },
];

export const networkTypes = [
  { value: 'all', label: 'report.query.common.all' },
  {
    value: 'roaming',
    label: 'report.query.bookingServiceItem.roaming',
  },
  {
    value: 'homeFleet',
    label: 'report.query.farmTypeItem.farmOut',
  },
  {
    value: 'provideService',
    label: 'report.query.bookingServiceItem.provideService',
  },
];

export const smsIntegrationList = [
  { value: '', label: 'None' },
  {
    value: SMS_INTERGRATIONS.SMSEliteTelecom,
    label: 'Elite Telecom',
  },
  {
    value: SMS_INTERGRATIONS.SMSTwilio,
    label: 'Twilio',
  },
  {
    value: SMS_INTERGRATIONS.SMSInfobip,
    label: 'Infobip',
  },
  {
    value: SMS_INTERGRATIONS.SMSExE,
    label: '8x8',
  },
  {
    value: SMS_INTERGRATIONS.SMSAfricaTalking,
    label: "Africa's Talking",
  },
  {
    value: SMS_INTERGRATIONS.SMSDialog,
    label: 'Dialog',
  },
  {
    value: SMS_INTERGRATIONS.SMSMobitel,
    label: 'Mobitel',
  },
  {
    value: SMS_INTERGRATIONS.SMSOvh,
    label: 'OVH',
  },
  {
    value: SMS_INTERGRATIONS.SMSOneway,
    label: 'Oneway',
  },
  {
    value: SMS_INTERGRATIONS.SMSRingCaptcha,
    label: 'RingCaptcha',
  },
  {
    value: SMS_INTERGRATIONS.SMSMobi,
    label: 'Mobi',
  },
  {
    value: SMS_INTERGRATIONS.SMSPlivo,
    label: 'Plivo',
  },
  {
    value: SMS_INTERGRATIONS.SMSiSendPro,
    label: 'iSendPro',
  },
  {
    value: SMS_INTERGRATIONS.SMSh3techs,
    label: 'h3techs',
  },
  {
    value: SMS_INTERGRATIONS.SMSAdsmediaApp,
    label: 'AdsmediaApp',
  },
];

export const payoutPaymentStatus = [
  { value: 'all', label: 'report.query.common.all' },
  { value: 'paid', label: 'report.query.payoutPaymentStatusItem.paid' },
  { value: 'pending', label: 'report.query.payoutPaymentStatusItem.pending' },
];

export const PAID_STATUS = {
  paid: 'paid',
  partial: 'partial',
  pending: 'pending'
}

export const NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD = [1, 16, 17];
export const CPT_NOTES_ROW = 'notes-row';
export const APPLY_SERVICE_TYPE = {
  ALL: 'all',
  CUSTOM_ZONE: 'custom_zone'
};
export const SUPPLIER_TYPE = {
  customized: 'customized'
};
export const CPT_TEXT_AREA_ROW = 'text-area-row';
export const BUTTON_ROW = 'button-row';
export const CPT_CHECKBOX_AMOUNT_ROW = 'checkbox-amount-row';
export const BOOK_FROM_CC_MENU_ID = 'bfc-menuid';
export const BOOK_FROM_CC_CPT_TEXT_AREA_ROW = 'bfc-text-area-row-parrent-item';
export const BOOK_FROM_CC_CPT_NOTES_ROW = 'bfc-notes-row-parrent-item';
export const BOOK_FROM_CC_CPT_CHECKBOX_AMOUNT_ROW =
  'bfc-checkbox-amount-row-parrent-item';
export const PAYMENT_LINK_NAME = {
  card: "Credit Card",
  affirm: "Affirm",
  promptpay: "PromptPay",
  bacs_debit: "BACS Debit",
  bancontact: "Bancontact",
  blik: "Blik",
  boleto: "Boleto",
  cashapp: "Cash App",
  eps: "EPS",
  fpx: "FPX",
  giropay: "Giropay",
  grabpay: "GrabPay",
  ideal: "iDEAL",
  klarna: "Klarna",
  konbini: "Konbini",
  link: "Link",
  oxxo: "OXXO",
  p24: "Przelewy24",
  paynow: "PayNow",
  paypal: "PayPal",
  pix: "PIX",
  afterpay_clearpay: "Afterpay Clearpay",
  alipay: "Alipay",
  au_becs_debit: "AU BECS Debit",
  sepa_debit: "SEPA Debit",
  sofort: "SOFORT",
  us_bank_account: "US Bank Account",
  wechat_pay: "WeChat Pay",
  google_pay: "Google Pay",
  apple_pay: "Apple Pay",
  cash: 'Cash',
  wire: 'Wire',
  check: 'Check',
  digitalWallet: 'Digital Wallet',
}

export const STATUS_GROUP_BOOKING = [
  'pending',
  'queue',
  'offered',
  'pre',
  'action',
  'confirmed',
]

export const STATUS_ASSIGN_SUPLIERS = [
  'pending',
  'queue',
  'offered',
  'pre',
  'action',
  'confirmed',
]

export const STATUS_ASSIGN_DRIVER = [
  'pre',
  'pending',
  'confirmed',
  'booked',
  'arrived',
  'queue',
  'offered',
  'action'
]

export const STATUS_ASSIGN_MULTI_DRIVER = [
  'pre',
  'pending',
  'confirmed'
]

export const TYPE_BOOKING_GROUP = {
  delivery: 'delivery',
  tranpsort: 'transport',
  transport_now: 'transport_now',
  intercity: 'intercity',
  shuttle: 'shuttle',
  manifest: 'manifest'
}

export const JOB_TYPE = {
  food: 'food',
  mart: 'mart',
}

export const BOOK_TYPE = {
  rideSharing: 'rideSharing',
  now: 'now',
  reservation: 'reservation',
  carHailing: 'carHailing',
  delivery: 'delivery',
  batchDelivery: 'batchDelivery',
  shuttle: 'shuttle',
  intercity: 'intercity',
  streetSharing: 'streetSharing',
}

export const COLOR_SERVICE_TYPE = {
  fromAirport: '#0E4D92',
  toAirport: '#FF851B',
  pointToPoint: '#0074D9',
  food: '#FF6347',
  mart: '#228B22',
  delivery: '#0047AB',
  batchDelivery: '#0047AB',
  hourly: '#9B59B6',
  roundTrip: '#008080',
  shuttle: '#008080',
  intercity: '#008080',
  shareTransfer: '#008080',
  foundersFund: '#009642',
  zeroCommission: '#FDB022',
}

export const STATUS_COLOR_MOBILE = {
  incident: '#F97066',
  noShow: '#D9D9D9',
  pending: '#98A2B3',
  pre: '#98A2B3',
  confirmed: '#8ACCDC',
  booked: '#FFC42E',
  engaged: '#00878F',
  action: '#E60093',
  queue: '#BB5EFA',
  offered: '#BB5EFA',
  arrived: '#D46C1E',
  droppedOff: '#FA1F24',
  canceledByCC: '#D46C1E',
  canceledByPassenger: '#D46C1E',
  canceledByDriver: '#D46C1E',
  canceledByPartner: '#D46C1E',
  canceledBymDispatcher: '#D46C1E',
  canceledByCorporateAdmin: '#D46C1E',
  canceledByAPI: '#D46C1E',
  canceledByWebBooking: '#D46C1E',
  canceledByTimeout: '#D46C1E',
  canceledByTimeout: '#D46C1E',
  failed: '#f52518',
  noShow: 'white',
  partialCompleted: '#04BE76',
  completed: '#04BE76',
  completedWithoutService: '#04BE76',
  incident: '#4752a3',
  delivering: '#00929b',
  collecting: '#fdca00',
  otwMerchant: '#fdca00',
  arrivedAndWaitingToCollectItem: '#D46C1E',
}
