/* global google */
import React, { useRef } from 'react';
import { QQMap, QQPolygon } from '../../../../../components/qqMap';
import { withGoogleMap, GoogleMap, Polygon } from 'react-google-maps';

const GettingStartedGoogleMap = withGoogleMap((props) => (
  <GoogleMap
    ref={(map) => {
      props.onMapLoad(props.map, map);
    }}
    defaultZoom={8}
    center={props.center || { lat: 16.059959, lng: 108.224258 }}
    onClick={props.onMapClick}
    options={{ gestureHandling: 'greedy' }}
  >
    {props.polygon ? (
      <Polygon
        paths={props.polygon}
        options={{
          strokeColor: 'yellow',
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: 'red',
          fillOpacity: 0.4,
        }}
      />
    ) : (
      ''
    )}
  </GoogleMap>
));

const convertCoordinatesToPath = (coor, isChina) => {
  if (coor) {
    if (isChina) {
      return coor.map((pos) => {
        return new window.qq.maps.LatLng(pos[1], pos[0]);
      });
    } else {
      return coor.map((pos) => {
        return {
          lat: pos[1],
          lng: pos[0],
        };
      });
    }
  }
  return [];
};

export default function RouteMap({ isChina, zone }) {
  let mapRef = useRef(null);

  const handleMapLoad = (key, ref) => {
    if (ref) {
      mapRef = ref;
    }
  };

  const getCenterPoint = (coor) => {
    if (coor) {
      if (isChina) {
        var latLng = new window.qq.maps.LatLngBounds();

        coor.map((pos) => {
          latLng.extend(new window.qq.maps.LatLng(pos[1], pos[0]));
        });

        if (mapRef && mapRef.current) {
          mapRef.current.fitBounds(latLng);
        }
        return latLng.getCenter();
      } else {
        var latLng = new google.maps.LatLngBounds();

        coor.map((pos) => {
          latLng.extend(new google.maps.LatLng(pos[1], pos[0]));
        });
        const centerLatLng = latLng.getCenter();

        if (mapRef && mapRef.current) {
          mapRef.current.fitBounds(latLng);
        }
        return {
          lat: centerLatLng.lat(),
          lng: centerLatLng.lng(),
        };
      }
    }
    return null;
  };

  if (isChina) {
    return (
      <QQMap
        className="fill"
        options={{
          center: zone ? getCenterPoint(zone.coordinates[0], true) : null,
        }}
        onMapLoad={handleMapLoad}
      >
        {zone && (
          <QQPolygon
            path={convertCoordinatesToPath(zone.coordinates[0], isChina)}
            options={{
              strokeColor: '#FFFF00',
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor: new window.qq.maps.Color.fromHex('#FF0000', 0.4),
            }}
            mapkey="1"
            fitBounds={true}
          />
        )}
      </QQMap>
    );
  } else {
    return (
      <GettingStartedGoogleMap
        containerElement={<div className="mapqup fill" />}
        mapElement={<div style={{ height: `100%` }} />}
        center={zone ? getCenterPoint(zone.coordinates[0], true) : null}
        polygon={zone ? convertCoordinatesToPath(zone.coordinates[0]) : null}
        map="fromZone"
        onMapLoad={handleMapLoad}
      />
    );
  }
}
