/* global google */
// /* eslint-disable import/first */
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import './components/select/react-select.scss';
import './index.scss';
import './utils/extentions';
import jquery from 'jquery';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory, IndexRedirect } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import App from './containers/app/App';
import configureStore from './store/configureStore';
import { translations } from './constants/languages/languages';

// All page related to Route
import handleLoadSettingServer from './utils/loadServerSetting';

import Affiliation from './containers/reports/affiliation';
import BookingDetails from './containers/reports/booking-details';
import BookingLogs from './containers/reports/booking-logs';
import BookingSummary from './containers/reports/booking-summary';
import CarActivity from './containers/reports/car-activity';
import ReportCorporate from './containers/reports/corporate';
import DailyCar from './containers/reports/daily-car';
import DailyDriver from './containers/reports/daily-driver';
import DailyDriverCar from './containers/reports/daily-driver-car';
import DocumentExpiry from './containers/reports/document-expiry';
import CreditWallet from './containers/reports/credit-wallet';
import CashWallet from './containers/reports/cash-wallet';
import DriverLoginStatus from './containers/reports/driver-login-status';
import Financial from './containers/reports/financial';
import FinancialCompany from './containers/reports/financial-company';
import FinancialProfit from './containers/reports/financial-profit';
import FinancialDriver from './containers/reports/financial-driver';
import IncidentCancellation from './containers/reports/incident-cancellation';
import mDispatcher from './containers/reports/mDispatcher';
import OperatorLogs from './containers/reports/operator-logs';
import PaidToDriver from './containers/reports/paid-to-driver';
import PrepaidTopUp from './containers/reports/prepaid';
import Promotion from './containers/reports/promotion';
import Rating from './containers/reports/rating';
import AcceptCancelRate from './containers/reports/accept-cancel-rate';
import Report from './containers/reports/Report';
import RevenueDriver from './containers/reports/revenue-driver';
import RevenueFleet from './containers/reports/revenue-fleet';
import RevenueMonthly from './containers/reports/revenue-monthly';
import SettlementHistory from './containers/reports/settlement-history';
import TotalAdjustment from './containers/reports/total-adjustment';
import ReferralDetail from './containers/reports/driver-referral-detail';
import DriverRefersPax from './containers/reports/driver-refers-pax';
import PassengerRefersPassengers from './containers/reports/passenger-refers-passengers';
import DriverWithdrawal from './containers/reports/driver-withdrawal';
import PenaltyCompensation from './containers/reports/penalty-compensation';
import IncompletePayment from './containers/reports/incomplete-payment';
import PassengerWallet from './containers/reports/passenger-wallet';
import ReferralPassengers from './containers/reports/referral-passengers';
import PassengerRefersPassengersDetails from './containers/reports/pax-referral-passengers-details';
import Refund from './containers/reports/refund';
import PayToDriver from './containers/reports/PayToDriver';
import PayoutHistory from './containers/reports/PayoutHistory';
import ReportVoucher from './containers/reports/voucher';
import FirstWillWin from './containers/reports/quest';
import DriverRefersReferrer from './containers/reports/driver-refers-referrer';
import DriverRefersReferee from './containers/reports/driver-refers-referee';
import ReportMerchant from './containers/reports/merchant';
import Airline from './containers/reports/airline';
import PayToMerchant from './containers/reports/PayToMerchant';
import PayoutHistoryMerchant from './containers/reports/PayoutHistoryMerchant';
import MerchantCashTransactionHistory from './containers/reports/cc-merchant-cash-transaction-history';
import MerchantCreditTransactionHistory from './containers/reports/cc-merchant-credit-transaction-history';
import DeleteAccountRequest from './containers/reports/delete-account-request';
import PassengerRating from './containers/reports/passenger-rating';
import Export from './containers/reports/export';
import AffiliationPayoutHistory from './containers/reports/affiliation-payout-history';
import AffiliationPayoutHistoryDetails from './containers/reports/affiliation-payout-history-details';
import CompanySettlement from './containers/reports/company-settlement';
import CompanySettlementDetails from './containers/reports/company-settlement-details';
import ThirdPartyBooking from './containers/reports/third-party-booking';
import Holidaytaxis from './containers/reports/holidaytaxis';
import Karhoo from './containers/reports/karhoo';

import StreetSharing from './containers/settings/rate/sharing/sharing';
import AppBanner from './containers/settings/appBanner';
import AppBannerPegasus from './containers/settings/appBannerPegasus';
import Campaign from './containers/settings/promotion/campaign';
import CarColor from './containers/settings/car/CarColor';
import ErrorPage from './containers/misc/ErrorPage';
import Map from './containers/map';
import Settings from './containers/settings/Settings';
import Cue from './containers/cue/Cue';
import Customer from './containers/customers/Customer';
import Driver from './containers/drivers/Driver';
import Message from './components/message/Message';
import FleetInfoSetting from './containers/settings/fleetInfo';
import StripeConnect from './containers/settings/stripeConnect/StripeConnect';
import GeneralSetting from './containers/settings/general';
import Login from './containers/login/Login';
import AddEditIntercityRouteModal from './containers/settings/rate/intercity/IntercityRoute/AddEditIntercityRouteModal';
import User from './containers/settings/user/user';
import FlatZone from './containers/settings/zone/flatZone';
import PWA from './containers/settings/pwa';
import IntercityZone from './containers/settings/zone/intercityZone';
import Company from './containers/settings/compnay/company';
import DispatchSetting from './containers/settings/DispatchSetting';
import VoiceSMSSettings from './containers/settings/voicesms';
import RegularSetting from './containers/settings/rate/regular/regular';
import Operation from './containers/settings/operation/operation';
import HourlySetting from './containers/settings/rate/hourly/hourly';
import FlatSetting from './containers/settings/rate/flat/flat';
import IntercityRate from './containers/settings/rate/intercity';
import DeliveryRate from './containers/settings/rate/delivery/delivery';
import ForgotPassword from './containers/login/forgotPassword';
import ResetPassword from './containers/login/resetPassword';
import CarMGMT from './containers/settings/car/carMgmt';
import MakeModel from './containers/settings/car/makeModel';
import CarType from './containers/settings/car/carType';
import ChangePassword from './containers/userSettings/changePassword';
import UserProfile from './containers/userSettings/userProfile';
import ShiftSettings from './containers/settings/shift/shiftSetting';
import ShiftTemplate from './containers/settings/shift/shiftTemplate';
import PromotionCode from './containers/settings/promotion/promotionCode';
import Quest from './containers/settings/promotion/Quest';
import Voucher from './containers/settings/promotion/Voucher';
import City from './containers/settings/city/city';
import Booking3rdIntegration from './containers/settings/3rdPartyIntergration/booking';
import HolidayTaxis from './containers/settings/3rdPartyIntergration/holidaytaxis';
import KarhooSetting from './containers/settings/3rdPartyIntergration/karhoo';
import MDispatcherType from './containers/settings/partner-type/mDispatcherType';
import FlightIntegration from './containers/settings/flightIntegration';
import APIManagement from './containers/settings/APIManagement';
import SMSIntegration from './containers/settings/smsIntergration';
import InvoiceSetting from './containers/settings/invoiceSetting';
import Permission from './containers/settings/permission/permission';
import ThirdParty from './containers/settings/thirdParty/thirdParty';
import Category from './containers/settings/thirdParty/category';
import DynamicSurcharge from './containers/settings/dynamicSurcharge/containers/DynamicSurcharge';
import DynamicSurchargeDetail from './containers/settings/dynamicSurcharge/containers/Surcharge';
import MDispatcher from './containers/mDispatcher/mDispatcher';
import Corporate from './containers/corporate/Corporate';
import CorporateAdd from './containers/corporate/Add';
import CorporateEdit from './containers/corporate/Edit';
import PointConfig from './containers/settings/pointConfig';
import DriverToPax from './containers/settings/referral/driverToPax';
import DynamicFare from './containers/settings/dynamicFare/containers/DynamicFare';
import DynamicFareDetail from './containers/settings/dynamicFare/containers/Fare';
import ImportBookings from './containers/importBookings/ImportBookings';
import Merchant from './containers/merchant';
import Invoice from './containers/Invoice';
import Notifications from './containers/notificationDetails/notifications/Notifications';
import PassengerRefersPax from './containers/settings/referral/paxRefersPax';
import DriverReferDriver from './containers/settings/referral/DriverReferDriver';
import PaymentInfo from './containers/settings/paymentInfo/PaymentInfo';
import QueuingArea from './containers/queuingArea/queuingArea';
import Reservation from './containers/reservation/Reservation';
import SignUpNotifications from './containers/notificationDetails/signUpNotifications';
import SmartData from './containers/smartData';
import SOS from './containers/notificationDetails/sos/sos';
import Trip from './containers/trips/Trip';
import WithdrawalRequests from './containers/withdrawal/Withdrawal';
import DriverNote from './containers/settings/driver-note/driverNote';
import Dashboard from './containers/dashboard/dashboard';
import NewBookingFromUrl from './containers/newbooking/NewBookingFromUrl';
import BookingDetailFromUrl from './containers/BookingDetailFromUrl';
import Version from './containers/version';
import jobsTab from './containers/settings/driverApp/jobsTab';
import EmailConfig from './containers/settings/emailConfig/emailConfig';
import DriverFields from './containers/settings/driverFields/driverFields';
import DriverDocument from './containers/settings/driverDocument/driverDocument';
import Social from './containers/settings/social/social';
import VehicleContainer from './containers/vehicle/index';
import CreateVehicle from './containers/vehicle/create';
import DetailVehicle from './containers/vehicle/detail';
import CreateDriver from './containers/drivers/create';
import DetailDriver from './containers/drivers/detail';
import createSupplier from './containers/supplier/create';
import Supplier from './containers/supplier';
import SupplierDetail from './containers/supplier/SupplierDetail';
// ///////////////////////////////////////////////////////////////////////
// browserHistory would be preferred over hashHistory, but browserHistory
// would require configuring the server. So we will use hashHistory here.
// Please change to browserHistory if you have your own backend server.
// import {browserHistory as history} from 'react-router';
// import { useRouterHistory } from "react-router";
// import { createHashHistory } from "history";
// const history = useRouterHistory(createHashHistory)();

const SupplierComponent = lazy(() => import('./components/supplierComponent/bookingDetail'));

const store = configureStore();
const history = syncHistoryWithStore(browserHistory, store);
syncTranslationWithStore(store);
store.dispatch(loadTranslations({ 'en-US': translations['en-US'] }));
store.dispatch(setLocale('en-US'));
handleLoadSettingServer();

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <Provider store={store}>
      <Router history={history}>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:token" component={ResetPassword} />
        <Route path="/version" component={Version} />
        <Route path="/" component={App}>
          {/* <IndexRedirect to="/map" /> */}
          <Route path="/supplier-booking-detail/:bookId" component={SupplierComponent} />
          <Route path="/cue" component={Cue} />
          <Route path="/newbooking" component={NewBookingFromUrl} />
          <Route path="/booking-detail" component={BookingDetailFromUrl} />
          <Route path="/import_bookings" component={ImportBookings} />
          <Route
            path="/import SMSIntegration from './containers/settings/smsIntergration/index';
settings"
            component={Settings}
          >
            <Route path="/settings/Fleet_info" component={FleetInfoSetting} />
            <Route path="/settings/Stripe_connect" component={StripeConnect} />
            <Route path="/settings/General" component={GeneralSetting} />
            <Route path="/settings/User" component={User} />
            <Route path="/settings/Zone/FlatZone" component={FlatZone} />
            <Route path="/settings/PWA" component={PWA} />
            <Route
              path="/settings/Zone/IntercityZone"
              component={IntercityZone}
            />
            <Route path="/settings/Company" component={Company} />
            <Route path="/settings/Dispatch" component={DispatchSetting} />
            <Route path="/settings/Voice_SMS" component={VoiceSMSSettings} />
            <Route path="/settings/rate/regular" component={RegularSetting} />
            <Route path="/settings/Rate/Flat" component={FlatSetting} />
            <Route path="/settings/rate/intercity" component={IntercityRate} />
            <Route
              path="/settings/rate/intercity/route"
              component={AddEditIntercityRouteModal}
            />
            <Route path="/settings/Rate/Delivery" component={DeliveryRate} />
            <Route path="/settings/Rate/Hourly" component={HourlySetting} />
            <Route
              path="/settings/rate/street_sharing"
              component={StreetSharing}
            />
            <Route path="/settings/Car/Car_mgmt" component={CarMGMT} />
            <Route
              path="/settings/Car/Car_mgmt/:action/:id"
              component={CarMGMT}
            />
            <Route path="/settings/Car/Car_Make" component={MakeModel} />
            <Route path="/settings/Car/Car_Color" component={CarColor} />
            <Route path="/settings/Car/Car_type" component={CarType} />
            <Route
              path="/settings/Shift/Shift_settings"
              component={ShiftSettings}
            />
            <Route
              path="/settings/Shift/Shift_template"
              component={ShiftTemplate}
            />
            <Route path="/settings/Operation" component={Operation} />
            <Route path="/settings/Driver_note" component={DriverNote} />
            <Route path="/settings/Promotion/Campaign" component={Campaign} />
            <Route
              path="/settings/Promotion/Promotion_code"
              component={PromotionCode}
            />
            <Route path="/settings/Point_config" component={PointConfig} />
            <Route path="/settings/Voucher_code" component={Voucher} />
            <Route path="/settings/Quest" component={Quest} />
            <Route path="/settings/City" component={City} />
            <Route path="/settings/Partner_type" component={MDispatcherType} />
            <Route path="/settings/Permission" component={Permission} />
            <Route path="/settings/Driver_app" component={jobsTab} />
            <Route
              path="/settings/Flight_integration"
              component={FlightIntegration}
            />
            <Route path="/settings/API_management" component={APIManagement} />
            <Route
              path="/settings/SMS_integration"
              component={SMSIntegration}
            />
            <Route
              path="/settings/Invoice_Setting"
              component={InvoiceSetting}
            />
            <Route
              path="/settings/third_party_integration/booking"
              component={Booking3rdIntegration}
            />
            <Route
              path="/settings/third_party_integration/holidaytaxis"
              component={HolidayTaxis}
            />
            <Route
              path="/settings/third_party_integration/karhoo"
              component={KarhooSetting}
            />
            <Route path="/settings/3rd_party_location" component={ThirdParty} />
            <Route
              path="/settings/3rd_party_location/category"
              component={Category}
            />
            {/* <Route path="/settings/Queuing_area" component={QueuingArea} /> */}
            <Route path="/settings/App_banner" component={AppBanner} />
            <Route
              path="/settings/App_banner_pegasus"
              component={AppBannerPegasus}
            />
            <Route
              path="/settings/Referral/Driver_refers_customer"
              component={DriverToPax}
            />
            <Route
              path="/settings/Referral/Customer_refers_customer"
              component={PassengerRefersPax}
            />
            <Route
              path="/settings/Referral/Driver_refer_driver"
              component={DriverReferDriver}
            />
            <Route path="/settings/payment_info" component={PaymentInfo} />
            <Route
              path="/settings/Dynamic_surcharge"
              component={DynamicSurcharge}
            />
            <Route
              path="/settings/Dynamic_surcharge/:zoneId"
              component={DynamicSurchargeDetail}
            />
            <Route path="/settings/Dynamic_fare" component={DynamicFare} />
            <Route
              path="/settings/Dynamic_fare/:zoneId"
              component={DynamicFareDetail}
            />
            <Route path="/settings/email_config" component={EmailConfig} />
            <Route
              path="/settings/Driver_document"
              component={DriverDocument}
            />
            <Route path="/settings/Driver_fields" component={DriverFields} />
            <Route path="/settings/social" component={Social} />
            <Route path="/settings/*" component={ErrorPage} />
          </Route>
          <Route path="/analytics" component={Dashboard} />
          <Route path="/smartdata" component={SmartData} />
          <Route path="/map" component={Map} />
          <Route path="/customer" component={Customer} />
          <Route path="/customer/add" component={Customer} />
          <Route path="/customer/edit/:customerId" component={Customer} />
          <Route path="/customer/view/:customerId" component={Customer} />
          <Route path="/customer/message" component={Message} />
          <Route path="/customer/message/add" component={Message} />
          <Route
            path="/customer/message/detail/:messageId"
            component={Message}
          />
          <Route path="/driver" component={Driver} />
          <Route path="/driver/message" component={Message} />
          <Route path="/driver/message/add" component={Message} />
          <Route path="/driver/message/detail/:messageId" component={Message} />
          <Route path="/corporate" component={Corporate} />
          <Route path="/corporate/add" component={CorporateAdd} />
          <Route path="/driver/add" component={CreateDriver} />
          <Route path="/driver/view/:driverId/:tabActive" component={DetailDriver} />
          <Route path="/supplier/add" component={createSupplier} />
          <Route
            path="/corporate/edit/:corporateId"
            component={CorporateEdit}
          />
          <Route
            path="/corporate/view/:corporateId"
            component={CorporateEdit}
          />
          <Route path="/supplier" component={Supplier} />
          <Route path="/supplier/detail/:supplierId/:tabActive" component={SupplierDetail} />
          <Route path="/vehicle" component={VehicleContainer} />
          <Route path="/vehicle/add" component={CreateVehicle} />
          <Route path="/vehicle/:vehicleId" component={DetailVehicle} />
          <Route path="/mDispatcher" component={MDispatcher} />
          <Route path="/withdrawal_requests" component={WithdrawalRequests} />
          <Route path="/Queuingarea" component={QueuingArea} />
          <Route path="/Trip" component={Trip} />
          <Route path="/Merchant" component={Merchant} />
          <Route path="/Invoice" component={Invoice} />
          <Route path="/merchant/message" component={Message} />
          <Route path="/merchant/message/add" component={Message} />
          <Route
            path="/merchant/message/detail/:messageId"
            component={Message}
          />
          <Route path="/Payout/pay_to_driver" component={PayToDriver} />
          <Route
            path="/Payout/payout_history_for_driver"
            component={PayoutHistory}
          />
          <Route path="/Payout/pay_to_merchant" component={PayToMerchant} />
          <Route
            path="/Payout/payout_history_for_merchant"
            component={PayoutHistoryMerchant}
          />
          <Route path="/settlement/pay_to_driver" component={PaidToDriver} />
          <Route
            path="/settlement/settlement_history"
            component={SettlementHistory}
          />
          <Route path="/calendar" component={Reservation} />
          <Route path="/reports" component={Report}>
            <Route path="/reports/Booking_details" component={BookingDetails} />
            <Route path="/reports/3rdBooking" component={ThirdPartyBooking} />
            <Route path="/reports/holidaytaxis" component={Holidaytaxis} />
            <Route path="/reports/karhoo" component={Karhoo} />
            <Route path="/reports/financial" component={Financial} />
            <Route
              path="/reports/financial/driver"
              component={FinancialDriver}
            />
            <Route
              path="/reports/financial/company"
              component={FinancialCompany}
            />
            <Route
              path="/reports/financial/profit"
              component={FinancialProfit}
            />
            <Route path="/reports/Car_activity" component={CarActivity} />
            <Route path="/reports/affiliation" component={Affiliation} />
            <Route
              path="/reports/affiliation/bookings"
              component={Affiliation}
            />
            <Route path="/reports/prepaid" component={PrepaidTopUp} />
            <Route path="/reports/operator_logs" component={OperatorLogs} />
            <Route path="/reports/corporate" component={ReportCorporate} />
            <Route path="/reports/airline" component={Airline} />
            <Route path="/reports/booking_logs" component={BookingLogs} />
            <Route path="/reports/promotion" component={Promotion} />
            <Route
              path="/reports/company_settlement"
              component={CompanySettlement}
            />
            <Route
              path="/reports/company_settlement/details"
              component={CompanySettlementDetails}
            />
            <Route path="/reports/rating" component={Rating} />
            <Route
              path="/reports/rating/passenger"
              component={PassengerRating}
            />
            <Route
              path="/reports/acceptcancel_rate"
              component={AcceptCancelRate}
            />
            <Route path="/reports/mDispatcher" component={mDispatcher} />
            <Route
              path="/reports/Total_adjustment"
              component={TotalAdjustment}
            />
            <Route
              path="/reports/Incident_Cancellation"
              component={IncidentCancellation}
            />
            <Route path="/reports/revenue" component={RevenueFleet} />
            <Route path="/reports/revenue/fleet" component={RevenueFleet} />
            <Route path="/reports/revenue/driver" component={RevenueDriver} />
            <Route path="/reports/revenue/monthly" component={RevenueMonthly} />
            <Route path="/reports/Daily" component={DailyDriver} />
            <Route path="/reports/Daily/Daily_Driver" component={DailyDriver} />
            <Route path="/reports/Daily/Daily_Car" component={DailyCar} />
            <Route
              path="/reports/Daily/Daily_Driver_Car"
              component={DailyDriverCar}
            />
            <Route
              path="/reports/Driver_login_status"
              component={DriverLoginStatus}
            />
            <Route path="/reports/Booking_summary" component={BookingSummary} />
            <Route path="/reports/Driver_wallet" component={CreditWallet} />
            <Route
              path="/reports/Driver_wallet/Credit_Wallet"
              component={CreditWallet}
            />
            <Route
              path="/reports/Driver_wallet/Cash_Wallet"
              component={CashWallet}
            />
            <Route
              path="/reports/Passenger_wallet"
              component={PassengerWallet}
            />
            <Route path="/reports/Document_expiry" component={DocumentExpiry} />
            <Route
              path="/reports/Driver_Referral_history"
              component={DriverRefersPax}
            />
            <Route
              path="/reports/financial/referral-details"
              component={ReferralDetail}
            />
            <Route
              path="/reports/Driver_Referral_history/Driver_refers_Pax"
              component={DriverRefersPax}
            />
            <Route
              path="/reports/Driver_Referral_history/Driver_refers_Pax/pax"
              component={ReferralPassengers}
            />
            <Route
              path="/reports/Driver_Referral_history/customers"
              component={ReferralPassengers}
            />
            <Route
              path="/reports/Driver_Referral_history/bookings"
              component={ReferralDetail}
            />
            <Route
              path="/reports/Passenger_refers_Passengers"
              component={PassengerRefersPassengers}
            />
            <Route
              path="/reports/Passenger_refers_Passengers/pax"
              component={PassengerRefersPassengersDetails}
            />
            <Route
              path="/reports/Driver_refers_drivers/Referrer"
              component={DriverRefersReferrer}
            />
            <Route
              path="/reports/Driver_refers_drivers/Referee"
              component={DriverRefersReferee}
            />
            <Route
              path="/reports/Driver_withdrawal"
              component={DriverWithdrawal}
            />
            <Route
              path="/reports/affiliation/penalty_compensation"
              component={PenaltyCompensation}
            />
            <Route
              path="/reports/affiliation/payout_history"
              component={AffiliationPayoutHistory}
            />
            <Route
              path="/reports/affiliation/payout_history/details"
              component={AffiliationPayoutHistoryDetails}
            />
            <Route
              path="/reports/incomplete_payment"
              component={IncompletePayment}
            />
            <Route path="/reports/refund" component={Refund} />
            <Route
              path="/reports/deletion_request"
              component={DeleteAccountRequest}
            />
            <Route path="/reports/redeemed" component={ReportVoucher} />
            <Route path="/reports/export" component={Export} />
            <Route path="/reports/quest" component={FirstWillWin} />
            <Route
              path="/reports/merchant/financial"
              component={ReportMerchant}
            />
            <Route
              path="/reports/merchant/cash"
              component={MerchantCashTransactionHistory}
            />
            <Route
              path="/reports/merchant/credit"
              component={MerchantCreditTransactionHistory}
            />
            <Route path="/notifications" component={Notifications} />
            <Route path="/sos" component={SOS} />
            <Route
              path="/signupnotifications"
              component={SignUpNotifications}
            />
          </Route>
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/profile" component={UserProfile} />
            <Route path="/vehicle" component={VehicleContainer} />
            <Route path="/vehicle/add" component={CreateVehicle} />
            <Route path="/vehicle/:vehicleId" component={DetailVehicle} />
        </Route>
        <Route path="/page-not-found" component={ErrorPage} />
        <Route path="*" component={ErrorPage} />
      </Router>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);
