/* global google */
import React, { Component, useState, useEffect, PureComponent } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from 'react-google-maps';
import _ from 'lodash';
import pickupIcon from './../../../assets/images/icons/delivery_pickup.svg';
import recipient_icon from './../../../assets/images/icons/delivery_recipient.svg';
import recipient1_icon from './../../../assets/images/icons/delivery_recipient_1.svg';
import recipient2_icon from './../../../assets/images/icons/delivery_recipient_2.svg';
import { getDirectionsWithoutETAMinDistance } from '../../../utils/mapUtils'


const GettingStartedGoogleMap = withGoogleMap(({ markerPickUps, markerDestinations, travelMode, onMapLoad, center, setDistanceDuration, auth }) => {
  const [directions, setDirections] = useState(null)
  const [bounds, setBounds] = useState(new google.maps.LatLngBounds())
  
  useEffect(() => {
    const directionsService = new google.maps.DirectionsService();
    let pointDirections = [...markerPickUps, ...markerDestinations].map((point) => {
      if(point.address.geo[1]) {
        setBounds(bounds.extend(new google.maps.LatLng(point.address.geo[1], point.address.geo[0])))
      }
      return {
        lat: point.address.geo[1],
        lng: point.address.geo[0]
      }
    })
    let directions = pointDirections.pop()
    let origin = pointDirections.shift()
    let waypoints = pointDirections.map((pointDirection) => (
      {location: pointDirection}
    ))

    let callback = (result) => {
      if (result) {
        setDirections(result)
        setDistanceDuration(result && result.routes[0] && result.routes[0].legs[0])
      } else {
        setDirections(null)
      }
    }
    getDirectionsWithoutETAMinDistance({
      origin: [origin.lat, origin.lng],
      waypoints,
      destination: [directions.lat, directions.lng],
      optimizeWaypoints: false,
      travelMode: travelMode === 'driving' ? travelMode.toUpperCase() : 'BICYCLING',
      callback,
      auth
    })
  }, []);

  return (
    <GoogleMap
      ref={onMapLoad}
      defaultZoom={14}
      center={center}
      options={{ gestureHandling: 'greedy' }}
      ref={map => {
        return map && map.fitBounds(bounds)
      }
    }
    >
      {markerPickUps.length > 0 && 
        markerPickUps.map((markerPickUp) => (
          <Marker
            position={{lat: markerPickUp.address.geo[1], lng: markerPickUp.address.geo[0]}}
            icon={pickupIcon}
            key={markerPickUp.address.geo[1]}
          />
        ))
      }
      {markerDestinations[0] && (
        <Marker
          position={{lat: markerDestinations[0].address.geo[1], lng: markerDestinations[0].address.geo[0]}}
          icon={(markerDestinations[1] ? recipient1_icon : recipient_icon)}
          key={markerDestinations[0].address.geo[1]}
        />
      )}
      {markerDestinations[1] && (
        <Marker
          position={{lat: markerDestinations[1].address.geo[1], lng: markerDestinations[1].address.geo[0]}}
          icon={recipient2_icon}
          key={markerDestinations[1].address.geo[1]}
        />
      )}
      {/* {directions && <DirectionsRenderer
            directions={directions}
            options={{ suppressMarkers: true}}
            preserveViewport={true}
      />} */}
    </GoogleMap>
  );
});

class DeliveryGGMap extends PureComponent {

  render() {
    const {
      pickups,
      destinations,
      onMapLoad,
      setDistanceDuration,
      fleetId
    } = this.props;
    return (
      <GettingStartedGoogleMap
        containerElement={<div className="fill booking-map" />}
        mapElement={<div style={{ height: `100%` }} />}
        setDistanceDuration={setDistanceDuration}
        onMapLoad={onMapLoad}
        travelMode={this.props.travelMode}
        markerPickUps={pickups}
        markerDestinations={destinations}
        fleetId={fleetId}
      />
    );
  }
}

export default DeliveryGGMap;
