import React from 'react';
import EliteTelecom from './eliteTelecom';
import Twilio from './twilio';
import Infobip from './infobip';
import ExE from './8x8';
import Africastalking from './africastalking';
import Mobitel from './mobitel';
import Ovh from './ovh';
import Oneway from './oneway';
import Ringcaptcha from './ringcaptcha';
import Mobi from './mobi';
import Plivo from './plivo';
import ISendPro from './iSendPro';
import H3techs from './h3techs';
import AdsmediaApp from './adsmediaApp';
import Dialog from './dialog';
import { SMS_INTERGRATIONS } from '../../../../constants/commondata';

const componentMap = {
  [SMS_INTERGRATIONS.SMSEliteTelecom]: EliteTelecom,
  [SMS_INTERGRATIONS.SMSTwilio]: Twilio,
  [SMS_INTERGRATIONS.SMSInfobip]: Infobip,
  [SMS_INTERGRATIONS.SMSExE]: ExE,
  [SMS_INTERGRATIONS.SMSAfricaTalking]: Africastalking,
  [SMS_INTERGRATIONS.SMSDialog]: Dialog,
  [SMS_INTERGRATIONS.SMSMobitel]: Mobitel,
  [SMS_INTERGRATIONS.SMSOvh]: Ovh,
  [SMS_INTERGRATIONS.SMSOneway]: Oneway,
  [SMS_INTERGRATIONS.SMSRingCaptcha]: Ringcaptcha,
  [SMS_INTERGRATIONS.SMSMobi]: Mobi,
  [SMS_INTERGRATIONS.SMSPlivo]: Plivo,
  [SMS_INTERGRATIONS.SMSiSendPro]: ISendPro,
  [SMS_INTERGRATIONS.SMSh3techs]: H3techs,
  [SMS_INTERGRATIONS.SMSAdsmediaApp]: AdsmediaApp,
};

const RenderFormSMS = (props) => {
  const { provider } = props;
  const Component = componentMap[provider];

  return Component ? <Component {...props} /> : null;
};

export default RenderFormSMS;