import React, { Fragment } from 'react';
import {
  FormGroup,
  Form,
  Tabs,
  Tab,
  InputGroup,
  FormControl,
  Label,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import currencyFormatter from 'currency-formatter';
import CcCheckbox from '../../../../../components/ccCheckbox/CcCheckbox';
import RadioButton from '../../../../../components/radioButton/radio';
import {
  Validation,
  textboxNumberHelper,
} from '../../../../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
  WarningCase,
} from '../../../../../components/validator';
import { DefaultCurenciesNumberInputField } from '../../../../../constants/commondata';

export default class TechFee extends React.Component {
  state = {
    currentFocus: {},
  };

  handleTechFeeCheckChange = (e) => {
    const { handleUpdateFormData } = this.props;
    handleUpdateFormData('techFeeActive', e.target.checked);
  };

  handlePaytoChange = (e) => {
    const value = parseInt(e.target.value || 0);
    const { handleUpdateFormData } = this.props;
    handleUpdateFormData('tollFeePayTo', value);
  };

  handeLimitDriverInputActiveChange = (e) => {
    const value = e.target.value == 'true';
    const { handleUpdateFormData, ValidatorCallback } = this.props;
    if (!value) {
      ValidatorCallback('tollFeeDriverCanInput', true);
    }
    handleUpdateFormData('tollFeeLimitDriverInputActive', value);
  };

  handleCancelInputMultiCurrenciesChange = (
    currency,
    e,
    keyName,
    nameChange
  ) => {
    const { handleUpdateFormData, formData } = this.props;
    let currentFieldValue = _.get(formData, keyName);
    let isAdd = true;
    let valueParseFloat = (e.target.value || '')
      .toString()
      .replace(/[^.0-9]+/, '');
    if (isNaN(valueParseFloat) || !valueParseFloat) valueParseFloat = 0;
    const cur = {
      currencyISO: currency,
      commandCenter: 0,
      paxApp: 0,
      webBooking: 0,
      mDispatcher: 0,
      partner: 0,
      carHailing: 0,
      kiosk: 0,
      api: 0,
      streetSharing: 0,
      [nameChange]: valueParseFloat,
    };
    currentFieldValue =
      currentFieldValue ||
      this.props.auth.selectedFleet.currencies.map((data) => ({
        commandCenter: 0,
        paxApp: 0,
        webBooking: 0,
        mDispatcher: 0,
        partner: 0,
        carHailing: 0,
        kiosk: 0,
        api: 0,
        streetSharing: 0,
        currencyISO: data.iso,
      }));
    currentFieldValue = currentFieldValue.map((c) => {
      if (c.currencyISO === currency) {
        isAdd = false;
        return { ...c, [nameChange]: valueParseFloat };
      }
      return c;
    });
    if (isAdd) {
      currentFieldValue.push(cur);
    }
    handleUpdateFormData(keyName, currentFieldValue);
  };

  getCurrencyValue = (currenciesAmount, currencyISO, keyName, nameValue) => {
    const currencyCurrent = _.find(
      currenciesAmount || [],
      (o) => o.currencyISO === currencyISO
    );
    if (currencyCurrent) {
      return this.parseInputValue(
        currencyCurrent[keyName],
        currencyISO,
        keyName,
        nameValue
      );
    }
    return 0;
  };

  setFocus = (key) => {
    const { currentFocus } = this.state;
    currentFocus[key] = true;
    this.setState({
      currentFocus,
    });
  };

  setUnFocus = (key) => {
    const { currentFocus } = this.state;
    currentFocus[key] = false;
    this.setState({
      currentFocus,
    });
  };

  parseInputValue = (value, currencyISO, keyName) => {
    const { currentFocus } = this.state;
    const isFocues = currentFocus[keyName];

    if (isFocues) {
      return (value || '').toString().replace(/[^.0-9]+/, '') || 0;
    }

    return currencyFormatter.format(value || 0, {
      code: currencyISO,
      format: '%v',
    });
  };

  formatCurrency = (amount) => {
    const VNDFormatter = Intl.NumberFormat('us-US');
    return VNDFormatter.format(amount);
  };

  renderMultiCurrency = (keyName) => {
    const { formData, editable, auth } = this.props;
    return (
      <Tabs
        id="currencies-tabs-Ondemand"
        className="currencies-tabs"
        defaultActiveKey={auth.selectedFleet.currencies[0].iso}
        animation={false}
        onSelect={this.tabSelectHandle}
      >
        {auth.selectedFleet.currencies.map((currencyCurrent) => {
          // const currentValue = formData.tollFeeDriverCanInput
          let techFeeData = _.get(formData, keyName, []);
          const currentField = techFeeData.find(
            (d) => d.currencyISO === currencyCurrent.iso
          );
          return (
            <Tab
              className="currencies-tab-item"
              eventKey={currencyCurrent.iso}
              title={currencyCurrent.iso}
              key={currencyCurrent.iso}
            >
              <>
                <FormGroup className="qup-input-group">
                  <Form.Label>Command center</Form.Label>
                  <InputGroup
                    className={`single-addon-left ${
                      editable ? 'disabled' : ''
                    }`}
                    >
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {currencyCurrent.symbol}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      className="form-custom no-marginBottom"
                      onChange={(e) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          currencyCurrent.iso,
                          e,
                          keyName,
                          'commandCenter'
                        );
                      }}
                      onBlur={(e) => {
                        this.setUnFocus('commandCenter');
                        textboxNumberHelper.onBlurHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'commandCenter'
                          );
                        });
                      }}
                      onFocus={(e) => {
                        this.setFocus('commandCenter');
                        textboxNumberHelper.onfocusHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'commandCenter'
                          );
                        });
                      }}
                      value={
                        currentField
                          ? currentField['commandCenter']
                          : DefaultCurenciesNumberInputField
                      }
                      // value={this.getCurrencyValue(currentField, currencyCurrent.iso, 'commandCenter')}
                      disabled={editable}
                    />
                    {' '}
                  </InputGroup>
                </FormGroup>
                <FormGroup className="qup-input-group">
                  <Form.Label>
                    {I18n.t('generalSetting.customerApp')}
                  </Form.Label>
                  <InputGroup
                    className={`single-addon-left ${
                      editable ? 'disabled' : ''
                    }`}
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {currencyCurrent.symbol}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      className="form-custom no-marginBottom"
                      onChange={(e) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          currencyCurrent.iso,
                          e,
                          keyName,
                          'paxApp'
                        );
                      }}
                      onBlur={(e) => {
                        this.setUnFocus('paxApp');
                        textboxNumberHelper.onBlurHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'paxApp'
                          );
                        });
                      }}
                      onFocus={(e) => {
                        this.setFocus('paxApp');
                        textboxNumberHelper.onfocusHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'paxApp'
                          );
                        });
                      }}
                      value={
                        currentField
                          ? currentField['paxApp']
                          : DefaultCurenciesNumberInputField
                      }
                      // value={this.getCurrencyValue(currentField, currencyCurrent.iso, 'paxApp')}
                      disabled={editable}
                    />
                    {' '}
                  </InputGroup>
                </FormGroup>
                <FormGroup className="qup-input-group">
                  <Form.Label>Web booking</Form.Label>
                  <InputGroup
                    className={`single-addon-left ${
                      editable ? 'disabled' : ''
                    }`}
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {currencyCurrent.symbol}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      className="form-custom no-marginBottom"
                      onChange={(e) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          currencyCurrent.iso,
                          e,
                          keyName,
                          'webBooking'
                        );
                      }}
                      onBlur={(e) => {
                        this.setUnFocus('webBooking');
                        textboxNumberHelper.onBlurHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'webBooking'
                          );
                        });
                      }}
                      onFocus={(e) => {
                        this.setFocus('webBooking');
                        textboxNumberHelper.onfocusHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'webBooking'
                          );
                        });
                      }}
                      // value={this.getCurrencyValue(currentField, currencyCurrent.iso, 'webBooking')}
                      value={
                        currentField
                          ? currentField['webBooking']
                          : DefaultCurenciesNumberInputField
                      }
                      disabled={editable}
                    />
                    {' '}
                  </InputGroup>
                </FormGroup>
                <FormGroup className="qup-input-group">
                  <Form.Label>Kiosk app</Form.Label>
                  <InputGroup
                    className={`single-addon-left ${
                      editable ? 'disabled' : ''
                    }`}
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {currencyCurrent.symbol}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      className="form-custom no-marginBottom"
                      onChange={(e) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          currencyCurrent.iso,
                          e,
                          keyName,
                          'kiosk'
                        );
                      }}
                      onBlur={(e) => {
                        this.setUnFocus('kiosk');
                        textboxNumberHelper.onBlurHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'kiosk'
                          );
                        });
                      }}
                      onFocus={(e) => {
                        this.setFocus('kiosk');
                        textboxNumberHelper.onfocusHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'kiosk'
                          );
                        });
                      }}
                      value={
                        currentField
                          ? currentField['kiosk']
                          : DefaultCurenciesNumberInputField
                      }
                      disabled={editable}
                    />
                    {' '}
                  </InputGroup>
                </FormGroup>
                <FormGroup className="qup-input-group">
                  <Form.Label>Partner app</Form.Label>
                  <InputGroup
                    className={`single-addon-left ${
                      editable ? 'disabled' : ''
                    }`}
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {currencyCurrent.symbol}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      className="form-custom no-marginBottom"
                      onChange={(e) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          currencyCurrent.iso,
                          e,
                          keyName,
                          'partner'
                        );
                      }}
                      onBlur={(e) => {
                        this.setUnFocus('partner');
                        textboxNumberHelper.onBlurHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'partner'
                          );
                        });
                      }}
                      onFocus={(e) => {
                        this.setFocus('partner');
                        textboxNumberHelper.onfocusHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'partner'
                          );
                        });
                      }}
                      value={
                        currentField
                          ? currentField['partner']
                          : DefaultCurenciesNumberInputField
                      }
                      disabled={editable}
                    />
                    {' '}
                  </InputGroup>
                </FormGroup>
                <FormGroup className="qup-input-group">
                  <Form.Label>Street hailing</Form.Label>
                  <InputGroup
                    className={`single-addon-left ${
                      editable ? 'disabled' : ''
                    }`}
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {currencyCurrent.symbol}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      className="form-custom no-marginBottom"
                      onChange={(e) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          currencyCurrent.iso,
                          e,
                          keyName,
                          'carHailing'
                        );
                      }}
                      onBlur={(e) => {
                        this.setUnFocus('carHailing');
                        textboxNumberHelper.onBlurHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'carHailing'
                          );
                        });
                      }}
                      onFocus={(e) => {
                        this.setFocus('carHailing');
                        textboxNumberHelper.onfocusHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'carHailing'
                          );
                        });
                      }}
                      value={
                        currentField
                          ? currentField['carHailing']
                          : DefaultCurenciesNumberInputField
                      }
                      disabled={editable}
                    />
                    {' '}
                  </InputGroup>
                </FormGroup>
                <FormGroup className="qup-input-group">
                  <Form.Label>API</Form.Label>
                  <InputGroup
                    className={`single-addon-left ${
                      editable ? 'disabled' : ''
                    }`}
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {currencyCurrent.symbol}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      className="form-custom no-marginBottom"
                      onChange={(e) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          currencyCurrent.iso,
                          e,
                          keyName,
                          'api'
                        );
                      }}
                      onBlur={(e) => {
                        this.setUnFocus('api');
                        textboxNumberHelper.onBlurHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'api'
                          );
                        });
                      }}
                      onFocus={(e) => {
                        this.setFocus('api');
                        textboxNumberHelper.onfocusHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'api'
                          );
                        });
                      }}
                      value={
                        currentField
                          ? currentField['api']
                          : DefaultCurenciesNumberInputField
                      }
                      disabled={editable}
                    />
                    {' '}
                  </InputGroup>
                </FormGroup>
                <FormGroup className="qup-input-group">
                  <Form.Label>Street Sharing</Form.Label>
                  <InputGroup
                    className={`single-addon-left ${
                      editable ? 'disabled' : ''
                    }`}
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {currencyCurrent.symbol}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      className="form-custom no-marginBottom"
                      onChange={(e) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          currencyCurrent.iso,
                          e,
                          keyName,
                          'streetSharing'
                        );
                      }}
                      onBlur={(e) => {
                        this.setUnFocus('streetSharing');
                        textboxNumberHelper.onBlurHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'streetSharing'
                          );
                        });
                      }}
                      onFocus={(e) => {
                        this.setFocus('streetSharing');
                        textboxNumberHelper.onfocusHandle(e, (event) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            currencyCurrent.iso,
                            event,
                            keyName,
                            'streetSharing'
                          );
                        });
                      }}
                      value={
                        currentField
                          ? currentField['streetSharing']
                          : DefaultCurenciesNumberInputField
                      }
                      disabled={editable}
                    />
                    {' '}
                  </InputGroup>
                </FormGroup>
              </>
            </Tab>
          );
        })}
      </Tabs>
    );
  };

  renderInputCurrency = (keyName) => {
    const { formData, editable, auth, currency } = this.props;
    const currencyCurrent = currency || auth.selectedFleet.currencies[0];
    const currentField = _.get(formData, keyName);
    return (
      <>
        <FormGroup className="qup-input-group">
          <Form.Label>Command center</Form.Label>
          <InputGroup
            className={`single-addon-left ${editable ? 'disabled' : ''}`}
          >
            <InputGroup.Prepend>
              <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              className="form-custom no-marginBottom"
              onChange={(e) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  e,
                  keyName,
                  'commandCenter'
                );
              }}
              onBlur={(e) => {
                this.setUnFocus('commandCenter');
                textboxNumberHelper.onBlurHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'commandCenter'
                  );
                });
              }}
              onFocus={(e) => {
                this.setFocus('commandCenter');
                textboxNumberHelper.onfocusHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'commandCenter'
                  );
                });
              }}
              value={this.getCurrencyValue(
                currentField,
                currencyCurrent.iso,
                'commandCenter'
              )}
              disabled={editable}
            />
            {' '}
          </InputGroup>
        </FormGroup>
        <FormGroup className="qup-input-group">
          <Form.Label>{I18n.t('generalSetting.customerApp')}</Form.Label>
          <InputGroup
            className={`single-addon-left ${editable ? 'disabled' : ''}`}
          >
            <InputGroup.Prepend>
              <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              className="form-custom no-marginBottom"
              onChange={(e) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  e,
                  keyName,
                  'paxApp'
                );
              }}
              onBlur={(e) => {
                this.setUnFocus('paxApp');
                textboxNumberHelper.onBlurHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'paxApp'
                  );
                });
              }}
              onFocus={(e) => {
                this.setFocus('paxApp');
                textboxNumberHelper.onfocusHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'paxApp'
                  );
                });
              }}
              value={this.getCurrencyValue(
                currentField,
                currencyCurrent.iso,
                'paxApp'
              )}
              disabled={editable}
            />
            {' '}
          </InputGroup>
        </FormGroup>
        <FormGroup className="qup-input-group">
          <Form.Label>Web booking</Form.Label>
          <InputGroup
            className={`single-addon-left ${editable ? 'disabled' : ''}`}
          >
            <InputGroup.Prepend>
              <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              className="form-custom no-marginBottom"
              onChange={(e) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  e,
                  keyName,
                  'webBooking'
                );
              }}
              onBlur={(e) => {
                this.setUnFocus('webBooking');
                textboxNumberHelper.onBlurHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'webBooking'
                  );
                });
              }}
              onFocus={(e) => {
                this.setFocus('webBooking');
                textboxNumberHelper.onfocusHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'webBooking'
                  );
                });
              }}
              value={this.getCurrencyValue(
                currentField,
                currencyCurrent.iso,
                'webBooking'
              )}
              disabled={editable}
            />
            {' '}
          </InputGroup>
        </FormGroup>
        <FormGroup className="qup-input-group">
          <Form.Label>Kiosk app</Form.Label>
          <InputGroup
            className={`single-addon-left ${editable ? 'disabled' : ''}`}
          >
            <InputGroup.Prepend>
              <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              className="form-custom no-marginBottom"
              onChange={(e) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  e,
                  keyName,
                  'kiosk'
                );
              }}
              onBlur={(e) => {
                this.setUnFocus('kiosk');
                textboxNumberHelper.onBlurHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'kiosk'
                  );
                });
              }}
              onFocus={(e) => {
                this.setFocus('kiosk');
                textboxNumberHelper.onfocusHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'kiosk'
                  );
                });
              }}
              value={this.getCurrencyValue(
                currentField,
                currencyCurrent.iso,
                'kiosk'
              )}
              disabled={editable}
            />
            {' '}
          </InputGroup>
        </FormGroup>
        <FormGroup className="qup-input-group">
          <Form.Label>Partner app</Form.Label>
          <InputGroup
            className={`single-addon-left ${editable ? 'disabled' : ''}`}
          >
            <InputGroup.Prepend>
              <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              className="form-custom no-marginBottom"
              onChange={(e) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  e,
                  keyName,
                  'partner'
                );
              }}
              onBlur={(e) => {
                this.setUnFocus('partner');
                textboxNumberHelper.onBlurHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'partner'
                  );
                });
              }}
              onFocus={(e) => {
                this.setFocus('partner');
                textboxNumberHelper.onfocusHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'partner'
                  );
                });
              }}
              value={this.getCurrencyValue(
                currentField,
                currencyCurrent.iso,
                'partner'
              )}
              disabled={editable}
            />
            {' '}
          </InputGroup>
        </FormGroup>
        <FormGroup className="qup-input-group">
          <Form.Label>Street hailing</Form.Label>
          <InputGroup
            className={`single-addon-left ${editable ? 'disabled' : ''}`}
          >
            <InputGroup.Prepend>
              <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              className="form-custom no-marginBottom"
              onChange={(e) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  e,
                  keyName,
                  'carHailing'
                );
              }}
              onBlur={(e) => {
                this.setUnFocus('carHailing');
                textboxNumberHelper.onBlurHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'carHailing'
                  );
                });
              }}
              onFocus={(e) => {
                this.setFocus('carHailing');
                textboxNumberHelper.onfocusHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'carHailing'
                  );
                });
              }}
              value={this.getCurrencyValue(
                currentField,
                currencyCurrent.iso,
                'carHailing'
              )}
              disabled={editable}
            />
            {' '}
          </InputGroup>
        </FormGroup>
        <FormGroup className="qup-input-group">
          <Form.Label>API</Form.Label>
          <InputGroup
            className={`single-addon-left ${editable ? 'disabled' : ''}`}
          >
            <InputGroup.Prepend>
              <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              className="form-custom no-marginBottom"
              onChange={(e) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  e,
                  keyName,
                  'api'
                );
              }}
              onBlur={(e) => {
                this.setUnFocus('api');
                textboxNumberHelper.onBlurHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'api'
                  );
                });
              }}
              onFocus={(e) => {
                this.setFocus('api');
                textboxNumberHelper.onfocusHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'api'
                  );
                });
              }}
              value={this.getCurrencyValue(
                currentField,
                currencyCurrent.iso,
                'api'
              )}
              disabled={editable}
            />
            {' '}
          </InputGroup>
        </FormGroup>
        <FormGroup className="qup-input-group">
          <Form.Label>Street Sharing</Form.Label>
          <InputGroup
            className={`single-addon-left ${editable ? 'disabled' : ''}`}
          >
            <InputGroup.Prepend>
              <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              className="form-custom no-marginBottom"
              onChange={(e) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  e,
                  keyName,
                  'streetSharing'
                );
              }}
              onBlur={(e) => {
                this.setUnFocus('streetSharing');
                textboxNumberHelper.onBlurHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'streetSharing'
                  );
                });
              }}
              onFocus={(e) => {
                this.setFocus('streetSharing');
                textboxNumberHelper.onfocusHandle(e, (event) => {
                  this.handleCancelInputMultiCurrenciesChange(
                    currencyCurrent.iso,
                    event,
                    keyName,
                    'streetSharing'
                  );
                });
              }}
              value={this.getCurrencyValue(
                currentField,
                currencyCurrent.iso,
                'streetSharing'
              )}
              disabled={editable}
            />
            {' '}
          </InputGroup>
        </FormGroup>
      </>
    );
  };

  renderLimitDriverCanInput = () => {
    const { formData, auth, currency } = this.props;
    if (formData.techFeeActive) {
      return auth.selectedFleet.multiCurrencies && !currency
        ? this.renderMultiCurrency('techFeeByCurrencies')
        : this.renderInputCurrency('techFeeByCurrencies');
    }
    return null;
  };

  render() {
    const { editable, formData, ValidatorCallback, isSubmited, valid, auth } =
      this.props;
    let { tollFeeDriverCanInput } = formData;
    if (!tollFeeDriverCanInput || !tollFeeDriverCanInput.length) {
      tollFeeDriverCanInput = [auth.selectedFleet.currencies[0]];
    }

    return (
      <Fragment>
        <CcCheckbox
          disabled={editable}
          checked={formData ? formData.techFeeActive : false}
          onChange={this.handleTechFeeCheckChange}
          text={I18n.t('generalSetting.techFee')}
          labelClassName="title"
          className="form-group-title"
        />
        {formData.techFeeActive ? (
          <Fragment>
            {formData.techFeeActive ? (
              <FormGroup
                className={`sub-controls ${
                  !isSubmited
                    ? null
                    : valid.tollFeeDriverCanInput === false
                    ? 'error'
                    : null
                }`}
              >
                {this.renderLimitDriverCanInput()}
                {/* <Validator id="tollFeeDriverCanInput" callback={ValidatorCallback}>
                  <ValidCase
                    valid={
                      tollFeeDriverCanInput.filter(
                        d => !Validation.isStringEmpty(d.value) && _.isNaN(d.value)
                      ).length === 0
                    }
                    message={I18n.t('messages.commonMessages.must_be_number')}
                    hide={!isSubmited}
                  />
                  <ValidCase
                    valid={
                      tollFeeDriverCanInput.filter(d => !Validation.isGreaterThan(d.value, 0))
                        .length === 0
                    }
                    message={I18n.t('messages.commonMessages.greater_than').format(0)}
                    hide={!isSubmited}
                  />
                  <ValidCase
                    valid={
                      tollFeeDriverCanInput.filter(
                        d => !Validation.isLessOrEqual(d.value, 9999999999)
                      ).length === 0
                    }
                    message={I18n.t('messages.commonMessages.less_or_equal').format(
                      this.formatCurrency(9999999999)
                    )}
                    hide={!isSubmited}
                  />
                </Validator>
                <WarningCase
                  validator={valid.tollFeeDriverCanInput}
                  message={I18n.t('message.warningInputChangeOver')}
                  initialValue={tollFeeDriverCanInput.map((ob) => ob.value)}
                  onChangeValue={tollFeeDriverCanInput.map((ob) => ob.value)}
                  range={20}
                  typePercent= {false}
                /> */}
              </FormGroup>
            ) : null}
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}
