import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import { Translate, I18n } from 'react-redux-i18n';
import PaymentMethodSelect from './PaymentMethodSelect';
import CreditSelect from './CreditSelect';
import { FormGroup, FormControl } from 'react-bootstrap';

class LocationInfoForm extends PureComponent {
  handleChangePaymentMethod = (value) => {
    this.props.onChange('paymentType', value);
  };

  handleChangeOperatorNote = (e) => {
    this.props.handleChangeOperatorNote(e);
  };

  handleChangeCredit = (value) => {
    this.props.onChangePsgInfo({creditInfo: value});
  };

  handleAddNewCustomerSuccess = (newCustomer) => {
    this.props.onChangePsgInfo({
      ...this.props.psgInfo,
      userId: newCustomer.userId,
      firstname: newCustomer.firstName,
      lastname: newCustomer.lastName,
      phone: newCustomer.phone,
      email: newCustomer.email,
      isCustomerVip: !!newCustomer.rank
    })
  }

  render() {
    const { request, psgInfo, valid, isSubmited, validatorCallback, isBookDetail, handleChangeBookingReference, externalInfo } = this.props;
    return (
      <Fragment>
        <FormGroupTitle>
          <Translate value="newbooking.PAYMENT_METHOD" />
        </FormGroupTitle>
        <PaymentMethodSelect
          value={request.paymentType}
          onChange={this.handleChangePaymentMethod}
          valid={valid}
          isSubmited={isSubmited}
          validatorCallback={validatorCallback}
          disabled={isBookDetail}
        />
        <CreditSelect
          isHide={request.paymentType !== 2}
          value={psgInfo.creditInfo}
          psgInfo={psgInfo}
          onChange={this.handleChangeCredit}
          onAddNewCustomer={this.handleAddNewCustomerSuccess}
          valid={valid}
          isSubmited={isSubmited}
          validatorCallback={validatorCallback}
          disabled={isBookDetail}
          externalInfo={externalInfo}
          handleChangeBookingReference={handleChangeBookingReference}
        />
        <FormGroupTitle>
          <Translate value="newbooking.OPERATOR_NOTE" />
        </FormGroupTitle>
        <FormGroup>
          <FormControl
            type="text"
            as="textarea"
            onChange={this.handleChangeOperatorNote}
            value={request.operatorNote}
            rows={4}
            maxLength={1000}
            className={"form-custom"}
            // placeholder={I18n.t("newbooking.Note")}
            placeholder={I18n.t("newbooking.operator_note")}
          />
        </FormGroup>
      </Fragment>
    );
  }
}


export default LocationInfoForm;
