import React, { Component } from 'react';
import currencyFormatter from 'currency-formatter';
import { I18n, Translate } from 'react-redux-i18n';
import _, { trimEnd } from 'lodash';
import {
  CCLiteCommonFunc,
  roundOff,
  checkEnableEditDriverEarning,
  calculatorAmountDue,
  checkShowInfoPrePaid,
} from '../../../utils/commonFunctions';
import {
  NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD,
  BOOK_TYPE_FEE,
  PAYOUT_CUSTOM_TYPE,
  PAYOUT_TBD,
} from '../../../constants/commondata';
import EditFare from './EditFareComponent';
import FareDeeplink from './FareDeeplink';
import { calculatorPayoutWhenCustom } from '../bookFunction/bookingInfo';
class TripEstimateDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowEditFare: false,
      allowMarkupPrice: false
    };
  }

  componentDidMount() {
    if (this.props.bookingPermission) {
      const editFare = this.props.bookingPermission.find(item => item.name === "EditFare") || {};
      const { status, request = {} } = this.props.data;
      //if (!drvInfo || !drvInfo.userId) { 
      if (status != 'arrived' && status != 'delivering' && status != 'booked' && status != 'engaged' && status != 'droppedOff') {
        if (!request.promoCustomerType || request.promoCustomerType !== "referral") {
          this.setState({
            allowEditFare: this.props.selectedFleet.generalSetting.editBookingFare && editFare.isActive,
            allowMarkupPrice: this.props.selectedFleet.generalSetting.markupPrice && editFare.isActive &&
              !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(parseInt(this.props.data.paymentMethod)) &&
              !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(parseInt(request.paymentType))
          });
        }
      }
      this.getServiceFeeByZone();
      //};
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.promo !== prevProps.promo) {
      if (this.props.bookingPermission) {
        const editFare = this.props.bookingPermission.find(item => item.name === "EditFare") || {};
        const { status, request = {} } = this.props.data;
        //if (!drvInfo || !drvInfo.userId) {
        if (status != 'arrived' && status != 'delivering' && status != 'booked' && status != 'engaged' && status != 'droppedOff') {
          if (this.props.promo && this.props.promo.promoCustomerType && this.props.promo.promoCustomerType === "referral" && !request.promoCustomerType) {
            this.setState({
              allowEditFare: this.props.selectedFleet.generalSetting.editBookingFare && editFare.isActive,
              allowMarkupPrice: this.props.selectedFleet.generalSetting.markupPrice && editFare.isActive &&
                !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(parseInt(this.props.data.paymentMethod))
            });
          }
        }
        //};
      };
    } else if (this.props.data.paymentMethod !== prevProps.data.paymentMethod || (this.props.data.request && this.props.data.request.paymentType !== prevProps.data.request.paymentType)) {
      if (this.props.bookingPermission) {
        const editFare = this.props.bookingPermission.find(item => item.name === "EditFare") || {};
        const { status } = this.props.data;
        //if (!drvInfo || !drvInfo.userId) {
        if (status != 'arrived' && status != 'delivering' && status != 'booked' && status != 'engaged' && status != 'droppedOff') {
          this.setState({
            allowMarkupPrice: this.props.selectedFleet.generalSetting.markupPrice && editFare.isActive &&
              !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(parseInt(this.props.data.paymentMethod)) &&
              (!this.props.data.request || !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(parseInt(this.props.data.request.paymentType)))
          });
        }
        //};
      };
    }
  }

  getServiceFeeByZone = () => {
    const { fleetId } = this.props.selectedFleet;
    if (!this.props.data?.request?.pickup?.zoneId) {
      return;
    }
    this.props.settingActions
      .getServiceFeesByZone({
        fleetId: fleetId,
        zoneId: this.props.data?.request?.pickup?.zoneId,
      })
      .then((data) => {
        this.setState({ serviceFeeZone: data.res });
      });
  };
  
  renderEstimatedFare = () => {
    const { data, selectedFleet } = this.props;

    const markupDifference = data.request.estimate.fare.markupDifference || 0;
    // SL-9047 [New CC (N)] Show fare on CC when Driver already dropped off
    if (data.status === 'droppedOff' && _.get(data, 'droppedOffInfo.total')) {
      const total = _.get(data, 'droppedOffInfo.total');
      const totalRoundOff = roundOff(total, data.currencyISO, selectedFleet.rounding);
      return total ? currencyFormatter.format(totalRoundOff, { code: data.currencyISO }) : '';
    }

    // if home booking or local booking, show estimated fare
    if (data.pricingType === 0 || data.request.psgFleetId === selectedFleet.fleetId) {
      const etaFareRoundOff = roundOff(_.get(data, 'request.estimate.fare.etaFare', 0) + markupDifference, data.currencyISO, selectedFleet.rounding);
      return data.request.estimate.fare && !_.isEmpty(data.request.estimate.fare)
        ? currencyFormatter.format(etaFareRoundOff, { code: data.currencyISO })
        : '';
    }
    // if provider booking , show estimated but fare
    return data.request.estimate.fare.estimateFareBuy
      && !_.isEmpty(data.request.estimate.fare.estimateFareBuy)
      ? currencyFormatter.format(data.request.estimate.fare.estimateFareBuy.etaFare + markupDifference, {
        code: data.currencyISO
      })
      : '';
  };

  renderDriverEarningUIBookingDetail = () => {
    const { data, auth, bookingPermission = [] } = this.props;
    const enableEditDriverEarning = checkEnableEditDriverEarning(auth, bookingPermission)
    const fareBookingDetail = _.get(data, 'request.estimate.fare', {})
    return (
      <>
        {enableEditDriverEarning && (
          <div className="item">
            <Translate value="supplier.totalPayout" />
            <span
              className={
                fareBookingDetail.addOnPrice && fareBookingDetail.addOnPrice !== 0
                  ? fareBookingDetail.addOnPrice > 0
                    ? 'adjust-detail'
                    : 'adjust-slow-detail'
                  : null
              }
            >
              <div className="driverEarningCT">
                <div>
                  <p style={{ marginBottom: '0px' }}>
                    {fareBookingDetail.supplierEarningType !==
                      PAYOUT_CUSTOM_TYPE.default &&
                      currencyFormatter.format(
                        calculatorPayoutWhenCustom(fareBookingDetail, this.state.serviceFeeZone, this.props.fareSettings?.fare, this.props.selectedFleet),
                        { code: fareBookingDetail.currencyISO }
                      )}
                  </p>
                  <p
                    className={
                      fareBookingDetail.supplierEarningType !==
                      PAYOUT_CUSTOM_TYPE.default
                        ? 'subFare'
                        : ''
                    }
                  >
                    {data?.supplierCompanies?.length != 1 && !this.props.driver?.phone && fareBookingDetail.supplierEarningType === 'default'
                      ? PAYOUT_TBD
                      :
                        (!this.props?.driver?.phone && 
                          (
                            this.props.commonData?.companies?.find(item => item._id === data?.supplierCompanies[0])
                            || this.props.commonData?.suppliers?.find(item => item._id === data?.supplierCompanies[0])?.commissionCompanyType === 'payToDriver'
                          )
                        )
                        ? PAYOUT_TBD :
                        currencyFormatter.format(
                          calculatorPayoutWhenCustom(
                            {...fareBookingDetail, supplierEarningType: 'default'}, 
                            Object.keys(this.props.serviceFeeZone || {}).length ? this.props.serviceFeeZone : this.state.serviceFeeZone, 
                            this.props.fareSettings?.fare || this.state.fareSettings?.fare, 
                            this.props.auth.selectedFleet, 
                            this.props.commonData?.suppliers?.find(item => item._id === (data?.supplierCompanies[0] || this.props.driver?.company?.companyId)),
                            BOOK_TYPE_FEE.parcel,
                            this.props.fareSettings || this.state.fareSettings,
                            this.props.driver,
                          ),
                          { code: fareBookingDetail?.currencyISO }
                        )
                      }
                  </p>
                </div>
              </div>
            </span>
          </div>
        )}
      </>
    )
  }

  renderAmountDueStatusInvoicing = () => {
    const { data } = this.props;
    const amountDue = calculatorAmountDue(data, CCLiteCommonFunc.isBookingStatusCompleted(data.status))
    const status = data.paidStatus || PAID_STATUS.pending;
    return (
      <>
        <div className="item">
          <Translate value="newbooking.AmountDue" />
          <span>{currencyFormatter.format(amountDue, {
            code: data.currencyISO,})}
          </span>
        </div>
        <div className="item">
          <Translate value="newbooking.Status" />
          <span className={`bk-${status} btnStatus`}>
            {I18n.t(`bookingdetail.${status}`)}
          </span>
        </div>
      </>
    );
  };

  getTextWarningRateNotAvailable = () => {
    const {
      newBooking, data, user, locationPickUp, locationDestination, cartypeSelected
    } = this.props;
    const supportedRates = _.get(newBooking, 'locationService.supportedRates') || {};
    let warningText = '';

    // not apply for hydra booking
    if (data.pricingType === 1 || !locationPickUp || !locationDestination) {
      return null;
    }
    // Cartype was NOT assigned any rate
    if (!supportedRates.flatFare) {
      if (!supportedRates.delivery || !cartypeSelected) {
        if (user.userType === 'CorporateUser' || user.userType === 'CorporateAdmin') {
          return <div>&nbsp;</div>;
        }
  
        warningText = I18n.t('newbooking.Warning_assigned_rate');
      }
    }

    return warningText ? (
      <div className="trip-estimate-container">
        <div className="estimate-header">
          <div className="estimate-title">
            <Translate value="newbooking.TRIP_ESTIMATE" />
          </div>
        </div>
        <span className="white-text">{warningText}</span>
      </div>
    ) : null;
  };

  // renderWarningOutstanding = () => {
  //   const enableOutstandingPayment = _.get(
  //     this.props.selectedFleet,
  //     'outstandingPayment.enable',
  //     false
  //   );
  //   const outStandingAmount = _.get(this.props.data, 'outStanding', []);
  //   if (enableOutstandingPayment && outStandingAmount.length > 0) {
  //     return (
  //       <p className="text-outstanding-warning">
  //         {I18n.t('newbooking.Warning_outstanding_balance')}
  //       </p>
  //     );
  //   }
  // }

  renderNewBooking = () => {
    const warningText = this.getTextWarningRateNotAvailable();
    if (warningText) {
      return warningText;
    }
    const {
      etaFare, disDur, locationPickUp, locationDestination, selectedFleet, data, promo, doPoints, reasonMarkup, markupType, markupValue, bookingPermission
    } = this.props;
    const { allowEditFare, allowMarkupPrice } = this.state;
    const permissionShowFare = bookingPermission.find(ob => ob.name === 'ShowFare') || {}
    const isShowFare = _.get(permissionShowFare, 'isActive', true)
    const unit = selectedFleet.unitDistance;
    let distanText = disDur ? disDur.distance.text : '';
    distanText = distanText.replace('mi', '').replace('km', '');
    distanText = `${distanText} ${unit}`;
    if (etaFare && !_.isEmpty(etaFare)) {
      let promoInfo = {};
      let promoValue = 0;
      if (promo) {
        promoInfo = { value: promo.value, type: promo.type, maximumValue: promo.maximumValue }
        if (promoInfo.type.toLowerCase() === 'percent') {
          promoValue = (parseFloat(etaFare.deliveryFee) * parseFloat(promoInfo.value)) / 100;
        } else {
          promoValue = promoInfo.value;
        }
      }
      const costOfGoods = doPoints.reduce((a, b) => a + ((b.recipient && b.recipient.amount) ? parseFloat(b.recipient.amount) : 0), 0);
      return (
        <div className="trip-estimate-container-delivery">
          <div className="estimate-header">
            <div className="estimate-title">
              <Translate value="newbooking.TRIP_ESTIMATE" />
              <FareDeeplink 
                fareInfo={etaFare} 
              />
            </div>
          </div>
          <div className="estimateInfo">
            {locationPickUp && locationDestination && disDur && (
              <div className="item">
                <Translate value="newbooking.Distance" />
                <span>{distanText}</span>
              </div>
            )}
            {locationPickUp && locationDestination && disDur && (
              <div className="item">
                <Translate value="newbooking.Duration" />
                <span>{disDur.duration.text}</span>
              </div>
            )}
            {costOfGoods ?
              <div className="item">
                <Translate value="newbooking.Cost_of_Goods" />
                <span>{currencyFormatter.format(costOfGoods, { code: etaFare.currencyISO })}</span>
              </div>
              : null
            }
            <div className="divider" />
            <div className="item">
              <Translate value="newbooking.Delivery_Fee" />
              <span>{currencyFormatter.format(etaFare.deliveryFee, { code: etaFare.currencyISO })}</span>
            </div>
            {etaFare.itemsFee ?
              <div className="item">
                <Translate value="newbooking.Item_fees" />
                <span>{currencyFormatter.format(etaFare.itemsFee, { code: etaFare.currencyISO })}</span>
              </div> : null}
            {selectedFleet.techFeeActive &&
              <div className="item">
                <Translate value="newbooking.Tech_Fee" />
                <span>{currencyFormatter.format(etaFare.techFee, { code: etaFare.currencyISO })}</span>
              </div>}
            {selectedFleet.fleetFareId.taxActive &&
              <div className="item">
                <Translate value="newbooking.Tax" />
                <span>{currencyFormatter.format(etaFare.tax, { code: etaFare.currencyISO })}</span>
              </div>}
            {promoValue ?
              <div className="item">
                <Translate value="newbooking.PromoTitle" className="promotion-value" />
                <div>
                  <span className="promotion-value">
                    {currencyFormatter.format(promoValue, {
                      code: etaFare.currencyISO
                    })}
                  </span>
                </div>
              </div> : null}
              {isShowFare && (
                <>
                  {allowEditFare || allowMarkupPrice ? (
                    <EditFare
                      etaFare={etaFare}
                      reasonMarkup={reasonMarkup}
                      markupType={markupType}
                      markupValue={markupValue}
                      promoInfo={promoInfo}
                      allowEditFare={allowEditFare}
                      locationPickUp={this.props.locationPickUp}
                      allowMarkupPrice={allowMarkupPrice}
                      data={data}
                      prevEtaFare={this.props.prevEtaFare}
                      selectedFleet={selectedFleet}
                      handleChangeETAFare={this.props.handleChangeETAFare}
                      handleChangeMarkupPrice={this.props.handleChangeMarkupPrice}
                      bookType={BOOK_TYPE_FEE.parcel}
                      serviceFeeZone={this.state.serviceFeeZone || {}}
                      supplierCompanies={data.supplierCompanies || []}
                      driver={this.props.driver}
                    />
                  ) : (
                    <div className="item">
                      <Translate value="newbooking.Fare" />
                      <div>
                        <span
                          className={
                            etaFare.addOnPrice && etaFare.addOnPrice !== 0
                              ? etaFare.addOnPrice > 0
                                ? 'adjust-detail'
                                : 'adjust-slow-detail'
                              : null
                          }
                        >
                          {currencyFormatter.format(etaFare.etaFare, {
                            code: etaFare.currencyISO,
                          })}{' '}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* {this.renderWarningOutstanding()} */}
          </div>
        </div>
      );
    }
    return '';
  }

  renderBookingDetail = () => {
    const { disDur, data, promo, selectedFleet, locationPickUp, locationDestination, prevEtaFare, bookingPermission = [] } = this.props;
    const { allowEditFare, allowMarkupPrice } = this.state;

    const isShowInfoPrePaid = checkShowInfoPrePaid(this.props.data)
    
    const warningText = this.getTextWarningRateNotAvailable();
    if (warningText) {
      return warningText;
    }
    const permissionShowFare = bookingPermission.find(ob => ob.name === 'ShowFare') || {}
    const isShowFare = _.get(permissionShowFare, 'isActive', true)
    const unit = selectedFleet.unitDistance;
    let distanText = disDur ? disDur.distance.text : data.request.estimate.distance;
    distanText = distanText ? distanText.replace('mi', '').replace('km', '') : "";
    distanText = `${distanText} ${unit}`;

    if (data.request && data.request.estimate.fare && !_.isEmpty(data.request.estimate.fare)) {
      const etaFare = data.request.estimate.fare;

      let promoInfo = {};
      let promoValue = 0;
      if (promo && promo.type) {
        promoInfo = { value: promo.value, type: promo.type, maximumValue: promo.maximumValue }
        if (promoInfo.type.toLowerCase() === 'percent') {
          promoValue = (parseFloat(etaFare.deliveryFee) * parseFloat(promoInfo.value)) / 100;
        } else {
          promoValue = promoInfo.value;
        }
      } else if (data.request.promoInfo) {
        promoInfo = { value: data.request.promoInfo.value, type: data.request.promoInfo.type, maximumValue: data.request.promoInfo.maximumValue }
        if (promoInfo.type.toLowerCase() === 'percent') {
          promoValue = (parseFloat(etaFare.deliveryFee) * parseFloat(promoInfo.value)) / 100;
        } else {
          promoValue = promoInfo.value;
        }
      }

      const costOfGoods = data.doPoints.reduce((a, b) => a + ((b.recipient && b.recipient.amount) ? parseFloat(b.recipient.amount) : 0), 0);
      let prevTotalFare = null
      if (prevEtaFare && prevEtaFare.originFare && ((prevEtaFare.isFareEdited && prevEtaFare.originFare.etaFare)
        || (etaFare && etaFare.markupDifference))) {
        prevTotalFare = prevEtaFare.originFare.etaFare;
      }
      return (
        <div className="trip-estimate-container-delivery">
          <div className="estimate-header">
            <div className="estimate-title">
              <Translate value="newbooking.TRIP_ESTIMATE" />
              <FareDeeplink fareInfo={etaFare} />
            </div>
          </div>
          <div className="estimateInfo">
            {locationPickUp && locationDestination && disDur && (
              <div className="item">
                <Translate value="newbooking.Distance" />
                <span>{distanText}</span>
              </div>
            )}
            {locationPickUp && locationDestination && disDur && (
              <div className="item">
                <Translate value="newbooking.Duration" />
                <span>{disDur.duration.text}</span>
              </div>
            )}
            {costOfGoods ?
              <div className="item">
                <Translate value="newbooking.Cost_of_Goods" />
                <span>{currencyFormatter.format(costOfGoods, { code: etaFare.currencyISO })}</span>
              </div>
              : null
            }
            <div className="divider" />
            <div className="item">
              <Translate value="newbooking.Delivery_Fee" />
              <span>{currencyFormatter.format(etaFare.deliveryFee, { code: etaFare.currencyISO })}</span>
            </div>
            {etaFare.itemsFee ?
              <div className="item">
                <Translate value="newbooking.Item_fees" />
                <span>{currencyFormatter.format(etaFare.itemsFee, { code: etaFare.currencyISO })}</span>
              </div> : null}
            {selectedFleet.techFeeActive &&
              <div className="item">
                <Translate value="newbooking.Tech_Fee" />
                <span>{currencyFormatter.format(etaFare.techFee, { code: etaFare.currencyISO })}</span>
              </div>}
            {selectedFleet.fleetFareId.taxActive &&
              <div className="item">
                <Translate value="newbooking.Tax" />
                <span>{currencyFormatter.format(etaFare.tax, { code: etaFare.currencyISO })}</span>
              </div>}
            {promoValue ?
              <div className="item">
                <Translate value="newbooking.PromoTitle" className="promotion-value" />
                <div>
                  <span className="promotion-value">
                    {currencyFormatter.format(promoValue, {
                      code: etaFare.currencyISO
                    })}
                  </span>
                </div>
              </div> : null}
            {isShowFare && (
              <>
                {allowEditFare || allowMarkupPrice ? (
                  <EditFare
                    etaFare={etaFare}
                    reasonMarkup={data.request.estimate.reasonMarkup}
                    markupType={data.request.estimate.markupType}
                    markupValue={data.request.estimate.markupValue}
                    forceMarkupPrice={data.request.estimate.forceMarkupPrice}
                    promoInfo={promoInfo}
                    allowEditFare={allowEditFare}
                    locationPickUp={this.props.locationPickUp}
                    allowMarkupPrice={allowMarkupPrice}
                    data={data}
                    isShowInfoPrePaid={isShowInfoPrePaid}
                    renderAmountDueStatusInvoicing={this.renderAmountDueStatusInvoicing}
                    isFareEditedBefore={this.props.isFareEditedBefore}
                    prevEtaFare={this.props.prevEtaFare}
                    selectedFleet={selectedFleet}
                    handleChangeETAFare={this.props.handleChangeETAFare}
                    handleChangeMarkupPrice={this.props.handleChangeMarkupPrice}
                    bookType={BOOK_TYPE_FEE.parcel}
                    serviceFeeZone={this.state.serviceFeeZone || {}}
                    supplierCompanies={data.supplierCompanies}
                    driver={this.props.driver}
                  />
                ) : (
                  <>
                    <div className="item">
                      <Translate value="newbooking.Fare" />
                      <div>
                        <span
                          className={
                            etaFare.addOnPrice && etaFare.addOnPrice !== 0
                              ? etaFare.addOnPrice > 0
                                ? 'adjust-detail'
                                : 'adjust-slow-detail'
                              : null
                          }
                        >
                          {this.renderEstimatedFare()}
                        </span>
                        {prevTotalFare && (
                          <div className="item">
                            <span style={{ textDecoration: 'line-through' }}>
                              {currencyFormatter.format(prevTotalFare, {
                                code: data.currencyISO,
                              })}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {
                      this.renderDriverEarningUIBookingDetail()
                    }
                    {
                      isShowInfoPrePaid &&
                      this.renderAmountDueStatusInvoicing()
                    }
                  </>
                )}
              </>
            )}
            {/* {this.renderWarningOutstanding()} */}
          </div>
        </div>
      );
    }
    return '';
  }

  render() {
    const { data } = this.props;
    if (data.bookId) return this.renderBookingDetail();
    return this.renderNewBooking();
  }
}

export default TripEstimateDelivery;
