import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  ButtonToolbar,
  Modal,
  Image,
  FormGroup,
  FormControl,
  Container,
} from 'react-bootstrap';
import CurrencyFormater from 'currency-formatter';
import _ from 'lodash';
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle';
import { Translate, I18n } from 'react-redux-i18n';
import * as loadingActions from '../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import closeIcon from '../../assets/images/icons/close.svg';
import { connect } from 'react-redux';
import {
  CCLiteCommonFunc,
  Validation,
  checkShowViewPaymentLogsBtn,
  trimPhoneNumber,
} from '../../utils/commonFunctions';
import AddressInformation from './AddressInformation';
import PackageInformation from './PackageInformation';
import DispatchDriver from './DispatchDriver';
import * as bookingDetailActions from '../../actions/bookingDetailAction';
import MapForm from './MapForm';
import './foodOrder.scss';
import IncidentButton from './BookingAction/IncidentButton';
import CancelButton from './BookingAction/CancelButton';
import MarkFailedButton from './BookingAction/MarkFailedButton';
import CompleteButton from './BookingAction/CompleteButton';
import ConfirmUpdateModal from './BookingAction/ConfirmUpdateModal';
import TripEstimate from './TripEstimate';
import * as intercityBookingActions from '../../actions/intercityBookingActions';
import { socketDispatchApi } from '../../utils/socketUtils';
import { socketConfigs } from '../../constants/socketConfigs';
import { bookingStatusMapping, userType } from '../../constants/commondata';
import * as cueActions from '../../actions/cueActions';
import DispatchLogs from '../../components/DispatchLogs';
import ViewPhotoCapture from '../../components/ViewPhotoCapture';
import TrackLink from '../../components/trackLink/TrackLink';
import * as settingActions from '../../actions/settingActions';
import * as uploadActions from '../../actions/uploadActions';
import { SendSMSBookingModal } from '../../components/bookingDetail';
import moment from 'moment';
import ExternalID from '../../components/bookingDetail/bookComponent/ExternalID';
import TrailNotes from '../../components/bookingDetail/bookComponent/trailNotes';
import PaymentLogs from '../../components/bookingDetail/bookComponent/PaymentLogs';

class FoodOrderDetail extends React.Component {
  constructor(props) {
    super();
    this.state = {
      request: {},
      psgInfo: {
        firstName: '',
        phone: '',
      },
      drvInfo: {
        fullName: '',
      },
      showSMSBooking: false,
      showTrailNote: false,
      smsBookingTemplateQuery: {
        list: [],
        listAll: [],
        page: 0,
        limit: 10,
        total: 0,
        selectedObj: null,
        hasMore: true,
        search: '',
      },
      disabledSpam: false,
      isSend: false,
      smsWillSend: {
        id: '',
        phone: '',
        name: '',
        content: '',
        subject: '',
        typeMessage: 'Inbox',
        typeRecipient: '',
        bookingId: '',
      },
      dispatchType: 0,
      forceAssign: false,
      valid: {},
      showConfirmUpdate: false,
      distanceDuration: {},
      statusText: '',
      pickupChecked: {},
      pickups: [],
      destinations: [],
      reasonMarkFailed: '',
    };
  }

  componentDidMount() {
    const { bookInfo, intercityBookingActions, user, auth } = this.props;
    intercityBookingActions.fetchCarTypeByCompany({
      companyId: user.roles.companyId,
      fleetId: auth.selectedFleet.fleetId,
    });
    this.transformStatusDisplay();
    this.setPickUpDestinations();

    if (bookInfo.drvInfo) {
      const drvInfo = {
        ...bookInfo.drvInfo,
        fullName: bookInfo.drvInfo.fullName || bookInfo.drvInfo.phone,
      };
      this.setState({
        drvInfo: drvInfo,
        dispatchType: bookInfo.dispatchType,
        externalInfo: bookInfo.externalInfo || {}
      });
    }
    if (bookInfo.request) {
      this.setState({
        request: bookInfo.request,
      });
    }
    this.getSMSBookingTemplateList(false, () => {}, true);
  }

  setPickUpDestinations = () => {
    const {
      deliveryInfo,
      jobType,
      deliveryInfo: { cashOnPickUp },
    } = this.props.bookInfo;
    this.setState({
      pickups: deliveryInfo.merchants,
      destinations: deliveryInfo.recipients,
    });
  };

  handleSaveBookingClick = (e) => {
    e && e.preventDefault();
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.handleUpdateBooking();
  };

  handleUpdateBooking = () => {
    this.setState({
      showConfirmUpdate: true,
      retryDispatch: false,
    });
  };

  handleCloseConfirmUpdate = () => {
    this.setState({
      showConfirmUpdate: false,
      retryDispatch: false,
    });
  };

  doUpdateBooking = () => {
    this.props.loadingActions.showLoadingSpiner();
    const {
      auth: { selectedFleet },
      bookInfo,
    } = this.props;
    let bookingObj = {
      bookId: bookInfo.bookId,
      dispatchType: this.state.dispatchType,
      fleetId: this.props.selectedFleet.fleetId,
      drvInfo: {
        phone: this.state.drvInfo.phone,
      },
      externalInfo: {
        ...this.state?.externalInfo,
        bookingReference: this.state.externalInfo?.bookingReference || ''
      },
    };
    if (bookingObj) {
      this.props.bookingDetailActions.updateBooking(bookingObj).then((res) => {
        this.handleResultUpdateBooking(res);
      });
    } else {
      this.props.loadingActions.hideLoadingSpiner();
    }
  };

  handleUpdateInfoAndDispatch = () => {
    this.props.loadingActions.showLoadingSpiner();
    const {
      auth: { selectedFleet },
      bookInfo,
    } = this.props;
    let bookingObj = {
      retryDispatch: true,
      bookId: bookInfo.bookId,
      dispatchType: this.state.dispatchType,
      fleetId: this.props.selectedFleet.fleetId,
      drvInfo: {
        phone: this.state.drvInfo.phone,
      },
      externalInfo: {
        ...this.state?.externalInfo,
        bookingReference: this.state?.externalInfo?.bookingReference || ''
      },
    };
    if (bookingObj) {
      this.props.bookingDetailActions.updateBooking(bookingObj).then((res) => {
        this.handleResultUpdateBooking(res);
      });
    } else {
      this.props.loadingActions.hideLoadingSpiner();
    }
  };

  handleResultUpdateBooking = (data) => {
    this.props.loadingActions.hideLoadingSpiner();
    if (data.code == 200 || data.returnCode == 200) {
      this.context.notification(
        'success',
        I18n.t('messages.booking.Update_booking_success')
      );
      this.close();
    } else {
      const errMgs = this.showMessageErrorBooking(data);
      if (errMgs) {
        this.context.notification('error', errMgs);
      }
    }
  };

  showMessageErrorBooking = (data) => {
    let msg = (
      <Translate value="messages.booking.data_incorect" dangerousHTML />
    );
    if(data.errorCode === "EXTERNAL_ID_EXISTED" || data?.error?.errorCode === "EXTERNAL_ID_EXISTED") {
      msg = I18n.t('messages.booking.EXTERNAL_ID_EXISTED');
      return msg;
    }
    if (data.code) {
      msg = I18n.t('messages.booking.' + data.code);
      const dataInfo = data.info || data.error;
      if (data.code == 303) {
        if (data.error.minimumBookAhead) {
          let hour = 0;
          const min = data.error.minimumBookAhead % 60;
          if (data.error.minimumBookAhead > 0) {
            hour = Math.floor(data.error.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (data.error.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            data.error.maximumBookAhead
          );
        }
        if (data.error.overlapTime) {
          // this.showConfirmForceOverlap();
          msg = I18n.t('messages.booking.driver_is_not_available');
        } else if (data.error.cannotAssignDriver) {
          msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated');
        } else if (dataInfo.seatsLuggageLimit === 0) {
          msg = I18n.t('messages.booking.seats_luggage_limit');
        } else if (dataInfo.fullCapacity === 0) {
          msg = I18n.t('messages.booking.full_capacity');
        }
      } else if (dataInfo.exDoDiffDo === false) {
        msg = I18n.t('messages.booking.exDoDiffDo');
      }
    } else {
      const dataInfo = data.info || data.error;
      if (dataInfo.limit === false) {
        msg = I18n.t('messages.booking.booking_limited');
      } else if (dataInfo.sameZone === false && dataInfo.crossZone === false) {
        msg = I18n.t('messages.booking.area_service_unavailable');
      } else if (!dataInfo.rate) {
        msg = I18n.t(
          'messages.booking.Please_reload_page_or_check_your_connection'
        );
      } else if (!dataInfo.pickUpTime) {
        msg = I18n.t('messages.booking.time_invalid');
        if (dataInfo.minimumBookAhead) {
          let hour = 0;
          let min = dataInfo.minimumBookAhead % 60;
          if (dataInfo.minimumBookAhead > 0) {
            hour = Math.floor(dataInfo.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (dataInfo.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            dataInfo.maximumBookAhead
          );
        }
        if (dataInfo.exDoDiffDo === false) {
          msg = I18n.t('messages.booking.exDoDiffDo');
        }
      } else if (
        dataInfo.psgInfo === false ||
        dataInfo.psgInfo.isActive === false
      ) {
        msg = I18n.t('messages.booking.psg_inactivate');
      } else if (dataInfo.preAuthorized === false) {
        if (dataInfo.preAuthorizedCode) {
          msg = I18n.t('messages.credits.' + dataInfo.preAuthorizedCode);
        }
      } else if (dataInfo.cannotAssignDriver) {
        msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated');
      } else if (dataInfo.overlapTime) {
        msg = I18n.t('messages.booking.driver_is_not_available');
      } else if (dataInfo.seatsLuggageLimit === 0) {
        msg = I18n.t('messages.booking.seats_luggage_limit');
      } else if (dataInfo.fullCapacity === 0) {
        msg = I18n.t('messages.booking.full_capacity');
      }
    }
    return msg;
  };

  handleIncidentBooking = (params) => {
    const dataRequest = {
      bookId: this.props.bookInfo.bookId,
      reason: params.reason,
      fleetId: this.props.auth.selectedFleet.fleetId,
      operator: {
        userId: this.props.auth.user._id,
        name: `${this.props.auth.user.firstName || ''} ${
          this.props.auth.user.lastName || ''
        }`,
      },
    };
    this.props.loadingActions.showLoadingSpiner();
    this.props.bookingDetailActions.incidentBooking(dataRequest).then((res) => {
      this.props.loadingActions.hideLoadingSpiner();
      if (res.returnCode == 200) {
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        this.context.notification(
          'error',
          I18n.t('messages.booking.incident_fail')
        );
      }
    });
  };

  handleChangeDrvInfo = (info) => {
    const { drvInfo } = this.state;
    this.setState({ drvInfo: { ...drvInfo, ...info } });
  };

  handleChangeDispatchDriverType = (value) => {
    this.setState({ dispatchType: value });
  };

  close = () => {
    this.props.closeBookingDialog();
  };

  sendSMSBooking = (e) => {
    this.setState({
      showSMSBooking: !this.state.showSMSBooking,
      smsWillSend: {
        id: '',
        phone: !_.isString(this.props.bookInfo.psgInfo)
          ? this.props.bookInfo.psgInfo.phone || ''
          : this.props.bookInfo.drvInfo.phone || '',
        name: '',
        content: '',
        subject: '',
        typeMessage: 'Inbox',
        typeRecipient: !_.isString(this.props.bookInfo.psgInfo)
          ? 'passenger'
          : 'driver',
      },
      isSend: false,
      smsBookingTemplateQuery: {
        ...this.state.smsBookingTemplateQuery,
        selectedObj: null,
      },
    });
  };

  getSMSBookingTemplateList = (
    loadMore = false,
    callback = null,
    firstLoad = false
  ) => {
    let bodyRequest = {
      fleetId: this.props.auth.selectedFleet.fleetId,
    };

    this.props.settingActions
      .getAllBookingSMSTemplates(bodyRequest)
      .then((data) => {
        if (data.ok && data.res) {
          let listFiter = data.res;
          const newData = listFiter.map((item) => {
            item.value = item.name;
            item.id = item._id;
            return item;
          });
          this.setState({
            smsBookingTemplateQuery: {
              ...this.state.smsBookingTemplateQuery,
              ...data.res,
              list: newData,
              listAll: newData,
            },
            smsWillSend: {
              id: '',
              phone: !_.isString(this.props.bookInfo.psgInfo)
                ? this.props.bookInfo.psgInfo.phone || ''
                : this.props.bookInfo.drvInfo.phone || '',
              name: '',
              content: '',
              subject: '',
              typeMessage: 'Inbox',
              typeRecipient: !_.isString(this.props.bookInfo.psgInfo)
                ? 'passenger'
                : 'driver',
            },
          });
        }
      });
  };
  handleSelectSMSBookingTemplate = (templateId) => {
    const smsBookingTemplateQuery = Object.assign(
      {},
      this.state.smsBookingTemplateQuery
    );
    smsBookingTemplateQuery.selectedObj = _.find(
      this.state.smsBookingTemplateQuery.list,
      (item) => item.id === templateId
    );

    if ((templateId && templateId.length === 0) || !templateId) {
      smsBookingTemplateQuery.list = this.state.smsBookingTemplateQuery.listAll;
    }

    this.setState({
      smsBookingTemplateQuery,
      smsWillSend: {
        ...this.state.smsWillSend,
        id: templateId || '',
        name:
          smsBookingTemplateQuery.selectedObj &&
          smsBookingTemplateQuery.selectedObj.name
            ? smsBookingTemplateQuery.selectedObj.name
            : '',
        subject:
          smsBookingTemplateQuery.selectedObj &&
          smsBookingTemplateQuery.selectedObj.name
            ? smsBookingTemplateQuery.selectedObj.name
            : '',
        content:
          smsBookingTemplateQuery.selectedObj &&
          smsBookingTemplateQuery.selectedObj.content
            ? smsBookingTemplateQuery.selectedObj.content
            : '',
      },
    });
  };

  handleChangeMessageType = (value) => {
    this.setState({
      smsWillSend: {
        ...this.state.smsWillSend,
        typeMessage: value,
      },
    });
  };

  handleSendMessageClick = () => {
    let data = Object.assign({}, this.state.smsWillSend);
    data.phone = trimPhoneNumber(data.phone);
    data.bookingId = this.state.data && this.state.data.bookId;
    const keyListArr = [
      '[FLEET_NAME]',
      '[FLEET_PHONE]',
      '[FLEET_EMAIL]',
      '[BOOKING_ID]',
      '[PASSENGER_FIRST_NAME]',
      '[PASSENGER_LAST_NAME]',
      '[PASSENGER_PHONE]',
      '[DRIVER_FIRST_NAME]',
      '[DRIVER_LAST_NAME]',
      '[DRIVER_PHONE]',
      '[PICKUP_TIME]',
      '[TRACK_LINK]',
    ];
    keyListArr.forEach((item) => {
      if (data.content.includes(item)) {
        let value = '';
        switch (item) {
          case '[FLEET_NAME]':
            value = this.props.auth.selectedFleet.name || '';
            break;
          case '[FLEET_PHONE]':
            value = this.props.auth.selectedFleet.phone || '';
            break;
          case '[FLEET_EMAIL]':
            value = this.props.auth.selectedFleet.email || '';
            break;
          case '[BOOKING_ID]':
            value = this.props.bookInfo.bookId || '';
            break;
          case '[PASSENGER_FIRST_NAME]':
            value =
              (this.props.bookInfo.psgInfo &&
                this.props.bookInfo.psgInfo.firstName &&
                this.props.bookInfo.psgInfo.firstName) ||
              '';
            break;
          case '[PASSENGER_LAST_NAME]':
            value =
              (this.props.bookInfo.psgInfo &&
                this.props.bookInfo.psgInfo.lastName &&
                this.props.bookInfo.psgInfo.lastName) ||
              '';
            break;
          case '[PASSENGER_PHONE]':
            value =
              (this.props.bookInfo.psgInfo &&
              this.props.bookInfo.psgInfo.phone &&
              this.props.bookInfo.psgInfo.phone !== 'No Phone'
                ? this.props.bookInfo.psgInfo.phone
                : '') || '';
            break;
          case '[DRIVER_FIRST_NAME]':
            value =
              (this.props.bookInfo.drvInfo &&
                this.props.bookInfo.drvInfo.firstName &&
                this.props.bookInfo.drvInfo.firstName) ||
              '';
            break;
          case '[DRIVER_LAST_NAME]':
            value =
              (this.props.bookInfo.drvInfo &&
                this.props.bookInfo.drvInfo.lastName &&
                this.props.bookInfo.drvInfo.lastName) ||
              '';
            break;
          case '[DRIVER_PHONE]':
            value =
              (this.props.bookInfo.drvInfo &&
              this.props.bookInfo.drvInfo.phone &&
              this.props.bookInfo.drvInfo.phone !== 'No Phone'
                ? this.props.bookInfo.drvInfo.phone
                : '') || '';
            break;
          case '[PICKUP_TIME]':
            let result = '';
            if (this.props.bookInfo.request.pickUpTime !== 'Now') {
              let timePickup = moment
                .tz(
                  this.props.bookInfo.request.pickUpTime,
                  this.props.auth.selectedFleet.timezone
                )
                .toString();
              result = timePickup.slice(0, timePickup.indexOf('GMT') - 1);
            }
            value =
              this.props.bookInfo.request.pickUpTime === 'Now' ? 'Now' : result;
            break;
          case '[TRACK_LINK]':
            value =
              `${this.props.auth.selectedFleet.trackLink}/${this.props.bookInfo.token}` ||
              '';
            break;
        }
        data.content = data.content.replaceAll(item, value);
      }
    });
    this.setState({ isSend: true });
    if (
      this.state.smsWillSend.content.length === 0 ||
      this.state.smsWillSend.phone.length === 0 ||
      this.state.smsWillSend.subject.length === 0
    )
      return;
    if (Validation.validateSearchingPhone(data.phone) === false) {
      return this.context.notification('error', I18n.t('errors.24001'));
    } else {
      data.fleetId = this.props.auth.selectedFleet.fleetId;
      this.setState({ disabledSpam: true });
      this.props.settingActions.sendMessageBooking(data).then((data) => {
        if (data && data.error === null) {
          this.context.notification(
            'success',
            I18n.t('report.result.bookingDetails.successSendMessage')
          );
          this.sendSMSBooking();
          return this.setState({ disabledSpam: false });
        } else {
          this.context.notification('error', data.error.message);
          return this.setState({ disabledSpam: false });
        }
      });
    }
  };

  handleChangeBookingReference = (e) => {
    this.setState({ 
      externalInfo: {
        ...this.state?.externalInfo,
        bookingReference: e.target.value
      }
    });
  }

  viewPaymentLogButtonClick = () => {
    this.setState({ showPaymentLogsModal: true });
  };

  handleSearchSMSTemplate = (inputValue, callback, forceUpdate = false) => {
    let params = _.pick(this.state.smsBookingTemplateQuery, [
      'search',
      'listAll',
      'list',
      'selectedObj',
    ]);
    if (params.listAll.length === 0) return;
    if (inputValue && inputValue.length > 0) {
      params.search = inputValue;
      params.list = params.listAll.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      this.setState({
        smsBookingTemplateQuery: {
          ...this.state.smsBookingTemplateQuery,
          ...params,
        },
      });
    } else {
      params.search = '';
      params.list = params.listAll;
      this.setState({
        smsBookingTemplateQuery: {
          ...this.state.smsBookingTemplateQuery,
          ...params,
        },
      });
    }
    return callback();
  };

  handleChangeRecipientType = (value) => {
    this.setState({
      smsWillSend: {
        ...this.state.smsWillSend,
        typeRecipient: value,
        phone:
          value === 'driver'
            ? this.props.bookInfo.drvInfo.phone
            : this.props.bookInfo.psgInfo.phone,
      },
    });
  };

  handleContentChange = (e) => {
    this.setState({ smsWillSend: { ...this.state.smsWillSend, content: e } });
  };

  handlePhoneChange = (e) => {
    this.setState({
      smsWillSend: { ...this.state.smsWillSend, phone: e.target.value },
    });
  };

  handleSubjectChange = (e) => {
    this.setState({
      smsWillSend: { ...this.state.smsWillSend, subject: e.target.value },
    });
  };

  validatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      let oldMessages = this.state.errorMessages;
      if (!valid) {
        if (!oldMessages) oldMessages = [];
        oldMessages = oldMessages.concat(messages);
      }
      this.setState({
        valid: { ...this.state.valid },
        errorMessages: oldMessages,
      });
    }
  };

  handleCancelBooking = (params) => {
    const dataRequest = {
      bookId: this.props.bookInfo.bookId,
      reason: params.reason,
      fleetId: this.props.auth.selectedFleet.fleetId,
      refundFare: params.refundFare,
      canceller:
        this.props.auth.user.roles.roleName == 'corporateAdmin'
          ? 'CorpAD'
          : 'CC',
      operator: {
        userId: this.props.auth.user._id,
        name: `${this.props.auth.user.firstName || ''} ${
          this.props.auth.user.lastName || ''
        }`,
      },
    };
    this.props.loadingActions.showLoadingSpiner();
    this.props.bookingDetailActions.cancelBooking(dataRequest).then((res) => {
      this.props.loadingActions.hideLoadingSpiner();
      console.log(res);
      if (res.returnCode == 200) {
        this.context.notification(
          'success',
          I18n.t('messages.booking.Cancel_booking_success')
        );
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        this.context.notification(
          'error',
          I18n.t('messages.booking.cancle_fail')
        );
      }
    });
  };

  submitMarkFailedRecipient = (paymentObject = {}) => {
    const dataRequest = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      bookId: this.props.bookInfo.bookId,
      reason: this.state.reasonMarkFailed,
      orderId: _.get(
        this.props.bookInfo,
        'deliveryInfo.recipients[0].orderId',
        ''
      ),
      ...paymentObject,
    };
    this.props.loadingActions.showLoadingSpiner();
    this.props.bookingDetailActions
      .markFailedRecipient(dataRequest)
      .then((response) => {
        this.props.loadingActions.hideLoadingSpiner();
        if (response && response.code === 1) {
          this.props.bookingDetailActions.bookingDetailClosed();
          this.context.notification(
            'success',
            I18n.t('messages.booking.marked_failed_ok')
          );
        } else {
          if (response && response.errorCode === 'ORDER_HAS_BEEN_FINISHED') {
            this.context.notification(
              'error',
              I18n.t(`messages.booking.ORDER_HAS_BEEN_FINISHED`)
            );
          }
        }
      });
  };

  closeModalMarkFailCompleted = () => {
    this.setState({
      showCompletedWhenMarkFailedRecipient: false,
      reasonMarkFailed: '',
    });
  };

  handleMarkFailedBooking = (reason) => {
    this.setState({
      showCompletedWhenMarkFailedRecipient: true,
      reasonMarkFailed: reason,
    });
  };

  transformStatusDisplay = () => {
    const { status, jobType } = this.props.bookInfo;
    if (
      status === 'booked' ||
      status === 'arrived' ||
      status === 'collecting'
    ) {
      this.setState({
        statusText: `statusDisplay.${jobType}_${status}`,
      });
    } else {
      this.setState({
        statusText: `statusDisplay.${status}`,
      });
    }
  };

  setDistanceDuration = (legs) => {
    this.setState({
      distanceDuration: legs,
    });
  };

  render() {
    const { bookInfo, jobType, permissions } = this.props;
    const { dispatchType, request, drvInfo, isSubmited, valid } = this.state;
    const canUpdate =
      bookingStatusMapping.canUpdateBookingDelivery[bookInfo.status];
    const canComplete =
      bookingStatusMapping.canCompleteBookingDelivery[bookInfo.status];
    const actionPermission = permissions && permissions.actions;
    const promoCode =
      bookInfo.request &&
      bookInfo.request.estimate &&
      bookInfo.request.estimate.fare.promoCode;
    const promoInfo = (bookInfo.request && bookInfo.request.promoInfo) || {};
    let valuePromo = '';
    if (promoInfo && promoCode) {
      if (promoInfo.type === 'amount') {
        valuePromo = CurrencyFormater.format(promoInfo.value, {
          code: promoInfo.currencyISO,
        });
      } else {
        valuePromo = promoInfo.value + '%';
      }
    }

    return (
      <Modal
        show={!!this.props.show}
        onHide={this.close}
        backdrop
        dialogClassName="book-detail-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate
              value="bookingdetail.Order_detail"
              className="white-text"
            />
            <span className="white-text">:</span> #{bookInfo.bookId}{' '}
            <span className="white-text">-</span>{' '}
            <span className="white-text">
              {I18n.t(`bookingdetail.${bookInfo.jobType}`)}
            </span>{' '}
            <span className="white-text">-</span>{' '}
            <Translate
              className="white-text text-tranform-none"
              value={this.state.statusText}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`clearfix newbooking-form ${this.state.showTrailNote ? 'show-trail-note' : ''}`}>
          <div className="bookingTitle">
            <span className="bookingTitle__text">
              <Translate value={`bookingdetail.${bookInfo.jobType}`} />
            </span>
          </div>
          <Container fluid>
            <Row>
              <Col xs={12} md={7} className="mt">
                <Row>
                  <Col xs={12} md={4} className="info-content">
                    <AddressInformation bookInfo={bookInfo} />
                  </Col>
                  <Col xs={12} md={4} className="info-content">
                    <PackageInformation
                      bookInfo={bookInfo}
                      selectedFleet={this.props.selectedFleet}
                      cueActions={this.props.cueActions}
                    />
                  </Col>
                  <Col xs={12} md={4} className="info-content">
                    <FormGroupTitle>
                      <Translate value="newbooking.PAYMENT_METHOD" />
                    </FormGroupTitle>
                    <FormGroup>
                      <FormControl
                        type="text"
                        value={
                          bookInfo.request && bookInfo.request.paymentType
                            ? I18n.t(
                                'paymentMethod.paymentMethod_' +
                                  bookInfo.request.paymentType
                              )
                            : null
                        }
                        className="form-custom"
                        disabled={true}
                      />
                    </FormGroup>
                    <ExternalID 
                      value={this.state?.externalInfo?.bookingReference}
                      handleChangeBookingReference={this.handleChangeBookingReference}
                      is3rdBooking={false}
                    />
                    {promoCode && (
                      <>
                        <FormGroupTitle>
                          <Translate value="newbooking.PromoTitle" />
                        </FormGroupTitle>
                        <FormGroup>
                          <FormControl
                            type="text"
                            value={
                              bookInfo.request &&
                              bookInfo.request.estimate &&
                              bookInfo.request.estimate.fare.promoCode
                            }
                            className="form-custom"
                            disabled={true}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Translate
                            value="newbooking.Promo"
                            className="promotion-value"
                          />
                          {'\u00A0'}
                          {promoInfo && !promoInfo.type ? (
                            <span>{promoInfo.value}</span>
                          ) : (
                            <span>{valuePromo}</span>
                          )}
                        </FormGroup>
                      </>
                    )}
                    <DispatchDriver
                      onChange={this.handleChangeDispatchDriverType}
                      onChangeDrvInfo={this.handleChangeDrvInfo}
                      dispatchType={dispatchType}
                      request={request}
                      drvInfo={drvInfo}
                      valid={valid}
                      isSubmited={isSubmited}
                      validatorCallback={this.validatorCallback}
                      status={bookInfo.status}
                    />
                    <TrackLink
                      status={bookInfo && bookInfo.status}
                      tokenBooking={bookInfo && bookInfo.token}
                      trackUrl={
                        this.props.auth &&
                        this.props.auth.selectedFleet &&
                        this.props.auth.selectedFleet.trackLink
                      }
                    />
                    <ViewPhotoCapture
                      data={bookInfo}
                      listItems={
                        bookInfo.deliveryInfo && bookInfo.deliveryInfo.merchants
                          ? bookInfo.deliveryInfo.merchants
                          : []
                      }
                      keyValue="collectedPhotos"
                      title={'view_good_receipt'}
                    />
                    <ViewPhotoCapture
                      data={bookInfo}
                      listItems={
                        bookInfo.deliveryInfo && bookInfo.deliveryInfo.recipients
                          ? bookInfo.deliveryInfo.recipients
                          : []
                      }
                      keyValue="deliveredPhotos"
                      title={'view_delivery_receipt'}
                    />

                    {checkShowViewPaymentLogsBtn(bookInfo) && (
                          <Button // btn view payment log
                            onClick={this.viewPaymentLogButtonClick}
                            variant="link"
                            className="paymentLogBtn"
                          >
                            <Translate value="bookingdetail.ViewPaymentLogs" />
                          </Button>
                        )}
                        {this.state.showPaymentLogsModal && (
                          <PaymentLogs // modal payment log
                            bookInfo={bookInfo}
                            hasPermissionAction={this.props?.permissions?.actions}
                            handleClose={() => {
                              this.setState({ showPaymentLogsModal: false });
                            }}
                          />
                        )}
                  </Col>
                  <Col xs={12} className="intercityBookingForm__buttonContainer">
                    <ButtonToolbar className="fill text-center btnBookingDetail" style={{marginTop: 0}}>
                      {actionPermission && (
                        <>
                          {canUpdate ? (
                            <Button
                              className="btn-save mr"
                              onClick={this.handleSaveBookingClick}
                            >
                              <Translate value="newbooking.Update" />
                            </Button>
                          ) : null}
                            <DispatchLogs data={bookInfo} />
                          {this.props.auth.user.userType !==
                            userType.CorporateAdmin &&
                            this.props.auth.user.userType !==
                              userType.CorporateUser &&
                            this.props.bookInfo &&
                            ((this.props.bookInfo.drvInfo &&
                              !_.isString(this.props.bookInfo.drvInfo) &&
                              this.props.bookInfo.drvInfo.phone &&
                              this.props.bookInfo.drvInfo.phone.length > 0) ||
                              (this.props.bookInfo.psgInfo &&
                                !_.isString(this.props.bookInfo.psgInfo) &&
                                this.props.bookInfo.psgInfo.phone &&
                                this.props.bookInfo.psgInfo.phone.length > 0)) && (
                              <Button
                                onClick={this.sendSMSBooking}
                                className="btn-send mr"
                              >
                                <Translate value="bookingdetail.sendMessage" />
                              </Button>
                            )}
                          <CancelButton
                            bookInfo={bookInfo}
                            onSubmit={this.handleCancelBooking}
                          />
                          <IncidentButton
                            bookInfo={bookInfo}
                            onIncidentBooking={this.handleIncidentBooking}
                          />
                          {canUpdate && (
                            <MarkFailedButton
                              bookInfo={bookInfo}
                              onSubmit={this.handleMarkFailedBooking}
                            />
                          )}
                          {canComplete ? (
                            <CompleteButton
                              bookInfo={bookInfo}
                              statusText={this.state.statusText}
                              pickups={this.state.pickups}
                              destinations={this.state.destinations}
                            />
                          ) : null}

                          {this.state.showCompletedWhenMarkFailedRecipient && (
                            <CompleteButton
                              completedMarkFailed
                              closeModalMarkFailCompleted={
                                this.closeModalMarkFailCompleted
                              }
                              submitMarkFailedRecipient={
                                this.submitMarkFailedRecipient
                              }
                              bookInfo={bookInfo}
                              statusText={this.state.statusText}
                              pickups={this.state.pickups}
                              destinations={this.state.destinations}
                            />
                          )}
                        </>
                      )}
                    </ButtonToolbar>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={5} className="mt">
                <div className="booking-map-container">
                  <MapForm
                    setDistanceDuration={this.setDistanceDuration}
                    pickups={this.state.pickups}
                    destinations={this.state.destinations}
                    fleetId={this.props.auth.selectedFleet.fleetId}
                  />
                  <TripEstimate distanceDuration={this.state.distanceDuration} />
                  {/* <TrailNotes
                      notification={this.context.notification}
                      settingActions={this.props.settingActions}
                      bookId={bookInfo.bookId}
                      fleetId={this.props.auth.selectedFleet.fleetId}
                      uploadService={this.props.uploadService}
                      setShowTrailNote={(active) => this.setState({ showTrailNote: active })}
                    /> */}
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <ConfirmUpdateModal
          visible={this.state.showConfirmUpdate}
          onClose={this.handleCloseConfirmUpdate}
          bookInfo={bookInfo}
          data={this.state}
          onUpdate={this.doUpdateBooking}
          handleUpdateInfoAndDispatch={this.handleUpdateInfoAndDispatch}
        />
        <SendSMSBookingModal
          showSMSBooking={this.state.showSMSBooking}
          sendSMSBooking={this.sendSMSBooking}
          smsBookingTemplateQuery={this.state.smsBookingTemplateQuery}
          getSMSBookingTemplateList={this.getSMSBookingTemplateList}
          handleSelectSMSBookingTemplate={this.handleSelectSMSBookingTemplate}
          handleChangeMessageType={this.handleChangeMessageType}
          handleChangeRecipientType={this.handleChangeRecipientType}
          handleContentChange={this.handleContentChange}
          handlePhoneChange={this.handlePhoneChange}
          handleSubjectChange={this.handleSubjectChange}
          smsWillSend={this.state.smsWillSend}
          handleSendMessageClick={this.handleSendMessageClick}
          checkDriver={
            this.props.bookInfo.drvInfo &&
            !_.isString(this.props.bookInfo.drvInfo) &&
            this.props.bookInfo.drvInfo.phone &&
            this.props.bookInfo.drvInfo.phone.length > 0
              ? true
              : false
          }
          checkCustomer={
            this.props.bookInfo.psgInfo &&
            !_.isString(this.props.bookInfo.psgInfo) &&
            this.props.bookInfo.psgInfo.phone &&
            this.props.bookInfo.psgInfo.phone.length > 0
              ? true
              : false
          }
          valid={this.state.valid}
          ValidatorCallback={this.validatorCallback}
          handleSearchSMSTemplate={this.handleSearchSMSTemplate}
          disabledSpam={this.state.disabledSpam}
          isSend={this.state.isSend}
        />
      </Modal>
    );
  }
}

FoodOrderDetail.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedFleet: state.auth.selectedFleet,
    auth: state.auth,
    socket: state.socket,
    bookInfo: state.bookingDetail.data,
    commonData: state.commonData,
    common: state.intercityBooking.common,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadingActions: bindActionCreators(loadingActions, dispatch),
    cueActions: bindActionCreators(cueActions, dispatch),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
    settingActions: bindActionCreators(settingActions, dispatch),
    uploadService: bindActionCreators(uploadActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodOrderDetail);
