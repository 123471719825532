import { activeStatus } from '../../../constants/commondata';

function getDefaultTip() {
  if (this.state.customer) {
    if (
      this.state.customer
      && this.state.customer.tips
      && this.state.customer.tips >= 0
      && this.state.customer.registerFrom == 'App'
    ) {
      return this.state.customer.tips;
    }
  }
  if (
    this.state.locationPickUp
    && this.props.newBooking.locationService
    && this.props.newBooking.locationService.crossZone
  ) {
    return this.props.newBooking.locationService.additionalService
      && this.props.newBooking.locationService.additionalService.tip
      && this.props.newBooking.locationService.additionalService.tip.value >= 0
      ? this.props.newBooking.locationService.additionalService.tip.value
      : 0;
  }
  return this.props.auth.selectedFleet.fleetFareId
    ? this.props.auth.selectedFleet.fleetFareId.tips
    : 0;
}

function canEditPromo(booking) {
  if (booking.dispatchRideSharing) {
    return false;
  }
  if (this.state.data.psgInfo.userId == '') return false;
  const status = activeStatus.filter((data) => data.code == booking.status)[0];
  if (status && status.canEditPromo) {
    return true;
  }
  return false;
}

export { getDefaultTip, canEditPromo };
