import React, { Component } from 'react';
import _ from 'lodash';
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Form,
  Image,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import LocationInput from './LocationInput';
import destinationIcon from '../../../assets/images/icons/destination2.svg';
import pickupIcon from '../../../assets/images/icons/pickup2.svg';
import CompleteWithPayment from './CompleteWithPayment';

const currencyFormatter = require('currency-formatter');

class CompleteWithoutServiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFocus: '',
    };
  }

  setFocus = (focus) => {
    this.setState({
      currentFocus: focus,
    });
  };

  onBlur = () => {
    this.setState({
      currentFocus: '',
    });
  };

  parseInputValue = (inputName, value) => {
    const { currentFocus } = this.state;
    const { data } = this.props;
    if (value) {
      value = value.toString();
      if (inputName == currentFocus) return value.replace(/[^.0-9]+/, '') || 0;
      return currencyFormatter.format(value, {
        code: data.currencyISO,
        format: '%v',
      });
    }
    if (currentFocus == inputName) {
      return '';
    }

    return currencyFormatter.format(0, {
      code: data.currencyISO,
      format: '%v',
    });
  };

  getTextSubmitButton = (paymentComplete) =>
    paymentComplete.isPending
      ? I18n.t('bookingdetail.Complete_booking')
      : I18n.t('bookingdetail.Pay_here');

  render() {
    const {
      showConfirmComplete,
      completeWithoutServiceCloseButtonClick,
      data,
      locationPickUp,
      commonData,
      locationDestination,
      handleSelectDestination,
      handleChangeDestination,
      handleAddressRemovedDestination,
      completeWithoutService,
      options,
      disabledCompleteButton,
    } = this.props;

    if (data.pricingType === 1) {
      return <CompleteWithPayment {...this.props} />;
    }

    return (
      <Modal
        show={showConfirmComplete}
        backdrop="static"
        dialogClassName="confirm-dialog"
        className="complete-with-payment-modal"
        onHide={completeWithoutServiceCloseButtonClick}
      >
        {showConfirmComplete ? (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate
                  value="bookingdetail.Booking_detail"
                  className="white-text"
                />
                <span className="white-text">:</span> #{this.props.data.bookId}
                <span className="white-text"> -</span>{' '}
                <Translate
                  className="white-text text-tranform-none"
                  value={`statusDisplay.${this.props.data.status}`}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="newbooking-form complete-with-payment md-payment">
              <div className="fill payment-dialog step0">
                <FormGroup>
                  <Form.Label>
                    <Translate value="bookingdetail.Pick_up" />
                  </Form.Label>
                  <FormControl
                    disabled
                    type="text"
                    className="form-custom"
                    value={locationPickUp.address}
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>
                    <Translate value="bookingdetail.Destination" />
                  </Form.Label>
                  <LocationInput
                    address={
                      locationDestination ? locationDestination.address : ''
                    }
                    onChange={handleChangeDestination}
                    onRemove={handleAddressRemovedDestination}
                    onSelect={handleSelectDestination}
                    value={
                      locationDestination ? locationDestination.address : ''
                    }
                    isChina={
                      commonData &&
                      commonData.location &&
                      commonData.location.isChina
                    }
                    options={options}
                    disabled={data.pricingType === 1}
                    data={this.props.data}
                  />
                </FormGroup>
              </div>

              <div className="fill payment-dialog">
                <FormGroup className="location-info">
                  <Image src={pickupIcon} />
                  <span>{locationPickUp.address}</span>
                </FormGroup>
                <FormGroup className="location-info">
                  <Image src={destinationIcon} />
                  <span>
                    {locationDestination ? locationDestination.address : ''}
                  </span>
                </FormGroup>
                <Form.Label>
                  (<span className="require">*</span>
                  ):
                  <Translate value="bookingdetail.change_payment_method_note" />
                </Form.Label>
              </div>
            </Modal.Body>

            <Modal.Footer className="pt0">
              <Button
                className="btn-cancel"
                onClick={completeWithoutServiceCloseButtonClick}
              >
                {I18n.t('bookingdetail.Cancel')}
              </Button>
              <Button
                className="btn-save"
                disabled={disabledCompleteButton}
                onClick={completeWithoutService}
              >
                {I18n.t('bookingdetail.Complete')}
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          ''
        )}
      </Modal>
    );
  }
}

export default CompleteWithoutServiceModal;
