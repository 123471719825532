import React, { Component } from 'react';
import currencyFormatter from 'currency-formatter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Translate } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';
import {
  bookingStatusMapping,
  PAYOUT_CUSTOM_TYPE,
  BOOK_TYPE_FEE,
  AFFILIATE_BOOKING_CAR_TYPE,
  userType,
  thirdPartyIntegration,
  PAYOUT_TBD,
} from '../../../../constants/commondata';
import {
  CCLiteCommonFunc,
  calculatorDriverEarningByType,
  checkEnableEditDriverEarning,
  checkBookingPrePaided,
  checkCorporateUser,
  getServiceType,
  getBookingType,
} from '../../../../utils/commonFunctions';
import ModalEditFare from './ModalEditFare';
import * as bookingDetailActions from '../../../../actions/bookingDetailAction';
import { th } from 'react-dom-factories';
import { calculatePayout, calculatorPayoutWhenCustom } from '../../bookFunction/bookingInfo';
import * as settingActions from '../../../../actions/settingActions';

class EditFareComponent extends Component {
  constructor(props) {
    super(props);
    let etaFare = props?.etaFare?.etaFare;
    let markupFare = props?.etaFare?.markupDifference;
    if (props.isHydraBooking) {
      etaFare = props.etaFare?.qupSellPrice;
      markupFare = props.fleetMarkup || 0;
    }
    const prevEtaFare = _.get(props.prevEtaFare, 'originFare', {});
    this.state = {
      serviceFeeZone: {},
      showModalEditFare: false,
      bookId: props.data && props.data.bookId ? props.data.bookId : null,
      fareSettings: {},
      valid: {},
      isSubmitted: false,
      totalFare: etaFare + (markupFare || 0),
      prevTotalFare: props.isHydraBooking
        ? prevEtaFare && prevEtaFare.qupSellPrice
        : prevEtaFare && prevEtaFare.etaFare,
    };
  }

  componentDidMount() {
    const { auth, bookingPermission = [], data = {} } = this.props;
    this.setState({
      enableEditDriverEarning: checkEnableEditDriverEarning(
        auth,
        bookingPermission
      ),
      isCompletedBooking:
        data && CCLiteCommonFunc.isBookingStatusCompleted(data.status),
    });
    this.getFareSettings();
    this.getServiceFeeByZone();
  }

  getServiceFeeByZone = async () => {
    const { fleetId } = this.props.auth.selectedFleet;
    let zoneId = this.props.data?.request?.pickup?.zoneId;
    if (Object.keys(this?.props?.serviceFeeZone || {})?.length) {
      return
    }
    if (!zoneId && this.props.locationPickUp) {
      const zoneObj = await this.props.bookingDetailActions.getZoneWithLatLng(
        {
          fleetId: fleetId,
          lat: this.props.locationPickUp?.lat,
          lng: this.props.locationPickUp?.lng,
        }
      );
      zoneId= zoneObj?.res[0]?._id
    }
    this.props.settingActions
      .getServiceFeesByZone({
        fleetId: fleetId,
        zoneId: zoneId,
      })
      .then((data) => {
        this.setState({ serviceFeeZone: data.res });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.etaFare !== prevProps.etaFare) {
      let etaFare = this.props.etaFare?.etaFare;
      let markupFare = this.props.etaFare?.markupDifference;
      if (this.props.isHydraBooking) {
        etaFare = this.props.etaFare?.qupSellPrice;
        markupFare = this.props.fleetMarkup || 0;
      }
      this.setState({
        totalFare: etaFare + (markupFare || 0),
      });
    }
    if (
      this.props.prevEtaFare !== prevProps.prevEtaFare &&
      this.props.prevEtaFare?.originFare &&
      this.props.prevEtaFare?.originFare.etaFare
    ) {
      const prevEtaFare = _.get(this.props.prevEtaFare, 'originFare', {});
      this.setState({
        prevTotalFare: this.props.isHydraBooking
          ? prevEtaFare.qupSellPrice
          : prevEtaFare.etaFare,
      });
    }
  }

  getFareSettings = () => {
    const { fleetId } = this.props.auth.selectedFleet;
    const zoneId = _.get(this.props.data, 'request.pickup.zoneId');
    this.props.bookingDetailActions
      .getFleetFareSetting({ fleetId, zoneId })
      .then((data) => {
        if (data.ok && data.res) {
          let etaFare = this.props.etaFare?.etaFare;
          let markupFare = this.props.etaFare?.markupDifference;
          if (this.props.isHydraBooking) {
            etaFare = this.props.etaFare?.qupSellPrice;
            markupFare = this.props.fleetMarkup || 0;
          }
          let totalFare = etaFare + (markupFare || 0);
          this.setState({
            totalFare,
            fareSettings: data.res,
          });
        }
      });
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  handleClickEditFare = async () => {
    const { auth, locationPickUp } = this.props;
    const allowCongfigSettingForEachZone = _.get(
      auth,
      'selectedFleet.generalSetting.allowCongfigSettingForEachZone',
      false
    );

    if (allowCongfigSettingForEachZone && locationPickUp) {
      const { fareSettings } = this.state;
      const defaultFleetCommissionType = _.get(
        fareSettings,
        'fare.defaultFleetCommissionType',
        'sameZones'
      );
      const serviceFeeType = _.get(fareSettings, 'fare.applyType', 'sameZones');

      if (
        defaultFleetCommissionType !== 'sameZones' ||
        serviceFeeType === 'custom_zone'
      ) {
        const zoneObj = await this.props.bookingDetailActions.getZoneWithLatLng(
          {
            fleetId: auth.selectedFleet.fleetId,
            lat: locationPickUp.lat,
            lng: locationPickUp.lng,
          }
        );

        if (zoneObj && zoneObj.res && zoneObj.res.length > 0) {
          const fareDefaultSetting =
            await this.props.bookingDetailActions.getFleetFareSetting({
              fleetId: auth.selectedFleet.fleetId,
              zoneId: zoneObj.res[0]._id,
            });
          if (fareDefaultSetting.ok && fareDefaultSetting.res) {
            this.setState({
              zoneId: zoneObj.res[0]._id,
              fareSettings: fareDefaultSetting.res,
            });
          }
        }
      }
    }

    this.setState({ showModalEditFare: true, isSubmitted: false });
  };

  handleCloseModalEditFare = () => {
    this.setState({ showModalEditFare: false, isSubmitted: false });
  };

  handleSaveEditFare = (
    editFare,
    originFare,
    reasonEditFare,
    changeFare,
    isReseted
  ) => {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return;
    let totalFare = editFare.etaFare;
    let isUpdateFare = changeFare && editFare !== originFare;
    if (isUpdateFare && !isReseted) {
      this.props.handleChangeETAFare(
        editFare,
        originFare,
        reasonEditFare,
        isUpdateFare
      );
    } else if (isReseted) {
      this.props.handleChangeETAFare(
        editFare,
        originFare,
        reasonEditFare,
        false,
        isReseted
      );
    }
    this.setState(
      {
        totalFare,
      },
      () => {
        this.handleCloseModalEditFare();
      }
    );
  };

  saveHolidayTaxisCompletedEditFare = (value) => {
    this.setState(
      {
        totalFare: value,
        isSubmitted: true,
      },
      () => {
        this.props?.saveHolidayTaxisCompletedEditFare(value);
        this.handleCloseModalEditFare();
      }
    );
  };

  checkShowDriverEarning = () => {
    const { enableEditDriverEarning, isCompletedBooking } = this.state;
    if (
      enableEditDriverEarning &&
      !isCompletedBooking &&
      !checkCorporateUser(this.props.auth?.user)
    ) {
      return true;
    }
    return false;
  };

  handleSaveMarkupPrice = (
    originFare,
    markupDifference,
    reasonMarkup,
    markupType,
    markupValue,
    isReseted,
    makeupPriceTotal,
    supplierEarning
  ) => {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return;
    let totalFare = this.props.etaFare.etaFare + markupDifference;
    if (this.props.isHydraBooking) {
      totalFare = this.props.etaFare.qupSellPrice + markupDifference;
    }
    this.props.handleChangeMarkupPrice(
      originFare,
      markupDifference,
      reasonMarkup,
      markupType,
      markupValue,
      isReseted,
      makeupPriceTotal,
      supplierEarning
    );

    this.setState(
      {
        totalFare,
      },
      () => {
        this.handleCloseModalEditFare();
      }
    );
  };

  renderTotalFare = () => {
    const { data, etaFare } = this.props,
      currencyISO = etaFare?.currencyISO;
    let { totalFare, isCompletedBooking } = this.state;
    if (isCompletedBooking) {
      totalFare = data?.completedInfo?.total || 0;
    }

    return (
      <span className="fareTotal">
        {currencyFormatter.format(totalFare, {
          code: currencyISO,
        })}{' '}
      </span>
    );
  };

  renderReceivableFare = () => {
    const { data, etaFare } = this.props,
      currencyISO = etaFare?.currencyISO;
    let { totalFare } = this.state;

    // booking holidaytaxi then get ReceivableFare from externalInfo
    if (data?.externalInfo?.thirdParty === thirdPartyIntegration.holidaytaxis) {
      totalFare = data?.externalInfo?.price || 0;
    }

    return (
      <span className="fare3rd">
        {currencyFormatter.format(totalFare, {
          code: currencyISO,
        })}{' '}
      </span>
    );
  };

  renderIconBtnEditFare = () => {
    return (
      <label
        className="cue-edit"
        onClick={(e) => {
          this.handleClickEditFare(e);
        }}
      >
        {' '}
        <i className="fa fa-pencil" />
      </label>
    );
  };

  render() {
    const {
      allowEditFare,
      isDisableOtherOperation = true,
      allowMarkupPrice,
      etaFare = {},
      prevEtaFare,
      promoInfo = {},
      auth,
      viewHistory = false,
      reasonMarkup,
      markupType,
      markupValue,
      forceMarkupPrice,
      bookType,
      isHydraBooking,
      farmIn,
      fleetMarkup,
      etaFareLocalOrigin,
      farmOut = false,
      data = {},
      driverCompany,
      is3rdBooking,
    } = this.props;
    const {
      showModalEditFare,
      prevTotalFare,
      fareSettings,
      enableEditDriverEarning,
      isCompletedBooking,
    } = this.state;
    let { totalFare } = this.state;
    const statusBooking = _.get(this.props.data, 'status', 'pending');
    if (isCompletedBooking) {
      totalFare = data?.completedInfo?.total || 0;
    }
    const showIconEditFare =
      bookType !== 'DELIVERY' &&
      isDisableOtherOperation &&
      !farmIn &&
      (!farmOut || (farmOut && bookingStatusMapping.canEdit[statusBooking]));

    const addOnPrice = _.get(data, 'request.estimate.fare.addOnPrice', 0);
    const userTypeCurrent = _.get(this.props.auth, 'user.userType', '');
    const isShowEditFareIconTransport =
      (allowEditFare || allowMarkupPrice) &&
      bookType !== BOOK_TYPE_FEE.parcel &&
      showIconEditFare &&
      !checkBookingPrePaided(this.props.data) &&
      !isCompletedBooking &&
      statusBooking !== 'allocated';

    return (
      <>
        {
          /* render Receivable, fare or total fare for 3rd booking */
          is3rdBooking ? (
            <>
              <div className="item">
                <Translate value="newbooking.Receivable" />
                {this.renderReceivableFare()}
              </div>

              {
                // booking holidaytaxis then can edit total fare
                data?.externalInfo?.thirdParty ===
                  thirdPartyIntegration.holidaytaxis && (
                  <div className="item">
                    <Translate value="newbooking.Fare" />
                    <span>
                      {this.renderTotalFare()}
                      {isShowEditFareIconTransport &&
                        this.renderIconBtnEditFare()}
                    </span>
                    {prevTotalFare && prevEtaFare.isFareEdited ? (
                      <div className="item-original-fare">
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: '#63666f',
                          }}
                        >
                          {currencyFormatter.format(
                            data?.externalInfo?.price || 0,
                            { code: etaFare?.currencyISO }
                          )}
                        </span>
                      </div>
                    ) : null}
                  </div>
                )
              }
            </>
          ) : (
            // render total fare for other booking
            <div className="item">
              {viewHistory ? (
                <div className="booking-dispatch-logs">
                  <div className="view-dispatch-logs">
                    <Button variant="link" onClick={this.handleClickEditFare}>
                      View Edited Fare
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  {bookType === BOOK_TYPE_FEE.parcel ||
                  bookType === BOOK_TYPE_FEE.intercity ? (
                    <Translate value="newbooking.Fare" />
                  ) : (
                    <>
                      {farmIn ? (
                        <Translate value="newbooking.Receivable" />
                      ) : (
                        <Translate value="newbooking.Fare" />
                      )}

                      <span
                        className={
                          addOnPrice !== 0
                            ? addOnPrice > 0
                              ? 'adjust-detail'
                              : 'adjust-slow-detail'
                            : 'farmInFareTrip'
                        }
                      ></span>
                    </>
                  )}
                  <span
                    className={
                      etaFare?.addOnPrice && etaFare?.addOnPrice !== 0
                        ? etaFare?.addOnPrice > 0
                          ? 'adjust-detail'
                          : 'adjust-slow-detail'
                        : null
                    }
                  >
                    {/* icon edit fare delivery booking */}
                    {bookType === BOOK_TYPE_FEE.parcel &&
                      !farmIn &&
                      !isCompletedBooking &&
                      this.renderIconBtnEditFare()}

                    {
                      /* Show fare value */
                      this.renderTotalFare()
                    }

                    {/* icon edit fare transport booking */}
                    {isShowEditFareIconTransport &&
                      this.renderIconBtnEditFare()}
                    {prevTotalFare &&
                    (prevEtaFare.isFareEdited || markupValue) ? (
                      <div className="item-original-fare">
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: '#63666f',
                          }}
                        >
                          {currencyFormatter.format(
                            etaFareLocalOrigin && isHydraBooking
                              ? etaFareLocalOrigin
                              : prevTotalFare,
                            { code: etaFare?.currencyISO }
                          )}
                        </span>
                      </div>
                    ) : null}
                  </span>
                </>
              )}
            </div>
          )
        }

        {/* for flow prepaid, Post Paid: Direct invoicing or book later */}
        {this.props.isShowInfoPrePaid &&
          this.props.renderAmountDueStatusInvoicing()}

        {/* render Driver earning */}
        {this.checkShowDriverEarning() && (
          <div className="item">
            <Translate value="supplier.totalPayout" />
            <span
              className={
                etaFare?.addOnPrice && etaFare?.addOnPrice !== 0
                  ? etaFare?.addOnPrice > 0
                    ? 'adjust-detail'
                    : 'adjust-slow-detail'
                  : null
              }
            >
              <div className="driverEarningCT">
                {bookType === BOOK_TYPE_FEE.parcel &&
                  !farmIn &&
                  !isCompletedBooking &&
                  this.renderIconBtnEditFare()}
                <div>
                  <p style={{ marginBottom: '0px' }}>
                    {etaFare?.supplierEarningType !==
                      PAYOUT_CUSTOM_TYPE.default &&
                      currencyFormatter.format(
                        calculatorPayoutWhenCustom(etaFare, Object.keys(this.props.serviceFeeZone || {}).length ? this.props.serviceFeeZone : this.state.serviceFeeZone, this.props.fareSettings?.fare || this.state.fareSettings?.fare, this.props.auth.selectedFleet),
                        { code: etaFare?.currencyISO }
                      )}
                  </p>
                  <p
                    className={
                      etaFare?.supplierEarningType !==
                      PAYOUT_CUSTOM_TYPE.default
                        ? 'subFare'
                        : ''
                    }
                  >
                    {this.props.supplierCompanies?.length != 1 && !this.props.driver?.phone && etaFare?.supplierEarningType === 'default'
                      ? PAYOUT_TBD
                      : 
                      (!this.props?.driver?.phone && 
                        (
                          this.props.commonData?.companies?.find(item => item._id === this.props.supplierCompanies[0])
                          || this.props.commonData?.suppliers?.find(item => item._id === this.props.supplierCompanies[0])?.commissionCompanyType === 'payToDriver'
                        )
                      )
                        ? PAYOUT_TBD :
                        currencyFormatter.format(
                          calculatorPayoutWhenCustom(
                            {...etaFare, supplierEarningType: 'default'}, 
                            Object.keys(this.props.serviceFeeZone || {}).length ? this.props.serviceFeeZone : this.state.serviceFeeZone, 
                            this.props.fareSettings?.fare || this.state.fareSettings?.fare, 
                            this.props.auth.selectedFleet, 
                            this.props.commonData?.suppliers?.find(item => item._id === (this.props.supplierCompanies[0] || this.props.driver?.company?.companyId)),
                            this.props.bookType,
                            this.props.fareSettings || this.state.fareSettings,
                            this.props.driver,
                          ),
                          { code: etaFare?.currencyISO }
                        )
                      }
                  </p>
                </div>
                {(enableEditDriverEarning ||
                  bookType !== BOOK_TYPE_FEE.parcel) &&
                  !checkBookingPrePaided(this.props.data) &&
                  bookType !== BOOK_TYPE_FEE.parcel &&
                  showIconEditFare &&
                  [userType.FleetAdmin, userType.FleetUser].includes(
                    userTypeCurrent
                  ) &&
                  !isCompletedBooking &&
                  statusBooking !== 'allocated' &&
                  this.renderIconBtnEditFare()}
              </div>
            </span>
          </div>
        )}

        {showModalEditFare && (
          <ModalEditFare
            bookId={this.state.bookId}
            is3rdBooking={this.props.is3rdBooking}
            etaFare={etaFare}
            reasonMarkup={reasonMarkup}
            driverCompany={driverCompany}
            supplierCompanies={this.props.supplierCompanies}
            companiesInfo={this.props.companyList}
            isHydraBooking={isHydraBooking}
            fleetMarkup={fleetMarkup}
            markupType={markupType}
            markupValue={markupValue}
            forceMarkupPrice={forceMarkupPrice}
            allowEditFare={allowEditFare}
            allowMarkupPrice={allowMarkupPrice}
            promoInfo={promoInfo}
            selectedFleet={auth.selectedFleet}
            fareSettings={fareSettings}
            data={this.props.data || {}}
            enableEditDriverEarning={this.checkShowDriverEarning()}
            prevEtaFare={this.props.prevEtaFare}
            isFareEditedBefore={this.props.isFareEditedBefore}
            bookType={this.props.bookType}
            isSubmitted={this.state.isSubmitted}
            valid={this.state.valid}
            handleCloseModalEditFare={this.handleCloseModalEditFare}
            driver={this.props.driver}
            handleSaveEditFare={this.handleSaveEditFare}
            saveHolidayTaxisCompletedEditFare={
              this.saveHolidayTaxisCompletedEditFare
            }
            handleSaveMarkupPrice={this.handleSaveMarkupPrice}
            ValidatorCallback={this.ValidatorCallback}
            viewHistory={viewHistory}
            user={this.props.user}
            settingActions={this.props.settingActions}
            serviceFeeZone={Object.keys(this.props.serviceFeeZone || {}).length ? this.props.serviceFeeZone : this.state.serviceFeeZone}
            commonData={this.props.commonData}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    commonData: state.commonData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFareComponent);
