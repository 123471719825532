import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Translate, I18n } from 'react-redux-i18n';
import { Col, Row, Button, ButtonToolbar, Modal, Container } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import currencyFormatter from 'currency-formatter';
import './intercityBooking.scss';

import * as loadingActions from '../../actions/loadingBarActions';
import * as paymentMethodActions from '../../actions/paymentMethodActions';
import * as newbookingActions from '../../actions/newbookingAction';
import * as intercityBookingActions from '../../actions/intercityBookingActions';
import LocationInfoForm from './LocationInfoForm';
import TravelerInfoForm from './TravelerInfoForm';
import AdditionalServiceForm from './AdditionalServiceForm';
import SeatLuggageForm from './TravelerInfoForm/SeatLuggageForm';
import NoteForm from './AdditionalServiceForm/NoteForm';
import PromotionForm from './PromotionForm';
import DispatchForm from './DispatchForm';
import PaymentForm from './PaymentForm';
import FlightInfo from './FlightInfo'
import MapForm from './MapForm';
import TripEstimate from './TripEstimate';
import { CCLiteCommonFunc } from '../../utils/commonFunctions';
import { socketDispatchApi } from '../../utils/socketUtils';
import { socketConfigs } from '../../constants/socketConfigs';
import {
  LOCATION_TYPE,
  paymentMethodNumber,
  userType,
  SERVICE_TYPE,
} from '../../constants/commondata';
import Confirm from '../../components/confirm/Confirm';
import { tranformAdditionalServicesBeforeSubmit } from './utils';
import * as uploadActions from '../../actions/uploadActions';
import TrailNotesNewBooking from '../../components/bookingDetail/bookComponent/trailNotes/trailNoteNewBooking.js';
class NewIntercityBooking extends Component {
  constructor(props) {
    super();
    this.state = {
      request: {
        routeSelected: null,
        companyId: props.user.roles.companyId,
        departureDate: null,
        departureTime: null,
        paymentType: 0,
        operatorNote: ''
      },
      psgInfo: {
        firstName: '',
        phone: '',
      },
      drvInfo: {
        fullName: '',
      },
      dispatchType: 0,
      forceAssign: false,
      valid: {},
      locationService: {},
      flightScheduleSuggestions: [],
      trailNotes: [],

    };
  }

  componentDidMount() {
    if (this.props.prevState) {
      this.getLocationService()
      this.setState(this.props.prevState, this.props.intercityBookingActions.etaFare);
    } else {
      this.props.paymentMethodActions.paymentMethod(
        this.props.auth.selectedFleet.fleetId
      );
      this.props.intercityBookingActions.fetchCarTypeByCompany({
        companyId: this.props.user.roles.companyId,
        fleetId: this.props.auth.selectedFleet.fleetId,
      });
    }
    this.onSocketDispatch();
  }

  handleAddTrailNotes = (data) => {
    let currentTrailNotes = [...this.state.trailNotes]
    if (currentTrailNotes?.length === 20) return this.context.notification('error', I18n.t('trailNotes.maxComments'))
    const newTrailNote = {
        availability: {
          operator: data.operator,
          driver: data.driver,
          passenger: data.passenger,
        },
        text: data.text,
        files: data.files,
        createdBy: {
          firstName: this.props.auth.user.firstName,
          lastName: this.props.auth.user.lastName,
          userName: this.props.auth.user.userName,
          _id: this.props.auth.user._id
        },
    }
    currentTrailNotes = [newTrailNote, ...currentTrailNotes]
    this.setState({
      trailNotes: currentTrailNotes
    });
  }

  handleDeleteTrailNotes = (index) => {
    const currentTrailNotes = [...this.state.trailNotes]
    currentTrailNotes.splice(index, 1)
    this.setState({
      trailNotes: currentTrailNotes
    });
  }


  getLocationService = () => {
    const { auth, common } = this.props
    const {
      pickupLocation,
      destinationLocation,
    } = common;
    let options = {
      fleetId: auth.selectedFleet && auth.selectedFleet.fleetId
    }
    if (pickupLocation) {
      options = {
        ...options,
        from: {
          geo: [pickupLocation.lng, pickupLocation.lat],
          zipCode: pickupLocation.zipCode
        },
        zipCodeFrom: pickupLocation.zipCode,
      };
    }
    if (destinationLocation && destinationLocation.lat) {
      options = {
        ...options,
        to: {
          geo: [destinationLocation.lng, destinationLocation.lat],
          zipCode: destinationLocation.zipCode
        },
        zipCodeTo: destinationLocation.zipCode
      };
      // if (this.state.extraDestination && this.state.extraDestination.lat) {
      //   options = {
      //     ...options,
      //     to: {
      //       geo: [this.state.extraDestination.lng, this.state.extraDestination.lat]
      //     },
      //     extra: [
      //       {
      //         geo: [this.state.locationDestination.lng, this.state.locationDestination.lat]
      //       }
      //     ]
      //   };
      // }
    }
    if (options.to || options.from) {
      this.props.intercityBookingActions.getService(options).then((response) => {
        const data = response.res || {}
        if (data) {
          let newLocationservice = {
            additionalService: data.additionalService,
            extraAirport: data.extraAirport,
            fromAirport: data.fromAirport,
            toAirport: data.toAirport,
            currency: data.currency
          }
          this.setState({
            locationService: newLocationservice
          })
        }
      })
    }
  }

  handleChangeOperatorNote = (e) => {
    const { request } = this.state;
    request.operatorNote = e.target.value;
    this.setState({ request: { ...request } });
  }

  handleChangeBookingReference = (e) => {
    this.setState({ 
      externalInfo: {
        bookingReference: e.target.value
      }
    });
  };

  handleChangeForm = (key, value) => {
    const { request } = this.state;
    request[key] = value;
    if (key == "paymentType" && value == 1) {
      let originFare;
      if (this.props.common.originFare && this.props.common.originFare.etaFare) {
        originFare = this.props.common.originFare;
      } else {
        originFare = this.props.common.etaFare;
      }
      this.props.intercityBookingActions.updateCommonData({
        etaFare: {
          ...originFare,
          markupDifference: 0,
          reasonMarkup: null,
          markupValue: 0
        },
        originFare: originFare,
        isFareEdited: false,
        reasonEditFare: null,
        prevEtaFare: {
          originFare: originFare,
          isFareEdited: false,
          reasonEditFare: null,
        }
      })
    }
    this.setState(
      { request: { ...request } }
    , () => {
      if(key == "paymentType") {
        let paymentType = this.state.request && this.state.request.paymentType
        this.props.intercityBookingActions.updateCommonData({
          ...this.props.common,
          paymentMethod: this.state.request.paymentType
        })
        this.props.intercityBookingActions.etaFare()
      }
    });
  };

  handleChangeDispatchType = (value) => {
    this.setState({ dispatchType: value })
  }

  handleChangePsgInfo = (info) => {
    const { psgInfo } = this.state;
    this.setState({ psgInfo: { ...psgInfo, ...info } });
  };

  handleChangeDrvInfo = (info) => {
    const { drvInfo } = this.state;
    this.setState({ drvInfo: { ...drvInfo, ...info } });
  }

  handleChangeETAFare = (fareEdited, originFare, reasonEditFare, isFareEdited) => {
    let shortTrip = this.props?.auth?.selectedFleet?.waiveOffCommission?.enable ? 
      (fareEdited?.etaFare > this.props?.auth?.selectedFleet?.waiveOffCommission?.amountByCurrencies[0]?.value
      ? false : true) : originFare?.shortTrip;
    this.props.intercityBookingActions.updateCommonData({
      etaFare: {
        ...fareEdited,
        markupDifference: 0,
        reasonMarkup: null,
        markupValue: 0,
        shortTrip: shortTrip
      },
      originFare,
      reasonEditFare,
      isFareEdited,
    })
  }

  handleChangeMarkupPrice = (originFare, markupDifference, reasonMarkup, markupType, markupValue, isFareRested) => {
    this.props.intercityBookingActions.updateCommonData({
      etaFare: {
        ...originFare,
        markupDifference,
        reasonMarkup,
        markupType,
        markupValue: parseFloat(markupValue)
      },
      originFare,
      isFareEdited: !isFareRested,
      reasonEditFare: null,
      prevEtaFare: {
        originFare,
        isFareEdited: !isFareRested,
        reasonEditFare: null,
      }
    });
  }

  validatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      let oldMessages = this.state.errorMessages;
      if (!valid) {
        if (!oldMessages) oldMessages = [];
        oldMessages = oldMessages.concat(messages);
      }
      this.setState({
        valid: { ...this.state.valid },
        errorMessages: oldMessages,
      });
    }
  };

  checkFromToFlight = () => {
    const { common } = this.props
    const { locationService } = this.state
    const { pickupLocation, destinationLocation } = common
    const isFromAirport = pickupLocation
      && locationService
      && locationService.additionalService
      && locationService.additionalService.fromAirport
      && locationService.additionalService.fromAirport.isActive
      && ((locationService.fromAirport
        && locationService.fromAirport._id)
        || (locationService.airport
          && locationService.airport[0])
      )
    const isToAirport = destinationLocation
      && locationService
      && locationService.additionalService
      && locationService.additionalService.toAirportNew
      && locationService.additionalService.toAirportNew.isActive
      && ((locationService.toAirport
        && locationService.toAirport._id)
        || (locationService.airport
          && locationService.airport[1])
      )
    // const isToExtraAirPort = destinationLocation
    //   && this.state.extraDestination
    //   && this.props.newBooking
    //   && this.props.newBooking.locationService
    //   && this.props.newBooking.locationService.additionalService
    //   && (
    //     (this.props.newBooking.locationService.additionalService.toAirportNew
    //       && this.props.newBooking.locationService.additionalService.toAirportNew.isActive)
    //     || (this.props.newBooking.locationService.additionalService.fromAirport
    //       && this.props.newBooking.locationService.additionalService.fromAirport.isActive)
    //   )
    //   && (this.props.newBooking.locationService.extraAirport
    //     && this.props.newBooking.locationService.extraAirport[0]
    //     && this.props.newBooking.locationService.extraAirport[0]._id)
    // )
    return {
      isFromAirport: isFromAirport ? true : false,
      isToAirport: isToAirport ? true : false
    }
  }

  newBookingRequestObjectBuilder = () => {
    const { request, psgInfo, forceAssign, dispatchType, locationService } = this.state;
    const {
      common,
      auth,
      commonData: { location },
    } = this.props;
    const {
      pickupLocation,
      destinationLocation,
      routeSelected,
      carType,
      pickupAddress,
      pickupFrom,
      destinationFrom,
      destinationAddress,
      pickupTime,
      pickupDate,
      promo,
      etaFare,
      originFare,
      reasonEditFare,
      isFareEdited,
      luggageNumber,
      paxNumber,
      additionalService,
      additionalServices,
      flightService
    } = common;
    const markupDifference = etaFare && etaFare.markupDifference || 0;
    const object = {
      mapProvider: 'google',
      platform: 'Web',
      bookFrom: 'CC',
      dispatchType,
      dispatcherId: auth.user._id,
      forceAssign,
      jupiter: {
        jobType: 'intercity',
      },
      intercityInfo: {
        routeId: routeSelected._id, // Intercity route ID
        routeNumber: routeSelected.routeNumber, // enum: [1, 2]
      },
      fleetId: this.props.auth.selectedFleet.fleetId,
      trailsNotes: this.state.trailNotes,
      request: {
        vehicleTypeRequest: carType.vehicleType,
        companyId: request.companyId,
        pickup: {
          geo: [pickupLocation.lng, pickupLocation.lat],
          city: pickupLocation.city,
          timezone: pickupLocation.timezone,
          cityName: pickupLocation.cityName,
          address: pickupAddress,
          zipCode: pickupLocation.zipCode,
          country: pickupLocation.countryCode,
          from:
            pickupFrom == LOCATION_TYPE.google && location.isChina
              ? LOCATION_TYPE.Tencent
              : pickupFrom,
          instructionLink: pickupLocation.instructionLink || '',
          addressDetails: pickupLocation.addressDetails
        },
        destination: {
          geo: [destinationLocation.lng, destinationLocation.lat],
          city: destinationLocation.city,
          cityName: destinationLocation.cityName,
          address: destinationAddress,
          zipCode: destinationLocation.zipCode,
          country: destinationLocation.countryCode,
          from:
            destinationFrom == LOCATION_TYPE.Google && location.isChina
              ? LOCATION_TYPE.Tencent
              : destinationFrom,
          instructionLink: destinationLocation.instructionLink || '',
          addressDetails: destinationLocation.addressDetails
        },
        pickUpTime: moment(pickupDate)
          .hour(pickupTime.hour)
          .minute(pickupTime.minute)
          .format('YYYY-MM-DD HH:mm'),
        vehicleType: carType.dispatch || [carType._id],
        fleetId: auth.selectedFleet.fleetId,
        special: '',
        tip: 0,
        promo: promo ? promo.promoCode : '',
        promoValue: promo ? promo.promoValue : '',
        estimate: {
          distance: this.state.disDur ? this.state.disDur.distance.text : '',
          time: this.state.disDur ? this.state.disDur.duration.text : '',
          estimateValue: this.state.disDur
            ? this.state.disDur.duration.value
            : '',
          fare: etaFare ? {
            ...etaFare,
            unroundedTotalAmt: etaFare.unroundedTotalAmt + markupDifference,
            totalWithoutPromo: etaFare.totalWithoutPromo + markupDifference,
            etaFare: etaFare.etaFare + markupDifference,
          } : {},
          originFare: originFare || {},
          reasonEditFare: reasonEditFare || "",
          isFareEdited: isFareEdited || false,
          isNormalFare: etaFare ? etaFare.normalFare : true,
          reasonMarkup: etaFare && etaFare.reasonMarkup || '',
          markupType: etaFare && etaFare.markupType || 'amount',
          markupValue: etaFare && etaFare.markupValue || 0
        },
        markupDifference,
        type: 0,
        typeRate: 0,
        paymentType: request.paymentType,
        notes: request.notes,
        operatorNote: request.operatorNote,
        paxNumber: paxNumber || 1,
        luggageNumber: luggageNumber || 0
      },
      externalInfo: {
        bookingReference: this.state?.externalInfo?.bookingReference || ''
      },
      travelerType: 0,
      psgInfo: psgInfo,
      drvInfo: {},
      ack: 'vtdurh0n8gh',
      hwMetered: false,
      operator: {
        userId: auth.user._id,
        name: `${auth.user.firstName || ''
          } ${auth.user.lastName || ''} `,
        role:
          auth.user.userType == userType.CorporateAdmin ||
            auth.user.userType == userType.CorporateUser
            ? 'corporate'
            : 'cc',
      },
      pricingType: 0,
    };
    const fromToFlight = this.checkFromToFlight()

    // check request type
    if (fromToFlight.isFromAirport) {
      object.request.type = 1;
    }
    if (this.isToAirport && object.request.type != 1) {
      object.request.type = 2;
    }


    // need to fix with transport because dont see FaFlightID
    if (fromToFlight.isFromAirport || fromToFlight.isToAirport) {
      object.request.moreInfo = {
        flightInfo: {
          flightNumber:
            locationService.additionalService.fromAirport.flightInfo
              || locationService.additionalService.toAirportNew.flightInfo
              ? flightService.flightNumber
              : '',
          type: flightService.meetDriver === 0 ? 0 : 1
        }
      };
      // if (this.state.flight) {
      //   object.request.moreInfo.flightInfo.airlineIcao = this.state.flight.icao;
      //   object.request.moreInfo.flightInfo.airlineIATA = this.state.flight.iata;
      // }
      let flightScheduleCommon = flightService && flightService.flightSchedule
      if (
        this.props.auth.selectedFleet?.process?.flight
        && flightScheduleCommon
      ) {
        object.request.moreInfo.flightInfo.ident = flightScheduleCommon.ident;
        object.request.moreInfo.flightInfo.actual_ident = flightScheduleCommon.actual_ident;
        object.request.moreInfo.flightInfo.departuretime = flightScheduleCommon.departuretime;
        object.request.moreInfo.flightInfo.arrivaltime = flightScheduleCommon.arrivaltime;
        object.request.moreInfo.flightInfo.origin = flightScheduleCommon.origin;
        object.request.moreInfo.flightInfo.destination = flightScheduleCommon.destination;
        object.request.moreInfo.flightInfo.aircrafttype = flightScheduleCommon.aircrafttype;
        object.request.moreInfo.flightInfo.meal_service = flightScheduleCommon.meal_service;
        object.request.moreInfo.flightInfo.seats_cabin_first = flightScheduleCommon.seats_cabin_first;
        object.request.moreInfo.flightInfo.seats_cabin_business = flightScheduleCommon.seats_cabin_business;
        object.request.moreInfo.flightInfo.seats_cabin_coach = flightScheduleCommon.seats_cabin_coach;
      }

      // if (
      //   object.request.type == 1
      //   && locationService.additionalService.fromAirport.meetDriver
      // ) {
      //   object.request.moreInfo.flightInfo.type = parseInt(this.state.data.meetDriver || 0);
      // }
    }


    if (object.psgInfo.phone && object.psgInfo.phone.length >= 6) {
      let phone = '';
      for (let i = 0; i < object.psgInfo.phone.length; i++) {
        const c = object.psgInfo.phone[i];
        if (c == '+' || parseInt(c) == 0 || !!parseInt(c)) {
          phone += c;
        }
      }
      object.psgInfo.phone = phone;
    }
    if (object.request.paymentType === paymentMethodNumber.personalCard) {
      object.psgInfo.creditInfo = _.pick(object.psgInfo.creditInfo, [
        'localToken',
        'crossToken',
        'cardMask',
      ]);
    }

    if(!_.isEmpty(etaFare)) {
      object.request.sessionToken = etaFare.sessionToken
    }

    const servicesMatched = _.filter(additionalServices, service => service.vehicleType.findIndex(o => o.vehicleType === carType.vehicleType) !== -1)
    if (servicesMatched.length) {
      object.request.services = tranformAdditionalServicesBeforeSubmit(servicesMatched, additionalService, routeSelected.currencyISO)
    };

    if (object.dispatchType == 1) {
      object.drvInfo = {
        phone: this.state.drvInfo.phone
      };
    }
    return object;
  };

  checkRequiredField = () => {
    const {
      common,
    } = this.props;
    const requiredFields = [
      'pickupLocation',
      'destinationLocation',
      'routeSelected',
      'carType',
      'pickupAddress',
      'pickupFrom',
      'destinationFrom',
      'destinationAddress',
      'pickupTime',
      'pickupDate',
      'etaFare'
    ]
    return _.some(requiredFields, field => _.isNil(common[field]) || _.isNull(common[field]))
  }

  handleSaveBookingClick = (e) => {
    e && e.preventDefault();
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }

    if (!CCLiteCommonFunc.isFormValid(this.state.valid) || this.checkRequiredField()) {
      return;
    }
    if (this.state.request.paymentType == paymentMethodNumber.personalCard && !this.state.psgInfo.creditInfo) {
      this.context.notification('error', I18n.t('messages.booking.Please_select_or_add_a_card'));
      return;
    }
    this.props.loadingActions.showLoadingSpiner();
    const {
      auth: { selectedFleet },
      commonData,
      common
    } = this.props;
    const { psgInfo } = this.state;
    if (psgInfo.phone) {
      let customerObject = {
        fleetId: selectedFleet.fleetId,
        phone: psgInfo.phone
          .replace(new RegExp(' ', 'g'), '')
          .replace(new RegExp('-', 'g'), ''),
        firstName: psgInfo.firstName || '',
        lastName: psgInfo.lastName || '',
        email: psgInfo.email || '',
        rank: psgInfo.rank || '',
        tips: selectedFleet.fleetFareId ? selectedFleet.fleetFareId.tips : 0,
      };
      // SL-23585 Save 'company' for customer by Company of Booking request
      if (common.companyId) {
        let companyData = [...commonData.companies, ...commonData.suppliers].find(c => c._id === common.companyId);
        if (companyData) {
          customerObject.company = {
            companyId: companyData._id,
            name: companyData.name
          };
        };
      };
      this.props.newbookingActions
        .checkCustomerBooking(customerObject)
        .then((data) => {
          if (data.ok && data.res && data.res.customer) {
            if (!this.state.psgInfo) {
              this.state.psgInfo = data.res.customer;
            }
            const bookingObj = this.newBookingRequestObjectBuilder();
            this.doSendNewBookingToDispatch(bookingObj)
          } else {
            this.props.loadingActions.hideLoadingSpiner();
            this.context.notification(
              'error',
              I18n.t('messages.booking.Check_customer_error'),
              data.message
            );
          }
        });
    } else {
      const bookingObj = this.newBookingRequestObjectBuilder();
      if (bookingObj) {
        this.props.newbookingActions.createNewBooking(bookingObj)
        .then((res) => {
          this.handleResultCreateBooking(res)
        })
      } else {
        this.props.loadingActions.hideLoadingSpiner();
      }
    }
  };

  doSendNewBookingToDispatch = (bookingObj) => {
    this.props.newbookingActions.createNewBooking(bookingObj)
    .then((res) => {
      this.handleResultCreateBooking(res)
    })
  }

  handleResultCreateBooking = (data) => {
    if (data.code) {
      this.props.loadingActions.hideLoadingSpiner();
      this.context.notification(
        'success',
        I18n.t('messages.booking.Create_new_booking_success')
      );
      this.setState({ preAuthBookId: null });
      this.props.intercityBookingActions.closeIntercityBookingForm();
    } else {
      // verify card pre-auth
      const preAuthCode = _.get(data, 'info.chargedResult.returnCode');
      if (preAuthCode === 113) {
        this.setState({
          bookId: data.bookId,
        });
        return this.verifyStripeSCA(
          data.info.chargedResult.response.clientSecret
        );
      }
      this.props.loadingActions.hideLoadingSpiner();
      const errMgs = this.showMessageErrorBooking(data);
      if (errMgs) {
        this.context.notification('error', errMgs);
      }
    }
  }

  onSocketDispatch = () => {
    socketDispatchApi.remove(
      socketConfigs.receive.booking.chargeIntercityBooking_result
    );
    socketDispatchApi.on(
      socketConfigs.receive.booking.chargeIntercityBooking_result,
      (data) => {
        const { bookId } = this.state;
        if (
          !bookId ||
          !data.bookId ||
          bookId !== data.bookId
        ) {
          return;
        }

        if (data.code) {
          this.props.loadingActions.hideLoadingSpiner();
          this.context.notification(
            'success',
            I18n.t('messages.booking.Create_new_booking_success')
          );
          this.setState({ preAuthBookId: null });
          this.props.intercityBookingActions.closeIntercityBookingForm();
        } else {
          //verify card pre-auth
          const preAuthCode = _.get(data, 'info.chargedResult.returnCode');
          if (preAuthCode === 113) {
            this.setState({
              clientSecret: data.info.chargedResult.response.clientSecret,
            });
            return this.verifyStripeSCA(
              data.info.chargedResult.response.clientSecret
            );
          }
          this.props.loadingActions.hideLoadingSpiner();
          this.context.notification(
            'error',
            this.showMessageErrorBooking(data)
          );
        }
      }
    );
  };

  showMessageErrorBooking = (data) => {
    let msg = <Translate value="messages.booking.data_incorect" dangerousHTML />
    if(data.errorCode === "EXTERNAL_ID_EXISTED" || data.error.errorCode === "EXTERNAL_ID_EXISTED") {
      msg = I18n.t('messages.booking.EXTERNAL_ID_EXISTED');
      return msg;
    }
    if (data.code) {
      msg = I18n.t('messages.booking.' + data.code);
      const dataInfo = data.info || data.error;
      if (data.code == 303) {
        if (data.error.minimumBookAhead) {
          let hour = 0;
          let min = data.error.minimumBookAhead % 60;
          if (data.error.minimumBookAhead > 0) {
            hour = Math.floor(data.error.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (data.error.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            data.error.maximumBookAhead
          );
        }
      } else if (dataInfo.exDoDiffDo === false) {
        msg = I18n.t('messages.booking.exDoDiffDo');
      }
    } else {
      const dataInfo = data.info || data.error;
      if (dataInfo.limit === false) {
        msg = I18n.t('messages.booking.booking_limited');
      } else if (dataInfo.sameZone === false && dataInfo.crossZone === false) {
        msg = I18n.t('messages.booking.area_service_unavailable');
      } else if (!dataInfo.rate) {
        msg = I18n.t(
          'messages.booking.Please_reload_page_or_check_your_connection'
        );
      } else if (!dataInfo.pickUpTime) {
        msg = I18n.t('messages.booking.time_invalid');
        if (dataInfo.minimumBookAhead) {
          let hour = 0;
          let min = dataInfo.minimumBookAhead % 60;
          if (dataInfo.minimumBookAhead > 0) {
            hour = Math.floor(dataInfo.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (dataInfo.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            dataInfo.maximumBookAhead
          );
        }
        if (dataInfo.exDoDiffDo === false) {
          msg = I18n.t('messages.booking.exDoDiffDo');
        }
      } else if (
        dataInfo.psgInfo === false ||
        dataInfo.psgInfo.isActive === false
      ) {
        msg = I18n.t('messages.booking.psg_inactivate');
      } else if (dataInfo.preAuthorized === false) {
        if (dataInfo.preAuthorizedCode) {
          msg = I18n.t('messages.credits.' + dataInfo.preAuthorizedCode);
        }
      } else if (dataInfo.cannotAssignDriver) {
        msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated');
      } else if (dataInfo.overlapTime) {
        msg = I18n.t('messages.booking.driver_is_not_available');
      } if (dataInfo.seatsLuggageLimit === 0) {
        msg = I18n.t('messages.booking.seats_luggage_limit');
      } else if (dataInfo.fullCapacity === 0) {
        msg = I18n.t('messages.booking.full_capacity');
      } else if (dataInfo.promo) {
        switch (dataInfo.promo) {
          case 10:
            msg = I18n.t('messages.promoMsg.430')
            break;
          case 11:
            msg = I18n.t('messages.promoMsg.431')
            break;
          case 12:
            msg = I18n.t('messages.promoMsg.432')
            break;
          case 13:
            msg = I18n.t('messages.promoMsg.433')
            break;
          default:
            break;
        }
      }
    }
    return msg;
  };

  verifyStripeSCA = (clientSecret) => {
    const { auth } = this.props;
    const stripe = window.Stripe(auth.selectedFleet.stripePublicKey);
    stripe.handleCardPayment(clientSecret).then((result) => {
      if (result.error) {
        // Display error.message in your UI.
        this.props.loadingActions.hideLoadingSpiner();
        const errorMessage = result.error.message || result.error.code;
        this.context.notification('error', errorMessage);
      }
    });
  };

  handleCancelBookingClick = () => {
    this.setState({ isShowCancelMessage: !this.state.isShowCancelMessage });
  };

  showConfirmForceOverlap = () => {
    this.setState({
      showConfirmForceOverlap: {
        title: I18n.t('bookingdetail.confirm_force_overlap'),
        body: I18n.t('bookingdetail.confirm_force_overlap_message'),
        buttonTitle: I18n.t('bookingdetail.Yes'),
        closeButtonClass: 'btn-cancel',
        closeButtonText: I18n.t('bookingdetail.No')
      },
      forceAssign: true
    });
  }

  handleCloseConfirmForceOverlapModal = () => {
    this.setState({ showConfirmForceOverlap: null, forceAssign: false });
  };

  handleConfirmForceOverlapClick = () => {
    this.handleSaveBookingClick();
    this.setState({ showConfirmForceOverlap: null });
  };


  render() {
    const { auth, commonData, common } = this.props;
    const { request, psgInfo, drvInfo, dispatchType, valid, isSubmited } = this.state;
    return (
      <ReactCSSTransitionGroup
        transitionName="bounceOutLeft"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
        transitionAppear
        transitionLeave
      >
        <div key="intercityBookingForm" className="intercityBookingForm">
          <div className="bookingTitle">
            <span className="bookingTitle__text"><Translate value="Sidebar.Intercity" /></span>
          </div>
          <Container fluid>
            <Row>
              <Col xs={12} md={7} className="mt">
                <Container>
                  <Row className=''>
                    <Col xs={12} sm={4} className="info-content">
                      <LocationInfoForm
                        onChange={this.handleChangeForm}
                        request={request}
                        valid={valid}
                        isSubmited={isSubmited}
                        getLocationService={this.getLocationService}
                        validatorCallback={this.validatorCallback}
                      />
                    </Col>
                    <Col xs={12} sm={4} className="info-content">
                      <FlightInfo
                        locationService={this.state.locationService}
                        // selectedFleet={this.props.auth && this.props.auth.selectedFleet}
                        flightScheduleSuggestions={this.state.flightScheduleSuggestions}
                      // onFlightScheduleSuggestionsFetchRequested={
                      //   this.onFlightScheduleSuggestionsFetchRequested
                      // }
                      // onFlightScheduleSuggestionsClearRequested={
                      //   this.onFlightScheduleSuggestionsClearRequested
                      // }
                      // flightScheduleAutocompleteSlectedHandle={
                      //   this.flightScheduleAutocompleteSlectedHandle
                      // }
                      // flightSchedule={this.state.flightSchedule}
                      // handleFlightNumerChange={this.handleFlightNumerChange}
                      // flightScheduleAplyButtonClick={this.flightScheduleAplyButtonClick}
                      // bookingCurrency={bookingCurrency}
                      // handleMeetdriverChange={this.handleMeetdriverChange}
                      // data={this.state.data}
                      // flightNumber={commonData.flightNumber}
                      // locationPickUp={common.pickupLocation}
                      // locationDestination={common.locationDestination}
                      // extraDestination={this.state.extraDestination}
                      // handleChangeNote={this.handleChangeNote}
                      // meetDriver={this.state.data.meetDriver || '0'}
                      />
                      <TravelerInfoForm
                        onChange={this.handleChangePsgInfo}
                        psgInfo={psgInfo}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                      />
                      <SeatLuggageForm
                        valid={valid}
                        isSubmited={isSubmited}
                        request={request}
                        validatorCallback={this.validatorCallback}
                      />
                      <AdditionalServiceForm
                        onChange={this.handleChangeForm}
                        request={request}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                      />
                      <NoteForm
                        onChange={this.handleChangeForm}
                        value={request.notes}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                      />
                    </Col>
                    <Col xs={12} sm={4} className="info-content">
                      <PromotionForm
                        request={request}
                        psgInfo={psgInfo}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                      />
                      <DispatchForm
                        onChange={this.handleChangeDispatchType}
                        onChangeDrvInfo={this.handleChangeDrvInfo}
                        dispatchType={dispatchType}
                        request={request}
                        drvInfo={drvInfo}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                      />
                      <PaymentForm
                        onChange={this.handleChangeForm}
                        onChangePsgInfo={this.handleChangePsgInfo}
                        handleChangeOperatorNote={this.handleChangeOperatorNote}
                        request={request}
                        psgInfo={psgInfo}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                        externalInfo={this.state.externalInfo}
                        handleChangeBookingReference={this.handleChangeBookingReference}
                      />
                    </Col>
                    <Col xs={12} className="intercityBookingForm__buttonContainer">
                      <ButtonToolbar className="text-center">
                        <Button
                          className="btn-save mr"
                          onClick={(e) => this.handleSaveBookingClick(e)}
                        >
                          <Translate value="newbooking.Create" />
                        </Button>
                        <Button
                          className="btn-cancel"
                          onClick={this.handleCancelBookingClick}
                        >
                          <Translate value="newbooking.Cancel" />
                        </Button>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs={12} md={5} style={{ height: '100%' }}>
                <div className="booking-map-container">
                  <MapForm />
                  <TripEstimate
                    request={request}
                    prevEtaFare={this.props.common}
                    handleChangeETAFare={this.handleChangeETAFare}
                    handleChangeMarkupPrice={this.handleChangeMarkupPrice} />                
                  {/* <div style={{marginTop: "20px"}}>
                    <TrailNotesNewBooking
                      notification={this.context.notification}
                      listTrailNotes={this.state.trailNotes}
                      handleAddTrailNotes={this.handleAddTrailNotes}
                      handleDeleteTrailNotes={this.handleDeleteTrailNotes}
                      uploadService={this.props.uploadService}
                      fleetId={this.props.auth.selectedFleet.fleetId}
                    />
                  </div> */}

                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {this.state.isShowCancelMessage ? (
          <Modal
            show
            backdrop
            key="modal-form"
            dialogClassName="confirm-dialog"
            className="confirm"
            onHide={this.handleCancelBookingClick}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="newbooking.CANCEL_BOOKING" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Translate value="newbooking.CANCEL_BOOKING_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.props.intercityBookingActions.closeIntercityBookingForm();
                }}
                className="btn-save"
              >
                <Translate value="newbooking.Yes" />
              </Button>
              <Button className="btn-cancel" onClick={this.handleCancelBookingClick}>
                <Translate value="newbooking.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        <Confirm
          confirm={this.state.showConfirmForceOverlap}
          handleConfirmCloseClick={this.handleCloseConfirmForceOverlapModal}
          handleConfirmButtonClick={this.handleConfirmForceOverlapClick}
        />
      </ReactCSSTransitionGroup>
    );
  }
}
NewIntercityBooking.contextTypes = {
  notification: PropTypes.func,
};
NewIntercityBooking.propTypes = {
  auth: PropTypes.object,
  newBooking: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    auth: state.auth,
    socket: state.socket,
    commonData: state.commonData,
    common: state.intercityBooking.common,
    prevState: state.intercityBooking.prevState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadingActions: bindActionCreators(loadingActions, dispatch),
    paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
    uploadService: bindActionCreators(uploadActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewIntercityBooking);
