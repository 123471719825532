import React, { useMemo } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';

function CompanySelect (props) {
  const handleChange = (e) => {
    const selectedCompany = _.find([...props.companies, ...props.suppliers], o => o._id === e.target.value);
    props.onChange(selectedCompany)
    props.intercityBookingActions.fetchCarTypeByCompany({ 
      fleetId: props.selectedFleet.fleetId,
      companyId: e.target.value != -1 ? e.target.value : null
    })
  }

  const isPermissionCompany = useMemo(() => !!props.user.roles.companyId, [props.user]);

  if (!props.routeSelected) {
    return null;
  }
  
  return (
    <FormGroup>
      <Form.Label>
        <Translate value="newbooking.Company" />
        &nbsp;
        <span className="require"> *</span>
      </Form.Label>
      <FormControl
        as="select"
        placeholder="select"
        className={"form-custom"}
        onChange={handleChange}
        value={props.value}
        style={{ marginBottom: 0 }}
        disabled={props.disabled}
      >
        {isPermissionCompany ? null : <option value={-1}>{I18n.t("newbooking.All_company")}</option>}
        {[...props.companies, ...props.suppliers].map(comp => {
          return (
            <option
              key={comp._id}
              value={comp._id}
            >
              {comp.name}
            </option>
          );
        })}
      </FormControl>
    </FormGroup>
  )
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedFleet: state.auth.selectedFleet,
    routeSelected: state.intercityBooking.common.routeSelected,
    companies: state.commonData.companies,
    suppliers: state.commonData.suppliers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    intercityBookingActions: bindActionCreators(intercityBookingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanySelect);