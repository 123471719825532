import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../settings.scss';
import {
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar,
  DropdownButton,
  ButtonGroup,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import * as settingActions from '../../../actions/settingActions';
import * as paymentMethodActions from '../../../actions/paymentMethodActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import { Validator, ValidCase } from '../../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  MultiSelectCommonFunc,
  textboxNumberHelper,
  convertToMinutes,
  convertToHours,
  convertToMinutesFromType,
} from '../../../utils/commonFunctions';
import { CompanyColumns } from './tableHeaderData';
import TableActions from '../../../components/table/tableAction/TableActions';
import Select from '../../../components/select/Select';
import { socketDispatchApi } from '../../../utils/socketUtils';
import { PAYMENTS_ONLY_APP, UNIT_TIME } from '../../../constants/commondata';
import { socketConfigs } from '../../../constants/socketConfigs';
import StickyTable from '../../../components/table/stickyTable/StickyTable';
import { convertTextStatus } from '../../../utils/commonFunctions';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import _ from 'lodash';
import copy from 'copy-to-clipboard';
import QuestionCircleTooltip from '../../../components/questionCircleTooltip/QuestionCircleTooltip';
import { BsSearch } from 'react-icons/bs';

class Company extends Component {
  constructor() {
    super();
    this.state = {
      companies: null,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      sortType: -1,
      valid: {},
      isSubmitted: false,
      editable: false,
      tableHeight: 500,
      isShowModalDisconnectStripe: false,
      rowHeight: 50,
      paymentMethodState: [],
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
      },
      str: '',
    };
  }

  hanldeOpenDisconnectModal = () => {
    this.setState({ isShowModalDisconnectStripe: true });
  };

  handleCloseModal = () => {
    this.setState({ isShowModalDisconnectStripe: false });
  };

  componentDidMount() {
    this.updateCompaniesList();
    this.props.paymentMethodActions
      .paymentMethod(this.props.auth.selectedFleet.fleetId)
      .then((data) => {
        const paymentMethodFleet = data?.res || {};
        let paymentMethods = [
          ...paymentMethodFleet.individualTypes,
          ...paymentMethodFleet.corporateTypes,
          ...paymentMethodFleet.type3rd,
        ];
        paymentMethods = Array.from(
          new Set(paymentMethods.map((a) => a.key))
        ).map((key) => {
          return paymentMethods.find((a) => a.key === key);
        });

        // remove paymentType chi support tren app
        _.remove(paymentMethods, (pm) => PAYMENTS_ONLY_APP.includes(pm.key));

        this.setState({
          paymentMethodState: paymentMethods,
        });
      });
    if (this.props?.commonData?.mapZone) {
      this.setState({
        fleetZones: _.filter(
          this.props?.commonData?.mapZone,
          (z) => z.isActive && z.display
        ),
      });
    }
  }

  handleSearchChange = (e) => {
    let str = e.target.value;
    this.setState({ str: str });
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.updateCompaniesList();
    }
  };

  updateCompaniesList = (activePage, limit) => {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId,
      search: this.state.str,
    };

    this.props.settingActions.getAllCompany(params).then((data) => {
      let { page, total, limit } = data.res;
      if (data.ok && data.res) {
        this.setState({
          companies: data.res,
          paymentMethodsApply: this.renderOldPayment(),
          operationZone: this.renderOldOperationZone(),
          footerData: { page, total, limit },
        });
      }
    });
  };

  saveDialogForm = async () => {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.state.dialogData.fleetId = this.props.auth.selectedFleet.fleetId;
    if (!this.state.dialogData.name.trim()) {
      return;
    }
    this.props.loadingBarActions.showLoadingSpiner();
    if (this.state.dialogData._id) {
      const delayBroadcast = this.state.dialogData.delayBroadcast || {};
      let objectCompany = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        name: this.state.dialogData.name,
        commissionValue: this.state.dialogData.commissionValue,
        companyId: this.state.dialogData._id,
        isHideFare: this.state.dialogData.isHideFare || false,
        broadcastBooking: this.state.dialogData.broadcastBooking || false,
        delayBroadcast: {
          valueInMinute: convertToMinutesFromType(
            delayBroadcast?.value,
            delayBroadcast?.unit
          ),
          value: delayBroadcast?.value || 0,
          unit: delayBroadcast?.unit || UNIT_TIME.Minute,
        },
        emails: this.state.dialogData?.emails || [],
        paymentMethodsApply: this.state.dialogData.paymentMethodsApply || [],
        operationZone: this.state.dialogData.operationZone || [],
        advanceBookingVisibility:
          this.state.dialogData.advanceBookingVisibility || 7,
        releaseTime: convertToMinutes(
          this.state.dialogData.releaseTime || '00:00'
        ),
      };
      objectCompany.connectToken = '';
      // Check existing stripe
      if (this.state.dialogData.connectToken) {
        this.props.loadingBarActions.showLoadingSpiner();
        const dataResponse =
          await this.props.settingActions.checkExistingStripe({
            fleetId: this.props.auth.selectedFleet.fleetId,
            token: this.state.dialogData.connectToken,
          });
        const status = _.get(dataResponse, 'res.response.status', '');
        if (status === 'activated') {
          objectCompany.connectToken = this.state.dialogData.connectToken;
        } else {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (status === 'pending') {
            this.context.notification(
              'error',
              'The stripe account id you provided is in-review, please try again'
            );
            return;
          }
          this.context.notification(
            'error',
            'The stripe account id you provided is incorrect, please try again'
          );
          return;
        }
      }

      this.props.settingActions.updateCompany(objectCompany).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            if (data.error.status === 411) {
              if (data.error.errorCode === 5002) {
                this.context.notification(
                  'error',
                  I18n.t(
                    'Unable to to delete a zone that has been assigned to a supplier'
                  )
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t(
                    'Unable to to delete a zone that has been assigned to a driver'
                  )
                );
              }
            } else {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            }
          } else {
            this.context.notification(
              'error',
              I18n.t('companySetting.Update_company_fail')
            );
          }
        } else {
          this.context.notification(
            'success',
            I18n.t('companySetting.Update_company_success')
          );
          // setTimeout(() => {/
          this.closeDialogForm();
          this.updateCompaniesList();
          this.setState({ isSubmited: false });
        }
      });
    } else {
      const params = {
        ...this.state.dialogData,
        releaseTime: convertToMinutes(this.state.dialogData.releaseTime),
        delayBroadcast: {
          ...this.state.dialogData?.delayBroadcast,
          valueInMinute: convertToMinutesFromType(
            this.state.dialogData?.delayBroadcast?.value,
            this.state.dialogData?.delayBroadcast?.unit
          ),
        },
      };
      this.props.settingActions.createCompany(params).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('companySetting.Create_company_fail')
            );
          }
        } else {
          this.context.notification(
            'success',
            I18n.t('companySetting.Create_company_success')
          );
          this.closeDialogForm();
          this.updateCompaniesList();
          this.setState({ isSubmited: false });
        }
      });
    }
  };

  handleHideFareChange = (e) => {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        isHideFare: e.target.checked,
      },
      dialogChanged: true,
    });
  };

  handleBroadcastBookingChange = (e) => {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        broadcastBooking: e.target.checked,
      },
      dialogChanged: true,
    });
  };

  handleChangeReleaseTime = (e) => {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        releaseTime: e.target.value,
      },
      dialogChanged: true,
    });
  };

  handleChangeAdvanceBookingVisibility = (e) => {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        advanceBookingVisibility: e.target.value,
      },
      dialogChanged: true,
    });
  };

  handleChangeDelayBroadcastType = (e) => {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        delayBroadcast: {
          ...this.state.dialogData?.delayBroadcast,
          unit: e.target.value,
        },
      },
      dialogChanged: true,
    });
  };

  handleChangeDelayBroadcastValue = (e) => {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        delayBroadcast: {
          ...this.state.dialogData?.delayBroadcast,
          value: e.target.value,
        },
      },
      dialogChanged: true,
    });
  };

  handlePaymentMethodsApplyChange = (e) => {
    let value = MultiSelectCommonFunc.getSelectValues(e);
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        paymentMethodsApply: value,
      },
    });
  };

  handleOperationZoneChange = (e) => {
    let value = MultiSelectCommonFunc.getSelectValues(e);
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        operationZone: value,
      },
    });
  };

  handleNameChange = (e) => {
    this.state.dialogData.name = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };

  handleConnectTokenChange = (e) => {
    this.state.dialogData.connectToken = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };

  handleCommissionChange = (e) => {
    this.state.dialogData.commissionValue = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };

  handleAddButtonClick = () => {
    let objectComany = {
      name: '',
      paymentMethodsApply: this.renderOldPayment(),
      operationZone: this.renderOldOperationZone(),
    };
    this.setState({
      showDialog: true,
      dialogData: objectComany,
      editable: true,
    });
  };

  closeDialogForm = () => {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      editable: false,
    });
  };

  renderOldPayment = (data = {}) => {
    let paymentMethod =
      this.state.paymentMethodState?.map((ob) => ob.key) || [];
    if (data?.res?.paymentMethodsApply?.length > 0) {
      paymentMethod = data.res.paymentMethodsApply;
    }
    return paymentMethod;
  };

  renderOldOperationZone = (data = {}) => {
    let zones = this.state.fleetZones?.map((ob) => ob._id) || [];
    if (data?.res?.operationZone?.length > 0) {
      zones = data.res.operationZone;
    }
    return zones;
  };

  handleMenuClick = (key, company) => {
    switch (key) {
      case 'Edit': {
        this.props.settingActions
          .geDetailCompany({
            companyId: company._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (data.ok) {
              this.setState({
                showDialog: true,
                dialogData: {
                  ...data.res,
                  editable: company.editable,
                  paymentMethodsApply: this.renderOldPayment(data),
                  operationZone: this.renderOldOperationZone(data),
                  releaseTime: convertToHours(data?.res?.releaseTime || 0),
                },
                dialogChanged: false,
                editable: true,
              });
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('companySetting.Load_company_fail')
                );
              }
            }
          });
        break;
      }
      case 'View': {
        this.props.settingActions
          .geDetailCompany({
            companyId: company._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (data.ok) {
              this.setState({
                showDialog: true,
                dialogData: {
                  ...data.res,
                  editable: company.editable,
                  paymentMethodsApply: this.renderOldPayment(data),
                  operationZone: this.renderOldOperationZone(data),
                  releaseTime: convertToHours(data?.res?.releaseTime || 0),
                },
                dialogChanged: false,
                editable: false,
              });
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('companySetting.Load_company_fail')
                );
              }
            }
          });
        break;
      }
      case 'changeStatus': {
        this.setState({ editable: true });
        break;
      }
      case 'Delete': {
        this.setState({ showConfirm: true, dialogData: company });
        break;
      }
      // case 'connectStripe': {
      //   this.props.loadingBarActions.showLoadingSpiner();
      //   this.props.settingActions
      //     .stripeConnectOnboarding({
      //       fleetId: this.props.auth.selectedFleet.fleetId,
      //       type: 'company',
      //       companyId: company._id,
      //     })
      //     .then((data) => {
      //       this.props.loadingBarActions.hideLoadingSpiner();
      //       const response = (data.res && data.res.response) || {};
      //       if (response.url) {
      //         window.open(response.url, '_blank');
      //         socketDispatchApi.remove(
      //           socketConfigs.receive.stripeConnectStatus
      //         );
      //         socketDispatchApi.on(
      //           socketConfigs.receive.stripeConnectStatus,
      //           (data) => {
      //             if (data && data.status) {
      //               this.context.notification(
      //                 'success',
      //                 'The Stripe has  been connected successfully'
      //               );
      //               this.updateCompaniesList();
      //             }
      //           }
      //         );
      //       }
      //     })
      //     .catch(function () {
      //       this.props.loadingBarActions.hideLoadingSpiner();
      //       console.log('error load connect');
      //     });
      //   break;
      // }
      // case 'loginStripe': {
      //   this.props.loadingBarActions.showLoadingSpiner();
      //   this.props.settingActions
      //     .stripeConnectLogin({
      //       fleetId: this.props.auth.selectedFleet.fleetId,
      //       type: 'company',
      //       companyId: company._id,
      //     })
      //     .then((data) => {
      //       this.props.loadingBarActions.hideLoadingSpiner();
      //       const response = (data.res && data.res.response) || {};
      //       if (response.url) {
      //         window.open(response.url, '_blank');
      //       }
      //     })
      //     .catch(function () {
      //       this.props.loadingBarActions.hideLoadingSpiner();
      //       console.log('error load login stripe');
      //     });
      //   break;
      // }
      // case 'disconnectStripe': {
      //   this.setState({
      //     isShowModalDisconnectStripe: true,
      //     companyIdSelecting: company._id,
      //   });
      //   break;
      // }
      case 'CopySignUp': {
        const { selectedFleet } = this.props.auth,
          linkSignUp = `${selectedFleet?.providerSignUpLink}?f=${selectedFleet?.fleetToken}&c=${company?._id}&isIframe=true&fpc=true`;
        copy(linkSignUp);
        this.context.notification(
          'success',
          'Sign up link has been coppied successfully!'
        );
        break;
      }
    }
  };

  handleConfirmedDisconnect = () => {
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.settingActions
      .disconnectStripe({
        fleetId: this.props.auth.selectedFleet.fleetId,
        type: 'company',
        companyId: this.state.companyIdSelecting,
      })
      .then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        const response = (data.res && data.res.response) || {};
        if (response.disconnected) {
          this.context.notification(
            'success',
            'The Stripe has  been disconnected successfully'
          );
          this.updateCompaniesList();
        } else {
          this.context.notification(
            'error',
            'There were errors when disconnecting account'
          );
        }
        this.setState({
          isShowModalDisconnectStripe: false,
        });
      })
      .catch(function () {
        this.props.loadingActions.hideLoadingSpiner();
        console.log('error load connect');
      });
  };

  handlePaginationSelect = (eventKey) => {
    this.updateCompaniesList(eventKey);
  };

  handleNumItemsPerPageChange = (e) => {
    this.updateCompaniesList(0, parseInt(e));
  };

  confirmDeleteUser = () => {
    this.props.settingActions
      .deleteCompany({
        fleetId: this.props.auth.selectedFleet.fleetId,
        companyId: this.state.dialogData._id,
      })
      .then((data) => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateCompaniesList();
          this.context.notification(
            'success',
            I18n.t('companySetting.Delete_company_success')
          );
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('companySetting.Delete_company_fail')
            );
          }
        }
      });
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  getTableColumns = () => {
    let tableColumns = Object.assign([], CompanyColumns);
    const companyCommission = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.companyCommission',
      false
    );
    if (!companyCommission) {
      _.remove(tableColumns, (columns) => columns.key === 'connectStatus');
    }
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'Name':
          col.customCell = (company) => {
            return company.editable || companyCommission ? (
              <a
                className="clickable"
                onClick={() => {
                  this.handleMenuClick('View', company);
                }}
              >
                {company.name}
              </a>
            ) : (
              <a className="disabled clickable">{company.name}</a>
            );
          };
          break;
        // case 'connectStatus':
        //   col.customCell = (company) => {
        //     return company.connectStatus === 'activated' ? (
        //       <span className="stripe_status activated">Active</span>
        //     ) : company.connectStatus === 'pending' ? (
        //       <span className="stripe_status pending">In Review</span>
        //     ) : (
        //       <span className="stripe_status empty">Inactive</span>
        //     );
        //   };
        //   break;

        case 'actions':
          col.customCell = (company, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [
                {
                  label: 'companySetting.View',
                  eventKey: 'View',
                },
                {
                  label: 'companySetting.Edit',
                  eventKey: 'Edit',
                },
              ];
              // Nếu bật custome company commission thì add thêm action connect stripe
              // if (companyCommission) {
              //   if (
              //     company.connectStatus === 'empty' ||
              //     !company.connectStatus
              //   ) {
              //     actionItems.push({
              //       label: 'companySetting.connectStripe',
              //       eventKey: 'connectStripe',
              //     });
              //   }
              //   if (
              //     company.connectStatus === 'pending' ||
              //     company.connectStatus === 'activated'
              //   ) {
              //     actionItems.push({
              //       label: 'companySetting.loginStripe',
              //       eventKey: 'loginStripe',
              //     });
              //     actionItems.push({
              //       label: 'companySetting.disconnectStripe',
              //       eventKey: 'disconnectStripe',
              //     });
              //   }
              // }
              if (company.deletable) {
                actionItems.push({
                  label: 'companySetting.Delete',
                  eventKey: 'Delete',
                });
              }
              if (
                this.props.auth?.selectedFleet?.providerSetting?.otherSetting
                  ?.showSupplierSelection
              ) {
                actionItems.push({
                  label: 'companySetting.CopySignUpLink',
                  eventKey: 'CopySignUp',
                });
              }
              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={company}
                  onSelect={(eventKey) => {
                    this.handleMenuClick(eventKey, company);
                  }}
                  totalRow={this.state.companies.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  disabled={actionItems && actionItems.length === 0}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={(e) => {
                    this.handleMenuClick('View', company);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="companySetting.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
        : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  handleLoadOptionDebounce = (input, callback) => {
    const reEmail =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (!reEmail.test(input))
      return callback({
        complete: false,
        options: [],
      });
    const result = {
      complete: true,
      options: [
        {
          value: input,
          id: input,
        },
      ],
    };
    callback(null, result);
  };

  render() {
    const delayBroadcast = this.state?.dialogData?.delayBroadcast || {},
      { advanceBookingVisibility = 0, releaseTime } =
        this.state.dialogData || {};
    const bodyData =
      this.state.companies && this.state.companies.list.length > 0
        ? this.state.companies.list
        : [];
    const companyCommission = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.companyCommission',
      false
    );
    const connectStatus = _.get(
      this.state.dialogData,
      'connectStatus',
      'empty'
    );
    const fleetEnableBroadcastBooking =
      this.props.auth?.selectedFleet?.process?.broadcastBooking || true;
    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={'confirm'}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="companySetting.DELETE_COMPANY" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="companySetting.DELETE_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button className={'btn-save m'} onClick={this.confirmDeleteUser}>
                <Translate value="companySetting.Yes" />
              </Button>
              <Button className={'btn-cancel'} onClick={this.closeDialogForm}>
                <Translate value="companySetting.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showDialog ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true} dialogClassName={'modal-max-600'}>
            <Modal.Header closeButton onHide={this.closeDialogForm}>
              <Modal.Title>
                {this.state.dialogData._id ? (
                  this.state.editable ? (
                    <Translate value="companySetting.EDIT_COMPANY" />
                  ) : (
                    <Translate value="companySetting.DETAIL_COMPANY" />
                  )
                ) : (
                  <Translate value="companySetting.ADD_COMPANY" />
                )}
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <FormGroup
                className={
                  !this.state.isSubmited
                    ? null
                    : this.state.valid.name
                    ? null
                    : 'error'
                }
              >
                <Form.Label>
                  <Translate value="companySetting.Name" />{' '}
                  <span className="require">*</span>
                </Form.Label>
                <FormControl
                  type="text"
                  className={'form-custom'}
                  onChange={this.handleNameChange}
                  value={
                    this.state.dialogData ? this.state.dialogData.name : ''
                  }
                  disabled={
                    !this.state.editable ||
                    (!this.state.dialogData.editable &&
                      this.state.dialogData._id)
                  }
                  placeholder={I18n.t('companySetting.Name')}
                />
                <Validator id="name" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmited}
                    valid={
                      !Validation.isStringEmpty(this.state.dialogData.name)
                    }
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>

              <FormGroup
                controlId="formControlsSelectMultiple"
                className={
                  !this.state?.isSubmited
                    ? null
                    : this.state.valid?.operationZone
                    ? null
                    : 'error'
                }
              >
                <Form.Label>
                  <Translate value="companySetting.operationZone" />{' '}
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  className="form-custom select-zone"
                  as="select"
                  multiple
                  onChange={this.handleOperationZoneChange}
                  value={this.state.dialogData?.operationZone || []}
                  disabled={!this.state.editable}
                >
                  {this.state.fleetZones?.map((z) => {
                    return (
                      <option key={z._id} value={z._id}>
                        {z.zoneName}
                      </option>
                    );
                  })}
                </FormControl>
                <Validator
                  id="operationZone"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!this.state?.isSubmited}
                    valid={
                      this.state.dialogData?.operationZone?.length > 0
                    }
                    message={I18n.t('driver.ERROR_INPUT_ZONE')}
                  />
                </Validator>
              </FormGroup>

              <Form.Label>
                (<span className="require">*</span>):{' '}
                <Translate value="companySetting.Required_fields" />
              </Form.Label>
            </Modal.Body>
            <Modal.Footer>
              {!this.props.permissions || this.props.permissions.actions ? (
                this.state.editable ? (
                  <Button
                    className={'btn-save mr-md'}
                    onClick={this.saveDialogForm}
                    // disabled={!CCLiteCommonFunc.isFormValid(this.state.valid)}
                  >
                    <Translate value="companySetting.Save" />
                  </Button>
                ) : null
              ) : null}
              <Button className={'btn-cancel'} onClick={this.closeDialogForm}>
                <Translate value="companySetting.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        <ButtonToolbar
          className="text-center header-button-group"
          ref={(node) => (this.toobarContainer = node)}
        >
          <ButtonGroup className="group-left">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.str}
                onKeyPress={this.handleSearchKeyPress}
                onChange={this.handleSearchChange}
                placeholder={I18n.t('General.search')}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {!this.props.permissions || this.props.permissions.actions ? (
              <Button
                className="btn-header text-add-header ml0"
                onClick={this.handleAddButtonClick}
              >
                {' '}
                <Translate value="companySetting.Add" />
              </Button>
            ) : null}
          </ButtonGroup>
        </ButtonToolbar>

        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>

        {/*Modal confirm disconnect stripe */}
        <Modal
          show={this.state.isShowModalDisconnectStripe}
          backdrop={true}
          dialogClassName="confirm-dialog"
          className="confirm"
          onHide={this.handleCloseModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Disconnect</Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.handleCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to disconnect from Stripe connect account?
            </p>
          </Modal.Body>
          <Modal.Footer className="text-center">
            <Button
              className="btn-save mr-md"
              onClick={this.handleConfirmedDisconnect}
            >
              {I18n.t('bookingdetail.Yes')}
            </Button>
            <Button className="btn-cancel" onClick={this.handleCloseModal}>
              {I18n.t('bookingdetail.No')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Company.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
    paymentMethod: state.paymentMethod,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Company);
