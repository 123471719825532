export const vi={
    'name':'Vietnamese',
    'flagCode':'vn',
	"locale": "en-en",
	"Sidebar": {
		'SETTINGS'      :'Cài Đặt',
        'MAP'           :'Bản Đồ',
        'CUE'           :'Cue',
        'NEWNOOKING'    :'Đặt Mới'
	},
    PageTitle:{
        'SETTINGS'      :'Cài Đặt',
        'MAP'           :'Bản Đồ',
        'CUE'           :'Cue',
        'NEWNOOKING'    :'Đặt Mới'
    },
    errors:{
        '303': 'user not found'
    },
    statusDisplay: {
    completedWithoutService: 'Complete without service',
        "confirmed": "Confirmed reservation",
        "pending": "Pending",
        "queue": "Dispatching",
        "offered": "Dispatching",
        'booked': "Driver on the way",
        'engaged': "P.O.B",
        "droppedOff": "Dropped off",
        "pre": 'Pending',
        "recurrent": "Recurrent",
        "vacant": "",
        "vacantTime": "",
        "arrived": "Arrived and waiting",
        "completed": 'Completed',
        "canceled": 'Canceled',
        "noShow": 'No show',
        "action": 'Waiting for process',
        "incident": 'Incident',
        "pickupTimeExceeded": 'Time out'
    },
}