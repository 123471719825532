import React, { useEffect, useState } from 'react';
import currencyFormatter from 'currency-formatter';

import { StatusColumnMobile } from '../StatusColumn';
import BookId from '../BookId';
import PaymentInfoColumn from '../PaymentInfoColumn';
import EstimatedFareColumn from '../EstimatedFareColumn';
import AirportPickup from '../AirportPickup';
import PickupDropOffLocalTime from '../PickupDropOffLocalTime';
import { I18n, Translate } from 'react-redux-i18n';
import PSG_ICON from '../../../../assets/images/icons/psg-vip.svg';
import PSG_LUG from '../../../../assets/images/icons/psg-lug.svg';
import PSG_NUMBER from '../../../../assets/images/icons/psg-number.svg';
import RECIPIENT_ICON from '../../../../assets/images/icons/recipient.svg';
import LocationColumn from '../LocationColumn';
import { Button } from 'react-bootstrap';
import FormatItem from '../FormatItem';
import {
  checkCanAssignDriver,
  checkCanDetachDriver,
  getBookingType,
} from '../../../../utils/commonFunctions';
import {
  BOOK_TYPE,
  COLOR_SERVICE_TYPE,
  JOB_TYPE,
  STATUS_COLOR_MOBILE,
} from '../../../../constants/commondata';
import {
  FoundersFund,
  GroupId,
  Hourly,
  RoundTrip,
  ZeroCommission,
  RateType,
  DeliveryType,
} from './ComponentMobileUI';
import PickupDropOffTime from '../PickupDropOffTime';
import DriverColumn from '../DriverColumn';
import PassengerColum from '../PassengerColum';
import AcceptOfferButton from '../acceptOfferButton';
import VehicleColumn from '../VehicleColumn';

const BookingMobileItem = ({ booking, selectedTab, ...props }) => {
  const [bookType, setBookType] = useState('');
  const [isCueFinished, setCueFinished] = useState(false);
  const [statusColor, setStatusColor] = useState('#8C8C8C');

  useEffect(() => {
    setBookType(getBookingType(booking));
    setCueFinished(selectedTab == 1);
  }, []);

  useEffect(() => {
    if (isCueFinished) {
      if (
        ['completed', 'completed', 'completedWithoutService'].includes(
          booking.status
        )
      ) {
        setStatusColor(STATUS_COLOR_MOBILE[booking.status]);
      } else {
        setStatusColor('#808080');
      }
    } else {
      setStatusColor(
        STATUS_COLOR_MOBILE[booking.status]
          ? STATUS_COLOR_MOBILE[booking.status]
          : '#8C8C8C'
      );
    }
  }, [booking]);

  const renderBookType = () => {
    if ([BOOK_TYPE.intercity, BOOK_TYPE.shuttle].includes(bookType)) {
      return (
        <FormatItem
          text={<Translate value={`cue.${bookType}`} />}
          bgColor={COLOR_SERVICE_TYPE.shuttle}
        />
      );
    }

    if ([BOOK_TYPE.batchDelivery, BOOK_TYPE.delivery].includes(bookType)) {
      return <DeliveryType booking={booking} />;
    }

    return <RateType booking={booking} />;
  };

  const RenderPrices = ({ data }) => {
    let dataFare = data?.request?.estimate?.fare?.etaFare || 0;
    if (props.commonData?.companies[0]?._id) {
      dataFare =
        dataFare -
        (dataFare * (props.commonData?.companies[0]?.commissionValue || 0)) /
          100;
    }
    return (
      <div
        style={{
          color: '#fff',
          fontSize: '14px',
        }}
      >
        {currencyFormatter.format(dataFare?.toFixed(2) || 0, {
          code: data.currencyISO,
        })}
      </div>
    );
  };

  return (
    <div
      id="BookingMobile"
      onClick={(e) => props.hancleViewDetailBooking(booking.bookId)}
      style={{ borderColor: statusColor }}
    >
      <div className="ct-rl">
        {selectedTab != 3 && (
          <StatusColumnMobile booking={booking} color={statusColor} />
        )}
        <div style={{ marginLeft: selectedTab == 3 ? '-10px' : 0 }}>
          <BookId
            booking={booking}
            lineShort={props.lineShort}
            isEnableGroupSetting={props.isEnableGroupSetting}
            selectedTab={selectedTab}
            checkEnableSelectMultiBook={props.checkEnableSelectMultiBook(
              booking
            )}
            checkedGroup={props.checkedGroup}
            handleClickGroup={props.handleClickGroup}
            isMobile
          />
        </div>
        {selectedTab == 3 && <RenderPrices data={booking} />}
      </div>

      <div className="ct-rl">
        {selectedTab == 0 ? (
          <EstimatedFareColumn
            booking={booking}
            auth={props.auth}
            lineShort={props.lineShort}
            isMobile
          />
        ) : (
          <PaymentInfoColumn
            booking={booking}
            auth={props.auth}
            permissions={props.permissions}
            lineShort={false}
            isMobile
          />
        )}
      </div>

      <PickupDropOffTime
        booking={booking}
        lineShort={props.lineShort}
        auth={props.auth}
        isMobile
      />

      <div className="info-list">
        <AirportPickup
          booking={booking}
          lineShort={props.lineShort}
          auth={props.auth}
          isMobile
        />
        <VehicleColumn booking={booking} lineShort={props.lineShort} isMobile isCueFinished={isCueFinished}/>
        {renderBookType()}
        <RoundTrip booking={booking} />
        <Hourly booking={booking} />
        <GroupId booking={booking} bookType={bookType} />
        <FoundersFund booking={booking} />
        <ZeroCommission booking={booking} />
        {
          // meet and greet icon
          booking?.request?.moreInfo?.flightInfo?.type === 1 &&
            booking?.request?.type === 1 &&
            !booking?.intercity && (
              <div className="info-icon vip">
                <img src={PSG_ICON} />
              </div>
            )
        }
        {bookType === BOOK_TYPE.rideSharing &&
          bookType === BOOK_TYPE.reservation &&
          bookType === BOOK_TYPE.now &&
          bookType === BOOK_TYPE.intercity && (
            <>
              <div className="info-icon psg">
                <img src={PSG_NUMBER} />
                <span>{booking?.request?.paxNumber || 0}</span>
              </div>
              <div className="info-icon psg">
                <img src={PSG_LUG} />
                <span>{booking?.request?.luggageNumber || 0}</span>
              </div>
            </>
          )}
        {
          // icon COD
          booking?.deliveryInfo?.cashOnDelivery && (
            <span
              style={{
                color: '#04BE76',
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                marginRight: '8px',
              }}
            >
              COD
            </span>
          )
        }
        {
          // icon Recipients
          booking?.deliveryInfo?.recipients?.length > 0 &&
            ![JOB_TYPE.food, JOB_TYPE.mart].includes(booking?.jobType) && (
              <div className="info-icon psg">
                <img src={RECIPIENT_ICON} />
                <span>{booking?.deliveryInfo?.recipients?.length || 0}</span>
              </div>
            )
        }
      </div>
      <div className="pax-info">
        <PassengerColum isMobile booking={booking} />
      </div>

      <LocationColumn booking={booking} lineShort={props.lineShort} isMobile />

      {selectedTab != 3 && (
        <DriverColumn
          booking={booking}
          isCueFinished={isCueFinished}
          handleAssignDriverOneBooking={props.handleAssignDriverOneBooking}
          isMobile
        />
      )}

      {selectedTab == 3 && <AcceptOfferButton data={booking} handleAcceptBooking={props.handleAcceptBooking}/>}
    </div>
  );
};

export default BookingMobileItem;
