import {
    CUE_REQUEST,
    CUE_SUCCESS,
    CUE_FAILURE,
    CUE_UPDATE,
    CUE_EDIT,
    CUE_UPDATE_FILLTER,
    CUE_ACTIVE_LOADMORE,
    CUE_ADD_NEW_BOOKING,
    CUE_BOOKING_FROM_LIST,
    UPDATE_DATA_POOL_CHANGES,
    BOOKING_UPDATE_TOTAL,
    RESET_CUE_FILTER,
    SYNCING_THIRD_PARTY_BOOKING
} from "../actions/cueActions";
import { activeStatus, finishedStatus, rideServiceSearchType, rideSharingSearchType } from "../constants/commondata"
import { CCLiteCommonFunc } from "../utils/commonFunctions"
import { realtimeHelper } from '../utils/realTimeHelper'
var _ = require('lodash');
var moment = require('moment');
const storageCueSort = parseFloat(localStorage.getItem('cue_sort') || 1)
const defaultFilter = {
    txtSearch: '',
    "bookingService": 'all',
    'supportService': 'all',
    "corporateId": null,
    "bookingType": 'all',
    "rideType": 'all',
    "dateFrom": null,
    "dateTo": null,
    "operator": "",
    "bookFrom": [],
    "carType": [],
    status: [],
    fleetId: null,
    status: {
        all: true,
        pending: true,
        dispatching: true,
        action: true,
        confirmed: true,
        booked: true,
        arrived: true,
        engaged: true,
        delivering:true,
        droppedOff: true,
        allocated: true,
    },
    finishedStatus: {
        all: true,
        completed: true,
        noShow: true,
        incident: true,
        canceled: true,
        completedWithoutService: true,
        partialCompleted: true,
        failed: true,
        allocated: true,
    },
    offerStatus: {
        all: true,
        pending: true,
        dispatching: true,
        action: true,
        confirmed: true,
        allocated: true
    },
    vip: null,
    corporate: null,
    driverCorporate: null,
    rideService: 'all',
    rideSharing: 'all',
    sort: { time: storageCueSort }
}
window.listActive = {};
window.cues = {}
let initialState = {
    listActive: {},
    listActiveIndex: [],
    cues: null,
    page: 0,
    showingPage: 1,
    limit: 20,
    total: 0,
    error: null,
    isCompleted: false,
    fillter: defaultFilter,
    isLoading: false,
    isSyncingThirdPartyBooking: false
};

let bookingFromList = [];
const PaxApp = "Pax App";

export default function cue(state = initialState, action = {}) {
    switch (action.type) {
        case CUE_REQUEST: {
            return { ...state, isLoading: true }
        }
        case CUE_SUCCESS: {
            if (state.isCompleted) {
                if (action.data.page == 0) {
                    state.cues = {};
                }
                if (action.data.list.length > 0) {
                    if (CCLiteCommonFunc.isBookingStatusCompleted(action.data.list[0].status)) {
                        action.data.list.map(item => {
                            state.cues[item._id] = item;
                        })
                    }
                }
            } else {
                if (action.data.list.length > 0) {
                    if (!CCLiteCommonFunc.isBookingStatusCompleted(action.data.list[0].status)) {
                        realtimeHelper.CUE_SUCCESS(action.data);
                        realtimeHelper.setListActiveSortedIndex(realtimeHelper.sortBookingList(state.fillter.sort).index)
                    }
                }

            }
            return { ...state, isLoading: false, page: action.data.page, total: action.data.total, limit: action.data.limit };
        }
        case CUE_FAILURE: {
            return { ...state, isLoading: false };
        }
        case SYNCING_THIRD_PARTY_BOOKING: {
            return { ...state, isSyncingThirdPartyBooking: action.data.isSyncing };
        }
        case CUE_UPDATE_FILLTER: {
            const data = action.data || {};
            let isSort = typeof data.sort !== 'undefined' && CCLiteCommonFunc.difference(data.sort, state.fillter.sort)
            state.fillter = Object.assign(state.fillter, data);
            state.isCompleted = action.isCompleted;
            state.showingPage = 1;
            if (!state.isCompleted) {
                state.isLoading = false;
                state.cues = []
                
                if (isSort) {
                    realtimeHelper.setListActiveSortedIndex(realtimeHelper.sortBookingList(state.fillter.sort).index)
                }

                let count = 0;
                let listBooking = realtimeHelper.listActiveSortedIndex()
                listBooking.every(booking => {
                    let item = realtimeHelper.cueBookings()[booking];
                    if (!item) return true;
                    if (item && realtimeHelper.validateBookFilter(item, state.fillter)) {
                        state.cues.push(item.bookId)
                        count++;
                    }

                    if (count >= 30) return false;
                    
                    return true;
                })
            }
            if (state.isCompleted) {
                if (Array.isArray(state.cues)) {
                    state.cues = {};
                }
            }
            return {
                ...state,
                fillter: state.fillter,
                cues: state.cues
            }
        }
        case CUE_ACTIVE_LOADMORE: {
            state.showingPage += 1;
            let count = 0;
            let total = state.showingPage * 30;
            state.cues = [];
            realtimeHelper.listActiveSortedIndex().every(bookingId => {
                let bk = realtimeHelper.cueBookings()[bookingId];
                if (!bk) {
                    return true;
                }
                if (realtimeHelper.validateBookFilter(bk, state.fillter)) {
                   // console.log("add cues: ",bk.bookId);
                    state.cues.push(bk.bookId)
                    ++count;
                }
                if (count == total) {
                    return false;
                }
                return true;
            })
            if (state.isCompleted) {
                if (Array.isArray(state.cues)) {
                    state.cues = {};
                }
            }
            return { ...state }
        }
        case CUE_BOOKING_FROM_LIST: {
            if (!_.isEmpty(action.data)) {
                window.bookingFromList = action.data;
            }
            return { ...state };
        }
        case UPDATE_DATA_POOL_CHANGES: {
            state.cues = [];
            let count = 0;
            let total = state.showingPage * 30;
            if (!realtimeHelper.isCueDataSorted()) {
                realtimeHelper.cueBookingsSortIndex(state.fillter.sort)
            }
            realtimeHelper.listActiveSortedIndex().every(bookingId => {
                let bk = realtimeHelper.cueBookings()[bookingId];
                if (!bk) {
                    return true;
                }
                if (realtimeHelper.validateBookFilter(bk, state.fillter)) {
                    state.cues.push(bk.bookId)
                    ++count;
                }
                if (count == total) {
                    return false;
                }
                return true;
            })
            if (state.isCompleted) {
                if (Array.isArray(state.cues)) {
                    state.cues = {};
                }
            }
            return { ...state }
        }
        case BOOKING_UPDATE_TOTAL: {
            if (action.data && CCLiteCommonFunc.isBookingStatusCompleted(action.data.bookInfo.status) && state.isCompleted && state.cues[action.data.bookInfo._id]) {
                state.cues[action.data.bookInfo._id] = Object.assign(state.cues[action.data.bookInfo._id], action.data.bookInfo);
            }
            return { ...state };
        }
        case RESET_CUE_FILTER: {
            return {
                ...state,
                filter: defaultFilter,
                cues: null,
            }
        }
        default:
            return state
    }
}