import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate, I18n } from "react-redux-i18n";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import MultipleSelect from "./../../components/multipleselect/MultipleSelect";
import {
  ListGroup,
  ListGroupItem,
  FormGroup,
  Form,
  FormControl,
  Panel,
  Row,
  Col,
  ButtonToolbar,
  Button,
} from "react-bootstrap";
import {
  fetchRateRegular,
  fetchRateFlat,
  fetchRateHourly
} from "../../actions/settingActions";
import { assignRateCorporate } from "../../actions/corporateAction";
import { fetchDeliveryRate } from "../../actions/deliveryRateActions";
import PropTypes from "prop-types";
require("react-intl-tel-input/dist/libphonenumber.js");
require("react-intl-tel-input/dist/main.css");

class AssignRate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFlatRateChange = this.handleFlatRateChange.bind(this);
    this.addZoneRateRegularClick = this.addZoneRateRegularClick.bind(this);
    this.addZoneRateHourlyClick = this.addZoneRateHourlyClick.bind(this);
    this.handleZoneRegularChange = this.handleZoneRegularChange.bind(this);
    this.handleRateRegularChange = this.handleRateRegularChange.bind(this);
    this.handleZoneHourlyChange = this.handleZoneHourlyChange.bind(this);
    this.handleRateHourlyChange = this.handleRateHourlyChange.bind(this);
    this.removeZoneRateComplied = this.removeZoneRateComplied.bind(this);
    this.handleAssignRateClick = this.handleAssignRateClick.bind(this);
    this.addZoneRateDeliveryClick = this.addZoneRateDeliveryClick.bind(this);
    this.handleZoneDeliveryChange = this.handleZoneDeliveryChange.bind(this);
    this.handleRateDeliveryChange = this.handleRateDeliveryChange.bind(this);
  }
  componentDidMount() {
    if (!this.state.regularRates) {
      this.props
        .fetchRateRegular({ fleetId: this.props.auth.selectedFleet.fleetId })
        .then(data => {
          if (data.ok && data.res) {
            this.setState({ regularRates: data.res });
          }
        });
      this.props
        .fetchRateFlat({ fleetId: this.props.auth.selectedFleet._id })
        .then(data => {
          if (data.ok && data.res) {
            this.setState({ flatRates: data.res });
          }
        });
      this.props
        .fetchRateHourly({ fleetId: this.props.auth.selectedFleet._id })
        .then(data => {
          if (data.ok && data.res) {
            this.setState({ hourlyRates: data.res });
          }
        });
      this.props
        .fetchDeliveryRate({ fleetId: this.props.auth.selectedFleet._id })
        .then(data => {
          if (data.ok && data.res) {
            this.setState({ deliveryRates: data.res });
          }
        });
    }
    if (this.props.data && !this.state.data) {
      this.setState({ data: this.props.data });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.nextProps.data && !this.state.data) {
      this.setState({ data: this.nextProps.data });
    }
  }

  addZoneRateRegularClick() {
    if (
      this.state.zoneRegular &&
      this.state.rateRegular &&
      this.state.zoneRegular.length > 0 &&
      this.state.rateRegular.length > 0
    ) {
      let newCorpRates = this.state.data.corpRates;
      this.state.zoneRegular.map(zone => {
        let existedData = this.state.data.corpRates.filter(item => {
          return (
            item.rateId._id === this.state.rateRegular[0] &&
            item.zoneId._id === zone
          );
        });
        if (existedData.length === 0) {
          let zoneName = this.state.data.zoneId.filter(item => {
            return zone === item._id;
          })[0].zoneName;
          let rateName = this.state.regularRates.filter(item => {
            return this.state.rateRegular[0] === item._id;
          })[0].name;
          newCorpRates.push({
            rateType: "Regular",
            rateId: {
              _id: this.state.rateRegular[0],
              name: rateName
            },
            zoneId: {
              _id: zone,
              zoneName: zoneName
            }
          });
        }
      });
      this.setState({
        data: { ...this.state.data, corpRates: newCorpRates },
        zoneRegular: null
      });
    }
  }

  addZoneRateHourlyClick() {
    if (
      this.state.zoneHourly &&
      this.state.rateHourly &&
      this.state.zoneHourly.length > 0 &&
      this.state.rateHourly.length > 0
    ) {
      let newCorpRates = this.state.data.corpRates;
      this.state.zoneHourly.map(zone => {
        let existedData = this.state.data.corpRates.filter(item => {
          return (
            item.rateId._id === this.state.rateHourly[0] &&
            item.zoneId._id === zone
          );
        });
        if (existedData.length === 0) {
          let zoneName = this.state.data.zoneId.filter(item => {
            return zone === item._id;
          })[0].zoneName;
          let rateName = this.state.hourlyRates.filter(item => {
            return this.state.rateHourly[0] === item._id;
          })[0].name;
          newCorpRates.push({
            rateType: "Hourly",
            rateId: {
              _id: this.state.rateHourly[0],
              name: rateName
            },
            zoneId: {
              _id: zone,
              zoneName: zoneName
            }
          });
        }
      });
      this.setState({
        data: { ...this.state.data, corpRates: newCorpRates },
        zoneHourly: null
      });
    }
  }

  addZoneRateDeliveryClick() {
    if (
      this.state.zoneDelivery &&
      this.state.rateDelivery &&
      this.state.zoneDelivery.length > 0 &&
      this.state.rateDelivery.length > 0
    ) {
      let newCorpRates = this.state.data.corpRates;
      this.state.zoneDelivery.map(zone => {
        let existedData = this.state.data.corpRates.filter(item => {
          return (
            item.rateId._id === this.state.rateDelivery[0] &&
            item.zoneId._id === zone
          );
        });
        if (existedData.length === 0) {
          let zoneName = this.state.data.zoneId.filter(item => {
            return zone === item._id;
          })[0].zoneName;
          let rateName = this.state.deliveryRates.filter(item => {
            return this.state.rateDelivery[0] === item._id;
          })[0].name;
          newCorpRates.push({
            rateType: "Delivery",
            rateId: {
              _id: this.state.rateDelivery[0],
              name: rateName
            },
            zoneId: {
              _id: zone,
              zoneName: zoneName
            }
          });
        }
      });
      this.setState({
        data: { ...this.state.data, corpRates: newCorpRates },
        zoneDelivery: null
      });
    }
  }

  handleFlatRateChange(e) {
    let rateChose = this.state.flatRates.filter(item => {
      return e.target.value === item._id;
    })[0];
    let rateName;
    if (rateChose) {
      rateName = rateChose.name;
    }
    let isRateFound = false;
    let rates = this.state.data.corpRates.map(rate => {
      if (rate.rateType === "Flat") {
        rate.rateId = {
          _id: e.target.value,
          name: rateName
        };
        isRateFound = true;
      }
      return rate;
    });
    if (!isRateFound) {
      rates.push({
        rateType: "Flat",
        rateId: {
          _id: e.target.value,
          name: rateName
        }
      });
    }
    this.setState({
      data: {
        ...this.state.data,
        corpRates: rates
      }
    });
  }

  handleZoneRegularChange(e) {
    this.setState({
      zoneRegular: e
    });
  }

  handleRateRegularChange(e) {
    this.setState({
      rateRegular: e
    });
  }

  handleZoneHourlyChange(e) {
    this.setState({
      zoneHourly: e
    });
  }

  handleRateHourlyChange(e) {
    this.setState({
      rateHourly: e
    });
  }
  handleZoneDeliveryChange(e) {
    this.setState({
      zoneDelivery: e
    });
  }

  handleRateDeliveryChange(e) {
    this.setState({
      rateDelivery: e
    });
  }

  removeZoneRateComplied(data) {
    let rates = this.state.data.corpRates.filter(rate => {
      if (
        data.rateId._id !== rate.rateId._id ||
        data.zoneId._id !== rate.zoneId._id
      ) {
        return rate;
      }
    });
    this.setState({
      data: {
        ...this.state.data,
        corpRates: rates
      }
    });
  }

  handleAssignRateClick() {
    let flatFareId = "";
    let regularRates = [];
    let hourlyRates = [];
    let deliveryRates = [];
    this.state.data.corpRates.map(rate => {
      if (rate.rateType === "Flat") {
        flatFareId = rate.rateId._id;
      } else if (rate.rateType === "Regular") {
        regularRates.push({
          zoneId: rate.zoneId._id,
          rateId: rate.rateId._id
        });
      } else if (rate.rateType === "Hourly") {
        hourlyRates.push({
          zoneId: rate.zoneId._id,
          rateId: rate.rateId._id
        });
      } else if (rate.rateType === "Delivery") {
        deliveryRates.push({
          zoneId: rate.zoneId._id,
          rateId: rate.rateId._id
        });
      }
      return rate;
    });
    this.props.loadingBarActions.showLoadingSpiner();
    this.props
      .assignRateCorporate({
        _id: this.props.corporateId,
        fleetId: this.props.auth.selectedFleet.fleetId,
        carTypeId: this.state.data._id,
        flatFareId: flatFareId,
        regular: regularRates,
        hourly: hourlyRates,
        delivery: deliveryRates
      })
      .then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.ok) {
          this.context.notification(
            "success",
            I18n.t("corporate.INFO_UPDATE_PRICING_SUCCESS")
          );
          this.props.handleAssignRateSuccess(this.state.data);
        } else {
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
        }
      });
  }

  render() {
    if (this.state.data) {
      let regularRates = this.state.data.corpRates.filter(rate => {
        if (rate.rateType === "Regular") {
          return rate;
        }
      });
      let flatFareId = "";
      this.state.data.corpRates.map(rate => {
        if (rate.rateType === "Flat" && rate.rateId) {
          flatFareId = rate.rateId._id;
        }
        return rate;
      });
      let hourlyRates = this.state.data.corpRates.filter(rate => {
        if (rate.rateType === "Hourly") {
          return rate;
        }
      });
      let deliveryRates = this.state.data.corpRates.filter(rate => {
        if (rate.rateType === "Delivery") {
          return rate;
        }
      });
      let zoneRegularSelectAble = this.state.data.zoneId.slice(0);
      regularRates.map(rate => {
        zoneRegularSelectAble = zoneRegularSelectAble.filter(function(item) {
          return item._id !== rate.zoneId._id;
        });
        return rate;
      });
      let zoneHourlySelectAble = this.state.data.zoneId.slice(0);
      hourlyRates.map(rate => {
        zoneHourlySelectAble = zoneHourlySelectAble.filter(function(item) {
          return item._id !== rate.zoneId._id;
        });
        return rate;
      });
      let zoneDeliverySelectAble = this.state.data.zoneId.slice(0);
      deliveryRates.map(rate => {
        zoneDeliverySelectAble = zoneDeliverySelectAble.filter(function(item) {
          return item._id !== rate.zoneId._id;
        });
        return rate;
      });
      return (
        <div className="content assign-rate-wrapper">
          <Row>
            <Col xs={12} sm={6} md={4}>
              <FormGroup>
                <Form.Label>
                  <Translate value="corporate.Car_Type_ID" />
                </Form.Label>
                <FormControl
                  type="text"
                  disabled={true}
                  value={this.state.data.vehicleType}
                  className="form-custom"
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup>
                <Form.Label>
                  <Translate value="corporate.App_Display_Name" />
                </Form.Label>
                <FormControl
                  type="text"
                  disabled={true}
                  value={this.state.data.appDisplayName}
                  className="form-custom"
                />
              </FormGroup>
            </Col>
          </Row>
          
          {(this.state.data.delivery || this.state.data.food || this.state.data.mart ||  (this.state.data.superHelper && !this.state.data.bookNow)) ? (
            <>
              <Row>
                <Col xs={12} className="group-title  mb-lg">
                  <Form.Label>
                    <Translate value="corporate.Delivery_Rate" />
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4} className="assign-container zone-select">
                  <FormGroup>
                    <MultipleSelect
                      className="assign-select"
                      data={zoneDeliverySelectAble}
                      isMultiple={true}
                      name="zoneName"
                      title={I18n.t("corporate.Zone")}
                      handleItemChange={this.handleZoneDeliveryChange} />
                  </FormGroup>
                  <i
                    className="fa fa-plus-circle assign-control"
                    aria-hidden="true" />
                </Col>
                <Col xs={12} md={4} className="assign-container rate-select">
                  <FormGroup>
                    <MultipleSelect
                      className="assign-select"
                      data={this.state.deliveryRates}
                      name="name"
                      title={I18n.t("corporate.Rate")}
                      handleItemChange={this.handleRateDeliveryChange} />
                  </FormGroup>
                  <i
                    className="fa fa-arrow-circle-right assign-control compile"
                    aria-hidden="true"
                    onClick={this.addZoneRateDeliveryClick} />
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.Zone_Rate" />
                    </Form.Label>
                    <ul className="compiled-list">
                      {deliveryRates.map(item => {
                        return (
                          <li>
                            {item.zoneId.zoneName} - {item.rateId.name}{" "}
                            <a className="text-remove">
                              <i
                                className="fa fa-remove"
                                aria-hidden="true"
                                onClick={() => {
                                  this.removeZoneRateComplied(item);
                                } } />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="group-title mb-lg">
                  <Form.Label>
                    <Translate value="corporate.Flat_Fare" />
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <FormControl
                      as="select"
                      value={flatFareId}
                      placeholder="select"
                      onChange={this.handleFlatRateChange}
                      className="form-custom"
                    >
                      <option value="">
                        {I18n.t("corporate.Please_select_rate")}
                      </option>
                      {this.state.flatRates
                        ? this.state.flatRates.map(rate => {
                          if(rate.serviceType === 'delivery') {
                            return <option value={rate._id}>{rate.name}</option>;
                          }
                          return null
                        })
                        : null}
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : (
            <><Row>
              <Col xs={12} className="group-title mb-lg">
                <Form.Label>
                  <Translate value="corporate.Normal_Fare" />
                </Form.Label>
              </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className="assign-container">
                  <FormGroup>
                    <MultipleSelect
                      className="assign-select"
                      data={zoneRegularSelectAble}
                      isMultiple={true}
                      name="zoneName"
                      title={I18n.t("corporate.Zone")}
                      handleItemChange={this.handleZoneRegularChange} />
                  </FormGroup>
                  <i
                    className="fa fa-plus-circle assign-control"
                    aria-hidden="true" />
                </Col>
                <Col xs={12} md={4} className="assign-container">
                  <FormGroup>
                    <MultipleSelect
                      className="assign-select"
                      value={this.state.driver}
                      data={this.state.regularRates}
                      name="name"
                      title={I18n.t("corporate.Rate")}
                      handleItemChange={this.handleRateRegularChange} />
                  </FormGroup>
                  <i
                    className="fa fa-arrow-circle-right assign-control compile"
                    aria-hidden="true"
                    onClick={this.addZoneRateRegularClick} />
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.Zone_Rate" />
                    </Form.Label>
                    <ul className="compiled-list">
                      {regularRates.map(item => {
                        return (
                          <li>
                            {item.zoneId.zoneName} - {item.rateId.name}{" "}
                            <a className="text-remove">
                              <i
                                className="fa fa-remove"
                                aria-hidden="true"
                                onClick={() => {
                                  this.removeZoneRateComplied(item);
                                } } />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="group-title mb-lg">
                  <Form.Label>
                    <Translate value="corporate.Flat_Fare" />
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <FormControl
                      as="select"
                      value={flatFareId}
                      placeholder="select"
                      onChange={this.handleFlatRateChange}
                      className="form-custom"
                    >
                      <option value="">
                        {I18n.t("corporate.Please_select_rate")}
                      </option>
                      {this.state.flatRates
                        ? this.state.flatRates.map(rate => {
                          if(rate.serviceType === 'transport') {
                            return <option value={rate._id}>{rate.name}</option>;
                          }
                          return null
                        })
                        : null}
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="group-title  mb-lg">
                  <Form.Label>
                    <Translate value="corporate.Hourly_Daily_Rate" />
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4} className="assign-container zone-select">
                  <FormGroup>
                    <MultipleSelect
                      className="assign-select"
                      data={zoneHourlySelectAble}
                      isMultiple={true}
                      name="zoneName"
                      title={I18n.t("corporate.Zone")}
                      handleItemChange={this.handleZoneHourlyChange} />
                  </FormGroup>
                  <i
                    className="fa fa-plus-circle assign-control"
                    aria-hidden="true" />
                </Col>
                <Col xs={12} md={4} className="assign-container rate-select">
                  <FormGroup>
                    <MultipleSelect
                      className="assign-select"
                      data={this.state.hourlyRates}
                      name="name"
                      title={I18n.t("corporate.Rate")}
                      handleItemChange={this.handleRateHourlyChange} />
                  </FormGroup>
                  <i
                    className="fa fa-arrow-circle-right assign-control compile"
                    aria-hidden="true"
                    onClick={this.addZoneRateHourlyClick} />
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.Zone_Rate" />
                    </Form.Label>
                    <ul className="compiled-list">
                      {hourlyRates.map(item => {
                        return (
                          <li>
                            {item.zoneId.zoneName} - {item.rateId.name}{" "}
                            <a className="text-remove">
                              <i
                                className="fa fa-remove"
                                aria-hidden="true"
                                onClick={() => {
                                  this.removeZoneRateComplied(item);
                                } } />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </FormGroup>
                </Col>
              </Row></>
          )}
          <ButtonToolbar className="text-center pt">
            {!this.props.permissions || this.props.permissions.actions ? (
              <Button
                className="btn-save mr-md"
                onClick={this.handleAssignRateClick}
              >
                <Translate value="corporate.Save" />
              </Button>
            ) : null}
            <Button
              className="btn-cancel"
              onClick={this.props.handleCancelAssignRateClick}
            >
              <Translate value="corporate.Cancel" />
            </Button>
          </ButtonToolbar>
        </div>
      );
    } else {
      return null;
    }
  }
}

AssignRate.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    assignRateCorporate: options => {
      return dispatch(assignRateCorporate(options));
    },
    fetchRateRegular: options => {
      return dispatch(fetchRateRegular(options));
    },
    fetchRateFlat: options => {
      return dispatch(fetchRateFlat(options));
    },
    fetchRateHourly: options => {
      return dispatch(fetchRateHourly(options));
    },
    fetchDeliveryRate: options => {
      return dispatch(fetchDeliveryRate(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignRate);
