import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Moment from 'react-moment';
import moment from 'moment';
import { Translate, I18n } from 'react-redux-i18n';
import * as settingActions from '../../actions/settingActions';
import {
  Button,
  ButtonToolbar,
  FormGroup,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import _ from 'lodash';
import Confirm from '../../components/confirm/Confirm';
import ExportComponent from '../../components/ExportComponent';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import {
  getSuppilerPermission,
  handleResultExport,
} from '../../utils/commonFunctions';
import {
  deleteDriver,
  activateDriver,
  activateMultiDriver,
  getDriver,
  exportDriverToExcel,
  countDriver,
  setFleetCommissionDriver,
} from '../../actions/driverAction';
import './driver.scss';

import { Columns, DriverActionMenuItem } from './tableHeaderData';
import StickyTable from '../../components/table/stickyTable/StickyTable';
import TableActions from '../../components/table/tableAction/TableActions';
import messageIcon from '../../assets/images/mess.svg';
import excelIcon from '../../assets/images/excel.svg';
import iconActivate from '../../assets/images/active.png';
import {
  DriverSearchBy,
  SettelementSetting,
  driverStatus,
  marketPlaceFilter,
  migrationStatus,
  cashBalance,
  DriverType,
  creditBalance,
  stripeConnectStatus,
} from '../../constants/commondata';
import CCDropDown from '../../components/dropdown/ccDropDown';
import TotalBalance from './components/TotalBalance';
import DateRange from './components/DateRange';
import DateTime from '../../components/dateTime/DateTime';
import Commission from './SetCommission';
import { ignoreExportCustomer } from '../../constants/appConfig';
import AddEdit from './AddEdit';
import TotalDrivers from './components/TotalDrivers';
import { BsSearch } from 'react-icons/bs';
import { Link } from 'react-router';

require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

const CONFIRM_DELETE_ID = 1;
const CONFIRM_ACTIVATE_ID = 2;
const CONFIRM_DEACTIVATE_ID = 3;
const CONFIRM_DELETE_LINK_ID = 4;
const CONFIRM_EXPORT_ID = 5;
const CONFIRM_SET_COMMISSION = 6;
let deleteUserId;
const currencyFormatter = require('currency-formatter');

class Driver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: null,
      sortType: -1,
      tableHeight: 500,
      rowHeight: 50,
      showComission: false,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
        active: 0,
      },
      driverNumberChanges: 0,
      str: '',
      searchBy: DriverSearchBy[0].value,
      footerLoading: true,
      currencyISO: '',
      dataCommission: {},
      showDialog: props.route.path !== '/driver',
      selectedDriver: props.params.driverId,
      selectedCarTypes: [],
      activeStatus: 'all',
      marketPlace: 'all',
      cashBalanceStatus: 'all',
      stripeConnectStatus: 'all',
      creditBalanceStatus: 'all',
      migrationStatus: 'all',
      selectedButton: 'custom',
      supplierList: []
    };
    this.handleResultExport = handleResultExport.bind(this);
  }

  componentDidMount() {
    this.getListDrivers(0, null, true);
    this.getAllSupplier()
  }

  getCompanyIdToQuery = () => {
    const permissionCompanyId = this.props.auth.user?.roles?.supplierId;
    if (this.state.companyId || permissionCompanyId) {
      return this.state.companyId || permissionCompanyId;
    }
    return null;
  };

  getListDrivers = (activePage, limit, countDriver, driver) => {
    if (this.state.isLoading) return;

    this.setState({
      isLoading: true,
      footerLoading: countDriver,
    });

    const params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId,
      },
    };

    if (this.state.sortName) {
      params.sort = { [this.state.sortName]: this.state.sortType };

      // detect last active
      if (this.state.sortName === 'lastActive') {
        params.sort = { [this.state.sortName]: this.state.sortType * -1 };
      }
    } else {
      params.sort = { createdDate: this.state.sortType };
    }

    if (this.state.str) {
      params.query.str = this.state.str.trim();
      params.query.searchBy = this.state.searchBy;
    }
    if (this.state.zoneId) {
      params.query.zoneId = this.state.zoneId;
    }
    if (this.state.marketPlace) {
      params.query.marketplace = this.state.marketPlace;
    }
    if (this.state.cityId) {
      params.query.cityId = this.state.cityId;
    }

    if (this.getCompanyIdToQuery()) {
      params.query.companyId = this.getCompanyIdToQuery();
    }

    if (this.state.driverType) {
      params.query.driverType = this.state.driverType;
    }
    if (this.state.activeStatus) {
      params.query.status = this.state.activeStatus;
    }

    if (this.state.cashBalanceStatus) {
      params.query.cashBalance = this.state.cashBalanceStatus;
    }
    if (this.state.stripeConnectStatus) {
      params.query.stripeConnectStatus = this.state.stripeConnectStatus;
    }
    if (this.state.creditBalanceStatus) {
      params.query.creditBalance = this.state.creditBalanceStatus;
    }
    if (
      this.state.migrationStatus &&
      this.props.auth.selectedFleet.fleetId == 'mycar'
    ) {
      params.query.migrationStatus = this.state.migrationStatus;
    }

    if (this.state.selectedCarTypes && this.state.selectedCarTypes.length) {
      params.query.carType = this.state.selectedCarTypes;
    }

    if (this.state.startDate && this.state.endDate) {
      params.query.startDate = this.state.startDate.toLocalISOString(
        this.props.auth.selectedFleet.timezone
      );
      params.query.endDate = this.state.endDate
        .addDays(1)
        .toLocalISOString(this.props.auth.selectedFleet.timezone);
    }

    this.props.getDriver(params).then((data) => {
      if (data.ok) {
        const { page, limit, summary } = data.res;
        const driverList = data.res;

        if (driver) {
          const index = _.findIndex(
            driverList.list,
            (o) => o.userId === driver.userId
          );
          if (index === -1) {
            driverList.list = [driver, ...driverList.list];
          }
        }

        if (summary) {
          this.setState({
            driver: driverList,
            footerData: {
              ...this.state.footerData,
              page,
              limit,
              total: summary && summary.total,
              active: summary && summary.totalActive,
            },
            footerLoading: false,
            isLoading: false,
          });
        } else {
          this.setState({
            driver: driverList,
            footerData: {
              ...this.state.footerData,
              page,
              limit,
            },
            isLoading: false,
          });
          if (countDriver && _.isUndefined(summary)) {
            this.loadTotalDriver(params);
          }
        }
      } else {
        if (data.error && data.error.errorCode) {
          let message = I18n.t(`errors.${data.error.errorCode}`);
          if (data.error.paymentReturnCode == 407 && data.error.message) {
            message += `Please check (${data.error.message})`;
          }
          this.context.notification('error', message);
        }
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  loadTotalDriver = (params) => {
    this.props.countDriver(params).then((data) => {
      if (!data.error) {
        this.setState({
          footerData: {
            ...this.state.footerData,
            total: data.res.total,
            active: data.res.totalActive,
          },
          footerLoading: false,
        });
      }
    });
  };

  handleMenuClick = (eventKey, detailItem) => {
    if (eventKey == 0) {
      this.props.router.push({
        pathname: `/driver/view/${detailItem.userId}/info`,
        state: {
          edit: true,
        },
      });
    } else if (eventKey == 3) {
      this.props.router.push({
        pathname: `/driver/view/${detailItem.userId}/info`,
        state: {
          edit: false,
        },
      });
    } else if (eventKey == 1) {
      let status = detailItem.isActive;
      if (detailItem.driverInfo.statusView === 'In review') {
        status = false;
      }
      this.changeDriverStatus([detailItem], status);
    } else if (eventKey == 2) {
      let status = detailItem.isActive;
      if (detailItem.driverInfo.statusView === 'In review') {
        status = true;
      }
      this.changeDriverStatus([detailItem], status);
    } else {
      deleteUserId = detailItem.userId;
      this.setState({
        confirm: {
          id: CONFIRM_DELETE_LINK_ID,
          title: I18n.t('driver.Delete'),
          body: I18n.t('driver.CONFIRM_DELETE'),
          buttonTitle: 'Yes',
          closeButtonText: 'No',
        },
      });
    }
  };

  handlePaginationSelect = (page) => this.getListDrivers(parseInt(page));

  handleNumItemsPerPageChange = (limit) => {
    this.getListDrivers(0, limit);
  };

  handleSelectRowClick = (checked, userId) => {
    const listUser = _.cloneDeep(this.state.driver.list);
    _.forEach(listUser, (user) => {
      if (userId === user.userId) {
        user.checked = checked;
        return false;
      }
    });
    this.setState({
      driver: { ...this.state.driver, list: listUser },
    });
  };

  handleSelectAllClick = (e) => {
    const { target } = e;
    const listUser = _.cloneDeep(this.state.driver.list);
    _.forEach(listUser, (user) => {
      user.checked = target.checked;
    });
    this.setState({
      driver: { ...this.state.driver, list: listUser },
    });
  };

  createStripeToken = (user) => {
    const stripe = window.Stripe(this.props.auth.selectedFleet.stripePublicKey);
    const form = {
      legal_entity: {
        first_name: user.firstName,
        last_name: user.lastName,
        address: {
          line1: user.driverInfo.bankAddress,
          city: user.driverInfo.bankCity,
          state: user.driverInfo.bankState,
          postal_code: user.driverInfo.bankZip,
        },
      },
      tos_shown_and_accepted: true,
    };
    // if (user.driverInfo.legelEntityVerification) {
    //     form.legal_entity.verification = {
    //         document: user.driverInfo.legelEntityVerification
    //     }
    // }
    return stripe.createToken('account', form).then((data) => ({
      userId: user.userId,
      token: data.token.id,
    }));
  };

  changeDriverStatus(listUser, isActive) {
    const users = _.cloneDeep(listUser);

    _.forEach(users, (user) => {
      delete user.driverWallet;
    });

    const fleet = this.props.auth.selectedFleet;

    if (
      !fleet.drvPayout.enable &&
      fleet.bankingInfoSetting &&
      fleet.bankingInfoSetting.enable &&
      fleet.bankingInfoSetting.verifyAccount ==
        SettelementSetting.verifyAccount &&
      fleet.creditConfig.configGateway &&
      fleet.countryCode == 'FR' &&
      fleet.creditConfig.configGateway.gateway == 'Stripe' &&
      window.Stripe &&
      !isActive &&
      fleet.stripePublicKey
    ) {
      const task = [];
      users.map((user) => {
        task.push(this.createStripeToken(user));
      });
      Promise.all(task).then((results) => {
        if (results.length == 1) {
          this.activeDriver(results[0], isActive);
        } else {
          this.activeDrivers(results, isActive);
        }
      });
    } else if (users.length == 1) {
      this.activeDriver(users[0], isActive);
    } else {
      this.activeDrivers(users, isActive);
    }
  }

  activeDriver = (params, isActive) => {
    this.props
      .activateDriver(
        {
          user: params,
          fleetId: this.props.auth.selectedFleet.fleetId,
          fleetName: this.props.auth.selectedFleet.name,
        },
        isActive
      )
      .then((data) => {
        if (data.ok) {
          const listUser = _.cloneDeep(this.state.driver.list);
          _.forEach(listUser, (user) => {
            if (params.userId === user.userId) {
              user.isActive = !isActive;
              user.driverInfo.isActivate = true;
              user.driverInfo.statusView = isActive ? 'Inactive' : 'Active';
              user.checked = false;
              if (!isActive) {
                user.driverInfo.isComplete = true;
                user.driverInfo.statusReview = 'completed';
              }
              if (isActive && data.res && data.res.activated) {
                user.driverInfo.isActivate = true;
                if (user.driverInfo.statusReview === 'inProgress') {
                  user.driverInfo.isComplete = false;
                  user.driverInfo.statusReview = 'inComplete';
                }
              }
              return false;
            }
          });
          let nModified = 0;
          if (data.res.updated) {
            nModified = 1;
          }

          const active = isActive
            ? this.state.footerData.active - nModified
            : this.state.footerData.active + nModified;

          this.setState({
            driver: {
              ...this.state.driver,
              list: listUser,
              active,
            },
            footerData: { ...this.state.footerData, active },
          });
          if (isActive) {
            if (
              this.props.auth.selectedFleet.hardwareMeter &&
              !data.res.disableMeter
            ) {
              this.context.notification(
                'success',
                I18n.t('driver.INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE')
              );
            } else {
              this.context.notification(
                'success',
                I18n.t('driver.INFO_DEACTIVAED_SUCCESS')
              );
            }
          } else {
            this.context.notification(
              'success',
              I18n.t('driver.INFO_ACTIVAED_SUCCESS')
            );
          }
        } else {
          let message = I18n.t(`errors.${data.error.errorCode}`);
          if (data.error.paymentReturnCode == 407 && data.error.message) {
            message += `Please check (${data.error.message})`;
          }
          this.context.notification('error', message);
          // this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
        }
      });
  };

  activeDrivers = (params, isActive) => {
    this.props
      .activateMultiDriver(
        {
          users: params,
          fleetId: this.props.auth.selectedFleet.fleetId,
        },
        isActive
      )
      .then((data) => {
        if (data.ok) {
          // this.getListDrivers();
          const active = isActive
            ? this.state.footerData.active - data.res.nModified
            : this.state.footerData.active + data.res.nModified;
          const { driver } = this.state;
          const { list } = data.res;
          const listUser = _.cloneDeep(driver.list);
          _.each(listUser, (drv) => {
            const updatedDrv = _.find(list, (o) => o.userId === drv.userId);
            drv.checked = false;
            if (updatedDrv) {
              drv.isActive = !isActive;
              drv.driverInfo.isActivate = true;
              drv.driverInfo.statusView = isActive ? 'Inactive' : 'Active';
              if (!isActive) {
                // drv.driverInfo.isActivate = true;
                drv.driverInfo.statusReview = 'completed';
                drv.driverInfo.isComplete = true;
              }
              if (isActive && drv.driverInfo.statusReview === 'inProgress') {
                drv.driverInfo.isComplete = false;
                drv.driverInfo.statusReview = 'inComplete';
              }
            }
          });
          this.setState({
            driver: {
              ...driver,
              list: [...listUser],
              active,
            },
            footerData: { ...this.state.footerData, active },
          });
          if (isActive) {
            if (this.props.auth.selectedFleet.hardwareMeter) {
              if (data.res.notDisableMeter === 0) {
                this.context.notification(
                  'success',
                  I18n.t('driver.INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS').format(
                    data.res.nModified,
                    data.res.disableMeter
                  )
                );
              } else {
                this.context.notification(
                  'success',
                  I18n.t(
                    'driver.INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE'
                  ).format(
                    data.res.nModified,
                    data.res.disableMeter,
                    data.res.notDisableMeter
                  )
                );
              }
            } else {
              this.context.notification(
                'success',
                I18n.t('driver.INFO_MULTIDEACTIVAED_SUCCESS').format(
                  data.res.nModified
                )
              );
            }
          } else {
            this.context.notification(
              'success',
              I18n.t('driver.INFO_MULTIACTIVAED_SUCCESS').format(
                data.res.nModified
              )
            );
          }
        } else {
          let message = I18n.t(`errors.${data.error.errorCode}`);
          if (data.error.paymentReturnCode == 407 && data.error.message) {
            message += `Please check (${data.error.message})`;
          }
          if (
            data.error.paymentReturnCode === 493 &&
            data.error.paymentMessage
          ) {
            message += `(${data.error.paymentMessage})`;
          }
          this.context.notification('error', message);
          // this.context.notification('error', I18n.t('errors.' + data.error.errorCode));
        }
      });
  };

  handleSortDriverClick = (sortName) => {
    if (this.state.isLoading) return;
    this.setState(
      {
        sortName,
        sortType: this.state.sortType == 1 ? -1 : 1,
      },
      function () {
        this.getListDrivers();
      }
    );
  };

  handleSearchChange = (e) => {
    const str = e.target.value;
    this.setState({ str });
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (this.state.isLoading) return;
      this.getListDrivers(0, null, true);
    }
  };

  handleApplyFilter = () => {
    this.getListDrivers(0, null, true);
  };

  handlelearFilter = () => {
    this.setState(
      {
        zoneId: null,
        marketPlace: 'all',
        cityId: null,
        companyId: null,
        driverType: null,
        selectedCarTypes: [],
        activeStatus: 'all',
        cashBalanceStatus: 'all',
        stripeConnectStatus: 'all',
        creditBalanceStatus: 'all',
        migrationStatus: 'all',
        str: '',
        startDate: null,
        endDate: null,
        disableDatetimeSelection: false,
        selectedButton: 'custom',
      },
      () => {
        this.getListDrivers(0, null, true);
      }
    );
  };

  handleCityChange = (cityId) => {
    this.setState({ cityId });
  };

  handleCompanyChange = (companyId) => {
    this.setState({ companyId });
  };

  handleDriverTypeChange = (driverType) => {
    this.setState({ driverType });
  };

  handleZoneChange = (zoneId) => {
    this.setState({ zoneId });
  };

  handleMarketPlaceFilterChange = (marketPlace) => {
    this.setState({ marketPlace });
  };

  handleCarTypesChange = (newValue) => {
    const selectedCarTypes = _.filter(this.props.commonData.carType, (item) => {
      return newValue.includes(item._id);
    });
    this.setState({ selectedCarTypes: _.map(selectedCarTypes, '_id') });
  };

  handleActiveStatusChange = (newValue) => {
    this.setState({ activeStatus: newValue });
  };

  handleCashBalanceStatusChange = (newValue) => {
    this.setState({ cashBalanceStatus: newValue });
  };
  handleStripeStatusChange = (newValue) => {
    this.setState({ stripeConnectStatus: newValue });
  };
  handleCreditBalanceStatusChange = (newValue) => {
    this.setState({ creditBalanceStatus: newValue });
  };
  handleMigrationStatusChange = (newValue) => {
    this.setState({ migrationStatus: newValue });
  };

  handleAddClick = () => {
    this.setState({
      selectedDriver: null,
      showDialog: true,
      actionType: 'add',
    });
    this.props.router.push('/driver/add');
  };

  handleExportToExcelClick = () => {
    this.doExport(true, null);
  };

  prepareParamExport = () => {
    const query = { fleetId: this.props.auth.selectedFleet.fleetId };
    if (this.state.startDate && this.state.endDate) {
      let startDate = this.state.startDate.toLocalISOString(
        this.props.auth.selectedFleet.timezone
      );
      let endDate = this.state.endDate
        .addDays(1)
        .toLocalISOString(this.props.auth.selectedFleet.timezone);

      query.startDate = moment(startDate)
        .tz(this.props.auth.selectedFleet.timezone)
        .format('YYYY[-]MM[-]DD');
      query.endDate = moment(endDate)
        .tz(this.props.auth.selectedFleet.timezone)
        .format('YYYY[-]MM[-]DD');
    }
    query.locale = localStorage.getItem('language');
    if (this.state.str) {
      query.str = this.state.str;
      query.searchBy = this.state.searchBy;
    }
    let sort = { createdDate: this.state.sortType };
    if (this.state.sortName) {
      sort = { [this.state.sortName]: this.state.sortType };

      // detect last active
      if (this.state.sortName === 'lastActive') {
        sort = { [this.state.sortName]: this.state.sortType * -1 };
      }
    }

    if (this.state.zoneId) {
      query.zoneId = this.state.zoneId;
    }
    if (this.state.marketPlace) {
      query.marketplace = this.state.marketPlace;
    }
    if (this.state.cityId) {
      query.cityId = this.state.cityId;
    }
    if (this.getCompanyIdToQuery()) {
      query.companyId = this.getCompanyIdToQuery();
    }

    if (this.state.activeStatus) {
      query.status = this.state.activeStatus;
    }

    if (this.state.cashBalanceStatus) {
      query.cashBalance = this.state.cashBalanceStatus;
    }
    if (this.state.stripeConnectStatus) {
      query.cashBalance = this.state.stripeConnectStatus;
    }
    if (this.state.creditBalanceStatus) {
      query.creditBalance = this.state.creditBalanceStatus;
    }
    if (
      this.state.migrationStatus &&
      this.props.auth.selectedFleet.fleetId == 'mycar'
    ) {
      query.migrationStatus = this.state.migrationStatus;
    }
    if (this.state.selectedCarTypes && this.state.selectedCarTypes.length) {
      query.carType = this.state.selectedCarTypes;
    }
    if (this.state.driverType) {
      query.driverType = this.state.driverType;
    }
    query.total = _.get(this.state, 'footerData.total', 0);
    query.referralCode = this.props.auth.selectedFleet.referral.enable
      ? 'true'
      : 'false';

    return { query };
  };

  handleChangeAllDriverStatus = (isActive) => {
    const drivers = this.state.driver.list || [];
    if (drivers.filter((user) => user.checked).length == 0) {
      this.setState({
        confirm: {
          title: isActive
            ? I18n.t('driver.Deactivate')
            : I18n.t('driver.Activate'),
          body: isActive
            ? I18n.t('driver.ALERT_SELECT_DEACTIVATE')
            : I18n.t('driver.ALERT_SELECT_ACTIVATE'),
        },
      });
    } else {
      this.setState({
        confirm: {
          id: isActive ? CONFIRM_DEACTIVATE_ID : CONFIRM_ACTIVATE_ID,
          title: isActive
            ? I18n.t('driver.Deactivate')
            : I18n.t('driver.Activate'),
          body: isActive
            ? I18n.t('driver.CONFIRM_DEACTIVATE_MULTI')
            : I18n.t('driver.CONFIRM_ACTIVATE_MULTI'),
          buttonTitle: 'Yes',
          closeButtonText: 'No',
        },
      });
    }
  };

  handleDeleteClick = () => {
    if (this.state.driver.list.filter((user) => user.checked).length == 0) {
      this.setState({
        confirm: {
          title: I18n.t('driver.Delete'),
          body: I18n.t('driver.ALERT_SELECT_DELETE'),
        },
      });
    } else {
      this.setState({
        confirm: {
          id: CONFIRM_DELETE_ID,
          title: I18n.t('driver.Delete'),
          body: I18n.t('driver.CONFIRM_DELETE_MULTI'),
          buttonTitle: 'Yes',
          closeButtonText: 'No',
        },
      });
    }
  };

  showFormCommission = (isShow) => {
    const { selectedFleet } = this.props.auth;
    const currencyISO =
      selectedFleet.currencies && selectedFleet.currencies[0].iso;
    this.setState({
      showComission: isShow,
      currencyISO,
    });
  };

  onChangeCurrencyCommission = (currencyISO) => {
    this.setState({ currencyISO });
  };

  handleApplyCommission = (data) => {
    this.setState({
      confirm: {
        id: CONFIRM_SET_COMMISSION,
        title: I18n.t('driver.Apply'),
        body: I18n.t('driver.Confirm_Set_Commission'),
        buttonTitle: 'Yes',
        closeButtonText: 'No',
      },
      dataCommission: {
        fleetId: data.fleetId,
        commission: data.commission,
        currencyISO: data.currencyISO,
        commissionType: data.commissionType,
      },
    });
  };

  handleSendMessageClick = () => {
    this.props.router.push({
      pathname: '/driver/message/',
    });
  };

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null });
  };

  handleConfirmButtonClick = (id) => {
    switch (id) {
      case CONFIRM_DELETE_ID:
        {
          if (this.state.driver.list) {
            const listUser = this.state.driver.list
              .filter((user) => user.checked)
              .map((user) => user.userId);
            if (listUser && listUser.length > 0) {
              this.props
                .deleteDriver({
                  userIds: listUser,
                  fleetId: this.props.auth.selectedFleet.fleetId,
                })
                .then((data) => {
                  if (data.ok) {
                    const deletedDriverIds = data.res
                      .filter((o) => o.deleteAccount)
                      .map((o) => o.userId);
                    const {
                      footerData,
                      driver,
                      driver: { list },
                    } = this.state;
                    this.setState({
                      driver: {
                        ...driver,
                        list: list.filter(
                          (o) => deletedDriverIds.indexOf(o._id) === -1
                        ),
                      },
                      footerData: {
                        ...footerData,
                        total: footerData.total - deletedDriverIds.length,
                      },
                      driverNumberChanges:
                        this.state.driverNumberChanges -
                        deletedDriverIds.length,
                    });
                  }
                });
            }
          }
        }
        break;
      case CONFIRM_ACTIVATE_ID:
        {
          if (this.state.driver.list) {
            const listUser = this.state.driver.list
              .filter((user) => user.checked && user.driverInfo.isComplete)
              .map((user) => user);
            if (listUser && listUser.length > 0) {
              this.changeDriverStatus(listUser, false);
            }
          }
        }
        break;
      case CONFIRM_DEACTIVATE_ID:
        {
          if (this.state.driver.list) {
            const listUser = this.state.driver.list
              .filter((user) => user.checked)
              .map((user) => user);
            if (listUser && listUser.length > 0) {
              this.changeDriverStatus(listUser, true);
            }
          }
        }
        break;
      case CONFIRM_DELETE_LINK_ID:
        {
          this.props
            .deleteDriver({
              userIds: [deleteUserId],
              fleetId: this.props.auth.selectedFleet.fleetId,
            })
            .then((data) => {
              if (data.ok) {
                const {
                  footerData,
                  driver,
                  driver: { list },
                } = this.state;
                this.setState({
                  driver: {
                    ...driver,
                    list: list.filter((o) => o._id !== deleteUserId),
                  },
                  footerData: {
                    ...footerData,
                    total: footerData.total - 1,
                  },
                  driverNumberChanges: this.state.driverNumberChanges - 1,
                });
              }
            });
        }
        break;
      case CONFIRM_EXPORT_ID:
        {
        }
        break;
      case CONFIRM_SET_COMMISSION: {
        this.props
          .setFleetCommissionDriver(this.state.dataCommission)
          .then((data) => {
            if (data.ok) {
              this.setState({ showComission: false });
              this.context.notification(
                'success',
                I18n.t('driver.Commission_Successfully')
              );
            } else {
            }
          });
      }
      default:
        break;
    }
    this.handleConfirmCloseClick();
  };

  getAllSupplier = () => {
    if (!this.props.auth?.user?.roles?.isSupplier) {
      let param = {
        limit: 500,
        page: 0,
        fleetId: this.props.auth?.selectedFleet?.fleetId,
        isSupplier: true,
        agentId: this.props.auth?.user?.roles?.companyId,
      };
      this.props.settingActions.getAllCompany(param).then((data) => {
        if (data.ok) {
          if (data?.res?.list?.length) {
            this.setState({
              supplierList: data?.res?.list
            })
          }
        }
      });
    }
  }

  handleChangeDateRange = (params) => {
    let { disableDatetimeSelection } = this.state;
    disableDatetimeSelection = params.selectedButton !== 'custom';
    if (params.selectedButton !== 'custom') {
      this.setState({
        startDate: params.fromDate,
        endDate: params.toDate,
        selectedButton: params.selectedButton,
        disableDatetimeSelection,
      });
    } else {
      this.setState({
        selectedButton: params.selectedButton,
        disableDatetimeSelection,
      });
    }
  };

  handleCustomDateSelect = () => {
    if (this.dateFromInput) {
      this.setState({ showDateFrom: true }, () => this.dateFromInput.focus());
    }
  };

  handleDateStartDateChange = (e) => {
    this.setState({ startDate: e._d });
    if (!this.state.endDate) {
      const today = new Date();
      this.setState({ endDate: today });
    }
  };

  handleDateEndDateChange = (e) => {
    this.setState({ endDate: e._d });
  };

  isFilteredDayValid(current) {
    return !current.isAfter(DateTime.moment());
  }

  getTableColumns = () => {
    let newColumns =
      this.props.auth.selectedFleet.fleetId === 'mycar'
        ? Columns
        : Columns.filter(function (col) {
            return col.key != 'migrationStatus';
          });
    if (!this.props.auth.selectedFleet.referral.enable) {
      newColumns = Columns.filter(function (col) {
        return col.key != 'referralCode';
      });
    }
    const tableColumns = Object.assign([], newColumns);
    const removeColumns = [];
    const {
      auth,
      commonData,
      permissions,
      language: { locale: language },
    } = this.props;
    const enableStripeConnect = _.get(
      auth,
      'selectedFleet.drvPayout.activeConnectAccount',
      false
    );
    // hidden columns
    if (
      !auth.selectedFleet.password ||
      !auth.selectedFleet.password.driver ||
      auth.selectedFleet.password.regBy !== 1
    )
      removeColumns.push('username');

    if (!auth.selectedFleet.driverDeposit) removeColumns.push('creditBalances');

    if (!auth.selectedFleet.driverCashWallet) removeColumns.push('cashBalance');

    if (!enableStripeConnect)
      removeColumns.push('driverInfo.stripeConnectStatus');

    if (auth?.user?.roles?.companyId) removeColumns.push('driverInfo.agent.name');
    if (auth?.user?.roles?.isSupplier) {
      removeColumns.push('driverInfo.supplier.name');
      removeColumns.push('creditBalances');
      removeColumns.push('cashBalance');
    } 

    _.remove(tableColumns, (col) => removeColumns.indexOf(col.key) !== -1);

    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'fullName':
          col.customCell = (driver) => (
            <OverlayTrigger
              placement="top"
              delayShow={300}
              overlay={
                <Tooltip id="tooltip">
                  <span>{driver.fullName}</span>
                </Tooltip>
              }
            >
              {driver.firstName != 'Apple Review' &&
              driver.lastName != 'No Delete' ? (
                <Link
                  className="clickable"
                  to={`/driver/view/${driver.userId}/info`}
                >
                  {driver.fullName}
                </Link>
              ) : (
                <span>{driver.fullName}</span>
              )}
            </OverlayTrigger>
          );
          break;
        case 'driverInfo.driverType':
          col.customCell = (driver) => {
            switch (driver.driverInfo.driverType) {
              case 'Individual Driver':
                return <Translate value="driver.Individual_Driver" />;
              case 'Fleet Owner':
                return <Translate value="driver.Owner_Operator" />;
              case 'deliveryIndividual':
                return <Translate value="driver.deliveryIndividual" />;
              case 'deliveryCompany':
                return <Translate value="driver.deliveryCompany" />;
              default:
                return;
            }
          };
          break;
        case 'rank':
          col.customCell = (driver) =>
            driver.rank === 1 ? (
              <i className="fa fa-check fa-2x activateIcon" />
            ) : (
              ''
            );
          break;
        case 'driverInfo.agent.name':
          col.customCell = (driver) => {
            let checkAgent = this.props.commonData?.companies?.map(item => item?.name).includes(driver?.driverInfo?.company?.name)
            let agentInclude = this.props.commonData?.suppliers?.filter(item => item?.name === driver?.driverInfo?.company?.name);
            if (!checkAgent && agentInclude[0]?.agentId) {
              let newNameCompany = this.props.commonData?.companies?.filter(item => item?._id === agentInclude[0]?.agentId);
              return newNameCompany[0]?.name || '';
            }
            return checkAgent ? driver?.driverInfo?.company?.name : '';
          };
          break;

        case 'driverInfo.supplier.name':
          col.customCell = (driver) => {
            let checkAgent = this.props.commonData?.companies?.map(item => item?.name).includes(driver?.driverInfo?.company?.name)
            return checkAgent ? '' : driver?.driverInfo?.company?.name;
          };
          break;

        case 'driverInfo.zoneName':
          col.customCell = (driver) => (
            <OverlayTrigger
              placement="top"
              delayShow={300}
              overlay={
                <Tooltip id={`zone_tooltip_${driver._id}`}>
                  <p>
                    {driver.driverInfo.zoneName &&
                    driver.driverInfo.zoneName.length > 0
                      ? driver.driverInfo.zoneName
                          .map((item) => item)
                          .join(', ')
                      : ''}
                  </p>
                </Tooltip>
              }
            >
              <span className="cursor-pointer">
                {driver.driverInfo.zoneName &&
                driver.driverInfo.zoneName.length > 0
                  ? driver.driverInfo.zoneName.map((item) => item).join(', ')
                  : ''}
              </span>
            </OverlayTrigger>
          );
          break;
        case 'createdDate':
          col.customCell = (driver) => {
            driver.language = language;
            return (
              <Moment
                tz={auth.selectedFleet.timezone}
                format="MMM DD, YYYY hh:mm A"
                date={driver.createdDate}
                locale={driver.language}
              />
            );
          };
          break;
        case 'lastActive':
          col.customCell = (driver) => {
            driver.language = language;
            return driver.lastActive ? (
              <Moment
                tz={auth.selectedFleet.timezone}
                format="MMM DD, YYYY hh:mm A"
                date={driver.lastActive}
                locale={driver.language}
              />
            ) : (
              'N/A'
            );
          };
          break;
        case 'Days_Since_Last_Active':
          col.customCell = (driver) => {
            let days = 'N/A';
            if (driver.lastActive) {
              const m = moment(driver.lastActive);
              const today = moment().utcOffset(0).startOf('day');
              days = Math.round(moment.duration(today - m).asDays());
              if (days < 0) days = 0;
            }
            return <span>{days}</span>;
          };
          break;
        case 'booking.completed':
          col.customCell = (driver) =>
            driver.booking && driver.booking.completed
              ? driver.booking.completed
              : 0;
          break;
        case 'driverInfo.stripeConnectStatus':
          col.customCell = (driver) => {
            let statusStr = '',
              statusClass = '';
            switch (_.get(driver, 'driverInfo.stripeConnectStatus', '')) {
              case 'activated':
                statusStr = 'Active';
                statusClass = 'Completed';
                break;
              case 'pending':
                statusStr = 'In Review';
                statusClass = 'inProgress';
                break;
              default:
                statusStr = 'Inactive';
                statusClass = 'Inactive';
                break;
            }

            return <div className={statusClass}>{statusStr}</div>;
          };
          break;
        case 'profileStatus':
          col.customCell = (driver) => {
            let statusStr;
            let statusClass;
            if (
              driver.driverInfo &&
              driver.driverInfo.statusReview === 'completed'
            ) {
              statusStr = <Translate value="driver.profile_completed" />;
              statusClass = 'Completed';
            } else if (driver.driverInfo.statusReview === 'inComplete') {
              statusStr = <Translate value="driver.profile_incomplete" />;
              statusClass = 'incomplete';
            } else if (driver.driverInfo.statusReview === 'inProgress') {
              statusStr = <Translate value="driver.profile_inProgress" />;
              statusClass = 'inProgress';
            } else {
              statusStr = <Translate value="driver.profile_completed" />;
              statusClass = 'Completed';
            }
            return <div className={statusClass}>{statusStr}</div>;
          };
          break;
        case 'driverStatus':
          col.customCell = (driver) => {
            let statusStr;
            let statusClass;
            if (driver && driver.isActive) {
              statusStr = <Translate value="driver.Active" />;
              statusClass = 'Active';
            } else if (driver.driverInfo && driver.driverInfo.isActivate) {
              statusStr = <Translate value="driver.Inactive" />;
              statusClass = 'Inactive';
            } else {
              statusStr = <Translate value="driver.In_Review" />;
              statusClass = 'Inreview';
            }
            return (
              <div className={statusClass}>
                {(!permissions || permissions.activatedriver) &&
                driver.firstName != 'Apple Review' &&
                driver.lastName != 'No Delete' &&
                driver.driverInfo.statusReview === 'completed' ? (
                  <a
                    onClick={() =>
                      this.changeDriverStatus(
                        [driver],
                        statusClass === 'Active'
                      )
                    }
                    href="javascript:void(0)"
                  >
                    {statusStr}
                  </a>
                ) : (
                  statusStr
                )}
              </div>
            );
          };
          break;
        case 'actions':
          col.customCell = (driver, rowIndex) => {
            let statusClass;
            if (permissions && permissions.activatedriver) {
              if (driver && driver.isActive) {
                statusClass = 'Active';
              } else if (driver.driverInfo && driver.driverInfo.isActivate) {
                if (driver.driverInfo.statusReview !== 'completed') {
                  statusClass = 'disableActivateDriver';
                } else {
                  statusClass = 'Inactive';
                }
              } else {
                if (driver.driverInfo.statusReview !== 'completed') {
                  statusClass = 'InreviewAndInProgress';
                } else {
                  statusClass = 'Inreview';
                }
              }
            } else {
              statusClass = 'disableActivateDriver';
            }
            return (
              <div className="driver-actions-container">
                {driver.firstName === 'Apple Review' ||
                driver.lastName === 'No Delete' ? null : !permissions ||
                  permissions.actions ? (
                  <TableActions
                    rowIndex={rowIndex}
                    rowData={driver}
                    onSelect={this.handleMenuClick}
                    totalRow={this.state.driver.list.length}
                    rowHeight={this.state.rowHeight}
                    menuItems={DriverActionMenuItem[statusClass]}
                    tableHeight={this.state.tableHeight}
                  />
                ) : (
                  <a
                    onClick={() => this.handleMenuClick('3', driver)}
                    href="javascript:void(0)"
                  >
                    <Translate value="driver.View" />
                  </a>
                )}
              </div>
            );
          };
          break;
        default:
          break;
      }
    });

    const checkBoxCol = this.renderSelectBoxColumn();
    tableColumns.unshift(checkBoxCol);

    return tableColumns;
  };

  renderSelectBoxColumn = () => {
    let isCheckedAll = false;
    if (
      this.state.driver &&
      this.state.driver.list &&
      this.state.driver.list.length > 0
    ) {
      isCheckedAll =
        _.filter(this.state.driver.list, (item) => item.checked).length ===
        this.state.driver.list.length;
    }

    return {
      key: 'table-selectbox',
      label: '',
      width: 60,
      fixed: true,
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
      customHeader: (columnKey) => (
        <CcCheckbox
          className="table-selectbox all-checkbox"
          checked={isCheckedAll}
          onChange={this.handleSelectAllClick}
        />
      ),
      customCell: (driver) => (
        <CcCheckbox
          className="table-selectbox"
          checked={driver.checked}
          //Do not allow operator check/uncheck to action the Apple Review Account
          disabled={
            driver.firstName == 'Apple Review' && driver.lastName == 'No Delete'
          }
          onChange={(e) => {
            this.handleSelectRowClick(e.target.checked, driver.userId);
          }}
        />
      ),
    };
  };

  getTableHeight = () => {
    const verticalPadding = 10;
    const toolbarMarginBottom = 30;
    const filterHeight = 100;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    const outerHeight =
      verticalPadding + toolbarMarginBottom + toolbarheight + filterHeight;
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && tableHeight < 500) tableHeight = 550;
    this.state.tableHeight = tableHeight;
    return tableHeight;
  };

  closeDialog = () => {
    this.props.router.push({
      pathname: '/driver',
    });
    this.setState({
      showDialog: false,
      selectedDriver: '',
    });
  };

  onUpdateDriver = (driverInfo, showDialog = false) => {
    const {
      driver,
      footerData,
      footerData: { total, active } = {},
    } = this.state;
    const foundIndex = _.findIndex(
      driver.list,
      (o) => o.userId === driverInfo.userId
    );
    if (foundIndex !== -1) {
      let isDriverChangeStatus = 0;
      const updateList = _.map(driver.list, (d) => {
        if (d.userId === driverInfo.userId) {
          isDriverChangeStatus = !d.isActive
            ? !driverInfo.isActive
              ? 0
              : 1
            : !driverInfo.isActive
            ? -1
            : 0;
          return { ...d, ...driverInfo };
        }
        return d;
      });
      this.setState({
        driver: {
          ...driver,
          list: updateList,
        },
        showDialog,
        selectedDriver: showDialog ? driverInfo.userId : '',
        footerData: {
          ...footerData,
          active: active + isDriverChangeStatus,
        },
      });
    } else {
      this.setState(
        {
          zoneId: null,
          cityId: null,
          companyId: null,
          marketPlace: 'all',
          activeStatus: 'all',
          cashBalanceStatus: 'all',
          stripeConnectStatus: 'all',
          creditBalanceStatus: 'all',
          migrationStatus: 'all',
          str: '',
          showDialog,
          selectedDriver: '',
          driverNumberChanges: this.state.driverNumberChanges + 1,
        },
        () => {
          this.getListDrivers(0, null, true, driverInfo);
        }
      );
    }
  };

  render() {
    const bodyData =
      this.state.driver && this.state.driver.list ? this.state.driver.list : [];
    /* SL-9695 Hide "Export to excel" button for Tappcar fleet */
    const { selectedFleet } = this.props.auth;
    /*  let ignoreExport = false;
    if (ignoreExportCustomer) {
      const ignoreFleets = ignoreExportCustomer.split(';');
      ignoreExport = ignoreFleets.indexOf(selectedFleet.fleetId) !== -1;
    } */
    const {
      permissions = null,
      auth: { user } = {},
      language: { locale: language },
    } = this.props || {};
    const { actions = false, activatedriver = false } = permissions || {};
    var exportPermission = user.isAdmin ? true : permissions.export;
    if (!user.isAdmin && selectedFleet.fleetId === 'applecabssouth') {
      exportPermission = false;
    }
    const ableToDownloadAccount = _.get(
      selectedFleet,
      'generalSetting.ableToDownloadAccount',
      true
    );
    const totalBalancePermission = user.isAdmin
      ? true
      : permissions.totalbalance;
    const setCommissionPermisison = user.isAdmin
      ? true
      : permissions.sefleetcommission;
    const editCommissionPermisison = user.isAdmin
      ? true
      : permissions.editCommissionPermisison;
    const getZoneBasedCompany = () => {
      const companyId = this.props.auth?.user?.roles?.companyId || '',
        fleetZones = this.props.commonData.mapZone,
        fleetCompany = [...this.props.commonData.companies, ...this.props.commonData.suppliers];

      if (companyId) {
        const cpInfo = fleetCompany.find((cp) => cp._id === companyId);
        if (cpInfo?.operationZone?.length > 0) {
          return fleetZones.filter((zone) => {
            if (!cpInfo?.operationZone?.length) {
              return true;
            }
            return cpInfo?.operationZone.includes(zone._id)
          });
        }
      }
      const newZones = _.filter(fleetZones, (z) => z.isActive && z.display);
      return newZones;
    };
    let zoneItems = getZoneBasedCompany() || [];
    const cities = _.cloneDeep(this.props.commonData.cities);
    const companies = _.cloneDeep([...this.props.commonData.companies, ...this.props.commonData.suppliers]);
    const isShowInbox = user.isAdmin
      ? true
      : permissions.sendinbox || permissions.sendsms
      ? true
      : false;

    const enableDelivery =
      (selectedFleet.delivery && selectedFleet.delivery.enable) ||
      (selectedFleet.food && selectedFleet.food.enable) ||
      (selectedFleet.mart && selectedFleet.mart.enable);

    let driverTypes = [
      {
        key: '0',
        value: DriverType.OwnerOperator,
        name: 'driver.Owner_Operator',
      },
      {
        key: '1',
        value: DriverType.IndividualDriver,
        name: 'driver.Individual_Driver',
      },
    ];
    if (enableDelivery) {
      driverTypes.push(
        {
          key: '2',
          value: DriverType.deliveryIndividual,
          name: 'driver.deliveryIndividual',
        },
        {
          key: '3',
          value: DriverType.deliveryCompany,
          name: 'driver.deliveryCompany',
        }
      );
    }

    const enableStripeConnect =
      (selectedFleet.drvPayout &&
        selectedFleet.drvPayout.activeConnectAccount) ||
      false;

    return (
      <div className="content">
        <div className="mb">
          <ButtonToolbar
            className="text-center header-button-group mb0"
            ref={(node) => (this.toobarContainer = node)}
          >
            <ButtonGroup className="group-left">
              <FormGroup className="search-format mb0 search-by">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control search-form"
                    value={this.state.str}
                    onKeyPress={this.handleSearchKeyPress}
                    onChange={this.handleSearchChange}
                    placeholder={I18n.t('General.search')}
                    disabled={this.state.isLoading}
                  />
                  {this.state.isLoading ? (
                    <i className="fa fa-circle-o-notch fa-spin spinner-icon" />
                  ) : (
                    <BsSearch className="search-icon" />
                  )}
                </div>
                <CCDropDown
                  id="driver-search-by-dropdown"
                  title=""
                  items={DriverSearchBy}
                  selectedItems={[
                    this.state.searchBy || DriverSearchBy[0].value,
                  ]}
                  valueKey="value"
                  labelKey="label"
                  onSelect={(value) => this.setState({ searchBy: value })}
                  className="search-by-dropdown"
                  disabled={this.state.isLoading}
                  pullRight
                />
              </FormGroup>

              <React.Fragment>
                {!permissions || actions || permissions.add ? (
                  <Button
                    className="btn-header text-add-header"
                    onClick={this.handleAddClick}
                  >
                    {' '}
                    <Translate value="driver.Add" />
                  </Button>
                ) : null}
                {/* <Button
                      className="btn-header text-delete-header"
                      onClick={this.handleDeleteClick}
                    >
                      {' '}
                      <Translate value="driver.Delete" />
                    </Button> */}
                {this.props?.auth?.user?.roles?.isSupplier && this.props?.commonData?.companies[0]?.supplierApproval && activatedriver ? (
                  <Button
                    className="btn-header text-active-header"
                    onClick={() => {
                      this.handleChangeAllDriverStatus(false);
                    }}
                  >
                    {' '}
                    <Translate value="driver.Activate" />
                  </Button>
                ) : null}
                {activatedriver ? (
                  <Button
                    className="btn-header text-deactive-header"
                    onClick={() => {
                      this.handleChangeAllDriverStatus(true);
                    }}
                  >
                    <Translate value="driver.Deactivate" />
                  </Button>
                ) : null}
              </React.Fragment>
            </ButtonGroup>
            <ButtonGroup className="group-right">
              {permissions &&
                totalBalancePermission &&
                !getSuppilerPermission(this.props.auth) && <TotalBalance />}
              {isShowInbox ? (
                permissions && actions ? (
                  <Button
                    className="btn-header text-add-header"
                    onClick={this.handleSendMessageClick}
                  >
                    <Translate value="driver.Send_Message" />
                  </Button>
                ) : (
                  <Button
                    className="btn-header text-add-header"
                    onClick={this.handleSendMessageClick}
                  >
                    <Translate value="driver.View_Message" />
                  </Button>
                )
              ) : null}

              {/* {this.props.permissions.export && (
                  <Button
                    className="btn-header text-add-header"
                    onClick={this.handleExportToExcelClick}
                  >

                    <Translate value="customer.Export_to_excel" />
                  </Button>
                )} */}
              {permissions && exportPermission && ableToDownloadAccount && (
                <ExportComponent
                  prepareParamExport={this.prepareParamExport}
                  urlExport={this.props.exportDriverToExcel}
                  user={this.props.auth.user}
                />
              )}
            </ButtonGroup>
          </ButtonToolbar>
          <div className="driver-toolbar-extend">
            <div className="toolbarExtend--leftContent">
              <div className="mb0">
                <DateRange
                  selectedButton={this.state.selectedButton}
                  setDateRange={this.handleChangeDateRange}
                  customRangeSelectCallback={this.handleCustomDateSelect}
                />
              </div>
              <FormGroup className="mb0">
                <DateTime
                  value={moment(this.state.startDate).locale(language)}
                  timeFormat={false}
                  onChange={this.handleDateStartDateChange}
                  inputProps={{
                    readOnly: true,
                    className: 'date-readonly form-control',
                    placeholder: I18n.t('customer.Registration_date_from'),
                    value: this.state.startDate
                      ? moment(this.state.startDate)
                          .locale(language)
                          .format('L')
                      : '',
                    disabled: this.state.disableDatetimeSelection,
                  }}
                  isValidDate={this.isFilteredDayValid}
                  closeOnSelect
                />
              </FormGroup>
              <FormGroup className="mb0">
                <DateTime
                  value={moment(this.state.endDate).locale(language)}
                  timeFormat={false}
                  onChange={this.handleDateEndDateChange}
                  inputProps={{
                    readOnly: true,
                    className: 'date-readonly form-control',
                    placeholder: I18n.t('customer.Registration_date_to'),
                    value: this.state.endDate
                      ? moment(this.state.endDate).locale(language).format('L')
                      : '',
                    disabled: this.state.disableDatetimeSelection,
                  }}
                  isValidDate={this.isFilteredDayValid}
                  closeOnSelect
                />
              </FormGroup>
            </div>
            <div>
              <TotalDrivers
                driverNumberChanges={this.state.driverNumberChanges}
                total={this.state.footerData.total}
              />
            </div>
          </div>
          <div className="driver-toolbar-extend driver-toolbar-filter">
            <div className="toolbarExtend--leftContent">
              <CCDropDown
                id="company-dropdown"
                items={[...companies]}
                title={I18n.t('report.query.company')}
                selectedItems={this.state.companyId || []}
                valueKey="_id"
                labelKey="name"
                onSelect={this.handleCompanyChange}
                noTranslateLabel
                searchable
                enableClearAll
              />
              <CCDropDown
                id="driverType-dropdown"
                items={driverTypes}
                title={I18n.t('driver.driverType')}
                selectedItems={this.state.driverType || []}
                valueKey="value"
                labelKey="name"
                onSelect={this.handleDriverTypeChange}
                enableClearAll
              />
              <CCDropDown
                id="car-type-dropdown"
                items={this.props.commonData.carType}
                title={I18n.t('cue.Car_type')}
                selectedItems={this.state.selectedCarTypes}
                labelKey="vehicleType"
                valueKey="_id"
                onSelect={this.handleCarTypesChange}
                multiSelect
                enableClearAll
                noTranslateLabel
                searchable
              />
              <CCDropDown
                id="zone-dropdown"
                items={zoneItems}
                title={I18n.t('cue.Zone')}
                valueKey="_id"
                labelKey="zoneName"
                selectedItems={this.state.zoneId || []}
                onSelect={this.handleZoneChange}
                searchable
                enableClearAll
              />
              <CCDropDown
                id="city-dropdown"
                items={cities}
                title={I18n.t('report.query.city')}
                selectedItems={this.state.cityId || []}
                valueKey="_id"
                labelKey="name"
                onSelect={this.handleCityChange}
                noTranslateLabel
                searchable
                enableClearAll
              />
              {!this.props?.auth?.user?.roles?.isSupplier && <CCDropDown
                id="zone-dropdown"
                items={marketPlaceFilter}
                title={I18n.t('generalSetting.marketPlace')}
                labelKey="label"
                valueKey="value"
                selectedItems={this.state.marketPlace || []}
                onSelect={this.handleMarketPlaceFilterChange}
              />}
              <CCDropDown
                id="status-dropdown"
                items={driverStatus}
                title={I18n.t('cue.Status')}
                selectedItems={this.state.activeStatus}
                labelKey="label"
                valueKey="value"
                onSelect={this.handleActiveStatusChange}
              />

              {!this.props?.auth?.user?.roles?.isSupplier && selectedFleet.driverCashWallet && (
                <CCDropDown
                  id="creditBalance-dropdown"
                  items={creditBalance}
                  title={I18n.t('driver.creditBalance')}
                  selectedItems={this.state.creditBalanceStatus}
                  labelKey="label"
                  valueKey="value"
                  onSelect={this.handleCreditBalanceStatusChange}
                />
              )}
              {!this.props?.auth?.user?.roles?.isSupplier && selectedFleet.driverCashWallet && (
                <CCDropDown
                  id="cashBalance-dropdown"
                  items={cashBalance}
                  title={I18n.t('driver.cashBalance')}
                  selectedItems={this.state.cashBalanceStatus}
                  labelKey="label"
                  valueKey="value"
                  onSelect={this.handleCashBalanceStatusChange}
                />
              )}

              {!this.props?.auth?.user?.roles?.isSupplier && enableStripeConnect && (
                <CCDropDown
                  id="car-type-dropdown"
                  items={stripeConnectStatus}
                  title={I18n.t('driver.stripeStatus')}
                  selectedItems={this.state.stripeConnectStatus}
                  labelKey="label"
                  valueKey="value"
                  onSelect={this.handleStripeStatusChange}
                />
              )}
              {this.props.auth.selectedFleet.fleetId == 'mycar' && (
                <CCDropDown
                  id="car-type-dropdown"
                  items={migrationStatus}
                  title={I18n.t('cue.Migration_Status')}
                  selectedItems={this.state.migrationStatus}
                  labelKey="label"
                  valueKey="value"
                  onSelect={this.handleMigrationStatusChange}
                />
              )}
              <Button
                className="btn-header text-add-header"
                onClick={this.handleApplyFilter}
              >
                <Translate value="customer.Apply" />
              </Button>
              <Button
                className="btn-header text-deactive-header"
                onClick={this.handlelearFilter}
              >
                <Translate value="customer.Clear" />
              </Button>
            </div>
          </div>
        </div>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            sortHandle={this.handleSortDriverClick}
            sortType={this.state.sortType}
            sortName={this.state.sortName}
            getTableHeight={this.getTableHeight}
            footerLoading={this.state.footerLoading}
            isLoading={this.state.isLoading}
            language={language}
          />
        </div>
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
        {setCommissionPermisison ? (
          <Commission
            auth={this.props.auth}
            isShow={this.state.showComission}
            currencyISO={this.state.currencyISO}
            showFormCommission={this.showFormCommission}
            onChangeCurrencyCommission={this.onChangeCurrencyCommission}
            handleApplyCommission={this.handleApplyCommission}
            editFleetCommission={editCommissionPermisison}
          />
        ) : null}
        {this.state.showDialog ? (
          <AddEdit
            isDriverList
            onUpdateDriver={this.onUpdateDriver}
            closeDialog={this.closeDialog}
            visible={this.state.showDialog}
            router={this.props.router}
            route={this.props.route}
            params={{ driverId: this.state.selectedDriver }}
            actionType={this.state.actionType}
          />
        ) : null}
      </div>
    );
  }
}

Driver.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    commonData: state.commonData,
    language: state.i18n,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    getDriver: bindActionCreators(getDriver, dispatch),
    deleteDriver: bindActionCreators(deleteDriver, dispatch),
    activateMultiDriver: bindActionCreators(activateMultiDriver, dispatch),
    exportDriverToExcel: bindActionCreators(exportDriverToExcel, dispatch),
    activateDriver: bindActionCreators(activateDriver, dispatch),
    countDriver: bindActionCreators(countDriver, dispatch),
    setFleetCommissionDriver: bindActionCreators(
      setFleetCommissionDriver,
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Driver);
