import moment from 'moment';
import React from 'react';
import Moment from 'react-moment';
import { I18n } from 'react-redux-i18n';
import { getBookingType } from '../../../utils/commonFunctions';
import { BOOK_TYPE } from '../../../constants/commondata';
const PickupDropOffLocalTime = ({
  booking = {},
  lineShort,
  auth,
  isMobile
}) => {
  const timeFormat = auth?.selectedFleet?.format24Hour ? 'HH:mm' : 'hh:mm A',
    expectedTime = booking?.time?.expectedPickupTime
  const pickupTimeText = (
    <Moment
      tz={moment.tz.guess()}
      format={`${timeFormat}, ddd MMM DD, YYYY`}
      date={expectedTime}
      locale={booking?.language}
    />
  )
  if(isMobile) {
    const isBookNow = getBookingType(booking) === BOOK_TYPE.now
    return (
      <div className='pk-time'>
        <span>{I18n.t('cue.pickupTime')}</span>
        <span
          style={{
            color: isBookNow ? '#FDB022' : 'white',
            fontWeight: 500
          }}
        >{isBookNow ? 'ASAP' : pickupTimeText}</span>
      </div>
    )
  }
  return (
    <div className={'time-local'}>
      <p>
        <Moment
          tz={moment.tz.guess()}
          format={`MMM DD, YYYY ${timeFormat}`}
          date={expectedTime}
          locale={booking?.language}
        />
      </p>
      {booking?.time.droppedOff && !lineShort && (
        <p>
          <Moment
            format={`MMM DD, YYYY ${timeFormat}`}
            date={booking.time?.droppedOff}
            locale={booking?.language}
          />
        </p>
      )}
    </div>
  )
};

export default PickupDropOffLocalTime;
