import React, { useState, useEffect } from 'react';
import { FormControl, Modal, Button } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import _ from 'lodash';

const LIST_BUTTON = [
  {
    label: 'bookingdetail.Completed_Booking',
    key: 'default',
    disabled: true,
    hidden: true,
  },
  { label: 'bookingdetail.Late_Cancel', key: 'canceled' },
  { label: 'bookingdetail.Cancel_Driver', key: 'canceledDriver' },
  { label: 'bookingdetail.No_Show', key: 'noShow' },
  { label: 'bookingdetail.Without_driver', key: 'withoutService' },
  { label: 'bookingdetail.Complete_driver', key: 'withService' },
];
const STATUS_CANCELED = ['action', 'pre', 'confirmed', 'booked', 'arrived'];
const STATUS_CANCEL_DRIVER = ['confirmed', 'booked', 'arrived'];
const STATUS_NOSHOW = ['action', 'pre', 'confirmed', 'booked', 'arrived'];
const STATUS_WITHOUTSERVICE = [
  'action',
  'pre',
  'confirmed',
  'booked',
  'arrived',
  'engaged',
  'droppedOff',
];
const STATUS_WITHSERVICE = ['booked', 'arrived', 'engaged', 'droppedOff'];

const OverrideButton = (props) => {
  const [valueSelected, setValueSelected] = useState('default');
  const [listBtnComplete, setListBtnComplete] = useState(LIST_BUTTON);
  const [isShowModal, setShowModal] = useState(false);
  const [isChargeDrvCancel, setChargeDrvCancel] = useState(false);
  const [applyCancellationPolicy, setApplyCancellationPolicy] = useState(false);

  useEffect(() => {
    const list = LIST_BUTTON.filter((btn) => {
      if (btn.key === 'default') return true;
      // check to show Cancel button
      if (
        btn.key === 'canceled' &&
        STATUS_CANCELED.includes(props.status) &&
        !props.isHydraBooking &&
        !props.is3rdBooking
      ) {
        return true;
      }
      if (
        btn.key === 'canceledDriver' &&
        STATUS_CANCEL_DRIVER.includes(props.status) &&
        !props.isHydraBooking
      ) {
        return true;
      }
      // check to show NoShow button
      if (btn.key === 'noShow' && STATUS_NOSHOW.includes(props.status)) {
        return true;
      }
      // check to show Without Service button
      if (
        btn.key === 'withoutService' &&
        STATUS_WITHOUTSERVICE.includes(props.status)
      ) {
        return true;
      }
      // check to show With Service button
      if (
        btn.key === 'withService' &&
        STATUS_WITHSERVICE.includes(props.status) &&
        !props.isHydraBooking
      ) {
        return true;
      }
      return false;
    });
    setListBtnComplete(list);
  }, [props.status]);

  const handleSelectComplete = (e) => {
    if (e.target.value === 'canceledDriver') {
      props.loadingActions.showLoadingSpiner();
      props.bookingDetailActions
        .checkChargeDrvCancel({
          fleetId: props.fleetId,
          bookId: props.bookId,
        })
        .then((res) => {
          props.loadingActions.hideLoadingSpiner();
          if (res && res.returnCode === 200) {
            setChargeDrvCancel(res.charge);
            setApplyCancellationPolicy(res.charge);
            setShowModal(true);
          } else {
            props.notification('error', 'Can not check driver cancel');
          }
        });
      setValueSelected(e.target.value);
      return;
    }

    if (e.target.value === 'withService') {
      props.completeWithPayment();
      return;
    }

    if (e.target.value === 'noShow') {
      setShowModal(true);
      setValueSelected(e.target.value);
    }

    if (e.target.value === 'canceled') {
      setShowModal(true);
      setValueSelected(e.target.value);
    }

    if (e.target.value === 'withoutService') {
      if (props.is3rdBooking) {
        let destination = _.get(props.booking, 'request.destination.address');
        if (!destination && props.booking.doPoints) {
          destination = _.get(
            props.booking.doPoints[props.booking.doPoints.length - 1],
            'address.address'
          );
        }
        setShowModal(true);
        setValueSelected(e.target.value);
      } else {
        props.completeWithoutService();
        return;
      }
    }
  };

  const closeModal = () => {
    setValueSelected('default');
    setShowModal(false);
  };

  const completeConfirmClick = () => {
    if (valueSelected === 'canceled') {
      props.bookingDetailActions
        .overrideCustomerCancel({
          fleetId: props.fleetId,
          bookId: props.bookId,
          applyCancellationPolicy: applyCancellationPolicy,
        })
        .then((res) => {
          if (res && res.ok) {
            props.notification(
              'success',
              'Booking has been canceled successfully'
            );
            props.closeBooking();
          } else {
            props.notification('error', 'Can not cancel booking');
          }
        });
    }
    if (valueSelected === 'canceledDriver') {
      props.bookingDetailActions
        .overrideDriverCancel({
          fleetId: props.fleetId,
          bookId: props.bookId,
          applyPenalty: applyCancellationPolicy,
        })
        .then((res) => {
          if (res && res.ok) {
            props.notification(
              'success',
              'Booking has been canceled successfully'
            );
            props.closeBooking();
          } else {
            props.notification('error', 'Can not cancel booking');
          }
        });
    }
    if (valueSelected === 'noShow') {
      props.bookingDetailActions
        .overrideCustomerNoShow({
          fleetId: props.fleetId,
          bookId: props.bookId,
        })
        .then((res) => {
          if (res && res.ok) {
            props.notification(
              'success',
              'Booking has been completed successfully'
            );
            props.closeBooking();
          } else {
            props.notification('error', 'Can not complete booking');
          }
        });
    }
    if (valueSelected === 'withoutService') {
      props.completeWithoutService();
    }
  };

  const getMsgOverride = () => {
    if (valueSelected === 'canceled') {
      return (
        <p className="msgOverride">
          Are you sure you want to complete this booking as{' '}
          <span>Late Cancel?</span>
        </p>
      );
    }
    if (valueSelected === 'canceledDriver') {
      if (isChargeDrvCancel) {
        return (
          <p className="msgOverride">
            Are you sure you want to cancel this booking for the driver? A
            penalty fee will be applied.
          </p>
        );
      }
      return (
        <p className="msgOverride">
          Are you sure you want to cancel this booking for the driver?
        </p>
      );
    }
    if (valueSelected === 'noShow') {
      return (
        <p className="msgOverride">
          Are you sure you want to complete this booking as{' '}
          <span>No Show?</span>
          <br /> No show policy will be applied.
        </p>
      );
    }
    if (valueSelected === 'withoutService') {
      return (
        <p className="msgOverride">
          Are you sure you want to <span>Complete without driver?</span>
          <br /> Full charge will be applied to customer. Driver will not
          receive any commission from this booking.
        </p>
      );
    }
    return null;
  };

  const cancellationCashBooking = _.get(
    props.auth,
    'selectedFleet.generalSetting.cancellationCashBooking',
    false
  );

  if(props.isSupplierPermission) return null

  return (
    <>
      <Modal
        show={isShowModal}
        dialogClassName="confirm-dialog"
        onHide={closeModal}
        className={'confirm'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="bookingdetail.Complete_booking" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {getMsgOverride()}
            {
              // case customer cancel
              ((valueSelected === 'canceled' &&
                (props.paymentType !== 1 ||
                  (props.paymentType === 1 &&
                    cancellationCashBooking &&
                    props.customerId)) &&
                (props.paymentType !== 1 ||
                  (props.paymentType === 1 &&
                    cancellationCashBooking &&
                    props.customerId)) &&
                (props.paymentType !== 1 ||
                  (props.paymentType === 1 &&
                    cancellationCashBooking &&
                    props.customerId))) ||
                // case driver cancel
                (valueSelected === 'canceledDriver' && isChargeDrvCancel)) && (
                <CcCheckbox
                  checked={applyCancellationPolicy}
                  onChange={(e) =>
                    setApplyCancellationPolicy(!applyCancellationPolicy)
                  }
                  labelClassName="title"
                  text={'Apply cancellation policy'}
                  className="form-group-title cb_override no-marginBottom"
                />
              )
            }
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-save" onClick={completeConfirmClick}>
            <Translate value="bookingdetail.Confirm" />
          </Button>
          <Button className="btn-cancel" onClick={closeModal}>
            <Translate value="bookingdetail.Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
      <FormControl
        as="select"
        className="overrideButton"
        value={valueSelected}
        placeholder="select"
        onChange={handleSelectComplete}
      >
        {listBtnComplete.map((btn) => {
          return (
            <option
              value={btn.key}
              key={btn.key}
              disabled={btn.disabled}
              hidden={btn.hidden}
            >
              {I18n.t(btn.label)}
            </option>
          );
        })}
      </FormControl>
    </>
  );
};

export default OverrideButton;
