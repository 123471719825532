/* global google */
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getPaths } from '../../settings/dynamicFare/components/utils';
import { QQAutocomplete, QQUltis } from '../../../components/qqMap';
import { geoPlaceDetailMapProvider } from '../../../utils/mapUtils';
import QueueGoogeMap from './QueueGoogeMap';
import PlacesAutocomplete from '../../../components/placesAutocomplete/PlacesAutocomplete';
import { ZoneActionStatus } from '../../../constants/commondata';

import * as queuingAreaActions from '../../../actions/queuingAreaActions';
import * as commonActions from '../../../actions/commonDataAction';
import * as loadingBarActions from "../../../actions/loadingBarActions";
import QueueTencentMap from './QueueTencentMap';
import { getLanguageAutoCompleteSearch } from '../../../utils/commonFunctions';
import AutocompleteWith3rd from '../../../components/qqMap/AutocompleteWith3rd';

class QueuingAreaMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      parentZones: [],
      otherAreas: [],
      otherPickupPolygon: [],
      otherCheckinPolygon: []
    };
    this.center = null;
    this.defaultZoom = 12;
    this.getPaths = getPaths.bind(this);
  }

  componentDidMount() {
    const {
      queuingAreaActions: { queuingAreaOtherAreas },
      auth: { selectedFleet },
      queueId,
      center
    } = this.props;
    if (center) {
      this.setCenterAndZoom(center);
    } else {
      this.getLocation();
    }
    queuingAreaOtherAreas({
      fleetId: selectedFleet.fleetId,
      _id: queueId
    }).then(({ res }) => {
      if (res) {
        this.setState(
          {
            otherAreas: res
          },
          this.initMapZone
        );
      }
    });
  }

  shouldComponentUpdate(nextProps) {
    const { center: currentCenter, activePickUpId, activeCheckIn } = this.props;
    const { center } = nextProps;
    if (center && !_.isEqual(currentCenter, center)) {
      this.setCenterAndZoom(center);
    }
    if (nextProps.activePickUpId && !_.isEqual(activePickUpId, nextProps.activePickUpId)) {
      const { pickUpAreas } = this.transformQueuePolygon();
      const foundArea = _.find(pickUpAreas, o => o.key === nextProps.activePickUpId);
      if (foundArea) {
        this.onPickUpPolygonClick(foundArea);
      }
    }

    if (nextProps.activeCheckIn && !_.isEqual(activeCheckIn, nextProps.activeCheckIn)) {
      const { driverAreaPolygon } = this.transformQueuePolygon();
      if (driverAreaPolygon) {
        this.onDriverAreaPolygonClick(driverAreaPolygon);
      }
    }
    return true;
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        const center = { lat: coords.latitude, lng: coords.longitude };
        this.setCenterAndZoom(center);
      });
    }
  };

  onCenterChanged = () => {
    if (this._map && this._map.getCenter && this._map.context) {
      const lat = this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
        .getCenter()
        .lat();
      const lng = this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
        .getCenter()
        .lng();
      this.center = {
        lat,
        lng
      };
    }
  };

  initMapZone = async () => {
    const { commonData, commonActions, auth, loadingBarActions } = this.props;
    const { otherAreas } = this.state;
    // init zone active fleet
    const parentZones = [];
    let otherPickupPolygon = [],
      finalZones = commonData.mapZone
    const otherCheckinPolygon = [];

    // load lại zones nếu chưa load full geo
    if(!commonData.isZoneLoadFullGeo) {
      loadingBarActions.showLoadingSpiner();
      const result = await commonActions.loadMapZoneFullGeo(auth.selectedFleet.fleetId)
      loadingBarActions.hideLoadingSpiner()
      if(result?.res?.length > 0) {
        finalZones = result?.res
      }
    }
    _.forEach(finalZones, operationZone => {
      if (operationZone.isActive && operationZone.display) {
        parentZones.push({
          _id: operationZone._id,
          name: operationZone.zoneName,
          isparentZones: true,
          isActive: true,
          path: operationZone.geo.coordinates[0].map(pos => {
            return {
              lat: pos[1],
              lng: pos[0]
            };
          })
        });
      }
    });

    if (otherAreas.length) {
      otherAreas.forEach(area => {
        otherCheckinPolygon.push({
          key: area._id,
          path: _.get(area, 'geo.coordinates[0]', []).map(pos => {
            return {
              lat: pos[1],
              lng: pos[0]
            };
          })
        });
        otherPickupPolygon = otherPickupPolygon.concat(
          area.pickUpAreas.map(o => {
            return {
              key: o._id,
              path: _.get(o, 'geo.coordinates[0]', []).map(pos => {
                return {
                  lat: pos[1],
                  lng: pos[0]
                };
              })
            };
          })
        );
      });
    }
    this.setState({ parentZones, otherPickupPolygon, otherCheckinPolygon });
  };

  transformQueuePolygon = () => {
    const {
      driverArea,
      pickUpAreas,
      showCheckInArea,
      pickupActiveId
    } = this.props;
    let pickUpPolygons = [];
    let driverAreaPolygon = null;
    if (driverArea && !showCheckInArea) {
      driverAreaPolygon = {
        key: 'driver_area_polygon',
        path: driverArea.coordinates[0].map(pos => {
          return {
            lat: pos[1],
            lng: pos[0]
          };
        })
      };
    }

    if (pickUpAreas && pickUpAreas.length) {
      pickUpPolygons = pickUpAreas.map((area) => {
        return {
          key: area._id,
          path:
          pickupActiveId === area._id
              ? []
              : area.geo.coordinates[0].map(pos => {
                  return {
                    lat: pos[1],
                    lng: pos[0]
                  };
                })
        };
      });
    }
    return { pickUpAreas: pickUpPolygons, driverAreaPolygon };
  };

  handleMapLoad = (key, ref) => {
    if (ref) {
      this[key] = ref;
    }
    if (key == 'map' && this.map && this.props.commonData?.location?.isChina) {
      window.qq.maps.event.addListener(this.map, 'bounds_changed', () => {
        const { center } = this.map;
        this.center = center;
        this.setCenterAndZoom(center);
      });
    }
  };

  handlePolylineClick = e => {
    console.log('---- polyline click ---', e.vertex);
    if (e.vertex === 0) {
      const { commonData, activeZone } = this.props;
      const isChina = _.get(commonData, 'location.isChina', false);

      if (isChina) {
        const lastPath = activeZone.path[activeZone.path.length - 1];

        if (!(e.latLng.lat === lastPath.lat && e.latLng.lng === lastPath.lng)) {
          activeZone.path.push({
            lat: e.latLng.lat,
            lng: e.latLng.lng
          });

          this.Polygon = activeZone.path;
        }
      } else {
        activeZone.path.push(e.latLng.toJSON());
      }
      this.props.onChangeActiveZone(activeZone, false);
    }
  };

  onDragEnd = e => {
    if (!e.latLng || (_.isNil(e.vertex) && _.isNil(e.edge))) return;
    console.log('---- Drag end ---', e.vertex);

    const { activeZone, polyline } = this.props;
    const { location: { isChina } } = this.props.commonData;
    const activePath = {
      path: [...activeZone.path]
    };
    if (activeZone) {
      if (e.vertex === 0 && polyline) {
        return this.handlePolylineClick(e);
      }

      if (isChina) {
        const { latLng } = e;

        if (latLng) {
          const polylinePath = this.Polyline
            ? this.Polyline.getPath().elems.map(obj => {
                return {
                  lat: obj.lat,
                  lng: obj.lng
                };
              })
            : null;

          if (polyline) {
            activeZone.path = polylinePath;
            if (
              activePath.path &&
              !_.isEqual(
                activePath.path[0],
                activePath.path[activePath.path.length - 1]
              )
            ) {
              activePath.path[0] = activePath.path[activePath.path.length - 1];
            }

            this.props.onChangeActiveZone(activeZone);
          }
        }
      } else {
        if (!polyline) {
          activePath.path = this.Polygon.getPaths()
            .getAt(0)
            .getArray()
            .map(p => p.toJSON());
        } else {
          activePath.path = this.Polyline.getPath()
            .getArray()
            .map(p => p.toJSON());
          //activePath.path.push(e.latLng.toJSON());
        }

        this.props.onChangeActiveZone({
          ...activeZone,
          path: activePath.path
        });
      }
    }
  };

  handleMapClick = e => {
    const { activeZone, polyline } = this.props;
    const { location: { isChina } } = this.props.commonData;

    if (activeZone) {
      if (isChina) {
        const { latLng } = e;

        if (latLng) {
          let polylinePath = this.Polyline
            ? this.Polyline.getPath().elems.map(obj => {
                return {
                  lat: obj.lat,
                  lng: obj.lng
                };
              })
            : null;

          if (polyline) {
            console.log('----------- map click ------------', polyline);
            if (polylinePath) {
              polylinePath.push({ lat: latLng.lat, lng: latLng.lng });
            } else {
              polylinePath = [];
              polylinePath.push({ lat: latLng.lat, lng: latLng.lng });
            }

            activeZone.path = polylinePath;

            this.props.onChangeActiveZone(activeZone);
          }
        }
      } else {
        console.log('----------- map click ------------', polyline);
        if (!polyline) {
          const polygonPath = this.Polygon.getPaths()
            .getAt(0)
            .getArray()
            .map(p => p.toJSON());
          activeZone.path = polygonPath;
        } else {
          const polylinePath = this.Polyline.getPath()
            .getArray()
            .map(p => p.toJSON());
          activeZone.path = polylinePath;
          activeZone.path.push(e.latLng.toJSON());
        }

        this.props.onChangeActiveZone(activeZone);
      }
    }
  };

  onPickUpPolygonClick = (area) => {
    const { commonData, polyline, pickUpAreas } = this.props;
    if (polyline) {
      return;
    }
    const isChina = _.get(commonData, 'location.isChina', false);

    if (isChina) {
      const latLng = new window.qq.maps.LatLngBounds();
      area.path.map(pos => {
        latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
      });

      if (this._map) {
        this._map.fitBounds(latLng);
      }

      const index = _.findIndex(pickUpAreas, o => o._id === area.key);
      if (this.props.handleEditPickupArea && index !== -1) {
        this.props.handleEditPickupArea(area.key);

      }
    } else {
      const latLng = new google.maps.LatLngBounds();
      area.path.map(pos => {
        latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
      });

      if (this._map) {
        this._map.fitBounds(latLng);
      }
      const index = _.findIndex(pickUpAreas, o => o._id === area.key);
      if (this.props.handleEditPickupArea && index !== -1) {
        this.props.handleEditPickupArea(area.key);
      };
    }
  }

  onDriverAreaPolygonClick = (area) => {
    const { commonData, polyline } = this.props;
    if (polyline) {
      return;
    }
    const isChina = _.get(commonData, 'location.isChina', false);

    if (isChina) {
      const latLng = new window.qq.maps.LatLngBounds();
      area.path.map(pos => {
        latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
      });

      if (this._map) {
        this._map.fitBounds(latLng);
      }

      if (this.props.handleEditCheckInArea) {
        this.props.handleEditCheckInArea();
      }
    } else {
      const latLng = new google.maps.LatLngBounds();
      area.path.map(pos => {
        latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
      });

      if (this._map) {
        this._map.fitBounds(latLng);
      }

      if (this.props.handleEditCheckInArea) {
        this.props.handleEditCheckInArea();
      }
    }
  }

  handleAddressChange = address => {
    this.setState({
      address
    });
  };

  handleSelectAddress = (address, placeId, pointOfInterest, sessiontoken) => {
    if (address && address != '') {
      this.setState({
        address
      });
      const callback = (err, result, results) => {
        if (!err) {
          this.updateLocationAfterPickup(result);
        }
      };
      geoPlaceDetailMapProvider({
        auth: this.props.auth,
        placeid: placeId,
        sessiontoken,
        callback,
        language: this.props.language,
        bookId: this.props?.bookInfo?.bookId,
        isTencent: this.props?.commonData?.location?.isChina
      });
    }
  };

  handleRemoveAddress = () => {
    this.setState({
      address: null
    });
  };

  setCenterAndZoom = (centerLatLng, zoomValue) => {
    const { location: { isChina } } = this.props.commonData || {};
    let map = null;
    if (isChina && this.map) {
      map = this.map;
    } else if (!isChina && this._map && this._map.context) {
      map = this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    }
    if (!map) return;
    if (centerLatLng) {
      if (isChina) {
        const center = new window.qq.maps.LatLngBounds();
        center.extend(
          new window.qq.maps.LatLng(centerLatLng.lat, centerLatLng.lng)
        );
        map.setCenter(center.getCenter());
      } else map.setCenter(centerLatLng);
    }
    if (zoomValue) map.setZoom(zoomValue);
  };

  updateLocationAfterPickup = result => {
    const { lat, lng } = result || {};
    const center = {
      lat,
      lng
    };
    this.center = center;
    this.setCenterAndZoom(center, 15);
  };

  render() {
    const {
      address = null,
      parentZones,
      otherCheckinPolygon,
      otherPickupPolygon
    } = this.state;
    const {
      activeZone,
      polyline,
      showPickupArea,
      showCheckInArea,
      center: defaultCenter,
      activePickUpId,
      activeCheckIn
    } = this.props;
    const { location: { isChina } } = this.props.commonData;
    const center = this.center || defaultCenter;
    const { driverAreaPolygon, pickUpAreas } = this.transformQueuePolygon();

    // check show other areas
    let otherAreasPolygon = [];
    if (showPickupArea) {
      otherAreasPolygon = otherPickupPolygon;
    } else if (showCheckInArea) {
      otherAreasPolygon = otherCheckinPolygon;
    }
    return (
      <>
        {isChina ? (
          <>
            <QueueTencentMap
              containerElement={<div className="mapqup mb-md" />}
              mapElement={<div style={{ height: '100%' }} />}
              center={center}
              onMapLoad={this.handleMapLoad}
              polyline={polyline}
              parentZones={parentZones}
              driverArea={driverAreaPolygon}
              pickUpAreas={pickUpAreas}
              otherAreasPolygon={otherAreasPolygon}
              activeZone={activeZone}
              onDragEnd={this.onDragEnd}
              handleMapClick={this.handleMapClick}
              onPolygonComplete={() => {}}
              handlePolylineClick={this.handlePolylineClick}
              defaultZoom={this.defaultZoom || 12}
              onPickUpPolygonClick={this.onPickUpPolygonClick}
              onDriverAreaPolygonClick={this.onDriverAreaPolygonClick}
              activePickUpId={activePickUpId}
              activeCheckIn={activeCheckIn}
            />
            <div className="zone-location">
              <AutocompleteWith3rd
                  className="form-custom form-control"
                  value={address}
                  onChange={this.handleAddressChange}
                  onSelect={this.handleSelectAddress}
                  handleAddressRemoved={this.handleRemoveAddress}
              />
            </div>
          </>
        ) : (
          <>
            <QueueGoogeMap
              containerElement={<div className="mapqup mb-md" />}
              mapElement={<div style={{ height: '100%' }} />}
              defaultZoom={this.defaultZoom || 12}
              center={center}
              polyline={polyline}
              parentZones={parentZones}
              driverArea={driverAreaPolygon}
              pickUpAreas={pickUpAreas}
              otherAreasPolygon={otherAreasPolygon}
              activeZone={activeZone}
              onDragEnd={this.onDragEnd}
              onMapLoad={this.handleMapLoad}
              handleMapClick={this.handleMapClick}
              onPolygonComplete={() => {}}
              handlePolylineClick={this.handlePolylineClick}
              onCenterChanged={this.onCenterChanged}
              onPickUpPolygonClick={this.onPickUpPolygonClick}
              onDriverAreaPolygonClick={this.onDriverAreaPolygonClick}
              editable
              activePickUpId={activePickUpId}
              activeCheckIn={activeCheckIn}
            />
            <div className="zone-location">
              <PlacesAutocomplete
                value={address}
                onSelect={this.handleSelectAddress}
                onChange={this.handleAddressChange}
                className="location-select form-custom"
                styles={{}}
                placeholder={I18n.t('map.Enter_a_location')}
                onEnterKeyDown={this.handleSelectAddress}
                handleAddressRemoved={this.handleRemoveAddress}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

QueuingAreaMap.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
    language: state.i18n
  };
}

function mapDispatchToProps(dispatch) {
  return {
    queuingAreaActions: bindActionCreators(queuingAreaActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(QueuingAreaMap);
